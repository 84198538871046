import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import CmsContent from "../../../MiddleWare/CmsContent";
import MultiSelect from "../../../components/Form/MultiSelect";
import { Alert } from "reactstrap";
import Datatable from "../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";

class MappingToGroup extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      categoryOptions: [],
      categoryOption: [],
      categorySelected: [],
      learnerOptions: [],
      learnerOption: [],
      selectedlearner: [],
      editvalue: null,
      categoryId: null,
      alertVisible: false,
      disableValue: false,
      disableGroup: false,
      button: "Submit",
      textalert: null,
      color: "success"
    };
  }

  async componentDidMount() {
    try {
      let customerId = await localStorage.getItem("userId");
      let serviceId = await localStorage.getItem("currentService");

      //let result = await CmsContent.getMasterValues('tbl_group');
      let result = await CmsContent.getConditionedValuewithStatus(
        "tbl_group",
        "customerId",
        customerId,
        "id",
        "name"
      );

      if (result) {
        console.log(result, 1);
        this.setState({ categoryOptions: result.data, customerId });
      }

      result = await CmsContent.getCompanyUsers(customerId, 6, serviceId);
      if (result) {
        console.log(result, 2);
        this.setState({ learnerOptions: result.data });
      }

      // result = await CmsContent.getAllSubtitleList("tbl_maptogroup");

      result = await CmsContent.getFreedom("*", "tbl_maptogroup", `customerId=${customerId} and userTypeId=6`, 1, 1)
      if (result) {
        console.log(result, 3);
        this.setState({ data: result.data });
      }
      this.check();
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      Header: "Cohort",
      accessor: "label"
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: d =>
        this.dataTableButton("warning", "Edit", () => {
          this.edition(d.original);
        })
    },
    {
      Header: "Un-Map All",
      accessor: "delete",
      Cell: d =>
        this.dataTableButton("danger", "Un-Map", () => {
          this.buttonDeletes(d);
        })
    }
  ];
  edition = async d => {
    this.setState({ button: "Update" });
    let { data: mapToGroup, learnerOptions: learnerList } = this.state;
    this.programSelect(d);

    let learners = mapToGroup
      .map((ival, i) => {
        if (ival.groupid == d.value) {
          let returnArray = {};
          returnArray.value = ival.userid;

          returnArray.label = this.getValueFromArray(ival.userid, learnerList);

          return returnArray;
        }
      })
      .filter(function (element) {
        return element !== undefined;
      });
    console.log(learners);
    let data = await this.removeDuplicates(learners, "value")
    this.learnerSelect(data);
    this.setState({
      button: "Update"
      // disableGroup: true,
      // alertVisible: true,
      // textalert: "Map To Group Updated",
      // color: "success"
    });
    // setTimeout(() => this.setState({ alertVisible: false }), 3000);
  };
  buttonDeletes = value => {
    this.setState({});
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deletion(value)
          //onClick: () => alert("Group removed from")
        },
        {
          label: "No"
        }
      ]
    });
  };
  deletion = async d => {
    let index = d.index;
    let value = d.original;
    // let result = await CmsContent.getSingleConditionedValue(
    //   "tbl_maptogroup",
    //   "groupid",
    //   value,
    //   "Delete"
    // );

    let result = await CmsContent.deleteFreedom("tbl_maptogroup",
      `groupid=${value.value}
     and customerid=${value.customerId}
      and userTypeId=6`)
    if (result) {
      let categoryOptions = this.state.categoryOptions;
      this.setState({ categoryOption: [] });
      categoryOptions.splice(index, 1);
      this.setState({ categoryOptions });
      this.check();
    }
  };
  check = () => {
    let { categoryOptions: learnerList, data: mapToGroup } = this.state;
    var newLearnerList = [];
    Object.keys(learnerList).forEach(key => {
      var checkJson = this.filterBy(mapToGroup, {
        groupid: learnerList[key].value
      });
      if (Object.keys(checkJson).length !== 0) {
        newLearnerList.push(learnerList[key]);
      }
    });
    this.setState({ categoryOption: newLearnerList });
  };
  programSelect = async e => {
    this.setState({ categorySelected: e, categoryId: e.value });

    if (this.state.button === "Submit") {
      let { learnerOptions: learnerList, data: mapToGroup } = this.state;
      var newLearnerList = [];
      Object.keys(learnerList).forEach(key => {
        var checkJson = this.filterBy(mapToGroup, {
          groupid: e.value,
          userid: learnerList[key].value
        });
        if (Object.keys(checkJson).length == 0) {
          newLearnerList.push(learnerList[key]);
        }
      });

      this.setState({ learnerOption: newLearnerList });
    } else {
      this.setState({
        learnerOption: this.state.learnerOptions,
        disableGroup: true,
        alertVisible: true,
        textalert: "Map To Group Updated",
        color: "success"
      });
    }
  };
  learnerSelect = async e => {
    if (e) {
      this.setState({ selectedlearner: e });
    }
  };

  onSubmit = async () => {
    const {
      categorySelected,
      selectedlearner,
      categoryId,
      customerId
    } = this.state;
    let selectedValue = "";
    const value = selectedlearner.map(
      values => (selectedValue += `${values.value},`)
    );
    selectedValue = selectedValue.replace(/,\s*$/, "");
    const formData = new FormData();
    let status = this.state.button === "Submit" ? "active" : "deactive";
    formData.append("groupid", categoryId);
    formData.append("userid", selectedValue);
    formData.append("status", status);
    formData.append("userTypeId", 6);
    formData.append("customerid", customerId);
    if (
      categorySelected == "" ||
      categorySelected == null ||
      categorySelected == undefined
    ) {
      this.setState({ errorselect: "select cohort" });
      return false;
    } else if (
      selectedlearner == "" ||
      selectedlearner == null ||
      selectedlearner == undefined
    ) {
      this.setState({ errorselect: "", error: "select learner" });
      return false;
    } else {
      this.setState({ error: "" });
    }
    this.setState({
      alertVisible: true,
      disableValue: true,
      selectedlearner: "",
      categorySelected: ""
    });
    setTimeout(() => this.setState({ alertVisible: false }), 3000);
    try {
      let result = await CmsContent.learningObjectSubmit(
        "tbl_maptogroup",
        formData
      );
      if (result) {
        // result = await CmsContent.getAllSubtitleList("tbl_maptogroup");
        result = await CmsContent.getFreedom("*", "tbl_maptogroup", `customerId=${customerId} and userTypeId=6`, 1, 1)
      
        if (result) {
          this.setState({
            data: result.data,
            button: "Submit",
            disableValue: false,
            disableGroup: false,
            alertVisible: true,
            textalert: "New Map To Group Added",
            color: "success"
          });
          setTimeout(() => this.setState({ alertVisible: false }), 3000);
          this.check();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  onDismiss = () => {
    this.setState({ alertVisible: false });
  };

  render() {
    const {
      categoryOptions,
      alertVisible,
      learnerOption,
      disableValue,
      errorselect,
      error,
      button,
      categorySelected,
      selectedlearner,
      disableGroup,
      textalert
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Map Learner To Cohort</h3>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Select Cohort</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          disabled={disableGroup}
                          options={categoryOptions}
                          handleChange={this.programSelect}
                          selectedService={categorySelected}
                        />
                        <span className="error-shows">{errorselect}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="subgroup">Learners</label>
                      </div>
                      <div className="col-sm-5">
                        <MultiSelect
                          options={learnerOption}
                          handleChange={this.learnerSelect}
                          selectedService={selectedlearner}
                        />
                        <span className="error-shows">{error}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={disableValue}
                          onClick={this.onSubmit}
                        >
                          {this.state.button}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <span>
                      Total of {Object.keys(this.state.categoryOption).length}{" "}
                      records
                    </span>
                    {this.state.categoryOption && (
                      <Datatable
                        data={this.state.categoryOption}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default MappingToGroup;
