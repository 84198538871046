import React, { Component } from "react";
import { Alert } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import cmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import LoginModal from "../../../../components/Modal/Modal";
import FromLibrary from "../FromLibrary";
import { ACCESS_POINT, FILEPATH } from "../../../../config";
import { ACCESS_SAMPLE } from "../../../../config";
import http from "../../../../MiddleWare/httpMiddleWare";
import Progress from "../../../../components/Extra/Progress";
import MultiSelect from "../../../../components/Form/MultiSelect";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import SingleSelect from "../../../../components/Form/SingleSelect";
import htmlToDraft from 'html-to-draftjs';
import AddquizmodelBc from '../AddquizmodelBc';
import Switch from "react-switch";

class Theme3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      getlibrarydataImage: props.that.getlibrarydataImage,
      getlibrarydataVideo: props.that.getlibrarydataVideo,
      getlibrarydataDocs: props.that.getlibrarydataDocs,
      data: props.that.data,
      userlog: JSON.parse(localStorage.getItem('userlog')),
      btnDisable: false,
      appendOverlayList: [],
      appendOverlayData: [],
      overlayOption: [
        { label: "Quiz", value: "Quiz" },
        { label: "Survey", value: "Survey" },
        { label: "Ad Banner", value: "Ad Banner" },
        { label: "Load Url", value: "Load Url" }
      ],
      overlayColor: [
        {
          rgb: {
            r: "241",
            g: "112",
            b: "19",
            a: "1"
          },
          hex: `#f17013`
        }
      ],
      texttype: null,
      customerId: this.props.that.customerId,
      timelineCheck: [],
      timelineCheckMM: [],
      timelineCheckSS: [],
      bannerImageCount: [],
      hour: [],
      minute: [],
      seconds: [],
      isEdit: false,
      screen: window.screen.height,
      uploadPercentage: 0,
      quizModel: {}

    }
  }

  async componentWillMount() {
    try {
      //Datatable
      if (this.props.that.data) {
        this.refreshSubtitle();
        this.refreshQuiz();
        let pages = [];
        this.props.that.data.map((ival, i) => {
          if (ival.themeId === 3) {
            pages.push(ival);
          }
        })
        this.setState({ data: pages })
      }
      //Edit without selecting themes
      if (this.props.that.isEdit) {
        //Edit QuizId
        const getQuizQuestion = await cmsContent.getFreedom(
          "id as value,quizName as label",
          "tbl_question",
          `customerid=${this.props.that.customerId}`,
          "id",
          "id DESC"
        );
        if (getQuizQuestion) {
          this.setState({ getQuizQuestion: getQuizQuestion.data });

        }
        //Content1
        const { contentBlocks, entityMap } = htmlToDraft(this.props.that.content1);
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState);

        // Subtitle
        let selectedService = [];
        let val = this.props.that.subTitleId ? this.props.that.subTitleId.split(',') : [];
        if (this.state.subtitleOption.length > 0) {
          this.state.subtitleOption.map((ival, i) => {
            val.map((jval, j) => {
              if (jval == ival.value) {
                selectedService.push(ival)
              }
            })
          })
        }
        //FielName
        if (this.props.that.fileName1 == "undefined") {
          this.setState({ file1: '', fileName1: '' })
        }
        else {
          this.setState({ file1: this.props.that.fileName1, fileName1: this.props.that.fileName1 })
        }
        if (this.props.that.thumbnailname == "undefined") {
          this.setState({ thumbnail: '', thumbnailname: '' })
        }
        else {
          this.setState({ thumbnail: this.props.that.thumbnailname, thumbnailname: this.props.that.thumbnailname })
        }
        if (this.props.that.fileText == "undefined") {
          this.setState({ fileText: '', fileName2: '' })
        }
        else {
          this.setState({ fileText: this.props.that.fileText, fileName2: this.props.that.fileText, })
        }
        this.setState({
          contentTitle1: this.props.that.contentTitle1, content1: this.props.that.content1, searchTags: this.props.that.searchTags,
          editorState,
          isEdit: this.props.that.isEdit,
          EditId: this.props.that.id,
          selectedService,
          data: this.props.that.datatable
        })
      }

      //Add Overlay States
      if (this.props.that.appendOverlayList) {
        let appendOverlayData = [];
        let appendOverlayList = this.props.that.appendOverlayList;
        let { getQuizQuestion } = this.state;
        console.log(appendOverlayList)
        appendOverlayList.map(async (ival, i) => {
          let appendOverlayListIndex = [];
          getQuizQuestion && getQuizQuestion.map((opt, o) => {
            if (ival.quizId == opt.value) {
              opt.isdisabled = true
            }
          })
          appendOverlayListIndex.overlayName = ival.overlayName;
          appendOverlayListIndex.appendValue = ival.overlayType;
          appendOverlayListIndex.overlayType = { label: ival.overlayType, value: ival.overlayType };
          appendOverlayListIndex.surveyUrl = ival.surveyUrl;
          appendOverlayListIndex.loadUrl = ival.loadUrl;
          appendOverlayListIndex.bannerTargetUrl = ival.bannerTargetUrl;
          appendOverlayListIndex.quizId = ival.quizId;
          appendOverlayListIndex.width = ival.width;
          appendOverlayListIndex.height = ival.height;
          appendOverlayListIndex.bannerImage = ival.bannerImage;
          appendOverlayListIndex.duration = ival.duration;
          appendOverlayListIndex.overlayPreamble = ival.overlayPreamble;
          appendOverlayListIndex.isCertified = ival.isCertified;
          appendOverlayListIndex.Instruction = ival.Instruction;

          appendOverlayData.push(appendOverlayListIndex);
          await this.setTimeline(ival.duration, i)
        })
        this.setState({ appendOverlayData, appendOverlayList, getQuizQuestion })

      }
    }
    catch (e) {
      console.log(e);
    }
  }
  column = [
    {
      Header: "Page",
      accessor: "name"
    },
    {
      Header: "Theme",
      acccessor: "edit",
      Cell: (d) => this.showTheme(d)
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "Visibility",
      accessor: "edit",
      Cell: d => this.showVisibility(d)
    },
    {
      Header: "Require Login",
      accessor: "requireLogin"
    },
    {
      Header: "Change Login Status",
      accessor: "edit",
      Cell: d => this.changeLoginStatus(d)
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: d => this.Editpages(d)
    },
    {
      Header: "Preview",
      accessor: "edit",
      Cell: d => this.PreviewPage(d)
    },

  ];

  showTheme = (d) => {
    return `Theme-${d.original.themeId}(To Add images,Text and Videos)`;
  };
  PreviewPage = (d) => {

    return (<button
      type="button"
      className="btn btn-info"
      onClick={() => this.PreviewRoute(d)}
    >
      Preview
    </button>
    );
  }

  PreviewRoute = (d) => {

    let id = d.original.id;
    let customerId = d.original.customerId;

    if (id) {
      //this.props.history.replace(`http://35.169.94.138:3030/samplesite/commonpg/${id}`);
      window.open(
        `${ACCESS_SAMPLE}/samplesite/th3/${id}/${customerId}`,
        '_blank'
      );


    }

  }

  handlechange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  selectImage1 = (e, type = null) => {
    if (type == 1) {
      this.setState({ fileText: e.target.files[0], fileName2: e.target.files[0].name });
    } else {
      this.setState({ file1: e.target.files[0], fileName1: e.target.files[0].name });
    }
  };

  selectthumbnail = e => {
    this.setState({
      thumbnail: e.target.files[0],
      thumbnailname: e.target.files[0].name
    });
  };
  showVisibility = id => {
    return (
      <button
        type="button"
        className="btn btn-info"
        onClick={() => this.updateVisibility(id)}
      >
        Hide/Show
      </button>
    );
  };
  updateVisibility = async value => {
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };

    if (newData.status === "show") {
      newData.status = "hide";
    } else {
      newData.status = "show";
    }
    const id = newData.id;
    const data = previousData.filter(value => value.id !== id);
    data.splice(index, 0, newData);
    try {
      const result = await cmsContent.updatePage(newData);
      if (result) {
        this.setState({ data: data });
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };

  changeLoginStatus = id => {
    return (
      <button
        type="button"
        className="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '10px' }}
        onClick={() => this.updateLogin(id)}
      >
        Change Login Status
      </button>
    );
  };

  updateLogin = async value => {
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };
    if (newData.requireLogin === "yes") {
      newData.requireLogin = "no";
    } else {
      newData.requireLogin = "yes";
    }
    const id = newData.id;
    const data = previousData.filter(value => value.id !== id);
    data.splice(index, 0, newData);
    this.setState({ data });
    try {
      const result = await cmsContent.updateLoginStatus(newData);
      if (result) {
        this.setState({ data: data });
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };

  Editpages = value => {
    return (
      <button className="btn btn-info" onClick={() => this.onEdit(value)}>
        Edit
      </button>
    );
  };
  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };
  onEditorStateChange1 = async (e, type, index, l = true) => {
    const { appendOverlayList } = this.state;
    appendOverlayList[index][type] = l ? e : e;
    console.log(e, appendOverlayList, "appendOverlayList");
    let appendOverlayData = await this.appendOverlayListToaod(appendOverlayList);
    await this.setState({ appendOverlayList, appendOverlayData });
  };
  appendOverlayListToaod = async (appendOverlayList) => {
    let appendOverlayData = [];
    await appendOverlayList.map((ival, i) => {
      let appendOverlayListIndex = [];
      appendOverlayListIndex.overlayName = ival.overlayName;
      appendOverlayListIndex.appendValue = ival.overlayType;
      appendOverlayListIndex.overlayType = { label: ival.overlayType, value: ival.overlayType };
      appendOverlayListIndex.surveyUrl = ival.surveyUrl;
      appendOverlayListIndex.loadUrl = ival.loadUrl;
      appendOverlayListIndex.bannerTargetUrl = ival.bannerTargetUrl;
      appendOverlayListIndex.quizId = ival.quizId;
      appendOverlayListIndex.width = ival.width;
      appendOverlayListIndex.height = ival.height;
      appendOverlayListIndex.bannerImage = ival.bannerImage;
      appendOverlayListIndex.overlayPreamble = ival.overlayPreamble;
      appendOverlayListIndex.isCertified = ival.isCertified;
      appendOverlayListIndex.Instruction = ival.Instruction
      console.log(appendOverlayListIndex);
      appendOverlayData.push(appendOverlayListIndex);
    })
    return appendOverlayData
  }

  viewmedia = async value => {
    this.setState({
      videotype: "",
      viewmedia: ACCESS_POINT + `/superAdmin/file?fileurl=${value}`
    });
    var that = this;
    setTimeout(function () {
      if (value.split(".")[1] === "mp4") {
        that.setState({ videotype: "video" });
      } else if (
        value.split(".")[1] === "jpg" ||
        value.split(".")[1] === "png" ||
        value.split(".")[1] === "jpeg"
      ) {
        that.setState({ videotype: "image" });
      } else {
        that.setState({ videotype: null });
      }
    }, 500);
  }
  contentHeading = [
    {
      Header: "Search By Hint",
      accessor: "hint"
    },
    {
      Header: "File Name",
      accessor: "label",
      Cell: d => this.aModalLink(d)
    },
    {
      Header: "Select",
      accessor: "edit",
      Cell: d => this.checktest(d)
    }
  ];
  aModalLink = id => {
    if ((this.state.customerId == 807 || this.state.customerId == '6075' || this.state.customerId == 6075) && id.original.file_extension == 'mp4') {
      return <button className='btn btn-primary' onClick={() => window.open(FILEPATH + id.original.label)}>viewmedia</button>
    } else {
      let str = id.original.label;
      let res = str.split("/");
      return (
        // <a href="#" onClick={() => this.viewmedia(id.original.label)}>
        //   {res[1]}
        // </a>      
        <button className='btn btn-primary' onClick={() => window.open(ACCESS_POINT + `/superAdmin/file?fileurl=${id.original.label}`)}>viewmedia</button>
      );

    }
  };
  checktest = id => {
    return (
      <button
        type="button"
        className="btn btn-info"
        data-dismiss="modal"
        onClick={() =>
          this.setState({ [this.state.label]: id.original.label, [this.state.label1]: id.original.label })
        }
      >
        Select
      </button>
    );
  };
  appendOverlayQuiz = async (appendValue, name, index) => {
    const { appendOverlayList } = this.state;
    let { getQuizQuestion } = this.state;
    //disable
    getQuizQuestion && getQuizQuestion.map((opt, o) => {
      if (appendValue.value == opt.value) {
        opt.isdisabled = true
      }
    })
    appendOverlayList[index][name] = appendValue.value;
    //disable
    getQuizQuestion && getQuizQuestion.map((opt, o) => {
      let op = appendOverlayList.some((ival) => ival.quizId == opt.value)
      if (op) {
        opt.isdisabled = true
      } else {
        opt.isdisabled = false
      }
    })
    let appendOverlayData = await this.appendOverlayListToaod(appendOverlayList);
    await this.setState({ appendOverlayList, appendOverlayData, getQuizQuestion });
  };
  submitCategory = async e => {
    console.log('entered submit');
    console.log(this.state.userlog, 'log');
    e.preventDefault();
    //Getting vaue from parent page
    const {
      checked,
      autherSelected,
      pageName,
      customerId
    } = this.props.that;

    //Getting vaue from current page
    const {
      contentTitle1,
      editorState,
      searchTags,
      linkto,
      file1,
      fileText,
      thumbnail,
      appendOverlayList,
      servicesValue,
      bannerImageCount,
      EditId,
      Index,
      thumbnailname,
      fileName2,
      userlog
    } = this.state;

    let editorContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    if (!autherSelected) {
      this.props.these('errorAuthorName', 'Select Author');
      return false
    } else if (!pageName) {
      this.props.these('errorAuthorName', '')
      this.props.these('errorPageName', 'Enter Page name');
      return false;
    }
    //Use this for parent state change
    // if (typeof this.props.these === 'function') {
    // 	this.props.these('errorPageName','e.pag.value');
    // 	this.props.these('errorAuthorName','e.target.value');
    // }

    // if (!authorId) {
    //   this.setState({ errorAuthorName: "Select Author" });
    //   return false;
    // } else if (!pageName) {
    //   this.setState({ errorAuthorName: null, errorPageName: "Add Page Name" });
    //   return false;
    // } else if (!themeId) {
    //   this.setState({ errorPageName: "", errorThemeId: "Select Theme" });
    //   return false;
    // } else {
    if (!contentTitle1) {
      this.setState({
        errorThemeId: "",
        errorContentTitle1: "Enter Content Title"
      });
      return false;
    } else if (!editorContent) {
      this.setState({
        errorContentTitle1: "",
        errorContent1: "Enter Content"
      });
      return false;
    } else if (!searchTags) {
      this.setState({
        errorContentTitle1: "",
        errorSearchTags: "Enter Search Tags"
      });
      return false;
    } else if (!file1 && (this.state.customerId != 807 && this.state.customerId !== '6075' && this.state.customerId !== 6075 && userlog.elearn != '1')) {
      this.setState({
        errorSearchTags: "",
        errorFile1: "Select File to Upload"
      });
      return false;
    } else if (!fileText && !fileName2 && (this.state.customerId == 807 || this.state.customerId == '6075' || this.state.customerId == 6075 || userlog.elearn == '1')) {
      this.setState({
        errorSearchTags: "",
        errorFile1: "Select File to Upload"
      });
      return false;
    }
    else if (!thumbnail) {
      this.setState({ errorFile1: '', errorFile2: "Select thumbnail to Upload" });
      return false;
    } else if (appendOverlayList) {
      let valid = appendOverlayList.filter(d => d.isCertified == true)
      if (valid.length > 1) {
        this.setState({ errorFile2: '', errorTick: "Please Tick Any One Overlay" });
        return false;
      }
    }
    this.setState({
      errorsubTitleSelected: "",
      errorFile2: "",
      errorTick: "",
      btnDisable: false
    });
    this.setState({ btnDisable: true });
    let today = new Date();
    var dateformat = today.getDate();
    if (today.getDate() < 10) {
      dateformat = "0" + today.getDate();
    }
    let date =
      today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + dateformat;

    let formData = new FormData();
    formData.append("name", pageName.trim());
    formData.append("themeId", 3);
    if (this.props.that.isEdit == true) {

      formData.append("authorid", autherSelected && autherSelected[0].value ? autherSelected[0].value : autherSelected.value)
    }
    else {
      formData.append("authorid", autherSelected && autherSelected.value);
    }
    formData.append("contentTitle1", contentTitle1.trim());
    formData.append("content1", editorContent);
    formData.append("show", checked);
    formData.append("searchTags", searchTags);
    // formData.append("linkto", linkto);
    formData.append("customerId", customerId);
    formData.append("Date", date);
    formData.append("status", "show");
    formData.append("file", file1);
    if (this.state.customerId == '807' || this.state.customerId == '6075' || userlog.elearn == '1') {
      if (fileText) {
        formData.append("filename", fileText);
      }
      if (!fileText && fileName2) {
        formData.append("filename", fileName2);
      }
    }
    else{
      formData.append("filename", fileText);
    }
    formData.append("file2", thumbnail);
    formData.append("thumbnail", thumbnailname);
    formData.append("videoOverlay", JSON.stringify(appendOverlayList));
    formData.append("subTitleId", servicesValue);
    bannerImageCount.map((ival, i) => {
      formData.append(ival, this.state[ival]);
    })

    console.log([...formData], 'formdata');
    try {
      if (this.state.isEdit) {
        formData.append("id", EditId);

        let { data } = await http.put(
          ACCESS_POINT + `/cmsContent/tbl_pages`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => {
              this.setState({
                uploadPercentage: parseInt(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                )
              });
              setTimeout(() => this.setState({ uploadPercentage: 0 }), 10000);
            }
          }
        );

        if (data && data.length) {
          data = data[0];
          let appendOverlayList = [];
          let appendOverlayListIndex = [];
          appendOverlayListIndex.overlayName = '';
          appendOverlayListIndex.appendValue = null;
          appendOverlayListIndex.overlayType = null;
          appendOverlayListIndex.surveyUrl = null;
          appendOverlayListIndex.loadUrl = null;
          appendOverlayListIndex.bannerTargetUrl = null;
          appendOverlayListIndex.quizId = null;
          appendOverlayListIndex.width = null;
          appendOverlayListIndex.height = null;
          appendOverlayListIndex.bannerImage = null;
          appendOverlayListIndex.overlayPreamble = null;

          appendOverlayList.push(appendOverlayListIndex);

          const previousData = [...this.state.data];

          if (this.props.that.isEdit) {
            let index = this.props.that.data.indexOf(this.props.that.data1);
            previousData[index] = data;
          }
          else {
            previousData[Index] = data;
          }
          setTimeout(() => this.setState({
            contentTitle1: '',
            editorState: EditorState.createEmpty(),
            searchTags: '',
            // linkto: '',
            file1: '',
            thumbnail: '',
            btnDisable: false,
            isEdit: false,
            appendOverlayData: [],
            appendOverlayList,
            data: previousData,
            thumbnailname: '',
            fileName1: '',
            errorContentTitle1: '',
            errorContent1: '',
            errorSearchTags: '',
            errorFile1: '',
            errorFile2: '',
            fileText: "",
            fileName2: '',
          }), 10000);
          this.props.these('pageName', '');
          this.props.these('checked', 0);
          this.props.these('errorPageName', '')
          this.props.these('autherSelected', '');
          this.props.these('themeSelected', '')
          this.props.these('isEdit', false)

          setTimeout(() => this.props.these('alertVisible', true), 10000);
        }
      } else {
        formData.append("requireLogin", 'no');
        let { data } = await http.post(
          ACCESS_POINT + `/cmsContent/tbl_pages`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => {
              this.setState({
                uploadPercentage: parseInt(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                )
              });
              setTimeout(() => this.setState({ uploadPercentage: 0 }), 10000);
            }
          }
        );

        if (data) {
          data = data[0];
          let appendOverlayList = [];
          let appendOverlayListIndex = [];
          appendOverlayListIndex.overlayName = '';
          appendOverlayListIndex.appendValue = null;
          appendOverlayListIndex.overlayType = null;
          appendOverlayListIndex.surveyUrl = null;
          appendOverlayListIndex.loadUrl = null;
          appendOverlayListIndex.bannerTargetUrl = null;
          appendOverlayListIndex.quizId = null;
          appendOverlayListIndex.width = null;
          appendOverlayListIndex.height = null;
          appendOverlayListIndex.bannerImage = null;
          appendOverlayListIndex.overlayPreamble = null;

          appendOverlayList.push(appendOverlayListIndex);
          const previousData = [data, ...this.state.data];
          //previousData[Index]=data;
          setTimeout(() => this.setState({
            contentTitle1: '',
            editorState: EditorState.createEmpty(),
            searchTags: '',
            // linkto: '',
            file1: '',
            thumbnail: '',
            btnDisable: false,
            isEdit: false,
            appendOverlayData: [],
            appendOverlayList,
            data: previousData,
            thumbnailname: '',
            fileName1: '',
            errorContentTitle1: '',
            errorContent1: '',
            errorSearchTags: '',
            errorFile1: '',
            errorFile2: '',
            fileText: '',
            fileName2: '',
          }), 10000);
          this.props.these('pageName', '');
          this.props.these('checked', 0);
          this.props.these('errorPageName', '')
          this.props.these('autherSelected', '');
          this.props.these('themeSelected', '')
          setTimeout(() => this.props.these('alertVisible', true), 10000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Theme3 Extras
  handleSubtitle = selectedOption => {

    let selectedValue = "";
    const value = selectedOption.map(
      values => (selectedValue += `${values.value},`)
    );
    selectedValue = selectedValue.replace(/,\s*$/, "");

    this.setState({
      selectedService: selectedOption,
      servicesValue: selectedValue
    });
  };
  refreshSubtitle = async () => {
    try {
      const { data: subtitleOption } = await cmsContent.getFreedom(
        "id as value,subTitleName as label",
        "tbl_Subtitle",
        `customerId=${this.props.that.customerId}`,
        "id",
        "id DESC"
      );
      if (subtitleOption) {

        this.setState({ subtitleOption });
      }
    } catch (error) {
      console.log(error);
    }
  };
  appendOverlayList = () => {
    const { appendOverlayList } = this.state;
    appendOverlayList.push({
      overlayName: null,
      overlayType: null,
      duration: null,
      overlayColor: [
        {
          rgb: {
            r: "241",
            g: "112",
            b: "19",
            a: "1"
          },
          hex: `#f17013`
        }
      ],
      quizId: null,
      bannerImage: null,
      bannerTargetUrl: null,
      loadUrl: null,
      width: null,
      height: null
    });
    this.setState({ appendOverlayList });
  };
  appendOverlayData = (appendValue, index) => {
    const { appendOverlayData, appendOverlayList } = this.state;
    const appendJson = {
      index,
      appendValue: appendValue.label
    };
    let indexValue = appendOverlayData[index];

    if (indexValue) {
      if (appendOverlayData.length > index) {
        if (parseInt(indexValue.index) !== parseInt(index)) {
          appendOverlayData.splice(indexValue.index, 1);
        }
      }
      if (parseInt(indexValue.index) === parseInt(index)) {
        appendOverlayData.splice(index, 1);
      }
    }
    appendOverlayData.splice(index, 0, appendJson);
    let appendOverlayListIndex = appendOverlayList[index];
    appendOverlayListIndex.overlayType = appendOverlayData[index].appendValue;
    appendOverlayListIndex.surveyUrl = null;
    appendOverlayListIndex.loadUrl = null;
    appendOverlayListIndex.bannerTargetUrl = null;
    appendOverlayListIndex.quizId = null;
    appendOverlayListIndex.width = null;
    appendOverlayListIndex.height = null;
    appendOverlayListIndex.bannerImage = null;
    this.setState({ appendOverlayData, appendOverlayList });
  };
  addOverlayColor = (color, index) => {
    const { overlayColor, appendOverlayList } = this.state;
    overlayColor[index] = color;
    appendOverlayList[index]["overlayColor"] = color;
    this.setState({ overlayColor, appendOverlayList });
  };
  setTimeline = async (Vidduration, index) => {
    const {
      appendOverlayList,
      timelineCheck,
      timelineCheckMM,
      timelineCheckSS,
    } = this.state;
    let hour = []
    let minute = []
    let seconds = []
    let duration = Vidduration ? Number(Vidduration) : 0;

    hour[index] = Math.floor(duration / 3600);
    duration = duration % 3600;
    hour[index] = hour[index] <= 9 ? `0${hour[index]}` : hour[index].toString();
    minute[index] = Math.floor(duration / 60);
    duration = duration % 60;
    minute[index] = minute[index] <= 9 ? `0${minute[index]}` : minute[index].toString();
    seconds[index] = duration
    duration = duration % 60;
    seconds[index] = seconds[index] <= 9 ? `0${seconds[index]}` : seconds[index].toString();

    timelineCheck[index] = { index: index, hh: hour[index] }
    timelineCheckMM[index] = { index: index, mm: minute[index] }
    timelineCheckSS[index] = { index: index, ss: seconds[index] }
    await this.setState({ timelineCheck, timelineCheckMM, timelineCheckSS })
    console.log(timelineCheck, timelineCheckMM, timelineCheckSS);
  }
  timelineJson = (e, type, index) => {
    const {
      appendOverlayList,
      timelineCheck,
      timelineCheckMM,
      timelineCheckSS,
      hour, minute, seconds
    } = this.state;

    let newValue = null;
    let oldValue = null;

    if (type === "hh") {
      if (timelineCheck.length > 0 && timelineCheck[index]) {
        timelineCheck.map(val => {
          if (val.index === index) {
            hour[index] = e.target.value;
            console.log('hour', hour)
            let previousHour = parseInt(timelineCheck[index].hh);
            let newHour = parseInt(e.target.value);
            timelineCheck[index].hh = e.target.value;
            if (newHour > previousHour) {
              newHour = newHour - previousHour;
              newValue = newHour * 60 * 60;
              if (parseInt(appendOverlayList[index]["duration"]) > 0) {
                oldValue = appendOverlayList[index]["duration"];
                newValue = newValue + parseInt(oldValue);
              }
            } else {
              newHour = previousHour - newHour;
              newValue = newHour * 60 * 60;
              if (parseInt(appendOverlayList[index]["duration"]) > 0) {
                oldValue = appendOverlayList[index]["duration"];
                newValue = parseInt(oldValue) - newValue;
              }
            }

            appendOverlayList[index]["duration"] = newValue;
          }
        });
      } else {
        let timeLineJson = {};
        timeLineJson.index = index;
        timeLineJson.hh = e.target.value;

        timelineCheck.push(timeLineJson);
        this.setState({ timelineCheck });
        newValue = e.target.value * 60 * 60;
        if (parseInt(appendOverlayList[index]["duration"]) > 0) {
          oldValue = appendOverlayList[index]["duration"];
          newValue = parseInt(newValue) + parseInt(oldValue);
        }
        appendOverlayList[index]["duration"] = newValue;
      }

    } else if (type === "mm") {
      if (timelineCheckMM.length > 0 && timelineCheckMM[index]) {
        minute[index] = e.target.value;
        timelineCheckMM.map(val => {
          if (val.index === index) {
            let previousHour = parseInt(timelineCheckMM[index].mm);
            let newHour = parseInt(e.target.value);
            timelineCheckMM[index].mm = e.target.value;
            if (newHour > previousHour) {
              newHour = newHour - previousHour;
              newValue = newHour * 60;
              if (parseInt(appendOverlayList[index]["duration"]) > 0) {
                oldValue = appendOverlayList[index]["duration"];
                newValue = newValue + parseInt(oldValue);
              }
            } else {
              newHour = previousHour - newHour;
              newValue = newHour * 60;
              if (parseInt(appendOverlayList[index]["duration"]) > 0) {
                oldValue = appendOverlayList[index]["duration"];
                newValue = parseInt(oldValue) - newValue;
              }
            }
            appendOverlayList[index]["duration"] = newValue;
          }
        });
      } else {
        let timeLineJson = {};
        timeLineJson.index = index;
        timeLineJson.mm = e.target.value;
        timelineCheckMM.push(timeLineJson);
        this.setState({ timelineCheckMM });
        newValue = e.target.value * 60;
        if (parseInt(appendOverlayList[index]["duration"]) > 0) {
          oldValue = appendOverlayList[index]["duration"];
          newValue = parseInt(newValue) + parseInt(oldValue);
        }
        appendOverlayList[index]["duration"] = newValue;
      }
    } else {
      if (timelineCheckSS.length > 0 && timelineCheckSS[index]) {
        seconds[index] = e.target.value
        timelineCheckSS.map(val => {
          if (val.index === index) {
            let previousHour = parseInt(timelineCheckSS[index].ss);
            let newHour = parseInt(e.target.value);
            timelineCheckSS[index].ss = e.target.value;
            if (newHour > previousHour) {
              newHour = newHour - previousHour;
              newValue = newHour;
              if (parseInt(appendOverlayList[index]["duration"]) > 0) {
                oldValue = appendOverlayList[index]["duration"];
                newValue = newValue + parseInt(oldValue);
              }
            } else {
              newHour = previousHour - newHour;
              newValue = newHour;
              if (parseInt(appendOverlayList[index]["duration"]) > 0) {
                oldValue = appendOverlayList[index]["duration"];
                newValue = parseInt(oldValue) - newValue;
              }
            }
            appendOverlayList[index]["duration"] = newValue;
          }
        });
      } else {
        let timeLineJson = {};
        timeLineJson.index = index;
        timeLineJson.ss = e.target.value;
        timelineCheckSS.push(timeLineJson);
        this.setState({ timelineCheckSS });
        newValue = e.target.value;
        if (parseInt(appendOverlayList[index]["duration"]) > 0) {
          oldValue = appendOverlayList[index]["duration"];
          newValue = parseInt(newValue) + parseInt(oldValue);
        }
        appendOverlayList[index]["duration"] = newValue;
      }
    }
    this.setState({ appendOverlayList });
    console.log(this.state.appendOverlayList, 'log')
  };
  json_refill = async (e, type, index, l = true) => {
    const { appendOverlayList } = this.state;
    appendOverlayList[index][type] = l ? e.target.value : e;
    let appendOverlayData = await this.appendOverlayListToaod(appendOverlayList);
    console.log(e, appendOverlayList, "appendOverlayList");
    await this.setState({ appendOverlayList, appendOverlayData });
  };

  removeAppend = async (index) => {
    let a = index - 1;
    const { appendOverlayList, appendOverlayData } = this.state;
    console.log(appendOverlayList, "before");
    appendOverlayList.splice(a, 1);
    appendOverlayData.splice(a, 1);
    console.log(appendOverlayList, "before");
    await this.setState({ appendOverlayList, appendOverlayData });
  };
  handleClick = index => {
    const { displayColorPicker } = this.state;
    displayColorPicker[index] = true;
    this.setState({ displayColorPicker });
  };
  handleClose = index => {
    const { displayColorPicker } = this.state;
    displayColorPicker[index] = false;
    this.setState({ displayColorPicker });
  };
  refreshQuiz = async () => {
    try {
      const getQuizQuestion = await cmsContent.getFreedom(
        "id as value,quizName as label",
        "tbl_question",
        `customerid=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (getQuizQuestion) {
        this.setState({ getQuizQuestion: getQuizQuestion.data });
        //console.log(this.state.getQuizQuestion)
      }
    } catch (error) {
      console.log(error);
    }
  };
  addBanner = (e, index) => {
    let { bannerImageCount, appendOverlayList } = this.state;
    appendOverlayList[index].bannerImage = e.target.files[0].name;
    bannerImageCount.push(e.target.files[0].name);
    this.setState({
      appendOverlayList,
      bannerImageCount,
      [e.target.files[0].name]: e.target.files[0],
      addBannerName: e.target.files[0].name
    });
  };
  onEdit = async (d) => {
    const org = d.original;

    const index = d.index;
    //Content-1
    const { contentBlocks, entityMap } = htmlToDraft(org.content1);
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);
    //Edit theme Selection
    const options = [
      { label: "Theme1", value: "1" },
      { label: "Theme2", value: "2" },
      { label: "Theme3", value: "3" },
      { label: "Theme4", value: "4" },
      { label: "Theme5", value: "5" },
      { label: "Theme6", value: "6" },
      { label: "Theme7", value: "7" },
      { label: "Theme8", value: "8" },
      { label: "Theme9", value: "9" }
    ];
    if (typeof this.props.these === 'function') {
      this.props.these('pageName', org.name);
      this.props.these('autherSelected', this.props.that.authorlist.filter(ival => ival.value === org.authorid)[0]);
      this.props.these('themeSelected', options.filter(ival => ival.value == org.themeId)[0]);
      this.props.these('checked', org.show == 1 ? 1 : 0);
    }
    //FileName
    if (org.file == "undefined") {
      this.setState({ file1: '', fileName1: '' })
    }
    else {
      this.setState({ file1: org.file, fileName1: org.file, })
    }
    if (org.file2 == "undefined") {
      this.setState({ thumbnail: '', thumbnailname: '' })
    }
    else {
      this.setState({ thumbnail: org.file2, thumbnailname: org.file2, })
    }
    if (org.filename == "undefined") {
      this.setState({ fileText: '', fileName2: '' })
    }
    else {
      this.setState({ fileText: org.filename, fileName2: org.filename, })
    }
    //Subtitle
    let val = org.subTitleId ? org.subTitleId.split(',') : [];
    let selectedService = [];
    this.state.subtitleOption.map((ival, i) => {
      val.map((jval, j) => {
        // console.log('jval',jval)
        if (jval == ival.value) {

          selectedService.push(ival)
        }
      })
    })
    //Add Overlay Edit
    let appendOverlayList = JSON.parse(org.videoOverlay);
    let appendOverlayData = [];
    let { getQuizQuestion } = this.state
    appendOverlayList.map(async (ival, i) => {
      let appendOverlayListIndex = [];
      getQuizQuestion && getQuizQuestion.map((opt, o) => {
        if (ival.quizId == opt.value) {
          opt.isdisabled = true
        }
      })
      appendOverlayListIndex.overlayName = ival.overlayName;
      appendOverlayListIndex.appendValue = ival.overlayType;
      appendOverlayListIndex.overlayType = { label: ival.overlayType, value: ival.overlayType };
      appendOverlayListIndex.surveyUrl = ival.surveyUrl;
      appendOverlayListIndex.loadUrl = ival.loadUrl;
      appendOverlayListIndex.bannerTargetUrl = ival.bannerTargetUrl;
      appendOverlayListIndex.quizId = ival.quizId;
      appendOverlayListIndex.width = ival.width;
      appendOverlayListIndex.height = ival.height;
      appendOverlayListIndex.bannerImage = ival.bannerImage;
      appendOverlayListIndex.overlayPreamble = ival.overlayPreamble;
      appendOverlayListIndex.isCertified = ival.isCertified;
      appendOverlayListIndex.Instruction = ival.Instruction
      console.log(appendOverlayListIndex);
      appendOverlayData.push(appendOverlayListIndex);
      await this.setTimeline(ival.duration, i)
    })

    await this.setState({
      contentTitle1: org.contentTitle1,
      searchTags: org.searchTags,
      file1: org.file,
      thumbnail: org.file2,
      fileName1: org.file,
      fileText: org.filename,
      fileName2: org.filename,
      thumbnailname: org.file2,
      editorState,
      selectedService,
      appendOverlayList,
      appendOverlayData,
      isEdit: true,
      EditId: org.id,
      Index: index,
      getQuizQuestion
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }



  quizModelresponse = async (s, v) => {
    this.setState({ [s]: v });
    //console.log(this.state.quizModel);
    if (this.state.quizModel) {
      const previousData = [this.state.quizModel, ...this.state.getQuizQuestion]
      this.setState({ getQuizQuestion: previousData });
    }
  };

  render() {
    const { uploadPercentage, hour, minute, seconds, userlog } = this.state;
    // console.log(hour)
    let appendData = [];
    var { appendOverlayData: aod, appendOverlayList: aol, appendOverlayList } = this.state;
    if (this.state.texttype) {

      var rgb = this.state.overlayColor[0].rgb;

      var styles = reactCSS({
        default: {
          color: {
            width: "36px",
            height: "14px",
            borderRadius: "2px",
            background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
          },
          swatch: {
            padding: "5px",
            background: "#fff",
            borderRadius: "1px",
            boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
            display: "inline-block",
            cursor: "pointer"
          },
          popover: {
            position: "absolute",
            zIndex: "2"
          },
          cover: {
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px"
          }
        }
      });
    }
    aod.map((appendOverlayData, index) => {
      console.log(appendOverlayData)
      let rgb = {
        r: "241",
        g: "112",
        b: "19",
        a: "1"
      };
      if (this.state.overlayColor[index]) {
        rgb = this.state.overlayColor[index].rgb;
      }
      const styles = reactCSS({
        default: {
          color: {
            width: "36px",
            height: "14px",
            borderRadius: "2px",
            background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
          },
          swatch: {
            padding: "5px",
            background: "#fff",
            borderRadius: "1px",
            boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
            display: "inline-block",
            cursor: "pointer"
          },
          popover: {
            position: "absolute",
            zIndex: "2"
          },
          cover: {
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px"
          }
        }
      });
      let hoursSlect = [];
      for (let i = 0; i <= 12; i++) {
        hoursSlect.push(<option value={i <= 9 ? `0${i}` : i}>{i <= 9 ? `0${i}` : i}</option>);
      }

      let minuteSlect = [];
      for (let i = 0; i <= 60; i++) {
        minuteSlect.push(<option value={i <= 9 ? `0${i}` : i}>{i <= 9 ? `0${i}` : i}</option>);
      }
      let secondsSlect = [];
      for (let i = 0; i <= 60; i++) {
        secondsSlect.push(<option value={i <= 9 ? `0${i}` : i}>{i <= 9 ? `0${i}` : i}</option>);
      }
      let duration = (this.state.appendOverlayList[index] && this.state.appendOverlayList[index].duration) ? Number(this.state.appendOverlayList[index].duration) : 0;
      // let durationH = Math.floor(duration / 3600);
      // duration = duration % 3600;
      // durationH = durationH <= 9 ? `0${durationH}` : durationH.toString();
      // let durationM = Math.floor(duration / 60);
      // duration = duration % 60;
      // durationM = durationM <= 9 ? `0${durationM}` : durationM.toString();
      // let durationS = duration;
      // duration = duration % 60;
      // durationS = durationS <= 9 ? `0${durationS}` : durationS.toString();
      hour[index] = Math.floor(duration / 3600);
      duration = duration % 3600;
      hour[index] = hour[index] <= 9 ? `0${hour[index]}` : hour[index].toString();
      minute[index] = Math.floor(duration / 60);
      duration = duration % 60;
      minute[index] = minute[index] <= 9 ? `0${minute[index]}` : minute[index].toString();
      seconds[index] = duration
      duration = duration % 60;
      seconds[index] = seconds[index] <= 9 ? `0${seconds[index]}` : seconds[index].toString();

      // this.state.timelineCheck.map((val, m) => {
      //   durationH = val.hh
      // })
      // this.state.timelineCheckMM.map((mval, i) => {
      //   durationM = mval.mm
      // })
      // this.state.timelineCheckSS.map((sval, m) => {
      //   durationS = sval.ss
      // })
      console.log(this.state.getQuizQuestion.filter(ival => ival.value === appendOverlayData.quizId), "quiziddddddddddd");
      appendData.push(
        <div>
          {(appendOverlayData.appendValue === "Survey" ||
            appendOverlayData.appendValue === "Load Url") && (
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">Destination URL</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    name={`overlayName${appendOverlayData.index}`}
                    placeholder="Enter Url"
                    className="form-control"
                    value={appendOverlayData.loadUrl}
                    onChange={e => this.json_refill(e, `loadUrl`, index)}
                  />
                </div>
                <div className="col-sm-3" />
              </div>
            )}
          {(appendOverlayData.appendValue === "Instruction") && (
            <div className="row form-group">
              <div className="col-sm-2" />
              <div className="col-sm-2">
                <label htmlFor="exampleInputEmail1">Instruction</label>
              </div>
              <div className="col-sm-5">
                <textarea
                  type="text"
                  name={`overlayName${appendOverlayData.index}`}
                  placeholder="Enter Instruction"
                  className="form-control"
                  value={appendOverlayData.Instruction}
                  onChange={e => this.json_refill(e, `Instruction`, index)}
                />

              </div>
              <div className="col-sm-3" />
            </div>
          )}


          {appendOverlayData.appendValue === "Ad Banner" && (
            <div>
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">Upload Image</label>
                </div>
                <div className="col-sm-5 custom-file">
                  <input
                    type="file"
                    className="custom-file-input "
                    id="customFile"
                    onChange={e => this.addBanner(e, index)}
                  />
                  <label
                    className="custom-file-label lblcross"
                    htmlFor="customFile"
                  >
                    {this.state.appendOverlayList[index] ? this.state.appendOverlayList[index].bannerImage : null}
                  </label>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">Target Url</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    name={`overlayImageTargetUrl${appendOverlayData.index}`}
                    placeholder="Enter Target Url"
                    className="form-control"
                    onChange={e =>
                      this.json_refill(e, `bannerTargetUrl`, index)
                    }
                    value={appendOverlayData.bannerTargetUrl}
                  />
                </div>
                <div className="col-sm-3" />
              </div>
            </div>
          )}
          {appendOverlayData.appendValue === "Quiz"
            && (
              <div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="exampleInputEmail1">Select Quiz</label>
                  </div>
                  <div className="col-sm-5">
                    {this.props.that.isEdit ? (
                      <SingleSelect
                        name="quiz"
                        options={this.state.getQuizQuestion}
                        handleChange={e =>
                          this.appendOverlayQuiz(e, "quizId", index)
                        }
                        isOptionDisabled={(option) => option.isdisabled}
                        selectedService={this.state.getQuizQuestion.filter(ival => ival.value === appendOverlayData.quizId)[0]}
                      // selectedService={this.state.getQuizQuestion.filter(ival => ival.value == appendOverlayData.quizId)}
                      />
                    ) : (
                      <SingleSelect
                        name="quiz"
                        options={this.state.getQuizQuestion}
                        handleChange={e =>
                          this.appendOverlayQuiz(e, "quizId", index)
                        }
                        isOptionDisabled={(option) => option.isdisabled}
                        selectedService={this.state.getQuizQuestion.filter(ival => ival.value === appendOverlayData.quizId)[0]}
                      />

                    )}
                  </div>
                  <div className="col-sm-1">
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={this.refreshQuiz}
                    >
                      Refresh
                    </button>
                  </div>
                  <div className="col-sm-2">
                    <LoginModal
                      buttonTitle="Add Quiz"
                      title="Add Quiz"
                      id="quiz"
                      extraClass="btn-width"
                      maxwidth="modal-lg"
                      onClick={this.reset}
                      bodyText={
                        <AddquizmodelBc that={this.state} these={this.quizModelresponse} />}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-5">
                    <label htmlFor="exampleInputEmail1">Quiz is Evaluated and Certificate Generated</label>
                  </div>
                  <div className="col-sm-1">
                    <label style={{ paddingTop: 5, fontSize: 'small' }}>
                      <Switch
                        height={18}
                        width={41}
                        marginBottom={1}
                        paddingTop={5}
                        onChange={e => this.onEditorStateChange1(e, `isCertified`, index)}
                        checked={(appendOverlayData.isCertified == true || appendOverlayData.isCertified == 'true') ? true : false}
                      />
                    </label>
                  </div>
                  <div className="error">{this.state.errorTick}</div>
                </div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-1">
                    <label htmlFor="exampleInputEmail1">Width %</label>
                  </div>
                  <div className="col-sm-2">
                    <input
                      type="number"
                      name={`width${appendOverlayData.index}`}
                      placeholder="width"
                      className="form-control"
                      onChange={e => this.json_refill(e, `width`, index)}
                      value={appendOverlayData.width}
                    />
                  </div>
                  <div className="col-sm-1" />
                  <div className="col-sm-1">
                    <label htmlFor="exampleInputEmail1">Height %</label>
                  </div>
                  <div className="col-sm-2">
                    <input
                      type="number"
                      name={`height${appendOverlayData.index}`}
                      placeholder="width"
                      className="form-control"
                      onChange={e => this.json_refill(e, `height`, index)}
                      value={appendOverlayData.height}
                    />
                  </div>
                  <div className="col-sm-3" />
                </div>
              </div>
            )
          }
          <div className="row form-group">
            <div className="col-sm-2" />
            <div className="col-sm-2">
              <label htmlFor="exampleInputEmail1">OverLay Preamble</label>
            </div>
            <div className="col-sm-5">
              <input
                type="text"
                name={`overlayPreamble${appendOverlayData.index}`}
                placeholder="Overlay Preamble"
                className="form-control"
                onChange={e => this.json_refill(e, `overlayPreamble`, index)}
                value={appendOverlayData && appendOverlayData.overlayPreamble}
              />
            </div>
            <div className="col-sm-3" />
          </div>
          <div className="row form-group">
            <div className="col-sm-2" />
            <div className="col-sm-2">
              <label htmlFor="exampleInputEmail1">OverLay Color</label>
            </div>
            {/* <div className="col-sm-5">
              <div style={styles.swatch} onClick={e => this.handleClick(index)}>
                <div style={styles.color} />
              </div>
              {this.state.displayColorPicker[index] ? (
                <div style={styles.popover}>
                  <div
                    style={styles.cover}
                    onClick={e => this.handleClose(index)}
                  />
                  <SketchPicker
                    color={rgb}
                    onChange={e => this.addOverlayColor(e, index)}
                  />
                </div>
              ) : null}
            </div> */}
            <div className="col-sm-3" />
          </div>
          <div className="row form-group">
            <div className="col-sm-2" />
            <div className="col-sm-1">
              <label className="duration-float" htmlFor="exampleInputEmail1">
                hh
              </label>
            </div>
            <div class="col-sm-2">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                onChange={e => this.timelineJson(e, `hh`, index)}
                // value={durationH}
                value={hour.length ? hour[index] : `00`}

              >
                {hoursSlect}
              </select>
            </div>
            <div className="col-sm-1">
              <label className="duration-float" htmlFor="exampleInputEmail1">
                mm
              </label>
            </div>
            <div class="col-sm-2">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                onChange={e => this.timelineJson(e, `mm`, index)}
                // value={durationM}
                value={minute.length ? minute[index] : `00`}
              >
                {minuteSlect}
              </select>
            </div>
            <div className="col-sm-1">
              <label className="duration-float" htmlFor="exampleInputEmail1">
                ss
              </label>
            </div>
            <div class="col-sm-2">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                onChange={e => this.timelineJson(e, `ss`, index)}
                // value={durationS}
                value={seconds.length ? seconds[index] : `00`}
              >
                {secondsSlect}
              </select>
            </div>
            {/* <div className="col-sm-5">
              <input type="text" name={`overlayName${appendOverlayData.index}`} placeholder="Enter Duration" className="form-control" onChange={e => this.json_refill(e, `duration`, index)} />
            </div> */}
            <div className="col-sm-3" />
          </div>
        </div >
      );
    });
    let appendOverlay = [];
    let overlayOption = [
      { label: "Quiz", value: "Quiz" },
      { label: "Survey", value: "Survey" },
      { label: "Ad Banner", value: "Ad Banner" },
      { label: "Load Url", value: "Load Url" },
      { label: "Instruction", value: "Instruction" }
    ];
    // console.log("enter");
    // console.log(aol, appendOverlay);
    aol.map((appendOverlayList, index) => {
      //console.log(appendOverlayList);
      appendOverlay.push(
        <React.Fragment>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4>Overlay Section -{index + 1}
                  </h4>
                </div>
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="exampleInputEmail1">Overlay Name</label>
                    </div>
                    <div className="col-sm-5">
                      <input
                        type="text"
                        name={`overlayName${index}`}
                        placeholder="Enter overlay Name"
                        className="form-control"
                        value={appendOverlayList.overlayName}
                        onChange={e => this.json_refill(e, `overlayName`, index)}
                      />
                    </div>
                    <div className="col-sm-3">
                      <i
                        class="fa fa-times-circle"
                        style={{ fontSize: "25px" }}
                        onClick={() => this.removeAppend(index + 1)}
                      ></i>
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="exampleInputEmail1">Overlay Type </label>
                    </div>
                    <div className="col-sm-5">
                      <SingleSelect
                        name={`overlayType${index}`}
                        options={overlayOption}
                        handleChange={e => this.appendOverlayData(e, index)}
                        selectedService={{ label: appendOverlayList.overlayType, value: appendOverlayList.overlayType }}
                      />
                    </div>
                    <div className="col-sm-3" />
                  </div>
                  {appendData && appendData[index]}
                </div>
              </div>
            </div>
          </div>

        </React.Fragment>
      );

      this.state.stateoverrelay = appendOverlay;
    });

    let hoursSlect = [];
    for (let i = 0; i <= 12; i++) {
      hoursSlect.push(<option>{i <= 9 ? `0${i}` : i}</option>);
    }
    let minuteSlect = [];
    for (let i = 0; i <= 60; i++) {
      minuteSlect.push(<option>{i <= 9 ? `0${i}` : i}</option>);
    }
    let secondsSlect = [];
    for (let i = 0; i <= 60; i++) {
      secondsSlect.push(<option>{i <= 9 ? `0${i}` : i}</option>);
    }
    return (
      <React.Fragment>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">
              Content Title
            </label>
          </div>
          <div className="col-sm-5">
            <textarea
              type="text"
              className="form-control"
              id="contentTitle1"
              name="contentTitle1"
              placeholder="Enter Content Title"
              value={this.state.contentTitle1}
              onChange={this.handlechange}
            />
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-4" />
          <span className="error-show ">
            {this.state.errorContentTitle1}
          </span>
        </div>

        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">Content 1</label>
          </div>
          <div className="col-sm-5">
            <Editor
              editorState={this.state.editorState}
              wrapperClassName="wrapper-className"
              editorClassName="editor-className editor-border"
              toolbarClassName="toolbar-className"
              onEditorStateChange={this.onEditorStateChange}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "link"
                ]
              }}
              value={this.state.editorState}
            />
          </div>
          <div className="col-sm-3" />
        </div>

        <div className="row form-group">
          <div className="col-sm-4" />
          <span className="error-show ">{this.state.errorContent1}</span>
        </div>

        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">Search Tags</label>
          </div>
          <div className="col-sm-5">
            <textarea
              type="text"
              className="form-control"
              id="searchTags"
              name="searchTags"
              placeholder="Enter Search Tags"
              value={this.state.searchTags}
              onChange={this.handlechange}
            />
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-4" />
          <span className="error-show ">{this.state.errorSearchTags}</span>
        </div>

        {(this.state.customerId == 807 || this.state.customerId == '6075' || this.state.customerId == 6075 || userlog.elearn == '1')
          ? <div className="row form-group pt-4">
            <div className="col-sm-2" />
            <div className="col-sm-2">
              <label htmlFor="exampleInputEmail1">Upload Video</label>
            </div>
            <div className="col-sm-5 custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                onChange={(e) => this.selectImage1(e, 1)}
                accept="video/mp4,video/x-m4v,video/*"
              />
              <label
                className="custom-file-label"
                htmlFor="customFile"
              >
                Choose File
                {/* {this.state.fileName1 ? this.state.fileName1.split("/")[2] : null} */}
                {/* {this.state.fileName1 ? this.state.fileName1 : null} */}
              </label>
              <span>
                <LoginModal
                  buttonTitle="Select Video from Library"
                  title="Select Video"
                  id="fsdfsf"
                  maxwidth="modal-xl"
                  bodyText={
                    <FromLibrary
                      datas={this.state.getlibrarydataVideo}
                      columnHeading={this.contentHeading}
                      imgsource={this.state.viewmedia}
                      type={this.state.videotype}
                    />
                  }
                  atagLink={true}
                  onClick={() => this.setState({ label1: 'filename', label: "fileName2" })}
                />
              </span>
            </div>

          </div>
          :
          (<div className="row form-group pt-4">
            <div className="col-sm-2" />
            <div className="col-sm-2">
              <label htmlFor="exampleInputEmail1">Upload Video</label>
            </div>
            <div className="col-sm-5 custom-file">
              <input
                type="file"
                className="custom-file-input"
                id="customFile"
                onChange={this.selectImage1}
                accept="video/mp4,video/x-m4v,video/*"
              />
              <label
                className="custom-file-label"
                htmlFor="customFile"
              >
                Choose File
                {/* {this.state.fileName1 ? this.state.fileName1.split("/")[2] : null} */}
                {/* {this.state.fileName1 ? this.state.fileName1 : null} */}
              </label>
              <span>
                <LoginModal
                  buttonTitle="Select Video from Library"
                  title="Select Video"
                  id="fsdfsf"
                  maxwidth="modal-xl"
                  bodyText={
                    <FromLibrary
                      datas={this.state.getlibrarydataVideo}
                      columnHeading={this.contentHeading}
                      imgsource={this.state.viewmedia}
                      type={this.state.videotype}
                    />
                  }
                  atagLink={true}
                  onClick={() => this.setState({ label1: 'file1', label: "fileName1" })}
                />
              </span>
            </div>
            {/* <div className="col-sm-3"/> */}
            {/*  <input
              type="text"
              className="form-control"
              placeholder="Direct to Url"
              onChange={d =>
                this.setState({ linkto: d.target.value })
              }
              value={this.state.linkto}
		  />*/}
            {/* </div> */}

          </div>)}
        {(this.state.customerId == 807 || this.state.customerId == '6075' || this.state.customerId == 6075 || userlog.elearn == '1') ? <div className="row form-group">
          <div className="col-sm-4" />
          <span className="file-show ">{this.state.fileName2}</span>
        </div> : <div className="row form-group">
          <div className="col-sm-4" />
          <span className="file-show ">{this.state.fileName1}</span>
        </div>}
        <div className="row form-group">
          <div className="col-sm-4" />
          <span className="error-show ">
            {this.state.erroFile1}
          </span>
        </div>
        {/* <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">File name</label>
          </div>
          <div className="col-sm-5">
            <input
              type="text"
              className="form-control"
              id="fileText"
              name="fileText"
              value={this.state.fileText}
              onChange={this.handlechange}
            // disabled={true}
            />
          </div>
          <div className="col-sm-3" />
        </div> */}
        <div className="row form-group pt-4">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">Select Thumbnail</label>
          </div>

          <div className="col-sm-5">
            <input
              type="file"
              className="custom-file-input"
              id="customFile"
              onChange={this.selectthumbnail}
              accept="image/*"
            />
            <label
              className="custom-file-label"
              htmlFor="customFileThumbnail"
            >
              {/* {this.state.thumbnailname ? this.state.thumbnailname.split("/")[2] : null} */}
              {/* {this.state.thumbnailname ? this.state.thumbnailname : null} */}
              Choose File
            </label>
            <span>
              <LoginModal
                buttonTitle="Select Image from Library"
                title="Select Image"
                id="hfdsf"
                bodyText={
                  <FromLibrary
                    datas={this.state.getlibrarydataImage}
                    columnHeading={this.contentHeading}
                    imgsource={this.state.viewmedia}
                    type={this.state.videotype}
                  />
                }
                atagLink={true}
                onClick={() => this.setState({ label1: 'thumbnail', label: "thumbnailname" })}
              />
            </span>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-sm-4" />
          <span className="file-show">{this.state.thumbnailname}</span>
        </div>
        <div className="row form-group">
          <div className="col-sm-4" />
          <span className="error-show ">
            {this.state.errorFile2}
          </span>
        </div>

        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">
              Select Subtitles
            </label>
          </div>
          <div className="col-sm-5">
            <MultiSelect
              options={this.state.subtitleOption}
              handleChange={this.handleSubtitle}
              selectedService={this.state.selectedService}
            />
          </div>
          <div className="col-sm-3">
            <button
              type="button"
              className="btn btn-info"
              onClick={this.refreshSubtitle}
            >
              Refresh
            </button>
          </div>
        </div>
        <div className="row form-group">
          <div className="col-sm-4" />
          <span className="error-show ">
            {this.state.errorsubTitleSelected}
          </span>
        </div>
        {appendOverlay.length ? this.state.stateoverrelay : null}
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2" />
          <div className="col-sm-5">
            <button
              type="button"
              className="btn btn-danger btn-radius mt-25"
              onClick={this.appendOverlayList}
            >
              Add Overlay
            </button>
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-7">
            {uploadPercentage > 0 && (
              <Progress percentage={uploadPercentage} />
            )}
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2" />
          <div className="col-sm-5">
            <button
              type="button"
              className={`btn btn-${this.state.isEdit ? 'secondary' : 'primary'}`}
              disabled={this.state.btnDisable}
              onClick={this.submitCategory}
            >
              {this.state.isEdit ? 'Update' : 'Add'}  Page
            </button>
          </div>
          <div className="col-sm-3" />
        </div>

        <div className="row form-group">
          <div className="col-sm-12">
            {this.state.data ? (<Datatable
              data={this.state.data}
              columnHeading={this.column}
            />) : null}
          </div>
        </div>

      </React.Fragment >
    );
  }
}

export default Theme3;