import React, { Component } from 'react';
import { Datatable } from '../../../../components/Datatable';
import CmsContent from '../../../../MiddleWare/CmsContent';
import SingleSelect from "../../../../components/Form/SingleSelect";
import FormMiddleWare from '../../../../components/Form/FormMiddleware';
// import { CSVLink } from 'react-csv';
import NewDataTables from '../../../../components/Extra/newDataTable';
import moment from 'moment';
import CanvasJSReact from "../../../../canvas/canvasjs.react";
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;
class Vidyadashborad extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            customerid: localStorage.getItem('userId'),
            serviceid: localStorage.getItem('currentService'),
            page2: 0,
            customerId: localStorage.getItem("userId"),
            pageOptions: [],
            barchartview: {},
            monthval: moment(new Date()).format("YYYY-MM"),
            datevals: moment(new Date()).format("YYYY-MM-DD")
        };
    }

    async componentDidMount() {
        try {
            this.setState({ loading: true, loading1: true })
            let QuizList = await CmsContent.getTwoConditionedValue('tbl_question', 'customerid', this.state.customerid, 1, 1, "id as value ,quizName as label,contentJson");
            let data = await CmsContent.getquizlog();
            this.setState({ data: data.data, QuizList: QuizList.data });
            console.log(QuizList.data, "new");
            const pages = await CmsContent.getFreedom(
                "id as value,name as label",
                "tbl_pages",
                `customerId=${this.state.customerId} and themeId=3`,
                "id",
                "id DESC"
            );
            if (pages) {
                this.setState({ pageOptions: pages.data });
            }

            // const { data: Employeecount } = await CmsContent.getFreedom(
            //     "count(id) as total",
            //     "cmndl_tbl_Employee",
            //     `customerid=${this.state.customerId} and  status=0 and (employee_id IS NOT NULL AND employee_id != '')`,
            //     "customerid",
            //     "id DESC"
            // );
            const GetOpts = await CmsContent.GetCmdlDatas(this.state.customerId, "Employee")
            console.log(GetOpts, "GetOpts");
            if (GetOpts) {
                if (GetOpts.Employee) {
                    let data = []
                    // let deleteData =[]
                    for (let ival of GetOpts.Employee) {
                        if (ival.employee_id != null && ival.status == 0) {
                            await data.push(ival)
                        }
                    }
                  
                    console.log(data, "data");
                    await this.setState({ Employeecount: data});
                }               
            }
            // console.log(Employeecount,"Employeecount");
            // this.setState({ Employeecount })
            const { data: dealercount } = await CmsContent.getFreedom(
                "count(id) as total",
                "cmndl_tbl_dealer",
                `customerid=${this.state.customerId} and  status=0`,
                "customerid",
                "id DESC"
            );
            this.setState({ dealercount })
            // console.log();
            let arr = {}
            arr.type = "main"
            arr.date = moment(new Date()).format("YYYY-MM-DD")
            arr.month = moment(new Date()).format("YYYY-MM")
            arr.customerId = this.state.customerId
            let Dashnum = await CmsContent.VidyaDashReport(arr)
            if (Dashnum) {
                let barchartview = this.canvaschart("No.of users per day", Dashnum.monthdata, "Days");
                this.setState({ Dashnum, barchartview, loading: false, loading1: false })
            }
        } catch (error) {
            console.error(error);
        }
    }
    column = [
        // {
        //     Header: "Category",
        //     accessor: "categoryName",
        // },
        {
            Header: "Courses Name",
            accessor: "subCategoryName",
        },
        {
            Header: "Page",
            accessor: "name",
        },

        {
            Header: "Status",
            accessor: "status",
            Cell: (d) => this.statusChange(d),
        },
        {
            Header: "Visibility",
            accessor: "edit",
            Cell: (d) => this.showVisibility(d),
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: (d) => this.edit(d, "customer"),
        },
        {
            Header: "Delete",
            accessor: "delete",
            Cell: (d) => this.delete(d),
        },
    ];
    column1 = [
        {
            name: 'Employee Code',
            selector: 'employee_id',
            sortable: true,
        },
        {
            name: 'Employee Name',
            selector: 'label',
        },
        {
            name: 'Program Name',
            selector: 'contentTitle1',
        },
        {
            name: 'Program Status',
            selector: 'iscomplete',
        },
        {
            name: 'Program Score',
            selector: 'percent',
            sortable: true,
        },
        {
            name: 'Module Completion date',
            selector: 'createdAt',
        },
        {
            name: 'Module Assigned Date',
            selector: 'PageDate',
        },
        {
            name: 'Business Unit',
            selector: 'BusinessUnit',
        },
        {
            name: 'Division',
            selector: 'Division',
        },
        {
            name: 'Branch',
            selector: 'Branch',
        },

    ];
    canvaschart = (title, array, x) => {
        const options = {
            theme: "light1",
            animationEnabled: true,
            colorSet: 'customColorSet1',
            title: {
                text: title,
                fontSize: 20,
            },
            axisX: {
                labelFontSize: 13,
                title: x,
            },
            data: [
                {
                    // Change type to "bar", "area", "spline", "pie",etc.
                    type: "column",
                    click: e => {
                        //this.Form(e);
                    },
                    dataPoints: array
                }
            ]
        };
        return options;
    };
    deside = d => {
        let val = d.original.posttotal * 100 / d.original.total;
        if (val >= 60) {
            return (this.dataTableButton('success', 'View', () => {
                this.ViewCertificate(d.original);
            }))
        } else {
            return <center>Incomplete</center>
        }
    };
    ViewCertificate = d => {
        window.open(`CmsCertificate/${btoa(this.state.selectQuiz.label)}/${btoa(d.label)}/${btoa(d.time)}/${btoa(d.score * 100 / d.total)}/${btoa(d.posttotal * 100 / d.total)}`, "_blank");
    }
    pageSelect = async (selectedOption) => {
        console.log(selectedOption, "selectedOption");
        await this.setState({
            CourseName: selectedOption, pageSelected: selectedOption
        });
    };
    ViewOne = (d) => {
        let answer = JSON.parse(d.answerJson)
        let question = JSON.parse(this.state.selectQuiz.contentJson);
        let postAnswer = JSON.parse(d.postAns)

        let finalSingleValue = [];
        question.map((ival, i) => {
            answer.map((mval, ind) => {
                if (ival.questionId == mval.questionId) {
                    if (ival.type === 'multiple') {
                        let ans1 = [];
                        answer[ind].answer.map((jval, j) => {
                            let ans = jval.split('option').pop();
                            ans1.push(ival.options[ans - 1]);
                        });
                        let postAns = [];
                        if (postAnswer && postAnswer[ind].answer) {
                            postAnswer[ind].answer.map((jval, j) => {
                                let ans = jval.split('option').pop();
                                postAns.push(ival.options[ans - 1]);
                            });
                            postAns = postAns.toString();
                        } else {
                            postAns = '-';
                        }

                        let actualAnswer = [];
                        if (ival.answer) {
                            ival.answer.split(',').map((jval, j) => {
                                let cA = jval.split('option').pop();
                                actualAnswer.push(ival.options[cA - 1]);
                            });
                        }

                        finalSingleValue.push({ question: ival.question, answer: ans1.toString().toLowerCase(), correct: actualAnswer.toString().toLowerCase(), postanswer: postAns, result: ans1.toString().toLowerCase() === actualAnswer.toString().toLowerCase() ? 'Correct' : "Wrong" });
                    } else {
                        let postAns = (postAnswer && postAnswer[ind] && postAnswer[ind].answer) ? postAnswer[ind].answer.toLowerCase() : '-';
                        finalSingleValue.push({ question: ival.question, answer: answer[ind].answer.toLowerCase(), correct: ival.answer.toLowerCase(), postanswer: postAns, result: answer[ind].answer.toLowerCase() == ival.answer.toLowerCase() ? 'Correct' : "Wrong" })
                    }
                }
            })
        });
        this.setState({ finalSingleValue, page2: 2, userName: d.label, prePost: d.prepost ? 'Pre' : 'Post', date: this.secondFunction(d) });
    }
    firstFunction = (d, value) => {
        return <a href="#" onClick={() => { this.doFunction(d) }}>{value}</a>
    }
    secondFunction(date) {
        let d = new Date(date.time),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-');
    }


    resetState = () => this.setState({ page2: 0, singleUser: [], finalSingleValue: [], userName: [], date: [] })
    resetdata = async () => {
        await this.setState({
            selectedstartdate: "", selectedenddate: "", pageSelected: { label: 'Select Course', value: 0 }, CourseName: []
        })
    }
    resetcoursedata = async () => {
        await this.setState({
            pageSelected: { label: 'Select Course', value: 0 }, CourseName: []
        })
    }
    submitdata = async () => {
        let { selectedstartdate,
            selectedenddate, CourseName } = this.state;
        console.log(selectedstartdate, selectedenddate);
        let Courseid = 0
        if (CourseName && CourseName.value) {
            Courseid = CourseName.value
        }
        this.setState({ loading3: true })
        let arr = {}
        arr.from = selectedstartdate
        arr.to = selectedenddate
        arr.id = this.state.customerid
        arr.Courseid = Courseid
        arr.type = "submitreaport"
        let result = await CmsContent.VidyaDashReport(arr)
        console.log(result);
        this.setState({ tableData: result.data, loading3: false })
    }
    OnBack = val => {
        this.setState({ page2: val - 1 })
    }
    fromDateSelect = (e) => {
        this.setState({ selectedstartdate: e.target.value });
        // console.log(this.state.selectedstartdate);
    };
    dateselapi = async (e, field) => {
        let datevals = e.target.value
        this.setState({ [field]: datevals });
        let arr = {}
        if (field == "datevals") {
            this.setState({ loading1: true })
            arr.type = "main"
            arr.date = moment(new Date(datevals)).format("YYYY-MM-DD")
            arr.month = moment(new Date(this.state.monthval)).format("YYYY-MM")
            arr.customerId = this.state.customerId
        } else {
            this.setState({ loading: true })
            arr.type = "main"
            arr.date = moment(new Date(this.state.datevals)).format("YYYY-MM-DD")
            arr.month = moment(new Date(datevals)).format("YYYY-MM")
            arr.customerId = this.state.customerId
        }
        let Dashnum = await CmsContent.VidyaDashReport(arr)
        if (Dashnum) {
            console.log(Dashnum, 'Dashnum');
            let barchartview = this.canvaschart("No.of users per day", Dashnum.monthdata, "Days");
            this.setState({ Dashnum, barchartview, loading: false, loading1: false })
        }

    };
    endDateSelect = async (e) => {
        this.setState({ selectedenddate: e.target.value });
        // console.log(e.target.value);
    };



    render() {
        const { UserList, QuizList, page2, singleUser, finalSingleValue, userName, prePost, date, loading, loading1, loading3, barchartview, Dashnum, tableData, pageOptions, pageSelected } = this.state;
        console.log(pageSelected, "pageSelected");
        return (<React.Fragment>
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="card">
                        <div className="body">
                            <div className="row">
                                <div className="container-fluid">
                                    <br />
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">Date</div>
                                        <div className="col-sm-4">
                                            <input
                                                type="date"
                                                id="dates"
                                                className="form-control"
                                                onChange={(e) => this.dateselapi(e, 'datevals')}
                                                value={this.state.datevals ? this.state.datevals : moment(new Date()).format("YYYY-MM-DD")}
                                            />
                                            <span className="error-shows">
                                                {this.state.startdateerror}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="container-fluid">
                                    <br />
                                    {loading1 && <div className="text-center"> <i
                                        className="fa fa-spinner fa-1x fa-spin" style={{ color: "blueviolet" }}
                                    ></i></div>}
                                    <div className="row  justify-content-center">
                                        {/* <div class="  col-lg-1" /> */}
                                        <div class="card  col-lg-2" style={{ margin: 10, height: 90, textAlign: 'center', }} >
                                            <div class="card-body">
                                                <h5 class="card-title">Total User Count</h5>
                                                <p class="card-text">{this.state.Employeecount && this.state.Employeecount.length && this.state.Employeecount.length}</p>
                                            </div>
                                        </div>
                                        <div class="card  col-lg-2" style={{ margin: 10, height: 90, textAlign: 'center', }} >
                                            <div class="card-body">
                                                <h5 class="card-title">Total Dealer Count</h5>
                                                <p class="card-text">{this.state.dealercount && this.state.dealercount.length && this.state.dealercount[0].total}</p>
                                            </div>
                                        </div>
                                        <div class="card  col-lg-2" style={{ margin: 10, height: 90, textAlign: 'center', }} >
                                            <div class="card-body">
                                                <h5 class="card-title">Number quiz attended</h5>
                                                <p class="card-text">{Dashnum && Dashnum.quizcount}</p>
                                            </div>
                                        </div>
                                        <div class="card col-lg-2" style={{ margin: 10, height: 90, textAlign: 'center' }} >
                                            <div class="card-body">
                                                <h5 class="card-title">Completed quiz</h5>
                                                <p class="card-text">{Dashnum && Dashnum.comp}</p>
                                            </div>
                                        </div>
                                        <div class="card  col-lg-2" style={{ margin: 10, height: 90, textAlign: 'center' }} >
                                            <div class="card-body">
                                                <h5 class="card-title">Total number access</h5>
                                                <p class="card-text">{Dashnum && Dashnum.acc_count}</p>
                                            </div>

                                        </div>
                                        {/* <div class="  col-lg-1" /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row clearfix">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="card">
                        <div className="body">
                            <div className="row">
                                <div className="container-fluid">
                                    <br />
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2  form-control-label">
                                            <label>Month</label>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="form-group">
                                                <input
                                                    type="month"
                                                    name="userperday"
                                                    min="2018-03"
                                                    className="form-control"
                                                    placeholder="Please enter an integer"
                                                    onChange={e => {
                                                        this.dateselapi(e, 'monthval')
                                                    }}
                                                    value={this.state.monthval ? this.state.monthval : moment(new Date()).format("YYYY-MM")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-1" />
                                <div className="col-sm-10" >
                                    {loading ? <div className="text-center"> <i
                                        className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }}
                                    ></i></div> : <CanvasJSChart options={barchartview} />}
                                </div>
                                <div className="col-sm-1" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <hr /> */}
            <div className="container-fluid">
                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">Date</div>
                    <div className="col-sm-3">
                        From Date
                        <input
                            type="date"
                            id="myDate"
                            className="form-control"
                            onChange={this.fromDateSelect}
                            value={this.state.selectedstartdate}
                        />
                        <span className="error-shows">
                            {this.state.startdateerror}
                        </span>
                    </div>
                    <div className="col-sm-3">
                        To Date
                        <input
                            type="date"
                            className="form-control"
                            onChange={this.endDateSelect}
                            value={this.state.selectedenddate}
                        />
                        <span className="error-shows">
                            {this.state.endateerror}
                        </span>
                    </div>
                    <div className="col-sm-2 error-shows"> </div>
                </div>
                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                        <label for="exampleInputEmail1">Select Course*</label>
                    </div>
                    <div className="col-sm-5">
                        <SingleSelect
                            handleChange={this.pageSelect}
                            options={pageOptions}
                            selectedService={pageSelected}
                        />
                    </div>
                    <div className="col-sm-2">
                        <i
                            class="fa fa-times-circle"
                            style={{ fontSize: "25px" }}
                            onClick={this.resetcoursedata}
                        ></i>
                    </div>
                </div>

                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                        <button
                            type="button"
                            className="btn btn-primary "
                            onClick={this.submitdata}
                        >
                            Submit
                        </button>
                    </div>
                    <div className="col-sm-2 text-left" >
                        <button
                            type="button"
                            className="btn btn-warning "
                            onClick={this.resetdata}
                        >
                            Reset
                        </button>
                    </div>
                </div>
                <span>
                    {tableData && `Total of ${Object.keys(this.state.tableData).length} records`}
                </span>
                <br></br>
                {/* {this.state.excel && this.state.excel.length > 0 && (
                                        <CSVLink
                                            data={this.state.Csv}
                                            headers={this.state.excelHead}
                                        >
                                            <button
                                                className="btn btn-success"
                                                style={{ marginLeft: "5px" }}
                                            >
                                                EXCEL
                                            </button>
                                        </CSVLink>
                                    )} */}

                {loading3 ? <div className="text-center"> < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i></div> :
                    tableData && <NewDataTables data={tableData} columns={this.column1} />
                }
            </div>
        </React.Fragment>
        );
    }
}

export default Vidyadashborad;
