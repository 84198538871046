import React, { Component } from 'react';

export default class AdminDashboard extends Component {
  componentDidMount() {
    let userDetails = localStorage.getItem('userlog');
    userDetails = JSON.parse(userDetails);
    localStorage.setItem('userId', userDetails.id);
    localStorage.setItem('userName', userDetails.userName);
    localStorage.setItem('mobileNumber', userDetails.mobileNumber);
    localStorage.setItem('email', userDetails.email);
    localStorage.setItem('userType', userDetails.userType);
    localStorage.setItem('customerId', userDetails.customerId);
  }

  render() {
    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3>Heading</h3>
                </div>
                <div className="card-body">
                  <h1>hello</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
