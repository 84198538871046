import React, { Component, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AdminHeader, UserHeader, DifuzaCarHeader } from "../screens/Header";
import Footer from "../screens/Footer";
// UserUnder
import UserUnder from "../screens/Header/UserUnder";
// NAVBAR
import { SideNavAdmin } from "../screens/SideNav";
import NikkiHeader from "../screens/Swtn/NikkiHeader";
import SideNavSecondAdmin from "../screens/SideNav/SideNavSecondAdmin";
import SideNavThirdAdmin from "../screens/SideNav/SideNavThirdAdmin";
import DifuzaNav from "../screens/SideNav/DifuzaNav";
import DifuzaUserNav from "../screens/SideNav/DifuzaUserNav";

import AdminDashboard from "../screens/Admin/AdminDashboard";
import AdminChart from "../screens/Admin/AdminChart";
import Login from "../screens/Login/Login";
import Hello from "../screens/Login/Hello";
import UserList from "../screens/Admin/UserList";
import Customer from "../screens/SecondAdmin/Customer";
import SubAdminDashboard from "../screens/SecondAdmin/AdminDashboard";
import SuperAdminDashboard from "../screens/ThirdAdmin/SuperAdminDashboard";
import DifuzaDashboard from "../screens/ThirdAdmin/Difuza/DifuzaDashboard";
import Ehsportal from "../screens/Login/Ehsportal";
import AddService from "../screens/Admin/AddService";

//Survey EOI Survey - TTS 9996663330
import CharMaster from "../screens/SecondAdmin/charMaster";
import QuesMaster from "../screens/SecondAdmin/questionMaster";
import AnsMaster from "../screens/SecondAdmin/answerMaster";
import RangeMaster from "../screens/SecondAdmin/rangemaster";
import MapChartoRange from "../screens/SecondAdmin/MapChartoRange";
import AddSurvey from "../screens/SecondAdmin/AddSurvey";
import MapComtosurvey from "../screens/SecondAdmin/MapCompanyToSurvey";

// CMS Screen
import CMSDashboard from "../screens/ThirdAdmin/CMS/CMSDashboard";
import CMSNav from "../screens/SideNav/CMSNav";
import CMSCategory from "../screens/ThirdAdmin/CMS/Category";
import CmdlCategory from "../screens/ThirdAdmin/CMS/CmdlCategory"
import CMSAddPage from "../screens/ThirdAdmin/CMS/AddPage";
import AddPagebc from "../screens/ThirdAdmin/CMS/AddPage_BC";
import Preview from "../screens/ThirdAdmin/CMS/PreviewTemplate";
import Addform from "../screens/ThirdAdmin/CMS/addform";
import Videoplay from "../screens/ThirdAdmin/CMS/videoplay";
import MapCatToPanel from "../screens/ThirdAdmin/CMS/MapCatToPanel";
import SubTitle from "../screens/ThirdAdmin/CMS/SubTitle";
import CMSUser from "../screens/ThirdAdmin/CMS/AddUser";
import CMSMentor from "../screens/ThirdAdmin/CMS/AddMentor";
import CMSDepartment from "../screens/ThirdAdmin/CMS/AddDepartment";
import VideoCurrentView from "../screens/ThirdAdmin/CMS/VideoCurrentView";
import VideoSeekLog from "../screens/ThirdAdmin/CMS/VideoSeekLog";
import VideoSeekLog1 from "../screens/ThirdAdmin/CMS/VideoSeekLog1";
import SelectedVideoView from "../screens/ThirdAdmin/CMS/SelectedVideoView";
import AddQuiz from "../screens/ThirdAdmin/CMS/AddQuiz";
import AddImage from "../screens/ThirdAdmin/CMS/AddImage";
import CategoryMaster from "../screens/ThirdAdmin/CMS/CategoryMaster";
import SubCategoryMaster from "../screens/ThirdAdmin/CMS/SubCategoryMaster";
import AddAuthor from "../screens/ThirdAdmin/CMS/AddAuthor";
import ArrangeNav from "../screens/ThirdAdmin/CMS/ArrangeNav";
import ArrangeSubCategory from "../screens/ThirdAdmin/CMS/ArrangeSubCategory";
import Quiztable from "../screens/ThirdAdmin/CMS/quizlog";
import QuizReport from "../screens/ThirdAdmin/CMS/cmndlquizlog";
import CMSuserlog from "../screens/ThirdAdmin/CMS/Cmsuserlog";
import CmsAlllog from "../screens/ThirdAdmin/CMS/Cmsalllog";
import GetUsers from "../screens/Admin/GetUsers";
import GetLearner from "../screens/Admin/GetLearner";
import CmsCertificate from "../screens/ThirdAdmin/CMS/cmsCertificate";
import GetLearnerss from "../screens/SecondAdmin/GetLearnerss";
import CMSuserlogFirstPage from "../screens/ThirdAdmin/CMS/CMSuserlogFirstPage";
import CmsLoginLog from "../screens/ThirdAdmin/CMS/CmsLoginLog";
import CMSalluserlog from "../screens/ThirdAdmin/CMS/cmsalluserlogs";
import AddMO from "../screens/ThirdAdmin/CMS/AddMO";
import AddSBU from "../screens/ThirdAdmin/CMS/AddSBU";
import AddMOHQ from "../screens/ThirdAdmin/CMS/AddMOHQ";
import AddRBH from "../screens/ThirdAdmin/CMS/AddRBH";
import AddState from "../screens/ThirdAdmin/CMS/AddState";
import AddSales from "../screens/ThirdAdmin/CMS/AddSales";
import AddDealers from "../screens/ThirdAdmin/CMS/AddDealers";
import DealerReport from "../screens/ThirdAdmin/CMS/DealerReport";
import LanguageMaster from "../screens/ThirdAdmin/CMS/LanguageMaster";
import AddBusiDesignation from "../screens/ThirdAdmin/CMS/AddBusiDesignation";

//CMS USer
import "./style.css";
import About from "../screens/CMSUser/About";
import SingleContent from "../screens/CMSUser/SingleContent";
import SingleContent1 from "../screens/CMSUser/SingleContent1";
import CMSfooter from "../screens/Footer/CMSfooter";
import VideoContent from "../screens/CMSUser/VideoContent";
import VideoList from "../screens/CMSUser/VideoList";
import SearchList from "../screens/CMSUser/SearchList";
import Home from "../screens/CMSUser/Home";
import MapPageToHome from "../screens/ThirdAdmin/CMS/MapPageToHome";
import CategoryList from "../screens/CMSUser/CategoryList";
import AddVideoValue from "../screens/ThirdAdmin/CMS/AddVideoValue";
import VideoAnalytics from "../screens/ThirdAdmin/CMS/VideoAnalytics";
import VideoListPage from "../screens/ThirdAdmin/CMS/VideoList";
import LikeReport from "../screens/ThirdAdmin/CMS/LikeReport";
import VideoWizard from "../screens/ThirdAdmin/CMS/VideoWizard";
import Otpmodel from "../screens/Modal/Otpmodel";
import UserEvaluation from "../screens/ThirdAdmin/CMS/UserEvaluation";
import Gamification from "../screens/CMSUser/Gamification";
import ListingMore from "../screens/CMSUser/listingMore";
import UserHome from "../screens/CMSUser/userHome";
import Dynamic from "../screens/CMSUser/Dynamic";
import DynamicTable from "../screens/ThirdAdmin/CMS/dynamicTable";
import SheetContent from "../screens/CMSUser/sheetContent";
import SheetContent2 from "../screens/CMSUser/sheetContent2";
import SheetContent3 from "../screens/CMSUser/SheetContent3";
import SheetContent4 from "../screens/CMSUser/SheetContent4";
import PublicationList from "../screens/CMSUser/publicationList";
import MessageComponent from "../screens/CMSUser/MessageComponent";
import MessageComponent1 from "../screens/CMSUser/MessageComponent1";

//Difuza screens
import BoxTypes from "../screens/ThirdAdmin/Difuza/BoxTypes";
import Group from "../screens/ThirdAdmin/Difuza/Groups";
import Program from "../screens/ThirdAdmin/Difuza/Program";
import Batch from "../screens/ThirdAdmin/Difuza/Batch";
import LearningObject from "../screens/ThirdAdmin/Difuza/LearningObject";
import Domain from "../screens/ThirdAdmin/Difuza/Domain";
import Location from "../screens/ThirdAdmin/Difuza/Location";
import Resources from "../screens/ThirdAdmin/Difuza/Resources";
import MappingToGroup from "../screens/ThirdAdmin/Difuza/MappingToGroup";
import MentorToGroup from "../screens/ThirdAdmin/Difuza/MentorToGroup";
import ManagerToMentor from "../screens/ThirdAdmin/Difuza/ManagerToMentor";
// import UnitOfMeasurement from "../screens/ThirdAdmin/Difuza/UnitOfMeasurement";
import MapPortletToGroup from "../screens/ThirdAdmin/Difuza/MapPortletToGroup";
import Reports from "../screens/ThirdAdmin/Difuza/Reports";
import SurveyReports from "../screens/ThirdAdmin/Difuza/SurveyReports";
import SurveyMapping from "../screens/ThirdAdmin/Difuza/SurveyMapping";
import UserLogReport from "../screens/ThirdAdmin/Difuza/UserLogReport";
import Survey from "../screens/ThirdAdmin/Difuza/Survey";
import SurveyPage from "../screens/ThirdAdmin/Difuza/SurveyPage";
import SurveyQuestion from "../screens/ThirdAdmin/Difuza/SurveyQuestion";
import AppLogo from "../screens/ThirdAdmin/Difuza/AppLogo";
import Viewtable from "../screens/ThirdAdmin/Difuza/viewtable";
import CohortsView from "../screens/ThirdAdmin/Difuza/CohortsView";
import PortletsView from "../screens/ThirdAdmin/Difuza/PortletsView";
import Learners from "../screens/ThirdAdmin/Difuza/Learners";
import Mentors from "../screens/ThirdAdmin/Difuza/Mentors";
import DifManager from "../screens/ThirdAdmin/Difuza/Manager";
import WizardForm from "../screens/ThirdAdmin/Difuza/WizardForm";
import LearningPath from "../screens/ThirdAdmin/Difuza/LearningPath";
import Forms from "../screens/ThirdAdmin/Difuza/Forms";
import SiteHome from "../screens/ThirdAdmin/Difuza/SiteHome";
import FormView from "../screens/ThirdAdmin/Difuza/FormView";
import FormReport from "../screens/ThirdAdmin/Difuza/FormReport";
import CMSFormReport from "../screens/ThirdAdmin/Difuza/FormReport";
import SurveyForm from "../screens/ThirdAdmin/Difuza/SurveyForm";
import PerformanceReport from "../screens/ThirdAdmin/Difuza/performanceReport";
import BoxTypesCopy from "../screens/ThirdAdmin/Difuza/BoxTypescopy";

// L4 User Difuza
import DifuzaUser from "../screens/ThirdAdmin/Difuza/DifuzaUser";
import DifuzaNextUser from "../screens/ThirdAdmin/Difuza/DifuzaNextUser";
import Privacy from "../screens/Login/privacy";
import DfUserSeeAll from "../screens/ThirdAdmin/Difuza/DfUserSeeAll";
import Rtreport from "../screens/ThirdAdmin/Difuza/Rtreport";

///Swtn
import ThemeFive from "../screens/Swtn/themeFive";
import ThemeSix from "../screens/Swtn/themeSix";
import SingleContent2 from "../screens/CMSUser/SingleContent2";
import Test from "../screens/test";
import AddUserType from "../screens/ThirdAdmin/CMS/AddUserType";
import AddEmployee from "../screens/ThirdAdmin/CMS/AddEmployee";
import AddCluster from "../screens/ThirdAdmin/CMS/AddCluster";
import ClusterMapping from "../screens/ThirdAdmin/CMS/ClusterMapping";
import CourseMappingToUser from "../screens/ThirdAdmin/CourseMappingToUser";
import MappingLoader from "../screens/ThirdAdmin/CMS/Coromandel/MappingLoader";
import VidyaReport from "../screens/ThirdAdmin/CMS/Coromandel/Report";
import Rulescmdl from "../screens/ThirdAdmin/CMS/Coromandel/Rules";
import AddLibrary from "../screens/ThirdAdmin/CMS/AddLibrary";

// recover vidhya employee
import RecoverEmployee from "../screens/ThirdAdmin/CMS/RecoverEmployee";
import RecoverDealers from "../screens/ThirdAdmin/CMS/RecoverDealer";

//redis update
import RedisUpdate from "../screens/redisUpdate/RedisUpdate";

// employeemaster
import BusinessUnit from "../screens/ThirdAdmin/CMS/BusinessUnit";
import Function from "../screens/ThirdAdmin/CMS/Function";
import SubFunction from "../screens/ThirdAdmin/CMS/SubFunction";
import Region from "../screens/ThirdAdmin/CMS/Region";
import Division from "../screens/ThirdAdmin/CMS/Division";
import Branch from "../screens/ThirdAdmin/CMS/Branch";
import Grade from "../screens/ThirdAdmin/CMS/Grade";
import Band from "../screens/ThirdAdmin/CMS/Band";
import EmpCategory from "../screens/ThirdAdmin/CMS/EmpCategory";

// psychology routes 
import PsychologyAdminNav from "../screens/SideNav/PsychologyAdminNav";
import AddSchool from "../screens/ThirdAdmin/PSYCHOLOGY/AddSchool";
import PyschologyForm from "../screens/ThirdAdmin/PSYCHOLOGY/form";
import UserPyschologyForm from "../screens/ThirdAdmin/PSYCHOLOGY/userform";


import PsychologyUserNav from "../screens/SideNav/PsychologyUserNav";
import AddClass from "../screens/ThirdAdmin/PSYCHOLOGY/AddClass";
import AddDepartment from "../screens/ThirdAdmin/PSYCHOLOGY/AddDepartment";
import AddMentor from "../screens/ThirdAdmin/PSYCHOLOGY/AddMentor";
import AdminReport from "../screens/ThirdAdmin/PSYCHOLOGY/AdminReport";
import MomAdminReport from "../screens/ThirdAdmin/PSYCHOLOGY/MomAdminReport";
import UserReport from "../screens/ThirdAdmin/PSYCHOLOGY/UserReport";
import Calender from "../screens/ThirdAdmin/PSYCHOLOGY/Calender";
import PSYDashboard from "../screens/ThirdAdmin/PSYCHOLOGY/PSYDashboard";
import SchoolDashboard from "../screens/ThirdAdmin/PSYCHOLOGY/SchoolDashboard";
import MomReport from "../screens/ThirdAdmin/PSYCHOLOGY/MomReport";
import AddressMaster from "../screens/ThirdAdmin/PSYCHOLOGY/SchoolAddressMaster";
import TopicMaster from "../screens/ThirdAdmin/PSYCHOLOGY/TopicMaster";
import EmailMaster from "../screens/ThirdAdmin/PSYCHOLOGY/EmailMaster";
import DiscussionMaster from "../screens/ThirdAdmin/PSYCHOLOGY/Discussion";
import NotesMaster from "../screens/ThirdAdmin/PSYCHOLOGY/NotesMaster";
import FeelTalk from "../screens/Login/FeeltalkLogin";
import AddDesignation from "../screens/ThirdAdmin/PSYCHOLOGY/AddDesignation";
import AddEntity from "../screens/ThirdAdmin/PSYCHOLOGY/AddEntity";


export default class AdminRoutes extends Component {

  render() {

    let difuza = ["difuza.com", "www.difuza.com"];
    let ehs = [
      "ehsportal.in",
      "www.ehsportal.in",
      "murugappa.ehsportal.in",
      "www.murugappa.ehsportal.in"
    ];
    let path = window.location.href.split("/");
    let v = [];
    console.log(path, "path");
    console.log(difuza.indexOf(path[2]), "difuza.indexOf(path[2])");
    if (difuza.indexOf(path[2]) !== -1) {
      console.log('login 1');
      v.push(<Route exact path={"/"} render={props => <Hello {...props} />} />);
    } else if (ehs.indexOf(path[2]) !== -1) {
      console.log('login 2');
      v.push(
        <Route exact path={"/"} render={props => <UserHome {...props} />} />
      );
    } else {
      console.log('login 3');
      v.push(<Route exact path={"/"} render={props => <Hello {...props} />} />);
    }
    return (
      <Router>
        <Switch>
          {/*  Admin Routing */}
          {v}
          <Route exact path={"/Test"} render={props => <Test {...props} />} />
          <Route exact path={"/operate"} render={props => <Login {...props} />} />
          <Route
            exact
            path={"/ehsportal"}
            render={props => <Ehsportal {...props} />}
          />
          <Route
            exact
            path={"/admin"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavAdmin {...props} />
                  <AdminDashboard {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/redisButton/dotcue"}
            render={
              props => (
                <React.Fragment>
                  <RedisUpdate {...props} />
                </React.Fragment>
              )
            }
          />
          <Route
            exact
            path={"/barChart"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavAdmin {...props} />
                  <AdminChart />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/userList"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavAdmin {...props} />
                  <UserList {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/AddService"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavAdmin {...props} />
                  <AddService {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/GetUsers/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavAdmin {...props} />
                  <GetUsers {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/GetLearners/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavAdmin {...props} />
                  <GetLearnerss {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/vidoplay/:video"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavAdmin {...props} />
                  <Videoplay {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/GetUsers/GetLearner/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavAdmin {...props} />
                  <GetLearner {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          {/* subAdmin Routing */}
          <Route
            exact
            path={"/subAdmin"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavSecondAdmin {...props} />
                  <SubAdminDashboard {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/charmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavSecondAdmin {...props} />
                  <CharMaster />

                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/rangemaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavSecondAdmin {...props} />
                  <RangeMaster />

                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/MapChartoRange"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavSecondAdmin {...props} />
                  <MapChartoRange />

                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/AddSurvey"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavSecondAdmin {...props} />
                  <AddSurvey />

                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/MapComtosurvey"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavSecondAdmin {...props} />
                  <MapComtosurvey />

                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />


          <Route
            exact
            path={"/quesmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavSecondAdmin {...props} />
                  <QuesMaster />

                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/ansmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavSecondAdmin {...props} />
                  <AnsMaster />

                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/customer"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavSecondAdmin {...props} />
                  <Customer />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          {/* L3 Super Admin Rouitng */}
          <Route
            exact
            path={"/superAdmin"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <SideNavThirdAdmin {...props} />
                  <SuperAdminDashboard {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          {/* CMS Routing */}
          <Route
            exact
            path={"/ContentManagementSystem/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSDashboard {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsCategory"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSCategory {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmdlCategory"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CmdlCategory {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/clusterMapping"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <ClusterMapping {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/courseMappingToUser"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CourseMappingToUser {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmdlCluster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddCluster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsuserlog"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSuserlogFirstPage {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsuserlogSecond/:p/:sd/:ed"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSuserlog {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsalluserlog/:userid/:sd/:ed"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSalluserlog {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsallog/:userid/:pageid"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CmsAlllog {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsloginog/:userid"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CmsLoginLog {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          {/* <Route
          exact
          path={"/cmsAddPage"}
          render={props => (
            <React.Fragment>
              <AdminHeader {...props} />
              <div className="app-body">
                <CMSNav {...props} />
                <CMSAddPage {...props} />
              </div>
              <Footer {...props} />
            </React.Fragment>
          )}
        /> */}
          <Route
            exact
            path={"/cmsAddPage"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddPagebc {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/PreviewTemplate"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <Preview {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/Cms/Forms"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <Forms {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/Cms/FormReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSFormReport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/addform"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <Addform {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsRightPanel"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <MapCatToPanel {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsHomeDownPanel"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <MapPageToHome {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsSubTitle"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <SubTitle {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddUser"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSUser {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddMentor"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSMentor {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddSBU"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddSBU {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddMOHQ"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddMOHQ {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddState"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddState {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddRBH"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddRBH {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddBusiDesignation"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddBusiDesignation {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddMO"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddMO {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddSales"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddSales {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddUserType"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddUserType {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddEmployee"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddEmployee {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          {/* RecoverEmployee */}
          <Route
            exact
            path={"/cmsAddEmployee/recoveremployee"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <RecoverEmployee {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddDealers"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddDealers {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/cmsAddDealers/recoverdealer"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <RecoverDealers {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/dealerReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  {/* <AddPagebc {...props} /> */}
                  <DealerReport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsLanguages"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <LanguageMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/cmsAddDepartment"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CMSDepartment {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsCurrentlyVideoView"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <VideoCurrentView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsSeekLog"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <VideoSeekLog1 {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsSeekLog/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <VideoSeekLog {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsquizlog"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <Quiztable {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmndlquizlog"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <QuizReport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsLikeLog"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <LikeReport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsSelectedVideoView/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <SelectedVideoView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsVideoList"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <VideoListPage {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsSelectedVideoTrackObject"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <VideoWizard {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddQuiz"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddQuiz {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddImage"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddImage {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddLibrary"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddLibrary {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmscategorymaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <CategoryMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/BusinessUnit"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <BusinessUnit {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/Function"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <Function {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/SubFunction"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <SubFunction {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/Region"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <Region {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/divisionmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <Division {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/BranchMaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <Branch {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/Grademaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <Grade {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/Bandmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <Band {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/empcategory"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <EmpCategory {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmssubcategorymaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <SubCategoryMaster {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddAuthor"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddAuthor {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsAddVideoValue"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <AddVideoValue {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsSelectedVideoAnalytics/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <VideoAnalytics {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cmsArrangeNav"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <ArrangeNav {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/ArrangeSubCategory"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <ArrangeSubCategory {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CmsCertificate/:quiz/:user/:time/:pre/:post"}
            render={props => (
              <React.Fragment>
                <CmsCertificate {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/addTable"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <DynamicTable {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          {/* CMS User Routing */}
          <Route
            exact
            path={"/CMS/About/:id"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body">
                  <About {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/singleContent/:id"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <SingleContent {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/cms/swtn/singleContent/:id"}
            render={props => (
              <React.Fragment>
                <NikkiHeader {...props} />
                <div className="app-body ">
                  <SingleContent2 {...props} />
                </div>
                {/* <CMSfooter {...props} />*/}
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/safetyHealth"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <SingleContent1 {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Gamification"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <Gamification {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/VideoContent/:id"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <VideoContent {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Dynamic/:id"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <Dynamic {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/home"}
            render={props => (
              <React.Fragment>
                <Home {...props} />
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Home"}
            render={props => <UserHome {...props} />}
          />
          <Route
            exact
            path={"/CMS/VideoList"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <VideoList {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/CategoryList"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <CategoryList {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/OTP"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <Otpmodel {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/SearchList"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <SearchList {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Evaluation"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <UserEvaluation {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/More/:id"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <ListingMore {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Sheet"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <SheetContent {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Sheet2"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <SheetContent2 {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Sheet3"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <SheetContent3 {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Sheet4"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <SheetContent4 {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CMS/Publication"}
            render={props => (
              <React.Fragment>
                <UserHeader {...props} />
                <div className="app-body ">
                  <PublicationList {...props} />
                </div>
                <CMSfooter {...props} />
              </React.Fragment>
            )}
          />
          {/* DF routing */}
          <Route
            exact
            path={"/Difuza/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <DifuzaDashboard {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/BoxTypes"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <BoxTypes {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/EditBoxTypes/:d"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <BoxTypes {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/BoxTypesCopy"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <BoxTypesCopy {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/BoxTypes/table/:customer/:usertype"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />

                  <Viewtable {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/MapPortletToGroup/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />

                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Reports/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />

                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyReports/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />

                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyMapping/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />

                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/UserLogReport/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />

                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Survey/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyPage/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyQuestion/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyMapping/:customer/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <CohortsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/CohortsView/:groupId/:contentId"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />

                  <PortletsView {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          {/* <Route
          exact
          path={'/DF/User'}
          render={props => (
            <React.Fragment>
              <AdminHeader {...props} />
              <div className="app-body">
                <DifuzaNav {...props} />
                <DFUser type="User" {...props} />
              </div>
              <Footer {...props} />
            </React.Fragment>
          )}
        /> */}
          <Route
            exact
            path={"/DF/Learners"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Learners {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Mentors"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Mentors {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Manager"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <DifManager {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          {/* <Route
          exact
          path={'/DF/Trainer'}
          render={props => (
            <React.Fragment>
              <AdminHeader {...props} />
              <div className="app-body">
                <DifuzaNav {...props} />
                <DFUser type="Trainer" {...props} />
              </div>
              <Footer {...props} />
            </React.Fragment>
          )}
        /> */}
          <Route
            exact
            path={"/DF/Group"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Group {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Program"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Program {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Batch"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Batch {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Domain"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Domain {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/LearningObject"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <LearningObject {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Location"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Location {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Resources"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Resources {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/MappingToGroup"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <MappingToGroup {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/ManagerToMentor"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <ManagerToMentor {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/MentorToGroup"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <MentorToGroup {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          {/* <Route
          exact
          path={"/DF/UOM"}
          render={props => (
            <React.Fragment>
              <AdminHeader {...props} />
              <div className="app-body">
                <DifuzaNav {...props} />
                <UnitOfMeasurement {...props} />
              </div>
              <Footer {...props} />
            </React.Fragment>
          )}
        /> */}
          <Route
            exact
            path={"/DF/MapPortletToGroup"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <MapPortletToGroup {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Reports"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Reports {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyReports"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <SurveyReports {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/UserLogReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <UserLogReport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Survey"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Survey {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyPage"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <SurveyPage {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyQuestion"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <SurveyQuestion {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyMapping"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <SurveyMapping {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/applogo"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <AppLogo {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Evaluation"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <AddQuiz {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/performanceReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <PerformanceReport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/WizardForm/:userid/:quizid/:portid"}
            render={props => (
              <React.Fragment>
                <div className="app-body">
                  <WizardForm {...props} />
                </div>
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/LearningPath"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <LearningPath {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/Forms"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <Forms {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/FormView/:userid/:formid/:portid"}
            render={props => (
              <React.Fragment>
                <div className="app-body">
                  <FormView {...props} />
                </div>
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/FormReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaNav {...props} />
                  <FormReport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/SurveyForm/:userid/:quizid/:portid"}
            render={props => (
              <React.Fragment>
                <div className="app-body">
                  <SurveyForm {...props} />
                </div>
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/userUnder"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  {/* <DifuzaUserNav {...props} /> */}
                  <UserUnder {...props} />
                </div>
              </React.Fragment>
            )}
          />
          {/*Difuza User page*/}
          <Route
            exact
            path={"/difuzaUser"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaUserNav {...props} />
                  <DifuzaUser {...props} />
                </div>
              </React.Fragment>
            )}
          />
          {/*Difuza Next user page*/}
          <Route
            exact
            path={"/difuzaNextUser"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaUserNav {...props} />
                  <DifuzaNextUser {...props} />
                </div>
              </React.Fragment>
            )}
          />
          {/* Df user See All */}
          <Route
            exact
            path={"/dfUserSeeAll"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <DifuzaUserNav {...props} />
                  <DfUserSeeAll {...props} />
                </div>
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/img"}
            render={props => (
              <React.Fragment>
                {/* <AdminHeader {...props} /> */}
                <div className="app-body">
                  {/* <DifuzaUserNav {...props} /> */}
                  <MessageComponent {...props} />
                </div>
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/img1"}
            render={props => (
              <React.Fragment>
                {/* <AdminHeader {...props} /> */}
                <div className="app-body">
                  {/* <DifuzaUserNav {...props} /> */}
                  <MessageComponent1 {...props} />
                </div>
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/Swtn/ThemeFive/:id"}
            render={props => (
              <React.Fragment>
                <NikkiHeader {...props} />
                <div className="app-body ">
                  <ThemeFive {...props} />
                </div>
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/Swtn/ThemeSix/:id"}
            render={props => (
              <React.Fragment>
                <NikkiHeader {...props} />
                <div className="app-body ">
                  <ThemeSix {...props} />
                </div>
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/DF/RTReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body" style={{ backgroundColor: "#252B30" }}>
                  <DifuzaNav {...props} />
                  <Rtreport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route exact path={"/difuzaPrivacy"} render={props => <Privacy />} />

          {/* coromandel  */}
          <Route
            exact
            path={"/Vidya/ClusterMapped"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <MappingLoader {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/Vidya/VidyaReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <VidyaReport {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/Vidya/Rules"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <CMSNav {...props} />
                  <Rulescmdl {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          {/*Psychology Report  routes*/}
          <Route exact path={"/feeltalk"} render={props => <FeelTalk {...props} />} />
          <Route
            exact
            path={"/PsychologyReports/:id"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <PSYDashboard {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/PsychologySchoolReports"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyUserNav {...props} />
                  <SchoolDashboard {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/adddesignation"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <AddDesignation {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/addentity"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <AddEntity {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/addschool"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <AddSchool />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
         //
          <Route
            exact
            path={"/addMentor"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <AddMentor {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/addclass"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <AddClass />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/addDepartment"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <AddDepartment {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/adminReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <AdminReport />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/momReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <MomAdminReport />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/psychologyForm"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <PyschologyForm />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />/
          <Route
            exact
            path={"/UserPyschologyForm"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyUserNav {...props} />
                  <UserPyschologyForm />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/psychologyDashboard"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyUserNav {...props} />
                  <PSYDashboard {...props} />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/userReport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyUserNav {...props} />
                  <UserReport />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/calender"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <Calender />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/usercalender"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyUserNav {...props} />
                  <Calender />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/momdatareport"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyUserNav {...props} />
                  <MomReport />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />


          <Route
            exact
            path={"/addressmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <AddressMaster />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/topicmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <TopicMaster />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={"/emailmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <EmailMaster />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/discussionmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <DiscussionMaster />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          <Route
            exact
            path={"/notesmaster"}
            render={props => (
              <React.Fragment>
                <AdminHeader {...props} />
                <div className="app-body">
                  <PsychologyAdminNav {...props} />
                  <NotesMaster />
                </div>
                <Footer {...props} />
              </React.Fragment>
            )}
          />

          {/* REouting End */}
        </Switch>
      </Router>

    );
  }
}
