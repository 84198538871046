import React, { Component } from "react";
import logo from "../../images/CMS/Murugappa_Group_Logo.svg_1.png";
import cmsContent from "../../MiddleWare/CmsContent";
import UserNav from "./UserNav";
import { Alert } from "reactstrap";
import "./usernav.css";

export default class UserHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputSearchkey: "",
      data: "",
      themeId: null,
      show: false
    };
  }

  async componentDidMount() {
    const { data: allSerchKey } = await cmsContent.getAllSerchKeyValue();
    let themeId = this.props.match.params.id;
    this.setState({ themeId });
    if (allSerchKey) {
      allSerchKey.map(searchValue =>
        console.log(searchValue.searchTags.split(","))
      );
    }
  }

  async componentWillReceiveProps(nextProps) {
    let themeId = nextProps.match.params.id;
    this.setState({ themeId });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getAllSearchKey = async () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
    const { inputSearchkey } = this.state;
    let values = inputSearchkey.trim().split(/[ ,]+/);
    const { data: allSerchKey } = await cmsContent.getAllSerchKeyValue(values);
    if (allSerchKey) {
      this.props.history.push({
        pathname: "/CMS/SearchList",
        state: { data: allSerchKey }
      });
    }
  };

  _handleKeyDown = e => {
    if (e.key === "Enter") {
      this.getAllSearchKey();
    }
  };
  logout = () => {
    if (localStorage.getItem("title") == "difuza") {
      localStorage.clear();
      window.location = "/";
    } else {
      localStorage.clear();
      window.location = "/CMS/Home";
    }

    //return localStorage.getItem('title') ?'' :
  };

  render() {
    const { inputSearchkey, themeId } = this.state;
    return (
      <React.Fragment>
        <header
          className="header-area"
          style={{ position: "sticky", top: 0, left: 0 }}
        >
          <div className="top-header-area">
            <div className="container nomargins">
              <div className="row nomargins">
                <div className="col-12 nomargins">
                  <div className="top-header-content d-flex align-items-center justify-content-between">
                    <a href="/CMS/Home">
                      <img
                        className="imgheight"
                        src={logo}
                        alt=""
                        style={{ maxHeight: "83px", cursor: "pointer" }}
                      />
                    </a>
                    {/* <div>
                      <Detector
                        render={({ online }) => (
                          <div className="text-danger">
                            {online || this.state.show ? null : (
                              <Alert
                                isOpen={true}
                                toggle={() => {
                                  this.setState({ show: true });
                                }}
                              >
                                Oops! You seem to be offline, please check your
                                internet connection and retry.
                              </Alert>
                            )}
                          </div>
                        )}
                      />
                    </div> */}
                    <div className="login-search-area d-flex align-items-center ">
                      <div
                        className="search-form "
                        style={{ marginBottom: "20px" }}
                      >
                        <span
                          style={{
                            color: "white",
                            marginLeft: 0,
                            fontSize: "13px"
                          }}
                        >
                          {" "}
                          {"Welcome " +
                            JSON.parse(localStorage.getItem("userlog"))
                              .userName}
                        </span>

                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        {/*&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                        {/*<span style={{ fontSize: 13, color: 'white' }}> </span>*/}
                        <span onClick={this.logout}>
                          <i
                            class="fa fa-sign-out hoverclass"
                            aria-hidden="true"
                            style={{
                              cursor: "pointer",
                              fontSize: 18,
                              marginTop: "1px",
                              color: "white" /*paddingLeft: 145 */
                            }}
                          ></i>
                        </span>
                        <div className="header-search nomargin">
                          <div className="input-group mb-6 ">
                            <input
                              type="search"
                              name="inputSearchkey"
                              id="inputSearchkey"
                              className="form-control "
                              placeholder="Search"
                              onChange={this.handleChange}
                              onKeyDown={this._handleKeyDown}
                              value={inputSearchkey}
                            />
                            <div
                              className="input-group-append"
                              style={{ cursor: "pointer" }}
                            >
                              <span className="input-group-text">
                                <i
                                  className="fa fa-search"
                                  onClick={this.getAllSearchKey}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {themeId ? (
            <UserNav themeId={themeId} />
          ) : (
            <UserNav themeId={themeId} />
          )}
        </header>
      </React.Fragment>
    );
  }
}
