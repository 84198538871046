import React from "react";
import Icon from "react-icons-kit";
import { mobilePhone } from "react-icons-kit/fa/mobilePhone";
import { user } from "react-icons-kit/fa/user";
import { office } from 'react-icons-kit/icomoon/office'
import { basic_mail_open_text } from "react-icons-kit/linea/basic_mail_open_text";
import { lock } from 'react-icons-kit/oct/lock'
import {
  Alert,
  Button,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { MultiSelect } from "../../components/Form";
import { SingleSelect } from "../../components/Form";

const AddUser = ({
  handleInput,
  userData,
  handleElearn,
  handleTemplateCheck,
  errorData,
  handleSubmit,
  alertVisible,
  onDismiss,
  checkMobileNumber,
  checkEmail,
  checkPassword,
  handleChange,
  userType,
  updateData,
  options,
  selectedService,
  updateEmail,
  updatePassword,
  uploadimg,
  logoname,
  count,
  selectedValue,
  serviceId,
  handleCount,
  selectedloginType,
  selectedOption,
  options1,
  handlelogintype,
  // errorloginType,
  arr,
  selecteddep,
  handledep

}) => {
  const { userName, mobileNumber, email, UserPassword, eLearn, domain, templateCheck } = userData;
  const {
    errorUserName,
    errorMobileNumber,
    errorEmail,
    errorServices,
    errorPassword,
    errorloginType,
    errorDomain,
    errordoj,
    errordep
  } = errorData;

  return (
    <React.Fragment>
      <Form>
        <Alert color="success" isOpen={alertVisible} toggle={onDismiss}>
          {updateData === false ? `Added Successfully` : `Updated Successfully`}
        </Alert>

        <InputGroup className={errorUserName ? null : "mb-3"}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <Icon icon={user} />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="text"
            name="userName"
            placeholder="Username"
            autoComplete="username"
            onChange={handleInput}
            value={userName}
          />
        </InputGroup>
        <span className="mb-3 span-error">{errorUserName}</span>
        <InputGroup className={errorMobileNumber ? null : "mb-3"}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <Icon icon={mobilePhone} />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="text"
            name="mobileNumber"
            placeholder="Mobile number"
            maxlength="10"
            autoComplete="Mobile number"
            onChange={handleInput}
            value={mobileNumber}
            disabled={updateData === false ? false : true}
            onBlur={checkMobileNumber}
          />
        </InputGroup>
        <span className="mb-3 span-error">{errorMobileNumber}</span>
        <InputGroup className={errorEmail ? null : "mb-3"}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <Icon icon={basic_mail_open_text} />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="text"
            name="email"
            placeholder="Email"
            autoComplete="email"
            onChange={handleInput}
            value={email}
            onBlur={updateEmail === true && checkEmail}
          />
        </InputGroup>
        <span className="mb-3 span-error">{errorEmail}</span>
        <InputGroup className={errorPassword ? null : "mb-3"}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <Icon icon={lock} />
            </InputGroupText>
          </InputGroupAddon>
          <Input
            type="password"
            name="UserPassword"
            placeholder="Password"
            onChange={handleInput}
            // defaultValue={UserPassword}
            value={UserPassword}
          //onBlur={updatePassword === true && checkPassword}
          />
        </InputGroup>
        <span className="mb-3 span-error">{errorPassword}</span>

        {/* multi Select */}
        {parseInt(userType) === 2 && (
          <div>
            <InputGroup
              className={errorData.errorloginType ? null : "mb-3 px-0"}
            >
              <InputGroupAddon addonType="prepend" className="col-1 px-0">
                <InputGroupText>
                  <Icon icon={user} />
                </InputGroupText>
              </InputGroupAddon>
              <SingleSelect
                width="90% !important"
                className="col-11 px-0"
                selectedService={selectedloginType}
                handleChange={handlelogintype}
                options={options1}
                // errorServices={errorloginType}
                disable={true}
              />
            </InputGroup>
            <span className="mb-3 span-error">{errorloginType}</span>
            <InputGroup className={((errorDomain) && (templateCheck === true)) ? null : "mb-3 px-0"}>
              <InputGroupAddon addonType="prepend" className="col-1 px-0">
                <InputGroupText>
                  <Icon icon={office} />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                name="domain"
                className="col-9 px-0"
                placeholder="  Domain Name"
                autoComplete="domain"
                onChange={handleInput}
                value={domain}
                disabled={(templateCheck) ? "" : "disabled"}
              />
              <div className="col-2 px-0" style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              >
                <input type='checkbox' onChange={handleTemplateCheck} style={{ marginLeft: "15px"}} checked={templateCheck} />
                &nbsp;
                &nbsp;
                <span>
                  Template
                </span>
              </div>
            </InputGroup>
            <span className="mb-3 span-error">{(templateCheck) ? errorDomain : ''}</span>
            <InputGroup
              className={errorData.errorAgentSelected ? null : "mb-3 px-0"}
            >
              <InputGroupAddon addonType="prepend" className="col-1 px-0">
                <InputGroupText>
                  <Icon icon={user} />
                </InputGroupText>
              </InputGroupAddon>
              <MultiSelect
                width="90% !important"
                className="col-9 px-0"
                selectedService={selectedService}
                handleChange={handleChange}
                options={options}
                // errorServices={errorServices}
                disable={true}
              />
              <div className="col-2 px-0" style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              >
                <input type='checkbox' onChange={handleElearn} checked={eLearn} />
                &nbsp;
                &nbsp;
                <span>
                  E-learn
                </span>
              </div>
            </InputGroup>
            <span className="mb-3 span-error">{errorServices}</span>

            {count &&
              arr &&
              arr.length > 0 &&
              arr.map((ival, i) => {
                return (
                  <InputGroup
                    className={
                      errorData.errorAgentSelected ? null : "mb-3 px-0"
                    }
                  >
                    <InputGroupAddon addonType="prepend" className="col-1 px-0">
                      <InputGroupText>
                        <Icon icon={user} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input width="col-5 px-0" value={ival} />
                    <InputGroupAddon
                      addonType="prepend"
                      className="col-1 px-0"
                    ></InputGroupAddon>
                    <Input
                      width="col-5 px-0"
                      onChange={handleCount}
                      type="number"
                      id={i}
                      min="0"
                    />
                  </InputGroup>
                );
              })}
            {/* {selectedService && selectedService.length && (
            <InputGroup
              className={errorData.errorAgentSelected ? null : "mb-3 px-0"}
            >
              <InputGroupAddon addonType="prepend" className="col-1 px-0">
                <InputGroupText>
                  <Icon icon={user} />
                </InputGroupText>
              </InputGroupAddon>
              <SingleSelect
                width="col-5 px-0"
                selectedService={selectedService}
                handleChange={handleChange}
                options={options}
                errorServices={errorServices}
              />
              <InputGroupAddon
                addonType="prepend"
                className="col-1 px-0"
              ></InputGroupAddon>
              <Input
                width="col-5 px-0"
                handleChange={handleChange}
                type="number"
              />
            </InputGroup>
            )} */}
            <InputGroup
              className={errorData.errorAgentSelected ? null : "mb-3"}
            >
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Icon icon={user} />
                </InputGroupText>
              </InputGroupAddon>
              <input
                type="file"
                id="images"
                accept="image/*"
                onChange={uploadimg}
              />
              <label className="custom-file-label lblcross" htmlFor="images">
                {logoname ? logoname : 'Select Picture/Files'}
              </label>
            </InputGroup>
          </div>
        )}

        {parseInt(userType) === 1 && (
          <div>
            <InputGroup
              className={errorData.errorloginType ? null : "mb-3 px-0"}
            >
              <InputGroupAddon addonType="prepend" className="col-1 px-0">
                <InputGroupText>
                  <Icon icon={user} />
                </InputGroupText>
              </InputGroupAddon>
              <SingleSelect
                width="90% !important"
                className="col-11 px-0"
                selectedService={selectedloginType}
                handleChange={handlelogintype}
                options={options1}
                placeholder={<div> Please select login type</div>}
                // errorServices={errorloginType}
                disable={true}
              />
            </InputGroup>
            <span className="mb-3 span-error">{errorloginType}</span>
            <InputGroup
              className={errorData.errorAgentSelected ? null : "mb-3 px-0"}
            >
              <InputGroupAddon addonType="prepend" className="col-1 px-0">
                <InputGroupText>
                  <Icon icon={user} />
                </InputGroupText>
              </InputGroupAddon>
              <MultiSelect
                width="90% !important"
                className="col-11 px-0"
                selectedService={selectedService}
                handleChange={handleChange}
                options={options}
                errorServices={errorServices}
                disable={true}
                placeholder={<div>Please select service</div>}
              />
            </InputGroup>
            <span className="mb-3 span-error">{errorServices}</span>

            {count && arr && arr.length > 0 &&
              arr.map((ival, i) => {
                return (
                  <InputGroup
                    className={
                      errorData.errorAgentSelected ? null : "mb-3 px-0"
                    }
                  >
                    <InputGroupAddon addonType="prepend" className="col-1 px-0">
                      <InputGroupText>
                        <Icon icon={user} />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input width="col-5 px-0" value={ival} />
                    <InputGroupAddon
                      addonType="prepend"
                      className="col-1 px-0"
                    ></InputGroupAddon>
                    <Input
                      width="col-5 px-0"
                      onChange={handleCount}
                      type="number"
                      id={i}
                      min="0"
                    />
                  </InputGroup>

                );
              })
            }
            <InputGroup className={errorData.errorAgentSelected ? null : "mb-3"}>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Icon icon={user} />
                </InputGroupText>
              </InputGroupAddon>
              <input
                type="file"
                id="images"
                accept="image/*"
                onChange={uploadimg}
              />
              <label className="custom-file-label lblcross" htmlFor="images">
                {logoname ? logoname : 'Select Picture/Files'}
              </label>
            </InputGroup>
          </div>

        )}
        {parseInt(userType) === 3 && (
          <InputGroup className={errorData.errorAgentSelected ? null : "mb-3"}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <Icon icon={user} />
              </InputGroupText>
            </InputGroupAddon>
            <input
              type="file"
              id="imagesuser"
              accept="image/*"
              onChange={uploadimg}
            />
            <label className="custom-file-label lblcross" htmlFor="imagesuser">
              {logoname ? logoname : 'Select Picture/Files'}
            </label>
          </InputGroup>
        )}
        {parseInt(userType) === 4 && (
          <InputGroup className={errorData.errorAgentSelected ? null : "mb-3"}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <Icon icon={user} />
              </InputGroupText>
            </InputGroupAddon>
            <input
              type="file"
              id="image"
              accept="image/*"
              onChange={uploadimg}
            />
            <label className="custom-file-label lblcross" htmlFor="image">
              {logoname ? logoname : 'Select Picture/Files'}
            </label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <label className="lblcross"></label>
          </InputGroup>
        )}
        {/* multi Select */}
        <Button
          color="primary card-header-background"
          onClick={handleSubmit}
          block
        >
          {updateData === false ? `Save` : `Update`}
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default AddUser;
