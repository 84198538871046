import React, { Component } from 'react';
import cmsContent from '../../../MiddleWare/CmsContent';
import Datatable from '../../../components/Datatable/Datatable';
import VideoPlayer from '../../../components/Extra/VideoPlayer';

class VideoAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      videoUrl: null,
      staticUrl: null
    };
  }

  async componentWillMount() {
    let id = this.props.match.params.id;
    try {
      const { data } = await cmsContent.videoJsonList(id);
      if (data) {
        this.setState({ data });
      }
      const { data: videoUrl } = await cmsContent.videoUrl(id);
      if (videoUrl) {
        this.setState({
          videoUrl: videoUrl[0].videoUrl,
          staticUrl: videoUrl[0].videoUrl
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  column = [
    {
      Header: 'Name',
      accessor: 'name'
    },
    {
      Header: 'Timestamp (ms)',
      accessor: 'timestamp'
    },
    {
      Header: 'Show Video',
      accessor: 'edit',
      Cell: d => this.showVideo(d)
    }
  ];

  showVideo = e => {
    return (
      <button type="button" className="btn btn-info" onClick={() => this.videoDuration(e)}>
        <span style={{ color: '#ffffff' }}>Show Video</span>
      </button>
    );
  };

  videoDuration = e => {
    const duration = (e.original.timestamp / 1000).toFixed(3);
    const videoUrl = this.state.staticUrl + `#t=${duration}`;
    this.setState({
      videoUrl: videoUrl
    });
  };

  ref = player => {
    this.player = player;
  };

  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Video Analytics</h1>
                  </div>
                  <div className="card-body">
                    <div className="row mb-3">
                      <div className="col-sm-12">
                        <VideoPlayer videoUrl={this.state.videoUrl} ref={this.ref} />
                      </div>
                    </div>
                    {this.state.data && <Datatable data={this.state.data} columnHeading={this.column} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default VideoAnalytics;
