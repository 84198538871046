import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
const logpdf = ({
  header,
  data,
  users,
  page,
  title = "User Logs-March 15,2020 to June 3,2020"
}) => {
  console.log("header", header);
  console.log("data", data);
  var get = [];
  if (data) {
    var pg = [];
    var usr = [];
    var user = users;
    var pages = page;
    data.map((ival, m) => {
      user.map((jval, n) => {
        if (ival.userId == jval.value) {
          usr.push(jval);
        }
      });
      pages.map((kval, k) => {
        if (ival.pageid === kval.value) {
          pg.push(kval);
        }
      });
      if (ival.max_timeline == null || ival.play == null) {
        get.push({
          SI_NO: m + 1,
          user: usr[0].label,
          page: pg[0].label,
          ip: ival.ipaddress,
          max: "N/A",
          exit: ival.datetime,
          entry: "N/A"
        });
      } else {
        get.push({
          SI_NO: m + 1,
          user: usr[0].label,
          page: pg[0].label,
          ip: ival.ipaddress,
          max: ival.max_timeline,
          exit: ival.datetime,
          entry: ival.play
        });
      }
    });
  }
  console.log("pdfvar", get);
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.title}>
          <Text>{title}</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            {header &&
              header.map(value => {
                return (
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
          </View>
          {data &&
            get &&
            get.map((value, index) => {
              console.log("get", value);
              return (
                <View style={styles.tableRow}>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{value.user}</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{value.page}</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{value.ip}</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{value.max}</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{value.entry}</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{value.exit}</Text>
                  </View>
                </View>
              );
            })}
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: {
    marginTop: "5%",
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableColHeader: {
    width: "14.4%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 55,
    fontWeight: "bold"
  },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: "auto",
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: "auto",
    fontSize: 10
  },
  title: {
    fontSize: 15,
    fontWeight: "bold",
    marginLeft: "25%"
  }
});

export default logpdf;
