import React, { Component } from "react";
import cmsContent from "../../../MiddleWare/CmsContent";
import SingleSelect from "../../../components/Form/SingleSelect";
import Datatable from "../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import CohortsView from "./CohortsView";
import moment from "moment";

class UserLogReport extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      customerId: localStorage.getItem("userId"),
      data: [],
      report: [],
      userSelect: [],
      cohortSelect: [],
      load: false,
    };
  }
  async componentWillMount() {
    try {
      let result = await cmsContent.getTwoConditionedValue(
        "tbl_portletname",
        "status",
        "active",
        1,
        1,
        "id as value , name as label"
      );

      if (result) {
        this.setState({ report: result.data });
      }
      var result1 = await cmsContent.getTwoConditionedValue(
        "tbl_boxcontent",
        "status",
        "active",
        1,
        1,
        "id as value , name as label"
      );

      if (result1) {
        this.setState({ boxcontent: result1.data });
      }
      var result2 = await cmsContent.getTwoConditionedValue(
        "tbl_group",
        "status",
        "active",
        "customerId",
        this.state.customerId,
        "id as value , name as label"
      );
      if (result2) {
        this.setState({ cohortSelect: result2.data });
      }

      // if(result3){

      // }

      var result3 = await cmsContent.getTwoConditionedValue(
        " tbl_user_web",
        "customerId",
        this.state.customerId,
        1,
        1,
        "id as value, userName as label "
      );
      if (result3) {
        this.setState({ userSelect: result3.data });
      }

      let result4 = await cmsContent.getAllSubtitleList("tbl_userlog");
      if (result4) {
        this.setState({ userip: result4.data });
      }
    } catch (error) {
      console.log(error);
    }
  }
  column = [
    {
      id: "portid",
      Header: "Portlet Name",
      accessor: (d) =>
        this.getValueFromArray2(
          d.portid,
          this.state.boxcontent,
          this.state.report
        ),
      Cell: (d) =>
        this.getValueFromArray2(
          d.original.portid,
          this.state.boxcontent,
          this.state.report
        ),
    },

    {
      Header: "Ip Address",
      accessor: "ip",
    },
    // {
    // Header: "Alias",
    // accessor: "alias",
    // accessor: (d) =>
    //   this.getValueFromArray3(
    //     d.original.portid,
    //     this.state.boxcontent,
    //     this.state.customerId
    //   ),
    // Cell: (d) =>
    //   this.getValueFromArray3(
    //     d.original.portid,
    //     this.state.boxcontent,
    //     this.state.customerId
    //   )
    // },

    {
      Header: "Last accessed",
      accessor: "createdAt",
      Cell: (d) => this.DateFormat(d.original.createdAt),
    },
    // {
    //   Header : "Last accessed"
    // }
  ];
  // aliasname = async (d) => {
  //   console.log(d);
  //   var a = [];
  //   this.state.boxcontent.map((ival, i) => {
  //     this.state.useralias.map((jval, j) => {
  //       if (ival.type == jval.boxtypeid) {
  //         a.push(jval.boxtitle);
  //       }
  //     });
  //   });
  //   return a[0];
  // };

  DateFormat = (d) => {
    // console.log(d);

    if (d) {
      var get = parseInt(d);

      var date = new Date(get); // Date 2011-05-09T06:08:45.178Z
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2);
      var day = ("0" + date.getDate()).slice(-2);

      return `${day}-${month}-${year}`;
    }
    // console.log(a)((yyyy = ival.timecreated.getFullYear())),
    //   (mm = ("0" + (ival.timecreated.getMonth() + 1)).slice(-2)),
    //   (dd = ("0" + ival.timecreated.getDate()).slice(-2)),
    //   (ival.timecreated = yyyy + "-" + mm + "-" + dd);
    //return "date";
    // var da = new Date(d * 1000);
    // console.log(da); // Convert the passed timestamp
    // var yyyy = da.getFullYear();
    // var mm = ("0" + (da.getMonth() + 1)).slice(-2); // Months are zer
    // var dd = ("0" + da.getDate()).slice(-2); // Add leading 0.

    //   let date = new Date(d);
    // var day = date.getDate();
    // var month = date.getMonth() + 1;
    // var year = date.getFullYear();
    //   var format = dd + "-" + mm + "-" + yyyy;
    //   console.log(format);
    //   return format;
    // }
    else {
      return "NA";
    }
  };

  programSelect = async (e) => {
    let result = await cmsContent.getLeanersFromCohorts(e.value);
    this.setState({
      cohortSelectvalue: e,
      Cohortid: e.value,
      learnerSelect: result.data,
    });
  };

  learner = async (e) => {
    this.setState({ load: true })
    let result1 = await cmsContent.getTwoConditionedValue(
      "tbl_userlog",
      "userid",
      e.value,
      1,
      1
    );
    let result4 = await cmsContent.getFreedom("*", "tbl_boxcontent", "name");
    let result3 = await cmsContent.getFreedom(
      "*",
      "tbl_map_grouptoportlet",
      `customerid=${this.state.customerId}`,
      "id",
      "id"
    );
    if (result3) {
      let a = [];
      result4.data.map((jval, j) => {
        result3.data.map((ival, i) => {
          if (jval.name == ival.contentid) {
            jval.alias = ival.boxtitle;
            a.push(ival.boxtitle);
          }
        });
      });
      console.log(a);
      // this.setState({ userlog: result3.data });
    }
    // console.log(e.value)
    // console.log(result1.data )

    // console.log(result1);

    // result1.data.map((ival, i) => {
    //   let date=
    // var s = new Date(ival.createdAt).toLocaleDateString("en-US");
    // console.log(s);
    //  var formatted=t.format("dd.mm.yyyy hh:MM:ss");

    //  ival.createdAt=moment(ival.createdAt).format("YYYY-MM-DD");
    //    let date =new Date(ival.createdAt);

    //    var day = date.getDay();
    //    var month = date.getMonth()+1;
    //     var year = date.getFullYear();

    //    var formatted = day+"-"+month+"-"+year

    //  console.log("formatted");
    //  console.log(date)
    // });
    //  console.log(result1.data);
    this.setState({ userlog: result1.data, load: false });
  };

  getValueFromArray1 = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.id == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.quizName;
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  };

  getValueFromArray2 = (d, array, array1) => {
    console.log(d);
    // console.log(array1);
    var test = array.filter((element, key) => {
      if (element.value == d) {
        return true;
      }
    });
    var test1 = array1.filter((item, i) => {
      if (test && test.length > 0 && item.value == test[0].label) {
        return item;
      }
    });
    let text = test1 && test1.length > 0 ? test1[0].label : "NA"
    return text;
  };

  render() {
    const { report } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h1>User Log Report</h1>
              </div>
              <div className="card-body" />
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2"></div>
                <div className="col-sm-5"></div>
                <div className="col-sm-3" />
              </div>

              <div className="card">
                <div className="card-body">
                  <div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Cohorts</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          disabled={this.state.disabled}
                          options={this.state.cohortSelect}
                          handleChange={this.programSelect}
                          selectedService={this.state.cohortSelectvalue}
                        />
                        {/* {report && (
                              <Datatable
                                data={report}
                                columnHeading={this.column}
                              />
                            )} */}
                      </div>
                    </div>
                    {this.state.cohortSelectvalue != null && (
                      <div>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label for="exampleInputEmail1">Learners</label>
                          </div>
                          <div className="col-sm-5">
                            <SingleSelect
                              disabled={this.state.disabled}
                              options={this.state.learnerSelect}
                              handleChange={this.learner}
                              selectedService={this.state.selectedlearnervalue}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div>
                      {
                        this.state.load &&
                        <div className="loader" />
                      }
                      {this.state.userlog && (
                        <Datatable
                          data={this.state.userlog}
                          columnHeading={this.column}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default UserLogReport;
