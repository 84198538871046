import React, { Component } from "react";
import { ACCESS_POINT } from "../../config";
class MessageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      src: ""
    };
  }
  render() {
    return (
      <React.Fragment>
        <div className="row-fluid-wrapper">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="col-sm-10">
                <img
                  style={{
                    width: 'auto',
                    height: 'auto',
                    marginBottom: '10%',
                    marginTop: '0%'
                  }}
                  src={ACCESS_POINT + '/superAdmin/file?fileurl=SDAS/Video/letter_2_1583921887807_1584447571520_1584703455953.jpg'}
                />
              </div>
              <div className="col-sm-2"></div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default MessageComponent;
