import React, { Component } from "react";
import renderHTML from "react-render-html";
import CmsContent from "../../MiddleWare/CmsContent";
import DownPanel from "./DownPanel";
import RightPanel from "./RightPanel";
import http from "../../MiddleWare/httpMiddleWare";


class Dynamic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageList: "",
      date: ""
    };
    let userlog = localStorage.getItem("userlog");
    userlog = JSON.parse(userlog);
    localStorage.setItem("userId", userlog.id);
    localStorage.setItem("userName", userlog.userName);
    localStorage.setItem("userEmail", userlog.email);
    localStorage.setItem("userMobile", userlog.mobileNumber);
  }

  async componentDidMount() {
    let themeid = this.props.match.params.id;

    let ipaddressget= await http.get('https://ipapi.co/json/',function(data) {
      console.log(JSON.stringify(data, null, 2));
    })

    let date = new Date();
     var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
     var day = date.getUTCDate();
     var year = date.getUTCFullYear();
     var hour = date.getHours();
     var minutes = date.getMinutes();
     var seconds = date.getSeconds();
     var newdate = year + "-" + month + "-" + day;
     var timesDate =  year +"-" +month + "-" +day +" " + hour +":" + minutes +":" +seconds+'';
     const userlog=new FormData;
     userlog.append('userid',JSON.parse(localStorage.getItem("userlog")).id)
     userlog.append('ipaddress',ipaddressget.data.ip)
     userlog.append('datetime',timesDate)
     userlog.append('date',newdate)
     userlog.append('pageid',themeid)
     userlog.append('customerId',JSON.parse(localStorage.getItem("userlog")).customerId) 
     console.log([...userlog])
  let datauser=await CmsContent.addMaster('tbl_CMSuserAccessLog',userlog);
  console.log(datauser.data)
    
    const result = await CmsContent.getThemeById(themeid);

    if (result) {
      if (result.data[0].Date != null && result.data[0].Date != undefined) {
        var dataformate = result.data[0].Date.split("-");
        this.setState({
          date: dataformate[2] + "-" + dataformate[1] + "-" + dataformate[0]
        });
      }
      if (result.data[0].sheetContent) {
        result.data[0].sheetContent = JSON.parse(result.data[0].sheetContent);
      }
      this.setState({ pageList: result.data[0] });
    }
  }

  async componentWillReceiveProps(nextProps) {
    let themeid = nextProps.match.params.id;



    let ipaddressget= await http.get('https://ipapi.co/json/',function(data) {
      console.log(JSON.stringify(data, null, 2));
    })

    let date = new Date();
     var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
     var day = date.getUTCDate();
     var year = date.getUTCFullYear();
     var hour = date.getHours();
     var minutes = date.getMinutes();
     var seconds = date.getSeconds();
     var newdate = year + "-" + month + "-" + day;
     var timesDate =  year +"-" +month + "-" +day +" " + hour +":" + minutes +":" +seconds+'';
     const userlog=new FormData;
     userlog.append('userid',JSON.parse(localStorage.getItem("userlog")).id)
     userlog.append('ipaddress',ipaddressget.data.ip)
     userlog.append('datetime',timesDate)
     userlog.append('date',newdate)
     userlog.append('pageid',themeid)
     userlog.append('customerId',JSON.parse(localStorage.getItem("userlog")).customerId) 
     console.log([...userlog])
  let datauser=await CmsContent.addMaster('tbl_CMSuserAccessLog',userlog);
  console.log(datauser.data)
    
    const result = await CmsContent.getThemeById(themeid);
    if (result) {
      if (result.data[0].Date != null && result.data[0].Date != undefined) {
        var dataformate = result.data[0].Date.split("-");
        this.setState({
          date: dataformate[2] + "-" + dataformate[1] + "-" + dataformate[0]
        });
      }
      if (result.data[0].sheetContent) {
        result.data[0].sheetContent = JSON.parse(result.data[0].sheetContent);
      }
      this.setState({ pageList: result.data[0] });
    }
  }
  loadContent = arr => {
    return (
      <React.Fragment>
        {arr.map((ival, i) => {
          let width1;
          let width2;
          let content1 = [];
          let content2 = [];
          if (ival.order === 2) {
            width1 = ival.textwidth;
            content1.push(
              <p className="content-para">
                {renderHTML(ival.text ? ival.text : "")}
              </p>
            );
            width2 = ival.imagewidth;
            content2.push(<img src={ival.image} alt="something went wrong" />);
          } else if (ival.order === 1) {
            width1 = ival.imagewidth;
            content1.push(<img src={ival.image} alt="something went wrong" />);
            width2 = ival.textwidth;
            content2.push(
              <p className="content-para">
                {renderHTML(ival.text ? ival.text : "")}
              </p>
            );
          }
          return (
            <div className="row form-group col-sm-12">
              {width1 ? (
                <div className={`col-sm-${width1}`}>{content1}</div>
              ) : null}
              {width2 ? (
                <div className={`col-sm-${width2}`}>{content2}</div>
              ) : null}
            </div>
          );
        })}
      </React.Fragment>
    );
  };
  render() {
    const { pageList, date } = this.state;
    console.log(pageList);
    return (
      <React.Fragment>
        <div className="container-fluid container-margin-top">
          <div className="row form-group">
            <div className="col-sm-9">
              <div className="card">
                <div className="card-body">
                  <div className="row form-group">
                    <h2 className="theme-tiile text-capitalize">
                      {pageList.name}
                    </h2>
                  </div>
                  <p
                    style={{
                      color: "#828282",
                      fontSize: 14,
                      lineHeight: 2,
                      fontWeight: 400,
                      marginTop: 0,
                      paddingTop: 0
                    }}
                  >
                    {/* {"Last Updated  :  "}
                    {date} */}
                  </p>
                  <br />
                  {pageList.sheetContent && pageList.sheetContent.length
                    ? this.loadContent(pageList.sheetContent)
                    : null}
                  <br />
                  {pageList.show === "1" && (
                    <div className="blog-post-author d-flex">
                      <div>
                        <div className="author-thumbnail">
                          <img src={pageList.authorimage} alt="" />
                        </div>
                        <div className="author-info">
                          <a className="author-name">
                            {pageList.authorname}, <span>The Author</span>
                          </a>
                          <p>{pageList.authorabout}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card">
                <div className="card-body">
                  <RightPanel />
                </div>
              </div>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-sm-12 mb-new">
              <div className="card">
                {/* <div className="card-body px-0">
                  <DownPanel />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Dynamic;
