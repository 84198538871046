import React, { Component } from 'react';
import CmsContent from '../../MiddleWare/CmsContent';
import renderHTML from 'react-render-html';
import NewVideoPlayer from '../../components/Extra/NewVideoPlayer';
import DownPanel from './DownPanel';
import LeftPanel from './LeftPanel';
import './style.css';
import TopComments from './TopComments';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageList: ''
    };
    let userlog = localStorage.getItem('userlog');
    userlog = JSON.parse(userlog);
    localStorage.setItem('userId', userlog.id);
    localStorage.setItem('userName', userlog.userName);
    localStorage.setItem('userEmail', userlog.email);
    localStorage.setItem('userMobile', userlog.mobileNumber);
  }

  async componentDidMount() {
    let themeid = this.props.match.params.id;
    const result = await CmsContent.getThemeById(themeid);
    if (result) {
      
      this.setState({ pageList: result.data[0] });
    }
  }

  async componentWillReceiveProps(nextProps) {
    let themeid = nextProps.match.params.id;
    const result = await CmsContent.getThemeById(themeid);
    if (result) {
      

      this.setState({ pageList: result.data[0] });
    }
  }
  coverContent = con => {

    if (this.state.pageList.linkto) {
      //console.log(this.state.pageList.linkto)
      return (<a href={'https://'+`${this.state.pageList.linkto}`}>{con}</a>)
    }
    return con
  }

  render() {
    const { pageList } = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-margin-top">
          <div className="row">
            <div className="col-sm-4 col-md-4">
              <div className="card">
                <div className="card-body">
                  <LeftPanel />
                </div>
              </div>
            </div>
            <div className="col-sm-8 col-md-8">
              <div className="card">
                <div className="card-body">
                  <div className="row"> {pageList.contentTitle1 && <h2 className="theme-tiile content-title text-capitalize">{pageList.contentTitle1}</h2>}</div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12">{this.props.match.params.id && <NewVideoPlayer Video={this.props.match.params.id} />}</div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12">{pageList.content1 && this.coverContent(<p className="content-para">{renderHTML(pageList.content1)}</p>)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-9 col-md-9">
              <div className="card">
                <div className="card-body">
                  <div className="row">{pageList.contentTitle2 && <h2 className="theme-tiile content-title text-capitalize">{pageList.contentTitle2}</h2>}</div>
                  <div className="row">
                    <div className="col-sm-5 col-md-5">
                      {pageList.content2  && <p className="content-para"> {pageList.content2}</p>}
                      <p className="content-para">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer
                        took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining
                        essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software
                        like Aldus PageMaker including versions of Lorem Ipsum
                      </p>
                    </div>
                    <div className="col-sm-7 col-md-7">{pageList.file2 && <img className="box-shadow-img" src={pageList.file2} alt="noimage" />}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-md-3">
              <div className="card">
                <div className="card-body comment-panel-background">
                  <TopComments />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 mb-new">
              <div className="card">
                <div className="card-body">
                  <DownPanel />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
