import React, { Component } from 'react';
import SingleSelect from '../../../components/Form/SingleSelect';
import CmsContent from '../../../MiddleWare/CmsContent';
import Datatable from '../../../components/Datatable/Datatable';
import { Alert } from 'reactstrap';
import { FormMiddleWare } from '../../../components/Form';
import { ACCESS_POINT } from '../../../config';

class Viewtable extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      dataTableData: [],
      Typename: ''
    };
  }
  async componentDidMount() {
    try {
      let getgroupvalue = await CmsContent.getColoumtablevalue('tbl_boxcontent', 'customerId', this.props.match.params['customer'], 'type', this.props.match.params['usertype']);

      if (getgroupvalue) {
        this.setState({ dataTableData: getgroupvalue.data });
      }
     
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      id: 'boxtypename',
      Header: 'PortletType',
      accessor: 'PortletType'
      // accessor: d => this.getValueFromArray(d.type, this.state.boxtype),
      // Cell: d => this.getValueFromArray(d.original.type, this.state.boxtype)
    },
    {
      id: 'portalname',
      Header: 'PortletName',
      accessor: 'PortletName'
      //  accessor: d => this.getValueFromArray(d.name, this.state.boxNameList)
      // Cell: d => this.getValueFromArray(d.original.name, this.state.boxNameList)
    },

    {
      Header: 'Text',
      accessor: 'text'
    },
    {
      Header: 'Images/Videos',
      accessor: 'media',
      Cell: d => this.viewMedia(d)
    },
    {
      Header: 'Thumbnail',
      accessor: 'thumbnail',
      Cell: d => this.viewThumbnail(d)
    },
    {
      Header: 'Link',
      accessor: 'url',
      Cell: d => this.viewLink(d)
    },
    {
      Header: 'Visibility',
      accessor: 'edit',
      Cell: d => this.showVisibility(d)
    }
  ];

  showVisibility = id => {
    let status = id.original.status;
    let colour = id.original.status == 'active' ? 'warning' : 'danger';
    return this.dataTableButton(colour, status);
  };

  viewLink = d => {
    let link = ACCESS_POINT + '/superAdmin/file?fileurl=' + d.original.url;
    return (
      <a target="_blank" href={link}>
        {d.original.url}
      </a>
    );
  };

  viewMedia = d => {
    let link = ACCESS_POINT + '/superAdmin/file?fileurl=' + d.original.media;
    return (
      <a target="_blank" href={link}>
        {d.original.media}
      </a>
    );
  };
  viewThumbnail = d => {
    let link = ACCESS_POINT + '/superAdmin/file?fileurl=' + d.original.thumbnail;
    return (
      <a target="_blank" href={link}>
        {d.original.thumbnail}
      </a>
    );
  };

  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h1>{this.props.match.params['usertype']}</h1>
                  </div>
                  {/*console.log(this.props.match.params['customer'])*/}
                  {/*console.log(this.props.match.params['usertype'])*/}

                  {
                    <Datatable
                      data={this.state.dataTableData}
                      columnHeading={this.column}
                    /> /*
                  <div className="card-body">{this.state.data && <Datatable data={this.state.data} columnHeading={this.column} />}</div>
                  */
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Viewtable;
