import React from "react";
import Datatable from "../../components/Datatable/Datatable";
import { FormMiddleWare } from "../../components/Form";
import LoginModal from "../../components/Modal/Modal";
import User from "../../MiddleWare/User";
import SuperAdmin from "../../MiddleWare/SuperAdmin";
import AddUser from "../Modal/AddCustomer";
import cmsContent from "../../MiddleWare/CmsContent";
import { confirmAlert } from "react-confirm-alert";
import { async } from "q";
export default class Customer extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      userIndex: "",
      userName: "",
      mobileNumber: "",
      email: "",
      UserPassword: '',
      errorUserName: "",
      errorMobileNumber: "",
      errorEmail: "",
      errorPassword: '',
      errorloginType: "",
      errorServices: "",
      alertVisible: false,
      data: [],
      selectedService: [],
      servicesValue: "",
      selectedloginType: null,
      selectedloginvalue: "",
      updateData: false,
      updateEmail: true,
      options: [],
      imgupload: [],
      logoname: "",
      counting: [],
      arr: [],
      userlogId: [],
      eLearn: false,
      domain:"",
      errorDomain:"",
      templateCheck:false,
      options1: [
        { value: 1, label: "SMS_Login" },
        { value: 2, label: "Email_Login" }
      ]
    };
  }

  async componentDidMount() {
    try {
      const AllServices = await SuperAdmin.getAllService();

      var userlog = localStorage.getItem("userlog");
      userlog = JSON.parse(userlog);
      const mappedServices = await cmsContent.getFreedom(
        "tbl_user_web.serviceEnabled",
        "tbl_user_web",
        `id=${userlog.id}`,
        1,
        1
      );
      let ServiceOptions = AllServices.data
      let mappedOptions = mappedServices.data[0].serviceEnabled.split(',')
      mappedOptions = mappedOptions.map((ival) => {
        return parseInt(ival)
      })

      // let filterOptions = 
      if (mappedOptions && mappedOptions.length > 0) {
        let filter = []
        filter = await ServiceOptions.filter((ival) => {
          return mappedOptions.some((jval) => {
            return ival.value == jval
          })
        })
        if (filter) {
          this.setState({ options: filter });
        }
      }
    } catch (error) { }

  }

  async componentWillMount() {
    this.userList();
  }

  column = [
    {
      Header: "UserName",
      accessor: "userName",
      Cell: d => this.getvalue(d.original, "Userview"),
      width: 270

    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "MobileNumber",
      accessor: "mobileNumber",
      width: 150

    },
    {
      Header: "Status",
      accessor: "status",
      width: 150,
      className: 'text-center'
    },
    {
      Header: "Visibility",
      accessor: "edit",
      Cell: d => this.showVisibility(d),
      width: 120
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: d => this.edit(d, "customer"),
      width: 120

    },
    {
      Header: "Delete",
      accessor: "delete",
      Cell: d => this.delete(d),
      width: 120

    }
  ];
  showVisibility = d => {
    let status = d.original.status;
    let colour = d.original.status == 'active' ? 'success' : 'danger';
    return this.dataTableButton(colour, status, () => this.updateVisibility(d));
  };
  updateVisibility = async value => {
    let values = value.original;
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };
    if (newData.status === 'active') {
      newData.status = 'Inactive';
      this.setState({ button: 'Inactive' });
    } else {
      newData.status = 'active';
      this.setState({ button: 'active' });
    }

    const id = newData.id;
    const data = previousData.filter(value => value.id !== id);
    try {
      const result = await User.editSelectedUsers(id, newData);
      if (result) {
        data.splice(index, 0, newData);
        this.setState({ data: data });
      }
    } catch (error) {
      console.log(error);
    }
  };
  dataTableButton = (info, con, func, disabled = false) => {
    let classes = 'btn btn-';
    classes += info;
    return (
      <center>
        <button type="button" disabled={disabled} className={classes} onClick={func} style={{ width: 80 }}>
          {con}
        </button>
      </center>
    );
  };
  userList = async () => {
    let customerId = localStorage.getItem("userId");
    try {
      const { data } = await User.getAllUser(customerId);

      if (data) {
        this.setState({ data });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  onSubmit = async () => {
    let userName = this.state.userName;
    let mobileNumber = this.state.mobileNumber;
    let email = this.state.email;
    let servicesValue = this.state.servicesValue;
    let imgupload = this.state.imgupload;
    let counting = this.state.counting;
    let selectedloginType = this.state.selectedloginType;
    let UserPassword = this.state.UserPassword
    let eLearn = this.state.eLearn
    let domain = this.state.domain;
    let templateCheck = this.state.templateCheck

    const formData = new FormData();

    formData.append("userName", userName);
    formData.append("mobileNumber", mobileNumber);
    formData.append("email", email);
    formData.append("serviceEnabled", servicesValue);
    formData.append("domain", domain);
    if (eLearn == true) {
      formData.append('elearn', '1')
    } else {
      formData.append('elearn', '0')
    }
    if (templateCheck == true) {
      formData.append('templateCheck', '1')
    } else {
      formData.append('templateCheck', '0')
    }
    if (imgupload && imgupload.size) {
      formData.append("image", imgupload);
    }
    formData.append("customerId", localStorage.getItem("userId"));
    formData.append("userType", "3");
    formData.append("status", "active");
    formData.append("usercount", counting);
    formData.append("LoginType", selectedloginType.value);
    formData.append('withoutmd5', UserPassword)
// console.log(...formData,"formDataformDataformData");
    try {
      //const result = await User.userInsert(userArray);
      const result = await User.adminuserInsert("tbl_user_web", formData);

      if (result) {
        this.setState({
          alertVisible: true,
          userName: "",
          mobileNumber: "",
          email: "",
          servicesValue: "",
          selectedValue: null,
          selectedService: [],
          imgupload: "",
          logoname: "",
          UserPassword: '',
          selectedloginType: null,
          counting: [],
          arr: [],
          count: '',
          eLearn: false,
          domain:"",
          templateCheck:false,

        });
      }
      this.userList();
      setTimeout(() => this.setState({ alertVisible: false }), 2000);
    } catch (error) {
      console.log(error);
    }
  };

  onUpdate = async () => {
    let userIndex = this.state.userIndex;
    let userName = this.state.userName;
    let mobileNumber = this.state.mobileNumber;
    let email = this.state.email;
    let servicesValue = this.state.servicesValue;
    let imgupload = this.state.imgupload;
    let counting = this.state.counting;
    let selectedloginType = this.state.selectedloginType;
    let UserPassword = this.state.UserPassword;
    let domain = this.state.domain;
    let templateCheck = this.state.templateCheck

    const formData = new FormData();
    formData.append("userName", userName);
    formData.append("mobileNumber", mobileNumber);
    formData.append("email", email);
    formData.append("serviceEnabled", servicesValue);
    formData.append("usercount", counting);
    formData.append("LoginType", selectedloginType.value);
    formData.append("domain", domain);

    if (templateCheck == true) {
      formData.append('templateCheck', '1')
    } else {
      formData.append('templateCheck', '0')
    }

    if (imgupload && imgupload.size) {
      formData.append('image', imgupload);
    }
    formData.append('withoutmd5', UserPassword)


    // get all the user except the edit user
    // get all the user
    const previousData = [...this.state.data];
    // get only the edit user from the list of user
    const getData = { ...previousData[userIndex] };
    const id = getData.id;
    // getData.userName = userName;
    // getData.mobileNumber = mobileNumber;
    // getData.email = email;
    // getData.serviceEnabled = servicesValue;
    // getData.image = imgupload;
    // // getData.withoutmd5=UserPassword

    // // get all the user except the edit user
    // const data = previousData.filter(value => value.id !== id);
    // data.splice(userIndex, 0, getData); // update the user value in the list at particular index
    try {
      // const result = await User.editSelectedUser(id, userArray);
      const result = await User.updateadminuserInsert(id, formData);
      if (result) {
        this.setState({
          alertVisible: true,
          userName: "",
          mobileNumber: "",
          email: "",
          userIndex: "",
          errorUserName: "",
          errorMobileNumber: "",
          errorEmail: "",
          selectedService: [],
          servicesValue: "",
          logoname: "",
          UserPassword: '',
          selectedloginType: null,
          counting: [],
          arr: [],
          count: '',
          domain:"",
          errorDomain:"",
          templateCheck:false
        });
      }
      this.userList()
      setTimeout(() => this.setState({ alertVisible: false }), 2000);
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };

  handleChanged = selectedOption => {
    let selectedValue = "";
    let arr = [];
    selectedOption.map(values => (selectedValue += `${values.value},`));
    selectedOption.map(values => arr.push(values.label));
    selectedValue = selectedValue.replace(/,\s*$/, "");
    this.addCount(selectedOption, selectedValue, arr);
  };
  addCount = (selectedOption, selectedValue, arr) => {
    var count = 0;
    if (selectedOption && selectedOption.length) {
      count = selectedOption.length;
    } else {
      this.setState({ counting: [] });
    }
    console.log(selectedOption, 'selectedOption')
    this.setState({
      selectedService: selectedOption,
      servicesValue: selectedValue,
      count,
      arr
    });
  };
  handleCount = async e => {
    let id = e.target.id;
    let value = e.target.value;
    let counting = await this.state.counting;
    counting[id] = value;
    this.setState({ counting });
  };
  buttonEdit = value => {
    // console.log(value);
    let values = value.original;
    const index = value.index;
    const previousData = [...this.state.data];
    // console.log(getData,"getData");
    // console.log(previousData,"previousData");
    const { userName, mobileNumber, email, serviceEnabled, withoutmd5, image ,domain} = {
      ...previousData[index]
    };
    // console.log(withoutmd5,"UserPassword");

    let selectedOption = []
    let serviceList = ''
    if (serviceEnabled && serviceEnabled.length > 0) {
      selectedOption = serviceEnabled
        .split(",")
        .map(d =>
          this.state.options.filter(
            values => parseInt(values.value) === parseInt(d)
          )
        );
      serviceList = selectedOption.map(list => list[0]);
    } else {
      serviceList = []
    }
    var logoname = "";
    if (image != "" && image != undefined) {
      logoname = image.split("SDAS/Video/");
    } else {
      logoname = "";
    }

    let selectedloginType = {};
    if (values && values.LoginType !== null) {
      this.state.options1.map((ival, index) => {
        if (ival.value === values.LoginType) {
          selectedloginType.value = ival.value;
          selectedloginType.label = ival.label;
        }
      });
    }
    console.log(selectedloginType, "selectedloginType");
    this.setState({
      userIndex: index,
      userName: userName,
      mobileNumber: mobileNumber,
      email: email,
      UserPassword: withoutmd5,
      errorUserName: "",
      errorMobileNumber: "",
      errorEmail: "",
      errorDomain:"",
      alertVisible: false,
      updateData: true,
      selectedService: serviceList,
      servicesValue: serviceEnabled,
      updateEmail: false,
      imgupload: image,
      logoname: logoname,
      domain:domain,
      selectedloginType: selectedloginType
    });
  };

  buttonDelete = value => {
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const id = value.original.id;
            const previousData = [...this.state.data];
            const data = previousData.filter(value => value.id !== id);
            this.setState({
              data
            });
            try {
              await User.deleteSelectedUser(id);
            } catch (error) {
              this.setState({
                data: previousData
              });
              console.log(error);
            }
          }
        },
        {
          label: "No"
        }
      ]
    });
  };

  reset = () => {
    this.setState({
      userIndex: "",
      userName: "",
      mobileNumber: "",
      email: "",
      errorUserName: "",
      errorMobileNumber: "",
      errorEmail: "",
      errorPassword: '',
      errorloginType: '',
      errorServices: '',
      logoname: "",
      alertVisible: false,
      updateData: false,
      updateEmail: true,
      selectedService: [],
      servicesValue: "",
      selectedloginType: null,
      UserPassword: '',
      counting: [],
      count: '',
      arr: [],
      eLearn: false,
      domain:"",
      errorDomain:"",
      templateCheck:false
    });
  };

  getvalue = (value, modalWindowId) => {
    // this.setState({ previousid: value });
    return (
      <button
        type="button"
        className="btn btn-warning"
        style={{ width: '250px' }}
        onClick={() => window.open("GetLearners" + "/" + value.id, "_blank")}
      >
        {value.userName}
      </button>
    );
  };

  buttonView = async value => {
    let result = await User.getUservalue(value);
    if (result) {
      this.setState({ data: result.data });
    }
  };
  handlelogintype = async selectedOption => {
    await this.setState({
      selectedloginType: selectedOption,
      selectedloginvalue: selectedOption.value
    });
    //console.log(this.state.selectedloginvalue);
  };
  handleElearn = async data => {
    await this.setState({ eLearn: !this.state.eLearn })
  }
  handleTemplateCheck = async data => {
    await this.setState({ templateCheck: !this.state.templateCheck })
    if(this.state.templateCheck === false){
      await this.setState({ domain:'' })
    }
  }
  render() {
    let userDetails = localStorage.getItem("userlog")
    userDetails = JSON.parse(userDetails)
    let userType = userDetails.userType

    const userData = {
      userName: this.state.userName,
      mobileNumber: this.state.mobileNumber,
      email: this.state.email,
      UserPassword: this.state.UserPassword,
      eLearn: this.state.eLearn,
      domain:this.state.domain,
      templateCheck: this.state.templateCheck,

    };

    const errorData = {
      errorUserName: this.state.errorUserName,
      errorMobileNumber: this.state.errorMobileNumber,
      errorEmail: this.state.errorEmail,
      errorPassword: this.state.errorPassword,
      errorloginType: this.state.errorloginType,
      errorServices: this.state.errorServices,
      errorDomain: this.state.errorDomain,
    };
    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3>Customer List</h3>
                </div>
                <div className="card-body">
                  <LoginModal
                    buttonTitle="Add Customer"
                    title={
                      this.state.updateData === false
                        ? "Add Company"
                        : "Update Company"
                    }
                    id="customer"
                    reset={this.reset}
                    bodyText={
                      <AddUser
                        handleInput={this.handleUserInput}
                        userData={userData}
                        errorData={errorData}
                        handleSubmit={this.handleSubmit}
                        onDismiss={this.onDismiss}
                        alertVisible={this.state.alertVisible}
                        checkMobileNumber={this.checkMobileNumber}
                        checkEmail={this.checkEmail}
                        handleChange={this.handleChanged}
                        userType={userType}
                        selectedService={this.state.selectedService}
                        handleElearn={this.handleElearn}
                        handleTemplateCheck={this.handleTemplateCheck}
                        // errorService={this.state.errorService}
                        updateData={this.state.updateData}
                        options={this.state.options}
                        options1={this.state.options1}
                        handlelogintype={this.handlelogintype}
                        selectedloginType={this.state.selectedloginType}
                        updateEmail={this.state.updateEmail}
                        uploadimg={this.uploadimg}
                        logoname={this.state.logoname}
                        count={this.state.count ? true : false}
                        arr={this.state.arr}
                        selectedValue={this.state.selectedValue}
                        handleCount={this.handleCount}
                      />
                    }
                  />
                  <br />
                  {this.state.data && (
                    <Datatable
                      data={this.state.data}
                      columnHeading={this.column}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
