import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import CmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import AddSubCategory from "./AddSubCategory";
import AddCategory from "./AddCategory";
import Datatable from "../../../components/Datatable/Datatable";
import "./style.css";
import { Alert } from "reactstrap";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import CMSCategorypdf from "../../../components/PDF/Categorypdf";
import { FormMiddleWare, MultiSelect } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";

class CmdlCategory extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            categorySelected: { value: 310, label: 'Courses' },
            categoryId: 310,
            categoryName: "Courses",
            categoryOptions: [],
            subCategorySelected: "",
            subCategoryId: "",
            subCategoryName: "",
            subCategoryOptions: [],
            pageSelected: "",
            deptSelected: "",
            pageId: "",
            pageName: "",
            pageOptions: [],
            csvUpdate: false,
            userOptions: [],
            UserOption: [],
            category: "",
            subCategory: "",
            errorCategory: "",
            errorSubCategory: "",
            errorCategoryId: "",
            errorSubCategoryId: "",
            errorPageId: "",
            data: [],
            alertVisible: false,
            btnDisable: false,
            formAlert: false,
            formBtn: false,
            updateorsubmit: "Submit",
            updateid: "",
            dataIndex: null,
            formAlertdelete: false,
            customerId: localStorage.getItem("userId"),
            EmpOption: [],
        };
    }

    async componentDidMount() {
        this.mainFun();
    }
    async mainFun() {
        try {
            this.setState({ loading: true })
            const languages = await CmsContent.getFreedom(
                "id as value,language as label",
                "cmndl_tbl_languages",
                1,
                "id",
                "id DESC"
            );
            if (languages) {
                this.setState({ langOptions: languages.data });
            }
            const User = await CmsContent.getFreedom(
                "id as value,usertype as label",
                "tbl_UserType",
                `customerid = ${this.state.customerId} and serviceid = 8`,
                "id",
                "id DESC"
            );
            if (User) {
                this.setState({ UserOption: User.data });
            }
            // const Employee = await CmsContent.getFreedom(
            //     "employee_id as value,employee_name as label",
            //     "cmndl_tbl_Employee",
            //     `customerid=${this.state.customerId}`,
            //     "id",
            //     "id DESC"
            // );
            // if (Employee) {
            //     this.setState({ EmpOption: Employee.data });
            // }
            const pages = await CmsContent.getFreedom(
                "id as value,name as label",
                "tbl_pages",
                `customerId=${this.state.customerId} and themeId=3`,
                "id",
                "id DESC"
            );
            if (pages) {
                this.setState({ pageOptions: pages.data });
            }
            const user = await CmsContent.getFreedom(
                "id as value,name as label",
                "tbl_department_master",
                `customerId=${this.state.customerId} and status="active"`,
                "id",
                "id DESC"
            );
            console.log(user)
            if (user) {

                this.setState({ userOptions: user.data });
            }
            const category = await CmsContent.getFreedom(
                "id as value,name as label",
                "tbl_category_master",
                `customerId=${this.state.customerId} and status="active"`,
                "id",
                "id DESC"
            );
            if (category) {
                this.setState({ categoryOptions: category.data });
            }
            const subCategory = await CmsContent.getFreedom(
                "id as value,name as label",
                "tbl_subCategory_master",
                `customerId=${this.state.customerId} and status="active"`,
                "id",
                "id DESC"
            );
            if (subCategory) {
                this.setState({ subCategoryOptions: subCategory.data });
            }
            let pageid = 310
            if (this.state.customerId == 807) {
                pageid = 310
            } else if (this.state.customerId == 1783) {
                pageid = 330
            }
            else if (this.state.customerId == 6075) {
                pageid = 330
            }
            const getAllMappedpages = await CmsContent.getFreedom(
                "tbl_mapping_page.id,tbl_mapping_page.language,tbl_mapping_page.Rules,tbl_mapping_page.pageId,tbl_mapping_page.subCategoryId,tbl_mapping_page.subCategoryName,tbl_mapping_page.categoryName,tbl_mapping_page.categoryId,tbl_mapping_page.status,tbl_mapping_page.subCategoryName,tbl_pages.name,tbl_mapping_page.deptid,tbl_mapping_page.userType,tbl_mapping_page.Employee_id",
                "tbl_pages,tbl_mapping_page",
                ` tbl_pages.id=tbl_mapping_page.pageId and tbl_pages.customerId=${this.state.customerId} and tbl_mapping_page.categoryId=${pageid} and tbl_pages.themeId=3`,
                "tbl_mapping_page.id",
                "tbl_mapping_page.id DESC"
            );
            if (getAllMappedpages) {
                this.setState({ data: getAllMappedpages.data, loading: false });
            }
            // const { data: Ruleslist } = await CmsContent.getFreedom(
            //     'id as value ,Name as label', "cmndl_tbl_rules", `status='active'`, 1, 1
            // )
            // if (Ruleslist) {
            //     await this.setState({ Ruleslist })
            // }
            // console.log(this.state.data)
            // console.log(getAllMappedpages.data);
            // if (getAllMappedpages.data.length > 0) {
            //     const header = [
            //         "SI.NO",
            //         "CATEGORY",
            //         "SUBCATEGORY",
            //         "PAGENAME",
            //         "STATUS",
            //     ];
            //     // const excelHead = [
            //     //     { label: "CATEGORY", key: "categoryName" },
            //     //     { label: "SUBCATEGORY", key: "subCategoryName" },
            //     //     { label: "PAGENAME", key: "name" },
            //     //     { label: "STATUS", key: "status" },
            //     // ];
            //     this.setState({
            //         // excelHead,
            //         header,
            //         // excel: getAllMappedpages.data,
            //         title: "MAP PAGE TO CATEGORY",
            //     });
            // }
        } catch (error) {
            console.log(error);
        }
    }
    column = [
        // {
        //     Header: "Category",
        //     accessor: "categoryName",
        // },
        {
            Header: "Courses Name",
            accessor: "subCategoryName",
        },
        {
            Header: "Page",
            accessor: "name",
        },

        {
            Header: "Status",
            accessor: "status",
            Cell: (d) => this.statusChange(d),
        },
        {
            Header: "Visibility",
            accessor: "edit",
            Cell: (d) => this.showVisibility(d),
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: (d) => this.edit(d, "customer"),
        },
        {
            Header: "Delete",
            accessor: "delete",
            Cell: (d) => this.delete(d),
        },
    ];
    column1 = [
        {
            Header: "Category",
            accessor: "categoryName",
        },
        {
            Header: "Sub-Category",
            accessor: "subCategoryName",
        },
        {
            Header: "page",
            accessor: "name",
        },
        // {
        //   Header: "Department",
        //   accessor: "deptid",
        //   Cell: d =>
        //         this.getValueFromArrays(
        //           d.original.deptid,
        //           this.state.userOptions
        //         )
        // },

        {
            Header: "Status",
            accessor: "status",
            Cell: (d) => this.statusChange(d),
        },
        {
            Header: "Visibility",
            accessor: "edit",
            Cell: (d) => this.showVisibility(d),
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: (d) => this.edit(d, "customer"),
        },
        {
            Header: "Delete",
            accessor: "delete",
            Cell: (d) => this.delete(d),
        },
    ];

    getValueFromArrays = (d, array) => {

        if (array.length > 0) {
            if (array.length !== 0) {
                let filtered = array.filter(function (item) {
                    return item.value == d;
                });
                let v = filtered[0];
                if (v != undefined) {

                    return v.label;
                } else {
                    return "---";
                }
            }
        } else {
            return "-";
        }
    };
    statusChange = (id) => {
        let nameCapitalized =
            id.original.status.charAt(0).toUpperCase() + id.original.status.slice(1);

        return (
            <span
                style={
                    id.original.status == "active"
                        ? { color: "green", fontWeight: "bold" }
                        : { color: "red", fontWeight: "bold" }
                }
            >
                {" "}
                {nameCapitalized}
            </span>
        );
    };

    showVisibility = (id) => {
        return (
            <button
                type="button"
                className="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '15px' }}
                onClick={() => this.updateVisibility(id)}
            >
                Active/Inactive
            </button>
        );
    };

    updateVisibility = async (value) => {
        const index = value.index;
        const previousData = [...this.state.data];
        const newData = { ...previousData[index] };

        if (newData.status === "active") {
            newData.status = "Inactive";
        } else {
            newData.status = "active";
        }
        console.log(newData)
        const id = newData.categoryId;
        const data = previousData.filter((value) => value.categoryId !== id);
        //const data = previousData.filter((value) => value.id !== id);
        data.splice(index, 0, newData);
        this.setState({ data });
        try {
            const result = await CmsContent.updateCategory(newData);
            if (result) {
                this.setState({ data: data });
            }
        } catch (error) {
            this.setState({ data: previousData });
            console.log(error);
        }
    };

    edit = (value, modalWindowId) => {
        return (
            <button
                type="button"
                data-toggle="modal"
                data-target={`#${modalWindowId}`}
                className="btn btn-warning"
                onClick={() => this.buttonEdit(value)}
            >
                Edit
            </button>
        );
    };

    buttonEdit = (value) => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        // console.log(value, 'value');
        console.log(this.state.data, 'dat');
        let categoryJson = {};
        let subcategoryJson = {};
        let pageJson = {};
        let deptJson = {};
        let language = {};
        let User = []
        let SelectedEmployee = [];
        const index = value.index;
        const previousData = [...this.state.data];
        const getData = { ...previousData[index] };
        console.log(getData);
        categoryJson.label = getData.categoryName;
        categoryJson.value = getData.categoryId;

        subcategoryJson.label = getData.subCategoryName;
        subcategoryJson.value = getData.subCategoryId;

        pageJson.label = getData.name;
        pageJson.value = getData.pageId;

        language.label = getData.language;
        language.value = getData.languageId;
        // let Rules = ''
        // if (getData.Rules) {
        //     Rules = this.state.Ruleslist.filter(ival => ival.value == parseInt(getData.Rules))
        // }
        // if (getData.userType) {
        //     JSON.parse(getData.userType).map((ival) => {
        //         this.state.UserOption.map((mval) => {
        //             if (ival == mval.value) {
        //                 User.push(mval)
        //             }
        //         })
        //     });
        // }
        // if (getData.Employee_id) {
        //     JSON.parse(getData.Employee_id).map((ival) => {
        //         this.state.EmpOption.map((mval) => {
        //             if (ival == mval.value) {
        //                 SelectedEmployee.push(mval)
        //             }
        //         })
        //     });
        // }
        // console.log(SelectedEmployee);
        // // console.log(getData);
        // deptJson.value = getData.deptid;
        // deptJson.label = this.getValueFromArrays(getData.deptid,this.state.userOptions);

        //   console.log(deptJson);
        // let Employees = this.removeDuplicates(SelectedEmployee, 'value')

        this.setState({
            dataIndex: index,
            updateid: getData.id,
            // Rulesvalue: Rules[0],
            categorySelected: categoryJson,
            categoryId: getData.categoryId,
            categoryName: getData.categoryName,
            language,
            subCategorySelected: subcategoryJson,
            subCategoryId: getData.subCategoryId,
            subCategoryName: getData.subCategoryName,

            pageSelected: pageJson,
            name: getData.name,
            pageId: getData.pageId,
            pageName: getData.name,
            User,
            // SelectedEmployee: Employees,
            // SelectedEmp: getData.Employee_id ? JSON.parse(getData.Employee_id) : [],
            // deptSelected:deptJson,
            // deptId:getData.deptId,
            // deptName:getData.deptName,

            updateorsubmit: "Update",
        });
    };

    delete = (value) => {
        return (
            <button
                type="button"
                className="btn btn-danger"
                onClick={() => this.DelConfirm(value)}
            >
                Delete
            </button>
        );
    };
    DelConfirm = (value) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <div style={{ textAlign: "center" }}>
                    <h1>Delete confirmation</h1>
                    <h4>Are you sure you want to Delete this ?</h4><br />
                    <button className="btn btn-primary" onClick={() => {
                        this.buttonDelete(value)
                        onClose();
                    }}  >Yes</button> &nbsp;  &nbsp;
                    <button className="btn btn-danger" onClick={onClose} >No</button>
                </div>
            }
        })
    }

    buttonDelete = async (e) => {
        //Getting Previous state data
        const previousData = [...this.state.data];

        // Seperating data row using row-index
        const getData = { ...previousData[e.index] };

        //getting id on that data
        const id = getData.id;
        //removing specific id in previous state data
        const data = previousData.filter((value) => value.id !== id);

        try {
            const result = await CmsContent.deleteMaster("tbl_mapping_page", id);
            if (result) {
                this.setState({
                    data,
                    updateorsubmit: "Submit",
                    pageSelected: "",
                    subCategorySelected: "",
                    categorySelected: "",
                    categoryId: "",
                    subCategoryId: "",
                    pageId: "",
                    formAlertdelete: true,
                    formBtn: false,
                });
                this.mainFun()
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
        } catch (error) {
            this.setState({ data: previousData });
            console.log(error);
        }
    };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    addNewCategory = async () => {
        let categoryArray = {};
        let categoryAddExtra = {};
        let categoryName = this.state.category;
        let categoryOptions = this.state.categoryOptions;

        if (!categoryName) {
            this.setState({ errorCategory: "Enter Categoty Name" });
            return false;
        } else {
            this.setState({ errorCategory: "", btnDisable: true });
            categoryArray.name = categoryName.trim();
            categoryArray.status = "active";
            categoryArray.customerId = this.state.customerId;

            try {
                const result = await CmsContent.addMaster(
                    "tbl_category_master",
                    categoryArray
                );
                if (result) {
                    categoryAddExtra.value = result.data.insertId;
                    categoryAddExtra.label = categoryName;
                    categoryOptions.splice(0, 0, categoryAddExtra);
                    this.setState({
                        category: "",
                        btnDisable: false,
                        alertVisible: true,
                        categoryOptions,
                    });
                    setTimeout(() => this.setState({ alertVisible: false }), 3000);
                    // this.componentDidMount()
                    this.mainFun();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    addNewSubCategory = async () => {
        let subCategoryArray = {};
        let subCategoryAddExtra = {};
        let subCategoryName = this.state.subCategory;
        let subCategoryOptions = this.state.subCategoryOptions;

        if (!subCategoryName) {
            this.setState({ errorSubCategory: "Enter Sub-Categoty Name" });
            return false;
        } else {
            this.setState({ errorSubCategory: "", btnDisable: true });
            subCategoryArray.name = subCategoryName.trim();
            subCategoryArray.status = "active";
            subCategoryArray.customerId = this.state.customerId;

            try {
                const result = await CmsContent.addMaster(
                    "tbl_subCategory_master",
                    subCategoryArray
                );

                if (result) {
                    subCategoryAddExtra.value = result.data.insertId;
                    subCategoryAddExtra.label = subCategoryName;
                    subCategoryOptions.splice(0, 0, subCategoryAddExtra);
                    this.setState({
                        subCategory: "",
                        btnDisable: false,
                        alertVisible: true,
                        subCategoryOptions,
                    });
                    this.mainFun()
                    setTimeout(
                        (dataIndex) => this.setState({ alertVisible: false }),
                        3000
                    );
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    categorySelect = (selectedOption) => {
        this.setState({
            categorySelected: selectedOption,
            categoryId: selectedOption.value,
            categoryName: selectedOption.label,
        });
    };

    subCategorySelect = (selectedOption) => {
        this.setState({
            subCategorySelected: selectedOption,
            subCategoryId: selectedOption.value,
            subCategoryName: selectedOption.label,
        });
        let result = this.state.data.filter((a) => a.subCategoryId == selectedOption.value)
        if (result.length > 0) {
            console.log(result[0]);
            let value = {}
            value.index = this.state.data.findIndex(i => i.id == result[0].id);
            this.buttonEdit(value)
        }
    };

    pageSelect = (selectedOption) => {
        this.setState({
            pageSelected: selectedOption,
            pageId: selectedOption.value,
            pageName: selectedOption.label,
        });
    };
    userSelect = (selectedOption) => {
        console.log(selectedOption);
        this.setState({
            deptSelected: selectedOption,
            deptId: selectedOption.value,
            deptName: selectedOption.label,
        });
    };

    submitCategory = async (e) => {
        let mappingArray = {};
        const {
            categoryId,
            categoryName,
            subCategoryId,
            subCategoryName,
            pageId,
            pageName,
            language,
            User,
            SelectedEmployee,
            updateorsubmit,
            deptId,
            deptName,
            Rulesvalue,
            csvUpdate, file, customerId
        } = this.state;

        // if (!categoryId) {
        //     this.setState({ errorCategoryId: "Select Category" });
        //     return false;
        if (!pageId) {
            this.setState({ errorCategoryId: "", errorPageId: "Select Page" });
            return false;
        }
        //  else if (!User && this.state.customerId == 807) {
        //     this.setState({ errorPageId: "", errorUserType: "Select User" });
        //     return false;
        // }

        else {
            // this.setState({ errorPageId: "", formBtn: true });
            mappingArray.categoryId = customerId == 807 ? categoryId : 330;
            mappingArray.categoryName = categoryName;
            if (subCategoryId) {
                mappingArray.subCategoryId = subCategoryId;
                mappingArray.subCategoryName = subCategoryName;
            }
            mappingArray.pageId = pageId;
            mappingArray.status = "active";
            mappingArray.customerId = this.state.customerId;
            // if (this.state.customerId == 807 || ) {
            mappingArray.languageId = language ? language.value : 2;
            mappingArray.language = language ? language.label : 'ENGLISH';
            // mappingArray.Rules = Rulesvalue.value
            // if (User) {
            //     let check = []
            //     User.map((ival) => {
            //         if (ival.value) {
            //             check.push(ival.value)
            //         }
            //     })
            //     mappingArray.userType = JSON.stringify(check)
            //     // }
            //     if (!csvUpdate && SelectedEmployee) {

            //         let check = []
            //         SelectedEmployee.map((ival) => {
            //             if (ival.value) {
            //                 check.push(ival.value)
            //             }
            //         })
            //         mappingArray.Employee_id = JSON.stringify(check)

            //     }
            // }
            // mappingArray.deptid = deptId

            // return true

            try {
                const result = await CmsContent.addMaster(
                    "tbl_mapping_page",
                    mappingArray
                );
                if (result) {
                    if (!csvUpdate) {
                        let logArray = mappingArray;
                        logArray.map_id = result.data.insertId
                        const log = await CmsContent.addMaster(
                            "cmndl_mapping_log",
                            logArray
                        );
                    }
                    // if (csvUpdate) {
                    //     let formData = new FormData()
                    //     formData.append("file", file);
                    //     formData.append("id", result.data.insertId);
                    //     formData.append("mapData", JSON.stringify(mappingArray));
                    //     formData.append("type", 'submit');
                    //     const result1 = await CmsContent.CmdlCourseMapCSV(
                    //         formData
                    //     )
                    // }
                    const userData = {
                        id: parseInt(result.data.insertId),
                        categoryName: categoryName,
                        subCategoryName: subCategoryName,
                        status: "active",
                        name: pageName,
                    };
                    const newData = [userData, ...this.state.data];
                    this.setState({
                        data: newData,
                        Rulesvalue: '',
                        pageSelected: "",
                        subCategorySelected: "",
                        categorySelected: "",
                        // categoryId: "",
                        subCategoryId: "",
                        pageId: "",
                        csvUpdate: false,
                        deptId: "",
                        deptSelected: "",
                        userName: "",
                        User: '',
                        SelectedEmployee: '',
                        SelectedEmp: '',
                        userSelected: "",
                        language: '',
                        formAlert: true,
                        formBtn: false,

                    });
                    this.mainFun()
                    setTimeout(() => this.setState({ alertVisible: false, formAlert: false, }), 3000);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    updateMaster = async (e) => {
        let mappingArray = {};
        const {
            categoryId,
            categoryName,
            subCategoryId,
            subCategoryName,
            pageId,
            pageName,
            updateid,
            dataIndex,
            language,
            User,
            SelectedEmployee,
            SelectedEmp,
            deptId, file,
            csvUpdate,
            customerId,
            Rulesvalue,
        } = this.state;
        // if (!User && this.state.customerId == 807) {
        //     this.setState({ errorPageId: "", errorUserType: "Select User" });
        //     return false;
        // }
        this.setState({ formBtn: true })
        mappingArray.categoryId = customerId == 807 ? categoryId : 330;
        mappingArray.categoryName = categoryName;
        mappingArray.pageId = pageId;
        mappingArray.status = "active";
        // mappingArray.Rules = Rulesvalue.value
        // if (this.state.customerId == 807) {
        mappingArray.languageId = language ? language.value : 2;
        mappingArray.language = language ? language.label : 'ENGLISH';
        mappingArray.customerId = this.state.customerId;
        // if (User) {
        //     let check = []
        //     User.map((ival) => {
        //         if (ival.value) {
        //             check.push(ival.value)
        //         }
        //     })
        //     mappingArray.userType = JSON.stringify(check);
        // }
        // let Empcheck = []
        // if (!csvUpdate && SelectedEmployee) {
        //     SelectedEmployee.map((ival) => {
        //         if (ival.value) {
        //             Empcheck.push(ival.value)
        //         }
        //     })
        //     mappingArray.Employee_id = JSON.stringify(Empcheck)
        // }
        // }
        if (subCategoryId) {
            mappingArray.subCategoryId = subCategoryId;
            mappingArray.subCategoryName = subCategoryName;
        }
        // mappingArray.deptid = deptId;
        const previousData = [...this.state.data];
        const getData = { ...previousData[dataIndex] };

        const id = getData.id;
        getData.categoryId = categoryId;
        getData.categoryName = categoryName;
        getData.pageId = pageId;
        getData.status = "active";
        getData.name = pageName;

        // getData.deptId=deptId;
        // getData.deptName=this.getValueFromArrays(deptId,this.state.userOptions)
        if (subCategoryId) {
            getData.subCategoryId = subCategoryId;
            getData.subCategoryName = subCategoryName;
        }
        const data = previousData.filter((value) => value.id !== id);
        data.splice(dataIndex, 0, getData);
        console.log(mappingArray)
        let categoryArray = {}
        categoryArray.mappingArray = mappingArray
        categoryArray.updateid = updateid
        // categoryArray.SelectedEmployee = JSON.stringify(Empcheck)
        // categoryArray.SelectedEmployeeOld = JSON.stringify(SelectedEmp)
        // categoryArray.csvUpdate = csvUpdate
        try {
            const result = await CmsContent.CmdlCourseMapUpdate(
                categoryArray
            );
            if (result) {
                // if (csvUpdate) {
                //     let formData = new FormData()
                //     formData.append("file", file);
                //     formData.append("id", updateid);
                //     formData.append("mapData", JSON.stringify(mappingArray));
                //     formData.append("currentEmp", JSON.stringify(SelectedEmp));
                //     formData.append("type", 'update');
                //     const result1 = await CmsContent.CmdlCourseMapCSV(
                //         formData
                //     )
                // }
                this.setState({
                    data,
                    updateorsubmit: "Submit",
                    pageSelected: "",
                    csvUpdate: false,
                    Rulesvalue: '',
                    subCategorySelected: "",
                    categorySelected: "",
                    // categoryId: "",
                    subCategoryId: "",
                    language: '',
                    pageId: "",
                    deptId: "",
                    deptSelected: "",
                    userName: "",
                    User: '',
                    SelectedEmployee: '',
                    SelectedEmp: '',
                    userSelected: "",
                    formAlert: true,
                    formBtn: false,
                });
                this.mainFun()
                setTimeout(() => this.setState({ alertVisible: false, formAlert: false, }), 3000);
            }
        } catch (error) {
            this.setState({ data: previousData });
            console.log(error);
        }
    };

    onDismiss = () => {
        this.setState({ alertVisible: false, formAlert: false });
        this.setState({ alertVisible: false, formAlertdelete: false });
    };
    handleCheck = (name, selectedOption) => {
        // if (name == "User") {
        // let check = [];
        // selectedOption.map((ival, i) => {
        //   check.push(ival.label)
        // })
        // console.log(check, "check");
        this.setState({
            [name]: selectedOption,
        });
        console.log(this.state.User);
        // }
    }
    handleSelect = (name, selectedOption) => {
        this.setState({
            [name]: selectedOption
        });
    };
    fileupload = e => {
        this.setState({ file: e.target.files[0] })
    }
    reset = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        this.setState({
            updateorsubmit: "Submit",
            pageSelected: "",
            csvUpdate: false,
            Rulesvalue: '',
            subCategorySelected: "",
            categorySelected: "",
            categoryId: "",
            subCategoryId: "",
            language: '',
            pageId: "",
            deptId: "",
            deptSelected: "",
            userName: "",
            User: '',
            SelectedEmployee: '',
            SelectedEmp: '',
            userSelected: "",
            updateid: ''
        })
    }
    render() {
        const {
            formAlert,
            formAlertdelete,
            categoryOptions,
            categorySelected,
            errorCategory,
            alertVisible,
            btnDisable,
            category,
            errorCategoryId,
            subCategoryOptions,
            subCategorySelected,
            subCategory,
            errorSubCategory,
            errorSubCategoryId,
            pageOptions,
            userOptions,
            pageSelected,
            deptSelected,
            errorPageId,
            formBtn,
            data,
            updateorsubmit,
            langOptions,
            UserOption,
            language,
            EmpOption,
            loading
        } = this.state;
        // console.log(categorySelected,'categorySelected');
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Map Course</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color="success"
                                                    isOpen={formAlert}
                                                    toggle={this.onDismiss}
                                                >
                                                    Page Successfully Mapped To Category
                                                </Alert>

                                                <Alert
                                                    className="badge-content"
                                                    color="danger"
                                                    isOpen={formAlertdelete}
                                                    toggle={this.onDismiss}
                                                >
                                                    Mapped Page Delete
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        {/*      <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Select Category</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          handleChange={this.categorySelect}
                          options={categoryOptions}
                          selectedService={categorySelected}
                        />
                      </div>
                      <div className="col-sm-3">
                        <LoginModal
                          buttonTitle="Add Category"
                          title="Add Category"
                          id="Category"
                          extraClass="btn-width"
                          onClick={this.reset}
                          bodyText={
                            <AddCategory
                              category={category}
                              handleChange={this.handleChange}
                              addNewCategory={this.addNewCategory}
                              error={errorCategory}
                              alert={alertVisible}
                              dismiss={this.onDismiss}
                              disableValue={btnDisable}
                              alertMsg="New Category Added"
                            />
                          }
                        />
                      </div>
                    </div> */}
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">{errorCategoryId}</span>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label for="exampleInputEmail1">
                                                    Select Title*
                                                </label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    handleChange={this.subCategorySelect}
                                                    options={subCategoryOptions}
                                                    selectedService={subCategorySelected}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <LoginModal
                                                    buttonTitle="Add Title"
                                                    title="Add Title"
                                                    id="subCategory"
                                                    extraClass="btn-width"
                                                    bodyText={
                                                        <AddSubCategory
                                                            subCategory={subCategory}
                                                            handleChange={this.handleChange}
                                                            addNewSubCategory={this.addNewSubCategory}
                                                            error={errorSubCategory}
                                                            alert={alertVisible}
                                                            dismiss={this.onDismiss}
                                                            disableValue={btnDisable}
                                                        />
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">{errorSubCategoryId}</span>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label for="exampleInputEmail1">Select Course*</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    handleChange={this.pageSelect}
                                                    options={pageOptions}
                                                    selectedService={pageSelected}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>

                                        {/* {
                    this.state.customerId==679&&
                    (
                      <div>
                        <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Select Department</label>
                      </div>
                      <div className="col-sm-5">
                        {console.log(userOptions)}
                        <SingleSelect
                           handleChange={this.userSelect}
                           options={userOptions}
                           selectedService={deptSelected}
                        />
                        
                      </div>
                      <div className="col-sm-3" />
                    </div>
                      </div>
                    )
                  }   */}
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">{errorPageId}</span>
                                        </div>
                                        {(true) && (
                                            <div>
                                                <div className="row form-group">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label for="exampleInputEmail1">Select Language</label>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <SingleSelect
                                                            handleChange={(e) => this.handleSelect('language', e)}
                                                            options={langOptions}
                                                            selectedService={language}
                                                        />
                                                    </div>
                                                    <div className="col-sm-3" />
                                                </div>
                                                {/* <div className="row form-group">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label for="exampleInputEmail1">Select Rules</label>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <SingleSelect
                                                            handleChange={(e) => this.handleSelect('Rulesvalue', e)}
                                                            options={this.state.Ruleslist}
                                                            selectedService={this.state.Rulesvalue}
                                                        />
                                                    </div>
                                                    <div className="col-sm-3" />
                                                </div>
                                                <div className="row form-group">
                                                    <div className="col-sm-4" />
                                                    <span className="error-show ">{this.state.errorLang}</span>
                                                </div> */}
                                                {/* <div className="row form-group">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label for="exampleInputEmail1">Select User*</label>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <MultiSelect
                                                            options={UserOption}
                                                            handleChange={(e) => this.handleCheck('User', e)}
                                                            selectedService={this.state.User}
                                                        />
                                                    </div>
                                                    <div className="col-sm-3" />
                                                </div> */}
                                                {/* <div className="row form-group">
                                                    <div className="col-sm-4" />
                                                    <span className="error-show ">{this.state.errorUserType}</span>
                                                </div> */}
                                            </div>

                                        )}
                                        {/* {this.state.User && this.state.User.filter(e => e.label == 'EMPLOYEE').length ?
                                            (<div>
                                                <div className="row form-group">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label for="exampleInputEmail1">Select Employee*</label>
                                                    </div>

                                                    {this.state.csvUpdate ?
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="customFile"
                                                                accept='.csv'
                                                                // value={this.state.csv}
                                                                onChange={this.fileupload}
                                                            />
                                                            <label
                                                                className="custom-file-label"
                                                                htmlFor="customFile"
                                                            >
                                                                {this.state.file ? this.state.file.name : 'Choose File'}
                                                            </label>
                                                        </div>
                                                        :
                                                        <div className="col-sm-5"><MultiSelect
                                                            options={EmpOption}
                                                            handleChange={(e) => this.handleCheck('SelectedEmployee', e)}
                                                            selectedService={this.state.SelectedEmployee}
                                                        />
                                                        </div>}

                                                    <div className="col-sm-3" >
                                                        <input
                                                            type="checkbox"
                                                            name="Checked"
                                                            onChange={() => this.setState({ csvUpdate: !this.state.csvUpdate })}
                                                            checked={this.state.csvUpdate}
                                                        />
                                                        <label>{" "} &nbsp; CSV Upload</label>
                                                    </div>
                                                </div>
                                                {this.state.csvUpdate ? <div className="row form-group">
                                                    <div className="col-sm-4" />
                                                    <div className="col-md-5">
                                                        <a href="../../files/Employee.csv" download="Employee.csv">
                                                            download sample.csv
                                                        </a>
                                                    </div>
                                                </div> : null}
                                                <div className="row form-group">
                                                    <div className="col-sm-4" />
                                                    <span className="error-show ">{this.state.errorEmp}</span>
                                                </div>
                                            </div>) : null
                                        } */}

                                        {/* <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {(updateorsubmit === "Submit" || !this.state.csvUpdate) ? null : 'Note: Old Mapped Employees will be unmapped'}
                                            </span>
                                        </div> */}
                                        {/* <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.csvUpdate ? 'MODE: CSV UPLOAD' : null}</span>
                                        </div> */}
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2" />
                                            <div className="col-sm-5">
                                                {updateorsubmit === "Submit" ? (
                                                    <button
                                                        type="button"
                                                        class="btn btn-primary"
                                                        onClick={this.submitCategory}
                                                        disabled={formBtn}
                                                    >
                                                        {updateorsubmit}
                                                    </button>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        class="btn btn-primary"
                                                        onClick={this.updateMaster}
                                                        disabled={formBtn}
                                                    >
                                                        {updateorsubmit}
                                                    </button>
                                                )}
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row d-flex justify-content-end">
                                            <button
                                                type="button"
                                                className="btn btn-warning"
                                                onClick={this.reset}
                                            >Reset</button>
                                            <div className="col-sm-3" />
                                        </div>
                                        <br></br>
                                        {/* {this.state.excel && this.state.excel.length > 0 ? (
                                            <PDFDownloadLink
                                                style={{ color: "white" }}
                                                document={
                                                    <CMSCategorypdf
                                                        title={this.state.title}
                                                        header={this.state.header}
                                                        data={this.state.excel}
                                                    />
                                                }
                                                fileName="Mappagetocategory.pdf"
                                            >
                                                {({ blob, url, loading, error }) =>
                                                    loading ? "Loading document..." : "Download Pdf"
                                                }
                                                <button className="btn btn-warning">PDF</button>
                                            </PDFDownloadLink>
                                        ) : null}
                                        {this.state.data && this.state.data.length ? (
                                            <CSVLink
                                                data={this.state.data}
                                                headers={this.state.excelHead}
                                            >
                                                <button
                                                    className="btn btn-success"
                                                    style={{ marginLeft: "5px" }}
                                                >
                                                    Excel
                                                </button>
                                            </CSVLink>
                                        ) : null} */}
                                        <br></br>
                                        <br></br>

                                        {loading ? <div className="text-center">
                                            < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                        </div> : data && this.state.customerId == 679 ? (
                                            <Datatable data={data} columnHeading={this.column1} />
                                        ) : (<Datatable data={data} columnHeading={this.column} />)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default CmdlCategory;
