import React from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Progress from "../../../../components/Extra/Progress";
import SingleSelect from "../../../../components/Form/SingleSelect";

class Type2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      button: this.props.button,
      customerId: localStorage.getItem("userId"),
      overlayOption: [
        { label: "Quiz", value: "Quiz" },
        { label: "Survey", value: "Survey" },
        { label: "Ad Banner", value: "Ad Banner" },
        { label: "Load Url", value: "Load Url" }
      ],
      overlayColor: [
        {
          rgb: {
            r: "241",
            g: "112",
            b: "19",
            a: "1"
          },
          hex: `#f17013`
        }
      ],
      getQuizQuestion: [],
      appendOverlayList: [],
      appendOverlayData: [],
      displayColorPicker: [],
      timelineCheckSS: []
    };
  }

  getQuiz = async () => {
    try {
      let getQuizQuestion = await CmsContent.getFreedom(
        "quizName as label,id as value",
        "tbl_question",
        `customerid=${this.state.customerId}`
      );
      this.setState({ getQuizQuestion: getQuizQuestion.data });
    } catch (error) {
      console.error(error);
    }
  };
  appendOverlayList = () => {
    this.getQuiz();
    const { appendOverlayList } = this.state;
    appendOverlayList.push({
      overlayName: null,
      overlayType: null,
      duration: null,
      overlayColor: [
        {
          rgb: {
            r: "241",
            g: "112",
            b: "19",
            a: "1"
          },
          hex: `#f17013`
        }
      ],
      quizId: null,
      bannerImage: null,
      bannerTargetUrl: null,
      loadUrl: null,
      width: null,
      height: null
    });
    this.setState(appendOverlayList);
  };
  showOverLay = appendData => {
    console.log(appendData);
    let appendOverlayList = this.state.appendOverlayList;
    return appendOverlayList.map((appendOverlayList, index) => {
      return (
        <div className="quiz-align my-3">
          <div className="row form-group mt-3">
            <div className="col-sm-2" />
            <div className="col-sm-2">
              <label htmlFor="exampleInputEmail1">Overlay Name</label>
            </div>
            <div className="col-sm-5">
              <input
                type="text"
                name={`overlayName${index}`}
                placeholder="Enter overlay Name"
                className="form-control"
                onChange={e => this.json_refill(e, `overlayName`, index)}
              />
            </div>
            <div className="col-sm-3">
              <i
                class="fa fa-times-circle"
                style={{ fontSize: "25px" }}
                onClick={() => this.removeAppend(index)}
              ></i>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-sm-2" />
            <div className="col-sm-2">
              <label htmlFor="exampleInputEmail1">Overlay Type</label>
            </div>
            <div className="col-sm-5">
              <SingleSelect
                name={`overlayType${index}`}
                options={this.state.overlayOption}
                handleChange={e => this.appendOverlayData(e, index)}
              />
            </div>
            <div className="col-sm-3" />
          </div>
          {appendData && appendData[index]}
        </div>
      );
    });
  };

  removeAppend = index => {
    const { appendOverlayList, appendOverlayData } = this.state;
    appendOverlayList.splice(index, 1);
    appendOverlayData.splice(index, 1);
    this.setState({ appendOverlayList, appendOverlayData });
  };
  json_refill = (e, type, index, l = true) => {
    const { appendOverlayList } = this.state;
    appendOverlayList[index][type] = l ? e.target.value : e;
    this.setState({ appendOverlayList });
  };

  appendOverlayData = (appendValue, index) => {
    const { appendOverlayData, appendOverlayList } = this.state;
    const appendJson = {
      index,
      appendValue: appendValue.label
    };
    let indexValue = appendOverlayData[index];
    if (indexValue) {
      if (appendOverlayData.length > index) {
        if (parseInt(indexValue.index) !== parseInt(index)) {
          appendOverlayData.splice(indexValue.index, 1);
        }
      }
      if (parseInt(indexValue.index) === parseInt(index)) {
        appendOverlayData.splice(index, 1);
      }
    }
    appendOverlayData.splice(index, 0, appendJson);
    let appendOverlayListIndex = appendOverlayList[index];
    appendOverlayListIndex.overlayType = appendOverlayData[index].appendValue;
    appendOverlayListIndex.surveyUrl = null;
    appendOverlayListIndex.loadUrl = null;
    appendOverlayListIndex.bannerTargetUrl = null;
    appendOverlayListIndex.quizId = null;
    appendOverlayListIndex.width = null;
    appendOverlayListIndex.height = null;
    appendOverlayListIndex.bannerImage = null;
    this.setState({ appendOverlayData, appendOverlayList });
  };

  render() {
    const {
      boxfilename,
      errorfile1,
      uploadPercentage,
      quizSelect,
      quizOptions,
      formSelect,
      formOptions,
      appendOverlayList,
      appendOverlayData,
      selectSurvey,
      SurveyList
    } = this.state;

    let appendData = [];

    appendOverlayData.map((appendOverlayData, index) => {
      let rgb = {
        r: "241",
        g: "112",
        b: "19",
        a: "1"
      };
      if (this.state.overlayColor[index]) {
        rgb = this.state.overlayColor[index].rgb;
      }
      const styles = reactCSS({
        default: {
          color: {
            width: "36px",
            height: "14px",
            borderRadius: "2px",
            background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
          },
          swatch: {
            padding: "5px",
            background: "#fff",
            borderRadius: "1px",
            boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
            display: "inline-block",
            cursor: "pointer"
          },
          popover: {
            position: "absolute",
            zIndex: "2"
          },
          cover: {
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px"
          }
        }
      });
      let hoursSlect = [];
      for (let i = 0; i <= 12; i++) {
        hoursSlect.push(<option>{i <= 9 ? `0${i}` : i}</option>);
      }
      let minuteSlect = [];
      for (let i = 0; i <= 60; i++) {
        minuteSlect.push(<option>{i <= 9 ? `0${i}` : i}</option>);
      }
      let secondsSlect = [];
      for (let i = 0; i <= 60; i++) {
        secondsSlect.push(<option>{i <= 9 ? `0${i}` : i}</option>);
      }

      appendData.push(
        <div>
          {appendOverlayData.appendValue === "Load Url" && (
            <div className="row form-group">
              <div className="col-sm-2" />
              <div className="col-sm-2">
                <label htmlFor="exampleInputEmail1">Destination URL</label>
              </div>
              <div className="col-sm-5">
                <input
                  type="text"
                  name={`overlayName${appendOverlayData.index}`}
                  placeholder="Enter Url"
                  className="form-control"
                  onChange={e => this.json_refill(e, `loadUrl`, index)}
                />
              </div>
              <div className="col-sm-3" />
            </div>
          )}
          {appendOverlayData.appendValue === "Survey" && (
            <div className="row form-group">
              <div className="col-sm-2" />
              <div className="col-sm-2">
                <label htmlFor="exampleInputEmail1">Select Survey</label>
              </div>
              <div className="col-sm-5">
                <SingleSelect
                  name="survey"
                  options={this.state.SurveyList}
                  handleChange={e => {
                    this.setState({ selectSurvey: e });
                    this.json_refill(e.value, `surveyUrl`, index, false);
                  }}
                  selected={selectSurvey}
                />
              </div>
              <div className="col-sm-3" />
            </div>
          )}
          {appendOverlayData.appendValue === "Ad Banner" && (
            <div>
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">Upload Image</label>
                </div>
                <div className="col-sm-5 custom-file">
                  <input
                    type="file"
                    className="custom-file-input "
                    id="customFile"
                    onChange={e => this.addBanner(e, index)}
                    accept="image/*"
                  />
                  <label
                    className="custom-file-label lblcross"
                    htmlFor="customFile"
                  >
                    {this.state.addBannerName}
                  </label>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">Target Url</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    name={`overlayImageTargetUrl${appendOverlayData.index}`}
                    placeholder="Enter Target Url"
                    className="form-control"
                    onChange={e =>
                      this.json_refill(e, `bannerTargetUrl`, index)
                    }
                  />
                </div>
                <div className="col-sm-3" />
              </div>
            </div>
          )}
          {appendOverlayData.appendValue === "Quiz" && (
            <div>
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">Select Evaluation</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect
                    name="quiz"
                    options={this.state.getQuizQuestion}
                    handleChange={e =>
                      this.appendOverlayQuiz(e, "quizId", index)
                    }
                  />
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-1 px-0">
                  <label htmlFor="exampleInputEmail1">Width %</label>
                </div>
                <div className="col-sm-2">
                  <input
                    type="number"
                    name={`width${appendOverlayData.index}`}
                    placeholder="width"
                    className="form-control"
                    onChange={e => this.json_refill(e, `width`, index)}
                  />
                </div>
                <div className="col-sm-1" />
                <div className="col-sm-1 px-0">
                  <label htmlFor="exampleInputEmail1">Height %</label>
                </div>
                <div className="col-sm-2">
                  <input
                    type="number"
                    name={`height${appendOverlayData.index}`}
                    placeholder="width"
                    className="form-control"
                    onChange={e => this.json_refill(e, `height`, index)}
                  />
                </div>
                <div className="col-sm-3" />
              </div>
            </div>
          )}
          <div className="row form-group">
            <div className="col-sm-2" />
            <div className="col-sm-2">
              <label htmlFor="exampleInputEmail1">OverLay Preamble</label>
            </div>
            <div className="col-sm-5">
              <input
                type="test"
                name={`overlayPreamble${appendOverlayData.index}`}
                placeholder="Overlay Preamble"
                className="form-control"
                onChange={e => this.json_refill(e, `overlayPreamble`, index)}
              />
            </div>
            <div className="col-sm-3" />
          </div>
          <div className="row form-group">
            <div className="col-sm-2" />
            <div className="col-sm-2">
              <label htmlFor="exampleInputEmail1">OverLay Color</label>
            </div>
            <div className="col-sm-5">
              <div style={styles.swatch} onClick={e => this.handleClick(index)}>
                <div style={styles.color} />
              </div>
              {this.state.displayColorPicker[index] ? (
                <div style={styles.popover}>
                  <div
                    style={styles.cover}
                    onClick={e => this.handleClose(index)}
                  />
                  <SketchPicker
                    color={rgb}
                    onChange={e => this.addOverlayColor(e, index)}
                  />
                </div>
              ) : null}
            </div>
            <div className="col-sm-3" />
          </div>
          <div className="row form-group">
            <div className="col-sm-2" />
            <div className="col-sm-1">
              <label className="duration-float" htmlFor="exampleInputEmail1">
                hh
              </label>
            </div>
            <div class="col-sm-2">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                onChange={e => this.timelineJson(e, `hh`, index)}
              >
                {hoursSlect}
              </select>
            </div>
            <div className="col-sm-1">
              <label className="duration-float" htmlFor="exampleInputEmail1">
                mm
              </label>
            </div>
            <div class="col-sm-2">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                onChange={e => this.timelineJson(e, `mm`, index)}
              >
                {minuteSlect}
              </select>
            </div>
            <div className="col-sm-1">
              <label className="duration-float" htmlFor="exampleInputEmail1">
                ss
              </label>
            </div>
            <div class="col-sm-2">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                onChange={e => this.timelineJson(e, `ss`, index)}
              >
                {secondsSlect}
              </select>
            </div>
            {/* <div className="col-sm-5">
            <input type="text" name={`overlayName${appendOverlayData.index}`} placeholder="Enter Duration" className="form-control" onChange={e => this.json_refill(e, `duration`, index)} />
          </div> */}
            <div className="col-sm-3" />
          </div>
        </div>
      );
      console.log(appendData);
    });

    return (
      <React.Fragment>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">Upload Video</label>
          </div>
          <div className="col-sm-5">
            <input
              type="file"
              id="image"
              accept="video/*"
              onChange={this.image}
            />
            <label className="custom-file-label lblcross" htmlFor="image">
              {boxfilename}
            </label>
            <br />
            <br />
            <span className="error-shows ">{errorfile1}</span>
          </div>
          {/* <div className="col-sm-3">
                              <button type="button" className="btn btn-danger btn-radius" onClick={() => this.appendOverlayList()}>
                                Add Overlay
                              </button>
                            </div> */}
        </div>
        <div className="row form-group">
          <div className="col-sm-4" />
          <div className="col-sm-3">
            <button
              type="button"
              className="btn btn-primary"
              onClick={
                this.state.button === "Add" ? this.addtext : this.onSubmit
              }
            >
              {this.state.button}
            </button>
          </div>
          <div className="col-sm-3">
            <button
              type="button"
              className="btn btn-danger btn-radius"
              onClick={() => this.appendOverlayList()}
            >
              Add Overlay
            </button>
          </div>
          <div className="col-sm-2" />
        </div>

        {this.showOverLay(appendData)}
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-7">
            {uploadPercentage > 0 && <Progress percentage={uploadPercentage} />}
          </div>
          <div className="col-sm-3" />
        </div>
      </React.Fragment>
    );
  }
}

export default Type2;
