import React from 'react';
import SingleSelect from "../../components/Form/SingleSelect";
import { Alert } from 'reactstrap';

const AddQues = ({ category, Checklist, allchecked, CheckBox,answer, type, checked, AnsOPTIONS, Characteristicsselect, handleChange, addNewCategory, error, alert, dismiss, disableValue, alertMsg }) => {

    let ans = []
    Checklist.map((ival, i) => {
        ans.push(
            <div>
                <div className="row form-group">
                    {/* <div className="col-sm-3">
                    <label for="exampleInputEmail1">User Type</label>
                </div> */}

                    <div style={{ padding: 10 }}
                    // {/* className="col-sm-2" */}
                    >
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="ShowChecked"
                                style={{}}
                                onClick={() => CheckBox(ival.value)}
                                checked={checked[ival.value]}
                            />
                            <label onClick={() => CheckBox(ival.value)} class="form-check-label" for="materialChecked">
                                {i + 1} ) {ival.label}
                            </label>
                        </div>
                    </div>
                </div>
                {!type && <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                        <label for="exampleInputEmail1">Answer</label>
                    </div>
                    <div className="col-sm-5">
                        <SingleSelect
                            options={AnsOPTIONS}
                            // disabled={disableReportTo}
                            handleChange={(v) => Characteristicsselect(v, "answer1", ival.value)}
                            selectedService={answer}
                            placeholder={"Select..."}
                        />
                    </div>
                    <div className="col-sm-3" />
                </div>}
            </div>


        )
    })

    return (
        <React.Fragment>
            <div style={{ padding: 5, height: 400, overflowY: "scroll" }}>
                {/* <Alert className="badge-content" color="success" isOpen={alert} toggle={dismiss}>
                    {alertMsg}
                </Alert> */}
                <div className="row form-group">
                    {/* <div className="col-sm-3">
                    <label for="exampleInputEmail1">User Type</label>
                </div> */}

                    <div style={{ padding: 10 }}
                    // {/* className="col-sm-2" */}
                    >
                        <div class="form-check">
                            <input
                                type="checkbox"
                                class="form-check-input"
                                id="ShowChecked"
                                style={{}}
                                onClick={() => CheckBox("end")}
                                checked={allchecked}
                            />
                            <label class="form-check-label" for="materialChecked">
                                Select All
                        </label>
                        </div>
                    </div>

                </div>
                {ans}
            </div>
        </React.Fragment>
    );
};

export default AddQues;

