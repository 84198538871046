import React from "react";
import { Alert } from "reactstrap";
import Datatable from "../../../components/Datatable/Datatable";

const AddLibaryModel = ({
  selectImage,
  fileName,
  handleChange,
  dismiss,
  alertVisible,
  disableValue,
  alertMsg,
  submit,
  List,
  column
}) => {
  return (
    <React.Fragment>
      <div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-7">
            <Alert
              className="badge-content"
              color="success"
              isOpen={alertVisible}
              toggle={dismiss}
            >
              File Uploaded Successfully
            </Alert>
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label for="exampleInputEmail1">Upload File</label>
          </div>
          <div className="col-sm-5 custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="customFile"
              onChange={selectImage}
            />
            <label className="custom-file-label" htmlFor="customFile">
              {fileName}
            </label>
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-4" />
          {/* <span className="error-show ">{errorFile}</span> */}
        </div>

        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label for="exampleInputEmail1">Search Hint</label>
          </div>
          <div className="col-sm-5 custom-file">
            <input
              type="text"
              name="searchhint"
              placeholder="Enter  hint"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-4" />
          {/* <span className="error-show ">{errorHint}</span> */}
        </div>

        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2" />
          <div className="col-sm-5">
            <button
              type="button"
              class="btn btn-primary"
              //   disabled={btnDisable}
              onClick={submit}
            >
              Submit
            </button>
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-12">
            {List ? <Datatable data={List} columnHeading={column} /> : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddLibaryModel;
