import React from 'react';

// const CustomEvent = ({ event }) => (
//     <div style={{ textWrap: "wrap" }}>
//         <span style={{ fontSize: "18px", fontWeight: "600" }}> {event.schoolname ? event.schoolname + ' - ' : ""} </span>  <span>{event.title}</span>

//         <br />
//     </div>
// );

const CustomEvent = ({ event, view }) => {
    return (
        <div className="rbc-event-content">
            {view === 'week' ? (
                <div style={{ fontSize: "18px", fontWeight: "600" }}>{event.schoolname} <span style={{ fontSize: "14px", fontWeight: "500" }}>{event.venue ? " - " + event.venue : ""}</span></div>
            ) : (
                <>
                    <div style={{ textWrap: "wrap" }}>
                        <span style={{ fontSize: "18px", fontWeight: "600" }}> {event.schoolname ? event.schoolname + ' - ' : ""} </span>  <span style={{ fontSize: "18px", fontWeight: "400" }}> {event.department ? event.department + ' - ' : ""} </span>  <span>{event.title}</span><span style={{ fontSize: "14px", fontWeight: "500" }}>{event.venue ? " - " + event.venue : ""}</span>
                        <br />
                    </div>
                </>
            )}
        </div>
    );
};

export default CustomEvent;
