import React, { Component } from 'react';
import { objectTracking, objectEncoding, videoEncoding, imageUpload, jsonDataRead } from '../../../MiddleWare/PythonContent';

class VideoWizard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDuration: null,
      toDuration: null,
      objectName: null,
      errorFromDuration: null,
      errorToDuration: null,
      errorObjectName: null,
      videoFile: null,
      videoFileName: null,
      errorVideoFile: null,
      userId: null,
      wizardNo: 1,
      videoEncodingFileName: null,
      videoEncodingFile: null,
      errorVideoEncodingFile: null,
      imageCount: [1],
      images: [{ imageFile: null, imageFileName: null, errorImage: null }],
      objectNameImage: null,
      errorObjectNameImage: null,
      objectButtonDisabled: false,
      imageUploadDisabled: false,
      startEncodingDisabled: false,
      errorAddMoreImage: null,
      customerId: localStorage.getItem("userId")
    };
  }

  componentWillMount() {
    const userId = localStorage.getItem('userId');
    this.setState({ userId });
  }

  changeValue = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  selectVideo = e => {
    if (e.target.files[0]) this.setState({ videoFile: e.target.files[0], videoFileName: e.target.files[0].name });
  };

  submitValue = async () => {
    const { fromDuration, toDuration, objectName, videoFile, userId } = this.state;
    if (!fromDuration) {
      this.setState({
        errorFromDuration: `please fill from duration`
      });
      return;
    } else {
      this.setState({
        errorFromDuration: null
      });
    }
    if (!toDuration) {
      this.setState({
        errorToDuration: `please fill to duration`
      });
      return;
    } else {
      this.setState({
        errorToDuration: null
      });
    }
    if (!objectName) {
      this.setState({
        errorObjectName: `please fill object name`
      });
      return;
    } else {
      this.setState({
        errorObjectName: null
      });
    }
    if (!videoFile) {
      this.setState({
        errorVideoFile: `please select video file`
      });
      return;
    } else {
      this.setState({
        errorVideoFile: null
      });
    }
    const formData = new FormData();
    formData.append('fromDuration', fromDuration);
    formData.append('toDuration', toDuration);
    formData.append('objectName', objectName);
    formData.append('videoFile', videoFile);
    formData.append('userId', userId);
    try {
      this.setState({
        objectButtonDisabled: true
      });
      const result = await objectTracking(formData);
      if (result.data) {
        this.setState({
          videoFile: null,
          videoFileName: null,
          fromDuration: '',
          toDuration: '',
          objectName: '',
          objectButtonDisabled: false
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  addImage = async () => {
    let imageCount = this.state.imageCount;
    let imageCountLength = imageCount.length;
    if (imageCountLength < 10) {
      imageCount.push(1);
      this.setState({
        imageCount
      });
    } else {
      this.setState({
        errorAddMoreImage: 'Allowed max of 10 images'
      });
    }
  };

  selectFile = (e, index) => {
    const images = [...this.state.images];
    images[index] = { imageFile: e.target.files[0], imageFileName: e.target.files[0].name };
    this.setState({
      images
    });
  };

  imageUpload = async () => {
    if (!this.state.objectNameImage) {
      this.setState({
        errorObjectNameImage: 'Please enter object name'
      });
      return;
    } else {
      this.setState({
        errorObjectNameImage: null
      });
    }
    const formData = new FormData();
    formData.append('userId', this.state.userId);
    formData.append('objectName', this.state.objectNameImage);
    this.state.images.map((value, index) => {
      if (value.imageFile) {
        formData.append(`videoFile_${index + 1}`, value.imageFile);
      }
      return null;
    });
    try {
      this.setState({
        imageUploadDisabled: true
      });
      const result = await imageUpload(formData);
      if (result.data) {
        this.setState({
          imageCount: [1],
          images: [{ imageFile: null, imageFileName: null, errorImage: null }],
          objectNameImage: '',
          imageUploadDisabled: false,
          errorAddMoreImage: null
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  startEncoding = async () => {
    const formData = new FormData();
    formData.append('userId', this.state.userId);
    try {
      this.setState({
        startEncodingDisabled: true
      });
      const result = await objectEncoding(formData);
     
      if (result.data) {
        this.setState({
          startEncodingDisabled: false
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  selectVideoEncoding = e => {
    if (e.target.files[0]) this.setState({ videoEncodingFile: e.target.files[0], videoEncodingFileName: e.target.files[0].name });
  };

  startEncodingVideo = async () => {
    if (!this.state.videoEncodingFile) {
      this.setState({
        errorVideoEncodingFile: `please select video file`
      });
      return;
    } else {
      this.setState({
        errorVideoEncodingFile: null
      });
    }
    const formData = new FormData();
    formData.append('userId', this.state.userId);
    formData.append('videoEncodingFile', this.state.videoEncodingFile);
    try {
      this.setState({
        startEncodingVideoDisabled: true
      });
      const result = await videoEncoding(formData);
      if (result.data) {
        const formData = new FormData();
        formData.append('timestamp', result.data[0].timestamp);
        const jsonData = await jsonDataRead(formData);
        if (jsonData.data) {
          this.setState({
            videoEncodingFile: null,
            videoEncodingFileName: null,
            startEncodingVideoDisabled: false
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  nextButton = () => {
    const { wizardNo } = this.state;
    if (parseInt(wizardNo) < 4) {
      this.setState({
        wizardNo: parseInt(wizardNo) + 1
      });
    }
  };

  previousButton = () => {
    const { wizardNo } = this.state;
    if (parseInt(wizardNo) !== 1) {
      this.setState({
        wizardNo: parseInt(wizardNo) - 1
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Video Object Tracking</h1>
                  </div>
                  <div className="card-body">
                    {this.state.wizardNo === 1 && (
                      <React.Fragment>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">From Duration</label>
                          </div>
                          <div className="col-sm-5">
                            <input type="text" name="fromDuration" placeholder="Enter From Duration" className="form-control" onChange={this.changeValue} value={this.state.fromDuration} />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">{this.state.errorFromDuration}</span>
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">To Duration</label>
                          </div>
                          <div className="col-sm-5">
                            <input type="text" name="toDuration" placeholder="Enter To Duration" className="form-control" onChange={this.changeValue} value={this.state.toDuration} />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">{this.state.errorToDuration}</span>
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">Object Name</label>
                          </div>
                          <div className="col-sm-5">
                            <input type="text" name="objectName" placeholder="Enter Object Name" className="form-control" onChange={this.changeValue} value={this.state.objectName} />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">{this.state.errorObjectName}</span>
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">Upload Video</label>
                          </div>
                          <div className="col-sm-5">
                            <input type="file" className="custom-file-input" id="videoFile" onChange={this.selectVideo} />
                            <label className="custom-file-label" htmlFor="customFileThumbnail">
                              {this.state.videoFileName}
                            </label>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">{this.state.errorVideoFile}</span>
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <button type="button" className="btn btn-primary" onClick={this.submitValue} disabled={this.state.objectButtonDisabled}>
                              Add Object
                            </button>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                      </React.Fragment>
                    )}

                    {this.state.wizardNo === 2 && (
                      <React.Fragment>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">Object Name</label>
                          </div>
                          <div className="col-sm-5">
                            <input type="text" name="objectNameImage" placeholder="Enter Object Name" className="form-control" onChange={this.changeValue} value={this.state.objectNameImage} />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">{this.state.errorObjectNameImage}</span>
                        </div>
                        {this.state.imageCount.map((value, index) => {
                          return (
                            <React.Fragment>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="exampleInputEmail1">Upload Image {index + 1}</label>
                                </div>
                                <div className="col-sm-5">
                                  <input type="file" className="custom-file-input" id={`videoFileEncoding${index + 1}`} onChange={e => this.selectFile(e, index)} />
                                  <label className="custom-file-label" htmlFor="customFileThumbnail">
                                    {this.state.images[index] && this.state.images[index].imageFileName}
                                  </label>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4" />
                                {/* <span className="error-show ">{this.state.images[index].errorImage}</span> */}
                              </div>
                            </React.Fragment>
                          );
                        })}
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <button type="button" className="btn btn-primary" onClick={this.addImage}>
                              Add More Image
                            </button>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">{this.state.errorAddMoreImage}</span>
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <button type="button" className="btn btn-primary" onClick={this.imageUpload} disabled={this.state.imageUploadDisabled}>
                              Image Upload
                            </button>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                      </React.Fragment>
                    )}

                    {this.state.wizardNo === 3 && (
                      <React.Fragment>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <button type="button" className="btn btn-primary" onClick={this.startEncoding} disabled={this.state.startEncodingDisabled}>
                              Start Encoding
                            </button>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                      </React.Fragment>
                    )}

                    {this.state.wizardNo === 4 && (
                      <React.Fragment>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">Upload Video</label>
                          </div>
                          <div className="col-sm-5">
                            <input type="file" className="custom-file-input" id="videoFileEncoding" onChange={this.selectVideoEncoding} />
                            <label className="custom-file-label" htmlFor="customFileThumbnail">
                              {this.state.videoEncodingFileName}
                            </label>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">{this.state.errorVideoEncodingFile}</span>
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <button type="button" className="btn btn-primary" onClick={this.startEncodingVideo} disabled={this.state.startEncodingVideoDisabled}>
                              Start Encoding Video
                            </button>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                      </React.Fragment>
                    )}

                    <div className="row form-group">
                      <div className="col-sm-3 ">
                        {this.state.wizardNo !== 1 && (
                          <button
                            type="button"
                            className="btn btn-primary justify-content-center"
                            onClick={this.previousButton}
                            disabled={this.state.objectButtonDisabled || this.state.imageUploadDisabled || this.state.startEncodingDisabled || this.state.startEncodingVideoDisabled}
                          >
                            Previous Button
                          </button>
                        )}
                      </div>
                      <div className="col-sm-6" />
                      <div className="col-sm-3">
                        {this.state.wizardNo !== 4 && (
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.nextButton}
                            disabled={this.state.objectButtonDisabled || this.state.imageUploadDisabled || this.state.startEncodingDisabled || this.state.startEncodingVideoDisabled}
                          >
                            Next Button
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default VideoWizard;
