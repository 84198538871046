import React, { Component } from "react";
import cmsContent from "../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import { Alert } from "reactstrap";
import Datatable from "../../../components/Datatable/Datatable";

import LoginModal from "../../../components/Modal/Modal";
class SurveyPage extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      quizName: "",
      pagelimit: "",
      title: "",
      description: "",
      customerId: localStorage.getItem("userId"),
      alertVisible: false,
      send_json: [],
      spinner: false,
      surveytitle: []
    };
  }
  async componentWillMount() {
    try {
      // }
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1;
      let day = dateObj.getUTCDate();
      let year = dateObj.getUTCFullYear();
      let newdate = year + "-" + month + "-" + day;
      this.setState({ newdate });
      const question = await cmsContent.getTwoConditionedValue("tbl_survey", "customerid", this.state.customerId, 1, 1);
      if (question) {
        console.log(question);
        this.setState({ data: question.data });
      }



    } catch (error) {
      console.log(error);
    }
  }
  column = [
    {
      Header: "Survey Name",
      accessor: "surveyname"
    },
    {
      Header: "Page Limit",
      accessor: "pagelimit"
    },
    {
      Header: "Title",
      accessor: "pagelimit",
      Cell: (e) => this.title(e)
    },

  ];
  heading = [
    {
      Header: "Title",
      accessor: "title"
    },
    {
      Header: "Descriptipon",
      accessor: "description"
    },


  ];
  title = (e) => {
    // console.log(e.original);
    const { surveytitle, spinner } = this.state;
    return (
      <div className="row form-group">
        <div className="col-sm-2" />
        <div className="col-sm-2">
          <LoginModal
            buttonTitle="Title"
            title="Title"
            id="title"
            extraClass="btn btn-primary"
            onClick={() => this.gettitle(e.original.id)}
            bodyText={
              <div>
                {
                  spinner && <div class="loader" />
                }
                {
                  surveytitle && surveytitle.length > 0 ?
                    <Datatable columnHeading={this.heading} data={surveytitle} /> :
                    <div >No data found </div>

                }
              </div>
            }
          />
        </div>
        <div className="col-sm-5" />
        {/* {this.state.title} */}
      </div>
    )
  }
  gettitle = async id => {
    this.setState({ spinner: true, surveytitle: [] })
    const surveytitle = await cmsContent.getTwoConditionedValue("tbl_surveyTitle", "surveyId", id, 1, 1);
    if (surveytitle) {
      console.log(surveytitle);
      this.setState({ surveytitle: surveytitle.data, spinner: false });
    }
  }
  limit = e => {
    this.setState({ limit: e.target.value });
  };
  addSingleChoice = () => {
    let { send_json } = this.state;
    let count = this.state.limit;
    for (var i = 0; i < count; i++) {
      send_json.push({
        question: null,
        title: null,
        description: null
      });
      this.setState({ send_json });
      this.bacfun();
    }
  };

  bacfun = () => {
    this.setState({ show: true });
    let count = this.state.limit;
    let title = [];
    // for (var j = 0; j < count; j++) {
    this.state.send_json.map((item, i) => {
      console.log(i);
      title.push(
        <div>
          <div className="row form-group">
            <div className="col-sm-2">
              <label for="exampleInputEmail1">Title {i + 1}</label>
            </div>
            <div className="col-sm-2" />
            <div className="col-sm-5">
              <input
                type="text"
                class="form-control"
                name="quizName"
                placeholder="Enter Title"
                onChange={e => {
                  this.JSON_refill(e.target.value, "title", i);
                }}
              // value={title}
              />
            </div>
            <div className="col-sm-3" />
          </div>
          <div className="row form-group">
            <div className="col-sm-2">
              <label for="exampleInputEmail1">Description {i + 1}</label>
            </div>
            <div className="col-sm-2" />
            <div className="col-sm-8">
              <input
                type="text"
                class="form-control"
                placeholder="Enter description"
                onChange={e => {
                  this.JSON_refill(e.target.value, "description", i);
                }}
              />
            </div>
          </div>
          <br />
        </div>
      );
      // }
    });
    this.setState({ title });
  };

  handlechange = e => {
    if (e.target.name === "optionCount") {
      this.setState({ optionJson: [] });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  JSON_refill = (quiz_value, quiz_name, index) => {
    let send_json = this.state.send_json;

    var JSON_data = send_json[index];
    JSON_data[quiz_name] = quiz_value;
    send_json[index] = JSON_data;

    this.setState({ send_json: send_json });
  };

  submit = async () => {
    const { send_json, quizName, customerId, limit, newdate } = this.state;
    console.log(limit);
    if (quizName == null || quizName == undefined || quizName == "") {
      this.setState({ error: "Please fill Survey Name" });
      return false;
    } else if (limit == null || limit == undefined || limit == "") {
      this.setState({ error: "", errorlimit: "Choose page limit" });
      return false;
    } else {
      this.setState({ errorlimit: " " });
    }
    let jsonContent = {};
    jsonContent.surveyname = quizName;
    jsonContent.customerid = customerId;
    jsonContent.pagelimit = limit;
    jsonContent.Titlejson = send_json;
    jsonContent.createdAt = newdate;
    try {
      const result = await cmsContent.addSurveyContent(jsonContent);
      if (result) {
        this.setState({
          quizName: "",
          limit: "",
          send_json: [],
          title: [],
          alertVisible: true
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const { quizName, alertVisible, limit, error, errorlimit } = this.state;

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h1>Survey</h1>
              </div>
              <div className="card-body" />
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-7">
                  <Alert
                    className="badge-content"
                    color="success"
                    isOpen={alertVisible}
                    toggle={this.onDismiss}
                  >
                    Survey Added Successfully
                  </Alert>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label for="exampleInputEmail1">Survey Name</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    class="form-control"
                    name="quizName"
                    placeholder="Enter Survey Name"
                    onChange={this.handlechange}
                    value={quizName}
                  />
                  <span className="error-shows">{error}</span>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label for="exampleInputEmail1">Page Limit</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter Page Limit"
                    min="1"
                    onChange={this.limit}
                    value={limit}
                  />
                  <span className="error-shows">{errorlimit}</span>
                </div>
                <div className="col-sm-3" />
              </div>

              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  {/* <button
                    type="button"
                    className="btn btn-primary btn-lg btn-block btn-radius"
                    onClick={() => this.addSingleChoice("multiple")}
                  >
                    Add Multi Choice
                  </button> */}
                </div>

                <div className="col-sm-4">
                  <button
                    type="button"
                    className="btn btn-primary btn-lg btn-block btn-radius"
                    onClick={() => this.addSingleChoice("multiple")}
                  // onClick={() => this.bacfun}
                  // onClick={this.bacfun}
                  >
                    Add Page limt
                  </button>
                </div>

                <div className="col-sm-2" />
              </div>
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-6"> {this.state.title}</div>
                <div className="col-sm-4" />
              </div>
              <div className="row form-group">
                <div className="col-sm-5" />
                <div className="col-sm-2">
                  <button
                    type="button"
                    className="btn btn-success btn-lg btn-radius"
                    onClick={this.submit}
                  >
                    Submit
                  </button>
                </div>
                <div className="col-sm-5" />
                {/* {this.state.title} */}
              </div>
              <div style={{ margin: "12px" }}>
                {this.state.data && (
                  <Datatable data={this.state.data} columnHeading={this.column} />)}
              </div>
            </div>

            {/* multi choice */}

          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default SurveyPage;
