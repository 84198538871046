import React, { Component } from "react";
import cmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import CmsmappingPDF from "../../../components/PDF/CMSMappingpdf";

class LikeReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      customerId: localStorage.getItem("userId"),
    };
  }

  async componentDidMount() {
    try {
      const result = await cmsContent.getLikesLogs();
      if (result) {
        this.setState({ data: result.data });
      }
      if (result.data.length > 0) {
        const header = ["SI.NO", "CATEGORYNAME", "LIKES"];
        const excelHead = [
          { label: "CATEGORYNAME", key: "categoryName" },

          { label: "LIKES", key: "count" },
        ];
        this.setState({
          excelHead,
          header,
          excel: result.data,
          title: "LIKE REPORT",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  column = [
    {
      Header: "Category",
      accessor: "categoryName",
    },
    {
      Header: "Likes",
      accessor: "count",
    },
  ];
  render() {
    const { data } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Likes Report</h1>
                  </div>
                  <div className="card-body">
                    <br></br>
                    {this.state.excel && this.state.excel.length > 0 ? (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <CmsmappingPDF
                            title={this.state.title}
                            header={this.state.header}
                            data={this.state.excel}
                          />
                        }
                        fileName="Mappagetocategory.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    ) : null}
                    {this.state.data && this.state.data.length > 0 ? (
                      <CSVLink
                        data={this.state.data}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          Excel
                        </button>
                      </CSVLink>
                    ) : null}
                    <br></br>
                    <br></br>
                    {data && (
                      <Datatable data={data} columnHeading={this.column} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default LikeReport;
