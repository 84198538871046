import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import SingleSelect from "../../../components/Form/SingleSelect";
import Datatable from "../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import Switch from "react-switch";
import LoginModal from "../../../components/Modal/Modal";
import { Alert } from "reactstrap";
import { async } from "q";
import { confirmAlert } from "react-confirm-alert";
const btoa = require("btoa");

class CohortsView extends FormMiddleWare {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      groupId: "",
      tabledata: [],
      filterData: [],
      // boxsize: [{ value: 'full', label: 'Full' }, { value: 'half', label: 'Half' }, { value: 'carousel', label: 'Carousel' }],
      // boxsize: [{ value: 'full', label: 'full' }, { value: 'half', label: 'half' }],
      boxsize: [
        { value: "full", label: "Full" },
        { value: "half", label: "Half" },
        { value: "double", label: "Double" }
      ],
      boxwidth: [],
      errorsize: null,
      position: null,
      errorposition: null,
      categoryOptions: [],
      searchcategoryOptions: [],
      categorySelected: [],
      searchcategorySelected: [],
      contentOptions: [],
      selectedContent: [],
      tray: false,
      button: "Submit",
      portletTitleError: null,
      editvalue: {},
      carousel: 0,
      showCarLength: false,
      alertVisible: false,
      disableCohorts: false,
      disableValue: false,
      CustomerId: localStorage.getItem("userId")
    };
  }

  getFilterData = async groupId => {
    var filterData = this.state.filterData;
    filterData = filterData.filter(obj => {
      return obj.groupid == groupId;
    });

    this.setState({ tableData: filterData });
  };

  async componentWillMount() {
    this.cohorts();
  }

  cohorts = async () => {
    try {
      const {
        match: { params }
      } = this.props;
      let userlog = JSON.parse(localStorage.getItem("userlog"));
      let companyid = userlog.id;
      let groupId = atob(params["groupId"]);

      this.setState({ groupId });
      let result = await CmsContent.getMasterValues("tbl_boxtype");
      if (result) {
        this.setState({ boxtype: result.data });
      }
      result = await CmsContent.getConditionedValuewithStatus(
        "tbl_group",
        "customerId",
        companyid,
        "id",
        "name"
      ); //
      if (result) {
        this.setState({
          categoryOptions: result.data,
          searchcategoryOptions: result.data
        });
      }

      let groupName = this.getValueFromArray(
        groupId,
        this.state.categoryOptions
      );

      this.setState({ groupName });

      result = await CmsContent.getFreedom(
        "*",
        "tbl_boxcontent",
        `customerId = ${this.state.CustomerId}`,
        "id",
        "id"
      );
      if (result) {
        this.setState({ boxcontent: result.data });
      }
      result = await CmsContent.getAllSubtitleList("tbl_portletname");
      if (result) {
        this.setState({ portletData: result.data });
      }
      //  result = await CmsContent.getSingleConditionedValue( 'tbl_map_grouptoportlet','customerId',this.state.customerId
      // );
      // if (result) {
      //
      //   this.setState({ tableData: result.data, filterData: result.data });
      // }
      result = await CmsContent.getValuebyColumn(
        "tbl_map_grouptoportlet",
        "customerid",
        companyid,
        "groupid",
        groupId
      );
      if (result) {
        let AllContent = [];
        result.data.map((ival, i) => {
          if (
            AllContent.filter(jval => jval.contentid === ival.contentid)
              .length === 0
          ) {
            AllContent.push({
              contentid: ival.contentid
            });
          }
        });

        AllContent.map((ival, i) => {
          AllContent[i].innerContent = [];
          this.state.boxcontent.map((jval, j) => {
            if (jval.name == ival.contentid) {
              AllContent[i].innerContent.push({
                jval
              });
            }
          });
        });
        this.setState({ tabledata: result.data, AllContent });
      }
      // this.getFilterData(groupId);
    } catch (error) {
      console.log(error);
    }
  };
  column = [
    // {
    //   id: 'groupid',
    //   Header: 'Cohorts',
    //   accessor: d => this.getValueFromArray(d.groupid, this.state.categoryOptions),
    //   Cell: d => this.getValueFromArray(d.original.groupid, this.state.categoryOptions)
    // },
    {
      id: "contentid",
      Header: "Portlet Name",
      // accessor: 'contentid',
      accessor: d =>
        this.getValueFromArrays(d.contentid, this.state.portletData),
      Cell: d =>
        this.getValueFromArrays(d.original.contentid, this.state.portletData)
    },
    {
      Header: "Alias",
      accessor: "boxtitle"
    },
    {
      id: "boxtypeid",
      Header: "Portlet Type",
      //accessor: 'boxtypeid',
      accessor: d => this.getValueFromArray(d.boxtypeid, this.state.boxtype),
      Cell: d =>
        this.getValueFromArray(d.original.boxtypeid, this.state.boxtype)
    },

    {
      Header: "Width",
      accessor: "width"
    },
    {
      Header: "Position",
      accessor: "position"
    },
    {
      Header: "Carosuel",
      accessor: "carouselLength"
    },
    {
      Header: "Tray",
      accessor: "tray"
    },
    {
      Header: "View Data",
      accessor: "view",
      Cell: d => this.view(d, "group")
    },
    {
      Header: "Edit Structure",
      accessor: "edit",
      Cell: d => this.edit(d, "group")
    },
    {
      Header: "Status",
      accessor: "edit",
      Cell: d => this.showVisibility(d)
    },
    {
      Header: "Un-Map Portlet",
      accessor: "delete",
      Cell: d =>
        this.dataTableButton("danger", "Un-Map", () => {
          this.buttonDeletes(d.original);
        })
    }
  ];

  showVisibility = id => {
    let status = id.original.status == "active" ? "Active" : "Inactive";
    let colour = id.original.status == "active" ? "warning" : "danger";
    return this.dataTableButton(colour, status, () =>
      this.updateVisibility(id)
    );
  };

  updateVisibility = async value => {
    let values = value.original;
    // let id = values.id;
    const index = value.index;
    const previousData = [...this.state.tabledata];
    const newData = { ...previousData[index] };
    let subCategoryArray = {};
    if (newData.status === "active") {
      newData.status = "Inactive";
      subCategoryArray.status = "Inactive";
      this.setState({ button: "Hide" });
    } else {
      newData.status = "active";
      subCategoryArray.status = "active";
      this.setState({ button: "Show" });
    }
    const id = newData.id;

    const data = previousData.filter(value => value.id !== id);
    data.splice(index, 0, newData);
    this.setState({ data });

    try {
      const result = await CmsContent.updateMaster(
        "tbl_map_grouptoportlet",
        newData.id,
        subCategoryArray,
        "id"
      );
      if (result) {
        this.setState({ tabledata: data, alertVisible1: true });
      }
      var that = this;
      setTimeout(function () {
        that.onDismiss();
      }, 3000);
    } catch (error) {
      console.log(error);
    }
  };

  wait = d => {
    return null;
  };

  view = (value, modalWindowId) => {
    let count = 0;
    let AllContent = this.state.AllContent;
    AllContent.map((item, i) => {
      if (value.original.contentid === item.contentid) {
        count = item.innerContent.length;
      }
    });
    return (
      <center>
        <button
          type="button"
          className="btn btn-warning"
          onClick={() =>
            window.open(
              "/CohortsView/" +
              btoa(value.original.groupid) +
              "/" +
              btoa(value.original.contentid),
              "_blank"
            )
          }
        >
          {/*console.log(this.state.labelid)*/}
          View ({count})
          {/*   componentDidUpdate() {this.setState({ labelid: value.original.id })}
            {console.log(this.state.labelid)}*/}
        </button>
      </center>
    );
  };
  getPortletData = async value => { };

  buttonDeletes = value => {
    this.setState({});
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.buttonDelete(value)
          //onClick: () => alert("Group removed from")
        },
        {
          label: "No"
        }
      ]
    });
  };

  buttonDelete = async value => {
    let id = value.id;

    let previousData = [...this.state.tableData];

    let datas = previousData.filter(delelteid => delelteid.id !== value.id);
    try {
      const result = await CmsContent.getSingleConditionedValue(
        "tbl_map_grouptoportlet",
        "id",
        id,
        "Delete"
      );
      if (result) {
        this.cohorts();

        this.setState({ tableData: datas });
      }
    } catch (error) {
      console.log(error);
    }
  };
  // deletion = async value => {
  //   const { tableData } = this.state;
  //   const previousData = [...this.state.data];
  //   const getData = { ...previousData[value.index] };
  //   const id = getData.value;
  //   const data = previousData.filter(delelteid => delelteid.value !== value);
  //   try {
  //     const result = await CmsContent.getSingleConditionedValue(
  //       'tbl_map_grouptoportlet',
  //       'id',
  //       id,
  //       'Delete'
  //     );
  //     if (result) {
  //       this.setState({ tableData });
  //     }
  //     const { editvalue } = this.state;
  //     if (editvalue === '') {
  //       this.setState({ error: '' });
  //     }
  //   } catch (error) {
  //     this.setState({ data: previousData });
  //     console.log(error);
  //   }
  // };

  getValueFromArrays = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.id == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.name;
        } else {
          return "---";
        }
      }
    } else {
      return "-";
    }
  };

  getValueFromBoxtypeJson = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v;
        } else {
          return "---";
        }
      }
    } else {
      return "-";
    }
  };

  getValueFromJson = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.id == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v;
        } else {
          return "---";
        }
      }
    } else {
      return "-";
    }
  };

  buttonEdit = async value => {
    let values = value.original;

    let editid = values.id;

    let index = value.index;

    let portletTitle = values.boxtitle;

    let position = values.position;

    let tray = values.tray;

    let boxwidth = {};
    boxwidth.label = values.width;

    let carousel = values.carouselLength;

    let categorySelected = {};
    categorySelected.value = values.groupid;
    categorySelected.label = this.getValueFromArray(
      value.original.groupid,
      this.state.categoryOptions
    );

    let boxtypeSelected = {};
    boxtypeSelected.value = values.boxtypeid;
    boxtypeSelected.label = this.getValueFromArray(
      values.boxtypeid,
      this.state.boxtype
    );

    let selectedContent = {};
    selectedContent.value = values.contentid;
    selectedContent.label = this.getValueFromArrays(
      values.contentid,
      this.state.portletData
    );

    this.setState({
      position,
      selectedContent,
      boxtypeSelected,
      portletTitle,
      categorySelected,
      carousel,
      boxwidth,
      editid,
      tray,
      index,
      editvalue: values,
      disableValue: true,
      button: "Update"
    });
  };
  update = async () => {
    const {
      categorySelected,
      carousel,
      boxtypeSelected,
      selectedContent,
      portletTitle,
      boxwidth,
      position,
      editid: id,
      editvalue: values
    } = this.state;

    try {
      let previousData = [this.state.tabledata];
      let groupArray = {};
      //groupArray.id = id;
      groupArray.groupid = categorySelected.value;
      groupArray.boxtypeid = boxtypeSelected.value;
      groupArray.contentid = selectedContent.value;
      groupArray.boxtitle = portletTitle;
      groupArray.width = boxwidth.label;
      groupArray.carouselLength = carousel;
      groupArray.position = position;
      groupArray.tray = this.state.tray;

      let result = await CmsContent.updateMaster(
        "tbl_map_grouptoportlet",
        id,
        groupArray
      );
      if (result) {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        previousData.id = id;
        previousData.groupid = categorySelected.value;
        previousData.boxtypeid = boxtypeSelected.value;
        previousData.contentid = selectedContent.value;
        previousData.boxtitle = portletTitle.trim();
        previousData.width = boxwidth.label;
        previousData.position = position;
        previousData.carouselLength = carousel;
        previousData.tray = this.state.tray;
        previousData.status = values.status;
        //let groupArray = [...this.state.tableData];
        var tabledata = this.state.tabledata;
        tabledata = tabledata.filter(function (obj) {
          return obj.id !== id;
        });

        this.setState({ alertVisible: true });
        setTimeout(() => {
          this.setState({ alertVisible: false });
        }, 3000);

        let newData = [previousData, ...tabledata];
        this.setState({ tabledata: [] });
        this.cohorts();
      }
    } catch (error) {
      console.log(error);
    }
  };

  boxwidth = e => {
    this.setState({
      boxwidth: e,
      showCarLength: e.value === "carousel" ? true : false,
      // carousel: 0
    });
  };
  orderPosition = e => {
    this.setState({ position: e.target.value });
  };
  portletTitle = e => {
    this.setState({ portletTitle: e.target.value });
  };
  programSelect = e => {
    this.setState({ categorySelected: e, categoryId: e.value });
  };
  programSelect1 = e => {
    this.setState({ searchcategorySelected: e, groupId: e.value });
    this.getFilterData(e.value);
  };
  handleBox = async e => {
    this.setState({ boxtypeSelected: e, boxtypeid: e.value });
    let result = await CmsContent.getConditionedValuewithStatus(
      "tbl_portletname",
      "type",
      e.value,
      "id",
      "name"
    );
    if (result) {
      this.setState({ contentOptions: result.data });
    }
  };
  // addnew = async () => {
  //   const { tableData, boxwidth, position, categoryId, boxtypeid, selectedContent, tray, portletTitle, carousel } = this.state;
  //   this.setState({ portletTitleError: null });

  //   let dateObj = new Date();
  //   let month = dateObj.getUTCMonth() + 1; //months from 1-12
  //   let day = dateObj.getUTCDate();
  //   let year = dateObj.getUTCFullYear();
  //   let newdate = year + '-' + month + '-' + day;

  //   const formDatas = new FormData();
  //   formDatas.append('groupid', categoryId);
  //   formDatas.append('boxtitle', portletTitle);
  //   formDatas.append('boxtypeid', boxtypeid);
  //   formDatas.append('position', position);
  //   formDatas.append('width', boxwidth.value);
  //   formDatas.append('contentid', selectedContent.value);
  //   formDatas.append('tray', tray ? 1 : 0);
  //   formDatas.append('carouselLength', carousel);
  //   formDatas.append('createdAt', newdate);

  //   formDatas.append('status', 'active');
  //   formDatas.append('customerid', this.state.CustomerId);

  //   let appendableValue = {};
  //   appendableValue.boxtitle = portletTitle;
  //   appendableValue.boxtypeid = boxtypeid;
  //   appendableValue.contentid = selectedContent.value;
  //   appendableValue.groupid = categoryId;
  //   appendableValue.position = position;
  //   appendableValue.tray = tray ? 1 : 0;
  //   appendableValue.width = boxwidth.value;

  //   try {
  //     const result = await CmsContent.mapUserToResource('tbl_map_grouptoportlet', formDatas);
  //     if (result) {
  //       let valueArray = {};
  //       valueArray.value = result.data.insertId;
  //       valueArray.boxtitle = portletTitle;
  //       valueArray.boxtypeid = boxtypeid;
  //       valueArray.contentid = selectedContent.value;
  //       valueArray.groupid = categoryId;
  //       valueArray.position = position;
  //       valueArray.tray = tray ? 1 : 0;
  //       valueArray.width = boxwidth.value;

  //       const newData = [valueArray, ...this.state.tableData];
  //       const newData1 = [valueArray, ...this.state.filterData];

  //       this.setState({
  //         tableData: newData,
  //         filterData: newData1,
  //         categoryId: null,
  //         // boxname: null,
  //         boxwidth: [],
  //         position: []
  //       });
  //       this.getFilterData(this.state.groupId);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  selectedTray = e => {
    const value = e;

    this.setState({ tray: value });
  };
  carousel = e => {
    this.setState({ carousel: e.target.value });
  };

  onDismiss = () => {
    this.setState({ alertVisible: false });
  };

  render() {
    const {
      boxsize,
      tableData,
      boxwidth,
      errorsize,
      position,
      errorposition,
      boxtypeSelected,
      categoryOptions,
      searchcategoryOptions,
      categorySelected,
      searchcategorySelected,
      selectedContent,
      contentOptions,
      portletTitle,
      portletTitleError,
      disableValue,
      button,
      carousel,
      alertVisible,
      showCarLength
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Portlets mapped to {this.state.groupName} </h1>
                  </div>
                  <Alert
                    className="badge-content"
                    color="success"
                    isOpen={alertVisible}
                    toggle={this.onDismiss}
                  >
                    New Map Portlet To Group Updated
                  </Alert>
                  <div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2"></div>
                      <div className="col-sm-5">
                        <LoginModal
                          title="Cohorts"
                          atagLink={true}
                          id="group"
                          onClick={this.buttonView}
                          bodyText={
                            <div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="group">Cohorts</label>
                                </div>
                                <div className="col-sm-5">
                                  <SingleSelect
                                    options={categoryOptions}
                                    disabled={disableValue}
                                    handleChange={this.programSelect}
                                    selectedService={categorySelected}
                                  />
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="subgroup">BoxType</label>
                                </div>
                                <div className="col-sm-5">
                                  <SingleSelect
                                    options={this.state.boxtype}
                                    disabled={disableValue}
                                    handleChange={this.handleBox}
                                    selectedService={this.state.boxtypeSelected}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <label>
                                    Tray :
                                    <Switch
                                      height={15}
                                      width={40}
                                      onChange={this.selectedTray}
                                      checked={this.state.tray}
                                    />
                                  </label>
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="position">
                                    Available Portlets
                                  </label>
                                </div>
                                <div className="col-sm-5">
                                  <SingleSelect
                                    className="hcross"
                                    options={contentOptions}
                                    handleChange={e => {
                                      this.setState({ selectedContent: e });
                                    }}
                                    placeholder="Select Width"
                                    value={selectedContent}
                                  />
                                  <span className="error-shows ">
                                    {errorsize}
                                  </span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="position">
                                    Portlet Alias
                                  </label>
                                </div>
                                <div className="col-sm-5">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="This name will be used for Portlet Title"
                                    onChange={this.portletTitle}
                                    value={portletTitle}
                                  />
                                  <span className="error-shows ">
                                    {portletTitleError}
                                  </span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="subgroup">Width</label>
                                </div>
                                <div className="col-sm-5">
                                  <SingleSelect
                                    className="hcross"
                                    options={boxsize}
                                    handleChange={this.boxwidth}
                                    placeholder="Select Width"
                                    value={boxwidth}
                                  />
                                  <span className="error-shows ">
                                    {errorsize}
                                  </span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              {/* {showCarLength && ( */}
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="Carousel">
                                    Carousel Length
                                  </label>
                                </div>
                                <div className="col-sm-5">
                                  <input
                                    type="number"
                                    // min={0}
                                    // id="carousel"
                                    className="form-control"
                                    placeholder="Enter Carousel Length"
                                    onChange={this.carousel}
                                    value={carousel}
                                  />
                                  <span className="error-shows ">
                                    {errorposition}
                                  </span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              {/* )} */}
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="position">
                                    Ordering Position
                                  </label>
                                </div>
                                <div className="col-sm-5">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter Portlet Order"
                                    onChange={this.orderPosition}
                                    value={position}
                                  />
                                  <span className="error-shows ">
                                    {errorposition}
                                  </span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    data-dismiss="modal"
                                    onClick={
                                      button === "Update"
                                        ? this.update
                                        : this.addnew
                                    }
                                  >
                                    {this.state.button}
                                  </button>
                                </div>
                              </div>
                              <div className="col-sm-3" />
                            </div>
                          }
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                  </div>
                  {/* <span>Total of {Object.keys(this.state.dataTableData).length} records</span> */}
                  {this.state.tabledata && (
                    <Datatable
                      data={this.state.tabledata}
                      columnHeading={this.column}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default CohortsView;
