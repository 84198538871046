import React, { Component } from "react";
import { Alert } from "reactstrap";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { FormMiddleWare } from "../../../components/Form";
import LoginModal from "../../../components/Modal/Modal";
import { confirmAlert } from "react-confirm-alert";
import Groupdf from "../../../components/PDF/Groupdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";

class Group extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      alertVisible: false,
      editvalue: "",
      editid: null,
      disableValue: false,
      addNewSubgroup: false,
      error: null,
      button: "Submit",
      index: null,
      group: [],
      // Gp:[],
      alertVisible1: false,
      formAlertdelete: false,
      customerId: localStorage.getItem("userId"),
    };
  }

  async componentDidMount() {
    this.userList();
  }
  userList = async () => {
    try {
      // let result = await CmsContent.getSingleConditionedValue(
      //   "tbl_group",
      //   "customerid",
      //   this.state.customerId
      // );
      // console.log(result);

      let result = await CmsContent.getFreedom("*", "tbl_group",
        `customerid=${this.state.customerId}`,
        1, 1)
      if (result) {
        this.setState({ data: result.data, group: result.data });
      }
      result = await CmsContent.getAllSubtitleList("tbl_user_web");
      if (result) {
        this.setState({ user: result.data });
      }
      result = await CmsContent.getAllSubtitleList("tbl_maptogroup");
      let AllContent = [];

      if (result) {
        this.state.data.map((ival, i) => {
          if (AllContent.filter((jval) => jval.id === ival.id).length === 0) {
            AllContent.push({
              groupid: ival.id,
              groupName: ival.name,
            });
          }
        });

        AllContent.map((ival, i) => {
          AllContent[i].innerContent = [];
          result.data.map((jval, j) => {
            if (jval.groupid === ival.groupid) {
              AllContent[i].innerContent.push({
                groupid: ival.groupid,
                groupName: ival.groupName,
                userId: jval.userid,
                userName: this.getValueFromArrays(jval.userid, this.state.user),
              });
            }
          });
        });
        var pp = [];
        AllContent.map((item, i) => {
          if (item.innerContent.length > 0) {
            item.innerContent.map(async (ival, j) => {
              if (item.groupid == ival.groupid) {
                pp.push({
                  SI_N0: i + 1,
                  group: ival.groupName,
                  user: ival.userName,
                });
              }
            });
          } else {
            pp.push({ SI_N0: i + 1, group: item.groupName, user: "NA" });
          }
        });

        var excel = [];
        var Csvt = [];

        this.setState({ mapgroup: result.data, excel1: AllContent, Csv1: pp });
      }
      console.log(result.data);
      if (result.data.length > 0) {
        const header1 = ["SI.NO", "COHORTS", "LEARNER"];
        const excelHead = [
          { label: "SI_No", key: "SI_N0" },
          { label: "COHORTS", key: "group" },
          { label: "LEANERS", key: "user" },
        ];

        this.setState({
          header1,
          excelHead,
          data: this.state.group,

          excel: this.state.excel1,
          Csvt: this.state.Csv1,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  column = [
    {
      Header: "Cohorts",
      accessor: "name",
    },
    {
      Header: "Learner",
      accessor: "view",
      Cell: (d) => this.view(d, "view"),
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: (d) => this.edit(d),
    },
    // {
    //   Header: 'Delete',
    //   accessor: 'delete',
    //   Cell: d => this.delete(d)
    // },
    {
      Header: <b>Delete</b>,
      accessor: "delete",
      Cell: (d) =>
        this.dataTableButton("danger", "Delete", () => {
          this.buttonDeletes(d.original);
        }),
    },
  ];
  columns = [
    {
      Header: "Leaners",
      accessor: "label",
    },
    {
      Header: "Remove",
      Cell: (d) => this.remove(d),
    },
  ];
  wait = (d) => {
    return null;
  };
  view = (value, modalWindowId) => {
    return (
      <center>
        <button
          type="button"
          data-toggle="modal"
          data-target={`#${modalWindowId}`}
          className="btn btn-warning"
          onClick={() => this.buttonView(value)}
        >
          View Leaners
        </button>
      </center>
    );
  };
  buttonView = (value) => {
    const { mapgroup } = this.state;

    let all = mapgroup
      .map((ival, i) => {
        if (ival.groupid == value.original.id) {
          let returnArray = {};
          returnArray.label = this.getValueFromArrays(
            ival.userid,
            this.state.user
          );
          returnArray.value = ival.userid;
          returnArray.id = ival.id;
          // returnArray.labels = ival.userName;

          return returnArray;
        }
      })
      .filter(function (element) {
        return element !== undefined;
      });
    this.setState({ buttonView: all });
  };

  getValueFromArrays = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.id == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.userName;
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  };

  getValueFromArray1 = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.id == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.name;
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  };

  remove = (value, modalWindowId) => {
    return (
      <center>
        <button
          type="button"
          data-toggle="modal"
          data-target={`#${modalWindowId}`}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          className="btn btn-danger"
          onClick={() => this.buttonRemoves(value)}
        >
          Remove
        </button>
      </center>
    );
  };

  buttonRemoves = (value) => {
    this.setState({});
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.buttonRemove(value),
          //onClick: () => alert("Group removed from")
        },
        {
          label: "No",
        },
      ],
    });
  };

  buttonRemove = async (value) => {
    let values = value.original;
    let index = value.index;
    let id = values.id;
    console.log(id);
    let previousData = [...this.state.buttonView];
    console.log(previousData);
    try {
      let result = await CmsContent.getSingleConditionedValue(
        "tbl_maptogroup",
        "id",
        id,
        "Delete"
      );
      if (result) {
        let datas = previousData.filter((delelteid, i) => {
          // console.log()
          if (delelteid.id !== id) {
            return delelteid;
          }
        });
        console.log(datas);
        this.setState({ buttonView: datas });
        this.userList();
      }
    } catch (error) {
      console.log(error);
    }
  };

  edit = (d) => {
    let value = d;

    return (
      <center>
        <button
          type="button"
          className="btn btn-info"
          onClick={() => this.edition(value)}
        >
          Edit
        </button>
      </center>
    );
  };
  edition = (value) => {
    let values = value.original;
    let index = value.index;
    let editvalue = values.name;
    let editid = values.id;
    let categorySelected = {};
    categorySelected.label = editvalue;
    categorySelected.value = editid;
    this.setState({
      index: value.index,
      editvalue: values.name,
      editid: values.id,
      categorySelected,
      error: null,
      button: "Update",
    });
  };

  buttonDeletes = (value) => {
    this.setState({});
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.buttonDelete(value),
        },
        {
          label: "No",
        },
      ],
    });
  };

  buttonDelete = async (value) => {
    const previousData = [...this.state.data];
    let id = value.id;
    //console.log(id)

    try {
      const result = await CmsContent.getSingleConditionedValue(
        "tbl_group",
        "id",
        id,
        "Delete"
      );
      if (result) {
        let datas = previousData.filter((delelteid, i) => {
          if (delelteid.id !== value.id) {
            return delelteid;
          }
        });
        this.setState({
          data: datas,
          formAlertdelete: true,
          alertVisible: true,
          textalert: "Selected Cohorts Deleted",
          color: "danger",
        });
        this.userList();
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //   getValueFromArrays = (d, array) => {
  //   // console.log(d);
  //   // console.log(array);
  //   // console.log('testing -> ' + d + ' array -> ' + JSON.stringify(array));
  //   if (array.length > 0) {
  //     if (array.length !== 0) {
  //       let filtered = array.filter(function(item) {
  //         // console.log(item.id + '=' + d);
  //         return item.id == d;
  //       });
  //       let v = filtered[0];
  //       if (v != undefined) {
  //         //console.log(v);
  //         return v.name;
  //       } else {
  //         return "---";
  //       }
  //     }
  //   } else {
  //     return "-";
  //   }
  // };
  handlechange = (e) => {
    let value = e.target.value;
    this.setState({ editvalue: value });
  };
  addnew = async () => {
    let value = this.state.editvalue;
    if (value === null || value.trim() === "") {
      this.setState({ error: "Please Fill The Cohorts" });
      return false;
    }
    let groupArray = {};
    groupArray.name = value.trim();
    groupArray.status = "active";
    groupArray.customerId = this.state.customerId;
    try {
      this.setState({ disableValue: true });
      const result = await CmsContent.addMaster("tbl_group", groupArray);
      if (result) {
        let valueArray = {};
        valueArray.value = result.data.insertId;
        valueArray.name = groupArray.name;
        const newData = [valueArray, ...this.state.data];
        this.setState({
          data: newData,
          alertVisible: true,
          editvalue: "",
          editid: null,
          disableValue: false,
          addNewSubgroup: false,
          error: null,
          button: "Submit",
          index: null,
          textalert: "New Cohorts Added",
          color: "success",
        });
        this.userList();
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  update = async () => {
    let value = this.state.editvalue;
    let index = this.state.index;
    if (value === "") {
      this.setState({ error: "Value Cannot Be Empty" });
      return false;
    } else {
      this.setState({ disableValue: true });
      try {
        let previousdata = [...this.state.data];

        let id = this.state.editid;
        let subgroupArray = {};
        subgroupArray.name = value.trim();
        subgroupArray.status = "active";
        const result = await CmsContent.updateMaster(
          "tbl_group",
          id,
          subgroupArray
        );
        if (result) {
          value.id = id;
          value.name = value.trim();
          this.setState({ data: previousdata, alertVisible1: true });
        }
      } catch (error) {
        console.log(error);
      }
      this.setState({
        alertVisible1: true,
        editvalue: "",
        disableValue: false,
        editid: null,
        addNewSubgroup: false,
        error: null,
        button: "Submit",
        index: null,
        alertVisible: true,
        textalert: "updated successfully",
        color: "success",
      });
      this.userList();
      setTimeout(() => this.setState({ alertVisible: false }), 3000);
    }
  };
  onDismiss = () => {
    this.setState({ alertVisible: false });
    this.setState({ alertVisible1: false });
    this.setState({ formAlertdelete: false });
  };
  render() {
    const {
      editvalue,
      alertVisible,
      alertVisible1,
      disableValue,
      error,
      button,
      formAlertdelete,
      textalert,
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Cohorts Master</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {textalert}
                          {/* New Cohorts Added */}
                        </Alert>
                        {/* <Alert
className="badge-content"
color="success"
isOpen={alertVisible1}
toggle={this.onDismiss}
>
New Cohorts Updated
</Alert>
<Alert
className="badge-content"
color="danger"
isOpen={formAlertdelete}
toggle={this.onDismiss}
>
Cohorts Deleted
</Alert> */}
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2"></div>
                      <div className="col-sm-5">
                        <LoginModal
                          buttonTitle={this.view}
                          atagLink={true}
                          title="Learners"
                          id="view"
                          onClick={this.buttonView}
                          bodyText={
                            <div>
                              {this.state.buttonView && (
                                <Datatable
                                  data={this.state.buttonView}
                                  columnHeading={this.columns}
                                />
                              )}
                            </div>
                          }
                        />
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="subgroup">Cohorts</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          name="subgroup"
                          placeholder="Enter cohort"
                          className="form-control"
                          value={editvalue}
                          onChange={(e) => this.handlechange(e)}
                        />
                        <span className="error-shows">{error}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={disableValue}
                          onClick={
                            button === "Update" ? this.update : this.addnew
                          }
                        >
                          {this.state.button}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    {this.state.group && this.state.group.length > 0 && (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <Groupdf
                            header={this.state.header1}
                            data={this.state.excel}
                          />
                        }
                        fileName="sample.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    )}
                    {this.state.excel && this.state.excel.length > 0 && (
                      <CSVLink
                        data={this.state.Csvt}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          Excel
                        </button>
                      </CSVLink>
                    )}
                    <div style={{ marginTop: "5px" }}></div>
                    <span>
                      Total of {Object.keys(this.state.data).length} records
                    </span>
                    {this.state.data && (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Group;
