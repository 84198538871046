import React, { Component } from 'react';
import CmsContent from '../../MiddleWare/CmsContent';
import RightPanel from './RightPanel';
import renderHTML from 'react-render-html';
import Pagination from 'react-js-pagination';
import like from '../../images/core-img/like.png';
import comment from '../../images/core-img/chat.png';

class CategoryList extends Component {
  constructor(props) {
    super(props);

    const userlog = localStorage.getItem('userlog');
    let userid = JSON.parse(userlog);

    this.state = {
      data: null,
      content: [],
      displaycontent: [],
      activePage: null,
      startindex: null,
      endindex: null,
      limit: 3,
      userid: userid.id,
      actual: null,
      like: []
    };
   
  }
  async componentDidMount() {
    this.setState({ activePage: 1, startindex: 0, endindex: 3 });
    const result = await CmsContent.getAllCategoryMappedPages();
      

    if (result) {
      let content = [];
      this.setState({ data: result.data });
      this.state.data.map((value, key) => {
       
        {
          (value.file_extension === 'jpg' || value.file_extension === 'jpeg' || (value.file_extension === 'mp4' && value.thumbnail)) && content.push(value);
        }
      });
     

      this.setState({ content });
      let displaycontent = [];
      this.state.content.map((value, key) => {
        if (key >= this.state.startindex && key < this.state.endindex) {
          displaycontent.push(value);
        }
      });
      this.setState({ displaycontent });
      this.handlePageChanges(1, false);
    }
  }

 

  handlePageChanges = (pageNumber, val = true) => {
    let activePage = pageNumber;
    let startindex;
    let endindex;
    let limit = this.state.limit;
    let displaycontent = [];

    endindex = activePage * limit;
    startindex = endindex - limit;

    this.state.content.map((value, key) => {
      if (key >= startindex && key < endindex) {
        displaycontent.push(value);
      }
    });
    let a = [];
    var d = '';
    displaycontent.map((value, key) => {
      a.push(
        <div className="single-blog-post featured-post mb-30">
         {/* <div className="post-thumb">
            <a href="#">
              {(value.file_extension === 'jpg' || value.file_extension === 'jpeg') && <img src={value.file} alt="Image" style={{ height: '524px', width: '787px' }} />}
              {value.file_extension === 'mp4' && value.thumbnail && <img src={value.thumbnail} alt="Image" style={{ height: '524px', width: '787px' }} />}
            </a>
          </div> */}
          <div className="post-data">
            <a href="#" className="post-catagory" style={{ textDecoration: 'none' }}>
              {value.categoryName}
            </a>
            <a href="#" className="post-title" style={{ textDecoration: 'none' }}>
              <h6 className="mt-0">{value.contentTitle1}</h6>
            </a>
            <div className="post-meta">
              <p className="post-author">
                By <a>{value.authorname}</a>
              </p>
              {/*{value.content1.length > 150 ? renderHTML(value.content1.substring(0, 150)) + 'Read more' : renderHTML(value.content1)} */}
              {/* {value.content1}
              {renderHTML(value.content1).length} */}
              {/*<p className="post-excerp">{renderHTML(value.content1)}</p> */}
               
                {/* {this.charlength(value.content1.length, renderHTML(value.content1), value.content1.substring(0, 150))} */}

                
              

              { value.content1.length > 150 ? 
                  <p className="post-excerp"> { renderHTML(value.content1.substr(0, 250)) } 
                  <br /> <a href={`${value.themeName}/${value.pageId}`}> Read More... </a> 
                  </p>
                  : 
                  <p className="post-excerp"> { renderHTML(value.content1) } </p>
                }

              <div className="d-flex align-items-center">
                <a href="#" className="post-like" onClick={e => this.likes(e, key, value.id)}>
                  <img src={like} alt="" /> <span>{value.likecount}</span>
                </a>
                <a href="#" className="post-comment">
                  <img src={comment} alt="" /> <span>10</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    });
    this.setState({ displaycontent, activePage: activePage, startindex: startindex, endindex: endindex, actual: a });
    if (val) {
      
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  };
  likes = async (e, key, id) => {
    e.preventDefault();
    let likes = {};
    likes.categoryid = id;
    likes.likedby = this.state.userid;
    likes.userid = this.state.userid;
    likes.status = 'active';
    try {
      if (this.state.like.indexOf(id) === -1) {
        const liked = await CmsContent.addMaster('LikeComment', likes);
        if (liked) {
          let pre = [...this.state.displaycontent];
          let s = this.state.like;
          s.push(id);
          pre[key].likecount = pre[key].likecount + 1;
          this.setState({ displaycontent: pre, like: s });
          this.handlePageChanges(this.state.activePage, false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  comments = async (e, id) => {
    e.preventDefault();
    
  };
  render() {
    const { displaycontent, actual } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-8 pr-0">
            <div className="card ">
              <div className="card-body">
                {this.state.actual}
                <Pagination
                  prevPageText={<i class="fa fa-caret-left" aria-hidden="true" />}
                  nextPageText={<i class="fa fa-caret-right" aria-hidden="true" />}
                  activePage={this.state.activePage}
                  itemsCountPerPage={1}
                  totalItemsCount={this.state.content.length / this.state.limit}
                  pageRangeDisplayed={4}
                  onChange={this.handlePageChanges}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="card">
              <div className="card-body">
                <RightPanel />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CategoryList;
