import React, { Component } from "react";
import { Bar, Pie } from "react-chartjs-2";
import CanvasJSReact from '@canvasjs/react-charts';
import CmsContent from "../../../MiddleWare/CmsContent";
import { SingleSelect } from "../../../components/Form";
import moment from "moment";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
class SchoolDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            customerId: JSON.parse(localStorage.getItem("userlog")).id,
            barChartLabel: [],
            bardata: [],
            SchoolOpt: [],
            schoolData: [],
            traningData: [],
            fromdate: "",
            todate: "",
            fromdate1: "",
            todate1: "",
            fromdate2: "",
            todate2: "",
        }
    }
    async componentDidMount() {
        const today = moment()
        const oneMonthAgo = moment().subtract(1, 'months');
        const enddate = today.format("YYYY-MM-DD")
        const startdate = oneMonthAgo.format('YYYY-MM-DD')
        this.setState({ fromdate: startdate, fromdate1: startdate, fromdate2: startdate, todate: enddate, todate1: enddate, todate2: enddate },

            async () => {
                if (this.state.fromdate && this.state.todate) {
                    let obj = {
                        fromdate: this.state.fromdate,
                        todate: this.state.todate
                    }
                    console.log(obj)
                    const result1 = await CmsContent.GetSchoolChartDatas(this.state.customerId, "1", obj)
                    console.log(result1, "result1");
                    if (result1 && result1.length > 0) {
                        let labels = []
                        result1.map((a, b) => {
                            labels.push(a.label)
                        })
                        this.setState({ barChartLabel: labels, bardata: result1 })
                    }
                }
                if (this.state.fromdate1 && this.state.todate1) {
                    let obj = {
                        fromdate: this.state.fromdate1,
                        todate: this.state.todate1
                    }
                    console.log("success", this.state.fromdate1, this.state.todate1)
                    const result = await CmsContent.GetSchoolChartDatas(this.state.customerId, "2", obj)
                    if (result && result.length > 0) {
                        this.setState({ data: result })
                    }
                }
            }
        )

        this.Mainfun(startdate, enddate)
    }
    Mainfun = async (startdate, enddate) => {
        try {
            if (startdate && enddate) {
                // console.log("enter if");
                let obj = {
                    fromdate: startdate,
                    todate: enddate
                }
                // console.log(obj,"obj");
                const result = await CmsContent.GetSchoolChartDatas(this.state.customerId, "topic", obj)
                // console.log(result,"result");
                if (result && result.length > 0) {
                    let topicarray = []
                    let topicArray = []
                    let result2 = result.map((a, b) => {
                        console.log(a.label, b, "a.label")
                        let obj = {
                            label: a.label.split(","),
                            y: b
                        }
                        console.log(obj, "firstobj")
                        topicArray.push(obj)
                    })
                    let second = []
                    topicArray.map((a, b) => {
                        a.label.map((ival, index) => {
                            let obj = {
                                label: ival,
                                y: a.y
                            }
                            console.log(obj, "obj")
                            second.push(obj)
                        })
                    })
                    // console.log(second, "second")
                    console.log(topicArray, "topicarray")
                    let labelMap = new Map();

                    second.forEach(item => {
                        if (!labelMap.has(item.label) || labelMap.get(item.label).y < item.y) {
                            labelMap.set(item.label, item);
                        }
                    });
                    let uniqueData = Array.from(labelMap.values());
                    console.log(uniqueData);
                    this.setState({ traningData: uniqueData })
                }
            }

        }
        catch (err) {
            console.log(err)
        }
    }
    optionSelect = async (selectedOption) => {
        if (selectedOption) {
            this.setState({
                schoolData: selectedOption,
            })

        }
    }
    handleChange = (e) => {
        const { name, value } = e.target
        const { SchoolOpt, schoolData, traningData, fromdate, todate } = this.state
        this.setState({ [name]: value },
            async () => {
                if (this.state.fromdate && this.state.todate) {
                    let obj = {
                        fromdate: this.state.fromdate,
                        todate: this.state.todate
                    }
                    console.log(obj)
                    const result1 = await CmsContent.GetSchoolChartDatas(this.state.customerId, "1", obj)
                    let labels = []
                    result1.map((a, b) => {
                        labels.push(a.label)
                    })
                    this.setState({ barChartLabel: labels, bardata: result1 })
                }
                if (this.state.fromdate1 && this.state.todate1) {
                    let obj = {
                        fromdate: this.state.fromdate1,
                        todate: this.state.todate1
                    }
                    console.log("success", this.state.fromdate1, this.state.todate1)
                    const result = await CmsContent.GetSchoolChartDatas(this.state.customerId, "2", obj)
                    this.setState({ data: result })
                }
                if (this.state.fromdate2 && this.state.todate2) {
                    let obj = {
                        fromdate: this.state.fromdate2,
                        todate: this.state.todate2
                    }
                    const result = await CmsContent.GetSchoolChartDatas(this.state.customerId, "topic", obj)
                    if (result && result.length > 0) {
                        let topicarray = []
                        let topicArray = []
                        let result2 = result.map((a, b) => {
                            console.log(a.label, b, "a.label")
                            let obj = {
                                label: a.label.split(","),
                                y: b
                            }
                            console.log(obj, "firstobj")
                            topicArray.push(obj)
                        })
                        let second = []
                        topicArray.map((a, b) => {
                            a.label.map((ival, index) => {
                                let obj = {
                                    label: ival,
                                    y: a.y
                                }
                                console.log(obj, "obj")
                                second.push(obj)
                            })
                        })
                        // console.log(second, "second")
                        console.log(topicArray, "topicarray")
                        let labelMap = new Map();

                        second.forEach(item => {
                            if (!labelMap.has(item.label) || labelMap.get(item.label).y < item.y) {
                                labelMap.set(item.label, item);
                            }
                        });
                        let uniqueData = Array.from(labelMap.values());
                        console.log(uniqueData);
                        this.setState({ traningData: uniqueData })
                    }
                }
            }
        )
    }
    render() {
        const options = {
            title: {
                text: ""
            },
            data: [
                {
                    type: "column",
                    dataPoints: this.state.bardata
                }
            ]
        }
        const pieoptions = {
            animationEnabled: true,
            exportEnabled: true,
            theme: "light1", // "light1", "dark1", "dark2"
            title: {
                text: ""
            },
            data: [{
                type: "pie",
                indexLabel: "{label}: {y}%",
                startAngle: -90,
                dataPoints: this.state.data
            }]
        }
        const graphoptions = {
            title: {
                text: ""
            },
            data: [
                {
                    // Change type to "doughnut", "line", "splineArea", etc.
                    type: "column",
                    dataPoints: this.state.traningData

                }
            ]
        }
        const { SchoolOpt, schoolData, traningData, fromdate, todate, fromdate1, todate1, fromdate2, todate2, bardata, data } = this.state
        console.log(fromdate)
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Content Management System</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-gorup py-2">
                                            <div className="col-sm-3" />
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select From date</label>
                                                <input
                                                    type="date"
                                                    id="myDate"
                                                    name="fromdate"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={fromdate}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select To date</label>
                                                <input
                                                    type="date"
                                                    id="myDate"
                                                    name="todate"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={this.state.todate}
                                                    disabled={fromdate == ""}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group ">
                                            <div className="col-sm-12">
                                                <div className="text-center">
                                                    <h1>Total number of students counseling in this school</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row form-group py-2">
                                            <div className="col-sm-12">

                                                <CanvasJSChart options={options}
                                                />
                                            </div>
                                        </div>
                                        <div className="row form-gorup py-2">
                                            <div className="col-sm-3" />
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select From date</label>
                                                <input
                                                    type="date"
                                                    id="myDate"
                                                    name="fromdate1"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={fromdate1}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select To date</label>
                                                <input
                                                    type="date"
                                                    id="myDate"
                                                    name="todate1"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={todate1}
                                                    disabled={fromdate1 == ""}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group py-3">
                                            <div className="col-sm-12">
                                                <div className="">
                                                    <h1>Total number of students training in this school</h1>
                                                </div>
                                                <div className="py-2">

                                                    <CanvasJSChart options={pieoptions}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                <div className="">
                                                    <h1>Training topics covered by the school</h1>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row form-group py-2">
                                            <div className="col-sm-3" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select School</label>
                                            </div>
                                            <div className="col-sm-4">
                                                <SingleSelect
                                                    options={SchoolOpt}
                                                    handleChange={(e) => this.optionSelect(e)}
                                                    selectedService={schoolData}
                                                />
                                            </div>
                                        </div> */}
                                        <div className="row form-gorup py-2">
                                            <div className="col-sm-3" />
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select From date</label>
                                                <input
                                                    type="date"
                                                    id="myDate"
                                                    name="fromdate2"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={fromdate2}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select To date</label>
                                                <input
                                                    type="date"
                                                    id="myDate"
                                                    name="todate2"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={todate2}
                                                    disabled={fromdate2 == ""}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group py-2">
                                            <div className="col-sm-12">

                                                <CanvasJSChart options={graphoptions}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}

export default SchoolDashboard;