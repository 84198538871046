import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import CmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import AddSubCategory from "./AddSubCategory";
import AddCategory from "./AddCategory";
import Datatable from "../../../components/Datatable/Datatable";
import "./style.css";
import { Alert } from "reactstrap";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import CMSCategorypdf from "../../../components/PDF/Categorypdf";
import { MultiSelect } from "../../../components/Form";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categorySelected: "",
      categoryId: "",
      categoryName: "",
      categoryOptions: [],
      subCategorySelected: "",
      subCategoryId: "",
      subCategoryName: "",
      subCategoryOptions: [],
      pageSelected: "",
      deptSelected: "",
      pageId: "",
      pageName: "",
      pageOptions: [],
      userOptions: [],
      UserOption: [],
      category: "",
      subCategory: "",
      errorCategory: "",
      errorSubCategory: "",
      errorCategoryId: "",
      errorSubCategoryId: "",
      errorPageId: "",
      data: [],
      alertVisible: false,
      btnDisable: false,
      formAlert: false,
      formBtn: false,
      updateorsubmit: "Submit",
      updateid: "",
      dataIndex: null,
      formAlertdelete: false,
      customerId: localStorage.getItem("userId"),
    };
  }

  async componentDidMount() {
    this.mainFun();
  }
  async mainFun() {
    try {
      const languages = await CmsContent.getFreedom(
        "id as value,language as label",
        "cmndl_tbl_languages",
        1,
        "id",
        "id DESC"
      );
      if (languages) {
        this.setState({ langOptions: languages.data });
      }
      const User = await CmsContent.getFreedom(
        "id as value,usertype as label",
        "tbl_UserType",
        `customerid = ${this.state.customerId} and serviceid = 8`,
        "id",
        "id DESC"
      );
      if (User) {
        this.setState({ UserOption: User.data });
      }
      const pages = await CmsContent.getFreedom(
        "id as value,name as label",
        "tbl_pages",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (pages) {
        this.setState({ pageOptions: pages.data });
      }
      const user = await CmsContent.getFreedom(
        "id as value,name as label",
        "tbl_department_master",
        `customerId=${this.state.customerId} and status="active"`,
        "id",
        "id DESC"
      );
      console.log(user)
      if (user) {

        this.setState({ userOptions: user.data });
      }
      const category = await CmsContent.getFreedom(
        "id as value,name as label",
        "tbl_category_master",
        `customerId=${this.state.customerId} and status="active"`,
        "id",
        "id DESC"
      );
      if (category) {
        this.setState({ categoryOptions: category.data });
      }
      const subCategory = await CmsContent.getFreedom(
        "id as value,name as label",
        "tbl_subCategory_master",
        `customerId=${this.state.customerId} and status="active"`,
        "id",
        "id DESC"
      );
      if (subCategory) {
        this.setState({ subCategoryOptions: subCategory.data });
      }

      const getAllMappedpages = await CmsContent.getFreedom(
        "tbl_mapping_page.id,tbl_mapping_page.language,tbl_mapping_page.pageId,tbl_mapping_page.subCategoryId,tbl_mapping_page.subCategoryName,tbl_mapping_page.categoryName,tbl_mapping_page.categoryId,tbl_mapping_page.status,tbl_mapping_page.subCategoryName,tbl_pages.name,tbl_mapping_page.deptid,tbl_mapping_page.userType",
        "tbl_pages,tbl_mapping_page",
        `tbl_pages.id=tbl_mapping_page.pageId and tbl_pages.customerId=${this.state.customerId}`,
        "tbl_mapping_page.id",
        "tbl_mapping_page.id DESC"
      );
      if (getAllMappedpages) {
        if (getAllMappedpages.data) {
          let data = []
          for (let ival of getAllMappedpages.data) {
            if (ival.subCategoryName != null) {
              await data.push(ival)
            } else {
              await data.push(ival)
            }
          }
          await this.setState({ data: data });
        }
        // this.setState({ data: getAllMappedpages.data });
      }
      console.log(this.state.data)
      console.log(getAllMappedpages.data);
      if (getAllMappedpages.data.length > 0) {
        const header = [
          "SI.NO",
          "CATEGORY",
          "SUBCATEGORY",
          "PAGENAME",
          "STATUS",
        ];
        const excelHead = [
          { label: "CATEGORY", key: "categoryName" },
          { label: "SUBCATEGORY", key: "subCategoryName" },
          { label: "PAGENAME", key: "name" },
          { label: "STATUS", key: "status" },
        ];
        this.setState({
          excelHead,
          header,
          excel: getAllMappedpages.data,
          title: "MAP PAGE TO CATEGORY",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  column = [
    {
      Header: "Category",
      accessor: "categoryName",
    },
    {
      Header: "Sub-Category",
      accessor: "subCategoryName",
    },
    {
      Header: "page",
      accessor: "name",
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: (d) => this.statusChange(d),
    },
    {
      Header: "Visibility",
      accessor: "edit",
      Cell: (d) => this.showVisibility(d),
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: (d) => this.edit(d, "customer"),
    },
    {
      Header: "Delete",
      accessor: "delete",
      Cell: (d) => this.delete(d),
    },
  ];
  column1 = [
    {
      Header: "Category",
      accessor: "categoryName",
    },
    {
      Header: "Sub-Category",
      accessor: "subCategoryName",
    },
    {
      Header: "page",
      accessor: "name",
    },
    // {
    //   Header: "Department",
    //   accessor: "deptid",
    //   Cell: d =>
    //         this.getValueFromArrays(
    //           d.original.deptid,
    //           this.state.userOptions
    //         )
    // },

    {
      Header: "Status",
      accessor: "status",
      Cell: (d) => this.statusChange(d),
    },
    {
      Header: "Visibility",
      accessor: "edit",
      Cell: (d) => this.showVisibility(d),
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: (d) => this.edit(d, "customer"),
    },
    {
      Header: "Delete",
      accessor: "delete",
      Cell: (d) => this.delete(d),
    },
  ];

  getValueFromArrays = (d, array) => {

    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {

          return v.label;
        } else {
          return "---";
        }
      }
    } else {
      return "-";
    }
  };
  statusChange = (id) => {
    let nameCapitalized =
      id.original.status.charAt(0).toUpperCase() + id.original.status.slice(1);

    return (
      <span
        style={
          id.original.status == "active"
            ? { color: "green", fontWeight: "bold" }
            : { color: "red", fontWeight: "bold" }
        }
      >
        {" "}
        {nameCapitalized}
      </span>
    );
  };

  showVisibility = (id) => {
    return (
      <button
        type="button"
        className="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '15px' }}
        onClick={() => this.updateVisibility(id)}
      >
        Active/Inactive
      </button>
    );
  };

  updateVisibility = async (value) => {
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };

    if (newData.status === "active") {
      newData.status = "Inactive";
    } else {
      newData.status = "active";
    }
    console.log(newData)
    const id = newData.categoryId;
    const data = previousData.filter((value) => value.categoryId !== id);
    //const data = previousData.filter((value) => value.id !== id);
    data.splice(index, 0, newData);
    this.setState({ data });
    try {
      const result = await CmsContent.updateCategory(newData);
      if (result) {
        this.setState({ data: data });
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };

  edit = (value, modalWindowId) => {
    return (
      <button
        type="button"
        data-toggle="modal"
        data-target={`#${modalWindowId}`}
        className="btn btn-warning"
        onClick={() => this.buttonEdit(value)}
      >
        Edit
      </button>
    );
  };

  buttonEdit = (value) => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // console.log(value, 'value');
    let categoryJson = {};
    let subcategoryJson = {};
    let pageJson = {};
    let deptJson = {};
    let language = {};
    let User = []
    const index = value.index;
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    categoryJson.label = getData.categoryName;
    categoryJson.value = getData.categoryId;

    subcategoryJson.label = getData.subCategoryName;
    subcategoryJson.value = getData.subCategoryId;

    pageJson.label = getData.name;
    pageJson.value = getData.pageId;

    language.label = getData.language;
    language.value = getData.languageId;

    if ((this.state.customerId == 807 || this.state.customerId == 6075) && getData.userType) {
      JSON.parse(getData.userType).map((ival) => {
        this.state.UserOption.map((mval) => {
          if (ival == mval.value) {
            User.push(mval)
          }
        })
      });
    }
    // console.log(getData);
    // deptJson.value = getData.deptid;
    // deptJson.label = this.getValueFromArrays(getData.deptid,this.state.userOptions);

    //   console.log(deptJson);

    this.setState({
      dataIndex: index,
      updateid: getData.id,
      categorySelected: categoryJson,
      categoryId: getData.categoryId,
      categoryName: getData.categoryName,
      language,
      subCategorySelected: subcategoryJson,
      subCategoryId: getData.subCategoryId,
      subCategoryName: getData.subCategoryName,

      pageSelected: pageJson,
      name: getData.name,
      pageId: getData.pageId,
      pageName: getData.name,
      User,
      // deptSelected:deptJson,
      // deptId:getData.deptId,
      // deptName:getData.deptName,

      updateorsubmit: "Update",
    });
  };

  delete = (value) => {
    return (
      <button
        type="button"
        className="btn btn-danger"
        onClick={() => this.buttonDelete(value)}
      >
        Delete
      </button>
    );
  };

  buttonDelete = async (e) => {
    //Getting Previous state data
    const previousData = [...this.state.data];

    // Seperating data row using row-index
    const getData = { ...previousData[e.index] };

    //getting id on that data
    const id = getData.id;
    //removing specific id in previous state data
    const data = previousData.filter((value) => value.id !== id);

    try {
      const result = await CmsContent.deleteMaster("tbl_mapping_page", id);
      if (result) {
        this.setState({
          data,
          updateorsubmit: "Submit",
          pageSelected: "",
          subCategorySelected: "",
          categorySelected: "",
          categoryId: "",
          subCategoryId: "",
          pageId: "",
          formAlertdelete: true,
          formBtn: false,
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  addNewCategory = async () => {
    let categoryArray = {};
    let categoryAddExtra = {};
    let categoryName = this.state.category;
    let categoryOptions = this.state.categoryOptions;

    if (!categoryName) {
      this.setState({ errorCategory: "Enter Categoty Name" });
      return false;
    } else {
      this.setState({ errorCategory: "", btnDisable: true });
      categoryArray.name = categoryName.trim();
      categoryArray.status = "active";
      categoryArray.customerId = this.state.customerId;

      try {
        const result = await CmsContent.addMaster(
          "tbl_category_master",
          categoryArray
        );
        if (result) {
          categoryAddExtra.value = result.data.insertId;
          categoryAddExtra.label = categoryName;
          categoryOptions.splice(0, 0, categoryAddExtra);
          this.setState({
            category: "",
            btnDisable: false,
            alertVisible: true,
            categoryOptions,
          });
          setTimeout(() => this.setState({ alertVisible: false }), 3000);
          // this.componentDidMount()
          this.mainFun();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  addNewSubCategory = async () => {
    let subCategoryArray = {};
    let subCategoryAddExtra = {};
    let subCategoryName = this.state.subCategory;
    let subCategoryOptions = this.state.subCategoryOptions;

    if (!subCategoryName) {
      this.setState({ errorSubCategory: "Enter Sub-Categoty Name" });
      return false;
    } else {
      this.setState({ errorSubCategory: "", btnDisable: true });
      subCategoryArray.name = subCategoryName.trim();
      subCategoryArray.status = "active";
      subCategoryArray.customerId = this.state.customerId;

      try {
        const result = await CmsContent.addMaster(
          "tbl_subCategory_master",
          subCategoryArray
        );

        if (result) {
          subCategoryAddExtra.value = result.data.insertId;
          subCategoryAddExtra.label = subCategoryName;
          subCategoryOptions.splice(0, 0, subCategoryAddExtra);
          this.setState({
            subCategory: "",
            btnDisable: false,
            alertVisible: true,
            subCategoryOptions,
          });
          setTimeout(
            (dataIndex) => this.setState({ alertVisible: false }),
            3000
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  categorySelect = (selectedOption) => {
    this.setState({
      categorySelected: selectedOption,
      categoryId: selectedOption.value,
      categoryName: selectedOption.label,
    });
  };

  subCategorySelect = (selectedOption) => {
    this.setState({
      subCategorySelected: selectedOption,
      subCategoryId: selectedOption.value,
      subCategoryName: selectedOption.label,
    });
  };

  pageSelect = (selectedOption) => {
    this.setState({
      pageSelected: selectedOption,
      pageId: selectedOption.value,
      pageName: selectedOption.label,
    });
  };
  userSelect = (selectedOption) => {
    console.log(selectedOption);
    this.setState({
      deptSelected: selectedOption,
      deptId: selectedOption.value,
      deptName: selectedOption.label,
    });
  };

  submitCategory = async (e) => {
    let mappingArray = {};
    const {
      categoryId,
      categoryName,
      subCategoryId,
      subCategoryName,
      pageId,
      pageName,
      language,
      User,
      updateorsubmit,
      deptId,
      deptName

    } = this.state;

    if (!categoryId) {
      this.setState({ errorCategoryId: "Select Category" });
      return false;
    } else if (!pageId) {
      this.setState({ errorCategoryId: "", errorPageId: "Select Page" });
      return false;
    } else if (!User && (this.state.customerId == 807 || this.state.customerId == 6075)) {
      this.setState({ errorPageId: "", errorUserType: "Select User" });
      return false;
    }
    else {
      this.setState({ errorPageId: "", formBtn: true });
      mappingArray.categoryId = categoryId;
      mappingArray.categoryName = categoryName;
      if (subCategoryId) {
        mappingArray.subCategoryId = subCategoryId;
        mappingArray.subCategoryName = subCategoryName;
      }
      mappingArray.pageId = pageId;
      mappingArray.status = "active";
      mappingArray.customerId = this.state.customerId;
      if ((this.state.customerId == 807 || this.state.customerId == 6075)) {
        mappingArray.languageId = language ? language.value : 2;
        mappingArray.language = language ? language.label : 'ENGLISH';
        if (User) {
          let check = []
          User.map((ival) => {
            if (ival.value) {
              check.push(ival.value)
            }
          })
          mappingArray.userType = JSON.stringify(check)
        }
      }
      // mappingArray.deptid = deptId
      try {
        const result = await CmsContent.addMaster(
          "tbl_mapping_page",
          mappingArray
        );

        if (result) {
          const userData = {
            id: parseInt(result.data.insertId),
            categoryName: categoryName,
            subCategoryName: subCategoryName,
            status: "active",
            name: pageName,
            subCategoryId: subCategoryId,
            language: language ? language.label : 'ENGLISH',
            languageId: language ? language.value : 2,
          };
          if (User && userData) {
            let check = []
            User.map((ival) => {
              if (ival.value) {
                check.push(ival.value)
              }
            })
            userData.userType = JSON.stringify(check)
          }
          const newData = [userData, ...this.state.data];
          this.setState({
            data: newData,
            pageSelected: "",
            subCategorySelected: "",
            categorySelected: "",
            categoryId: "",
            subCategoryId: "",
            pageId: "",
            deptId: "",
            deptSelected: "",
            userName: "",
            User: '',
            userSelected: "",
            language: '',
            formAlert: true,
            formBtn: false,

          });

          setTimeout(() => this.setState({ alertVisible: false, formAlert: false, }), 3000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  updateMaster = async (e) => {
    let mappingArray = {};
    const {
      categoryId,
      categoryName,
      subCategoryId,
      subCategoryName,
      pageId,
      pageName,
      updateid,
      dataIndex,
      language,
      User,
      deptId
    } = this.state;
    if (!User && (this.state.customerId == 807 || this.state.customerId == 6075)) {
      this.setState({ errorPageId: "", errorUserType: "Select User" });
      return false;
    }

    mappingArray.categoryId = categoryId;
    mappingArray.categoryName = categoryName;
    mappingArray.pageId = pageId;
    mappingArray.status = "active";
    if ((this.state.customerId == 807 || this.state.customerId == 6075)) {
      mappingArray.languageId = language ? language.value : 2;
      mappingArray.language = language ? language.label : 'ENGLISH';
      if (User) {
        let check = []
        User.map((ival) => {
          if (ival.value) {
            check.push(ival.value)
          }
        })
        mappingArray.userType = JSON.stringify(check);
      }
    }
    if (subCategoryId) {
      mappingArray.subCategoryId = subCategoryId;
      mappingArray.subCategoryName = subCategoryName;
    }
    // mappingArray.deptid = deptId;
    const previousData = [...this.state.data];
    const getData = { ...previousData[dataIndex] };

    const id = getData.id;
    getData.categoryId = categoryId;
    getData.categoryName = categoryName;
    getData.pageId = pageId;
    getData.status = "active";
    getData.name = pageName;

    // getData.deptId=deptId;
    // getData.deptName=this.getValueFromArrays(deptId,this.state.userOptions)
    if (subCategoryId) {
      getData.subCategoryId = subCategoryId;
      getData.subCategoryName = subCategoryName;
    }
    const data = previousData.filter((value) => value.id !== id);
    data.splice(dataIndex, 0, getData);
    console.log(mappingArray)
    try {
      const result = await CmsContent.updateMaster(
        "tbl_mapping_page",
        updateid,
        mappingArray
      );
      if (result) {
        this.setState({
          data,
          updateorsubmit: "Submit",
          pageSelected: "",
          subCategorySelected: "",
          categorySelected: "",
          categoryId: "",
          subCategoryId: "",
          language: '',
          pageId: "",
          deptId: "",
          deptSelected: "",
          userName: "",
          User: '',
          userSelected: "",
          formAlert: true,
          formBtn: false,
        });
        setTimeout(() => this.setState({ alertVisible: false, formAlert: false, }), 3000);
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };

  onDismiss = () => {
    this.setState({ alertVisible: false, formAlert: false });
    this.setState({ alertVisible: false, formAlertdelete: false });
  };
  handleCheck = (name, selectedOption) => {
    if (name == "User") {
      // let check = [];
      // selectedOption.map((ival, i) => {
      //   check.push(ival.label)
      // })
      // console.log(check, "check");
      this.setState({
        [name]: selectedOption,
      });
      console.log(this.state.User);
    }
  }
  handleSelect = (name, selectedOption) => {
    this.setState({
      [name]: selectedOption
    });
  };

  render() {
    const {
      formAlert,
      formAlertdelete,
      categoryOptions,
      categorySelected,
      errorCategory,
      alertVisible,
      btnDisable,
      category,
      errorCategoryId,
      subCategoryOptions,
      subCategorySelected,
      subCategory,
      errorSubCategory,
      errorSubCategoryId,
      pageOptions,
      userOptions,
      pageSelected,
      deptSelected,
      errorPageId,
      formBtn,
      data,
      updateorsubmit,
      langOptions,
      UserOption,
      language
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Map Page To Category</h3>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color="success"
                          isOpen={formAlert}
                          toggle={this.onDismiss}
                        >
                          Page Successfully Mapped To Category
                        </Alert>

                        <Alert
                          className="badge-content"
                          color="danger"
                          isOpen={formAlertdelete}
                          toggle={this.onDismiss}
                        >
                          Mapped Page Delete
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Select Category</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          handleChange={this.categorySelect}
                          options={categoryOptions}
                          selectedService={categorySelected}
                        />
                      </div>
                      <div className="col-sm-3">
                        <LoginModal
                          buttonTitle="Add Category"
                          title="Add Category"
                          id="Category"
                          extraClass="btn-width"
                          onClick={this.reset}
                          bodyText={
                            <AddCategory
                              category={category}
                              handleChange={this.handleChange}
                              addNewCategory={this.addNewCategory}
                              error={errorCategory}
                              alert={alertVisible}
                              dismiss={this.onDismiss}
                              disableValue={btnDisable}
                              alertMsg="New Category Added"
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorCategoryId}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">
                          Select Sub-Category
                        </label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          handleChange={this.subCategorySelect}
                          options={subCategoryOptions}
                          selectedService={subCategorySelected}
                        />
                      </div>
                      <div className="col-sm-3">
                        <LoginModal
                          buttonTitle="Add SubCategory"
                          title="Add SubCategory"
                          id="subCategory"
                          extraClass="btn-width"
                          bodyText={
                            <AddSubCategory
                              subCategory={subCategory}
                              handleChange={this.handleChange}
                              addNewSubCategory={this.addNewSubCategory}
                              error={errorSubCategory}
                              alert={alertVisible}
                              dismiss={this.onDismiss}
                              disableValue={btnDisable}
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorSubCategoryId}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Select Page</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          handleChange={this.pageSelect}
                          options={pageOptions}
                          selectedService={pageSelected}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    {/* {
                    this.state.customerId==679&&
                    (
                      <div>
                        <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Select Department</label>
                      </div>
                      <div className="col-sm-5">
                        {console.log(userOptions)}
                        <SingleSelect
                           handleChange={this.userSelect}
                           options={userOptions}
                           selectedService={deptSelected}
                        />
                        
                      </div>
                      <div className="col-sm-3" />
                    </div>
                      </div>
                    )
                  }   */}
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorPageId}</span>
                    </div>
                    {(this.state.customerId == 807 || this.state.customerId == 6075) && (
                      <div>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label for="exampleInputEmail1">Select Language</label>
                          </div>
                          <div className="col-sm-5">
                            <SingleSelect
                              handleChange={(e) => this.handleSelect('language', e)}
                              options={langOptions}
                              selectedService={language}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">{this.state.errorLang}</span>
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label for="exampleInputEmail1">Select User</label>
                          </div>
                          <div className="col-sm-5">
                            <MultiSelect
                              options={UserOption}
                              handleChange={(e) => this.handleCheck('User', e)}
                              selectedService={this.state.User}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">{this.state.errorUserType}</span>
                        </div>
                      </div>

                    )}

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        {updateorsubmit === "Submit" ? (
                          <button
                            type="button"
                            class="btn btn-primary"
                            onClick={this.submitCategory}
                            disabled={formBtn}
                          >
                            {updateorsubmit}
                          </button>
                        ) : (
                          <button
                            type="button"
                            class="btn btn-primary"
                            onClick={this.updateMaster}
                            disabled={formBtn}
                          >
                            {updateorsubmit}
                          </button>
                        )}
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <br></br>
                    {this.state.excel && this.state.excel.length > 0 ? (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <CMSCategorypdf
                            title={this.state.title}
                            header={this.state.header}
                            data={this.state.excel}
                          />
                        }
                        fileName="Mappagetocategory.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    ) : null}
                    {this.state.data && this.state.data.length ? (
                      <CSVLink
                        data={this.state.data}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          Excel
                        </button>
                      </CSVLink>
                    ) : null}
                    <br></br>
                    <br></br>

                    {data && this.state.customerId == 679 ? (
                      <Datatable data={data} columnHeading={this.column1} />
                    ) : (<Datatable data={data} columnHeading={this.column} />)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Category;
