import React, { Component } from 'react';

class quizUserAnswer extends Component {
  render() {
    return (
      <div>
        <h1>hello</h1>
      </div>
    );
  }
}

export default quizUserAnswer;
