import React, { Component } from 'react';
import { ACCESS_POINT } from '../../config';

export default class NikkiHeader extends Component {
  render() { 

    return <React.Fragment>
      <header className="header-area">
        {/* Navbar Area */}
        <div className="nikki-main-menu">
          <div className="classy-nav-container breakpoint-off">
            <div className="container-fluid">
              {/* Menu */}
              <nav className="classy-navbar justify-content-between" id="nikkiNav">
                {/* Nav brand */}
                <a href="http://34.200.41.150/nikki/index.html" className="nav-brand">
      <div style={{width:'43%'}}> <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/WhatsAppImage2020-04-14at7_1586875179500.jpeg`} alt="" /> 
        <p style={{fontSize:'18px'}}>SWTN</p>
          </div>
          </a>
                {/* Navbar Toggler */}
                <div className="classy-navbar-toggler">
                  <span className="navbarToggler"><span /><span /><span /></span>
                </div>
                {/* Menu */}
                <div className="classy-menu">
                  {/* close btn */}
                  <div className="classycloseIcon">
                    <div className="cross-wrap"><span className="top" /><span className="bottom" /></div>
                  </div>
                  {/* Nav Start */}
                  <div className="classynav">
                    <ul>
                      <li><a href={`/cms/swtn/singleContent/247`}>Home</a></li>
					  <li><a href={`/cms/swtn/singleContent/253`}>History</a></li>
					  <li>< a href="#">Area Of Focus</a>
					  <ul className="dropdown">
					  <li className="cn-dropdown-itemhas-down"><a href={`/Swtn/themeFive/287`}>SOCIAL EQUITY BUDGETING</a></li>
					  <li className="cn-dropdown-itemhas-down"><a href={`/cms/swtn/singleContent/257`}>SOCIAL EQUITY WATCH</a></li>
					  <li className="cn-dropdown-itemhas-down"><a href={`/Swtn/themeFive/286`}>SOCIAL MONITORING</a></li>
					  <li className="cn-dropdown-itemhas-down"><a href={`/Swtn/themeFive/285`}>PEOPLE’S MANIFESTOS</a></li> 
					  </ul>
					  </li>
					  <li><a href={`#`}>Publications</a></li>
					  <li><a href={`/cms/swtn/singleContent/254`}>Links</a></li>
					  <li><a href={`/cms/swtn/singleContent/247`}>ContactUs</a></li> 
						  {/*<li><a href={`#`}>Staff</a></li>*/} 
                    </ul> 
                    {/* Search Form */}
                    <div className="search-form">
                      <form action="#" method="get">
                        <input type="search" name="search" className="form-control" placeholder="Search and hit enter..." />
                        <button type="submit"><i className="fa fa-search" /></button>
                      </form>
                    </div>
                    {/* Social Button */}
                    <div className="top-social-info">
                      <a href="#" data-toggle="tooltip" data-placement="bottom" title="Facebook"><i className="fa fa-facebook" aria-hidden="true" /></a>
                      <a href="#" data-toggle="tooltip" data-placement="bottom" title="Twitter"><i className="fa fa-twitter" aria-hidden="true" /></a>
                      <a href="#" data-toggle="tooltip" data-placement="bottom" title="Instagram"><i className="fa fa-instagram" aria-hidden="true" /></a>
                      <a href="#" data-toggle="tooltip" data-placement="bottom" title="Pinterest"><i className="fa fa-pinterest" aria-hidden="true" /></a>
                      <a href="#" data-toggle="tooltip" data-placement="bottom" title="RSS Feed"><i className="fa fa-rss" aria-hidden="true" /></a>
                    </div>
                  </div>
                  {/* Nav End */}
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
   
    </React.Fragment>;
  }
}
