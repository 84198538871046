import React, { Component } from "react";
import cmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from "reactstrap";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import AddauthorPDF from "../../../components/PDF/Addauthor";

class AddAuthor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      Authorname: "",
      Authoremail: "",
      Authorabout: "",
      fileName1: "choose File",
      file: "",
      editvalue: "",
      editname: "",
      editemail: "",
      editauthor: "",
      editimage: "",
      editid: null,
      index: null,
      button: "Submit",
      alertVisible: false,
      color: "success",
      customerId: localStorage.getItem("userId"),
    };
  }
  authorname = (e) => {
    let name = e.target.value;
    this.setState({ editname: name });
    this.setState({ Authorname: name });
  };
  authoremail = (e) => {
    let email = e.target.value;
    this.setState({ editemail: email });
    this.setState({ Authoremail: email });
  };

  aboutvalue = (e) => {
    let about = e.target.value;
    this.setState({ editauthor: about });
    this.setState({ Authorabout: about });
  };

  authorimage = (e) => {
    let filename = e.target.files[0];
    let filepath = e.target.files[0].name;

    this.setState({ file: filename });
    this.setState({ filename: filepath });
  };

  async componentDidMount() {
    try {
      const result = await cmsContent.getFreedom(
        "*",
        "tbl_author",
        `customerId = ${this.state.customerId} and status = 'active'`,
        "id",
        "id"
      );

      if (result) {
        this.setState({ data: result.data });
        if (result.data.length > 0) {
          const header = ["SI.NO", "NAME", "EMAIL", "ABOUT"];
          const excelHead = [
            { label: "NAME", key: "name" },
            { label: "EMAIL", key: "email" },
            { label: "ABOUT", key: "about" },
          ];
          this.setState({
            excelHead,
            header,
            excel: result.data,
            title: "AUTHOR",
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      Header: "name",
      accessor: "name",
    },
    {
      Header: "email",
      accessor: "email",
    },
    {
      Header: "about	",
      accessor: "about",
    },
    {
      Header: "edit",
      accessor: "edit",
      Cell: (d) => this.edit(d),
    },
    {
      Header: "delete",
      accessor: "delete",
      Cell: (d) => this.delete(d),
    },
  ];

  edit = (d) => {
    let value = d;
    return (
      <button
        type="button"
        className="btn btn-info"
        onClick={() => this.edition(value)}
      >
        Edit
      </button>
    );
  };

  edition = (value) => {
    let values = value.original;
    let img = values.image;

    let res = img.split("/");

    this.state.authorimage = res[1];
    this.state.filename = this.state.authorimage;
    //this.state.editimage=this.state.authorimage

    this.setState({
      index: value.index,
      Authorname: values.name,
      Authoremail: values.email,
      Authorabout: values.about,
      authorimage: res[1],
      editimage: values.image,
      editid: values.id,
      button: "Update",
      color: "danger",
    });
  };
  delete = (d) => {
    let value = d.original.id;

    return (
      <button
        type="button"
        className="btn btn-danger"
        onClick={() => this.deletion(value)}
      >
        Delete
      </button>
    );
  };
  deletion = async (value) => {
    const result = await cmsContent.deleteMaster("tbl_author", value);

    if (result) {
    }
  };
  addnew = async () => {
    const { Authorname, Authoremail } = this.state;

    if (!Authorname) {
      this.setState({ errorAuthorname: "Please Add Authorname" });
      return false;
    } else if (!Authoremail) {
      this.setState({ errorAuthoremail: "Please Add Authoremail" });
    }

    let username = this.state.Authorname;
    let useremail = this.state.Authoremail;
    let userabout = this.state.Authorabout;
    let userfile = this.state.file;
    let active = "active";
    let customerId = this.state.customerId;

    let value = this.state.editvalue;
    const formData = new FormData();
    formData.append("name", username.trim());
    formData.append("email", useremail.trim());
    formData.append("about", userabout.trim());
    formData.append("image", userfile);
    formData.append("status", active);
    formData.append("customerId", customerId);

    let categoryArray = {};

    try {
      const authorInsert = await cmsContent.authorinsert(
        formData,
        "tbl_author"
      );

      if (authorInsert) {
        let valueArray = {};
        valueArray.id = authorInsert.data.insertId;
        valueArray.name = this.state.Authorname;
        valueArray.email = this.state.Authoremail;
        valueArray.about = this.state.Authorabout;
        const newData = [valueArray, ...this.state.data];

        this.setState({
          data: newData,
          editvalue: "",
          editid: null,
          error: null,
          button: "Submit",
          index: null,
          alertVisible: true,
          Authorname: "",
          Authoremail: "",
          Authorabout: "",
          filename: "",
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  update = async () => {
    let id = this.state.editid;

    try {
      const formDatas = new FormData();
      formDatas.append("id", id);
      formDatas.append("name", this.state.Authorname);
      formDatas.append("email", this.state.Authoremail);
      formDatas.append("about", this.state.Authorabout);
      formDatas.append("status", "active");
      formDatas.append("customerId", this.state.customerId);

      if (this.state.file) {
        formDatas.append("image", this.state.file);
      }

      const result = await cmsContent.updateauthor("tbl_author", formDatas);

      if (result) {
        let previousdata = [...this.state.data];
        let index = this.state.index;

        previousdata[index].name = this.state.Authorname;
        previousdata[index].email = this.state.Authoremail;
        previousdata[index].about = this.state.Authorabout;

        this.setState({
          alertVisible: true,
          Authorname: "",
          Authoremail: "",
          authorimage: "",
          editimage: "",
          Authorabout: "",
          button: "Submit",
          data: previousdata,
          filename: "",
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);

      }
    } catch (error) {
      console.log(error);
    }
  };
  onDismiss = () => {
    this.setState({ alertVisible: false });
  };
  render() {
    const {
      editvalue,
      Authorname,
      Authoremail,
      Authorabout,
      authorimage,
      button,
      editimage,
      alertVisible,
      errorAuthorname,
      errorAuthoremail,
      errorAuthorabout,
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Author Master</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          Author Master Added
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="Authorname">Author Name</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          id="Authorname"
                          name="Authorname"
                          placeholder="name"
                          value={Authorname}
                          onChange={(e) => this.authorname(e)}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorAuthorname}</span>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="Authoremail">Author Email</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          id="Authoremail"
                          name="Authoremail"
                          placeholder=" email"
                          value={Authoremail}
                          onChange={(e) => this.authoremail(e)}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorAuthoremail}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="Authormobile">Author about</label>
                      </div>
                      <div className="col-sm-5">
                        <textarea
                          type="text"
                          className="form-control"
                          id="Authorabout"
                          name="Authorabout"
                          placeholder="Enter about"
                          value={Authorabout}
                          onChange={(e) => this.aboutvalue(e)}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorAuthorabout}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="authorimage">Upload Image</label>
                      </div>
                      <div className="col-sm-5 custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="authorimage"
                          onChange={(e) => this.authorimage(e)}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="authorimage"
                        >
                          {this.state.filename}
                        </label>
                      </div>

                      {/* {this.state.editimage && <img src={this.state.editimage} alt="image" />}
                       */}
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={
                            button === "Update" ? this.update : this.addnew
                          }
                        >
                          {this.state.button}
                        </button>
                      </div>

                      <div className="col-sm-3" />
                    </div>
                    <br></br>
                    {this.state.excel && this.state.excel.length > 0 ? (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <AddauthorPDF
                            title={this.state.title}
                            header={this.state.header}
                            data={this.state.excel}
                          />
                        }
                        fileName="AddAuthor.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    ) : null}
                    {this.state.data && this.state.data.length ? (
                      <CSVLink
                        data={this.state.data}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          Excel
                        </button>
                      </CSVLink>
                    ) : null}
                    <br></br>
                    <br></br>

                    {this.state.data && (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default AddAuthor;
