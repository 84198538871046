import React, { Component } from "react";
import CmsContent from "../../MiddleWare/CmsContent";
import DownPanel from "./DownPanel";
import RightPanel from "./RightPanel";
import renderHTML from "react-render-html";
import "./style.css";
import "./videoStyle.css";
import NewVideoPlayer from "../../components/Extra/NewVideoPlayer";
import PreLoader from "./preLoader";
import http from "../../MiddleWare/httpMiddleWare";


class SingleContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageList: "",
      date: ""
    };
    let userlog = localStorage.getItem("userlog");
    userlog = JSON.parse(userlog);
    localStorage.setItem("userId", userlog.id);
    this.state.userId=userlog.id;
    localStorage.setItem("userName", userlog.userName);
    localStorage.setItem("userEmail", userlog.email);
    localStorage.setItem("userMobile", userlog.mobileNumber);
  }

  async componentDidMount() {
    let themeid = this.props.match.params.id;

    const formData=new FormData;
    formData.append('userId',this.state.userId);
    formData.append('pageid',themeid);
    await CmsContent.pageViewCount(formData);

    let ipaddressget= await http.get('https://ipapi.co/json/',function(data) {
      console.log(JSON.stringify(data, null, 2));
    })

    let date = new Date();
     var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
     var day = date.getUTCDate();
     var year = date.getUTCFullYear();
     var hour = date.getHours();
     var minutes = date.getMinutes();
     var seconds = date.getSeconds();
     var newdate = year + "-" + month + "-" + day;
     var timesDate =  year +"-" +month + "-" +day +" " + hour +":" + minutes +":" +seconds+'';
     const userlog=new FormData;
     userlog.append('userid',JSON.parse(localStorage.getItem("userlog")).id)
     userlog.append('ipaddress',ipaddressget.data.ip)
     userlog.append('datetime',timesDate)
     userlog.append('date',newdate)
     userlog.append('pageid',themeid)
     userlog.append('customerId',JSON.parse(localStorage.getItem("userlog")).customerId) 
     console.log([...userlog])
  let datauser=await CmsContent.addMaster('tbl_CMSuserAccessLog',userlog);
  console.log(datauser.data)
    
    const result = await CmsContent.getThemeById(themeid);

    if (result) {
      if (result.data[0].Date != null && result.data[0].Date != undefined) {
        var dataformate = result.data[0].Date.split("-");
        this.setState({
          date: dataformate[2] + "-" + dataformate[1] + "-" + dataformate[0]
        });
      }
      this.setState({ pageList: result.data[0], themeid });
    }
  }

  async componentWillReceiveProps(nextProps) {
    let themeid = nextProps.match.params.id;
    let ipaddressget= await http.get('https://ipapi.co/json/',function(data) {
      console.log(JSON.stringify(data, null, 2));
    })

    let date = new Date();
     var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
     var day = date.getUTCDate();
     var year = date.getUTCFullYear();
     var hour = date.getHours();
     var minutes = date.getMinutes();
     var seconds = date.getSeconds();
     var newdate = year + "-" + month + "-" + day;
     var timesDate =  year +"-" +month + "-" +day +" " + hour +":" + minutes +":" +seconds+'';
     const userlog=new FormData;
     userlog.append('userid',JSON.parse(localStorage.getItem("userlog")).id)
     userlog.append('ipaddress',ipaddressget.data.ip)
     userlog.append('datetime',timesDate)
     userlog.append('date',newdate)
     userlog.append('pageid',themeid)
     userlog.append('customerId',JSON.parse(localStorage.getItem("userlog")).customerId) 
     console.log([...userlog])
  let datauser=await CmsContent.addMaster('tbl_CMSuserAccessLog',userlog);
  console.log(datauser.data)

    const formData=new FormData;
    formData.append('userId',this.state.userId);
    formData.append('pageid',themeid);
    let countResult=await CmsContent.pageViewCount(formData);
    
    const result = await CmsContent.getThemeById(themeid);
    if (result) {
      if (result.data[0].Date != null && result.data[0].Date != undefined) {
        var dataformate = result.data[0].Date.split("-");
        this.setState({
          date: dataformate[2] + "-" + dataformate[1] + "-" + dataformate[0]
        });
      }
      this.setState({ pageList: result.data[0] ,countViews:countResult.data.resultcount});
    }
  }
  coverContent = con => {
    if (this.state.pageList.linkto) {
      return <a href={`${this.state.pageList.linkto}`}>{con}</a>;
    }
    return con;
  };
  render() {
    const { pageList, date } = this.state;
    if (!pageList) {
      return <PreLoader />;
    } else {
      return (
        <React.Fragment>
          <div className="container-fluid container-margin-top">
            <div className="row form-group">
              <div className="col-sm-9">
                <div className="card">
                  <div className="card-body">
                    <div className="row form-group">
                      <a href="">
                        {this.coverContent(
                          <h2 className="theme-tiile text-capitalize">
                            {pageList.contentTitle1}
                          </h2>
                        )}
                      </a>
                    </div>
                    {this.coverContent(
                      <p
                        style={{
                          color: "#828282",
                          fontSize: 14,
                          lineHeight: 2,
                          fontWeight: 400,
                          marginTop: 0,
                          paddingTop: 0
                        }}
                      >
                        {/* {"Last Updated  :  "} */}
                        {/* {date} */}
                      </p>
                    )}
                    <br />
                    <div className="row form-group">
                      <div className="col-sm-12">
                        {/* {pageList.content1 && this.coverContent(<p className="content-para">{renderHTML(pageList.content1)}</p>)} */}
                      </div>
                    </div>
                    <br />
                    <div
                      className="row form-group"
                      style={{ textAlign: "center" }}
                    >
                      <div className="col-sm-12">
                        {pageList.file &&
                          this.coverContent(
                            <img
                              className="box-shadow-img"
                              src={pageList.file}
                              alt="something went wrong"
                            />
                          )}
                      </div>
                    </div>
                    <div className="col-sm-12">
                      {pageList.content1 &&
                        this.coverContent(
                          <p className="content-para">
                            {renderHTML(pageList.content1)}
                          </p>
                        )}

                      {this.state.themeid && false && (
                        <NewVideoPlayer Video={this.state.themeid} />
                      )}
                    </div>
                    {/* <div
                      className="row video-view-eye"
                      style={{ paddingLeft: "0", marginLeft: "-28px" }}
                    >
                      <span className="view-section">
                        <i class="fa fa-eye icon-left" aria-hidden="true">
                          <span className="video-count-spacing">{`${this.state.countViews} views`}</span>
                        </i>
                      </span>
                    </div> */}
                    <br />
                    <br />
                    <br />
                    <div
                      className="blog-post-author d-flex"
                      style={{ marginTop: "42px" }}
                    >
                      {pageList && pageList.show === "1" && (
                        <div>
                          <div className="author-thumbnail">
                            <img src={pageList.authorimage} alt="" />
                          </div>
                          <div className="author-info">
                            <a className="author-name">
                              {pageList.authorname}, <span>The Author</span>
                            </a>
                            <p>{pageList.authorabout}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="card">
                  <div className="card-body">
                    <RightPanel />
                  </div>
                </div>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-12 mb-new">
                <div className="card">
                  {/* <div className="card-body px-0">
                    <DownPanel />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default SingleContent;
