import React, { Component } from 'react';
import userimage1 from '../../images/CMS/19.jpg';
import userimage2 from '../../images/CMS/20.jpg';
import userimage4 from '../../images/CMS/22.jpg';
import { Link } from 'react-router-dom';
import cmsContent from '../../MiddleWare/CmsContent';

class LeftPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
      adminId: JSON.parse(localStorage.getItem("userlog")).customerId
    };
  }
  async componentDidMount() {

    const result = await cmsContent.getFreedom(
      'tbl_pages.Date,tbl_pages.id,tbl_rightPanel_mapping.categoryName,tbl_rightPanel_mapping.content1,tbl_rightPanel_mapping.file,theme_master.themeName',
      'tbl_rightPanel_mapping,tbl_pages,theme_master',
      `tbl_pages.id = tbl_rightPanel_mapping.pageId and tbl_pages.themeId = theme_master.themeId and tbl_rightPanel_mapping.status ='active' and tbl_pages.customerId=${this.state.adminId} and tbl_rightPanel_mapping.customerId=tbl_pages.customerId`,
      'tbl_rightPanel_mapping.id',
      'tbl_rightPanel_mapping.id')

    if (result) {
      this.setState({ dataList: result.data });
    }
  }
  render() {
    return (
      <React.Fragment>
        {this.state.dataList.map(value => (
          <div className="single-blog-post small-featured-post d-flex">
            <div className="post-thumb">
              <img src={value.file} alt="" className="w-100" />
            </div>
            <div class="post-data">
              <span className="post-catagory">{value.categoryName}</span>
              <div className="post-meta">
                <Link className="nav-link post-title content-padding" to={`/CMS/${value.themeName}/${value.id}`}>
                  <h6 className="comment-panel-color rightpanel-zeromargin">{value.content1}.</h6>
                </Link>

                <p className="post-date">
                  <span>7:00 AM</span> | <span>April 14</span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </React.Fragment>
    );
  }
}

export default LeftPanel;
