import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import CMSaddcatsubcatpdf from "../../../components/PDF/Cmsaddcatsubcat";
import { toLower } from "lodash";

class Department extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      data: [],
      alertVisible: false,
      editvalue: "",
      editid: null,
      disableValue: false,
      addNewSubCategory: false,
      error: null,
      button: "Submit",
      msglock:false,
      index: null,
      formAlertdelete: false,
      customerId: localStorage.getItem("userId"),
    };
  }
  async componentDidMount() {
    try {
      // const result = await cmsContent.getFreedom("tbl_category_master");

      const result = await cmsContent.getFreedom(
        "*",
        "tbl_department_master",
        `customerId='${this.state.customerId}'`,
        "id",
        "id DESC"
      );

      if (result) {
        //console.log(result);
        this.setState({ data: result.data });
      }
      if (result.data.length > 0) {
        const header = ["SI.NO", "CATEGORYNAME", "STATUS"];
        const excelHead = [
          { label: "CATEGORYNAME", key: "name" },
          { label: "STATUS", key: "status" },
        ];
        this.setState({
          excelHead,
          header,
          excel: result.data,
          title: "CATEGORY",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  column = [
    {
      Header: "Department",
      accessor: "name",
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: (d) => this.edit(d),
    },
    {
      Header: "Delete",
      accessor: "delete",
      Cell: (d) => this.delete(d),
    },
  ];
//   updatestatus = (d) => {
//     if (d.original.status == "active") {
//       return <span style={{ color: "green", fontWeight: "bold" }}>Show</span>
//     } else {
//       return <span style={{ color: "red", fontWeight: "bold" }}>Hide</span>
//     }
//   };
//   updatevisible = (id) => {
//     return (
//       <button
//         type="button"
//         className="btn btn-info"
//         onClick={() => this.updateVisibility(id)}
//       >
//         Hide/Show
//       </button>
//     );
//   };
//   updateVisibility = async (value) => {
//     //console.log(value);
//     const index = value.index;
//     const previousData = [...this.state.data];
//     const newData = { ...previousData[index] };

//     if (newData.status === "active") {
//       newData.status = "inactive";
//     } else {
//       newData.status = "active";
//     }
//     const id = newData.id;
//     const data = previousData.filter((value) => value.id !== id);
//     data.splice(index, 0, newData);
//     console.log(data);

//     try {
//       const result = await cmsContent.updatecatsubcat(
//         "tbl_category_master",
//         newData
//       );
//       if (result) {
//         this.setState({ data: data });
//       }
//     } catch (error) {
//       this.setState({ data: previousData });
//       console.log(error);
//     }
//   };

  edit = (d) => {
    let value = d;
    return (
      <button
        type="button"
        className="btn btn-info"
        onClick={() => this.edition(value)}
      >
        Edit
      </button>
    );
  };
  edition = (value) => {
    let values = value.original;
    this.setState({
      index: value.index,
      editvalue: values.name,
      editid: values.id,
      button: "Update",
    });
  };
  delete = (d) => {

    return (
      <button
        type="button"
        className="btn btn-danger"
        onClick={() => this.deletion(d)}
      >
        Delete
      </button>
    );
  };
  deletion = async (value) => {
    //Getting Previous state data

    const previousData = [...this.state.data];
    // Seperating data row using row-index
    const getData = { ...previousData[value.index] };

    //getting id on that data
    const id = getData.id;
    //removing specific id in previous state data
    const data = previousData.filter((delelteid) => delelteid.id !== id);

    try {
      const result = await cmsContent.deleteMaster(
        "tbl_department_master",
        id
      );
      if (result) {
        this.setState({ editvalue: "", formAlertdelete: true, data });

        setTimeout(() => this.setState({ formAlertdelete: false }), 3000);
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };
  handlechange = (e) => {
    //let value = e.target.value; 
     
    this.setState({ editvalue: e.target.value }); 
   

  };


  addnew = async () => {
    
    if (!this.edit) {
      this.setState({ errorColourName: "Enter category" });
      return false;
    }
    if(this.state.data){
      
    let filtered_item =
    this.state.data && this.state.data.length > 0
        ? this.state.data.filter(i => {
          return i.name.toLowerCase() === this.state.editvalue.toLowerCase();;
        })
        : [];

    if (filtered_item.length > 0) {
      this.setState({msglock:true})
           this.setState({msgforcheck:"Already Added"})
      return false;
    }}
    this.submit()
       
  };

submit = async()=>{
    let value = this.state.editvalue;
    let customerId = this.state.customerId;
    if (value === null || value.trim() === "") {
      return false;
    }
    let categoryArray = {};
    categoryArray.name = value.trim();
    categoryArray.status = "active";
    categoryArray.customerId = customerId;
    categoryArray.serviceId = localStorage.getItem("currentService")

    try {
      this.setState({ disableValue: true });
      const result = await cmsContent.addMaster(
        "tbl_department_master",
        categoryArray
      );

      if (result) {
        let valueArray = {};
        valueArray.value = result.data.insertId;
        valueArray.name = categoryArray.name;
        console.log(valueArray);
        const newData = [valueArray, ...this.state.data];
        this.setState({
          data: newData,
          alertVisible: true,
          editvalue: "",
          editid: null,
          disableValue: false,
          addNewSubCategory: false,
          error: null,
          button: "Submit",
          index: null,
        });
      }
    } catch (error) {
      console.log(error);
    }
}

  update = async () => {
    if(this.state.data){
      
      let filtered_item =
      this.state.data && this.state.data.length > 0
          ? this.state.data.filter(i => {
            return i.name.toLowerCase() === this.state.editvalue.toLowerCase();;
          })
          : [];
  
      if (filtered_item.length > 0) {
        this.setState({msglock:true})
             this.setState({msgforcheck:"Already Added"})
        return false;
      }}

    let value = this.state.editvalue;
    if (value === "") {
      this.setState({ error: "Value Cannot Be Empty" });
      return false;
    } else {
      this.setState({ disableValue: true });
      try {
        let previousdata = [...this.state.data];
        let index = this.state.index;

        let id = this.state.editid;
        let subCategoryArray = {};
        subCategoryArray.name = value;
        subCategoryArray.status = "active";
        const result = await cmsContent.updateMaster(
            "tbl_department_master",
          id,
          subCategoryArray
        );
        if (result) {
          previousdata[index].name = value;
          this.setState({ data: previousdata, alertVisible: true });
        }
      } catch (error) {
        console.log(error);
      }
      this.setState({
        alertVisible: true,
        editvalue: "",
        editid: null,
        addNewSubCategory: false,
        error: null,
        button: "Submit",
        index: null,
      });
    }
  };
  onDismiss = () => {
    this.setState({ alertVisible: false });
    this.setState({ formAlertdelete: false });
    this.setState({msglock:false})
  };
  render() {
    const {
      editvalue,
      alertVisible,
      disableValue,
      error,
      button,
      formAlertdelete,
      msgforcheck,
      msglock,
    } = this.state;

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Department</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color="success"
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          New Department Added
                        </Alert>
                        <Alert
                          className="badge-content"
                          color="success"
                          isOpen={msglock}
                          toggle={this.onDismiss}
                        >
                          {msgforcheck}
                        </Alert>

                        <Alert
                          className="badge-content"
                          color="danger"
                          isOpen={formAlertdelete}
                          toggle={this.onDismiss}
                        >
                         Department Delete
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="subcategory">Department</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          name="subcategory"
                          placeholder="Enter Department"
                          className="form-control"
                          value={editvalue}
                          onChange={(e) => this.handlechange(e)}
                        />
                        <span className="modal-error-show">{error}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={disableValue}
                          onClick={
                            button === "Update" ? this.update : this.addnew
                          }
                        >
                          {this.state.button}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <br></br>
                    {this.state.excel && this.state.excel.length > 0 ? (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <CMSaddcatsubcatpdf
                            title={this.state.title}
                            header={this.state.header}
                            data={this.state.excel}
                          />
                        }
                        fileName="Addcategory.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    ) : null}
                    {this.state.data && this.state.data.length ? (
                      <CSVLink
                        data={this.state.data}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          Excel
                        </button>
                      </CSVLink>
                    ) : null}
                    <br></br>
                    <br></br>
                        {console.log(this.state.data)}
                    {this.state.data && (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Department;
