import React from 'react';
import { Alert } from 'reactstrap';

const AddCategory = ({ category, handleChange, addNewCategory, error, alert, dismiss, disableValue, alertMsg }) => {
  return (
    <React.Fragment>
      <div>
        <Alert className="badge-content" color="success" isOpen={alert} toggle={dismiss}>
          {alertMsg}
        </Alert>
        <div className="row form-group">
          <div className="col-sm-1" />
          <div className="col-sm-3">
            <label for="exampleInputEmail1">Category Name</label>
          </div>
          <div className="col-sm-7">
            <input type="text" class="form-control" id="category" name="category" placeholder="Enter Category" onChange={handleChange} value={category} />
          </div>
          <div className="col-sm-1" />
        </div>
        <div className="row form-group">
          <div className="col-sm-4" />
          <span className="modal-error-show">{error}</span>
        </div>

        <div className="row form-group">
          <div className="col-sm-4" />
          <div className="col-sm-4">
            <button type="button" class="btn btn-primary" disabled={disableValue} onClick={addNewCategory}>
              Add Category
            </button>
          </div>
          <div className="col-sm-4" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddCategory;
