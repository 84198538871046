import React, { Component } from 'react';
import { HorizontalBar } from 'react-chartjs-2';

class HorizontalBarChart1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start:0,
      end:10,
      bar: {
        labels: ['-'],
        datasets: [
          {
            label: 'Duration',
            fill: false,
            backgroundColor: [
              '#007BC3',
              '#FF303E',
              '#FF6384',
              '#4BC0C0',
              '#FFCE56',
              '#E7E9ED',
              '#007BC3',
              '#FF303E',
              '#FF6384',
              '#4BC0C0',
              '#FFCE56',
              '#E7E9ED',
              '#36A2EB'
            ],
            borderColor: [
              '#007BC3',
              '#FF303E',
              '#FF6384',
              '#4BC0C0',
              '#FFCE56',
              '#E7E9ED',
              '#007BC3',
              '#FF303E',
              '#FF6384',
              '#4BC0C0',
              '#FFCE56',
              '#E7E9ED',
              '#36A2EB'
            ],
            borderWidth: 1,
            hoverBackgroundColor: [
              '#007BC3',
              '#FF303E',
              '#FF6384',
              '#4BC0C0',
              '#FFCE56',
              '#E7E9ED',
              '#007BC3',
              '#FF303E',
              '#FF6384',
              '#4BC0C0',
              '#FFCE56',
              '#E7E9ED',
              '#36A2EB'
            ],
            hoverBorderColor: [
              '#007BC3',
              '#FF303E',
              '#FF6384',
              '#4BC0C0',
              '#FFCE56',
              '#E7E9ED',
              '#007BC3',
              '#FF303E',
              '#FF6384',
              '#4BC0C0',
              '#FFCE56',
              '#E7E9ED',
              '#36A2EB'
            ],
            data: [0]
          }
        ]
      }
    }
  }
  back=()=>{
    if(this.state.start !==0){
      this.setState({ start:this.state.start-10, end:this.state.end-10 })
    }
  }
  next=()=>{
    if(this.state.end < this.props.dataValue.length){
      this.setState({ start:this.state.start+10, end:this.state.end+10 })
    }
  }
  render() {
    let { dataValue, barOptions, rest } = this.props;
    // console.log(dataValue)
    // console.log(barOptions)
    const dataTableData = [];
    const data = dataValue.map((ival,i)=>{
      if((i >= this.state.start) && (i < this.state.end)){
        dataTableData.push(ival);
      }
    })
    var oldDataSet = this.state.bar.datasets[0];
    var newData = [];
    var newLabel = [];
    for (var x = 0; x < dataTableData.length; x++) {
      newData.push(dataTableData[x].duration);
      newLabel.push(dataTableData[x].username);
    }
    var newDataSet = {
      ...oldDataSet
    };
    newDataSet.data = newData;
    newDataSet.label = "Duration";
    var newState = {
      labels: [...newLabel],
      datasets: [newDataSet]
    };
    return (
      <React.Fragment>
        <div>
          <HorizontalBar data={newState} {...rest} options={barOptions} />
        </div>
        <div>
          <div>
            <a onClick={() => this.back()}><i className="fa fa-chevron-left rtReportColor arrowinRTreport"></i></a>
            <a onClick={() => this.next()}><i className="fa fa-chevron-right rtReportColor arrowinRTreport"></i></a>
            &nbsp;
            <span className="rtReportColor">{this.state.start + 1} to {this.state.end} of {dataValue.length}</span>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default HorizontalBarChart1;
