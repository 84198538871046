import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import SingleSelect from "../../../components/Form/SingleSelect";
import Datatable from "../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import { async } from "q";

class FormReport extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      formList: [],
      answerList: [],
      userList: [],
      SingleFormStatus: false
    };
  }
  async componentWillMount() {
    try {
      let customerId = await localStorage.getItem("userId");
      console.log(customerId);
      let serviceId = await localStorage.getItem("currentService");

      let formList = await CmsContent.getTwoConditionedValue(
        "tbl_form",
        "companyid",
        customerId,
        "status",
        "active",
        "name as label,id as value"
      );

      let questionList = await CmsContent.getTwoConditionedValue(
        "tbl_formquestion",
        "companyid",
        customerId,
        1,
        1
      );

      let answerList = await CmsContent.getTwoConditionedValue(
        "tbl_formanswer",
        "companyid",
        customerId,
        1,
        1,
        "formid,answer,userid,createdAt"
      );

      let learnerList = await CmsContent.getFreedom(
        "id as value,userName as label",
        "tbl_user_web",
        `customerId =${customerId} and serviceId =${serviceId} and status ='active'`,
        1,
        1
      );

      // console.log(learnerList.data,"l");
      // console.log(answerList.data,"a");
      // console.log(formList.data,'f');
      // console.log(questionList.data,'q');

      this.setState({
        customerId,
        formList: formList.data,
        answerList: answerList.data,
        learnerList: learnerList.data,
        questionList: questionList.data
      });
    } catch (error) {
      console.log(error);
    }
  }
  column = [
    {
      Header: "Created On",
      accessor: "createdAt"
    },
    {
      Header: "View",
      accessor: "view",
      Cell: d =>
        this.dataTableButton("primary", "View", () => {
          this.viewAnswer(d);
        })
    }
  ];
  viewAnswer = e => {
    this.setState({
      SingleForm: e.original.singleForm,
      SingleFormStatus: true
    });
  };
  columns = [
    {
      Header: "Question",
      accessor: "question"
    },
    {
      Header: "Answer",
      accessor: "answer"
    }
  ];
  getUnique(array) {
    let uniqueArray = [];
    // Loop through array values
    for (var i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  }
  getQuestionAnswer(e, answerList, uOqa) {
    let formId = e.value;
    // let questions=JSON.parse(e.formjson);
    let questions = this.state.questionList;

    let report = [];
    let uniqueArray = [];
    let usersList = answerList.map((ival, i) => {
      if (formId === ival.formid) {
        if (uOqa === 1) {
          let reportList = [];
          let answers = JSON.parse(ival.answer);
          questions.map((jval, j) => {
            reportList.push({
              question: jval.question,
              questionId: jval.parentid
            });
            //reportList.push({question:jval.question,questionId: jval.parentid ,answer:answers[j].answer,createdAt:ival.createdAt})
          });
          report.push(reportList);
        } else if (uOqa === 0) {
          if (uniqueArray.indexOf(ival.userid) === -1) {
            uniqueArray.push(ival.userid);
            report.push({ userid: ival.userid });
          }
        }
      }
    });
    // console.log(report, "report");
    return report;
  }
  getuserDetails(list, learnerList) {
    let array = [];
    list.map((ival, i) => {
      learnerList.map((jval, j) => {
        if (ival.userid === jval.value) {
          array.push(jval);
        }
      });
    });
    return array;
  }
  formSelect = e => {
    let report = this.getQuestionAnswer(e, this.state.answerList, 0);

    let userList = this.getuserDetails(report, this.state.learnerList);

    this.setState({ formSelect: e, report, userList, id: e.value });
    this.resetState();
    this.formSelect1(e);
  };
  resetState = () => {
    this.setState({ fullData: [], datatable: [] });
    if (this.state.userSelect) {
      this.setState({ userSelect: [] });
    }
  };
  formSelect1 = async (formSelect) => {
    // let formSelect = await this.state.formSelect;
    // console.log(formSelect,"formSelect");
    let userForm = await CmsContent.getFreedom(
      "answer as answer , formid as value",
      "tbl_formanswer",
      `formid = ${formSelect.value}  and companyid = ${this.state.customerId}`,
      "id",
      "id desc"
    );

    let heading = await CmsContent.getFreedom(
      "question as name",
      "tbl_formquestion",
      `tbl_formquestion.parentid = ${formSelect.value}`,
      "id",
      "id desc"
    );


    if (userForm && heading && heading.data.length && userForm.data.length) {
      let k = 0;
      let datatable = [];
      let fullData = [];
      // userForm.data = await userForm.data.reverse()
      // heading.data = heading.data.reverse()
      heading.data.map((ival, i) => {
        datatable.push({ id: i, accessor: i.toString(), Header: ival.name });
      });
      for (
        let i = 1, count = Math.round(userForm.data.length / heading.data.length);
        i <= count;
        i++
      ) {
        let halfData = [];
        heading.data.map((jval, j) => {
          halfData.push(userForm.data[k].answer);
          k = k + 1;
        });
        fullData.push({ ...halfData });
      }
      // console.log('datatable,form', datatable[0], userForm.data[0]);  
      this.setState({ datatable, fullData });
    }
  }
  userSelect = async userSelect => {
    let formSelect = await this.state.formSelect;
    // console.log(formSelect, "formSelect");
    let userForm = await CmsContent.getFreedom(
      "answer as answer , formid as value",
      "tbl_formanswer",
      `formid = ${formSelect.value} and userid = ${userSelect.value} and companyid = ${this.state.customerId}`,
      "id",
      "id desc"
    );

    let heading = await CmsContent.getFreedom(
      "question as name",
      "tbl_formquestion",
      `tbl_formquestion.parentid = ${formSelect.value}`,
      "id",
      "id desc"
    );

    this.setState({ userSelect: userSelect });
    if (userForm && heading && heading.data.length && userForm.data.length) {
      let k = 0;
      let datatable = [];
      let fullData = [];
      // userForm.data = userForm.data.reverse()
      heading.data.map((ival, i) => {
        datatable.push({ id: i, accessor: i.toString(), Header: ival.name });
      });
      for (
        let i = 1, count = Math.round(userForm.data.length / heading.data.length);
        i <= count;
        i++
      ) {
        let halfData = [];
        heading.data.map((jval, j) => {
          halfData.push(userForm.data[k].answer);
          k = k + 1;
        });
        fullData.push({ ...halfData });
      }
      this.setState({ datatable, fullData });
    }
    // if(formSelect.value){
    //   let datatable = [];
    // // let questionColumn = JSON.parse(formSelect.formjson);
    // let questionColumn = this.state.questionList;
    //   this.state.formList.filter((item,i)=>{

    //     if(item.value == formSelect.value){
    //       questionColumn.filter((element,key)=>{
    //         if(item.value == element.parentid){
    //           datatable.push({accessor: key.toString(), Header: element.question})
    //         }
    //       })
    //     }
    //   })
    // let fullData = [];
    // userForm.data.map((ival, i) => {
    //       let halfData = [];
    //       // let answer = JSON.parse(ival.Answerjson);
    //       let answer = this.state.answerList;
    //       questionColumn.map((jval, j) => {
    //           halfData.push(answer[j].answer)
    //       });
    //       fullData.push({ ...halfData })
    //   });
    // this.setState({ datatable, fullData });
    // }
  };
  render() {
    // console.log(this.state.formSelect, "formSelec");
    // console.log(this.state.userList, 'userlist');
    // console.log(this.state.fullData, 'data');
    const {
      formList,
      formSelect,
      answerList,
      report,
      userList,
      userSelect,
      finalUsers,
      datatable,
      SingleFormStatus,
      SingleForm
    } = this.state;

    if (SingleFormStatus) {
      var column = this.columns;
      var datatables = SingleForm;
    } else {
      var column = this.column;
      var datatables = datatable;
    }
    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h1>Form Report</h1>
                </div>
                <div className="card-body" style={{ minHeight: "300px" }}>
                  {formList ? (
                    <div>
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-2">
                          <label for="exampleInputEmail1">Select Form</label>
                        </div>
                        <div className="col-sm-5">
                          <SingleSelect
                            options={formList}
                            handleChange={this.formSelect}
                            selectedService={formSelect}
                          />
                          {/* <span className="error-shows">{errorselect}</span> */}
                        </div>
                        <div className="col-sm-3" />
                      </div>

                      {userList.length > 0 && (
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label for="exampleInputEmail1">Select User</label>
                          </div>
                          <div className="col-sm-5">
                            <SingleSelect
                              options={userList}
                              handleChange={this.userSelect}
                              selectedService={userSelect}
                            />
                            {/* <span className="error-shows">{errorselect}</span> */}
                          </div>
                          <div className="col-sm-3" />
                        </div>
                      )}
                    </div>
                  ) : null}
                  {SingleFormStatus &&
                    this.dataTableButton("danger", "Back", () => {
                      this.setState({ SingleFormStatus: false });
                    })}
                  {/* {datatables &&  (
                    <Datatable data={datatables}columnHeading={column}/>)} */}
                  {this.state.fullData && this.state.fullData.length ? (
                    <Datatable
                      data={this.state.fullData}
                      columnHeading={this.state.datatable}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default FormReport;
