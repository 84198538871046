import React, { Component } from "react";
import cmsContent from "../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import StepZilla from "react-stepzilla";
import "./cssStyle.css";
import { endOfDay } from "date-fns/esm";
import { element } from "prop-types";

class SurveyForm extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      userId: this.props.location.pathname.split("/")[3],
      surveyId: this.props.location.pathname.split("/")[4],
      portid: this.props.location.pathname.split("/")[5],
      customerId: localStorage.getItem("userId"),
      data: [],
      steps: [],
      step: 0,
      radio: [],
      sta: "none"
    };
  }
  async componentDidMount() {
    try {
      let userDetails = await cmsContent.getFreedom(
        "*",
        "tbl_user_web",
        `id=${this.state.userId}`,
        "id",
        "id"
      );

      let SurveyList = await cmsContent.getFreedom(
        "*",
        "tbl_survey,tbl_surveyTitle",
        `tbl_survey.id=${this.state.surveyId} and tbl_surveyTitle.surveyId =${this.state.surveyId}`,
        1,
        "tbl_survey.id"
      );
      let SurveyTitle = await cmsContent.getFreedom(
        "*",
        "tbl_surveyTitle",
        `surveyId = ${this.state.surveyId}`,
        1,
        "id"
      );

      let SurveyQuestion = await cmsContent.getFreedom(
        "*",
        "tbl_surveyQuestion",
        `1`,
        1,
        "id"
      );

      let mappedSurvey = await cmsContent.getFreedom(
        "*",
        "tbl_survey_mapping",
        `tbl_survey_mapping.surveyid =${this.state.surveyId}`,
        1,
        "id"
      );

      let dataInside = [];
      if (mappedSurvey.data && SurveyQuestion.data && SurveyTitle.data) {
        SurveyTitle.data.map((val, index) => {
          mappedSurvey.data.map((item, i) => {
            SurveyQuestion.data.filter((element, key) => {
              if (
                item.surveyid == this.state.surveyId &&
                val.id == item.titleId &&
                item.surveyQuestionid == element.id
              ) {
                dataInside.push({
                  surveyId: item.surveyid,
                  quesId: element.id,
                  questions: element.questions,
                  options: element.options,
                  marks: element.marks,
                  titleId: item.titleId
                });
              }
            });
          });
        });
      }
      let Answer = [];
      if (SurveyList.data && SurveyTitle.data) {
        SurveyTitle.data.map((item, i) => {
          Answer[i] = [];
          dataInside.map((jval, j) => {
            if (jval.titleId == SurveyTitle.data[i].id) {
              Answer[i][j] = [];
              jval.options.split(",").map((kval, k) => {
                Answer[i][j][k] = false;
              });
            } else {
              Answer[i][j] = [];
              jval.options.split(",").map((kval, k) => {
                Answer[i][j][k] = false;
              });
            }
          });
        });
      }
      this.setState({
        userDetails: userDetails.data[0],
        SurveyList: SurveyList.data,
        SurveyTitle: SurveyTitle.data,
        SurveyQuestion: SurveyQuestion.data,
        mappedSurvey: mappedSurvey.data,
        dataInside,
        Answer
      });
      this.process();
    } catch (error) {
      console.log(error);
    }
  }

  process = async () => {
    const {
      SurveyList,
      SurveyTitle,
      SurveyQuestion,
      mappedSurvey,
      surveyId
    } = this.state;
    let dataInside = this.state.dataInside;
    console.log(dataInside);
    let len = SurveyTitle.length;
    let l = dataInside.length;
    let steps = [];
    let ii = 0;
    SurveyTitle.map(async (item, i) => {
      ii = i + 1;
      await steps.push({
        name: `Step ${i + 1}`,
        component: (
          <div>
            <div className="card card-shadow">
              {/* <div className="card-header lightGrey"> */}
              <div className="row">
                <div className="col-sm-12">
                  <center>
                    <h1>{item.title}</h1>
                  </center>
                </div>
                {/* </div> */}
              </div>
              <div className="card-body">
                <div className="row col-sm-12">
                  <div style={{ textAlign: "center", margin: "auto" }}>
                    {item.description}
                  </div>
                </div>
              </div>
            </div>
            {this.limitedEdition(i + 1, item, dataInside, l)}
          </div>
        )
      });
      // this.setState({ step: i });
    });
    // await steps.push({
    //   name: `Step ${ii} + 1`,
    //   component: <div>Survey has been completed.</div>
    // });
    // }
    if (dataInside && dataInside.length) {
      steps.push({
        name: `Step ${SurveyTitle.length + 1}`,
        component: <div>Survey has been completed.</div>
      });
    }
    this.setState({ steps: steps });
  };

  limitedEdition = (step, item, data, limit) => {
    let content = [];
    const { surveyId, SurveyTitle } = this.state;
    let count = 0;
    data.map((ival, i) => {
      if (ival.surveyId == surveyId && ival.titleId == item.id) {
        count = count + 1;
        content.push(this.getContent(ival, i, data.length, step, count));
      }
    });
    return content;
  };
  getContent = (ival, i, l, step, count) => {
    let Answer = this.state.Answer;
    return (
      <div className="card card-shadow">
        <div className="card-header lightGrey">
          <div className="row">
            <div className="col-sm-12">
              <h1 className="question-align">
                {`${count})  ${ival.questions}`}
              </h1>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row col-sm-12">
            {ival.options.split(",").map((jval, j) => {
              return (
                <div class="col-sm-12">
                  <input
                    type="radio"
                    name={`radio${step - 1}` + [i]}
                    // name={`radio${step}` + [i] + "" + [j]}
                    value={jval}
                    onChange={e => this.quizAnswered(e, i, j, step)}
                    checked={Answer[step - 1][i][j]}
                  />
                  <label className="ml-1">{jval}</label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  quizAnswered = async (e, id, j, step) => {
    let data = this.state.dataInside;
    let Answer = this.state.Answer;
    let stepEnd = this.state.dataInside.length;
    let Step = step - 1;

    Answer[step - 1][id] = Answer[step - 1][id].map((ival, i) => {
      if (j === i) {
        return true;
      } else {
        return false;
      }
    });
    this.setState({ Answer });
    this.process();
  };

  NextButton = st => {
    document
      .getElementsByClassName("pull-right")[0]
      .setAttribute("style", `display:${st} !important`);
  };

  submitQuiz = async step => {
    const { Answer, dataInside, userId, SurveyList, userDetails } = this.state;
    let data = dataInside;
    let answer = [];
    if (step == SurveyList[0].pagelimit) {
      Answer.map(async (kval, k) => {
        kval.map(async (ival, i) => {
          ival.map(async (jval, j) => {
            if (jval === true) {
              let question = data[i].options.split(",");
              let mark = data[i].marks.split(",");
              await answer.push({
                questionid: data[i].quesId,
                surveyId: this.state.surveyId,
                surveyAnswer: question[j],
                mark: mark[j],
                userid: userId,
                customerid: userDetails.customerId,
                portId: this.state.portid
              });
            } else {
              return false;
            }
          });
        });
      });
      let result = await cmsContent.addSurveyAnswer("tbl_surveyAnswer", answer);
      if (result) {
        console.log(result);
      }
    }
  };

  render() {
    let steps = this.state.steps;
    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className="card"
                style={{
                  boxShadow: "0px 0px 10px #b9b3b3",
                  borderRadius: "25px"
                }}
              >
                <div className="card-body">
                  <div className="step-progress">
                    {steps && steps.length ? (
                      <StepZilla
                        steps={steps}
                        stepsNavigation={true}
                        prevBtnOnLastStep={false}
                        startAtStep={0}
                        showSteps={false}
                        preventEnterSubmission={false}
                        nextTextOnFinalActionStep="Submit"
                        onStepChange={step => {
                          this.submitQuiz(step);
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
export default SurveyForm;
