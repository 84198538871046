import React, { Component } from "react";
import CmsContent from '../../../../MiddleWare/CmsContent';
import http from "../../../../MiddleWare/httpMiddleWare";
import { ACCESS_POINT } from "../../../../config";
import LoginModal from "../../../../components/Modal/Modal";
// import Progress from "../../components/Extra/Progress";
class Theme11 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentBody: [{
                content: '',
                contentTitle: '',
                contentAuthor: '',
                imageContent: {},
                files_imageContent_0: '',
                contentParagraph: '',
                fileContent: {},
                files_fileContent_0: '',
                multiLangChecked: false

            }],
            isEdit: false,
            submitting: false,
            PageData: [],
            msgShow: false,
            msg: '',
            EditData: {}
        }
    }
    async componentDidMount() {
        try {
            let PageData = this.props.that
            await this.setState({ PageData, EditData: PageData.data1 })
            if (PageData && PageData.data1 && PageData.data1.sheetContent2) {
                let contentBody = JSON.parse(PageData.data1.sheetContent2)
                // console.log(contentBody, "contentBody");
                await this.setState({ contentBody, isEdit: true })
            }
            else if (PageData && PageData.data1 && PageData.data1.sheetContent) {
                let contentBody = JSON.parse(PageData.data1.sheetContent)
                console.log(contentBody, "contentBody didmount");
                contentBody.map((ival) => {
                    ival.multiLangChecked = false
                })
                console.log(contentBody, "contentBody didmount 2");
                await this.setState({ contentBody, isEdit: true })
            }

        } catch (e) {
            console.log(e);
        }
    }
    async componentDidUpdate(prev) {
        if (prev.that != this.props.that) {
            await this.setState({ PageData: this.props.that }, () => { console.log(this.state.PageData, 'PageData') })
        }
    }
    handlChange = async (e, i) => {
        let { contentBody, files } = this.state
        const name = e.target.name
        const value = e.target.value

        if (name == 'imageContent') {
            console.log(e.target.files, 'file 1 ');
            contentBody[i]['imageContent'] = e.target.files[0]
            contentBody[i][`files_imageContent_${i}`] = e.target.files[0].name
        }
        else if (name == 'fileContent') {
            console.log(e.target.files, 'file 2 ');
            contentBody[i]['fileContent'] = e.target.files[0]
            contentBody[i][`files_fileContent_${i}`] = e.target.files[0].name
        } else if (name == 'content') {
            contentBody[0][`${name}`] = value
        }
        else {
            contentBody[i][`${name}`] = value
        }
        await this.setState({ contentBody })
    }
    addContent = async (index) => {
        let { contentBody } = this.state;
        console.log(contentBody, 'contentBody 1');
        contentBody.push({
            contentTitle: '',
            contentAuthor: '',
            imageContent: {},
            contentParagraph: '',
            fileContent: {},
            multiLangChecked: false
        })

        console.log(contentBody, 'contentBody 2');
        await this.setState({ contentBody });
    }
    removeContent = async (a) => {
        const { contentBody } = this.state;
        let index = a;
        contentBody.splice(index, 1);
        this.setState({ contentBody });
    }
    CheckBox = async (d, index) => {
        let { contentBody } = this.state;
        if (contentBody && contentBody[index] && (contentBody[index].multiLangChecked == false || contentBody[index].multiLangChecked == true)) {
            contentBody[index].multiLangChecked = !contentBody[index].multiLangChecked
            await this.setState({ contentBody });
        }
    };
    updateTheme = async () => {
        try {
            let { contentBody, EditData, PageData } = this.state
            await this.setState({ submitting: true })
            let formData = new FormData()
            formData.append('id', EditData.id)
            formData.append('themeId', PageData.themeId)
            formData.append('name', PageData.pageName)
            formData.append("status", "show");
            if (PageData && PageData.autherSelected && PageData.autherSelected[0] && PageData.autherSelected[0].value) {
                formData.append("authorid", PageData.autherSelected[0].value)
            } else if (PageData && PageData.autherSelected && PageData.autherSelected.value) {
                formData.append("authorid", PageData.autherSelected.value)
            }
            if (contentBody && contentBody.length > 0) {
                let wait = contentBody.map((ival, i) => {
                    if (ival && ival.imageContent && ival.imageContent.size) {
                        formData.append(`files_imageContent_${i}`, ival.imageContent)
                    }
                    if (ival && ival.fileContent && ival.fileContent.size) {
                        formData.append(`files_fileContent_${i}`, ival.fileContent)
                    }
                    // delete contentBody[i].imageContent
                    // delete contentBody[i].fileContent
                })
                Promise.all(wait)
            }
            if (contentBody && contentBody.length > 0) {
                if (contentBody.find(x => x.multiLangChecked == true)) {
                    formData.append('sheetContent2', JSON.stringify(contentBody))
                    formData.append('sheetContent', '')
                } else {
                    formData.append('sheetContent', JSON.stringify(contentBody))
                    formData.append('sheetContent2', '')
                }
            }
            console.log(contentBody, 'contentBody');
            if (this.state.isEdit) {
                const themeUpdate = await http.put(
                    ACCESS_POINT + `/cmsContent/tbl_pages`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                ).then((response) => {
                    console.log(response, 'response')
                    if (response && response.status === 200) {
                        this.setState({ msgShow: true, msg: 'Theme updated successfully!' })
                        setTimeout(() => {
                            this.setState({ msgShow: false, msg: '', submitting: false })
                        }, 3000);
                        window.location.reload()
                    }
                })
            }
        } catch (error) {
            console.log(error, 'error');
        }

    }
    submitTheme = async () => {
        try {
            let { PageData, contentBody } = this.state;
            await this.setState({ submitting: true })
            const formData = new FormData()
            formData.append('themeId', PageData.themeId)
            formData.append('name', PageData.pageName)
            formData.append('customerId', PageData.customerId)
            formData.append("authorid", PageData.autherSelected.value);
            formData.append("status", "show");
            if (contentBody && contentBody.length > 0) {
                let wait = contentBody.map((ival, i) => {
                    if (ival && ival.imageContent && ival.imageContent.size) {
                        formData.append(`files_imageContent_${i}`, ival.imageContent)
                    }
                    if (ival && ival.fileContent && ival.fileContent.size) {
                        formData.append(`files_fileContent_${i}`, ival.fileContent)
                    }
                    delete contentBody[i].imageContent
                    delete contentBody[i].fileContent
                })
                Promise.all(wait)
            }
            if (contentBody && contentBody.length > 0) {
                if (contentBody.find(x => x.multiLangChecked == true)) {
                    formData.append('sheetContent2', JSON.stringify(contentBody))
                } else {
                    formData.append('sheetContent', JSON.stringify(contentBody))
                }
            }
            if (!this.state.isEdit) {
                const themeUpdate = CmsContent.themeInsert('tbl_pages', formData).then((response) => {
                    console.log(response, 'response')
                    if (response && response.status === 200) {
                        this.setState({ msgShow: true, msg: 'Theme updated successfully!' })
                        setTimeout(() => {
                            this.setState({ msgShow: false, msg: '', submitting: false })
                        }, 3000);
                        window.location.reload()
                    }
                })

            }
        } catch (error) {
            console.log('error:', error)
        }
    }

    render() {
        let { contentBody } = this.state;
        let index = 0
        return (
            <React.Fragment>

                {
                    contentBody
                        ?
                        <>
                            <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                    <label htmlFor="exampleInputEmail1">
                                        Content
                                    </label>
                                </div>
                                <div className="col-sm-5">
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        id="content"
                                        name="content"
                                        placeholder="Enter Content "
                                        value={this.state.contentBody[0].content}
                                        onChange={(e, i) => this.handlChange(e, i)}

                                    />
                                </div>
                                <div className="col-sm-2" />
                            </div>
                            <br></br>
                            {contentBody.map((ival, i) => {
                                index = i
                                return (
                                    <div>
                                        <center> <h2 className="text-body"> Content {i + 1}</h2></center>
                                        <br></br>

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">
                                                    Content Title
                                                </label>
                                            </div>
                                            <div className="col-sm-5">
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    id="contentTitle"
                                                    name="contentTitle"
                                                    placeholder="Enter Content Title"
                                                    value={this.state.contentBody[i].contentTitle}
                                                    onChange={(e) => this.handlChange(e, i)}
                                                />
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="row form-group">
                                                    {/* <div className="col-sm-2" />
                                                <div className="col-sm-2" /> */}
                                                    <div className="col-sm-2">
                                                        <i class="fa fa-times-circle"
                                                            style={{ fontSize: "25px" }}
                                                            onClick={() => this.removeContent(i)}
                                                        ></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">
                                                    Content Author Name
                                                </label>
                                            </div>
                                            <div className="col-sm-5">
                                                <textarea
                                                    type="text"
                                                    className="form-control"
                                                    id="contentAuthor"
                                                    name="contentAuthor"
                                                    placeholder="Enter Content Author Name"
                                                    value={this.state.contentBody[i].contentAuthor}
                                                    onChange={(e) => this.handlChange(e, i)}

                                                />
                                            </div>
                                            <div className="col-sm-2" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">
                                                    {
                                                        (ival.multiLangChecked)
                                                            ?
                                                            'Multi Language Content'
                                                            :
                                                            'Content Paragraph'
                                                    }
                                                </label>
                                            </div>
                                            {
                                                (ival.multiLangChecked)

                                                    ?
                                                    <>
                                                        <div className="col-sm-5">
                                                            <pre>
                                                                <textarea
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="contentParagraph"
                                                                    name="contentParagraph"
                                                                    placeholder="Enter multi language content  "
                                                                    value={this.state.contentBody[i].contentParagraph}
                                                                    onChange={(e) => this.handlChange(e, i)}
                                                                    rows={5}

                                                                />
                                                            </pre>
                                                        </div>
                                                    </>
                                                    :
                                                    <>

                                                        <div className="col-sm-5">
                                                            <pre>
                                                                <textarea
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="contentParagraph"
                                                                    name="contentParagraph"
                                                                    placeholder="Enter content paragraph"
                                                                    value={this.state.contentBody[i].contentParagraph}
                                                                    onChange={(e) => this.handlChange(e, i)}
                                                                    rows={5}

                                                                />
                                                            </pre>

                                                        </div>

                                                    </>
                                            }
                                            <div className="col-sm-2" >
                                                <div class="form-check">
                                                    <input
                                                        type="checkbox"
                                                        class="form-check-input"
                                                        id="ShowChecked"
                                                        onClick={(d) => this.CheckBox(d, i)}
                                                        checked={contentBody && contentBody[i] && contentBody[i].multiLangChecked}
                                                    />
                                                    <label class="form-check-label" for="materialChecked" style={{ marginLeft: '10px', marginTop: '9px' }}>
                                                        Multi language
                                                    </label>
                                                </div>
                                                <br></br>
                                                <br></br>
                                                {
                                                    (contentBody[i].multiLangChecked)
                                                        ?
                                                        <div >
                                                            <LoginModal
                                                                buttonTitle="Preview"
                                                                title=" Content arrangement in webpage"
                                                                id="preview"
                                                                extraClass="btn-width btn-primary"
                                                                // onClick={this.reset}
                                                                bodyText={
                                                                    <>

                                                                        <div className="row">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-7">
                                                                                <div className="card border border-dark">
                                                                                    <pre style={{
                                                                                        paddingLeft: '50px', paddingRight: '50px', textAlign: 'justify', fontSize: ".875em"
                                                                                    }}>
                                                                                        {ival.contentParagraph}
                                                                                    </pre>
                                                                                </div>
                                                                            </div>
                                                                            {/* <div className="col-sm-3" /> */}


                                                                        </div>
                                                                    </>
                                                                    // <Addauthormodel
                                                                    //   authorname={this.state.authorname}
                                                                    //   handleChange={(d) => this.handlechange(d)}
                                                                    //   addNewauthor={this.addNewauthor}
                                                                    //   errorauthorname={this.state.errorauthorname}
                                                                    //   authoremail={this.state.authoremail}
                                                                    //   errorauthoremail={this.state.errorauthoremail}
                                                                    //   authorabout={this.state.authorabout}
                                                                    //   errorauthorabout={this.state.errorauthorabout}
                                                                    //   authorlogo={this.state.authorlogo}
                                                                    //   handleImage={this.handleImage}
                                                                    //   alert={this.state.alertVisible1}
                                                                    //   dismiss={this.onDismiss}
                                                                    //   disableValue={this.state.btnDisable}
                                                                    //   alertMsg="New author Added"
                                                                    // />
                                                                }
                                                            />
                                                        </div>
                                                        // <button>Preview</button>
                                                        :
                                                        <></>
                                                }

                                            </div>
                                        </div>
                                        <div>
                                            {
                                                (ival.multiLangChecked)
                                                    ?
                                                    <div className="row">
                                                        <div className="col-sm-4" />
                                                        <div className="col-sm-5">
                                                            <label style={{ color: 'red' }}> Note: Make sure your spacing arrangements are very clear</label>
                                                        </div>
                                                        <div className="col-sm-3" />
                                                    </div>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        {/* {
                                        (ival.multiLangChecked)
                                            ?
                                            <>
                                                <div className="row form-group">
                                                    <div className="col-sm-2" />

                                                    <div className="col-sm-2" />
                                                </div>
                                            </>
                                            :
                                            <></>

                                    } */}
                                        <div className="row form-group pt-4">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1"> Image Content </label>
                                            </div>

                                            <div className="col-sm-5">
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="imageContent"
                                                    name="imageContent"
                                                    onChange={(e) => this.handlChange(e, i)}
                                                    accept="image/*"
                                                />
                                                <label
                                                    className="custom-file-label"
                                                    htmlFor="imageContent"
                                                >
                                                    {
                                                        (contentBody && contentBody[i] && contentBody[i].imageContent && contentBody[i].imageContent.name)
                                                            ?
                                                            contentBody[i].imageContent.name
                                                            :
                                                            (contentBody && contentBody[i] && contentBody[i][`files_imageContent_${i}`])
                                                                ?
                                                                contentBody[i][`files_imageContent_${i}`].split('/')[1]
                                                                :
                                                                'Choose File'
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row form-group pt-4">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1"> File Content </label>
                                            </div>

                                            <div className="col-sm-5">
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="fileContent"
                                                    name="fileContent"
                                                    onChange={(e) => this.handlChange(e, i)}
                                                    accept="image/*"
                                                />
                                                <label
                                                    className="custom-file-label"
                                                    htmlFor="fileContent"
                                                >
                                                    {
                                                        (contentBody && contentBody[i] && contentBody[i].fileContent && contentBody[i].fileContent.name)

                                                            ?
                                                            contentBody[i].fileContent.name
                                                            :
                                                            (contentBody && contentBody[i] && contentBody[i][`files_fileContent_${i}`])
                                                                ?
                                                                contentBody[i][`files_fileContent_${i}`].split('/')[1]
                                                                :
                                                                'Choose File'
                                                    }
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                )

                            })}
                        </>
                        :
                        null

                }
                <br></br>
                <div className="row">
                    <div className="col-sm-5" >
                    </div>
                    <div className="col-sm-5">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => this.addContent(index)}
                        >Add Content </button>
                    </div>

                </div>
                <br />
                <br />
                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2" />
                    <div className="col-sm-5">
                        <button
                            type="button"
                            className={`btn ${this.state.isEdit ? "btn-secondary" : "btn-primary"
                                }`}
                            disabled={this.state.btnDisable}
                            onClick={this.state.isEdit ? this.updateTheme : this.submitTheme}
                        >
                            {
                                (this.state.submitting)
                                    ?
                                    "Submitting..."
                                    :
                                    (this.state.isEdit)
                                        ?
                                        "Update"
                                        :
                                        "Add Page"
                            }
                        </button>
                    </div>
                    <div className="col-sm-3" />
                </div>
                <center>
                    <div>
                        {
                            (this.state.msgShow)
                                ?
                                <div style={{ color: 'forestgreen', fontSize: 'medium', fontWeight: 600, backgroundColor: '#AAF7C2', borderRadius: '7px' }}> {this.state.msg}</div>
                                :
                                <div></div>

                        }
                    </div>
                </center>
            </React.Fragment >
        )
    }
}
export default Theme11;