import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import SingleSelect from "../../../components/Form/SingleSelect";
import { Alert } from "reactstrap";
import { ACCESS_POINT } from "../../../config";
import { Datatable } from "../../../components/Datatable";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import LoginModal from "../../../components/Modal/Modal";
import Alluserlogpdf from "./alluserlogpdf";


//import Accesspdf from './pdf';


class CMSalluserlog extends FormMiddleWare {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      customerId: localStorage.getItem("userId"),
      pageOptions: [],
      videoptions: [],
      tabledata: [],
      users: [],
      Accessusercount: [],
      alluserviewpage: [],
      selectedstartdate: params["sd"],
      selectedenddate: params["ed"],
      disablepage: false,
      disablevideos: false,
      AllContent: [],
      userselected: params["userid"],
      visible: false,
    };
  }

  async componentWillMount() {
    try {
      const pages = await CmsContent.getFreedom(
        "id as value,name as label,file",
        "tbl_pages",
        `customerId=${this.state.customerId} and status="show" `,
        "id",
        "id DESC"
      );
      if (pages) {
        // console.log("pg", pages);
        this.setState({ pageOptions: pages.data });
      }

      const users = await CmsContent.getFreedom(
        "id as value,userName as label,email as email,mobileNumber as number",
        "tbl_user_web",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      console.log(users);
      if (users) {
        this.setState({ users: users.data });
      }

      let date = new Date();
      var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
      var day = date.getUTCDate();
      var year = date.getUTCFullYear();

      let todaydate = year + "-" + month + "-" + day;

      let userselected = this.state.userselected;
      let { data: getAlldataForUsers } = await CmsContent.getFreedom(
        "tbl_CMSuserAccessLog.*,tbl_pages.name,tbl_user_web.userName,tbl_user_web.email,tbl_user_web.mobileNumber",
        "tbl_CMSuserAccessLog,tbl_user_web,tbl_pages",
        `tbl_CMSuserAccessLog.userId=${userselected} and tbl_user_web.id=${userselected} and tbl_pages.id=tbl_CMSuserAccessLog.pageid and tbl_CMSuserAccessLog.customerId=tbl_pages.customerId  and tbl_CMSuserAccessLog.date between '${this.state.selectedstartdate}' and '${this.state.selectedenddate}'`,
        "tbl_CMSuserAccessLog.id",
        "tbl_CMSuserAccessLog.id DESC"
      );
      const { data: first } = await CmsContent.getFreedom("*", 'tbl_CMSuserAccessLog', `userId=${userselected} `, 'pageid', 'startTime desc')
      // console.log(first)
      const { data: last } = await CmsContent.getFreedom("*", 'tbl_CMSuserAccessLog', `userId = ${userselected}`, 'pageid', 'datetime asc')
      // console.log(last)

      if (getAlldataForUsers) {
        let data = [];
        getAlldataForUsers.map((ival, i) => {
          let a = true;
          if (data.length) {
            data.map((jval, j) => {
              if (jval.userId === ival.userId && jval.pageid === ival.pageid) {
                a = false;
              }
            });
            if (a) {
              data.push(ival);
            }
          } else {
            data.push(ival);
          }
        });

        // let frstdata = [];
        // let lastdata = [];
        // data.map((kval, m) => {
        //   first.map((fval, n) => {
        //     if (kval.pageid == fval.pageid) {

        //       frstdata.push(fval)
        //     }
        //   })
        // })
        // data.map((kval, m) => {
        //   last.map((fval, n) => {
        //     if (kval.pageid == fval.pageid) {
        //       lastdata.push(fval)
        //     }
        //   })
        // })
        // console.log(lastdata)
        let AllContent = [];


        data.map((ival, m) => {


          AllContent.push({
            userName: ival.userName,
            email: ival.email,
            number: ival.mobileNumber,
            pagename: ival.name,
            pageid: ival.pageid,
            userId: ival.userId,
            starttime: ival.startTime,

            endtime: ival.datetime,
            maxtime: ival.max_timeline,
            date: ival.date
          })

          this.setState({ username: ival.userName, pagename: ival.name, pageid: ival.pageid })
        })


        AllContent.map((kval, i) => {
          AllContent[i].innerContent = [];
          getAlldataForUsers.map((dval, b) => {
            if (kval.pageid == dval.pageid) {
              AllContent[i].innerContent.push(dval)
            }
          })
        })

        //Count
        AllContent.map((ival, i) => {
          AllContent[i].pageviews = 0;
          getAlldataForUsers.map((jval, j) => {
            let pageviews = 0;
            pageviews = AllContent[i].innerContent.length;
            if (jval.userId == ival.userId) {
              AllContent[i].pageviews = pageviews;
            }
          });
        });
        //First and Lastaccess
        AllContent.map((ival, i) => {
          AllContent[i].firstaccess = [];
          AllContent[i].lastaccess = [];
          first.map((fval, n) => {
            if (ival.pageid == fval.pageid) {
              AllContent[i].firstaccess = fval.startTime
            }
            last.map((lval, a) => {
              if (ival.pageid == lval.pageid) {
                AllContent[i].lastaccess = lval.datetime
              }
            })
          })
        })
        // Last acesss
        // AllContent.map(async (aval, i) => {
        //   AllContent[i].lastaccess = []
        //   // const date = await CmsContent.getFreedom("*", 'tbl_CMSuserAccessLog', `userId = ${ userselected } and pageid = ${ aval.pageid }`, 'pageid', 'datetime asc')
        //   AllContent[i].lastaccess = date.data[0].datetime;
        // })
        // //Firts Access

        // AllContent.map(async (fval, i) => {
        //   AllContent[i].firstaccess = [];
        //   const date = await CmsContent.getFreedom("*", 'tbl_CMSuserAccessLog', `userId = ${ userselected } and pageid = ${ fval.pageid }`, 'pageid', 'startTime desc')
        //   console.log(date)
        //   AllContent[i].firstaccess = date.data[0].startTime;
        // })
        console.log(AllContent)
        if (AllContent) {
          const header = ["SI.NO", "UserName", "Email", "Mobile Number", "Page Name", "Count", "FirstAccessed", "LastAccessed", "LongestdurationandDate"];
          const excelHead = [
            { label: "UserName", key: "userName" },
            { label: "Email", key: "email" },
            { label: "Mobile Number", key: "number" },
            { label: "PageName", key: "pagename" },
            { label: "Count", key: "pageviews" },
            { label: "firstAccessed", key: "firstaccess" },
            { label: "lastAccessed", key: 'lastaccess' },
            { label: "LongestDuraton&Date", key: "maxtime" }
          ];
          this.setState({ header, excelHead });

        }
        this.setState({
          tabledata: AllContent,
          excel: AllContent, page: "Alluserslog"
        })
      }
    } catch (error) {
      console.log(error);
    }
  }
  accesscolumn = [
    {
      Header: "UserName",
      accessor: "userName"
    },
    {
      Header: "Page Name",
      accessor: "page"
    },
    {
      Header: "Ip Address",
      accessor: "ip"
    },
    {
      Header: "Max Time",
      accessor: "max"
    },
    {
      Header: "startTime",
      accessor: "entry"
    },
    {
      Header: "Exit Time",
      accessor: "exit"
    }
  ]
  column = [
    {
      Header: "UserName",
      accessor: "userName"
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "Mobile Number",
      accessor: "number"
    },
    {
      Header: "PageName",
      accessor: "pagename"
    },
    {
      Header: "Count",
      accessor: "pageviews"
    },
    {
      Header: "FirstAccessed",
      accessor: "firstaccess",
      // Cell: (d) => this.firstaccss(d.original)
    },
    {
      Header: "LastAccessed",
      accessor: "lastaccess",
      //Cell: (d) => this.lastaccss(d.original)
    },
    {
      Header: "LongestDuration&Date",
      accessor: "maxtime",
      Cell: (d) => this.checktime(d)
    },
    {
      Header: "View Details",
      Cell: (d) => this.viewlogs(d)
    }

  ]
  checktime = d => {

    //console.log(d);
    if (d.original.maxtime) {
      let num = parseInt(d.original.maxtime);
      //var sec = num.getSeconds();
      //var hours = Math.floor(num / 60);
      var minutes = Math.floor(num / 60) % 60;
      var sec = Math.floor(num - minutes * 60);
      return minutes + "m" + ":" + sec + "s" + " " + "on" + " " + d.original.date;
    } else {
      return "N/A";
    }
  }
  lastaccss = (d) => {
    return d.lastaccess;
  }
  firstaccss = d => {
    return d.firstaccess;
  }

  viewlogs = d => {
    return (
      <button
        className="btn btn-primary"
        data-toggle="modal" data-target="#myModal"
        onClick={() => this.openmodal(d.original)}>
        View
      </button>
    )
  }
  openmodal = (d) => {
    console.log(d)
    let data = [];
    let values = d.innerContent;
    values.map((jval, m) => {
      if (jval.max_timeline == null || jval.play == null) {
        data.push({
          SI_NO: m + 1,
          userName: jval.userName,
          page: jval.name,
          ip: jval.ipaddress,
          max: "N/A",
          exit: jval.datetime,
          entry: "N/A",
        });
      }
    })
    this.setState({ accessdata: data, visible: true })
  }




  render() {
    const { formAlert, formAlertdelete, categoryOptions, visible, tabledata } = this.state;

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-sm-4"> </div>
                      <div className="col-sm-12">
                        <h3 style={{ textAlign: "center" }}>Total access log of  - {this.state.username} - {this.state.pagename}</h3>
                      </div>
                      <div className="col-sm-4"> </div>
                    </div>
                    <br />
                    {/* {this.state.excel && this.state.excel.length > 0 && (
                      <CSVLink
                        data={this.state.Csv}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          EXCEL
                        </button>
                      </CSVLink>
                    )} */}
                    {/* {this.state.excel && this.state.excel.length > 0 && (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <Loginpdf
                            header={this.state.header1}
                            data={this.state.excel}
                            users={this.state.users}

                          />
                        }
                        fileName="sample.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    )} */}
                    {this.state.excel && this.state.excel.length > 0 && (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <Alluserlogpdf
                            header={this.state.header}
                            data={this.state.excel}
                            page={this.state.page}

                          />
                        }
                        fileName="sample.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    )}
                    {this.state.excel && this.state.excel.length > 0 && (
                      <CSVLink
                        data={this.state.excel}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          EXCEL
                        </button>
                      </CSVLink>
                    )}
                    {visible == true && (

                      <div class="modal fade" id="myModal" role="dialog">
                        <div class="modal-dialog modal-lg">
                          <div class="modal-content">
                            <div class="modal-header">
                              <div class="col-sm-7">
                                <h4 style={{ textAlign: "center" }}>Total Access Log of - {this.state.username} - {this.state.pagename}</h4>
                              </div>
                              <button type="button" class="close" data-dismiss="modal">&times;</button>


                            </div>
                            <div class="modal-body">
                              <Datatable
                                data={this.state.accessdata}
                                columnHeading={this.accesscolumn}
                              />
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {this.state.tabledata && (
                      <Datatable
                        data={this.state.tabledata}
                        columnHeading={this.column}
                      />
                    )}


                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default CMSalluserlog;

