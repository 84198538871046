import React, { Component } from 'react';
import renderHTML from 'react-render-html';

class DynamicTable extends Component {
  state = {
    tableStyle: `<style>
                    table {
                      border-collapse: collapse;
                    }
                    table, th, td {
                      border: 1px solid black;
                      text-align: center;
                    }
                    .asd{
                      margin:10px;
                    }
                    </style>`,
    tableContent: `<table>
                      <tr>
                        <th><p class="asd">Month</p></th>
                        <th>Savings</th>
                        <th>Savings for holiday!</th>
                      </tr>
                      <tr>
                        <td>January</td>
                        <td>$100</td>
                        <td rowspan="2">$50</td>
                      </tr>
                      <tr>
                        <td>February</td>
                        <td>$80</td>
                      </tr>
                    </table>`
  }
  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Add Table Entry</h3>
                  </div>
                  <div className="card-body">
                    <h4>Heading</h4>
                    <div className="content-para" style={{ overflowY: 'scroll', height: 500 }}>{renderHTML(`
                    <div>
                    ${this.state.tableStyle}
                    ${this.state.tableContent}
                    </div>
                    `)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default DynamicTable;