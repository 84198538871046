import React, { Component } from 'react';
import { Datatable } from '../../../../components/Datatable';
import CmsContent from '../../../../MiddleWare/CmsContent';
import SingleSelect from "../../../../components/Form/SingleSelect";
import FormMiddleWare from '../../../../components/Form/FormMiddleware';
import swal from 'sweetalert';
import LoginModal from '../../../../components/Modal/Modal';


class Rulescmdl extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            customerid: localStorage.getItem('userId'),
            serviceid: localStorage.getItem('currentService'),
            customerId: localStorage.getItem("userId"),
            pageOptions: [],
            scenario: [
                { label: "Scenario 1", value: '', vals: "Mail on launch", location: '', Body: 1 },
                { label: "Scenario 2", value: '', vals: 'Attempt days trigger', location: '', Body: 2 },
                { label: "Scenario 3", value: '', location: "", Body: 3 },
                { label: "Scenario 4", value: '', location: '', Body: 4 },
            ]
        }
    }
    datas = (d) => {
        let data = JSON.parse(d.Scenario)
        if (d) {
            return (
                <LoginModal
                    id={"mm" + d.id}
                    buttonTitle="Open"
                    bodyText={<div>
                        <table className="table table-hover">
                            {data && data.map((ival, i) => {
                                return (<tr>
                                    <th>{ival.vals}</th>
                                    <td>{ival.value}</td>
                                </tr>)
                            })}
                        </table>
                    </div>}
                />
            )
        }
    }

    column = [
        {
            Header: "Id",
            accessor: "id",
        },
        {
            Header: "Rule name",
            accessor: "Name",
        },
        {
            Header: "Score",
            accessor: "score",
        },
        {
            Header: "Edit ",
            accessor: "",
            Cell: (d) => this.edit(d),
        },
        // {
        //     Header: "Scenario",
        //     accessor: "data",
        //     Cell: (d) => this.datas(d.original)
        // },
        // {
        //     Header: "Status",
        //     accessor: "status",
        //     Cell: (d) => this.status(d.original)
        // }
    ]
    async componentDidMount() {
        this.main()
    }
    buttonEdit = (d) => {
        console.log(d.original, d.index);
        let Index = d.index;
        let value = d.original;
        let editid = value.id;
        this.setState({
            Rule_name: value.Name,
            score: value.score,
            scenario: value.Scenario ? JSON.parse(value.Scenario) : this.state.scenario,
            editid,
            Index,
            isEdit: true,
        });
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    };
    status = (d) => {
        if (d.status == "active") {
            return (<button className='btn btn-success' style={{ width: 100 }}
            //  onClick={() => cngstatus(d.id, 1)}
            >Active</button>)
        } else {
            return (<button className='btn btn-danger' style={{ width: 100 }}
            // onClick={() => cngstatus(d.id, 2)}
            >Deactivated</button>)
        }
    }
    async main() {
        try {
            let data = await CmsContent.getFreedom('*', "cmndl_tbl_rules", 1, 1, 1)
            this.setState({ data: data.data })
        } catch (error) {
            console.error(error);
        }
    }
    submitdata = async () => {
        let data = {}
        data.id = "da6a"
        let result = await CmsContent.mailtest1(data)
        console.log(result);
    }
    hdlhanges = async (e, val, type, local) => {
        if (type == 'stype') {

            let { scenario } = this.state
            if (local == 'location') {
                scenario[val].location = e.target.value
            }
            else { scenario[val].value = e.target.value }
            this.setState({ scenario })
        } else {
            let { name, value } = e.target
            this.setState({ [name]: value })
        }
    }
    // cngstatus = async (id, value) => { }
    submit = async () => {
        let { Rule_name, score, stype, scenario, editid } = this.state
        if (Rule_name && score) {
            let data = {}
            data.Name = Rule_name
            data.score = score
            data.Scenario = JSON.stringify(scenario)
            let result = editid ? await CmsContent.updateMaster('cmndl_tbl_rules', editid, data) :
                await CmsContent.addMaster('cmndl_tbl_rules', data)
            if (result) {
                swal({
                    title: `Successfully Updated`,
                    icon: "success",
                    button: {
                        text: "Ok",
                        closeModal: false,
                    },
                }).then(async (willDelete) => {
                    this.main()
                    this.reset()
                    swal.close()
                });
            } else {
                swal({
                    title: `Faild`,
                    icon: "success",
                    button: {
                        text: "Ok",
                        closeModal: false,
                    },
                }).then(async (willDelete) => {
                    swal.close()
                });
            }
        }
    }
    reset = () => {
        let { scenario } = this.state
        scenario.map((ival, i) => {
            ival.value = ''
            ival.location = ''
        })
        this.setState({ Rule_name: '', score: '', scenario, editid: null, isEdit: false })
    }
    render() {
        return (
            <main className="main my-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3>Rules</h3>
                                </div>
                                <div className="card-body" style={{ minHeight: 350 }}>
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label for="exampleInputEmail1">Rule name</label>
                                        </div>
                                        <div className="col-sm-5">
                                            <input
                                                type="text"
                                                id="Rule_name"
                                                name="Rule_name"
                                                placeholder='Enter Rule name'
                                                className="form-control"
                                                onChange={(e) => this.hdlhanges(e, 'Rule_name')}
                                                value={this.state.Rule_name}
                                            />
                                        </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label for="exampleInputEmail1">Score</label>
                                        </div>
                                        <div className="col-sm-5">
                                            <input
                                                type="number"
                                                id="score"
                                                name="score"
                                                placeholder='Enter score'
                                                className="form-control"
                                                onChange={(e) => this.hdlhanges(e, 'score')}
                                                value={this.state.score}
                                            />
                                        </div>
                                    </div>
                                    {this.state.scenario.map((ival, i) => {
                                        if (i == 0) {
                                            return (
                                                <React.Fragment>
                                                    {/* <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label for="exampleInputEmail1">{ival.label}</label>
                                                        </div>
                                                        <div className='col-sm-5'>
                                                            <div className="form-check-inline" >
                                                                <input type="radio" id={"YES"} name={"side"} value={"YES"} onChange={(e) => this.hdlhanges(e, i, "stype")} checked={ival.value == "YES" ? true : false} />
                                                                <label for={'YES'} className="form-check-label">YES</label> &nbsp;
                                                                <input type="radio" id={"NO"} name={"side"} value={"NO"} onChange={(e) => this.hdlhanges(e, i, "stype")} checked={ival.value == "NO" ? true : false} />
                                                                <label for={'NO'} className="form-check-label">NO</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <label for="exampleInputEmail1">
                                                                {ival.vals}
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label for="exampleInputEmail1">{ival.label + " location"}</label>
                                                        </div>
                                                        <div className='col-sm-5'>
                                                            <input
                                                                type="text"
                                                                id='value'
                                                                name="value"
                                                                placeholder={ival.label + " location"}
                                                                className="form-control"
                                                                onChange={(e) => this.hdlhanges(e, i, "stype", 'location')}
                                                                value={ival.location}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <label for="exampleInputEmail1">

                                                            </label>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        } else if (i == 1) {
                                            return (
                                                <React.Fragment>
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label for="exampleInputEmail1">{ival.label}</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="number"
                                                                id='value'
                                                                name="value"
                                                                placeholder={ival.vals}
                                                                className="form-control"
                                                                onChange={(e) => this.hdlhanges(e, i, "stype")}
                                                                value={ival.value}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <label for="exampleInputEmail1">
                                                                {ival.vals}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label for="exampleInputEmail1">{ival.label + " location"}</label>
                                                        </div>
                                                        <div className='col-sm-5'>
                                                            <input
                                                                type="text"
                                                                id='value'
                                                                name="value"
                                                                placeholder={ival.label + " location"}
                                                                className="form-control"
                                                                onChange={(e) => this.hdlhanges(e, i, "stype", 'location')}
                                                                value={ival.location}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <label for="exampleInputEmail1">

                                                            </label>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                        else {
                                            return (
                                                <div className="row form-group">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label for="exampleInputEmail1">{ival.label + " location"}</label>
                                                    </div>
                                                    <div className='col-sm-5'>
                                                        <input
                                                            type="text"
                                                            id='value'
                                                            name="value"
                                                            placeholder={ival.label + " location"}
                                                            className="form-control"
                                                            onChange={(e) => this.hdlhanges(e, i, "stype", 'location')}
                                                            value={ival.location}
                                                        />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label for="exampleInputEmail1">
                                                            {ival.vals}
                                                        </label>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}


                                    {this.state.Rule_name && this.state.score ?
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary "
                                                    // onClick={this.submitdata}
                                                    onClick={this.submit}
                                                >{this.state.editid ? 'Update' : 'Submit'}</button>
                                            </div>
                                        </div> : null}
                                    <div className="row d-flex justify-content-end">
                                        <button
                                            type="button"
                                            className="btn btn-warning"
                                            onClick={this.reset}
                                        >Reset</button>
                                        <div className="col-sm-3" />                                       
                                    </div>
                                    <br />
                                    <br />
                                    <div className="row form-group">
                                        <div className="col-sm-12">
                                            {this.state.data &&
                                                <Datatable data={this.state.data} columnHeading={this.column} />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        );
    }
}

export default Rulescmdl;
