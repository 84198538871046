import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = ({ startDate, changeDate }) => {
  return (
    <ReactDatePicker
      showYearDropdown
      scrollableYearDropdown
      showMonthDropdown
      useShortMonthInDropdown
      dropdownMode="select"
      selected={startDate}
      onChange={changeDate}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={1}
      dateFormat="dd/MM/yyyy - HH:mm"
    />
  );
};

export default DatePicker;
