import React from 'react';

const Video = ({ imgsource }) => {
  return (
    <div>
      <figure id="videoContainer" data-fullscreen="false">
        <video id="video" controls crossOrigin="anonymous" preload="metadata">
          <source src={imgsource} type="video/mp4" />
        </video>
      </figure>
    </div>
  );
};

export default Video;
