import React from "react";
import {
  Page,
  Text,
  Font,
  Document,
  StyleSheet,
  Image,
  View
} from "@react-pdf/renderer";
import Certificate from "../../../images/CMS/certificate.png";
// import Logo from "../../../images/CMS/logo_cms_cert.png";
// import Logo1 from "../../../images/CMS/MDC_Logo_New_Reverse-1.png";
import Sign from "../../../images/CMS/digital_signature_Mr_Jaiswal.png";
var arr = window.location.href.split("/");
const ExamplePDF = ({ username, date, quizname, prevalue, postvalue }) => {
  let topl2 = {
    top: 435
  };
  let topl3 = {
    top: 460
  };
  if (quizname.length >= 14) {
    topl2 = {
      top: 455
    };
    topl3 = {
      top: 475
    };
  }
  return (
    <Document>
      <Page>
        <Image style={styles.image} src={Certificate} />
        {/* <Image wrap={true} style={styles.logo} src={Logo} /> */}
        <Text style={styles.username}>{username}</Text>
        {/* <Image wrap={true} style={styles.logo1} src={Logo1} /> */}
        <Text style={styles.l1}>
          For having successfully completed an E-learning course on {quizname}.
        </Text>
        {/* <Text style={[styles.l2, topl2]}>Evaluation :{"\n"}</Text> */}
        <Text style={[styles.l3, topl3]}>
          Evaluation : Pre-program evaluation - {prevalue}% - Post-program evaluation -
          {postvalue}%{"\n"}
        </Text>
        <Text style={styles.date}>
          {date}
        </Text>
        <Text style={styles.Dtext}>Date</Text>
        <Text style={styles.SignH1}>Manoj Kumar Jaiswal</Text>
        <Text style={styles.SignH2}>EVP & Head - MDC</Text>
        <Image wrap={true} style={styles.sign} src={Sign} />
      </Page>
    </Document>
  );
};
Font.register({
  family: "Parisienne-Regular",
  src: `${arr[0]}//${arr[2]}/vendors/Parisienne-Regular.ttf`
});

const styles = StyleSheet.create({
  username: {
    margin: 12,
    fontSize: 16,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    fontFamily: "Parisienne-Regular",
    position: "absolute",
    top: 420,
    left: 270,
    width: 150
  },
  l1: {
    margin: 12,
    fontSize: 13,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    fontFamily: "Parisienne-Regular",
    position: "absolute",
    top: 440,
    left: 115,
    width: 380
  },
  l2: {
    margin: 12,
    fontSize: 13,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    fontFamily: "Parisienne-Regular",
    fontWeight: "bold",
    position: "absolute",
    left: 115,
    width: 380
  },
  l3: {
    margin: 12,
    fontSize: 13,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    fontFamily: "Parisienne-Regular",
    position: "absolute",
    left: 115,
    width: 380
  },
  Dtext: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    // fontFamily: "Parisienne-Regular",
    position: "absolute",
    top: 525,
    left: 180,
    width: 380
  },
  date: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
    fontFamily: "Parisienne-Regular",
    position: "absolute",
    top: 505,
    left: 160,
    width: 380
  },
  image: {
    width: "auto",
    height: "auto",
    top: "25%"
  },
  sign: {
    width: 100,
    height: 38,
    position: "absolute",
    left: 355,
    top: 505,
    backgroundImage: `url({ Sign })`,
    backgroundRepeat: "no - repeat"
  },
  // logo: {
  //   width: 70,
  //   height: 50,
  //   position: "absolute",
  //   left: 105,
  //   top: 285
  // },
  // logo1: {
  //   width: 75,
  //   height: 32,
  //   position: "absolute",
  //   left: 420,
  //   top: 295
  // },
  SignH1: {
    margin: 10,
    fontSize: 10,
    textAlign: "justify",
    // fontFamily: "Verdana, sans-serif",
    fontFamily: "Times-Roman",
    position: "absolute",
    top: 525,
    left: 345,
    width: 380
  },
  SignH2: {
    margin: 10,
    fontSize: 10,
    textAlign: "justify",
    // fontFamily: "Verdana, sans-serif",
    fontFamily: "Times-Roman",
    position: "absolute",
    top: 540,
    left: 348,
    width: 380
  }
});

export default ExamplePDF;
