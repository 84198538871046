import React from 'react';
import Select from 'react-select';
import './Style.css';

const SingleSelect = ({ errorServices, handleChange, selectedService, options, width, disabled, placeholder, ...res }) => {
  return (
    <React.Fragment>
      <Select styles={{ menu: provided => ({ ...provided, zIndex: 9999 }) }} isSearchable={options && options.length > 15 ? true : false} className={width} clearValue value={selectedService} onChange={handleChange} options={options} isDisabled={disabled} placeholder={placeholder ? placeholder : 'Please select...'} {...res} />
    </React.Fragment>
  );
};

export default SingleSelect;
