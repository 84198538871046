import React from 'react';
import { Alert } from 'reactstrap';

const AddProductSize = ({ handleChange, addNewProductSize, error, alert, dismiss, disableValue, productSize, alertMsg }) => {
    return (
        <React.Fragment>
            <div>
                <Alert className="badge-content" color="success" isOpen={alert} toggle={dismiss}>
                    {alertMsg}
                </Alert>
                <div className="row form-group">
                    <div className="col-sm-1" />
                    <div className="col-sm-3">
                        <label for="exampleInputEmail1">Size</label>
                    </div>
                    <div className="col-sm-7">
                        <input type="text" class="form-control" id="productSize" name="productSize" placeholder="Enter Product Size" onChange={handleChange} value={productSize} />
                    </div>
                    <div className="col-sm-1" />
                </div>
                <div className="row form-group">
                    <div className="col-sm-4" />
                    <span className="modal-error-show">{error}</span>
                </div>

                <div className="row form-group">
                    <div className="col-sm-4" />
                    <div className="col-sm-4">
                        <button type="button" class="btn btn-primary" disabled={disableValue} onClick={addNewProductSize}>
                            Add Size
                        </button>
                    </div>
                    <div className="col-sm-4" />
                </div>
            </div>
        </React.Fragment>
    );
};

export default AddProductSize;
