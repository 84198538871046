import React, { Component, useRef } from "react";
import vidosrc from "./Hellovideo/home.mp4";
import { ACCESS_POINT } from "../../config";
import "./privacy.css";
import { FormMiddleWare } from "../../components/Form";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  view
} from "reactstrap";
import login from "../../MiddleWare/Login";
import CmsContent from "../../MiddleWare/CmsContent";
import "./privacy.css";
let link =
  ACCESS_POINT +
  "/superAdmin/file?fileurl=/SDAS/Video/difuzaVideo_1572615730456.mp4";

var vidoimg = require("./Hellovideo/home.jpg");
var img2 = require("./img/login.png");
export default class Hello extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      alertVisible: false,
      mobileNumber: "",
      mobile: "",
      password: "",
      errorpassword: "",
      loginwpass: 0,
      isToggleOn: false,
      errorMobileNumber: "",
      errorMobileNumber1: "",
      errorlogin: "",
      set: false,
      preloader1: false,
      show: 1,
      placeholder: 1,
      local: window.location.href.split("/")[2] === "localhost:3000",
      button: "Sign In",
      browser: localStorage.getItem("browser"),
      Ip: localStorage.getItem("IP"),
      IPTime: localStorage.getItem("IPTime"),
      device: localStorage.getItem("device"),
      os: localStorage.getItem("os"),
      preloader: false,
      preloaderwp: false,
      contactname: "",
      contactalertvisible: false,
      contactloader: false,
      dataimage: "",
      cotacterrornumber: "", errorcaptchacapture: "", cotacterroremail: "",
      isToastOpen: false, Toastmsg: null, Toastcolor: null
    };
  }




  success = (response) => {
    var res = response.profileObj;

    console.log(res);

    // debugger;
    let email = res.email;
    this.setState({ preloader1: true });
    this.chechmail(email)
  }
  chechmail = async (email) => {
    if (email === null) {
      this.setState({ preloader1: false });
      this.toast("Email must not be Empty");
      return false;
    } else {
      let { data: result } = await CmsContent.GoogleLogin(email)
      console.log(result, email);
      if (result.code == "User Found") {
        let { data: login_otp_notsend } = await CmsContent.getFreedom(
          "*",
          "tbl_user_web",
          `email="${email}"`,
          1,
          1
        );
        console.log(login_otp_notsend);

        if (login_otp_notsend.length) {
          const result = await login.Freelogin(
            login_otp_notsend[0].mobileNumber,
            ""
          );
          let userOrder = await CmsContent.getFreedom(
            "*",
            "tbl_UserType",
            `id	 = ${login_otp_notsend[0].userType}`,
            "id",
            "id"
          );

          if (userOrder) {
            localStorage.setItem(`usertypelog`, JSON.stringify(userOrder.data[0]));
          }
          if (this.state.local || result) {
            let userlog = "";
            try {
              const result = await login.Freelogin(login_otp_notsend[0].mobileNumber, "");
              // this.setState({ preloader: false });
              if (result) {
                var id = result.data[0].id;
                let categoryArray = {};
                categoryArray.loginStatus = "online";
                const { data: data } = await CmsContent.updateMaster(
                  "tbl_user_web",
                  id,
                  categoryArray
                );

                if (data) {
                  const result1 = await CmsContent.getFreedom(
                    "*",
                    "tbl_user_web",
                    "loginStatus = 'online'",
                    1,
                    1
                  );
                  if (result1) {
                    result1.data.map((ival, i) => {
                      if (result.data[0].id === ival.id) {
                        localStorage.setItem(
                          `userlog${i}`,
                          JSON.stringify(result.data[0])
                        );
                        userlog = JSON.parse(localStorage.getItem(`userlog${i}`));
                      } else {
                        localStorage.setItem(
                          `userlog${i}`,
                          JSON.stringify(result1.data[i])
                        );
                      }
                    });
                  }
                  localStorage.setItem(`userlog`, JSON.stringify(userlog));

                  // // Here we set user is logged in
                  // let info =await JSON.parse(localStorage.getItem('info'));
                  // if(info && info.width){
                  //   info.id=userlog.id;
                  //   console.log(info)
                  //   await localStorage.setItem(`info`, JSON.stringify(info));
                  // }

                  userlog = JSON.parse(localStorage.getItem(`userlog`));

                  let userArray = {};
                  userArray.userId = userlog.id;
                  userArray.userName = userlog.userName;
                  userArray.ip = this.state.Ip;
                  userArray.browser = this.state.browser;
                  userArray.device = this.state.device;
                  userArray.os = this.state.os;
                  userArray.customerId = userlog.customerId;
                  userArray.userType = userlog.userType;

                  if (!this.state.IPTime) {
                    let date = new Date();
                    userArray.createdAt = this.date(date);
                  } else {
                    userArray.createdAt = this.date(this.state.IPTime);
                  }

                  let res = await CmsContent.addMaster(
                    "tbl_OriginalLog",
                    userArray
                  );
                  console.log(userlog, "userlog");
                  if (res) {
                    // window.location.href = "/subAdmin";
                    this.setState({ preloader: true });
                    if (parseInt(userlog.userType) === 1) {
                      // this.props.history.replace("/admin");
                      window.location.href = "/admin";
                    } else if (parseInt(userlog.userType) === 2) {
                      // this.props.history.replace("/subAdmin");
                      window.location.href = "/subAdmin";
                    } else if (parseInt(userlog.userType) === 3 && parseInt(userlog.id) == 620) {
                      window.location.href = "/DifuzaCarz/10";
                    }
                    else if (parseInt(userlog.userType) === 3 && parseInt(userlog.id) == 5985) {
                      window.location.href = "/DifuzaCarz/10";
                    }

                    else if (parseInt(userlog.userType) === 3) {
                      //this.props.history.replace('/superAdmin');
                      window.location.href = "/superAdmin";
                    } else if (parseInt(userlog.userType) === 160) {
                      //this.props.history.replace('/superAdmin');
                      window.location.href = "/DifuzaCarz/10";
                    } else if (
                      parseInt(userlog.userType) === 6 &&
                      parseInt(userlog.serviceId) === 8
                    ) {
                      if (this.state.link) {
                        window.location.href = this.state.link;
                      } else {
                        window.location.href = "/CMS/Home";
                      }
                    } else if (parseInt(userlog.customerId) === 246 && parseInt(userlog.userType) === 6) {
                      window.location.href = '/Crucible/dashborad';
                    }
                    else if (
                      (parseInt(userlog.userType) === 6 ||
                        parseInt(userlog.userType) === 8) &&
                      parseInt(userlog.serviceId) === 9
                    ) {
                      window.location.href = "/difuzaUser";
                    } else if (
                      parseInt(userlog.serviceId) == 10 &&
                      parseInt(userlog.userType) != 3 &&
                      parseInt(userlog.userType) != 3
                    ) {
                      //console.log(userlog)
                      window.location.href = "/DFCars/UserDashboard";
                    }
                    else if (parseInt(userlog.serviceId) == 11) {
                      window.location.href = "/crm/crmdashboard"
                    }
                    else if (parseInt(userlog.serviceId) == 12) {
                      console.log(userlog)
                      window.location.href = '/R7/UserDashboard';
                    }
                    else if (parseInt(userlog.serviceId) == 14) {
                      console.log(userlog)
                      window.location.href = '/Lead/UserDashboard';
                    }
                    else if (parseInt(userlog.userType) === 144) {
                      // window.open('https://eoi.ttsrinath.com/')
                      window.location.href = 'https://eoi.ttsrinath.com/'
                      this.setState({ preloader1: false });
                      //  '/EntrepreneurialOrientationInventory/13';
                      // this.props.history.replace('//EntrepreneurialOrientationInventory/');
                    }
                  }
                }
              }
            } catch (error) {
              if (error.response.status === 204) {
                this.setState({
                  errorMobileNumber: "Mobile number does not exist"
                });
              }
              console.log(error);
            }
          }
        }
      } else {
        this.setState({
          errorMobileNumber: "User Not Found"
        });
      }
    }
  }
  error = response => {
    console.error(response) // eslint-disable-line
  }

  handleUserInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  componentDidMount() {
    let userlog = JSON.parse(localStorage.getItem(`userlog`));
    if (userlog) { this.Redirectpage(userlog) }
    const link = document.createElement("link");
    link.href = "../assets/Hellocss/bootstrap.min.css";
    link.rel = "stylesheet";
    document.body.appendChild(link);

    const link1 = document.createElement("link");
    link1.href = "../assets/Hellocss/style.css";
    link1.rel = "stylesheet";
    document.body.appendChild(link1);

    var body = document.getElementsByTagName("body");
    body[0].setAttribute("style", "justify-content: center");

    setTimeout(() => {
      this.setState({ set: true });
      const script = document.createElement("script");
      script.src = "../assets/Hellojs/script.js";
      script.async = true;
      document.body.appendChild(script);
    }, 300);
    localStorage.setItem("title", "difuza");

    document.getElementsByTagName("title")[0].innerHTML = localStorage.getItem(
      "title"
    );
  }
  _handleKeyDown = e => {
    if (e.key === "Enter") {
      if (this.state.placeholder === 1) {
        this.handleSubmit();
      } else {
        this.handleSubmitOTP();
      }
    }
  };
  responseGoogle = (response) => {
    console.log(response);
  }
  Redirectpage = (userlog) => {
    console.log(userlog, "userlog");
    // window.location.href = "/subAdmin";
    if (parseInt(userlog.userType) === 1) {
      // this.props.history.replace("/admin");
      window.location.href = "/admin";
    } else if (parseInt(userlog.userType) === 2) {
      // this.props.history.replace("/subAdmin");
      window.location.href = "/subAdmin";
    } else if (parseInt(userlog.userType) === 3 && parseInt(userlog.id) == 620) {
      window.location.href = "/DifuzaCarz/10";
    }
    else if (parseInt(userlog.userType) === 3 && parseInt(userlog.id) == 5985) {
      window.location.href = "/DifuzaCarz/10";
    }
    else if (parseInt(userlog.userType) === 3) {
      //this.props.history.replace('/superAdmin');
      window.location.href = "/superAdmin";
    } else if (parseInt(userlog.userType) === 160) {
      //this.props.history.replace('/superAdmin');
      window.location.href = "/DFCars/UserDashboard";
    }
    else if (
      parseInt(userlog.userType) === 6 &&
      parseInt(userlog.serviceId) === 8
    ) {
      if (this.state.link) {
        window.location.href = this.state.link;
      } else {
        window.location.href = "/CMS/Home";
      }
    } else if (parseInt(userlog.customerId) === 246 && parseInt(userlog.userType) === 6) {
      window.location.href = '/Crucible/dashborad';
    }
    else if (
      (parseInt(userlog.userType) === 6 ||
        parseInt(userlog.userType) === 8) &&
      parseInt(userlog.serviceId) === 9
    ) {
      window.location.href = "/difuzaUser";
    } else if (
      parseInt(userlog.serviceId) == 10 &&
      parseInt(userlog.userType) != 3 &&
      parseInt(userlog.userType) != 3
    ) {
      //console.log(userlog)
      window.location.href = "/DFCars/UserDashboard";
    }
    else if (parseInt(userlog.serviceId) == 11) {
      window.location.href = "/crm/crmdashboard"
    }
    else if (parseInt(userlog.serviceId) == 12) {
      console.log(userlog)
      window.location.href = '/R7/UserDashboard';
    }
    else if (parseInt(userlog.serviceId) == 14) {
      console.log(userlog)
      window.location.href = '/Lead/UserDashboard';
    }
    else if (parseInt(userlog.userType) === 144) {
      // window.open('https://eoi.ttsrinath.com/')
      window.location.href = 'https://eoi.ttsrinath.com/'
      this.setState({ preloader1: false });
      //  '/EntrepreneurialOrientationInventory/13';
      // this.props.history.replace('//EntrepreneurialOrientationInventory/');
    } else if (parseInt(userlog.userType) == 4 && parseInt(userlog.customerId) === 11025) {
      window.location.href = '/PsychologySchoolReports'
    }
  }
  handleSubmitWithPass = async (e, type) => {
    if (type == "direct" || e.key == "Enter") {
      await this.setState({ errorlogin: "", preloader: false })
      await this.setState(
        {
          isToastOpen: true,
          Toastmsg: "Checking.. please Wait",
          Toastcolor: "bg-warning"
        })
      let mobile = this.state.mobile;
      let password = this.state.password;
      if (mobile === "") {
        await this.setState({ errorMobileNumber1: "Enter Mobile Number", isToastOpen: false, });
        return false;
      } else if (
        !/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/.test(mobile)
      ) {
        await this.setState({ errorMobileNumber1: "Invalid Mobile Number", isToastOpen: false, });
        return false;
      } else if (password == '') {
        await this.setState({ errorpassword: "Enter Password", isToastOpen: false, });
        return false;
      }
      else {
        await this.setState({ preloaderwp: true, errorpassword: "", errorMobileNumber1: "" })
        let { data: result } = await CmsContent.WithPassowrdlogin(mobile, password)
        console.log(result, mobile);
        if (result.code == "User Found") {
          let { data: login_otp_notsend } = await CmsContent.getFreedom(
            "*",
            "tbl_user_web",
            `mobileNumber="${mobile}"`,
            1,
            1
          );
          // console.log(login_otp_notsend);

          if (login_otp_notsend.length) {
            const result = await login.Freelogin(
              login_otp_notsend[0].mobileNumber,
              ""
            );
            let userOrder = await CmsContent.getFreedom(
              "*",
              "tbl_UserType",
              `id	 = ${login_otp_notsend[0].userType}`,
              "id",
              "id"
            );

            if (userOrder) {
              localStorage.setItem(`usertypelog`, JSON.stringify(userOrder.data[0]));
            }
            if (this.state.local || result) {
              let userlog = "";
              try {
                const result = await login.Freelogin(login_otp_notsend[0].mobileNumber, "");
                // this.setState({ preloader: false });
                if (result) {
                  if (result && result.data[0].serviceId == 10) {
                    if (localStorage.getItem("menu")) {
                      localStorage.removeItem("menu")
                    }
                    if (localStorage.getItem("Downline_Details")) {
                      localStorage.removeItem("Downline_Details")
                    }
                  }
                  var id = result.data[0].id;
                  localStorage.setItem("userId", JSON.stringify(result.data[0].id));
                  let categoryArray = {};
                  categoryArray.loginStatus = "online";
                  const { data: data } = await CmsContent.updateMaster(
                    "tbl_user_web",
                    id,
                    categoryArray
                  );

                  if (data) {
                    const result1 = await CmsContent.getFreedom(
                      "*",
                      "tbl_user_web",
                      "loginStatus = 'online'",
                      1,
                      1
                    );
                    if (result1 && result.data[0].serviceId != 10) {
                      result1.data.map((ival, i) => {
                        if (result.data[0].id === ival.id) {
                          localStorage.setItem(
                            `userlog${i}`,
                            JSON.stringify(result.data[0])
                          );
                          userlog = JSON.parse(localStorage.getItem(`userlog${i}`));
                        } else {
                          localStorage.setItem(
                            `userlog${i}`,
                            JSON.stringify(result1.data[i])
                          );
                        }
                      });
                    }

                    localStorage.setItem(`userlog`, JSON.stringify(result.data[0]));

                    // // Here we set user is logged in
                    // let info =await JSON.parse(localStorage.getItem('info'));
                    // if(info && info.width){
                    //   info.id=userlog.id;
                    //   console.log(info)
                    //   await localStorage.setItem(`info`, JSON.stringify(info));
                    // }

                    userlog = JSON.parse(localStorage.getItem(`userlog`));

                    let userArray = {};
                    userArray.userId = userlog.id;
                    userArray.userName = userlog.userName;
                    userArray.ip = this.state.Ip;
                    userArray.browser = this.state.browser;
                    userArray.device = this.state.device;
                    userArray.os = this.state.os;
                    userArray.customerId = userlog.customerId;
                    userArray.userType = userlog.userType;

                    if (!this.state.IPTime) {
                      let date = new Date();
                      userArray.createdAt = this.date(date);
                    } else {
                      userArray.createdAt = this.date(this.state.IPTime);
                    }
                    await this.setState(
                      {
                        isToastOpen: true,
                        Toastmsg: `Welcome ${login_otp_notsend[0].userName}`,
                        Toastcolor: "bg-success"
                      })
                    let res = await CmsContent.addMaster(
                      "tbl_OriginalLog",
                      userArray
                    );
                    if (res) {
                      this.Redirectpage(userlog)
                    }
                  }
                }
              } catch (error) {
                if (error.response.status === 204) {
                  this.setState({
                    errorMobileNumber: "Mobile number does not exist"
                  });
                }
                console.log(error);
              }
            }
          }
          else {
            await this.setState(
              {
                isToastOpen: true,
                Toastmsg: "User Not Found",
                Toastcolor: "bg-danger"
              })
            setTimeout(() => {
              this.setState(
                { isToastOpen: false, Toastmsg: null, Toastcolor: null })
            }, 3000);
            // Error
          }
        } else {
          await this.setState({
            errorlogin: "User Not Found", preloaderwp: false
          });
          await this.setState(
            {
              isToastOpen: true,
              Toastmsg: "User Not Found",
              Toastcolor: "bg-danger"
            })
          setTimeout(() => {
            this.setState(
              { isToastOpen: false, Toastmsg: null, Toastcolor: null })
          }, 3000);
        }
      }
    }
  }
  handleSubmit = async () => {
    let mobileNumber = this.state.mobileNumber;
    await this.setState({ preloader: true })
    if (mobileNumber === "") {
      this.setState({ errorMobileNumber: "Enter Mobile Number", preloader: false });
      return false;
    } else if (
      !/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/.test(mobileNumber)
    ) {
      this.setState({ errorMobileNumber: "Invalid Mobile Number", preloader: false });
      return false;
    } else {
      await this.setState({
        errorMobileNumber: "",
        actualNum: mobileNumber
      });
    }
    localStorage.clear();
    let login_otp_notsend = await CmsContent.getFreedom(
      "tbl_user_web.*",
      "tbl_mobile_otp_notsend,tbl_user_web",
      `tbl_mobile_otp_notsend.mobileNumber=${mobileNumber} and tbl_user_web.mobileNumber=${mobileNumber} `,
      1,
      1
    );

    if (login_otp_notsend.data.length) {
      const result = await login.Freelogin(
        login_otp_notsend.data[0].mobileNumber,
        ""
      );
      let userOrder = await CmsContent.getFreedom(
        "*",
        "tbl_UserType",
        `id	 = ${login_otp_notsend.data[0].userType}`,
        "id",
        "id"
      );
      if (userOrder) {
        localStorage.setItem(`usertypelog`, JSON.stringify(userOrder.data[0]));
      }
      if (this.state.local || result) {
        let userlog = "";
        try {
          const result = await login.Freelogin(mobileNumber, "");
          if (result) {
            var id = result.data[0].id;
            let categoryArray = {};
            categoryArray.loginStatus = "online";
            const { data: data } = await CmsContent.updateMaster(
              "tbl_user_web",
              id,
              categoryArray
            );
            if (result.data[0].serviceId == 10) {
              const Location = await CmsContent.getFreedom(
                `tbl_location.*`,
                `tbl_user_web,tbl_MaptoLocation,tbl_location`,
                `tbl_user_web.userportid = tbl_MaptoLocation.id and tbl_user_web.id=${id} and tbl_MaptoLocation.locationId = tbl_location.id`,
                1,
                1
              );
              if (Location) {
                localStorage.setItem("LocationID", JSON.stringify(Location.data[0].id));
              }
            }
            if (data) {
              const result1 = await CmsContent.getFreedom(
                "*",
                "tbl_user_web",
                "loginStatus = 'online'",
                1,
                1
              );
              if (result1) {
                result1.data.map((ival, i) => {
                  if (result.data[0].id === ival.id) {
                    localStorage.setItem(
                      `userlog${i}`,
                      JSON.stringify(result.data[0])
                    );
                    userlog = JSON.parse(localStorage.getItem(`userlog${i}`));
                  } else {
                    localStorage.setItem(
                      `userlog${i}`,
                      JSON.stringify(result1.data[i])
                    );
                  }
                });
              }
              localStorage.setItem(`userlog`, JSON.stringify(userlog));

              // // Here we set user is logged in
              // let info =await JSON.parse(localStorage.getItem('info'));
              // if(info && info.width){
              //   info.id=userlog.id;
              //   console.log(info)
              //   await localStorage.setItem(`info`, JSON.stringify(info));
              // }

              userlog = JSON.parse(localStorage.getItem(`userlog`));

              let userArray = {};
              userArray.userId = userlog.id;
              userArray.userName = userlog.userName;
              userArray.ip = this.state.Ip;
              userArray.browser = this.state.browser;
              userArray.device = this.state.device;
              userArray.os = this.state.os;
              userArray.customerId = userlog.customerId;
              userArray.userType = userlog.userType;

              if (!this.state.IPTime) {
                let date = new Date();
                userArray.createdAt = this.date(date);
              } else {
                userArray.createdAt = this.date(this.state.IPTime);
              }

              let res = await CmsContent.addMaster(
                "tbl_OriginalLog",
                userArray
              );
              if (res) {
                // window.location.href = "/subAdmin";
                this.setState({ preloader: true });
                this.Redirectpage(userlog)
                // if (parseInt(userlog.userType) === 1) {
                //   // this.props.history.replace("/admin");
                //   window.location.href = "/admin";
                // } else if (parseInt(userlog.userType) === 2) {
                //   // this.props.history.replace("/subAdmin");
                //   window.location.href = "/subAdmin";
                // } else if (parseInt(userlog.userType) === 3 && parseInt(userlog.id) == 620) {
                //   window.location.href = "/DifuzaCarz/10";
                // } else if (parseInt(userlog.userType) === 3) {
                //   //this.props.history.replace('/superAdmin');
                //   window.location.href = "/superAdmin";
                // } else if (parseInt(userlog.userType) === 160) {
                //   //this.props.history.replace('/superAdmin');
                //   window.location.href = '/DFCars/UserDashboard';
                // } else if (
                //   parseInt(userlog.userType) === 6 &&
                //   parseInt(userlog.serviceId) === 8
                // ) {
                //   if (this.state.link) {
                //     window.location.href = this.state.link;
                //   } else {
                //     window.location.href = "/CMS/Home";
                //   }
                // } else if (parseInt(userlog.customerId) === 246 && parseInt(userlog.userType) === 6) {
                //   window.location.href = '/Crucible/dashborad';
                // }
                // else if (
                //   (parseInt(userlog.userType) === 6 ||
                //     parseInt(userlog.userType) === 8) &&
                //   parseInt(userlog.serviceId) === 9
                // ) {
                //   window.location.href = "/difuzaUser";
                // } else if (
                //   parseInt(userlog.serviceId) == 10 &&
                //   parseInt(userlog.userType) != 3 &&
                //   parseInt(userlog.userType) != 3
                // ) {
                //   //console.log(userlog)
                //   window.location.href = "/DFCars/UserDashboard";
                // }
                // else if (parseInt(userlog.serviceId) == 11) {
                //   window.location.href = "/crm/crmdashboard"
                // }
                // else if (parseInt(userlog.serviceId) == 12) {
                //   console.log(userlog)
                //   window.location.href = '/R7/UserDashboard';
                // }
                // else if (parseInt(userlog.serviceId) == 14) {
                //   console.log(userlog)
                //   window.location.href = '/Lead/UserDashboard';
                // }
                // else if (parseInt(userlog.userType) === 144) {
                //   window.location.href = 'https://eoi.ttsrinath.com/'
                //   this.setState({ preloader1: false });
                //   // window.location.href = '/EntrepreneurialOrientationInventory/13';
                //   // this.props.history.replace('//EntrepreneurialOrientationInventory/');
                // }
              }
            }
          }
        } catch (error) {
          if (error.response.status === 204) {
            this.setState({
              errorMobileNumber: "Mobile number does not exist",
              preloader: false
            });
          }
          console.log(error);
        }
      }
    } else {
      try {
        const result = await login.sendOTP(mobileNumber);
        if (result) {
          if (result.data.res == false) {
            this.setState({
              errorMobileNumber: "Mobile number does not exist",
              preloader: false
            });
          } else {
            this.setState({
              mobileNumber: "",
              placeholder: 2,
              preloader: false
            });
          }
        }
      } catch (error) {
        if (error.response.status === 204) {
          this.setState({ errorMobileNumber: "Mobile number does not exist", preloader: false });
        }
        console.log(error);
      }
    }
  };

  date = dt => {
    var now = new Date(dt);
    var year = now.getFullYear();
    var month = now.getMonth() + 1;
    var day = now.getDate();
    var hour = now.getHours();
    var minute = now.getMinutes();
    var second = now.getSeconds();

    return (
      year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
    );
  };


  handleSubmitOTP = async para => {
    this.setState({ preloader: false })

    let mobileNumber = this.state.mobileNumber;
    if (!mobileNumber) {
      this.setState({ errorMobileNumber: "Enter OTP", preloader: false });
      return false;
    } else {
      this.setState({ errorMobileNumber: "" });
    }
    try {
      const result = await login.Freelogin(this.state.actualNum, mobileNumber);
      // otp not enter check
      if (result.data.length == 0) {
        this.setState({
          errorMobileNumber: "Incorrect OTP entered ", preloader: false
        });
      }
      if (result && result.data[0].serviceId == 10) {
        if (localStorage.getItem("menu")) {
          localStorage.removeItem("menu")
        }
        if (localStorage.getItem("Downline_Details")) {
          localStorage.removeItem("Downline_Details")
        }
      }
      if (result && result.data.length > 0) {
        this.setState({
          mobileNumber: "",
          errorMobileNumber: "",
          preloader: true
        });
        localStorage.setItem("userlog", JSON.stringify(result.data[0]));
        localStorage.setItem("userId", JSON.stringify(result.data[0].id));

        let userlog = localStorage.getItem("userlog");
        userlog = JSON.parse(userlog);
        localStorage.setItem("userType", userlog.userType);

        var id = userlog.id;
        let categoryArray = {};
        categoryArray.loginStatus = "online";

        let userArray = {};
        userArray.userId = userlog.id;
        userArray.userName = userlog.userName;
        userArray.ip = this.state.Ip;
        userArray.browser = this.state.browser;
        userArray.device = this.state.device;
        userArray.os = this.state.os;
        userArray.customerId = userlog.customerId;
        userArray.userType = userlog.userType;

        if (!this.state.IPTime) {
          let date = new Date();
          userArray.createdAt = this.date(date);
        } else {
          userArray.createdAt = this.date(this.state.IPTime);
        }

        const { data: data } = await CmsContent.updateMaster(
          "tbl_user_web",
          id,
          categoryArray
        );
        if (data) {
          let res = await CmsContent.addMaster("tbl_OriginalLog", userArray);
          console.log(userArray.data);
          if (res) {
            this.setState({ preloader: true });
            if (parseInt(userlog.userType) === 1) {
              //this.props.history.replace('/admin');
              window.location.href = "/admin";
            } else if (parseInt(userlog.userType) === 2) {
              //this.props.history.replace('/subAdmin');
              window.location.href = "/subAdmin";
            } else if (parseInt(userlog.userType) === 3 && parseInt(userlog.id) == 620) {
              window.location.href = "/DifuzaCarz/10";
            }
            else if (parseInt(userlog.userType) === 3 && parseInt(userlog.id) == 5985) {
              window.location.href = "/DifuzaCarz/10";
            }
            else if (parseInt(userlog.userType) === 3) {
              //this.props.history.replace('/superAdmin');
              // window.location.href = "/superAdmin";

              window.location.href = "/superAdmin";
            } else if (parseInt(userlog.userType) === 160) {
              //this.props.history.replace('/superAdmin');
              window.location.href = "/DifuzaCarz/10";
            } else if (
              parseInt(userlog.userType) === 6 &&
              parseInt(userlog.serviceId) === 8
            ) {
              window.location.href = "/CMS/Home";
            } else if (parseInt(userlog.serviceId) == 14) {
              console.log(userlog)
              window.location.href = '/Lead/UserDashboard';
            }
            else if (parseInt(userlog.customerId) === 246 && parseInt(userlog.userType) === 6) {
              window.location.href = '/Crucible/dashborad';
            }
            else if (
              (parseInt(userlog.userType) === 6 ||
                parseInt(userlog.userType) === 8) &&
              parseInt(userlog.serviceId) === 9
            ) {
              window.location.href = "/difuzaUser";
            }
          } else {
            this.setState({
              show: 2,
              errorMobileNumber: "Incorrect Otp", preloader: false
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  resend = async () => {
    this.setState({ mobileNumber: "" });
    const result = await login.loginOTPCheck(this.state.actualNum, null);
    if (result) {
      this.setState({
        show: 1,
        errorMobileNumber: "Your OTP has been sent",
        placeholder: 2
      });
    }
  };

  reset = () => {
    this.setState({
      preloader: false,
      show: 1,
      placeholder: 1,
      mobileNumber: "",
      errorMobileNumber: ""

    });
  };

  contactname = e => {
    console.log(e.target.value);
    this.setState({ contactname: e.target.value });
  };

  contactemail = e => {
    this.state = { contactemail: "" };
    this.setState({ contactemail: e.target.value });
    //  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //   if (!e.target.value.match(mailformat)) {
    //     this.setState({ cotacterroremail: "Please Enter correct mail" });
    //   } else {
    //     this.setState({ cotacterroremail: "" });
    //   }
  };

  organization = e => {
    this.state = { organization: "" };
    this.setState({ organization: e.target.value });
  };

  contactnumber = e => {
    this.state = { contactnumber: "" };
    this.setState({ contactnumber: e.target.value });
    // if (e.target.value.length < 4) {
    //   this.setState({ cotacterrornumber: "Please enter number" });
    // } else {
    //   this.setState({ cotacterrornumber: "" });
    // }
  };
  submit = async () => {
    let cotacterroremailvalidate = ""
    if (!this.state.contactemail) {
      // this.setState({ cotacterroremail: "Please Enter Email" });
      cotacterroremailvalidate = "Please Enter Email"
    } else {
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!this.state.contactemail.match(mailformat)) {
        // this.setState({ cotacterroremail: "Please Enter correct mail" });
        cotacterroremailvalidate = "Please Enter correct mail"
      } else {
        // this.setState({ cotacterroremail: "" });
        cotacterroremailvalidate = ""
      }
    }
    await this.setState({ cotacterroremail: cotacterroremailvalidate })
    console.log(cotacterroremailvalidate)

    let contactnumbervalidate = ""
    if (!this.state.contactnumber) {
      // this.setState({ cotacterrornumber: "Please enter number" });
      contactnumbervalidate = "Please enter number"
    } else {
      if (this.state.contactnumber.length < 4 || (!this.state.contactnumber.match(/^\d+/))) {
        //  this.setState({ cotacterrornumber: "Please enter number" });
        contactnumbervalidate = "Please enter number"
      } else {
        // this.setState({ cotacterrornumber: "" });
        contactnumbervalidate = ""
      }
    }
    await this.setState({ cotacterrornumber: contactnumbervalidate })
    console.log(contactnumbervalidate)
    !this.state.contactname
      ? this.setState({ cotacterrorname: "Please enter Name" })
      : this.setState({ cotacterrorname: "" });
    !this.state.organization
      ? this.setState({ cotacterrorrorganization: "Please enter organization" })
      : this.setState({ cotacterrorrorganization: "" });

    let captchacapturevalidate = ""
    if (!this.state.captchacapture) {
      //this.setState({ errorcaptchacapture: "Please enter captcha" });
      captchacapturevalidate = "Please enter captcha"
    } else {
      if (this.state.captchacapturevalid != this.state.captchacapture) {
        //  this.setState({ errorcaptchacapture: "Please enter correct captcha" });
        captchacapturevalidate = "Please enter correct captcha"
      } else {
        // this.setState({ errorcaptchacapture: "" });
        captchacapturevalidate = ""
      }
    }
    await this.setState({ errorcaptchacapture: captchacapturevalidate });
    console.log(captchacapturevalidate)

    console.log("this.state.errorcaptchacapture", this.state.errorcaptchacapture)
    console.log("this.state.cotacterrornumber", this.state.cotacterrornumber)
    console.log("this.state.cotacterroremail", this.state.cotacterroremail);
    if (
      this.state.errorcaptchacapture == "" &&
      this.state.cotacterrornumber == "" &&
      this.state.contactname &&
      this.state.contactemail &&
      this.state.cotacterroremail == "" &&
      this.state.organization &&
      this.state.contactnumber
    ) {
      const formdata = new FormData();
      formdata.append("name", this.state.contactname);
      formdata.append("email", this.state.contactemail);
      formdata.append("organization", this.state.organization);
      formdata.append("contactnumber", this.state.contactnumber);
      this.setState({ contactloader: true });

      console.log('true');
      let result = await CmsContent.contactform(formdata);
      console.log(result.data);

      if (result) {
        this.setState({
          contactloader: false,
          captchacapture: "",
          contactname: "",
          contactemail: "",
          contactnumber: "",
          organization: "",
          contactalertvisible: true
        });
      }
    }
  };
  parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }
  Social_Login_Hub = async (response, type) => {
    try {
      await this.setState(
        {
          isToastOpen: true,
          Toastmsg: "Checking.. please Wait",
          Toastcolor: "bg-warning"
        })
      if (type == "Google" && response && response.credential) {
        let decode = await this.parseJwt(response.credential)
        console.log(decode, "decode");
        if (decode.email_verified) {
          let check = await CmsContent.getFreedom(
            "*",
            "tbl_user_web",
            `email = '${decode.email}' and status = "active"`,
            1, 1)
          if (check && check.data && check.data.length > 0) {
            console.log(check.data[0], "check");
            let mobile = check.data[0].mobileNumber
            if (check && check.data && check.data.length > 0) {
              let { data: login_otp_notsend } = await CmsContent.getFreedom(
                "*",
                "tbl_user_web",
                `mobileNumber="${mobile}"`,
                1,
                1
              );
              // console.log(login_otp_notsend);

              if (login_otp_notsend.length) {
                const result = await login.Freelogin(
                  login_otp_notsend[0].mobileNumber,
                  ""
                );
                let userOrder = await CmsContent.getFreedom(
                  "*",
                  "tbl_UserType",
                  `id	 = ${login_otp_notsend[0].userType}`,
                  "id",
                  "id"
                );

                if (userOrder) {
                  localStorage.setItem(`usertypelog`, JSON.stringify(userOrder.data[0]));
                }
                if (this.state.local || result) {
                  let userlog = "";
                  try {
                    const result = await login.Freelogin(login_otp_notsend[0].mobileNumber, "");
                    // this.setState({ preloader: false });
                    if (result) {
                      if (result && result.data[0].serviceId == 10) {
                        if (localStorage.getItem("menu")) {
                          localStorage.removeItem("menu")
                        }
                        if (localStorage.getItem("Downline_Details")) {
                          localStorage.removeItem("Downline_Details")
                        }
                      }
                      var id = result.data[0].id;
                      localStorage.setItem("userId", JSON.stringify(result.data[0].id));
                      let categoryArray = {};
                      categoryArray.loginStatus = "online";
                      const { data: data } = await CmsContent.updateMaster(
                        "tbl_user_web",
                        id,
                        categoryArray
                      );

                      if (data) {
                        const result1 = await CmsContent.getFreedom(
                          "*",
                          "tbl_user_web",
                          "loginStatus = 'online'",
                          1,
                          1
                        );
                        if (result1 && result.data[0].serviceId != 10) {
                          result1.data.map((ival, i) => {
                            if (result.data[0].id === ival.id) {
                              localStorage.setItem(
                                `userlog${i}`,
                                JSON.stringify(result.data[0])
                              );
                              userlog = JSON.parse(localStorage.getItem(`userlog${i}`));
                            } else {
                              localStorage.setItem(
                                `userlog${i}`,
                                JSON.stringify(result1.data[i])
                              );
                            }
                          });
                        }

                        localStorage.setItem(`userlog`, JSON.stringify(result.data[0]));

                        // // Here we set user is logged in
                        // let info =await JSON.parse(localStorage.getItem('info'));
                        // if(info && info.width){
                        //   info.id=userlog.id;
                        //   console.log(info)
                        //   await localStorage.setItem(`info`, JSON.stringify(info));
                        // }

                        userlog = JSON.parse(localStorage.getItem(`userlog`));

                        let userArray = {};
                        userArray.userId = userlog.id;
                        userArray.userName = userlog.userName;
                        userArray.ip = this.state.Ip;
                        userArray.browser = this.state.browser;
                        userArray.device = this.state.device;
                        userArray.os = this.state.os;
                        userArray.customerId = userlog.customerId;
                        userArray.userType = userlog.userType;

                        if (!this.state.IPTime) {
                          let date = new Date();
                          userArray.createdAt = this.date(date);
                        } else {
                          userArray.createdAt = this.date(this.state.IPTime);
                        }
                        await this.setState(
                          {
                            isToastOpen: true,
                            Toastmsg: `Welcome ${check.data[0].userName}`,
                            Toastcolor: "bg-success"
                          })
                        let res = await CmsContent.addMaster(
                          "tbl_OriginalLog",
                          userArray
                        );
                        if (res) {
                          this.Redirectpage(userlog)
                        }
                      }
                    }
                  } catch (error) {
                    if (error.response.status === 204) {
                      this.setState({
                        errorMobileNumber: "Mobile number does not exist"
                      });
                    }
                    console.log(error);
                  }
                }
              }
              else {
                await this.setState(
                  {
                    isToastOpen: true,
                    Toastmsg: "User Not Found",
                    Toastcolor: "bg-danger"
                  })
                setTimeout(() => {
                  this.setState(
                    { isToastOpen: false, Toastmsg: null, Toastcolor: null })
                }, 3000);
                // Error
              }
            }
            else {
              await this.setState(
                {
                  isToastOpen: true,
                  Toastmsg: "User Not Found",
                  Toastcolor: "bg-danger"
                })
              setTimeout(() => {
                this.setState(
                  { isToastOpen: false, Toastmsg: null, Toastcolor: null })
              }, 3000);
              // Error
            }
          }
          else {
            await this.setState(
              {
                isToastOpen: true,
                Toastmsg: "User Not Found",
                Toastcolor: "bg-danger"
              })
            setTimeout(() => {
              this.setState(
                { isToastOpen: false, Toastmsg: null, Toastcolor: null })
            }, 3000);
            // Error
          }
        } else {
          await this.setState(
            {
              isToastOpen: true,
              Toastmsg: "Something Went Wrong .. Please Try Again!",
              Toastcolor: "bg-danger"
            })
          setTimeout(() => {
            this.setState(
              { isToastOpen: false, Toastmsg: null, Toastcolor: null })
          }, 3000);
          // Error
        }
      }
      if (type == "Error") {
        // Error

        await this.setState(
          {
            isToastOpen: true,
            Toastmsg: "Something Went Wrong .. Please Try Again!",
            Toastcolor: "bg-danger"
          })
        setTimeout(() => {
          this.setState(
            { isToastOpen: false, Toastmsg: null, Toastcolor: null })
        }, 3000);
      }
    } catch (error) {
      this.setState({ Preloader: false })

      console.log(error);
    }
  }

  captchaview = async () => {
    console.log("captchaview");

    var captcha = await CmsContent.createcaptcha();

    console.log(captcha.data.text);

    this.setState({
      dataimage: captcha.data.data,
      captchacapturevalid: captcha.data.text
    });
  };

  captchacapture = e => {
    console.log("test");
    this.setState({ captchacapture: e.target.value });
    //  if (this.state.captchacapturevalid != e.target.value) {
    //    this.setState({ errorcaptchacapture: "Please enter correct captcha" });
    //  } else {
    //    this.setState({ errorcaptchacapture: "" });
    //  }
    console.log(this.state.captchacapturevalid);
  };

  resetfield = e => {
    console.log("reset ");
    this.setState({ placeholder: 1, errorMobileNumber: "", preloader: false });
  };

  resendotp = async e => {
    this.setState({ mobileNumber: "" });
    const result = await login.sendOTP(this.state.actualNum);
    if (result) {
      this.setState({
        errorMobileNumber: "OTP sent",
        placeholder: 2
      });
    }
  };
  loginchange = async () => {
    await this.setState(state => ({ isToggleOn: !state.isToggleOn }));
    if (this.state.isToggleOn === true) {
      await this.setState({ loginwpass: 1 })
    } else (
      await this.setState({ loginwpass: 0 })
    )
  };
  render() {
    return (
      <React.Fragment>
        {this.state.set && (
          <div>

            <div class="base">
              <div class="menu">
                <div class="icon">
                  <div class="bar"></div>
                </div>
              </div>
              <div class="icons">
                <i class="fa df" aria-hidden="true">
                  df
                </i>
                <i class="fa fa-phone icon2" aria-hidden="true"></i>
                <i class="fa fa-sign-in icon3" aria-hidden="true"></i>
              </div>

              <div class="section">
                <div class="cover1">
                  <div class="cover2">
                    <div class="content menuClick" data-anchor="2"></div>
                  </div>
                </div>
              </div>
              <div class="section-static top menuClick" data-anchor="1"></div>
              <div
                class="section-static bottom menuClick"
                data-anchor="3"
                onClick={(e) => this.captchaview()}
              ></div>
            </div>
            {this.state.isToastOpen &&
              <div style={{ position: "fixed", top: 5, right: "8px", zIndex: 1000, width: "auto" }}
                className={`toast align-items-center ${this.state.Toastcolor ? this.state.Toastcolor : "bg-success"} border-0 show`}
                role="alert" aria-live="assertive" aria-atomic="true">
                <div class="d-flex">
                  <div class="toast-body">
                    <b style={{ textTransform: "uppercase" }}>{this.state.Toastmsg ? this.state.Toastmsg : "Login Successfully!!!"}</b>
                  </div>
                </div>
              </div>
            }
            <div class="containerl">
              <div id="link1" class="sections " data-anchor="1">
                <div class="cross">
                  <i class="fa fa-close"></i>
                </div>
                <div class="col">
                  <div class="section-copy">
                    <h2>About</h2>
                  </div>

                  <div class="section-list">
                    <div class="select-listContent">
                      <h2>difuza</h2>
                      <p style={{ color: " #fff" }}>
                        difuza is a versatile LXP (Learning Experience Platform)
                        aimed at disseminating structured business communication
                        data over android and ios devices.
                        <br />
                        <br />
                        The difuza LXP assimilates varied data streams, add
                        structure and diffuses actionable info to your audience.
                        <br />
                        <br />
                        difuza is ubiquitous to FinTech, EdTech, HRTech,
                        LawTech, HealthTech, PropTech, RetailTech, Management
                        Events, Crime Prevention, Community Initiatives.
                        <br />
                        <br />
                        The framework is used by Equity Market Advisories,
                        Learning and Development Consultants, Personalized
                        Training, Legal Consulting Firms, Insurance Advisories,
                        Personal Medicare, Community Organizations, Event
                        communication ventures and enterprises who need to get
                        that productive chunk of data across.
                        <br />
                        <br />
                        difuza follows the philosophy of Offline First and the
                        bite-snack-meal approach to get business information
                        from disparate sources to your personal device.
                        <br />
                        <br />
                        There is a whole new evolution of Difuza happening in
                        the months to come, stay with us to stay ahead of the
                        curve.
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div id="link2" class="sections loginSec" data-anchor="2">
                <div class="col">
                  <div class="cross">
                    <i class="fa fa-close"></i>
                  </div>
                  <div class="section-copy">
                    <h2>Login</h2>
                  </div>
                  <div class="section-list">
                    <div class="select-listContent">
                      <img
                        class="mb-4"
                        src={img2}
                        alt=""
                        width="72"
                        height="72"
                      />
                      {this.state.loginwpass === 1 && (
                        <div>
                          <input type="text"
                            className="form-control"
                            name="mobile"
                            onChange={(event) => this.handleUserInput(event)}
                            value={this.state.mobile}
                            onKeyDown={(e) => this.handleSubmitWithPass(e, "press")}
                            maxLength="10"
                            placeholder="Mobile" />
                          <p style={{ color: "white", fontSize: "17px" }}>
                            {this.state.errorMobileNumber1}
                          </p>
                          <input
                            type="password"
                            name="password"
                            className="form-control"
                            onChange={(event) => this.handleUserInput(event)}
                            onKeyDown={(e) => this.handleSubmitWithPass(e, "press")}
                            value={this.state.password}

                            placeholder="Password" />
                          <p style={{ color: "white", fontSize: "17px" }}>
                            {this.state.errorpassword}
                          </p>
                          <button type="button"
                            class="btn btn-lg btn-primary btn-block"
                            onClick={(e) => this.handleSubmitWithPass(e, "direct")}
                          >
                            <span style={{ border: "none", color: "white" }}>
                              {this.state.preloaderwp && (
                                <i
                                  style={{ marginRight: 5 }}
                                  className="fa fa-spinner fa-spin"
                                ></i>
                              )}
                              Sign in
                            </span>
                          </button>
                          <p style={{ color: "white", paddingLeft: "40%", fontSize: "17px" }}>
                            {this.state.errorlogin}
                          </p>
                        </div>
                      )}
                      {this.state.loginwpass === 0 && (<div>
                        <Input
                          class="form-control"
                          type={
                            this.state.placeholder === 1 ? "text" : "password"
                          }
                          name="mobileNumber"
                          placeholder={
                            this.state.placeholder === 1
                              ? "Mobile"
                              : "Enter Password"
                          }
                          maxlength={this.state.placeholder === 1 ? "10" : "4"}
                          autoComplete="Mobile number"
                          onChange={(event) => this.handleUserInput(event)}
                          value={this.state.mobileNumber}
                          onKeyDown={this._handleKeyDown}
                        />
                        <p style={{ color: "white", fontSize: "17px" }}>
                          {this.state.errorMobileNumber}
                        </p>
                        {
                          this.state.show === 1 && this.state.placeholder === 1 ? (
                            <button
                              type="button"
                              class="btn btn-lg btn-primary btn-block"
                              onClick={
                                this.state.placeholder === 1
                                  ? this.handleSubmit
                                  : this.handleSubmitOTP
                              }
                              block
                            >
                              <span style={{ border: "none", color: "white" }}>
                                {this.state.preloader && (
                                  <i
                                    style={{ marginRight: 5 }}
                                    className="fa fa-spinner fa-spin"
                                  ></i>
                                )}
                                Sign in
                              </span>
                            </button>
                          ) : (
                            <div>
                              <br />
                              <div className="row">
                                <div className="col-sm-4 centerbutton">
                                  <button
                                    type="button"
                                    class="btn btn-lg btn-primary  "
                                    style={{ marginLeft: 20, marginBottom: 7 }}
                                    onClick={this.handleSubmitOTP}
                                    block
                                  >
                                    {/* {this.state.button} */}
                                    <span
                                      style={{ border: "none", color: "white" }}
                                    >
                                      {this.state.preloader && (
                                        <i
                                          style={{ marginRight: 5 }}
                                          className="fa fa-spinner fa-spin"
                                        ></i>
                                      )}
                                      Sign in
                                    </span>
                                  </button>
                                </div>

                                <div className="col-sm-4 centerbutton">
                                  <button
                                    type="button"
                                    class="btn btn-lg btn-primary marginmove "
                                    style={{ marginLeft: 0, marginBottom: 7 }}
                                    onClick={this.resendotp}
                                    block
                                  >
                                    {/* {this.state.button} */}
                                    Resend OTP
                                  </button>
                                </div>

                                <div className="col-sm-4 centerbutton">
                                  <button
                                    type="button"
                                    class="btn btn-lg btn-primary "
                                    style={{ marginLeft: 20 }}
                                    onClick={this.resetfield}
                                    block
                                  >
                                    {/* {this.state.button} */}
                                    Reset
                                  </button>
                                </div>
                              </div>
                            </div>
                          )
                        }
                      </div>)}
                      <br />
                      {this.state.show === 2 && (
                        <div>
                          <button
                            type="button"
                            class="btn btn-lg btn-primary "
                            style={{ width: "48%" }}
                            onClick={this.resend}
                            block
                          >
                            {/* {this.state.button} */}
                            Resend OTP
                          </button>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <button
                            type="button"
                            class="btn btn-lg btn-primary "
                            style={{ width: "48%" }}
                            onClick={this.reset}
                            block
                          >
                            Reset
                          </button>
                        </div>
                      )}
                      <div className="row">

                        <div className="col-sm-6 text-center" >
                          <button className="btn btn-primary loginBtn" onClick={this.loginchange}
                            style={{ height: "44px" }}
                            type="button"
                          >
                            {this.state.isToggleOn ? "Login with OTP" : "Login with password"}
                          </button>
                        </div>
                        {/* <div className="col-2"></div> */}
                        <div className="col-sm-6 text-center">
                          {this.state.preloader1 ? (
                            <i
                              style={{ marginRight: 5 }}
                              className="fa fa-spinner fa-spin"
                            ></i>
                          ) :
                            <GoogleOAuthProvider clientId="369837058276-odf9vovb5eor6f3pqklbtaproc0890rb.apps.googleusercontent.com">
                              <GoogleLogin
                                onSuccess={credentialResponse => {
                                  this.Social_Login_Hub(credentialResponse, "Google")
                                }}
                                size='large'
                                icon='standard'
                                theme='outline'
                                shape='rectangular'
                                text="continue_with"
                                onError={() => {
                                  this.Social_Login_Hub({}, "Error")
                                }}
                                useOneTap={true}
                                context="signin"
                              />
                            </GoogleOAuthProvider>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="link3" class="sections " data-anchor="3">
                <div class="cross">
                  <i class="fa fa-close"></i>
                </div>
                <div class="col">
                  <div class="section-copy">
                    <h2>Contact</h2>
                  </div>
                  <div class="section-list">
                    <div class="select-listContent">
                      {this.state.contactalertvisible && (
                        <div className="row form-group">
                          <div className="col-sm-2"></div>
                          <div className="col-sm-8">
                            <span>
                              {" "}
                              Your enquiry has been sent successfully.
                            </span>
                          </div>

                          <div className="col-sm-2"></div>
                        </div>
                      )}
                      <div className="row form-group">
                        <div className="col-sm-3">
                          <label htmlFor="Input">Name</label>
                        </div>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            placeholder="Enter Your Name"
                            name="txtname"
                            class="form-control"
                            size="30"
                            value={this.state.contactname}
                            onChange={(e) => this.contactname(e)}
                          />
                          <span> {this.state.cotacterrorname} </span>
                        </div>
                        <div className="col-sm-3"> </div>
                      </div>

                      <div className="row form-group">
                        <div className="col-sm-3">
                          <label htmlFor="exampleInputEmail1">Email</label>
                        </div>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            placeholder="Enter Your Email"
                            name="txtnameemail"
                            class="form-control"
                            size="30"
                            value={this.state.contactemail}
                            onChange={(e) => this.contactemail(e)}
                          />
                          <span>{this.state.cotacterroremail} </span>
                        </div>
                        <div className="col-sm-3"> </div>
                      </div>

                      <div className="row form-group">
                        <div className="col-sm-3">
                          <label htmlFor="exampleInputEmail1">
                            Organization
                          </label>
                        </div>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control"
                            name="txtorganization"
                            placeholder="Organization"
                            size="30"
                            value={this.state.Organization}
                            onChange={(e) => this.organization(e)}
                          />
                          <span>{this.state.cotacterrorrorganization} </span>
                        </div>
                        <div className="col-sm-3"> </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-sm-3">
                          <label htmlFor="exampleInputEmail1">
                            MobileNumber
                          </label>
                        </div>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            name="number"
                            class="form-control"
                            placeholder="Enter Mobile Number"
                            size="30"
                            maxlength="10"
                            minlength="4"
                            autoComplete="Mobile number"
                            value={this.state.contactnumber}
                            onChange={(e) => this.contactnumber(e)}
                          />
                          <span> {this.state.cotacterrornumber} </span>
                        </div>
                        <div className="col-sm-3"></div>
                      </div>

                      <div className="row form-group">
                        <div className="col-sm-3">
                          <label htmlFor="exampleInputEmail1">Captcha</label>
                        </div>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            name="captcha"
                            class="form-control"
                            placeholder="Enter Captcha"
                            size="30"
                            value={this.state.captchacapture}
                            onChange={(e) => this.captchacapture(e)}
                          />
                          <span> {this.state.errorcaptchacapture} </span>
                        </div>
                        <div className="col-sm-3">
                          {this.state.dataimage && (
                            <svg
                              class="resclass"
                              style={{ height: 40, marginLeft: -25 }}
                              dangerouslySetInnerHTML={{
                                __html: this.state.dataimage,
                              }}
                            />
                          )}
                        </div>
                      </div>

                      <div className="row form-group">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">
                          <button
                            type="button"
                            class="btn btn-lg btn-primary btn-block"
                            onClick={this.submit}
                          >
                            <span style={{ border: "none", color: "white" }}>
                              {this.state.contactloader && (
                                <i
                                  style={{ marginRight: 5 }}
                                  className="fa fa-spinner fa-spin"
                                ></i>
                              )}
                              Submit
                            </span>
                          </button>
                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <br />
                      <div className="row form-group ">
                        <div className="col-sm-3" />
                        <div className="col-sm-6">
                          <label>
                            <pre style={{ color: "#fff" }}>
                              <label>difuza</label>
                              <br />
                              <label>
                                22919 Merrick Blvd #513 Laurelton NY 11413-2108
                              </label>
                            </pre>
                          </label>
                        </div>
                        <div className="col-sm-3" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container d-flex w-100 h-100">
              <div class="row align-items-center w-100">
                <div class="text-center w-100">
                  <h1 style={{ color: "#FFF" }} class="logo">
                    difuza
                  </h1>
                </div>
              </div>

              <div class="fullscreen-bg">
                <div class="overlay"></div>
                <video
                  loop
                  // muted
                  autoplay
                  poster={vidoimg}
                  class="fullscreen-bg__video"
                  width="100%"
                  preload="metadata"
                  autoPlay
                  muted
                >
                  <source src={vidosrc} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        )
        }
      </React.Fragment>
    );
  }
}
