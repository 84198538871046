import React, { Component } from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  View,
  Image
} from "@react-pdf/renderer";
import { ACCESS_POINT } from "../../../config";
import Certificate from "../../../images/CMS/certificate.png";
import Logo from "../../../images/CMS/logo_cms_cert.png";
import Sign from "../../../images/CMS/digital_signature_Mr_Jaiswal.png";
import Logo1 from "../../../images/CMS/MDC_Logo_New_Reverse-1.png";
// '../../../images/CMS/sign.png
import ExamplePDF from "./PdfCertificate";
import "../fonts/font.css";
import PreLoader from "../../CMSUser/preLoader";
export default class CmsCertificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      customerid: localStorage.getItem("userId"),
      serviceid: localStorage.getItem("currentService"),
      page2: 0,
      show: false
    };
  }
  render() {
    const {
      match: { params }
    } = this.props;
    let username = atob(params.user);
    let quizname = atob(params.quiz);
    let date = new Date(atob(params.time));
    date =
      date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear();
    let prevalue = Math.round(Number(atob(params.pre)));
    let postvalue = Math.round(Number(atob(params.post)));
    let length = username.length;

    var len = 428;
    if (length < 15) {
      len = 398;
    } else if (length > 20) {
      len = 428;
    }
    return (
      <div style={{ height: "149%", backgroundColor: "#e0c380" }}>
        <div>
          <PDFDownloadLink
            style={{ color: "white" }}
            document={
              <ExamplePDF
                username={username}
                date={date}
                quizname={quizname}
                prevalue={prevalue}
                postvalue={postvalue}
                Sign={Sign}
              />
            }
            fileName={`${username}_${quizname}.pdf`}
          >
            <button className="btn btn-success" style={{ float: "right" }}>
              Save as PDF
            </button>
          </PDFDownloadLink>
        </div>
        <div
          style={{
            backgroundImage: `url(${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/certificate_1581610769302.png)`,
            // backgroundImage: `url(${Certificate}`,
            width: 900,
            height: 635,
            backgroundRepeat: "no-repeat",
            position: "relative",
            left: "25%",
            top: "15%"
          }}
        >
          <div
            style={{
              fontFamily: "Parisienne-Regular",
              position: "absolute",
              top: 315,
              left: len,
              fontSize: 45
            }}
          >
            {username}
          </div>
          <div
            style={{
              position: "absolute",
              width: 830,
              fontFamily: "Parisienne-Regular",
              left: 250,
              top: 373,
              wordWrap: "break-word",
              fontSize: 16
            }}
          >
            <div
              style={{
                fontSize: 16,
                fontFamily: "Parisienne-Regular"
                // paddingBottom: 5
              }}
            >
              For having successfully completed an E-learning course on{" "}
              {quizname}.
            </div>
            <b>Evaluation</b> :<br />
            Pre-program evaluation - {prevalue}% - Post-program evaluation -{" "}
            {postvalue}%
          </div>
          <img
            src={Sign}
            style={{
              width: 150,
              height: 48,
              backgroundColor: "transparent",
              position: "absolute",
              left: 535,
              top: 450
            }}
          />
          <div
            style={{
              fontSize: 12,
              fontFamily: "serif",
              fontWeight: "bold",
              width: 155,
              height: 48,
              backgroundColor: "transparent",
              position: "absolute",
              left: 550,
              top: 490
            }}
          >
            Manoj Kumar Jaiswal
          </div>
          <div
            style={{
              fontSize: 12,
              fontFamily: "serif",
              fontWeight: "bold",
              width: 155,
              height: 48,
              backgroundColor: "transparent",
              position: "absolute",
              left: 550,
              top: 508
            }}
          >
            EVP & Head - MDC
          </div>
          <div
            style={{
              fontSize: 17,
              fontFamily: "serif",
              fontWeight: "bold",
              width: 155,
              height: 48,
              backgroundColor: "transparent",
              position: "absolute",
              left: 290,
              top: 490
            }}
          >
            Date
          </div>
          <div
            style={{
              fontSize: 22,
              position: "absolute",
              left: 255,
              top: 462,
              fontFamily: "Parisienne-Regular"
            }}
          >
            {date}
          </div>
        </div>
      </div>
    );
  }
}
