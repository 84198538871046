import React, { Component } from "react";
// import SingleSelect from "../../../components/Form/SingleSelect";
import LoginModal from "../../../components/Modal/Modal";
import Addformmodel from "../CMS/addPages/Addformmodel";
class addform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formList: []
    };
  }
  formdetails = async (s, v) => {
    this.setState({ [s]: v });
    if (v) {
      const previousData = [v, ...this.state.formList];
      this.setState({ formList: previousData });
    }
  };
  handleForm = selectedForm => {
    this.setState({
      formSelected: selectedForm,
      formId: selectedForm.value,
      formName: selectedForm.label
    });
  };
  render() {
    const { formList, formSelected } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Add Form</h3>
                  </div>
                  <div className="col-sm-3">
                    <div style={{ width: "1000px" }}>
                      <Addformmodel these={this.formdetails} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default addform;
