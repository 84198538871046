import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';


const ReportsPdf = ({ header, data ,title="QUIZ LIST",pg}) => {
  console.log(data)
  
  return (
    <Document>
      <Page style={styles.body}>
      <View style={styles.title}>
         <Text>{title}</Text>
        </View>
        <View style={styles.table}>
        <View style={styles.tableRow}>
            {header &&
              header.map(value => {
                  if(pg===0){
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );}
                else if(pg===1){
                    return (
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{value}</Text>
                      </View>
                    );}
                else{
                    return(
                      <View style={styles.tableCol1}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>)

                }
              })}
          </View>
 
          {data &&
            data.map((value,index) => {
                if(pg==0){
                //   var x = value.total;
                //   var y= value.count;
                //   var z= value.score;
                //   var a =value.label;
              return( 
                <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{index+1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.label}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.count}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.score}</Text>
                  </View>
                  
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.total}</Text>
                  </View>
                  </View>
                  )
              }
                 else if(pg==1){
                    //  var a=value.label;
                    // var x =value.time;
                    // var y = value.score; 
                    // var z= value.total;

                return( 
                    <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{index+1}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{value.label}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{value.score}</Text>
                      </View>
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{value.total}</Text>
                      </View>
                      
                      <View style={styles.tableCol}>
                        <Text style={styles.tableCell}>{value.time}</Text>
                      </View>
                      </View>
                      )
                }
                else{
                //    var a =value.question;
                //    var y =value.answer;
                //    var z=value.correct;
                //    var x='';
                return( 
                    <View style={styles.tableRow}>
                  <View style={styles.tableCol1}>
                      <Text style={styles.tableCell}>{index+1}</Text>
                      </View>
                      <View style={styles.tableCol1}>
                        <Text style={styles.tableCell}>{value.question}</Text>
                      </View>
                      <View style={styles.tableCol1}>
                        <Text style={styles.tableCell}>{value.answer}</Text>
                      </View>
                      <View style={styles.tableCol1}>
                        <Text style={styles.tableCell}>{value.correct}</Text>
                      </View>

                      
                      </View>
                )
                
             }
           
            })}
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    textAlign:"center"
  },
  tableColHeader: {
    marginTop:'5px',
    width: '16.6%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: '20%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol1: {
    width: '25%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 'auto',
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: 'auto',
    margin: 5,
    fontSize: 10
  },
  title:{
    fontSize:19,
    fontWeight:"bold",
    marginLeft:'38%',
    marginBottom:'2px'
  
    }
});

export default ReportsPdf;
