import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import { Alert } from "reactstrap";
import Datatable from "../../../components/Datatable/Datatable";
import AddOuizpdf from '../../../components/PDF/AddOuizpdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink, CSVDownload } from "react-csv";
import LoginModal from "../../../components/Modal/Modal";
import swal from 'sweetalert';
import Switch from "react-switch";
// import FromLibrary from "../CMS/FromLibrary";
import { ACCESS_POINT } from "../../../config";
import iconClose from "../../../images/CMS/icon-hamburger-close.svg";
import FromLibrary from "./CmndlFormLibrary";
class Evaluation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quizName: "",
      ModelquizName: "",
      send_json: [],
      alertVisible: false,
      alertVisible1: false,
      optionCount: "",
      optionJson: [],
      optionListArray: [],
      samplecsv: 0,
      optionJsonArray: [],
      data: [],
      dataListArray: [],
      customerId: localStorage.getItem("userId"),
      data_1: [],
      EditProcess: false,
      loadingArray: []
    };
  }
  MainFunc = async () => {
    try {
      let customerId = this.state.customerId;
      //  const result = await CmsContent.displayQuizQuestion("active", customerId);
      const result = await CmsContent.getFreedom(
        "tbl_question.quizName,tbl_question.id,tbl_question.customerid",
        "tbl_question",
        `customerid=${this.state.customerId} and type ='active'`, 'id', 'id desc'
      );
      // console.log(result.data)
      if (result) {
        //this.setState({ data: result.data });
        //console.log(result.data)
      }
      const getLibraryImage = await CmsContent.getFreedom(
        "id as value,file as label,type as hint",
        "tbl_image",
        `customerId='${this.state.customerId}' and file_extension in ('jpg','png','jpeg')`,
        "id",
        "id DESC"
      );
      if (getLibraryImage) {
        this.setState({ getlibrarydataImage: getLibraryImage.data });
      }
      if (result.data.length > 0) {

        const header = [
          'SI.No',
          'quizname',
          'type',
          'answer',
          'option'
        ];
        const excelHead = [
          { label: "Quizname", key: "quizName" },
          { label: "Quiztype", key: "type" },
          { label: "Question", key: "question" },
          { label: "Answer", key: "answer" },
          { label: "options", key: "options" }
        ];

        this.setState({
          header,
          excelHead,
          data: result.data
        });
      }



    } catch (error) {
      console.log(error);
    }
  }
  async componentDidMount() {
    await this.MainFunc()
  }
  column = [
    {
      Header: "Quiz Name",
      accessor: "quizName",

    },
    {
      Header: "View",
      Cell: (d) => this.viewlist(d)
    },
    {
      Header: "Edit",
      Cell: (d) => this.state.loadingArray[d.index] ? <div className="text-center"> <i
        className="fa fa-spinner fa-1x fa-spin" style={{ color: "blueviolet" }}
      ></i></div> : this.EditList(d)
    }

  ];
  column_1 = [
    {
      Header: "Quiz Name",
      accessor: "quizName",

    },

    {
      Header: "Quiz Type",
      accessor: "type"
    },
    {
      Header: "Question",
      accessor: "question"
    },
    {
      Header: "Answer",
      accessor: "answer"
    },
    {
      Header: "Options",
      accessor: "options"
    }


  ];
  /*
  {
    Header: "Quiz Type",
    accessor: "type"
  },
  {
    Header: "Question",
    accessor: "question"
  },
  {
    Header: "Answer",
    accessor: "answer"
  },
  {
    Header: "Options",
    accessor: "options"
  }
  */
  contentHeading = [
    {
      Header: "Search By Hint",
      accessor: "hint"
    },
    // {
    //   Header: "File Name",
    //   accessor: "label",
    //   Cell: d => this.aModalLink(d)
    // },
    {
      Header: "image",
      accessor: "label",
      Cell: d => d.original.label ? <img src={`${ACCESS_POINT}/cmsContent/getImages/${d.original.label.split("/")[0]}/${d.original.label.split("/")[1]}`} style={{ objectFit: 'contain', width: 100, height: 50 }} /> : null
    },
    {
      Header: "Select",
      accessor: "edit",
      Cell: d => this.checktest(d)
    }
  ];
  viewmedia = async value => {
    this.setState({
      videotype: "",
      viewmedia: ACCESS_POINT + `/superAdmin/file?fileurl=${value}`
    });
    var that = this;
    setTimeout(function () {
      if (value.split(".")[1] === "mp4") {
        that.setState({ videotype: "video" });
      } else if (
        value.split(".")[1] === "jpg" ||
        value.split(".")[1] === "png" ||
        value.split(".")[1] === "jpeg"
      ) {
        that.setState({ videotype: "image" });
      } else {
        that.setState({ videotype: null });
      }
    }, 500);
  }
  aModalLink = id => {
    let str = id.original.label;
    let res = str.split("/");
    return (
      <a href="#" onClick={() => this.viewmedia(id.original.label)}>
        {res[2]}
      </a>
    );
  };
  checktest = (id) => {
    return (
      <button
        type="button"
        className="btn btn-info"
        data-dismiss="modal"
        onClick={() => {
          // this.setState({ [this.state.label]: id.original.label, [this.state.label1]: id.original.label });
          this.JSON_refill(id.original.label, "File", this.state.Clickindex);
        }
        }
      >
        Select
      </button>
    );
  };
  viewlist = (d) => {
    // console.log(d.original)

    return (

      <center>
        <button type="button" class="btn btn-primary" onClick={() => { this.viewdata(d.original) }} data-toggle="modal" data-target="#myModal">
          view
        </button>
      </center>
    );
  }
  EditList = (d) => {
    return (
      <center>
        <button type="button" class="btn btn-warning" onClick={() => { this.EditData(d.original, d.index) }} >
          Edit
        </button>
      </center>
    );
  }
  EditData = async (Editdata, index) => {
    let loadingArray = this.state.loadingArray
    loadingArray[index] = true;
    await this.setState({ quizViewlist: [], send_json: [], loadingArray })
    let view = {};
    view.id = Editdata.id;
    view.type = 'active';

    let result = await CmsContent.displayQuizQuestion(view, this.state.customerId);
    if (result.data.length > 0) {
      await this.setState({ quizName: Editdata.quizName, customerId: Editdata.customerid, EditProcess: true, EditId: Editdata.id })
      await this.EditDataAlign(result.data)
    }
    window.scroll({ top: 10, left: 0, behavior: "smooth" });
  }

  EditDataAlign = async (Data) => {
    const { send_json } = this.state;
    console.log(Data, "data");
    let eddata = Data;
    let ismutiple = false
    if (eddata && eddata.length > 0) {
      // ismutiple = eddata.some(e=>e.type == "multiple")
      // if (ismutiple) {
      //   return true
      // }
      let wait = await eddata.map(async (ival, i) => {
        // let ans = []
        // if (ival && ival.type == "multiple") {
        //   ans.push(
        //     ival.answer.split(",")
        //   )
        ismutiple = true
        // }

        send_json.push({
          question: ival.question,
          answer: ival && ival.type == "multiple" ? ival.answer : ival.answer,
          type: ival.type,
          options: (ival && ival.type == "multiple") ? ival.options.split(",") : [],
          count: ival.count,
          questionId: ival.questionId,
          File: ival.File ? ival.File : null
        });
      })
      await Promise.all(wait)
    }
    if (send_json && send_json.length > 0) {
      let wait = await send_json && send_json.map(async (ival, i) => {
        if (ival && ival.count) {
          await this.handlePhoneCount(parseInt(ival.count), i)
          await this.printOptions(i)
        }
      })
    }
    console.log(send_json, "send_json");
    await this.setState({ send_json, loadingArray: [] });
  };

  viewdata = async (data) => {
    // console.log(data)
    let view = {};
    view.id = data.id;
    view.type = 'active';
    let result = await CmsContent.displayQuizQuestion(view, this.state.customerId);
    // console.log(result.data)
    if (result.data) {
      this.setState({ data_1: result.data })
    }
  }
  question = ad => {
    let value = JSON.parse(ad.original.contentJson);
    return value[0].question;
  };

  answer = ad => {
    let value = JSON.parse(ad.original.contentJson);
    return value[0].answer;
  };

  option = ad => {
    let value = JSON.parse(ad.original.contentJson);
    return value[0].answer;
  };

  handlechange = e => {
    if (e.target.name === "optionCount") {
      this.setState({ optionJson: [] });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  JSON_refill = (quiz_value, quiz_name, index) => {

    let send_json = this.state.send_json;
    if (index + 1) {
      var JSON_data = send_json[index];
      JSON_data[quiz_name] = quiz_value;
      JSON_data['questionId'] = index;
      send_json[index] = JSON_data;
    }
    this.setState({ send_json: send_json });
    // console.log(send_json, 'send_json');
  };

  appendOption = async (value, key, index) => {
    // console.log(value, key, index, "appendd");

    let send_json = this.state.send_json;
    // console.log(send_json, "send_jsonsend_jsonsend_jsonsend_json");
    if (index + 1) {
      var JSON_data = send_json[index];
      JSON_data["options"][key] = value;
      send_json[index] = JSON_data;
    }
    send_json[index].count = send_json[index].options.length;
    await this.setState({ send_json: send_json });
  };

  optionAnswer = async (value, key, index) => {
    if (document.getElementById(`option${key + 1}index${index + 1}`).checked) {
      let checkedstate = `option${key + 1}index${index + 1}`;

      document.getElementById(`options${key + 1}indexs${index + 1}`).innerHTML = 'Checked';
      //console.log(document.getElementById(`option${key + 1}index${index+1}`).checked )
    }
    else {
      document.getElementById(`options${key + 1}indexs${index + 1}`).innerHTML = '';
    }
    let send_json = this.state.send_json;
    if (index + 1) {
      var JSON_data = send_json[index];

      if (JSON_data["answer"]) {
        // let oldAnswer = JSON_data["answer"];
        // let newValue = `${oldAnswer},${value}`;
        // JSON_data["answer"] = newValue;
        if (document.getElementById(`option${key + 1}index${index + 1}`).checked) {
          let oldAnswer = JSON_data["answer"];
          let newValue = `${oldAnswer},${value}`;
          JSON_data["answer"] = newValue;
        } else {
          let str1 = document.getElementById(`option${key + 1}index${index + 1}`).value;
          console.log(JSON_data["answer"]);
          let newValue = (JSON_data["answer"] && JSON_data["answer"].split(',').length > 1) ? JSON_data["answer"].replace(str1, '') : null;
          if (newValue) {
            JSON_data["answer"] = this.trimCommas(newValue);
          } else {
            JSON_data["answer"] = ""
          }
        }
      } else {
        if (document.getElementById(`option${key + 1}index${index + 1}`).checked) {
          JSON_data["answer"] = value;
        }
      }
    }
    console.log(JSON_data, "JSON_data");
  };
  trimCommas = (s) => {
    s = s.replace(/,*$/, "");
    s = s.replace(/^\,*/, "");
    return s;
  }

  handlePhoneCount = async (value, index) => {
    let { send_json } = this.state
    send_json[index].count = value
    await this.setState({ send_json })
    const { optionListArray } = this.state;
    for (let i = 0; i <= index; i++) {
      if (i === index) {
        optionListArray.splice(index, 1);
        let optionObject = {};
        optionObject.index = index;
        optionObject.value = value;
        optionListArray.splice(index, 0, optionObject);
      } else {


        let optionObject = {};
        optionObject.index = null;
        optionObject.value = null;
        optionListArray.splice(index, 0, optionObject);

        // if (!optionListArray[index].value) {

        // }
      }
    }
    await this.setState({ optionListArray });
  };

  printOptions = async (index) => {
    const { optionJson, optionListArray, optionJsonArray } = this.state;
    this.setState({ optionJson: [] });
    let total = optionListArray[index].value;
    for (let i = 0; i < total; i++) {
      optionJson.push({ option: null });
    }
    // optionJsonArray.splice(index, 0, optionJson);
    optionJsonArray[index] = optionJson;
    await this.setState({ optionJsonArray });
  };

  addSingleChoice = type => {
    const { send_json } = this.state;
    send_json.push({
      question: null,
      answer: null,
      type,
      options: [],
      count: null,
      questionId: null,
      File: null
    });
    this.setState({ send_json });
  };
  confirmalert = () => {

    let { EditProcess } = this.state
    swal({
      title: "Are you sure?",
      text: `You want to ${EditProcess ? "Update" : "Submit"} this Quiz`,
      icon: `${EditProcess ? "warning" : "success"}`,
      buttons: true,
      dangerMode: true,
    })

      .then(async (willDelete) => {
        if (willDelete) {
          this.submitQuiz()
        } else {
          //   swal(`Course ${d.label} has been not Modified!`);
        }
      });
  }
  submitQuiz = async () => {
    const { send_json, quizName, customerId, EditId, EditProcess } = this.state;

    if (quizName == null || quizName == undefined || quizName == "") {
      this.setState({ error: "Please fill Quiz Name" });
      return false;
    } else {
      this.setState({ error: " " });
    }

    let today = new Date();
    let createdAt =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let jsonContent = {};
    jsonContent.quizName = quizName;
    if (EditId && EditProcess) {
      jsonContent.contentJson = JSON.stringify(send_json);
    } else {
      jsonContent.contentJson = send_json;
    }
    jsonContent.customerid = customerId;
    jsonContent.createdAt = createdAt;
    jsonContent.type = "active";
    try {
      if (EditProcess && EditProcess == true && EditId) {
        var result = await CmsContent.updateMaster("tbl_question", EditId, jsonContent);
      } else {
        var result = await CmsContent.addQuizContent(jsonContent);
      }
      if (result.data.insertId) {
        let valueArray = {};
        valueArray.quizName = quizName;
        valueArray.id = result.data.insertId;
        const newdata = [valueArray, ...this.state.data]
        this.setState({ data: newdata, quizName: "", send_json: [], alertVisible: true });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      } else {
        if (result) {
          this.setState({ quizName: "", send_json: [], alertVisible: true, EditId: "", EditProcess: false });
          setTimeout(() => this.setState({ alertVisible: false }), window.location.reload(), 3000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  removeQuiz = index => {
    const { send_json, optionJsonArray } = this.state;
    send_json.splice(index, 1);
    optionJsonArray.splice(index, 1);
    if (send_json.length != index) {
      send_json[index].questionId = index

    }
    this.setState({ send_json: [], optionJsonArray: [] });
    this.setState({ send_json, optionJsonArray });
  };


  submitcsv = async () => {
    const { ModelquizName, customerId } = this.state;
    if (ModelquizName == null || ModelquizName == undefined || ModelquizName == "") {
      this.setState({ error: "Please fill Quiz Name" });
      return false;
    } else {
      this.setState({ error: " " });
    }
    const files = this.state.csv;
    if (!files) {
      this.setState({ fileerror: "Please Select CSV File" });
      return false;
    }
    const customerid = this.state.customerid;
    const formData = new FormData();
    formData.append("file", files);
    formData.append("customerid", customerId);
    formData.append("quizName", ModelquizName);
    formData.append("type", "active");
    console.log([...formData])
    try {
      const result = await CmsContent.CMNDLQuizCSV("tbl_question", formData);
      if (result && result.data.color == "success") {
        console.log(result);
        await this.setState({
          badgeText: result.data.content1 ? result.data.content1 : result.data.content,
          badgeColor: result.data.color1 ? result.data.color1 : result.data.color,
          alertVisible: true
        })
        setTimeout(async () => {
          await this.setState({
            badgeText: "",
            badgeColor: "",
            alertVisible: false,
            error: "",
            fileerror: "",
            ModelquizName: ""
          });
          await this.MainFunc()
        }, 3000);
      }
      else {
        await this.setState({
          badgeText: result.data.content1 ? result.data.content1 : result.data.content,
          badgeColor: result.data.color1 ? result.data.color1 : result.data.color,
          alertVisible: true
        })
        setTimeout(async () => {
          await this.setState({
            badgeText: "",
            badgeColor: "",
            alertVisible: false,
            error: "",
            fileerror: "",
          });
          await this.MainFunc()
        }, 5000);
      }
    }
    catch (error) {
      console.log(error)
    }
  };

  fileupload = e => {
    this.setState({ csv: e.target.files[0] })
  }
  onDismiss = () => {
    this.setState({ alertVisible: false });
  };
  CSVbuttonChange = async () => {
    await this.setState(state => ({ isToggleOn: !state.isToggleOn }));
    if (this.state.isToggleOn === true) {
      await this.setState({ samplecsv: 1 })
    } else (
      await this.setState({ samplecsv: 0 })
    )
  };
  reset = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    this.setState({ quizName: "", send_json: [], alertVisible: false, EditId: "", EditProcess: false, Clickindex: null, optionJsonArray: [] })
  }
  selectImage = e => {
    this.setState({ file: e.target.files[0] });
    this.setState({ fileName: e.target.files[0].name });
  //   if (e) {
  //     this.setState({
  //         file: e.map(fileItem => fileItem.file),
  //         fileName: e.map(fileItem => fileItem.file)
  //     });      
  // }
  };
  submit = async e => {
    e.preventDefault();
    const { file, hint } = this.state;
    if (!file) {
      this.setState({ errorFile: "Select File to Upload" });
      return false;
    } else if (!hint) {
      this.setState({ errorFile: "", errorHint: "Enter Searchable hint" });
      return false;
    } else {
      this.setState({ errorFile: "", btnDisable: true });
      let customerId = this.state.customerId;
      const formData = new FormData();
      // if (file) {
      //   file.map((ival) => {
      //       formData.append("file", ival);
      //   })
      // }
      formData.append("file", file)
      formData.append("type", hint);
      formData.append("customerId", customerId);
      // formData.append("storedAt", "");

      try {
        let Result = await CmsContent.insertImage(formData, "tbl_image");
        if (Result) {
          let list = this.state.getlibrarydataImage;
          const id = list[list.length - 1].id + 1;
          Result.data.value = id
          Result.data.label = Result.data.file
          Result.data.hint = Result.data.type
          list = [Result.data, ...list]
          this.setState({
            getlibrarydataImage: list,
            file: "",
            errorFile: "",
            btnDisable: false,
            alertVisible1: true,
            fileName: "",
            hint: "",
            errorHint: null
          });
          //remove success msg after 3 sec
          setTimeout(() => this.setState({ alertVisible1: false }), 3000);
        }
      } catch (error) { }
    }
  };
  render() {
    const {
      send_json,
      quizName,
      ModelquizName,
      alertVisible,
      optionCount,
      fileerror,
      optionJson,
      optionListArray,
      badgeColor,
      badgeText,
      optionJsonArray,
      data,
      dataListArray,
      error, EditProcess
    } = this.state;
    let quizViewlist = [];


    send_json.map((quizList, index) => {
      if (quizList.type === "single") {
        // console.log(quizList, "quizList");
        quizViewlist.push(
          <div>
            <div className="row form-group question-margin">
              <div className="col-sm-2">Question {index + 1}</div>
              <div className="col-sm-7">
                <textarea
                  type="text"
                  class="form-control"
                  name={`question${index}`}
                  placeholder="Enter Question"
                  onChange={e => {
                    this.JSON_refill(e.target.value, "question", index);
                  }}
                  value={quizList.question}
                // value="okk"
                />
              </div>
              <div className="col-sm-2">
                <div className="row form-group">
                  <div className="col-sm-12">Answer {index + 1}</div>
                </div>
                <div className="row form-group">
                  <div className="col-sm-6 form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={quizList && quizList.answer == "yes" ? true : false}
                      name={`answer${index}`}
                      value="yes"
                      onChange={e => {
                        this.JSON_refill(e.target.value, "answer", index);
                      }}
                    />
                    <label className="form-check-label" for="answer">
                      Yes
                    </label>
                  </div>
                  <div className="col-sm-6 form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={quizList && quizList.answer == "No" ? true : false}
                      name={`answer${index}`}
                      value="No"
                      onChange={e => {
                        this.JSON_refill(e.target.value, "answer", index);
                      }}
                    />
                    <label className="form-check-label" for="answer">
                      No
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-sm-1">
                <i
                  className="fa fa-window-close fa-3x"
                  aria-hidden="true"
                  onClick={() => this.removeQuiz(index)}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-2" />
              <div className="col-sm-3" >
                <LoginModal
                  buttonTitle="Select Image from Library"
                  title="Select Image"
                  id="hfdsf"
                  bodyText={
                    <FromLibrary
                      selectImage={this.selectImage}
                      fileName={this.state.fileName}
                      handleChange={e => this.setState({ hint: e.target.value })}
                      dismiss={this.onDismiss}
                      disableValue={this.state.btnDisable}
                      alertMsg="New Library Added"
                      submit={this.submit}
                      hint={this.state.hint}
                      alertVisible={this.state.alertVisible1}
                      datas={this.state.getlibrarydataImage}
                      columnHeading={this.contentHeading}
                      imgsource={this.state.viewmedia}
                      type={this.state.videotype}
                    />
                  }
                  // atagLink={true}
                  onClick={() => {
                    this.setState({ Clickindex: index });
                  }}
                />
              </div>
              <div className="col-sm-2">
                {quizList.File ?
                  <div style={{ position: 'relative', display: 'inline' }}>
                    <img src={ACCESS_POINT + `/superAdmin/sharefile?filename=` + quizList.File} style={{ objectFit: 'contain', height: 76 }} />
                    <img src={iconClose} style={{ height: 20, width: 20, position: 'absolute', borderRadius: '70px', backgroundColor: 'black', right: 0 }}
                      onClick={() => this.JSON_refill(null, "File", index)}></img>
                  </div>
                  : null}
              </div>
              {/* <div className="error">{quizList.File}</div> */}
            </div>
          </div >
        );
      } else {

        let optionsAppend = [];
        optionJsonArray.map((totalOption, index1) => {
          if (index1 === index) {
            totalOption.map((val, key) => {
              // console.log(quizList.answer[key] == "option3", "quizList.answer[key]", key)
              optionsAppend.push(
                <div className="col-sm-6">
                  <div className="row form-group">
                    <div className="col-sm-12">Option {key + 1}</div>
                  </div>
                  <div className="row form-group">
                    <div className="col-sm-12">
                      <div className="form-check form-check-inline">


                        < input
                          className="form-check-input"
                          type="checkbox"
                          // id="inlineCheckbox1"
                          id={`option${key + 1}index${index1 + 1}`}
                          defaultChecked={EditProcess && quizList.answer ? quizList.answer.split(",").filter(e => e == `option${key + 1}`).length > 0 ? true : false : ''}
                          // checked={quizList.answer.split(",").filter(e => e == `option${key + 1}`).length > 0 ? true : false}
                          value={`option${key + 1}`}
                          onChange={e =>
                            this.optionAnswer(e.target.value, key, index1)
                          }
                        />
                        <label
                          className="form-check-label"
                          for="inlineCheckbox1"
                        >
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder={`option ${key + 1}`}
                            onChange={e =>
                              this.appendOption(e.target.value, key, index1)
                            }
                            value={quizList.options[key]}
                          />
                        </label>

                      </div>
                      <span id={`options${key + 1}indexs${index1 + 1}`} style={{ fontSize: 15, marginLeft: 20 }}></span>
                    </div>
                  </div>
                </div>
              )
            }
            );
          }
        });

        quizViewlist.push(
          <div>
            {/* {EditProcess && <label style={{ color: "red", fontFamily: "sans-serif", fontSize: "12px" }}>*In Edit Process , You Cannot Change Select Option </label>} */}
            <div className="row form-group question-margin" style={{ marginBottom: -10 }}>
              <div className="col-sm-2">Question {index + 1}</div>
              <div className="col-sm-7">
                <textarea
                  type="text"
                  class="form-control"
                  name="question"
                  placeholder="Enter Question"
                  onChange={e => {
                    this.JSON_refill(e.target.value, "question", index);
                  }}
                  value={quizList.question}
                />
              </div>
              <div className="col-sm-2">
                <div className="row form-group">
                  <div className="col-sm-8">
                    <div className="row form-group">
                      <input
                        type="number"
                        class="form-control"
                        name="optionCount"
                        placeholder="number"
                        onChange={e =>
                          this.handlePhoneCount(e.target.value, index)
                        }
                        value={quizList.count}
                      />
                    </div>
                    <div className="row form-group">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={e => this.printOptions(index)}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-4" />
                </div>
              </div>
              <div className="col-sm-1">
                <i
                  className="fa fa-window-close fa-3x"
                  aria-hidden="true"
                  onClick={() => this.removeQuiz(index)}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-2" />
              <div className="col-sm-3" >
                <LoginModal
                  buttonTitle="Select Image from Library"
                  title="Select Image"
                  id="hfdsf"
                  bodyText={
                    <FromLibrary
                      selectImage={this.selectImage}
                      fileName={this.state.fileName}
                      handleChange={e => this.setState({ hint: e.target.value })}
                      dismiss={this.onDismiss}
                      disableValue={this.state.btnDisable}
                      alertMsg="New Library Added"
                      submit={this.submit}
                      hint={this.state.hint}
                      alertVisible={this.state.alertVisible1}
                      datas={this.state.getlibrarydataImage}
                      columnHeading={this.contentHeading}
                      imgsource={this.state.viewmedia}
                      type={this.state.videotype}
                    />
                  }
                  // atagLink={true}
                  onClick={() => {
                    this.setState({ label1: `thumbnail${index}`, label: `thumbnailname${index}`, Clickindex: index });
                  }}
                />
              </div>
              <div className="col-sm-2">
                {quizList.File ?
                  <div style={{ position: 'relative', display: 'inline' }}>
                    <img src={ACCESS_POINT + `/superAdmin/sharefile?filename=` + quizList.File} style={{ objectFit: 'contain', height: 76 }} />
                    <img src={iconClose} style={{ height: 20, width: 20, position: 'absolute', borderRadius: '70px', backgroundColor: 'black', right: 0 }}
                      onClick={() => this.JSON_refill(null, "File", index)}></img>
                  </div>
                  : null}
              </div>
              {/* <div className="error">{quizList.File}</div> */}
            </div>
            <div className="row form-group">
              <div className="col-sm-2"> </div>
              <div className="col-sm-7">
                <div className="row form-group">{optionsAppend}</div>
              </div>
              <div className="col-sm-2"> </div>
            </div>
          </div>
        );
      }
    });

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="card">
              <div className="card-body" />
              {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myModal">
    Open modal
  </button> */}


              <div class="modal" id="myModal">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">


                    <div class="modal-header">
                      <h4 class="modal-title">Evaluation</h4>
                      <button type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>


                    <div class="modal-body">
                      <Datatable
                        data={this.state.data_1}
                        columnHeading={this.column_1}
                      />
                    </div>


                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                    </div>

                  </div>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-7">
                  <Alert
                    className="badge-content"
                    color="success"
                    isOpen={alertVisible}
                    toggle={this.onDismiss}
                  >
                    Question Added Successfully
                  </Alert>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label for="exampleInputEmail1">
                    <b>Quiz Name</b>
                  </label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    class="form-control"
                    name="quizName"
                    placeholder="Enter Quiz Name"
                    onChange={this.handlechange}
                    value={quizName}
                  />
                  <span className="error-shows">{error}</span>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group">
                <div className="col-sm-1" />
                <div className="col-sm-4">
                  <button
                    type="button"
                    className="btn btn-primary btn-lg btn-block btn-radius"
                    onClick={() => this.addSingleChoice("multiple")}
                  >
                    Add Multi Choice
                  </button>
                </div>
                <div className="col-sm-4">
                  <button
                    type="button"
                    className="btn btn-primary btn-lg btn-block btn-radius"
                    onClick={() => this.addSingleChoice("single")}
                  >
                    Add Single Choice
                  </button>
                </div>
                <div className="col-sm-2" />
              </div>
              <div className="row form-group">
                <div className="col-sm-1" />
                {this.state.data && this.state.data.length > 0 && (
                  <PDFDownloadLink
                    style={{ color: 'white' }}
                    document={<AddOuizpdf title={this.state.title} header={this.state.header} data={this.state.data} />}
                    fileName="sample.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'Loading document...' : 'Download Pdf'
                    }
                    <button className="btn btn-warning">
                      PDF
                    </button>
                  </PDFDownloadLink>
                )}
                {this.state.data && this.state.data.length > 0 && (
                  <CSVLink data={this.state.data} headers={this.state.excelHead}>
                    <button className="btn btn-success" style={{ marginLeft: '12px', marginRight: "12px" }}>
                      Excel
                    </button>
                  </CSVLink>)}

                <LoginModal
                  buttonTitle="Import Csv"
                  title="Csv file upload"
                  // buttonStyle={{ marginLeft: "-105px" }}
                  extraClass="info"
                  // onClick={this.reset}
                  id="import"
                  bodyText={
                    <div className="container">
                      <Alert
                        className="badge-content"
                        color={badgeColor && badgeColor}
                        isOpen={alertVisible}
                        toggle={this.onDismiss}
                      >
                        {badgeText && badgeText}
                      </Alert>
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-2">
                          <label for="exampleInputEmail1">
                            <b>Quiz Name</b>
                          </label>
                        </div>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            class="form-control"
                            name="ModelquizName"
                            placeholder="Enter Quiz Name"
                            onChange={this.handlechange}
                            value={ModelquizName}
                          />
                          <span className="error-shows">{error}</span>
                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-2">
                          <label for="exampleInputEmail1">
                            <b>CSV Upload</b>
                          </label>
                        </div>
                        <div className="col-sm-5">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="customFile"
                            accept='.csv'
                            // value={this.state.csv}
                            onChange={this.fileupload}
                          />
                          <span className="error-shows">{fileerror}</span>
                          <br />
                          <label
                            className="custom-file-label"
                            htmlFor="customFile"
                          >
                            {this.state.csv ? this.state.csv.name : 'Choose File'}
                          </label>
                          <br />
                          <br />
                        </div>
                        <div className="col-sm-3" />
                      </div>
                      <div className="row form-group">
                        <div className="col-sm-1" />
                        {this.state.samplecsv === 0 && (
                          <div className="col">
                            <a href="../../files/QuizcsvUploadSample.csv" download="QuizSample.csv">
                              download sample.csv
                            </a>
                          </div>
                        )}
                        {this.state.samplecsv === 1 && (
                          <div className="col">
                            <table id="example" className="table table-striped table-bordered" style={{ width: "100%" }}>
                              <thead>
                                <tr>
                                  <th>Question</th>
                                  <th>Answer</th>
                                  <th>Type</th>
                                  <th>Options</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Please Type Question Name What you want</td>
                                  <td>option1</td>
                                  <td>Multiple</td>
                                  <td>correct,wrong,none of the above</td>
                                </tr>
                                <tr>
                                  <td>Please Type Question Name What you want</td>
                                  <td>option2,option3</td>
                                  <td>Multiple</td>
                                  <td>yes,no,wrong</td>
                                </tr>
                                <tr>
                                  <td>Please Type Question Name What you want</td>
                                  <td>Yes</td>
                                  <td>Single</td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>Please Type Question Name What you want</td>
                                  <td>No</td>
                                  <td>Single</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>

                      <div className="row ">
                        <div className="col" />
                        <div className="col">
                          <button className="btn btn-warning waves-effect d-flex justify-content-center" onClick={this.CSVbuttonChange}
                            type="button"
                          >
                            {this.state.isToggleOn ? "Download Samples" : "Samples In Table"}
                          </button>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            class="btn btn-primary waves-effect d-flex justify-content-center"
                            onClick={(e) => this.submitcsv(e)}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="col" />
                      </div>
                      <br />
                    </div>
                  }
                />
              </div>
            </div>

            {/* multi choice */}

            {quizViewlist.length > 0 && (
              <div className="row form-group">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      {quizViewlist}
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-3">


                          <button
                            type="button"
                            className="btn btn-success btn-lg btn-radius"
                            onClick={this.confirmalert}
                          >
                            {EditProcess && EditProcess == true ? "Update Quiz" : "Submit Quiz"}

                          </button>
                        </div>
                        <div className="col-sm-3">
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={this.reset}
                          >Reset</button>
                          <div className="col-sm-3" />
                        </div>
                        <div className="col-sm-4" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row form-group">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="col-sm-12">
                      {data && (
                        <Datatable
                          data={this.state.data}
                          columnHeading={this.column}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </main>
      </React.Fragment >
    );
  }
}

export default Evaluation;
