import React, { Component } from "react";
import { Link } from "react-router-dom";
import CmsContent from "../../MiddleWare/CmsContent";
import "./usernav.css";
import logo from "../../images/CMS/murugappa_logo1.png";
import { Alert } from "reactstrap";

class UserHomeNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      categoryArrray: [],
      fullList: [],
      adminId: JSON.parse(localStorage.getItem("userlog")).customerId,
      show: false,
      exemption: [41, 43, 44, 45, 46, 47, 49]
    };
  }

  async componentDidMount() {
    try {
      const result = await CmsContent.getFreedom(
        "tbl_mapping_page.id as subcatorderid,tbl_mapping_page.listingorder,tbl_mapping_page.listingsubcatagory,tbl_mapping_page.categoryId,tbl_mapping_page.categoryName,tbl_mapping_page.subCategoryId,tbl_mapping_page.subCategoryName,tbl_pages.id as pageId ,theme_master.themeName,tbl_pages.requireLogin",
        "tbl_mapping_page,tbl_pages,theme_master",
        `tbl_mapping_page.pageId = tbl_pages.id and tbl_pages.themeId =  theme_master.themeId and tbl_pages.customerId=${this.state.adminId} and tbl_pages.status="show" and  tbl_mapping_page.status = "active"`,
        "tbl_mapping_page.id",
        "tbl_mapping_page.listingorder,tbl_mapping_page.listingsubcatagory"
      );
      if (result) {
        this.setState({ categories: result.data });
      }
    } catch (error) {
      console.log(error);
    }
  }

  logout = () => {
    if (localStorage.getItem("title") == "difuza") {
      localStorage.clear();
      window.location = "/";
    } else if (
      localStorage.getItem("title") == "Murugappa Safety Architecture"
    ) {
      localStorage.clear();
      window.location = "/ehsportal";
    }

    //return localStorage.getItem('title') ?'' :
  };

  render() {
    const { categories, exemption } = this.state;
    var categoryData = [];
    var fullList = [];
    if (categories.length > 0) {
      const map = new Map();
      for (const item of categories) {
        if (!map.has(item.categoryId)) {
          map.set(item.categoryId, true); // set any value to Map
          categoryData.push({ categoryId: item.categoryId });
        }
      }

      fullList = categoryData.map(values => {
        return categories.filter(list => list.categoryId === values.categoryId);
      });
    }
    var chunk_size = 5;
    var groups = "";
    var fullData = [];
    var exceptionData = [];
    if (fullList.length > 0) {
      fullList = fullList.filter((ival, i) => {
        if (exemption.indexOf(ival[0].categoryId) === -1) {
          return ival;
        } else {
          exceptionData.push(ival);
        }
        return null;
      });
      fullList.map(values => {
        if (values[0].subCategoryName) {
          groups = values
            .map(function (e, i) {
              return i % chunk_size === 0
                ? values.slice(i, i + chunk_size)
                : null;
            })
            .filter(function (e) {
              return e;
            });
          var innerJsonData = [];
          groups.map(innerData =>
            innerJsonData.push(
              <ul className="single-mega cn-col-4">
                {innerData.map(dataValue => (
                  <Link
                    className="dropdown-item text-capitalize colorchange"
                    to={`/CMS/${dataValue.themeName}/${dataValue.pageId}`}
                  >
                    {dataValue.subCategoryName}
                  </Link>
                ))}
              </ul>
            )
          );

          fullData.push(
            <li className="nav-item dropdown position-static">
              <a
                className="nav-link dropdown-toggle nav-font-color"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {values[0].categoryName}
              </a>
              <div
                className="dropdown-menu w-100"
                style={{ backgroundColor: "white", marginTop: "12px" }}
                aria-labelledby="navbarDropdownMenuLink"
              >
                {innerJsonData}
              </div>
            </li>
          );
        } else {
          fullData.push(
            <li className="nav-item nav-font text-capitalize">
              <Link
                className="nav-link"
                to={`/CMS/${values[0].themeName}/${values[0].pageId}`}
              >
                <span className="nav-font-color">{values[0].categoryName}</span>
              </Link>
            </li>
          );
        }
      });
    }

    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg navbar-light ">
          <span style={{ fontSize: 13, color: "red", marginRight: 12 }}>
            {"Welcome " + JSON.parse(localStorage.getItem("userlog")).userName}
          </span>

          <a onClick={this.logout}>
            <i
              class="fa fa-sign-out hoverclass"
              aria-hidden="true"
              style={{ fontSize: 18, color: "red" }}
            ></i>
          </a>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item nav-font text-capitalize">
                <Link className="nav-link" to={`/CMS/Home`}>
                  <span className="nav-font-color">Home</span>
                </Link>
              </li>
              <li className="nav-item dropdown position-static">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Murugappa Safety Architecture
                </a>
                <div
                  className="dropdown-menu w-100"
                  aria-labelledby="navbarDropdown"
                >
                  <ul className="single-mega cn-col-4">
                    <Link
                      className="dropdown-item text-capitalize"
                      to="/CMS/singleContent/205"
                    >
                      Safestart
                    </Link>
                    <Link
                      className="dropdown-item text-capitalize"
                      to="/CMS/singleContent/205"
                    >
                      Audits
                    </Link>
                  </ul>
                </div>
              </li>

              <li className="nav-item dropdown position-static">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  About Us
                </a>
                <div
                  className="dropdown-menu w-100"
                  aria-labelledby="navbarDropdown"
                >
                  <ul className="single-mega cn-col-4">
                    <Link
                      className="dropdown-item text-capitalize"
                      to="/CMS/singleContent/205"
                    >
                      Message from the Chairman
                    </Link>
                    <Link
                      className="dropdown-item text-capitalize"
                      to="/CMS/singleContent/205"
                    >
                      Message from the Director HR
                    </Link>
                    <Link
                      className="dropdown-item text-capitalize"
                      to="/CMS/singleContent/205"
                    >
                      Message from Mr.Manoj Jaiswal
                    </Link>
                  </ul>
                </div>
              </li>
              <li className="nav-item nav-font">
                <Link className="nav-link" to={`/CMS/singleContent/205`}>
                  Partnerships and Consulting
                </Link>
              </li>
              {fullData}
              <li className="nav-item nav-font">
                <Link className="nav-link" to={`/CMS/Publication`}>
                  Publications
                </Link>
              </li>
              {/* <li className="nav-item nav-font">
                        <Link className="nav-link" to={`/CMS/singleContent/205`}>
                          Safestart
                        </Link>
                      </li> */}
              {/* <li className="nav-item nav-font text-capitalize">
                  <Link className="nav-link" to={`/CMS/VideoList`}>
                    <span className="nav-font-color">Videos</span>
                  </Link>
                </li>
                <li className="nav-item nav-font text-capitalize">
                  <Link className="nav-link" to={`/CMS/CategoryList`}>
                    <span className="nav-font-color">Categories</span>
                  </Link>
                </li> */}
              <li className="nav-item nav-font text-capitalize">
                <Link className="nav-link" to={`/CMS/Evaluation`}>
                  <span className="nav-font-color">Evaluation</span>
                </Link>
              </li>
              <li className="nav-item nav-font text-capitalize">
                <Link className="nav-link" to={`/CMS/Gamification`}>
                  <span className="nav-font-color">Gamification</span>
                </Link>
              </li>
              <li className="nav-item dropdown position-static">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Online Resource Center
                </a>
                <div
                  className="dropdown-menu w-100"
                  aria-labelledby="navbarDropdown"
                  style={{ overflow: "scroll", height: 502 }}
                >
                  {exceptionData.map((ival, i) => {
                    return (
                      <ul className="single-mega cn-col-4">
                        <div>
                          <b>{ival[0].categoryName}</b>
                          {ival.map((jval, j) => {
                            if (j < 5) {
                              return (
                                <Link
                                  className="dropdown-item text-capitalize"
                                  to={`/CMS/${jval.themeName}/${jval.pageId}`}
                                >
                                  {jval.subCategoryName}
                                </Link>
                              );
                            } else if (j === 5) {
                              return (
                                <Link
                                  className="dropdown-item text-capitalize"
                                  to={`/CMS/More/${btoa(jval.categoryId)}`}
                                  style={{ textAlign: "end", color: "red" }}
                                >
                                  more..
                                </Link>
                              );
                            }
                          })}
                        </div>
                      </ul>
                    );
                  })}
                </div>
              </li>
            </ul>
          </div>
        </nav>
        {/* <Detector
          render={({ online }) => (
            <div className="text-danger">
              {online || this.state.show ? null : (
                <Alert
                  isOpen={true}
                  toggle={() => {
                    this.setState({ show: true });
                  }}
                >
                  Oops! You seem to be offline, please check your internet
                  connection and retry.
                </Alert>
              )}
            </div>
          )}
        /> */}
      </React.Fragment>
    );
  }
}

export default UserHomeNav;
