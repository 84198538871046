import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const AddQuizpdf = ({ header, data ,title="EVALUATION"}) => {
      //  console.log(header)
  return (
    <Document>
      <Page style={styles.body}>
      <View style={styles.title}>
         <Text>{title}</Text>
        </View>
        <View style={styles.table}>
        
        <View style={styles.tableRow}>
            {header &&
              header.map(value => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
          </View>

          {data &&
            data.map((value,index) => {
               
              return (
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{index+1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.quizName}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.question}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.answer}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.options}</Text>
                  </View>
                  
                </View>
              );
            })}
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop:9

  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableColHeader: {
    width: '15%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: '20%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 'auto',
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: 'auto',
    fontSize: 10
  },
   title:{
  fontSize:19,
  fontWeight:"bold",
  marginLeft:'38%',

  }
});

export default AddQuizpdf;
