import React, { Component } from "react";
import cmsContent from "../../../MiddleWare/CmsContent";
import { Alert } from "reactstrap";
import Datatable from "../../../components/Datatable/Datatable";
import { ACCESS_POINT, FILEPATH } from "../../../config";
import { CopyToClipboard } from "react-copy-to-clipboard";
import LoginModal from "../../../components/Modal/Modal";

class AddLibrary extends Component {
  //defining states
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      url: "",
      errorFile: null,
      value: "",
      btnDisable: false,
      alertVisible: false,
      fileName: "choose File",
      errorHint: null,
      hint: null,
      customerId: localStorage.getItem("userId"),
      List: []
    };
  }
  async componentDidMount() {
    try {
      const condition = this.state.customerId == 807 ? `customerId=${this.state.customerId} and storedAt='ftp'` : `customerId=${this.state.customerId}`
      const { data: List } = await cmsContent.getFreedom(
        "*",
        "tbl_image",
        condition,
        "id",
        "id DESC"
      );
      if (List) {
        this.setState({ List });
      }
    } catch (error) {
      console.log(error);
    }
  }
  //submit validation function
  submit = async () => {
    //alert("submit")
    //e.preventDefault();
    //     let value = this.state.value;
    // console.log(value)
    //     if (value === null || value.trim() === "") {
    //       return false;
    //     }
    const { file, hint } = this.state;
    console.log(file);
    console.log(hint);
    //
    if (!file) {
      this.setState({ errorFile: "Select File to Upload" });
      return false;
    } else if (!hint) {
      this.setState({ errorFile: "", errorHint: "Enter Searchable hint" });
      return false;
    } else {
      this.setState({ errorFile: "", btnDisable: true });
      let customerId = this.state.customerId;
      console.log(customerId);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", hint);
      formData.append("customerId", customerId);
      formData.append("storedAt", "ftp");
      //let groupArray ={};
      //formData.file=value.trim();
      //formData.status="active";
      // formData.customerId=this.state.customer
      ;
      //formData.serviceId=this.state.serviceId;

      try {
        const result = await cmsContent.insertImage(formData, "tbl_image");

        //let result;
        if (result) {
          let valueArray = {};
          console.log(result);
          valueArray.type = result.data.type;
          valueArray.file = result.data.file;
          valueArray.customerId = result.data.customerId
          valueArray.file_extension = result.data.file_extension

          //valueArray.uploadResult = result.List.insertId;
          //valueArray.formData = value.trim();
          //    console.log(valueArray);
          const newData = [valueArray, ...this.state.List];
          this.setState({
            List: newData,
            file: "",
            errorFile: "",
            btnDisable: false,
            alertVisible: true,
            fileName: "choose File",
            errorHint: null,
            hint: ""
          });
          //remove success msg after 3 sec
          setTimeout(() => this.setState({ alertVisible: false }), 3000);
        }
      } catch (error) { }
    }
  };

  //remove success msg
  onDismiss = () => {
    this.setState({ alertVisible: false });
  };

  //onchange event function
  selectImage = e => {
    this.setState({ file: e.target.files[0] });
    this.setState({ fileName: e.target.files[0].name });
    // console.log(e.target.files[0]);
  };

  column = [
    {
      Header: "Hint",
      accessor: "type"
    },
    {
      Header: "Link",
      accessor: "file",
      Cell: d => this.fullLink(d)
    },
    {
      id: 'file_extension',
      Header: "Type",
      accessor: d => this.file_extension(d, 1),
      Cell: d => this.file_extension(d, 2)
    },
    {
      Header: "Image",
      accessor: "file_extension",
      Cell: d => this.showimage(d)
    },
    {
      Header: "Copy",
      accessor: "file",
      Cell: d => this.clipBoard(d)
    },
    {
      Header: "Share",
      accessor: "file",
      Cell: d => this.sharelink(d)
    }
  ];

  sharelink = (d) => {
    const user = this.state.customerId == 807 ? true : false
    return (
      <div>
        <LoginModal
          title="Share Link"
          buttonTitle="Share Link"
          id="customer"
          extraClass="btn btn-warning"
          onClick={() => this.reset(d)}
          bodyText={
            <div >

              <input
                type="text"
                name="searchhint"

                className="form-control"
                value={user ? FILEPATH + this.state.url : `${ACCESS_POINT}/superAdmin/sharefile?filename=${this.state.url}`}
              />         <br></br>

              <CopyToClipboard
                text={user ? FILEPATH + this.state.url : `${ACCESS_POINT}/superAdmin/sharefile?filename=${this.state.url}`}
                onCopy={() => this.setState({ copied: true })}
              >
                <button type="button" className="btn btn-primary">
                  Copy to clipboard
                </button>
              </CopyToClipboard>
              <br></br>
              {
                this.state.copied == true && (
                  <div>
                    <span style={{ color: "green" }}>
                      URL is Copied
                    </span>
                  </div>
                )
              }
            </div>

          }
        />
      </div>
    )
  }

  reset = async (d) => {
    await this.setState({ url: d.original.file, copied: false })
  }
  fullLink = (d) => {
    if (this.state.customerId == 807) {
      return FILEPATH + d.original.file
    }
    else return `${ACCESS_POINT}/superAdmin/file?fileurl=${d.original.file}`
  }
  file_extension = (d, q) => {
    let org = (q === 1) ? d : d.original;
    let validationImage = ["jpeg", "jpg", "png", "gif"];
    let validationVideo = [
      "ogg",
      "ogv",
      "mpg",
      "mpeg",
      "mp4",
      "mp3",
      "dat",
      "avi"
    ];
    let ImageExtension = org.file_extension.toLowerCase();
    if (validationImage.indexOf(ImageExtension) !== -1) {
      return "Image";
    } else if (validationVideo.indexOf(ImageExtension) !== -1) {
      return "Video";
    } else {
      return "NA";
    }
  };
  clipBoard = d => {
    return (
      <div>
        <CopyToClipboard
          text={this.state.customerId == 807 ? FILEPATH + d.original.file : `${ACCESS_POINT}/superAdmin/file?fileurl=${d.original.file}`}
          onCopy={() => this.setState({ copied: true })}
        >
          <button type="button" className="btn btn-primary">
            Copy to clipboard
          </button>
        </CopyToClipboard>
      </div>
    );
  };
  showimage = (d) => {
    let validationImage = ["jpeg", "jpg", "png", "gif"];
    let validationVideo = [
      "ogg",
      "ogv",
      "mpg",
      "mpeg",
      "mp4",
      "mp3",
      "dat",
      "avi"
    ];
    let ImageExtension = d.original.file_extension.toLowerCase();
    if (validationImage.indexOf(ImageExtension) !== -1) {
      if (this.state.customerId == 807) {
        return (
          <img src={FILEPATH + d.original.file} style={{ width: 25, height: 25 }} />
        )
      } else {
        return (
          <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${d.original.file}`} style={{ width: 25, height: 25 }} />
        )
      }
    } else if (validationVideo.indexOf(ImageExtension) !== -1) {
      return "Video";
    } else {
      return "NA";
    }


  }
  //rendering
  render() {
    //using state values to render and return
    const { errorFile, btnDisable, alertVisible, hint, errorHint } = this.state;

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Add File</h3>
                  </div>
                  <div className="card-body" />

                  <div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color="success"
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          File Uploaded Successfully
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Upload File</label>
                      </div>
                      <div className="col-sm-5 custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          onChange={this.selectImage}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          {this.state.fileName}
                        </label>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorFile}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Search Hint</label>
                      </div>
                      <div className="col-sm-5 custom-file">
                        <input
                          type="text"
                          name="searchhint"
                          placeholder="Enter  hint"
                          className="form-control"
                          onChange={e =>
                            this.setState({ hint: e.target.value })
                          }
                          value={this.state.hint}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorHint}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          class="btn btn-primary"
                          disabled={btnDisable}
                          onClick={this.submit}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-12">
                        {this.state.List ? (<Datatable
                          data={this.state.List}
                          columnHeading={this.column}
                        />) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default AddLibrary;
