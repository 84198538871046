import React from "react";
import CmsContent from "../../../../MiddleWare/CmsContent";

class Type3 extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            boxfilename:'',
            errorfile:'',
            link:'',
            errortext:'',
            button:this.props.button,
            customerId: localStorage.getItem("userId")
       }
    }


    image =e=>{
        var filesData = e.target.files[0];  
        var filesDataLength = filesData.size;
        var filesDataName = filesData.name;
    
        var that = this;
        var binaryFileType = false;
        const file = e.target.files[0];
        const filereader = new FileReader();
        filereader.onloadend = function(evt) {
          if (evt.target.readyState === FileReader.DONE) {
            const uint = new Uint8Array(evt.target.result);
            let bytes = [];
            uint.forEach(byte => {
              bytes.push(byte.toString(16));
            });
            const hex = bytes.join("").toUpperCase();
            //alert(hex);
            if (that.getMimetype(hex) != "Unknown filetype") {
              binaryFileType = true;
              that.setState({ errorImage: true });
            }
          }
    
          if (!binaryFileType) {
            that.setState({ errorImage: false });
            that.setState({
              boxfilename: "",
              errorfile: "Please Select a Valid Image",
              errorfile1: "Please Select a Valid video",
              boxfileSize: 0
            });
            // return false;
            return false;
          } else {
            that.setState({
              appLogosize: filesData.size,
              errorfile: "",
              errorfile1: ""
            });
    
            that.setState({
              // appLogosize: e.target.files[0].size,
              boxfile: filesData,
              boxfilename: filesDataName,
              boxfileSize: filesDataLength,
              errorfile: "",
              binaryFileType: false
              //logosize: e.target.files[0].size,
              // beforeviewapplogo: ""
            });
            // if (!filesDataLength.length) return;
            // that.createImage(filesDataLength[0]);
          }
        };
        const blob = file.slice(0, 4);
        filereader.readAsArrayBuffer(blob);
    
    
      }
    
      getMimetype(signature) {
        switch (signature) {
          case "89504E47":
            return "image/png";
          case "47494638":
            return "image/gif";
          // case "25504446":
          //   return "application/pdf";
          case "FFD8FFDB":
            return "image/jpg";
          case "FFD8FFE0":
            return "image/jpeg";
          case "52494646":
            return "video/dat/avi";
          case "001B3":
            return "video/mpg/mpeg";
          case "4F676753":
            return "video/ogv/ogg";
          case "00018":
            return "video/mp4";
          case "00020":
            return "video/mp4";
          case "4944333":
            return "video/mp3";
          // case "504B0304":
          //   return "application/zip";
          // case "4D5A900":
          //   return "image/exe";
          default:
            return "Unknown filetype";
        }
      }
    
 
      
    link = e => {
        this.setState({ link: e.target.value });
      };

      componentWillReceiveProps(nextProps) {
        if (nextProps) {
          this.setState({
            button: nextProps.button,
            boxname: nextProps.boxname,
            boxvalue: nextProps.boxvalue,
            thumbnail: nextProps.thumbnail,
            traytext: nextProps.traytext,
            portletHeading: nextProps.portletHeading,
            descriptionText: nextProps.descriptionText,
            newdate: nextProps.newdate
          });
        }
      }


      is_url(str) {
        var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (regexp.test(str)) {
          return true;
        } else {
          return false;
        }
      }


      onSubmit = async () => {


console.log('submit ')

if(!this.state.boxname) 
{

this.props.errormessageboxname('errorname','Please Enter Box Name');
console.log('boxname empty')

}
else
{
 this.props.errormessageboxname('errorname',''); 

}


if(!this.state.portletHeading) 
{
 console.log('portletHeading empty')
 this.props.errormessageheading('errorheading','Please Enter Portlet Heading');

}
 
else
{
 this.props.errormessageheading('errorheading',''); 

}

if(!this.state.thumbnail) 
{
  this.props.errorthumbnail('errorthumbnail','Please Select a thumbnail');

}
 
else
{
 this.props.errorthumbnail('errorthumbnail',''); 

}
 

if(!this.state.boxfile)
{

this.setState({errorfile :'Please Select a image'})

}
else

{

 this.setState({errorfile :''})


}

if(!this.state.link)
{

this.setState({errortext :'Please enter link'})

}
else

{

 this.setState({errortext :''})


}

if (!this.is_url(this.state.link)) {
    this.setState({ errortext: "Please enter a valid Link" });
    return false
  }



 
let filename = this.state.boxfilename.split(".");

let formData = new FormData();
formData.append("name", this.state.boxname);
formData.append("type", this.state.boxvalue);
formData.append("thumbnail", this.state.thumbnail);
formData.append("notificationtext", this.state.traytext);
formData.append("heading", this.state.portletHeading);
formData.append("description", this.state.descriptionText);
// formData.append("type", this.state.selectSurvey.value);
formData.append("customerId", this.state.customerId);
formData.append("status", "active");
formData.append("createdAt", this.state.newdate);
formData.append("url", this.state.link);
formData.append("media", this.state.boxfile);
formData.append("extension", filename[1]);
 

let validationImage = ["jpeg", "jpg", "png", "gif"];
 var extension = this.state.boxfile.name.split(".").pop();
let ImageExtension = extension.toLowerCase();
if (validationImage.indexOf(ImageExtension) == -1) {
  this.setState({ errorfile: "Please Choose Valid Image File" });
  return false;
} else {
  this.setState({ errorfile: "" });
}

if(this.state.boxname && this.state.portletHeading && this.state.thumbnail && this.state.boxfile) 
{


    console.log([...formData])
    this.submitCommon(formData);
    
}


      }

       
  submitCommon = async formData => {

  //  this.props.handler(this.state.boxvalue,this.props.alertVisible)

    try {
      const result = await CmsContent.authorinsert(
        formData,
        "tbl_boxcontent"
      );
      console.log(result.data)
      if (result) {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
         
        this.props.handler(this.state.boxvalue,this.props.alertVisible)

        // this.setState({ alertVisible: true, boxvalue: "" });
        // setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };


  addtext =  async () =>
  {



if(!this.state.boxname) 
{

this.props.errormessageboxname('errorname','Please Enter Box Name');
console.log('boxname empty')

}
else
{
 this.props.errormessageboxname('errorname',''); 

}


if(!this.state.portletHeading) 
{
 console.log('portletHeading empty')
 this.props.errormessageheading('errorheading','Please Enter Portlet Heading');

}
 
else
{
 this.props.errormessageheading('errorheading',''); 

}

if(!this.state.thumbnail) 
{
  this.props.errorthumbnail('errorthumbnail','Please Select a thumbnail');

}
 
else
{
 this.props.errorthumbnail('errorthumbnail',''); 

}
 

if(!this.state.boxfile)
{

this.setState({errorfile :'Please Select a image'})

}
else

{

 this.setState({errorfile :''})


}

if(!this.state.link)
{

this.setState({errortext :'Please enter link'})

}
else

{

 this.setState({errortext :''})


}

if (!this.is_url(this.state.link)) {
    this.setState({ errortext: "Please enter a valid Link" });
    return false
  }


  let validationImage = ["jpeg", "jpg", "png", "gif"];
  var extension = this.state.boxfile.name.split(".").pop();
  let ImageExtension = extension.toLowerCase();
  if (validationImage.indexOf(ImageExtension) == -1) {
    this.setState({ errorfile: "Please Choose Valid Image File" });
    return false;
  } else {
    this.setState({ errorfile: "" });
  }
 
let filename = this.state.boxfilename.split(".");

let formData = new FormData();
formData.append("name", this.state.boxname);
formData.append("type", this.state.boxvalue);
formData.append("thumbnail", this.state.thumbnail);
formData.append("notificationtext", this.state.traytext);
formData.append("heading", this.state.portletHeading);
formData.append("description", this.state.descriptionText);
// formData.append("type", this.state.selectSurvey.value);
formData.append("customerId", this.state.customerId);
formData.append("status", "active");
formData.append("createdAt", this.state.newdate);
formData.append("url", this.state.link);
formData.append("media", this.state.boxfile);
formData.append("extension", filename[1]);
 



if(this.state.boxname && this.state.portletHeading && this.state.thumbnail && this.state.boxfile) 
{


    console.log([...formData])
    this.submitCommon(formData);
    
}

  }

    render() { 
        console.log(this.props)
        const {boxfilename,errorfile,link,errortext}=this.state;
        return ( <React.Fragment> 
<div className="row form-group">
              <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="exampleInputEmail1">
                                Upload Image
                              </label>
                            </div>

                            <div className="col-sm-5">
                            <input
                                type="file"
                                id="image"
                                accept="image/*"
                                onChange={this.image}
                              />
                               <label
                                className="custom-file-label lblcross"
                                htmlFor="image"
                                style={{ fontSize: "15px", overflow: "hidden" }}
                              >
                                {" "}
                                <span style={{ fontSize: "12px" }}>
                                  {boxfilename}
                                </span>
                              </label>
                              <span className="error-shows ">
                                <br />
                                <br />
                                {errorfile}
                              </span>

                            </div>
                            <div
                              className="col-sm-3"
                              style={{
                                fontSize: 12,
                                marginTop: 12,
                                color: "#007bff"
                              }}
                            >
                              {" "}
                              Prescribed Size : 120x120 px
                            </div>
  </div>

  <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2">
                                <label htmlFor="exampleInputEmail1">
                                  link{" "}
                                </label>
                              </div>
                              <div className="col-sm-5">
                                <textarea
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Link Here"
                                  onChange={this.link}
                                  value={link}
                                />
                                <span className="error-shows ">
                                  {errortext}
                                </span>
                              </div>
                              <div className="col-sm-3" />
                            </div>
                            

     <div className="row form-group">
                <div className="col-sm-4" />
                <div className="col-sm-5">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={
                      this.state.button === "Add" ? this.addtext : this.onSubmit
                    }
                  >
                    {this.state.button}
                  </button>
                </div>
                <div className="col-sm-3" />
              </div>     


        </React.Fragment> );
    }
}
 
export default Type3 ;