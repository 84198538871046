import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import MultiSelect from "../../../components/Form/MultiSelect";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from "reactstrap";
import { FormMiddleWare } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";

class SurveyMapping extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      customerId: localStorage.getItem("userId"),
      textalert: null,
      alertVisible: false,
      errorSur: "",
      errorSurTiTle: "",
      errorSurQues: "",
      surveyreduce: [],
      questionList1: [],
      questionSelected: [],
      survey_mapping: [],
    };
  }
  async componentWillMount() {
    // tbl_surveyQuestion
    try {
      const result = await CmsContent.getFreedom(
        "surveyname as label , id as value",
        "tbl_survey",
        `customerid=${this.state.customerId}`,
        "id",
        "id DESC"
      );

      const title = await CmsContent.getFreedom(
        "title as label , id as value,surveyId as surveyId ",
        "tbl_surveyTitle",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );

      const questionList = await CmsContent.getFreedom(
        "questions as label , id as value",
        "tbl_surveyQuestion",
        `companyid=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      const survey_mapping = await CmsContent.getFreedom(
        "*",
        "tbl_survey_mapping",
        `customerid=${this.state.customerId}`,
        1, 1
      )
      console.log(survey_mapping);
      this.setState({
        survey_mapping: survey_mapping.data,
        surveyList: result.data,
        title: title.data,
        questionList: questionList.data
      });
    } catch (error) {
      console.log(error);
    }
  }

  surveySelect = async e => {
    const { survey_mapping, questionList } = this.state;

    console.log(e);

    console.log("tes1t");
    const { title } = this.state;
    this.setState({ surveySelected: e });
    let titleOption = title.filter((item, i) => {
      if (item.surveyId == e.value) {
        return item;
        // T.push({ label: item.title, value: item.id });
      }
    });
    let questionSelected = []
    let data = await survey_mapping.map(async (ival, i) => {
      if (ival.surveyid == e.value) {
        questionSelected.push(ival)
      }
    })
    // console.log(questionSelected);

    await this.setState({ titleOption, surveyreduce: questionSelected, titleSelected: null });
  };

  titleSelect = async e => {
    var { surveyreduce, survey_mapping, surveySelected, questionList } = this.state;
    let questionSelected = []
    let question = []
    let data = await surveyreduce.map(async (ival, i) => {
      if (ival.titleId == e.value) {
        let datas = await this.getValueFromArray(ival.surveyQuestionid, questionList)
        questionSelected.push({ "label": datas, "value": ival.surveyQuestionid })
      }
      else {
        question.push(ival.surveyQuestionid)
      }

    })
    await Promise.all(data)
    console.log(question, "question");
    let datas = await this.removeDuplicates(questionSelected, "value")
    let datas1 = questionList
    if (question.length > 0) {
      question.map((ival) => {
        datas1 = datas1.filter((a) => a.value != ival)
        console.log(datas1);
      })
    }

    console.log(datas1);

    await this.setState({
      titleSelected: e, questionSelected: datas
      , questionList1: datas1
    });
  };

  questionSelect = async e => {
    if (e) {
      console.log(e);
      await this.setState({ questionSelected: e });
    }
  };

  mapSurvey = async () => {
    const {
      surveySelected,
      titleSelected,
      questionSelected,
      customerId
    } = this.state;
    let selectedValue = "";

    if (!surveySelected) {
      this.setState({ errorSur: "Select Survey" });
      return false;
    } else if (!titleSelected) {
      this.setState({ errorSur: "", errorSurTiTle: "Select Survey Title" });
      return false;
    } else if (!questionSelected) {
      this.setState({
        errorSurTiTle: "",
        errorSurQues: "Choose Survey Questions"
      });
      return false;
    } else {
      this.setState({ errorSur: "", errorSurTiTle: "", errorSurQues: "" });
    }

    if (questionSelected && surveySelected && titleSelected) {
      const value = questionSelected.map(
        values => (selectedValue += `${values.value},`)
      );

      selectedValue = selectedValue.replace(/,\s*$/, "");
      const formData = new FormData();
      formData.append("surveyQuestionid", selectedValue);
      formData.append("surveyid", surveySelected.value);
      formData.append("titleId", titleSelected.value);
      formData.append("customerid", customerId);

      try {
        let result = await CmsContent.learningObjectSubmit(
          "tbl_survey_mapping",
          formData
        );
        if (result) {
          this.setState({
            selectedValue: "",
            questionSelected: "",
            surveySelected: "",
            titleSelected: "",
            alertVisible: true,
            textalert: "Survey Mapped successfully",
            color: "success",
            error: ""
          });
          this.componentWillMount()
          setTimeout(() => this.setState({ alertVisible: false }), 3000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  render() {
    const {
      surveyList,
      questionList,
      questionSelected,
      surveySelected,
      textalert,
      alertVisible,
      titleOption,
      titleSelected,
      error,
      questionList1,
      errorSurTiTle,
      errorSur,
      errorSurQues
    } = this.state;
    console.log(questionSelected);
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Mapping Survey</h1>
                  </div>
                  <div className="card-body" style={{ height: "400px" }}>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Survey</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          // disabled={this.state.disabled}
                          options={surveyList}
                          handleChange={this.surveySelect}
                          selectedService={surveySelected}
                        />
                        <span className="error-shows">{errorSur}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Survey Title</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          // disabled={this.state.disabled}
                          options={titleOption}
                          handleChange={this.titleSelect}
                          selectedService={titleSelected}
                        />
                        <span className="error-shows">{errorSurTiTle}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="subgroup">Survey Question</label>
                      </div>
                      <div className="col-sm-5">
                        <MultiSelect
                          // disabled={this.state.disabled}
                          options={questionList1}
                          handleChange={this.questionSelect}
                          selectedService={questionSelected}
                        />
                        <span className="error-shows">{errorSurQues}</span>
                      </div>

                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          // disabled={disableValue}
                          onClick={this.mapSurvey}
                        >
                          Map Survey
                          {/* {this.state.button} */}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default SurveyMapping;
