import React, { Component } from 'react';

export default class AdminDashboard extends Component {
  constructor(props) {
    super(props);
    let userlog = localStorage.getItem('userlog');
    userlog = JSON.parse(userlog);
    localStorage.setItem('userId', userlog.id);
    localStorage.setItem('userName', userlog.userName);
    localStorage.setItem('userEmail', userlog.email);
    localStorage.setItem('userMobile', userlog.mobileNumber);
  }
  render() {
    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3>Heading</h3>
                </div>
                <div className="card-body">
                  <h4>sub admin dashboard</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
