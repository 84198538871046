import React, { Component } from "react";
import "./cssStyle.css";

export default class SpinKit extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        <div className="card" style={{ height: "820px" }}>
          <div
            class="sk-bounce"
            style={{ marginLeft: "50%", marginTop: "25%" }}
          >
            <div class="sk-bounce-dot"></div>
            <div class="sk-bounce-dot"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
