import React, { Component } from "react";

class ProductTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      headers: props.headers,
      products: props.products,
      condition:{},
      start:0,
      end:10
    }
  }
  requestSort = (key) => {
    if(this.state.condition.key){
      let condition= this.state.condition;
      condition.key=key;
      condition.order=condition.order==='a'?'d':'a';
      this.setState({ condition });
    } else {
      let condition= this.state.condition;
      condition.key=key;
      condition.order='a';
      this.setState({ condition });
    }
  }
  back=()=>{
    if(this.state.start !==0){
      this.setState({ start:this.state.start-10, end:this.state.end-10 })
    }
  }
  next=()=>{
    if(this.state.end < this.props.products.length){
      this.setState({ start:this.state.start+10, end:this.state.end+10 })
    }
  }
  render() {
    let {headers,products} =this.props;
    products.sort((a, b) => {
      if (a[this.state.condition.key] < b[this.state.condition.key]) {
        return this.state.condition.order === 'a' ? -1 : 1;
      }
      if (a[this.state.condition.key] > b[this.state.condition.key]) {
        return this.state.condition.order === 'a' ? 1 : -1;
      }
      return 0;
    });
    products = products.filter((ival,i)=>{
      if((i >= this.state.start) && (i < this.state.end)){
        return ival
      }
    })
    return (
      <React.Fragment>
        <div class="row clearfix table-wrapper hidden-xs">
          <div class="col-md-12">
            <div class="table-responsive">
              <table className="table table-borderless table-hover table-not_found rtReportColor">
                <thead>
                  <tr>
                  {headers.map((item) => (
                  <th style={{borderBottom:"0.5px solid #666"}}>
                    <a
                      style={{color:'#fff',cursor: 'pointer'}}
                      onClick={() => this.requestSort(item.key)}
                      className={item.style}
                    >
                      {item.as}
                      {(this.state.condition.key!==item.key) ? <i className="fa fa-fw fa-sort" style={{fontWeight:'bold'}}></i> : null}
                      {(this.state.condition.key && this.state.condition.key===item.key && this.state.condition.order==='a') ? (<i className="fa fa-fw fa-sort-asc" style={{fontWeight:'bold'}}></i>):null}
                      {(this.state.condition.key && this.state.condition.key===item.key && this.state.condition.order==='d') ? (<i className="fa fa-fw fa-sort-desc" style={{fontWeight:'bold'}}></i>):null}
                    </a>
                  </th>
                ))}
                  </tr>
                </thead>
                <tbody>
                {products.map((item,i) => (
                <tr style={(i%2==0)?{}:{backgroundColor: '#181B1F'}}>
                  {headers.map((j) => (
                    <td >{(typeof j.Makeup === "function") ? j.Makeup(item[j.key]) : item[j.key] }</td>
                  ))}
                </tr>
              ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <a onClick={() => this.back()}><i className="fa fa-chevron-left rtReportColor arrowinRTreport"></i></a>
        <a onClick={() => this.next()}><i className="fa fa-chevron-right rtReportColor arrowinRTreport"></i></a>
          &nbsp;
          <span className="rtReportColor">{this.state.start} to {this.state.end} of {this.props.products.length}</span>
      </React.Fragment>
    );
  }
}

export default ProductTable;