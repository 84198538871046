import React from 'react';
import Datatable from '../../components/Datatable/Datatable';
import { FormMiddleWare } from '../../components/Form';
import LoginModal from '../../components/Modal/Modal';
import User from '../../MiddleWare/User';
import AddUser from '../Modal/AddCustomer';
import { confirmAlert } from "react-confirm-alert";
import SuperAdmin from '../../MiddleWare/SuperAdmin';


export default class UserList extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      mobileNumber: '',
      email: '',
      UserPassword: '',
      errorPassword: '',
      errorloginType: "",
      errorServices: "",
      updateEmail: true,
      errorUserName: '',
      errorMobileNumber: '',
      errorEmail: '',
      alertVisible: false,
      data: [],
      uservalue: [],
      updateData: false,
      imgupload: [],
      logoname: '',
      previoususerid: '',
      arrayvalue: [],
      options: [],
      options1: [
        { value: 1, label: "SMS_Login" },
        { value: 2, label: "Email_Login" }
      ],
      counting: [],
      selectedService: [],
      servicesValue: "",
      selectedloginType: null,
      selectedloginvalue: "",
      button: '',
      textview: 'Users(L2) Under Admin(L1)',
      textviewusertype: null
    };
  }

  async componentWillMount() {
    this.userList();
    try {
      const services = await SuperAdmin.getAllService();
      if (services) {
        this.setState({ options: services.data });
      }
    } catch (error) { }
  }
  userList = async () => {
    let customerId = localStorage.getItem('userId');

    try {
      // const result = await User.getAllUser(customerId);
      const result = await User.getUservalue(customerId);
      // console.log(result, "result");
      if (result) {
        const jsonData = result.data.map((value, key) => {
          const userData = {
            //key + 1
            id: value.id,
            userName: value.userName,
            mobileNumber: value.mobileNumber,
            email: value.email,
            status: value.status,
            image: value.image,
            UserPassword: value.withoutmd5,
            LoginType: value.LoginType,
            serviceEnabled: value.serviceEnabled
          };
          return userData;
        });
        // console.log(jsonData, 'jsonData');
        this.setState({ data: jsonData });

      }
    } catch (error) {
      console.log(error);
    }
  };
  onSubmit = async () => {
    let userArray = {};
    let userName = this.state.userName;
    let mobileNumber = this.state.mobileNumber;
    let email = this.state.email;
    let imgupload = this.state.imgupload
    let UserPassword = this.state.UserPassword
    let servicesValue = this.state.servicesValue;
    let counting = this.state.counting;
    let selectedloginType = this.state.selectedloginType;
    let selectedService = this.state.selectedService

    const formData = new FormData();
    formData.append('userType', '2');
    formData.append('customerId', localStorage.getItem('userId'));
    if (imgupload && imgupload.size) {
      formData.append('image', imgupload);
    }
    formData.append("serviceEnabled", servicesValue);
    formData.append("usercount", counting);
    formData.append("LoginType", selectedloginType.value);
    formData.append('userName', userName);
    formData.append('mobileNumber', mobileNumber);
    formData.append('email', email);
    formData.append('status', 'active');
    formData.append('withoutmd5', UserPassword)
    console.log(...formData,"formData");

    try {
      //const result = await User.userInsert(userArray);
      const result = await User.adminuserInsert('tbl_user_web', formData);
      if (result) {

        this.setState({
          alertVisible: true,
          errorUserName: '',
          errorMobileNumber: '',
          errorEmail: '',
          errorPassword: '',
          errorloginType: '',
          errorServices: '',
          userName: '',
          mobileNumber: '',
          email: '',
          image: '',
          logoname: '',
          UserPassword: '',
          imgupload: '',
          servicesValue: '',
          counting: [],
          selectedloginType: null,
          selectedService: [],
          count: '',
          arr: []

        });
      }
      setTimeout(() => { this.setState({ alertVisible: false }) }, 2000);
      // window.location.reload();
      setTimeout(function () {
        window.location.reload();
      }, 2600);
    } catch (error) {
      console.log(error);
    }
  };
  column = [
    {
      Header: 'UserName',
      accessor: 'userName',
      Cell: d => this.getvalue(d.original, 'Userview'),
      width: 270
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'MobileNumber',
      accessor: 'mobileNumber',
      width: 150
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 150,
      className: 'text-center'
    },
    {
      Header: 'Visibility',
      accessor: 'edit',
      Cell: d => this.showVisibility(d),
      width: 120
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: d => this.edit(d, 'userAdd'),
      width: 120
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: d => this.delete(d),
      width: 120
    }
  ];
  showVisibility = d => {
    let status = d.original.status;
    let colour = d.original.status == 'active' ? 'success' : 'danger';
    return this.dataTableButton(colour, status, () => this.updateVisibility(d));
  };
  updateVisibility = async value => {
    let values = value.original;
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };
    if (newData.status === 'active') {
      newData.status = 'Inactive';
      this.setState({ button: 'Inactive' });
    } else {
      newData.status = 'active';
      this.setState({ button: 'active' });
    }

    const id = newData.id;
    const data = previousData.filter(value => value.id !== id);
    try {
      const result = await User.editSelectedUsers(id, newData);
      if (result) {
        data.splice(index, 0, newData);
        this.setState({ data: data });
      }
    } catch (error) {
      console.log(error);
    }
  };
  dataTableButton = (info, con, func, disabled = false) => {
    let classes = 'btn btn-';
    classes += info;
    return (
      <center>
        <button type="button" disabled={disabled} className={classes} onClick={func} style={{ width: 80 }}>
          {con}
        </button>
      </center>
    );
  };
  getvalue = (value, modalWindowId) => {

    return (
      <button type="button" className="btn btn-warning" style={{ width: '250px' }} onClick={() => window.open('GetUsers' + '/' + value.id, '_blank')}>
        {value.userName}
      </button>
    );
  };
  buttonEdit = async value => {
    let values = value.original;
    const index = value.index;
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    const { userName, mobileNumber, email, image, UserPassword, serviceEnabled } = { ...previousData[index] };
    let selectedOption = []
    let serviceList = ''
    if (serviceEnabled && serviceEnabled.length > 0) {
      selectedOption = serviceEnabled
        .split(",")
        .map(d =>
          this.state.options.filter(
            values => parseInt(values.value) === parseInt(d)
          )
        );
      serviceList = selectedOption.map(list => list[0]);
    } else {
      serviceList = []
    }
    var logoname = '';
    if (image != '' && image != undefined && image != null) {
      var logoname = image.split('SDAS/Video/');
    } else {
      var logoname = 'difuzaWebLogo.2dcc7030.png';
    }
    let selectedloginType = {};
    if (values && values.LoginType !== null) {
      this.state.options1.map((ival, index) => {
        if (ival.value === values.LoginType) {
          selectedloginType.value = ival.value;
          selectedloginType.label = ival.label;
        }
      });
    }

    this.setState({
      userIndex: index,
      userName: userName,
      mobileNumber: mobileNumber,
      email: email,
      UserPassword: UserPassword,
      errorUserName: '',
      errorMobileNumber: '',
      errorEmail: '',
      alertVisible: false,
      updateEmail: false,
      imgupload: image,
      logoname: logoname,
      updateData: true,
      selectedService: serviceList,
      servicesValue: serviceEnabled,
      selectedloginType: selectedloginType
    });
  };
  buttonDelete = value => {

    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const id = value.original.id;
            const previousData = [...this.state.data];
            const data = previousData.filter(value => value.id !== id);
            this.setState({
              data
            });
            try {
              await User.deleteSelectedUser(id);
            } catch (error) {
              this.setState({
                data: previousData
              });
              console.log(error);
            }
          }
        },
        {
          label: "No"
        }
      ]
    });
  };
  onUpdate = async value => {
    let userIndex = this.state.userIndex;
    let userName = this.state.userName;
    let mobileNumber = this.state.mobileNumber;
    let email = this.state.email;
    let imgupload = this.state.imgupload;
    let UserPassword = this.state.UserPassword;
    let servicesValue = this.state.servicesValue;
    let counting = this.state.counting;
    let selectedloginType = this.state.selectedloginType;

    let userArray = {};
    userArray.userName = userName;
    userArray.mobileNumber = mobileNumber;
    userArray.email = email;
    userArray.image = imgupload;
    userArray.UserPassword = UserPassword;
    userArray.servicesValue = servicesValue;
    userArray.counting = counting;
    userArray.selectedloginType = selectedloginType

    const previousData = [...this.state.data];
    // get only the edit user from the list of user
    const getData = { ...previousData[userIndex] };
    const id = getData.id;
    getData.userName = userName;
    getData.mobileNumber = mobileNumber;
    getData.email = email;

    const formData = new FormData();
    formData.append('userName', userName);
    formData.append('mobileNumber', mobileNumber);
    formData.append('email', email);
    if (imgupload && imgupload.size) {
      formData.append('image', imgupload);
    }
    formData.append('withoutmd5', UserPassword)
    formData.append("serviceEnabled", servicesValue);
    formData.append("LoginType", selectedloginType.value);
    // console.log(...formData, "formData");
    // console.log(imgupload, 'imgupload');

    // get all the user except the edit user
    const data = previousData.filter(value => value.id !== id);
    data.splice(userIndex, 0, getData);
    try {
      const result = await User.updateadminuserInsert(id, formData);
      if (result) {
        this.setState({
          data,
          alertVisible: true,
          userName: '',
          mobileNumber: '',
          UserPassword: '',
          email: '',
          userIndex: '',
          errorUserName: '',
          errorMobileNumber: '',
          errorEmail: '',
          updateData: false,
          logoname: '',
          servicesValue: '',
          counting: [],
          selectedloginType: null,
          selectedService: [],
          count: '',
          arr: []
        });
      }
      setTimeout(() => this.setState({ alertVisible: false }), 2000);
      setTimeout(function () {
        window.location.reload();
      }, 2600);
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };
  //previousbutton = async value => {
  // let result = await User.getUservalue(this.state.previoususerid);

  // if (result) {
  //   this.setState({ data: result.data });
  // }
  // alert(a);
  // this.props.history.goBack();
  //};
  handlelogintype = async selectedOption => {
    await this.setState({
      selectedloginType: selectedOption,
      selectedloginvalue: selectedOption.value
    });
  };
  handleCount = async e => {
    let id = e.target.id;
    let value = e.target.value;
    let counting = await this.state.counting;
    counting[id] = value;
    this.setState({ counting });
  };
  handleChanged = selectedOption => {
    let selectedValue = "";
    let arr = [];
    selectedOption.map(values => (selectedValue += `${values.value},`));
    selectedOption.map(values => arr.push(values.label));
    selectedValue = selectedValue.replace(/,\s*$/, "");
    // this.addCount(selectedOption, selectedValue, arr);
    //count calcualtion
    var count = 0;
    if (selectedOption && selectedOption.length) {
      count = selectedOption.length;
    } else {
      this.setState({ counting: [] });
    }
    this.setState({
      selectedService: selectedOption,
      servicesValue: selectedValue,
      count,
      arr
    });

  };
  // addCount = (selectedOption, selectedValue, arr) => {

  // };
  reset = () => {
    this.setState({
      errorUserName: '',
      errorMobileNumber: '',
      errorEmail: '',
      errorPassword: '',
      errorloginType: '',
      errorServices: '',
      userName: '',
      mobileNumber: '',
      email: '',
      image: '',
      logoname: '',
      UserPassword: '',
      imgupload: '',
      servicesValue: '',
      counting: [],
      selectedloginType: null,
      selectedService: [],
      count: '',
      arr: [],
      updateData: false
    })
  }

  render() {
    console.log("enter");
    let userType = localStorage.getItem('userType');
    const userData = {
      userName: this.state.userName,
      mobileNumber: this.state.mobileNumber,
      email: this.state.email,
      image: this.state.logoname,
      UserPassword: this.state.UserPassword
    };
    // console
    const errorData = {
      errorUserName: this.state.errorUserName,
      errorMobileNumber: this.state.errorMobileNumber,
      errorEmail: this.state.errorEmail,
      errorPassword: this.state.errorPassword,
      errorloginType: this.state.errorloginType,
      errorServices: this.state.errorServices
    };
    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12" />
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <center>
                    {' '}
                    <h3>
                      {'List of '}
                      {this.state.textview}
                    </h3>{' '}
                  </center>
                </div>
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2"></div>
                    <div className="col-sm-5">
                      {/* <LoginModal
                        atagLink={true}
                        title="User View"
                        id="Userview"
                        onClick={this.buttonView}
                        bodyText={<div>{this.state.uservalue && <Datatable data={this.state.uservalue} columnHeading={this.columns} />}</div>}
                      /> */}
                    </div>
                  </div>
                  <LoginModal
                    buttonTitle="Add Customer"
                    title={this.state.updateData === false ? 'Add User' : 'Update User'}
                    id="userAdd"
                    reset={this.reset}
                    bodyText={
                      <AddUser
                        handleInput={this.handleUserInput}
                        userData={userData}
                        errorData={errorData}
                        handleSubmit={this.handleSubmit}
                        onDismiss={this.onDismiss}
                        alertVisible={this.state.alertVisible}
                        checkMobileNumber={this.checkMobileNumber}
                        checkEmail={this.checkEmail}
                        uploadimg={this.uploadimg}
                        logoname={this.state.logoname}
                        userType={userType}
                        updateData={this.state.updateData}
                        updateEmail={this.state.updateEmail}
                        options={this.state.options}
                        options1={this.state.options1}
                        handleCount={this.handleCount}
                        handleChange={this.handleChanged}
                        arr={this.state.arr}
                        count={this.state.count ? true : false}
                        handlelogintype={this.handlelogintype}
                        selectedloginType={this.state.selectedloginType}
                        selectedService={this.state.selectedService}
                        selectedValue={this.state.selectedValue}
                      />
                    }
                  />
                  <br />
                  {/* <button type="button" class="btn btn-primary " onClick={this.previousbutton}>
                    BACK
                  </button> */}

                  {this.state.data && <Datatable data={this.state.data} columnHeading={this.column} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
