import React, { Component } from 'react';
import socket from '../../../MiddleWare/socketMiddleWare';
import Datatable from '../../../components/Datatable/Datatable';

class SelectedVideoView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      userListData: {}
    };
  }

  column = [
    {
      Header: 'UserName',
      accessor: '0'
    },
    {
      Header: 'Current TimeLine',
      accessor: '1'
    }
  ];

  componentDidMount() {
    let videoId = this.props.match.params.id;
    const cId=localStorage.getItem('userId');
    socket.emit('connection');
    socket.on(`responseTimestamp${cId}`, data => {
      console.log(data)
      let userViewData = [];
      var userListData = this.state.userListData;
      if (parseInt(data.videoId) === parseInt(videoId)) {
        userListData[data.userName] = parseInt(data.timestamp);
        const entries = Object.entries(userListData);
        entries.map(values => userViewData.push(Object.assign({}, values)));
        // userViewData.map(list => {});
        this.setState({ data: userViewData });
      }
    });
  }

  render() {
    const { data } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Add Datatable</h3>
                  </div>
                  <div className="card-body">
                    <h5>Add Content</h5>
                    {data && <Datatable data={data} columnHeading={this.column} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default SelectedVideoView;
