import React, { Component } from 'react';
import CmsContent from '../../../MiddleWare/CmsContent';
import SingleSelect from "../../../components/Form/SingleSelect";
import Datatable from '../../../components/Datatable/Datatable';
import FormMiddleWare from '../../../components/Form/FormMiddleware';
import { Alert } from "reactstrap";


class FormView extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.location.pathname.split("/")[3],
            formId: this.props.location.pathname.split("/")[4],
            portid: this.props.location.pathname.split("/")[5],
            formjson: [],
            json: [],
            arr: [],
            data: [],
            checked: false,
            answerJson: [],
            error: [],
            number: 0,
            showEnd: false,
            alertVisible: false,
            color: "success",
            totalValidateJsonLength: 0,
            customerId: localStorage.getItem("userId")
        }
    }
    async componentWillMount() {
        try {
            let userId = this.state.userId;
            let l3Id = await CmsContent.getTwoConditionedValue('tbl_user_web', 'id', userId, 'status', 'active', "customerId as value");
            this.setState({ customerId: l3Id.data[0].value });

            let ports = await CmsContent.getFreedom(
                "tbl_map_grouptoportlet.contentid as value , tbl_boxcontent.heading as label",
                'tbl_map_grouptoportlet,tbl_boxcontent',
                `tbl_boxcontent.id = ${this.state.portid}  and tbl_map_grouptoportlet.contentid = tbl_boxcontent.name`,
                1,
                1
            );
            if (ports.data) {
                this.setState({ ports: ports.data, portTitle: ports.data.label });
            }
            this.doThis(this.state.formId);
        } catch (error) {
            console.log(error);
        }
    };
    doThis = async formId => {
        let result = await CmsContent.getTwoConditionedValue('tbl_formquestion', 'parentid', formId, 1, 1);
        if (result) {
            let answerJson = [];
            result.data.map((ival, i) => {
                answerJson[ival.id] = { answer: '-', formid: ival.parentid, questionId: ival.id };
            })
            await this.setState({ data: result.data, answerJson });
            console.log("this.process");
            await this.process();
        }
        this.getAttendedForms();
    }
    getAttendedForms = async () => {
        try {
            let result = await CmsContent.getFreedom(
                "tbl_form.name as label ,tbl_form.id as value",
                'tbl_formanswer,tbl_form',
                `tbl_form.id = tbl_formanswer.formid and tbl_formanswer.userid= ${this.state.userId} `,
                'tbl_formanswer.formid',
                'tbl_formanswer.formid DESC'
            );
            if (result) {
                await this.setState({ selectformoption: result.data });
            }
        } catch (error) {
            console.log(error);
        }
    }
    column = [
        {
            Header: "Date",
            accessor: 'createdAt'
        },
        {
            Header: "View",
            accessor: "form",
            Cell: d => this.view(d)
        }
    ];
    columns = [
        {
            Header: "Question",
            accessor: "question",
        },
        {
            Header: "Answer",
            accessor: "answer",
        },
    ];
    view = d => {
        return (
            this.dataTableButton('warning', 'View', () => {
                this.viewForm(d);
            })
        );
    };
    viewForm = value => {
        let fullForm = this.state.fullForm;
        let values = value.original;
        let question;
        fullForm.map((ival, i) => {
            if (values.formid === ival.value) {
                question = ival.formJson;
            }
        });
        question = JSON.parse(question);
        let answer = JSON.parse(values.answer);
        let finalOutput = [];
        question.map((ival, i) => {
            finalOutput.push({ question: ival.name, answer: answer[i].answer })
        });
        this.setState({ finalOutput, showEnd: true });
    };

    async answers(label, index, options = null, key = null, type, questionId, formid) {

        let answerJson = await this.state.answerJson;

        if (type == "text") {
            answerJson[questionId] = { answer: label, questionId: questionId, formid: formid };
        } else if (type == 'textarea') {
            answerJson[questionId] = { answer: label, questionId: questionId, formid: formid };
        } else if (type == 'selectbox') {
            answerJson[questionId] = { answer: label.label, value: label.value, questionId: questionId, formid: formid };
        } else if (type == 'radio') {
            answerJson[questionId] = { answer: label, value: key, questionId: questionId, formid: formid };
        } else if (type == 'checkbox') {
            let check = [];
            options.map((ival, i) => {
                var checkBox = document.getElementById(`inlineCheckbox${index}${i}`);
                if (checkBox.checked == true) {
                    check.push(ival)
                }
            });
            answerJson[questionId] = { answer: check.toString(), value: key, questionId: questionId, formid: formid };
        }
        await this.setState({ answerJson });
    }

    programSelect = async e => {
        await this.setState({ categorySelected: e, categoryId: e.value });
        let result = await CmsContent.getFreedom(
            '*',
            'tbl_formanswer',
            `tbl_formanswer.formid = ${e.value} and tbl_formanswer.userid = ${this.state.userId} and tbl_formanswer.companyid = ${this.state.customerId} and tbl_formanswer.portid = ${this.state.portid}`,
            1,
            1
        );
        let heading = await CmsContent.getFreedom(
            "question as name",
            'tbl_formquestion',
            `tbl_formquestion.parentid = ${e.value} and tbl_formquestion.companyid = ${this.state.customerId}`,
            'id',
            'id'
        );
        let k = 0
        let questionDataColumn = [];
        let fullData = [];

        if (heading.data.length > 0 && result.data.length > 0) {

            heading.data.map((ival, i) => {
                questionDataColumn.push({ id: i, accessor: i.toString(), Header: ival.name })
            });
            for (let i = 0, count = (result.data.length / heading.data.length); i < count; i++) {
                let halfData = [];
                heading.data.map((jval, j) => {
                    halfData.push(result.data[k].answer)
                    k = k + 1;
                });
                fullData.push({ ...halfData })
            }
        }
        await this.setState({ questionDataColumn, fullData });
        this.listform()
    };

    back = () => {
        this.setState({ number: 0, questionDataColumn: [], fullData: [], categorySelected: [], json: [], categoryId: undefined });
    }
    Addnew = async () => {
        this.doThis(this.state.categoryId);
        this.back();
    }
    listform = async () => {
        let datatable = [];
        await datatable.push(
            <div>

                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2" />
                    <div className="col-sm-4">
                        <SingleSelect
                            options={this.state.selectformoption}
                            handleChange={this.programSelect}
                            selectedService={this.state.categorySelected}
                        />
                    </div>
                </div>
                <div className="col-sm-4" />
                {this.state.categoryId && (
                    <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-2" />
                        <div className="col-sm-3">
                            <button
                                type="button"
                                className="btn btn-danger"
                                onClick={this.Addnew}>
                                Add new
                        </button>
                        </div>
                    </div>
                )}
            </div>
        )
        this.setState({
            datatable, number: 1
            // ,alertVisible: true, textalert: "Your Form Have Been Submitted",
            // color: "success"
        });

        // setTimeout(() => this.setState({ alertVisible: false }), 3000);

    }

    formSubmit = async () => {
        let error = [...this.state.error];
        let e = [];
        this.state.data.map((item, i) => {
            this.state.answerJson.map((element, key) => {
                if (item.required == 1 && item.id == element.questionId && (element.answer == null || element.answer == "-")) {
                    error[i] = "Value must not be empty";
                    e.push(error[i]);
                } else if (item.required == 1 && item.id == element.questionId && (element.answer != null || element.answer != "-")) {
                    error[i] = null
                    e.push(error[i]);
                }
            })
        })
        this.setState({ error: error });
        this.process();

        for (var i = 0; i < e.length; i++) {
            if (e[i] != null) {
                return false;
            }
        }
        let answer = [];
        this.state.answerJson.map((item, i) => {
            let categoryArray = {};
            categoryArray.formId = item.formid;
            categoryArray.questionId = item.questionId;
            categoryArray.answer = item.answer;
            categoryArray.userid = this.state.userId;
            categoryArray.companyid = this.state.customerId;
            categoryArray.portid = this.state.portid;
            answer.push(categoryArray)
        })
        try {
            let result = await CmsContent.chanAnswer('tbl_formanswer', answer);
            if (result) {
                this.getAttendedForms();
                await this.setState({
                    json: undefined, arr: [], alertVisible: true, textalert: "Your Form Have Been Submitted",
                    color: "success"
                });
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
                this.listform();
            }
        } catch (error) {
            console.log(error);
        }
    }
    process = async () => {
        console.log("tis.process");
        let data = await this.state.data;
        if (data) {
            let arr = data;
            let contentJson = [];
            if (arr && arr.length) {
                let answerJson = await this.state.answerJson;
                let answerJsons = answerJson.length;
                let json = await arr.map(async (item, index) => {
                    if (item.type == 'text') {
                        await contentJson.push(
                            <div>
                                <div className="row form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-2">
                                        <label htmlFor="position">{item.question}</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={item.placeholder}
                                            onChange={e => this.answers(e.target.value, index, null, null, 'text', item.id, item.parentid)}
                                        />
                                        <span className="error-shows" id={`${index}`}>{this.state.error[index]}</span>
                                    </div>
                                    <div className="col-sm-3">
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    if (item.type == 'textarea') {
                        await contentJson.push(
                            <div>
                                <div className="row form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-2">
                                        <label>{item.question}</label>
                                    </div>
                                    <div className="col-sm-5">
                                        <textarea type="text" className="form-control" placeholder={item.placeholder} onChange={e => this.answers(e.target.value, index, null, null, 'textarea', item.id, item.parentid)}></textarea>
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="error-shows" id={`${index}`}>{this.state.error[index]}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    if (item.type == 'radio') {
                        let arr1 = [];
                        await item.options.split(",").map((element, key) => {
                            arr1.push(
                                <div>
                                    <div className="form-check form-check-inline">
                                        <input type="radio" name="element" value={`${element}`} id={`inlineCheckbox${key}`} onChange={e => this.answers(element, key, null, key, 'radio', item.id, item.parentid)} />
                                        <label className="form-check-label" for={`inlineCheckbox${key}`} style={{ marginLeft: "10px" }}>{`${element}`} </label>
                                    </div>
                                </div>
                            );
                        });
                        await contentJson.push(
                            <div>
                                <div className="row form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-2">
                                        <label>{item.question}</label>
                                    </div>
                                    <div className="col-sm-5">
                                        {arr1}
                                        <span className="error-shows" id={`${index}`}>{this.state.error[index]}</span>
                                    </div>
                                    <div className="col-sm-3">
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    if (item.type == 'selectbox') {
                        let option = [];
                        item.options.split(",").map((ival, i) => {
                            option.push({ label: ival, value: i });
                        })
                        await contentJson.push(
                            <div>
                                <div className="row form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-2">
                                        <label>{item.question}</label>
                                    </div>
                                    <div className="col-sm-5" >
                                        <SingleSelect
                                            options={option}
                                            placeholder={item.placeholder}
                                            handleChange={e => { this.answers(e, index, null, null, 'selectbox', item.id, item.parentid) }}
                                            selectedService={this.state.selectbox}
                                        />
                                    </div>
                                    <span className="error-shows" id={`${index}`}>{this.state.error[index]}</span>
                                    <div className="col-sm-3">
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    if (item.type == 'checkbox') {
                        let arr1 = [];
                        await item.options.split(",").map((element, key) => {
                            arr1.push(
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" id={`inlineCheckbox${index}${key}`} onChange={e => { this.answers(element, index, item.options.split(","), key, 'checkbox', item.id, item.parentid) }} value={element} />
                                    <label className="form-check-label" for={`inlineCheckbox${index}${key}`} style={{ marginLeft: "10px" }}>{`${element}`}</label>
                                </div>
                            );
                        });
                        await contentJson.push(
                            <div>
                                <div className="row form-group">
                                    <div className="col-sm-2" />
                                    <div className="col-sm-2">
                                        <label>{item.question}</label>
                                    </div>
                                    <div className="col-sm-5">
                                        {arr1}
                                    </div>
                                    <div className="col-sm-3">
                                        <span className="error-shows" id={`${index}`}>{this.state.error[index]}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                });
                await Promise.all(json);
                await this.setState({ json: contentJson, answerJson });
            }
        }
    };

    onDismiss = () => {
        this.setState({ alertVisible: false });
    }

    render() {
        let json = this.state.json;
        console.log(json);
        if (json && json.length) {
            return (
                <React.Fragment>
                    <main className="main my-4">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>{this.state.portTitle}</h1>
                                    <div className="card" style={{ margin: "-20px", marginTop: "5px", boxShadow: "0px 0px 10px #b9b3b3", borderRadius: "8px" }}>
                                        <div className="card-body">
                                            {this.state.number == 0 ?
                                                <div>
                                                    {/* <Alert
                                                        className="badge-content"
                                                        color={this.state.color}
                                                        isOpen={this.state.alertVisible}
                                                        toggle={this.onDismiss}
                                                    >
                                                        {this.state.textalert}
                                                    </Alert> */}
                                                    {json && json.length && json}

                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={this.formSubmit}
                                                            >
                                                                Save
                                                            </button>

                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={this.listform}
                                                                style={{ marginLeft: "10px" }}
                                                            >
                                                                List
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div>

                                                    {this.state.datatable}
                                                    {(this.state.fullData && this.state.fullData.length) ? (<Datatable data={this.state.fullData} columnHeading={this.state.questionDataColumn} />) : null}
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </React.Fragment>
            );
        } else if (json === undefined) {
            return (
                <React.Fragment>
                    <main className="main my-4">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>{this.state.title}</h1>
                                    <div className="card" style={{ margin: "-20px", marginTop: "5px", boxShadow: "0px 0px 10px #b9b3b3", borderRadius: "8px" }}>
                                        <div className="card-body">
                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-8">
                                                    <Alert
                                                        className="badge-content"
                                                        color={this.state.color}
                                                        isOpen={this.state.alertVisible}
                                                        toggle={this.onDismiss}
                                                    >
                                                        {this.state.textalert}
                                                    </Alert>
                                                </div>
                                                <div className="col-sm-2" />
                                            </div>
                                            {this.state.datatable}
                                            {(this.state.fullData && this.state.fullData.length) ? (<Datatable data={this.state.fullData} columnHeading={this.state.questionDataColumn} />) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </React.Fragment>
            )
        } else {
            return (
                <div>Loading...</div>
            );
        }
    }
};
export default FormView;