import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import CmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import AddCategory from "./AddCategory";
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from "reactstrap";
import { ACCESS_POINT } from "../../../config";
import FromLibrary from "./FromLibrary";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import CmsmappingPDF from "../../../components/PDF/CMSMappingpdf";
import swal from "sweetalert";

class MapCatToPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categorySelected: "",
      categoryId: "",
      categoryName: "",
      categoryOptions: [],
      pageSelected: "",
      pageId: "",
      pageName: "",
      pageOptions: [],
      category: "",
      data: [],
      content1: "",
      file1: "",
      fileName1: "",
      errorCategory: "",
      errorTitle: "",
      errorContent: "",
      errorFile1: "",
      errorPageId: "",
      alertVisible: false,
      btnDisable: false,
      btnDisable1: false,
      isEdit: false,
      customerId: localStorage.getItem("userId"),
      panelId: "",
      fromLibrary: false,
    };
  }

  column = [
    {
      Header: "Category",
      accessor: "categoryName",
    },
    {
      Header: "Content",
      accessor: "content1",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: (d) => this.edit(d),
    },
    {
      Header: "Delete",
      accessor: "delete",
      Cell: (d) => this.delete(d),
    },
  ];
  contentHeading = [
    {
      Header: "Search By Hint",
      accessor: "hint",
    },
    {
      Header: "File Name",
      accessor: "label",
      Cell: (d) => this.aModalLink(d),
    },
    {
      Header: "Select",
      accessor: "edit",
      Cell: (d) => this.checktest(d),
    },
  ];
  aModalLink = (id) => {
    //console.log(id);
    let str = id.original.label;
    let res = str.split("/");
    return (
      <a href="#" onClick={() => this.viewmedia(id.original.label)}>
        {res[2]}
      </a>
    );
  };
  // viewmedia = async (value) => {
  //   console.log(value);
  // };

  checktest = (id) => {
    //console.log(id);
    return (
      <button
        type="button"
        className="btn btn-info"
        data-dismiss="modal"
        onClick={() =>
          this.setState({
            file1: id.original.label,
            fileName1: id.original.label,
            fromlibrary: true,
          })
        }
      >
        Select
      </button>
    );
  };

  viewmedia = async (value) => {
    console.log(value);
    this.setState({
      videotype: "",
      viewmedia: ACCESS_POINT + `/superAdmin/file?fileurl=${value}`,
    });
    var that = this;
    // console.log(value.split('.')[1]);
    if (
      value.split(".")[1] === "jpg" ||
      value.split(".")[1] === "png" ||
      value.split(".")[1] === "jpeg"
    ) {
      this.setState({ fileName1: value.split("/")[2] });
      this.setState({ videotype: "image" });
    } else {
      this.setState({ videotype: null });
    }
  };

  edit = (value) => {
    //console.log(value);
    return (
      <button
        type="button"
        className="btn btn-warning"
        onClick={() => this.buttonEdit(value)}
      >
        Edit
      </button>
    );
  };

  buttonEdit = (d) => {
    console.log(d);
    let Index = d.index;
    let pageId = d.original.pageId;
    let pageName = "";
    let pageSelected = {};
    let content1 = d.original.content1;
    let categoryId = d.original.categoryId;
    let file1 = d.original.file;
    let fileName1 = d.original.file;
    let categorySelected = {};
    let categoryName = "";
    let panelId = d.original.id;
    this.state.pageOptions.map((ival, i) => {
      if (ival.value == pageId) {
        //console.log(ival);
        pageSelected.value = ival.value;
        pageSelected.label = ival.label;
        pageName = ival.label;
      }
    });
    this.state.categoryOptions.map((ival, i) => {
      if (ival.value === categoryId) {
        categorySelected.value = ival.value;
        categorySelected.label = ival.label;
        categoryName = ival.label;
      }
    });

    // console.log(pageId)
    // console.log(fileName1)
    // console.log(file1)
    // console.log(content1)
    // console.log(categoryName)
    // console.log(categoryId)

    this.setState({
      pageId,
      pageName,
      pageSelected,
      content1,
      categoryName,
      categorySelected,
      categoryId,
      fileName1,
      file1,
      panelId,
      isEdit: true,
      btnDisable1: true,
      Index,
    });
  };

  delete = (value) => {
    return (
      <button
        type="button"
        className="btn btn-danger"
        onClick={() => this.buttonDelete(value)}
      >
        Delete
      </button>
    );
  };

  buttonDelete = async (d) => {
    console.log(d);
    let Index = d.index;
    let id = d.original.id;
    //console.log(Index);
    try {
      if (d) {
        swal({
          title: "Are you sure?",
          text: "Once deleted, you will not be able to recover this Page",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
          .then(async (willDelete) => {
            if (willDelete) {
              const result = await CmsContent.deleteRightPanel('tbl_rightPanel_mapping', id);
              if (result) {
                // console.log(result);
                // const previousData = [...this.state.data];
                await this.MainFunc()
                // const data = previousData.filter((value) => value.id !== id);
                // this.setState({
                //   data,
                // });
                swal("Your page has been deleted!", {
                  icon: "success",
                });
              }
              else {
                await this.MainFunc()
                swal("Something went wrong. Try Again", {
                  icon: "warning",
                });
              }
            }
          });
      }

    } catch (error) {
      console.log(error);
    }
  };
  async componentDidMount() {
    try {
      await this.MainFunc()
    } catch (error) {
      console.log(error);
    }
  }
  MainFunc = async () => {
    try {
      const pages = await CmsContent.getFreedom(
        "id as value,name as label",
        "tbl_pages",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (pages) {
        this.setState({ pageOptions: pages.data });
      }
      const category = await CmsContent.getFreedom(
        "id as value,name as label",
        "tbl_rightPanel_title",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (category) {
        this.setState({ categoryOptions: category.data });
      }

      const getAllMappedpages = await CmsContent.getFreedom(
        //'categoryName,content1,status',
        "*",
        "tbl_rightPanel_mapping",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (getAllMappedpages) {
        // console.log(getAllMappedpages.data);
        this.setState({ data: getAllMappedpages.data });
      }
      if (getAllMappedpages.data.length > 0) {
        const header = ["SI.NO", "CATEGORYNAME", "CONTENT", "STATUS"];
        const excelHead = [
          { label: "CATEGORY", key: "categoryName" },
          { label: "CONTENT", key: "content1" },

          { label: "STATUS", key: "status" },
        ];
        this.setState({
          excelHead,
          header,
          excel: getAllMappedpages.data,
          title: "MAP PAGE TO RIGHT PANEL",
        });
      }
      const getLibraryImage = await CmsContent.getLibraryImage("image");
      if (getLibraryImage) {
        let gallery = getLibraryImage.data.filter(x => x.customerid == this.state.customerId)
        this.setState({ getlibrarydataImage: gallery });
      }
    } catch (error) {
      console.log(error);
    }
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  selectImage1 = (e) => {
    this.setState({ file1: e.target.files[0], fromLibrary: false });
    this.setState({ fileName1: e.target.files[0].name });
  };

  addNewCategory = async () => {
    let categoryArray = {};
    let categoryAddExtra = {};
    let categoryName = this.state.category;
    //let categoryOptions = this.state.categoryOptions;

    if (!categoryName) {
      this.setState({ errorCategory: "Enter Categoty Name" });
      return false;
    } else {
      this.setState({ errorCategory: "", btnDisable: true });
      categoryArray.name = categoryName.trim();
      categoryArray.status = "active";
      categoryArray.customerId = this.state.customerId;
      try {
        const result = await CmsContent.addMaster(
          "tbl_rightPanel_title",
          categoryArray
        );
        if (result) {
          categoryAddExtra.value = result.data.insertId;
          categoryAddExtra.label = categoryName;
          // categoryOptions.push(categoryAddExtra);
          const previousData = [
            categoryAddExtra,
            ...this.state.categoryOptions,
          ];
          console.log(previousData);
          this.setState({
            category: "",
            btnDisable: false,
            alertVisible: true,
            categoryOptions: previousData,
          });
          setTimeout(() => this.setState({ alertVisible: false }), 3000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  categorySelect = (selectedOption) => {
    this.setState({
      categorySelected: selectedOption,
      categoryId: selectedOption.value,
      categoryName: selectedOption.label,
    });
  };

  pageSelect = (selectedOption) => {
    //console.log(selectedOption);
    this.setState({
      pageSelected: selectedOption,
      pageId: selectedOption.value,
      pageName: selectedOption.label,
    });
  };

  submitCategory = async (e) => {
    e.preventDefault();
    const {
      categoryId,
      panelId,
      categoryName,
      pageId,
      file1,
      content1,
      fileName1,
      Index,
    } = this.state;

    if (!categoryId) {
      this.setState({ errorTitle: "Select Panel Title" });
      return false;
    } else if (!content1) {
      this.setState({ errorTitle: "", errorContent: "Enter Panel Content" });
      return false;
    }
    else if (!file1 && !this.state.customerId == 159) {
      this.setState({ errorContent: "", errorFile1: "Select Image" });
      return false;
    }
    else if (!pageId) {
      this.setState({ errorFile1: "", errorPageId: "Select Page" });
      return false;
    } else {
      this.setState({
        errorPageId: "",
        btnDisable: true,
      });
      //console.log(file1);
      const formData = new FormData();
      formData.append("categoryId", categoryId);
      formData.append("categoryName", categoryName);
      formData.append("pageId", pageId);
      formData.append("status", "active");
      formData.append("file", file1);
      formData.append("content1", content1.trim());
      formData.append("customerId", this.state.customerId);

      try {
        if (this.state.isEdit) {
          formData.append("id", panelId);

          console.log([...formData]);
          const result = await CmsContent.rightPanelUpdate(
            "tbl_rightPanel_mapping",
            formData
          );
          if (result) {
            let data = result.data[0];

            const previousData = [...this.state.data];
            previousData[Index] = data;
            this.setState({
              data: previousData,
              pageSelected: "",
              categorySelected: "",
              categoryId: "",
              pageId: "",
              fileName1: "",
              content1: "",
              file1: "",
              alertVisible: true,
              btnDisable: false,
              isEdit: false,
            });
            setTimeout(() => this.setState({ alertVisible: false }), 3000);
          }
        } else {
          const result = await CmsContent.rightPanelAdd(
            "tbl_rightPanel_mapping",
            formData
          );

          if (result) {
            const userData = {
              id: parseInt(result.data.insertId),
              categoryName: categoryName,
              categoryId: categoryId,
              content1: content1,
              pageId: pageId,
              fileName1: fileName1,
              file: file1,
              status: "active",
            };
            const newData = [userData, ...this.state.data];

            this.setState({
              data: newData,
              pageSelected: "",
              categorySelected: "",
              categoryId: "",
              pageId: "",
              fileName1: "",
              content1: "",
              file1: "",
              alertVisible: true,
              btnDisable: false,
              errorFile1: "",
            });
            setTimeout(() => this.setState({ alertVisible: false }), 3000);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  onDismiss = () => {
    this.setState({ alertVisible: false, formAlert: false });
  };

  render() {
    const {
      errorCategory,
      alertVisible,
      btnDisable,
      errorTitle,
      errorContent,
      errorFile1,
      errorPageId,
      content1,
      getlibrarydataImage,
      viewmedia,
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Add Right Panel</h3>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color="success"
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          New List Added To Right Panel
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Select Title</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          handleChange={this.categorySelect}
                          options={this.state.categoryOptions}
                          selectedService={this.state.categorySelected}
                        />
                      </div>
                      <div className="col-sm-3">
                        <LoginModal
                          buttonTitle="Add Panel Title"
                          title="Add panel Title"
                          id="Category"
                          extraClass="btn-width"
                          onClick={this.reset}
                          bodyText={
                            <AddCategory
                              category={this.state.category}
                              handleChange={this.handleChange}
                              addNewCategory={this.addNewCategory}
                              error={errorCategory}
                              alert={alertVisible}
                              dismiss={this.onDismiss}
                              disableValue={btnDisable}
                              alertMsg="New Panel Title Added"
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorTitle}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Small Content</label>
                      </div>
                      <div className="col-sm-5">
                        <textarea
                          type="text"
                          class="form-control"
                          id="content1"
                          name="content1"
                          placeholder="Enter First Content"
                          onChange={this.handleChange}
                          value={content1}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorContent}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Upload Image</label>
                      </div>
                      <div className="col-sm-5 custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          onChange={this.selectImage1}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          Choose file
                        </label>
                        <span>
                          <LoginModal
                            buttonTitle="Select Image from Library"
                            title="Select Image"
                            id="customer"
                            maxwidth="modal-xl"
                            bodyText={
                              <FromLibrary
                                datas={getlibrarydataImage}
                                columnHeading={this.contentHeading}
                                imgsource={viewmedia}
                                type={this.state.videotype}
                              />
                            }
                            atagLink={true}
                          />
                        </span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <br></br>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="file-show ">{this.state.fileName1}</span>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorFile1}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Select Page</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          handleChange={this.pageSelect}
                          options={this.state.pageOptions}
                          selectedService={this.state.pageSelected}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorPageId}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          class="btn btn-primary"
                          disabled={btnDisable}
                          onClick={this.submitCategory}
                        >
                          {this.state.isEdit ? "Update" : "Submit"}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <br></br>
                    {this.state.excel && this.state.excel.length > 0 ? (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <CmsmappingPDF
                            title={this.state.title}
                            header={this.state.header}
                            data={this.state.excel}
                          />
                        }
                        fileName="Mappagetopanel.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    ) : null}
                    {this.state.data && this.state.data.length ? (
                      <CSVLink
                        data={this.state.data}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          Excel
                        </button>
                      </CSVLink>
                    ) : null}
                    <br></br>
                    <br></br>

                    {this.state.data && (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default MapCatToPanel;
