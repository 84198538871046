import React, { Component } from 'react';
import CmsContent from '../../MiddleWare/CmsContent';
import { ACCESS_POINT } from "../../config";
import {Collapse} from 'react-collapse';

class ThemeSix extends Component {
  constructor(props) {
    super(props);
    this.state = {
		pageList:"",
		date:"" ,
		Content:[],
		Content2:[],
		contentTitle:"",
		InnerData:[],
		open:false,
    };
  }

async componentDidMount() {
   let themeid = this.props.match.params.id;
    const result = await CmsContent.getThemeById(themeid);
      //console.log(result.data) 
    if (result) {
		   this.setState({Content:result.data[0]});
		 //  let Content1=[];
		   /*if(this.state.Content){
		   this.state.Content.map((ival,i)=>{
			   console.log(ival) 
		   });
		   }*/
		  
		  this.state.Content.sheetContent = JSON.parse(this.state.Content.sheetContent);
           this.setState({InnerData:this.state.Content.sheetContent})
		    this.AddArray()
      }
	  
		   
		   
   
  }
 AddArray=()=>{
	 const{InnerData}=this.state;
	 let array=[];
	 array=InnerData;
	 //console.log(array);
	 let array1=[];
	 array.map((ival,i)=>{
		 let kkk={};
		 kkk.name=ival.name;
		 kkk.link=ival.link;
		 kkk.icon=ival.icon;
		 kkk.files=ival.files;
		 kkk.tray=ival.tray;
		 kkk.drop=false;
	//console.log(kkk);	 
	  array1.push(kkk)
	 })
	// console.log(array1);
	 this.setState({InnerData:array1})
 } 
Dropopen=(d,i,c)=>{
	const{InnerData}=this.state;

	if(c==false){
		InnerData[i].drop=true;
	}
	else if(c==true){
		InnerData[i].drop=false;
	}
	//console.log(InnerData);
}
  
  render(){
	  const{Content,InnerData}=this.state;
	  let title=Content.contentTitle1;
	  console.log(InnerData);

	 
	  
    return <React.Fragment>
              <div class="breadcrumb-area">
        <div class="container" style={{backgroundColor:"#fff"}}>
            <div class="row" style={{backgroundColor:"#fff"}}> 
				{/*Theme5{this.props.data}*/}
	         
                <div class="col-10" style={{marginLeft:"7%"}}>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">{title}</li>
                        </ol>
                    </nav>
                </div>
				                 <br/>
                 <br/>

     <div class="about-us-area section-padding-0-100">
        <div class="container">
            <div class="row">
               <div class="col-1"></div>
                <div class="col-10">
                    <div class="about-content" style={{marginTop:"17px"}}>
                       <div class="section-heading text-center">
                            
                       
                          <h2 class="post-title" style={{color:"#191919"}}>
							  {title}
                          </h2>
                       
                       
                        </div>
						</div>
						<br/>
						<br/> 
						
						 {InnerData.map((ival,i)=>{
						 
					return(
					<div>
                   <div className="row form-group">
                  <div className="col-sm-1" />
                  <div className="col-sm-2">

						{i+1}
					</div>
				   <div className="col-sm-8">
				   {ival.link&&ival.files==0&&(
					   <a href={`${ACCESS_POINT}/superAdmin/file?fileurl=${ival.link}`}><p style={{color:'slategray',fontSize:'17px'}}>{ival.name}</p></a>
					   )}
					{ival.tray==true&&ival.files.map((jval,j)=>{
						return (
					<div>
				    <a href={`#`}  onClick={d=>this.Dropopen(d,i,ival.drop)}><p>{ival.name}</p> </a>
					<Collapse isOpened={ival.drop}>
					<div>
					<a href={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.link}`}><p style={{color:'slategray',fontSize:'14px'}}>{jval.name}</p></a>
					</div>
					</Collapse>
					</div>
					 
					)
					  
					})
					}
				   {/*ival.files.map((jval,j)=>{
                    return(
					<div>					  
					  <p>{jval.name}</p>
					   </div>)
					   
				   })*/}                   			

							
					</div>
                     <div className="col-sm-1" />
					</div>
					</div> 
						 )
						 })
						 }

						
						
						
						{/*<table style={{textAlign:'center',fontSize:'x-large'}}>
						<tr>
						
						 <th>SI.NO</th>
						 <th colspan="16">Publications</th>
						 </tr>
						{InnerData.map((ival,i)=>{
							console.log(ival.link);
							return(
					 
						 <tr>
						 <td>{i+1}</td>
						 <td colspan="16">
						<a href={`${ACCESS_POINT}/superAdmin/file?fileurl=${ival.link}`} target="_blank">{ival.name}</a>
						 </td>
						 </tr>
						 
						
						)
						})	}
						</table>
						
						</div>
						<div  class="row">
						<div class="col-10">
						<a href={`#`}  onClick={()=>this.Dropopen()}><p>Hello</p></a>
						<Collapse isOpened={this.state.open}>
                          <div>
                         
                       </div>
                      </Collapse>
						
						</div>
  </div>*/}
                          <div className="row form-group">
						  <div className="col-sm-1" />
                         <div className="col-sm-10">
						  <p>  
						People with COVID-19 generally develop signs and symptoms, 
						including mild respiratory symptoms and fever, on an average of 5-6 d
						ays after infection (mean incubation period 5-6 days, range 1-14 days).
						Most people infected with COVID-19 virus have mild disease and recover. 
						</p>
						 </div>
						 </div> 
						  
						</div> 
						</div>
						</div>
      </div>
	</div>
	</div>
	</div>

           </React.Fragment>
  }
}
export default ThemeSix;
