import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CMSNav extends Component {
  render() {
    let serviceId = localStorage.getItem('currentService');
    let userlog = localStorage.getItem('userlog');
    let userlog1 = JSON.parse(userlog)
    let customerid = localStorage.getItem("userId")
    console.log(userlog1.templateCheck, 'templatecheck')
    // console.log(userlog1.customerId, "userlog.customerId");
    userlog = JSON.parse(userlog);
    // console.log(userlog.id, "userlog.id");
    if (parseInt(userlog1.customerId) == 246) {
      return (
        <div className="sidebar">
          <nav className="sidebar-nav">
            <ul className="nav">
              <li className="nav-item">
                <Link className="nav-link" to={`/Crucible/dashborad`}>
                  <i className="nav-icon icon-speedometer" />
                  <span className="link-white">Dashboard</span>
                </Link>
              </li>
              <li className="nav-item nav-dropdown">
                <Link className="nav-link nav-dropdown-toggle" href="#">
                  <i className="nav-icon icon-puzzle" /> CMS
                </Link>
                <ul className="nav-dropdown-items">
                  <li className="nav-item">
                    <Link className="nav-link" to={`/Crucible/Cms`}>
                      <i className="nav-icon fa fa-indent" />
                      <span className="link-white">CMS</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={`/Crucible/Files`}>
                      <i className="nav-icon fa fa-indent" />
                      <span className="link-white">FILES</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      )
    } else if (parseInt(customerid) == 6597) {
      return (
        <>
          <div className="sidebar">
            <nav className="sidebar-nav">
              <ul className="nav">
                <li className="nav-item">
                  <Link className="nav-link" to={`/superAdmin`}>
                    <i className="nav-icon icon-speedometer" />
                    <span className="link-white">Service Dashboard</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to={`/ContentManagementSystem/${serviceId}`}>
                    <i className="nav-icon icon-speedometer" />
                    <span className="link-white">Dashboard</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/cmsAddPage">
                    <i className="nav-icon fa fa-user-circle-o" />
                    <span className="link-white">Add Pages</span>
                  </Link>
                </li>

                <li className="nav-item nav-dropdown">
                  <Link className="nav-link nav-dropdown-toggle" to="#">
                    <i className="nav-icon fa fa-arrows" /> Master
                  </Link>
                  <ul className="nav-dropdown-items">
                    {
                      (customerid != 807 || userlog1.elearn != 1)
                        ?
                        <li className="nav-item">
                          <Link className="nav-link" to="/addMachineType">
                            <i className="nav-icon fa fa-user-plus" />
                            <span className="link-white">Add Machine Type</span>
                          </Link>
                        </li>
                        :
                        <></>

                    }
                    <li className="nav-item">
                      <Link className="nav-link" to="/addNewMachines">
                        <i className="nav-icon fa fa-user-plus" />
                        <span className="link-white">Add New Machines</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/addNewManufactures">
                        <i className="nav-icon fa fa-user-plus" />
                        <span className="link-white">Add New Manufacture</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/refererHosts">
                        <i className="nav-icon fa fa-user-plus" />
                        <span className="link-white">Referer Hosts</span>
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/cmsAddImage">
                    <i className="nav-icon fa fa-commenting-o" />
                    <span className="link-white">Add To Library</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </>)
    }
    else {
      return (
        <div className="sidebar">
          <nav className="sidebar-nav">
            <ul className="nav">
              <li className="nav-item">
                <Link className="nav-link" to={`/superAdmin`}>
                  <i className="nav-icon icon-speedometer" />
                  <span className="link-white">Service Dashboard</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={`/ContentManagementSystem/${serviceId}`}>
                  <i className="nav-icon icon-speedometer" />
                  <span className="link-white">Dashboard</span>
                </Link>
              </li>
              {userlog1.templateCheck == 1
                ?
                <div>
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsAddPage">
                      <i className="nav-icon fa fa-user-circle-o" />
                      <span className="link-white">Add Pages</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/PreviewTemplate">
                      <i className="nav-icon fa fa-user-circle-o" />
                      <span className="link-white">PreviewTemplate</span>
                    </Link>
                  </li>
                </div>
                :
                <li className="nav-item">
                  <Link className="nav-link" to="/cmsAddPage">
                    <i className="nav-icon fa fa-user-circle-o" />
                    <span className="link-white">Add Pages</span>
                  </Link>
                </li>
              }
              <li className="nav-item nav-dropdown">
                <Link className="nav-link nav-dropdown-toggle" to="#">
                  <i className="nav-icon fa fa-file-text-o" /> Mapping
                </Link>
                <ul className="nav-dropdown-items">
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsCategory">
                      <i className="nav-icon fa fa-indent" />
                      <span className="link-white">Map Page To Category</span>
                    </Link>
                  </li>
                  {
                    (userlog1.templateCheck == 1)
                    &&
                    <li className="nav-item">
                      <Link className="nav-link" to="/courseMappingToUser">
                        <i className="nav-icon fa fa-indent" />
                        <span className="link-white">Map course to user</span>
                      </Link>
                    </li>
                  }
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsRightPanel">
                      <i className="nav-icon fa fa-indent" />
                      <span className="link-white">Map Page To Right Panel</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsHomeDownPanel">
                      <i className="nav-icon fa fa-indent" />
                      <span className="link-white">Map Page To Home</span>
                    </Link>
                  </li>
                </ul>
              </li>
              {
                (customerid == 807 || customerid == 1783 || customerid == 6075 || userlog1.elearn == 1) && (


                  <li className="nav-item nav-dropdown">
                    <Link className="nav-link nav-dropdown-toggle" to="#">
                      <i className="nav-icon fa fa-arrows" />Course Mapping
                    </Link>
                    <ul className="nav-dropdown-items">
                      <li className="nav-item">
                        <Link className="nav-link" to="/cmdlCategory">
                          <i className="nav-icon fa fa-file-text-o" />
                          <span className="link-white">Courses Mapping</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/cmdlCluster">
                          <i className="nav-icon fa fa-user-plus" />
                          <span className="link-white">Add Cluster</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/clusterMapping">
                          <i className="nav-icon fa fa-indent" />
                          <span className="link-white">Map courses to Cluster</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/vidya/Rules">
                          <i className="nav-icon fa fa-user-plus" />
                          <span className="link-white">Add Rules</span>
                        </Link>
                      </li>
                    </ul>
                  </li>)
              }

              {
                (customerid == 807 || customerid == 1783 || customerid == 6075 || userlog1.elearn == 1) && (


                  <li className="nav-item nav-dropdown">
                    <Link className="nav-link nav-dropdown-toggle" to="#">
                      <i className="nav-icon fa fa-arrows" />Employee Master
                    </Link>
                    <ul className="nav-dropdown-items">
                      <li className="nav-item">
                        <Link className="nav-link" to="/BusinessUnit">
                          <i className="nav-icon fa fa-file-text-o" />
                          <span className="link-white">Business Unit</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/Function">
                          <i className="nav-icon fa fa-file-text-o" />
                          <span className="link-white">Function</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/SubFunction">
                          <i className="nav-icon fa fa-file-text-o" />
                          <span className="link-white">Sub Function</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/Region">
                          <i className="nav-icon fa fa-file-text-o" />
                          <span className="link-white">Region</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/divisionmaster">
                          <i className="nav-icon fa fa-file-text-o" />
                          <span className="link-white">Division</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/BranchMaster">
                          <i className="nav-icon fa fa-file-text-o" />
                          <span className="link-white">Branch</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/Grademaster">
                          <i className="nav-icon fa fa-file-text-o" />
                          <span className="link-white">Grade</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/Bandmaster">
                          <i className="nav-icon fa fa-file-text-o" />
                          <span className="link-white">Band</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/empcategory">
                          <i className="nav-icon fa fa-file-text-o" />
                          <span className="link-white">Employee Category Type</span>
                        </Link>
                      </li>
                    </ul>
                  </li>)
              }
              {
                (customerid == 807 || customerid == 1783 || customerid == 6075 || userlog1.elearn == 1) && (


                  <li className="nav-item nav-dropdown">
                    <Link className="nav-link nav-dropdown-toggle" to="#">
                      <i className="nav-icon fa fa-arrows" />Course Master
                    </Link>
                    <ul className="nav-dropdown-items">

                      <li className="nav-item">
                        <Link className="nav-link" to="/Vidya/VidyaReport">
                          <i className="nav-icon fa fa-user-plus" />
                          <span className="link-white">Course Report</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/cmsAddEmployee">
                          <i className="nav-icon fa fa-user-plus" />
                          <span className="link-white">Add Employee</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/cmsAddDealers">
                          <i className="nav-icon fa fa-user-plus" />
                          <span className="link-white">Add Dealers</span>
                        </Link>
                      </li>
                      {

                        customerid == 807 && <li className="nav-item">
                          <Link className="nav-link" to="/dealerReport">
                            <i className="nav-icon fa fa-user-plus" />
                            <span className="link-white"> Dealer report</span>
                          </Link>
                        </li>

                      }
                      <li className="nav-item">
                        <Link className="nav-link" to="/cmsAddSBU">
                          <i className="nav-icon fa fa-user-plus" />
                          <span className="link-white">Add SBU</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/cmsAddState">
                          <i className="nav-icon fa fa-user-plus" />
                          <span className="link-white">Add  State</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/cmsAddMOHQ">
                          <i className="nav-icon fa fa-user-plus" />
                          <span className="link-white">Add  MOHQ</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/cmsAddRBH">
                          <i className="nav-icon fa fa-user-plus" />
                          <span className="link-white">Add  RBH</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/cmsAddBusiDesignation">
                          <i className="nav-icon fa fa-user-plus" />
                          <span className="link-white">Add  Business Designation</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/cmsLanguages">
                          <i className="nav-icon fa fa-user-plus" />
                          <span className="link-white">Add Language Master</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/cmsAddMO">
                          <i className="nav-icon fa fa-user-plus" />
                          <span className="link-white">Add Marketing officer</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/cmsAddSales">
                          <i className="nav-icon fa fa-user-plus" />
                          <span className="link-white">Add Sales area</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to="/cmsAddUserType">
                          <i className="nav-icon fa fa-user-plus" />
                          <span className="link-white">Add UserType</span>
                        </Link>
                      </li>


                    </ul>
                  </li>


                )
              }
              <li className="nav-item nav-dropdown">
                <Link className="nav-link nav-dropdown-toggle" to="#">
                  <i className="nav-icon fa fa-arrows" /> Master
                </Link>
                <ul className="nav-dropdown-items">
                  {
                    (customerid != 807 || userlog1.elearn != 1)
                      ?
                      <li className="nav-item">
                        <Link className="nav-link" to="/cmsAddUser">
                          <i className="nav-icon fa fa-user-plus" />
                          <span className="link-white">Add User</span>
                        </Link>
                      </li>
                      :
                      <></>

                  }

                  {
                    customerid == 679 && (
                      <div>
                        <li className="nav-item">
                          <Link className="nav-link" to="/cmsAddMentor">
                            <i className="nav-icon fa fa-user-plus" />
                            <span className="link-white">Add Mentor</span>
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                  <Link className="nav-link" to="/cmsAddDepartment">
                    <i className="nav-icon fa fa-user-plus" />
                    <span className="link-white">Add Department</span>
                  </Link>
                </li> */}
                      </div>
                    )
                  }

                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsSubTitle">
                      <i className="nav-icon fa fa-commenting-o" />
                      <span className="link-white">Subtitle</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsAddQuiz">
                      <i className="nav-icon fa fa-commenting-o" />
                      <span className="link-white">Add Quiz</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsAddImage">
                      <i className="nav-icon fa fa-commenting-o" />
                      <span className="link-white">Add To Library</span>
                    </Link>
                  </li>
                  {
                    customerid == 807 && (<li className="nav-item">
                      <Link className="nav-link" to="/cmsAddLibrary">
                        <i className="nav-icon fa fa-commenting-o" />
                        <span className="link-white">Add To Library(1)</span>
                      </Link>
                    </li>)
                  }

                  <li className="nav-item">
                    <Link className="nav-link" to="/cmscategorymaster">
                      <i className="nav-icon fa fa-user-plus" />
                      <span className="link-white">Add Category</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmssubcategorymaster">
                      <i className="nav-icon fa fa-user-plus" />
                      <span className="link-white">Add SubCategory</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsAddAuthor">
                      <i className="nav-icon fa fa-user-plus" />
                      <span className="link-white">Add Author</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsAddVideoValue">
                      <i className="nav-icon fa fa-user-plus" />
                      <span className="link-white">Video Analytics</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsVideoList">
                      <i className="nav-icon fa fa-user-plus" />
                      <span className="link-white">Video List</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsSelectedVideoTrackObject">
                      <i className="nav-icon fa fa-user-plus" />
                      <span className="link-white">Video Object Tracking</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item nav-dropdown">
                <Link className="nav-link nav-dropdown-toggle" to="#">
                  <i className="nav-icon fa fa-arrows" /> Reports
                </Link>
                {/* <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/cmsCurrentlyVideoView">
                    <i className="nav-icon fa fa-user-plus" />
                    <span className="link-white">Usage Stats</span>
                  </Link>
                </li>
              </ul> */}
                <ul className="nav-dropdown-items">
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsSeekLog">
                      <i className="nav-icon fa fa-user-plus" />
                      <span className="link-white">Video Seek Log</span>
                    </Link>
                  </li>
                </ul>
                <ul className="nav-dropdown-items">
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsuserlog">
                      <i className="nav-icon fa fa-user-plus" />
                      <span className="link-white">Total Access Log</span>
                    </Link>
                  </li>
                </ul>
                <ul className="nav-dropdown-items">
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsLikeLog">
                      <i className="nav-icon fa fa-arrows" />
                      <span className="link-white">Like</span>
                    </Link>
                  </li>
                </ul>

                {(customerid == 807 || customerid == 1783 || customerid == 6075) ? (<ul className="nav-dropdown-items">
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmndlquizlog">
                      <i className="nav-icon fa fa-arrows" />
                      <span className="link-white">Quiz Reports</span>
                    </Link>
                  </li>
                </ul>) : (<ul className="nav-dropdown-items">
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsquizlog">
                      <i className="nav-icon fa fa-arrows" />
                      <span className="link-white">Quiz Reports</span>
                    </Link>
                  </li>
                </ul>
                )}
              </li>

              <li className="nav-item nav-dropdown">
                <Link className="nav-link nav-dropdown-toggle" to="#">
                  <i className="nav-icon fa fa-arrows" /> Listing Order
                </Link>
                <ul className="nav-dropdown-items">
                  <li className="nav-item">
                    <Link className="nav-link" to="/cmsArrangeNav">
                      <i className="nav-icon fa fa-user-plus" />
                      <span className="link-white">Arrange Category</span>
                    </Link>
                  </li>
                </ul>
                <ul className="nav-dropdown-items">
                  <li className="nav-item">
                    <Link className="nav-link" to="/ArrangeSubCategory">
                      <i className="nav-icon fa fa-user-plus" />
                      <span className="link-white">Arrange Sub-Category</span>
                    </Link>
                  </li>
                </ul>
              </li>

              {customerid == 620 && (

                <li className="nav-item nav-dropdown">
                  <Link className="nav-link nav-dropdown-toggle" to="#">
                    <i className="nav-icon fa fa-arrows" /> Site
                  </Link>
                  <ul className="nav-dropdown-items">
                    <li className="nav-item">
                      <Link className="nav-link" to="/Cms/sitehome">
                        <i className="nav-icon fa fa-user-plus" />
                        <span className="link-white">Home</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
              <li className="nav-item nav-dropdown">
                <Link className="nav-link nav-dropdown-toggle" to="#">
                  <i className="nav-icon fa fa-arrows" /> Form
                </Link>
                <ul className="nav-dropdown-items">
                  <li className="nav-item">
                    <Link className="nav-link" to="/Cms/Forms">
                      <i className="nav-icon fa fa-user-plus" />
                      <span className="link-white">Form</span>
                    </Link>
                  </li>
                </ul>
                <ul className="nav-dropdown-items">
                  <li className="nav-item">
                    <Link className="nav-link" to="/Cms/FormReport">
                      <i className="nav-icon fa fa-user-plus" />
                      <span className="link-white">Form Report</span>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={`/CMS/addTable`}>
                  <i className="nav-icon fa fa-indent" />
                  <span className="link-white">Add Table Data</span>
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" target="_blank" href={`http://35.169.94.138:3030/${userlog.id}`}>
                  <i className="nav-icon fa fa-desktop" />
                  <span className="link-white">Preview</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      );
    }
  }
}

export default CMSNav;
