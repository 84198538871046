import React, { Component } from "react";
import { Alert } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html";
import cmsContent from "../../../../MiddleWare/CmsContent";
import http from "../../../../MiddleWare/httpMiddleWare";
import Datatable from "../../../../components/Datatable/Datatable";
import LoginModal from "../../../../components/Modal/Modal";
import FromLibrary from "../FromLibrary";
import { ACCESS_POINT } from "../../../../config";
import { ACCESS_SAMPLE } from "../../../../config";
import Switch from "react-switch";
import Progress from "../../../../components/Extra/Progress";
import htmlToDraft from 'html-to-draftjs';
import { isBuffer } from "lodash-es";


class Theme5 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorState: EditorState.createEmpty(),
			getlibrarydataImage: props.that.getlibrarydataImage,
			getlibrarydataDocs: props.that.getlibrarydataDocs,
			data: [],
			Files: [],
			btnDisable: false,
			AllContent: [],
			EmptySection: { title: "", thumbnail: '', files: [] },
			uploadPercentage: 0,
			isEdit: false,
			label: '',
			label1: '',
			screen: window.screen.height,
			box: false
		}
	}
	async componentWillMount() {
		try {
			//console.log(this.props.that.getlibrarydataImage);
			console.log(this.props.that)
			if (this.props.that.data) {

				let pages = [];
				this.props.that.data.map((ival, i) => {
					if (ival.themeId === 5) {
						//console.log(ival)
						pages.push(ival);
					}
				})

				this.setState({ data: pages });
				if (this.props.that.isEdit) {
					//Content1


					const { contentBlocks, entityMap } = htmlToDraft(this.props.that.content1);
					const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
					const editorState = EditorState.createWithContent(contentState);
					//this.setState({ editorState })

					//Section content
					let data = JSON.parse(this.props.that.theme4);
					data.map((ival, i) => {
						data[i].title = this.draftToHTML(ival.title)
					})

					this.setState({ isEdit: this.props.that.isEdit, EditId: this.props.that.id, contentTitle1: this.props.that.contentTitle1, searchTags: this.props.that.searchTags, editorState, AllContent: data, data: this.props.that.datatable })
				}
			}
		} catch (e) {
			console.log(e);
		}
	}
	column = [
		{
			Header: "Page",
			accessor: "name"
		},
		{
			Header: "Status",
			accessor: "status"
		},
		{
			Header: "Theme",
			accessor: "Edit",
			Cell: d => this.showTheme(d)
		},
		{
			Header: "Visibility",
			accessor: "edit",
			Cell: d => this.showVisibility(d)
		},
		{
			Header: "Require Login",
			accessor: "requireLogin"
		},
		{
			Header: "Change Login Status",
			accessor: "edit",
			Cell: d => this.changeLoginStatus(d)
		},
		{
			Header: "Edit",
			accessor: "edit",
			Cell: d => this.Editpages(d)
		},
		{
			Header: "Preview",
			accessor: "edit",
			Cell: d => this.PreviewPage(d)
		}
	];

	showTheme = d => {
		return `Theme-${d.original.themeId}(To Display Box type Content in Page)`;
	}
	PreviewPage = (d) => {

		return (<button
			type="button"
			className="btn btn-info"
			onClick={() => this.PreviewRoute(d)}
		>
			Preview
		</button>
		);
	}

	PreviewRoute = (d) => {
		console.log(d);
		let id = d.original.id;
		let customerId = d.original.customerId;
		if (id) {
			window.open(
				`${ACCESS_SAMPLE}/samplesite/th5/${id}/${customerId}`,
				'_blank'
			);
		}
	}


	viewmedia = async value => {
		this.setState({
			videotype: "",
			viewmedia: ACCESS_POINT + `/superAdmin/file?fileurl=${value}`
		});
		var that = this;
		setTimeout(function () {
			if (value.split(".")[1] === "mp4") {
				that.setState({ videotype: "video" });
			} else if (
				value.split(".")[1] === "jpg" ||
				value.split(".")[1] === "png" ||
				value.split(".")[1] === "jpeg"
			) {
				that.setState({ videotype: "image" });
			} else {
				that.setState({ videotype: null });
			}
		}, 500);
	}

	contentHeading = [
		{
			Header: "Search By Hint",
			accessor: "hint"
		},
		{
			Header: "File Name",
			accessor: "label",
			Cell: d => this.aModalLink(d)
		},
		{
			Header: "Select",
			accessor: "edit",
			Cell: d => this.checktest(d)
		}
	];

	aModalLink = id => {
		let str = id.original.label;
		let res = str.split("/");
		return (
			<a href="#" onClick={() => this.viewmedia(id.original.label)}>
				{res[2]}
			</a>
		);
	};

	checktest = id => {
		return (
			<button
				type="button"
				className="btn btn-info"
				data-dismiss="modal"
				onClick={() => {
					let { a, AllContent } = this.state;
					if (a.j !== undefined) {
						AllContent[a['i']].files[a['j']][a['name']] = id.original.label;
						this.setState({ a: {}, AllContent })
					} else {
						AllContent[a['i']][a['name']] = id.original.label;
						this.setState({ a: {}, AllContent })
					}
				}}
			>
				Select
			</button>
		);
	};

	showVisibility = id => {
		return (
			<button
				type="button"
				className="btn btn-info"
				onClick={() => this.updateVisibility(id)}
			>
				Hide/Show
			</button>
		);
	};
	updateVisibility = async value => {
		const index = value.index;
		console.log(index);
		const previousData = [...this.state.data];
		//console.log(previousData);
		const newData = { ...previousData[index] };
		console.log(newData);
		if (newData.status === "show") {
			newData.status = "hide";
		} else {
			newData.status = "show";
		}
		const id = newData.id;
		const data = previousData.filter(value => value.id !== id);
		data.splice(index, 0, newData);
		try {
			const result = await cmsContent.updatePage(newData);
			if (result) {
				this.setState({ data: data });
			}
		} catch (error) {
			this.setState({ data: previousData });
			console.log(error);
		}
	};

	changeLoginStatus = id => {
		return (
			<button
				type="button"
				className="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '10px' }}
				onClick={() => this.updateLogin(id)}
			>
				Change Login Status
			</button>
		);
	};

	updateLogin = async value => {
		const index = value.index;
		const previousData = [...this.state.data];
		const newData = { ...previousData[index] };
		if (newData.requireLogin === "yes") {
			newData.requireLogin = "no";
		} else {
			newData.requireLogin = "yes";
		}
		const id = newData.id;
		const data = previousData.filter(value => value.id !== id);
		data.splice(index, 0, newData);
		this.setState({ data });
		try {
			const result = await cmsContent.updateLoginStatus(newData);
			if (result) {
				this.setState({ data: data });
			}
		} catch (error) {
			this.setState({ data: previousData });
			console.log(error);
		}
	};

	Editpages = value => {
		return (<button className="btn btn-info" onClick={() => this.onEdit(value)}>
			Edit
		</button>)
	};
	draftToHTML = v => {
		const { contentBlocks, entityMap } = htmlToDraft(v);
		const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
		const editorState = EditorState.createWithContent(contentState);
		return editorState;
	}
	onEdit = d => {

		const org = d.original;
		console.log(org.content1)
		const editorState = this.draftToHTML(org.content1);
		const index = d.index;
		console.log(org);
		const options = [
			{ label: "Theme1", value: "1" },
			{ label: "Theme2", value: "2" },
			{ label: "Theme3", value: "3" },
			{ label: "Theme4", value: "4" },
			{ label: "Theme5", value: "5" },
			{ label: "Theme6", value: "6" },
			{ label: "Theme7", value: "7" },
			{ label: "Theme8", value: "8" },
			{ label: "Theme9", value: "9" }
		];
		if (typeof this.props.these === 'function') {
			this.props.these('pageName', org.name);
			this.props.these('autherSelected', this.props.that.authorlist.filter(ival => ival.value === org.authorid)[0]);
			this.props.these('themeSelected', options.filter(ival => ival.value == org.themeId)[0]);
			this.props.these('checked', org.show == 1 ? 1 : 0);
		}
		let data = JSON.parse(org.sheetContent);
		data.map((ival, i) => {
			if (ival.title == null) {
				ival.title = "<p></p>"
			}
			ival.title = this.draftToHTML(ival.title)
		})
		this.setState({
			contentTitle1: org.contentTitle1,
			box: org.contentTitle2,
			searchTags: org.searchTags,
			editorState,
			isEdit: true,
			AllContent: data,
			EditId: org.id,
			Index: index
		})
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
	}

	updateCategory = async e => {
		e.preventDefault();
		const {
			checked,
			autherSelected,
			pageName,
			customerId,

		} = this.props.that;
		const { editorState,
			contentTitle1,
			searchTags,
			AllContent, Files, EditId, Index } = this.state;
		if (!autherSelected) {
			this.props.these('errorAuthorName', 'Select Author')
			return false;
		} else if (!pageName) {
			this.props.these('errorAuthorName', '')
			this.props.these('errorPageName', 'Enter Page name')
			return false;
		}
		let editorContent = editorState
			? draftToHtml(
				convertToRaw(editorState.getCurrentContent())
			)
			: null;
		let AllContent1 = [];
		AllContent.map((ival, i) => {
			let arr = {};
			arr.title = ival.title
				? draftToHtml(
					convertToRaw(ival.title.getCurrentContent())
				)
				: null;
			arr.thumbnail = ival.thumbnail;
			arr.sort = ival.sort
			arr.files = ival.files;
			AllContent1.push(arr);
		});
		let today = new Date();
		let dateformat = today.getDate();
		if (today.getDate() < 10) {
			dateformat = "0" + today.getDate();
		}
		let date =
			today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + dateformat;
		const formData = new FormData();
		formData.append("name", pageName);
		formData.append("themeId", '5');
		if (this.props.that.isEdit == true) {
			formData.append("authorid", autherSelected[0].value);
		}
		else {
			formData.append("authorid", autherSelected.value);
		}
		formData.append("contentTitle1", contentTitle1);
		formData.append("content1", editorContent);
		formData.append("searchTags", searchTags);
		formData.append("show", checked);
		formData.append("contentTitle2", this.state.box);
		Files.map((ival, i) => {
			let a = Object.keys(ival)[0];
			formData.append(a, ival[a])
		});
		formData.append("sheetContent", JSON.stringify(AllContent1));
		formData.append("Date", date);
		formData.append("status", "show");
		formData.append("customerId", customerId);
		formData.append("id", EditId);

		try {

			let { data } = await http.put(
				ACCESS_POINT + `/cmsContent/tbl_pages`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data"
					},
					onUploadProgress: progressEvent => {
						this.setState({
							uploadPercentage: parseInt(
								Math.round((progressEvent.loaded * 100) / progressEvent.total)
							)
						});
						setTimeout(() => this.setState({ uploadPercentage: 0 }), 10000);

					}
				}
			);

			if (data && data.length) {
				console.log([...formData])
				data = data[0];

				const previousData = [...this.state.data];

				if (this.props.that.isEdit) {
					let index = this.props.that.data.indexOf(this.props.that.data1);
					previousData[index] = data;

				}
				else {
					previousData[Index] = data;
				}
				// console.log(previousData);
				setTimeout(() => this.setState({
					contentTitle1: "",
					editorState: EditorState.createEmpty(),
					searchTags: "",
					AllContent: [],
					btnDisable: false,
					isEdit: false,
					data: previousData,
					box: false
				}), 10000);
				this.props.these('pageName', '');
				this.props.these('checked', 0);
				this.props.these('errorPageName', '');
				this.props.these('autherSelected', '');
				this.props.these('themeSelected', '')
				this.props.these('isEdit', false)
				/*window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});*/
				//
				setTimeout(() => this.props.these('alertVisible', true), 10000);
			}



		} catch (error) {
			console.log(error);
		}

	}


	handlechange = e => {

		this.setState({ [e.target.name]: e.target.value });
	};
	onEditorStateChange = editorState => {
		this.setState({
			editorState
		});
	};
	AddFile = () => {
		let { AllContent, EmptySection } = this.state;
		AllContent.push({ title: "", thumbnail: '', files: [] });
		this.setState({ AllContent });
	}

	AddPage = (i) => {
		let { AllContent, EmptyFileSection } = this.state;
		AllContent[i].files.push({ name: '', link: '', icon: '' });
		this.setState({ AllContent });
	}
	HandlingChange = (a, b, c, d = null) => {
		let { AllContent, Files } = this.state;
		if (d !== null) {
			if (c === 'name') {
				AllContent[a].files[d][c] = b.target.value;
			} else if (c === 'icon' || c === 'link') {
				AllContent[a].files[d][c] = b.target.files[0].name;
				Files.push({ [b.target.files[0].name]: b.target.files[0] })
			}
		} else {
			if (c === 'title') {
				AllContent[a][c] = b;
			} else if (c === 'thumbnail') {
				AllContent[a][c] = b.target.files[0].name;
				Files.push({ [b.target.files[0].name]: b.target.files[0] })
			}
		}
		if (c === 'sort') {
			AllContent[a]['sort'] = b.target.value
		}
		// console.log(AllContent, b.target.value)
		this.setState({ AllContent, Files });
	}

	submitCategory = async e => {
		//alert(1);
		e.preventDefault();
		//Getting vaue from parent page
		const {
			checked,
			autherSelected,
			pageName,
			customerId,

		} = this.props.that;
		const { editorState,
			contentTitle1,
			searchTags,
			AllContent, Files } = this.state;
		if (!autherSelected) {
			this.props.these('errorAuthorName', 'Select Author')
			return false;
		} else if (!pageName) {
			this.props.these('errorAuthorName', '')
			this.props.these('errorPageName', 'Enter Page name')
			return false;
		}
		let editorContent = editorState
			? draftToHtml(
				convertToRaw(editorState.getCurrentContent())
			)
			: null;
		let FileName = [];
		let AllContent1 = [];
		AllContent.map((ival, i) => {
			let arr = {};
			arr.title = ival.title
				? draftToHtml(
					convertToRaw(ival.title.getCurrentContent())
				)
				: null;
			arr.thumbnail = ival.thumbnail;
			arr.files = ival.files;
			arr.sort = ival.sort
			AllContent1.push(arr);
		});
		let today = new Date();
		let dateformat = today.getDate();
		if (today.getDate() < 10) {
			dateformat = "0" + today.getDate();
		}
		let date =
			today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + dateformat;
		const formData = new FormData();
		formData.append("name", pageName);
		formData.append("themeId", '5');
		formData.append("authorid", autherSelected.value);
		formData.append("contentTitle1", contentTitle1);
		formData.append("contentTitle2", this.state.box);
		formData.append("content1", editorContent);
		formData.append("searchTags", searchTags);
		formData.append("show", checked);
		Files.map((ival, i) => {
			let a = Object.keys(ival)[0];
			formData.append(a, ival[a])
		});
		formData.append("sheetContent", JSON.stringify(AllContent1));
		formData.append("Date", date);
		formData.append("status", "show");
		formData.append("customerId", customerId);
		formData.append("requireLogin", 'no');
		console.log([...formData]);
		try {
			let { data } = await http.post(
				ACCESS_POINT + `/cmsContent/theme/themeFive`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data"
					},
					onUploadProgress: progressEvent => {
						this.setState({
							uploadPercentage: parseInt(
								Math.round((progressEvent.loaded * 100) / progressEvent.total)
							)
						});
						setTimeout(() => this.setState({ uploadPercentage: 0 }), 10000);

					}
				}
			);
			if (data && data.length) {
				data = data[0];
				console.log(data);
				const previousData = [data, ...this.state.data];
				// previousData[Index]=data;
				console.log(previousData);
				setTimeout(() => this.setState({
					contentTitle1: "",
					editorState: EditorState.createEmpty(),
					searchTags: "",
					AllContent: [],
					btnDisable: false,
					data: previousData,
					box: false
				}), 10000);
				this.props.these('pageName', '');
				this.props.these('checked', 0);
				this.props.these('errorPageName', '')
				this.props.these('autherSelected', '');
				this.props.these('themeSelected', '')
				/*window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});*/
				//this.props.these('alertVisible', true);
				setTimeout(() => this.props.these('alertVisible', true), 10000)
			}

		} catch (error) {
			console.log(error);
		}
	}

	removeArray = (a, b = null) => {
		const { AllContent } = this.state;
		let index1 = a - 1;

		if (b !== null) {
			let index2 = b - 1;
			AllContent[index1].files.splice(index1, 1);
		} else {
			AllContent.splice(index1, 1);
		}
		this.setState({ AllContent });
	}

	HandlingBox = (d) => {
		//console.log(d);
		/*if(d===true){
		this.setState({box:"1"});
		}else{*/
		this.setState({ box: d });
		//}
	}


	render() {
		const { AllContent, uploadPercentage } = this.state;
		return (
			<React.Fragment>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2">
						<label htmlFor="exampleInputEmail1">
							Content Title
						</label>
					</div>
					<div className="col-sm-5">
						<textarea
							type="text"
							className="form-control"
							id="contentTitle1"
							name="contentTitle1"
							placeholder="Enter Content Title"
							value={this.state.contentTitle1}
							onChange={this.handlechange}
						/>
					</div>
					<div className="col-sm-1 ">
						<label style={{ paddingTop: 5, fontSize: 'small' }}>
							BoxType:
							<Switch
								height={18}
								width={41}
								marginBottom={1}
								paddingTop={5}
								onChange={d => this.HandlingBox(d)}
								checked={this.state.box}
							/>
						</label>
					</div>
					<div className="col-sm-2" />
				</div>
				<div className="row form-group">
					<div className="col-sm-4" />
					<span className="error-show ">
						{this.state.errorContentTitle1}
					</span>
				</div>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2">
						<label htmlFor="exampleInputEmail1">Content</label>
					</div>
					<div className="col-sm-5">
						<Editor
							editorState={this.state.editorState}
							wrapperClassName="wrapper-className"
							editorClassName="editor-className editor-border"
							toolbarClassName="toolbar-className"
							onEditorStateChange={this.onEditorStateChange}
							toolbar={{
								options: [
									"inline",
									"blockType",
									"fontSize",
									"fontFamily",
									"list",
									"link"
								]
							}}
							value={this.state.editorState}
						/>
					</div>
					<div className="col-sm-3" />
				</div>

				<div className="row form-group">
					<div className="col-sm-4" />
					<span className="error-show ">{this.state.errorContent1}</span>
				</div>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2">
						<label htmlFor="exampleInputEmail1">Search Tags</label>
					</div>
					<div className="col-sm-5">
						<textarea
							type="text"
							className="form-control"
							id="searchTags"
							name="searchTags"
							placeholder="Enter Search Tags"
							value={this.state.searchTags}
							onChange={this.handlechange}
						/>
					</div>
					<div className="col-sm-3" />
				</div>
				<div className="row form-group">
					<div className="col-sm-4" />
					<span className="error-show ">{this.state.errorSearchTags}</span>
				</div>

				{AllContent.map((ival, i) => {
					let index = i + 1;

					return (
						<React.Fragment>
							<div className="row">
								<div className="col-12">
									<div className="card">
										<div className="card-header">
											<div className="row form-group">
												<div className="col-7">
													<h4>Section -{index}</h4>
												</div>
												<div className="col-1">
													<label htmlFor="sort" style={{ float: "right" }}>Sort :</label>
												</div>
												<div className="col-4">
													<input type="number"
														className="form-control"
														name="sort"
														id="sort"
														placeholder={`Sort ${i + 1}`}
														style={{ float: "left" }}
														onChange={(e) => this.HandlingChange(i, e, 'sort')}
														value={ival.sort}
													/>
												</div>
											</div>

										</div>
										<div className="card-body"></div>
										<div className="row form-group">
											<div className="col-sm-2" />
											<div className="col-sm-2">
												<label htmlFor="exampleInputEmail1">
													Section Title {index}
												</label>
											</div>
											<div className="col-sm-5">
												<Editor
													editorState={ival.title}
													wrapperClassName="wrapper-className"
													editorClassName="editor-className editor-border"
													toolbarClassName="toolbar-className"
													onEditorStateChange={d => this.HandlingChange(i, d, 'title')}
													toolbar={{
														options: [
															"inline",
															"blockType",
															"fontSize",
															"fontFamily",
															"list",
															"link"
														]
													}}
													value={this.state.editorState}
												/>
											</div>
											<div className="col-sm-1">
												<i class="fa fa-times-circle"
													style={{ fontSize: "25px" }}
													onClick={() => this.removeArray(index)}
												></i>
											</div>
											<div className="col-sm-2" />
										</div>
										<div className="row form-group">
											<div className="col-sm-2" />
											<div className="col-sm-2">
												<label htmlFor="exampleInputEmail1">
													Upload Thumbnail {index}
												</label>
											</div>
											<div className="col-sm-5">
												<input
													type="file"
													className="custom-file-input"
													id="customFile"
													onChange={d => this.HandlingChange(i, d, 'thumbnail')}
													accept="image/*"
												/>
												<label className="custom-file-label" htmlFor="customFileThumbnail" style={{ fontSize: '10px' }}>
													{ival.thumbnail ? ival.thumbnail : null}
												</label>
												<span>
													<LoginModal
														buttonTitle="Select Image from Library"
														title="Select Image"
														id="fsdfsf"
														maxwidth="modal-xl"
														bodyText={
															<FromLibrary
																datas={this.state.getlibrarydataImage}
																columnHeading={this.contentHeading}
																imgsource={this.state.viewmedia}
																type={this.state.videotype}
															/>
														}
														atagLink={true}
														onClick={() => { this.setState({ a: { i, name: "thumbnail" } }) }}
													/>
												</span>
											</div>
											<div className="col-sm-3" />
										</div>
										{ival.files.map((jval, j) => {
											let jndex = j + 1;
											return (
												<React.Fragment>
													<div className="row form-group" style={{ backgroundColor: "#f1ecec", width: "99%", marginLeft: "3px" }}>
														{/*<div className="col-sm-2" />*/}
														<div className="col-sm-1" style={{ textAlign: "center" }}>
															<i class="fa fa-times-circle"
																style={{ fontSize: "25px" }}
																onClick={() => this.removeArray(index, jndex)}
															></i>
														</div>
														<div className="col-sm-1">
															<label htmlFor="exampleInputEmail1" style={{ fontSize: "15px" }}>
																Icon {jndex}
															</label>
														</div>
														<div className="col-sm-2">
															<input
																type="file"
																className="custom-file-input"
																onChange={d => this.HandlingChange(i, d, 'icon', j)}
																accept="image/*"
															/>
															<label className="custom-file-label" htmlFor="customFileThumbnail" style={{ fontSize: '10px' }}>
																{jval.icon ? jval.icon.substring(0, 15) : null}
															</label>
															<span>
																<LoginModal
																	buttonTitle="Image Library"
																	title="Select Image"
																	id="fsdfsf"
																	maxwidth="modal-xl"
																	bodyText={
																		<FromLibrary
																			datas={this.state.getlibrarydataImage}
																			columnHeading={this.contentHeading}
																			imgsource={this.state.viewmedia}
																			type={this.state.videotype}
																		/>
																	}
																	atagLink={true}
																	onClick={() => { this.setState({ a: { i, name: "icon", j } }) }}
																/>
															</span>
														</div>
														{/*  <div className="col-sm-3" />*/}
														<div className="col-sm-1">
															<label htmlFor="exampleInputEmail1" style={{ fontSize: "15px" }}>
																Name {jndex}
															</label>
														</div>
														<div className="col-sm-3">
															<input
																type="text"
																className="form-control"
																placeholder="Enter Name Title"
																value={jval.name}
																onChange={d => this.HandlingChange(i, d, 'name', j)}
															/>
														</div>
														<div className="col-sm-1">
															<label htmlFor="exampleInputEmail1" style={{ fontSize: "15px" }}>
																Link {jndex}
															</label>
														</div>
														<div className="col-sm-2">
															<input
																type="file"
																className="custom-file-input"
																onChange={d => this.HandlingChange(i, d, 'link', j)}
																accept="image/*,application/pdf"
															/>
															<label className="custom-file-label" htmlFor="customFileThumbnail" style={{ fontSize: '9px' }}>
																{jval.link ? jval.link.substring(0, 15) : null}
															</label>
															<span>
																<LoginModal
																	buttonTitle="Document Library"
																	title="Select Document"
																	id="fsdfsf"
																	maxwidth="modal-xl"
																	bodyText={
																		<FromLibrary
																			datas={this.state.getlibrarydataDocs}
																			columnHeading={this.contentHeading}
																			imgsource={this.state.viewmedia}
																			type={this.state.videotype}
																		/>
																	}
																	atagLink={true}
																	onClick={() => { this.setState({ a: { i, name: "link", j } }) }}
																/>
															</span>
														</div>
														<div className="col-sm-1" />
													</div>
												</React.Fragment>
											)
										})
										}

										{this.state.box === false && (
											<div className="row form-group">
												<div className="col-sm-2" />
												<div className="col-sm-6" />
												<div className="col-sm-2">
													<button
														type="button"
														className="btn btn-primary"
														onClick={() => this.AddPage(i)}
													>
														Add File
													</button>
												</div>
											</div>)}
									</div>
								</div>
							</div>
						</React.Fragment>
					)
				})}

				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-6" />
					<div className="col-sm-2">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.AddFile()}
						>
							Add New Section
						</button>
					</div>
				</div>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-7">
						{uploadPercentage > 0 && (
							<Progress percentage={uploadPercentage} />
						)}
					</div>
					<div className="col-sm-3" />
				</div>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2" />
					<div className="col-sm-5">
						<button
							type="button"
							className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
							disabled={this.state.btnDisable}
							onClick={this.state.isEdit ? this.updateCategory : this.submitCategory}
						>
							{this.state.isEdit ? 'Update' : 'Add Page'}
						</button>
					</div>
					<div className="col-sm-3" />
				</div>
				<div className="row form-group">
					<div className="col-sm-12">
						{this.state.data ? (<Datatable
							data={this.state.data}
							columnHeading={this.column}
						/>) : null}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Theme5;