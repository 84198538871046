import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from "reactstrap";

class ArrangeNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      themeId: null,
      changenumber: null,
      fullData: [],
      index: null,
      alertVisible: false,
      customerId: localStorage.getItem("userId")
    };
  }
  async componentDidMount() {
    const result = await CmsContent.getFreedom(
        'tbl_mapping_page.id as subcatorderid,tbl_mapping_page.listingorder,tbl_mapping_page.listingsubcatagory,tbl_mapping_page.categoryId,tbl_mapping_page.categoryName,tbl_mapping_page.subCategoryId,tbl_mapping_page.subCategoryName,tbl_pages.id as pageId ,theme_master.themeName,tbl_pages.requireLogin',
        'tbl_mapping_page,tbl_pages,theme_master',
        `tbl_mapping_page.pageId = tbl_pages.id and tbl_pages.themeId =  theme_master.themeId and tbl_pages.customerId=${this.state.customerId} and tbl_pages.status="show" and  tbl_mapping_page.status = "active"`,
        'tbl_mapping_page.id',
        'tbl_mapping_page.listingorder,tbl_mapping_page.listingsubcatagory'
      );
    if (result) {
		//console.log(result.data)
      this.setState({ categories: result.data });
      let categories = [...this.state.categories];
      var categoryData = [];
      var fullList = [];
      if (categories.length > 0) {
        const map = new Map();
        for (const item of categories) {
          if (!map.has(item.categoryId)) {
            map.set(item.categoryId, true); // set any value to Map
            categoryData.push({ categoryId: item.categoryId });
          }
        }
        fullList = categoryData.map(values => {
          return categories.filter(
            list => list.categoryId === values.categoryId
          );
        });
      }

      var fullData = [];
      if (fullList.length > 0) {
        fullList.map(values => {
          
          let val = [];
          val.categoryName = values[0].categoryName;
          val.listingorder = values[0].listingorder;
          val.categoryId = values[0].categoryId;
		  val.subcatorderid=values[0].subcatorderid;
          if (values[0].subCategoryName) {
            fullData.push(val);
          } else {
            fullData.push(val);
          }
        });
        this.setState({ fullData });
       
      }
    }
  }
  column = [
    {
      Header: "categoryName",
      accessor: "categoryName",
      Cell: d => this.displayname(d)
    },
    {
      Header: "Order",
      accessor: "listingorder",
      Cell: d => this.displayorder(d)
    },
    {
      Header: "edit",
      accessor: "edit",
      Cell: d => this.edit(d)
    }
  ];
  displayname = d => {
    return d.original.categoryName;
  };
  displayorder = d => {
    return d.original.listingorder;
  };
  edit = d => {
   

    let categoryId = d.original.categoryId;
    let categoryName = d.original.categoryName;
    let listingorder = d.original.listingorder;
	let catid = d.original.subcatorderid;
	//console.log(d.original); 
    let index = d.index;
    return (
      <div>
        <button
          type="button"
          data-toggle="modal"
          data-target="#new"
          className="btn btn-primary"
          onClick={() =>
            this.setState({ categoryId, listingorder, categoryName, index,catid})
          }
        >
          Change Order
        </button>
      </div>
    );
  };

  changeOrder = () => {
    if (this.state.listingorder != this.state.changenumber) {
		console.log(this.state.catid)
      const results = CmsContent.UpdateOrder(
        this.state.categoryId,
        this.state.changenumber,
		this.state.catid 
      );
      if (results) {
        let fullData = [...this.state.fullData];
        let index = this.state.index;
        fullData[index].listingorder = this.state.changenumber;
        this.setState({
          changenumber: "",
          index: null,
          categoryId: null,
          categoryName: null,
          listingorder: null,
          fullData,
          alertVisible: true
        });
        setTimeout(() => this.setState({ alertVisible: false }), 2000);
      }
    }
  };
  change = d => {
    this.setState({ changenumber: d.target.value });
  };
  onDismiss = () => {
    this.setState({ alertVisible: false, formAlert: false });
  };

  render() {
    const { fullData, alertVisible } = this.state;
    

    return (
      <main className="main my-4">
        <main className="container-fluid">
          <div>
            <div
              className="modal fade"
              id="new"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title" id="exampleModalLabel">
                      Change Order
                    </h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="row form-group">
                      <div className="col-sm-1" />
                      <div className="col-sm-3">
                        <label for="exampleInputEmail1">Category Order</label>
                      </div>
                      <div className="col-sm-7">
                        <input
                          type="number"
                          class="form-control"
                          id="category"
                          name="category"
                          placeholder="Decimal/Rational"
                          onChange={this.change}
                          value={this.state.changenumber}
                        />
                      </div>
                      <div className="col-sm-1" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <div className="col-sm-4">
                        <button
                          type="button"
                          class="btn btn-primary"
                          data-dismiss="modal"
                          onClick={() =>
                            this.changeOrder(this.state.changenumber)
                          }
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-sm-4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h1>Arrange Category</h1>
                </div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-7">
                    <Alert
                      className="badge-content"
                      color="success"
                      isOpen={alertVisible}
                      toggle={this.onDismiss}
                    >
                      Category Ordered
                    </Alert>
                  </div>
                  <div className="col-sm-3" />
                </div>
                <div className="card-body">
                  {fullData && (
                    <Datatable data={fullData} columnHeading={this.column} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </main>
      </main>
    );
  }
}

export default ArrangeNav;
