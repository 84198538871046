import React, { Component } from 'react';
import homepage1 from '../../images/CMS/homepage-module-1.jpg';
import { Link } from 'react-router-dom';
import cmsContent from '../../MiddleWare/CmsContent';
import './style.css';
import './stylenew.css';

class HomeBottomPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: []
    };
  }
  async componentDidMount() {
    const result = await cmsContent.getAllHomePanelList();
    if (result) {
      this.setState({ dataList: result.data });
      
 
    }
  }

  render() {
    const { dataList } = this.state;
    let appendHomepanel = [];
    let newClass = null;
    if (dataList) {
      dataList.map((homeValue, index) => {
        if (index === 0) {
          newClass = 'color-code1';
        } else if (index === 1) {
          newClass = 'color-code2';
        } else {
          newClass = 'color-code3';
        }
        appendHomepanel.push(
          <div className="col-sm-4 ">
            {/*
            <div className={`animate-movein item lifestyle shadow-view ${newClass}`} style={{ top: 0, opacity: 1 }}>
              <div className="module-image">
                <div className="item-title">
                  <span className="inner">{homeValue.categoryName}</span>
                </div>
                <Link to={`/CMS/${homeValue.themeName}/${homeValue.id}`}>
                  <img className="homepanel-scaleImage" src={homeValue.file} alt="Lifestyle" />
                </Link>
              </div>
              <div className="module-description">
                <h1>
                  <Link to={`/CMS/${homeValue.themeName}/${homeValue.id}`}>{homeValue.categoryName}</Link>
                </h1>
                <p>{homeValue.content1}</p>
              </div>
            </div>
             */}
            <div class=" widget has-shadow shadow-view">
              <div class="widget-header bordered no-actions d-flex align-items-center">
                <h4> {homeValue.categoryName}</h4>
                 
              </div>
              <div class="widget-body">
                <div class="row">
                  <div class="col-md-12 about-infos d-flex flex-column">
                    <Link to={`/CMS/${homeValue.themeName}/${homeValue.id}`}>
                      <img class="card-img-bottom" src={homeValue.file} alt="Card image" style={{ width: '100%', height: 200 }} />
                    </Link>
                    <div class="about-text" style={{ marginLeft: 9,marginTop: 15 }}>
                      {homeValue.content1}
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        );
      });
    }
    
    return (
      <React.Fragment>
        <div className="row" style={{ padding: 10 }}>
          {appendHomepanel}
        </div>{' '}
      </React.Fragment>
    );
  }
}

export default HomeBottomPanel;
