import React from 'react';
import { Datatable } from '../../../components/Datatable';
import CmsContent from '../../../MiddleWare/CmsContent';
import SingleSelect from "../../../components/Form/SingleSelect";
import FormMiddleWare from '../../../components/Form/FormMiddleware';

class UserEvaluation extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      customerid: JSON.parse(localStorage.getItem('userlog')).id,
      page2: 0,
      reportData: []
    };
  }
  
  async componentWillMount() {
    window.scrollTo(0, 0);
    try {
      let customerid = this.state.customerid;
      let QuizList = await CmsContent.getFreedom(
        'tbl_question.quizName as label,tbl_question.id as value,tbl_question.contentJson',
        'tbl_quizAnswer,tbl_question',
        `tbl_quizAnswer.userId=${customerid} and tbl_quizAnswer.quizId=tbl_question.id and tbl_quizAnswer.serviceid=8`,
        'tbl_quizAnswer.quizId',
        'tbl_quizAnswer.id DESC');
      let DifferencQuery = await CmsContent.getFreedom(
        'quizId,userName as label,userId as value,total as score,totalQue as total,createdAt as time,quizanswer as answerJson,prepost,posttotal',
        'tbl_quizAnswer',
        `id IN ( SELECT MAX(id) FROM tbl_quizAnswer where userId=${customerid} and serviceid=8 GROUP BY quizId ) `);

      let finalOut = [];
      DifferencQuery.data.map((ival, i) => {
        let v = this.getValueFromArray(ival.quizId, QuizList.data);
        finalOut.push({ quizname: v, total: ival.total, score: ival.score, prepost: ival.prepost ? "Pre & Post" : "Pre", postScore: ival.posttotal, time: this.getDate(ival.time) })
      });
      this.setState({ QuizList: QuizList.data, reportData: finalOut });
    } catch (error) {
      console.error(error);
    }
  }
  selectQuiz = async selectQuiz => {
    try {
      let customerid = this.state.customerid;
      let DetailedList = await CmsContent.getFreedom(
        'tbl_user_web.userName as label,tbl_quizAnswer.userId as value,tbl_quizAnswer.total as score,tbl_quizAnswer.totalQue as total,tbl_quizAnswer.createdAt as time,tbl_quizAnswer.quizanswer as answerJson,tbl_quizAnswer.prepost,tbl_quizAnswer.posttotal',
        'tbl_quizAnswer,tbl_user_web',
        `tbl_user_web.id=tbl_quizAnswer.userId and tbl_quizAnswer.quizId=${selectQuiz.value} and tbl_quizAnswer.userId=${customerid} and tbl_quizAnswer.serviceid=8`,
        'tbl_quizAnswer.id',
        'tbl_quizAnswer.createdAt DESC,tbl_quizAnswer.id DESC');
      this.resetState();
      this.setState({ selectQuiz, DetailedList: DetailedList.data });
    } catch (error) {
      console.error(error);
    }
  };
  resetState = () => {
    this.setState({ page2: 1 })
  }
  column = [
    {
      Header: 'No of questions',
      accessor: 'total',
      Cell: d => this.firstFunction(d.original, d.original.total)
    },
    {
      Header: 'Pre Score',
      accessor: 'score',
      Cell: d => this.firstFunction(d.original, d.original.score)
    },
    {
      Header: 'Post Score',
      accessor: 'posttotal',
      Cell: d => this.firstFunction(d.original, d.original.posttotal)
    },
    {
      Header: 'Pre/Post Evaluation',
      accessor: 'prepost',
      Cell: d => d.original.prepost ? "Pre & Post" : "Pre"
    },
    {
      Header: 'Attended on',
      accessor: 'time',
      Cell: d => this.firstFunction(d.original, d.original.time, true)
    },
    {
      Header: 'Certificate',
      accessor: 'certificate',
      Cell: d => this.deside(d)
    }
  ];
  column1 = [
    {
      Header: 'Question',
      accessor: 'question',
    },
    {
      Header: 'Response',
      accessor: 'answer'
    },
    {
      Header: 'Answer',
      accessor: 'correct',
    },
    {
      Header: 'Result',
      accessor: 'result',
    }
  ];
  column2 = [
    {
      Header: 'Evaluation Name',
      accessor: 'quizname',
    },
    {
      Header: 'No of questions',
      accessor: 'total'
    },
    {
      Header: 'Pre-Score',
      accessor: 'score',
    },
    {
      Header: 'Post-Score',
      accessor: 'postScore',
    },
    {
      Header: 'Pre/Post',
      accessor: 'prepost',
    },
    {
      Header: 'Attended on',
      accessor: 'time',
    }
  ];
  deside = d => {
    let Preval = d.original.score * 100 / d.original.total;
    let Postval = d.original.posttotal * 100 / d.original.total;
    if ((Preval >= 60) || (Postval >= 60)) {
      return (this.dataTableButton('success', 'View', () => {
        this.ViewCertificate(d.original);
      }))
    } else {
      return <center>Incomplete</center>
    }
  };
  ViewCertificate = d => {
    window.open(`/CmsCertificate/${btoa(this.state.selectQuiz.label)}/${btoa(d.label)}/${btoa(d.time)}/${btoa(d.score * 100 / d.total)}/${btoa(d.posttotal * 100 / d.total)}`, "_blank");
  }
  firstFunction = (d, value, val = false) => {
    value = val ? this.getDate(value) : value;
    return value;
    // return <a onClick={() => { this.doFunction(d) }}>{value}</a>
  }
  doFunction = (d) => {
    let answer = JSON.parse(d.answerJson)
    let question = JSON.parse(this.state.selectQuiz.contentJson);
    let finalSingleValue = [];
    question.map((ival, i) => {
      if (ival.type === 'multiple') {
        let ans1 = [];
        answer[i].answer.map((jval, j) => {
          let ans = jval.split('option').pop();
          ans1.push(ival.options[ans - 1]);
        });
        let actualAnswer = [];
        ival.answer.split(',').map((jval, j) => {
          let cA = jval.split('option').pop();
          actualAnswer.push(ival.options[cA - 1]);
        });
        finalSingleValue.push({ question: ival.question, answer: ans1.toString().toLowerCase(), correct: actualAnswer.toString().toLowerCase(), result: ans1.toString().toLowerCase() === actualAnswer.toString().toLowerCase() ? 'Correct' : "Wrong" });
      } else {
        finalSingleValue.push({ question: ival.question, answer: answer[i].answer.toLowerCase(), correct: ival.answer.toLowerCase(), result: answer[i].answer.toLowerCase() == ival.answer.toLowerCase() ? 'Correct' : "Wrong" })
      }
    });
    this.setState({ finalSingleValue, page2: 2, userName: d.label, prePost: d.prepost ? 'Pre' : 'Post', date: this.getDate(d.time) });
  }
  OnBack = val => {
    if (val === 3) {
      this.setState({ page2: 0 })
    } else {
      this.setState({ page2: val - 1 })
    }
  }
  PrintFunction() {
    this.setState({ page2: 3 })
  }
  render() {
    const { QuizList, page2, DetailedList, finalSingleValue, userName, prePost, date, reportData } = this.state;
    if (page2 === 1) {
      var tableData = DetailedList;

      var column = this.column;
    } else if (page2 === 2) {
      var tableData = finalSingleValue;
      var column = this.column1;
    } else if (page2 === 3) {
      var tableData = reportData;
      var column = this.column2;
    }
    let len = DetailedList && DetailedList.length ? DetailedList.length : 0;
    return (
      <React.Fragment>
        <div className="container-fluid container-margin-top" ref={this.myRef}>
          <div className="card">
            <h2 className="theme-tiile" style={{ marginTop: 15 }}>
              Evaluation
            </h2>
            <div className="row form-group">
              <div className="col-12">
                <div className="card-body" style={{ minHeight: '300px' }}>
                  {page2 !== 3 &&
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" style={{ textAlign: 'end' }} >
                        <label htmlFor="exampleInputEmail1" style={{ marginTop: '.5rem',marginLeft:'-3px' }}>Your Evaluation Score for</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect options={QuizList} handleChange={this.selectQuiz} value={this.state.box} placeholder="Select Quiz" />
                      </div>
                      <div className="col-sm-3" >
                        {this.dataTableButton('primary', 'Consolidated Evaluation Report ', () => { this.PrintFunction(); })}
                      </div>
                    </div>
                  }
                  {page2 === 1 && `Number of Attempts: ${len}`}
                  <div className="row form-group ml-0 mb-0">
                    <div className="col-sm-1 p-0" >
                      {page2 >= 2 && this.dataTableButton('danger', 'Back', () => {
                        this.OnBack(page2);
                      })}
                    </div>
                    <div className="col-sm-11 h2">
                      <center>
                        {page2 === 2 && `${userName}'s ${prePost} response on ${date}`}
                      </center>
                    </div>
                  </div>
                  {tableData && <Datatable data={tableData} columnHeading={column} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserEvaluation;