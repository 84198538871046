import React, { Component } from "react";
import Device from "react-device";
import AdminRoutes from "./Routes/AdminRoutes";
import CmsContent from "./MiddleWare/CmsContent";

// var browserName = navigator.appName;
// var nAgt = navigator.userAgent;
// var nameOffset, verOffset, ix;

// if ((verOffset = nAgt.indexOf("OPR/")) != -1) {
//   // Opera
//   browserName = "Opera";
// } else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
//   //Microsoft Internet Explorer
//   browserName = "Microsoft Internet Explorer";
// } else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
//   // Chrome
//   browserName = "Chrome";
// } else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
//   // Safari
//   browserName = "Safari";
// } else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
//   // Firefox
//   browserName = "Firefox";
// } else if (
//   (nameOffset = nAgt.lastIndexOf(" ") + 1) < (verOffset = nAgt.lastIndexOf("/"))
// ) {
//   browserName = nAgt.substring(nameOffset, verOffset);
//   if (browserName.toLowerCase() == browserName.toUpperCase()) {
//     browserName = navigator.appName;
//   }
// } else if (
//   window.orientation !== "undefined" ||
//   navigator.userAgent.indexOf("IEMobile") !== -1
// ) {
//   alert(navigator.userAgent);
// }
// localStorage.setItem("browser", browserName);

const onChange = deviceInfo => {
  try {

    if (deviceInfo.device.vendor === undefined) {
      localStorage.setItem("device", "Desktop");
    } else {
      localStorage.setItem("device", deviceInfo.device.vendor);
    }
    localStorage.setItem("browser", deviceInfo.browser.name);
    localStorage.setItem("os", deviceInfo.os.name);

    let Info = {
      width: deviceInfo.screen.width,
      height: deviceInfo.screen.height,
      browser: deviceInfo.browser.name,
      engine: deviceInfo.engine.name,
      os: deviceInfo.os.name,
      device: deviceInfo.device.vendor ? deviceInfo.device.vendor : 'Desktop'
    }
    let dasd = Info ? JSON.stringify(Info) : '';
    localStorage.setItem("info", dasd);
  } catch (error) {
    console.log(error);
  }
};
class App extends Component {
  async componentWillMount() {
    // let ips = await CmsContent.getIP();
    // if (ips) {
    //   localStorage.setItem("IP", ips.data.ip);
    //   localStorage.setItem("IPTime", ips.data.time);
    // }
  }
  render() {
    return (
      <React.Fragment>
        <Device onChange={onChange} />
        <AdminRoutes />
      </React.Fragment>
    );
  }
}

export default App;
