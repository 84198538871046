import React from "react";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import { Redirect } from "react-router-dom";
import { ACCESS_POINT } from "../../../config";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';



class DifuzaNextUser extends FormMiddleWare {
    constructor(props) {
      super(props);
      this.state = {
       type : this.props.location.state.type,
       data : this.props.location.state.data,
       data1:this.props.location.state.data1,
       content:[],
      };
    }
    
async componentDidMount (){
   
   try{
    let type=this.state.type;
    let data=this.state.data;
    let data1=this.state.data1;
    this.process(type,data,data1);
   }catch(error){
       console.log(error);
   }
}
process=(type,data,data1)=>{
    let content=[];
    if(type===1){
      let title=data1.title;
        let name=data.name;
        let url=data.url;
        let text=data.text;
        let description=data.description;
      //  console.log(title);

        content.push(  
            
            <div class="container" style={{marginTop:'13px'}}>  
                <h1 style={{marginLeft:'23%',textAlign:'center'}}>{title}</h1>
            <div class="card-body" style={{width:'124%',backgroundColor: 'azure',borderRadius:'21px',marginLeft:'16px'}}>

            <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${url}`} style={{  marginTop:'-3px',
          marginLeft:'0%',
          borderRadius: '10px',
          height: '507px',
          marginBottom:'26px',
          width:'100%'}}/>
            <h3>{text}</h3>
            <p>{description}</p>
           </div>
            </div>);
       
    }else if(type===2){
        let name=data.name;
        let url=data.url;
        let title=data1.title;
        let text=data.text;
        let description=data.description;
      

        content.push(
          <div class="container" style={{marginTop:'13px'}}>  
            <div className="card-body" style={{width:'134%',backgroundColor: 'azure',borderRadius:'21px',marginLeft:'16px'}}>
            <h3 style={{textAlign:'center'}}>{name}</h3>
            <video
            width="100%"
            controls
            style={{ marginTop: 8, width: "100%" }}
            >
            <source
                src={`${ACCESS_POINT}/superAdmin/file?fileurl=${url}`}
                type="video/mp4"
            />
            Your browser does not support HTML5 video.
            </video>
            <p>{text}</p>
            <p style={{marginTop:'13px'}}>{description}</p>
            </div>
            </div>
        )

    }else if(type===4){
      let name=data.name;
          let title="";
          let des="";
          des=data.description;
          title=data.title;
          let url=data.url;
           
            data.resource.map((item,i)=>{
                
                if(item.extension==='pdf'){
                     content.push(
                        <div class="container" style={{marginTop:'13px'}}>
                        <div class="card-body" style={{width:'124%',backgroundColor: 'azure',borderRadius:'21px',marginLeft:'16px'}}>
                        
                  <nav><a href={item.url}> <h3>{item.title}</h3></a></nav>     
                        <p>{des}</p>
                       </div>
                        </div>

                     )

                }
                if(item.extension!=='pdf'){
                content.push(  
                    <div class="container" style={{marginTop:'13px'}}>
                       <h1 style={{marginLeft:'23%',textAlign:'center'}}>{name}</h1>
                    <div class="card-body" style={{width:'124%',backgroundColor: 'azure',borderRadius:'21px',marginLeft:'16px'}}>
                    <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${url}`} style={{  marginTop:'-3px',
                  marginLeft:'0%',
                  borderRadius: '10px',
                  height: '507px',
                  marginBottom:'26px',
                  width:'100%'}}/>
                    <h3>{item.title}</h3>
                    <p>{des}</p>
                   </div>
                    </div>);
                }
            })
 }else  if(type===5){
     console.log(data);
    let name=data.name;
    let url=data.url;
    let text=data.text;
    let description=data.description;

    content.push(  
        <div class="container" style={{marginTop:'13px'}}>
        <div class="card-body" style={{width:'124%',backgroundColor: 'azure',borderRadius:'21px',marginLeft:'16px'}}>
        
     <h3>{name}</h3>   
        <p>{text}</p>
        <p>{description}</p>
       </div>
        </div>)
 } 
 else  if(type===6){
    console.log(data);
   let name=data.name;
   let pname=data.programName;
   let batch=data.batch;
   let date=data.fromdate+" "+"to"+""+data.todate;
   let location=data.location;
   let url=data.thumbnail;
   let text=data.text;
   let description=data.description;

   content.push(  
    <div class="container" style={{marginTop:'13px'}}>  
    <h3 style={{marginBottom:'9px',marginLeft:'23%',textAlign:'center'}}>{name}</h3>
    <div class="card-body" style={{width:'124%',backgroundColor: 'azure',borderRadius:'21px',marginLeft:'16px'}}>
        
    <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${url}`} style={{  marginTop:'-3px',
  marginLeft:'0%',
  borderRadius: '10px',
  height: '507px',
  marginBottom:'26px',
  width:'100%'}}/>
    <h3>{pname}</h3>
    <p>{batch}</p>
    <p>{date}</p>
    <p>{location}</p>

    <p>{description}</p>
   </div>
    </div>);

}

    this.setState({content:content})
}
    

backtohome=()=>{
    console.log(56)
    return(
       window.location.href="/difuzaUser"
    )
}

render(){

const{content}=this.state;
return(
<React.Fragment>
    <main className="main my-4">
      <div className="container-fluid"> 
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h1>DIFUZA_USER</h1>
                <button type="button" onClick={()=>this.backtohome()}>BACK</button>
              </div>
              <div className="card-body" style={{backgroundColor:'rgb(211, 211, 211)'}} >
               <div style={{width:"70%",marginLeft:"20px",marginBottom:"8px"}} className='my-own-custom-container'>

                {content }
                <br/><br/>
                
             
                 </div>

                 </div>
               </div>
          </div>
        </div>
      </div>
    </main>
  </React.Fragment>
      );
   }
}

export default DifuzaNextUser ;