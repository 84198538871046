import React, { Component } from "react";
import cmsContent from "../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import { Alert } from "reactstrap";
import Datatable from "../../../components/Datatable/Datatable";

class Survey extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      send_json: [],
      optionCount: "",
      optionJson: [],
      optionListArray: [],
      optionJsonArray: [],
      customerId: localStorage.getItem("userId"),
      data1: [],
      alertVisible: false
    };
  }

  async componentWillMount() {
    try {
      const question = await cmsContent.getTwoConditionedValue(
        "tbl_survey",
        "customerid",
        this.state.customerId,
        1,
        1
      );
      if (question) {
        this.setState({ data1: question.data });
      }
      const survey = await cmsContent.getTwoConditionedValue(
        "tbl_survey",
        "customerid",
        this.state.customerId,
        1,
        1,
        "id as value , surveyname as label"
      );

      const score = await cmsContent.getTwoConditionedValue(
        "tbl_surveyQuestion",
        "companyid",
        this.state.customerId,
        1,
        1
      );
      // if (score) {
      await this.setState({ data4: score.data, survey: survey.data });
      // }
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      Header: "Questions",
      accessor: "questions",

    },
    {
      Header: "Options(Marks)",
      accessor: "options",
      Cell: (e) => this.getmarks(e.original.options, e.original.marks)
    },
    // {
    //   Header: "Mark",
    //   accessor: "marks",
    // },


  ];
  getmarks = (a, b) => {

    let name = a.split(",")
    let mark = b.split(",")

    // console.log(name.length, name, mark.length);
    let markswithname = []
    for (let index = 0; index < name.length; index++) {
      markswithname.push(name[index] + '(' + mark[index] + ')')
    }
    // console.log(markswithname);
    return (
      <div>
        {markswithname && markswithname.map((ival, i) => {
          return ival + " "
        })

        }
      </div>
    )
  }
  question = ad => {
    let value = JSON.parse(ad.original.contentJson);
    return value[0].question;
  };

  answer = ad => {
    let value = JSON.parse(ad.original.contentJson);
    return value[0].answer;
  };

  option = ad => {
    let value = JSON.parse(ad.original.contentJson);
    return value[0].answer;
  };

  handlechange = e => {
    if (e.target.name === "optionCount") {
      this.setState({ optionJson: [] });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  JSON_refill = (quiz_value, quiz_name, index) => {
    let send_json = this.state.send_json;
    if (index + 1) {
      var JSON_data = send_json[index];
      JSON_data[quiz_name] = quiz_value;
      send_json[index] = JSON_data;
    }
    this.setState({ send_json: send_json });
  };

  appendOption = (value, key, index) => {
    let send_json = this.state.send_json;
    if (index + 1) {
      var JSON_data = send_json[index];
      JSON_data["options"][key] = value;
      send_json[index] = JSON_data;
    }
    send_json[index].count = send_json[index].options.length;
    this.setState({ send_json: send_json });
  };

  mark = (value, key, index) => {
    let send_json = this.state.send_json;
    if (index + 1) {
      var JSON_data = send_json[index];
      JSON_data["mark"][key] = value;
      send_json[index] = JSON_data;
    }
    send_json[index].count = send_json[index].options.length;
    this.setState({ send_json: send_json });
  };

  printOptions = index => {
    const { optionJson, optionListArray, optionJsonArray } = this.state;
    this.setState({ optionJson: [] });
    let total = optionListArray[index].value;
    for (let i = 0; i < total; i++) {
      optionJson.push({ option: null });
    }
    optionJsonArray.splice(index, 0, optionJson);
    this.setState({ optionJsonArray });
  };

  optionAnswer = (value, key, index) => {
    let send_json = this.state.send_json;
    if (index + 1) {
      var JSON_data = send_json[index];

      if (JSON_data["answer"]) {
        let oldAnswer = JSON_data["answer"];
        let newValue = `${oldAnswer},${value}`;
        JSON_data["answer"] = newValue;
      } else {
        JSON_data["answer"] = value;
      }
    }
  };

  submitQuiz = async () => {
    const { send_json, quizName, customerId } = this.state;

    let question = [];

    let insert = [];
    send_json.map((item, index) => {
      let mark = [];
      let options = [];
      question.push(item.question);
      item.mark.map((ival, i) => {
        mark.push(ival);
      });
      item.options.map((jval, j) => {
        options.push(jval);
      });
      let body2 = {};
      body2.questions = item.question;
      body2.options = options.toString();
      body2.marks = mark.toString();
      body2.companyid = customerId;
      insert.push(body2);
    });

    let jsonContent = {};
    jsonContent.surveyname = quizName;
    jsonContent.customerid = this.state.customerId;
    jsonContent.recordlimit = this.state.limit;
    try {
      const result = await cmsContent.addSurveyQuestion(insert);
      if (result) {
        this.setState({
          quizName: "",
          send_json: [],
          limit: "",
          alertVisible: true
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  addSingleChoice = type => {
    const { send_json } = this.state;
    send_json.push({
      question: null,
      answer: null,
      type,
      options: [],
      mark: [],
      count: null
    });

    const limit = this.state.limit;
    let pageCount = 0;
    if (send_json.length > limit) {
      pageCount = send_json.length / limit;
    } else {
      pageCount = 1;
    }
    this.setState({ send_json, pageCount });
  };

  removetype = async (index, type) => {
    if (type == "text") {
      this.setState({ alertVisible: true, error: "Text Field Removed" });
      setTimeout(() => {
        this.setState({ alertVisible: false });
      }, 1000);
    }

    // if (type == "radio") {
    //   this.setState({ alertVisible: true, error: "Radio Field Removed" });
    //   setTimeout(() => {
    //     this.setState({ alertVisible: false });
    //   }, 1000);
    // }

    const surveyJson = this.state.surveyJson;
    await surveyJson.splice(index, 1);
    setTimeout(async () => {
      await this.setState({ surveyJson });
    }, 1000);
    this.formbuild();
  };

  removeQuiz = index => {
    const { send_json, optionJsonArray } = this.state;
    send_json.splice(index, 1);
    optionJsonArray.splice(index, 1);
    this.setState({ send_json: [], optionJsonArray: [] });
    this.setState({ send_json, optionJsonArray });
  };

  score = async (e, name, index, option) => {
    await this.setState({ score: e, label: name });
    let score = this.state.score;
    let label = this.state.label;
    let scorelist = [];
    scorelist.push({ label: label, score: score });
    this.state.send_json.map((item, index) => {
      item.options.push(scorelist);
    });
  };

  handlePhoneCount = (value, index) => {
    const { optionListArray } = this.state;
    for (let i = 0; i <= index; i++) {
      if (i === index) {
        optionListArray.splice(index, 1);
        let optionObject = {};
        optionObject.index = index;
        optionObject.value = value;
        optionListArray.splice(index, 0, optionObject);
      } else {
        let optionObject = {};
        optionObject.index = null;
        optionObject.value = null;
        optionListArray.splice(index, 0, optionObject);

        // if (!optionListArray[index].value) {

        // }
      }
    }
    this.setState({ optionListArray });
  };
  limit = e => {
    this.setState({ limit: e.target.value });
  };
  bacfun = () => {
    this.setState({ show: false });
  };
  render() {
    const {
      send_json,
      quizName,
      alertVisible,
      optionCount,
      optionJson,
      optionListArray,
      optionJsonArray,
      data1,
      question,
      dataListArray,
      limit,
      data4,
      error
    } = this.state;
    let quizViewlist = [];

    send_json.map((quizList, index) => {
      if (quizList.type === "single") {
        quizViewlist.push(
          <div className="row form-group question-margin">
            <div className="col-sm-2">Question {index + 1}</div>
            <div className="col-sm-7">
              <textarea
                type="text"
                class="form-control"
                name={`question${index}`}
                placeholder="Enter Question"
                onChange={e => {
                  this.JSON_refill(e.target.value, "question", index);
                }}
                value={quizList.question}
              />
            </div>
            <div className="col-sm-2">
              <div className="row form-group">
                <div className="col-sm-12">Answer {index + 1}</div>
              </div>
              <div className="row form-group">
                <div className="col-sm-6 form-check">
                  {/* <input
                    className="form-check-input"
                    type=""
                    name={`answer${index}`}
                    onChange={e => {
                      this.JSON_refill(e.target.value, "answer", index);
                    }}
                  /> */}
                  <label className="form-check-label" for="answer">
                    Yes
                  </label>
                </div>
                <div className="col-sm-6 form-check">
                  {/* <input
                    className="form-check-input"
                    type="radio"
                    name={`answer${index}`}
                    onChange={e => {
                      this.JSON_refill(e.target.value, "answer", index);
                    }}
                  /> */}
                  <label className="form-check-label" for="answer">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="col-sm-1">
              <i
                className="fa fa-window-close fa-3x"
                aria-hidden="true"
                onClick={() => this.removeQuiz(index)}
              />
            </div>
          </div>
        );
      } else {
        let optionsAppend = [];
        optionJsonArray.map((totalOption, index1) => {
          if (index1 === index) {
            totalOption.map((val, key) =>
              optionsAppend.push(
                <div className="col-sm-5">
                  <div className="row form-group">
                    <div className="col-sm-6">Option {key + 1}</div>
                    <div className="col-sm-4">mark {key + 1}</div>
                  </div>
                  <div className="row form-group">
                    <div className="col-sm-5">
                      {/* <input
                        className="form-check-input"
                        type="radio"
                        id="inlineradio1"
                        name="inlineradio1"
                        onChange={e =>
                          this.optionAnswer(e.target.value, key, index1)
                        }
                        value={`option${key + 1}`}
                      /> */}
                      <input
                        type="text"
                        class="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder={`option ${key + 1}`}
                        onChange={e =>
                          this.appendOption(e.target.value, key, index1)
                        }
                        value={quizList.options[key]}
                      />
                    </div>

                    <div className="col-sm-5">
                      <input
                        type="number"
                        class="form-control"
                        onChange={e => this.mark(e.target.value, key, index1)}
                        value={quizList.mark[key]}
                      />
                    </div>
                    <div className="col-sm-2"></div>
                  </div>
                </div>
              )
            );
          }
        });

        quizViewlist.push(
          <div>
            <div className="row form-group question-margin">
              <div className="col-sm-2">Question {index + 1}</div>
              <div className="col-sm-7">
                <textarea
                  type="text"
                  class="form-control"
                  name="question"
                  placeholder="Enter Question"
                  onChange={e => {
                    this.JSON_refill(e.target.value, "question", index);
                  }}
                  value={quizList.question}
                />
              </div>
              <div className="col-sm-2">
                <div className="row form-group">
                  <div className="col-sm-8">
                    <div className="row form-group">
                      <input
                        type="number"
                        class="form-control"
                        name="optionCount"
                        placeholder="number"
                        onChange={e =>
                          this.handlePhoneCount(e.target.value, index)
                        }
                        value={quizList.count}
                      />
                    </div>
                    <div className="row form-group">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={e => this.printOptions(index)}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-4" />
                </div>
              </div>
              <div className="col-sm-1">
                <i
                  className="fa fa-window-close fa-3x"
                  aria-hidden="true"
                  onClick={() => this.removeQuiz(index)}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-2"> </div>
              <div className="col-sm-7">
                <div className="row form-group">{optionsAppend}</div>
              </div>
              <div className="col-sm-2"> </div>
            </div>
          </div>
        );
      }
    });

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h1>Survey Question</h1>
              </div>
              <div className="card-body" />
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-7">
                  <h2>Survey</h2>
                  <Alert
                    className="badge-content"
                    color="success"
                    isOpen={alertVisible}
                    toggle={this.onDismiss}
                  >
                    Question Added Successfully
                  </Alert>
                </div>
                <div className="col-sm-3" />
              </div>
              {/* <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label for="exampleInputEmail1">Survey Name</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    class="form-control"
                    name="quizName"
                    placeholder="Enter Survey Name"
                    onChange={this.handlechange}
                    value={quizName}
                  />
                  <span className="error-shows">{error}</span>
                </div>
                <div className="col-sm-3" />
              </div> */}
              {/* <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label for="exampleInputEmail1">
                    Question Limit per Page
                  </label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Enter Limit"
                    onChange={this.limit}
                    value={limit}
                  />
                  <span className="error-shows">{error}</span>
                </div>
                <div className="col-sm-3" />
              </div> */}

              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  {/* <button
                    type="button"
                    className="btn btn-primary btn-lg btn-block btn-radius"
                    onClick={() => this.addSingleChoice("multiple")}
                  >
                    Add Multi Choice
                  </button> */}
                </div>
                <div className="col-sm-4">
                  <button
                    type="button"
                    className="btn btn-primary btn-lg btn-block btn-radius"
                    onClick={() => this.addSingleChoice("multiple")}
                  >
                    Add Questions
                  </button>
                </div>
                <div className="col-sm-2" />
              </div>
            </div>
            {/* multi choice */}
            {this.state.show && (
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label for="exampleInputEmail1">Title</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    class="form-control"
                    name="quizName"
                    placeholder="Enter Title"
                    onClick={this.bacfun}
                  />
                  <span className="error-shows">{error}</span>
                </div>
                <div className="col-sm-3" />
              </div>
            )}
            {this.state.show && (
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label for="exampleInputEmail1">Description</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Enter description"
                    onClick={this.bacfun}
                  // onClick={() => this.addSingleChoice("multiple")}
                  />
                  <span className="error-shows">{error}</span>
                </div>
                <div className="col-sm-3" />
              </div>
            )}
            {quizViewlist.length > 0 && (
              <div className="row form-group">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      {quizViewlist}
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-3">
                          <button
                            type="button"
                            className="btn btn-success btn-lg btn-radius"
                            onClick={this.submitQuiz}
                          >
                            Submit
                          </button>
                        </div>
                        <div className="col-sm-7" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="row form-group">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="col-sm-12">
                      {data4 && (
                        <Datatable data={data4} columnHeading={this.column} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default Survey;
