
import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import cmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import Addauthormodel from "./Addauthormodel";
import { Alert } from "reactstrap";
import { ACCESS_SAMPLE } from "../../../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import AddpagePDF from "../../../components/PDF/Addpagespdf";
import { MultiSelect } from "../../../components/Form";
//import Theme9 from './addPages/theme9';
import Datatable from "../../../components/Datatable/Datatable";
import { FormMiddleWare } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";

class ClusterMapping extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            authorlist: [],
            checked: 0,
            customerId: localStorage.getItem("userId"),
            errorPageName: null,
            data: [],
            MOlist: [],
            Checked: false,
            Courselist: [],
            alertVisible1: false,
            btnDisable: false,
            formAlert: false,
            formBtn: false,
            themeSelected: "",
            screen: window.screen.height,
            danger: "btn btn-danger",
            success: "btn btn-success",
            home: "Home",
            set: "SetusHome",
            Courselist: [],
            loopvideo: false,
            clusterlock: false,
        };
    }
    column = [
        {
            Header: "CLUSTER",
            accessor: "clusterName"
            // accessor: "clusterId",
            // Cell: d => this.getValueFromArray(d.original.clusterId, this.state.MOlist)
        },
        {
            Header: "COURSES",
            accessor: "courseName",

            // accessor: "courseId",
            // Cell: d => this.getStringFromArray(d.original.courseId, this.state.Courselist),

        },
        // {
        //     Header: "MO",
        //     accessor: "edit",
        //     Cell: (d) =>  this.getValueFromArray(d.original.moid,this.state.MOlist),
        // },
        {
            Header: "EDIT",
            accessor: "edit",
            Cell: (d) => this.Editpages(d),
        },
        {
            Header: "DELETE",
            Cell: (d) => this.buttondel(d),
        },


    ];





    getStringFromArray = (d, array) => {
        let str = '';
        if (array.length) {
            if (d) {
                JSON.parse(d).map((ival) => {
                    let val = this.getValueFromArray(ival, array)
                    if (val && val != '-') {
                        str += val + ','
                    }
                })
            }
        }
        return str
    }

    showVisibility = (id) => {
        return (
            <button
                type="button"
                className="btn btn-info"
                onClick={() => this.updateVisibility(id)}
            >
                Hide/Show
            </button>
        );
    };

    changeLoginStatus = (id) => {
        return (
            <button
                type="button"
                class="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '10px' }}
                onClick={() => this.updateLogin(id)}
            >
                Change Login Status
            </button>
        );
    };
    buttondel = (d) => {
        if (d.original.status == 0) {
            return (
                <button

                    className="btn btn-danger"
                    onClick={() => this.Delete(d)}
                >Delete</button>
            );
        } else {
            return (<span className="label">Deleted</span>)

        }
    }

    Delete = d => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <div style={{ textAlign: "center" }}>
                    <h1>Delete confirmation</h1>
                    <h4>Are you sure you want to Delete this ?</h4><br />
                    <button className="btn btn-primary" onClick={() => {
                        this.HandleDelete(d.original)
                        onClose();
                    }}  >Yes</button> &nbsp;  &nbsp;
                    <button className="btn btn-danger" onClick={onClose} >No</button>
                </div>
            }
        })
    };
    HandleDelete = async (d) => {
        try {
            let obj = {}
            obj.status = Date.now()
            const result = await cmsContent.updateMaster(
                "cmndl_mapping_cluster",
                d.id,
                obj
            )
            if (result) {
                this.MainFun();
            }
        } catch (error) {
            console.log(error);
        }
    }



    Editpages = (value) => {
        if (value.original.status == 0) {
            return (
                <button className="btn btn-info" onClick={() => this.onEdit(value.original)}>
                    Edit
                </button>
            );
        } else {
            return (<span className="label">Deleted</span>)
        }
    };
    getValueFromArray1 = (d, array, ch = "value", ch1 = "label") => {
        // console.log(d, "d");
        // console.log(array, "array");
        // console.log(ch, "value");
        // console.log(ch1,"ch1");
        if (array.length > 0) {
            if (array.length !== 0) {
                let filtered = array.filter(function (item) {
                    return item[ch] == d;
                });
                let v = filtered[0];
                if (v != undefined) {
                    return v;
                } else {
                    return '-';
                }
            }
        } else {
            return '-';
        }
    };
    onEdit = (value) => {
        let d = value;
        let course = []
        let loopvideo = false
        let clusterlock = false
        if (d.clusterId) {
            let MO = this.getValueFromArray1(d.clusterId, this.state.MOlist)
            this.setState({ MO })
        }
        if (d.courseId) {
            JSON.parse(d.courseId).map((ival) => {
                this.state.Courselist.map((mval) => {
                    if (ival == mval.value) {
                        course.push(mval)
                    }
                })
            });
        }
        let User = []
        if (d.userType) {
            JSON.parse(d.userType).map((ival) => {
                this.state.UserOption.map((mval) => {
                    if (ival == mval.value) {
                        User.push(mval)
                    }
                })
            });
        }

        let empId = JSON.parse(d.Employee_id)
        let removeDuplicateId = [...new Set(empId)];

        let SelectedEmployee = []
        if (d.Employee_id) {
            removeDuplicateId.map(async (ival) => {
                // await 
                this.state.EmpOption.map(async (mval) => {
                    if (ival && ival == mval.value) {
                        // await 
                        SelectedEmployee.push(mval)
                    }
                })
            });
        }
        if (d.loop_video) {
            if (d.loop_video == 1) {
                loopvideo = true
            }
        }
        if (d.cluster_lock) {
            if (d.cluster_lock == 1) {
                clusterlock = true
            }
        }
        let Rules = this.state.Ruleslist.filter(ival => parseInt(ival.value) == parseInt(d.Rules))
        let Rules1 = this.state.Ruleslist.filter(ival => parseInt(ival.value) == parseInt(d.Dealer_Rules))
        // await 
        this.setState({
            editId: d.id,
            isEdit: true,
            course,
            SelectedEmp: d.Employee_id ? JSON.parse(d.Employee_id) : [],
            SelectedEmployee, User, loopvideo, clusterlock,
            Rulesvalue: Rules.length > 0 ? Rules[0] : null,
            Rulesvalue1: Rules1.length > 0 ? Rules1[0] : null
        });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    async componentDidMount() {
        this.MainFun()
    }
    MainFun = async () => {
        try {
            this.setState({ loading: true })
            const User = await cmsContent.getFreedom(
                "id as value,usertype as label",
                "tbl_UserType",
                `customerid = ${this.state.customerId} and serviceid = 8`,
                "id",
                "id DESC"
            );
            if (User) {
                this.setState({ UserOption: User.data });
            }
            const Employee = await cmsContent.getFreedom(
                "employee_id as value,employee_name as label",
                "cmndl_tbl_Employee",
                `customerid=${this.state.customerId}`,
                "id",
                "id DESC"
            );
            if (Employee) {
                this.setState({ EmpOption: Employee.data });
            }
            const MO = await cmsContent.getFreedom(
                "name as label,id as value",
                "cmndl_tbl_cluster",
                ` customerid=${this.state.customerId}`,
                "id",
                "id DESC"
            );
            if (MO) {
                this.setState({ MOlist: MO.data });
            }
            const Courselist = await cmsContent.getFreedom(
                "tbl_pages.name as label,tbl_pages.id as value",
                "tbl_pages,tbl_mapping_page",
                `tbl_pages.customerId=${this.state.customerId} and tbl_pages.themeId=3 and tbl_pages.id=tbl_mapping_page.pageId`,
                "tbl_pages.id",
                "tbl_pages.id DESC"
            );
            if (Courselist) {
                this.setState({ Courselist: Courselist.data });
            }
            const sales = await cmsContent.getFreedom(
                "*",
                "cmndl_mapping_cluster",
                `status=0 and  customerid=${this.state.customerId}`,
                "id",
                "id DESC"
            );
            console.log(sales, 'sales data');
            if (sales && sales.data.length > 0) {
                sales.data.map((ival) => {
                    ival.courseId = JSON.parse(ival.courseId);
                    let string = '';
                    ival.courseId.map((jval) => {
                        let val = this.getValueFromArray(jval, this.state.Courselist)
                        if (val && val != '-') {
                            string += val + ', '
                        }
                    })
                    ival.courseName = string
                    ival.courseId = JSON.stringify(ival.courseId)

                })
                await this.setState({ data: sales.data });
            }
            const { data: Ruleslist } = await cmsContent.getFreedom(
                'id as value ,Name as label', "cmndl_tbl_rules", `status='active'`, 1, 1
            )
            if (Ruleslist) {
                await this.setState({ Ruleslist })
            }
            this.setState({ loading: false })
        } catch (error) {
            console.log(error);
        }
    }

    handlechange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };



    submitCategory = async () => {
        const {
            Code, Checked,
            Name, errorName, errorCode, errorMO, MO, course, isEdit, editId, User, SelectedEmployee, csvUpdate,
            file, SelectedEmp, Rulesvalue, Rulesvalue1 } = this.state;
        if (!MO) {
            this.setState({
                errorMO: "select ",
            });
            return false;
        }
        else if (!course) {
            this.setState({ errorMO: "", errorCourse: "enter  course" });
            return false;
        }
        // else if (!User) {
        //     this.setState({ errorPageId: "", errorUserType: "Select User" });
        //     return false;
        // }

        let categoryArray = {}
        categoryArray.customerid = this.state.customerId;
        categoryArray.loop_video = this.state.loopvideo
        categoryArray.cluster_lock = this.state.clusterlock
        if (MO) {
            categoryArray.clusterId = MO.value;
            categoryArray.clusterName = MO.label;
        }
        if (course) {
            // categoryArray.courseId = course.value;       
            let check = []
            course.map((ival) => {
                if (ival.value) {
                    check.push(ival.value)
                }
            })
            categoryArray.courseId = JSON.stringify(check)
        }

        categoryArray.customerid = this.state.customerId
        if (User) {
            let check = []
            User.map((ival) => {
                if (ival.value) {
                    check.push(ival.value)
                }
            })
            categoryArray.userType = JSON.stringify(check)
        }
        if (SelectedEmployee) {
            let check = []
            SelectedEmployee.map((ival) => {
                if (ival.value) {
                    check.push(ival.value)
                }
            })
            categoryArray.Employee_id = JSON.stringify(check)
        }
        categoryArray.Rules = Rulesvalue ? Rulesvalue.value : null
        categoryArray.Dealer_Rules = Rulesvalue1 ? Rulesvalue1.value : null
        // categoryArray.Checked = Checked
        // console.log([...formData]);
        console.log('loast', categoryArray);


        try {
            this.setState({ btnDisable: true })
            let formData = new FormData()
            formData.append("mapData", JSON.stringify(categoryArray));
            file && formData.append("file", file);
            isEdit && formData.append("currentEmp", JSON.stringify(SelectedEmp));
            const result = isEdit ? await cmsContent.CmdlClusterMapCSV(
                editId,
                csvUpdate,
                formData
            ) : await cmsContent.CmdlClusterMapCSV(
                "id",
                csvUpdate,
                formData
            );
            console.log(result, 'resilt');
            if (result) {
                if (result.data.csvStatus == true) {
                    alert(`There is an error in the uploaded data empty spaces in  Employee_id : ${result.data.errorId}  please clear space and reupload this ID's`)
                }
                if (Checked) {
                    let CategoryArray = {}
                    CategoryArray.customerid = this.state.customerId
                    CategoryArray.editId = isEdit ? editId : result.data.insertId
                    CategoryArray.logid = result.data.logid
                    await localStorage.setItem('ClusterData', JSON.stringify(CategoryArray))
                    window.open('/Vidya/ClusterMapped')
                    this.reset()
                    this.MainFun()
                    // setTimeout(() => this.setState({ alertVisible: false }), 3000);
                    return true
                }
                this.setState({
                    Code: "",
                    Name: '', errorName: '', errorCode: '',
                    MO: '', errorMO: '', errorCourse: '',
                    course: '',
                    Sales: '', errorSales: '',
                    btnDisable: false,
                    alertVisible: true,
                    SelectedEmployee: null,
                    User: null,
                    Checked: false,
                    isEdit: null, loopvideo: false,
                    clusterlock: false,
                    csvUpdate: false,
                    file: null,
                    SelectedEmp: [],
                    Rulesvalue: '',
                    Rulesvalue1: ''
                });
                this.MainFun()
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    handleSelect = (name, selectedOption) => {
        this.setState({
            [name]: selectedOption,
            //   themeId: selectedOption.value,
            //   themeName: selectedOption.label,
        });

        if (name == 'MO') {
            let result = this.state.data.filter((a) => a.clusterId == selectedOption.value)
            // console.log(JSON.parse(result[0].Employee_id), 'Employee_id')
            if (result.length > 0) {
                this.onEdit(result[0])
            }
        }
    };
    handleCheck = (name, selectedOption) => {
        this.setState({
            [name]: selectedOption,
        });
        // console.log(this.state.User);

    }
    fileupload = e => {
        this.setState({ file: e.target.files[0] })
    }
    reset = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        this.setState({
            Code: "",
            Name: '', errorName: '', errorCode: '',
            MO: '', errorMO: '', errorCourse: '',
            course: '',
            Sales: '', errorSales: '',
            btnDisable: false,
            alertVisible: false,
            SelectedEmployee: null,
            SelectedEmp: [],
            User: null,
            Checked: false,
            isEdit: null, loopvideo: false,
            clusterlock: false,
            csvUpdate: false,
            file: null,
            SelectedEmp: [],
            Rulesvalue: '',
            Rulesvalue1: ''
        })
    }
    render() {
        const { MOlist, //cluster off,
            Courselist,
        } = this.state;
        console.log(MOlist, 'MOlist');
        console.log(this.state.MO, 'this.state.MO');
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Map Cluster to Course</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color="success"
                                                    isOpen={this.state.alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    Added Successfully
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Select Cluster</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    options={MOlist}
                                                    handleChange={(e) => this.handleSelect('MO', e)}
                                                    selectedService={this.state.MO}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.errorMO}
                                            </span>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Select Course</label>
                                            </div>
                                            <div className="col-sm-5">
                                                {/* <SingleSelect
                                                    options={Courselist}
                                                    handleChange={(e) => this.handleSelect('course', e)}
                                                    selectedService={this.state.course}
                                                /> */}
                                                <MultiSelect
                                                    options={Courselist}
                                                    handleChange={(e) => this.handleCheck('course', e)}
                                                    selectedService={this.state.course}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.errorCourse}
                                            </span>
                                        </div>


                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label for="exampleInputEmail1">Select User</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <MultiSelect
                                                    options={this.state.UserOption}
                                                    handleChange={(e) => this.handleCheck('User', e)}
                                                    selectedService={this.state.User}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">{this.state.errorUserType}</span>
                                        </div>

                                        {this.state.User && this.state.User.filter(e => e.label == 'EMPLOYEE').length ?
                                            (<div>
                                                <div className="row form-group">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label for="exampleInputEmail1">Select Employee</label>
                                                    </div>
                                                    {this.state.csvUpdate ?
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="customFile"
                                                                accept='.csv'
                                                                // value={this.state.csv}
                                                                onChange={this.fileupload}
                                                            />
                                                            <label
                                                                className="custom-file-label"
                                                                htmlFor="customFile"
                                                            >
                                                                {this.state.file ? this.state.file.name : 'Choose File'}
                                                            </label>
                                                        </div>
                                                        : <div className="col-sm-5">
                                                            <MultiSelect
                                                                options={this.state.EmpOption}
                                                                handleChange={(e) => this.handleCheck('SelectedEmployee', e)}
                                                                selectedService={this.state.SelectedEmployee}
                                                            />
                                                        </div>}
                                                    <div className="col-sm-3" >
                                                        <input
                                                            type="checkbox"
                                                            name="Checked"
                                                            onChange={() => this.setState({ csvUpdate: !this.state.csvUpdate, file: null })}
                                                            checked={this.state.csvUpdate}
                                                        />
                                                        <label>{" "} &nbsp; CSV Upload</label>
                                                    </div>
                                                </div>
                                                {this.state.csvUpdate ? <div className="row form-group">
                                                    <div className="col-sm-4" />
                                                    <div className="col-md-5">
                                                        <a href="../../files/Employee.csv" download="Employee.csv">
                                                            download sample.csv
                                                        </a>
                                                    </div>
                                                </div> : null}
                                                <div className="row form-group">
                                                    <div className="col-sm-4" />
                                                    <span className="error-show ">{this.state.errorEmp}</span>
                                                </div>
                                            </div>) : null
                                        }
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {(!this.state.isEdit || !this.state.csvUpdate) ? null : 'Note: Old Mapped Employees will be unmapped'}
                                            </span>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.csvUpdate ? 'MODE: CSV UPLOAD' : null}</span>
                                        </div>
                                        {this.state.User && this.state.User.filter(e => e.label == 'EMPLOYEE').length ?
                                            (<><div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label for="exampleInputEmail1">Select Employee Rules</label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <SingleSelect
                                                        handleChange={(e) => this.handleSelect('Rulesvalue', e)}
                                                        options={this.state.Ruleslist}
                                                        selectedService={this.state.Rulesvalue}
                                                    />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>
                                                <div className="row form-group">
                                                    <div className="col-sm-4" />
                                                    <span className="error-show ">{this.state.errorLang}</span>
                                                </div></>) : null}
                                        {this.state.User && this.state.User.filter(e => e.label == 'DEALER').length ?
                                            (<><div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label for="exampleInputEmail1">Select Dealer Rules</label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <SingleSelect
                                                        handleChange={(e) => this.handleSelect('Rulesvalue1', e)}
                                                        options={this.state.Ruleslist}
                                                        selectedService={this.state.Rulesvalue1}
                                                    />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>
                                                <div className="row form-group">
                                                    <div className="col-sm-4" />
                                                    <span className="error-show ">{this.state.errorLang1}</span>
                                                </div></>) : null}
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label for="exampleInputEmail1">Send mail to Mapped users</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="checkbox"
                                                    name="Checked"
                                                    onChange={() => this.setState({ Checked: !this.state.Checked })}
                                                    checked={this.state.Checked}
                                                />

                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label for="exampleInputEmail1">Loop this Cluster Videos</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="checkbox"
                                                    name="loopvideo"
                                                    onChange={() => this.setState({ loopvideo: !this.state.loopvideo })}
                                                    checked={this.state.loopvideo}
                                                />

                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label for="exampleInputEmail1">Lock this Cluster ?</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="checkbox"
                                                    name="clusterlock"
                                                    onChange={() => this.setState({ clusterlock: !this.state.clusterlock })}
                                                    checked={this.state.clusterlock}
                                                />

                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2" />
                                            <div className="col-sm-5">
                                                <button
                                                    type="button"
                                                    className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
                                                    disabled={this.state.btnDisable}
                                                    onClick={this.submitCategory}
                                                >
                                                    {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                                    {this.state.isEdit ? 'Update' : 'Submit'}
                                                </button>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row d-flex justify-content-end">
                                            <button
                                                type="button"
                                                className="btn btn-warning"
                                                onClick={this.reset}
                                            >Reset</button>
                                            <div className="col-sm-3" />
                                        </div>
                                        <br></br>
                                        <span>
                                            Total of {Object.keys(this.state.data).length} records
                                        </span>
                                        <br></br>
                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                {this.state.loading ? <div className="text-center">
                                                    < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                </div> : this.state.data ? (
                                                    <Datatable
                                                        data={this.state.data}
                                                        columnHeading={this.column}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default ClusterMapping;
