
import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import cmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import Addauthormodel from "./Addauthormodel";
import { Alert } from "reactstrap";
import { ACCESS_SAMPLE } from "../../../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import AddpagePDF from "../../../components/PDF/Addpagespdf";

//import Theme9 from './addPages/theme9';
import Datatable from "../../../components/Datatable/Datatable";
import { FormMiddleWare } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";
import CmsContent from "../../../MiddleWare/CmsContent";
import Paginationfun from "../pagination";
class AddEmployee extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            isEdit: false,
            customerId: localStorage.getItem("userId"),
            data: [],
            editId: null,
            userid: null,
            added: [],
            notadded: [],
            badgeColor: false,
            badgeText: false,
            emp_id: "",
            ansgender: "", ansBusinessUnit: null, ansFunction: "", ansSubFunction: "",
            ansRegion: "", ansDivision: "", ansBranch: "",
            ansoffEmailAdd: "", ansGrade: "", ansBand: "",
            ansEmpCatType: "", ansL1ManCode: "",
            ansL1Man: "", ansL2ManCode: "", ansL2Man: "", ansHRManCode: "", ansHRMan: "", ansBusiDesignation: "",
            errgender: "", errBusinessUnit: "", errFunction: "", errSubFunction: "", errRegion: "", errDivision: "", errBranch: "", erroffEmailAdd: "", errGrade: "", errBand: "", errEmpCatType: "", errL1ManCode: "", errL1ManCode: "",
            errL1Man: "", errL2ManCode: "", errL2Man: "", errHRManCode: "", errHRMan: "", errBusiDesignation: "",
            formAlertdelete: false,
            textalert: "",
            genderopt: [{ label: "MALE", value: 1 }, { label: "FEMALE", value: 2 }],
            // EmployeeCatopt: [{ label: "MS", value: 1 }, { label: "NMS", value: 2 }, { label: "NMS - Staff", value: 3 }, { label: "NMS - Worker", value: 4 }, { label: "MS-Liberty", value: 5 }],
            // Bandopt: [{ label: "Band-1", value: 1 }, { label: "Band-2", value: 2 }, { label: "Band-3", value: 3 }, { label: "NMS", value: 4 },],
            danger: "btn btn-danger",
            success: "btn btn-success",
            pageNumber: 1,
            SelectedEnquiries: [],
            businessunitopt: [],
            bulkAllocate: false,
            modalInput: "",
            showform: false,
        };
    }
    column = [
        {
            Header: "ID ",
            accessor: "id",
        },
        {
            Header: "EMPLOYEE ID",
            accessor: "employee_id",
        },
        {
            Header: "EMPLOYEE NAME",
            accessor: "employee_name",
        },
        {
            Header: "EMAIL",
            accessor: "email",
        },

        {
            Header: "EDIT",
            accessor: "edit",
            Cell: (d) => this.Editpages(d, 'customer'),
        },
        {
            Header: "STATUS",
            // accessor: "edit",
            Cell: (d) => this.buttondel(d),
        },
        {
            Header: "Delete",
            accessor: "edit",
            Cell: (d) => this.DatatableCheckboxx(d, this.updateMyData, this.state.SelectedEnquiries)
        }
    ];
    async componentDidMount() {
        this.MainFun()

        const togglePassword = document.querySelector('#togglePassword');
        const password = document.querySelector('#password');
        document.querySelector('#togglePassword').addEventListener('click', function (e) {
            this.classList.toggle('fa-eye-slash');
            const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
            password.setAttribute('type', type);
        });
    }
    DatatableCheckboxx = (d, func, SelectedEnquiries) => {
        // console.log(d.original.id, "SelectedEnquiries[d.original.id]");
        if (d.original.status == 0) {
            return (
                <div style={{ 'text-align': 'center' }}>
                    <input type="checkbox"
                        checked={SelectedEnquiries && SelectedEnquiries[d.original.id] &&
                            SelectedEnquiries[d.original.id] == true ? true : false}
                        onChange={(event) => func(parseInt(d.original.id), d.column.id, event.target.checked ? "Yes" : "No")} />
                </div>)
        } else {
            return (
                <></>
            )
        }
    };
    updateMyData = async (d, f, event) => {
        let { SelectedEnquiries } = this.state
        // console.log(d, "d", event, "event", f, "f");
        // console.log(SelectedEnquiries, "SelectedEnquiries");
        let AllocateType = "Delete"
        const index = SelectedEnquiries.indexOf(d)
        // console.log(index, "index");
        if (index > -1 && event == "No") {
            SelectedEnquiries.splice(index, 1)
            SelectedEnquiries[d] = false
            if (index == 0) {
                SelectedEnquiries.length = 0;
                this.setState({ bulkAllocate: false })

            }

        }
        else {
            SelectedEnquiries.push(d)
            SelectedEnquiries[d] = true
            this.setState({ bulkAllocate: true })
        }
        this.setState({ SelectedEnquiries, AllocateType })

    }
    selectalldata = async (event) => {
        // console.log(event, event);
        let { data } = this.state
        let SelectedEnquiries = []
        // console.log(SelectedEnquiries, "SelectedEnquiries");
        // console.log(data, "data");

        if (event == "Yes") {
            for (let index = 0; index < 100; index++) {
                const element = data[index];
                if (element.status == 0) {
                    SelectedEnquiries[element.id] = true
                    SelectedEnquiries.push(element.id)
                }
            }

            await this.setState({ isSelectUnallocated: true })
            // console.log(SelectedEnquiries.length, "SelectedEnquiries length");
        }

        else {
            await this.setState({ isSelectUnallocated: false })
        }
        await this.setState({ SelectedEnquiries })
    };
    confirmDeleteT = async (e) => {
        // console.log(e, "ee");
        let { SelectedEnquiries } = this.state
        // console.log(SelectedEnquiries, "SelectedEnquiries");
        if (SelectedEnquiries) {
            // const filteredData = Object.fromEntries(
            //     Object.entries(SelectedEnquiries).filter(([key, value]) => value === true)
            // );

            const keysWithTrueValues = Object.keys(SelectedEnquiries)
                .filter(key => SelectedEnquiries[key] === true)
                .map(Number);

            console.log(keysWithTrueValues);
            const DeleteMultyUser = await cmsContent.DeleteMultyUser("cmndl_tbl_Employee", keysWithTrueValues)
            // console.log(DeleteMultyUser, "DeleteMultyUser");
            if (DeleteMultyUser) {
                this.setState({ bulkAllocate: false, SelectedEnquiries: [], isSelectUnallocated: false })
                // this.MainFun()

                setTimeout(() => {
                    window.location.reload()
                }, 3000);
            }

        }
    }
    confirmDeleteP = async (e) => {
        console.log(e, "ee");
        let { SelectedEnquiries } = this.state
        console.log(SelectedEnquiries, "SelectedEnquiries");
        if (SelectedEnquiries) {
            // const filteredData = Object.fromEntries(
            //     Object.entries(SelectedEnquiries).filter(([key, value]) => value === true)
            // );

            const keysWithTrueValues = Object.keys(SelectedEnquiries)
                .filter(key => SelectedEnquiries[key] === true)
                .map(Number);

            console.log(keysWithTrueValues);
            const DeleteMultyUser = await cmsContent.DeletePermanentMultyUser("cmndl_tbl_Employee", keysWithTrueValues)
            console.log(DeleteMultyUser, "DeleteMultyUser");
            if (DeleteMultyUser) {
                this.setState({ bulkAllocate: false, SelectedEnquiries: [], isSelectUnallocated: false })
                // this.MainFun()

                setTimeout(() => {
                    window.location.reload()
                }, 3000);
            }

        }
    }
    multyDelete = d => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <div style={{ textAlign: "center" }}>
                    {/* <h1>Delete confirmation</h1>
                    <h4>Are you sure you want to Delete this temporary ?</h4><br />
                    <button className="btn btn-primary" onClick={() => {
                        this.confirmDeleteT(d)
                        onClose();
                    }}  >Yes</button> &nbsp;  &nbsp;
                    <button className="btn btn-danger" onClick={onClose} >No</button>
                    <br></br> */}
                    <br></br>

                    <h1>Delete confirmation</h1>
                    <h4>Are you sure you want to Delete this permanetly ?</h4><br />
                    <button className="btn btn-primary" onClick={() => {
                        this.confirmDeleteP(d)
                        onClose();
                    }}  >Yes</button> &nbsp;  &nbsp;
                    <button className="btn btn-danger" onClick={onClose} >No</button>
                </div>
            }
        })
    };
    MainFun = async () => {
        try {
            this.setState({ loading: true })
            // const GetOpts = await cmsContent.getCmndlEmpopt(this.state.customerId)
            // let val = {}
            // val.pageNumber = this.state.pageNumber
            const GetOpts = await cmsContent.GetCmdlDatas(this.state.customerId, "Employee")
            if (GetOpts) {
                if (GetOpts.Employee) {
                    let data = []
                    for (let ival of GetOpts.Employee) {
                        if (ival.employee_id != null && ival.status == 0) {
                            await data.push(ival)
                        }
                    }
                    await this.setState({ data: data });
                    // console.log(data, "data")
                }
                // console.log(GetOpts.BusiDesignation, "GetOpts.BusiDesignation");
                if (GetOpts.BusiDesignation) {
                    await this.setState({ BusiDesignationopt: GetOpts.BusiDesignation });
                }
                if (GetOpts.Businessunit) {
                    await this.setState({ businessunitopt: GetOpts.Businessunit });
                    console.log(GetOpts.Businessunit)
                }
                if (GetOpts.Function) {
                    await this.setState({ Functionopt: GetOpts.Function });
                }
                if (GetOpts.SubFunction) {
                    await this.setState({ SubFunctionopt: GetOpts.SubFunction });
                }
                if (GetOpts.Region) {
                    await this.setState({ Regionopt: GetOpts.Region });
                }
                if (GetOpts.Division) {
                    await this.setState({ Divisionopt: GetOpts.Division });
                }
                if (GetOpts.Band) {
                    await this.setState({ Bandopt: GetOpts.Band });
                }
                if (GetOpts.Grade) {
                    await this.setState({ Gradeopt: GetOpts.Grade });
                }
                if (GetOpts.Branch) {
                    await this.setState({ Branchopt: GetOpts.Branch });
                }
                if (GetOpts.Empcattype) {
                    await this.setState({ Empcatopt: GetOpts.Empcattype });
                }
                this.setState({
                    totalpage: GetOpts.totalpage,
                    totalrecords: GetOpts.totalrecords
                })
            }
            this.setState({ loading: false })

        } catch (error) {
            console.log(error);
        }
    }
    Editpages = (value, modalWindowId) => {
        if (value.original.status == 0) {
            return (
                <button type="button" data-toggle="modal" data-target={`#${modalWindowId}`} className="btn btn-warning" onClick={() => this.onEdit(value)}>
                    {/* <button className="btn btn-info" onClick={() => this.onEdit(value)}> */}
                    Edit
                </button>
            );

        } else {
            return (<span className="label">Deleted</span>)
        }
    };
    onEdit = async (value) => {
        var showform = document.getElementById('showform')
        showform.classList.toggle("d-none")
        let d = value.original;
        console.log(d, "d");
        console.log(d.BusinessDesignation.split(/\s/).join('').toUpperCase());
        if (d.BusinessDesignation) {
            if (isNaN(d.BusinessDesignation) == true) {
                let ansBusiDesignation = this.getValueFromArray1(d.BusinessDesignation, this.state.BusiDesignationopt, 'label')
                await this.setState({ ansBusiDesignation })
            } else {
                let ansBusiDesignation = this.getValueFromArray1(d.BusinessDesignation.split(/\s/).join('').toUpperCase(), this.state.BusiDesignationopt, 'value')
                await this.setState({ ansBusiDesignation })
            }
        }

        if (d.BusinessUnit) {
            console.log("sathesh", d.BusinessUnit)
            if (isNaN(d.BusinessUnit) == true) {
                let ansBusinessUnit = this.getValueFromArray1(d.BusinessUnit, this.state.businessunitopt, 'label')

                console.log("if working", ansBusinessUnit)
                await this.setState({ ansBusinessUnit: ansBusinessUnit })
            } else {
                let ansBusinessUnit = this.getValueFromArray1(d.BusinessUnit.split(/\s/).join('').toUpperCase(), this.state.businessunitopt, 'value')
                await this.setState({ ansBusinessUnit })
                console.log("else working")

            }
        }
        if (d.Function) {
            if (isNaN(d.Function) == true) {
                let ansFunction = this.getValueFromArray1(d.Function, this.state.Functionopt, 'label')
                await this.setState({ ansFunction: ansFunction })
            } else {
                let ansFunction = this.getValueFromArray1(d.Function.split(/\s/).join('').toUpperCase(), this.state.Functionopt, 'value')
                await this.setState({ ansFunction })

            }
        }
        if (d.SubFunction) {
            if (isNaN(d.SubFunction) == true) {
                let ansSubFunction = this.getValueFromArray1(d.SubFunction, this.state.SubFunctionopt, 'label')
                await this.setState({ ansSubFunction: ansSubFunction })
            } else {
                let ansSubFunction = this.getValueFromArray1(d.SubFunction.split(/\s/).join('').toUpperCase(), this.state.SubFunctionopt, 'value')
                await this.setState({ ansSubFunction })

            }
        }
        if (d.Region) {
            if (isNaN(d.Region) == true) {
                let ansRegion = this.getValueFromArray1(d.Region, this.state.Regionopt, 'label')
                await this.setState({ ansRegion: ansRegion })
            } else {
                let ansRegion = this.getValueFromArray1(d.Region.split(/\s/).join('').toUpperCase(), this.state.Regionopt, 'value')
                await this.setState({ ansRegion })

            }
        }
        if (d.Division) {
            if (isNaN(d.Division) == true) {
                let ansDivision = this.getValueFromArray1(d.Division, this.state.Divisionopt, 'label')
                await this.setState({ ansDivision: ansDivision })
            } else {
                let ansDivision = this.getValueFromArray1(d.Division.split(/\s/).join('').toUpperCase(), this.state.Divisionopt, 'value')
                await this.setState({ ansDivision })

            }
        }
        if (d.Branch) {
            if (isNaN(d.Branch) == true) {
                let ansBranch = this.getValueFromArray1(d.Branch, this.state.Branchopt, 'label')
                await this.setState({ ansBranch: ansBranch })
            } else {
                let ansBranch = this.getValueFromArray1(d.Branch.split(/\s/).join('').toUpperCase(), this.state.Branchopt, 'value')
                await this.setState({ ansBranch })

            }
        }
        if (d.Grade) {
            if (isNaN(d.Grade) == true) {
                let ansGrade = this.getValueFromArray1(d.Grade, this.state.Gradeopt, 'label')
                await this.setState({ ansGrade: ansGrade })
            } else {
                let ansGrade = this.getValueFromArray1(d.Grade.split(/\s/).join('').toUpperCase(), this.state.Gradeopt, 'value')
                await this.setState({ ansGrade })

            }
        }
        if (d.Band) {
            if (isNaN(d.Band) == true) {
                let ansBand = this.getValueFromArray1(d.Band, this.state.Bandopt, 'label')
                await this.setState({ ansBand: ansBand })
            } else {
                let ansBand = this.getValueFromArray1(d.Band.split(/\s/).join('').toUpperCase(), this.state.Bandopt, 'value')
                await this.setState({ ansBand })

            }
        }
        // console.log("d",d)
        if (d.EmpCatType) {
            if (isNaN(d.EmpCatType) == true) {
                let ansEmpCatType = this.getValueFromArray1(d.EmpCatType, this.state.Empcatopt, 'label')
                await this.setState({ ansEmpCatType: ansEmpCatType })
            } else {
                let ansEmpCatType = this.getValueFromArray1(d.EmpCatType.split(/\s/).join('').toUpperCase(), this.state.Empcatopt, 'value')
                await this.setState({ ansEmpCatType })

            }
        }
        if (d.gender) {
            let ansgender = this.getValueFromArray1(d.gender, this.state.genderopt, 'label')
            await this.setState({ ansgender })
        }

        await this.setState({
            emp_name: d.employee_name,
            emp_id: d.employee_id,
            password: d.password,
            ansoffEmailAdd: d.email, ansL1ManCode: d.L1ManCode,
            ansL1Man: d.L1Man, ansL2ManCode: d.L2ManCode, ansL2Man: d.L2Man, ansHRManCode: d.HRManCode, ansHRMan: d.HRMan,
            userid: d.userid,
            editId: d.id,
            isEdit: true
        });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };
    buttondel = (d) => {
        if (d.original.status == 0) {
            return (
                <button
                    className="btn btn-success"
                // onClick={() => this.Delete(d)}
                >Active</button>
            );
        } else {
            return (
                <button
                    className="btn btn-danger"

                    onClick={() => this.Recover(d)}
                >InActive</button>
                // <span className="label">Deleted</span>
            )

        }
    }
    Delete = d => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <div style={{ textAlign: "center" }}>
                    <h1>Delete confirmation</h1>
                    <h4>Are you sure ?</h4><br />
                    <button className="btn btn-primary" onClick={() => {
                        this.HandleDelete(d.original)
                        onClose();
                    }}  >Yes</button> &nbsp;  &nbsp;
                    <button className="btn btn-danger" onClick={onClose} >No</button>
                </div>
            }
        })
    };
    Recover = d => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <div style={{ textAlign: "center" }}>
                    <h1>Recover confirmation</h1>
                    <h4>Are you sure ?</h4><br />
                    <button className="btn btn-primary" onClick={() => {
                        this.RecoverData(d.original)
                        onClose();
                    }}  >Yes</button> &nbsp;  &nbsp;
                    <button className="btn btn-danger" onClick={onClose} >No</button>
                </div>
            }
        })
    };
    RecoverData = async (e) => {
        console.log(e, "ee");
        let { SelectedEnquiries } = this.state

        const RecoverUser = await cmsContent.recoverUser("cmndl_tbl_Employee", e)
        // console.log(RecoverUser, "RecoverUser");
        if (RecoverUser) {
            // this.MainFun()
            setTimeout(() => {
                window.location.reload()
            }, 3000);



        }


    }
    HandleDelete = async (d) => {
        try {
            let obj = {}
            obj.status = d.status == 0 ? Date.now() : 0;
            const result = await cmsContent.updateMaster(
                "cmndl_tbl_Employee",
                d.id,
                obj
            )
            if (result) {
                this.MainFun();
            }
        } catch (error) {
            console.log(error);
        }
    }
    getValueFromArray1 = (d, array, ch = "value", ch1 = "label") => {
        if (array.length > 0) {
            if (array.length !== 0) {
                let filtered = array.filter(function (item) {
                    return item[ch] == d;
                });
                let v = filtered[0];
                if (v != undefined) {
                    return v;
                } else {
                    return '-';
                }
            }
        } else {
            return '-';
        }
    };
    handlechange = async (e, type, name) => {
        if (type == "Select") {
            console.log("e", e)
            await this.setState({
                [name]: e,
                errgender: "", errBusinessUnit: "", errFunction: "", errSubFunction: "", errRegion: "", errDivision: "", errBranch: "",  errGrade: "", errBand: "", 
            })
        }
        else if (type == "caps") {
            await this.setState({
                [e.target.name]: e.target.value.toUpperCase(), errorempname: "", errorCode: "",erroffEmailAdd: "",errEmpCatType: "", errL1ManCode: "", errL1ManCode: "",
                errL1Man: "", errL2ManCode: "", errL2Man: "", errHRManCode: "", errHRMan: "", errBusiDesignation: "",
            });
        }
        else {
            await this.setState({ [e.target.name]: e.target.value, errorempname: "", errorCode: "" });
        }
    };
    submitCategory = async () => {
        const { emp_id, emp_name, password, isEdit, editId, userid,
            ansgender, ansBusinessUnit, ansFunction,
            ansSubFunction, ansRegion,
            ansDivision, ansBranch, ansoffEmailAdd, ansGrade, ansBand, ansEmpCatType, ansL1ManCode, ansL1Man, ansL2ManCode, ansL2Man,
            ansHRManCode, ansHRMan, ansBusiDesignation, customerId
        } = this.state;

        if (customerId && parseInt(customerId) && parseInt(customerId) == 6075) {
            console.log("if")
            if (!emp_id) {
                this.setState({ errorCode: "Please Enter Employee ID " });
                return false;
            }
            else if (!emp_name) {
                this.setState({
                    errorempname: "Please Enter Employee Name ",
                });
                return false;
            }
            else if (!password) {
                this.setState({
                    errorpassword: "Please Enter Password ",
                });
                return false;
            }
        } else if (customerId && parseInt(customerId) && parseInt(customerId) == 807) {
            if (!emp_id) {
                this.setState({ errorCode: "Please Enter Employee ID " });
                return false;
            }
            else if (!emp_name) {
                this.setState({
                    errorempname: "Please Enter Employee Name ",
                });
                return false;
            }
            else if (!ansgender) {
                this.setState({
                    errgender: "Please Select Gender ",
                });
                return false;
            }
            else if (!ansBusinessUnit) {
                this.setState({
                    errBusinessUnit: "Please Select Business Unit  ",
                });
                return false;
            }

            else if (!ansFunction) {
                this.setState({
                    errFunction: "Please Select Function ",
                });
                return false;
            }
            else if (!ansSubFunction) {
                this.setState({
                    errSubFunction: "Please Select Sub Function ",
                });
                return false;
            }
            else if (!ansRegion) {
                this.setState({
                    errRegion: "Please Select Region ",
                });
                return false;
            }
            else if (!ansDivision) {
                this.setState({
                    errDivision: "Please Select Division  ",
                });
                return false;
            }
            else if (!ansBranch) {
                this.setState({
                    errBranch: "Please Select Branch  ",
                });
                return false;
            }
            else if (!ansoffEmailAdd) {
                this.setState({
                    erroffEmailAdd: "Please Enter Office Email Address ",
                });
                return false;
            }
            else if (!ansGrade) {
                this.setState({
                    errGrade: "Please Enter Select Name ",
                });
                return false;
            }
            else if (!ansBand) {
                this.setState({
                    errBand: "Please Select Band ",
                });
                return false;
            }
            else if (!ansEmpCatType) {
                this.setState({
                    errEmpCatType: "Please Enter Employee Category Type  ",
                });
                return false;
            }
            else if (!ansL1ManCode) {
                this.setState({
                    errL1ManCode: "Please Enter L1 Manager Code  ",
                });
                return false;
            } else if (!ansL1Man) {
                this.setState({
                    errL1Man: "Please Enter L1 Manager  ",
                });
                return false;
            } else if (!ansL2ManCode) {
                this.setState({
                    errL2ManCode: "Please Enter L2 Manager Code  ",
                });
                return false;
            } else if (!ansL2Man) {
                this.setState({
                    errL2Man: "Please Enter L2 Manager  ",
                });
                return false;
            } else if (!ansHRManCode) {
                this.setState({
                    errHRManCode: "Please Enter HR Manager Code  ",
                });
                return false;
            }
            else if (!ansHRMan) {
                this.setState({
                    errHRMan: "Please Enter HR Manager  ",
                });
                return false;
            } else if (!ansBusiDesignation) {
                this.setState({
                    errBusiDesignation: "Please Enter Business Designation  ",
                });
                return false;
            }
            else if (!password) {
                this.setState({
                    errorpassword: "Please Enter Password ",
                });
                return false;
            }
        } else {
            if (!emp_id) {
                this.setState({ errorCode: "Please Enter Employee ID " });
                return false;
            }
            else if (!emp_name) {
                this.setState({
                    errorempname: "Please Enter Employee Name ",
                });
                return false;
            }
            else if (!ansoffEmailAdd) {
                this.setState({
                    erroffEmailAdd: "Please Enter Office Email Address ",
                });
                return false;
            }
            else if (!password) {
                this.setState({
                    errorpassword: "Please Enter Password ",
                });
                return false;
            }
        }
        let categoryArray = {}
        categoryArray.employee_name = emp_name
        categoryArray.employee_id = emp_id.replace(/\s/g, "")
        categoryArray.password = password
        categoryArray.customerid = this.state.customerId
        categoryArray.gender = ansgender && ansgender.label ? ansgender.label : ""
        categoryArray.BusinessUnit = ansBusinessUnit ? ansBusinessUnit.label : null
        categoryArray.Function = ansFunction ? ansFunction.label : null
        categoryArray.SubFunction = ansSubFunction ? ansSubFunction.label : null
        categoryArray.Region = ansRegion ? ansRegion.label : null
        categoryArray.Division = ansDivision ? ansDivision.label : null
        categoryArray.Branch = ansBranch ? ansBranch.label : null
        categoryArray.email = ansoffEmailAdd ? ansoffEmailAdd : ""
        categoryArray.Grade = ansGrade ? ansGrade.label : null
        categoryArray.Band = ansBand ? ansBand.label : null
        categoryArray.EmpCatType = ansEmpCatType ? ansEmpCatType.label : null
        categoryArray.L1ManCode = ansL1ManCode ? ansL1ManCode : ""
        categoryArray.L1Man = ansL1Man ? ansL1Man : ""
        categoryArray.L2ManCode = ansL2ManCode ? ansL2ManCode : ""
        categoryArray.L2Man = ansL2Man ? ansL2Man : ""
        categoryArray.HRManCode = ansHRManCode ? ansHRManCode : ""
        categoryArray.HRMan = ansHRMan ? ansHRMan : ""
        categoryArray.BusinessDesignation = ansBusiDesignation ? ansBusiDesignation.label : null

        if (editId) {
            categoryArray.id = editId ? editId : null
            categoryArray.userid = userid ? userid : null
        }
        console.log(categoryArray, "categoryArray");
        // this.setState({btnDisable: true })
        try {
            const UpdateEmployee = await cmsContent.CmndlEmployeeUpdate("cmndl_tbl_Employee",
                categoryArray)
            console.log(UpdateEmployee);
            if (UpdateEmployee) {
                if (UpdateEmployee && UpdateEmployee.data && UpdateEmployee.data.content == "Employee ID is Already Exist") {
                    await this.setState({
                        alertVisible: true,
                        badgeText: UpdateEmployee.data.content,
                        badgeColor: UpdateEmployee.data.color
                    });
                } else {
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth"
                    });
                    this.setState({
                        errorempname: '', errorCode: '', emp_id: "", emp_name: "", password: '', ansgender: "",
                        ansgender: "", ansBusinessUnit: "", ansFunction: "", ansSubFunction: "",
                        ansRegion: "", ansDivision: "", ansBranch: "",
                        ansoffEmailAdd: "", ansGrade: "", ansBand: "",
                        ansEmpCatType: "", ansL1ManCode: "",
                        ansL1Man: "", ansL2ManCode: "", ansL2Man: "", ansHRManCode: "", ansHRMan: "", ansBusiDesignation: "",
                        errgender: "", errBusinessUnit: "", errFunction: "", errSubFunction: "", errRegion: "", errDivision: "", errBranch: "", erroffEmailAdd: "", errGrade: "", errBand: "", errEmpCatType: "", errL1ManCode: "",
                        errL1Man: "", errL2ManCode: "", errL2Man: "", errHRManCode: "", errHRMan: "", errBusiDesignation: "",
                        errorpassword: '',
                        isEdit: false,
                        editId: '',
                        btnDisable: false,
                        alertVisible: true,
                        badgeText: UpdateEmployee.data.content,
                        badgeColor: UpdateEmployee.data.color

                    });
                    this.MainFun()
                    setTimeout(() => {
                        this.setState({ alertVisible: false, badgeText: false, badgeColor: false })
                    }, 3000);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    fileupload = e => {
        this.setState({ csv: e.target.files[0] })
    }

    submitcsv = async () => {
        console.log("enter11");
        const { usertypelog, status1, status2, status3 } = this.state;
        console.log(usertypelog);
        const files = this.state.csv;
        // var typelabel = usertypelog.orderBy == 9 ? "seller" : "buyer";
        const customerid = this.state.customerid;
        const ServiceId = 10;
        const formData = new FormData();
        formData.append("customerid", this.state.customerId);
        formData.append("file", files);
        console.log([...formData])
        console.log('log');
        try {
            const result = await CmsContent.CMNDLCSV("cmndl_tbl_Employee", formData);
            console.log(result, "result");
            if (result && result.data && result.data.added || result.data.Notadded) {
                console.log(result);
                await this.setState({
                    csv: "",
                    alertVisible: true,
                    badgeText: result.data.content1 ? result.data.content1 : result.data.content,
                    badgeColor: result.data.color1 ? result.data.color1 : result.data.color,
                    status1: '', status2: '', status3: '', added: result.data.added ? result.data.added : [],
                    notadded: result.data.Notadded ? result.data.Notadded : []
                })
                setTimeout(() => {
                    this.setState({
                        csv: "",
                        alertVisible: false, textalert: "",
                        badgeText: false, badgeColor: false,
                    }); this.MainFun();
                    window.location.reload()
                }, 3000);

            }
            else {
                await this.setState({
                    csv: "",
                    alertVisible: true, badgeText: result.data.content,
                    badgeColor: result.data.color, status1: '', status2: '', status3: ''
                })
                setTimeout(() => {
                    this.setState({
                        csv: "",
                        alertVisible: false, textalert: "", badgeText: result.data.content,
                        badgeColor: result.data.color
                    }); this.MainFun();
                    window.location.reload()
                }, 2000);
            }
        }
        catch (error) {
            console.log(error)
        }
    };

    submitcsvDeleteEmployee = async () => {
        console.log("enter222");
        const { usertypelog, status1, status2, status3 } = this.state;
        console.log(usertypelog);
        const files = this.state.csv;
        console.log(files,"files");
        // var typelabel = usertypelog.orderBy == 9 ? "seller" : "buyer";
        const customerid = this.state.customerid;
        const ServiceId = 10;
        const formData = new FormData();
        formData.append("customerid", this.state.customerId);
        formData.append("file", files);
        console.log([...formData])
        // console.log('log');
        try {
            const result = await CmsContent.dealerDeleteCsvUpload("cmndl_tbl_Employee", formData);
            console.log(result, "result");
            if (result && result.data && result.data.added || result.data.Notadded) {
                console.log(result);
                await this.setState({
                    csv: "",
                    alertVisible: true,
                    badgeText: result.data.content1 ? result.data.content1 : result.data.content,
                    badgeColor: result.data.color1 ? result.data.color1 : result.data.color,
                    status1: '', status2: '', status3: '', added: result.data.added ? result.data.added : [],
                    notadded: result.data.Notadded ? result.data.Notadded : []
                })
                setTimeout(() => {
                    this.setState({
                        csv: "",
                        alertVisible: false, textalert: "",
                        badgeText: false, badgeColor: false,
                    }); this.MainFun();
                    window.location.reload()
                }, 3000);

            }
            else {
                await this.setState({
                    csv: "",
                    alertVisible: true, badgeText: result.data.content,
                    badgeColor: result.data.color, status1: '', status2: '', status3: ''
                })
                setTimeout(() => {
                    this.setState({
                        csv: "",
                        alertVisible: false, textalert: "", badgeText: result.data.content,
                        badgeColor: result.data.color
                    }); this.MainFun();
                    window.location.reload()
                }, 2000);
            }
        }
        catch (error) {
            console.log(error)
        }
    };

    reset1 = async () => {
        await this.setState({
            errorempname: '', errorCode: '', emp_id: "", emp_name: "", password: '', ansgender: "",
            ansgender: "", ansBusinessUnit: "", ansFunction: "", ansSubFunction: "",
            ansRegion: "", ansDivision: "", ansBranch: "",
            ansoffEmailAdd: "", ansGrade: "", ansBand: "",
            badgeText: false, badgeColor: false, 
            ansEmpCatType: "", ansL1ManCode: "",
            ansL1Man: "", ansL2ManCode: "", ansL2Man: "", ansHRManCode: "", ansHRMan: "", ansBusiDesignation: "",
            errgender: "", errBusinessUnit: "", errFunction: "", errSubFunction: "", errRegion: "", errDivision: "", errBranch: "", erroffEmailAdd: "", errGrade: "", errBand: "", errEmpCatType: "", errL1ManCode: "",
            errL1Man: "", errL2ManCode: "", errL2Man: "", errHRManCode: "", errHRMan: "", errBusiDesignation: "",
            errorpassword: '', editId: false, userid: false, isEdit: false, alertVisible: false, error: ""
        })
    }

    modalhandlechange = (e) => {
        this.setState({ modalInput: e.target.value })
    }

    submitmodal = async (b) => {
        let value = this.state.modalInput
        if (value === null || value.trim() === "") {
            this.setState({ error: "Value Cannot Be Empty" });
            return false;
        }
        else if (b === "businessunit_name") {
            const a = {
                table_name: "cmdl_businessunit_master",
                categoryArray: { businessunit_name: null }
            }
            a.categoryArray.businessunit_name = value.trim();
            console.log(a.categoryArray.businessunit_name)
            console.log(a, "a");
            try {
                this.setState({ disableValue: true });
                const result = await cmsContent.addMaster(
                    a.table_name,
                    a.categoryArray
                );

                if (result) {
                    this.setState({
                        alertVisible: true,
                        modalInput: "",
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                    }, 3000);
                }
                this.MainFun()
            } catch (error) {
                console.log(error);
            }
        }
        else if (b === "function_name") {
            const a = {
                table_name: "cmdl_function_master",
                categoryArray: { function_name: null }
            }
            a.categoryArray.function_name = value.trim();
            console.log(a.categoryArray.function_name)
            console.log(a, "a");
            try {
                this.setState({ disableValue: true });
                const result = await cmsContent.addMaster(
                    a.table_name,
                    a.categoryArray
                );

                if (result) {
                    this.setState({
                        alertVisible: true,
                        modalInput: "",
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                    }, 3000);
                    this.MainFun()
                }
            } catch (error) {
                console.log(error);
            }
        }
        else if (b === "subfunction_name") {
            const a = {
                table_name: "cmdl_subfunction_master",
                categoryArray: { subfunction_name: null }
            }
            a.categoryArray.subfunction_name = value.trim();
            console.log(a.categoryArray.subfunction_name)
            console.log(a, "a");
            try {
                this.setState({ disableValue: true });
                const result = await cmsContent.addMaster(
                    a.table_name,
                    a.categoryArray
                );

                if (result) {
                    this.setState({
                        alertVisible: true,
                        modalInput: "",
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                    }, 3000);
                    this.MainFun()
                }
            } catch (error) {
                console.log(error);
            }
        }
        else if (b === "region") {
            const a = {
                table_name: "cmdl_region_master",
                categoryArray: { region_name: null }
            }
            a.categoryArray.region_name = value.trim();
            console.log(a.categoryArray.region_name)
            console.log(a, "a");
            try {
                this.setState({ disableValue: true });
                const result = await cmsContent.addMaster(
                    a.table_name,
                    a.categoryArray
                );

                if (result) {
                    this.setState({
                        alertVisible: true,
                        modalInput: "",
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                    }, 3000);
                    this.MainFun()
                }
            } catch (error) {
                console.log(error);
            }
        }
        else if (b === "division") {
            const a = {
                table_name: "cmdl_division_master",
                categoryArray: { division_name: null }
            }
            a.categoryArray.division_name = value.trim();
            console.log(a.categoryArray.division_name)
            try {
                this.setState({ disableValue: true });
                const result = await cmsContent.addMaster(
                    a.table_name,
                    a.categoryArray
                );

                if (result) {
                    this.setState({
                        alertVisible: true,
                        modalInput: "",
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                    }, 3000);
                    this.MainFun()
                }
            } catch (error) {
                console.log(error);
            }
        }
        else if (b === "branch") {
            const a = {
                table_name: "cmdl_branch_master",
                categoryArray: { branch_name: null }
            }
            a.categoryArray.branch_name = value.trim();
            console.log(a.categoryArray.branch_name)
            try {
                this.setState({ disableValue: true });
                const result = await cmsContent.addMaster(
                    a.table_name,
                    a.categoryArray
                );

                if (result) {
                    this.setState({
                        alertVisible: true,
                        modalInput: "",
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                    }, 3000);
                    this.MainFun()
                }
            } catch (error) {
                console.log(error);
            }
        }
        else if (b === "grade") {
            const a = {
                table_name: "cmdl_grade_master",
                categoryArray: { grade_name: null }
            }
            a.categoryArray.grade_name = value.trim();
            console.log(a.categoryArray.grade_name)
            try {
                this.setState({ disableValue: true });
                const result = await cmsContent.addMaster(
                    a.table_name,
                    a.categoryArray
                );

                if (result) {
                    this.setState({
                        alertVisible: true,
                        modalInput: "",
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                    }, 3000);
                    this.MainFun()
                }
            } catch (error) {
                console.log(error);
            }
        }
        else if (b === "band") {
            const a = {
                table_name: "cmdl_band_master",
                categoryArray: { band_name: null }
            }
            a.categoryArray.band_name = value.trim();
            console.log(a.categoryArray.band_name)
            try {
                this.setState({ disableValue: true });
                const result = await cmsContent.addMaster(
                    a.table_name,
                    a.categoryArray
                );

                if (result) {
                    this.setState({
                        alertVisible: true,
                        modalInput: "",
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                    }, 3000);
                    this.MainFun()
                }
            } catch (error) {
                console.log(error);
            }
        }
        else if (b === "empcattype") {
            const a = {
                table_name: "cmdl_empcategory_master",
                categoryArray: { empcategory_name: null }
            }
            a.categoryArray.empcategory_name = value.trim();
            console.log(a.categoryArray.empcategory_name)
            try {
                this.setState({ disableValue: true });
                const result = await cmsContent.addMaster(
                    a.table_name,
                    a.categoryArray
                );

                if (result) {
                    this.setState({
                        alertVisible: true,
                        modalInput: "",
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                    }, 3000);
                    this.MainFun()
                }
            } catch (error) {
                console.log(error);
            }
        }

    }

    reset = () => {
        this.setState({ error: "" })
    }

    onDismiss = () => {
        this.setState({ alertVisible: false });
        this.setState({ formAlertdelete: false });
    };

    showModalForm = () => {
        var showform = document.getElementById('showform')
        showform.classList.toggle("d-none")
        this.reset1()
    }

    closeForm = () => {
        var showform = document.getElementById('showform')
        showform.classList.toggle("d-none")
        this.reset1()

    }

    render() {
        const {
            badgeColor,
            badgeText,
            textalert,
            color,
            MOlist, //marketing off
            Saleslist, alertVisible, added, notadded, loading, businessunitopt, ansBusinessUnit, modalInput, error
        } = this.state;

        // const togglePassword = document.querySelector('#togglePassword');
        // const password = document.querySelector('#password');
        // document.querySelector('#togglePassword').addEventListener('click',function(e) {
        //    this.classList.toggle('fa-eye-slash');
        //     const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
        //     password.setAttribute('type', type);
        // });
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Add Employee</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="inlinebutton">
                                            <div>
                                                <button className="btn btn-primary" onClick={() => this.showModalForm()}>Add Employee</button>
                                            </div>
                                            <>
                                                {/* <>
                                                <LoginModal
                                                    buttonTitle="Add Employee"
                                                    title={
                                                        this.state.updateData === false
                                                            ? "Add User"
                                                            : "Update User"
                                                    }
                                                    id="customer"
                                                    onClick={this.reset}
                                                    bodyText={
                                                        <div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">EMPLOYEE ID</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="pageName"
                                                                        name="emp_id"
                                                                        placeholder="Enter ID"
                                                                        value={this.state.emp_id}
                                                                        onChange={this.handlechange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errorCode}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">EMPLOYEE NAME</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="Name"
                                                                        name="emp_name"
                                                                        placeholder="Enter name"
                                                                        value={this.state.emp_name}
                                                                        onChange={d => this.handlechange(d, "caps")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errorempname}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">GENDER</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <SingleSelect
                                                                        options={this.state.genderopt}
                                                                        handleChange={d => this.handlechange(d, "Select", "ansgender")}
                                                                        selectedService={this.state.ansgender}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errgender}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">Business Unit </label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <SingleSelect
                                                                        options={businessunitopt}
                                                                        handleChange={d => this.handlechange(d, "Select", "ansBusinessUnit")}
                                                                        selectedService={this.state.ansBusinessUnit}
                                                                    />

                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <LoginModal
                                                                        buttonTitle="Add Business Unit "
                                                                        title="Add Business Unit"
                                                                        id="businessUnit"
                                                                        extraClass="btn-width"
                                                                        onClick={this.newreset}
                                                                        bodyText={
                                                                            <div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-4" />
                                                                                    <Alert
                                                                                        className="badge-content"
                                                                                        color="success"
                                                                                        isOpen={alertVisible}
                                                                                        toggle={this.onDismiss}
                                                                                    >
                                                                                        New Business Unit Added
                                                                                    </Alert>
                                                                                </div>
                                                                                <div className="card-header">
                                                                                    <h1>Business Unit</h1>
                                                                                </div>
                                                                                <div className="row form-group pt-5">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2">
                                                                                        <label htmlFor="subcategory">Business Unit</label>
                                                                                    </div>
                                                                                    <div className="col-sm-5">
                                                                                        <input
                                                                                            type="text"
                                                                                            name="subcategory"
                                                                                            placeholder="Enter Business Unit"
                                                                                            className="form-control"
                                                                                            value={modalInput}
                                                                                            onChange={(e) => this.modalhandlechange(e)}
                                                                                        />
                                                                                        <span className="modal-error-show">{error}</span>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-5">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary"
                                                                                            onClick={() => this.submitmodal("businessunit_name")}
                                                                                        >
                                                                                            submit
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <br></br>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </div>

                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errBusinessUnit}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">Function</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <SingleSelect
                                                                        options={this.state.Functionopt}
                                                                        handleChange={d => this.handlechange(d, "Select", "ansFunction")}
                                                                        selectedService={this.state.ansFunction}
                                                                    />

                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <LoginModal
                                                                        buttonTitle="Add Function Unit "
                                                                        title="Add Function Unit"
                                                                        id="functionunit"
                                                                        extraClass="btn-width"
                                                                        onClick={this.reset}
                                                                        bodyText={
                                                                            <div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-4" />
                                                                                    <Alert
                                                                                        className="badge-content"
                                                                                        color="success"
                                                                                        isOpen={alertVisible}
                                                                                        toggle={this.onDismiss}
                                                                                    >
                                                                                        New Function Unit Added
                                                                                    </Alert>
                                                                                </div>
                                                                                <div className="card-header">
                                                                                    <h1>Function</h1>
                                                                                </div>
                                                                                <div className="row form-group pt-5">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2">
                                                                                        <label htmlFor="subcategory">Function</label>
                                                                                    </div>
                                                                                    <div className="col-sm-5">
                                                                                        <input
                                                                                            type="text"
                                                                                            name="subcategory"
                                                                                            placeholder="Enter Function"
                                                                                            className="form-control"
                                                                                            value={modalInput}
                                                                                            onChange={(e) => this.modalhandlechange(e)}
                                                                                        />
                                                                                        <span className="modal-error-show">{error}</span>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-5">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary"
                                                                                            onClick={() => this.submitmodal("function_name")}
                                                                                        >
                                                                                            submit
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <br></br>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errFunction}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">Sub Function</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <SingleSelect
                                                                        options={this.state.SubFunctionopt}
                                                                        handleChange={d => this.handlechange(d, "Select", "ansSubFunction")}
                                                                        selectedService={this.state.ansSubFunction}
                                                                    />

                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <LoginModal
                                                                        buttonTitle="Add Sub Function Unit "
                                                                        title="Add Sub Function Unit"
                                                                        id="subfunctionunit"
                                                                        extraClass="btn-width"
                                                                        onClick={this.reset}
                                                                        bodyText={
                                                                            <div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-4" />
                                                                                    <Alert
                                                                                        className="badge-content"
                                                                                        color="success"
                                                                                        isOpen={alertVisible}
                                                                                        toggle={this.onDismiss}
                                                                                    >
                                                                                        New Sub Function Unit Added
                                                                                    </Alert>
                                                                                </div>
                                                                                <div className="card-header">
                                                                                    <h1>Function</h1>
                                                                                </div>
                                                                                <div className="row form-group pt-5">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2">
                                                                                        <label htmlFor="subcategory">Sub Function</label>
                                                                                    </div>
                                                                                    <div className="col-sm-5">
                                                                                        <input
                                                                                            type="text"
                                                                                            name="subcategory"
                                                                                            placeholder="Enter Sub Function"
                                                                                            className="form-control"
                                                                                            value={modalInput}
                                                                                            onChange={(e) => this.modalhandlechange(e)}
                                                                                        />
                                                                                        <span className="modal-error-show">{error}</span>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-5">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary"
                                                                                            onClick={() => this.submitmodal("subfunction_name")}
                                                                                        >
                                                                                            submit
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <br></br>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errSubFunction}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">Region</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <SingleSelect
                                                                        options={this.state.Regionopt}
                                                                        handleChange={d => this.handlechange(d, "Select", "ansRegion")}
                                                                        selectedService={this.state.ansRegion}
                                                                    />

                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <LoginModal
                                                                        buttonTitle="Add Region"
                                                                        title="Add Region"
                                                                        id="Region"
                                                                        extraClass="btn-width"
                                                                        onClick={this.reset}
                                                                        bodyText={
                                                                            <div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-4" />
                                                                                    <Alert
                                                                                        className="badge-content"
                                                                                        color="success"
                                                                                        isOpen={alertVisible}
                                                                                        toggle={this.onDismiss}
                                                                                    >
                                                                                        New Region Added
                                                                                    </Alert>
                                                                                </div>
                                                                                <div className="card-header">
                                                                                    <h1>Region</h1>
                                                                                </div>
                                                                                <div className="row form-group pt-5">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2">
                                                                                        <label htmlFor="subcategory">Region</label>
                                                                                    </div>
                                                                                    <div className="col-sm-5">
                                                                                        <input
                                                                                            type="text"
                                                                                            name="subcategory"
                                                                                            placeholder="Enter Region Function"
                                                                                            className="form-control"
                                                                                            value={modalInput}
                                                                                            onChange={(e) => this.modalhandlechange(e)}
                                                                                        />
                                                                                        <span className="modal-error-show">{error}</span>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-5">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary"
                                                                                            onClick={() => this.submitmodal("region")}
                                                                                        >
                                                                                            submit
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <br></br>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errRegion}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">Division</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <SingleSelect
                                                                        options={this.state.Divisionopt}
                                                                        handleChange={d => this.handlechange(d, "Select", "ansDivision")}
                                                                        selectedService={this.state.ansDivision}
                                                                    />

                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <LoginModal
                                                                        buttonTitle="Add Division"
                                                                        title="Add Division"
                                                                        id="division"
                                                                        extraClass="btn-width"
                                                                        onClick={this.reset}
                                                                        bodyText={
                                                                            <div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-4" />
                                                                                    <Alert
                                                                                        className="badge-content"
                                                                                        color="success"
                                                                                        isOpen={alertVisible}
                                                                                        toggle={this.onDismiss}
                                                                                    >
                                                                                        New Division Added
                                                                                    </Alert>
                                                                                </div>
                                                                                <div className="card-header">
                                                                                    <h1>Division</h1>
                                                                                </div>
                                                                                <div className="row form-group pt-5">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2">
                                                                                        <label htmlFor="subcategory">Division</label>
                                                                                    </div>
                                                                                    <div className="col-sm-5">
                                                                                        <input
                                                                                            type="text"
                                                                                            name="subcategory"
                                                                                            placeholder="Enter Division Function"
                                                                                            className="form-control"
                                                                                            value={modalInput}
                                                                                            onChange={(e) => this.modalhandlechange(e)}
                                                                                        />
                                                                                        <span className="modal-error-show">{error}</span>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-5">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary"
                                                                                            onClick={() => this.submitmodal("division")}
                                                                                        >
                                                                                            submit
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <br></br>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errDivision}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">Branch</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <SingleSelect
                                                                        options={this.state.Branchopt}
                                                                        handleChange={d => this.handlechange(d, "Select", "ansBranch")}
                                                                        selectedService={this.state.ansBranch}
                                                                    />

                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <LoginModal
                                                                        buttonTitle="Add Branch"
                                                                        title="Add Branch"
                                                                        id="Branch"
                                                                        extraClass="btn-width"
                                                                        onClick={this.reset}
                                                                        bodyText={
                                                                            <div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-4" />
                                                                                    <Alert
                                                                                        className="badge-content"
                                                                                        color="success"
                                                                                        isOpen={alertVisible}
                                                                                        toggle={this.onDismiss}
                                                                                    >
                                                                                        New Branch Added
                                                                                    </Alert>
                                                                                </div>
                                                                                <div className="card-header">
                                                                                    <h1>Branch</h1>
                                                                                </div>
                                                                                <div className="row form-group pt-5">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2">
                                                                                        <label htmlFor="subcategory">Branch</label>
                                                                                    </div>
                                                                                    <div className="col-sm-5">
                                                                                        <input
                                                                                            type="text"
                                                                                            name="subcategory"
                                                                                            placeholder="Enter Branch Function"
                                                                                            className="form-control"
                                                                                            value={modalInput}
                                                                                            onChange={(e) => this.modalhandlechange(e)}
                                                                                        />
                                                                                        <span className="modal-error-show">{error}</span>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-5">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary"
                                                                                            onClick={() => this.submitmodal("branch")}
                                                                                        >
                                                                                            submit
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <br></br>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errBranch}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">Office Email Address </label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="Name"
                                                                        name="ansoffEmailAdd"
                                                                        placeholder="Enter Office Email Address"
                                                                        value={this.state.ansoffEmailAdd}
                                                                        onChange={this.handlechange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.erroffEmailAdd}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">Grade</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <SingleSelect
                                                                        options={this.state.Gradeopt}
                                                                        handleChange={d => this.handlechange(d, "Select", "ansGrade")}
                                                                        selectedService={this.state.ansGrade}
                                                                    />

                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <LoginModal
                                                                        buttonTitle="Add Grade"
                                                                        title="Add Grade"
                                                                        id="Grade"
                                                                        extraClass="btn-width"
                                                                        onClick={this.reset}
                                                                        bodyText={
                                                                            <div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-4" />
                                                                                    <Alert
                                                                                        className="badge-content"
                                                                                        color="success"
                                                                                        isOpen={alertVisible}
                                                                                        toggle={this.onDismiss}
                                                                                    >
                                                                                        New Grade Added
                                                                                    </Alert>
                                                                                </div>
                                                                                <div className="card-header">
                                                                                    <h1>Grade</h1>
                                                                                </div>
                                                                                <div className="row form-group pt-5">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2">
                                                                                        <label htmlFor="subcategory">Grade</label>
                                                                                    </div>
                                                                                    <div className="col-sm-5">
                                                                                        <input
                                                                                            type="text"
                                                                                            name="subcategory"
                                                                                            placeholder="Enter Grade "
                                                                                            className="form-control"
                                                                                            value={modalInput}
                                                                                            onChange={(e) => this.modalhandlechange(e)}
                                                                                        />
                                                                                        <span className="modal-error-show">{error}</span>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-5">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary"
                                                                                            onClick={() => this.submitmodal("grade")}
                                                                                        >
                                                                                            submit
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <br></br>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errGrade}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">Band</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <SingleSelect
                                                                        options={this.state.Bandopt}
                                                                        handleChange={d => this.handlechange(d, "Select", "ansBand")}
                                                                        selectedService={this.state.ansBand}
                                                                    />

                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <LoginModal
                                                                        buttonTitle="Add Band"
                                                                        title="Add Band"
                                                                        id="Band"
                                                                        extraClass="btn-width"
                                                                        onClick={this.reset}
                                                                        bodyText={
                                                                            <div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-4" />
                                                                                    <Alert
                                                                                        className="badge-content"
                                                                                        color="success"
                                                                                        isOpen={alertVisible}
                                                                                        toggle={this.onDismiss}
                                                                                    >
                                                                                        New Band Added
                                                                                    </Alert>
                                                                                </div>
                                                                                <div className="card-header">
                                                                                    <h1>Band</h1>
                                                                                </div>
                                                                                <div className="row form-group pt-5">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2">
                                                                                        <label htmlFor="subcategory">Band</label>
                                                                                    </div>
                                                                                    <div className="col-sm-5">
                                                                                        <input
                                                                                            type="text"
                                                                                            name="subcategory"
                                                                                            placeholder="Enter Band "
                                                                                            className="form-control"
                                                                                            value={modalInput}
                                                                                            onChange={(e) => this.modalhandlechange(e)}
                                                                                        />
                                                                                        <span className="modal-error-show">{error}</span>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-5">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary"
                                                                                            onClick={() => this.submitmodal("band")}
                                                                                        >
                                                                                            submit
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <br></br>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errBand}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">Employee Category Type</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <SingleSelect
                                                                        options={this.state.Empcatopt}
                                                                        handleChange={d => this.handlechange(d, "Select", "ansEmpCatType")}
                                                                        selectedService={this.state.ansEmpCatType}
                                                                    />

                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <LoginModal
                                                                        buttonTitle="Add Empcattype"
                                                                        title="Add Employee Category Type"
                                                                        id="EmployeeCategoryType"
                                                                        extraClass="btn-width"
                                                                        onClick={this.newreset}
                                                                        bodyText={
                                                                            <div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-4" />
                                                                                    <Alert
                                                                                        className="badge-content"
                                                                                        color="success"
                                                                                        isOpen={alertVisible}
                                                                                        toggle={this.onDismiss}
                                                                                    >
                                                                                        New Employee Category Type Added
                                                                                    </Alert>
                                                                                </div>
                                                                                <div className="card-header">
                                                                                    <h1>Employee Category Type</h1>
                                                                                </div>
                                                                                <div className="row form-group pt-5">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2">
                                                                                        <label htmlFor="subcategory">Employee Category Type</label>
                                                                                    </div>
                                                                                    <div className="col-sm-5">
                                                                                        <input
                                                                                            type="text"
                                                                                            name="subcategory"
                                                                                            placeholder="Enter Employee Category Type "
                                                                                            className="form-control"
                                                                                            value={modalInput}
                                                                                            onChange={(e) => this.modalhandlechange(e)}
                                                                                        />
                                                                                        <span className="modal-error-show">{error}</span>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-2" />
                                                                                    <div className="col-sm-5">
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-primary"
                                                                                            onClick={() => this.submitmodal("empcattype")}
                                                                                        >
                                                                                            submit
                                                                                        </button>
                                                                                    </div>
                                                                                    <div className="col-sm-3" />
                                                                                </div>
                                                                                <br></br>
                                                                            </div>
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errEmpCatType}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">L1 Manager Code</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="Name"
                                                                        name="ansL1ManCode"
                                                                        placeholder="Enter L1 Manager Code"
                                                                        value={this.state.ansL1ManCode}
                                                                        onChange={d => this.handlechange(d, "caps")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errL1ManCode}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">L1 Manager</label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="Name"
                                                                        name="ansL1Man"
                                                                        placeholder="Enter L1 Manager"
                                                                        value={this.state.ansL1Man}
                                                                        onChange={d => this.handlechange(d, "caps")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errL1Man}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">L2 Manager Code </label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="Name"
                                                                        name="ansL2ManCode"
                                                                        placeholder="Enter L2 Manager Code"
                                                                        value={this.state.ansL2ManCode}
                                                                        onChange={d => this.handlechange(d, "caps")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errL2ManCode}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">L2 Manager </label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="Name"
                                                                        name="ansL2Man"
                                                                        placeholder="Enter L2 Manager"
                                                                        value={this.state.ansL2Man}
                                                                        onChange={d => this.handlechange(d, "caps")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errL2Man}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">HR Manager Code </label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="Name"
                                                                        name="ansHRManCode"
                                                                        placeholder="Enter HR Manager Code"
                                                                        value={this.state.ansHRManCode}
                                                                        onChange={d => this.handlechange(d, "caps")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errHRManCode}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">HR Manager </label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="Name"
                                                                        name="ansHRMan"
                                                                        placeholder="Enter HR Manager"
                                                                        value={this.state.ansHRMan}
                                                                        onChange={d => this.handlechange(d, "caps")}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errHRMan}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">Business Designation </label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <SingleSelect
                                                                        options={this.state.BusiDesignationopt}
                                                                        handleChange={d => this.handlechange(d, "Select", "ansBusiDesignation")}
                                                                        selectedService={this.state.ansBusiDesignation}
                                                                    />
                                                                    
                                                                </div>
                                                            </div>

                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errBusiDesignation}
                                                                </span>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="exampleInputEmail1">PASSWORD</label>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <input
                                                                        type="password"
                                                                        className="form-control"
                                                                        id="password"
                                                                        name="password"
                                                                        placeholder="Enter Password"
                                                                        value={this.state.password}
                                                                        onChange={this.handlechange}
                                                                    />
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <i class="fa fa-eye" id="togglePassword" style={{ marginLeft: '30px', cursor: 'pointer' }}></i>
                                                                </div>
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <span className="error-show ">
                                                                    {this.state.errorpassword}
                                                                </span>
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-7">
                                                                    <Alert
                                                                        className="badge-content"
                                                                        color={badgeColor && badgeColor}
                                                                        isOpen={this.state.alertVisible}
                                                                        toggle={this.onDismiss}
                                                                    >
                                                                        {badgeText && badgeText}
                                                                    </Alert>
                                                                </div>
                                                                <div className="col-sm-3" />
                                                            </div>
                                                            <br />
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-5">
                                                                    <button
                                                                        type="button"
                                                                        className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
                                                                        disabled={this.state.btnDisable}
                                                                        onClick={this.submitCategory}
                                                                    >
                                                                        {this.state.isEdit ? 'Update' : 'Submit'}
                                                                    </button>
                                                                </div>
                                                                <div className="col-sm-3" />
                                                            </div>

                                                        </div>
                                                    }
                                                />
                                            </> */}

                                            </>
                                            <LoginModal
                                                buttonTitle="Import Csv"
                                                title="Csv file upload"
                                                buttonStyle={{ marginLeft: "10px" }}
                                                extraClass="success"
                                                onClick={this.reset}
                                                id="import"
                                                bodyText={
                                                    <div>
                                                        <br />
                                                        <Alert
                                                            className="badge-content"
                                                            color={badgeColor && badgeColor}
                                                            isOpen={alertVisible}
                                                            toggle={this.onDismiss}
                                                        >
                                                            {badgeText && badgeText}
                                                        </Alert>
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="customFile"
                                                            accept='.csv'
                                                            // value={this.state.csv}
                                                            onChange={this.fileupload}
                                                        />
                                                        <br />
                                                        <label
                                                            className="custom-file-label"
                                                            htmlFor="customFile"
                                                        >
                                                            {this.state.csv ? this.state.csv.name : 'Choose File'}
                                                        </label>
                                                        <br />
                                                        {/* {notadded && notadded.length > 0 &&
                                                            <table class="table table-striped table-dark">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">No</th>
                                                                        <th scope="col">Added Csv Data's</th>
                                                                        <th scope="col">Not Added Csv Data's</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        notadded && notadded[0] && notadded[0].map((jval, j) => {
                                                                            console.log(jval.EMPCode);
                                                                            added && added[0] && added[0].map((ival, i) => {
                                                                                jval.EMPCode = "-"
                                                                                return (
                                                                                    <tr>
                                                                                        <th scope="row">{j + 1}</th>
                                                                                        <td> {ival.EMPCode}</td>
                                                                                        <td> {jval.EMPCode}</td>
                                                                                    </tr>
                                                                                )
                                                                            })

                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        } */}
                                                        {notadded && notadded.length > 0 && <div className="row text-center">
                                                            <div className="col-sm-6">
                                                                <h3>Added</h3>
                                                                <br />
                                                                <h5>EmployeeID</h5>
                                                                {added && added[0] &&
                                                                    added[0].map((ival, i) => {
                                                                        return (
                                                                            <div className="">

                                                                                {ival.EMPCode}
                                                                            </div>
                                                                        )
                                                                    })}
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <h3>Not Added</h3>
                                                                <br />
                                                                <h5>EmployeeID</h5>
                                                                {notadded && notadded[0] &&
                                                                    notadded[0].map((ival, i) => {
                                                                        return (
                                                                            <div className="">

                                                                                {ival.EMPCode}
                                                                            </div>
                                                                        )
                                                                    })}
                                                            </div>

                                                        </div>
                                                        }
                                                        <br />
                                                        <br />
                                                        <div className="col-md-5">
                                                            <a href="../../files/LearnersList.csv" download="LearnersList.csv">
                                                                download sample.csv
                                                            </a>
                                                        </div>
                                                        <br />
                                                        <button
                                                            type="button"
                                                            class="btn btn-primary waves-effect"
                                                            onClick={(e) => this.submitcsv(e)}
                                                        >
                                                            Submit
                                                        </button>
                                                        <br />
                                                    </div>
                                                }
                                            />
                                            <LoginModal
                                                buttonTitle="Import Csv"
                                                title="Csv file upload"
                                                buttonStyle={{ marginLeft: "10px" }}
                                                extraClass="danger"
                                                onClick={this.reset}
                                                id="import1"
                                                bodyText={
                                                    <div>
                                                        <br />
                                                        <Alert
                                                            className="badge-content"
                                                            color={badgeColor && badgeColor}
                                                            isOpen={alertVisible}
                                                            toggle={this.onDismiss}
                                                        >
                                                            {badgeText && badgeText}
                                                        </Alert>
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            id="customFile"
                                                            accept='.csv'
                                                            // value={this.state.csv}
                                                            onChange={this.fileupload}
                                                        />
                                                        <br />
                                                        <label
                                                            className="custom-file-label"
                                                            htmlFor="customFile"
                                                        >
                                                            {this.state.csv ? this.state.csv.name : 'Choose File'}
                                                        </label>
                                                        <br />
                                                        <br />
                                                        <br />
                                                        <div className="col-md-5">
                                                            <a href="../../files/deleteEmployeeSample.csv" download="deleteEmployeeSample.csv">
                                                                download sample.csv
                                                            </a>
                                                        </div>
                                                        <br />
                                                        <button
                                                            type="button"
                                                            class="btn btn-primary waves-effect"
                                                            onClick={(e) => this.submitcsvDeleteEmployee(e)}
                                                        >
                                                            Submit
                                                        </button>
                                                        <br />
                                                    </div>
                                                }
                                            />

                                        </div>
                                        <br></br>

                                        <div className="row form-group d-none py-5" id="showform" >
                                            <div className="col-sm-2"></div>
                                            <div className="col-sm-8">
                                                <div className="py-5" style={{ border: "1px solid #80808038", position: "relative" }}>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <Alert
                                                            className="badge-content"
                                                            color="success"
                                                            isOpen={alertVisible}
                                                            toggle={this.onDismiss}
                                                        >
                                                            New Employee Added
                                                        </Alert>
                                                    </div>
                                                    <div className="" style={{ position: "absolute", top: "8px", right: "30px", fontSize: "40px", cursor: "pointer" }}>
                                                        <span onClick={this.closeForm} style={{border:"1px solid #80808045",padding:"1px 12px"}}>&times;</span>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">EMPLOYEE ID</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="pageName"
                                                                name="emp_id"
                                                                placeholder="Enter ID"
                                                                value={this.state.emp_id}
                                                                onChange={this.handlechange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errorCode}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">EMPLOYEE NAME</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="Name"
                                                                name="emp_name"
                                                                placeholder="Enter name"
                                                                value={this.state.emp_name}
                                                                onChange={d => this.handlechange(d, "caps")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errorempname}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">GENDER</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <SingleSelect
                                                                options={this.state.genderopt}
                                                                handleChange={d => this.handlechange(d, "Select", "ansgender")}
                                                                selectedService={this.state.ansgender}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errgender}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Business Unit </label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <SingleSelect
                                                                options={businessunitopt}
                                                                handleChange={d => this.handlechange(d, "Select", "ansBusinessUnit")}
                                                                selectedService={this.state.ansBusinessUnit}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3">
                                                            <LoginModal
                                                                buttonTitle="Add Business Unit "
                                                                title="Add Business Unit"
                                                                id="businessUnit"
                                                                extraClass="btn-width"
                                                                onClick={this.reset}
                                                                bodyText={
                                                                    <div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={alertVisible}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                New Business Unit Added
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="card-header">
                                                                            <h1>Business Unit</h1>
                                                                        </div>
                                                                        <div className="row form-group pt-5">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2">
                                                                                <label htmlFor="subcategory">Business Unit</label>
                                                                            </div>
                                                                            <div className="col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    name="subcategory"
                                                                                    placeholder="Enter Business Unit"
                                                                                    className="form-control"
                                                                                    value={modalInput}
                                                                                    onChange={(e) => this.modalhandlechange(e)}
                                                                                />
                                                                                <span className="modal-error-show">{error}</span>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-5">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => this.submitmodal("businessunit_name")}
                                                                                >
                                                                                    submit
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <br></br>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errBusinessUnit}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Function</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <SingleSelect
                                                                options={this.state.Functionopt}
                                                                handleChange={d => this.handlechange(d, "Select", "ansFunction")}
                                                                selectedService={this.state.ansFunction}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3">
                                                            <LoginModal
                                                                buttonTitle="Add Function Unit "
                                                                title="Add Function Unit"
                                                                id="functionunit"
                                                                extraClass="btn-width"
                                                                onClick={this.reset}
                                                                bodyText={
                                                                    <div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={alertVisible}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                New Function Unit Added
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="card-header">
                                                                            <h1>Function</h1>
                                                                        </div>
                                                                        <div className="row form-group pt-5">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2">
                                                                                <label htmlFor="subcategory">Function</label>
                                                                            </div>
                                                                            <div className="col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    name="subcategory"
                                                                                    placeholder="Enter Function"
                                                                                    className="form-control"
                                                                                    value={modalInput}
                                                                                    onChange={(e) => this.modalhandlechange(e)}
                                                                                />
                                                                                <span className="modal-error-show">{error}</span>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-5">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => this.submitmodal("function_name")}
                                                                                >
                                                                                    submit
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <br></br>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errFunction}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Sub Function</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <SingleSelect
                                                                options={this.state.SubFunctionopt}
                                                                handleChange={d => this.handlechange(d, "Select", "ansSubFunction")}
                                                                selectedService={this.state.ansSubFunction}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3">
                                                            <LoginModal
                                                                buttonTitle="Add Sub Function Unit "
                                                                title="Add Sub Function Unit"
                                                                id="subfunctionunit"
                                                                extraClass="btn-width"
                                                                onClick={this.reset}
                                                                bodyText={
                                                                    <div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={alertVisible}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                New Sub Function Unit Added
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="card-header">
                                                                            <h1>Function</h1>
                                                                        </div>
                                                                        <div className="row form-group pt-5">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2">
                                                                                <label htmlFor="subcategory">Sub Function</label>
                                                                            </div>
                                                                            <div className="col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    name="subcategory"
                                                                                    placeholder="Enter Sub Function"
                                                                                    className="form-control"
                                                                                    value={modalInput}
                                                                                    onChange={(e) => this.modalhandlechange(e)}
                                                                                />
                                                                                <span className="modal-error-show">{error}</span>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-5">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => this.submitmodal("subfunction_name")}
                                                                                >
                                                                                    submit
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <br></br>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errSubFunction}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Region</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <SingleSelect
                                                                options={this.state.Regionopt}
                                                                handleChange={d => this.handlechange(d, "Select", "ansRegion")}
                                                                selectedService={this.state.ansRegion}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3">
                                                            <LoginModal
                                                                buttonTitle="Add Region"
                                                                title="Add Region"
                                                                id="Region"
                                                                extraClass="btn-width"
                                                                onClick={this.reset}
                                                                bodyText={
                                                                    <div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={alertVisible}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                New Region Added
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="card-header">
                                                                            <h1>Region</h1>
                                                                        </div>
                                                                        <div className="row form-group pt-5">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2">
                                                                                <label htmlFor="subcategory">Region</label>
                                                                            </div>
                                                                            <div className="col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    name="subcategory"
                                                                                    placeholder="Enter Region Function"
                                                                                    className="form-control"
                                                                                    value={modalInput}
                                                                                    onChange={(e) => this.modalhandlechange(e)}
                                                                                />
                                                                                <span className="modal-error-show">{error}</span>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-5">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => this.submitmodal("region")}
                                                                                >
                                                                                    submit
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <br></br>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errRegion}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Division</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <SingleSelect
                                                                options={this.state.Divisionopt}
                                                                handleChange={d => this.handlechange(d, "Select", "ansDivision")}
                                                                selectedService={this.state.ansDivision}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3">
                                                            <LoginModal
                                                                buttonTitle="Add Division"
                                                                title="Add Division"
                                                                id="division"
                                                                extraClass="btn-width"
                                                                onClick={this.reset}
                                                                bodyText={
                                                                    <div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={alertVisible}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                New Division Added
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="card-header">
                                                                            <h1>Division</h1>
                                                                        </div>
                                                                        <div className="row form-group pt-5">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2">
                                                                                <label htmlFor="subcategory">Division</label>
                                                                            </div>
                                                                            <div className="col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    name="subcategory"
                                                                                    placeholder="Enter Division Function"
                                                                                    className="form-control"
                                                                                    value={modalInput}
                                                                                    onChange={(e) => this.modalhandlechange(e)}
                                                                                />
                                                                                <span className="modal-error-show">{error}</span>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-5">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => this.submitmodal("division")}
                                                                                >
                                                                                    submit
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <br></br>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errDivision}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Branch</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <SingleSelect
                                                                options={this.state.Branchopt}
                                                                handleChange={d => this.handlechange(d, "Select", "ansBranch")}
                                                                selectedService={this.state.ansBranch}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3">
                                                            <LoginModal
                                                                buttonTitle="Add Branch"
                                                                title="Add Branch"
                                                                id="Branch"
                                                                extraClass="btn-width"
                                                                onClick={this.reset}
                                                                bodyText={
                                                                    <div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={alertVisible}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                New Branch Added
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="card-header">
                                                                            <h1>Branch</h1>
                                                                        </div>
                                                                        <div className="row form-group pt-5">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2">
                                                                                <label htmlFor="subcategory">Branch</label>
                                                                            </div>
                                                                            <div className="col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    name="subcategory"
                                                                                    placeholder="Enter Branch Function"
                                                                                    className="form-control"
                                                                                    value={modalInput}
                                                                                    onChange={(e) => this.modalhandlechange(e)}
                                                                                />
                                                                                <span className="modal-error-show">{error}</span>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-5">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => this.submitmodal("branch")}
                                                                                >
                                                                                    submit
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <br></br>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errBranch}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Office Email Address </label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="Name"
                                                                name="ansoffEmailAdd"
                                                                placeholder="Enter Office Email Address"
                                                                value={this.state.ansoffEmailAdd}
                                                                onChange={this.handlechange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.erroffEmailAdd}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Grade</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <SingleSelect
                                                                options={this.state.Gradeopt}
                                                                handleChange={d => this.handlechange(d, "Select", "ansGrade")}
                                                                selectedService={this.state.ansGrade}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3">
                                                            <LoginModal
                                                                buttonTitle="Add Grade"
                                                                title="Add Grade"
                                                                id="Grade"
                                                                extraClass="btn-width"
                                                                onClick={this.reset}
                                                                bodyText={
                                                                    <div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={alertVisible}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                New Grade Added
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="card-header">
                                                                            <h1>Grade</h1>
                                                                        </div>
                                                                        <div className="row form-group pt-5">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2">
                                                                                <label htmlFor="subcategory">Grade</label>
                                                                            </div>
                                                                            <div className="col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    name="subcategory"
                                                                                    placeholder="Enter Grade "
                                                                                    className="form-control"
                                                                                    value={modalInput}
                                                                                    onChange={(e) => this.modalhandlechange(e)}
                                                                                />
                                                                                <span className="modal-error-show">{error}</span>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-5">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => this.submitmodal("grade")}
                                                                                >
                                                                                    submit
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <br></br>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errGrade}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Band</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <SingleSelect
                                                                options={this.state.Bandopt}
                                                                handleChange={d => this.handlechange(d, "Select", "ansBand")}
                                                                selectedService={this.state.ansBand}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3">
                                                            <LoginModal
                                                                buttonTitle="Add Band"
                                                                title="Add Band"
                                                                id="Band"
                                                                extraClass="btn-width"
                                                                onClick={this.reset}
                                                                bodyText={
                                                                    <div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={alertVisible}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                New Band Added
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="card-header">
                                                                            <h1>Band</h1>
                                                                        </div>
                                                                        <div className="row form-group pt-5">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2">
                                                                                <label htmlFor="subcategory">Band</label>
                                                                            </div>
                                                                            <div className="col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    name="subcategory"
                                                                                    placeholder="Enter Band "
                                                                                    className="form-control"
                                                                                    value={modalInput}
                                                                                    onChange={(e) => this.modalhandlechange(e)}
                                                                                />
                                                                                <span className="modal-error-show">{error}</span>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-5">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => this.submitmodal("band")}
                                                                                >
                                                                                    submit
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <br></br>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errBand}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Employee Category Type</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <SingleSelect
                                                                options={this.state.Empcatopt}
                                                                handleChange={d => this.handlechange(d, "Select", "ansEmpCatType")}
                                                                selectedService={this.state.ansEmpCatType}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3">
                                                            <LoginModal
                                                                buttonTitle="Add Emp_Category"
                                                                title="Add Employee Category Type"
                                                                id="EmployeeCategoryType"
                                                                extraClass="btn-width"
                                                                onClick={this.reset}
                                                                bodyText={
                                                                    <div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={alertVisible}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                New Employee Category Type Added
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="card-header">
                                                                            <h1>Employee Category Type</h1>
                                                                        </div>
                                                                        <div className="row form-group pt-5">
                                                                            <div className="col-sm-1" />
                                                                            <div className="col-sm-4">
                                                                                <label htmlFor="subcategory">Employee Category Type</label>
                                                                            </div>
                                                                            <div className="col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    name="subcategory"
                                                                                    placeholder="Enter Employee Category Type "
                                                                                    className="form-control"
                                                                                    value={modalInput}
                                                                                    onChange={(e) => this.modalhandlechange(e)}
                                                                                />
                                                                                <span className="modal-error-show">{error}</span>
                                                                            </div>
                                                                            <div className="col-sm-2" />
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-5">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => this.submitmodal("empcattype")}
                                                                                >
                                                                                    submit
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <br></br>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errEmpCatType}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">L1 Manager Code</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="Name"
                                                                name="ansL1ManCode"
                                                                placeholder="Enter L1 Manager Code"
                                                                value={this.state.ansL1ManCode}
                                                                onChange={d => this.handlechange(d, "caps")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errL1ManCode}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">L1 Manager</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="Name"
                                                                name="ansL1Man"
                                                                placeholder="Enter L1 Manager"
                                                                value={this.state.ansL1Man}
                                                                onChange={d => this.handlechange(d, "caps")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errL1Man}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">L2 Manager Code </label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="Name"
                                                                name="ansL2ManCode"
                                                                placeholder="Enter L2 Manager Code"
                                                                value={this.state.ansL2ManCode}
                                                                onChange={d => this.handlechange(d, "caps")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errL2ManCode}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">L2 Manager </label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="Name"
                                                                name="ansL2Man"
                                                                placeholder="Enter L2 Manager"
                                                                value={this.state.ansL2Man}
                                                                onChange={d => this.handlechange(d, "caps")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errL2Man}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">HR Manager Code </label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="Name"
                                                                name="ansHRManCode"
                                                                placeholder="Enter HR Manager Code"
                                                                value={this.state.ansHRManCode}
                                                                onChange={d => this.handlechange(d, "caps")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errHRManCode}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">HR Manager </label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="Name"
                                                                name="ansHRMan"
                                                                placeholder="Enter HR Manager"
                                                                value={this.state.ansHRMan}
                                                                onChange={d => this.handlechange(d, "caps")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errHRMan}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Business Designation </label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <SingleSelect
                                                                options={this.state.BusiDesignationopt}
                                                                handleChange={d => this.handlechange(d, "Select", "ansBusiDesignation")}
                                                                selectedService={this.state.ansBusiDesignation}
                                                            />
                                                            {/* <input
                                                    type="text"
                                                    className="form-control"
                                                    id="Name"
                                                    name="ansBranch"
                                                    placeholder="Enter Branch"
                                                    value={this.state.ansBranch}
                                                    onChange={this.handlechange}
                                                /> */}
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errBusiDesignation}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">PASSWORD</label>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                id="password"
                                                                name="password"
                                                                placeholder="Enter Password"
                                                                value={this.state.password}
                                                                onChange={this.handlechange}
                                                            />
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <i class="fa fa-eye" id="togglePassword" style={{ marginLeft: '30px', cursor: 'pointer' }}></i>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.errorpassword}
                                                        </span>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-7">
                                                            <Alert
                                                                className="badge-content"
                                                                color={badgeColor && badgeColor}
                                                                isOpen={this.state.alertVisible}
                                                                toggle={this.onDismiss}
                                                            >
                                                                {badgeText && badgeText}
                                                            </Alert>
                                                        </div>
                                                        <div className="col-sm-3" />
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-5">
                                                            <button
                                                                type="button"
                                                                className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
                                                                disabled={this.state.btnDisable}
                                                                onClick={this.submitCategory}
                                                            >
                                                                {this.state.isEdit ? 'Update' : 'Submit'}
                                                            </button>
                                                        </div>
                                                        <div className="col-sm-3" />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-sm-2"></div>
                                        </div>

                                        <br></br>
                                        <span>
                                            Total of {Object.keys(this.state.data).length} records
                                        </span>
                                        <br></br>


                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                {loading ? <div className="text-center">
                                                    < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                </div> : this.state.data ?
                                                    <>
                                                        {this.state.bulkAllocate == true ?
                                                            <>
                                                                <div className="d-flex justify-content-end">
                                                                    <div className="d-flex justify-content-space-between">

                                                                        <h3 style={{ marginTop: '4px', fontWeight: '600px', fontSize: 'larger' }}>Select all</h3><span>
                                                                            &nbsp; &nbsp;

                                                                            <input type="checkbox"
                                                                                checked={this.state.isSelectUnallocated}
                                                                                onChange={(event) => this.selectalldata(event.target.checked ? "Yes" : "No")} /></span>
                                                                    </div>
                                                                </div>
                                                                <br></br>

                                                                <div className="d-flex justify-content-end">
                                                                    <button type="button"
                                                                        class="btn btn-primary waves-effect"
                                                                        onClick={(d) => this.multyDelete(d)}
                                                                    // onClick={(e) => this.multyDelete(e)}
                                                                    >Delete</button>
                                                                </div>
                                                            </>
                                                            : <></>
                                                        }
                                                        <br></br>
                                                        {/* ( */}
                                                        <Datatable
                                                            data={this.state.data}
                                                            columnHeading={this.column}
                                                        />
                                                        {/* ) */}
                                                    </>
                                                    : <>No data Found</>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {this.state.totalpage && this.state.totalpage > 1 && this.state.pageNumber && <Paginationfun
                        totalpage={this.state.totalpage}
                        page={this.state.pageNumber}
                        pagechage={async (e) => {
                            await this.setState({ pageNumber: e, loading: true, })
                            await this.MainFun()
                        }} />} */}
                </main>
            </React.Fragment>
        );
    }
}

export default AddEmployee;
