import React, { Component } from "react";
import CmsContent from "../../MiddleWare/CmsContent";
import DownPanel from "./DownPanel";
//import RightPanel from "./RightPanel";
import renderHTML from "react-render-html";
import "./style.css";
import "./videoStyle.css";
import NewVideoPlayer from "../../components/Extra/NewVideoPlayer";
import PreLoader from "./preLoader";

class SingleContent2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageList: "",
      date: ""
    };
    let userlog = localStorage.getItem("userlog");
    userlog = JSON.parse(userlog);
    console.log(userlog);
    localStorage.setItem("userId", 166);
    localStorage.setItem("userName", "karan_tst");
    localStorage.setItem("userEmail", "karan1234@gmail.com");
    localStorage.setItem("userMobile",9962181144);
  }

  async componentDidMount() {
    let themeid = this.props.match.params.id;
     console.log(themeid);

    const result = await CmsContent.getThemeById(themeid);

    if (result) {
      if (result.data[0].Date != null && result.data[0].Date != undefined) {
        var dataformate = result.data[0].Date.split("-");
        this.setState({
          date: dataformate[2] + "-" + dataformate[1] + "-" + dataformate[0]
        });
      }
      this.setState({ pageList: result.data[0], themeid });
    }
  }

  async componentWillReceiveProps(nextProps) {
    let themeid = nextProps.match.params.id;
    // console.log(themeid);
    const result = await CmsContent.getThemeById(themeid);
    if (result) {
      if (result.data[0].Date != null && result.data[0].Date != undefined) {
        var dataformate = result.data[0].Date.split("-");
        this.setState({
          date: dataformate[2] + "-" + dataformate[1] + "-" + dataformate[0]
        });
      }
      this.setState({ pageList: result.data[0] });
    }
  }
  coverContent = con => {
    if (this.state.pageList.linkto) {
      //console.log(this.state.pageList.linkto)
      return <a href={`${this.state.pageList.linkto}`}>{con}</a>;
    }
    return con;
  };
  render() {
    const { pageList, date } = this.state;
    console.log(pageList);
    /*if (!pageList) {
      //return <PreLoader />;
    } else if(pageList) { */
      return (
        <React.Fragment>
            <div class="breadcrumb-area">
        <div class="container" style={{backgroundColor:"#fff"}}>
            <div class="row" style={{backgroundColor:"#fff"}}> 
               

                <div class="col-10" style={{marginLeft:"7%"}}>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">ELEC_COM</li>
                        </ol>
                    </nav>
                </div>
                 <br/>
                 <br/>

                <div class="about-us-area section-padding-0-100">
        <div class="container">
            <div class="row">
               <div class="col-1"></div>
                <div class="col-10">
                    <div class="about-content" style={{marginTop:"17px"}}>
                       <div class="section-heading text-center">
                            <a href="">
                        {this.coverContent(
                          <h2 class="post-title" style={{color:"#191919"}}>
                            {pageList.contentTitle1}
                          </h2>
                        )}
                      </a>
                        </div>
                         {/*pageList.file &&
                          this.coverContent(
                            <img
                              className="box-shadow-img"
                              src={pageList.file}
                              alt="something went wrong"
                            />
                          )*/}
                        <div class="post-share">
                                             {pageList.content1 &&
                        this.coverContent(
                          <p >
                            {renderHTML(pageList.content1)}
                          </p>
                        )}

                      {this.state.themeid && false && (
                        <NewVideoPlayer Video={this.state.themeid} />
                      )}
                      <br/>
<br/>
                      <div
                      className="blog-post-author d-flex"
                      style={{ marginTop: "42px" }}
                    >
                      {pageList && pageList.show === "1" && (
                        <div>
                          <div className="author-thumbnail">
                            <img src={pageList.authorimage} alt="" />
                          </div>
                          <div className="author-info">
                            <a className="author-name">
                              {pageList.authorname}, <span>The Author</span>
                            </a>
                            <p>{pageList.authorabout}</p>
                          </div>
                        </div>
                      )}
                    </div>
                      
 

                     </div>
                     </div>
                   </div>
                  </div></div></div>


  
    
            </div>
        </div>
    </div>
          
        </React.Fragment>
      );
    
  }
}

export default SingleContent2;
