import React, { Component } from 'react';
import BarChart from '../../components/Chart/BarChart';

const barData = {
  labels: ['fsdfsdfsd', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      label: 'My First dataset',
      backgroundColor: 'rgba(255,99,132,0.1)',
      borderColor: 'rgba(255,99,132,1)',
      borderWidth: 1,
      hoverBackgroundColor: 'rgba(255,99,132,0.1)',
      hoverBorderColor: 'rgba(255,99,132,1)',
      data: [65, 59, 80, 81, 56, 55, 40]
    }
  ]
};
const options = {
  title: {
    display: true,
    position: 'top',
    fontSize: 20,
    text: 'Bar Chart Title' //
  },
  scales: {
    xAxes: [
      {
        display: true,
        gridLines: {
          color: 'rgb(255,255,255)'
        },
        scaleLabel: {
          display: true,
          fontSize: 26,
          fontWeight: 'bold',
          labelString: 'Months'
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          display: true
        },
        ticks: {
          beginAtZero: true,
          max: 100,
          stepSize: 25
        },
        scaleLabel: {
          display: true,
          labelString: 'Total'
        }
      }
    ]
  }
};
export default class AdminChart extends Component {
  render() {
    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3>Heading</h3>
                </div>
                <div className="card-body">
                  <BarChart dataValue={barData} barOptions={options} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
