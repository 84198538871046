import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CmsContent from '../../MiddleWare/CmsContent';
import './usernav.css';
import logo from '../../images/CMS/murugappa_logo1.png';

class HomeMobileNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      categoryArrray: [],
      fullList: [],
      adminId:JSON.parse(localStorage.getItem("userlog")).customerId
    };
  }

  async componentDidMount() {
    try {
      const result = await CmsContent.getFreedom(
        'tbl_mapping_page.id as subcatorderid,tbl_mapping_page.listingorder,tbl_mapping_page.listingsubcatagory,tbl_mapping_page.categoryId,tbl_mapping_page.categoryName,tbl_mapping_page.subCategoryId,tbl_mapping_page.subCategoryName,tbl_pages.id as pageId ,theme_master.themeName,tbl_pages.requireLogin',
        'tbl_mapping_page,tbl_pages,theme_master',
        `tbl_mapping_page.pageId = tbl_pages.id and tbl_pages.themeId =  theme_master.themeId and tbl_pages.customerId=${this.state.adminId} and tbl_pages.status="show" and  tbl_mapping_page.status = "active"`,
        'tbl_mapping_page.id',
        'tbl_mapping_page.listingorder,tbl_mapping_page.listingsubcatagory'
      );;
      if (result) {
       
        this.setState({ categories: result.data });
      }
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const { categories } = this.state;
    var categoryData = [];
    var fullList = [];
    if (categories.length > 0) {
      const map = new Map();
      for (const item of categories) {
        if (!map.has(item.categoryId)) {
          map.set(item.categoryId, true); // set any value to Map
          categoryData.push({ categoryId: item.categoryId });
        }
      }

      fullList = categoryData.map(values => {
        return categories.filter(list => list.categoryId === values.categoryId);
      });
    }
    var chunk_size = 5;
    var groups = '';
    var fullData = [];
    if (fullList.length > 0) {
      fullList.map(values => {
        if (values[0].subCategoryName) {
          groups = values
            .map(function(e, i) {
              return i % chunk_size === 0 ? values.slice(i, i + chunk_size) : null;
            })
            .filter(function(e) {
              return e;
            });
          var innerJsonData = [];
          groups.map(innerData =>
            innerJsonData.push(
              <div class="item">
                {innerData.map(dataValue => (
                  <a href="#">
                    <Link className="dropdown-item text-capitalize colorchange" to={`/CMS/${dataValue.themeName}/${dataValue.pageId}`}>
                      {dataValue.subCategoryName}
                    </Link>
                  </a>
                ))}
              </div>
            )
          );
          fullData.push(
            <div class="item">
              <a href="#">
                <a className="nav-link dropdown-toggle nav-font-color" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {values[0].categoryName}
                </a>
                <div className="dropdown-menu w-100" style={{ backgroundColor: 'white', marginTop: '12px' }} aria-labelledby="navbarDropdownMenuLink">
                  {innerJsonData}
                </div>
              </a>
            </div>
          );
        } else {
          fullData.push(
            <div class="item">
              <a href="#">
                <Link className="nav-link" to={`/CMS/${values[0].themeName}/${values[0].pageId}`}>
                  <span className="nav-font-color">{values[0].categoryName}</span>
                </Link>
              </a>
            </div>
          );
        }
      });
    }

    return (
      <React.Fragment>
        <nav>{fullData}</nav>
      </React.Fragment>
    );
  }
}

export default HomeMobileNav;
