
import React, { Component } from "react";
import { Alert } from "reactstrap";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";
import TableExample from '../../../components/PDF/TableExample';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink, CSVDownload } from "react-csv";

class Program extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      alertVisible: false,
      alertVisible1: false,
      editvalue: "",
      editid: null,
      disableValue: false,
      addNewSubgroup: false,
      error: null,
      button: "Submit",
      index: null,
      formAlertdelete: false,
      customerId: localStorage.getItem("userId"),
      textalert: "New Program Added",
      color: "success"
    };
  }
  async componentWillMount() {
    try {
      // const result = await CmsContent.getSingleConditionedValue(
      //   "tbl_program",
      //   "customerId",
      //   this.state.customerId
      // );
      const result = await CmsContent.getFreedom("*",
        "tbl_program",
        `customerId =${this.state.customerId}`, 1, 1)
      if (result) {
        // console.log(result.trim());
        //this.setState({ data: result.data });
        // console.log(this.state.data);
      }
      if (result.data.length > 0) {

        const excelHead = [
          { label: "Program Name", key: "name" }
        ];
        const header = [
          'SI.NO',
          'PROGRAM',

        ];

        this.setState({
          header,
          excelHead,
          data: result.data,
          title: "PROGRAM"
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  column = [
    {
      Header: "Programs",
      accessor: "name"
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: (d, modalWindowId) =>
        this.dataTableButton("warning", "Edit", () => {
          this.buttonEdit(d, modalWindowId);
        })
    },
    {
      Header: "Delete",
      accessor: "delete",
      Cell: d =>
        this.dataTableButton("danger", "Delete", () => {
          this.buttonDeletes(d.original);
        })
    }
  ];

  // column= [
  //   {
  //     name: <b>Programs</b>,
  //     selector: "name",
  //     sortable: true,
  //   },
  //   {
  //         name: <b>Edit</b>,
  //          selector: "edit",

  //          cell: (d, modalWindowId) =>
  //            this.dataTableButton("warning", "Edit", () => {
  //              this.buttonEdit(d, modalWindowId);
  //            }),


  //          },
  //          {
  //               name: <b>Delete</b>,
  //               selector: "delete",

  //               cell: d =>
  //                 this.dataTableButton("danger", "Delete", () => {
  //                   this.buttonDeletes(d);
  //                 })
  //             }



  // {
  //   name: <b>Status</b>,
  //   selector: d => this.getStatus(d),
  //   sortable: true,
  //   cell: d => this.getStatus(d)
  // },
  // {
  //   name: <b>Score</b>,
  //   selector: d => this.getScore(d),
  //   sortable: true,
  //   cell: d => this.getScore(d)
  // }

  // getStatus = d => {
  //   console.log("entered")
  //   console.log(d)
  //   return  <div className="row col-12"><div className="col-3 p-0" style={{ marginTop: 'auto' }}>Completed</div><div className="col-6"></div></div> 
  // }

  buttonEdit = value => {
    // console.log(value);
    let values = value.original;
    this.setState({
      index: value.index,
      editvalue: values.name,
      editid: values.id,
      error: null,
      button: "Update",
      textalert: "New Program Updated",
      color: "success"
    });
  };
  buttonDeletes = value => {
    this.setState({});
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.buttonDelete(value)
          //onClick: () => alert("Group removed from")
        },
        {
          label: "No"
        }
      ]
    });
  };
  buttonDelete = async value => {
    const previousData = [...this.state.data];
    let id = value.id;
    // console.log(id);
    try {
      const result = await CmsContent.getSingleConditionedValue(
        "tbl_program",
        "id",
        id,
        "Delete"
      );
      if (result) {
        //console.log(result);
        let datas = previousData.filter((delelteid, i) => {
          // console.log(delelteid.value + "->" + value.id);
          if (delelteid.id !== value.id) {
            return delelteid;
          }
        });
        this.setState({
          data: datas,
          formAlertdelete: true,
          color: "danger",
          alertVisible: true,
          textalert: "Selected Program Deleted"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  getValueFromArrays = (d, array) => {
    // console.log(d);
    // console.log(array);
    // console.log('testing -> ' + d + ' array -> ' + JSON.stringify(array));
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          // console.log(item.id + '=' + d);
          return item.id == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          //console.log(v);
          return v.name;
        } else {
          return "---";
        }
      }
    } else {
      return "-";
    }
  };
  handlechange = e => {
    let value = e.target.value;
    //console.log(value);
    this.setState({ editvalue: value });
  };
  addnew = async () => {
    //let value = this.state.editvalue;
    const { editvalue: value, customerId } = this.state;
    // console.log(this.state.editvalue);
    if (value === null || value.trim() === "") {
      this.setState({ error: "Please Fill The Content" });
      return false;
    }
    let groupArray = {};

    groupArray.name = value.trim();
    //console.log(value.trim());
    groupArray.status = "active";
    groupArray.customerId = this.state.customerId;
    // console.log(data)
    try {
      this.setState({ disableValue: true });
      const result = await CmsContent.addMaster("tbl_program", groupArray);
      //console.log(result)
      if (result) {
        // console.log(result);
        let valueArray = {};
        // console.log(result.data);
        valueArray.id = result.data.insertId;
        valueArray.name = groupArray.name;
        // console.log(valueArray.id)
        valueArray.customerId = this.state.customerId;
        //console.log(valueArray);
        const newData = [valueArray, ...this.state.data];
        this.setState({
          data: newData,
          customerId,
          alertVisible: true,
          editvalue: "",
          editid: null,
          disableValue: false,
          addNewSubgroup: false,
          error: null,
          button: "Submit",
          index: null
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  update = async () => {
    //    let value = this.state.editvalue;
    const { editvalue: value } = this.state;
    // console.log(value);
    if (value === "") {
      this.setState({ error: "Value Cannot Be Empty" });
      return false;
    } else {
      this.setState({ disableValue: true, error: null });
      try {
        let previousdata = [...this.state.data];
        //  let index = this.state.index;
        // let id = this.state.editid;
        const { index, editid } = this.state;
        let id = this.state.editid;
        //console.log(id);
        let subgroupArray = {};
        subgroupArray.name = value;
        subgroupArray.status = "active";
        const result = await CmsContent.updateMaster(
          "tbl_program",
          id,
          subgroupArray
        );
        if (result) {
          previousdata[index].name = value;
          setTimeout(() => this.setState({ data: previousdata }), 500);
        }
      } catch (error) {
        console.log(error);
      }
      this.setState({
        alertVisible: true,
        editvalue: "",
        editid: null,
        addNewSubgroup: false,
        error: null,
        disableValue: false,
        button: "Submit",
        index: null
      });
    }
  };
  submited = () => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => alert("Click Yes")
        },
        {
          label: "No",
          onClick: () => alert("Click No")
        }
      ]
    });
  };
  // setRedirect = () => {
  //   this.setState({
  //     redirect: true
  //   });
  // };
  // renderRedirect = () => {
  //   if (this.state.redirect) {
  //     return <Redirect to="/DF/Program" />;
  //   }
  // };
  onDismiss = () => {
    this.setState({ alertVisible: false });
    this.setState({ alertVisible1: false });
    this.setState({ formAlertdelete: false });
  };



  render() {
    const {
      editvalue,
      alertVisible,
      alertVisible1,
      disableValue,
      error,
      button,
      formAlertdelete,
      textalert
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Programs Master</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {/* New Program Added */}
                          {textalert}
                        </Alert>
                        {/* <Alert
                          className="badge-content"
                          color="success"
                          isOpen={alertVisible1}
                          toggle={this.onDismiss}
                        >
                          New Program Updated
                        </Alert>
                        <Alert
                          className="badge-content"
                          color="danger"
                          isOpen={formAlertdelete}
                          toggle={this.onDismiss}
                        >
                          Selected Program Deleted
                        </Alert> */}
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="subgroup">Programs</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          name="subgroup"
                          placeholder="Enter Program"
                          className="form-control"
                          value={editvalue}
                          onChange={e => this.handlechange(e)}
                        />
                        <span className="error-shows">{error}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={disableValue}
                          onClick={
                            button === "Update" ? this.update : this.addnew
                          }
                        >
                          {this.state.button}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    {this.state.data && this.state.data.length > 0 && (
                      <PDFDownloadLink
                        style={{ color: 'white' }}
                        document={<TableExample title={this.state.title} header={this.state.header} data={this.state.data} />}
                        fileName="sample.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? 'Loading document...' : 'Download Pdf'
                        }
                        <button className="btn btn-warning ">
                          PDF
                    </button>
                      </PDFDownloadLink>
                    )}
                    {this.state.data && this.state.data.length > 0 && (
                      <CSVLink data={this.state.data} headers={this.state.excelHead}>
                        <button className="btn btn-success" style={{ marginLeft: '11px' }}>
                          Excel
                    </button>
                      </CSVLink>
                    )}
                    <div style={{ marginTop: '5px' }}></div>
                    <span>
                      Total of {Object.keys(this.state.data).length} records
                    </span>
                    {this.state.data && (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Program;

