import React, { Component } from "react";
import { Alert } from "reactstrap";
import LoginModal from "../../../components/Modal/Modal";
import AddCategory from "./AddCategory";
import SingleSelect from "../../../components/Form/SingleSelect";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import CmsmappingPDF from "../../../components/PDF/CMSMappingpdf";

class MapPageToHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categorySelected: "",
      categoryId: "",
      categoryName: "",
      categoryOptions: [],
      pageSelected: "",
      pageId: "",
      pageName: "",
      pageOptions: [],
      category: "",
      data: [],
      content1: "",
      file1: "",
      fileName1: "choose File",
      errorCategory: "",
      errorTitle: "",
      errorContent: "",
      errorFile1: "",
      errorPageId: "",
      alertVisible: false,
      btnDisable: false,
      customerId: localStorage.getItem("userId"),
    };
  }

  column = [
    {
      Header: "Category",
      accessor: "categoryName",
    },
    {
      Header: "Content",
      accessor: "content1",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: (d) => this.edit(d, "customer"),
    },
    {
      Header: "Delete",
      accessor: "delete",
      Cell: (d) => this.delete(d),
    },
  ];

  edit = (value, modalWindowId) => {
    return (
      <button
        type="button"
        data-toggle="modal"
        data-target={`#${modalWindowId}`}
        className="btn btn-warning"
        onClick={() => this.buttonEdit(value)}
      >
        Edit
      </button>
    );
  };

  delete = (value) => {
    return (
      <button
        type="button"
        className="btn btn-danger"
        onClick={() => this.buttonDelete(value)}
      >
        Delete
      </button>
    );
  };

  buttonEdit = (value) => {
    console.log(value,"value");
    
  };
  buttonDelete=(value)=>{
    //console.log(value);
  }

  async componentDidMount() {
    try {
      const pages = await CmsContent.getFreedom(
        "id as value,name as label",
        "tbl_pages",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (pages) {
        this.setState({ pageOptions: pages.data });
      }
      const category = await CmsContent.getFreedom(
        "id as value,name as label",
        "tbl_homePanel_title",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (category) {
        this.setState({ categoryOptions: category.data });
      }
      const getAllMappedpages = await CmsContent.getFreedom(
        "categoryName,content1,status",
        "tbl_homePanel_mapping",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (getAllMappedpages) {
        this.setState({ data: getAllMappedpages.data });
      }
      if (getAllMappedpages.data.length > 0) {
        const header = ["SI.NO", "CATEGORYNAME", "CONTENT", "STATUS"];
        const excelHead = [
          { label: "CATEGORY", key: "categoryName" },
          { label: "CONTENT", key: "content1" },
          { label: "STATUS", key: "status" },
        ];
        this.setState({
          excelHead,
          header,
          excel: getAllMappedpages.data,
          title: "MAP PAGE TO RIGHT HOME",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  selectImage1 = (e) => {
    this.setState({ file1: e.target.files[0] });
    this.setState({ fileName1: e.target.files[0].name });
  };

  addNewCategory = async () => {
    let categoryArray = {};
    let categoryAddExtra = {};
    let categoryName = this.state.category;
    let categoryOptions = this.state.categoryOptions;

    if (!categoryName) {
      this.setState({ errorCategory: "Enter Categoty Name" });
      return false;
    } else {
      this.setState({ errorCategory: "", btnDisable: true });
      categoryArray.name = categoryName.trim();
      categoryArray.status = "active";
      categoryArray.customerId = this.state.customerId;
      try {
        const result = await CmsContent.addMaster(
          "tbl_homePanel_title",
          categoryArray
        );
        if (result) {
          categoryAddExtra.value = result.data.insertId;
          categoryAddExtra.label = categoryName;
          categoryOptions.push(categoryAddExtra);
          this.setState({
            category: "",
            btnDisable: false,
            alertVisible: true,
            categoryOptions,
          });
          setTimeout(() => this.setState({ alertVisible: false }), 3000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  categorySelect = (selectedOption) => {
    this.setState({
      categorySelected: selectedOption,
      categoryId: selectedOption.value,
      categoryName: selectedOption.label,
    });
  };

  pageSelect = (selectedOption) => {
    this.setState({
      pageSelected: selectedOption,
      pageId: selectedOption.value,
      pageName: selectedOption.label,
    });
  };

  submitCategory = async (e) => {
    e.preventDefault();
    const { categoryId, categoryName, pageId, file1, content1 } = this.state;

    if (!categoryId) {
      this.setState({ errorTitle: "Select Panel Title" });
      return false;
    } else if (!content1) {
      this.setState({ errorTitle: "", errorContent: "Enter Panel Content" });
      return false;
    } else if (!file1) {
      this.setState({ errorContent: "", errorFile1: "Select Image" });
      return false;
    } else if (!pageId) {
      this.setState({ errorFile1: "", errorPageId: "Select Page" });
      return false;
    } else {
      this.setState({
        errorPageId: "",
        btnDisable: true,
      });
      const formData = new FormData();
      formData.append("categoryId", categoryId);
      formData.append("categoryName", categoryName);
      formData.append("pageId", pageId);
      formData.append("status", "active");
      formData.append("file1", file1);
      formData.append("content1", content1.trim());
      formData.append("customerId", this.state.customerId);
      try {
        const result = await CmsContent.rightPanelAdd(
          "tbl_homePanel_mapping",
          formData
        );
        if (result) {
          const userData = {
            id: parseInt(result.data.insertId),
            categoryName: categoryName,
            content1: content1,
            status: "active",
          };
          const newData = [userData, ...this.state.data];
          this.setState({
            data: newData,
            pageSelected: "",
            categorySelected: "",
            categoryId: "",
            pageId: "",
            fileName1: "",
            content1: "",
            file1: "",
            alertVisible: true,
            btnDisable: false,
          });
          setTimeout(() => this.setState({ alertVisible: false }), 3000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  onDismiss = () => {
    this.setState({ alertVisible: false, formAlert: false });
  };

  render() {
    const {
      errorCategory,
      alertVisible,
      btnDisable,
      errorTitle,
      errorContent,
      errorFile1,
      errorPageId,
      content1,
    } = this.state;

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Map Page To Home</h3>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color="success"
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          New Page Added To Home Panel
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Select Title</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          handleChange={this.categorySelect}
                          options={this.state.categoryOptions}
                          selectedService={this.state.categorySelected}
                        />
                      </div>
                      <div className="col-sm-3">
                        <LoginModal
                          buttonTitle="Add Panel Title"
                          title="Add panel Title"
                          id="Category"
                          extraClass="btn-width"
                          onClick={this.reset}
                          bodyText={
                            <AddCategory
                              category={this.state.category}
                              handleChange={this.handleChange}
                              addNewCategory={this.addNewCategory}
                              error={errorCategory}
                              alert={alertVisible}
                              dismiss={this.onDismiss}
                              disableValue={btnDisable}
                              alertMsg="New Panel Title Added"
                            />
                          }
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorTitle}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Add Content</label>
                      </div>
                      <div className="col-sm-5">
                        <textarea
                          type="text"
                          class="form-control"
                          id="content1"
                          name="content1"
                          placeholder="Enter First Content"
                          onChange={this.handleChange}
                          value={content1}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorContent}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Upload Image</label>
                      </div>
                      <div className="col-sm-5 custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          onChange={this.selectImage1}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          {this.state.fileName1}
                        </label>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorFile1}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Select Page</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          handleChange={this.pageSelect}
                          options={this.state.pageOptions}
                          selectedService={this.state.pageSelected}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorPageId}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          class="btn btn-primary"
                          disabled={btnDisable}
                          onClick={this.submitCategory}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <br></br>
                    {this.state.excel && this.state.excel.length > 0 ? (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <CmsmappingPDF
                            title={this.state.title}
                            header={this.state.header}
                            data={this.state.excel}
                          />
                        }
                        fileName="Mappagetopanel.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    ) : null}
                    {this.state.data && this.state.data.length ? (
                      <CSVLink
                        data={this.state.data}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          Excel
                        </button>
                      </CSVLink>
                    ) : null}
                    <br></br>
                    <br></br>

                    {this.state.data && (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default MapPageToHome;
