import React, { Component } from "react";
import CmsContent from "../../../../MiddleWare/CmsContent";
import SingleSelect from "../../../../components/Form/SingleSelect";
import Switch from "react-switch";

class Type6 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: localStorage.getItem("userId"),
      button: this.props.button,
      boxname: "",
      boxvalue: "",
      thumbnail: "",
      traytext: "",
      portletHeading: "",
      descriptionText: "",
      newdate: "",
      errorQuiz: null,
      attendance: false,
      eventType: [
        { value: "new", label: "Customised" },
        { value: "lob", label: "From LOB" }
      ]
    };
  }

  async componentDidMount() {
    const locationList = await CmsContent.getConditionedValuewithStatus(
      "tbl_location",
      "customerId",
      this.state.customerId,
      "id",
      "name"
    );
    if (locationList) {
      this.setState({ locationOptions: locationList.data });
    }
    const programList = await CmsContent.getConditionedValuewithStatus(
      "tbl_program",
      "customerId",
      this.state.customerId,
      "id",
      "name"
    );
    if (programList) {
      this.setState({ programOptions: programList.data });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        button: nextProps.button,
        boxname: nextProps.boxname,
        boxvalue: nextProps.boxvalue,
        thumbnail: nextProps.thumbnail,
        traytext: nextProps.traytext,
        portletHeading: nextProps.portletHeading,
        descriptionText: nextProps.descriptionText,
        newdate: nextProps.newdate
      });
    }
  }

  refresh = async () => {
    const quizList = await CmsContent.getTwoConditionedValue(
      "tbl_question",
      "type",
      "active",
      "customerid",
      this.state.customerId,
      "id as value ,quizName as label"
    );
    if (quizList) {
      this.setState({ quizOptions: quizList.data });
    }
  };

  attendance = () => {
    this.setState({ attendance: !this.state.attendance });
  };

  submitCommon = async formData => {
    try {
      const result = await CmsContent.mappingportlet(
        formData,
        "tbl_boxcontent"
      );
      if (result) {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        this.props.handler(this.state.boxvalue,this.props.alertVisible)
        // this.setState({ alertVisible: true, boxvalue: "" });
        // setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  onSubmit = async () => {
    if (!this.state.boxname) {
      this.props.errormessageboxname("errorname", "Please Enter Box Name");
      return false;
    } else {
      this.props.errormessageboxname("errorname", "");
    }
    if (!this.state.portletHeading) {
      this.props.errormessageheading(
        "errorheading",
        "Please Enter Portlet Heading"
      );
      return false;
    } else {
      this.props.errormessageheading("errorheading", "");
    }
    if (!this.state.thumbnail) {
      this.props.errorthumbnail("errorthumbnail", "Please Select a thumbnail");
      return false;
    } else {
      this.props.errorthumbnail("errorthumbnail", "");
    }
    if (!this.state.selectedEvents) {
      this.setState({ errortext: "Please Select Event Type" });
      return false;
    } else {
      this.setState({ errortext: "" });
    }

    if (!this.state.locationSelect) {
      this.setState({ locationerror: "Please Select Location" });
      return false;
    } else {
      this.setState({ locationerror: "" });
    }
    

     if(this.state.boxname && this.state.thumbnail && this.state.portletHeading && this.state.selectedEvents && this.state.locationSelect)
     {
    let formData = new FormData();
    formData.append("name", this.state.boxname);
    formData.append("type", this.state.boxvalue);
    formData.append("status", "active");
    formData.append("customerId", this.state.customerId);
    formData.append("thumbnail", this.state.thumbnail);
    formData.append("attendance", this.state.attendance ? 1 : 0);
    formData.append("notificationtext", this.state.traytext);
    formData.append("heading", this.state.portletHeading);
    formData.append("description", this.state.descriptionText);
    formData.append("createdAt", this.state.newdate);
    formData.append("programname", this.state.programName);
    formData.append("fromdate", this.state.fromDateSelect);
    formData.append("todate", this.state.toDateSelect);
    formData.append("batchname", this.state.batchName);
    formData.append("location", this.state.locationSelect.label);
   
    console.log([...formData])
     this.submitCommon(formData);
     }
  };

  addtext = async () => {


    if (!this.state.boxname) {
      this.props.errormessageboxname("errorname", "Please Enter Box Name");
      return false;
    } else {
      this.props.errormessageboxname("errorname", "");
    }
    if (!this.state.portletHeading) {
      this.props.errormessageheading(
        "errorheading",
        "Please Enter Portlet Heading"
      );
      return false;
    } else {
      this.props.errormessageheading("errorheading", "");
    }
    if (!this.state.thumbnail) {
      this.props.errorthumbnail("errorthumbnail", "Please Select a thumbnail");
      return false;
    } else {
      this.props.errorthumbnail("errorthumbnail", "");
    }
    
    if (!this.state.selectedEvents) {
      this.setState({ errortext: "Please Select Event Type" });
      return false;
    } else {
      this.setState({ errortext: "" });
    }

    if (!this.state.locationSelect) {
      this.setState({ locationerror: "Please Select Location" });
      return false;
    } else {
      this.setState({ locationerror: "" });
    }
    

    if(this.state.boxname && this.state.thumbnail && this.state.portletHeading && this.state.selectedEvents && this.state.locationSelect)
    {

    let formData = new FormData();
    formData.append("name", this.state.boxname);
    formData.append("type", this.state.boxvalue);
    formData.append("status", "active");
    formData.append("customerId", this.state.customerId);
    formData.append("thumbnail", this.state.thumbnail);
    formData.append("attendance", this.state.attendance ? 1 : 0);
    formData.append("notificationtext", this.state.traytext);
    formData.append("heading", this.state.portletHeading);
    formData.append("description", this.state.descriptionText);
    formData.append("createdAt", this.state.newdate);
    formData.append("programname", this.state.programName);
    formData.append("fromdate", this.state.fromDateSelect);
    formData.append("todate", this.state.toDateSelect);
    formData.append("batchname", this.state.batchName);
    formData.append("location", this.state.locationSelect.label);

       this.submitCommon(formData);
    }
  };

  programName = e => {
    this.setState({ programName: e.target.value });
  };

  fromDateSelect = e => {
    this.setState({ fromDateSelect: e.target.value });
    let today = new Date();
    var dateformat = today.getDate();
    if (today.getDate() < 10) {
      dateformat = "0" + today.getDate();
    }
    var monthformate = today.getMonth() + 1;
    if (monthformate < 10) {
      monthformate = "0" + (today.getMonth() + 1);
    }
    let todaydate = today.getFullYear() + "-" + monthformate + "-" + dateformat;

    if (todaydate > e.target.value) {
      this.setState({ startdateerror: "Please check start date" });
    } else {
      this.setState({ startdateerror: "" });
    }
  };
  toDateSelect = e => {
    this.setState({ toDateSelect: e.target.value });
    if (this.state.selectedstartdate > e.target.value) {
      this.setState({ endateerror: "Please check end date" });
      return false;
    } else {
      this.setState({ endateerror: "" });
    }

    let firstdate = new Date(this.state.fromDateSelect);
    let secondate = new Date(e.target.value);
    let diffTime = Math.abs(secondate - firstdate);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let today = new Date();

    var dateformat = today.getDate();
    if (today.getDate() < 10) {
      dateformat = "0" + today.getDate();
    }
    var monthformate = today.getMonth() + 1;
    if (monthformate < 10) {
      monthformate = "0" + (today.getMonth() + 1);
    }

    let todaydate = today.getFullYear() + "-" + monthformate + "-" + dateformat;

    if (todaydate > this.state.toDateSelect) {
      this.setState({ calculatedateerror: "Please check start date" });
    }

    if (diffDays >= 7) {
      this.setState({
        calculatedateerror:
          "Please choose the date between 7 days from the start date"
      });
    } else {
      this.setState({ calculatedateerror: "" });
    }
    if (todaydate > e.target.value) {
      this.setState({ endateerror: "Please Change End Date " });
      return false;
    } else {
      this.setState({ endateerror: "" });
    }
  };
  locationSelect = e => {
    this.setState({ locationSelect: e });
  };
  eventsChange = e => {
    this.setState({ events: e.value, selectedEvents: e });
  };
  selectedprogram = async e => {
    this.setState({ selectedprogram: e });
    let val = e.value;
    try {
      let getBatch = await CmsContent.getConditionedValuewithStatus(
        "tbl_batch",
        "programid",
        val
      );
      if (getBatch) {
        this.setState({ batchOptions: getBatch.data, programName: e.label });
      }
    } catch (error) {
      console.log(error);
    }
  };
  batchSelect = async e => {
    this.setState({ batchSelect: e });
    let val = e.value;
    try {
      let getBatch = await CmsContent.getConditionedValuewithStatus(
        "tbl_learningobject",
        "batchid",
        val,
        "id",
        "programId"
      );
      if (getBatch) {
        let data = getBatch.data;
        let listingDate = [];
        let listingDates = await data.map(async (ival, i) => {
          let value = {};
          value.label = ival.fromdate + " to " + ival.enddate;
          value.value = ival.id;
          value.location = ival.location;
          await listingDate.push(value);
        });
        this.setState({ lobs: listingDate, batchName: e.label });
      }
    } catch (error) {
      console.log(error);
    }
  };
  lobSelect = async e => {
    let label = e.label;
    let arr = label.split(" to ");
    let getLocation = await CmsContent.getConditionedValuewithStatus(
      "tbl_location",
      "id",
      e.location
    );
    this.setState({
      lobSelect: e,
      fromDateSelect: arr[0],
      toDateSelect: arr[1],
      locationSelect: getLocation.data[0]
    });

  };

  render() {
    const {} = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-12">
            <div className="card-body">
              <div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="exampleInputEmail1">Event Type</label>
                  </div>
                  <div className="col-sm-5">
                    <SingleSelect
                      options={this.state.eventType}
                      handleChange={this.eventsChange}
                      placeholder="Select Event Type"
                      value={this.state.selectedEvents}
                    />
                    <span className="error-shows ">{this.state.errortext}</span>
                  </div>
                  <div className="col-sm-3">
                    <label>Attendance&nbsp;:</label>&nbsp;&nbsp;
                    <Switch
                      height={18}
                      width={41}
                      marginTop={20}
                      paddingTop={0}
                      onChange={this.attendance}
                      checked={this.state.attendance}
                    />
                  </div>
                </div>
                {this.state.events === "new" && (
                  <div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail8">Program Name</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Program Name"
                          onChange={this.programName}
                          value={this.state.programName}
                        />
                      </div>
                      <div className="col-sm-3">
                        {/* {this.state.attendance  ?(
                                    <div>
                                      <QRCode id="123456" value="http://facebook.github.io/react/" />
                                      <a href="#" onClick={this.downloadQR}> Download QR </a>
                                    </div>
                                  ):null} */}
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">Date</div>
                      <div className="col-sm-3">
                        From Date
                        <input
                          type="date"
                          id="myDate"
                          className="form-control"
                          onChange={this.fromDateSelect}
                          value={this.state.fromDateSelect}
                        />
                        <span className="error-shows">
                          {this.state.startdateerror}
                        </span>
                      </div>
                      <div className="col-sm-3">
                        To Date
                        <input
                          type="date"
                          className="form-control"
                          onChange={this.toDateSelect}
                          value={this.state.toDateSelect}
                        />
                        <span className="error-shows">
                          {this.state.endateerror}
                        </span>
                      </div>
                      <div className="col-sm-2 error-shows"> </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2"></div>
                      <div className="col-sm-6 error-shows">
                        {" "}
                        {this.state.calculatedateerror}{" "}
                      </div>
                      <div className="col-sm-2 "> </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail8">Location</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          options={this.state.locationOptions}
                          handleChange={this.locationSelect}
                          selectedService={this.state.locationSelect}
                        />
                        <span className="error-shows">
                          {this.state.locationerror}
                        </span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                  </div>
                )}
                {this.state.events === "lob" && (
                  <div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail8">Program Name</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          handleChange={this.selectedprogram}
                          options={this.state.programOptions}
                          selectedService={this.state.selectedprogram}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">Select Batch</div>
                      <div className="col-sm-5">
                        <SingleSelect
                          handleChange={this.batchSelect}
                          options={this.state.batchOptions}
                          selectedService={this.state.batchSelect}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail8">Dates</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          options={this.state.lobs}
                          handleChange={this.lobSelect}
                          selectedService={this.state.lobSelect}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                  </div>
                )}
                <div className="row form-group">
                  <div className="col-sm-4" />
                  <div className="col-sm-5">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={
                        this.state.button === "Add"
                          ? this.addtext
                          : this.onSubmit
                      }
                    >
                      {this.state.button}
                    </button>
                  </div>
                  <div className="col-sm-3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Type6;
