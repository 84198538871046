import React, { Component } from 'react';
import { ACCESS_POINT } from '../../config';
import login from '../../MiddleWare/Login';
import CmsContent from '../../MiddleWare/CmsContent';
import { Link } from 'react-router-dom';
import MessageComponent from './MessageComponent';
import ScriptTag from 'react-script-tag';
class HomePanelList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageList: '',
      themeid: '',
      date: '',
      placeHolder: 1,
      showResend: false,
      exemption: [41, 43, 44, 45, 46, 47, 49, 58],
      loaded: true,
      preloader:false
      // adminId: JSON.parse(localStorage.getItem("userlog")).customerId
    };
  }
  scrollTop = () => {
    document.getElementById('signin').scrollIntoView(true);
  };
  loadScript = src => {
    this.setState({ loaded: false });
    return <ScriptTag type="text/javascript" src={src} />;
  };
  async componentWillMount() {
    let userlog = JSON.parse(localStorage.getItem('userlog'));
    if (userlog) {
      this.setState({ adminId: userlog.customerId, userlog });
    } else {
      this.setState({ adminId: 117 });
    }
  }
  async componentDidMount() {
    this.setState({ themeId: this.props.themeId });
    try {
      const { data: categories } = await CmsContent.getFreedom(
        'tbl_mapping_page.id as subcatorderid,tbl_mapping_page.listingorder,tbl_mapping_page.listingsubcatagory,tbl_mapping_page.categoryId,tbl_mapping_page.categoryName,tbl_mapping_page.subCategoryId,tbl_mapping_page.subCategoryName,tbl_pages.id as pageId ,theme_master.themeName,tbl_pages.requireLogin',
        'tbl_mapping_page,tbl_pages,theme_master',
        `tbl_mapping_page.pageId = tbl_pages.id and tbl_pages.themeId =  theme_master.themeId and tbl_pages.customerId=${this.state.adminId} and tbl_pages.status="show" and  tbl_mapping_page.status = "active"`,
        'tbl_mapping_page.id',
        'tbl_mapping_page.listingorder,tbl_mapping_page.listingsubcatagory'
      );
      if (categories) {
        let AllContent = [];
        var fullList = [];
        if (categories) {
          categories.map((ival, i) => {
            if (AllContent.filter(jval => jval.categoryId === ival.categoryId).length === 0) {
              AllContent.push({
                categoryId: ival.categoryId,
                categoryName: ival.categoryName
              });
            }
            console.log(categories);
          });
          AllContent.map((ival, i) => {
            AllContent[i].innerContent = [];
            categories.map((jval, j) => {
              if (jval.categoryId === ival.categoryId) {
                AllContent[i].innerContent.push({
                  categoryId: jval.categoryId,
                  categoryName: jval.categoryName,
                  pageId: jval.pageId,
                  subCategoryId: jval.subCategoryId,
                  subCategoryName: jval.subCategoryName,
                  subcatorderid: jval.subcatorderid,
                  themeName: jval.themeName
                });
              }
            });
          });
        }

        var categoryData = [];
        const map = new Map();
        for (const item of categories) {
          if (!map.has(item.categoryId)) {
            map.set(item.categoryId, true);
            categoryData.push({ categoryId: item.categoryId });
          }
        }
        fullList = categoryData.map(values => {
          return categories.filter(list => list.categoryId === values.categoryId);
        });

        this.setState({ categories, AllContent });
        this.navContent(fullList, categoryData, this.state.userlog);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // navContent = (resList, categoryData, userlog) => {
  //   let Content = [];
  //   let innerContent = [];
  //   resList.map((ival, i) => {
  //     if (ival[0].categoryId === 60 || ival[0].categoryId === 61 || ival[0].categoryId === 62) {
  //       console.log('ival')
  //       console.log(ival)
  //       console.log('ival')
  //     } else {
  //       let para = `/CMS/More/${btoa(ival[0].categoryId)}`;
  //       innerContent.push(
  //         <li className="hs-menu-item hs-menu-depth-2 " role="menuitem">
  //           <a
  //             href="javascript:void(0)"
  //             role="menuitem"
  //             onClick={() => {
  //               this.submitFunction(para);
  //               this.scrollTop();
  //             }}
  //           >
  //             {ival && ival[0].categoryName}
  //           </a>
  //         </li>
  //       );
  //     }
  //   });
  //   Content.push(<ul className="hs-menu-children-wrapper">{innerContent}</ul>);
  //   this.setState({ Content });
  // };


  navContent = (resList, categoryData, userlog) => {
    let Content = [];
    let innerContent = [];
    resList.map((ival, i) => {
      if (ival[0].categoryId !== 60 && ival[0].categoryId !== 61 && ival[0].categoryId !== 62 && ival[0].categoryId !== 55) {
        let c = [];
        ival.map((jval, j) => {
          if (j < 4) {
            c.push(
              <li>
                <a className="dropdown-item text-capitalize white "
                  onClick={() => { this.submitFunction(`/CMS/${jval.themeName}/${jval.pageId}`); this.scrollTop(); }} style={{ width: '100%' }} href="javascript:void(0)">
                  {jval.subCategoryName}
                </a>
              </li>)
          } else if (j === 4) {
            c.push(
              <li style={{ marginBottom: 20 }}>
                <div className="row col-12">
                  <div className="col-9" style={{ paddingLeft: 0 }}>
                    <a className="dropdown-item text-capitalize white"
                      onClick={() => { this.submitFunction(`/CMS/${jval.themeName}/${jval.pageId}`); this.scrollTop(); }} style={{ width: '100%' }} href="javascript:void(0)">
                      {jval.subCategoryName}
                    </a>
                  </div>
                  <div className="col-3">
                    <a
                      className="dropdown-item text-capitalize white"
                      href="javascript:void(0)"
                      onClick={() => { this.submitFunction(`/CMS/More/${btoa(jval.categoryId)}`); this.scrollTop(); }}
                    >
                      <b> more..</b>
                    </a>
                  </div>
                </div>
              </li>)
          }
        })
        innerContent.push(
          <div className="col-sm-4" >
            <ul className='ulsubMenu'>
              <li className=" pb10">
                <b className="text-capitalize white">{ival[0].categoryName}</b>
              </li>
              {c}
            </ul>
          </div>)
      }
    });
    Content.push(
      // <ul className="hs-menu-children-wrapper" style={{ width: window.screen.width / 2.5, backgroundColor: '#fff', marginLeft: -window.screen.width / 5, overflowY: 'scroll', maxHeight: window.screen.width / 5 }} >
      <ul className="hs-menu-children-wrapper responsivea" style={{ position:"fixed", backgroundColor: '#ececec' }}>
        {/* style={{ width: window.screen.width, backgroundColor: '#ececec', left: -window.screen.width / 1.7 }} */}
        <li className="hs-menu-item hs-menu-depth-2 " role="menuitem">
          <div className="col-sm-12 row" >
            {innerContent}
          </div>
        </li>
      </ul >
    )
    this.setState({ Content });
  };

  showAboutus = () => {
    this.setState({ showAboutus: !this.state.showAboutus });
  };

  _handleKeyDown = e => {
    if (e.key === 'Enter') {
      if (this.state.placeHolder === 1) {
        this.handleSubmit();
      } else {
        this.handleSubmitOTP();
      }
    }
  };

  //   showAboutus = () => {
  //     this.setState({ showAboutus: !this.state.showAboutus });
  //   };
  handleUserInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  submitFunction = async d => {
    let l1 = d;
    if (d) {
      this.setState({ link: l1 });
    }
    if (localStorage.getItem('userlog')) {
      if (d) {
        await this.setState({ link: d });
        let neWTab = ['/CMS/Sheet', '/CMS/Sheet2', '/CMS/Sheet3', '/CMS/Sheet4'];
        if (neWTab.indexOf(d) !== -1) {
          window.open(d, '_blank');
        } else {
          window.location.href = this.state.link;
        }
      }
    } else {
      if (d) {
        this.setState({ hideShow: true, link: d });
      } else {
        let link;
        if (this.state.link) {
          link = this.state.link;
        }
        this.setState({ hideShow: true, link: link });
      }

      if (this.state.placeHolder === 1) {
        this.handleSubmit();
      } else {
        this.handleSubmitOTP();
      }
    }
  };
  handleSubmit = async d => {
    let mobileNumber = this.state.mobileNumber;
    if (mobileNumber === '' && !this.state.link) {
      this.setState({ errorMobileNumber: '' });
      return false;
    } else if (mobileNumber === '' || mobileNumber === undefined) {
      this.setState({ errorMobileNumber: 'Please enter mobile number' });
      return false;
    } else if (!/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/.test(mobileNumber)) {
      this.setState({ errorMobileNumber: 'Invalid Mobile Number' });
      return false;
    } else {
      this.setState({ errorMobileNumber: '', actualNum: mobileNumber });
    }
    if (
      mobileNumber == 9500114666 ||
      mobileNumber == 9500114776 ||
      mobileNumber == 9500114777 ||
      mobileNumber == 9959022134 ||
      mobileNumber == 9879879879 ||
      mobileNumber == 9500114888 ||
      mobileNumber == 'user@ehsportal.in' ||
      mobileNumber == 'girish@dotcue.net' ||
      mobileNumber == 8754542738 ||
      mobileNumber == 6666666661
    ) {
      this.setState({ hideShow: false, errorMobileNumber: '',preloader:true });
      const result = await login.loginCheck(mobileNumber);
      if (result) {
        localStorage.setItem('userlog', JSON.stringify(result.data[0]));
        let userlog = localStorage.getItem('userlog');
        userlog = JSON.parse(userlog);
        localStorage.setItem('userType', userlog.userType);
        if (parseInt(userlog.userType) === 1) {
          this.props.history.replace('/admin');
        } else if (parseInt(userlog.userType) === 2) {
          this.props.history.replace('/subAdmin');
        } else if (parseInt(userlog.userType) === 3) {
          //this.props.history.replace('/superAdmin');
          window.location.href = '/superAdmin';
        } else if (parseInt(userlog.userType) === 6 && parseInt(userlog.serviceId) === 8) {
          if (this.state.link) {
            window.location.href = this.state.link;
          } else {
            window.location.href = '/CMS/Home';
          }
          // this.props.history.replace('/CMS/Home');
        }
        this.setState({ link: "" });
      }
    } else {
      try {
        const result = await login.loginOTPCheck(mobileNumber);
        if (result) {
          if (result.data.res) {
            this.setState({
              mobileNumber: '',
              errorMobileNumber: 'Your OTP has been sent',
              placeHolder: 2,
              showResend: true
            });
          } else {
            this.setState({
              errorMobileNumber: 'Mobile Number does not exists'
            });
          }
        } else {
          // this.setState({ showResend: true });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  handleSubmitOTP = async para => {
    let mobileNumber = this.state.mobileNumber;
    if (mobileNumber === '') {
      this.setState({ errorMobileNumber: 'Enter OTP' });
      return false;
    } else {
      this.setState({
        errorMobileNumber: ''
      });
    }
    try {
      const result = await login.loginOTPCheckPost(this.state.actualNum, mobileNumber);
      if (result) {
        if (result.data.length) {
          this.setState({ hideShow: false, show: true });
          localStorage.setItem('userlog', JSON.stringify(result.data[0]));
          let userlog = localStorage.getItem('userlog');
          userlog = JSON.parse(userlog);
          localStorage.setItem('userType', userlog.userType);

          if (this.state.link) {
            window.location.href = this.state.link;
          } else {
            if (parseInt(userlog.userType) === 1) {
              this.props.history.replace('/admin');
            } else if (parseInt(userlog.userType) === 2) {
              this.props.history.replace('/subAdmin');
            } else if (parseInt(userlog.userType) === 3) {
              //this.props.history.replace('/superAdmin');
              window.location.href = '/superAdmin';
            } else if (parseInt(userlog.userType) === 6 && parseInt(userlog.serviceId) === 8) {
              window.location.href = '/CMS/Home';

              // this.props.history.replace('/CMS/Home');
            }
          }
          // this.setState({ link: "" });
        } else {
          // window.location.href = "/CMS/singleContent/205";
          this.setState({
            errorMobileNumber: 'Incorrect OTP',
            showResend: true
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  logout = () => {
    if (localStorage.getItem('userlog')) {
      localStorage.clear();
      window.location = '/CMS/Home';
    }
  };
  resend = async () => {
    this.setState({ mobileNumber: '' });
    const result = await login.loginOTPCheck(this.state.actualNum, null);
    if (result) {
      this.setState({
        errorMobileNumber: 'OTP sent',
        placeHolder: 2
      });
    }
  };
  reset = () => {
    this.setState({
      showResend: false,
      placeHolder: 1,
      mobileNumber: '',
      errorMobileNumber: ''
    });
  };
  // msgCom = () => {
  //   console.log("msgCom");
  //   let p = [];
  //   p.push(<MessageComponent />);
  //   this.setState({ p });
  // };
  render() {
    const { showResend } = this.state;
    const BBaner = ACCESS_POINT + '/superAdmin/file?fileurl=SDAS/Video/image_1581056126531.jpg';
    let { AllContent, exemption } = this.state;
    var chunk_size = 5;
    var groups = '';
    var fullData = [];
    var exceptionData = [];
    let reference = [];
    if (AllContent && AllContent.length > 0) {
      AllContent = AllContent.filter((ival, i) => {
        if (exemption.indexOf(ival.categoryId) === -1) {
          return ival;
        } else {
          exceptionData.push(ival);
        }
        return null;
      });
      AllContent.map((values, val) => {
        if (values.innerContent && values.innerContent[0].subCategoryId) {
          console.log(values.innerContent[0].subCategoryId);
          groups = values.innerContent
            .map(function (e, i) {
              return i % chunk_size === 0 ? values.innerContent.slice(i, i + chunk_size) : null;
            })
            .filter(function (e) {
              return e;
            });

          var innerJsonData = [];
          groups.map(innerData => {
            innerJsonData.push(
              <ul className="hs-menu-children-wrapper">
                <li className="hs-menu-item hs-menu-depth-2 " role="menuitem">
                  {innerData.map(dataValue => {
                    let para;
                    if (dataValue.subCategoryId === 152) {
                      // please dont remove
                    } else {
                      if (dataValue.subCategoryName === 'CUMI ABR MM Nagar') {
                        para = '/CMS/Sheet';
                      } else if (dataValue.subCategoryName === 'Murugappa Management Services, HO') {
                        para = '/CMS/Sheet3';
                      } else if (dataValue.subCategoryName === 'EID Parry, Nelikuppam') {
                        para = '/CMS/Sheet2';
                      } else if (
                        // jval.categoryName === "Work@Murugappa" &&
                        dataValue.subCategoryName === 'TPI, Avadi'
                      ) {
                        para = '/CMS/Sheet4';
                      } else if (dataValue.subCategoryId === 156) {
                        // console.log(dataValue)
                        para = '/CMS/safetyHealth';
                      } else {
                        para = `/CMS/${dataValue.themeName}/${dataValue.pageId}`;
                      }
                      if (dataValue.subCategoryId === 153) {
                        para = ACCESS_POINT + '/superAdmin/file?fileurl=SDAS/Video/letter_1_1583905591293.jpg';
                        return (
                          <a
                            className="atahfont16"
                            // href="javascript:void(0)"
                            href="/img"
                            style={{
                              fontSize: '15px'
                            }}
                            target="_blank"
                          >
                            {dataValue.subCategoryName}
                          </a>
                        );
                      } else if (dataValue.subCategoryId === 154) {
                        para = ACCESS_POINT + '/superAdmin/file?fileurl=SDAS/Video/letter_1_1583905591293.jpg';
                        return (
                          <a
                            className="atahfont16"
                            // href="javascript:void(0)"
                            href="/img1"
                            style={{
                              fontSize: '15px'
                            }}
                            target="_blank"
                          // onClick={() => this.msgCom(para)}
                          >
                            {dataValue.subCategoryName}
                          </a>
                        );
                      } else if (dataValue.subCategoryId === 155) {
                        para = ACCESS_POINT + '/superAdmin/file?fileurl=SDAS/Video/SafetyCulture_1_1583922018018_1583940024923.pdf';
                        return (
                          <a
                            className="atahfont16"
                            // href="javascript:void(0)"
                            href={para}
                            style={{
                              fontSize: '15px'
                            }}
                            target="_blank"
                          // onClick={() => this.msgCom(para)}
                          >
                            {dataValue.subCategoryName}
                          </a>
                        );
                      } else {
                        return (
                          <a
                            className="atahfont16"
                            href="javascript:void(0)"
                            style={{
                              fontSize: '15px'
                            }}
                            // onClick={() => this.submitFunction(para)}
                            onClick={() => {
                              this.submitFunction(para);
                              this.scrollTop();
                            }}
                          >
                            {dataValue.subCategoryName}
                          </a>
                        );
                      }
                    }
                  })}
                </li>
              </ul>
            );
          });

          fullData.push(
            <li className="hs-menu-item hs-menu-depth-1 hs-item-has-children plus-link" role="menu">
              <a
                className="atahfont16"
                href="javascript:void(0)"
                style={{
                  fontSize: '15px'
                }}
                role="menuitem"
                // onClick={() => this.submitFunction()}
                onClick={() => {
                  this.submitFunction();
                  // this.scrollTop();
                }}
              >
                {values.categoryName}
              </a>
              {innerJsonData}
            </li>
          );
        } else {
          if (values.categoryId === 55) {
            reference.push(<li className="hs-menu-item hs-menu-depth-1" role="menu">
              <a
                style={{
                  fontSize: '15px'
                }}
                href="javascript:void(0)"
                onClick={() => {
                  this.submitFunction('/CMS/publication');
                  this.scrollTop();
                }}
              >
                {values.categoryName}
              </a>
            </li>)
          } else {

            fullData.push(
              <li className="hs-menu-item hs-menu-depth-1" role="menu">
                <a
                  style={{
                    fontSize: '15px'
                  }}
                  href="javascript:void(0)"
                  onClick={() => {
                    this.submitFunction('/CMS/' + values.innerContent[0].themeName + '/' + values.innerContent[0].pageId);
                    this.scrollTop();
                  }}
                >
                  {values.categoryName}
                </a>
              </li>
            );
          }
        }
      });
    }
    return (
      <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
        <div className="row-fluid ">
          <div
            className="span12 widget-span widget-type-cell"
            style={{
              backgroundColor: '#cecece',
              backgroundImage: `url(${BBaner})`,
              backgroundAttachment: 'scroll',
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}
            data-widget-type="cell"
            data-x={0}
            data-w={12}
          >
            <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
              <div className="row-fluid ">
                <div className="span12 widget-span widget-type-global_group " style={{}} data-widget-type="global_group" data-x={0} data-w={12}>
                  {/* start coded_template: id:5191653338 path:generated_global_groups/5191653334.html */}
                  <div className data-global-widget-path="generated_global_groups/5191653334.html">
                    <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-space h10 mobile-spacer" style={{}} data-widget-type="space" data-x={0} data-w={12}>
                          <div className="cell-wrapper layout-widget-wrapper">
                            <span
                              id="hs_cos_wrapper_module_1495728017374190"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_space"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="space"
                            />
                          </div>
                          {/*end layout-widget-wrapper */}
                        </div>
                        {/*end widget-span */}
                      </div>
                      {/*end row*/}
                    </div>
                    {/*end row-wrapper */}
                    <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
                      <div className="row-fluid ">
                        {/* <div
                          className="span12 widget-span widget-type-cell page-margins image-header"
                          style={{
                            padding: "0px !important",
                            margin: "0px !important",
                            display: "inline"
                          }}
                          data-widget-type="cell"
                          data-x={0}
                          data-w={12}
                        > */}
                        <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
                          <div className="row-fluid ">
                            <div className="span2 widget-span widget-type-logo header-logo" style={{}} data-widget-type="logo" data-x={0} data-w={2}>
                              <div className="cell-wrapper layout-widget-wrapper">
                                <span
                                  id="hs_cos_wrapper_logo"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_logo"
                                  style={{}}
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="logo"
                                >
                                  <a
                                    href="javascript:void(0)"
                                    id="hs-link-logo"
                                    style={{
                                      borderWidth: '0px',
                                      border: '0px'
                                    }}
                                  >
                                    <img
                                      width="80%"
                                      style={{
                                        float: 'right',
                                        marginRight: 10
                                      }}
                                      src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/1200px-Murugappa_Group_Logo_1581051820908.png`}
                                    />
                                  </a>
                                </span>
                              </div>
                              {/*end layout-widget-wrapper */}
                            </div>
                            {/*end widget-span */}
                            <div className="span10 widget-span widget-type-menu header-menu" style={{ margin: '0px' }} data-widget-type="menu" data-x={2} data-w={10}>
                              <div className="cell-wrapper layout-widget-wrapper">
                                <span
                                  id="hs_cos_wrapper_module_149572574624899"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu"
                                  style={{}}
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="menu"
                                >
                                  <div
                                    id="hs_menu_wrapper_module_149572574624899"
                                    className="hs-menu-wrapper active-branch flyouts hs-menu-flow-horizontal"
                                    role="navigation"
                                    data-sitemap-name="2017 Header Menu"
                                    data-menu-id={5149757634}
                                    aria-label="Navigation Menu"
                                  >
                                    <ul>
                                      {AllContent && AllContent.length && this.state.loaded ? this.loadScript('/js/2017_styles.js') : null}
                                      {fullData}
                                      <li className="hs-menu-item hs-menu-depth-1 " role="menu">
                                        <a
                                          className="atahfont16"
                                          href="javascript:void(0)"
                                          style={{
                                            fontSize: '15px'
                                          }}
                                          role="menuitem"
                                          onClick={() => {
                                            this.submitFunction('/CMS/Dynamic/218');
                                            this.scrollTop();
                                          }}
                                        >
                                          Partnerships
                                        </a>
                                      </li>

                                      <li className="hs-menu-item hs-menu-depth-1" role="menu">
                                        <a
                                          className="atahfont16"
                                          href="javascript:void(0)"
                                          role="menuitem"
                                          style={{
                                            fontSize: '15px'
                                          }}
                                          onClick={() => {
                                            this.submitFunction('/CMS/Evaluation');
                                            this.scrollTop();
                                          }}
                                        >
                                          Evaluation
                                        </a>
                                        {this.state.EvaContent}
                                      </li>
                                      <li className="hs-menu-item hs-menu-depth-1" role="menu">
                                        <a
                                          className="atahfont16"
                                          href="javascript:void(0)"
                                          style={{
                                            fontSize: '15px'
                                          }}
                                          role="menuitem"
                                          onClick={() => this.submitFunction('/CMS/Gamification')}
                                        >
                                          Gamification
                                        </a>
                                        {this.state.GameContent}
                                      </li>
                                      <li className="hs-menu-item hs-menu-depth-1 hs-item-has-children" role="menu">
                                        <a
                                          className="atahfont16"
                                          href="javascript:void(0)"
                                          style={{
                                            fontSize: '15px'
                                          }}
                                          role="menuitem"
                                        >
                                          Online Resource Centre
                                        </a>
                                        {this.state.Content}
                                      </li>
                                      {reference}
                                      <li
                                        className="hs-menu-item hs-menu-depth-1 widthOneLine"
                                        style={{
                                          display: this.state.hideShow ? 'block' : 'none'
                                        }}
                                      >
                                        <input
                                          id="signin"
                                          className="blackHeading m2 widthInput paddingOnLeft"
                                          type="text"
                                          name="mobileNumber"
                                          placeholder={this.state.placeHolder === 1 ? 'Enter Mobile Number' : 'Enter OTP'}
                                          maxlength={this.state.placeHolder === 1 ? '10' : '4'}
                                          style={{
                                            display: 'inline-block',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            boxSizing: 'border-box'
                                          }}
                                          onChange={event => this.handleUserInput(event)}
                                          value={this.state.mobileNumber}
                                          onKeyDown={this._handleKeyDown}
                                        />
                                        <p
                                          style={{
                                            color: '#fff',
                                            fontSize: '12px'
                                          }}
                                          className="paddingOnLeft"
                                        >
                                          {this.state.errorMobileNumber}
                                        </p>
                                      </li>

                                      {showResend === false && !localStorage.getItem('userlog') && (
                                        <li className="hs-menu-item hs-menu-depth-1 ">
                                          <a
                                            href="javascript:void(0)"
                                            role="menuitem"
                                            className="red-outline atahfont16"
                                            style={{
                                              fontSize: '15px'
                                            }}
                                            onClick={() => this.submitFunction()}
                                          >
                                            <span  style={{border: 'none', color: 'white'}}>
                                          {this.state.preloader && <i style={{marginRight:5}}className="fa fa-spinner fa-spin"></i>  }Sign In
                                                 </span>
                                            
                                          </a>

                                        
                                          
                                        </li>
                                      )}
                                      {showResend === false && localStorage.getItem('userlog') && (
                                        <li className="hs-menu-item hs-menu-depth-1 ">
                                          <p
                                            className="atahfont16"
                                            style={{
                                              margin: '3px',
                                              color: 'red',
                                              fontWeight: 'bold',
                                              fontSize: '12px'
                                            }}
                                          >
                                            {'Welcome ' + JSON.parse(localStorage.getItem('userlog')).userName}
                                            <i
                                              class="fa fa-sign-out hoverclass"
                                              aria-hidden="true"
                                              style={{
                                                cursor: 'pointer',
                                                fontSize: '18px',
                                                marginTop: '1px',
                                                color: 'red',
                                                marginLeft: '8px'
                                              }}
                                              onClick={this.logout}
                                            ></i>
                                          </p>
                                        </li>
                                      )}
                                      {showResend === true && !localStorage.getItem('userlog') && (
                                        <li className="hs-menu-item hs-menu-depth-1 ">
                                          <a
                                            href="javascript:void(0)"
                                            role="menuitem"
                                            className="red-outline atahfont16"
                                            style={{
                                              fontSize: '12px'
                                              //  window.innerWidth / 98
                                            }}
                                            onClick={() => this.submitFunction()}
                                          >
                                            Sign In
                                          </a>
                                        </li>
                                      )}
                                      {showResend === true && (
                                        <li className="hs-menu-item hs-menu-depth-1 ">
                                          <a
                                            href="javascript:void(0)"
                                            role="menuitem"
                                            className="red-outline atahfont16"
                                            style={{
                                              fontSize: '12px'
                                              //  window.innerWidth / 98
                                            }}
                                            onClick={this.resend}
                                          >
                                            Resend OTP
                                          </a>
                                        </li>
                                      )}
                                      {showResend === true && (
                                        <li className="hs-menu-item hs-menu-depth-1 ">
                                          <a
                                            href="javascript:void(0)"
                                            role="menuitem"
                                            className="red-outline atahfont16"
                                            style={{
                                              fontSize: '12px'
                                              //  window.innerWidth / 98
                                            }}
                                            onClick={this.reset}
                                          >
                                            Reset
                                          </a>
                                        </li>
                                      )}
                                    </ul>
                                  </div>
                                  {localStorage.getItem('userlog') && (
                                    <div>
                                      {/* <span
                                          className="atahfont16"
                                          style={{
                                            color: "white",
                                            position: "relative",
                                            top: "-44px",
                                            left: "74%",
                                            fontSize: 14
                                          }}
                                        >
                                          {"Welcome " +
                                            JSON.parse(
                                              localStorage.getItem(
                                                "userlog"
                                              )
                                            ).userName}
                                        </span>
                                        */}
                                    </div>
                                  )}
                                  {/* <span
                                      style={{
                                        color: "#fff",
                                        position: "relative",
                                        top: "-70px",
                                        left: "74%",
                                        fontSize: 14
                                      }}
                                    >
                                      {this.state.errorMobileNumber}
                                    </span> */}
                                </span>
                              </div>
                              {/*end layout-widget-wrapper */}
                            </div>
                            {/*end widget-span */}
                          </div>
                          {/*end row*/}
                        </div>
                        {/*end row-wrapper */}
                        <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
                          <div className="row-fluid ">
                            <div id="mobile-button">
                              <div className="span12 widget-span widget-type-raw_html " style={{}} data-widget-type="raw_html" data-x={0} data-w={12}>
                                <div className="cell-wrapper layout-widget-wrapper">
                                  <span
                                    id="hs_cos_wrapper_module_1496761489723256"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_raw_html"
                                    style={{}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="raw_html"
                                  >
                                    <i className="fa fa-bars" aria-hidden="true" />
                                  </span>
                                </div>
                                {/*end layout-widget-wrapper */}
                              </div>
                              {/*end widget-span */}
                            </div>
                          </div>
                          {/*end row*/}
                        </div>
                        {/*end row-wrapper */}
                        {/* </div> */}
                        {/*end widget-span */}
                      </div>
                      {/*end row*/}
                    </div>
                    {/*end row-wrapper */}
                  </div>
                  {/* end coded_template: id:5191653338 path:generated_global_groups/5191653334.html */}
                </div>
                {/*end widget-span */}
              </div>
              {/*end row*/}
            </div>
            {/*end row-wrapper */}
            <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
              <div className="row-fluid ">
                <div className="span12 widget-span widget-type-cell page-margins" data-widget-type="cell" data-x={0} data-w={12}>
                  <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
                    <div className="row-fluid ">
                      <div className="span12 widget-span widget-type-space min75" style={{}} data-widget-type="space" data-x={0} data-w={12}>
                        <div className="cell-wrapper layout-widget-wrapper">
                          <span
                            id="hs_cos_wrapper_module_1495800856750194"
                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_space"
                            style={{}}
                            data-hs-cos-general-type="widget"
                            data-hs-cos-type="space"
                          />
                        </div>
                        {/*end layout-widget-wrapper */}
                      </div>
                      {/*end widget-span */}
                    </div>
                    {/*end row*/}
                  </div>
                  {/*end row-wrapper */}
                  <div className="row-fluid-wrapper row-depth-1 row-number-6 ">
                    <div className="row-fluid ">
                      <div className="span12 widget-span widget-type-header force-white-text narrow-on-desktop fw100" style={{}} data-widget-type="header" data-x={0} data-w={12}>
                        <div className="cell-wrapper layout-widget-wrapper">
                          <span
                            id="hs_cos_wrapper_module_1495725762349100"
                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header"
                            style={{}}
                            data-hs-cos-general-type="widget"
                            data-hs-cos-type="header"
                          >
                            <h1 className="my-45">Safety is non-negotiable at Murugappa Group</h1>
                          </span>
                        </div>
                        {/*end layout-widget-wrapper */}
                      </div>
                      {/*end widget-span */}
                    </div>
                    {/*end row*/}
                  </div>
                  {/*end row-wrapper */}
                  <div className="row-fluid-wrapper row-depth-1 row-number-7 ">
                    <div className="row-fluid ">
                      <div className="span12 widget-span widget-type-custom_widget button-links" style={{}} data-widget-type="custom_widget" data-x={0} data-w={12}>
                        <div
                          id="hs_cos_wrapper_module_1574088304015103"
                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text widget-type-rich_text"
                          style={{}}
                          data-hs-cos-general-type="widget"
                          data-hs-cos-type="module"
                        >
                          <span
                            id="hs_cos_wrapper_module_1574088304015103_"
                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                            style={{}}
                            data-hs-cos-general-type="widget"
                            data-hs-cos-type="rich_text"
                          >
                            <p align="center">
                              <a className="atahfont16" role="menuitem" id="myBtn" href="javascript:void(0);" data-toggle="modal" data-target="#myModal">
                                Overview Video
                              </a>
                            </p>
                          </span>
                        </div>
                      </div>
                      {/*end widget-span */}
                    </div>
                    {/*end row*/}
                  </div>
                  {/*end row-wrapper */}
                  <div className="row-fluid-wrapper row-depth-1 row-number-8 ">
                    <div className="row-fluid ">
                      <div className="span12 widget-span widget-type-raw_html " style={{}} data-widget-type="raw_html" data-x={0} data-w={12}>
                        <div className="cell-wrapper layout-widget-wrapper">
                          <span
                            id="hs_cos_wrapper_module_1495725769291102"
                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_raw_html"
                            style={{}}
                            data-hs-cos-general-type="widget"
                            data-hs-cos-type="raw_html"
                          >
                            <div className="h10" />
                            <hr className="red-line" />
                            <div className="min24" />
                          </span>
                        </div>
                        {/*end layout-widget-wrapper */}
                      </div>
                      {/*end widget-span */}
                    </div>
                    {/*end row*/}
                  </div>
                  {/*end row-wrapper */}
                  <div className="row-fluid-wrapper row-depth-1 row-number-9 ">
                    <div className="row-fluid ">
                      <div className="span12 widget-span widget-type-rich_text force-white-text" style={{}} data-widget-type="rich_text" data-x={0} data-w={12}>
                        <div className="cell-wrapper layout-widget-wrapper">
                          <span
                            id="hs_cos_wrapper_module_1495725774430104"
                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text"
                            style={{}}
                            data-hs-cos-general-type="widget"
                            data-hs-cos-type="rich_text"
                          >
                            <div style={{ textAlign: 'center' }}>
                              <span>
                                A Commitment to safety should not be a priority, but a value that shapes decision-making all the time, at every level. Every company desires safe operations, but the
                                challenge is to translate this desire into action. Written rules, standards and procedures while important and necessary, are not enough. Companies must develop a
                                culture in which the value of safety is embedded into every level of the workforce. A commitment to safety and operational integrity begins with management. But
                                management alone cannot drive the entire culture. For a culture of safety to flourish, it must be embedded throughout the organization. Having considered the
                                significance of safety-driven strategy, MDC embarked on an initiative of building a culture of safety among the employees of Murugappa Group on how behaviors shape the
                                safety culture of an organization.
                              </span>
                            </div>
                          </span>
                        </div>
                        {/*end layout-widget-wrapper */}
                      </div>
                      {/*end widget-span */}
                    </div>
                    {/*end row*/}
                  </div>
                  {/*end row-wrapper */}
                  <div className="row-fluid-wrapper row-depth-1 row-number-10 ">
                    <div className="row-fluid ">
                      <div className="span12 widget-span widget-type-space min24" style={{}} data-widget-type="space" data-x={0} data-w={12}>
                        <div className="cell-wrapper layout-widget-wrapper">
                          <span
                            id="hs_cos_wrapper_module_1495800815241183"
                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_space"
                            style={{}}
                            data-hs-cos-general-type="widget"
                            data-hs-cos-type="space"
                          />
                        </div>
                        {/*end layout-widget-wrapper */}
                      </div>
                      {/*end widget-span */}
                    </div>
                    {/*end row*/}
                  </div>
                  {/*end row-wrapper */}
                  <div className="row-fluid-wrapper row-depth-1 row-number-11 ">
                    <div className="row-fluid ">
                      <div className="span12 widget-span widget-type-raw_jinja h0" style={{}} data-widget-type="raw_jinja" data-x={0} data-w={12}></div>
                      {/*end widget-span */}
                    </div>
                    {/*end row*/}
                  </div>
                  {/*end row-wrapper */}
                </div>
                {/*end widget-span */}
              </div>
              {/*end row*/}
            </div>
            {/*end row-wrapper */}
          </div>
          {/*end widget-span */}
        </div>
        {/*end row*/}
      </div>
    );
  }
}
export default HomePanelList;
;