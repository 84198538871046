import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";


const Userlogpdf = ({
  header,
  data,
  page,
  title = "User Logs-March 15,2020 to June 3,2020"
}) => {
  console.log(data)


  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.title}>
          <Text>{title}</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>

            {data &&
              header &&
              header.map(value => {
                return (
                  <View style={styles1.tableCol}>
                    <Text style={styles1.tableCell}>{value}</Text>
                  </View>
                );
              })}


          </View>

          {data &&
            data.map((value, index) => {
              return (
                <View style={styles1.tableRow}>
                  <View style={styles1.tableCol}>
                    <Text style={styles1.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles1.tableCol}>
                    <Text style={styles1.tableCell}>{value.username}</Text>
                  </View>
                  <View style={styles1.tableCol}>
                    <Text style={styles1.tableCell}>{value.pagename}</Text>
                  </View>
                  <View style={styles1.tableCol}>
                    <Text style={styles1.tableCell}>{value.count}</Text>
                  </View>
                  <View style={styles1.tableCol}>
                    <Text style={styles1.tableCell}>{value.email}</Text>
                  </View>
                  <View style={styles1.tableCol}>
                    <Text style={styles1.tableCell}>{value.mobile}</Text>
                  </View>

                </View>
              );
            })}
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: "5px"
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    //textAlign: "center"
  },
  tableColHeader: {
    width: "16.6%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: "33.3%%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: "auto",
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: "auto",
    margin: 5,
    fontSize: 10
  },
  title: {
    fontSize: 15,

    fontWeight: "bold",
    marginLeft: "8%",
    textAlign: "center"
  }
});

const styles1 = StyleSheet.create({
  body: {
    padding: 10
  },
  table: {
    marginTop: "5%",
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",

  },
  tableColHeader: {
    width: "25%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 55,
    fontWeight: "bold"
  },
  tableCol: {
    width: "17.5%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: "auto",
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: "auto",
    margin: 5,
    fontSize: 10
  },
  title: {
    fontSize: 15,
    fontWeight: "bold",
    marginLeft: "25%"
  }
});
const styles2 = StyleSheet.create({
  body: {
    padding: 10
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: "5px"
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    //textAlign: "center"
  },
  tableColHeader: {
    width: "16.6%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: "28.3%%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: "auto",
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: "auto",
    margin: 5,
    fontSize: 10
  },
  title: {
    fontSize: 15,
    fontWeight: "bold",
    marginLeft: "8%",
    textAlign: "center"
  }
});
const styles3 = StyleSheet.create({
  body: {
    padding: 10
  },
  table: {
    marginTop: "5%",
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",

  },
  tableColHeader: {
    width: "25%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 55,
    fontWeight: "bold"
  },
  tableCol: {
    width: "16.8%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: "auto",
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: "auto",
    margin: 5,
    fontSize: 10
  },
  title: {
    fontSize: 15,
    fontWeight: "bold",
    marginLeft: "25%"
  }
});
export default Userlogpdf;
