import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from "reactstrap";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import SingleSelect from "../../../components/Form/SingleSelect";
import { PDFDownloadLink } from '@react-pdf/renderer';

import ReportsPdf from "../../../components/PDF/ReportsPdf";

class Reports extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      customerid: localStorage.getItem('userId'),
      serviceid: localStorage.getItem('currentService'),
      page2: 0,
    };
  }
  async componentWillMount() {
    try {
      let QuizList = await CmsContent.getTwoConditionedValue('tbl_question', 'customerid', this.state.customerid, 1, 1, "id as value ,quizName as label,contentJson");

      let data = await CmsContent.getquizlog();

      this.setState({ data: data.data, QuizList: QuizList.data });
     // console.log(data.data)

    } catch (error) {
      console.log(error);
    }
  }
  column = [
    {
      Header: 'Username',
      accessor: 'label',
      Cell: d => this.firstFunction(d.original, d.original.label)
    },
    {
      Header: 'Attempts',
      accessor: 'count',
      Cell: d => this.firstFunction(d.original, d.original.count)
    },
    {
      Header: 'Score',
      accessor: 'score',
      Cell: d => this.firstFunction(d.original, d.original.score)
    },
    {
      Header: 'Total Questions',
      accessor: 'total',
      Cell: d => this.firstFunction(d.original, d.original.total)
    }
  ];
  column1 = [
    {
      Header: 'Username',
      accessor: 'label',
    },
    {
      Header: 'Score',
      accessor: 'score',
    },
    {
      Header: 'Total Questions',
      accessor: 'total',
    },
    {
      Header: 'Attended on',
      accessor: 'time',
      Cell: d => this.getDate(d.original.time)
    },
    {
      Header: 'View Answer',
      accessor: 'view',
      Cell: d => this.dataTableButton('info', 'View', () => {
        this.ViewOne(d.original);
      })
    }
  ];
  column2 = [
    {
      Header: 'Question',
      accessor: 'question',
    },
    {
      Header: 'Response',
      accessor: 'answer'
    },
    {
      Header: 'Answer',
      accessor: 'correct',
    },
  ];
  header1 = [
        'SI.NO',
        'USERNAME',
        'ATTEMPTS',
        'SCORE',
        'TOTAL QUESTIONS'
      ];
 header2  = [
            'SI.NO',
            'USERNAME',
            'SCORE',
            'TOTAL QUESTIONS',
            'ATTENDANED DATE'
          ];
header3=[
        'SI.NO',
        'QUESTIONS',
        'RESPONSE',
        'ANSWER'
   
];

  selectQuiz = async selectQuiz => {
    try {
      let UserList = await CmsContent.getFreedom(
        'tbl_user_web.userName as label,tbl_quizAnswer.userId as value, count(tbl_quizAnswer.userId) as count,tbl_quizAnswer.total as score,tbl_quizAnswer.totalQue as total',
        'tbl_quizAnswer,tbl_user_web',
        `tbl_user_web.id=tbl_quizAnswer.userId and tbl_quizAnswer.quizId=${selectQuiz.value} and tbl_quizAnswer.serviceid=${this.state.serviceid}`,
        'tbl_quizAnswer.userId',
        'tbl_quizAnswer.id DESC');
      this.resetState();
      this.setState({ selectQuiz, UserList: UserList.data });
      
      // if (UserList.data.length > 0) {

      //   const header = [
      //     'SI.NO',
      //     'USERNAME',
      //     'ATTEMPTS',
      //     'SCORE',
      //     'TOTAL QUESTIONS'
      //   ];
     
      //   this.setState({
      //     header,
      //     selectQuiz,
      //    // excelHead,
      //    // excel,
      //    UserList: UserList.data,
      //     title:"QUIZ LIST"
           
      //   });
        //console.log( UserList.data)
      
     // }
    
    } catch (error) {
      console.error(error);
    }
  };
  firstFunction = (d, value) => {
    return <a href="#" onClick={() => { this.doFunction(d) }}>{value}</a>
  }
  doFunction = async (d) => {
    try {
      let selectQuiz = this.state.selectQuiz;
      let singleUser = await CmsContent.getFreedom(
        'tbl_user_web.userName as label,tbl_quizAnswer.userId as value,tbl_quizAnswer.total as score,tbl_quizAnswer.totalQue as total,tbl_quizAnswer.createdAt as time,tbl_quizAnswer.quizanswer as answerJson',
        'tbl_quizAnswer,tbl_user_web',
        `tbl_user_web.id=tbl_quizAnswer.userId and tbl_quizAnswer.quizId=${selectQuiz.value} and tbl_quizAnswer.userId=${d.value} and tbl_quizAnswer.serviceid=${this.state.serviceid}`,
        'tbl_quizAnswer.id',
        'tbl_quizAnswer.id DESC');
     this.setState({ page2: 1, singleUser: singleUser.data });
      //console.log(singleUser.data);
      // if(singleUser.data.length>0){
      //   const header1  = [
      //     'SI.NO',
      //     'USERNAME',
      
      //     'SCORE',
      //     'TOTAL QUESTIONS',
      //     'ATTENDANED DATE'
      //   ];
      //   this.setState({
      //     header,
      //     selectQuiz,
      //     page2: 1,
      //    // excelHead,
      //    // excel,
      //    singleUser: singleUser.data,
      //     title:"QUIZ LIST"
           
      //   });
      //   console.log(singleUser.data)
     
      // }
    } catch (error) {
      console.error(error);
    }
  }
  ViewOne = (d) => {
    let answer = JSON.parse(d.answerJson)
    let question = JSON.parse(this.state.selectQuiz.contentJson);
    let finalSingleValue = [];
    question.map((ival, i) => {
      if (ival.type === 'multiple') {
        let ans1 = [];
        answer[i].answer.map((jval, j) => {
          let ans = jval.split('option').pop();
          ans1.push(ival.options[ans - 1]);
        });

        let actualAnswer = [];
        ival.answer.split(',').map((jval, j) => {
          let cA = jval.split('option').pop();
          actualAnswer.push(ival.options[cA - 1]);
        });

        finalSingleValue.push({ question: ival.question, answer: ans1.toString().toLowerCase(), correct: actualAnswer.toString().toLowerCase() });
      } else {
        finalSingleValue.push({ question: ival.question, answer: answer[i].answer.toLowerCase(), correct: ival.answer.toLowerCase() })
      }
    });
    this.setState({ finalSingleValue, page2: 2, userName: d.label, date: this.getDate(d.time) });
  }
  resetState = () => this.setState({ page2: 0, singleUser: [], finalSingleValue: [], userName: [], date: [] })
  OnBack =async val => {
    this.setState({ page2: val - 1})
    var tableData;
    if ((val-1) === 0) {
      await this.setState({tableData : this.state.UserList})
    } else if ((val-1) === 1) {
      await this.setState({tableData : this.state.singleUser})
    } else if ((val-1) === 2) {
      await this.setState({tableData : this.state.finalSingleValue})
    }
  }
  render() {
    const { QuizList, page2, UserList, singleUser, finalSingleValue, userName, date } = this.state;
    if (page2 === 0) {
      var tableData = UserList;
      var column = this.column;
      var header = this.header1;
    } else if (page2 === 1) {
      var tableData = singleUser;
      var column = this.column1;
      var header = this.header2;
    } else if (page2 === 2) {
      var tableData = finalSingleValue;
      var column = this.column2;
      var header = this.header3;
    }
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h1>Evaluation Report</h1>
              </div>
              <div className="card-body" style={{ minHeight: 350 }}>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="exampleInputEmail1">Select Quiz</label>
                  </div>
                  <div className="col-sm-5">
                    <SingleSelect options={QuizList} handleChange={this.selectQuiz} value={this.state.box} placeholder="Select Quiz" />
                  </div>
                  <div className="col-sm-3" />
                  {this.state.data && this.state.data.length > 0 && (
                  <PDFDownloadLink
                    style={{ color: 'white' }}
                    document={<ReportsPdf title={this.state.title} header={header} data={tableData} pg={page2} />}
                    fileName="sample.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? 'Loading document...' : 'Download Pdf'
                    }
                    <button className="btn btn-warning" style={{marginLeft:'23px'}}>
                      PDF
                    </button>
                  </PDFDownloadLink>)}

                </div>
                <div className="row form-group ml-0 mb-3">
                  <div className="col-sm-1 p-0" >
                    {page2 !== 0 && this.dataTableButton('danger', 'Back', () => {
                      this.OnBack(page2);
                    })}
                  </div>
                  <div className="col-sm-11 h2"><center>{page2 === 2 && `${userName}'s response on ${date}`}</center></div>
                </div>
                {tableData && <Datatable data={tableData} columnHeading={column} />}
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Reports;
