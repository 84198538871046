import React, { Component } from "react";
import "./privacy.css";

class Privacy extends Component {
  render() {
    return (
      <React.Fragment>
        <title>difuza - LXP</title>
        <meta
          name="description"
          content="difuza develops Websites,Website Templates,Multimedia Solutions,Usability Services,E-commerce Solutions,Web Applications, Corporate Branding Solutions,usability, usability engineering, seo,bpo,bre,bpm,elearning,cbt,branding,web application development,download,internet,software,Flash,ASP,JSP,VB,JAVA,Linux,php,cgi,actionscript,Usability Design, Entirely Flash Driven Websites, Ad-Banner Design, Flash Templates, Newsletters and Ecards, 2D Animation, Classical Cell Animation, 3D Animation, Design, Collateral, Datasheets & Press Kits, Business Cards and Letter Heads, Flyers, Print Ads, Package Design, Page and Product Design. Graphic Design and Art, Illustrations, Digital Imaging, Book Layouts,CD-Inlay Card Layout, Streamers, Buntings, Danglers, Billboards,Signage for corporations the world over."
        />
        <meta
          name="keywords"
          content="web design,web designing,web designers,web design,websites,multimedia,e-commerce,php,web,brand development, usability, usability engineering,seo,bpo,bre,bpm,elearning,cbt,branding,web application development,download,internet,software,Flash,ASP,JSP,VB,JAVA,Linux,
php,cgi,actionscript,usability design,entirely flash websites,ad-Banner design,flash templates, newsletters and ecards,2d animation,classical cell animation,3d animation,design,collateral, datasheets,press kits,business cards and letter heads,flyers,print ads,package design,page and product design,graphic design and art,illustrations,digital imaging,book Layouts,CD-inlay card layout,streamers,buntings,danglers,billboards,signage,web hosting india,web design india,web promotion india,india web hosting companies,chennai web development,chennai web solutions,india internet solutions, india e-commerce solutions,india web development,chennai website designing,india web promotions,chennai web hosting,india web marketing,chennai web promotion sevices,web award chennai,data conversion,chennai,india,XML conversion,HTML conversion,SGML conversion,TIFF conversion,PDF conversion, data harvesting,data mining."
        />
        <meta
          name="title"
          content="web hosting india,web design india,web promotion india,india web hosting companies,chennai web development,chennai web solutions,india internet solutions,india e-commerce solutions,web development chennai,web designers,web developers india,website designing chennai,web hosting services,india web solutions,india web promotion services,india web promotions,web marketing india,web award chennai,data conversion,chennai,india,XML conversion,HTML conversion,SGML conversion,TIFF conversion,PDF conversion,data harvesting,data mining,difuza - website development,multimedia,branding,web application development,WAP enabled applications,mobile interface development,e-commerce,solutions,hosting services,CMS,PHP,JAVA,Flash,Chennai,Kilpauk,India."
        />
        <meta
          name="Classification"
          content="web designing,web designers,web Design,websites,multimedia,e-commerce,php,web,brand development,usability,usability engineering,seo,bpo,bre,bpm,elearning,cbt,branding,web application development,download,internet,software,flash,ASP,asp,jsp,vb,JSP,VB,JAVA,java,linux,php,cgi,actionscript,usability design,entirely flash websites,ad-banner design,flash templates,newsletters,ecards,2d animation,classical cell animation,3d animation,design,collateral,datasheets,press kits,business cards and letter heads,flyers,print ads,package design,page design,product design,graphic design,art,illustrations,digital imaging,book layouts,CD-inlay card,layout,streamers,buntings,danglers,billboards,signage,web hosting india,web design india,web promotion india,india web hosting companies,chennai web development,chennai,web solutions,india,internet solutions,india e-commerce solutions,india web development,chennai website designing,india web promotions,chennai web hosting,india web marketing,chennai web promotion sevices, web award chennai, data conversion,chennai,india,XML conversion,HTML conversion,SGML conversion,TIFF conversion,PDF conversion, data harvesting,data mining."
        />
        <meta name="copyright" content="difuza" />
        <meta name="author" content="difuza" />
        <meta name="allow-search" content="yes" />
        <meta name="audience" content="all" />
        <meta name="robots" content="all, index, follow" />
        <meta name="googlebot" content="index,follow,all" />
        <meta name="MSNBot" content="index,follow,all" />
        <meta name="AskJeeves" content="index,follow,all" />
        <meta name="Voila" content="index,follow,all" />
        <meta name="Inktomi Slurp" content="index,follow,all" />
        <meta name="Yahoo Slurp" content="index,follow,all" />
        <meta name="Rating" content="General" />
        <meta name="Language" content="english" />
        <meta name="distribution" content="Global" />
        <meta name="resource-type" content="document" />
        <meta name="revisit-after" content="7 days" />
        <meta name="subject" content="difuza" />
        <style
          type="text/css"
          dangerouslySetInnerHTML={{
            __html: "\n<!--\n.tesjus {  text-align: justify}\n-->\n"
          }}
        />
        <body className="bg">
          <div className="titlep">
            <font face="Verdana, Arial, Helvetica, sans-serif" size={3}>
              <b>difuza</b>
            </font>
          </div>
          <div align="center" />
          <p>
            <font className="fonting" size={2}>
              <span className="tesjus">Privacy Policy</span>
            </font>
          </p>
          <p className="tesjus1">
            <font className="fonting" size={2}>
              {/* <span className="tesjus1"> */}
              TraXERP built the difuza app as a Commercial app. This SERVICE is
              provided by difuza and is intended for use as is. {/* </span> */}
            </font>
          </p>
          <p className="tesjus1">
            <font className="fonting" size={2}>
              This page is used to inform visitors regarding our policies with
              the collection, use, and disclosure of Personal Information if
              anyone decided to use our Service.{" "}
            </font>
          </p>
          <p className="tesjus1">
            <font className="fonting" size={2}>
              If you choose to use our Service, then you agree to the collection
              and use of information in relation to this policy. The Personal
              Information that we collect is used for providing and improving
              the Service. We will not use or share your information with anyone
              except as described in this Privacy Policy.
            </font>
          </p>
          <p className="tesjus1">
            <font className="fonting" size={2}>
              The terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions, which is accessible at difuza unless
              otherwise defined in this Privacy Policy.
            </font>
          </p>
          <p>
            <b>
              <font className="fonting" size={2}>
                <span className="tesjus">Information Collection and Use</span>
              </font>
            </b>
          </p>
          <p className="tesjus1">
            <font className="fonting" size={2}>
              For a better experience, while using our Service, we may require
              you to provide us with certain personally identifiable
              information, including but not limited to Application ID for Deep
              Linking of App. The information that we request will be retained
              by us and used as described in this privacy policy.
            </font>
          </p>
          <p className="tesjus1">
            <font className="fonting" size={2}>
              The app does use third party services that may collect information
              used to identify you.
            </font>
          </p>
          <p className="tesjus1">
            <font className="fonting" size={2}>
              Link to privacy policy of third party service providers used by
              the app
            </font>
          </p>
          {/* <p className="tesjus1">
            <font className="fonting" size={2}></font>
          </p> */}
          <ul className="ulP">
            <li>
              <font className="fonting" size={2}>
                <a
                  className="link"
                  target="_blank"
                  href="https://policies.google.com/privacy"
                >
                  Google Play Services
                </a>
              </font>
            </li>
          </ul>
          <font face="Verdana, Arial, Helvetica, sans-serif" size={2}></font>
          <p />
          <p>
            <b>
              <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
                <span className="tesjus">Log Data</span>
              </font>
            </b>
          </p>
          <p className="tesjus1">
            <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
              We want to inform you that whenever you use our Service, in a case
              of an error in the app we collect data and information (through
              third party products) on your phone called Log Data. This Log Data
              may include information such as your device Internet Protocol
              (â€œIPâ€) address, device name, operating system version, the
              configuration of the app when utilizing our Service, the time and
              date of your use of the Service, and other statistics.
            </font>
          </p>
          <p>
            <b>
              <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
                <span className="tesjus">Cookies</span>
              </font>
            </b>
          </p>
          <p className="tesjus1">
            <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device's internal memory.
            </font>
          </p>
          <p className="tesjus1">
            <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
              This Service does not use these â€œcookiesâ€ explicitly. However,
              the app may use third party code and libraries that use
              â€œcookiesâ€ to collect information and improve their services.
              You have the option to either accept or refuse these cookies and
              know when a cookie is being sent to your device. If you choose to
              refuse our cookies, you may not be able to use some portions of
              this Service.
            </font>
          </p>
          <p>
            <b>
              <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
                <span className="tesjus">Service Providers</span>
              </font>
            </b>
          </p>
          <p className="tesjus1">
            <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
              We may employ third-party companies and individuals due to the
              following reasons:
            </font>
          </p>
          {/* <p className="tesjus1">
            <font face="Verdana, Arial, Helvetica, sans-serif" size={2}></font>
          </p> */}
          <ul className="ulP">
            <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
              <li>To facilitate our Service;</li>
              <li>To provide the Service on our behalf;</li>
              <li>To perform Service-related services; or</li>
              <li>To assist us in analyzing how our Service is used.</li>
            </font>
          </ul>
          <font face="Verdana, Arial, Helvetica, sans-serif" size={2}></font>
          <p />
          <p className="tesjus1">
            <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
              We want to inform users of this Service that these third parties
              have access to your Personal Information. The reason is to perform
              the tasks assigned to them on our behalf. However, they are
              obligated not to disclose or use the information for any other
              purpose.
            </font>
          </p>
          <p>
            <b>
              <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
                <span className="tesjus">Security</span>
              </font>
            </b>
          </p>
          <p className="tesjus1">
            <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
              We value your trust in providing us your Personal Information,
              thus we are striving to use commercially acceptable means of
              protecting it. But remember that no method of transmission over
              the internet, or method of electronic storage is 100% secure and
              reliable, and we cannot guarantee its absolute security.
            </font>
          </p>
          <p>
            <b>
              <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
                <span className="tesjus">Links to Other Sites</span>
              </font>
            </b>
          </p>
          <p className="tesjus1">
            <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
              This Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by us. Therefore, we
              strongly advise you to review the Privacy Policy of these
              websites. We have no control over and assume no responsibility for
              the content, privacy policies, or practices of any third-party
              sites or services.
            </font>
          </p>
          <p>
            <b>
              <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
                <span className="tesjus">Children's Privacy</span>
              </font>
            </b>
          </p>
          <p className="tesjus1">
            <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
              These Services do not address anyone under the age of 13. We do
              not knowingly collect personally identifiable information from
              children under 13. In the case we discover that a child under 13
              has provided us with personal information, we immediately delete
              this from our servers. If you are a parent or guardian and you are
              aware that your child has provided us with personal information,
              please contact us so that we will be able to do necessary actions.
            </font>
          </p>
          <p>
            <b>
              <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
                <span className="tesjus">Changes to This Privacy Policy</span>
              </font>
            </b>
          </p>
          <p className="tesjus1">
            <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
              We may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. We will
              notify you of any changes by posting the new Privacy Policy on
              this page. These changes are effective immediately after they are
              posted on this page.
            </font>
          </p>
          <p>
            <b>
              <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
                <span className="tesjus">Contact Us</span>
              </font>
            </b>
          </p>
          <p className="tesjus1">
            <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
              If you have any questions or suggestions about our Privacy Policy,
              do not hesitate to contact us at difuzapp@gmail.com.
            </font>
          </p>
          <p className="tesjus1">
            <font face="Verdana, Arial, Helvetica, sans-serif" size={2}>
              This privacy policy page was created at{" "}
              <a
                className="link"
                target="_blank"
                href="https://www.privacypolicytemplate.net/"
              >
                privacypolicytemplate.net
              </a>{" "}
              and modified/generated by{" "}
              <a
                className="link"
                target="_blank"
                href="https://app-privacy-policy-generator.firebaseapp.com/"
              >
                App Privacy Policy Generator
              </a>
            </font>
          </p>
        </body>
      </React.Fragment>
    );
  }
}

export default Privacy;
