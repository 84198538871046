import React, { Component } from "react";
import cmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
// import { Alert } from "reactstrap";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import SingleSelect from "../../../components/Form/SingleSelect";
import { PDFDownloadLink } from "@react-pdf/renderer";
import SurveyRpPdf from "../../../components/PDF/SurveyRpPdf";

class SurveyReports extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      customerid: localStorage.getItem("userId"),
      page2: 0
    };
  }

  async componentWillMount() {
    try {
      let user = await cmsContent.getTwoConditionedValue(
        "tbl_user_web",
        "customerid",
        this.state.customerid,
        1,
        1,
        "id as value ,userName as label"
      );

      let question = await cmsContent.getTwoConditionedValue(
        "tbl_surveyQuestion",
        "companyid",
        this.state.customerid,
        1,
        1,
        "id as value ,questions as label"
      );

      let SurveyList = await cmsContent.getTwoConditionedValue(
        "tbl_survey",
        "customerid",
        this.state.customerid,
        1,
        1,
        "id as value ,surveyname as label"
      );
      let SurveyAnsList = await cmsContent.getTwoConditionedValue(
        "tbl_surveyAnswer",
        "customerid",
        this.state.customerid,
        1,
        1
        //   "id as value ,surveyname as label"
      );

      if (SurveyList) {
        this.setState({
          SurveyList: SurveyList.data,
          user: user.data,
          question: question.data,
          SurveyAnsList: SurveyAnsList.data
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  column = [
    {
      Header: "Username",
      accessor: "userid",
      Cell: d => this.getValueFromArray(d.original.userid, this.state.user)
    },
    {
      Header: "Total Questions",
      accessor: "question",
      Cell: d => this.Tque(d.original)
    },

    // {
    //   Header: "Answer",
    //   accessor: "answer"
    // },
    {
      Header: "Score",
      accessor: "mark",
      Cell: d => this.Score(d.original)
    },
    {
      Header: "Attempts",
      accessor: "portId",
      Cell: d => this.attempt(d.original)
    },
    {
      Header: "View",
      accessor: "id",
      Cell: d => this.View(d)
    }
  ];
  column1 = [
    // {
    //   Header: "Username",
    //   accessor: "userid",
    //   Cell: d => this.getValueFromArray(d.original.userid, this.state.user)
    // },
    {
      Header: "Questions",
      accessor: "question"
    },
    {
      Header: "Answer",
      accessor: "answer"
    },
    {
      Header: "Score",
      accessor: "mark"
    },
    {
      Header: "Date",
      accessor: "date",
      Cell: d => this.date(d.original.date)
    }
  ];
  header1 = ["SI.NO", "USERNAME", "TOTAL QUESTION", "SCORE", "ATTEMPTS"];
  header2 = ["SI.NO", "QUESTION", "ANSWER", "SCORE", "DATE"];
  date = d => {
    let dt = new Date(d);
    var date = dt.getDate();
    var month = dt.getMonth() + 1;
    var year = dt.getFullYear();
    return date + "-" + month + "-" + year;
  };
  View = (d, value) => {
    return (
      <a
        href="#"
        onClick={() => {
          this.doFunction(d);
        }}
      >
        View
      </a>
    );
  };
  doFunction = async d => {
    try {
      const { selectSurvey, userselect } = this.state;
      let result = await cmsContent.getFreedom(
        "tbl_surveyAnswer.surveyAnswer as answer , tbl_surveyAnswer.mark as mark , tbl_surveyAnswer.userid as userid ,tbl_surveyQuestion.questions as question , tbl_surveyAnswer.createdAt as date",
        "tbl_surveyAnswer,tbl_surveyQuestion,tbl_survey_mapping",
        `tbl_surveyAnswer.userid = ${userselect.value} and  tbl_surveyAnswer.surveyId = ${selectSurvey.value} and tbl_survey_mapping.surveyid = ${selectSurvey.value} and tbl_survey_mapping.surveyid = ${selectSurvey.value}  and tbl_survey_mapping.surveyQuestionid = tbl_surveyAnswer.questionid and  tbl_surveyAnswer.questionid = tbl_surveyQuestion.id  and  tbl_surveyAnswer.customerid = ${this.state.customerid} `,
        "tbl_surveyAnswer.id",
        "tbl_surveyAnswer.id"
      );
      this.setState({ page2: 1, Report1: result.data });
    } catch (error) {
      console.error(error);
    }
  };
  attempt = d => {
    let surveyQue = this.state.surveyQue;
    let userScore = this.state.userScore;
    // console.log(surveyQue.length);
    //console.log(userScore.length);
    let att = userScore.length / surveyQue.length;
    return att;
  };
  Tque = d => {
    let surveyQue = this.state.surveyQue;
    if (surveyQue) {
      return surveyQue.length;
    }
  };
  Score = d => {
    let userScore = this.state.userScore;
    let count = 0;
    userScore.map((item, i) => {
      count = count + item.mark;
    });
    return count;
  };

  selectSurvey = async e => {
    try {
      let UserList = await cmsContent.getFreedom(
        "tbl_user_web.userName as label ,tbl_user_web.id as value",
        "tbl_surveyAnswer,tbl_survey,tbl_user_web",
        `tbl_survey.id = tbl_surveyAnswer.surveyId and tbl_user_web.id = tbl_surveyAnswer.userid and tbl_surveyAnswer.surveyId =${e.value} and tbl_user_web.customerId = ${this.state.customerid} `,
        "tbl_surveyAnswer.userid",
        1
      );

      console.log(UserList);

      let surveyQue = await cmsContent.getFreedom(
        "*",
        "tbl_survey_mapping",
        `tbl_survey_mapping.surveyid = ${e.value} and tbl_survey_mapping.customerId = ${this.state.customerid} `,
        "tbl_survey_mapping.id",
        "tbl_survey_mapping.id"
      );
      await this.setState({
        selectSurvey: e,
        UserList: UserList.data,
        surveyQue: surveyQue.data
      });
      // console.log(UserList.data);
    } catch (error) {
      console.error(error);
    }
  };

  selectUser = async e => {
    const { selectSurvey } = this.state;
    this.setState({ userselect: e, userId: e.value });
    console.log(`tbl_surveyAnswer.userid = ${e.value} and
    tbl_surveyAnswer.surveyId = ${selectSurvey.value} and tbl_survey_mapping.surveyid = ${selectSurvey.value} and tbl_survey_mapping.surveyQuestionid = tbl_surveyAnswer.questionid and  tbl_surveyAnswer.questionid = tbl_surveyQuestion.id  and  tbl_surveyAnswer.customerid = ${this.state.customerid}`)
    let Report = await cmsContent.getFreedom(
      "tbl_surveyAnswer.surveyAnswer as answer , tbl_surveyAnswer.mark as mark , tbl_surveyAnswer.userid as userid ,tbl_surveyQuestion.questions as question",
      "tbl_surveyAnswer,tbl_surveyQuestion,tbl_survey_mapping",
      `tbl_surveyAnswer.userid = ${e.value} and
      tbl_surveyAnswer.surveyId = ${selectSurvey.value} and tbl_survey_mapping.surveyid = ${selectSurvey.value} and tbl_survey_mapping.surveyQuestionid = tbl_surveyAnswer.questionid and  tbl_surveyAnswer.questionid = tbl_surveyQuestion.id  and  tbl_surveyAnswer.customerid = ${this.state.customerid}`,
      "tbl_surveyAnswer.id",
      "tbl_surveyAnswer.id"
    );

    console.log(Report.data)

    let dataTable = await cmsContent.getFreedom(
      "tbl_surveyAnswer.*",
      "tbl_boxcontent,tbl_surveyAnswer",
      `tbl_surveyAnswer.surveyId = ${selectSurvey.value} and tbl_surveyAnswer.userid = ${e.value} and tbl_boxcontent.type = 10 and tbl_boxcontent.survey = ${selectSurvey.value}  and tbl_boxcontent.customerid=${this.state.customerid} and tbl_surveyAnswer.customerid=${this.state.customerid} and tbl_boxcontent.id = tbl_surveyAnswer.portId`,
      "tbl_surveyAnswer.userid",
      "tbl_surveyAnswer.id"
    );

    this.setState({ Report: dataTable.data, userScore: Report.data });
    //console.log( Report.data)
  };

  getValueFromArray1 = (d, array) => {
    console.log(d);
    console.log(array);
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.id == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.surveyname;
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  };

  OnBack = val => {
    this.setState({ page2: val - 1 });
  };

  render() {
    const {
      data1,
      SurveyList,
      UserList,

      selectSurvey,
      Report,
      surveyname,
      alertVisible,
      question,
      answer,
      page2,
      Report1,
      userselect
    } = this.state;

    if (page2 === 0) {
      var tableData = Report;

      var column = this.column;
      var header = this.header1;
    } else if (page2 === 1) {
      var tableData = Report1;
      var column = this.column1;
      var header = this.header2;
    }
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h1>Survey Reports</h1>
              </div>
              <div className="card-body" style={{ height: (selectSurvey && UserList.length > 0) ? "auto" : "400px" }}>
                <div className="row form-group">
                  <div className="col-sm-2" />

                  <div className="col-sm-2">
                    <label htmlFor="exampleInputEmail1">Survey Name</label>
                  </div>

                  <div className="col-sm-5">
                    <SingleSelect
                      options={SurveyList}
                      handleChange={e => this.selectSurvey(e)}
                      value={this.state.box}
                      placeholder="Survey Name"
                    />
                  </div>
                  <div className="col-sm-3" />
                </div>
                <div></div>
                {selectSurvey && UserList.length > 0 && (
                  <div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">User</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          options={UserList}
                          placeholder="Select User"
                          handleChange={e => this.selectUser(e)}
                          value={this.state.userselect}
                        />
                      </div>
                      <div className="col-sm-3" />
                      <br /><br /><br /><br />
                      {tableData && tableData.length > 0 && (
                        <PDFDownloadLink
                          style={{ color: "white" }}
                          document={
                            <SurveyRpPdf
                              header={header}
                              pg={page2}
                              data={tableData}
                              User={userselect.label}
                              score={this.state.userScore}
                              ques={this.state.surveyQue}
                            />
                          }
                          fileName="sample.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? "Loading document..." : "Download Pdf"
                          }
                          <button
                            className="btn btn-warning"
                            style={{ marginLeft: "23px" }}
                          >
                            PDF
                          </button>
                        </PDFDownloadLink>
                      )}
                    </div>
                    {this.state.userselect && (
                      <div className="row form-group">
                        <div className="col-sm-12">
                          <div className="row form-group ml-0 mb-3">
                            <div className="col-sm-1 p-0">
                              {page2 !== 0 &&
                                this.dataTableButton("danger", "Back", () => {
                                  this.OnBack(page2);
                                })}
                            </div>
                            <div className="col-sm-11 h2">
                              <center>
                                {page2 === 2 &&
                                  `${userselect.label}'s response on date`}
                              </center>
                            </div>
                          </div>

                          <div className="col-sm-12">
                            {tableData && (
                              <Datatable
                                data={tableData}
                                columnHeading={column}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default SurveyReports;
