import React, { Component } from 'react';
import User from '../../MiddleWare/User';
import moment from 'moment';
import swal from "sweetalert";
import { STEERAUTO } from '../../config';
var aesjs = require('aes-js');
class FormMiddleWare extends Component {
  removeDuplicates = (originalArray, key) => {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][key]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  };
  handleUserInput = e => {
    // console.log(e.target.name, e.target.value, "e.target.name, e.target.value ")
    if (e.target.name === 'email') {
      this.setState({ updateEmail: true });
    }
    if (e.target.name == "mobile") {
      e.target.value = e.target.value.slice(0, 10)
    }
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSelect = (e, name) => {
    this.setState({ [name]: e })
  }

  handleChange = selectedOption => {
    let selectedValue = '';
    selectedOption.map(values => (selectedValue += `${values.value},`));
    selectedValue = selectedValue.replace(/,\s*$/, '');
    this.setState({
      selectedService: selectedOption,
      servicesValue: selectedValue
    });
  };

  CheckPassword = async (password) => {
    // let password = this.state.password;
    var passw = /^[A-Za-z]\w{7,14}$/;
    if (password.match(passw)) {
      this.setState({ passError: null, errorPassword: null });
      return true;
    }
    else {
      this.setState({
        errorPassword: "* Password contains 7 to 16 characters which contain only characters, numeric digits, underscore and first character must be a letter",
        passError: "* Password contains 7 to 16 characters which contain only characters, numeric digits, underscore and first character must be a letter"
      });
      return false;
    }
  }

  validate() {

    let valid = true
    const { leadname, usertypelog, mobile, stateselected, Register, errorregno, errormobile, errorleadname, errorlocation, status1, errorstatus1, errorNext_Contact_date, Next_Contact_date, sources } = this.state;

    // console.log(mobile, "mobile" )
    // console.log(status1, "status1" )
    // console.log(Next_Contact_date, "Next_Contact_date" )
    // console.log(Register, "registervalidate")

    if (leadname === '') {
      // console.log("leadname invalid")
      this.setState({ errorleadname: 'Please enter name' });
      valid &= false;
    } else {
      // console.log("leadname valid")
      this.setState({ errorleadname: '' });
    }
    if (mobile === '') {
      // console.log("mobile valid")
      this.setState({ errormobile: 'Mobile Number Required' });
      valid &= false;
    } else {
      // console.log("mobile invalid")
      this.setState({ errormobile: '' });
    }
    if (mobile) {
      if (this.phonenumber(mobile)) {
        // console.log("mobile invalid 111")
        valid = true;
      } else {
        // console.log("mobile valid  111")
        this.setState({ errormobile: 'Please Enter Valid Mobile Number' });
        valid = false;
      }
    }
    if (usertypelog.orderBy == 1 && stateselected === '') {
      // console.log( "usertypelog invalid")
      this.setState({ errorlocation: 'Location  Required' });
      valid &= false;
    } else {
      // console.log( "usertypelog valid")
      this.setState({ errorlocation: '' });
    }
    if (Object.keys(status1).length === 0) {
      // console.log("objecct keys  invalid'")
      this.setState({ errorstatus1: 'Status1 Required' });
      valid &= false;
    } else {
      // console.log("objecct keys  valid'")
      this.setState({ errorstatus1: '' });
    }
    if (status1.label != "Mark Closed" && Next_Contact_date === '') {
      // console.log("Next_Contact_date  invalid'")
      this.setState({ errorNext_Contact_date: 'Select Next-Followup Date' });
      valid &= false;
    } else {
      // console.log("Next_Contact_date valid ")
      this.setState({ errorNext_Contact_date: '' });
    }
    if (Register === "") {
      this.setState({ errorregno: 'Register Number Required' });
      valid &= false;
    }
    else {
      this.setState({ errorregno: "" });
    }
    if (sources === "") {
      this.setState({ errorsources: "Sources Required" })
      valid &= false;
    } else {
      this.setState({ errorsources: "" })
    }

    return valid

  };


  checkEmail = async () => {
    if (!this.state.updateEmail) return true;
    let email = this.state.email;
    let { currentmail, updateData } = this.state;
    if (email === '') {
      this.setState({ errorEmail: 'Enter Email' });
      return false;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      this.setState({ errorEmail: 'Invalid Email' });
      return false;
    } else {
      this.setState({ errorEmail: '' });

      try {
        if ((currentmail == email) && updateData) {
          this.setState({ errorEmail: '' });
          return true;
        } else {
          const result = await User.userEmailCheck(email);
          if (result) {
            this.setState({ errorEmail: 'Email already exists' });
            return false;
          }
        }
      } catch (error) {
        this.setState({ errorEmail: '' });
        // console.log(error);
        return true;
      }
    }
  };

  showVisibility = d => {

    let status = d.original.status;
    let colour = d.original.status == 'active' ? 'warning' : 'danger';
    return this.dataTableButton(colour, status, () => this.updateVisibility(d));
  };

  updateVisibility = async value => {
    let values = value.original;
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };
    if (newData.status === 'active') {
      newData.status = 'Inactive';
      this.setState({ button: 'Inactive' });
    } else {
      newData.status = 'active';
      this.setState({ button: 'active' });
    }

    const id = newData.id;
    const data = previousData.filter(value => value.id !== id);
    try {
      const result = await User.editSelectedUsers(id, newData);
      if (result) {
        data.splice(index, 0, newData);
        this.setState({ data: data });
      }
    } catch (error) {
      console.log(error);
    }
  };
  uploadimg = e => {


    //this.setState({ FileName: e.target.files[0] });
    this.setState({ logoname: e.target.files[0].name });
    this.setState({ imgupload: e.target.files[0] });


  };
  checkMobileNumber = async () => {
    if (this.state.updateData) return true;
    let mobileNumber = this.state.mobileNumber;
    if (mobileNumber === '') {
      this.setState({
        errorUserName: '',
        errorMobileNumber: 'Enter Mobile Number'
      });
      return false;
    } else if (!/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/.test(mobileNumber)) {
      this.setState({ errorMobileNumber: 'Invalid Mobile Number' });
      return false;
    } else {
      this.setState({ errorMobileNumber: '' });
      try {
        const result = await User.userMobileCheck(mobileNumber);
        console.log(result, 'result')
        if (result && result.data && result.data.length > 0) {
          this.setState({ errorMobileNumber: 'Mobile number already exists' });
          return false;
        }
      } catch (error) {
        this.setState({ errorMobileNumber: '' });
        // console.log(error);
        return true;
      }
    }
  };

  checkDomain = async (domain) => {
    if (domain !== "") {
      const checkDomain = await User.userDomainCheck(domain);
      if (checkDomain) {
        return checkDomain
      }
    }
  }

  singlefileupload = e => {
    this.setState({ [e.target.name]: e.target.files[0] })
  }
  handleSubmit = async () => {
    let userName = this.state.userName;
    let UserPassword = this.state.UserPassword
    let selectedloginType = this.state.selectedloginType
    let selectedService = this.state.selectedService
    let domain = this.state.domain
    let templateCheck = this.state.templateCheck
    if (userName === '') {
      this.setState({ errorUserName: 'Enter Username' });
      return false;
    } else {
      this.setState({ errorUserName: '' });
    }
    if (UserPassword === '') {
      this.setState({ errorPassword: 'Enter Password' });
      return false;
    } else {
      this.setState({ errorPassword: '' });
    }
    if (selectedloginType && selectedloginType == null) {
      this.setState({ errorloginType: 'Please select LoginType' })
      return false;
    } else {
      this.setState({ errorloginType: '' })
    }
    if (templateCheck === true) {
      if (domain === '') {
        this.setState({ errorDomain: 'Enter Domain Name' });
        return false;
      } else if (domain) {
        let availability = await this.checkDomain(domain);
        if (availability === "Not available") {
          this.setState({ errorDomain: 'This domain is not available' });
          return false;
        } else if(availability === "Available"){
          this.setState({ errorDomain: '' });
        }
      } else {
        this.setState({ errorDomain: '' });
      }
    }
    if (selectedService && selectedService.length == 0) {
      this.setState({ errorServices: 'Please select Service' })
      return false;
    } else {
      this.setState({ errorServices: '' })
    }
    if ((await this.checkMobileNumber()) && (await this.checkEmail())) {
      this.state.updateData === false ? this.onSubmit() : this.onUpdate();
    }
  };

  onDismiss = () => {
    this.setState({ alertVisible: false });
  };

  // Table
  edit = (value, modalWindowId) => {
    return (
      <center>
        <button type="button" data-toggle="modal" data-target={`#${modalWindowId}`} style={{ width: 80 }} className="btn btn-info" onClick={() => this.buttonEdit(value)}>
          Edit
        </button>
      </center>
    );
  };

  delete = value => {
    return (
      <center>
        <button type="button" className="btn btn-danger" style={{ width: 80 }} onClick={() => this.buttonDelete(value)}>
          Delete
        </button>
      </center>
    );
  };

  // Table

  // DatePicker
  changeDate = date => {

    this.setState({
      startDate: date
    });
    let changeDate = moment(date).format('MM-DD-YYYY HH:mm:ss');

  };
  //Date picker

  getValueFromArray = (d, array, ch = "value", ch1 = "label") => {
    if (array.length > 0) {

      let filtered = array.filter(function (item) {
        return item[ch] == d;
      });
      let v = filtered[0];
      if (v != undefined) {
        return v[ch1];
      } else {
        return '-';
      }

    } else {
      return '-';
    }
  };
  DatatableCheckbox = (d, func) => {
    return (
      <div style={{ 'text-align': 'center' }}>
        <input type="checkbox"
          defaultChecked={d.value == "Yes" ? true : false}
          onChange={(event) => func(parseInt(d.original.id), d.column.id, event.target.checked ? "Yes" : "No")} />
      </div>)
  };

  dataTableButton = (info, con, func, disabled = false) => {
    let classes = 'btn btn-';
    classes += info;
    return (
      <center>
        <button type="button" disabled={disabled} className={classes} onClick={func}>
          {con}
        </button>
      </center>
    );
  };
  getDate(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');
  }

  dataTableModalButton = (modalWindowId, info, con, func) => {
    let classes = 'btn btn-';
    classes += info;
    return (
      <center>
        <button type="button" data-toggle="modal" data-target={`#${modalWindowId}`} className={classes} onClick={func}>
          {con}
        </button>
      </center>
    );
  };

  filterBy(list, criteria) {
    return list.filter(candidate => Object.keys(criteria).every(key => candidate[key] == criteria[key]));
  }
  NoneEmpty = (arr) => {
    //validate non empty sTRing ''
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === "") return false;
    }
    return true
  }
  phonenumber = (inputtxt) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (inputtxt.match(phoneno)) {
      return true
    }
    else {
      return false;
    }
  }
  Encrypt(text) {
    // An example 128-bit key (16 bytes * 8 bits/byte = 128 bits)
    var key = [13, 2, 11, 14, 16, 9, 8, 12, 3, 15, 10, 1, 4, 5, 6, 7];

    // Convert text to bytes
    // var text = 'Text may be any length you wish, no padding is required.';
    var textBytes = aesjs.utils.utf8.toBytes(text);

    // The counter is optional, and if omitted will begin at 1
    var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
    var encryptedBytes = aesCtr.encrypt(textBytes);

    // To print or store the binary data, you may convert it to hex
    var encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
    // console.log(encryptedHex);
    // "a338eda3874ed884b6199150d36f49988c90f5c47fe7792b0cf8c7f77eeffd87
    //  ea145b73e82aefcf2076f881c88879e4e25b1d7b24ba2788"

    // When ready to decrypt the hex string, convert it back to bytes
    var encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);

    // The counter mode of operation maintains internal state, so to
    // decrypt a new instance must be instantiated.
    var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
    var decryptedBytes = aesCtr.decrypt(encryptedBytes);

    // Convert our bytes back into text
    var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
    // console.log(decryptedText);
    // "Text may be any length you wish, no padding is required."

    return encryptedHex

  }
  Decrypt(encryptedHex) {
    try {
      // An example 128-bit key (16 bytes * 8 bits/byte = 128 bits)
      var key = [13, 2, 11, 14, 16, 9, 8, 12, 3, 15, 10, 1, 4, 5, 6, 7];

      // When ready to decrypt the hex string, convert it back to bytes
      var encryptedBytes = aesjs.utils.hex.toBytes(encryptedHex);

      // The counter mode of operation maintains internal state, so to
      // decrypt a new instance must be instantiated.
      var aesCtr = new aesjs.ModeOfOperation.ctr(key, new aesjs.Counter(5));
      var decryptedBytes = aesCtr.decrypt(encryptedBytes);

      // Convert our bytes back into text
      var decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
      // console.log(decryptedText);
      // "Text may be any length you wish, no padding is required."
      return decryptedText

    } catch (error) {
      console.log(error, "error");
      return null
    }

  }
  encryption = async (name, data) => {
    try {
      if (data) {
        let encrypt = JSON.stringify(data)
        let ENcrypted = this.Encrypt(encrypt)
        localStorage.setItem(`${name}`, ENcrypted);
      }
    } catch (error) {
      console.log(error);
    }
  }
  decryption = async (name) => {
    try {
      let getdata = await localStorage.getItem(`${name}`)
      if (getdata) {
        let Decrypt = await this.Decrypt(getdata)
        if (Decrypt) {
          let Data = await JSON.parse(Decrypt)
          if (Data) {
            return Data
          }
        }
      } else {
        return null
      }
    } catch (error) {
      console.log(error);
    }
  }
  Productnav = async (routepage) => {
    let id = await this.Encrypt(routepage)
    // window.open(`${STEERAUTO}/SingleListCars?product=${id}`)
    window.location.href = `${STEERAUTO}/SingleListCars?product=${id}`
  }
  alert_validation = async (func, d) => {
    swal({
      title: "Are you sure?",
      icon: "warning",
      // buttons: ["Reject", "Accept"],
      buttons: {
        catch: {
          text: "Reject",
          value: "catch",
          className: 'swal-button--cancel'
        },
        defeat: "Accept",
      },
      dangerMode: true,
    })

      .then(async (willDelete) => {
        switch (willDelete) {
          case "defeat":
            func(d, "yes")
            break;

          case "catch":
            func(d, "no")
            break;

          default:
            swal.close()
        }
      });
  }
  alert_Submit = async (func, d = null, title = "Are you sure?") => {
    swal({
      title: title,
      // text: `You want to Plac`,
      icon: "warning",
      buttons: ["No", "Submit"],
      // buttons: true,
      dangerMode: true,
    })

      .then(async (willDelete) => {
        if (willDelete) {
          func(d, "yes")
        } else {
          swal.close()
        }
      });;
  }
  alert_DisSelect = async (func, d = null, title = "Are you sure?") => {
    swal({
      title: title,
      // text: `You want to Plac`,
      icon: "warning",
      buttons: ["No", "DisSelect"],
      // buttons: true,
      dangerMode: true,
    })

      .then(async (willDelete) => {
        if (willDelete) {
          func(d, "yes")
        } else {
          swal.close()
        }
      });;
  }
  ScrollTop = (top = 0) => {
    window.scroll({
      top: top,
      left: 0,
      behavior: "smooth",
    });
  }


  formSave = async (ref, customerId, answerJson, field = 'data') => {
    try {
      if (localStorage.getItem("formFillData")) {
        let formJson = await JSON.parse(localStorage.getItem("formFillData"))

        if (formJson && formJson.length) {
          let Index = await formJson.findIndex(e => e.prosid == customerId && e.ref == ref)
          if (Index > -1) {
            let object = formJson[Index]
            object.prosid = customerId
            object.ref = ref
            object[field] = answerJson
            formJson[Index] = object
            await localStorage.setItem("formFillData", JSON.stringify(formJson))
          } else {
            let object = {}
            object.prosid = customerId
            object.ref = ref
            object[field] = answerJson
            formJson.push(object)
            await localStorage.setItem("formFillData", JSON.stringify(formJson))
          }
        } else {
          let object = {}
          object.prosid = customerId
          object.ref = ref
          object[field] = answerJson
          formJson.push(object)
          await localStorage.setItem("formFillData", JSON.stringify(formJson))
        }
      } else {

        let formJson = []
        let object = {}
        object.prosid = customerId
        object.ref = ref
        object[field] = answerJson
        formJson[0] = object
        await localStorage.setItem("formFillData", JSON.stringify(formJson))
      }

    } catch (error) {
      console.log(error);
    }
  }

  getSavedData = async (ref, customerId) => {
    if (localStorage.getItem("formFillData")) {
      let formFillData = await JSON.parse(localStorage.getItem("formFillData"))
      const Data = await formFillData.filter(e => e.prosid == customerId && e.ref == ref)
      if (Data && Data.length) {
        let answerJson = Data[0]
        return answerJson
      } else return false
    } else return false
  }

  RemoveFormData = async (ref, customerId) => {
    if (localStorage.getItem("formFillData")) {
      let formJson = await JSON.parse(localStorage.getItem("formFillData"))
      if (formJson && formJson.length) {
        let Index = await formJson.findIndex(e => e.prosid == customerId && e.ref == ref)
        console.log(ref, customerId, Index, 'check', Index > -1);
        if (Index > -1) formJson.splice(Index, 1)
        localStorage.setItem("formFillData", JSON.stringify(formJson))
      }
    }
  }

  TypingControl = (func) => {
    var duration = 800;
    clearTimeout(this.inputTimer);
    this.inputTimer = setTimeout(() => {
      func()
    }, duration);
  }

  Call = (mobileNumber) => {
    if (mobileNumber) {
      return <span onClick={() => { window.location.href = `tel:${mobileNumber}` }} ><em>{mobileNumber}</em></span>

    } else return '-'
  }
  autoUpdate = async (auto = false) => {
    console.log(process.env.NODE_ENV, "process.env.NODE_ENV", 'serviceWorker' in navigator, "'serviceWorker' in navigator");
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      const registration = await navigator.serviceWorker.ready;
      console.log(registration, "registration");
      registration.addEventListener("updatefound", event => {
        if (auto) {
          console.log(registration.installing, "Enter PWA Auto Update");
          const newSW = registration.installing;
          newSW.addEventListener("statechange", event => {
            if (newSW.state == "installed") {
              // swal(`Updated`);
            }
            if (newSW.state == "activated") {
              window.location.reload()
            }
          });
        } else {
          console.log("Enter PWA Manual Update");
          const newSW = registration.installing;
          newSW.addEventListener("statechange", event => {
            if (newSW.state == "installed") {
              swal(`Updated`);
            }
            if (newSW.state == "activated") {
              window.location.reload()
            }
          });
        }
      })
    }
  }
}



export default FormMiddleWare;
