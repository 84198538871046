import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import SingleSelect from "../../../../components/Form/SingleSelect";
import { ACCESS_POINT } from "../../../../config";
import { ACCESS_SAMPLE } from "../../../../config";
import http from "../../../../MiddleWare/httpMiddleWare";
import draftToHtml from "draftjs-to-html";
import Datatable from "../../../../components/Datatable/Datatable";
import cmsContent from "../../../../MiddleWare/CmsContent";
import htmlToDraft from "html-to-draftjs";
import Progress from "../../../../components/Extra/Progress";

class Theme4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      btnDisable: false,
      //   rowCount: 0
      data: props.that.data,
      rowCount: [],
      widthInPercentage: [
        { label: "0%", value: 0 },
        { label: "8%", value: 1 },
        { label: "16%", value: 2 },
        { label: "24%", value: 3 },
        { label: "36%", value: 4 },
        { label: "42%", value: 5 },
        { label: "52%", value: 6 },
        { label: "58%", value: 7 },
        { label: "64%", value: 8 },
        { label: "72%", value: 9 },
        { label: "84%", value: 10 },
        { label: "96%", value: 11 },
        { label: "100%", value: 12 }
      ],
      ImageContentWidth: [],
      TextContentWidth: [],
      Order: [
        { label: "Image Before Text", value: 1 },
        { label: "Image After Text", value: 2 }
      ],
      OrderSeleceted: [],
      linkto: null,
      files: [],
      isEdit: false,
      screen: window.screen.height,
      uploadPercentage: 0
    };
  }
  async componentWillMount() {
    try {

      const { customerId } = this.props.that;

      const Author = await cmsContent.getFreedom(
        "id as value,name as label",
        "tbl_author",
        `customerId=${customerId}`,
        "id",
        "id DESC"
      );
      if (Author) {
        this.setState({ authorlist: Author.data });
      }
      if (this.props.that.data) {
        let pages = [];
        this.props.that.data.map((ival, i) => {
          if (ival.themeId === 4) {
            pages.push(ival);
          }
        });
        this.setState({ data: pages });
      }
      if (this.props.that.isEdit) {
        console.log("get", this.props.that.theme4);
        let sheetContent = JSON.parse(this.props.that.theme4);
        // console.log(sheet)
        sheetContent.map((ival, i) => {
          let { contentBlocks, entityMap } = htmlToDraft(ival.text);
          let contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap
          );
          sheetContent[i].text = EditorState.createWithContent(contentState);
          sheetContent[i].imagewidth = this.state.widthInPercentage.filter(
            jval => jval.value === ival.imagewidth
          )[0];
          sheetContent[i].textwidth = this.state.widthInPercentage.filter(
            jval => jval.value === ival.textwidth
          )[0];
          sheetContent[i].order = this.state.Order.filter(
            jval => jval.value === ival.order
          )[0];
        });
        this.setState({
          isEdit: true,
          EditId: this.props.that.id,
          rowCount: sheetContent,
          data: this.props.that.datatable,
          searchTags:this.props.that.searchTags
        });
        console.log("shet", this.state.rowCount);
      }
    } catch (e) {
      console.log(e);
    }
  }
  column = [
    {
      Header: "Page",
      accessor: "name"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "Theme",
      accessor: "edit",
      Cell: d => this.showTheme(d)
    },
    {
      Header: "Visibility",
      accessor: "edit",
      Cell: d => this.showVisibility(d)
    },
    {
      Header: "Require Login",
      accessor: "requireLogin"
    },
    {
      Header: "Change Login Status",
      accessor: "edit",
      Cell: d => this.changeLoginStatus(d)
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: d => this.Editpages(d)
    },
    {
      Header: "Preview",
      accessor: "edit",
      Cell: d => this.PreviewPage(d)
    }
  ];
  showTheme = (d) => {
    return `Theme-${d.original.themeId}(To Add Content Section wise)`;
  };
  PreviewPage = d => {
    return (
      <button
        type="button"
        className="btn btn-info"
        onClick={() => this.PreviewRoute(d)}
      >
        Preview
      </button>
    );
  };

  PreviewRoute = d => {
    console.log(d);
    let id = d.original.id;
    let customerId = d.original.customerId;
    if (id) {
      window.open(
        `${ACCESS_SAMPLE}/samplesite/dynamicth4/${id}/${customerId}`,
        "_blank"
      );
    }
  };

  Editpages = value => {
    return (
      <button className="btn btn-info" onClick={() => this.onEdit(value)}>
        Edit
      </button>
    );
  };

  removeArray = (i) => {
    let index1 = i - 1;
    console.log(this.state.rowCount)
    this.state.rowCount.splice(index1, 1)
  }

  onEdit = value => {
    let org = value.original;
    const index = value.index;
    //Theme Selction
    const options = [
      { label: "Theme1", value: "1" },
      { label: "Theme2", value: "2" },
      { label: "Theme3", value: "3" },
      { label: "Theme4", value: "4" },
      { label: "Theme5", value: "5" },
      { label: "Theme6", value: "6" },
      { label: "Theme7", value: "7" },
      { label: "Theme8", value: "8" },
      { label: "Theme9", value: "9" }
    ];
    if (typeof this.props.these === "function") {
      this.props.these("pageName", org.name);
      this.props.these(
        "autherSelected",
        this.props.that.authorlist.filter(
          ival => ival.value === org.authorid
        )[0]
      );
      this.props.these('themeSelected', options.filter(ival => ival.value == org.themeId)[0]);
      this.props.these("checked", org.show == 1 ? 1 : 0);
    }
    let sheetContent = JSON.parse(org.sheetContent);
    sheetContent.map((ival, i) => {
      let { contentBlocks, entityMap } = htmlToDraft(ival.text);
      let contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      sheetContent[i].text = EditorState.createWithContent(contentState);
      sheetContent[i].imagewidth = this.state.widthInPercentage.filter(
        jval => jval.value === ival.imagewidth
      )[0];
      sheetContent[i].textwidth = this.state.widthInPercentage.filter(
        jval => jval.value === ival.textwidth
      )[0];
      sheetContent[i].order = this.state.Order.filter(
        jval => jval.value === ival.order
      )[0];
    });
    this.setState({
      rowCount: sheetContent,
      isEdit: true,
      EditId: org.id,
      files: [],
      Index: index,
      searchTags: org.searchTags,
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  };

  showVisibility = id => {
    return (
      <button
        type="button"
        className="btn btn-info"
        onClick={() => this.updateVisibility(id)}
      >
        Hide/Show
      </button>
    );
  };
  updateVisibility = async value => {
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };

    if (newData.status === "show") {
      newData.status = "hide";
    } else {
      newData.status = "show";
    }
    const id = newData.id;
    const data = previousData.filter(value => value.id !== id);
    data.splice(index, 0, newData);
    try {
      const result = await cmsContent.updatePage(newData);
      if (result) {
        this.setState({ data: data });
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };

  changeLoginStatus = id => {
    return (
      <button
        type="button"
        className="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '10px' }}
        onClick={() => this.updateLogin(id)}
      >
        Change Login Status
      </button>
    );
  };

  updateLogin = async value => {
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };
    if (newData.requireLogin === "yes") {
      newData.requireLogin = "no";
    } else {
      newData.requireLogin = "yes";
    }
    const id = newData.id;
    const data = previousData.filter(value => value.id !== id);
    data.splice(index, 0, newData);
    this.setState({ data });
    try {
      const result = await cmsContent.updateLoginStatus(newData);
      if (result) {
        this.setState({ data: data });
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };

  addRow = (a, b = false) => {
    const rowCount = this.state.rowCount;
    if (b == false) {
      rowCount.push({
        text: EditorState.createEmpty(),
        imagewidth: null,
        textwidth: null,
        image: null
      });
    } else {
      let index1 = a - 1;
      rowCount.splice(index1, 1)
    }

    this.setState({ rowCount });
  };
  commonFun = (d, i, t) => {
    let { rowCount, files, widthInPercentage } = this.state;
    if (t === "text") {
      rowCount[i][t] = d;
    } else if (t === "textwidth") {
      rowCount[i]["imagewidth"] = widthInPercentage.filter(
        ival => ival.value === 12 - d.value
      )[0];
      rowCount[i][t] = widthInPercentage.filter(
        ival => ival.value === d.value
      )[0];
    } else if (t === "image") {
      rowCount[i][t] = d.target.files[0].name;
      files.push({ [d.target.files[0].name]: d.target.files[0] });
    } else if (t === "imagewidth") {
      rowCount[i][t] = widthInPercentage.filter(
        ival => ival.value === d.value
      )[0];
      rowCount[i]["textwidth"] = widthInPercentage.filter(
        ival => ival.value === 12 - d.value
      )[0];
    } else if (t === "order") {
      rowCount[i][t] = d;
    } else if (t == "sort") {
      rowCount[i][t] = d.target.value
      console.log(rowCount[i][t], d.target.value)
    }
    this.setState({ rowCount, files });
  };
  rowContent = (ival, i) => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="row form-group">
                  <div className="col-sm-7" >
                    <h4>Content Section -{i + 1}
                      <i class="fa fa-times-circle"
                        style={{ fontSize: "25px" }}
                        onClick={() => this.addRow(i + 1, true)}
                      ></i>
                    </h4>
                  </div>
                  <div className="col-sm-1">
                    <label htmlFor="sort" style={{ float: "right" }}>Sort :</label>
                  </div>
                  <div className="col-sm-4" >
                    <input type="number"
                      className="form-control"
                      name="sort"
                      id="sort"
                      placeholder={`Sort ${i + 1}`}
                      style={{ float: "left" }}
                      onChange={(e) => this.commonFun(e, i, "sort")}
                      value={this.state.rowCount[i].sort}
                    />

                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="exampleInputEmail1">{`Text-${i + 1}`}</label>
                  </div>
                  <div className="col-sm-5">
                    <Editor
                      editorState={this.state.rowCount[i].text}
                      wrapperClassName="wrapper-className"
                      editorClassName="editor-className editor-border"
                      toolbarClassName="toolbar-className"
                      onEditorStateChange={d => this.commonFun(d, i, "text")}
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "link"
                        ]
                      }}
                      value={this.state.rowCount[i].text}
                    />
                  </div>
                  <div className="col-sm-3">
                    <label htmlFor="exampleInputEmail1">{`Text width-${i + 1}`}</label>
                    <SingleSelect
                      options={this.state.widthInPercentage}
                      handleChange={d => this.commonFun(d, i, "textwidth")}
                      selectedService={this.state.rowCount[i].textwidth}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="exampleInputEmail1">{`Image-${i + 1}`}</label>
                  </div>
                  <div className="col-sm-5">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFile"
                      onChange={d => this.commonFun(d, i, "image")}
                      accept="image/*"
                    />
                    <label className="custom-file-label" htmlFor="customFileThumbnail">
                      {this.state.rowCount[i].image && this.state.rowCount[i].image
                        ? this.state.rowCount[i].image
                        : "select file to upload"}
                    </label>

                    {/* <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1" style={{marginLeft:'-179px',marginTop:'26px'}}>{`video-${i + 1}`}</label>

            </div> */}

                    <span>
                      {/* <LoginModal
                buttonTitle="Select Image from Library"
                title="Select Image"
                id="customer1"
                bodyText={<FromLibrary datas={this.state.getlibrarydataImage} columnHeading={this.contentVideoHeading} imgsource={this.state.viewmedia} type={this.state.videotype} />}
                atagLink={true}
              /> */}
                    </span>
                  </div>

                  <div className="col-sm-3">
                    <label htmlFor="exampleInputEmail1">{`Image width-${i + 1}`}</label>
                    <SingleSelect
                      options={this.state.widthInPercentage}
                      handleChange={d => this.commonFun(d, i, "imagewidth")}
                      selectedService={this.state.rowCount[i].imagewidth}
                    />
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="exampleInputEmail1">{`Order-${i + 1}`}</label>
                  </div>
                  <div className="col-sm-5">
                    <SingleSelect
                      options={this.state.Order}
                      handleChange={d => this.commonFun(d, i, "order")}
                      selectedService={this.state.rowCount[i].order}
                    />
                  </div>
                  <div className="col-sm-3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  theme4Submit = async () => {
    let state = this.state;
    // if (!state.authorId) {
    //   this.setState({ errorAuthorName: "Select Author" });
    //   return false;
    // } else if (!state.pageName) {
    //   this.setState({ errorAuthorName: null, errorPageName: "Add Page Name" });
    //   return false;
    // } else if (!state.themeId) {
    //   this.setState({ errorPageName: "", errorThemeId: "Select Theme" });
    //   return false;
    // }
    const { checked, autherSelected, pageName, customerId } = this.props.that;
    let finallContent = [];
    state.rowCount.map((ival, i) => {
      let finallInnerContent = {};
      finallInnerContent.imagewidth = ival.imagewidth.value;
      finallInnerContent.textwidth = ival.textwidth.value;
      finallInnerContent.order = ival.order.value;
      finallInnerContent.image = ival.image;
      finallInnerContent.sort = ival.sort;
      finallInnerContent.text = draftToHtml(
        convertToRaw(ival.text.getCurrentContent())
      );
      finallContent.push(finallInnerContent);
    });
    let today = new Date();
    let dateformat = today.getDate();
    if (today.getDate() < 10) {
      dateformat = "0" + today.getDate();
    }
    let date =
      today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + dateformat;
    if (!autherSelected) {
      this.props.these("errorAuthorName", "Select Author");
      return false;
    } else if (!pageName) {
      this.props.these("errorAuthorName", "");
      this.props.these("errorPageName", "Enter Page name");
      return false;
    }

    const formData = new FormData();
    formData.append("name", pageName.trim());
    formData.append("themeId", 4);
    if (this.props.that.isEdit == true) {
      console.log("ed");
      formData.append("authorid", autherSelected[0].value);
    } else {
      formData.append("authorid", autherSelected.value);
    }
    formData.append("sheetContent", JSON.stringify(finallContent));
    formData.append("Date", date);
    formData.append("status", "show");
    formData.append("searchTags", this.state.searchTags);
    formData.append("customerId", customerId);
    formData.append("show", checked ? 1 : 0);
    state.files.map(ival => {
      let a = Object.keys(ival)[0];
      formData.append([a], ival[a]);
    });
    try {
      if (state.isEdit) {
        formData.append("id", state.EditId);
        console.log([...formData]);
        let { data } = await http.put(
          ACCESS_POINT + `/cmsContent/tbl_pages`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => {
              this.setState({
                uploadPercentage: parseInt(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                )
              });
              setTimeout(() => this.setState({ uploadPercentage: 0 }), 8000);
              ///10000
            }
          }
        );
        if (data && data.length) {
          data = data[0];
          const previousData = [...this.state.data];
          if (this.props.that.isEdit) {
            let index = this.props.that.data.indexOf(this.props.that.data1);
            previousData[index] = data;

          }
          else {
            previousData[state.index] = data;
          }
          setTimeout(
            () =>
              this.setState({
                rowCount: [],
                data: previousData,
                isEdit: false

              }),
            8000
          );
          this.props.these("pageName", "");
          this.props.these("checked", 0);
          this.props.these("errorPageName", "");
          this.props.these('autherSelected', '');
          this.props.these('themeSelected', '')
          this.props.these('isEdit', false)
          this.setState({ searchTags: '' })
          setTimeout(() => this.props.these("alertVisible", true), 8000);
        }
      } else {
        formData.append("requireLogin", "no");
        let { data } = await http.post(
          ACCESS_POINT + `/cmsContent/insertThemeFour/tbl_pages`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => {
              this.setState({
                uploadPercentage: parseInt(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                )
              });
              setTimeout(() => this.setState({ uploadPercentage: 0 }), 8000);
              ///10000
            }
          }
        );
        if (data && data.length) {
          data = data[0];
          const previousData = [data, ...this.state.data];
          setTimeout(
            () =>
              this.setState({
                rowCount: [],
                data: previousData
              }),
            8000
          );
          this.props.these("pageName", "");
          this.props.these("checked", 0);
          this.props.these("errorPageName", "");
          this.props.these('autherSelected', '');
          this.props.these('themeSelected', '')

          this.setState({ searchTags: '' })

          setTimeout(() => this.props.these("alertVisible", true), 8000);
        }
      }
      window.location.reload()
    } catch (error) {
      console.log(error);
    }
  };
  CheckBox = async () => {
    var check = document.getElementById("ShowChecked").checked;
    if (check === true) {
      await this.setState({ checked: 1 });
    } else if (check === false) {
      await this.setState({ checked: 0 });
    }
  };
  handlechange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { uploadPercentage } = this.state;
    return (
      <React.Fragment>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">Search Tags</label>
          </div>
          <div className="col-sm-5">
            <textarea
              type="text"
              className="form-control"
              id="searchTags"
              name="searchTags"
              placeholder="Enter Search Tags"
              value={this.state.searchTags}
              onChange={this.handlechange}
            />
          </div>
          <div className="col-sm-3" />
        </div>
        {this.state.rowCount.map((ival, i) => {
          return this.rowContent(ival, i);
        })}
        <div className="row form-group">
          <div className="col-sm-4" />
          <div className="col-sm-5">
            <button
              type="button"
              className="btn btn-primary"
              disabled={this.state.btnDisable}
              onClick={this.addRow}
            >
              Add Content Row
            </button>
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-7">
            {uploadPercentage > 0 && <Progress percentage={uploadPercentage} />}
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2" />
          <div className="col-sm-5">
            <button
              type="button"
              className={`btn btn-${this.state.isEdit ? "secondary" : "primary"
                }`}
              disabled={this.state.btnDisable}
              onClick={this.theme4Submit}
            >
              {this.state.isEdit ? "Update" : "Add"} Page
            </button>
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-12">
            {this.state.data ? (
              <Datatable data={this.state.data} columnHeading={this.column} />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Theme4;
