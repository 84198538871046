import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../MiddleWare/CmsContent";
import Datatable from "../../components/Datatable/Datatable";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import CMSaddcatsubcatpdf from "../../components/PDF/Cmsaddcatsubcat";
import SingleSelect from "../../components/Form/SingleSelect";
import { FormMiddleWare, MultiSelect } from "../../components/Form";
import { range } from "lodash";

export default class MapChartoUser extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            alertVisible: false,
            editvalue: "",
            editid: null,
            disableValue: false,
            addNewSubCategory: false,
            error: null,
            button: "Submit",
            index: null,
            CHAROPTIONS: [],
            Characteristics: null,
            formAlertdelete: false,
            ques: [{ value: "" }],
            type: null,
            RangeOPTIONS: [],
            PosorNeg: [
                { value: "Positive", label: "Positive" },
                { value: "Negative", label: "Negative" },
            ],
            customerId: localStorage.getItem("userId"),
        };
    }
    async componentDidMount() {
        this.Mainfun()
    }
    async Mainfun() {
        try {
            // const result = await cmsContent.getFreedom("tbl_category_master");
            const result2 = await cmsContent.getFreedom(
                "id as value, name as label",
                "tbl_EOI_Characteristics_master",
                `customerId='${this.state.customerId}'`,
                "id",
                "id DESC"
            );

            if (result2) {
                //console.log(result);
                this.setState({ CHAROPTIONS: result2.data });
            }
            const result23 = await cmsContent.getFreedom(
                "id as value, name as label",
                "tbl_EOI_range_master",
                `customerId='${this.state.customerId}'`,
                "id",
                "id DESC"
            );

            if (result23) {
                //console.log(result);
                this.setState({ RangeOPTIONS: result23.data });
            }


            const result = await cmsContent.getFreedom(
                "tbl_EOI_MapCharRange.*,tbl_EOI_Characteristics_master.name as charname,tbl_EOI_range_master.name as rangename",
                "tbl_EOI_MapCharRange,tbl_EOI_Characteristics_master,tbl_EOI_range_master",
                `tbl_EOI_MapCharRange.customerId=${this.state.customerId} and 
                tbl_EOI_Characteristics_master.id=tbl_EOI_MapCharRange.charid and tbl_EOI_range_master.id=tbl_EOI_MapCharRange.rangeid`,
                "id",
                "id DESC"
            );

            if (result) {
                //console.log(result);
                this.setState({ data: result.data });
            }

        } catch (error) {
            console.log(error);
        }
    }
    column = [

        {
            Header: "Range Name",
            accessor: "rangename",
        },
        {
            Header: "Characteristics Name",
            accessor: "charname",
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: (d) => this.edit(d),
        },
        {
            Header: "Delete",
            accessor: "delete",
            Cell: (d) => this.delete(d),
        },
    ]
    edit = (d) => {
        let value = d;
        return (
            <button
                type="button"
                className="btn btn-info"
                onClick={() => this.edition(value)}
            >
                Edit
            </button>
        );
    };
    edition = async (value) => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        let values = value.original;
        let char = { value: values.charid, label: values.charname }
        let range = { value: values.rangeid, label: value.rangename }


        let ques = this.state.ques;
        ques[0].value = values.question
        await this.setState({
            index: value.index,
            editvalue: values.name,
            editid: values.id,
            Characteristicsselect: char,
            range,
            ques,
            button: "Update",
        });
    };
    delete = (d) => {

        return (
            <button
                type="button"
                className="btn btn-danger"
                onClick={() => this.deletion(d)}
            >
                Delete
            </button>
        );
    };
    deletion = async (value) => {
        //Getting Previous state data

        const previousData = [...this.state.data];
        // Seperating data row using row-index
        const getData = { ...previousData[value.index] };

        //getting id on that data
        const id = getData.id;
        //removing specific id in previous state data
        const data = previousData.filter((delelteid) => delelteid.id !== id);

        try {
            const result = await cmsContent.deleteMaster(
                "tbl_EOI_MapCharRange",
                id
            );
            if (result) {
                this.setState({ editvalue: "", formAlertdelete: true, data });

                setTimeout(() => this.setState({ formAlertdelete: false }), 3000);
            }
        } catch (error) {
            this.setState({ data: previousData });
            console.log(error);
        }
    };
    handlechange = (e) => {
        let value = e.target.value;
        this.setState({ editvalue: value });
    };
    addnew = async () => {
        let value = this.state.editvalue;
        let { ques, Characteristicsselect, type, range } = this.state;
        let customerId = this.state.customerId;
        if (Characteristicsselect == null || range == null) {
            alert("Enter All Fields")
            return false;
        }
        let categoryArray = {};
        categoryArray.charid = JSON.stringify(Characteristicsselect)
        categoryArray.rangeid = range.value
        categoryArray.customerId = customerId;

        try {
            this.setState({ disableValue: true });
            // const result = await cmsContent.addMaster(
            //     "tbl_EOI_MapCharRange",
            //     categoryArray
            // );
            const result = await cmsContent.maprangeEOI("tbl_EOI_questions_master/id", categoryArray)
            if (result) {
                // let valueArray = {};
                // valueArray.value = result.data.insertId;
                // valueArray.name = categoryArray.name;
                // console.log(valueArray);
                // const newData = [valueArray, ...this.state.data];
                this.setState({
                    // data: newData,
                    alertVisible: true,
                    editvalue: "",
                    Characteristicsselect: null,
                    type: null,
                    editid: null,
                    range: null,
                    ques: [{ value: "" }],
                    disableValue: false,
                    addNewSubCategory: false,
                    error: null,
                    button: "Submit",
                    index: null,
                });

                setTimeout(() => {
                    this.setState({

                        alertVisible: false,

                    });
                }, 2000);

                this.Mainfun()
            }
        } catch (error) {
            console.log(error);
        }
    };
    update = async () => {

        this.setState({ disableValue: true });
        try {
            let value = this.state.editvalue;
            let { ques, Characteristicsselect, type, range } = this.state;
            let customerId = this.state.customerId;
            if (Characteristicsselect == null || range == null) {
                alert("Enter All Fields")
                return false;
            }


            let id = this.state.editid;
            let categoryArray = {};
            categoryArray.charid = JSON.stringify(Characteristicsselect)
            categoryArray.rangeid = range.value
            categoryArray.customerId = customerId;
            // const result = await cmsContent.updateMaster(
            //     "tbl_EOI_MapCharRange",
            //     id,
            //     categoryArray
            // );
            const result = await cmsContent.maprangeEOI(`tbl_EOI_questions_master/${id}`, categoryArray)
            if (result) {
                // previousdata[index].name = value;

                this.setState({
                    // data: previousdata,
                    alertVisible: true,
                    editvalue: "",
                    Characteristicsselect: null,
                    type: null,
                    range: null,
                    editid: null,
                    ques: [{ value: "" }],
                    addNewSubCategory: false,
                    error: null,
                    button: "Submit",
                    index: null, disableValue: false,
                });
                setTimeout(() => {
                    this.setState({ alertVisible: false })
                }, 2000);
                this.Mainfun()
            }
        } catch (error) {
            console.log(error);
        }


    };
    onDismiss = () => {
        this.setState({ alertVisible: false });
        this.setState({ formAlertdelete: false });
    };
    Characteristicsselect = (v, ch) => {
        if (ch == "range"
        ) {
            let data = this.state.data
            let Characteristicsselect = []
            data.map((ival) => {
                console.log(ival, v.value);
                if (v.value == ival.rangeid) {
                    Characteristicsselect.push({
                        label: ival.charname,
                        value: ival.charid
                    })
                }
            })
            console.log(Characteristicsselect);
            Characteristicsselect = this.removeDuplicates(Characteristicsselect, "value")
            if (Characteristicsselect.length > 0) {
                this.setState({ button: "Update" })
            } else {
                this.setState({ button: "Submit" })
            }
            this.setState({ Characteristicsselect })
        }
        this.setState({ [ch]: v })
    }
    addmore = async () => {
        let { ques } = this.state;
        ques.push({ value: "" })
        // if(ques.length==5)
        this.setState({ ques })
    }
    handleans = async (e, i) => {
        let { ques } = this.state;
        ques[i].value = e.target.value
        this.setState({ ques })
    }

    render() {
        const {
            editvalue,
            alertVisible,
            disableValue,
            error,
            button,
            formAlertdelete,
            CHAROPTIONS,
            Characteristicsselect,
            ques,
            PosorNeg,
            type,
            range,
            RangeOPTIONS,
        } = this.state;
        console.log(Characteristicsselect);
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Map Characteristics TO Range</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color="success"
                                                    isOpen={alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    Map Characteristics TO Range Added/Updated
                        </Alert>

                                                <Alert
                                                    className="badge-content"
                                                    color="danger"
                                                    isOpen={formAlertdelete}
                                                    toggle={this.onDismiss}
                                                >
                                                    Delete
                        </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label for="exampleInputEmail1">Select Range</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    options={RangeOPTIONS}
                                                    // disabled={disableReportTo}
                                                    handleChange={(v) => this.Characteristicsselect(v, "range")}
                                                    selectedService={range}
                                                    placeholder={"Select..."}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label for="exampleInputEmail1">Select Characteristics</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <MultiSelect
                                                    options={CHAROPTIONS}
                                                    // disabled={disableReportTo}
                                                    handleChange={(v) => this.Characteristicsselect(v, "Characteristicsselect")}
                                                    selectedService={Characteristicsselect}
                                                    placeholder={"Select..."}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2" />
                                            <div className="col-sm-5">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    disabled={disableValue}
                                                    onClick={
                                                        button === "Update" ? this.update : this.addnew
                                                    }
                                                >
                                                    {this.state.button}
                                                </button>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <br></br>


                                        {this.state.data && (
                                            <Datatable
                                                data={this.state.data}
                                                columnHeading={this.column}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

// export default QuestionMaster;
