import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import cmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import Addauthormodel from "./Addauthormodel";
import { Alert } from "reactstrap";
import { ACCESS_SAMPLE } from "../../../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import AddpagePDF from "../../../components/PDF/Addpagespdf";

//import Theme9 from './addPages/theme9';
import Datatable from "../../../components/Datatable/Datatable";


class AddBusiDesignation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            authorlist: [],
            checked: 0,
            customerId: localStorage.getItem("userId"),
            errorBusiDesignation: "",
            errorPageName: null,
            data: [],
            alertVisible1: false,
            btnDisable: false,
            formAlert: false,
            formBtn: false,
            themeSelected: "",
            screen: window.screen.height,
            danger: "btn btn-danger",
            ansBusiDesignation: "",
            success: "btn btn-success",
            home: "Home",
            set: "SetusHome",
        };
    }
    column = [
        {
            Header: "ID",
            accessor: "id",
        },
        {
            Header: "BUSINESS DESIGNATION",
            accessor: "BusinessDesignation",
        },
        {
            Header: "EDIT",
            accessor: "edit",
            Cell: (d) => this.Editpages(d),
        },


    ];
    // showVisibility = (id) => {
    //     return (
    //         <button
    //             type="button"
    //             className="btn btn-info"
    //             onClick={() => this.updateVisibility(id)}
    //         >
    //             Hide/Show
    //         </button>
    //     );
    // };

    changeLoginStatus = (id) => {
        return (
            <button
                type="button"
                class="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '10px' }}
                onClick={() => this.updateLogin(id)}
            >
                Change Login Status
            </button>
        );
    };

    Editpages = (value) => {
        return (
            <button className="btn btn-info" onClick={() => this.onEdit(value)}>
                Edit
            </button>
        );
    };
    onEdit = (value) => {
        let d = value.original;
        console.log(d);
        this.setState({
            ansBusiDesignation: d.BusinessDesignation,
            editId: d.id,
            isEdit: true
        });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    async componentDidMount() {
        this.Mainfun()
    }
    Mainfun = async () => {
        try {
            this.setState({ loading: true })
            const BusiDesignation = await cmsContent.getFreedom(
                "*",
                "cmndl_tbl_BusiDesignation",
                1,
                "id",
                "id DESC"
            );
            if (BusiDesignation) {
                // console.log(ACCESS_POINT.ACCESS_SAMPLE);
                await this.setState({ data: BusiDesignation.data, loading: false });
            }

        } catch (error) {
            console.log(error);
            this.setState({ loading: false })
        }
    }
    handlechange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    submitCategory = async () => {
        const {
            ansBusiDesignation,
            isEdit, editId
        } = this.state;
        if (!ansBusiDesignation) {
            this.setState({ errorBusiDesignation: "enter Business Designation" });
            return false;
        }

        let categoryArray = {}
        if (ansBusiDesignation) {
            categoryArray.BusinessDesignation = ansBusiDesignation;
        }
        categoryArray.customerid = this.state.customerId;
        // console.log([...formData]);

        try {
            const result = isEdit ? await cmsContent.updateMaster(
                "cmndl_tbl_BusiDesignation",
                editId,
                categoryArray
            ) : await cmsContent.addMaster(
                "cmndl_tbl_BusiDesignation",
                categoryArray
            );
            if (result) {
                this.setState({
                    ansBusiDesignation: "",
                    errorBusiDesignation: '',
                    isEdit: false,
                    btnDisable: false,
                    alertVisible: true,

                });
                this.Mainfun()
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };
    async componentWillReceiveProps() {
        this.setState({ themeId: 0 });

    }

    render() {
        console.log(this.state.data);

        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Add Business Designation</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color="success"
                                                    isOpen={this.state.alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    Added Successfully
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>


                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Business Designation</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="pageName"
                                                    name="ansBusiDesignation"
                                                    placeholder="Enter Business Designation"
                                                    value={this.state.ansBusiDesignation}
                                                    onChange={this.handlechange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.errorBusiDesignation}
                                            </span>
                                        </div>
                                        <br />

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2" />
                                            <div className="col-sm-5">
                                                <button
                                                    type="button"
                                                    className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
                                                    disabled={this.state.btnDisable}
                                                    onClick={this.submitCategory}
                                                >
                                                    {this.state.isEdit ? 'Update' : 'Submit'}
                                                </button>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <br />

                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                {this.state.loading ? <div className="text-center"> <i
                                                    className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                                ></i></div> : this.state.data && this.state.data.length ? (
                                                    <Datatable
                                                        data={this.state.data}
                                                        columnHeading={this.column}
                                                    />
                                                ) : <div className="text-center">No Data Found</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default AddBusiDesignation;
