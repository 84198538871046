import React, { Component } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import CmsContent from "../../../MiddleWare/CmsContent";
import { ACCESS_POINT } from "../../../config";


class Videoplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      customerId: localStorage.getItem("userId"),
      videosrc:''
    };
  }

  async componentWillMount() {

  const {
    match: { params }
  } = this.props;
  let video = params['video'];
  console.log(video)
  this.setState({videosrc :ACCESS_POINT+'/superAdmin/file?fileurl=SDAS/Video/'+video})
  }
  async componentDidMount() {
    const { data } = await CmsContent.videoList(this.state.customerId);
    if (data) {
      this.setState({
        data
      });
    }
  }
 
  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Video Listing</h1>
                  </div>
                  <div className="card-body">
                    {/* {this.state.data && (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    )} */}

<video width="400" controls>
  <source src={this.state.videosrc} type="video/mp4"/>
   Your browser does not support HTML video.
</video>

                    {/* <video width="400" controls>
  <source src="http://localhost:5000/superAdmin/file?fileurl=SDAS/Video/new_1588251415783.mp4" />
   Your browser does not support HTML video.
</video> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Videoplay;
