import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import SingleSelect from "../../../components/Form/SingleSelect";
import LineChart from "../../../components/Chart/LineChart";
import BarChart from '../../../components/Chart/BarChart';
import HorizontalBarChart1 from '../../../components/Chart/HorizontalBarChart1';
import ScatterChart from '../../../components/Chart/ScatterChart';
import socketIOClient from 'socket.io-client';
import DatePicker from '../../../components/Form/DatePicker';
import moment from 'moment';
import ProductTable from "../../../components/cDatatable/Datatable";
class Rtreport extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      resultData: [],
      portletList: [],
      watchingUsers: [],
      dataTableData: [],
      startDate: new Date(),
      endDate: new Date(),
      ViewedList: [],
      portletcount: [],
      usercount: [],
      videoViewers: 0,
      from: 0,
      to: 10,
      f1: 0,
      t1: 10,
      d1: [],
      customerId: localStorage.getItem('userId'),
      first: [
        { label: 'Portlet List', value: 'Portlet List' },
        { label: 'Video portlets', value: 'Video portlets' }
      ],
      firstSelectList: [],
      secondSelectList: [],
      firstSelect: {},
      selectsecondSelectList: {},
      selectsecondSelect: {},
      socketTable: [],
      DBfirstSelectList: [],
      DBsecondSelectList: [],
    };
  }
  data = []
  column = [
    {
      Header: "User Name",
      accessor: "username"
    },
    {
      Header: "Video Name",
      accessor: "pgname"
    },
    {
      Header: "Duration in sec",
      accessor: "duration"
    }
  ];
  column1 = [
    {
      Header: "User Name",
      accessor: "userName"
    },
    {
      Header: "Start Time",
      accessor: "starttime",
      Cell: d => this.toDate(d.original.starttime)
    },
    {
      Header: "End Time",
      accessor: "endtime",
      Cell: d => this.toDate(d.original.endtime)
    },
    {
      Header: "Duration in sec",
      accessor: "duration"
    }
  ];
  headers = [
    {
      key: 'sno',
      as: "S.NO",
      style: "RTdatatable"
    }, {
      key: 'userName',
      as: "User Name",
      style: "RTdatatable"
    }, {
      key: 'ip',
      as: "IP address",
      style: "RTdatatable"
    }, {
      key: 'starttime',
      as: "Start Time",
      style: "RTdatatable",
      Makeup: d => this.toDate(d)
    }, {
      key: 'endtime',
      as: "End Time",
      style: "RTdatatable",
      Makeup: d => this.toDate(d)
    }, {
      key: 'duration',
      as: "Duration in sec",
      style: "RTdatatable"
    }
  ]
  headers1 = [
    {
      key: 'username',
      as: "User Name",
      style: "RTdatatable"
    }, {
      key: 'ip',
      as: "IP address",
      style: "RTdatatable"
    }
  ]
  toDate = d => {
    var dateUTC = new Date(Number(d));
    const v = moment(dateUTC).format("DD-MM-YYYY h:mm:ss")
    return v;
  };
  async componentDidMount() {
    try {
      const socket = socketIOClient('https://exp.difuza.com');
      socket.on(`responseTimestamp${this.state.customerId}`, data => {
        console.log(data)
        let stateData = this.data;
        stateData.push(data);
        this.data = stateData;
      });
      const { data: portletList } = await CmsContent.getFreedom(
        'id as value,boxtitle as label,contentid,boxtypeid',
        "tbl_map_grouptoportlet",
        `status='active' and customerid='${this.state.customerId}'`,
        'id',
        'id'
      );
      if (portletList) {
        this.setState({ portletList });
      }
      const { data: portletcount } = await CmsContent.getFreedom(
        'tbl_boxcontent.*',
        "tbl_map_grouptoportlet,tbl_boxcontent",
        `tbl_boxcontent.name=tbl_map_grouptoportlet.contentid and tbl_map_grouptoportlet.status='active' and tbl_boxcontent.status='active' and tbl_map_grouptoportlet.customerid='${this.state.customerId}'`,
        'tbl_boxcontent.id',
        'tbl_boxcontent.id'
      );
      if (portletcount) {
        this.setState({ portletcount });
      }
      const { data: usercount } = await CmsContent.getFreedom(
        'customerId as cid',
        "tbl_user_web",
        `serviceId='9' and customerId='${this.state.customerId}'`,
        'id',
        'id'
      );
      if (usercount) {
        this.setState({ usercount });
      }
      const { data: videoViewers } = await CmsContent.getFreedom(
        'tbl_userlog.portid,tbl_userlog.userid',
        "tbl_boxcontent,tbl_userlog",
        `tbl_boxcontent.id=tbl_userlog.portid and tbl_boxcontent.type='2'`,
        'tbl_userlog.id',
        'tbl_userlog.portid'
      );
      if (videoViewers) {
        let count = [];
        videoViewers.map((ival, i) => {
          if (count.filter(jval => jval.portid === ival.portid).length === 0) {
            count.push({ portid: ival.portid, count: 0 });
          }
        });
        let TotalViews = 0;
        count.map((ival, i) => {
          let Views = [];
          videoViewers.map((jval, j) => {
            if (jval.portid === ival.portid) {
              if (Views.filter(kval => kval === jval.userid).length === 0) {
                count[i].count = count[i].count + 1;
                Views.push(jval.userid);
                TotalViews = TotalViews + 1;
              }
            }
          })
        });
        this.setState({ videoViewers: TotalViews });
      }
      setInterval(() => {
        let data = []
        let watchingUsers = [];
        if (this.data.length) {
          this.data.map((ival, i) => {
            if (watchingUsers.filter(jval => ival.userid === jval).length === 0) {
              watchingUsers.push(ival.userid);
            }
            let pushData = true;
            data.map((jval, j) => {
              if ((ival.userid === jval.userid) && (jval.portid === ival.portid)) {
                pushData = false;
                data[j].duration = Math.floor(ival.duration);
              }
            })
            if (pushData) {
              data.push(ival);
            }
          })
        }
        this.setState({ resultData: data, watchingUsers });
        this.data = [];
        this.videoSelect()
        this.getLiveUpdates();
      }, 3000)
    } catch (error) {
      console.log(error);
    }
  }
  groupBy = (objectArray, property) => {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  }
  firstSelect = d => {
    if (d.value === 'Portlet List') {
      let ar = [1, 5, 6];
      let firstSelectList = this.state.portletList.filter(ival => (ar.indexOf(ival.boxtypeid) !== -1));
      this.setState({ firstSelect: d, firstSelectList })
    } else if (d.value === 'Video portlets') {
      let firstSelectList = this.state.portletList.filter(ival => ival.boxtypeid === 2);
      this.setState({ firstSelect: d, firstSelectList, selectsecondSelectList: {} })
    }
  }
  selectFirstSelectList = async d => {
    try {
      const { data: secondSelectList } = await CmsContent.getFreedom(
        'tbl_boxcontent.heading as label,tbl_boxcontent.id as value',
        "tbl_boxcontent",
        `tbl_boxcontent.name='${d.contentid}' and tbl_boxcontent.status='active' `,
        'tbl_boxcontent.id',
        'tbl_boxcontent.id'
      );
      if (secondSelectList) {
        this.setState({ selectsecondSelectList: d, secondSelectList, selectsecondSelect: {} });
      }
    } catch (e) {
      console.log(e)
    }
  }
  selectsecondSelect = async d => {
    this.setState({ selectsecondSelect: d });
  }
  getLiveUpdates = async () => {
    const { firstSelect, selectsecondSelectList, selectsecondSelect } = this.state;
    if (firstSelect.value && selectsecondSelectList.value && selectsecondSelect.value) {
      let data = this.state.resultData.filter(ival => ival.pg === '3' && ival.portid === selectsecondSelect.value.toString());
      this.setState({ socketTable: data });
    } else if (firstSelect.value && selectsecondSelectList.value) {
      let data = this.state.resultData.filter(ival => ival.pg === '2' && ival.portid === selectsecondSelectList.value.toString());
      this.setState({ socketTable: data });
      console.log(this.state.socketTable)
    }
  }
  videoSelect = () => {
    const e = this.state.selectsecondSelect;
    if (this.state.firstSelect.value === 'Video portlets' && e.value) {
      const data = this.state.resultData;
      const dataTableData = [];
      data.map((ival, i) => {
        if ((Number(ival.portid) === e.value) && (ival.pg === '3') && (this.state.from <= i) && (this.state.to > i)) {
          dataTableData.push(ival);
        }
      });
      this.setState({ videoSelect: e, dataTableData});
    }
  }
  content = (data, option, type) => {
    if (type === "line") {
      return <LineChart dataValue={data} optionsValue={option} />;
    } else if (type === "bar") {
      return <BarChart dataValue={data} barOptions={option} />;
    } else if (type === "horizontalbar") {
      return <HorizontalBarChart1 dataValue={data} barOptions={option} />;
    } else if (type === "scatter") {
      return <ScatterChart dataValue={data} />;
    }
  }
  portletSelectRange = d => {
    if (d.value === 'Portlet List') {
      let ar = [1, 5, 6];
      let DBfirstSelectList = this.state.portletList.filter(ival => (ar.indexOf(ival.boxtypeid) !== -1));
      this.setState({ portletSelectRange: d, DBfirstSelectList, DBselectsecondSelectList: {} })
    } else if (d.value === 'Video portlets') {
      let DBfirstSelectList = this.state.portletList.filter(ival => ival.boxtypeid === 2);
      this.setState({ portletSelectRange: d, DBfirstSelectList, DBselectsecondSelectList: {} })
    }
  }
  selectDBfirstList = async d => {
    try {
      const { data: DBsecondSelectList } = await CmsContent.getFreedom(
        'tbl_boxcontent.heading as label,tbl_boxcontent.id as value',
        "tbl_boxcontent",
        `tbl_boxcontent.name='${d.contentid}' and tbl_boxcontent.status='active' `,
        'tbl_boxcontent.id',
        'tbl_boxcontent.id'
      );
      if (DBsecondSelectList) {
        this.setState({ selectDBfirstList: d, DBsecondSelectList, DBselectsecondSelect: {} });
      }
    } catch (e) {
      console.log(e)
    }
  }
  DBselectsecond = async d => {
    this.setState({ DBselectsecond: d });
  }
  startDate = date => {
    let startDateTime = moment(date).toDate().getTime();
    this.setState({
      startDate: date,
      startDateTime
    });
  };
  endDate = date => {
    let endDateTime = moment(date).toDate().getTime();
    this.setState({
      endDate: date,
      endDateTime
    });
  };
  submitLimit = async () => {
    const { startDateTime, endDateTime, DBselectsecond,selectDBfirstList } = this.state;
    try {
    if(DBselectsecond && DBselectsecond.value){
      let { data: ViewedList } = await CmsContent.getFreedom(
        'tbl_user_web.userName,tbl_userlog.duration,tbl_userlog.starttime,tbl_userlog.endtime,tbl_userlog.ip',
        "tbl_userlog,tbl_user_web",
        `tbl_userlog.userid=tbl_user_web.id and tbl_userlog.portid='${DBselectsecond.value}' and tbl_userlog.pg='3'  and tbl_userlog.starttime between '${startDateTime}' and '${endDateTime}'`,
        'tbl_userlog.id',
        'tbl_userlog.id'
      );
      if (ViewedList) {
        this.setState({ ViewedList });
      }
    } else if(selectDBfirstList && selectDBfirstList.value){
      let { data: ViewedList } = await CmsContent.getFreedom(
        'tbl_user_web.userName,tbl_userlog.duration,tbl_userlog.starttime,tbl_userlog.endtime,tbl_userlog.ip',
        "tbl_userlog,tbl_user_web",
        `tbl_userlog.userid=tbl_user_web.id and tbl_userlog.portid='${selectDBfirstList.value}' and tbl_userlog.pg='2'  and tbl_userlog.starttime between '${startDateTime}' and '${endDateTime}'`,
        'tbl_userlog.id',
        'tbl_userlog.id'
      );
      if (ViewedList) {
        this.setState({ ViewedList });
      }
    }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const bgcolor = { backgroundColor: '#252B30', color: "rgba(0,0, 0, 1)" };
    let { ViewedList } = this.state;
    let d1=[];
    ViewedList.map((ival, i) => {
      d1.push({ ...ival, sno: i + 1 });
    })
    
    return (
      <React.Fragment>
        <main className="main my-4" style={bgcolor}>
          <div className="container-fluid">
            <div className="card" style={bgcolor}>
              <div className="card-header" style={bgcolor}>
                <h1>Real Time Logs</h1>
              </div>
              <div className="card-body" style={{ ...bgcolor, minHeight: 500 }}>

                <div className="row form-group">
                  <div className="col-sm-3">
                    <div className="grid-module green">
                      <div className="col-title rtReportColor my-1" >
                        <i className="fa fa-bar-chart"></i> Total portlets
			                </div>
                      <h3 className="text-white h3">{this.state.portletcount.length}</h3>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="grid-module red">
                      <div className="col-title rtReportColor my-1" >
                        <i className="fa fa-bar-chart"></i> Total videos
			                </div>
                      <h3 className="text-white h3">{this.state.portletcount.filter(ival => ival.type === 2).length}</h3>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="grid-module gray">
                      <div className="col-title rtReportColor my-1" >
                        <i className="fa fa-bar-chart"></i> User Count
			                </div>
                      <h3 className="text-white h3">{this.state.usercount.length}</h3>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="grid-module blue">
                      <div className="col-title rtReportColor my-1" >
                        <i className="fa fa-bar-chart"></i> Total video viewers
			                </div>
                      <h3 className="text-white h3">{this.state.videoViewers}</h3>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="grid-module red">
                      <div className="col-title rtReportColor my-1" >
                        <i className="fa fa-bar-chart"></i>Logged In Users
			                </div>
                      <h3 className="text-white h3">{Object.keys(this.groupBy(this.state.resultData, 'userid')).length}</h3>
                    </div>
                  </div>
                </div>

                {/* <div className="row form-group">
                <div className="col-sm-2" />
                  <div className="col-sm-8">
                    <HorizontalBarChart1 dataValue={[{ duration: Math.floor(Math.random()*10), username: 'ganapathy' },{ duration: Math.floor(Math.random()*10), username: 'selvam' },{ duration: Math.floor(Math.random()*10), username: 'ram' }, { duration: Math.floor(Math.random()*10), username: 'prasad' },{ duration: Math.floor(Math.random()*10), username: 'ganapathy' },{ duration: Math.floor(Math.random()*10), username: 'selvam' },{ duration: Math.floor(Math.random()*10), username: 'ram' }, { duration: Math.floor(Math.random()*10), username: 'prasad' },{ duration: Math.floor(Math.random()*10), username: 'nith' },{ duration: Math.floor(Math.random()*10), username: 'niramj' },{ duration: Math.floor(Math.random()*10), username: 'hello' }]} barOptions={{ maintainAspectRatio: true }} />
                  </div>
                <div className="col-sm-2" />
                </div> */}
                
                <div className="row form-group">
                  <div className="col-sm-3">
                    <SingleSelect
                      options={this.state.first}
                      handleChange={this.firstSelect}
                      selectedService={this.state.firstSelect}
                    />
                  </div>
                  {(this.state.firstSelectList.length) ? (
                    <div className="col-sm-3">
                      <SingleSelect
                        options={this.state.firstSelectList}
                        handleChange={this.selectFirstSelectList}
                        selectedService={this.state.selectFirstSelectList}
                      />
                    </div>
                  ) : null}
                  {(this.state.secondSelectList.length) ? (
                    <div className="col-sm-3">
                      <SingleSelect
                        options={this.state.secondSelectList}
                        handleChange={this.selectsecondSelect}
                        selectedService={this.state.selectsecondSelect}
                      />
                    </div>
                  ) : null}
                  <div className="col-sm-3">
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={this.getLiveUpdates}
                    >
                      Submit
                    </button>
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-8">
                    {this.state.firstSelect.value === 'Video portlets' ? (
                      <div>{this.content(this.state.socketTable, { maintainAspectRatio: false }, 'horizontalbar')}</div>
                    ) : null}
                  </div>
                  <div className="col-sm-2" />
                </div>

                <div className="row form-group mt-2">
                  <div className="col-sm-2" />
                  <div className="col-sm-8">
                    <div className="form-group" style={{ color: "white", width: "-webkit-fill-available" }}>
                      {(this.state.socketTable.length) ? (<ProductTable
                        headers={this.headers1}
                        products={this.state.socketTable}
                      />) : null}
                    </div>
                  </div>
                  <div className="col-sm-2" />
                </div>

                <div className="row form-group" style={{ color: "black" }}>
                  <div className="col-sm-2">
                    {(this.state.first.length) ? (
                      <SingleSelect
                        options={this.state.first}
                        handleChange={this.portletSelectRange}
                        selectedService={this.state.portletSelectRange}
                      />
                    ) : null}
                  </div>
                  <div className="col-sm-2">
                    {(this.state.DBfirstSelectList.length) ? (
                      <SingleSelect
                        options={this.state.DBfirstSelectList}
                        handleChange={this.selectDBfirstList}
                        selectedService={this.state.selectDBfirstList}
                      />
                    ) : null}
                  </div>

                  <div className="col-sm-2">
                    {(this.state.DBsecondSelectList.length) ? (
                      <SingleSelect
                        options={this.state.DBsecondSelectList}
                        handleChange={this.DBselectsecond}
                        selectedService={this.state.DBselectsecond}
                      />
                    ) : null}
                  </div>
                  <div className="col-sm-5 row" >
                    <div className="col-sm-3" >
                      <label style={{ color: "white" }}>
                        Start Date:
                       </label>
                    </div>
                    <div className="col-sm-9" >
                      <DatePicker startDate={this.state.startDate} changeDate={this.startDate} />
                    </div>
                    <div className="col-sm-3" >
                      <label style={{ color: "white" }}>
                        End Date
                      </label>
                    </div>
                    <div className="col-sm-9" >
                      <DatePicker startDate={this.state.endDate} changeDate={this.endDate} />
                    </div>
                  </div>

                  <div className="col-sm-1">
                    <button
                      type="button"
                      style={{ marginLeft: -100 }}
                      className="btn btn-primary"
                      onClick={this.submitLimit}
                    >
                      Submit
                    </button>
                  </div>
                </div>


                <div className="form-group" style={{ color: "white", width: "-webkit-fill-available" }}>
                  <ProductTable
                    headers={this.headers}
                    products={d1}
                  />
                </div>

              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Rtreport;
