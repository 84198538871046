
import React, { Component } from "react";
import { MultiSelect, SingleSelect } from "../../../components/Form";
import cmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import { Alert } from "reactstrap";
import Datatable from "../../../components/Datatable/Datatable";
import { FormMiddleWare } from "../../../components/Form";
import { unique } from "@tensorflow/tfjs";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
// import User from "../../../MiddleWare/User";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from "moment";
import { result } from "lodash";


class PyschologyForm extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            isEdit: false,
            customerId: localStorage.getItem("userId"),
            LoginuserId: JSON.parse(localStorage.getItem("userlog")).id,
            data: [],
            editId: null,
            userid: null,
            formAlertdelete: false,
            textalert: "",
            genderopt: [{ label: "MALE", value: 1 }, { label: "FEMALE", value: 2 }],
            danger: "btn btn-danger",
            success: "btn btn-success",
            pageNumber: 1,
            SelectedEnquiries: [],
            bulkAllocate: false,
            addCounsellingColumn: [
                { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                { label: "Student ID", value: 2, type: "text", Name: "StudentId" },
                { label: "NAME", value: 3, type: "text", Name: "Name" },
                { label: "CLASS", value: 4, type: "selectbox", Name: "Class" },
                { label: "DATE OF BIRTH ", value: 5, type: "date", Name: "DOB" },
                { label: "Student Rank", value: 6, type: "text", Name: "StudentRank" },
                { label: "NAME OF THE MENTOR", value: 7, type: "selectbox", Name: "Mentor" },
                { label: "GENDER", value: 8, type: "selectbox", Name: "Gender" },
                { label: "DEPARTMENT", value: 9, type: "selectbox", Name: "Department" },
                // { label: "WHETHER COUNSELLING CONDUCTED ALONG WITH PARENT  ?", value: 8, type: "selectbox", Name: "Withparent" },
                { label: "DATE OF COUNSELING ", value: 10, type: "date", Name: "Date" },
                { label: "REASON FOR THE COUNSELING", value: 11, type: "textarea", Name: "Reason" },
                { label: "Hostel Name ", value: 12, type: "textarea", Name: "HostelName" },

            ],
            addTrainingColumn: [
                { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                // { label: "Student ID", value: 2, type: "text", Name: "StudentId" },
                { label: "DEPARTMENT", value: 2, type: "selectboxMulti", Name: "Department" },
                { label: "TOPIC", value: 3, type: "selectboxMulti", Name: "Topic" },
                { label: "DATE OF COUNSELING ", value: 4, type: "date", Name: "Date" },
                { label: "DESCRIPTION", value: 5, type: "textarea", Name: "Discription" },
                { label: "REASON FOR THE COUNSELING", value: 6, type: "textarea", Name: "Reason" },

            ],
            TSColumn: [
                { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                { label: "Name", value: 2, type: "text", Name: "Name" },
                { label: "Gender", value: 3, type: "selectbox", Name: "Gender" },
                { label: "Age", value: 4, type: "number", Name: "Age" },
                { label: "Marital Status", value: 5, type: "selectbox", Name: "MaritalStatus" },
                { label: "Class ", value: 6, type: "selectbox", Name: "Class" },
                { label: "Department", value: 7, type: "selectbox", Name: "Department" },
                { label: "Subject", value: 8, type: "textarea", Name: "Subject" },
                { label: "DESCRIPTION", value: 9, type: "textarea", Name: "Discription" },
                { label: "Date ", value: 10, type: "date", Name: "Date" },
            ],
            TSColumn1: [
                { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                // { label: "Name", value: 2, type: "text", Name: "Name" },
                // { label: "Gender", value: 3, type: "selectbox", Name: "Gender" },
                // { label: "Age", value: 4, type: "number", Name: "Age" },
                // { label: "Marital Status", value: 5, type: "selectbox", Name: "MaritalStatus" },
                { label: "Class ", value: 6, type: "selectbox", Name: "Class" },
                { label: "Department", value: 7, type: "selectboxMulti", Name: "Department" },
                // { label: "Subject", value: 8, type: "textarea", Name: "Subject" },
                { label: "DESCRIPTION", value: 9, type: "textarea", Name: "Discription" },
                { label: "Date ", value: 10, type: "date", Name: "Date" },
            ],

            NTSColumn: [
                { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                { label: "Name", value: 2, type: "text", Name: "Name" },
                { label: "Gender", value: 3, type: "selectbox", Name: "Gender" },
                { label: "Age", value: 4, type: "number", Name: "Age" },
                { label: "Marital Status", value: 5, type: "selectbox", Name: "MaritalStatus" },
                { label: "Hostel Name ", value: 6, type: "textarea", Name: "HostelName" },
                { label: "DESCRIPTION", value: 7, type: "textarea", Name: "Discription" },
                { label: "Date ", value: 8, type: "date", Name: "Date" },
            ],
            NTSColumn1: [
                { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                { label: "Name", value: 2, type: "text", Name: "Name" },
                { label: "Gender", value: 3, type: "selectbox", Name: "Gender" },
                { label: "Age", value: 4, type: "number", Name: "Age" },
                { label: "Marital Status", value: 5, type: "selectbox", Name: "MaritalStatus" },
                { label: "DESCRIPTION", value: 6, type: "textarea", Name: "Discription" },
                { label: "Date ", value: 7, type: "date", Name: "Date" },
            ],
            CompanyFormColumn: [
                { label: "EMPLOYEE ID", value: 2, type: "text", Name: "EmployeeId" },
                { label: "EMPLOYEE NAME", value: 3, type: "text", Name: "Name" },
                { label: "MOBILE NUMBER", value: 4, type: "number", Name: "MobileNumber" },
                { label: "GENDER", value: 5, type: "selectbox", Name: "Gender" },
                { label: "DESIGNATION", value: 6, type: "selectbox", Name: "Designation" },
                { label: "DEPARTMENT", value: 7, type: "selectboxMulti", Name: "Department" },
                { label: "DESCRIPTION", value: 8, type: "textarea", Name: "Description" },
                { label: "DATE OF COUNSELING", value: 9, type: "date", Name: "Date" },
                { label: "REASON FOR COUNSELLING", value: 10, type: "textarea", Name: "Reason" }
            ],

            counsellingArray: [],
            trainingArray: [],
            TSArray: [],
            NTSArray: [],
            CompanyArray: [],

            CounsellingData: [],
            userAddRow: "",
            RowCount: "",
            FormType: 0,
            counsellingOptions: [
                { label: "1 to 1 [counseling form]", value: 1 },
                { label: "Group Counseling/Training Form", value: 2 },
            ],
            usertypeOptions: [
                { label: "Student", value: 1 },
                { label: "Teaching Staff", value: 2 },
                { label: "Non-Teaching Staff ", value: 3 },
            ],
            NTSOptions: [
                { label: "Warden", value: 1 },
                { label: "PT", value: 2 },
                { label: "Housekeeping", value: 3 },
            ],
            entityOpt: [
                { label: "School", value: 1 },
                { label: "Company", value: 2 },
            ],
            // showDatatable:true
            emailSend: false,
        };
    }
    column = [
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "SCHOOL NAME ",
            accessor: "userName",
        },
        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "DOB",
            accessor: "dob",
        },
        {
            Header: "CLASS",
            accessor: "className",
        },
        {
            Header: "DEPARTMENT",
            accessor: "departmentName",
        },

        // {
        //     Header: "STUDENT RANK",
        //     accessor: "studentRank",
        // },
        {
            Header: "NAME OF THE MENTOR",
            accessor: "nameOfMentorName",
        },
        {
            Header: "REASON OF COUNSELING",
            accessor: "reason",
        },
        {
            Header: "DATE OF COUNSELING",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },
        {
            Header: "HOSTEL NAME",
            accessor: "HostelName",
        },
        {
            Header: "EDIT",
            accessor: "edit",
            Cell: (d) => this.Editpages(d, 'customer'),
        },
        // {
        //     Header: "STATUS",
        //     // accessor: "edit",
        //     Cell: (d) => this.buttondel(d),
        // },
        // {
        //     Header: "Delete",
        //     accessor: "edit",
        //     Cell: (d) => this.DatatableCheckboxx(d, this.updateMyData, this.state.SelectedEnquiries)
        // }
    ];
    column1 = [
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "SCHOOL NAME ",
            accessor: "userName",
        },
        // {
        //     Header: "NAME",
        //     accessor: "name",
        // },
        {
            Header: "TOPIC",
            accessor: "topic",
        },
        {
            Header: "DEPARTMENT",
            accessor: "departmentName",
        },
        {
            Header: "DESCRIPTION",
            accessor: "discription",
        },
        {
            Header: "DATE OF COUNSELING",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },
        {
            Header: "EDIT",
            accessor: "edit",
            Cell: (d) => this.Editpages(d, 'customer'),
        },

        // {
        //     Header: "STATUS",
        //     // accessor: "edit",
        //     Cell: (d) => this.buttondel(d),
        // },
        // {
        //     Header: "Delete",
        //     accessor: "edit",
        //     Cell: (d) => this.DatatableCheckboxx(d, this.updateMyData, this.state.SelectedEnquiries)
        // }
    ];
    column2 = [
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "SCHOOL NAME ",
            accessor: "userName",
        },

        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "AGE",
            accessor: "Age",
        },
        {
            Header: "DEPARTMENT",
            accessor: "departmentName",
        },
        {
            Header: "CLASS",
            accessor: "className",
        },
        {
            Header: "GENDER",
            accessor: "gender",
        },
        {
            Header: "MARITAL STATUS",
            accessor: "MaritalStatus",
        },
        {
            Header: "DESCRIPTION",
            accessor: "discription",
        },
        {
            Header: "SUBJECT",
            accessor: "Subject",
        },
        {
            Header: "Date",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },
        {
            Header: "EDIT",
            accessor: "edit",
            Cell: (d) => this.Editpages(d, 'customer'),
        },


    ];
    column3 = [
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "SCHOOL NAME ",
            accessor: "userName",
        },

        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "AGE",
            accessor: "Age",
        },
        {
            Header: "GENDER",
            accessor: "gender",
        },
        {
            Header: "MARITAL STATUS",
            accessor: "MaritalStatus",
        },
        {
            Header: "DESCRIPTION",
            accessor: "discription",
        },
        {
            Header: "HOSTEL NAME",
            accessor: "HostelName",
        },
        {
            Header: "NTS",
            accessor: "NTSname",
        },
        {
            Header: "DATE",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },
        {
            Header: "EDIT",
            accessor: "edit",
            Cell: (d) => this.Editpages(d, 'customer'),
        },

    ];
    column4 = [
        {
            Header: "COMPANY NAME ",
            accessor: "userName",
        },
        {
            Header: "EMPLOYEEID",
            accessor: "employeeId",
        },
        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "DEPARTMENT",
            accessor: "departmentName",
        },
        {
            Header: "MOBILE NUMBER",
            accessor: "mobileNumber",
        },
        {
            Header: "DESIGNATION",
            accessor: "designationName",
        },
        {
            Header: "GENDER",
            accessor: "gender",
        },
        {
            Header: "DESCRIPTION",
            accessor: "discription",
        },

        {
            Header: "DATE",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },
        {
            Header: "EDIT",
            accessor: "edit",
            Cell: (d) => this.Editpages(d, 'customer'),
        },

    ];
    excelcolumn1 = [
        {
            label: "COUNSELING TYPE",
            key: "counsellingType",
        },
        {
            label: "SCHOOL NAME",
            key: "userName",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "TOPIC",
            key: "topic",
        },
        {
            label: "DEPARTMENT",
            key: "departmentName",
        },

        {
            label: "DESCRIPTION",
            key: "discription",
        },
        {
            label: "REASON OF COUNSELING",
            key: "reason",
        },
        {
            label: "DATE OF COUNSELING",
            key: "date",
        },

    ]
    excelColumn = [
        {
            label: "COUNSELLING TYPE",
            key: "counsellingType",
        },
        {
            label: "SCHOOL NAME ",
            key: "userName",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "DOB",
            key: "dob",
        },
        {
            label: "CLASS",
            key: "className",
        },
        {
            label: "STUDENT RANK",
            key: "studentRank",
        },
        {
            label: "DEPARTMENT",
            key: "departmentName",
        },
        {
            label: "NAME OF THE MENTOR",
            key: "nameOfMentorName",
        },
        {
            label: "REASON OF COUNSELLING",
            key: "reason",
        },
        {
            label: "DATE OF COUNSELLING",
            key: "date",
        },
        {
            label: "HOSTEL NAME",
            key: "HostelName",
        },
        // {
        //     Header: "EDIT",
        //     accessor: "edit",
        //     Cell: (d) => this.Editpages(d, 'customer'),
        // },
        // {
        //     Header: "STATUS",
        //     // accessor: "edit",
        //     Cell: (d) => this.buttondel(d),
        // },
        // {
        //     Header: "Delete",
        //     accessor: "edit",
        //     Cell: (d) => this.DatatableCheckboxx(d, this.updateMyData, this.state.SelectedEnquiries)
        // }
    ];
    excelcolumn2 = [
        {
            label: "COUNSELING TYPE",
            key: "counsellingType",
        },
        {
            label: "SCHOOL NAME ",
            key: "userName",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "Age",
            key: "Age",
        },
        {
            label: "DEPARTMENT",
            key: "departmentName",
        },
        {
            label: "CLASS",
            key: "className",
        },
        {
            label: "GENDER",
            key: "gender",
        },
        {
            label: "MARITAL STATUS",
            key: "MaritalStatus",
        },
        {
            label: "DESCRIPTION",
            key: "discription",
        },
        {
            label: "SUBJECT",
            key: "Subject",
        },
        {
            label: "DATE",
            key: "date",
        },

    ]
    excelcolumn3 = [
        {
            label: "COUNSELING TYPE",
            key: "counsellingType",
        },
        {
            label: "SCHOOL NAME ",
            key: "userName",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "AGE",
            key: "Age",
        },
        {
            label: "GENDER",
            key: "gender",
        },
        {
            label: "MARITAL STATUS",
            key: "MaritalStatus",
        },
        {
            label: "HOSTEL NAME",
            key: "HostelName",
        },
        {
            label: "DESCRIPTION",
            key: "discription",
        },
        {
            label: "NTS",
            key: "NTSname",
        },
        {
            label: "DATE",
            key: "date",
        },

    ]
    excelcolumn4 = [
        {
            label: "COMPANY NAME ",
            key: "userName",
        },
        {
            label: "EMPLOYEEID",
            key: "employeeId",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "MOBILE NUMBER",
            key: "mobileNumber",
        },
        {
            label: "DESIGNATION",
            key: "designationName",
        },
        {
            label: "GENDER",
            key: "gender",
        },
        {
            label: "DESCRIPTION",
            key: "discription",
        },

        {
            label: "DATE",
            key: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },

    ];
    async componentDidMount() {
        this.MainFun()
    }

    MainFun = async () => {
        try {
            this.setState({ loading: true })
            const Class = await cmsContent.getFreedom(
                "id as value, class_name as label",
                "psychol_class",
                `id`,
                "id",
                "id"
            );
            if (Class) {
                this.setState({ ClassOptions: Class.data })
            }
            const Department = await cmsContent.getFreedom(
                "id as value, department_name as label,entity_type as entity_type",
                "psychol_department",
                "id",
                "id",
                "id"
            );
            if (Department) {
                this.setState({ DepartmentOptions1: Department.data })
            }
            const schoolDepartment = await cmsContent.getFreedom(
                "id as value, department_name as label,entity_type as entity_type",
                "psychol_department",
                `psychol_department.entity_type = '1'`,
                "id",
                "id"
            );
            if (schoolDepartment) {
                this.setState({ DepartmentOptions: schoolDepartment.data })
            }
            const CmpDepartment = await cmsContent.getFreedom(
                "id as value, department_name as label,entity_type as entity_type",
                "psychol_department",
                `psychol_department.entity_type = '2'`,
                "id",
                "id"
            );
            if (CmpDepartment) {
                this.setState({ CmpDepartmentOptions: CmpDepartment.data })
            }
            const Topic = await cmsContent.getFreedom(
                "id as value, topic_name as label",
                "psychol_topic",
                `id`,
                "id",
                "id"
            );
            if (Topic) {
                this.setState({ TopicOptions: Topic.data })
            }
            const Email = await cmsContent.getFreedom(
                "id as value, email_id as label",
                "psychol_email",
                `id`,
                "id",
                "id"
            );
            if (Email) {
                this.setState({ EmailOptions: Email.data })
            }
            const Mentor = await cmsContent.getFreedom(
                "id as value, mentor_name as label",
                "psychol_mentor",
                `id`,
                "id",
                "id"
            );
            if (Mentor) {
                this.setState({ MentorOptions: Mentor.data })
            }
            // const Counselling = await cmsContent.getFreedom(
            //     "*",
            //     "psychol_form",
            //     `customerId=${this.state.customerId}`,
            //     "id",
            //     "id"
            // );
            // const Training = await cmsContent.getFreedom(
            //     "*",
            //     "psychol_training_form",
            //     `customerId=${this.state.customerId}`,
            //     "id",
            //     "id"
            // );
            // if (Training) {
            //     Training.data.map((ival) => {
            //         Department.data.map((kval) => {
            //             if (ival.department == kval.value) {
            //                 ival.departmentName = kval.label
            //             }
            //         })
            //     })
            //     this.setState({ TrainingData: Training.data })
            // }

            const school = await cmsContent.getFreedom(
                "id as value, userName as label",
                "tbl_user_web",
                `customerId=${this.state.LoginuserId} && entitytype='1'`,
                "id",
                "id"
            );
            if (school) {
                this.setState({ SchoolOptions: school.data })
            }
            const company = await cmsContent.getFreedom(
                "id as value, userName as label",
                "tbl_user_web",
                `customerId=${this.state.LoginuserId} && entitytype='2'`,
                "id",
                "id"
            );
            if (company) {
                this.setState({ CompanyOptions: company.data })
            }
            const Designation = await cmsContent.getFreedom(
                "id as value, designation_name as label",
                "psychol_designation",
                `id`,
                "id",
                "id"
            );
            if (Designation) {
                this.setState({ DesignationOpt: Designation.data })
            }

            const Counselling = await cmsContent.getFreedom(
                "psychol_form.*,tbl_user_web.userName",
                "psychol_form,tbl_user_web",
                `tbl_user_web.id=psychol_form.customerId`,
                "id",
                "id"
            );
            // console.log(Counselling,"Counselling");
            if (Counselling && Class && Department && Mentor && school) {
                const classLookup = Class.data.reduce((acc, c) => {
                    acc[c.value] = c.label;
                    return acc;
                }, {});

                const departmentLookup = Department.data.reduce((acc, d) => {
                    acc[d.value] = d.label;
                    return acc;
                }, {});
                const DesignationLookup = Designation.data.reduce((acc, d) => {
                    acc[d.value] = d.label;
                    return acc;
                }, {});
                const topicLookup = Topic.data.reduce((acc, d) => {
                    acc[d.value] = d.label;
                    return acc;
                }, {});

                const mentorLookup = Mentor.data.reduce((acc, m) => {
                    acc[m.value] = m.label;
                    return acc;
                }, {});
                const schoolLookup = school.data.reduce((acc, d) => {
                    acc[d.value] = d.label;
                    return acc;
                }, {});

                const NTSLookup = this.state.NTSOptions.reduce((acc, d) => {
                    acc[d.value] = d.label;
                    return acc;
                }, {});

                await Counselling.data.forEach((ival) => {
                    if (classLookup[ival.class]) {
                        ival.className = classLookup[ival.class];
                    }
                    if (ival.department !== null || ival.department != null || ival.department !== "") {
                        console.log(ival.department)
                        let dept = ival.department && ival.department.split(",")
                        if (dept && dept.length && dept !== null) {
                            dept.map((kval, k) => {
                                if (departmentLookup[kval]) {
                                    dept[k] = departmentLookup[kval];
                                }
                            })
                            let selectedValues = "";
                            dept.map(values => (selectedValues += `${values},`));
                            ival.departmentName = selectedValues.replace(/,\s*$/, "");
                        }
                    }
                    // if (departmentLookup[ival.department]) {
                    //     ival.departmentName = departmentLookup[ival.department];
                    // }
                    if (DesignationLookup[ival.designation]) {
                        ival.designationName = DesignationLookup[ival.designation];
                    }
                    if (topicLookup[ival.topic]) {
                        ival.topic = topicLookup[ival.topic];
                    }
                    if (mentorLookup[ival.nameOfMentor]) {
                        ival.nameOfMentorName = mentorLookup[ival.nameOfMentor];
                    }
                    if (schoolLookup[ival.customerId]) {
                        ival.customerId = schoolLookup[ival.customerId];
                    }
                    if (NTSLookup[ival.NTS]) {
                        ival.NTSname = NTSLookup[ival.NTS];
                    }
                    if (ival.counsellingType) {
                        if (ival.counsellingType == "1") {
                            ival.counsellingId = ival.counsellingType
                            ival.counsellingType = "1 to 1 Counseling"
                        }
                        if (ival.counsellingType == "2") {
                            ival.counsellingId = ival.counsellingType
                            ival.counsellingType = "Group Counseling/Training"
                        }
                    }
                    if (ival.date) {
                        ival.date = moment(ival.date).format('DD-MM-YYYY');
                    }
                });
                // console.log(Counselling, "Counselling");
                let counsellingdata = []
                let trainingdata = []
                let TSData = []
                let NTSData = []
                let CompanyData = []
                Counselling.data.forEach((ival) => {
                    if (ival.counsellingId == "1" && ival.type == "1" && ival.entityType == "1") {
                        counsellingdata.push(ival)
                    } else if (ival.counsellingId == "2" && ival.type == "1" && ival.entityType == "1") {
                        trainingdata.push(ival)
                    } else if (ival.type == "2" && ival.entityType == "1") {
                        TSData.push(ival)
                    } else if (ival.type == "3" && ival.entityType == "1") {
                        NTSData.push(ival)
                    }
                    if (ival.entityType == "2") {
                        CompanyData.push(ival)
                    }

                });
                this.setState({ CounsellingData: counsellingdata, TrainingData: trainingdata, TSData, NTSData, CompanyData });
            }


            // if (Counselling && Class && Department && Mentor) {
            //     const classLookup = Class.data.reduce((acc, c) => {
            //         acc[c.value] = c.label;
            //         return acc;
            //     }, {});

            //     const departmentLookup = Department.data.reduce((acc, d) => {
            //         acc[d.value] = d.label;
            //         return acc;
            //     }, {});

            //     const mentorLookup = Mentor.data.reduce((acc, m) => {
            //         acc[m.value] = m.label;
            //         return acc;
            //     }, {});

            //     Counselling.data.forEach((ival) => {
            //         if (classLookup[ival.class]) {
            //             ival.className = classLookup[ival.class];
            //         }
            //         if (departmentLookup[ival.department]) {
            //             ival.departmentName = departmentLookup[ival.department];
            //         }
            //         if (mentorLookup[ival.nameOfMentor]) {
            //             ival.nameOfMentorName = mentorLookup[ival.nameOfMentor];
            //         }
            //     });

            //     this.setState({ CounsellingData: Counselling.data });
            // }




            this.setState({ loading: false })

        } catch (error) {
            console.log(error);
        }
    }
    DateFormateCahnge(data) {
        return data.map(item => ({
            ...item,
            date: moment(item.date).format('DD-MM-YYYY')
        }));
    }

    Editpages = (value, modalWindowId) => {
        return (
            <button className="btn btn-info" onClick={() => this.onEdit(value)}>
                Edit
            </button>
        );


    };
    onEdit = async (value) => {
        const { counsellingOptions, usertypeOptions, SchoolOptions, NTSOptions, entityOpt } = this.state;
        const d = value.original;
        console.log(d, "d");
        await this.setState({ userAddRow: 0 })
        const counselingtype = counsellingOptions.find(ival => ival.value == d.counsellingId);
        const entityType = entityOpt.find(ival => ival.value == d.entityType);
        const userType = usertypeOptions.find(ival => ival.value == d.type);
        const schoolSelect = SchoolOptions.find(ival => ival.label == d.userName);
        let NTSOptSelect
        if (d.NTS) {
            NTSOptSelect = NTSOptions.find(ival => ival.value == d.NTS);
        }

        if ((!counselingtype || !userType || !schoolSelect) && !entityType) {
            console.error("Failed to find matching options");
            return;
        }
        if (!entityType) {
            console.error("Failed to find matching options");
            return;
        }

        try {
            await this.setState({
                FormType: 1,
                counsellingOptSelect: counselingtype,
                entityOptSelect: entityType,
                usertypeOptSelect: userType,
                userAddRow: 1,
                SchoolNameSelect: schoolSelect,
                SchoolName: schoolSelect,
                NTSOptSelect,
                isEdit: true,
                editId: d.id
            });

            await this.addRow(1, "1");

            const { counsellingOptSelect, usertypeOptSelect, counsellingArray, trainingArray, TSArray, NTSArray, entityOptSelect, CompanyArray, CompanyOptions } = this.state;
            // console.log(counsellingOptSelect, "counsellingOptSelect", usertypeOptSelect, "usertypeOptSelect");

            const formatDate = (date) => moment(date, 'DD-MM-YYYY').format("YYYY-MM-DD");
            if (entityOptSelect.value == "2") {
                // console.log("enter 111");
                console.log(d, "comapnay")
                const companyNameSelect = CompanyOptions.find(ival => ival.value == d.customerId);
                this.setState({ CompanyNameSelect: companyNameSelect })
                const genderSelect = CompanyArray[0].GenderOption.find(ival => ival.label == d.gender);
                const designationSelect = CompanyArray[0].DesignationOption.find(ival => ival.value == d.designation);
                const department = d.department.split(",");
                console.log(department, "department");
                const result = department.join(",")
                const departmentSelect = CompanyArray[0].DepartmentOption.filter(ival => department.some(jval => ival.value == jval));
                console.log(departmentSelect, "departmentSelect");
                const dataFormate = formatDate(d.date);
                CompanyArray.forEach(ival => {
                    Object.assign(ival, {
                        CompanyNameSelect: companyNameSelect,
                        Date: dataFormate,
                        Reason: d.reason,
                        EmployeeId: d.employeeId,
                        MobileNumber: d.mobileNumber,
                        Description: d.discription,
                        GenderSelect: genderSelect,
                        DesignationSelect: designationSelect,
                        DepartmentSelect: departmentSelect,
                        DepartmentSelectdval: result,
                        Name: d.name,

                    });
                });
                // console.log(CompanyArray,"CompanyArray");

                await this.setState({ CompanyArray });
            }

            if (counsellingOptSelect?.value && usertypeOptSelect?.value && entityOptSelect.value == "1") {
                if (counsellingOptSelect.value == "1" && usertypeOptSelect.value == "1") {
                    console.log("enter11");

                    const classSelect = counsellingArray[0].ClassOption.find(ival => ival.value == d.class);
                    const genderSelect = counsellingArray[0].GenderOption.find(ival => ival.label == d.gender);
                    const departmentSelect = counsellingArray[0].DepartmentOption.find(ival => ival.value == d.department);
                    const mentorSelect = counsellingArray[0].MentorOption.find(ival => ival.value == d.nameOfMentor);

                    const dataFormate = formatDate(d.date);
                    const dobFormate = formatDate(d.dob);

                    counsellingArray.forEach(ival => {
                        Object.assign(ival, {
                            SchoolName: schoolSelect.label,
                            SchoolNameSelect: schoolSelect,
                            Name: d.name,
                            DepartmentSelect: departmentSelect,
                            MentorSelect: mentorSelect,
                            Date: dataFormate,
                            Reason: d.reason,
                            DOB: dobFormate,
                            StudentId: d.studentid,
                            StudentRank: d.studentRank,
                            ClassSelect: classSelect,
                            GenderSelect: genderSelect,
                            HostelName: d.HostelName
                        });
                    });

                    await this.setState({ counsellingArray });

                } else if (counsellingOptSelect.value == "2" && usertypeOptSelect.value == "1") {
                    console.log(trainingArray, "enter 2");
                    const department = d.department.split(",");
                    console.log(department, "department");
                    const departmentSelect = trainingArray[0].DepartmentOption.filter(ival => department.some(jval => ival.value == jval));
                    console.log(departmentSelect, "departmentSelect");
                    const topic = d.topic.split(",");
                    console.log(topic, "topic");
                    const result = topic.join(",")
                    const TopicSelect = trainingArray[0].TopicOption.filter(ival => topic.includes(ival.label));
                    console.log(TopicSelect)
                    const dataFormate = formatDate(d.date);

                    trainingArray.forEach(ival => {
                        Object.assign(ival, {
                            SchoolName: schoolSelect.label,
                            SchoolNameSelect: schoolSelect,
                            DepartmentSelect: departmentSelect,
                            DepartmentSelectdval: d.department,
                            Date: dataFormate,
                            Reason: d.reason,
                            Discription: d.discription,
                            TopicSelect: TopicSelect,
                            TopicSelectd: result,
                        });
                    });
                    console.log(trainingArray, "trainingArray edit");

                    await this.setState({ trainingArray });

                } else if (usertypeOptSelect.value == "2") {
                    console.log("enter 3",d);
                    // console.log(TSArray, "enter 2");

                    const department = d.department.split(",");
                    console.log(department, "department");
                    const result = department.join(",")
                    console.log(result)
                    const departmentSelect = TSArray[0].DepartmentOption.filter(ival => department.some(jval => ival.value == jval));
                    console.log(departmentSelect, "departmentSelect");
                    const classSelect = TSArray[0].ClassOption.find(ival => ival.value == d.class);
                    console.log(classSelect,"classselect")
                    const genderSelect = TSArray[0].GenderOption.find(ival => ival.label == d.gender);
                    const maritalSelect = TSArray[0].MaritalStatusOption.find(ival => ival.label == d.MaritalStatus);
                    console.log(TSArray, "tsArray")
                    const dataFormate = formatDate(d.date);

                    TSArray.forEach(ival => {
                        Object.assign(ival, {
                            SchoolName: schoolSelect.label,
                            SchoolNameSelect: schoolSelect,
                            DepartmentSelect: departmentSelect,
                            DepartmentSelectdval: result,
                            Date: dataFormate,
                            Reason: d.reason,
                            Discription: d.discription,
                            ClassSelect: classSelect,
                            GenderSelect: genderSelect,
                            Subject: d.Subject,
                            Name: d.name,
                            Age: d.Age,
                            MaritalStatusSelect: maritalSelect
                        });
                    });
                    console.log(TSArray, "TSArray");

                    await this.setState({ TSArray });

                } else if (usertypeOptSelect.value == "3") {
                    console.log("enter 4");
                    // console.log(NTSArray, "enter 3");
                    // this.setState(prevState => ({
                    //     NTSArray: prevState.NTSArray.map((row, index) =>
                    //         index === rowIndex ? { ...row, [columnLabel]: value } : row
                    //     )
                    // }));

                    const genderSelect = NTSArray[0].GenderOption.find(ival => ival.label == d.gender);
                    const maritalSelect = NTSArray[0].MaritalStatusOption.find(ival => ival.label == d.MaritalStatus);

                    const dataFormate = formatDate(d.date);

                    NTSArray.forEach(ival => {
                        Object.assign(ival, {
                            SchoolName: schoolSelect.label,
                            SchoolNameSelect: schoolSelect,
                            Date: dataFormate,
                            // Reason: d.reason,
                            HostelName: d.HostelName,
                            Discription: d.discription,
                            GenderSelect: genderSelect,
                            // Subject: d.Subject,
                            Name: d.name,
                            Age: d.Age,
                            MaritalStatusSelect: maritalSelect
                        });
                    });

                    await this.setState({ NTSArray });
                }
                else if (counsellingOptSelect.value == "2" && usertypeOptSelect.value == "3" && NTSOptSelect.value == "1") {
                    console.log("enter 4", NTSOptSelect, "select");
                    const dataFormate = formatDate(d.date);
                    NTSArray.forEach(ival => {
                        Object.assign(ival, {
                            SchoolName: schoolSelect.label,
                            SchoolNameSelect: schoolSelect,
                            Date: dataFormate,
                            HostelName: d.HostelName,
                            Discription: d.discription,
                        });
                    });

                    await this.setState({ NTSArray });
                }
                if (this.state.counsellingOptSelect.value == "2" && this.state.usertypeOptSelect.value == "3" && NTSOptSelect.value != "1") {
                    let NTSColumn1 = [
                        { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                        //  { label: "Name", value: 2, type: "text", Name: "Name" },
                        //  { label: "Gender", value: 3, type: "selectbox", Name: "Gender" },
                        //  { label: "Age", value: 4, type: "number", Name: "Age" },
                        //  { label: "Marital Status", value: 5, type: "selectbox", Name: "MaritalStatus" },
                        { label: "DESCRIPTION", value: 6, type: "textarea", Name: "Discription" },
                        { label: "Date ", value: 7, type: "date", Name: "Date" },
                    ]

                    this.setState({ NTSColumn1 })
                }
                if (this.state.counsellingOptSelect.value == "2" && this.state.usertypeOptSelect.value == "3" && NTSOptSelect.value == "1") {
                    let NTSColumn = [
                        { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                        // { label: "Name", value: 2, type: "text", Name: "Name" },
                        // { label: "Gender", value: 3, type: "selectbox", Name: "Gender" },
                        // { label: "Age", value: 4, type: "number", Name: "Age" },
                        // { label: "Marital Status", value: 5, type: "selectbox", Name: "MaritalStatus" },
                        { label: "Hostel Name ", value: 6, type: "textarea", Name: "HostelName" },
                        { label: "DESCRIPTION", value: 7, type: "textarea", Name: "Discription" },
                        { label: "Date ", value: 8, type: "date", Name: "Date" },
                    ]
                    this.setState({ NTSColumn })
                }
                if (this.state.counsellingOptSelect.value == "1" && this.state.usertypeOptSelect.value == "3" && NTSOptSelect.value != "1") {
                    let NTSColumn1 = [
                        { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                        { label: "Name", value: 2, type: "text", Name: "Name" },
                        { label: "Gender", value: 3, type: "selectbox", Name: "Gender" },
                        { label: "Age", value: 4, type: "number", Name: "Age" },
                        { label: "Marital Status", value: 5, type: "selectbox", Name: "MaritalStatus" },
                        { label: "DESCRIPTION", value: 6, type: "textarea", Name: "Discription" },
                        { label: "Date ", value: 7, type: "date", Name: "Date" },
                    ]

                    this.setState({ NTSColumn1 })
                }
                if (this.state.counsellingOptSelect.value == "1" && this.state.usertypeOptSelect.value == "3" && NTSOptSelect.value == "1") {
                    let NTSColumn = [
                        { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                        { label: "Name", value: 2, type: "text", Name: "Name" },
                        { label: "Gender", value: 3, type: "selectbox", Name: "Gender" },
                        { label: "Age", value: 4, type: "number", Name: "Age" },
                        { label: "Marital Status", value: 5, type: "selectbox", Name: "MaritalStatus" },
                        { label: "Hostel Name ", value: 6, type: "textarea", Name: "HostelName" },
                        { label: "DESCRIPTION", value: 7, type: "textarea", Name: "Discription" },
                        { label: "Date ", value: 8, type: "date", Name: "Date" },
                    ]
                    this.setState({ NTSColumn })
                }
            }

            // console.log(NTSArray, "NTSArray");

        } catch (error) {
            console.error("Error updating state:", error);
        }
    };

    // onEdit = async (value) => {
    //     let { counsellingOptions, usertypeOptions, SchoolOptions } = this.state
    //     let d = value.original;
    //     console.log(d, "ddd");
    //     let counselingtype = counsellingOptions.filter(ival => ival.value == d.counsellingId)
    //     let userType = usertypeOptions.filter(ival => ival.value == d.type)
    //     let schoolSelect = SchoolOptions.filter(ival => ival.label == d.userName)


    //     await this.setState({
    //         FormType: 1,
    //         counsellingOptSelect: counselingtype[0],
    //         usertypeOptSelect: userType[0],
    //         userAddRow: 1,
    //         SchoolNameSelect: schoolSelect[0],
    //         SchoolName: schoolSelect[0],
    //         isEdit: true,
    //         editId:d.id
    //     })
    //     await this.addRow(1, "1")

    //     let { counsellingOptSelect, usertypeOptSelect, counsellingArray, trainingArray,TSArray } = this.state

    //     if (counsellingOptSelect && counsellingOptSelect.value && usertypeOptSelect && usertypeOptSelect.value) {
    //         if (counsellingOptSelect.value == "1" && usertypeOptSelect.value == "1") {
    //             console.log("enter11");
    //             let classSelect = counsellingArray[0].ClassOption.filter(ival => ival.value == d.class)
    //             let genderSelect = counsellingArray[0].GenderOption.filter(ival => ival.label == d.gender)
    //             let departmentSelect = counsellingArray[0].DepartmentOption.filter(ival => ival.value == d.department)
    //             let mentorSelect = counsellingArray[0].MentorOption.filter(ival => ival.value == d.nameOfMentor)
    //             console.log(d.date, "d.date");
    //             let dataFormate = moment(d.date, 'DD-MM-YYYY').format("YYYY-MM-DD")
    //             let dobFormate = moment(d.dob, "DD-MM-YYYY").format("YYYY-MM-DD")
    //             console.log(dataFormate, "dataFormate",dobFormate,"v");


    //             counsellingArray.map((ival) => {
    //                 ival.SchoolName = schoolSelect[0].label
    //                 ival.SchoolNameSelect = schoolSelect[0]
    //                 ival.Name = d.name
    //                 ival.DepartmentSelect = departmentSelect[0]
    //                 ival.MentorSelect = mentorSelect[0]
    //                 ival.Date = dataFormate
    //                 ival.Reason = d.reason
    //                 ival.DOB=dobFormate
    //                 ival.StudentId = d.studentid
    //                 ival.StudentRank = d.studentRank
    //                 ival.ClassSelect = classSelect[0]
    //                 ival.GenderSelect = genderSelect[0]
    //                 ival.HostelName = d.HostelName

    //             })

    //             await this.setState({ counsellingArray });
    //         } else if (counsellingOptSelect.value == "2" && usertypeOptSelect.value == "1") {
    //             let departmentSelect = trainingArray[0].DepartmentOption.filter(ival => ival.value == d.department)
    //             let topic = d.topic.split(",")
    //             let TopicSelect = trainingArray[0].TopicOption.filter(ival => topic.some(jval=>ival.label == jval))
    //             console.log(topic, "topic");
    //             let dataFormate = moment(d.date, 'DD-MM-YYYY').format("YYYY-MM-DD")
    //             trainingArray.map((ival) => {
    //                 ival.SchoolName = schoolSelect[0].label
    //                 ival.SchoolNameSelect = schoolSelect[0]
    //                 ival.DepartmentSelect = departmentSelect[0]
    //                 ival.Date = dataFormate
    //                 ival.Reason = d.reason
    //                 ival.Discription = d.discription
    //                 ival.TopicSelect=TopicSelect
    //                 ival.TopicSelectd=topic

    //             })

    //             await this.setState({ trainingArray });

    //         }
    //         else if (usertypeOptSelect.value == "2") {
    //             console.log(TSArray,"enter 2");
    //             let departmentSelect = TSArray[0].DepartmentOption.filter(ival => ival.value == d.department)
    //             let classSelect = TSArray[0].ClassOption.filter(ival => ival.value == d.class)
    //             let genderSelect = TSArray[0].GenderOption.filter(ival => ival.label == d.gender)
    //             let maritalSelect = TSArray[0].MaritalStatusOption.filter(ival => ival.label == d.MaritalStatus)

    //             let dataFormate = moment(d.date, 'DD-MM-YYYY').format("YYYY-MM-DD")
    //             TSArray.map((ival) => {
    //                 ival.SchoolName = schoolSelect[0].label
    //                 ival.SchoolNameSelect = schoolSelect[0]
    //                 ival.DepartmentSelect = departmentSelect[0]
    //                 ival.Date = dataFormate
    //                 ival.Reason = d.reason
    //                 ival.Discription = d.discription
    //                 ival.ClassSelect = classSelect[0]
    //                 ival.GenderSelect = genderSelect[0]
    //                 ival.Subject=d.Subject
    //                 ival.Name=d.name
    //                 ival.Age=d.Age
    //                 ival.MaritalStatusSelect=maritalSelect[0]

    //             })

    //             await this.setState({ TSArray });

    //         }
    //         else if (usertypeOptSelect.value == "3") {
    //             console.log("enter 3");
    //             // this.setState(prevState => ({
    //             //     NTSArray: prevState.NTSArray.map((row, index) =>
    //             //         index === rowIndex ? { ...row, [columnLabel]: value } : row
    //             //     )
    //             // }));
    //         }
    //     }
    //     console.log(counsellingArray, "counsellingArray");



    // };

    buttondel = (d) => {
        if (d.original.status == 0) {
            return (
                <button
                    className="btn btn-success"
                    onClick={() => this.Delete(d)}
                >Active</button>
            );
        } else {
            return (
                <button
                    className="btn btn-danger"
                    onClick={() => this.Recover(d)}
                >InActive</button>
                // <span className="label">Deleted</span>
            )

        }
    }
    dateFormateChange = (d) => {
        console.log(d, "d");
        if (d.original.date && d.original.date != null) {
            return moment(d.original.date).format('DD-MM-YYYY')
        } else {
            return d.original.date
        }
    }

    handlechange = async (e, type, name) => {
        if (type == "Select") {
            await this.setState({
                [name]: e
            })
        }
        else if (type == "caps") {
            await this.setState({
                [e.target.name]: e.target.value.toUpperCase(), errorempname: "", errorCode: "", errgender: "", errBusinessUnit: "", errFunction: "", errSubFunction: "", errRegion: "", errDivision: "", errBranch: "", erroffEmailAdd: "", errGrade: "", errBand: "", errEmpCatType: "", errL1ManCode: "", errL1ManCode: "",
                errL1Man: "", errL2ManCode: "", errL2Man: "", errHRManCode: "", errHRMan: "", errBusiDesignation: "",
            });
        }
        else {
            await this.setState({ [e.target.name]: e.target.value, errorempname: "", errorCode: "" });
        }
    };

    submitCategory = async () => {
        const { } = this.state;
        try {

        } catch (error) {
            console.log(error);
        }
    };

    fileupload = e => {
        this.setState({ csv: e.target.files[0] })
    }

    addRow = (value, type) => {
        let { userAddRow, counsellingArray, trainingArray, TSArray, NTSArray, usertypeOptSelect, SchoolNameSelect, counsellingOptSelect, entityOptSelect, CompanyArray, CompanyNameSelect } = this.state
        // console.log(entityOptSelect,"entityOptSelect");

        // if (type === "1") {
        if (entityOptSelect.value == "2") {
            console.log("enter 1");

            let addIndex = value ? CompanyArray.length : 0
            const newItems = Array.from({ length: value ? value : Number(userAddRow) }, (_, index) => ({

                EmployeeId: "",
                Name: "",
                MobileNumber: "",
                DesignationSelect: "",
                DepartmentSelect: "",
                Description: "",
                Date: "",
                Reason: "",
                GenderSelect: "",
                GenderOption: [{ label: "MALE", value: 1 }, { label: "FEMALE", value: 2 }],
                DesignationOption: this.state.DesignationOpt,
                CompanyNameSelect: CompanyNameSelect ? CompanyNameSelect : "",
                DepartmentOption: this.state.CmpDepartmentOptions,

                index: value ? addIndex : index
            }));
            console.log(newItems, "newItems");
            this.setState(prevState => ({
                CompanyArray: [...prevState.CompanyArray, ...newItems]
            }));
            // } else if (type === "2") {
        }
        if (entityOptSelect.value == "1" && counsellingOptSelect.value == "1" && usertypeOptSelect.value == "1") {
            // console.log("enter 1");

            let addIndex = value ? counsellingArray.length : 0
            const newItems = Array.from({ length: value ? value : Number(userAddRow) }, (_, index) => ({

                SchoolName: "",
                // SchoolNameSelect: "",
                SchoolNameSelect: SchoolNameSelect ? SchoolNameSelect : "",
                StudentRank: "",
                StudentId: "",
                Name: "",
                ClassSelect: "",
                Discription: "",
                GenderSelect: "",
                DepartmentSelect: "",
                MentorSelect: "",
                // WithparentSelect: "",
                DOB: "",
                Date: "",
                Reason: "",
                GenderOption: [{ label: "MALE", value: 1 }, { label: "FEMALE", value: 2 }],
                DepartmentOption: this.state.DepartmentOptions,
                ClassOption: this.state.ClassOptions,
                MentorOption: this.state.MentorOptions,
                // WithparentOption: [{ label: "Yes", value: 1 }, { label: "No", value: 0 }],


                index: value ? addIndex : index
            }));
            console.log(newItems, "newItems");
            let newArray = newItems.map((ival, index) => {
                return {
                    ...ival, DepartmentOption: ival.DepartmentOption.filter((jval, index) => {
                        return jval.entity_type == entityOptSelect.value ? jval : ""
                    })
                }
            })
            this.setState(prevState => ({
                counsellingArray: [...prevState.counsellingArray, ...newArray]
            }));
            // } else if (type === "2") {
        } else if (entityOptSelect.value == "1" && counsellingOptSelect.value == "2" && usertypeOptSelect.value == "1") {
            // console.log("enter 2");
            console.log(userAddRow, "userAddRow");
            let addIndex = value ? trainingArray.length : 0
            const newItems = Array.from({ length: value ? value : Number(userAddRow) }, (_, index) => ({

                SchoolName: "",
                // SchoolNameSelect: "",
                SchoolNameSelect: SchoolNameSelect ? SchoolNameSelect : "",
                StudentId: "",
                Name: "",
                DepartmentSelect: "",
                Discription: "",
                Date: "",
                Reason: "",
                TopicSelect: "",
                TopicSelectd: "",
                TopicOption: this.state.TopicOptions,
                DepartmentOption: this.state.DepartmentOptions,

                index: value ? addIndex : index
            }));
            console.log(newItems, "newItems");
            let newArray = newItems.map((ival, index) => {
                return {
                    ...ival, DepartmentOption: ival.DepartmentOption.filter((jval, index) => {
                        return jval.entity_type == entityOptSelect.value ? jval : ""
                    })
                }
            })
            this.setState(prevState => ({
                trainingArray: [...prevState.trainingArray, ...newArray]
            }));
        } else if (entityOptSelect.value == "1" && usertypeOptSelect.value == "2") {
            // console.log("enter 2");
            console.log(userAddRow, "userAddRow");
            let addIndex = value ? TSArray.length : 0
            const newItems = Array.from({ length: value ? value : Number(userAddRow) }, (_, index) => ({

                SchoolName: "",
                // SchoolNameSelect: "",
                SchoolNameSelect: SchoolNameSelect ? SchoolNameSelect : "",
                Name: "",
                Age: "",
                ClassSelect: "",
                Discription: "",
                GenderSelect: "",
                DepartmentSelect: "",
                MaritalStatusSelect: "",
                Subject: "",
                GenderOption: [{ label: "MALE", value: 1 }, { label: "FEMALE", value: 2 }],
                MaritalStatusOption: [{ label: "MARRIED", value: 1 }, { label: "UNMARRIED", value: 2 }],
                DepartmentOption: this.state.DepartmentOptions,
                ClassOption: this.state.ClassOptions,

                index: value ? addIndex : index
            }));
            let newArray = newItems.map((ival, index) => {
                return {
                    ...ival, DepartmentOption: ival.DepartmentOption.filter((jval, index) => {
                        return jval.entity_type == entityOptSelect.value ? jval : ""
                    })
                }
            })
            console.log(newArray, "newArray")
            this.setState(prevState => ({
                TSArray: [...prevState.TSArray, ...newArray]
            }));
        } else if (entityOptSelect.value == "1" && usertypeOptSelect.value == "3") {
            // console.log("enter 2");
            console.log(userAddRow, "userAddRow");
            let addIndex = value ? NTSArray.length : 0
            const newItems = Array.from({ length: value ? value : Number(userAddRow) }, (_, index) => ({

                SchoolName: "",
                // SchoolNameSelect: "",
                SchoolNameSelect: SchoolNameSelect ? SchoolNameSelect : "",
                Name: "",
                Age: "",
                Discription: "",
                HostelName: "",
                GenderSelect: "",
                MaritalStatusSelect: "",
                GenderOption: [{ label: "MALE", value: 1 }, { label: "FEMALE", value: 2 }],
                MaritalStatusOption: [{ label: "MARRIED", value: 1 }, { label: "UNMARRIED", value: 2 }],



                index: value ? addIndex : index
            }));
            // console.log(newItems, "newItems");
            // console.log(NTSArray, "NTSArray");
            this.setState(prevState => ({
                NTSArray: [...prevState.NTSArray, ...newItems]
            }));
        }

        // console.log(trainingArray, "trainingArray");
    }

    handleInputChange = (e, rowIndex, columnLabel, type) => {
        const { value } = e.target;
        const { usertypeOptSelect, counsellingOptSelect, entityOptSelect } = this.state;
        // console.log(usertypeOptSelect,"usertypeOptSelect");
        if (entityOptSelect && entityOptSelect.value == "2") {
            this.setState(prevState => ({
                CompanyArray: prevState.CompanyArray.map((row, index) =>
                    index === rowIndex ? { ...row, [columnLabel]: value } : row
                )
            }));
        }
        if ((entityOptSelect && entityOptSelect.value == "1") && counsellingOptSelect && counsellingOptSelect.value && usertypeOptSelect && usertypeOptSelect.value) {
            // if (type == "1") {
            if (counsellingOptSelect.value == "1" && usertypeOptSelect.value == "1") {
                // console.log("enter11");

                this.setState(prevState => ({
                    counsellingArray: prevState.counsellingArray.map((row, index) =>
                        index === rowIndex ? { ...row, [columnLabel]: value } : row
                    )
                }));
                // } else if (type == "2") {
            } else if (counsellingOptSelect.value == "2" && usertypeOptSelect.value == "1") {
                this.setState(prevState => ({
                    trainingArray: prevState.trainingArray.map((row, index) =>
                        index === rowIndex ? { ...row, [columnLabel]: value } : row
                    )
                }));
            }
            else if (usertypeOptSelect.value == "2") {
                this.setState(prevState => ({
                    TSArray: prevState.TSArray.map((row, index) =>
                        index === rowIndex ? { ...row, [columnLabel]: value } : row
                    )
                }));
            }
            else if (usertypeOptSelect.value == "3") {
                this.setState(prevState => ({
                    NTSArray: prevState.NTSArray.map((row, index) =>
                        index === rowIndex ? { ...row, [columnLabel]: value } : row
                    )
                }));
            }
        }

    };

    handleSelectChange = async (value, rowIndex, columnLabel, type) => {
        let { adminId, usertypeOptSelect, counsellingOptSelect, entityOptSelect } = this.state
        console.log(value, "value");
        console.log(rowIndex, "rowIndex");
        console.log(columnLabel, "columnLabel");
        // console.log(usertypeOptSelect,"usertypeOptSelect");
        if (rowIndex == "entityForm") {
            this.setState({ entityOptSelect: value, userAddRow: "", SchoolNameSelect: "", CompanyNameSelect: "", CompanyArray: [], counsellingArray: [], trainingArray: [], TSArray: [], NTSArray: [] });
        }
        if (entityOptSelect && entityOptSelect.value == "2") {
            this.setState(prevState => ({
                CompanyArray: prevState.CompanyArray.map((row, index) =>
                    index === rowIndex ? { ...row, [columnLabel + "Select"]: value } : row
                )
            }));
        }
        if ((entityOptSelect && entityOptSelect.value == "1") && counsellingOptSelect && counsellingOptSelect.value && usertypeOptSelect && usertypeOptSelect.value) {
            // if (type == "1") {
            if (counsellingOptSelect.value == "1" && usertypeOptSelect.value == "1") {

                this.setState(prevState => ({
                    counsellingArray: prevState.counsellingArray.map((row, index) =>
                        index === rowIndex ? { ...row, [columnLabel + "Select"]: value } : row
                    )
                }));
                // } else if (type == "2") {
            } else if (counsellingOptSelect.value == "2" && usertypeOptSelect.value == "1") {
                this.setState(prevState => ({
                    trainingArray: prevState.trainingArray.map((row, index) =>
                        index === rowIndex ? { ...row, [columnLabel + "Select"]: value } : row
                    )
                }));
            } else if (usertypeOptSelect.value == "2") {
                this.setState(prevState => ({
                    TSArray: prevState.TSArray.map((row, index) =>
                        index === rowIndex ? { ...row, [columnLabel + "Select"]: value } : row
                    )
                }));
            } else if (usertypeOptSelect.value == "3") {
                this.setState(prevState => ({
                    NTSArray: prevState.NTSArray.map((row, index) =>
                        index === rowIndex ? { ...row, [columnLabel + "Select"]: value } : row
                    )
                }));
            }
        }
        if (rowIndex == "counsellingForm") {
            console.log("enter");
            this.setState({ counsellingOptSelect: value, SchoolNameSelect: "", usertypeOptSelect: "", NTSOptSelect: "", userAddRow: "", CompanyArray: [], counsellingArray: [], trainingArray: [], TSArray: [], NTSArray: [] })
        }
        if (rowIndex == "UsertypeForm") {
            console.log("enter");
            this.setState({ usertypeOptSelect: value, SchoolNameSelect: "", NTSOptSelect: "", userAddRow: "", CompanyArray: [], counsellingArray: [], trainingArray: [], TSArray: [], NTSArray: [] });
        }
        if (rowIndex == "NonTeachingStaff") {
            // console.log("enter");
            this.setState({ NTSOptSelect: value, userAddRow: "", CompanyArray: [], counsellingArray: [], trainingArray: [], TSArray: [], NTSArray: [] });

            if (this.state.counsellingOptSelect.value == "2" && this.state.usertypeOptSelect.value == "3" && value.value != "1") {
                let NTSColumn1 = [
                    { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                    //  { label: "Name", value: 2, type: "text", Name: "Name" },
                    //  { label: "Gender", value: 3, type: "selectbox", Name: "Gender" },
                    //  { label: "Age", value: 4, type: "number", Name: "Age" },
                    //  { label: "Marital Status", value: 5, type: "selectbox", Name: "MaritalStatus" },
                    { label: "DESCRIPTION", value: 6, type: "textarea", Name: "Discription" },
                    { label: "Date ", value: 7, type: "date", Name: "Date" },
                ]

                this.setState({ NTSColumn1 })
            }
            if (this.state.counsellingOptSelect.value == "2" && this.state.usertypeOptSelect.value == "3" && value.value == "1") {
                let NTSColumn = [
                    { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                    // { label: "Name", value: 2, type: "text", Name: "Name" },
                    // { label: "Gender", value: 3, type: "selectbox", Name: "Gender" },
                    // { label: "Age", value: 4, type: "number", Name: "Age" },
                    // { label: "Marital Status", value: 5, type: "selectbox", Name: "MaritalStatus" },
                    { label: "Hostel Name ", value: 6, type: "textarea", Name: "HostelName" },
                    { label: "DESCRIPTION", value: 7, type: "textarea", Name: "Discription" },
                    { label: "Date ", value: 8, type: "date", Name: "Date" },
                ]
                this.setState({ NTSColumn })
            }
            if (this.state.counsellingOptSelect.value == "1" && this.state.usertypeOptSelect.value == "3" && value.value != "1") {
                let NTSColumn1 = [
                    { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                    { label: "Name", value: 2, type: "text", Name: "Name" },
                    { label: "Gender", value: 3, type: "selectbox", Name: "Gender" },
                    { label: "Age", value: 4, type: "number", Name: "Age" },
                    { label: "Marital Status", value: 5, type: "selectbox", Name: "MaritalStatus" },
                    { label: "DESCRIPTION", value: 6, type: "textarea", Name: "Discription" },
                    { label: "Date ", value: 7, type: "date", Name: "Date" },
                ]

                this.setState({ NTSColumn1 })
            }
            if (this.state.counsellingOptSelect.value == "1" && this.state.usertypeOptSelect.value == "3" && value.value == "1") {
                let NTSColumn = [
                    { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                    { label: "Name", value: 2, type: "text", Name: "Name" },
                    { label: "Gender", value: 3, type: "selectbox", Name: "Gender" },
                    { label: "Age", value: 4, type: "number", Name: "Age" },
                    { label: "Marital Status", value: 5, type: "selectbox", Name: "MaritalStatus" },
                    { label: "Hostel Name ", value: 6, type: "textarea", Name: "HostelName" },
                    { label: "DESCRIPTION", value: 7, type: "textarea", Name: "Discription" },
                    { label: "Date ", value: 8, type: "date", Name: "Date" },
                ]
                this.setState({ NTSColumn })
            }
        }
        if (rowIndex == "SelectSchool") {
            // console.log("enter");
            // console.log(this.state.counsellingArray, "counsellingArray");
            if (counsellingOptSelect.value == "1" && usertypeOptSelect.value == "1") {
                this.setState(prevState => ({
                    counsellingArray: prevState.counsellingArray.map(row => ({
                        ...row,
                        SchoolNameSelect: value
                    })),
                    SchoolNameSelect: value
                }));
            }
            else if (counsellingOptSelect.value == "2" && usertypeOptSelect.value == "1") {
                this.setState(prevState => ({
                    trainingArray: prevState.trainingArray.map(row => ({
                        ...row,
                        SchoolNameSelect: value
                    })),
                    SchoolNameSelect: value
                }));
            }
            else if (usertypeOptSelect.value == "2") {
                this.setState(prevState => ({
                    TSArray: prevState.TSArray.map(row => ({
                        ...row,
                        SchoolNameSelect: value
                    })),
                    SchoolNameSelect: value
                }));
            }
            else if (usertypeOptSelect.value == "3") {
                this.setState(prevState => ({
                    NTSArray: prevState.NTSArray.map(row => ({
                        ...row,
                        SchoolNameSelect: value
                    })),
                    SchoolNameSelect: value
                }));
            }

        }
        if (rowIndex == "SelectCompany") {
            // console.log("enter");
            // console.log(this.state.counsellingArray, "counsellingArray");
            if (entityOptSelect.value == "2") {
                // console.log("ente");
                this.setState(prevState => ({
                    CompanyArray: prevState.CompanyArray.map(row => ({
                        ...row,
                        CompanyNameSelect: value
                    })),
                    CompanyNameSelect: value
                }));
            }
        }
        // else if (rowIndex == "trnschool") {
        //     console.log("enter");
        //     console.log(this.state.trainingArray, "trainingArray");
        //     this.setState(prevState => ({
        //         trainingArray: prevState.trainingArray.map(row => ({
        //             ...row,
        //             SchoolNameSelect: value
        //         }))
        //     }));
        // }


    };

    handleSelectChange2 = async (value, rowIndex, columnLabel) => {
        let { adminId, usertypeOptSelect, counsellingOptSelect, entityOptSelect } = this.state
        if (value) {
            // this.setState({ disablefromdate: false })
            let selectedValue = "";
            value.map(values => (selectedValue += `${values.label},`));
            selectedValue = selectedValue.replace(/,\s*$/, "");

            let selectedValues = "";
            value.map(values => (selectedValues += `${values.value},`));
            selectedValues = selectedValues.replace(/,\s*$/, "");

            if (entityOptSelect && entityOptSelect.value == "1" && counsellingOptSelect && counsellingOptSelect.value && usertypeOptSelect && usertypeOptSelect.value) {
                if (counsellingOptSelect.value == "2" && usertypeOptSelect.value == "1") {
                    this.setState(prevState => ({
                        trainingArray: prevState.trainingArray.map((row, index) =>
                            index === rowIndex ? { ...row, [columnLabel + "Select"]: value, [columnLabel + "Selectd"]: selectedValue, [columnLabel + "Selectdval"]: selectedValues } : row
                        )
                    }));
                }
                if (counsellingOptSelect.value == "2" && usertypeOptSelect.value == "2") {
                    this.setState(prevState => ({
                        TSArray: prevState.TSArray.map((row, index) =>
                            index === rowIndex ? { ...row, [columnLabel + "Select"]: value, [columnLabel + "Selectd"]: selectedValue, [columnLabel + "Selectdval"]: selectedValues } : row
                        )
                    }));
                }
            }
            if (entityOptSelect && entityOptSelect.value == "2") {
                this.setState(prevState => ({
                    CompanyArray: prevState.CompanyArray.map((row, index) =>
                        index === rowIndex ? { ...row, [columnLabel + "Select"]: value, [columnLabel + "Selectd"]: selectedValue, [columnLabel + "Selectdval"]: selectedValues } : row
                    )
                }));
            }
        }
    };

    counsellingFormSubmit = async () => {
        let { counsellingArray, counsellingOptSelect, isEdit, editId, usertypeOptSelect, entityOptSelect } = this.state
        console.log(counsellingArray, "counsellingArray");
        let formData = []
        console.log(isEdit, "isEdit");
        let wait = counsellingArray.map((ival) => {
            let result = {}
            result.counsellingType = counsellingOptSelect.value;
            result.name = ival.Name;
            result.studentid = ival.StudentId;
            result.studentRank = ival.StudentRank;
            result.class = ival.ClassSelect.value;
            result.nameOfMentor = ival.MentorSelect.value;
            result.gender = ival.GenderSelect.label;
            result.department = ival.DepartmentSelect.value;
            // result.withParent = ival.WithparentSelect.label;
            result.dob = ival.DOB == "" ? "-" : moment(ival.DOB).format('DD-MM-YYYY');
            result.date = ival.Date;
            result.reason = ival.Reason;
            // result.customerId = ival.SchoolNameSelect.value;
            result.SCHOOLNAME = ival.SchoolNameSelect.value;
            result.HostelName = ival.HostelName;
            result.type = usertypeOptSelect.value;
            result.entityType = entityOptSelect.value;

            console.log(result, "result");
            formData.push(result);
        })
        Promise.all(wait)
        // console.log(formData, "formData");
        // return false
        let emptyarray = []
        let validate = formData.map((a, b) => {
            console.log(a, "a", b, "b")
            for (let key in a) {
                if (a.hasOwnProperty(key)) {
                    if ((a[key] === "" || a[key] === undefined) && (key !== "studentid" && key !== "studentRank" && key !== "HostelName" && key !== "dob")) {
                        emptyarray.push(`Fill the  ${key.toUpperCase()} in ${b + 1} row `)
                    }
                }
            }
        })
        console.log(emptyarray, "emptyaray")
        let uniquearray = emptyarray.filter((a, b) => emptyarray.indexOf(a) === b)
        console.log(uniquearray, "uniquearray")

        if (uniquearray && uniquearray.length > 0) {
            const number = uniquearray.toString()
            console.log(number, "number")
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `<ul id="err-msg" style="padding:"20px",text-align:"center">
                ${uniquearray.map((ival) => {
                    return (
                        `<li style="list-style-type: disclosure-closed;">${ival}</li>`
                    )
                })}
                </ul>`,
            });
        }
        else {
            try {
                if (isEdit) {
                    formData.map((a, b) => {
                        for (let key in a) {
                            if (a.hasOwnProperty(key)) {
                                if (key === "SCHOOLNAME") {
                                    a["customerId"] = a["SCHOOLNAME"];
                                    delete a["SCHOOLNAME"];
                                }
                            }
                        }
                    })
                    const result = await cmsContent.updateMaster("psychol_form", editId, formData[0])
                    if (result) {
                        window.scrollTo(0, 0);

                        this.setState({
                            counsellingArray: [],
                            userAddRow: "",
                            alertVisible: true,
                            badgeText: "Successfully Updated ",
                            badgeColor: "success"
                        })
                        setTimeout(() => {
                            this.setState({ alertVisible: false })
                            window.location.reload()
                        }, 2000);

                    }

                } else {
                    formData.map((a, b) => {
                        for (let key in a) {
                            if (a.hasOwnProperty(key)) {
                                if (key === "SCHOOLNAME") {
                                    a["customerId"] = a["SCHOOLNAME"];
                                    delete a["SCHOOLNAME"];
                                }
                            }
                        }
                    })

                    const result = await cmsContent.InsertMultydata("psychol_form", formData);
                    console.log(result, "result");
                    if (result) {
                        window.scrollTo(0, 0);

                        this.setState({
                            counsellingArray: [],
                            userAddRow: "",
                            alertVisible: true,
                            badgeText: "Successfully Submitted",
                            badgeColor: "success"
                        })
                        setTimeout(() => {
                            this.setState({ alertVisible: false })
                            window.location.reload()
                        }, 2000);

                    }
                }

            } catch (error) {

            }
        }
    }

    tainingFormSubmit = async () => {
        let { trainingArray, counsellingOptSelect, usertypeOptSelect, isEdit, editId, entityOptSelect } = this.state
        console.log(trainingArray, "trainingArray", editId, "editId");
        let formData = []
        let wait = trainingArray.map((ival) => {
            let result = {}
            result.counsellingType = counsellingOptSelect.value;
            result.name = ival.Name;
            result.studentid = ival.StudentId;
            // result.department = ival.DepartmentSelect.value;
            result.department = ival.DepartmentSelectdval
            result.discription = ival.Discription;
            result.date = ival.Date;
            result.reason = ival.Reason;
            result.topic = ival.TopicSelectd;
            // result.customerId = ival.SchoolNameSelect.value;
            result.SCHOOLNAME = ival.SchoolNameSelect.value;
            result.type = usertypeOptSelect.value;
            result.entityType = entityOptSelect.value;

            console.log(result, "result");

            //   result.index = ival.index;
            formData.push(result);
        })
        Promise.all(wait)
        console.log(formData, "formData");
        // return false
        let emptyarray = []
        let validate = formData.map((a, b) => {
            console.log(a, "a", b, "b")
            for (let key in a) {
                if (a.hasOwnProperty(key)) {
                    console.log(key, "key");
                    if ((a[key] === "" || a[key] === undefined) && (key !== "studentid" && key !== "name" && key !== "reason")) {
                        emptyarray.push(`Fill the  ${key.toUpperCase()} in ${b + 1} row `)
                    }
                }
            }
        })
        console.log(emptyarray)
        let uniquearray = emptyarray.filter((a, b) => emptyarray.indexOf(a) === b)
        console.log(uniquearray, "uniquearray")
        if (uniquearray && uniquearray.length > 0) {
            const number = uniquearray.toString()
            console.log(number, "number")
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `<ul id="err-msg" style="padding:"20px",text-align:"center">
                ${uniquearray.map((ival) => {
                    return (
                        `<li style="list-style-type: disclosure-closed;">${ival}</li>`
                    )
                })}
                </ul>`,
            });
        }
        else {
            console.log("enter2 1");
            try {
                console.log("enter2 2");
                console.log(formData[0], "formData");
                if (isEdit) {
                    formData.map((a, b) => {
                        for (let key in a) {
                            if (a.hasOwnProperty(key)) {
                                if (key === "SCHOOLNAME") {
                                    a["customerId"] = a["SCHOOLNAME"];
                                    delete a["SCHOOLNAME"];
                                }
                            }
                        }
                    })
                    const result = await cmsContent.updateMaster("psychol_form", editId, formData[0])
                    console.log(result, "result");
                    if (result) {
                        window.scrollTo(0, 0);

                        this.setState({
                            trainingArray: [],
                            userAddRow: "",
                            alertVisible: true,
                            badgeText: "Successfully Updated ",
                            badgeColor: "success"
                        })
                        setTimeout(() => {
                            this.setState({ alertVisible: false })
                            window.location.reload()
                        }, 2000);

                    }

                } else {
                    formData.map((a, b) => {
                        for (let key in a) {
                            if (a.hasOwnProperty(key)) {
                                if (key === "SCHOOLNAME") {
                                    a["customerId"] = a["SCHOOLNAME"];
                                    delete a["SCHOOLNAME"];
                                }
                            }
                        }
                    })
                    const result = await cmsContent.InsertMultydata("psychol_form", formData);
                    console.log(result, "result");
                    if (result) {
                        window.scrollTo(0, 0);

                        this.setState({
                            trainingArray: [],
                            userAddRow: "",
                            alertVisible: true,
                            badgeText: "Successfully Submitted",
                            badgeColor: "success"
                        })
                        setTimeout(() => {
                            this.setState({ alertVisible: false })
                            window.location.reload()
                        }, 2000);
                    }
                }

            } catch (error) {

            }
        }
    }

    TSFormSubmit = async () => {
        let { TSArray, counsellingOptSelect, usertypeOptSelect, isEdit, editId, entityOptSelect } = this.state
        console.log(TSArray, "TSArray",counsellingOptSelect,);
        let formData = []
        let wait = TSArray.map((ival) => {
            console.log(ival, "ival")
            let result = {}
            result.counsellingType = counsellingOptSelect.value;
            result.name = ival.Name;
            result.Age = ival.Age;
            result.department = isEdit||counsellingOptSelect.value=="2" ? ival.DepartmentSelectdval : ival.DepartmentSelect.value;
            result.gender = ival.GenderSelect ? ival.GenderSelect.label : null;
            result.class = ival.ClassSelect ? ival.ClassSelect.value : null;
            result.discription = ival.Discription;
            result.MaritalStatus = ival.MaritalStatusSelect ? ival.MaritalStatusSelect.label : null;
            // result.customerId = ival.SchoolNameSelect.value;
            result.SCHOOLNAME = ival.SchoolNameSelect.value;
            result.date = ival.Date;
            result.Subject = ival.subject!="" ?ival.Subject:"";
            result.type = usertypeOptSelect.value;
            result.entityType = entityOptSelect.value;

            // console.log(result, "result");

            //   result.index = ival.index;
            formData.push(result);
        })
        Promise.all(wait)
        console.log(formData, "formData");
        // return false

        let emptyarray = []
        let validate = formData.map((a, b) => {
            console.log(a, "a", b, "b")
            for (let key in a) {
                if (a.hasOwnProperty(key)) {
                    console.log(key, "key");
                    if ((a[key] === "" || a[key] === undefined) && (key !== "name" && key !== "Age" && key !== "gender" && key !== "Subject" && key !== "MaritalStatus" && key !== "class")) {
                        emptyarray.push(`Fill the  ${key.toUpperCase()} in ${b + 1} row `)
                    }
                }
            }
        })
        console.log(emptyarray)
        let uniquearray = emptyarray.filter((a, b) => emptyarray.indexOf(a) === b)
        // console.log(uniquearray, "uniquearray")
        if (uniquearray && uniquearray.length > 0) {
            const number = uniquearray.toString()
            console.log(number, "number")
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `<ul id="err-msg" style="padding:"20px",text-align:"center">
                ${uniquearray.map((ival) => {
                    return (
                        `<li style="list-style-type: disclosure-closed;">${ival}</li>`
                    )
                })}
                </ul>`,
            });
        }
        else {
            // return false

            try {
                if (isEdit) {
                    formData.map((a, b) => {
                        for (let key in a) {
                            if (a.hasOwnProperty(key)) {
                                if (key === "SCHOOLNAME") {
                                    a["customerId"] = a["SCHOOLNAME"];
                                    delete a["SCHOOLNAME"];
                                }
                            }
                        }
                    })
                    const result = await cmsContent.updateMaster("psychol_form", editId, formData[0])
                    if (result) {
                        window.scrollTo(0, 0);

                        this.setState({
                            TSArray: [],
                            userAddRow: "",
                            alertVisible: true,
                            badgeText: "Successfully Updated ",
                            badgeColor: "success"
                        })
                        setTimeout(() => {
                            this.setState({ alertVisible: false })
                            window.location.reload()
                        }, 2000);

                    }

                } else {
                    formData.map((a, b) => {
                        for (let key in a) {
                            if (a.hasOwnProperty(key)) {
                                if (key === "SCHOOLNAME") {
                                    a["customerId"] = a["SCHOOLNAME"];
                                    delete a["SCHOOLNAME"];
                                }
                            }
                        }
                    })
                    const result = await cmsContent.InsertMultydata("psychol_form", formData);
                    console.log(result, "result");
                    if (result) {
                        window.scrollTo(0, 0);

                        this.setState({
                            TSArray: [],
                            userAddRow: "",
                            alertVisible: true,
                            badgeText: "Successfully Submitted",
                            badgeColor: "success"
                        })
                        setTimeout(() => {
                            this.setState({ alertVisible: false })
                            window.location.reload()
                        }, 2000);
                    }
                }

            } catch (error) {

            }
        }
    }

    NTSFormSubmit = async () => {
        let { NTSArray, NTSOptSelect, counsellingOptSelect, usertypeOptSelect, isEdit, editId, entityOptSelect } = this.state
        let formData = []
        let wait = NTSArray.map((ival) => {
            let result = {}
            result.counsellingType = counsellingOptSelect.value;
            result.name = ival.Name;
            result.Age = ival.Age;
            result.gender = ival.GenderSelect ? ival.GenderSelect.label : null;
            result.MaritalStatus = ival.MaritalStatusSelect ? ival.MaritalStatusSelect.label : null;
            result.HostelName = ival.HostelName;
            result.discription = ival.Discription;
            // result.customerId = ival.SchoolNameSelect.value;
            result.SCHOOLNAME = ival.SchoolNameSelect.value;
            result.date = ival.Date;
            result.NTS = NTSOptSelect.value;
            result.type = usertypeOptSelect.value;
            result.entityType = entityOptSelect.value;

            // console.log(result, "result");

            //   result.index = ival.index;
            formData.push(result);
        })
        Promise.all(wait)
        // console.log(formData, "formData");
        let emptyarray = []
        let validate = formData.map((a, b) => {
            console.log(a, "a", b, "b")
            for (let key in a) {
                if (a.hasOwnProperty(key)) {
                    console.log(key, "keys");
                    if ((a[key] === "" || a[key] === undefined) && (key !== "name" && key !== "Age" && key !== "gender" && key !== "HostelName" && key !== "MaritalStatus" && key !== "HostelName")) {
                        emptyarray.push(`Fill the  ${key.toUpperCase()} in ${b + 1} row `)
                    }
                }
            }
        })
        console.log(emptyarray)
        let uniquearray = emptyarray.filter((a, b) => emptyarray.indexOf(a) === b)
        // console.log(uniquearray, "uniquearray")
        if (uniquearray && uniquearray.length > 0) {
            const number = uniquearray.toString()
            console.log(number, "number")
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `<ul id="err-msg" style="padding:"20px",text-align:"center">
                ${uniquearray.map((ival) => {
                    return (
                        `<li style="list-style-type: disclosure-closed;">${ival}</li>`
                    )
                })}
                </ul>`,
            });
        }
        else {
            // return false

            try {
                if (isEdit) {
                    console.log("enter here");
                    formData.map((a, b) => {
                        for (let key in a) {
                            if (a.hasOwnProperty(key)) {
                                if (key === "SCHOOLNAME") {
                                    a["customerId"] = a["SCHOOLNAME"];
                                    delete a["SCHOOLNAME"];
                                }
                            }
                        }
                    })
                    const result = await cmsContent.updateMaster("psychol_form", editId, formData[0])
                    console.log(result, "result");
                    if (result) {
                        window.scrollTo(0, 0);

                        this.setState({
                            TSArray: [],
                            userAddRow: "",
                            alertVisible: true,
                            badgeText: "Successfully Updated ",
                            badgeColor: "success"
                        })
                        setTimeout(() => {
                            this.setState({ alertVisible: false })
                            window.location.reload()
                        }, 2000);

                    }

                } else {
                    formData.map((a, b) => {
                        for (let key in a) {
                            if (a.hasOwnProperty(key)) {
                                if (key === "SCHOOLNAME") {
                                    a["customerId"] = a["SCHOOLNAME"];
                                    delete a["SCHOOLNAME"];
                                }
                            }
                        }
                    })
                    const result = await cmsContent.InsertMultydata("psychol_form", formData);
                    console.log(result, "result");
                    if (result) {
                        window.scrollTo(0, 0);

                        this.setState({
                            NTSArray: [],
                            userAddRow: "",
                            alertVisible: true,
                            badgeText: "Successfully Submitted",
                            badgeColor: "success"
                        })
                        setTimeout(() => {
                            this.setState({ alertVisible: false })
                            window.location.reload()
                        }, 2000);
                    }
                }

            } catch (error) {

            }
        }
    }

    CompanyFormSubmit = async () => {
        console.log("enter ehe");
        let { CompanyArray, NTSOptSelect, counsellingOptSelect, usertypeOptSelect, isEdit, editId, entityOptSelect } = this.state
        let formData = []
        console.log(CompanyArray, "CompanyArray");
        console.log(entityOptSelect, entityOptSelect);

        // return false

        let wait = CompanyArray.map((ival) => {
            let result = {}
            // result.customerId = ival.CompanyNameSelect.value;
            result.COMPANYNAME = ival.CompanyNameSelect.value;
            result.name = ival.Name;
            result.employeeId = ival.EmployeeId;
            result.gender = ival.GenderSelect.label;
            result.mobileNumber = ival.MobileNumber;
            result.department = ival.DepartmentSelectdval;
            result.discription = ival.Description;
            result.reason = ival.Reason;
            result.designation = ival.DesignationSelect.value;
            result.date = ival.Date;
            result.entityType = entityOptSelect.value;

            //   result.index = ival.index;
            console.log(result, "result");
            formData.push(result);
        })
        Promise.all(wait)
        // console.log(formData, "formData");
        let emptyarray = []
        let validate = formData.map((a, b) => {
            console.log(a, "a", b, "b")
            for (let key in a) {
                if (a.hasOwnProperty(key)) {
                    console.log(key, "keys", a[key], "a[key]");
                    if ((a[key] === "" || a[key] === undefined) && (key !== "name" && key !== "gender" && key !== "employeeId" && key !== "mobileNumber")) {
                        emptyarray.push(`Fill the  ${key.toUpperCase()} in ${b + 1} row `)
                    }
                }
            }
        })
        console.log(emptyarray)
        let uniquearray = emptyarray.filter((a, b) => emptyarray.indexOf(a) === b)
        // console.log(uniquearray, "uniquearray")
        if (uniquearray && uniquearray.length > 0) {
            const number = uniquearray.toString()
            console.log(number, "number")
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `<ul id="err-msg" style="padding:"20px",text-align:"center">
                ${uniquearray.map((ival) => {
                    return (
                        `<li style="list-style-type: disclosure-closed;">${ival}</li>`
                    )
                })}
                </ul>`,
            });
        }
        else {
            // return false

            try {
                if (isEdit) {
                    console.log("enter here");
                    formData.map((a, b) => {
                        for (let key in a) {
                            if (a.hasOwnProperty(key)) {
                                if (key === "COMPANYNAME") {
                                    a["customerId"] = a["COMPANYNAME"];
                                    delete a["COMPANYNAME"];
                                }
                            }
                        }
                    })
                    const result = await cmsContent.updateMaster("psychol_form", editId, formData[0])
                    console.log(result, "result");
                    if (result) {
                        window.scrollTo(0, 0);

                        this.setState({
                            TSArray: [],
                            userAddRow: "",
                            alertVisible: true,
                            badgeText: "Successfully Updated ",
                            badgeColor: "success"
                        })
                        setTimeout(() => {
                            this.setState({ alertVisible: false })
                            window.location.reload()
                        }, 2000);

                    }

                } else {
                    formData.map((a, b) => {
                        for (let key in a) {
                            if (a.hasOwnProperty(key)) {
                                if (key === "COMPANYNAME") {
                                    a["customerId"] = a["COMPANYNAME"];
                                    delete a["COMPANYNAME"];
                                }
                            }
                        }
                    })
                    const result = await cmsContent.InsertMultydata("psychol_form", formData);
                    console.log(result, "result");
                    if (result) {
                        window.scrollTo(0, 0);

                        this.setState({
                            NTSArray: [],
                            userAddRow: "",
                            alertVisible: true,
                            badgeText: "Successfully Submitted",
                            badgeColor: "success"
                        })
                        setTimeout(() => {
                            this.setState({ alertVisible: false })
                            window.location.reload()
                        }, 2000);
                    }
                }

            } catch (error) {

            }
        }
    }

    reset = async () => {
        await this.setState({
            errorempname: '', errorCode: '', emp_id: "", emp_name: "", password: '', ansgender: "",
            ansgender: "", ansBusinessUnit: "", ansFunction: "", ansSubFunction: "",
            ansRegion: "", ansDivision: "", ansBranch: "",
            ansoffEmailAdd: "", ansGrade: "", ansBand: "",
            badgeText: false, badgeColor: false, alertVisible: false,
            ansEmpCatType: "", ansL1ManCode: "",
            ansL1Man: "", ansL2ManCode: "", ansL2Man: "", ansHRManCode: "", ansHRMan: "", ansBusiDesignation: "",
            errgender: "", errBusinessUnit: "", errFunction: "", errSubFunction: "", errRegion: "", errDivision: "", errBranch: "", erroffEmailAdd: "", errGrade: "", errBand: "", errEmpCatType: "", errL1ManCode: "",
            errL1Man: "", errL2ManCode: "", errL2Man: "", errHRManCode: "", errHRMan: "", errBusiDesignation: "",
            errorpassword: '', editId: false, userid: false, isEdit: false, alertVisible: false,
        })
    }

    deleteColumn = (a, b, c, d) => {
        console.log(a, "a", b, "b", c, "c", d, "d")
        const { counsellingArray, trainingArray, TSArray, NTSArray, CompanyArray } = this.state
        if (d == "2") {
            console.log("success")
            const filteredarray = CompanyArray.filter((ival, index) => {
                return b !== index
            })
            this.setState({ CompanyArray: filteredarray })
        }
        if (c == "1" && d == "1") {
            console.log("success")
            const filteredarray = counsellingArray.filter((ival, index) => {
                return b !== index
            })
            this.setState({ counsellingArray: filteredarray })
        }
        if (c == "2" && d == "1") {
            console.log("success")
            const filteredarray = trainingArray.filter((ival, index) => {
                return b !== index
            })
            this.setState({ trainingArray: filteredarray })
        }
        if (c == "3" && d == "1") {
            console.log("success")
            const filteredarray = TSArray.filter((ival, index) => {
                return b !== index
            })
            this.setState({ TSArray: filteredarray })
        }
        if (c == "4" && d == "1") {
            console.log("success")
            const filteredarray = NTSArray.filter((ival, index) => {
                return b !== index
            })
            console.log(filteredarray, "filteredarray")
            this.setState({ NTSArray: filteredarray })
        }
    }


    convertToSheetData = (headers, data) => {
        // Extract labels from headers
        const labels = headers.map(header => header.label);

        // Extract data based on keys
        const sheetData = data.map(item => {
            return headers.map(header => item[header.key]);
        });

        // Add labels as the first row
        sheetData.unshift(labels);

        return sheetData;
    };

    exportToExcel = () => {
        // const { sheetData } = this.state;
        const { CounsellingData, TrainingData, TSData, NTSData } = this.state;
        // console.log(CounsellingData, TrainingData, TSData, NTSData, "CounsellingData, TrainingData, TSData, NTSData");

        const sheetData = [
            {
                sheetName: '1 to 1 Counselling Form',
                headers: [
                    {
                        label: "SCHOOL NAME ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "CLASS",
                        key: "className",
                    },
                    {
                        label: "DEPARTMENT",
                        key: "departmentName",
                    },
                    {
                        label: "NAME OF THE MENTOR",
                        key: "nameOfMentorName",
                    },
                    {
                        label: "REASON OF COUNSELLING",
                        key: "reason",
                    },
                    {
                        label: "DATE OF COUNSELLING",
                        key: "date",
                    },
                    {
                        label: "HOSTEL NAME",
                        key: "HostelName",
                    },
                ],
                data: CounsellingData || []
            },
            {
                sheetName: 'Group Counseling-Training Form',
                headers: [
                    {
                        label: "SCHOOL NAME ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "TOPIC",
                        key: "topic",
                    },
                    {
                        label: "DEPARTMENT",
                        key: "departmentName",
                    },
                    {
                        label: "DESCRIPTION",
                        key: "discription",
                    },
                    {
                        label: "REASON OF COUNSELLING",
                        key: "reason",
                    },
                    {
                        label: "DATE OF COUNSELLING",
                        key: "date",
                    },
                ],
                data: TrainingData || []
            },
            {
                sheetName: 'Teaching Staff',
                headers: [
                    {
                        label: "School Name ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "AGE",
                        key: "Age",
                    },
                    {
                        label: "DEPARTMENT",
                        key: "departmentName",
                    },
                    {
                        label: "CLASS",
                        key: "className",
                    },
                    {
                        label: "GENDER",
                        key: "gender",
                    },
                    {
                        label: "MARITAL STATUS",
                        key: "MaritalStatus",
                    },
                    {
                        label: "DESCRIPTION",
                        key: "discription",
                    },
                    {
                        label: "SUBJECT",
                        key: "Subject",
                    },
                    {
                        label: "DATE",
                        key: "date",
                    },
                ],
                data: TSData || []
            },
            {
                sheetName: 'Non-Teaching Staff',
                headers: [
                    {
                        label: "SCHOOL NAME ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "AGE",
                        key: "Age",
                    },
                    {
                        label: "GENDER",
                        key: "gender",
                    },
                    {
                        label: "MARITAL STATUS",
                        key: "MaritalStatus",
                    },
                    {
                        label: "HOSTEL NAME",
                        key: "HostelName",
                    },
                    {
                        label: "DESCRIPTION",
                        key: "discription",
                    },
                    {
                        label: "NTS",
                        key: "NTSname",
                    },
                    {
                        label: "DATE",
                        key: "date",
                    },
                ],
                data: NTSData || []
            }
        ];

        // Create a new workbook
        const sheetsToExport = sheetData.filter(sheet => sheet.data.length > 0);

        const workbook = XLSX.utils.book_new();

        sheetsToExport.forEach(sheet => {
            const worksheetData = this.convertToSheetData(sheet.headers, sheet.data);
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            // XLSX.utils.book_append_sheet(workbook, worksheet, sheet.sheetName);

            // Set column widths (10 characters wide)
            const wscols = sheet.headers.map(header => ({ wch: 20 }));
            worksheet['!cols'] = wscols;

            // Set row heights (10 pixels high)
            worksheet['!rows'] = sheet.data.map(() => ({ hpx: 20 }));

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, sheet.sheetName);

        });

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'ConsolidateForms.xlsx');
    };

    exportToExcelAndSendEmail = async () => {
        console.log("enteree");
        const { CounsellingData, TrainingData, TSData, NTSData, emailSelectedValues } = this.state;

        const sheetData = [
            {
                sheetName: '1 to 1 Counselling Form',
                headers: [
                    {
                        label: "SCHOOL NAME ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "CLASS",
                        key: "className",
                    },
                    {
                        label: "DEPARTMENT",
                        key: "departmentName",
                    },
                    {
                        label: "NAME OF THE MENTOR",
                        key: "nameOfMentorName",
                    },
                    {
                        label: "REASON OF COUNSELLING",
                        key: "reason",
                    },
                    {
                        label: "DATE OF COUNSELLING",
                        key: "date",
                    },
                    {
                        label: "HOSTEL NAME",
                        key: "HostelName",
                    },
                ],
                data: CounsellingData || []
            },
            {
                sheetName: 'Group Counseling-Training Form',
                headers: [
                    {
                        label: "SCHOOL NAME ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "TOPIC",
                        key: "topic",
                    },
                    {
                        label: "DEPARTMENT",
                        key: "departmentName",
                    },
                    {
                        label: "DESCRIPTION",
                        key: "discription",
                    },
                    {
                        label: "REASON OF COUNSELLING",
                        key: "reason",
                    },
                    {
                        label: "DATE OF COUNSELLING",
                        key: "date",
                    },
                ],
                data: TrainingData || []
            },
            {
                sheetName: 'Teaching Staff',
                headers: [
                    {
                        label: "School Name ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "AGE",
                        key: "Age",
                    },
                    {
                        label: "DEPARTMENT",
                        key: "departmentName",
                    },
                    {
                        label: "CLASS",
                        key: "className",
                    },
                    {
                        label: "GENDER",
                        key: "gender",
                    },
                    {
                        label: "MARITAL STATUS",
                        key: "MaritalStatus",
                    },
                    {
                        label: "DESCRIPTION",
                        key: "discription",
                    },
                    {
                        label: "SUBJECT",
                        key: "Subject",
                    },
                    {
                        label: "DATE",
                        key: "date",
                    },
                ],
                data: TSData || []
            },
            {
                sheetName: 'Non-Teaching Staff',
                headers: [
                    {
                        label: "SCHOOL NAME ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "AGE",
                        key: "Age",
                    },
                    {
                        label: "GENDER",
                        key: "gender",
                    },
                    {
                        label: "MARITAL STATUS",
                        key: "MaritalStatus",
                    },
                    {
                        label: "HOSTEL NAME",
                        key: "HostelName",
                    },
                    {
                        label: "DESCRIPTION",
                        key: "discription",
                    },
                    {
                        label: "NTS",
                        key: "NTSname",
                    },
                    {
                        label: "DATE",
                        key: "date",
                    },
                ],
                data: NTSData || []
            }
        ];

        const sheetsToExport = sheetData.filter(sheet => sheet.data.length > 0);
        const workbook = XLSX.utils.book_new();
        sheetsToExport.forEach(sheet => {
            const worksheetData = this.convertToSheetData(sheet.headers, sheet.data);
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            XLSX.utils.book_append_sheet(workbook, worksheet, sheet.sheetName);
        });
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        // saveAs(blob, 'ConsolidateReport.xlsx');
        // Send email with attachment
        try {
            const formData = new FormData();
            formData.append('email', emailSelectedValues);
            formData.append('file', blob, 'ConsolidateReport.xlsx');

            const submit = await cmsContent.emailexcel(formData)

            if (submit) {
                this.setState({
                    alertVisible: true,
                    emailSelectedOptions: "",
                })
                setTimeout(() => {
                    this.setState({ alertVisible: false })
                }, 3000);
            }
            // console.log('Email sent successfully:', submit.data);
            // alert('Excel sheet sent to ' + emailSelectedValues);
            // this.setState({ emailSelectedOptions: "" })
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to send Excel sheet via email. Please try again.');
            this.setState({ emailSelectedOptions: "" })
        }
    };

    // modalhandlechange = (e) => {
    //     this.setState({ modalInput: e.target.value, error: "" })
    // }
    optionSelect2 = async (selectedOption) => {
        if (selectedOption) {
            // this.setState({ disablefromdate: false })
            let selectedValue = "";
            selectedOption.map(values => (selectedValue += `${values.label},`));
            selectedValue = selectedValue.replace(/,\s*$/, "");
            await this.setState({
                emailSelectedOptions: selectedOption,
                emailSelectedValues: selectedValue
            });
        }
    };

    emailExcelSend = async () => {
        const { emailSelectedValues, emailSingleExcelData, emailSingleExcelvalue } = this.state;
        console.log(emailSingleExcelData, "emailSingleExcelData");
        console.log(emailSingleExcelvalue, "emailSingleExcelvalue");
        console.log(emailSelectedValues, "emailSelectedValues");
        let sheetData;
        if (emailSingleExcelvalue == "1") {

            sheetData = [
                {
                    sheetName: '1 to 1 Counselling Form',
                    headers: [
                        {
                            label: "SCHOOL NAME ",
                            key: "customerId",
                        },
                        {
                            label: "NAME",
                            key: "name",
                        },
                        {
                            label: "CLASS",
                            key: "className",
                        },
                        {
                            label: "DEPARTMENT",
                            key: "departmentName",
                        },
                        {
                            label: "NAME OF THE MENTOR",
                            key: "nameOfMentorName",
                        },
                        {
                            label: "REASON OF COUNSELLING",
                            key: "reason",
                        },
                        {
                            label: "DATE OF COUNSELLING",
                            key: "date",
                        },
                        {
                            label: "HOSTEL NAME",
                            key: "HostelName",
                        },
                    ],
                    data: emailSingleExcelData || []
                },
            ];
        } else if (emailSingleExcelvalue == "2") {

            sheetData = [
                {
                    sheetName: 'Group Counseling-Training Form',
                    headers: [
                        {
                            label: "SCHOOL NAME ",
                            key: "customerId",
                        },
                        {
                            label: "NAME",
                            key: "name",
                        },
                        {
                            label: "TOPIC",
                            key: "topic",
                        },
                        {
                            label: "DEPARTMENT",
                            key: "departmentName",
                        },
                        {
                            label: "DESCRIPTION",
                            key: "discription",
                        },
                        {
                            label: "REASON OF COUNSELLING",
                            key: "reason",
                        },
                        {
                            label: "DATE OF COUNSELLING",
                            key: "date",
                        },
                    ],
                    data: emailSingleExcelData || []
                },
            ]
        } else if (emailSingleExcelvalue == "3") {

            sheetData = [
                {
                    sheetName: 'Teaching Staff',
                    headers: [
                        {
                            label: "School Name ",
                            key: "customerId",
                        },
                        {
                            label: "NAME",
                            key: "name",
                        },
                        {
                            label: "AGE",
                            key: "Age",
                        },
                        {
                            label: "DEPARTMENT",
                            key: "departmentName",
                        },
                        {
                            label: "CLASS",
                            key: "className",
                        },
                        {
                            label: "GENDER",
                            key: "gender",
                        },
                        {
                            label: "MARITAL STATUS",
                            key: "MaritalStatus",
                        },
                        {
                            label: "DESCRIPTION",
                            key: "discription",
                        },
                        {
                            label: "SUBJECT",
                            key: "Subject",
                        },
                        {
                            label: "DATE",
                            key: "date",
                        },
                    ],
                    data: emailSingleExcelData || []
                },
            ]
        } else if (emailSingleExcelvalue == "4") {

            sheetData = [
                {
                    sheetName: 'Non-Teaching Staff',
                    headers: [
                        {
                            label: "SCHOOL NAME ",
                            key: "customerId",
                        },
                        {
                            label: "NAME",
                            key: "name",
                        },
                        {
                            label: "AGE",
                            key: "Age",
                        },
                        {
                            label: "GENDER",
                            key: "gender",
                        },
                        {
                            label: "MARITAL STATUS",
                            key: "MaritalStatus",
                        },
                        {
                            label: "HOSTEL NAME",
                            key: "HostelName",
                        },
                        {
                            label: "DESCRIPTION",
                            key: "discription",
                        },
                        {
                            label: "NTS",
                            key: "NTSname",
                        },
                        {
                            label: "DATE",
                            key: "date",
                        },
                    ],
                    data: emailSingleExcelData || []
                }
            ]
        }
        else if (emailSingleExcelvalue == "5") {

            sheetData = [
                {
                    sheetName: 'Non-Teaching Staff',
                    headers: [
                        {
                            label: "COMPANY NAME ",
                            key: "userName",
                        },
                        {
                            label: "EMPLOYEEID",
                            key: "employeeId",
                        },
                        {
                            label: "NAME",
                            key: "name",
                        },
                        {
                            label: "MOBILE NUMBER",
                            key: "mobileNumber",
                        },
                        {
                            label: "DESIGNATION",
                            key: "designationName",
                        },
                        {
                            label: "GENDER",
                            key: "gender",
                        },
                        {
                            label: "DESCRIPTION",
                            key: "discription",
                        },

                        {
                            label: "DATE",
                            key: "date",

                        }
                    ],
                    data: emailSingleExcelData || []
                }
            ]
        }

        const sheetsToExport = sheetData.filter(sheet => sheet.data.length > 0);
        const workbook = XLSX.utils.book_new();
        sheetsToExport.forEach(sheet => {
            const worksheetData = this.convertToSheetData(sheet.headers, sheet.data);
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            XLSX.utils.book_append_sheet(workbook, worksheet, sheet.sheetName);
        });
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });


        try {
            const formData = new FormData();
            formData.append('email', emailSelectedValues);
            formData.append('file', blob, 'ExcelReport.xlsx');
            const submit = await cmsContent.emailexcel(formData)

            console.log('Email sent successfully:', submit.data);
            if (submit) {
                this.setState({
                    alertVisible: true,
                    emailSelectedOptions: "",
                })
                setTimeout(() => {
                    this.setState({ alertVisible: false })
                }, 3000);
                // this.MainFun()
            }
            // alert('Excel sheet sent to ' + emailSelectedValues);
            // this.setState({ emailSelectedOptions: "" })
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to send Excel sheet via email. Please try again.');
            this.setState({ emailSelectedOptions: "" })
        }


    }
    emailSingleExcel = async (data, value) => {
        this.setState({ emailSend: true, emailSingleExcelData: data, emailSingleExcelvalue: value })
    }

    back = () => {
        if (this.state.isEdit) {
            console.log("back")
            this.setState({
                FormType: 0, userAddRow: "",
                counsellingArray: [],
                trainingArray: [],
                TSArray: [],
                NTSArray: [],
                CompanyArray: [],
                userAddRow: "",
                RowCount: "",
                entityOptSelect: "",
                counsellingOptSelect: "",
                usertypeOptSelect: "",
                NTSOptSelect: "",
                SchoolNameSelect: "",
                companyNameSelect: "",
                isEdit: false,
            })
        }
        else {
            this.setState({ FormType: 0 })
        }
        // this.setState({FormType:0,userAddRow:0})
    }
    render() {
        const {
            badgeColor,
            badgeText,
            textalert,
            color,
            MOlist, //marketing off
            Saleslist, alertVisible, added, notadded, loading,
            addCounsellingColumn, addTrainingColumn, counsellingArray, userAddRow, trainingArray, TrainingData,
            SchoolOptions, schoolSelect, CounsellingData, usertypeOptSelect, TSColumn, TSColumn1, NTSColumn, NTSArray, TSArray,
            NTSOptSelect, NTSColumn1, counsellingOptSelect, entityOpt, entityOptSelect, CompanyOptions, CompanyArray, CompanyFormColumn, CompanyData
        } = this.state;
        // console.log(NTSArray, "NTSArray");
        // console.log(counsellingArray, "counsellingArray");
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3> Form </h3>
                                    </div>
                                    <br></br>
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-7">
                                            <Alert
                                                className="badge-content"
                                                color={badgeColor}
                                                isOpen={this.state.alertVisible}
                                                toggle={this.onDismiss}
                                            >
                                                {badgeText && badgeText}
                                            </Alert>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <br></br>
                                    {/* <div className="row form-group">
                                        <div className="col-md-3">
                                            <button className='btn btn-primary' style={{ marginTop: "-15px" }} onClick={() => this.setState({ multyIndicatorsAdd: false, showDatatable: false })}>Training Form</button>
                                        </div>
                                        <div className="col-md-3" />
                                    </div> */}

                                    {this.state.FormType == 1 ?
                                        <>
                                            <>
                                                <div className="row form-group mx-2">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-4">
                                                        <button className='btn btn-primary' onClick={() => this.back()}>Back</button>
                                                    </div>
                                                </div>
                                                <br></br>

                                                <>
                                                    <div className="row form-group mx-2">
                                                        <div className="col-sm-3" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="indicators">Entity Type</label>
                                                        </div>
                                                        <div className="col-sm-3 ">
                                                            <SingleSelect
                                                                handleChange={(d) => this.handleSelectChange(d, "entityForm")}
                                                                options={this.state.entityOpt}
                                                                selectedService={this.state.entityOptSelect}
                                                            // disabled={jval.value == 9 && is_managerSelect.value==1}
                                                            />                                                        </div>
                                                        <div className="col-sm-2" />
                                                    </div>
                                                    {
                                                        entityOptSelect && entityOptSelect.value == "1" &&
                                                        <>
                                                            <div className="row form-group mx-2">
                                                                <div className="col-sm-3" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="indicators">counseling Form</label>
                                                                </div>
                                                                <div className="col-sm-3 ">
                                                                    <SingleSelect
                                                                        handleChange={(d) => this.handleSelectChange(d, "counsellingForm")}
                                                                        options={this.state.counsellingOptions}
                                                                        selectedService={this.state.counsellingOptSelect}
                                                                    // disabled={jval.value == 9 && is_managerSelect.value==1}
                                                                    />                                                        </div>
                                                                <div className="col-sm-2" />
                                                            </div>
                                                            <div className="row form-group mx-2">
                                                                <div className="col-sm-3" />
                                                                <div className="col-sm-2">
                                                                    <label htmlFor="indicators">Usertype Form</label>
                                                                </div>
                                                                <div className="col-sm-3 ">
                                                                    <SingleSelect
                                                                        handleChange={(d) => this.handleSelectChange(d, "UsertypeForm")}
                                                                        options={this.state.usertypeOptions}
                                                                        selectedService={this.state.usertypeOptSelect}
                                                                    // disabled={jval.value == 9 && is_managerSelect.value==1}
                                                                    />                                                        </div>
                                                                <div className="col-sm-2" />
                                                            </div>
                                                            {usertypeOptSelect && usertypeOptSelect.value == "3" ?
                                                                <div className="row form-group mx-2">
                                                                    <div className="col-sm-3" />
                                                                    <div className="col-sm-2">
                                                                        <label htmlFor="indicators">Non Teaching Staff</label>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <SingleSelect
                                                                            handleChange={(d) => this.handleSelectChange(d, "NonTeachingStaff")}
                                                                            options={this.state.NTSOptions}
                                                                            selectedService={this.state.NTSOptSelect}
                                                                        // disabled={jval.value == 9 && is_managerSelect.value==1}
                                                                        />                                                        </div>
                                                                    <div className="col-sm-2" />
                                                                </div>
                                                                :
                                                                <></>
                                                            }
                                                        </>
                                                    }
                                                    <div className="row form-group mx-2">
                                                        <div className="col-sm-3" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="indicators">How many users add ?</label>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <input type="number" className="form-control" name="userAddRow" id="indicators" placeholder="How many users add " value={userAddRow} onChange={this.handleUserInput}
                                                                disabled={((counsellingArray && counsellingArray.length > 0) || (trainingArray && trainingArray.length > 0) || (TSArray && TSArray.length > 0) || (NTSArray && NTSArray.length > 0)) ? true : false} />
                                                        </div>
                                                        <div className="col-sm-1">
                                                            <button className='btn btn-primary addbtn' onClick={() => this.addRow(0, "1")} disabled={((counsellingArray && counsellingArray.length > 0) || (trainingArray && trainingArray.length > 0) || (TSArray && TSArray.length > 0) || (NTSArray && NTSArray.length > 0)) ? true : false}>Add</button>
                                                            {/* <button className='btn btn-info' onClick={()=>this.setState({counsellingArray:[]})}>Clear</button> */}
                                                        </div>
                                                        {/* <div className="col-sm-2">
                                                            <SingleSelect
                                                                handleChange={(d) => this.handleSelectChange(d, "counschool")}
                                                                options={SchoolOptions}
                                                                selectedService={this.state.SchoolNameSelect}
                                                            // disabled={jval.value == 9 && is_managerSelect.value==1}
                                                            />                                                        </div> */}
                                                        <div className="col-sm-2" />
                                                    </div>
                                                    <div className="row form-group mx-2">
                                                        <div className="col-sm-3" />
                                                        <div className="col-sm-2">
                                                            {
                                                                entityOptSelect && entityOptSelect.value == "2" ?

                                                                    <label htmlFor="indicators">Select Company</label>
                                                                    :
                                                                    <label htmlFor="indicators">Select School</label>

                                                            }
                                                        </div>
                                                        <div className="col-sm-3 ">
                                                            {
                                                                entityOptSelect && entityOptSelect.value == "2" ?

                                                                    <SingleSelect
                                                                        handleChange={(d) => this.handleSelectChange(d, "SelectCompany")}
                                                                        options={CompanyOptions}
                                                                        selectedService={this.state.CompanyNameSelect}
                                                                        className="w-100 "
                                                                    // disabled={jval.value == 9 && is_managerSelect.value==1}
                                                                    />
                                                                    :
                                                                    <SingleSelect
                                                                        handleChange={(d) => this.handleSelectChange(d, "SelectSchool")}
                                                                        options={SchoolOptions}
                                                                        selectedService={this.state.SchoolNameSelect}
                                                                        className="w-100 "
                                                                    // disabled={jval.value == 9 && is_managerSelect.value==1}
                                                                    />
                                                            }
                                                        </div>
                                                        <div className="col-sm-2" />
                                                    </div>

                                                    <div className=" table-container" style={{ overflowX: "auto", minHeight: "200px" }}>
                                                        <table className="table table-bordered table-responsive">
                                                            {(entityOptSelect && entityOptSelect.value == "2") || ((entityOptSelect && entityOptSelect.value == "1") && (counsellingOptSelect && counsellingOptSelect.value) && (usertypeOptSelect && usertypeOptSelect.value) || (NTSOptSelect && NTSOptSelect.value)) ?
                                                                <thead>
                                                                    {window.innerWidth < 768 ?
                                                                        <>
                                                                            {((CompanyArray && CompanyArray.length > 0) || (counsellingArray && counsellingArray.length > 0) || (trainingArray && trainingArray.length > 0) || (TSArray && TSArray.length > 0) || (NTSArray && NTSArray.length > 0)) && (entityOptSelect.value == "2" ? CompanyArray : entityOptSelect.value == "1" && counsellingOptSelect.value == "1" && usertypeOptSelect.value == "1" ? counsellingArray : entityOptSelect.value == "1" && counsellingOptSelect.value == "2" && usertypeOptSelect.value == "1" ? trainingArray : entityOptSelect.value == "1" && usertypeOptSelect.value == "2" ? TSArray : entityOptSelect.value == "1" && usertypeOptSelect.value == "3" ? NTSArray : "").map((a, b) => {
                                                                                return (
                                                                                    <>
                                                                                        <tr className="rowTableborder">
                                                                                            <th>S.NO </th>
                                                                                            {
                                                                                                entityOptSelect && entityOptSelect.value == "2" ? <>
                                                                                                    {CompanyFormColumn.map((index) => (
                                                                                                        <th key={index}> {index.label.toLocaleUpperCase()}</th>
                                                                                                    ))}
                                                                                                </>
                                                                                                    :
                                                                                                    entityOptSelect.value == "1" && counsellingOptSelect.value == "1" && usertypeOptSelect.value == "1" ?
                                                                                                        <>
                                                                                                            {addCounsellingColumn.map((index) => (
                                                                                                                <th key={index}> {index.label.toLocaleUpperCase()}</th>
                                                                                                            ))}
                                                                                                        </>
                                                                                                        : entityOptSelect.value == "1" && counsellingOptSelect.value == "2" && usertypeOptSelect.value == "1" ?
                                                                                                            <>
                                                                                                                {addTrainingColumn.map((index) => (
                                                                                                                    <th key={index}> {index.label.toLocaleUpperCase()}</th>
                                                                                                                ))}
                                                                                                            </>
                                                                                                            : entityOptSelect.value == "1" && counsellingOptSelect.value == "1" && usertypeOptSelect.value == "2" ?
                                                                                                                <>
                                                                                                                    {TSColumn.map((index) => (
                                                                                                                        <th key={index}> {index.label.toLocaleUpperCase()}</th>
                                                                                                                    ))}
                                                                                                                </>
                                                                                                                : entityOptSelect.value == "1" && counsellingOptSelect.value == "2" && usertypeOptSelect.value == "2" ?
                                                                                                                    <>
                                                                                                                        {TSColumn1.map((index) => (
                                                                                                                            <th key={index}> {index.label.toLocaleUpperCase()}</th>
                                                                                                                        ))}
                                                                                                                    </>
                                                                                                                    : entityOptSelect.value == "1" && usertypeOptSelect.value == "3" && NTSOptSelect.value != "2" && NTSOptSelect.value != "3" && NTSOptSelect.value == "1" ?
                                                                                                                        <>
                                                                                                                            {NTSColumn.map((index) => (
                                                                                                                                <th key={index}> {index.label.toLocaleUpperCase()}</th>
                                                                                                                            ))}
                                                                                                                        </>
                                                                                                                        : entityOptSelect.value == "1" && usertypeOptSelect.value == "3" && (NTSOptSelect.value == "2" || NTSOptSelect.value == "3") && NTSOptSelect.value != "1" ?
                                                                                                                            <>
                                                                                                                                {NTSColumn1.map((index) => (
                                                                                                                                    <th key={index}> {index.label.toLocaleUpperCase()}</th>
                                                                                                                                ))}
                                                                                                                            </>

                                                                                                                            :
                                                                                                                            <></>
                                                                                            }


                                                                                        </tr>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </> :
                                                                        <>
                                                                            <tr>
                                                                                <th>S.No </th>
                                                                                {entityOptSelect && entityOptSelect.value == "2" ? <>
                                                                                    {CompanyFormColumn.map((index) => (
                                                                                        <th key={index}> {index.label.toLocaleUpperCase()}</th>
                                                                                    ))}
                                                                                </>
                                                                                    :
                                                                                    entityOptSelect.value == "1" && counsellingOptSelect.value == "1" && usertypeOptSelect.value == "1" ?
                                                                                        <>
                                                                                            {addCounsellingColumn.map((index) => (
                                                                                                <th key={index}> {index.label.toLocaleUpperCase()}</th>
                                                                                            ))}
                                                                                        </>
                                                                                        : entityOptSelect.value == "1" && counsellingOptSelect.value == "2" && usertypeOptSelect.value == "1" ?
                                                                                            <>
                                                                                                {addTrainingColumn.map((index) => (
                                                                                                    <th key={index}> {index.label.toLocaleUpperCase()}</th>
                                                                                                ))}
                                                                                            </>
                                                                                            : entityOptSelect.value == "1" && counsellingOptSelect.value == "1" && usertypeOptSelect.value == "2" ?
                                                                                                <>
                                                                                                    {TSColumn.map((index) => (
                                                                                                        <th key={index}> {index.label.toLocaleUpperCase()}</th>
                                                                                                    ))}
                                                                                                </>
                                                                                                : entityOptSelect.value == "1" && counsellingOptSelect.value == "2" && usertypeOptSelect.value == "2" ?
                                                                                                    <>
                                                                                                        {TSColumn1.map((index) => (
                                                                                                            <th key={index}> {index.label.toLocaleUpperCase()}</th>
                                                                                                        ))}
                                                                                                    </>
                                                                                                    : entityOptSelect.value == "1" && usertypeOptSelect.value == "3" && NTSOptSelect.value != "2" && NTSOptSelect.value != "3" && NTSOptSelect.value == "1" ?
                                                                                                        <>
                                                                                                            {NTSColumn.map((index) => (
                                                                                                                <th key={index}> {index.label.toLocaleUpperCase()}</th>
                                                                                                            ))}
                                                                                                        </>
                                                                                                        : entityOptSelect.value == "1" && usertypeOptSelect.value == "3" && (NTSOptSelect.value == "2" || NTSOptSelect.value == "3") && NTSOptSelect.value != "1" ?
                                                                                                            <>
                                                                                                                {NTSColumn1.map((index) => (
                                                                                                                    <th key={index}> {index.label.toLocaleUpperCase()}</th>
                                                                                                                ))}
                                                                                                            </>

                                                                                                            :
                                                                                                            <></>
                                                                                }

                                                                                {/* {addCounsellingColumn.map((index) => (
                                                                        <th key={index}> {index.label}</th>
                                                                    ))} */}
                                                                            </tr>
                                                                        </>}

                                                                </thead>
                                                                :
                                                                <></>
                                                            }

                                                            <tbody>
                                                                {((CompanyArray && CompanyArray.length > 0) || (counsellingArray && counsellingArray.length > 0) || (trainingArray && trainingArray.length > 0) || (TSArray && TSArray.length > 0) || (NTSArray && NTSArray.length > 0)) && (entityOptSelect.value == "2" ? CompanyArray : entityOptSelect.value == "1" && counsellingOptSelect.value == "1" && usertypeOptSelect.value == "1" ? counsellingArray : entityOptSelect.value == "1" && counsellingOptSelect.value == "2" && usertypeOptSelect.value == "1" ? trainingArray : entityOptSelect.value == "1" && usertypeOptSelect.value == "2" ? TSArray : entityOptSelect.value == "1" && usertypeOptSelect.value == "3" ? NTSArray : "").map((ival, i) =>
                                                                (
                                                                    // console.log(ival,"type",i,"i")
                                                                    <tr title={i + 1} className="rowTableData">
                                                                        <td><span><i
                                                                            class="fa fa-times-circle closeform"
                                                                            style={{ fontSize: "20px", display: "inline-block", marginTop: "3px", padding: "1px 2px", color: "red", cursor: "pointer" }}
                                                                            onClick={() => this.deleteColumn(ival, i, usertypeOptSelect ? usertypeOptSelect.value : null, entityOptSelect.value)}
                                                                        ></i>{i + 1}</span> </td>

                                                                        {(entityOptSelect.value == "2" ? CompanyFormColumn : entityOptSelect.value == "1" && counsellingOptSelect.value == "1" && usertypeOptSelect.value == "1" ? addCounsellingColumn : entityOptSelect.value == "1" && counsellingOptSelect.value == "2" && usertypeOptSelect.value == "1" ? addTrainingColumn : entityOptSelect.value == "1" && counsellingOptSelect.value == "1" && usertypeOptSelect.value == "2" ? TSColumn : entityOptSelect.value == "1" && counsellingOptSelect.value == "2" && usertypeOptSelect.value == "2" ? TSColumn1 : entityOptSelect.value == "1" && usertypeOptSelect.value == "3" && NTSOptSelect.value != "2" && NTSOptSelect.value != "3" && NTSOptSelect.value == "1" ? NTSColumn : entityOptSelect.value == "1" && usertypeOptSelect.value == "3" && (NTSOptSelect.value == "2" || NTSOptSelect.value == "3") && NTSOptSelect.value != "1" ? NTSColumn1 : "").map((jval, index) => (
                                                                            <>
                                                                                <td  >{jval && jval.type === "textarea" ? <>
                                                                                    <textarea
                                                                                        // type="text"
                                                                                        className="form-control w-100"
                                                                                        value={ival[jval.Name]}
                                                                                        onChange={(e) => this.handleInputChange(e, i, jval.Name, "1")}
                                                                                    />
                                                                                </> : jval.type === "selectbox" ?
                                                                                    <div >
                                                                                        <SingleSelect
                                                                                            handleChange={(d) => this.handleSelectChange(d, i, jval.Name, "1")}
                                                                                            options={ival[jval.Name + 'Option']}
                                                                                            selectedService={ival[jval.Name + "Select"]}
                                                                                            // disabled={jval.value == 9 && is_managerSelect.value==1}
                                                                                            disabled={jval.disabled}
                                                                                            className="w-100"
                                                                                        />

                                                                                        {/* {console.log("textypeselected :----", `${ival.textType}`)} */}
                                                                                    </div>
                                                                                    : jval.type === "selectboxMulti" ?
                                                                                        <div >
                                                                                            <MultiSelect
                                                                                                handleChange={(d) => this.handleSelectChange2(d, i, jval.Name)}
                                                                                                options={ival[jval.Name + 'Option']}
                                                                                                selectedService={ival[jval.Name + "Select"]}
                                                                                                // disabled={jval.disabled}
                                                                                                className="w-100"
                                                                                            />

                                                                                            {/* {console.log("textypeselected :----", `${ival.textType}`)} */}
                                                                                        </div>
                                                                                        : jval.type === "date" ?
                                                                                            <input type="date" value={ival[jval.Name]}
                                                                                                onChange={(e) => this.handleInputChange(e, i, jval.Name, "1")} className="form-control w-100"
                                                                                            ></input>
                                                                                            : jval.type === "number" ?
                                                                                                <input type="number" className="form-control w-100" value={ival[jval.Name]}
                                                                                                    onChange={(e) => this.handleInputChange(e, i, jval.Name, "1")}
                                                                                                ></input>
                                                                                                : jval.type === "text" ?
                                                                                                    <input type="text" className="form-control w-100    " value={ival[jval.Name]} style={{ width: "280px" }}
                                                                                                        onChange={(e) => this.handleInputChange(e, i, jval.Name, "1")}
                                                                                                    ></input>
                                                                                                    :
                                                                                                    <>
                                                                                                    </>}
                                                                                </td>
                                                                            </>
                                                                        ))}
                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>
                                                        {this.state.submitDataAlert ?
                                                            <div className='loader1'></div>
                                                            :
                                                            <>
                                                                {((CompanyArray && CompanyArray.length > 0) || (counsellingArray && counsellingArray.length > 0) || (trainingArray && trainingArray.length > 0) || (TSArray && TSArray.length > 0) || (NTSArray && NTSArray.length > 0)) &&

                                                                    <div className="row form-group add-sumbitBtn">
                                                                        <div className="col-1" >
                                                                            <button className='btn btn-primary' onClick={() => this.addRow(1, "1")}>Add one</button>
                                                                        </div>
                                                                        <div className="col-1">
                                                                            <button type="button" className="btn btn-success" onClick={
                                                                                ((CompanyArray && CompanyArray.length > 0) || (counsellingArray && counsellingArray.length > 0) || (trainingArray && trainingArray.length > 0) || (TSArray && TSArray.length > 0) || (NTSArray && NTSArray.length > 0)) &&
                                                                                (entityOptSelect.value == "2" ? this.CompanyFormSubmit : entityOptSelect.value == "1" && counsellingOptSelect.value == "1" && usertypeOptSelect.value == "1" ? this.counsellingFormSubmit : entityOptSelect.value == "1" && counsellingOptSelect.value == "2" && usertypeOptSelect.value == "1" ? this.tainingFormSubmit : entityOptSelect.value == "1" && usertypeOptSelect.value == "2" ? this.TSFormSubmit : entityOptSelect.value == "1" && usertypeOptSelect.value == "3" ? this.NTSFormSubmit : "")
                                                                                // this.counsellingFormSubmit}>
                                                                            }>
                                                                                {this.state.isEdit ? 'Update' : 'Submit'}
                                                                            </button>
                                                                        </div>
                                                                        {/* <div className="col-sm-1">
                                                                            <CSVLink
                                                                                headers={this.state.excelHeadmultidata}
                                                                                data={counsellingArray}
                                                                                filename={"AddMultiUser.xls"}
                                                                                className="btn btncolor"
                                                                                target="_blank"
                                                                            >
                                                                                Generate Excel
                                                                            </CSVLink>
                                                                        </div> */}

                                                                    </div>
                                                                }
                                                            </>
                                                        }
                                                    </div>


                                                </>

                                            </>
                                            <>
                                                {/* <>
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-4">
                                                            <button className='btn btn-primary' onClick={() => this.setState({ FormType: 0, userAddRow: "" })}>Back</button>
                                                        </div>
                                                    </div>
                                                    <br></br>

                                                    <>
                                                        <div className="row form-group ">
                                                            <div className="col-sm-2" />
                                                            <div className="col-sm-2">
                                                                <label htmlFor="indicators">How many users add ?</label>
                                                            </div>
                                                            <div className="col-sm-3">
                                                                <input type="number" className="form-control" name="userAddRow" id="indicators" placeholder="Please enter indicators" value={userAddRow} onChange={this.handleUserInput} />
                                                            </div>
                                                            <div className="col-sm-1" >
                                                                <button className='btn btn-primary' onClick={() => this.addRow(0, "2")} disabled={trainingArray && trainingArray.length > 0 ? true : false}>Add</button>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <SingleSelect
                                                                    handleChange={(d) => this.handleSelectChange(d, "trnschool")}
                                                                    options={SchoolOptions}
                                                                    selectedService={this.state.SchoolNameSelect}
                                                                />
                                                            </div>
                                                            <div className="col-sm-2" />

                                                        </div>

                                                        <div className="container" style={{ overflowX: "auto", minHeight: "200px" }}>
                                                            <table className="table table-bordered">
                                                                <thead>
                                                                    <tr>
                                                                        <th>S.No </th>

                                                                        {addTrainingColumn.map((index) => (
                                                                            <th key={index}> {index.label}</th>
                                                                        ))}
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {trainingArray && trainingArray.length > 0 && trainingArray.map((ival, i) => (

                                                                        <tr title={i + 1}>
                                                                            <td><span><i
                                                                                class="fa fa-times-circle"
                                                                                style={{ fontSize: "20px", display: "inline-block", marginTop: "3px", padding: "1px 1px", color: "red", cursor: "pointer" }}
                                                                                onClick={() => this.deleteColumn(ival, i, "training")}
                                                                            ></i>{i + 1}</span> </td>
                                                                            {addTrainingColumn.map((jval, index) => (
                                                                                <>
                                                                                    <td  >{jval && jval.type === "textarea" ? <>
                                                                                        <textarea
                                                                                            style={{ width: "280px" }}
                                                                                            value={ival[jval.Name]}
                                                                                            onChange={(e) => this.handleInputChange(e, i, jval.Name, "2")}
                                                                                        /></> : jval.type === "selectbox" ?
                                                                                        <div style={{ width: "280px" }}>
                                                                                            <SingleSelect
                                                                                                handleChange={(d) => this.handleSelectChange(d, i, jval.Name, "2")}
                                                                                                options={ival[jval.Name + 'Option']}
                                                                                                selectedService={ival[jval.Name + "Select"]}
                                                                                                disabled={jval.disabled}
                                                                                            />

                                                                                        </div>
                                                                                        : jval.type === "date" ?
                                                                                            <input type="date" value={ival[jval.Name]} style={{ width: "280px" }}
                                                                                                onChange={(e) => this.handleInputChange(e, i, jval.Name, "2")}
                                                                                            ></input>
                                                                                            : jval.type === "number" ?
                                                                                                <input type="number" className="form-control" value={ival[jval.Name]} style={{ width: "280px" }}
                                                                                                    onChange={(e) => this.handleInputChange(e, i, jval.Name, "2")}
                                                                                                ></input>
                                                                                                : jval.type === "text" ?
                                                                                                    <input type="text" className="form-control" value={ival[jval.Name]} style={{ width: "280px" }}
                                                                                                        onChange={(e) => this.handleInputChange(e, i, jval.Name, "2")}
                                                                                                    ></input>
                                                                                                    :
                                                                                                    <>
                                                                                                    </>}
                                                                                    </td>
                                                                                </>
                                                                            ))}
                                                                        </tr>
                                                                    ))}

                                                                </tbody>
                                                            </table>
                                                            {this.state.submitDataAlert ?
                                                                <div className='loader1'></div>
                                                                :
                                                                <>
                                                                    {trainingArray && trainingArray.length > 0 &&

                                                                        <div className="row form-group ">
                                                                            <div className="col-sm-1" >
                                                                                <button className='btn btn-primary' onClick={() => this.addRow(1, "2")}>Add one</button>
                                                                            </div>
                                                                            <div className="col-sm-1">
                                                                                <button type="button" className="btn btn-success" onClick={this.tainingFormSubmit}>
                                                                                    Submit
                                                                                </button>
                                                                            </div>
                                                                           

                                                                        </div>
                                                                    }
                                                                </>
                                                            }
                                                        </div>


                                                    </>

                                                </> */}
                                            </>

                                        </>
                                        // : this.state.FormType == 2 ?

                                        : this.state.FormType == 0 ?
                                            <>
                                                <LoginModal
                                                    // buttonTitle="Send Mail "
                                                    // title="Send Mail"
                                                    id="sendMail"
                                                    extraClass="btn-width d-none"
                                                    onClick={this.reset}
                                                    bodyText={
                                                        <div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-4" />
                                                                <Alert
                                                                    className="badge-content"
                                                                    color="success"
                                                                    isOpen={alertVisible}
                                                                    toggle={this.onDismiss}
                                                                >
                                                                    Mail Sent Successfully
                                                                </Alert>
                                                            </div>
                                                            <div className="card-header">
                                                                <h1>SEND MAIL</h1>
                                                            </div>
                                                            <div className="row form-group pt-5">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2">

                                                                    <label htmlFor="subcategory">Email </label>
                                                                </div>
                                                                <div className="col-sm-5">
                                                                    <MultiSelect
                                                                        // disabled={this.state.DCDisable}
                                                                        options={this.state.EmailOptions}
                                                                        handleChange={(e) => this.optionSelect2(e)}
                                                                        selectedService={this.state.emailSelectedOptions}
                                                                    // disabled={this.state.disableselectform}

                                                                    />
                                                                    {/* <input
                                                                        type="text"
                                                                        name="subcategory"
                                                                        placeholder="Enter Email"
                                                                        className="form-control"
                                                                        value={this.state.modalInput}
                                                                        onChange={(e) => this.modalhandlechange(e)}
                                                                    /> */}
                                                                    {/* <span className="modal-error-show">{error}</span> */}
                                                                </div>
                                                                <div className="col-sm-3" />
                                                            </div>
                                                            <div className="row form-group">
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-2" />
                                                                <div className="col-sm-5">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary"
                                                                        onClick={this.state.emailSend ? this.emailExcelSend : this.exportToExcelAndSendEmail}
                                                                    >
                                                                        submit
                                                                    </button>
                                                                </div>
                                                                <div className="col-sm-3" />
                                                            </div>
                                                            <br></br>
                                                        </div>
                                                    }
                                                />
                                                <div className='row' style={{ marginBottom: "20px", marginLeft: "20px" }}>
                                                    <div className='col-sm-12 d-lg-flex justify-content-lg-center align-items-lg-center'>
                                                        <div className="mt-3 text-lg-center">
                                                            <button className='btn btn-primary ' style={{ marginTop: "-15px", marginLeft: "20px" }} onClick={() => this.setState({ FormType: 1 })}>Add Form</button>
                                                        </div>
                                                        {/* <div className="mt-3 text-lg-center">
                                                                <button className='btn btn-primary ' style={{ marginTop: "-15px", marginLeft: "20px" }} onClick={() => this.setState({ FormType: 1 })}>Add Counselling Form</button>
                                                            </div>
                                                            <div className="mt-3 text-lg-center ">
                                                                <button className='btn btn-primary' style={{ marginTop: "-15px", marginLeft: "20px" }} onClick={() => this.setState({ FormType: 2 })}>Add Group Counseling/Training Form</button>
                                                            </div> */}
                                                    </div>
                                                </div>
                                                <div className="row form-group">

                                                    <div className="col-sm-2" >
                                                        <button className="btn btn-success" onClick={this.exportToExcel}>Export Consolidate Forms</button>

                                                    </div>&nbsp;&nbsp;
                                                    {/* <div className="col-sm-1" /> */}
                                                    <div className="col-sm-2" >
                                                        {/* <button className="btn btn-success" onClick={this.exportToExcelAndSendEmail}>Email Consolidate Forms</button> */}
                                                        {/* <button className="btn btn-info" data-toggle="modal" data-target='#sendMail'>Mail Consolidate Forms</button> */}

                                                    </div>
                                                    <div className="col-sm-8" />
                                                </div>

                                                <br></br>
                                                <div className="row form-group">
                                                    <div className="card-header mx-3">
                                                        <h1 className=""> 1 to 1 Counseling Form Datas :</h1>
                                                    </div>
                                                    <br></br>
                                                    <div className="col-sm-12">
                                                        {loading ? <div className="text-center">
                                                            < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                        </div> : this.state.CounsellingData ?
                                                            <>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-8"></div>
                                                                    <div className="col-sm-2 ">
                                                                        {this.state.CounsellingData && this.state.CounsellingData.length > 0 && (
                                                                            <CSVLink
                                                                                data={this.state.CounsellingData}
                                                                                headers={this.excelColumn}
                                                                            >
                                                                                <button
                                                                                    className="btn btn-success excelBtn"
                                                                                    style={{ marginLeft: "135px" }}
                                                                                >
                                                                                    EXCEL
                                                                                </button>
                                                                            </CSVLink>
                                                                        )}
                                                                    </div>
                                                                    &nbsp;
                                                                    {this.state.CounsellingData && this.state.CounsellingData.length > 0 && (
                                                                        <div className="col-sm-1 ">
                                                                            {/* <button className="btn btn-info" data-toggle="modal" data-target='#sendMail' onClick={() => this.emailSingleExcel(CounsellingData, "1")}>Mail Excel</button> */}
                                                                        </div>)}
                                                                </div>
                                                                <Datatable
                                                                    data={this.state.CounsellingData}
                                                                    columnHeading={this.column}
                                                                />
                                                            </>


                                                            : <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data Found</div>
                                                        }

                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className="row form-group">
                                                    <div className="card-header  mx-3">
                                                        <h1 className="">Group Counseling/Training Form Datas :</h1>
                                                    </div>
                                                    <br></br>
                                                    <div className="col-sm-12">
                                                        {loading ? <div className="text-center">
                                                            < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                        </div> : this.state.TrainingData ?
                                                            <>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-8"></div>
                                                                    <div className="col-sm-2 py-1">
                                                                        {this.state.TrainingData && this.state.TrainingData.length > 0 && (
                                                                            <CSVLink
                                                                                data={this.state.TrainingData}
                                                                                headers={this.excelcolumn1}
                                                                            >
                                                                                <button
                                                                                    className="btn btn-success excelBtn"
                                                                                    style={{ marginLeft: "135px" }}
                                                                                >
                                                                                    EXCEL
                                                                                </button>
                                                                            </CSVLink>
                                                                        )}
                                                                    </div>
                                                                    &nbsp;
                                                                    {this.state.TrainingData && this.state.TrainingData.length > 0 && (
                                                                        <div className="col-sm-1 ">
                                                                            {/* <button className="btn btn-info" data-toggle="modal" data-target='#sendMail' onClick={() => this.emailSingleExcel(TrainingData, "2")}>Mail Excel</button> */}
                                                                        </div>)}
                                                                </div>
                                                                <Datatable
                                                                    data={this.state.TrainingData}
                                                                    columnHeading={this.column1}
                                                                />
                                                            </>


                                                            : <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data Found</div>}

                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className="row form-group">
                                                    <div className="card-header  mx-3">
                                                        <h1 className="">Teaching Staff Form Datas :</h1>
                                                    </div>
                                                    <br></br>
                                                    <div className="col-sm-12">
                                                        {loading ? <div className="text-center">
                                                            < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                        </div> : this.state.TSData ?
                                                            <>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-8"></div>
                                                                    <div className="col-sm-2 py-1">
                                                                        {this.state.TSData && this.state.TSData.length > 0 && (
                                                                            <CSVLink
                                                                                data={this.state.TSData}
                                                                                headers={this.excelcolumn2}
                                                                            >
                                                                                <button
                                                                                    className="btn btn-success excelBtn"
                                                                                    style={{ marginLeft: "135px" }}
                                                                                >
                                                                                    EXCEL
                                                                                </button>
                                                                            </CSVLink>
                                                                        )}
                                                                    </div>
                                                                    &nbsp;
                                                                    {this.state.TSData && this.state.TSData.length > 0 && (
                                                                        <div className="col-sm-1 ">
                                                                            {/* <button className="btn btn-info" data-toggle="modal" data-target='#sendMail' onClick={() => this.emailSingleExcel(this.state.TSData, "3")}>Mail Excel</button> */}
                                                                        </div>)}
                                                                </div>
                                                                <Datatable
                                                                    data={this.state.TSData}
                                                                    columnHeading={this.column2}
                                                                />
                                                            </>


                                                            : <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data Found</div>}

                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className="row form-group">
                                                    <div className="card-header  mx-3">
                                                        <h1 className="">Non-Teaching Staff Form Datas :</h1>
                                                    </div>
                                                    <br></br>
                                                    <div className="col-sm-12">
                                                        {loading ? <div className="text-center">
                                                            < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                        </div> : this.state.NTSData ?
                                                            <>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-8"></div>
                                                                    <div className="col-sm-2 py-1">
                                                                        {this.state.NTSData && this.state.NTSData.length > 0 && (
                                                                            <CSVLink
                                                                                data={this.state.NTSData}
                                                                                headers={this.excelcolumn3}
                                                                            >
                                                                                <button
                                                                                    className="btn btn-success excelBtn"
                                                                                    style={{ marginLeft: "135px" }}
                                                                                >
                                                                                    EXCEL
                                                                                </button>
                                                                            </CSVLink>
                                                                        )}
                                                                    </div>
                                                                    &nbsp;
                                                                    {this.state.NTSData && this.state.NTSData.length > 0 && (
                                                                        <div className="col-sm-1 ">
                                                                            {/* <button className="btn btn-info" data-toggle="modal" data-target='#sendMail' onClick={() => this.emailSingleExcel(this.state.NTSData, "4")}>Mail Excel</button> */}
                                                                        </div>)}
                                                                </div>
                                                                <Datatable
                                                                    data={this.state.NTSData}
                                                                    columnHeading={this.column3}
                                                                />
                                                            </>


                                                            : <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data Found</div>}

                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className="row form-group">
                                                    <div className="card-header  mx-3">
                                                        <h1 className="">Company Form Datas :</h1>
                                                    </div>
                                                    <br></br>
                                                    <div className="col-sm-12">
                                                        {loading ? <div className="text-center">
                                                            < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                        </div> : this.state.CompanyData ?
                                                            <>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-8"></div>
                                                                    <div className="col-sm-2 py-1">
                                                                        {this.state.CompanyData && this.state.CompanyData.length > 0 && (
                                                                            <CSVLink
                                                                                data={this.state.CompanyData}
                                                                                headers={this.excelcolumn4}
                                                                            >
                                                                                <button
                                                                                    className="btn btn-success excelBtn"
                                                                                    style={{ marginLeft: "135px" }}
                                                                                >
                                                                                    EXCEL
                                                                                </button>
                                                                            </CSVLink>
                                                                        )}
                                                                    </div>
                                                                    &nbsp;
                                                                    {this.state.CompanyData && this.state.CompanyData.length > 0 && (
                                                                        <div className="col-sm-1 ">
                                                                            {/* <button className="btn btn-info" data-toggle="modal" data-target='#sendMail' onClick={() => this.emailSingleExcel(this.state.CompanyData, "5")}>Mail Excel</button> */}
                                                                        </div>)}
                                                                </div>
                                                                <Datatable
                                                                    data={this.state.CompanyData}
                                                                    columnHeading={this.column4}
                                                                />
                                                            </>


                                                            : <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data Found</div>}

                                                    </div>
                                                </div>
                                            </> :
                                            <>
                                            </>}
                                    <>
                                        {/* <div className="card-body">
                                        <div className="inlinebutton">
                                            <LoginModal
                                                buttonTitle="Form"
                                                title={
                                                    this.state.updateData === false
                                                        ? " Form"
                                                        : "Update Form"
                                                }
                                                id="customer"
                                                onClick={this.reset}
                                                bodyText={
                                                    <div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4">
                                                                <label htmlFor="exampleInputEmail1">NAME :</label>
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="pageName"
                                                                    name="emp_id"
                                                                    placeholder="Enter ID"
                                                                    value={this.state.emp_id}
                                                                    onChange={this.handlechange}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-4" />
                                                            <span className="error-show ">
                                                                {this.state.errorCode}
                                                            </span>
                                                        </div>
                                                        <br />
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4">
                                                                <label htmlFor="exampleInputEmail1">CLASS</label>
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="Name"
                                                                    name="emp_name"
                                                                    placeholder="Enter name"
                                                                    value={this.state.emp_name}
                                                                    onChange={d => this.handlechange(d, "caps")}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-4" />
                                                            <span className="error-show ">
                                                                {this.state.errorempname}
                                                            </span>
                                                        </div>
                                                        <br />
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4">
                                                                <label htmlFor="exampleInputEmail1">GENDER</label>
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <SingleSelect
                                                                    options={this.state.genderopt}
                                                                    handleChange={d => this.handlechange(d, "Select", "ansgender")}
                                                                    selectedService={this.state.ansgender}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-4" />
                                                            <span className="error-show ">
                                                                {this.state.errgender}
                                                            </span>
                                                        </div>
                                                        <br />
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4">
                                                                <label htmlFor="exampleInputEmail1">DEPARTMENT</label>
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="Name"
                                                                    name="ansBusinessUnit"
                                                                    placeholder="Enter Business Unit"
                                                                    value={this.state.ansBusinessUnit}
                                                                    onChange={d => this.handlechange(d, "caps")}
                                                                />
                                                               
                                                            </div>
                                                        </div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-4" />
                                                            <span className="error-show ">
                                                                {this.state.errBusinessUnit}
                                                            </span>
                                                        </div>
                                                        <br />
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4">
                                                                <label htmlFor="exampleInputEmail1"> NAME OF THE MENTOR  </label>
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="Name"
                                                                    name="ansFunction"
                                                                    placeholder="Enter Function"
                                                                    value={this.state.ansFunction}
                                                                    onChange={d => this.handlechange(d, "caps")}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-4" />
                                                            <span className="error-show ">
                                                                {this.state.errFunction}
                                                            </span>
                                                        </div>
                                                        <br />
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4">
                                                                <label htmlFor="exampleInputEmail1">WHETHER COUNSELLING CONDUCTED ALONG WITH PARENT  ?</label>
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="Name"
                                                                    name="ansSubFunction"
                                                                    placeholder="Enter Sub Function"
                                                                    value={this.state.ansSubFunction}
                                                                    onChange={d => this.handlechange(d, "caps")}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-4" />
                                                            <span className="error-show ">
                                                                {this.state.errSubFunction}
                                                            </span>
                                                        </div>
                                                        <br />
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4">
                                                                <label htmlFor="exampleInputEmail1">DATE OF COUNSELLING </label>
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    id="Name"
                                                                    name="ansRegion"
                                                                    placeholder="Enter Region"
                                                                    value={this.state.ansRegion}
                                                                    onChange={d => this.handlechange(d, "caps")}
                                                                />
                                                              
                                                            </div>
                                                        </div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-4" />
                                                            <span className="error-show ">
                                                                {this.state.errRegion}
                                                            </span>
                                                        </div>
                                                        <br />
                                                        <div className="row form-group">
                                                            <div className="col-sm-1" />
                                                            <div className="col-sm-4">
                                                                <label htmlFor="exampleInputEmail1">REASON FOR THE COUNSELLING </label>
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <input
                                                                    type="textarea"
                                                                    className="form-control"
                                                                    id="Name"
                                                                    name="ansDivision"
                                                                    placeholder="Enter Division"
                                                                    value={this.state.ansDivision}
                                                                    onChange={d => this.handlechange(d, "caps")}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-4" />
                                                            <span className="error-show ">
                                                                {this.state.errDivision}
                                                            </span>
                                                        </div>
                                                        <br />


                                                        <br />
                                                        <div className="row form-group">
                                                            <div className="col-sm-4" />
                                                            <span className="error-show ">
                                                                {this.state.errorpassword}
                                                            </span>
                                                        </div>
                                                        <div className="row form-group">
                                                            <div className="col-sm-2" />
                                                            <div className="col-sm-7">
                                                                <Alert
                                                                    className="badge-content"
                                                                    color={badgeColor && badgeColor}
                                                                    isOpen={this.state.alertVisible}
                                                                    toggle={this.onDismiss}
                                                                >
                                                                    {badgeText && badgeText}
                                                                </Alert>
                                                            </div>
                                                            <div className="col-sm-3" />
                                                        </div>
                                                        <br />
                                                        <div className="row form-group">
                                                            <div className="col-sm-2" />
                                                            <div className="col-sm-2" />
                                                            <div className="col-sm-5">
                                                                <button
                                                                    type="button"
                                                                    className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
                                                                    disabled={this.state.btnDisable}
                                                                    onClick={this.submitCategory}
                                                                >
                                                                    {this.state.isEdit ? 'Update' : 'Submit'}
                                                                </button>
                                                            </div>
                                                            <div className="col-sm-3" />
                                                        </div>

                                                    </div>
                                                }
                                            />
                                        </div>
                                        <br></br>
                                        <span>
                                            Total of {Object.keys(this.state.data).length} records
                                        </span>
                                        <br></br>

                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                {loading ? <div className="text-center">
                                                    < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                </div> : this.state.data ?
                                                    
                                                        <Datatable
                                                            data={this.state.data}
                                                            columnHeading={this.column}
                                                        />
                                                      
                                                    : <>No data Found</>}

                                            </div>
                                        </div>
                                    </div> */}
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </React.Fragment>
        );
    }
}

export default PyschologyForm;
