import React, { Component } from 'react';
import CmsContent from '../../MiddleWare/CmsContent';
import DownPanel from './DownPanel';
import RightPanel from './RightPanel';
import renderHTML from 'react-render-html';
import './style.css';
import { Button, Card, CardBody, CardHeader, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { ACCESS_POINT } from '../../config';

class Gamification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageList: '',
      date: ''
    };
    let userlog = localStorage.getItem('userlog');
    userlog = JSON.parse(userlog);
    localStorage.setItem('userId', userlog.id);
    localStorage.setItem('userName', userlog.userName);
    localStorage.setItem('userEmail', userlog.email);
    localStorage.setItem('userMobile', userlog.mobileNumber);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    let themeid = this.props.match.params.id;
    const result = await CmsContent.getThemeById(themeid);

    if (result) {
      if (result.data[0].Date != null && result.data[0].Date != undefined) {
        var dataformate = result.data[0].Date.split('-');
        this.setState({ date: dataformate[2] + '-' + dataformate[1] + '-' + dataformate[0] });
      }

      this.setState({ pageList: result.data[0] });
    }
  }

  async componentWillReceiveProps(nextProps) {
    window.scrollTo(0, 0);
    let themeid = nextProps.match.params.id;
    const result = await CmsContent.getThemeById(themeid);
    if (result) {
      if (result.data[0].Date != null && result.data[0].Date != undefined) {
        var dataformate = result.data[0].Date.split('-');
        this.setState({ date: dataformate[2] + '-' + dataformate[1] + '-' + dataformate[0] });
      }

      this.setState({ pageList: result.data[0] });
    }
  }

  render() {
    const { pageList, date } = this.state;
    const styles = {
      boxShadow: '0 0 10 #8e7f7f',
      webkitBorderRadius: 25,
      webkitBoxShadow: '1px 3px 25px #9E9E9E',
      marginTop: 30,
      marginRight: 20,
      paddingTop: 0,
      marginLeft: 20
    };
    let textalign = { textAlign: 'center' };
    let downloadicon = { width: 25, height: 15, marginLeft: -6 };
    let imageTag = { width: '40%', marginTop: 20 };
    let atag = { marginLeft: -30 };
    let dowfont = { fontSize: 15 /*marginLeft: -2 */ };
    let nopadding = { paddingLeft: 5, paddingRight: 0 };
    let gameheading = { paddingLeft: 5, paddingRight: 0, fontWeight: 'bold' };

    return (
      <React.Fragment>
        <div className="container-fluid container-margin-top">
          <div className="card">
            <h2 className="theme-tiile" style={{ marginTop: 15 }}>
              Gamification{' '}
            </h2>
            <div className="row form-group" style={{ marginBottom: 60 }}>
              <div className="col-sm-4">
                <div className="card-body" style={styles}>
                  <div className="title" style={textalign}>
                    <img style={imageTag} src={ACCESS_POINT + '/superAdmin/file?fileurl=SDAS/Video/sb1_1579521836511.jpg'} alt="Italian Trulli" />
                    <br /> <br />
                    Electrical Shocks & Burns
                  </div>
                  <br />
                  <br />
                  <div className="col-sm-12 " style={textalign}>
                    <button
                      onClick={() => {
                        window.location.href = `${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Electrical_Shocks_Burns.zip`;
                      }}
                      className="btn btn-success btn-lg"
                    >
                      <i className="fa fa-download" aria-hidden="true" style={downloadicon}></i>
                      <span style={dowfont}>Download</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="card-body" style={styles}>
                  <div className="title" style={textalign}>
                    <img style={imageTag} src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Heightsafety_1584626683500.jpg`}></img> <br />
                    <br />
                    Height Safety
                  </div>
                  <br />
                  <br />
                  <div className="col-sm-12 " style={textalign}>
                    <button
                      onClick={() => {
                        window.location.href = `${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Height_Safety.zip`;
                      }}
                      className="btn btn-success btn-lg"
                    >
                      <i className="fa fa-download" aria-hidden="true" style={downloadicon}></i>
                      <span style={dowfont}>Download</span>
                    </button>
                  </div>
                </div>

                {/* <div className="card">
                <div className="card-body">
                  <RightPanel />
                </div>
              </div> */}
              </div>
              <div className="col-sm-4">
                <div className="card-body" style={styles}>
                  <div className="title" style={textalign}>
                    <img style={imageTag} src={ACCESS_POINT + '/superAdmin/file?fileurl=SDAS/Video/ppe1_1579521842359.jpg'} alt="Italian Trulli" />
                    <br />
                    <br />
                    Personal Protective Equipment
                  </div>

                  <br />
                  <br />

                  <div className="col-sm-12 " style={textalign}>
                    <button
                      onClick={() => {
                        window.location.href = `${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Personal_Protective_Equipment.zip`;
                      }}
                      className="btn btn-success btn-lg"
                    >
                      <i className="fa fa-download" aria-hidden="true" style={downloadicon}></i>
                      <span style={dowfont}>Download</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="card-body" style={styles}>
                  <div className="title" style={textalign}>
                    <img style={imageTag} src={ACCESS_POINT + '/superAdmin/file?fileurl=SDAS/Video/fs2_1579521827682.jpg'} alt="Italian Trulli" />
                    <br /> <br />
                    Fire Safety
                  </div>
                  <br />
                  <br />
                  <div className="col-sm-12 " style={textalign}>
                    <button
                      onClick={() => {
                        window.location.href = `${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/FIRE_SAFETY.zip`;
                      }}
                      className="btn btn-success btn-lg"
                    >
                      <i className="fa fa-download" aria-hidden="true" style={downloadicon}></i>
                      <span style={dowfont}>Download</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-4">
                <div className="card-body" style={styles}>
                  <div className="title" style={textalign}>
                    <img style={imageTag} src={ACCESS_POINT + '/superAdmin/file?fileurl=SDAS/Video/te1_1579521797251.jpg'} alt="Italian Trulli" />
                    <br /> <br />
                    Tools Equipment Safety
                  </div>
                  <br />
                  <br />
                  <div className="col-sm-12 " style={textalign}>
                    <button
                      onClick={() => {
                        window.location.href = `${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Tools_Equipment_Safety.zip`;
                      }}
                      className="btn btn-success btn-lg"
                    >
                      <i className="fa fa-download" aria-hidden="true" style={downloadicon}></i>
                      <span style={dowfont}>Download</span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-sm-4" style={{ fontSize: 14 }}>
                <div className="card-body" style={styles}>
                  <div className="row" style={{ paddingLeft: 10, paddingBottom: 12, paddingTop: 20, fontWeight: 'bold', textAlign: 'center' }}>
                    Recommended System Requirements or Equivalent
                  </div>
                  <div style={{ paddingBottom: 20 }}>
                    <div className="row">
                      <div className="col-3" style={gameheading}>
                        Processor
                      </div>
                      <div className="col-9" style={nopadding}>
                        :&nbsp;Intel Core i3 7100U, Intel Core i3 7130U
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3" style={gameheading}>
                        Graphics
                      </div>
                      <div className="col-9" style={nopadding}>
                        :&nbsp;620, NVIDIA GeForce 940MX
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-3" style={gameheading}>
                        RAM
                      </div>
                      <div className="col-9" style={nopadding}>
                        :&nbsp;4 GB
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-3" style={gameheading}>
                        OS
                      </div>
                      <div className="col-9" style={nopadding}>
                        :&nbsp;Windows 10
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-3" style={gameheading}>
                        Hard Disk
                      </div>
                      <div className="col-9" style={nopadding}>
                        :&nbsp;7 GB
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-sm-12 mb-new">
              <div className="card">{/* <div className="card-body px-0">
                  <DownPanel />
                </div> */}</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Gamification;
