import React, { Component } from "react";
import FormMiddleWare from "../../components/Form/FormMiddleware";
import CmsContent from "../../MiddleWare/CmsContent";
import { Datatable } from "../../components/Datatable";
import SingleSelect from "../../components/Form/SingleSelect";


class UserUnder extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page2: 0,
      company: [],
      selectgroupcompany: '',
      usertypelearners: []
    };
  }
  async componentWillMount() {
    let Userlog = JSON.parse(localStorage.getItem("userlog"));
    console.log(Userlog.userType)
    var result = '';

    if (Userlog.userType == 1) {

      let usertypelearners = await CmsContent.getFreedom(
        "*",
        "tbl_user_web",
        `userType=2 or userType=3 `,
        1,
        1
      );

      this.setState({ usertypelearners })
      let usertype_2 = await CmsContent.getFreedom(
        "*",
        "tbl_user_web",
        `userType=2 and loginStatus = 'online'`,
        1,
        1
      );


      let usertype_3 = await CmsContent.getFreedom(
        "*",
        "tbl_user_web",
        `userType=3 and loginStatus = 'online'`,
        1,
        1
      );

      let usertype_6_8 = await CmsContent.getFreedom(
        "*",
        "tbl_user_web",
        `userType=6 and loginStatus = 'online' or userType=8 and loginStatus = 'online'`,
        1,
        1
      );

      this.setState({ company: [{ label: `L2 - (${usertype_2.data.length} Users)`, value: "2" }, { label: `L3 - (${usertype_3.data.length} Users)`, value: "3" }, { label: `L4 - (${usertype_6_8.data.length} Users)`, value: "4" }] })

      console.log(this.state.company)

      //  this.setState({company :selectcompany.data })



    }
    else if (Userlog.userType == 2) {

      let usertype_3 = await CmsContent.getFreedom(
        "*",
        "tbl_user_web",
        `userType=3 and loginStatus = 'online' and customerId=${Userlog.customerId}`,
        1,
        1
      );

      console.log(usertype_3.data.length)
      let usertype_6_8 = await CmsContent.getFreedom(
        "*",
        "tbl_user_web",
        ` customerId=${Userlog.customerId} and  userType=6 and loginStatus = 'online' or userType=8 and loginStatus = 'online'   `,
        1,
        1
      );
      console.log(usertype_6_8.data.length)
      console.log(`userType=6 and loginStatus = 'online' or userType=8 and loginStatus = 'online' and customerId=${Userlog.customerId}`)
      this.setState({ company: [{ label: `L3 - (${usertype_3.data.length} Users)`, value: "3" }, { label: `L4 - (${usertype_6_8.data.length} Users)`, value: "4" }] })

      console.log(this.state.company)

    }


    var result1 = await CmsContent.getFreedom(
      "*",
      "tbl_OriginalLog",
      `customerId = ${Userlog.id}`,
      1,
      1
    );
    //    console.log(result.data)
    //   console.log(result1.data)

    let userType = await CmsContent.getFreedom(
      "usertype as label , id as value",
      "tbl_UserType",
      1,
      1,
      1
    );
    await this.setState({
      data: result.data,
      Userlog,
      log: result1.data,
      userType: userType.data,
      MultipleUserReport: result1.data
    });
  }

  column = [
    {
      Header: "Username",
      accessor: "userName"

    },
    {
      Header: "User Type",
      accessor: "userType",
      Cell: d =>
        this.getValueFromArray(d.original.userType, this.state.userType)
    },
    {
      Header: "mobileNumber",
      accessor: "mobileNumber"

    },
    {
      Header: "Login Status",
      accessor: "loginStatus"
    },
    {
      Header: "View",
      accessor: "id",
      Cell: d => this.View(d)
    }
  ];
  nameview = (customerId, companyid) => {
    //console.log(customerId.id)
    // console.log(companyid.data[0])
    let userName = '';
    companyid.data.map(values => {

      if (values.id == customerId.customerId) {
        //console.log(values.userName+''+values.id+''+customerId.customerId)
        userName = values.userName;
      }
    })


    return (
      <center>

        {userName}

      </center>
    );

  }

  column3 = [
    {
      Header: "L2",
      Cell: d => this.nameview(d.original, this.state.usertypelearners)

    },
    {
      Header: "Username",
      accessor: "userName"

    },
    {
      Header: "User Type",
      accessor: "userType",
      Cell: d =>
        this.getValueFromArray(d.original.userType, this.state.userType)
    },
    {
      Header: "mobileNumber",
      accessor: "mobileNumber"

    },
    {
      Header: "Login Status",
      accessor: "loginStatus"
    },
    {
      Header: "View",
      accessor: "id",
      Cell: d => this.View(d)
    }
  ];
  nameview_l4 = (value, datas) => {
    // console.log(value.id)
    // console.log(datas.customerId)

    let filtered = datas.data.filter(function (item) {
      // console.log(item.customerId+'==>'+value.id)

      return item.id == value.customerId;
    });
    //console.log(filtered[0].customerId)

    let filtered_2 = datas.data.filter(
      function (item) {
        // console.log(item.customerId+'==>'+value.id)
        return item.id == filtered[0].customerId;
      }
    )

    ///customerId

    return (
      <center>

        {filtered_2[0].userName}

      </center>
    );

  }

  column4 = [
    {
      Header: "L2",
      Cell: d => this.nameview_l4(d.original, this.state.usertypelearners)
    },
    {
      Header: "L3",
      Cell: d => this.nameview(d.original, this.state.usertypelearners)

    },
    {
      Header: "Username",
      accessor: "userName"

    },
    {
      Header: "User Type",
      accessor: "userType",
      Cell: d =>
        this.getValueFromArray(d.original.userType, this.state.userType)
    },
    {
      Header: "mobileNumber",
      accessor: "mobileNumber"

    },
    {
      Header: "Login Status",
      accessor: "loginStatus"
    },
    {
      Header: "View",
      accessor: "id",
      Cell: d => this.View(d)
    }
  ];

  View = (d, value) => {
    return (
      <center>
        <a
          href="#"
          onClick={() => {
            this.doFunction(d);
          }}
        >
          View
        </a>
      </center>
    );
  };
  doFunction = async d => {
    let report = await CmsContent.getFreedom(
      "*",
      "tbl_OriginalLog",
      `userId = ${d.original.id}`,
      1,
      1
    );
    this.setState({ SingleUserReport: report.data, page2: 1 });
  };
  ViewAll = () => {
    return (
      <a
        href="#"
        onClick={() => {
          this.report2();
        }}
      >
        View All
      </a>
    );
  };

  column1 = [
    {
      Header: "Username",
      accessor: "userName"

    },
    {
      Header: "User Type",
      accessor: "userType",
      Cell: d =>
        this.getValueFromArray(d.original.userType, this.state.userType)
    },
    {
      Header: "IP",
      accessor: "ip"
    },
    {
      Header: "Browser",
      accessor: "browser"
    },
    {
      Header: "Device",
      accessor: "device"
    },
    {
      Header: "OS",
      accessor: "os"
    },
    {
      Header: "Created At",
      accessor: "createdAt"
    }
  ];


  view = d => {
    return "NA";
  };

  OnBack = (val, type) => {
    if (val === 3) {
      this.setState({ page2: 0 });
    } else {
      this.setState({ page2: val - 1 });
    }
  };

  report2 = () => {
    this.setState({ page2: 3 });
  };


  selectcompany = async (value) => {
    console.log(value)

    if (value.value == 2) {
      console.log(value.value)
      let onlineleaners = await CmsContent.getFreedom(
        "*",
        "tbl_user_web",
        `userType=2 and loginStatus = 'online'`,
        1,
        1
      );


      this.setState({ data: onlineleaners.data, page2: 0 })
      console.log(onlineleaners.data)
      console.log(this.state.data)

    }
    if (value.value == 3) {
      console.log(value.value)
      let onlineleaners = await CmsContent.getFreedom(
        "*",
        "tbl_user_web",
        `userType=3 and loginStatus = 'online'`,
        1,
        1
      );

      this.setState({ data: onlineleaners.data, page2: 'l3users' })
      console.log(onlineleaners.data)
      console.log(this.state.data)
    }

    if (value.value == 4) {
      console.log(value.value)
      let onlineleaners = await CmsContent.getFreedom(
        "*",
        "tbl_user_web",
        `userType=6 and loginStatus = 'online' or userType=8 and loginStatus = 'online'`,
        1,
        1
      );

      this.setState({ data: onlineleaners.data, page2: 'l4users' })
      console.log(onlineleaners.data)
      console.log(this.state.data)
    }
    this.setState({ selectgroupcompany: value })

  }
  render() {
    const { page2, data, SingleUserReport, MultipleUserReport } = this.state;
    console.log(page2)
    if (page2 === 0) {
      var tableData = data;
      var column = this.column;
    } else if (page2 === 1) {
      var tableData = SingleUserReport;
      var column = this.column1;
    } else if (page2 === 3) {
      var tableData = MultipleUserReport;
      var column = this.column1;
    }
    else if (page2 == "l3users") {
      var tableData = data;
      var column = this.column3;
    }
    else if (page2 == "l4users") {
      var tableData = data;
      var column = this.column4;
    }


    return (
      <React.Fragment>
        <main className="">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h1>Logged In Users</h1>
              </div>
              <div className="card-body">
                <div className="row form-group">
                  <div className="col-sm-4"></div>
                  <div className="col-sm-4">

                    <SingleSelect
                      options={this.state.company}
                      handleChange={d => this.selectcompany(d)}
                      selectedService={this.state.selectgroupcompany}
                    />
                  </div>
                  <div className="col-sm-4"></div>
                  {/* {this.state.log && (
                      <Datatable
                        data={this.state.log}
                        columnHeading={this.column}
                      />
                    )} */}
                  <div className="col-sm-12">
                    <div className="row form-group ml-0 mb-3">
                      <div className="col-sm-9 p-0"></div>
                      <div className="col-sm-1 p-0">
                        {page2 === 1 &&
                          this.dataTableButton("danger", "Back", () => {
                            this.OnBack(page2, "view");
                          })}
                        {page2 === 3 &&
                          this.dataTableButton("danger", "Back", () => {
                            this.OnBack(3);
                          })}
                      </div>
                      {/* <div className="col-sm-1 p-0">
                        {page2 === 0 &&
                          this.dataTableButton("success", "View All", () => {
                            this.report2(page2);
                          })}
                      </div> */}
                    </div>

                    <Datatable data={tableData} columnHeading={column} />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default UserUnder;
