import React, { Component } from 'react';
import { Datatable } from '../../../components/Datatable';
import CmsContent from '../../../MiddleWare/CmsContent';
import SingleSelect from "../../../components/Form/SingleSelect";
import FormMiddleWare from '../../../components/Form/FormMiddleware';
import { PDFDownloadLink } from '@react-pdf/renderer';
import LoginModal from '../../../components/Modal/Modal';
import CmsCertificate from './cmsCertificate';
class Quiztable extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      customerid: localStorage.getItem('userId'),
      serviceid: localStorage.getItem('currentService'),
      page2: 0,
      customerId: localStorage.getItem("userId")
    };
  }

  async componentWillMount() {
    try {
      let QuizList = await CmsContent.getTwoConditionedValue('tbl_question', 'customerid', this.state.customerid, 1, 1, "id as value ,quizName as label,contentJson");

      let data = await CmsContent.getquizlog();

      this.setState({ data: data.data, QuizList: QuizList.data });
    } catch (error) {
      console.error(error);
    }
  }
  column = [
    {
      Header: 'Username',
      accessor: 'label',
      Cell: d => this.firstFunction(d.original, d.original.label)
    },
    {
      Header: 'Attempts',
      accessor: 'count',
      Cell: d => this.firstFunction(d.original, d.original.count)
    },
    {
      Header: 'Score',
      accessor: 'score',
      Cell: d => this.firstFunction(d.original, d.original.score)
    },
    {
      Header: 'Total Questions',
      accessor: 'total',
      Cell: d => this.firstFunction(d.original, d.original.total)
    }
  ];
  column1 = [
    {
      Header: 'Username',
      accessor: 'label',
    },
    {
      Header: 'Pre-Score',
      accessor: 'score',
    },
    {
      Header: 'Post-Score',
      accessor: 'posttotal',
    },
    {
      Header: 'Total Questions',
      accessor: 'total',
    },
    {
      Header: 'Attended on',
      accessor: 'time',
      Cell: d => this.secondFunction(d.original)
    },
    {
      Header: 'Pre/Post',
      accessor: 'time',
      Cell: d => d.original.prepost ? "Pre & Post" : "Pre"
    },
    {
      Header: 'View Answer',
      accessor: 'view',
      Cell: d => this.dataTableButton('info', 'View', () => {
        this.ViewOne(d.original);
      })
    },
    {
      Header: 'Certificate',
      accessor: 'certificate',
      Cell: d => this.deside(d)
    }
  ];
  column2 = [
    {
      Header: 'Question',
      accessor: 'question',
    },
    {
      Header: 'Pre-Response',
      accessor: 'answer'
    },
    {
      Header: 'Post-Response',
      accessor: 'postanswer',
    },
    {
      Header: 'Correct Answer',
      accessor: 'correct',
    },
  ];
  deside = d => {
    let val = d.original.posttotal * 100 / d.original.total;  
    if (val >= 60) {
      return (this.dataTableButton('success', 'View', () => {
        this.ViewCertificate(d.original);
      }))
    } else {
      return <center>Incomplete</center>
    }
  };
  ViewCertificate = d => {
    window.open(`CmsCertificate/${btoa(this.state.selectQuiz.label)}/${btoa(d.label)}/${btoa(d.time)}/${btoa(d.score * 100 / d.total)}/${btoa(d.posttotal * 100 / d.total)}`, "_blank");
  }
  ViewOne = (d) => {
    let answer = JSON.parse(d.answerJson)
    let question = JSON.parse(this.state.selectQuiz.contentJson);
    let postAnswer = JSON.parse(d.postAns)

    let finalSingleValue = [];
    question.map((ival, i) => {
      if (ival.type === 'multiple') {
        let ans1 = [];
        answer[i].answer.map((jval, j) => {
          let ans = jval.split('option').pop();
          ans1.push(ival.options[ans - 1]);
        });
        let postAns = [];
        if (postAnswer && postAnswer[i].answer) {
          postAnswer[i].answer.map((jval, j) => {
            let ans = jval.split('option').pop();
            postAns.push(ival.options[ans - 1]);
          });
          postAns = postAns.toString();
        } else {
          postAns = '-';
        }

        let actualAnswer = [];       
        if(ival.answer){
        ival.answer.split(',').map((jval, j) => {
          let cA = jval.split('option').pop();
          actualAnswer.push(ival.options[cA - 1]);
        });
        }

        finalSingleValue.push({ question: ival.question, answer: ans1.toString().toLowerCase(), correct: actualAnswer.toString().toLowerCase(), postanswer: postAns, result: ans1.toString().toLowerCase() === actualAnswer.toString().toLowerCase() ? 'Correct' : "Wrong" });
      } else {
        let postAns = (postAnswer &&  postAnswer[i]&& postAnswer[i].answer) ? postAnswer[i].answer.toLowerCase() : '-';
        finalSingleValue.push({ question: ival.question, answer: answer[i].answer.toLowerCase(), correct: ival.answer.toLowerCase(), postanswer: postAns, result: answer[i].answer.toLowerCase() == ival.answer.toLowerCase() ? 'Correct' : "Wrong" })
      }
    });
    this.setState({ finalSingleValue, page2: 2, userName: d.label, prePost: d.prepost ? 'Pre' : 'Post', date: this.secondFunction(d) });
  }
  firstFunction = (d, value) => {
    return <a href="#" onClick={() => { this.doFunction(d) }}>{value}</a>
  }
  secondFunction(date) {
    let d = new Date(date.time),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('-');
  }

  doFunction = async (d) => {
    try {
      let selectQuiz = this.state.selectQuiz;
      let singleUser = await CmsContent.getFreedom(
        'tbl_user_web.userName as label,tbl_quizAnswer.userId as value,tbl_quizAnswer.total as score,tbl_quizAnswer.totalQue as total,tbl_quizAnswer.createdAt as time,tbl_quizAnswer.quizanswer as answerJson,tbl_quizAnswer.prepost,tbl_quizAnswer.postanswer as postAns,tbl_quizAnswer.posttotal',
        'tbl_quizAnswer,tbl_user_web',
        `tbl_user_web.id=tbl_quizAnswer.userId and tbl_quizAnswer.quizId=${selectQuiz.value} and tbl_quizAnswer.userId=${d.value} and tbl_quizAnswer.serviceid=${this.state.serviceid}`,
        'tbl_quizAnswer.id',
        'tbl_quizAnswer.id DESC');
      this.setState({ page2: 1, singleUser: singleUser.data });
    } catch (error) {
      console.error(error);
    }
  }
  resetState = () => this.setState({ page2: 0, singleUser: [], finalSingleValue: [], userName: [], date: [] })
  selectQuiz = async selectQuiz => {
    try {
      let UserList = await CmsContent.getFreedom(
        'tbl_user_web.userName as label,tbl_quizAnswer.userId as value, count(tbl_quizAnswer.userId) as count,tbl_quizAnswer.total as score,tbl_quizAnswer.totalQue as total',
        'tbl_quizAnswer,tbl_user_web',
        `tbl_user_web.id=tbl_quizAnswer.userId and tbl_quizAnswer.quizId=${selectQuiz.value} and tbl_quizAnswer.serviceid=${this.state.serviceid}`,
        'tbl_quizAnswer.userId',
        'tbl_quizAnswer.id DESC');
      this.resetState();
      this.setState({ selectQuiz, UserList: UserList.data });
    } catch (error) {
      console.error(error);
    }
  };
  OnBack = val => {
    this.setState({ page2: val - 1 })
  }
  render() {
    const { UserList, QuizList, page2, singleUser, finalSingleValue, userName, prePost, date } = this.state;
    if (page2 === 0) {
      var tableData = UserList;
      var column = this.column;
    } else if (page2 === 1) {
      var tableData = singleUser;
      var column = this.column1;
    } else if (page2 === 2) {

      var tableData = finalSingleValue;
      var column = this.column2;
    }
    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3>Quiz table</h3>
                </div>
                <div className="card-body" style={{ minHeight: 350 }}>
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="exampleInputEmail1">Select Quiz</label>
                    </div>
                    <div className="col-sm-5">
                      <SingleSelect options={QuizList} handleChange={this.selectQuiz} value={this.state.box} placeholder="Select Quiz" />
                    </div>
                    <div className="col-sm-3" />
                  </div>
                  <div className="row form-group ml-0 mb-0">
                    <div className="col-sm-1 p-0" >
                      {page2 !== 0 && this.dataTableButton('danger', 'Back', () => {
                        this.OnBack(page2);
                      })}
                    </div>
                    {/* <PDFDownloadLink
                      style={{ color: 'white' }}
                      document={<QuizCertificate />}
                      fileName="sample.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Download Pdf'
                      }
                      <button className="btn btn-success">
                        Download Table Pdf
                    </button>
                    </PDFDownloadLink> */}
                    <div className="col-sm-11 h2"><center>{page2 === 2 && `${userName}'s ${prePost} response on ${date}`}</center></div>
                  </div>
                  {tableData && <Datatable data={tableData} columnHeading={column} />}


                </div>
              </div>
            </div>
          </div>
        </div>
      </main >
    );
  }
}

export default Quiztable;
