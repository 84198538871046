import React, { Component } from "react";
import cmsContent from "../../../MiddleWare/CmsContent";
import { Alert } from "reactstrap";
import User from "../../../MiddleWare/User";
import { ACCESS_POINT } from "../../../config";
class AppLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      applogo: [],
      logoname: "Select Image",
      errorlogo: null,
      errorimage: null,
      customerId: localStorage.getItem("userId"),
      image: [],
      imagename: "Select Image",
      alertVisible: false,
      updatechange: "",
      imageview: null,
      dashimgview: null,
      beforeviewimage: "",
      beforeviewapplogo: "",
      binaryFileType: false
    };
  }
  async componentWilUnMount() {
    window.top.location.href = "";
  }
  async componentDidMount() {
    const getdashbordlogoupdate = await User.getUserById(
      localStorage.getItem("userId")
    );
    
    await this.setState({
      linktext: getdashbordlogoupdate.data[0].link,
      beforeviewimage:
        ACCESS_POINT +
        "/superAdmin/file?fileurl=" +
        getdashbordlogoupdate.data[0].image
    });

    await this.setState({
      beforeviewapplogo:
        ACCESS_POINT +
        "/superAdmin/file?fileurl=" +
        getdashbordlogoupdate.data[0].applogo
    });
  }

  async fileCheck(userfile) {
    var that = this;
    var binaryFileType = false;
    const file = userfile;
    const filereader = new FileReader();
    filereader.onloadend = function(evt) {
      if (evt.target.readyState === FileReader.DONE) {
        const uint = new Uint8Array(evt.target.result);
        let bytes = [];
        uint.forEach(byte => {
          bytes.push(byte.toString(16));
        });
        const hex = bytes.join("").toUpperCase();
        //alert(hex);
        if (that.getMimetype(hex) != "Unknown filetype") {
          that.setState({ binaryFileType: true });
        }
        // alert(binaryFileType);
      }
    };
    const blob = file.slice(0, 4);
    filereader.readAsArrayBuffer(blob);
  }

  appLogo = e => {
    var filesData = e.target.files[0];
    var filesDataLength = e.target.files;
    var filesDataName = filesData.name;

    var that = this;
    var binaryFileType = false;
    const file = e.target.files[0];
    const filereader = new FileReader();
    filereader.onloadend = function(evt) {
      if (evt.target.readyState === FileReader.DONE) {
        const uint = new Uint8Array(evt.target.result);
        let bytes = [];
        uint.forEach(byte => {
          bytes.push(byte.toString(16));
        });
        const hex = bytes.join("").toUpperCase();
        //alert(hex);
        if (that.getMimetype(hex) != "Unknown filetype") {
          binaryFileType = true;
        }
      }

      if (!binaryFileType) {
        that.setState({
          errorlogo: "Please Select a Valid Image",
          appLogosize: 0
        });
        // return false;
        return false;
      } else {
        that.setState({ appLogosize: filesData.size });

        that.setState({
          // appLogosize: e.target.files[0].size,
          applogo: filesData,
          logoname: filesDataName,
          errorlogo: "",
          binaryFileType: false,
          //logosize: e.target.files[0].size,
          beforeviewapplogo: ""
        });
        if (!filesDataLength.length) return;
        that.createImage(filesDataLength[0]);
      }
    };
    const blob = file.slice(0, 4);
    filereader.readAsArrayBuffer(blob);
  };

  createImage(file) {
    let reader = new FileReader();
    reader.onload = e => {
      this.setState({
        imageview: e.target.result
      });
    };
    reader.readAsDataURL(file);
  }

  image = e => {
    // this.setState({
    //   image: e.target.files[0],
    //   imagename: e.target.files[0].name,
    //   imagesize: e.target.files[0].size,
    //   beforeviewimage: ""
    // });
    // let files = e.target.files;
    // if (!files.length) return;
    // this.createImages(files[0]);
    var fileData = e.target.files[0];
    var fileDataLength = e.target.files;
    var fileDataName = fileData.name;

    var that = this;
    var binaryFileType = false;
    const file = e.target.files[0];
    const filereader = new FileReader();
    filereader.onloadend = function(evt) {
      if (evt.target.readyState === FileReader.DONE) {
        const uint = new Uint8Array(evt.target.result);
        let bytes = [];
        uint.forEach(byte => {
          bytes.push(byte.toString(16));
        });
        const hex = bytes.join("").toUpperCase();
        if (that.getMimetype(hex) != "Unknown filetype") {
          binaryFileType = true;
        }
      }
      // if (this.state.imagesize > 250000) {
      //   this.setState({
      //     errorimage: "Please Select a file size below 250kb",
      //     errorimage: ""
      //   });
      //   return false;
      // }
      if (!binaryFileType) {
        that.setState({
          errorimage: "Please Select a Valid Image",
          imagesize: 0
        });

        // return false;
        return false;
      } else {
        that.setState({ imagesize: fileData.size });

        that.setState({
          image: fileData,
          imagename: fileDataName,
          errorimage: "",
          binaryFileType: false,
          //logosize: e.target.files[0].size,
          beforeviewimage: ""
        });
        if (!fileDataLength.length) return;
        that.createImages(fileDataLength[0]);
      }
    };
    const blob = file.slice(0, 4);
    filereader.readAsArrayBuffer(blob);
  };
  createImages(file) {
    let reader = new FileReader();
    reader.onload = e => {
      this.setState({
        dashimgview: e.target.result
      });
    };
    reader.readAsDataURL(file);
  }
  onSubmit = async () => {
    const {
      logoname,
      applogo,
      customerId,
      image,
      imagename,
      linktext
    } = this.state;
    if (applogo.length === 0) {
      // this.setState({ errorlogo: 'Please Select Image' });
      // return false;
    }
    if (image.length === 0) {
      // this.setState({ errorimage: 'Please Select Image' });
      // return false;
    }
    if (this.state.imagesize > 250000) {
      this.setState({
        errorimage: "Please Select a file size below 250kb"
      });
      return false;
    }
    if (this.state.appLogosize > 250000) {
      this.setState({
        errorlogo: "Please Select a file size below 250kb"
      });
      return false;
    }

    if (image.length != 0) {
      

      const formDatas = new FormData();
      formDatas.append("id", customerId);
      formDatas.append("image", image);
     
      const result_2 = await cmsContent.updateauthor("tbl_user_web", formDatas);

      if (result_2) {
        
        const getdashbordlogoupdate = await User.getUserById(
          localStorage.getItem("userId")
        );
       
        localStorage.setItem(
          "userlog",
          JSON.stringify(getdashbordlogoupdate.data[0])
        );
       

       // this.componentWilUnMount();
        this.setState({
          updatechange: getdashbordlogoupdate.data[0].image,
          image: [],
          imagename: null,
          errorlogo: null,
          errorimage: null,
          alertVisible: true

          // textalert: ' Dash Board Logo Added',
          // color: 'success'
        });
      }
      setTimeout(() => this.setState({ alertVisible: false }), 3000);
    }

    try {
      if (applogo.length != 0) {
        
        const Formdata = new FormData();
        Formdata.append("id", customerId);
        Formdata.append("applogo", applogo);
        
        
        const result = await cmsContent.updateauthor("tbl_user_web", Formdata);

        if (result) {
          
          this.setState({
            applogo: [],
            logoname: null,
            errorlogo: null,
            errorimage: null,
            alertVisible: true
          });
        }
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      if (linktext.length != 0 && this.is_url(linktext) == true) {
        

        this.setState({ errortext: "" });
      } else {
        
        this.setState({ errortext: "Please enter a valid Link" });
        return false;
      }

      if (linktext.length != 0) {
        
        const Formdata1 = new FormData();
        Formdata1.append("id", customerId);
        // Formdata1.append("applogo", applogo);
        Formdata1.append("link", linktext);
        
        const result = await cmsContent.updateauthor("tbl_user_web", Formdata1);
        if (result) {
          
          this.setState({
            alertVisible: true,
            linktext: ""
          });
        }
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  link = e => {
    
    this.setState({ linktext: e.target.value });
  };

  is_url(str) {
    var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (regexp.test(str)) {
    
      return true;
    } else {
      
      return false;
    }
  }

  onDismiss = () => {
    this.setState({ alertVisible: false });
  };

  getMimetype(signature) {
    switch (signature) {
      case "89504E47":
        return "image/png";
      case "47494638":
        return "image/gif";
      // case "25504446":
      //   return "application/pdf";
      case "FFD8FFDB":
        return "image/jpg";
      case "FFD8FFE0":
        return "image/jpeg";
      // case "504B0304":
      //   return "application/zip";
      // case "4D5A900":
      //   return "image/exe";
      default:
        return "Unknown filetype";
    }
  }

  render() {
    const {
      logoname,
      errorlogo,
      imagename,
      errorimage,
      alertVisible,
      errortext,
      linktext
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-7">
                      <Alert
                        className="badge-content"
                        color="success"
                        isOpen={alertVisible}
                        toggle={this.onDismiss}
                      >
                        Submitted Successfully
                      </Alert>
                    </div>
                  </div>
                  <div className="card-header">
                    <h1>AppLogo</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">
                          Upload AppLogo
                        </label>
                      </div>

                      <div className="col-sm-5">
                        <input
                          type="file"
                          id="images"
                          accept="image/*"
                          onChange={this.appLogo}
                        />
                        <label
                          className="custom-file-label lblcross"
                          htmlFor="images"
                        >
                          <span style={{ fontSize: "12px" }}>{logoname}</span>
                        </label>
                        <span className="error-shows ">
                          {" "}
                          <br /> <br />
                          {errorlogo}
                        </span>
                        <span
                          className="col-sm-3 "
                          style={{ fontSize: 12, color: "#007bff" }}
                        >
                          Prescribed Size : 100x45 px
                        </span>
                      </div>
                      <div className="col-sm-3">
                        {this.state.imageview && (
                          <img
                            src={this.state.imageview}
                            alt="logo"
                            height="100px"
                            width="100px"
                          />
                        )}
                        {this.state.beforeviewapplogo && (
                          <img
                            src={this.state.beforeviewapplogo}
                            alt="logo"
                            height="100px"
                            width="100px"
                          />
                        )}
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">
                          Dash Board Logo
                        </label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="file"
                          id="imagess"
                          accept="image/*"
                          onChange={this.image}
                        />

                        <label
                          className="custom-file-label lblcross"
                          htmlFor="imagess"
                        >
                          <span style={{ fontSize: "12px" }}>{imagename}</span>
                        </label>
                        <span className="error-shows ">
                          {" "}
                          <br /> <br />
                          {errorimage}
                        </span>
                        <span
                          className="col-sm-3 "
                          style={{ fontSize: 12, color: "#007bff" }}
                        >
                          Prescribed Size : 230x76 px
                        </span>
                      </div>
                      {/* <div className="col-sm-3" style={{ fontSize: 12, marginTop: 12 }}>
                        Prescribed Size : 840x840 px
                      </div> */}
                      <div className="col-sm-3">
                        {this.state.dashimgview && (
                          <img
                            src={this.state.dashimgview}
                            alt="logo"
                            height="100px"
                            width="100px"
                          />
                        )}
                        {this.state.beforeviewimage && (
                          <img
                            src={this.state.beforeviewimage}
                            alt="logo"
                            height="100px"
                            width="100px"
                          />
                        )}
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">Link </label>
                      </div>
                      <div className="col-sm-5">
                        <textarea
                          type="text"
                          className="form-control"
                          // placeholder={linktext}
                          onChange={this.link}
                          value={linktext}
                        />
                        <span className="error-shows ">{errortext}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.onSubmit}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default AppLogo;
