import React, { Component } from 'react';
import CmsContent from '../../../MiddleWare/CmsContent';

class LearningPath extends Component {
  render() {
    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h1>Mentors</h1>
                </div>
                <div className="card-body">

                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2"></div>
                    <div className="col-sm-5">
                      hello
                    </div>
                    <div className="col-sm-3" />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default LearningPath;