import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
const loginpdf = ({
  header,
  data,
  title = "User Logs-March 15,2020 to June 3,2020"
}) => {
  console.log("header", header);
  console.log("data", data);

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.title}>
          <Text>{title}</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            {header &&
              header.map(value => {
                return (
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
          </View>
          {data &&
            data.map((value, index) => {
              console.log("get", value);
              return (
                <View style={styles.tableRow}>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{value.userName}</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{value.ipAddress}</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{value.maxtime}</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{value.entrytime}</Text>
                  </View>
                  <View style={styles.tableColHeader}>
                    <Text style={styles.tableCell}>{value.datetime}</Text>
                  </View>

                </View>
              );
            })}
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: {
    marginTop: "5%",
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row"
  },
  tableColHeader: {
    width: "16.4%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize: 55,
    fontWeight: "bold"
  },
  tableCol: {
    width: "30%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: "auto",
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: "auto",
    fontSize: 10
  },
  title: {
    fontSize: 15,
    fontWeight: "bold",
    marginLeft: "25%"
  }
});

export default loginpdf;
