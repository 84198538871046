
import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import cmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import Addauthormodel from "./Addauthormodel";
import { Alert } from "reactstrap";
import { ACCESS_SAMPLE } from "../../../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import AddpagePDF from "../../../components/PDF/Addpagespdf";

//import Theme9 from './addPages/theme9';
import Datatable from "../../../components/Datatable/Datatable";
import { FormMiddleWare } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";
import Paginationfun from "../pagination";
class RecoverDealers extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            authorlist: [],
            checked: 0,
            customerId: localStorage.getItem("userId"),
            errorPageName: null,
            data: [],
            MOlist: [],
            Saleslist: [],
            alertVisible1: false,
            btnDisable: false,
            formAlert: false,
            formBtn: false,
            themeSelected: "",
            screen: window.screen.height,
            danger: "btn btn-danger",
            success: "btn btn-success",
            home: "Home",
            set: "SetusHome",
            pageNumber: 1,
            SelectedEnquiries: [],
            bulkAllocate: false,
            unActiveDealer: false
        };
    }
    column = [
        {
            Header: "DEALER ID",
            accessor: "employeeId",
        },
        {
            Header: "DEALER SHOP NAME",
            accessor: "dealerShopName",
        },
        {
            Header: "IP ADDRESS",
            accessor: "clientIP",
            // Cell: (d) => this.getValueFromArray(d.original.moid, this.state.MOlist),
        },
        {
            Header: "DATE & TIME",
            accessor: "currentDateTime",
            // Cell: (d) => this.getValueFromArray(d.original.salesid, this.state.Saleslist, 'sales_code'),
        },
        // {
        //     Header: "EMAIL",
        //     accessor: "Email",
        // },
        // {
        //     Header: "MOBILE",
        //     accessor: "Mobile",
        // },
        // {
        //     Header: "SITE",
        //     Cell: (d) => this.ViewSite(d),
        // },
        // {
        //     Header: "EDIT",
        //     accessor: "edit",
        //     Cell: (d) => this.Editpages(d),
        // },
        // {
        //     Header: "STATUS",
        //     // accessor: "edit",
        //     Cell: (d) => this.buttondelstatus(d),
        // },
        // {
        //     Header: "DELETE",
        //     // accessor: "edit",
        //     Cell: (d) => this.buttondel(d),
        // },
        // {
        //     Header: "Delete",
        //     accessor: "edit",
        //     Cell: (d) => this.DatatableCheckboxx(d, this.updateMyData, this.state.SelectedEnquiries)
        // }


    ];
    // buttondelstatus = (d) => {
    //     if (d.original.status == 0) {
    //         return (
    //             <button
    //                 className="btn btn-success"
    //             // onClick={() => this.Delete(d)}
    //             >Active</button>
    //         );
    //     } else {
    //         return (
    //             <button
    //                 className="btn btn-danger"

    //                 onClick={() => this.Recover(d)}
    //             >InActive</button>
    //             // <span className="label">Deleted</span>
    //         )

    //     }
    // }
    // Recover = d => {
    //     confirmAlert({
    //         customUI: ({ onClose }) => {
    //             return <div style={{ textAlign: "center" }}>
    //                 <h1>Recover confirmation</h1>
    //                 <h4>Are you sure ?</h4><br />
    //                 <button className="btn btn-primary" onClick={() => {
    //                     this.RecoverData(d.original)
    //                     onClose();
    //                 }}  >Yes</button> &nbsp;  &nbsp;
    //                 <button className="btn btn-danger" onClick={onClose} >No</button>
    //             </div>
    //         }
    //     })
    // };
    // RecoverData = async (e) => {
    //     console.log(e, "ee");
    //     let { SelectedEnquiries } = this.state

    //     const RecoverUser = await cmsContent.recoverDealer("cmndl_tbl_dealer", e)
    //     console.log(RecoverUser, "RecoverUser");
    //     if (RecoverUser) {
    //         // this.MainFun()
    //         setTimeout(() => {
    //             window.location.reload()
    //         }, 3000);



    //     }


    // }
    // DatatableCheckboxx = (d, func, SelectedEnquiries) => {
    //     // console.log(d.original.id, "SelectedEnquiries[d.original.id]");
    //     if (d.original.status == 0) {
    //         return (
    //             <div style={{ 'text-align': 'center' }}>
    //                 <input type="checkbox"
    //                     checked={SelectedEnquiries && SelectedEnquiries[d.original.id] &&
    //                         SelectedEnquiries[d.original.id] == true ? true : false}
    //                     onChange={(event) => func(parseInt(d.original.id), d.column.id, event.target.checked ? "Yes" : "No")} />
    //             </div>)
    //     } else {
    //         return (
    //             <></>
    //         )
    //     }
    // };
    // updateMyData = async (d, f, event) => {
    //     let { SelectedEnquiries } = this.state
    //     console.log(d, "d", event, "event", f, "f");
    //     console.log(SelectedEnquiries, "SelectedEnquiries");
    //     let AllocateType = "Delete"
    //     const index = SelectedEnquiries.indexOf(d)
    //     console.log(index, "index");
    //     if (index > -1 && event == "No") {
    //         SelectedEnquiries.splice(index, 1)
    //         SelectedEnquiries[d] = false
    //         if (index == 0) {
    //             SelectedEnquiries.length = 0;
    //             this.setState({ bulkAllocate: false })

    //         }

    //     }
    //     else {
    //         SelectedEnquiries.push(d)
    //         SelectedEnquiries[d] = true
    //         this.setState({ bulkAllocate: true })
    //     }
    //     this.setState({ SelectedEnquiries, AllocateType })

    // }
    // selectalldata = async (event) => {
    //     console.log(event, event);
    //     let { data } = this.state
    //     let SelectedEnquiries = []
    //     console.log(SelectedEnquiries, "SelectedEnquiries");
    //     console.log(data, "data");

    //     if (event == "Yes") {
    //         for (let index = 0; index < 10; index++) {
    //             const element = data[index];
    //             if (element.status == 0) {
    //                 SelectedEnquiries[element.id] = true
    //                 SelectedEnquiries.push(element.id)
    //             }
    //         }

    //         await this.setState({ isSelectUnallocated: true })
    //         // console.log(SelectedEnquiries.length, "SelectedEnquiries length");
    //     }

    //     else {
    //         await this.setState({ isSelectUnallocated: false })
    //     }
    //     await this.setState({ SelectedEnquiries })
    // };
    // confirmDeleteT = async (e) => {
    //     console.log(e, "ee");
    //     let { SelectedEnquiries } = this.state
    //     console.log(SelectedEnquiries, "SelectedEnquiries");
    //     if (SelectedEnquiries) {
    //         // const filteredData = Object.fromEntries(
    //         //     Object.entries(SelectedEnquiries).filter(([key, value]) => value === true)
    //         // );

    //         const keysWithTrueValues = Object.keys(SelectedEnquiries)
    //             .filter(key => SelectedEnquiries[key] === true)
    //             .map(Number);

    //         console.log(keysWithTrueValues);
    //         const DeleteMultyUser = await cmsContent.DeleteMultyDealer("cmndl_tbl_dealer", keysWithTrueValues)
    //         console.log(DeleteMultyUser, "DeleteMultyUser");
    //         if (DeleteMultyUser) {
    //             this.setState({ bulkAllocate: false, SelectedEnquiries: [], isSelectUnallocated: false })
    //             // this.MainFun()

    //             setTimeout(() => {
    //                 window.location.reload()
    //             }, 3000);
    //         }

    //     }
    // }
    // confirmDeleteP = async (e) => {
    //     console.log(e, "ee");
    //     let { SelectedEnquiries } = this.state
    //     console.log(SelectedEnquiries, "SelectedEnquiries");
    //     if (SelectedEnquiries) {
    //         // const filteredData = Object.fromEntries(
    //         //     Object.entries(SelectedEnquiries).filter(([key, value]) => value === true)
    //         // );

    //         const keysWithTrueValues = Object.keys(SelectedEnquiries)
    //             .filter(key => SelectedEnquiries[key] === true)
    //             .map(Number);

    //         console.log(keysWithTrueValues);
    //         const DeleteMultyUser = await cmsContent.DeletePermanentMultyDealer("cmndl_tbl_dealer", keysWithTrueValues)
    //         console.log(DeleteMultyUser, "DeleteMultyUser");
    //         if (DeleteMultyUser) {
    //             this.setState({ bulkAllocate: false, SelectedEnquiries: [], isSelectUnallocated: false })
    //             // this.MainFun()

    //             setTimeout(() => {
    //                 window.location.reload()
    //             }, 3000);
    //         }

    //     }
    // }
    // multyDelete = d => {
    //     confirmAlert({
    //         customUI: ({ onClose }) => {
    //             return <div style={{ textAlign: "center" }}>
    //                 <h1>Delete confirmation</h1>
    //                 <h4>Are you sure you want to Delete this temporary ?</h4><br />
    //                 <button className="btn btn-primary" onClick={() => {
    //                     this.confirmDeleteT(d)
    //                     onClose();
    //                 }}  >Yes</button> &nbsp;  &nbsp;
    //                 <button className="btn btn-danger" onClick={onClose} >No</button>
    //                 <br></br>
    //                 <br></br>

    //                 <h1>Delete confirmation</h1>
    //                 <h4>Are you sure you want to Delete this permanetly ?</h4><br />
    //                 <button className="btn btn-primary" onClick={() => {
    //                     this.confirmDeleteP(d)
    //                     onClose();
    //                 }}  >Yes</button> &nbsp;  &nbsp;
    //                 <button className="btn btn-danger" onClick={onClose} >No</button>
    //             </div>
    //         }
    //     })
    // };






    // showVisibility = (id) => {
    //     return (
    //         <button
    //             type="button"
    //             className="btn btn-info"
    //             onClick={() => this.updateVisibility(id)}
    //         >
    //             Hide/Show
    //         </button>
    //     );
    // };

    // changeLoginStatus = (id) => {
    //     return (
    //         <button
    //             type="button"
    //             class="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '10px' }}
    //             onClick={() => this.updateLogin(id)}
    //         >
    //             Change Login Status
    //         </button>
    //     );
    // };
    // buttondel = (d) => {
    //     if (d.original.status == 0) {
    //         return (
    //             <button

    //                 className="btn btn-danger"
    //                 onClick={() => this.Delete(d)}
    //             >Delete</button>
    //         );
    //     } else {
    //         return (<span className="label">Deleted</span>)

    //     }
    // }
    // Delete = d => {
    //     confirmAlert({
    //         customUI: ({ onClose }) => {
    //             return <div style={{ textAlign: "center" }}>
    //                 <h1>Delete confirmation</h1>
    //                 <h4>Are you sure you want to Delete this ?</h4><br />
    //                 <button className="btn btn-primary" onClick={() => {
    //                     this.HandleDelete(d.original)
    //                     onClose();
    //                 }}  >Yes</button> &nbsp;  &nbsp;
    //                 <button className="btn btn-danger" onClick={onClose} >No</button>
    //             </div>
    //         }
    //     })
    // };
    // HandleDelete = async (d) => {
    //     try {
    //         let obj = {}
    //         obj.status = Date.now()
    //         const result = await cmsContent.updateMaster(
    //             "cmndl_tbl_dealer",
    //             d.id,
    //             obj
    //         )
    //         if (result) {
    //             this.MainFun();
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }


    // Editpages = (value) => {
    //     if (value.original.status == 0) {
    //         return (
    //             <button className="btn btn-info" onClick={() => this.onEdit(value)}>
    //                 Edit
    //             </button>
    //         );

    //     } else {
    //         return (<span className="label">Deleted</span>)
    //     }
    // };
    // ViewSite = (value) => {
    //     if (value.original.status == 0) {
    //         return (
    //             <button className="btn btn-primary"
    //                 onClick={() => this.handleLogin(value.original.dealer_code)}
    //             // onClick={() => window.open(`http://localhost:3001/Vidya/Login/${value.original.dealer_code}`)}
    //             >
    //                 View site
    //             </button>
    //         );

    //     } else {
    //         return (<span className="label">Deleted</span>)
    //     }
    // };
    // handleLogin = async (value) => {
    //     try {
    //         const result = await cmsContent.coromandelHandlelogin(value)
    //         if (result) {
    //             // console.log(result);
    //             if (result.data.status == 'SUCCESS') {
    //                 window.open(result.data.url + value)
    //             }
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }
    // getValueFromArray1 = (d, array, ch = "value", ch1 = "label") => {
    //     if (array.length > 0) {
    //         if (array.length !== 0) {
    //             let filtered = array.filter(function (item) {
    //                 return item[ch] == d;
    //             });
    //             let v = filtered[0];
    //             if (v != undefined) {
    //                 return v;
    //             } else {
    //                 return '-';
    //             }
    //         }
    //     } else {
    //         return '-';
    //     }
    // };
    // onEdit = (value) => {
    //     let d = value.original;

    //     if (d.moid) {
    //         let MO = this.getValueFromArray1(d.moid, this.state.MOlist)
    //         // console.log(MO);
    //         this.setState({ MO })
    //     }
    //     if (d.salesid) {
    //         let Sales = this.getValueFromArray1(d.salesid, this.state.Saleslist, 'sales_code')
    //         // console.log(Sales);
    //         this.setState({ Sales })
    //     }
    //     this.setState({
    //         Name: d.dealer_name,
    //         Code: d.dealer_code,
    //         Email: d.Email,
    //         Mobile: d.Mobile,
    //         editId: d.id,
    //         isEdit: true
    //     });
    //     window.scrollTo({
    //         top: 0,
    //         left: 0,
    //         behavior: "smooth",
    //     });
    // };

    async componentDidMount() {
        this.MainFun()
    }
    MainFun = async () => {
        try {
            // this.setState({ loading: true })
            // let val = {}
            // val.pageNumber = this.state.pageNumber
            // const data = await cmsContent.GetCmdlDatas(this.state.customerId, "Dealer", val)
            // console.log(data.data, "data");
            // let datas=[]
            // let deleteData=[]
            // for (let ival of data.data) {
            //     if (ival.dealer_code != null) {
            //         await datas.push(ival)
            //     }
            // }
            // for (let ival of data.data) {
            //     if (ival.dealer_code != null && ival.status !== "0") {
            //         await deleteData.push(ival)
            //     }
            // }

            // await this.setState({
            //     totalpage: data.totalpage,
            //     totalrecords: data.totalrecords,
            //     MOlist: data.MOlist,
            //     Saleslist: data.Saleslist,
            //     data: datas,
            //     deleteData:deleteData
            // })
            // this.setState({ loading: false })
            const result = await cmsContent.GetJsonDatas("dealer")
            this.setState({ data: result })
        } catch (error) {
            console.log(error);
            this.setState({ loading: false })
        }
    }
    // handlechange = (e) => {
    //     this.setState({ [e.target.name]: e.target.value });
    // };



    // submitCategory = async () => {
    //     const {
    //         Code,
    //         Name, Email, Mobile, errorName, errorCode, errorMO, MO, Sales, isEdit, editId
    //     } = this.state;
    //     if (!MO) {
    //         this.setState({
    //             errorMO: "select name",
    //         });
    //         return false;
    //     }
    //     else if (!Sales) {
    //         this.setState({
    //             errorSales: "select Sales",
    //         });
    //         return false;
    //     }
    //     else if (!Code) {
    //         this.setState({ errorMO: "", errorCode: "enter  code" });
    //         return false;
    //     } else if (!Name) {
    //         this.setState({
    //             errorName: "enter name",
    //             errorCode: "",
    //         });
    //         return false;
    //     } else if (!Email) {
    //         this.setState({
    //             errorEmail: "enter Email",
    //             errorName: "",
    //         });
    //         return false;
    //     } else if (!Mobile) {
    //         this.setState({
    //             errorMobile: "enter Mobile Number",
    //             errorEmail: "",
    //         });
    //         return false;
    //     }

    //     let categoryArray = {}
    //     categoryArray.moid = MO.value;
    //     categoryArray.salesid = Sales.sales_code;
    //     categoryArray.sales_name = Sales.sales_name;
    //     categoryArray.dealer_code = Code;
    //     categoryArray.dealer_name = Name;
    //     categoryArray.Email = Email;
    //     categoryArray.Mobile = Mobile;
    //     categoryArray.customerid = this.state.customerId;
    //     // console.log([...formData]);
    //     console.log('loast', categoryArray);
    //     try {
    //         const result = isEdit ? await cmsContent.updateMaster(
    //             "cmndl_tbl_dealer",
    //             editId,
    //             categoryArray
    //         ) : await cmsContent.addMaster(
    //             "cmndl_tbl_dealer",
    //             categoryArray
    //         );
    //         if (result) {
    //             window.scrollTo({
    //                 top: 0,
    //                 behavior: "smooth"
    //             });
    //             this.setState({
    //                 Code: "",
    //                 Name: '', errorName: '', errorCode: '',
    //                 MO: '', errorMO: '', errorEmail: '', errorMobile: '',
    //                 Email: '',
    //                 Sales: '',
    //                 Mobile: '',
    //                 btnDisable: false,
    //                 alertVisible: true,

    //             });
    //             this.MainFun()
    //             setTimeout(() => this.setState({ alertVisible: false }), 3000);
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // handleSelect = (name, selectedOption) => {
    //     this.setState({
    //         [name]: selectedOption,
    //         //   themeId: selectedOption.value,
    //         //   themeName: selectedOption.label,
    //     });
    // };
    render() {
        const { MOlist, //marketing off
            Saleslist, unActiveDealer
        } = this.state;
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Deleted Dealers</h3>
                                    </div>
                                    <div className="card-body">
                                        {/* {unActiveDealer?
                                        <>
                                         <div className="d-flex justify-content-end">
                                                    <button type="button"
                                                        class="btn btn-primary waves-effect"
                                                        onClick={() => this.setState({ unActiveDealer: false })}
                                                    > View all Dealers </button>
                                            </div>
                                          <div className="row form-group">
                                            <div className="col-sm-12">
                                                <p>Total of {this.state.totalrecords ? this.state.totalrecords : '0'} records found , currently showing {this.state.deleteData.length ? this.state.deleteData.length : "0"} records only </p><br />
                                                {this.state.loading ? <div className="text-center">
                                                    < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                </div> : this.state.deleteData ?
                                                    <>
                                                        <Datatable
                                                            data={this.state.deleteData}
                                                            columnHeading={this.column}
                                                        />
                                                    </>
                                                    : null}

                                            </div>
                                        </div>
                                        </>
                                        :
                                        <> */}
                                        {/* <div className="d-flex justify-content-end">
                                                    <button type="button"
                                                        class="btn btn-primary waves-effect"
                                                        onClick={() => this.setState({ unActiveDealer: true })}                                                                    // onClick={(e) => this.multyDelete(e)}
                                                    >View Delete dealers only</button>
                                                </div> */}
                                        <br></br>
                                        <br></br>

                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                {/* <p>Total of {this.state.data? this.state.data : '0'} records found , currently showing {this.state.data.length ? this.state.data.length : "0"} records only </p><br /> */}
                                                <p style={{ fontSize: "20px", fontWeight: "500", padding: "3px 0px" }}>Total Number Of Deleted Dealers :{this.state.data ? <>{this.state.data.length}</> : "0"}</p>
                                                <br></br>

                                                {this.state.loading ? <div className="text-center">
                                                    < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                </div> : this.state.data ?
                                                    <>
                                                        <Datatable
                                                            data={this.state.data}
                                                            columnHeading={this.column}
                                                        />
                                                    </>
                                                    : null}

                                            </div>
                                        </div>
                                        {/* </>} */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {this.state.totalpage && this.state.totalpage > 1 && this.state.pageNumber && <Paginationfun
                        totalpage={this.state.totalpage}
                        page={this.state.pageNumber}
                        pagechage={async (e) => {
                            await this.setState({ pageNumber: e, loading: true, })
                            await this.MainFun()
                        }} />} */}
                </main>
            </React.Fragment>
        );
    }
}

export default RecoverDealers;
