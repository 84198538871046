import http from './httpMiddleWare';
import { PYTHON_ACCESS_POINT, ACCESS_POINT } from '../config';

export const objectTracking = async formData => {
  const result = await http.post(PYTHON_ACCESS_POINT + `/videoFrames`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return result;
};

export const objectEncoding = async formData => {
  const result = await http.post(PYTHON_ACCESS_POINT + `/objectEncoding`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return result;
};

export const videoEncoding = async formData => {
  const result = await http.post(PYTHON_ACCESS_POINT + `/videoEncoding`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return result;
};

export const imageUpload = async formData => {
  const result = await http.post(PYTHON_ACCESS_POINT + `/imageUpload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return result;
};

export const jsonDataRead = async formData => {
  const result = await http.post(ACCESS_POINT + `/cmsContent/jsonDataRead/json`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return result;
};
