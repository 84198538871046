import React, { Component } from "react";
import { Alert } from "reactstrap";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import { ACCESS_POINT } from "../../../../config";
import { ACCESS_SAMPLE } from "../../../../config";
import http from "../../../../MiddleWare/httpMiddleWare";
import Progress from "../../../../components/Extra/Progress";
import SingleSelect from "../../../../components/Form/SingleSelect";


class Theme10 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			uploadPercentage: 0,
			data:[] ,
			boxtype:[],
			isEdit:false,
			btnDisable:false,
			screen: window.screen.height,
			errorContentTitle1:'',
			searchTags:'',
			contentTitle1:'',
			boxtype:[],
			boxSelected:{},
			errorformName:''

		}
	}

 column = [
		{
			Header: "Page",
			accessor: "name"
		},
		{
			Header: "Status",
			accessor: "status"
		},
		{
			Header:"Theme",
			accessor:"edit",
			Cell:d=>this.showTheme(d)
		},
		{
			Header: "Visibility",
			accessor: "edit",
			Cell: d => this.showVisibility(d)
		},
		{
			Header: "Require Login",
			accessor: "requireLogin"
		},
		{
			Header: "Change Login Status",
			accessor: "edit",
			Cell: d => this.changeLoginStatus(d)
		},
		{
			Header: "Edit",
			accessor: "edit",
			Cell: d => this.Editpages(d)
		},
		{
			Header: "Preview",
			accessor: "edit",
			Cell: d => this.PreviewPage(d)
		}
	];
showTheme=d=>
{
	return `Theme-${d.original.themeId}`;
}
showVisibility = id => {
		return (
			<button
				type="button"
				className="btn btn-info"
				onClick={() => this.updateVisibility(id)}
			>
				Hide/Show
			</button>
		);
	};
	updateVisibility = async value => {
		const index = value.index;
		const previousData = [...this.state.data];
		//console.log(previousData);
		const newData = { ...previousData[index] };

		if (newData.status === "show") {
			newData.status = "hide";
		} else {
			newData.status = "show";
		}
		const id = newData.id;
		const data = previousData.filter(value => value.id !== id);
		data.splice(index, 0, newData);
		try {
			const result = await CmsContent.updatePage(newData);
			if (result) {
				this.setState({ data: data });
			}
		} catch (error) {
			this.setState({ data: previousData });
			console.log(error);
		}
	};

  changeLoginStatus = id => {
		return (
			<button
				type="button"
				className="btn btn-info btn-lg col-lg-12" style={{paddingLeft:5,marginBottom:'4px',fontSize:'10px'}}
				onClick={() => this.updateLogin(id)}
			>
				Change Login Status
			</button>
		);
	};

	updateLogin = async value => {
		const index = value.index;
		const previousData = [...this.state.data];
		const newData = { ...previousData[index] };
		if (newData.requireLogin === "yes") {
			newData.requireLogin = "no";
		} else {
			newData.requireLogin = "yes";
		}
		const id = newData.id;
		const data = previousData.filter(value => value.id !== id);
		data.splice(index, 0, newData);
		this.setState({ data });
		try {
			const result = await CmsContent.updateLoginStatus(newData);
			if (result) {
				this.setState({ data: data });
			}
		} catch (error) {
			this.setState({ data: previousData });
			console.log(error);
		}
	};


 PreviewPage = (d) => {

		return (<button
			type="button"
			className="btn btn-info"
			onClick={() => this.PreviewRoute(d)}
		>
			Preview
		</button>
		);
	}
PreviewRoute = d => {
    let id = d.original.id;
    let customerId = d.original.customerId;
    if (id) {
      window.open(
        `${ACCESS_SAMPLE}/samplesite/th10/${id}/${customerId}`,
        "_blank"
      );
    }
  };

Editpages = value => {
		return (
			<button className="btn btn-info" onClick={() => this.onEdit(value)}>
				Edit
			</button>
		);
	};
 onEdit =async(d) => {
	 const org = d.original;
	 const Index = d.index;
	 const editId=org.id;
	 console.log(d);
	 const options = [
		 { label: "Theme1", value: "1" },
		 { label: "Theme2", value: "2" },
		 { label: "Theme3", value: "3" },
		 { label: "Theme4", value: "4" },
		 { label: "Theme5", value: "5" },
		 { label: "Theme6", value: "6" },
		 { label: "Theme7", value: "7" },
		 { label: "Theme8", value: "8" },
		 { label: "Theme9", value: "9" }
	 ];
	 if (typeof this.props.these === 'function') {
			this.props.these('pageName', org.name);
			this.props.these('autherSelected', this.props.that.authorlist.filter(ival => ival.value === org.authorid)[0]);
		 this.props.these('themeSelected', options.filter(ival => ival.value == org.themeId)[0]);
			this.props.these('checked', org.show == 1 ? 1 : 0);
			this.props.these('errorPageName', '');
		}
		let arr={};
		this.state.boxtype.map((ival,i)=>{
			if(ival.value===parseInt(org.sheetContent)){
				//console.log(ival)
				arr.value=ival.value;
				arr.label=ival.label;
			}
		});
		this.setState({
			contentTitle1:org.contentTitle1,
			searchTags:org.searchTags,
			isEdit:true,
			boxSelected:arr,
			Index,
			editId
		})
		window.scrollTo({
			top: 0,
			left: 0,
		   behavior: "smooth"
		   });
 }

async componentWillMount() {
		const {customerId} = this.props.that;
    try{
		if (this.props.that.data) {
		     let pages = [];
				this.props.that.data.map((ival, i) => {
					if (ival.themeId === 10) {
						pages.push(ival);
					}
				})
			this.setState({ data: pages,for:this.props.that.sheetContent});
			this.cohorts();
		}
	}catch(error){
		console.log(error);
	}
}

updateCategory=async e =>{

e.preventDefault();
		const {
			checked,
			autherSelected,
			pageName,
			customerId,
      } = this.props.that;

	  const { contentTitle1,
			searchTags,
			boxSelected,
			errorContentTitle1,
  			Index,editId,
			isEdit} = this.state;

			if (!autherSelected) {
			this.props.these('errorAuthorName', 'Select Author')
			return false;
		} else if (!pageName) {
			this.props.these('errorAuthorName', '')
			this.props.these('errorPageName', 'Enter Page name')
			return false;
		} else if (!contentTitle1) {
			this.props.these('errorPageName', '');
			this.setState({ errorContentTitle1: 'Enter Content Title' });
			return false;
		}else if(boxSelected==null ||boxSelected.value==undefined ){
			console.log('1dgdg22');
			//this.props.these('errorPageName', '');
			this.props.these('errorAuthorName', '');
			this.setState({errorformName:'Select Portlet name'});
			return false;
		} else {
			this.setState({ errorContentTitle1: '' });
		}
		let today = new Date();
		let dateformat = today.getDate();
		if (today.getDate() < 10) {
			dateformat = "0" + today.getDate();
		}
		let date =
			today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + dateformat;
			const formData = new FormData();
			formData.append("name", pageName);
			formData.append("themeId", '10');
			formData.append("authorid", autherSelected.value);
			formData.append("contentTitle1", contentTitle1);
			formData.append("searchTags", searchTags);
			formData.append("show", checked);
			formData.append("sheetContent", boxSelected.value);
			formData.append("Date", date);
			formData.append("status", "show");
			formData.append("customerId", customerId);
			formData.append("requireLogin", 'yes');
			formData.append("id", editId);
			console.log([...formData]);
			 try{
		let {data} = await http.put(
			ACCESS_POINT + `/cmsContent/tbl_pages`,
			formData,
			{
			headers: {
			"Content-Type": "multipart/form-data"
			},
			onUploadProgress: progressEvent => {
			this.setState({
				uploadPercentage: parseInt(
				Math.round((progressEvent.loaded * 100) / progressEvent.total)
				)
			});
			setTimeout(() => this.setState({ uploadPercentage: 0 }), 5000);
	           }
			}
				);
				console.log(data)
				if (data && data.length) {
					data = data[0];
					//console.log(data);
					const previousData = [...this.state.data];
					previousData[Index] = data;
					// previousData[Index]=data;
					//console.log(previousData);
				setTimeout(() =>this.setState({
						contentTitle1: "",
						searchTags: "",
						boxSelected:[],
						data: previousData,
						isEdit:false
					}),5000);
					this.props.these('pageName', '');
					this.props.these('checked', 0);
					this.props.these('errorPageName', '')
					this.props.these('autherSelected', '');
					this.props.these('themeSelected', '')
				setTimeout(() =>this.props.these('alertVisible', true),5000)
				}
			 }catch(error){
				 console.log(error)
			 }
}

 cohorts = async () => {

  try{
	 const {customerId} = this.props.that;

	// let result = await CmsContent.getFreedom('id as value ,name as label','tbl_boxtype',1);
	 let result = await CmsContent.getFreedom(
        "id as value,name as label",
		"tbl_group",
        `customerId=${customerId}`,
      );
	  if(result){
		  console.log(result.data);
		  this.setState({boxtype:result.data});
	  }

	 result = await CmsContent.getFreedom(
	  '*',
	  'tbl_map_grouptoportlet',
	  `customerid=${customerId} and groupid=26`
	  )
	  if(result){
		  console.log(result.data);
	  }
  }catch(error){
	  console.log(error);
  }
 }

submitCategory=async(e)=>{
	e.preventDefault();
		const {
			checked,
			autherSelected,
			pageName,
			customerId,
      } = this.props.that;

	  const { contentTitle1,
			searchTags,
			boxSelected,
			errorContentTitle1 } = this.state;
			if (!autherSelected) {
			this.props.these('errorAuthorName', 'Select Author')
			return false;
		} else if (!pageName) {
			this.props.these('errorAuthorName', '')
			this.props.these('errorPageName', 'Enter Page name')
			return false;
		} else if (!contentTitle1) {
			this.props.these('errorPageName', '');
			this.setState({ errorContentTitle1: 'Enter Content Title' });
			return false;
		}else if(boxSelected==null ||boxSelected.value==undefined ){
			console.log('1dgdg22');
			//this.props.these('errorPageName', '');
			this.props.these('errorAuthorName', '');
			this.setState({errorformName:'Select Portlet name'});
			return false;
		} else {
			this.setState({ errorContentTitle1: '' });
		}
		let today = new Date();
		let dateformat = today.getDate();
		if (today.getDate() < 10) {
			dateformat = "0" + today.getDate();
		}
		let date =
			today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + dateformat;
			const formData = new FormData();
			formData.append("name", pageName);
			formData.append("themeId", '10');
			formData.append("authorid", autherSelected.value);
			formData.append("contentTitle1", contentTitle1);
			formData.append("searchTags", searchTags);
			formData.append("show", checked);
			formData.append("sheetContent", boxSelected.value);
			formData.append("Date", date);
			formData.append("status", "show");
			formData.append("customerId", customerId);
			formData.append("requireLogin", 'yes');
			console.log([...formData]);
			 try{
		let {data} = await http.post(
			ACCESS_POINT + `/cmsContent/theme/themeTen`,
			formData,
			{
			headers: {
			"Content-Type": "multipart/form-data"
			},
			onUploadProgress: progressEvent => {
			this.setState({
				uploadPercentage: parseInt(
				Math.round((progressEvent.loaded * 100) / progressEvent.total)
				)
			});
			setTimeout(() => this.setState({ uploadPercentage: 0 }), 5000);
	           }
			}
				);
				if (data && data.length) {
					data = data[0];
					//console.log(data);
					const previousData = [data, ...this.state.data];
					// previousData[Index]=data;
					//console.log(previousData);
				setTimeout(() =>this.setState({
						contentTitle1: "",
						searchTags: "",
						boxSelected:[],
						data: previousData,
					}),5000);
					this.props.these('pageName', '');
					this.props.these('checked', 0);
					this.props.these('errorPageName', '')
					this.props.these('autherSelected', '');
					this.props.these('themeSelected', '')
				setTimeout(() =>this.props.these('alertVisible', true),5000)
				}
			 }catch(error){
				 console.log(error)
			 }
}

handlechange=async e =>{
	this.setState({[e.target.name]:e.target.value});
}

handleForm=async d =>{
	console.log(d)
	this.setState({boxSelected:d})
}

render() {
	const { uploadPercentage }=this.state;
	return(
	    <React.Fragment>
		<div className="row form-group">
			<div className="col-sm-2" />
		<div className="col-sm-2">
			<label htmlFor="exampleInputEmail1">
			Content Title
            </label>
		 </div>
		<div className="col-sm-5">
			<textarea
			type="text"
			className="form-control"
			id="contentTitle1"
			name="contentTitle1"
			placeholder="Enter Content Title"
			value={this.state.contentTitle1}
			onChange={this.handlechange}
			/>
			</div>
			<div className="col-sm-3"/>
			</div>
	<div className="row form-group">
		<div className="col-sm-4" />
		<span className="error-show ">
		{this.state.errorContentTitle1}
		</span>
	</div>
  <div className="row form-group">
	<div className="col-sm-2" />
		<div className="col-sm-2">
			<label htmlFor="exampleInputEmail1">Search Tags</label>
		</div>
	<div className="col-sm-5">
		<textarea
			type="text"
			className="form-control"
			id="searchTags"
			name="searchTags"
			placeholder="Enter Search Tags"
			value={this.state.searchTags}
			onChange={this.handlechange}
			/>
		</div>
		<div className="col-sm-3" />
		</div>
		<div className="row form-group">
		<div className="col-sm-4" />
		<span className="error-show ">{this.state.errorSearchTags}</span>
		</div>
		<div className="row form-group">
				<div className="col-sm-2"/>
				<div className="col-sm-2">
				<label htmlFor="exampleInputEmail1">
					Cohorts List
                 </label>
					</div>
					<div className="col-sm-5">
						<SingleSelect
                          options={this.state.boxtype}
                          handleChange={d => this.handleForm(d)}
                          selectedService={this.state.boxSelected}
                        />
					</div>
				<div className="col-sm-3"/>
					</div>
					<div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{this.state.errorformName}</span>
                    </div>
	<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-7">
						{uploadPercentage > 0 && (
							<Progress percentage={uploadPercentage} />
						)}
					</div>
					<div className="col-sm-3" />
				</div>
	<div className="row form-group">
		<div className="col-sm-2" />
		<div className="col-sm-2" />
		<div className="col-sm-5">
		<button
			type="button"
			className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
			disabled={this.state.btnDisable}
			onClick={this.state.isEdit ? this.updateCategory : this.submitCategory}
			>
			{this.state.isEdit ? 'Update' : 'Add Page'}
			</button>
			</div>
			<div className="col-sm-3" />
			</div>
	    <div className="row form-group">
					<div className="col-sm-12">
						{this.state.data.length ? (<Datatable
							data={this.state.data}
							columnHeading={this.column}
						/>) : null}
					</div>
				</div>
			</React.Fragment>
	)
}
}
export default Theme10;