import React, { Component } from "react";
import { Link } from "react-router-dom";

class DifuzaNav extends Component {
  // async componentWillUnmount() {
  //   window.top.location.href = "";
  // }
  render() {
    let serviceId = localStorage.getItem("currentService");

    return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <li className="nav">
            <li className="nav-item">
              <Link className="nav-link" to={`/superAdmin`}>
                <i className="nav-icon icon-speedometer" />
                <span>Service Dashboard</span>
              </Link>
            </li>

            <li className="nav-item">
              <Link className="nav-link" to={`/Difuza/${serviceId}`}>
                <i className="nav-icon icon-speedometer" />
                <span>Dashboard</span>
              </Link>
            </li>
            <li className="nav-item nav-dropdown">
              <Link className="nav-link nav-dropdown-toggle" href="#">
                <i className="nav-icon icon-layers" /> Masters
              </Link>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/BoxTypes">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Portlet type</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/MapPortletToGroup">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Manage App Content</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/Group">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Cohorts</span>
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/DF/Manager">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Manager</span>
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/Mentors">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Mentors</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/Learners">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Learners</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/applogo">
                    <i className="nav-icon icon-calendar" />
                    <span className="link-white">App Logo</span>
                  </Link>
                </li>
                <li className="nav-item nav-dropdown">
                  <Link className="nav-link nav-dropdown-toggle" href="#">
                    <i className="nav-icon icon-layers" />
                    LOB Masters
                  </Link>
                  <ul className="nav-dropdown-items">
                    <li className="nav-item">
                      <Link className="nav-link" to="/DF/Program">
                        <i className="nav-icon fa fa-user" />
                        <span className="link-white">Program</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/DF/Batch">
                        <i className="nav-icon fa fa-user" />
                        <span className="link-white">Batch</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/DF/Domain">
                        <i className="nav-icon fa fa-user" />
                        <span className="link-white">Domain</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/DF/Location">
                        <i className="nav-icon fa fa-user" />
                        <span className="link-white">Location</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/DF/Resources">
                        <i className="nav-icon fa fa-user" />
                        <span className="link-white">Resources</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/DF/LearningObject" href="#">
                <i className="nav-icon icon-layers" />
                <span>LOB</span>
              </Link>
            </li>

            <li className="nav-item nav-dropdown">
              <Link className="nav-link nav-dropdown-toggle" href="#">
                <i className="nav-icon icon-layers" /> Mapping
              </Link>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/MappingToGroup">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Map User To Cohort</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/MentorToGroup">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Map Mentor To Cohort</span>
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link className="nav-link" to="/DF/ManagerToMentor">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Map Manager To Mentors</span>
                  </Link>
                </li> */}
              </ul>
              {/* <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/MapPortletToGroup">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Manage App Content</span>
                  </Link>
                </li>
              </ul> */}
            </li>

            <li className="nav-item nav-dropdown">
              <Link className="nav-link nav-dropdown-toggle" href="#">
                <i className="nav-icon icon-layers" /> Survey
              </Link>

              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/SurveyPage">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Survey</span>
                  </Link>
                </li>
              </ul>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/SurveyQuestion">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Survey Question</span>
                  </Link>
                </li>
              </ul>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/SurveyMapping">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Survey Mapping</span>
                  </Link>
                </li>
              </ul>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/SurveyReports">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Survey Report</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item nav-dropdown">
              <Link className="nav-link nav-dropdown-toggle" href="#">
                <i className="nav-icon icon-layers" /> Evaluation
              </Link>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/Evaluation" href="#">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Evaluation</span>
                  </Link>
                </li>
              </ul>

              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/Reports">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Evaluation Report</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item nav-dropdown">
              <Link className="nav-link nav-dropdown-toggle" href="#">
                <i className="nav-icon icon-layers" /> Form
              </Link>
              <ul className="nav-dropdown-items">
                <li className="nav-item nav-dropdown">
                  <Link className="nav-link" to="/DF/Forms">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Form</span>
                  </Link>
                </li>
              </ul>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/DF/FormReport">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Form Report</span>
                  </Link>
                </li>
              </ul>
            </li>

            {/* <li className="nav-item">
                <Link className="nav-link" to="/DF/Survey">
                  <i className="nav-icon fa fa-user" />
                  <span className="link-white">Survey</span>
                </Link>
              </li> */}

            <li className="nav-item">
              <Link className="nav-link" to="/DF/UserLogReport">
                <i className="nav-icon fa fa-user" />
                <span className="link-white">User Log Report</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/DF/LearningPath">
                <i className="nav-icon fa fa-user" />
                <span className="link-white">Learning Path Matrix</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/DF/performanceReport">
                <i className="nav-icon fa fa-flag-checkered" />
                <span className="link-white">Performance Reports</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/DF/RTReport">
                <i className="nav-icon fa fa-desktop" />
                <span className="link-white">Real Time</span>
              </Link>
            </li>
          </li>
        </nav>
      </div>
    );
  }
}
export default DifuzaNav;
