import 'uuid';

const serviceList = async serviceId => await localStorage.setItem('currentService', serviceId);

const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
};

const getUuid = () => {
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

export default {
  serviceList,
  getUuid
};
