import React, { Component } from "react";
import Icon from "react-icons-kit";
import imglogo from "../Login/img/Murugappa_Group_Logo_1576922020749.png";
import { mobilePhone } from "react-icons-kit/fa/mobilePhone";
import { BrowserRouter as Router } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from "reactstrap";
import login from "../../MiddleWare/Login";
import "../Login/Register.css";

export default class LoginModalPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertVisible: false,
      mobileNumber: "",
      errorMobileNumber: "",
      placeHolder: 1,
      show: 1
      //   para: this.props.para
    };
  }

  componentWillMount() {
    // console.log(this.state.para);
    document
      .getElementsByClassName("app")[0]
      .setAttribute("style", "background-color: white !important");
    document.getElementsByTagName("title")[0].innerHTML =
      "Murugappa Safety Architecture";
    localStorage.setItem("title", "Murugappa Safety Architecture");
  }

  componentDidMount() {
    console.log(this.state.para);
    document.getElementsByTagName("title")[0].innerHTML = localStorage.getItem(
      "title"
    );
  }

  handleUserInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleSubmit = async () => {
    let mobileNumber = this.state.mobileNumber;
    if (mobileNumber === "") {
      this.setState({ errorMobileNumber: "Enter Mobile Number" });
      return false;
    } else if (
      !/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/.test(mobileNumber)
    ) {
      this.setState({ errorMobileNumber: "Invalid Mobile Number" });
      return false;
    } else {
      this.setState({ errorMobileNumber: "",  actualNum: mobileNumber });
    }
    if (
      mobileNumber == 9500114666 ||
      // mobileNumber == 9500114776 ||
      mobileNumber == 9500114777 ||
      mobileNumber == 9959022134 ||
      mobileNumber == 9879879879 ||
      mobileNumber == 9500114888 ||
      mobileNumber == 6666666661
    ) {
      this.setState({ hideShow: false, errorMobileNumber: "" });
      const result = await login.loginCheck(mobileNumber);
      if (result) {
        localStorage.setItem("userlog", JSON.stringify(result.data[0]));
        let userlog = localStorage.getItem("userlog");
        userlog = JSON.parse(userlog);
        localStorage.setItem("userType", userlog.userType);
        if (parseInt(userlog.userType) === 1) {
          this.props.history.replace("/admin");
        } else if (parseInt(userlog.userType) === 2) {
          this.props.history.replace("/subAdmin");
        } else if (parseInt(userlog.userType) === 3) {
          //this.props.history.replace('/superAdmin');
          window.location.href = "/superAdmin";
        } else if (
          parseInt(userlog.userType) === 6 &&
          parseInt(userlog.serviceId) === 8
        ) {
          if (this.state.link) {
            window.location.href = this.state.link;
          } else {
            window.location.href = "/CMS/Home";
          }
          // this.props.history.replace('/CMS/Home');
        }
        // this.setState({ link: "" });
      }
    } else {
      try {
        const result = await login.loginOTPCheck(mobileNumber);
        if (result) {
          console.log(result);
          if (result.data.res) {
            this.setState({
              mobileNumber: "",
              errorMobileNumber: "Your OTP has been sent",
              placeHolder: 2
            });
          } else {
            this.setState({
              errorMobileNumber: "Mobile Number does not exists"
            });
          }
        } else {
          // this.setState({ showResend: true });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  handleSubmitOTP = async para => {
    let mobileNumber = this.state.mobileNumber;
    if (mobileNumber === "") {
      this.setState({ errorMobileNumber: "Enter OTP" });
      return false;
    } else {
      this.setState({
        errorMobileNumber: ""
      });
    }
    try {
      const result = await login.loginOTPCheckPost(
        this.state.actualNum,
        mobileNumber
      );
      if (result) {
        if (result.data.length) {
          this.setState({ hideShow: false, show: true });
          localStorage.setItem("userlog", JSON.stringify(result.data[0]));
          let userlog = localStorage.getItem("userlog");
          userlog = JSON.parse(userlog);
          localStorage.setItem("userType", userlog.userType);

          if (this.state.link) {
            window.location.href = this.state.link;
          } else {
            if (parseInt(userlog.userType) === 1) {
              this.props.history.replace("/admin");
            } else if (parseInt(userlog.userType) === 2) {
              this.props.history.replace("/subAdmin");
            } else if (parseInt(userlog.userType) === 3) {
              //this.props.history.replace('/superAdmin');
              window.location.href = "/superAdmin";
            } else if (
              parseInt(userlog.userType) === 6 &&
              parseInt(userlog.serviceId) === 8
            ) {
              window.location.href = "/CMS/Home";

              // this.props.history.replace('/CMS/Home');
            }
          }
          // this.setState({ link: "" });
        } else {
          // window.location.href = "/CMS/singleContent/205";
          this.setState({
            errorMobileNumber: "Incorrect OTP",
            show: 2,
            showResend: true
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  resend = async () => {
    this.setState({ mobileNumber: "" });
    const result = await login.loginOTPCheck(this.state.actualNum, null);
    if (result) {
      this.setState({
        show: 1,
        errorMobileNumber: "OTP sent",
        placeholder: 2
      });
    }
  };

  reset = () => {
    this.setState({
      show: 1,
      placeholder: 1,
      mobileNumber: "",
      errorMobileNumber: ""
    });
  };

  render() {
    const styles = {
      boxShadow: "0 0 10 #8e7f7f",
      webkitBorderRadius: 25,
      webkitBoxShadow: "1px 3px 25px #9E9E9E",
      marginTop: 15
      /*   marginRight: 20,*/
      //   paddingTop: 0,
      /*marginLeft: 20,*/
      //   height: 250
    };
    return (
      //   <React.Fragment>
      <Router>
        <div
          className=""
          style={{ backgroundColor: "rgb(white)", height: "600px" }}
        >
          <Container>
            <div className="row">
              <div className="col-sm-4"> </div>
              <div className="col-sm-4">
                <center>
                  {" "}
                  <img src={imglogo} style={{ marginTop: 52 }} alt="image" />
                </center>
              </div>

              <div className="col-sm-4"> </div>
            </div>

            <div className="row">
              <div className="col-sm-2"> </div>
              <div className="col-sm-8">
                <div className="card-body" style={styles}>
                  <center>
                    <h2
                      className=""
                      style={{
                        marginTop: 0,
                        paddingTop: 30,
                        color: "#ed1c28"
                      }}
                    >
                      {" "}
                      {" Murugappa Safety Architecture"}{" "}
                    </h2>
                  </center>
                  <br />

                  <div className="row">
                    <div className="col-sm-3">
                      {/**} <label>{'Enter Number'} </label>{' '}*/}
                    </div>
                    <div className="col-sm-7">
                      <Input
                        type="text"
                        name="mobileNumber"
                        placeholder={
                          this.state.placeHolder === 1
                            ? "Enter Mobile Number"
                            : "Enter OTP"
                        }
                        maxlength={this.state.placeHolder === 1 ? "10" : "4"}
                        onChange={event => this.handleUserInput(event)}
                        value={this.state.mobileNumber}
                        onKeyDown={this._handleKeyDown}
                      />
                      <center>
                        {" "}
                        <span style={{ color: "red", fontSize: 10 }}>
                          {this.state.errorMobileNumber}{" "}
                        </span>
                      </center>
                    </div>
                    <div className="col-sm-2"> </div>
                  </div>
                  <br />

                  <div className="row">
                    <div className="col-sm-3"> </div>
                    {this.state.show === 1 && (
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-danger checksize"
                          style={{ color: "white", backgroundColor: "#ed1c28" }}
                          onClick={
                            this.state.placeHolder === 1
                              ? this.handleSubmit
                              : this.handleSubmitOTP
                          }
                        >
                          Submit
                        </button>
                      </div>
                    )}
                    {this.state.show === 2 && (
                      // <div>
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-danger checksize"
                          style={{
                            color: "white",
                            backgroundColor: "#ed1c28"
                          }}
                          onClick={this.resend}
                          block
                        >
                          {/* {this.state.button} */}
                          Resend OTP
                        </button>
                        {/* </div>
                    )}
                    {this.state.show === 2 && ( */}
                        {/* <div className="col-sm-3"> */}
                        &nbsp;
                        <button
                          type="button"
                          className="btn btn-danger checksize"
                          style={{
                            color: "white",
                            backgroundColor: "#ed1c28"
                          }}
                          onClick={this.reset}
                          block
                        >
                          Reset
                        </button>
                      </div>
                      // </div>
                    )}
                    <div className="col-sm-3"> </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-2"> </div>
            </div>
          </Container>
        </div>
      </Router>
      //   </React.Fragment>
    );
  }
}
