import React from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import SingleSelect from "../../../components/Form/SingleSelect";
import Type1 from "./BoxtypeComponents/Type1";
import Type6 from "./BoxtypeComponents/Type6";
import Type8 from "./BoxtypeComponents/Type8";
import Type9 from "./BoxtypeComponents/Type9";
import Type10 from "./BoxtypeComponents/Type10";
import Type3 from "./BoxtypeComponents/Type3";
import Type7 from "./BoxtypeComponents/Type7";
import Type4 from "./BoxtypeComponents/Type4";
import Type2 from "./BoxtypeComponents/Type2";
import Type5 from "./BoxtypeComponents/Type5";

import {
  Document,
  PDFViewer,
  Page,
  Image,
  PDFDownloadLink
} from "@react-pdf/renderer";
import { ACCESS_POINT } from "../../../config";
import LoginModal from "../../../components/Modal/Modal";
import Datatable from "../../../components/Datatable/Datatable";
import { confirmAlert } from "react-confirm-alert";
import Switch from "react-switch";
import { Alert } from "reactstrap";

class BoxTypescopy extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      boxtypedisable: false,
      customerId: localStorage.getItem("userId"),
      dataTableReload: true,
      disableGroup: false,
      modal: false,
      button: "Submit",
      errorname: "",
      errorheading: "",
      thumbnail: "",
      alertVisible: false,
      boxval: 0
    };
    // this.submittingCommon = this.submittingCommon.bind(this);
    this.handler = this.handler.bind(this);
  }
  async componentDidMount() {
    try {
      const { data: boxtype } = await CmsContent.getMasterValues(
        "tbl_boxtype",
        "status",
        "asc"
      );
      if (boxtype) {
        this.setState({ boxtype });
      }
      const { data: boxNameList } = await CmsContent.getSelectvalue(
        "tbl_portletname",
        "customerid",
        this.state.customerId
      );
      if (boxNameList) {
        this.setState({ boxNameList });
      }
      const { data: resourceOptions } = await CmsContent.getSelectvalue(
        "tbl_resources",
        "companyid",
        this.state.customerId
      );
      if (resourceOptions) {
        this.setState({ resourceOptions });
      }
      const { data: dataTableData } = await CmsContent.getSingleConditioned(
        "tbl_boxcontent",
        "customerId",
        this.state.customerId
      );
      if (dataTableData) {
        this.setState({ dataTableData });
      }
      let dateObj = new Date();
      let month = dateObj.getUTCMonth() + 1;
      let day = dateObj.getUTCDate();
      let year = dateObj.getUTCFullYear();
      let newdate = year + "-" + month + "-" + day;
      this.setState({ newdate });
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      id: "type",
      Header: "Portlet Type",
      accessor: d => this.getValueFromArray(d.type, this.state.boxtype),
      Cell: d => this.getValueFromArray(d.original.type, this.state.boxtype)
    },
    {
      id: "name",
      Header: "Portlet Name",
      accessor: d => this.getValueFromArray(d.name, this.state.boxNameList),
      Cell: d => this.getValueFromArray(d.original.name, this.state.boxNameList)
    },
    {
      Header: "Add Content",
      accessor: "addnew",
      Cell: d => this.addnew(d.original, "new")
    },
    {
      Header: "Text",
      accessor: "text",
      Cell: d => this.check1(d)
    },
    {
      Header: "Images/Videos",
      accessor: "media",
      Cell: d => this.viewMedia(d)
    },
    {
      Header: "Thumbnail",
      accessor: "thumbnail",
      Cell: d => this.viewThumbnail(d)
    },
    {
      Header: "Link",
      accessor: "url",
      Cell: d => this.viewLink(d)
    },
    {
      Header: "Visibility",
      accessor: "edit",
      Cell: d => this.showVisibility(d)
    },
    {
      Header: "Delete",
      accessor: "delete",
      Cell: d =>
        this.dataTableButton("danger", "Delete", () => {
          this.buttonDeletes(d.original);
        })
    }
  ];
  addnew = (value, modalWindowId) => {
    return (
      <button
        type="button"
        data-toggle="modal"
        data-target={`#${modalWindowId}`}
        className="btn btn-warning"
        onClick={() => this.buttonAdd(value)}
      >
        Add New
      </button>
    );
  };
  buttonAdd = d => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    let boxname = {};
    boxname.value = d.name;
    boxname.label = this.getValueFromArray(d.name, this.state.boxNameList);

    let wordVal = d.name;

    let box = {};
    box.value = d.type;
    box.label = this.getValueFromArray(d.type, this.state.boxtype);
    this.setState({
      // modal: true,
      boxval: 1,
      wordVal,
      boxname: boxname.label,
      boxvalue: d.type,
      box: box,
      button: "Add",
      disableGroup: true
    });
  };
  check1 = d => {
    if (d.original.type === 4) {
      var value = d.original.text;
      var result = "";
      if (value) {
        let res = value.split(",");
        for (var i = 0; i < res.length; i++) {
          result +=
            this.getValueFromArray(res[i], this.state.resourceOptions) + ",";
        }
        result = result.replace(/,\s*$/, "");
        return result;
      }
      return result;
    } else if (d.original.type === 6 && d.original.attendance != 0) {
      return (
        <LoginModal
          buttonTitle="QR-code"
          title={`QR-code For ${d.original.programname}`}
          id={`code${d.original.id}`}
          extraClass="btn btn-primary"
          bodyText={
            <div className="row form-group">
              <div className="col-sm-2" />
              <div className="col-sm-5">
                {/* <QRCode id={`qrcode${d.original.id}`} value={d.original.attendance} bgColor="#FFFFFF"	/> */}
              </div>
              <div className="col-sm-2">
                {this.state.qr ? (
                  <PDFDownloadLink
                    style={{ color: "white" }}
                    document={
                      <Document>
                        <Page>
                          <Image src={this.state.qr} />
                        </Page>
                      </Document>
                    }
                    fileName="QR-code.pdf"
                  >
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        setTimeout(this.setState({ qr: "" }), 500);
                      }}
                    >
                      Download Pdf
                    </button>
                  </PDFDownloadLink>
                ) : (
                  <a
                    href="javascript:void(0)"
                    onClick={() => this.downloadQR(`qrcode${d.original.id}`)}
                  >
                    Download QR
                  </a>
                )}
              </div>
            </div>
          }
          onClick={() => this.downloadQR(`qrcode${d.original.id}`)}
        />
      );
    } else {
      return d.original.text;
    }
  };
  viewMedia = d => {
    let link = ACCESS_POINT + "/superAdmin/file?fileurl=" + d.original.media;
    return (
      <a target="_blank" href={link}>
        {d.original.media}
      </a>
    );
  };
  viewThumbnail = d => {
    let link =
      ACCESS_POINT + "/superAdmin/file?fileurl=" + d.original.thumbnail;
    return (
      <a target="_blank" href={link}>
        {d.original.thumbnail}
      </a>
    );
  };
  viewLink = d => {
    let link = ACCESS_POINT + "/superAdmin/file?fileurl=" + d.original.url;
    return (
      <a target="_blank" href={link}>
        {d.original.url}
      </a>
    );
  };
  showVisibility = id => {
    let status = id.original.status == "active" ? "Active" : "Inactive";
    let colour = id.original.status == "active" ? "warning" : "danger";
    return this.dataTableButton(colour, status, () =>
      this.updateVisibility(id)
    );
  };
  buttonDeletes = value => {
    this.setState({});
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.buttonDelete(value)
          //onClick: () => alert("Group removed from")
        },
        {
          label: "No"
        }
      ]
    });
  };
  buttonDelete = async value => {
    let id = value.id;
    let previousData = [...this.state.dataTableData];
    try {
      const result = await CmsContent.getSingleConditionedValue(
        "tbl_boxcontent",
        "id",
        id,
        "Delete"
      );
      if (result) {
        let data = previousData.filter(delelteid => delelteid.id !== value.id);
        this.setState({ dataTableData: data, formAlertdelete: true });
      }
    } catch (error) {
      console.log(error);
    }
    setTimeout(() => this.setState({ formAlertdelete: false }), 3000);
  };
  handleBox = e => {
    this.setState({ boxvalue: e.value, box: e, modal: false });
  };
  portletname = e => {
    if (e) {
      this.setState({ boxname: e.target.value });
    }
  };
  portletHeading = e => {
    this.setState({ portletHeading: e.target.value });
  };
  thumbnail = e => {
    // this.setState({
    //   thumbnailSize: e.target.files[0].size,
    //   thumbnail: e.target.files[0],
    //   thumbnailname: e.target.files[0].name
    // });

    var filesData = e.target.files[0];
    var filesDataLength = filesData.size;
    var filesDataName = filesData.name;

    var that = this;
    var binaryFileType = false;
    const file = e.target.files[0];
    const filereader = new FileReader();
    filereader.onloadend = function(evt) {
      if (evt.target.readyState === FileReader.DONE) {
        const uint = new Uint8Array(evt.target.result);
        let bytes = [];
        uint.forEach(byte => {
          bytes.push(byte.toString(16));
        });
        const hex = bytes.join("").toUpperCase();
        //alert(hex);
        if (that.getMimetype(hex) != "Unknown filetype") {
          binaryFileType = true;
        }
      }

      if (!binaryFileType) {
        that.setState({
          filesDataName: "",
          errorthumbnail: "Please Select a Valid Image",
          thumbnailSize: 0
        });
        // return false;
        return false;
      } else {
        that.setState({ appLogosize: filesData.size, errorthumbnail: "" });

        that.setState({
          // appLogosize: e.target.files[0].size,
          thumbnail: filesData,
          thumbnailname: filesDataName,
          thumbnailSize: filesDataLength,
          errorlogo: "",
          binaryFileType: false
          //logosize: e.target.files[0].size,
          // beforeviewapplogo: ""
        });
        // if (!filesDataLength.length) return;
        // that.createImage(filesDataLength[0]);
      }
    };
    const blob = file.slice(0, 4);
    filereader.readAsArrayBuffer(blob);
  };
  descriptionText = e => {
    this.setState({ descriptionText: e.target.value });
  };
  tray = () => {
    this.setState({ tray: !this.state.tray, traytext: null });
  };
  traytext = e => {
    this.setState({ traytext: e.target.value });
  };
  getMimetype(signature) {
    switch (signature) {
      case "89504E47":
        return "image/png";
      case "47494638":
        return "image/gif";
      // case "25504446":
      //   return "application/pdf";
      case "FFD8FFDB":
        return "image/jpg";
      case "FFD8FFE0":
        return "image/jpeg";
      case "52494646":
        return "video/dat/avi";
      case "001B3":
        return "video/mpg/mpeg";
      case "4F676753":
        return "video/ogv/ogg";
      case "00018":
        return "video/mp4";
      case "00020":
        return "video/mp4";
      case "4944333":
        return "video/mp3";
      // case "504B0304":
      //   return "application/zip";
      // case "4D5A900":
      //   return "image/exe";
      default:
        return "Unknown filetype";
    }
  }

  errormessageboxname = async (errorname, errorcontent) => {
    this.setState({ errorname: errorcontent });
  };

  errormessageheading = async (errorheading, errorcontent) => {
    this.setState({ errorheading: errorcontent });
  };

  errorthumbnail = async (errorthumbnail, errorcontent) => {
    this.setState({ errorthumbnail: errorcontent });
  };

  errorfile = async (errorfile, errorcontent) => {
    this.setState({ errorfile: errorcontent });
  };

  handler = (alertVisible, boxvalue) => {
    this.setState({ alertVisible: true, boxvalue: "" });
    setTimeout(() => this.setState({ alertVisible: false }), 3000);
  };

  back = () => {
    this.setState({ boxvalue: "" });
  };

  render() {
    var div1 = (
      <div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            {JSON.stringify(this.state.boxvalue)}
            <label htmlFor="exampleInputEmail1">Portlet Name</label>
          </div>
          <div className="col-sm-5">
            <input
              type="text"
              disabled={this.state.disableGroup}
              className="form-control"
              id="Authoremail"
              name="Authoremail"
              placeholder="Enter Portlet Name"
              onChange={this.portletname}
              value={this.state.boxname}
            />
            <span className="error-shows ">{this.state.errorname}</span>
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">Portlet Heading</label>
          </div>
          <div className="col-sm-5">
            <input
              type="text"
              className="form-control"
              id="Authoremail"
              name="Authoremail"
              placeholder="Enter Portlet Heading"
              onChange={this.portletHeading}
              value={this.state.portletHeading}
            />
            <span className="error-shows ">{this.state.errorheading}</span>
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">Upload Thumbnail</label>
          </div>
          <div className="col-sm-5">
            <input
              type="file"
              id="images"
              accept="image/*"
              onChange={this.thumbnail}
            />
            <label
              className="custom-file-label lblcross"
              htmlFor="images"
              style={{ fontSize: "15px", overflow: "hidden" }}
            >
              <span style={{ fontSize: "12px" }}>
                {this.state.thumbnailname}
              </span>
            </label>
            <br />
            <br />
            <span className="error-shows">{this.state.errorthumbnail}</span>
          </div>
          <div
            className="col-sm-3 "
            style={{
              fontSize: 12,
              marginTop: 12,
              color: "#007bff"
            }}
          >
            Prescribed Size : 120x120 px
          </div>
        </div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">Descripition</label>
          </div>
          <div className="col-sm-5">
            <textarea
              type="text"
              className="form-control"
              placeholder="Enter Description Text"
              onChange={this.descriptionText}
              value={this.state.descriptionText}
            />
            <span className="error-shows ">{this.state.errordescription}</span>
          </div>
          <div className="col-sm-3">
            {/* {this.state.boxvalue !== 9 && ( */}
            <div>
              <br />
              <label>Add Tray: </label>&nbsp;&nbsp;
              <Switch
                height={18}
                width={41}
                marginTop={20}
                paddingTop={0}
                onChange={this.tray}
                checked={this.state.tray}
              />
            </div>
            {/* )} */}
          </div>
        </div>
        {this.state.tray && (
          <div className="row form-group">
            <div className="col-sm-2" />
            <div className="col-sm-2">
              <label htmlFor="exampleInputEmail1">Tray Text</label>
            </div>
            <div className="col-sm-5">
              <textarea
                type="text"
                className="form-control"
                placeholder="Enter Notification Text "
                onChange={this.traytext}
                value={this.state.traytext}
              />
              <span className="error-shows ">{this.state.errortext}</span>
            </div>
            <div className="col-sm-3" />
          </div>
        )}
        {this.state.boxvalue === 1 && (
          <Type1
            button={this.state.button}
            boxname={
              this.state.boxval === 0 ? this.state.boxname : this.state.wordVal
            }
            boxvalue={this.state.boxvalue}
            thumbnail={this.state.thumbnail}
            traytext={this.state.traytext}
            portletHeading={this.state.portletHeading}
            descriptionText={this.state.descriptionText}
            newdate={this.state.newdate}
            errormessageboxname={this.errormessageboxname}
            errormessageheading={this.errormessageheading}
            errorthumbnail={this.errorthumbnail}
            handler={this.handler}
            alertVisible={this.state.alertVisible}
          />
        )}
        {this.state.boxvalue === 6 && (
          <Type6
            button={this.state.button}
            boxname={
              this.state.boxval === 0 ? this.state.boxname : this.state.wordVal
            }
            boxvalue={this.state.boxvalue}
            thumbnail={this.state.thumbnail}
            traytext={this.state.traytext}
            portletHeading={this.state.portletHeading}
            descriptionText={this.state.descriptionText}
            newdate={this.state.newdate}
            errormessageboxname={this.errormessageboxname}
            errormessageheading={this.errormessageheading}
            errorthumbnail={this.errorthumbnail}
            handler={this.handler}
            alertVisible={this.state.alertVisible}
          />
        )}
        {this.state.boxvalue === 8 && (
          <Type8
            button={this.state.button}
            boxname={
              this.state.boxval === 0 ? this.state.boxname : this.state.wordVal
            }
            boxvalue={this.state.boxvalue}
            thumbnail={this.state.thumbnail}
            traytext={this.state.traytext}
            portletHeading={this.state.portletHeading}
            descriptionText={this.state.descriptionText}
            newdate={this.state.newdate}
            errormessageboxname={this.errormessageboxname}
            errormessageheading={this.errormessageheading}
            errorthumbnail={this.errorthumbnail}
            handler={this.handler}
            alertVisible={this.state.alertVisible}
          />
        )}
        {this.state.boxvalue === 9 && (
          <Type9
            button={this.state.button}
            boxname={
              this.state.boxval === 0 ? this.state.boxname : this.state.wordVal
            }
            boxvalue={this.state.boxvalue}
            thumbnail={this.state.thumbnail}
            traytext={this.state.traytext}
            portletHeading={this.state.portletHeading}
            descriptionText={this.state.descriptionText}
            newdate={this.state.newdate}
            errormessageboxname={this.errormessageboxname}
            errormessageheading={this.errormessageheading}
            errorthumbnail={this.errorthumbnail}
            handler={this.handler}
            alertVisible={this.state.alertVisible}
          />
        )}
        {this.state.boxvalue === 10 && (
          <Type10
            button={this.state.button}
            boxname={
              this.state.boxval === 0 ? this.state.boxname : this.state.wordVal
            }
            boxvalue={this.state.boxvalue}
            thumbnail={this.state.thumbnail}
            traytext={this.state.traytext}
            portletHeading={this.state.portletHeading}
            descriptionText={this.state.descriptionText}
            newdate={this.state.newdate}
            errormessageboxname={this.errormessageboxname}
            errormessageheading={this.errormessageheading}
            errorthumbnail={this.errorthumbnail}
            handler={this.handler}
            alertVisible={this.state.alertVisible}
          />
        )}

        {this.state.boxvalue === 3 && (
          <Type3
            button={this.state.button}
            boxname={this.state.boxname}
            boxvalue={this.state.boxvalue}
            thumbnail={this.state.thumbnail}
            traytext={this.state.traytext}
            portletHeading={this.state.portletHeading}
            descriptionText={this.state.descriptionText}
            newdate={this.state.newdate}
            errormessageboxname={this.errormessageboxname}
            errormessageheading={this.errormessageheading}
            errorthumbnail={this.errorthumbnail}
            handler={this.handler}
            alertVisible={this.state.alertVisible}
          />
        )}

        {this.state.boxvalue === 7 && (
          <Type7
            button={this.state.button}
            boxname={this.state.boxname}
            boxvalue={this.state.boxvalue}
            thumbnail={this.state.thumbnail}
            traytext={this.state.traytext}
            portletHeading={this.state.portletHeading}
            descriptionText={this.state.descriptionText}
            newdate={this.state.newdate}
            errormessageboxname={this.errormessageboxname}
            errormessageheading={this.errormessageheading}
            errorthumbnail={this.errorthumbnail}
            handler={this.handler}
            alertVisible={this.state.alertVisible}
          />
        )}

        {this.state.boxvalue === 4 && (
          <Type4
            button={this.state.button}
            boxname={this.state.boxname}
            boxvalue={this.state.boxvalue}
            thumbnail={this.state.thumbnail}
            traytext={this.state.traytext}
            portletHeading={this.state.portletHeading}
            descriptionText={this.state.descriptionText}
            newdate={this.state.newdate}
            errormessageboxname={this.errormessageboxname}
            errormessageheading={this.errormessageheading}
            errorthumbnail={this.errorthumbnail}
            handler={this.handler}
            alertVisible={this.state.alertVisible}
          />
        )}

        {this.state.boxvalue === 2 && (
          <Type2
            button={this.state.button}
            boxname={this.state.boxname}
            boxvalue={this.state.boxvalue}
            thumbnail={this.state.thumbnail}
            traytext={this.state.traytext}
            portletHeading={this.state.portletHeading}
            descriptionText={this.state.descriptionText}
            newdate={this.state.newdate}
            errormessageboxname={this.errormessageboxname}
            errormessageheading={this.errormessageheading}
            errorthumbnail={this.errorthumbnail}
            handler={this.handler}
            alertVisible={this.state.alertVisible}
          />
        )}

        {this.state.boxvalue === 5 && (
          <Type5
            button={this.state.button}
            boxname={this.state.boxname}
            boxvalue={this.state.boxvalue}
            thumbnail={this.state.thumbnail}
            traytext={this.state.traytext}
            portletHeading={this.state.portletHeading}
            descriptionText={this.state.descriptionText}
            newdate={this.state.newdate}
            errormessageboxname={this.errormessageboxname}
            errormessageheading={this.errormessageheading}
            errorthumbnail={this.errorthumbnail}
            handler={this.handler}
            alertVisible={this.state.alertVisible}
          />
        )}
      </div>
    );
    var div = (
      // this.state.modal === false ? (
      //   <LoginModal id="new" bodyText={<div>{div1}</div>} />
      // ) : (
      <div>{div1}</div>
    );
    // );
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Portlet Master</h1>
                  </div>
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-7">
                      <Alert
                        className="badge-content"
                        color="success"
                        isOpen={this.state.alertVisible}
                        toggle={this.onDismiss}
                      >
                        New Portlet Added
                      </Alert>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="card-body">
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-2">
                          <label htmlFor="exampleInputEmail1">
                            Select Portlet-Type
                          </label>
                        </div>
                        <div className="col-sm-5">
                          <SingleSelect
                            disabled={this.state.disableGroup}
                            options={this.state.boxtype}
                            handleChange={this.handleBox}
                            value={this.state.box}
                            placeholder="Select BoxType"
                          />
                        </div>
                        <div className="col-sm-3">
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={() => window.open("/DF/MapPortletToGroup")}
                          >
                            Manage App
                          </button>
                        </div>

                        {/* <div className="col-sm-2">
                          <button
                            type="button"
                            className="btn btn-warning"
                            onClick={() => this.back()}
                          >
                            Reset
                          </button>
                        </div> */}
                      </div>
                      {/* <Type1 /> */}
                      {(this.state.boxvalue === 1 ||
                        this.state.boxvalue === 2 ||
                        this.state.boxvalue === 3 ||
                        this.state.boxvalue === 4 ||
                        this.state.boxvalue === 5 ||
                        this.state.boxvalue === 6 ||
                        this.state.boxvalue === 7 ||
                        this.state.boxvalue === 8 ||
                        this.state.boxvalue === 9 ||
                        this.state.boxvalue === 10) && <div>{div}</div>}

                      {this.state.dataTableData &&
                        this.state.dataTableReload && (
                          <Datatable
                            data={this.state.dataTableData}
                            columnHeading={this.column}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
export default BoxTypescopy;
