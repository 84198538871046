import React, { Component } from 'react';
import CmsContent from '../../MiddleWare/CmsContent';
import renderHTML from "react-render-html";
import { ACCESS_POINT } from "../../config";

class ThemeFive extends Component {
  constructor(props) {
    super(props);
    this.state = {
		pageList:"",
		date:"" ,
		Content:[], 
		contentTitle:"",
		InnerData:[],
    };
  }
  
  async componentDidMount() {
    let themeid = this.props.match.params.id;
    const result = await CmsContent.getThemeById(themeid);
      //console.log(result.data)
    if (result) {
		   this.setState({Content:result.data[0]});
		  this.state.Content.sheetContent = JSON.parse(this.state.Content.sheetContent);
           this.setState({InnerData:this.state.Content.sheetContent})
      }
      /*if (result.data[0].sheetContent) {
        result.data[0].sheetContent = JSON.parse(result.data[0].sheetContent);
      }
      this.setState({ pageList: result.data[0] });
    }*/
  }


  
  render(){
	  const{Content,InnerData}=this.state;
	  
	  let text=Content.content1;
	  //console.log(renderHTML(content));
	  let ContentTitle=Content.contentTitle1; 
    return <React.Fragment>
	 <div class="breadcrumb-area">
        <div class="container" style={{backgroundColor:"#fff"}}>
            <div class="row" style={{backgroundColor:"#fff"}}> 
				{/*Theme5{this.props.data}*/}
	         
                <div class="col-10" style={{marginLeft:"7%"}}>
                    <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a href="#"><i class="fa fa-home"></i> Home</a></li>
                            <li class="breadcrumb-item active" aria-current="page">AREA OF FOCUS</li>
							<li class="breadcrumb-item active" aria-current="page">{ContentTitle}</li> 
                        </ol>
                    </nav>
                </div>
				                 <br/> 
                 <br/>

                <div class="about-us-area section-padding-0-100">
        <div class="container">
            <div class="row">
               <div class="col-1"></div>
                <div class="col-10">
                    <div class="about-content" style={{marginTop:"17px"}}>
                       <div class="section-heading text-center">
                           
                       
                          <h2 class="post-title" style={{color:"#191919"}}>
							  {ContentTitle}
							  
                          </h2>
                              
                        </div>
						<br/><br/>
						<div class="post-share">
						
						 <p>
						 {text&&(
							 renderHTML(text)
							 )}
						</p>
						<br/><br/>
                        </div> 
						{InnerData.map((ival,i)=>{
							//let img=`http://35.169.94.138:3000/${ival.thumbnail}`;
							return(
                            <div>
							<div class="post-share">
							
							 <p>{ival.title&&(
								 renderHTML(ival.title)
								 )}
							 </p>
							 
							</div>  
							 <br/><br/>
							<div class="row">
                             <div class="col-1"></div>
							 <div class="col-4">
							 {ival.thumbnail&&(
							 <img
                              className="box-shadow-img"
                              src={`${ACCESS_POINT}/superAdmin/file?fileurl=${ival.thumbnail}`}
                              alt="something went wrong"
                            />
							 )}
							</div>
							{/*<div class="col-5">
							 <a href={}><p>"Social Development of Dalits and Tamilnadu Government Budget", 1999.</p><br/></a>
							 
							 
						</div>*/}
						<div class="col-6">
							{ival.files.map((jval,j)=>{
								return(
								 <div>
								 {/*<p>{jval.name}</p>*/}
									
							 <a href={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.link}`}><p> 
							  
                             <img
                              className="box-shadow-img"
							  
                              src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/download_1586853763899.jpg`}
                              alt="something went wrong"
							  style={{width:'14px',marginBottom:'2px'}}
									 />
							 {jval.name}</p></a><br/>								 
							 </div>
							   
							  )
								
							})
								
							}
							</div>
							</div>

							</div>)
						})
						}
						</div>
						</div></div>
						</div></div>
	</div>
	</div>
	</div>
	</React.Fragment>
  }
}
export default ThemeFive;
