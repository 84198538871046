import React, { Component } from "react";
import { Alert } from 'reactstrap';
import SingleSelect from "../../../../src/components/Form/SingleSelect";
import CmsContent from '../../../MiddleWare/CmsContent';


class AddProductSubcategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categorySelected: "",
            categoryId: "",
            categoryName: "",
            categoryOptions: [],
            customerId: localStorage.getItem("userId"),
            subCategoryOptions: [],
            isOpen: false,


        }
    }
    async componentDidMount() {
        this.mainFunc()
    }
    mainFunc = async () => {
        try {
            const category = await CmsContent.getFreedom(
                "id as value,name as label",
                "tbl_category_productmanagement",
                `customerId=${this.state.customerId} and status="active"`,
                "id",
                "id DESC"
            );
            if (category && category.data && category.data.length) {
                this.setState({ categoryOptions: category.data });
            }
            const subCategory = await CmsContent.getFreedom(
                "id as value,name as label",
                "tbl_subCategory_master",
                `customerId=${this.state.customerId} and status="active"`,
                "id",
                "id DESC"
            );
            if (subCategory && subCategory.data && subCategory.data.length) {
                this.setState({ subCategoryOptions: subCategory.data });
            }

        } catch (e) {
            console.log(e);
        }
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    categorySelect = (selectedOption) => {
        this.setState({
            categorySelected: selectedOption,
            categoryId: selectedOption.value,
            categoryName: selectedOption.label,
        });
    };
    addNewSubCategory = async () => {
        let subCategoryArray = {};
        let subCategoryAddExtra = {};
        let subCategoryName = this.state.subCategory;
        let subCategoryOptions = this.state.subCategoryOptions;


        if (!subCategoryName) {
            this.setState({ errorSubCategory: "Enter Sub-Categoty Name" });
            return false;
        } else {

            this.setState({ errorSubCategory: "", btnDisable: true });
            subCategoryArray.name = subCategoryName.trim();
            subCategoryArray.status = "active";
            subCategoryArray.customerId = this.state.customerId;
            subCategoryArray.categoryid = this.state.categoryId;

            try {

                const result = await CmsContent.addMaster(
                    "tbl_subCategory_productmanagement",
                    subCategoryArray
                );

                if (result) {

                    subCategoryAddExtra.value = result.data.insertId;
                    subCategoryAddExtra.label = subCategoryName;
                    subCategoryOptions.splice(0, 0, subCategoryAddExtra);
                    console.log("enter7");
                    this.setState({
                        subCategory: "",
                        btnDisable: false,
                        isOpen: true,
                        subCategoryOptions,
                        categorySelected: "",

                    });
                    setTimeout(
                        (dataIndex) => this.setState({ isOpen: false }),
                        3000
                    );
                    this.componentDidMount();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    refreshQuiz = async (d) => {
        this.componentDidMount();
    };
    render() {
        const {
            categoryOptions, categorySelected, subCategory, isOpen, error, dismiss, disableValue
        } = this.state;
        return (
            <React.Fragment>
                <div>
                    {isOpen ?
                        <Alert className="badge-content" color="success" isOpen={alert} toggle={dismiss}>
                            New Sub-Category Added
                        </Alert>
                        :
                        <></>

                    }

                    <div className="row form-group">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <label for="exampleInputEmail1">Select Category</label>
                        </div>
                        <div className="col-sm-6">
                            <SingleSelect
                                handleChange={this.categorySelect}
                                options={categoryOptions}
                                selectedService={categorySelected}
                            />
                        </div>
                        <div className="col-sm-1">
                            <button
                                type="button"
                                className="btn btn-info"
                                onClick={(d) => this.refreshQuiz(d)}
                            >
                                Refresh
                            </button>
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col-sm-1" />
                        <div className="col-sm-3">
                            <label for="exampleInputEmail1">SubCategory Name</label>
                        </div>
                        <div className="col-sm-6">
                            <input type="text" class="form-control" id="subCategory" name="subCategory" placeholder="Enter Sub-category" onChange={this.handleChange} value={subCategory} />
                        </div>
                        <div className="col-sm-1" />
                    </div>
                    <div className="row form-group">
                        <div className="col-sm-4" />
                        <span className="modal-error-show">{error}</span>
                    </div>

                    <div className="row form-group">
                        <div className="col-sm-5" />
                        <div className="col-sm-4">
                            <button type="button" class="btn btn-primary" disabled={disableValue} onClick={this.addNewSubCategory} >
                                Add SubCategory
                            </button>
                        </div>
                        <div className="col-sm-4" />
                    </div>
                </div>
            </React.Fragment>
        )

    }
}
export default AddProductSubcategory;