import React, { Component } from "react";
import "./style.css";
import { ACCESS_POINT } from "../../config";

export default class preLoader extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        <img
          style={{ position: "absolute", top: "20%", left: "44.5%" }}
          width="10%"
          src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/1200px-Murugappa_Group_Logo_1581051820908.png`}
        />
        <div
          className="sk-bounce"
          style={{
            position: "absolute",
            top: "50%",
            left: "48.3%"
          }}
        >
          <div className="sk-bounce-dot"></div>
          <div className="sk-bounce-dot"></div>
        </div>
      </React.Fragment>
    );
  }
}
