import React, { Component } from "react";
import { Alert } from "reactstrap";
import CmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import { FormMiddleWare } from "../../../../components/Form";
import SingleSelect from "../../../../components/Form/SingleSelect";
import { confirmAlert } from "react-confirm-alert";
import { BoxTypescopy } from "../BoxTypescopy";

class Type9 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: localStorage.getItem("userId"),
      button: this.props.button,
      boxname: "",
      boxvalue: "",
      thumbnail: "",
      traytext: "",
      portletHeading: "",
      descriptionText: "",
      newdate: ""
    };
  }

  async componentDidMount() {
    const formList = await CmsContent.getTwoConditionedValue(
      "tbl_form",
      "status",
      "active",
      "companyid",
      this.state.customerId,
      "id as value ,name as label"
    );
    if (formList) {
      this.setState({ formOptions: formList.data });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      this.setState({
        button: nextProps.button,
        boxname: nextProps.boxname,
        boxvalue: nextProps.boxvalue,
        thumbnail: nextProps.thumbnail,
        traytext: nextProps.traytext,
        portletHeading: nextProps.portletHeading,
        descriptionText: nextProps.descriptionText,
        newdate: nextProps.newdate
      });
    }
  }

  refresh = async type => {
    const formList = await CmsContent.getTwoConditionedValue(
      "tbl_form",
      "status",
      "active",
      "companyid",
      this.state.customerId,
      "id as value ,name as label"
    );
    if (formList) {
      this.setState({ formOptions: formList.data });
    }
  };

  formSelect = e => {
    this.setState({ formSelect: e });
  };

  submitCommon = async formData => {
    try {
      const result = await CmsContent.mappingportlet(
        formData,
        "tbl_boxcontent"
      );
      if (result) {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
        this.props.handler(this.state.boxvalue,this.props.alertVisible)

        // this.setState({ alertVisible: true, boxvalue: "" });
        // setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  onSubmit = async () => {
     if (!this.state.boxname) {
      this.props.errormessageboxname("errorname", "Please Enter Box Name");
      return false;
    } else {
      this.props.errormessageboxname("errorname", "");
    }
    if (!this.state.portletHeading) {
      this.props.errormessageheading(
        "errorheading",
        "Please Enter Portlet Heading"
      );
      return false;
    } else {
      this.props.errormessageheading("errorheading", "");
    }
    if (!this.state.thumbnail) {
      this.props.errorthumbnail("errorthumbnail", "Please Select a thumbnail");
      return false;
    } else {
      this.props.errorthumbnail("errorthumbnail", "");
    }
    if (!this.state.formSelect) {
      this.setState({ errortext: "Please Select " });
      return false;
    } else {
      this.setState({ errortext: "" });
    }
    let formData = new FormData();
    formData.append("name", this.state.boxname);
    formData.append("type", this.state.boxvalue);
    formData.append("thumbnail", this.state.thumbnail);
    formData.append("notificationtext", this.state.traytext);
    formData.append("heading", this.state.portletHeading);
    formData.append("description", this.state.descriptionText);
    formData.append("form", this.state.formSelect.value);
    formData.append("customerId", this.state.customerId);
    formData.append("status", "active");
    formData.append("createdAt", this.state.newdate);
       
 //this.submitCommon(formData);

 if(this.state.boxname && this.state.thumbnail && this.state.portletHeading && this.state.formSelect )

 {
  console.log('ts')
  console.log([...formData])
   this.submitCommon(formData);

 }

  };

  addtext = async () => {
    if (!this.state.portletHeading) {
      this.props.errormessageheading(
        "errorheading",
        "Please Enter Portlet Heading"
      );
      return false;
    } else {
      this.props.errormessageheading("errorheading", "");
    }
    if (!this.state.thumbnail) {
      this.props.errorthumbnail("errorthumbnail", "Please Select a thumbnail");
      return false;
    } else {
      this.props.errorthumbnail("errorthumbnail", "");
    }
    if (!this.state.formSelect) {
      this.setState({ errortext: "Please Select " });
      return false;
    } else {
      this.setState({ errortext: "" });
    }
    

    let formData = new FormData();

    formData.append("name", this.state.boxname);
    formData.append("type", this.state.boxvalue);
    formData.append("thumbnail", this.state.thumbnail);
    formData.append("notificationtext", this.state.traytext);
    formData.append("heading", this.state.portletHeading);
    formData.append("description", this.state.descriptionText);
    formData.append("form", this.state.formSelect.value);
    formData.append("customerId", this.state.customerId);
    formData.append("status", "active");
    formData.append("createdAt", this.state.newdate);

    if(this.state.boxname && this.state.thumbnail && this.state.portletHeading && this.state.formSelect )

 {
  console.log('ts')
  console.log([...formData])
   this.submitCommon(formData);

 }
   };

  render() {
    const {errortext} = this.state;
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-12">
            <div className="card-body">
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">Select Form</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect
                    options={this.state.formOptions}
                    handleChange={this.formSelect}
                    selectedService={this.state.formSelect}
                  />
                  <span className="error-shows ">{errortext}</span>
                </div>
                <div className="col-sm-3">
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={() => this.refresh(this.state.boxvalue)}
                  >
                    Refresh
                  </button>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-sm-4" />
                <div className="col-sm-5">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={
                      this.state.button === "Add" ? this.addtext : this.onSubmit
                    }
                  >
                    {this.state.button}
                  </button>
                </div>
                <div className="col-sm-3" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Type9;
