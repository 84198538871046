import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const Groupdf = ({ header,data,title="GROUPS"}) => {

var k="";
var por=[];


if(data){
   data.map((item,i)=>{
     if(item.innerContent.length > 0){
       item.innerContent.map(async(ival,j)=>{
         if(item.groupid == ival.groupid){
           await por.push({group : ival.groupName,user : ival.userName});
          }
        })
        }else{
         por.push({group : item.groupName , user : "NA"});
        }
    })

  }


  return (
    <Document>
      <Page style={styles.body}>
      <View style={styles.title}>
         <Text>{title}</Text>
        </View>
        <View style={styles.table}>
        <View style={styles.tableRow}>
            {header &&
              header.map(value => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
          </View>
              
         {data && por &&
            por.map((value,index) => {
              // if(data[index].innerContent.length > 0){
              //   value.innerContent.map((element,key)=>{
              //     console.log(value.groupName)
                  return (
                    <View style={styles.tableRow}>
                      <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{index+1}</Text>
                    </View>
                     <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{value.group}</Text>
                    </View> 
                        <View style={styles.tableCol}>
                      <Text style={styles.tableCell}>{value.user}</Text>
                    </View>    
                  </View>
                );
              })
          //  }
              // else{
              // //  console.log(data[index].innerContent.length)
              //   return (
              //     <View style={styles.tableRow}>
              //     <View style={styles.tableCol}>
              //     <Text style={styles.tableCell}>{index+1}</Text>
              //     </View>
              //     <View style={styles.tableCol}>
              //     <Text style={styles.tableCell}>{value.groupName}</Text>
              //     </View> 
              //     <View style={styles.tableCol}>
              //     <Text style={styles.tableCell}>NA</Text>
              //     </View>    
              //     </View>
              //     );
              //   }  
            //})
         } 
        </View>
      </Page>
    </Document>
  );
};




const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop:'5px'
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    textAlign:"center"
  },
  tableColHeader: {
    width: '16.6%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: '33.3%%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 'auto',
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: 'auto',
    margin: 5,
    fontSize: 10
  },
  title:{
    fontSize:19,
    fontWeight:"bold",
    marginLeft:'42%',
  
    }
});


export default Groupdf;
