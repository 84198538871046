import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import cmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";

class AddVideoValue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      video: null,
      jsonFileName: null,
      jsonFile: null,
      videoId: null,
      data: null,
      alertVisible: false,
      videoSelected: [],
      customerId: localStorage.getItem("userId")
    };
  }
  async componentDidMount() {
    try {
      const result = await cmsContent.videoList();
      if (result.data) {
        let data = result.data;
        this.setState({
          video: data
        });
      }
      const { data } = await cmsContent.videoAnalyticsList();
      if (data) {
        this.setState({ data });
      }
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      Header: "Video Name",
      accessor: "video_Name"
    },
    {
      Header: "Json File",
      accessor: "json_file"
    },
    {
      Header: "Show Details",
      accessor: "edit",
      Cell: d => this.showDetails(d)
    }
  ];

  showDetails = e => {
    let videoId = e.original.video_id;

    return (
      <Link
        className="nav-link"
        target="_blank"
        to={`/cmsSelectedVideoAnalytics/${videoId}`}
      >
        <button type="button" className="btn btn-info">
          <span style={{ color: "#ffffff" }}>Show Details</span>
        </button>
      </Link>
    );
  };

  selectthumbnail = e => {
    this.setState({
      jsonFile: e.target.files[0],
      jsonFileName: e.target.files[0].name
    });
  };

  videoChange = e => {
    this.setState({
      videoSelected: e,
      videoId: e.value
    });
  };

  submitValue = async () => {
    const jsonFile = this.state.jsonFile;
    const videoId = this.state.videoId;
    const formData = new FormData();
    formData.append("videoId", videoId);
    formData.append("jsonFile", jsonFile);

    try {
      const result = await cmsContent.videoValue(formData);
      if (result) {
        this.setState({
          jsonFileName: null,
          jsonFile: null,
          videoId: null,
          alertVisible: true,
          videoSelected: []
        });
        const { data } = await cmsContent.videoAnalyticsList();
        if (data) {
          this.setState({ data });
        }
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Video Analytics</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color="success"
                          isOpen={this.state.alertVisible}
                          toggle={this.onDismiss}
                        >
                          Json Added Successfully
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="videoName">Video Name</label>
                      </div>
                      <div className="col-sm-5">
                        {this.state.video && (
                          <SingleSelect
                            name="quiz"
                            options={this.state.video}
                            handleChange={this.videoChange}
                            selectedService={this.state.videoSelected}
                          />
                        )}
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="videoName">Json File</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="jsonFile"
                          onChange={this.selectthumbnail}
                        />
                        <label
                          style={{ zIndex: "0" }}
                          className="custom-file-label"
                          htmlFor="customFileThumbnail"
                        >
                          {this.state.jsonFileName}
                        </label>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.submitValue}
                        >
                          Add Page
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    {this.state.data && (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default AddVideoValue;
