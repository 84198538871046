import React, { Component } from "react";
import CmsContent from '../../../../MiddleWare/CmsContent';
import http from "../../../../MiddleWare/httpMiddleWare";
import { ACCESS_POINT } from "../../../../config";
import LoginModal from "../../../../components/Modal/Modal";
import SingleSelect from "../../../../components/Form/SingleSelect";
import AddProductSubcategory from "../AddProductSubcategory";
import AddCategory from "../AddCategory";
import AddBrand from "../AddBrand";
// import AddColour from "../AddColour";
import MultiSelect from "../../../../components/Form/MultiSelect";
import { getImageSize } from "react-image-size";
import AddProductSize from "../AddProductSize";

// import Progress from "../../components/Extra/Progress";
class Theme12 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            getlibrarydataImage: props.that.getlibrarydataImage,
            contentBody: [{
                ProductName: '',
                ProductCode: '',
                ProductPrice: '',
                ProductSize: [],
                files: [],
                categorySelected: "",
                categoryId: "",
                categoryName: "",
                subCategorySelected: "",
                subCategoryId: "",
                subCategoryName: "",
                subCategoryOptions1: [],
                brandSelected: "",
                brandId: "",
                brandName: "",
                // colourSelected: "",
                // colourId: "",
                colourName: "",
                colourCode: [],
                imagedimension: false,
            }],

            Files: [],
            btnDisable: false,
            alertVisible: false,
            isEdit: false,
            submitting: false,
            PageData: [],
            msgShow: false,
            msg: '',
            EditData: {},
            errorCategory: "",
            errorSubCategory: "",
            errorBrand: "",
            // errorColour: "",
            colourOptions: [],
            brandOptions: [],
            subCategoryOptions: [],
            categoryOptions: [],
            category: "",
            subCategory: "",
            brand: "",
            productSize: "",
            errorProductSize: "",
            // colour: "",
            customerId: localStorage.getItem("userId"),
            userDetails: localStorage.getItem("userlog"),
            sizes: [],
            colourAddedMessage: "",
            colourAddedMessage_color: "",
            colourAddedMessageShow: false,
            colorDivDisable: false,
            colorIndex: null,
            paletteIndex: null

        }
    }
    async componentDidMount() {
        try {
            localStorage.removeItem("index")
            let PageData = this.props.that
            await this.setState({ PageData, EditData: PageData.data1 })
            if (PageData && PageData.data1 && PageData.data1.sheetContent2) {
                let contentBody = JSON.parse(PageData.data1.sheetContent2)
                // console.log(contentBody, "contentBody");
                await this.setState({ contentBody, isEdit: true })
            }
            else if (PageData && PageData.data1 && PageData.data1.sheetContent) {
                let contentBody = JSON.parse(PageData.data1.sheetContent)
                //  console.log(contentBody, "contentBody");
                await this.setState({ contentBody, isEdit: true })
            }

            this.mainFun();

        } catch (e) {
            console.log(e);
        }
    }
    mainFun = async () => {
        try {

            const category = await CmsContent.getFreedom(
                "id as value,name as label",
                "tbl_category_productmanagement",
                `customerId=${this.state.customerId} and status="active"`,
                "id",
                "id DESC"
            );

            if (category) {

                this.setState({ categoryOptions: category.data });
            }

            const subCategory = await CmsContent.getFreedom(

                "tbl_subCategory_productmanagement.name as label,tbl_subCategory_productmanagement.id as value,tbl_subCategory_productmanagement.categoryid as categoryid",
                "tbl_subCategory_productmanagement,tbl_category_productmanagement",
                `tbl_subCategory_productmanagement.customerId=${this.state.customerId} and tbl_subCategory_productmanagement.status="active" and tbl_subCategory_productmanagement.categoryid=tbl_category_productmanagement.id `,
                "tbl_subCategory_productmanagement.id",
                "tbl_subCategory_productmanagement.id DESC"
            );

            if (subCategory) {

                this.setState({ subCategoryOptions: subCategory.data });
            }

            const brand = await CmsContent.getFreedom(
                "id as value,brand as label",
                "tbl_brand_master",
                `customerId=${this.state.customerId} and status="active"`,
                "id",
                "id DESC"
            )
            if (brand) {

                this.setState({ brandOptions: brand.data });
            }

            const sizes = await CmsContent.getFreedom(
                "id AS value, name AS label",
                "tbl_product_size",
                `customerId=${this.state.customerId} and status="active"`,
                "id",
                "id DESC"
            )
            if (sizes && sizes.data) {
                console.log(sizes, 'sizes')
                await this.setState({ sizes: sizes.data.length > 0 ? sizes.data : [] });
            }

        } catch (error) {
            console.log(error);
        }
    }
    async componentDidUpdate(prev) {
        if (prev.that != this.props.that) {
            await this.setState({ PageData: this.props.that }, () => { console.log(this.state.PageData, 'PageData') })
        }
    }
    handlChange = async (e, i, select = null) => {
        let { contentBody } = this.state
        console.log(contentBody[i]['ProductSize'], "ProductSize")
        if (select === null) {
            console.log(e, i, "ei");
            const name = e.target.name
            const value = e.target.value
            contentBody[i][`${name}`] = value
            await this.setState({ contentBody })
        } else {
            console.log(e, i, "ei", select, 'slect');
            contentBody[i][select] = e;
            await this.setState({ contentBody })
        }

    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };
    AddNewImage = (i) => {
        let { contentBody } = this.state;
        if (contentBody[i].files.length < 5) {
            contentBody[i].files.push({ Img: '', name: '' });
            contentBody[i].imagedimension = true
        }
        else { }
        this.setState({ contentBody });
    };
    removeArray = (a, b = null) => {
        // console.log(a,"a",b,"b");
        const { contentBody } = this.state;
        let index1 = a - 1;
        if (b !== null) {
            let index2 = b - 1;
            contentBody[index1].files.splice(index2, 1);
            if (contentBody[index1].files.length == "0") {
                contentBody[index1].imagedimension = false
            }
            else {
                contentBody[index1].imagedimension = true
            }
        } else {

            contentBody.splice(index1, 1);
        }
        this.setState({ contentBody });
    }
    HandlingChange = async (index, data, type, fileindex = null) => {
        let { contentBody, Files } = this.state;

        if (fileindex !== null) {
            if (type === 'Img') {
                const file = data.target.files[0]
                if (file) {
                    const img = new Image();
                    img.src = URL.createObjectURL(file)
                    const dimensions = await getImageSize(img.src);

                    if (dimensions.width != 640 || dimensions.height != 800) {
                        alert("Image dimension should be 640 x 800 pixels ")
                        return false;
                    }
                    else if (file.size > 300000) {
                        alert("File size must be 300KB or Less")
                        return false;
                    }
                }

                contentBody[index].files[fileindex][type] = file.name;
                Files.push({ [file.name]: file });
                await this.setState({ contentBody, Files });

                // contentBody[index].files[fileindex][type] = data.target.files[0].name;
                // Files.push({ [data.target.files[0].name]: data.target.files[0] });
                // await this.setState({ contentBody, Files });

            }
            //  else {
            //     contentBody[index].files[fileindex][type] = data.target.value;
            // }
        }
    }
    categorySelect = async (d, i) => {
        let { contentBody, subCategoryOptions } = this.state
        if (d) {
            contentBody[i][`categorySelected`] = d
            contentBody[i][`categoryId`] = d.value
            contentBody[i][`categoryName`] = d.label
            let subCatOpt = subCategoryOptions.filter((ival) => { return ival.categoryid == d.value })
            contentBody[i]['subCategoryOptions1'] = subCatOpt
            this.setState({ contentBody })
            console.log(contentBody, "contentbody of select");

        }
    }
    subCategorySelect = (d, i) => {
        let { contentBody } = this.state
        if (d) {
            contentBody[i][`subCategorySelected`] = d
            contentBody[i][`subCategoryId`] = d.value
            contentBody[i][`subCategoryName`] = d.label
        }
        this.setState({
            contentBody
        });
    };

    brandSelect = (d, i) => {
        let { contentBody } = this.state
        if (d) {
            contentBody[i][`brandSelected`] = d
            contentBody[i][`brandId`] = d.value
            contentBody[i][`brandName`] = d.label
        }
        this.setState({
            contentBody
        });
    };
    onDismiss = () => {
        this.setState({ alertVisible: false });
        this.setState({ alertVisible: false });
    };
    refreshQuiz = async (d, i) => {
        let { contentBody, subCategoryOptions } = this.state
        try {
            const subCategory = await CmsContent.getFreedom(
                "tbl_subCategory_productmanagement.name as label,tbl_subCategory_productmanagement.id as value,tbl_subCategory_productmanagement.categoryid as categoryid",
                "tbl_subCategory_productmanagement,tbl_category_productmanagement",
                `tbl_subCategory_productmanagement.customerId=${this.state.customerId} and tbl_subCategory_productmanagement.status="active" and tbl_subCategory_productmanagement.categoryid=tbl_category_productmanagement.id `,
                "tbl_subCategory_productmanagement.id",
                "tbl_subCategory_productmanagement.id DESC"
            );
            subCategoryOptions = subCategory.data

            const categoryvalue = contentBody[i].categorySelected.value
            let subCatOpt = subCategoryOptions.filter((ival) => { return ival.categoryid == categoryvalue })

            contentBody[i]['subCategoryOptions1'] = subCatOpt

            this.setState({ contentBody })

        } catch (error) {
            console.log(error);
        }
    };

    addNewCategory = async () => {
        let categoryArray = {};
        let categoryAddExtra = {};
        let categoryName = this.state.category;
        console.log(categoryName, "categoryName");

        let categoryOptions = this.state.categoryOptions;

        if (!categoryName) {
            this.setState({ errorCategory: "Enter Categoty Name" });
            return false;
        } else {
            this.setState({ errorCategory: "", btnDisable: true });
            categoryArray.name = categoryName.trim();
            categoryArray.status = "active";
            categoryArray.customerId = this.state.customerId;

            try {
                const result = await CmsContent.addMaster(
                    "tbl_category_productmanagement",
                    categoryArray
                );
                if (result) {
                    categoryAddExtra.value = result.data.insertId;
                    categoryAddExtra.label = categoryName;
                    categoryOptions.splice(0, 0, categoryAddExtra);
                    this.setState({
                        category: "",
                        btnDisable: false,
                        alertVisible: true,
                        categoryOptions,
                    });
                    setTimeout(() => this.setState({ alertVisible: false }), 3000);
                    this.mainFun();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    addNewSubCategory = async () => {
        let subCategoryArray = {};
        let subCategoryAddExtra = {};
        let subCategoryName = this.state.subCategory;

        let subCategoryOptions = this.state.subCategoryOptions;
        if (!subCategoryName) {
            this.setState({ errorSubCategory: "Enter Sub-Categoty Name" });
            return false;
        } else {
            this.setState({ errorSubCategory: "", btnDisable: true });
            subCategoryArray.name = subCategoryName.trim();
            subCategoryArray.status = "active";
            subCategoryArray.customerId = this.state.customerId;

            try {
                const result = await CmsContent.addMaster(
                    "tbl_subCategory_productmanagement",
                    subCategoryArray
                );

                if (result) {
                    subCategoryAddExtra.value = result.data.insertId;
                    subCategoryAddExtra.label = subCategoryName;
                    subCategoryOptions.splice(0, 0, subCategoryAddExtra);
                    this.setState({
                        subCategory: "",
                        btnDisable: false,
                        alertVisible: true,
                        subCategoryOptions,
                    });
                    setTimeout(
                        (dataIndex) => this.setState({ alertVisible: false }),
                        3000
                    );
                    this.mainFun();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    addNewBrand = async () => {
        let brandArray = {};
        let brandAddExtra = {};
        let brandName = this.state.brand;
        let brandOptions = this.state.brandOptions;
        if (!brandName) {
            this.setState({ errorBrand: "Enter Brand Name" });
            return false;
        } else {

            this.setState({ errorBrand: "", btnDisable: true });
            brandArray.brand = brandName.trim();
            brandArray.status = "active";
            brandArray.customerId = this.state.customerId;

            try {

                const result = await CmsContent.addMaster(
                    "tbl_brand_master",
                    brandArray
                );

                if (result) {

                    brandAddExtra.value = result.data.insertId;
                    brandAddExtra.label = brandName;
                    brandOptions.splice(0, 0, brandAddExtra);
                    this.setState({
                        brand: "",
                        btnDisable: false,
                        alertVisible: true,
                        brandOptions,
                    });
                    setTimeout(
                        (dataIndex) => this.setState({ alertVisible: false }),
                        3000
                    );
                    this.mainFun();
                }
            } catch (error) {
                console.log(error);
            }
        }
    };
    addNewProductSize = async () => {
        let { productSize, customerId, btnDisable, alertVisible } = this.state;
        console.log(productSize, 'productSize')
        if (productSize === "") {
            this.setState({ errorProductSize: " Please enter size" });
            return false
        } else {
            try {
                let object = {};
                object.name = productSize.trim();
                object.customerId = customerId;
                this.setState({ errorProductSize: "", btnDisable: true });
                const result = await CmsContent.addMaster(
                    "tbl_product_size",
                    object
                );

                if (result) {
                    console.log(result, 'result')
                    this.setState({
                        btnDisable: false,
                        alertVisible: true,
                    });
                    setTimeout(
                        () => this.setState({ alertVisible: false }),
                        3000
                    );
                    this.mainFun();
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
    addContent = async (index) => {
        let { contentBody } = this.state;

        contentBody.push({
            ProductName: '',
            ProductCode: '',
            ProductPrice: '',
            ProductSize: '',
            imageContent: {},
            files: [],
            categorySelected: "",
            categoryId: "",
            categoryName: "",
            categoryOptions: [],
            subCategorySelected: "",
            subCategoryId: "",
            subCategoryName: "",
            subCategoryOptions: [],
            brandSelected: "",
            brandId: "",
            brandName: "",
            brandOptions: [],
            // colourSelected: "",
            // colourId: "",
            colourName: "",
            colourOptions: [],
            category: "",
            subCategory: "",
            brand: "",
            // colour: "",
            errorCategory: "",
            errorSubCategory: "",
            errorBrand: "",
            // errorColour: "",
        })
        await this.setState({ contentBody });
    }
    removeContent = async (a) => {
        const { contentBody } = this.state;
        let index = a;
        contentBody.splice(index, 1);
        this.setState({ contentBody });
    }
    updateTheme = async () => {
        try {
            let { contentBody, EditData, PageData, Files } = this.state
            await this.setState({ submitting: true })
            let formData = new FormData()
            formData.append('id', EditData.id)
            formData.append('themeId', PageData.themeId)
            formData.append('name', PageData.pageName)
            Files.map((ival, i) => {
                let a = Object.keys(ival)[0];
                formData.append(a, ival[a])
            });
            if (PageData && PageData.autherSelected && PageData.autherSelected[0] && PageData.autherSelected[0].value) {
                formData.append("authorid", PageData.autherSelected[0].value)
            } else if (PageData && PageData.autherSelected && PageData.autherSelected.value) {
                formData.append("authorid", PageData.autherSelected.value)
            }
            if (contentBody && contentBody.length > 0) {
                if (contentBody && contentBody.length) {

                    formData.append('sheetContent', JSON.stringify(contentBody))
                }
            }

            if (this.state.isEdit) {
                const themeUpdate = await http.put(
                    ACCESS_POINT + `/cmsContent/tbl_pages`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }
                ).then((response) => {

                    if (response && response.status === 200) {
                        this.setState({ msgShow: true, msg: 'Theme updated successfully!' })
                        setTimeout(() => {
                            this.setState({ msgShow: false, msg: '', submitting: false })
                        }, 3000);
                        window.location.reload()
                    }
                })
            }
        } catch (error) {
            console.log(error, 'error');
        }

    }
    submitTheme = async () => {

        try {
            let { PageData, contentBody, Files } = this.state
            await this.setState({ submitting: true })
            const formData = new FormData()
            formData.append('themeId', PageData.themeId)
            formData.append('name', PageData.pageName)
            formData.append('customerId', PageData.customerId)
            formData.append("authorid", PageData.autherSelected.value);
            Files.map((ival, i) => {
                let a = Object.keys(ival)[0];
                formData.append(a, ival[a])
            });
            if (contentBody && contentBody.length > 0) {

                if (contentBody && contentBody.length) {

                    formData.append('sheetContent', JSON.stringify(contentBody))
                }
            }
            // console.log([...formData], "formDataformDataformDataformData");
            if (!this.state.isEdit) {
                const themeUpdate = CmsContent.themeInsert('tbl_pages', formData).then((response) => {
                    // console.log(response, 'response')
                    if (response && response.status === 200) {
                        this.setState({ msgShow: true, msg: 'Theme updated successfully!' })
                        setTimeout(() => {
                            this.setState({ msgShow: false, msg: '', submitting: false })
                        }, 3000);
                        window.location.reload()
                    }
                })

            }
        } catch (error) {
            console.log('error:', error)
        }
    }
    handleColour = async (color, colorIndex = null, paletteIndex = null) => {
        let index = localStorage.getItem("index")
        await this.setState({ colorIndex, paletteIndex })
        let { contentBody } = this.state;
        let previouslyAddedColourName = contentBody[index].colourName ? contentBody[index].colourName : ''
        let previouslyAddedColourCode = contentBody[index].colourCode ? contentBody[index].colourCode : []
        let find = previouslyAddedColourCode.findIndex((ival) => { return ival.code === color.code })
        if (find === -1) {
            await this.setState({ colorDivDisable: true })
            if (previouslyAddedColourName.length === 0) {
                previouslyAddedColourName += `${color.name}`
                previouslyAddedColourCode.push(color)
            } else {
                previouslyAddedColourName += `,${color.name}`
                previouslyAddedColourCode.push(color)
            }
            contentBody[index].colourName = previouslyAddedColourName
            contentBody[index].colourCode = previouslyAddedColourCode
            await this.setState({ contentBody, colourAddedMessageShow: true, colourAddedMessage: "Added!", colourAddedMessage_color: "forestgreen", })
            setTimeout(() => {
                this.setState({ colourAddedMessageShow: false, colorDivDisable: false, })
            }, 500);
        } else {
            await this.setState({ colourAddedMessageShow: true, colourAddedMessage: "Already added!", colourAddedMessage_color: "red" })
            setTimeout(() => {
                this.setState({ colourAddedMessageShow: false })
            }, 500);
        }

    }

    AddColour = () => {
        let innerWidth = window.innerWidth
        let { colorDivDisable, colourAddedMessage_color, colourAddedMessage, colourAddedMessageShow, paletteIndex } = this.state;
        //a single line of palette should have 10 colours
        let colours = {
            palette1: [
                { code: "#010101", name: "Black" },
                { code: "#1a1a1a", name: "Charcoal" },
                { code: "#333333", name: "Very Dark Gray" },
                { code: "#4e4c4d", name: "Gunmetal Gray" },
                { code: "#666666", name: "Dark Gray" },
                { code: "#808080", name: "Gray" },
                { code: "#989898", name: "Cool Gray" },
                { code: "#b3b3b3", name: "Light Gray" },
                { code: "#cccccc", name: "Silver" },
                { code: "#e7e7e7", name: "Light Silver" }
            ],
            palette2: [
                { code: "#164700", name: 'Forest Green' },
                { code: "#216b00", name: 'Dark Green' },
                { code: "#2d8e00", name: 'Green' },
                { code: "#38b201", name: 'Vivid Green' },
                { code: "#43d601", name: 'Lime Green' },
                { code: "#50f900", name: 'Neon Green' },
                { code: "#65fa1f", name: 'Bright Lime Green' },
                { code: "#7dfb42", name: 'Spring Green' },
                { code: "#96fb66", name: 'Fresh Green' },
                { code: "#aeff89", name: 'Pale Green' },
            ],
            palette3: [
                { code: "#002855", name: 'Midnight Blue' },
                { code: "#1a3d6a", name: 'Dark Blue' },
                { code: "#335477", name: 'Navy Blue' },
                { code: "#4e6988", name: 'Steel Blue' },
                { code: "#667e99", name: 'Slate Blue' },
                { code: "#8193aa", name: 'Sky Blue' },
                { code: "#99a8bd", name: 'Light Blue' },
                { code: "#b3bfcd", name: 'Pale Blue' },
                { code: "#ccd4de", name: 'Silver Blue' },
                { code: "#e7e9ee", name: 'Light Grayish Blue' }
            ],
            palette4: [
                { code: "#d80e34", name: 'Crimson' },
                { code: "#db1948", name: 'Ruby Red' },
                { code: "#dc335e", name: 'Coral Red' },
                { code: "#e44d71", name: 'Salmon Pink' },
                { code: "#eb6484", name: 'Rose Pink' },
                { code: "#e96586", name: 'Hot Pink' },
                { code: "#ef99ae", name: 'Light Pink' },
                { code: "#f2b4c5", name: 'Pale Pink' },
                { code: "#f8ccd6", name: 'Light Rose' },
                { code: "#fbe6eb", name: 'Blush Pink' },
            ],
            palette5: [
                { code: "#980500", name: 'Dark Red' },
                { code: "#b20701", name: 'Maroon' },
                { code: "#cc0900", name: 'Crimson' },
                { code: "#ea0d00", name: 'Scarlet' },
                { code: "red", name: 'Red' },
                { code: "#fe0f00", name: 'Fire Engine Red' },
                { code: "#ff1d1d", name: 'Bright Red' },
                { code: "#ff4343", name: 'Coral' },
                { code: "#ff6665", name: 'Pinkish Red' },
                { code: "#ff898a", name: 'Salmon' },
            ],
            palette6: [
                { code: "#692b03", name: 'Rust Brown' },
                { code: "#8c3903", name: 'Burnt Orange' },
                { code: "#ae4a03", name: 'Sienna' },
                { code: "#d05803", name: 'Terra Cotta' },
                { code: "#ff5a00", name: 'Vivid Orange' },
                { code: "#f36506", name: 'Pumpkin Orange' },
                { code: "#f57923", name: 'Tangerine' },
                { code: "#fa9047", name: 'Apricot' },
                { code: "#fba569", name: 'Coral Orange' },
                { code: "#fba569", name: 'Peach' },
            ],
            palette7: [
                { code: "yellow", name: 'Yellow' },
                { code: "#fffb06", name: 'Lemon Yellow' },
                { code: "#ffc904", name: 'Golden Yellow' },
                { code: "#ffb804", name: 'Amber Yellow' },
                { code: "#f7c41b", name: 'Sunshine Yellow' },
                { code: "#f4be02", name: 'Bright Yellow' },
                { code: "#f7cc32", name: 'Yellow Gold' },
                { code: "#f9d349", name: 'Canary Yellow' },
                { code: "#fad053", name: 'Pastel Yellow' },
                { code: "#fce49a", name: 'Cream Yellow' },

            ],
            palette8: [
                { code: "#b5be03", name: 'Lime Green' },
                { code: "#bcc419", name: 'Yellow-Green' },
                { code: "#c3cb32", name: 'Chartreuse' },
                { code: "#cbd14c", name: 'Yellow' },
                { code: "#d5d85d", name: 'Pale Yellow' },
                { code: "#d2d86c", name: 'Light Yellow' },
                { code: "#dcde82", name: 'Pastel Yellow' },
                { code: "#e1e4a1", name: 'Light Cream' },
                { code: "#f1f2cb", name: 'Light Beige' },
                { code: "#fbf7e7", name: 'Pale Ivory' },
            ],
            palette9: [
                { code: "#0098a8", name: 'Teal Blue' },
                { code: "#1ba1b6", name: 'Cerulean Blue' },
                { code: "#33abba", name: 'Turquoise' },
                { code: "#4cb6c2", name: 'Light Blue' },
                { code: "#66c1ca", name: 'Sky Blue' },
                { code: "#a4dbe7", name: 'Light Sky Blue' },
                { code: "#abdfe9", name: 'Powder Blue' },
                { code: "#b3e4ec", name: 'Baby Blue' },
                { code: "#bee6ef", name: 'Ice Blue' },
                { code: "#c9e8f2", name: 'Pale Blue' },
            ],
            palette10: [
                { code: "#013f4e", name: 'Dark Teal' },
                { code: "#1a515e", name: 'Navy Blue' },
                { code: "#346470", name: 'Deep Blue' },
                { code: "#4d7783", name: 'Steel Blue' },
                { code: "#668b94", name: 'Slate Blue' },
                { code: "#819fa7", name: 'Blue Gray' },
                { code: "#9ab1b9", name: 'Light Blue Gray' },
                { code: "#ccd8da", name: 'Silver Gray' },
                { code: "#ccd8da", name: 'Silver Gray' },
                { code: "#e8eaef", name: 'Light Grayish Blue' },
            ],
            palette11: [
                { code: "#0133a0", name: 'Royal Blue' },
                { code: "#1b47ac", name: 'Sapphire Blue' },
                { code: "#355db4", name: 'Cobalt Blue' },
                { code: "#4d71bd", name: 'Azure Blue' },
                { code: "#6685c6", name: 'Sky Blue' },
                { code: "#8099cf", name: 'Baby Blue' },
                { code: "#96add9", name: 'Powder Blue' },
                { code: "#96add9", name: 'Powder Blue' },
                { code: "#cad4ed", name: 'Periwinkle Blue' },
                { code: "#e4ebf5", name: 'Pale Blue Gray' },
            ],
            palette12: [
                { code: "#521478", name: "Deep Purple" },
                { code: "#622084", name: "Indigo" },
                { code: "#773995", name: "Violet" },
                { code: "#8651a1", name: "Purple" },
                { code: "#9868b1", name: "Lavender" },
                { code: "#a883be", name: "Lilac" },
                { code: "#bb9dcb", name: "Pale Lilac" },
                { code: "#ccb5d6", name: "Mauve" },
                { code: "#c6b0bd", name: "Dusty Rose" },
                { code: "#cbb8c4", name: "Light Mauve" }
            ],
            palette13: [
                { code: "#4f372c", name: "Russet" },
                { code: "#61493e", name: "Umber" },
                { code: "#725e57", name: "Slate Gray" },
                { code: "#83726a", name: "Taupe Gray" },
                { code: "#958780", name: "Cool Gray" },
                { code: "#a99b94", name: "Gray" },
                { code: "#b8afaa", name: "Ash Gray" },
                { code: "#cac3c2", name: "Silver Gray" },
                { code: "#dcd7d5", name: "Light Gray" },
                { code: "#edecea", name: "Pale Gray" }
            ],
            palette14: [
                { code: "#dbd3c7", name: "Cotton Grey" },
                { code: "#ddd9cc", name: "Pale Silver" },
                { code: "#e0dbd1", name: "Pearl Grey" },
                { code: "#e5e0d7", name: "Light Grey" },
                { code: "#e8e3dd", name: "Silver White" },
                { code: "#ede8e2", name: "Ivory White" },
                { code: "#f1ecea", name: "Snow White" },
                { code: "#f3f2ef", name: "Cream White" },
                { code: "#f7f6f4", name: "Pure White" },
                { code: "white", name: "White" }
            ],

        }
        let ColourEnt = Object.entries(colours)
        return (
            <React.Fragment>
                <div className='container'>
                    <center>
                        {
                            ColourEnt.map((ival, i) => {
                                return (
                                    <>
                                        <div className='row d-flex justify-content-center' >
                                            {
                                                ival && ival[1].length && ival[1].map((jval, colorIndex) => {
                                                    if (innerWidth < 985) {
                                                        return (
                                                            <div aria-disabled={colorDivDisable}
                                                                className={(colorDivDisable) ? 'col-sm-12 is-disabled' : ' col-sm-12 palette'
                                                                }>
                                                                <div style={{ backgroundColor: jval.code, height: "40px", width: "270px" }}
                                                                    onClick={() => { this.handleColour(jval, colorIndex, i) }}
                                                                >
                                                                    {
                                                                        innerWidth < 985 && colourAddedMessageShow && this.state.colorIndex === colorIndex && paletteIndex === i &&
                                                                        <span style={{
                                                                            textShadow: (i === 0 && (colorIndex === 0 || colorIndex === 1)) ?
                                                                                "rgb(243 237 237) -1px -1px 0px"
                                                                                :
                                                                                "-1px -1px 0 black"
                                                                        }}>
                                                                            {colourAddedMessage}
                                                                        </span>
                                                                    }

                                                                </div>
                                                            </div>
                                                        )
                                                    } else if (innerWidth > 985) {
                                                        return (
                                                            <div aria-disabled={colorDivDisable}
                                                                className={(colorDivDisable) ? 'is-disabled' : 'palette'
                                                                }>
                                                                <div style={{ backgroundColor: jval.code, height: "40px", width: "40px" }}
                                                                    onClick={() => { this.handleColour(jval) }}
                                                                />
                                                            </div>
                                                        )
                                                    }

                                                })
                                            }
                                        </div>
                                    </>

                                )
                            })
                        }
                    </center>
                    <center>
                        {
                            innerWidth > 985 && colourAddedMessageShow
                                ?
                                <span style={{ fontWeight: 600, color: colourAddedMessage_color }}>  {colourAddedMessage} </span>
                                :
                                <></>
                        }
                    </center>

                </div >
            </React.Fragment >
        );
    }

    removeColour = async (i) => {
        let { contentBody } = this.state;
        contentBody[i].colourName = "";
        contentBody[i].colourCode = [];
        await this.setState({ contentBody })
    }

    render() {
        let { contentBody } = this.state;
        const {
            categoryOptions,
            errorCategory,
            alertVisible,
            btnDisable,
            category,
            brandOptions,
            subCategory,
            errorSubCategory,
            brand,
            errorBrand,
            errorProductSize
            // colour,
            // errorColour,
        } = this.state;
        let index = 0
        return (
            <React.Fragment>

                {
                    contentBody
                        ?
                        contentBody.map((ival, i) => {
                            index = i + 1
                            return (
                                <div>
                                    <center> <h2 className="text-body"> Product {index}</h2></center>
                                    <div className="row form-group">
                                        <div className="col-sm-1" />
                                        <div className="col-sm-1">
                                            <i class="fa fa-times-circle"
                                                style={{ fontSize: "25px" }}
                                                onClick={() => this.removeContent(i)}
                                            ></i>
                                        </div>
                                    </div>
                                    <br></br>

                                    <div className="row form-group">
                                        <div className="col-sm-2" />

                                        <div className="col-sm-2">
                                            <label for="exampleInputEmail1">Select Category</label>
                                        </div>
                                        <div className="col-sm-5">
                                            <SingleSelect
                                                handleChange={(d) => this.categorySelect(d, i)}
                                                options={categoryOptions}
                                                selectedService={ival.categorySelected}
                                            />
                                        </div>

                                        <div className="col-sm-1.5" >
                                            <LoginModal
                                                buttonTitle="Add Category"
                                                buttonStyle={{ width: "170px" }}
                                                title="Add Category"
                                                id="Category"
                                                extraClass="btn-width"
                                                onClick={this.reset}
                                                bodyText={
                                                    <AddCategory
                                                        category={category}
                                                        handleChange={(e) => this.handleChange(e, i)}
                                                        addNewCategory={this.addNewCategory}
                                                        error={errorCategory}
                                                        alert={alertVisible}
                                                        dismiss={this.onDismiss}
                                                        disableValue={btnDisable}
                                                        alertMsg="New Category Added"
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-sm-2" />

                                        <div className="col-sm-2">
                                            <label for="exampleInputEmail1">
                                                Select Sub-Category
                                            </label>
                                        </div>
                                        <div className="col-sm-5">
                                            <SingleSelect
                                                handleChange={(d) => this.subCategorySelect(d, i)}
                                                options={ival.subCategoryOptions1}
                                                selectedService={ival.subCategorySelected}
                                            />
                                        </div>
                                        <div className="col-sm-1.5" >
                                            <LoginModal
                                                buttonTitle="Add SubCategory"
                                                buttonStyle={{ width: "170px" }}
                                                title="Add SubCategory"
                                                id="subCategory"
                                                extraClass="btn-width"
                                                bodyText={
                                                    <AddProductSubcategory
                                                        subCategory={subCategory}
                                                        handleChange={(e) => this.handleChange(e, i)}
                                                        addNewSubCategory={this.addNewSubCategory}
                                                        error={errorSubCategory}
                                                        alert={alertVisible}
                                                        dismiss={this.onDismiss}
                                                        disableValue={btnDisable}
                                                        alertMsg="New subCategory Added"
                                                    />
                                                }
                                            />
                                        </div>
                                        <div className="col-sm-1">
                                            <button
                                                type="button"
                                                className="btn btn-info"
                                                onClick={(d) => this.refreshQuiz(d, i)}
                                            >
                                                Refresh
                                            </button>
                                        </div>

                                    </div>

                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label for="exampleInputEmail1">Select Brand</label>
                                        </div>
                                        <div className="col-sm-5">
                                            <SingleSelect
                                                handleChange={(d) => this.brandSelect(d, i)}
                                                options={brandOptions}
                                                selectedService={ival.brandSelected}
                                            />
                                        </div>
                                        <div className="col-sm-1.5">
                                            <LoginModal
                                                buttonTitle="Add Brand"
                                                buttonStyle={{ width: "170px" }}
                                                title="Add Brand"
                                                id="brand"
                                                extraClass="btn-width"
                                                bodyText={
                                                    <AddBrand
                                                        brand={brand}
                                                        handleChange={(e) => this.handleChange(e, i)}
                                                        addNewBrand={this.addNewBrand}
                                                        error={errorBrand}
                                                        alert={alertVisible}
                                                        dismiss={this.onDismiss}
                                                        disableValue={btnDisable}
                                                        alertMsg="New brand Added"
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label for="exampleInputEmail1">Select color</label>
                                        </div>
                                        <div className="col-sm-5">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="productColor"
                                                name="productColor"
                                                placeholder=" Selected colours will appear here"
                                                value={this.state.contentBody[i].colourName}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-sm-1.5" >
                                            <button
                                                type="button"
                                                className={`btn btn-btn-width`}
                                                style={{ width: "170px" }}
                                                data-toggle="modal"
                                                data-target={`#colour`}
                                                disabled={false}
                                                // onClick={onClick}
                                                onClick={() => {
                                                    // console.log('clicked button ')
                                                    localStorage.setItem("index", i)
                                                }}
                                            >
                                                Add Colour
                                            </button>
                                            <div
                                                className="modal fade"
                                                id="colour"
                                                tabindex="-1"
                                                role="dialog"
                                                aria-labelledby="exampleModalLabel"
                                                aria-hidden="true"
                                            >
                                                <div className={`modal-dialog  modal-lg}`} role="document">
                                                    <div className="modal-content" style={{ width: window.innerWidth < 985 ? "fit-content" : '800px' }}>
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="exampleModalLabel">
                                                                Add Colour
                                                            </h5>
                                                            <button
                                                                type="button"
                                                                className="close "
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true"
                                                                    onClick={() => {
                                                                        console.log('clicked span ')
                                                                        localStorage.removeItem("index")
                                                                    }}
                                                                >&times;</span>
                                                            </button>
                                                        </div>
                                                        <div className="modal-body"
                                                        >{this.AddColour()}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        &nbsp;&nbsp;
                                        <div className="col-sm-1.5" >
                                            <button
                                                type="button"
                                                className={`btn btn-primary`}
                                                style={{ width: "90px" }}
                                                onClick={() => {
                                                    this.removeColour(i)
                                                }}
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label >
                                                Product Name
                                            </label>
                                        </div>
                                        <div className="col-sm-5">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="contentTitle"
                                                name="ProductName"
                                                placeholder="Enter Product Name"
                                                value={this.state.contentBody[i].ProductName}
                                                onChange={(e) => this.handlChange(e, i)}
                                            />
                                        </div>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label >
                                                Product Code
                                            </label>
                                        </div>
                                        <div className="col-sm-5">
                                            <input
                                                type="tel"
                                                className="form-control"
                                                id="contentTitle"
                                                name="ProductCode"
                                                placeholder="Enter Product Code"
                                                value={this.state.contentBody[i].ProductCode}
                                                onChange={(e) => this.handlChange(e, i)}
                                            />
                                        </div>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label >
                                                Product Price
                                            </label>
                                        </div>
                                        <div className="col-sm-5">
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="contentTitle"
                                                name="ProductPrice"
                                                placeholder="Enter Product Price"
                                                value={this.state.contentBody[i].ProductPrice}
                                                onChange={(e) => this.handlChange(e, i)}
                                            />
                                        </div>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label >
                                                Product Size
                                            </label>
                                        </div>
                                        <div className="col-sm-5">
                                            <MultiSelect
                                                options={this.state.sizes}
                                                id="contentTitle"
                                                name="ProductSize"
                                                placeholder="Enter Product Size"
                                                selectedService={this.state.contentBody[i].ProductSize}
                                                handleChange={(e) => this.handlChange(e, i, "ProductSize")}
                                            />
                                        </div>
                                        <div className="col-sm-1.5">
                                            <LoginModal
                                                buttonTitle="Add Product size"
                                                buttonStyle={{ width: "170px" }}
                                                title="Add Product size"
                                                id="productSze"
                                                extraClass="btn-width"
                                                bodyText={
                                                    <AddProductSize
                                                        handleChange={(e) => this.handleChange(e, i)}
                                                        addNewProductSize={this.addNewProductSize}
                                                        error={errorProductSize}
                                                        alert={alertVisible}
                                                        dismiss={this.onDismiss}
                                                        disableValue={btnDisable}
                                                        productSize={this.state.productSize}
                                                        alertMsg="New Size Added"
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                    <br></br>
                                    {ival.files.map((jval, j) => {
                                        let jndex = j + 1;
                                        return (
                                            <React.Fragment>
                                                <div className="row form-group">
                                                    <div className="col-sm-2" />

                                                    <div className="col-sm-2">
                                                        <label htmlFor="exampleInputEmail1">
                                                            Upload Image {jndex}
                                                        </label>
                                                    </div>
                                                    <div className="col-sm-5">
                                                        <input
                                                            type="file"
                                                            className="custom-file-input"
                                                            onChange={d => this.HandlingChange(i, d, 'Img', j)}
                                                            accept=".jpg, .jpeg, .png"
                                                            maxFileSize={"300KB"}
                                                            imagePreviewMaxHeight="4500px"


                                                        />

                                                        <label className="custom-file-label" htmlFor="customFileThumbnail" style={{ fontSize: '9px' }}>
                                                            {jval.Img ? jval.Img : "Upload file"}
                                                        </label>

                                                    </div>
                                                    <div className="col-sm-1">
                                                        <i class="fa fa-times-circle"
                                                            style={{ fontSize: "25px" }}
                                                            onClick={() => this.removeArray(index, jndex)}
                                                        ></i>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        )
                                    })
                                    }
                                    {ival.imagedimension ?
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <div className="col-sm-5" >
                                                <label style={{ color: "red" }} >Maximum image size : 300KB</label><br />
                                                <label style={{ color: "red" }} >Image dimension :640 x 800 pixels</label><br />
                                                <label style={{ color: "red" }} >Maximum image count : 5 </label><br />
                                            </div>
                                        </div>

                                        :
                                        <></>
                                    }
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-6" />
                                        <div className="col-sm-2">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => this.AddNewImage(i)}
                                            >
                                                Add Images
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )

                        })
                        :
                        null

                }
                <br></br>
                <div className="row">
                    <div className="col-sm-5" >
                    </div>
                    <div className="col-sm-5">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => this.addContent(index)}
                        >Add More Products </button>
                    </div>

                </div>
                <br />
                <br />
                <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2" />
                    <div className="col-sm-5">
                        <button
                            type="button"
                            className={`btn ${this.state.isEdit ? "btn-secondary" : "btn-primary"
                                }`}
                            disabled={this.state.btnDisable}
                            onClick={this.state.isEdit ? this.updateTheme : this.submitTheme}
                        >
                            {
                                (this.state.submitting)
                                    ?
                                    "Submitting..."
                                    :
                                    (this.state.isEdit)
                                        ?
                                        "Update"
                                        :
                                        "Add Page"
                            }
                        </button>
                    </div>
                    <div className="col-sm-3" />
                </div>
                <center>
                    <div>
                        {
                            (this.state.msgShow)
                                ?
                                <div style={{ color: 'forestgreen', fontSize: 'medium', fontWeight: 600, backgroundColor: '#AAF7C2', borderRadius: '7px' }}> {this.state.msg}</div>
                                :
                                <div></div>

                        }
                    </div>
                </center>
            </React.Fragment >
        )
    }
}
export default Theme12;