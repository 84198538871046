import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../MiddleWare/CmsContent";
import Datatable from "../../components/Datatable/Datatable";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import CMSaddcatsubcatpdf from "../../components/PDF/Cmsaddcatsubcat";

class CharMaster extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            alertVisible: false,
            editvalue: "",
            alias: "",
            editid: null,
            disableValue: false,
            addNewSubCategory: false,
            error: null,
            button: "Submit",
            index: null,
            formAlertdelete: false,
            customerId: localStorage.getItem("userId"),
        };
    }
    async componentDidMount() {
        this.Mainfun()
    }
    async Mainfun() {
        try {
            // const result = await cmsContent.getFreedom("tbl_category_master");

            const result = await cmsContent.getFreedom(
                "*",
                "tbl_EOI_Characteristics_master",
                `customerId='${this.state.customerId}'`,
                "id",
                "id DESC"
            );

            if (result) {
                //console.log(result);
                this.setState({ data: result.data });
            }

        } catch (error) {
            console.log(error);
        }
    }
    column = [
        {
            Header: "Category",
            accessor: "name",
        },
        {
            Header: "Alias",
            accessor: "alias",
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: (d) => this.edit(d),
        },
        {
            Header: "Delete",
            accessor: "delete",
            Cell: (d) => this.delete(d),
        },
    ]

    edit = (d) => {
        let value = d;
        return (
            <button
                type="button"
                className="btn btn-info"
                onClick={() => this.edition(value)}
            >
                Edit
            </button>
        );
    };
    edition = (value) => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });

        let values = value.original;
        this.setState({
            index: value.index,
            editvalue: values.name,
            editid: values.id,
            alias: values.alias,
            button: "Update",
        });
    };
    delete = (d) => {

        return (
            <button
                type="button"
                className="btn btn-danger"
                onClick={() => this.deletion(d)}
            >
                Delete
            </button>
        );
    };
    deletion = async (value) => {
        //Getting Previous state data

        const previousData = [...this.state.data];
        // Seperating data row using row-index
        const getData = { ...previousData[value.index] };

        //getting id on that data
        const id = getData.id;
        //removing specific id in previous state data
        const data = previousData.filter((delelteid) => delelteid.id !== id);

        try {
            const result = await cmsContent.deleteMaster(
                "tbl_EOI_Characteristics_master",
                id
            );
            if (result) {
                this.setState({ editvalue: "", formAlertdelete: true, data });

                setTimeout(() => this.setState({ formAlertdelete: false }), 3000);
            }
        } catch (error) {
            this.setState({ data: previousData });
            console.log(error);
        }
    };
    handlechange = (e) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: value });
    };
    addnew = async () => {
        let value = this.state.editvalue;
        let { alias } = this.state;
        let customerId = this.state.customerId;
        if (value === null || value.trim() === "" || alias == "") {
            return false;
        }
        let categoryArray = {};
        categoryArray.name = value.trim();
        categoryArray.status = "active";
        categoryArray.alias = alias
        categoryArray.customerId = customerId;

        try {
            this.setState({ disableValue: true });
            const result = await cmsContent.addMaster(
                "tbl_EOI_Characteristics_master",
                categoryArray
            );

            if (result) {
                let valueArray = {};
                valueArray.value = result.data.insertId;
                valueArray.name = categoryArray.name;
                console.log(valueArray);
                const newData = [valueArray, ...this.state.data];
                this.setState({
                    data: newData,
                    alertVisible: true,
                    editvalue: "",
                    alias: "",
                    editid: null,
                    disableValue: false,
                    addNewSubCategory: false,
                    error: null,
                    button: "Submit",
                    index: null,
                });
                this.Mainfun()
                setTimeout(() => {
                    this.setState({

                        alertVisible: false,

                    });
                }, 2000);
            }
        } catch (error) {
            console.log(error);
        }
    };
    update = async () => {
        let value = this.state.editvalue;
        let { alias } = this.state;
        if (value === "" || alias == "") {
            this.setState({ error: "Value Cannot Be Empty" });
            return false;
        } else {
            this.setState({ disableValue: true });
            try {
                let previousdata = [...this.state.data];
                let index = this.state.index;

                let id = this.state.editid;
                let subCategoryArray = {};
                subCategoryArray.name = value;
                subCategoryArray.alias = alias
                // subCategoryArray.status = "active";
                const result = await cmsContent.updateMaster(
                    "tbl_EOI_Characteristics_master",
                    id,
                    subCategoryArray
                );
                if (result) {
                    previousdata[index].name = value;

                    this.setState({
                        data: previousdata, alertVisible: true,
                        editvalue: "",
                        editid: null,
                        addNewSubCategory: false,
                        error: null,
                        button: "Submit",
                        index: null,
                    });
                    this.Mainfun()
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                    }, 2000);
                }
            } catch (error) {
                console.log(error);
            }

        }
    };
    onDismiss = () => {
        this.setState({ alertVisible: false });
        this.setState({ formAlertdelete: false });
    };
    render() {
        const {
            editvalue, alias,
            alertVisible,
            disableValue,
            error,
            button,
            formAlertdelete,
        } = this.state;

        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Characteristics Master</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color="success"
                                                    isOpen={alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    New Category Added
                        </Alert>

                                                <Alert
                                                    className="badge-content"
                                                    color="danger"
                                                    isOpen={formAlertdelete}
                                                    toggle={this.onDismiss}
                                                >
                                                    Delete
                        </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="subcategory">Characteristics</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    name="editvalue"
                                                    placeholder="Enter Characteristics Name"
                                                    className="form-control"
                                                    value={editvalue}
                                                    onChange={(e) => this.handlechange(e)}
                                                />
                                                <span className="modal-error-show">{error}</span>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="subcategory">Code</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    name="alias"
                                                    placeholder="Enter Characteristics Name"
                                                    className="form-control"
                                                    value={alias}
                                                    onChange={(e) => this.handlechange(e)}
                                                />
                                                <span className="modal-error-show">{error}</span>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2" />
                                            <div className="col-sm-5">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    disabled={disableValue}
                                                    onClick={
                                                        button === "Update" ? this.update : this.addnew
                                                    }
                                                >
                                                    {this.state.button}
                                                </button>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <br></br>


                                        {this.state.data && (
                                            <Datatable
                                                data={this.state.data}
                                                columnHeading={this.column}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default CharMaster;
