import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import CmsContent from "../../../MiddleWare/CmsContent";
import Utilities from "../../../MiddleWare/Utilities";
import SingleSelect from "../../../components/Form/SingleSelect";
import MultiSelect from "../../../components/Form/MultiSelect";
import CanvasJSReact from "../../../canvas/canvasjs.react";
import SpinKit from "./SpinKit";
import Dashboard1 from './dashboard1';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJS = CanvasJSReact.CanvasJS;

export default class DifuzaDashboard extends Component {
  constructor(props) {
    super(props);
    Utilities.serviceList(this.props.match.params.id);
    this.state = {
      customerId: localStorage.getItem("userId"),
      serviceId: this.props.match.params.id,
      selecteduser: [],
      quiz: [],
      formoption: [],
      quizUserList: [],
      number: null,
      SelectYaxis: [],
      portletList: [
        { label: 'Video', value: 2 },
        { label: 'Evaluation', value: 8 }
      ],
    };
  }
  addSymbols(e) {
    var suffixes = ["", "K", "M", "B"];
    var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);
    if (order > suffixes.length - 1) order = suffixes.length - 1;
    var suffix = suffixes[order];
    return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
  }
  getContent = async () => {
    let serviceId = this.state.serviceId;
    let customerId = this.state.customerId;
    try {
      let learnerCount = await CmsContent.getThreeConditionedValue(
        "tbl_user_web",
        "customerId",
        customerId,
        "serviceId",
        serviceId,
        "userType",
        6,
        "count (*) as count"
      );
      learnerCount = learnerCount.data[0].count;
      let trainerCount = await CmsContent.getThreeConditionedValue(
        "tbl_user_web",
        "customerId",
        customerId,
        "serviceId",
        serviceId,
        "userType",
        8,
        "count (*) as count"
      );
      trainerCount = trainerCount.data[0].count;
      let cohortCount = await CmsContent.getTwoConditionedValue(
        "tbl_group",
        "customerId",
        customerId,
        "status",
        "active",
        "count (*) as count"
      );
      cohortCount = cohortCount.data[0].count;
      let portletCount = await CmsContent.getTwoConditionedValue(
        "tbl_portletname",
        "customerid",
        this.state.customerId,
        "status",
        "active",
        "count(*) as counts"
      );
      portletCount = portletCount.data[0].counts;

      let formdata = await CmsContent.getTwoConditionedValue(
        "tbl_form",
        "companyid",
        customerId,
        "status",
        "active",
        "id as value , name as label"
      );

      let formQuestionData = await CmsContent.getTwoConditionedValue(
        "tbl_formquestion",
        "companyid",
        customerId,
        1,
        1
      );

      let formansdata = await CmsContent.getTwoConditionedValue(
        "tbl_formanswer",
        "companyid",
        customerId,
        1,
        1,
        "formid as value , userid as user,createdAt as date, answer as answerjson"
      );

      let date = [];
      formansdata.data.filter((item, key) => {
        date.push({
          value: key,
          label: item.date,
          formid: item.value,
          userid: item.user
        });
      });

      let portTypeCount = await CmsContent.getTwoConditionedValue(
        "tbl_boxtype",
        1,
        1,
        1,
        1,
        "id as value,name as label"
      );

      let quizdata = await CmsContent.getTwoConditionedValue(
        "tbl_question",
        "customerid",
        customerId,
        "type",
        "active",
        "id as value , quizName as label , contentJson as json "
      );


      let quizAnsdata = await CmsContent.getTwoConditionedValue(
        "tbl_quizAnswer",
        "serviceid",
        serviceId,
        1,
        1,
        "id as value ,quizId as quizId,userId as userId ,userName as label , quizanswer as quizJson "
      );


      let surveyData = await CmsContent.getTwoConditionedValue(
        "tbl_survey",
        "customerid",
        customerId,
        1,
        1,
        "id as value , surveyname as label"
      );

      let surveyQues = await CmsContent.getTwoConditionedValue(
        "tbl_surveyQuestion",
        "companyid",
        customerId,
        1,
        1,
        "id as value , questions as label ,options as options "
      );

      let surveyAns = await CmsContent.getTwoConditionedValue(
        "tbl_surveyAnswer",
        "customerid",
        customerId,
        1,
        1,
        "questionid as value , surveyId as surveyid, surveyAnswer as label , mark as mark , userid as userid"
      );

      let portInnerList = {};
      portInnerList.tableName1 = "tbl_boxtype";
      portInnerList.columnName1 = "id";
      portInnerList.tableName2 = "tbl_boxcontent";
      portInnerList.columnName2 = "type";
      portInnerList.tableName3 = "tbl_boxcontent";
      portInnerList.columnName3 = "customerId";
      portInnerList.value = customerId;
      portInnerList.tableName4 = "tbl_boxtype";
      portInnerList.columnName4 = "id";
      portInnerList.select =
        "tbl_boxtype.id as mid,tbl_boxtype.name as mname,tbl_boxcontent.status as active,tbl_boxtype.* ,tbl_boxcontent.*";
      portInnerList.order = "asc";
      let portInnerLists = await CmsContent.getInnerJoinList(portInnerList);
      let textcount = 0;
      let videocount = 0;
      let linkcount = 0;
      let resourcecount = 0;
      let noticount = 0;
      let eventcount = 0;
      let urlcount = 0;
      let evalcount = 0;
      console.log(evalcount)
      let formcount = 0;
      let surveycount = 0;
      let video = portInnerLists.data.filter(function (item) {
        if (item.mid == 1) {
          textcount = textcount + 1;
          return textcount;
        } else if (item.mid == 2) {
          videocount = videocount + 1;
          return videocount;
        } else if (item.mid == 3) {
          linkcount = linkcount + 1;
          return linkcount;
        } else if (item.mid == 4) {
          resourcecount = resourcecount + 1;
          return resourcecount;
        } else if (item.mid == 5) {
          noticount = noticount + 1;
          return noticount;
        } else if (item.mid == 6) {
          eventcount = eventcount + 1;
          return eventcount;
        } else if (item.mid == 7) {
          urlcount = urlcount + 1;
          return urlcount;
        } else if (item.mid == 8) {
          evalcount = evalcount + 1;
       
          return evalcount;
        } else if (item.mid == 9) {
          formcount = formcount + 1;
          return formcount;
        } else if (item.mid == 10) {
          surveycount = surveycount + 1;
          return surveycount;
        }
      });


      var Q = portInnerLists.data.filter((item, i) => {
        return item.mid == 8;
      });
      var F = portInnerLists.data.filter((item, i) => {
        return item.mid == 9;
      });
      var S = portInnerLists.data.filter((item, i) => {
        return item.mid == 10;
      });

      let QList = [];
      let FList = [];
      let SList = [];
      Q.map((element, key) => {
        quizdata.data.map((item, i) => {
          if (item.value == element.evaluation && QList.indexOf(item) === -1) {
            QList.push(item);
          }
        });
      });

      F.map((element, key) => {
        formdata.data.map((item, i) => {
          if (item.value == element.form && FList.indexOf(item) === -1) {
            FList.push(item);
          }
        });
      });

      S.map((element, key) => {
        surveyData.data.map((item, i) => {
          if (item.value == element.survey && SList.indexOf(item) === -1) {
            SList.push(item);
          }
        });
      });


      // var groupBy = function(xs, key) {
      //   return xs.reduce(function(rv, x) {
      //     (rv[x[key]] = rv[x[key]] || []).push(x);
      //     return rv;
      //   }, {});
      // };
      // var groubedByTeam=groupBy(SList, "value")

      // let QList = await CmsContent.getFreedom(

      // );

      let learnerList = await CmsContent.getTwoConditionedValue(
        "tbl_user_web",
        "customerId",
        customerId,
        "status",
        "active",
        "id as value,userName as label"
      );
      let barchartview = this.canvaschart("Portlet List", [
        { label: "Video", y: videocount },
        { label: "Text", y: textcount },
        { label: "Link", y: linkcount },
        { label: "Resource", y: resourcecount },
        { label: "Notification", y: noticount },
        { label: "Event", y: eventcount },
        { label: "URL/JSON", y: urlcount },
        { label: "Evaluation", y: evalcount },
        { label: "Form", y: formcount },
        { label: "Survey", y: surveycount }
      ]);
      let portListCount = this.donutDataStructure(
        [
          "Video",
          "Text",
          "Link",
          "Resource",
          "Notification",
          "Event",
          "URL/JSON",
          "Evaluation",
          "Form"
        ],
        [
          videocount,
          textcount,
          linkcount,
          resourcecount,
          noticount,
          eventcount,
          urlcount,
          evalcount,
          formcount
        ],
        [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#ff6347",
          "#3cb371",
          "#ffa500",
          "#d0839b",
          "#5a3636",
          "#d9fd2a"
        ]
      );
      await this.setState({
        date,
        serviceId,
        learnerCount,
        trainerCount,
        cohortCount,
        portletCount,
        portTypeCount: portTypeCount.data.length,
        portTypelist: portTypeCount,
        portListCount,
        barchartview,
        formdata: formdata.data,
        formQuestionData: formQuestionData.data,
        formansdata: formansdata.data,
        learnerList: learnerList.data,
        quizdata: quizdata.data,
        quizAnsdata: quizAnsdata.data,
        surveyData: surveyData.data,
        surveyQues: surveyQues.data,
        surveyAns: surveyAns.data,
        QList,
        FList,
        SList
      });
    } catch (error) {
      console.log(error);
    }
  }
  canvaschart = (title, array) => {
    const options = {
      theme: "light1",
      animationEnabled: true,
      title: {
        text: title
      },
      data: [
        {
          // Change type to "bar", "area", "spline", "pie",etc.
          type: "column",
          click: e => {
            this.Form(e);
          },
          dataPoints: array
        }
      ]
    };
    return options;
  };
  canvaschart1 = (title, array) => {
    let options = {
      theme: "light1",
      animationEnabled: true,
      title: {
        text: title
      },
      toolTip: {
        content: "{name} - {y}"
      },
      data: array
    };
    return options;
  };
  canvaschart2 = (title, array) => {
    let options = {
      theme: "light1",
      animationEnabled: true,
      title: {
        text: title
      },
      toolTip: {
        content: "{name} - {y}"
      },
      // data: array
      data: array
    };
    return options;
  };

  canvaschart3 = (title, array, question) => {
    let options = {
      theme: "light1",
      animationEnabled: true,
      title: {
        text: title
      },
      axisX: {
        title: question,
        gridThickness: 1,
        tickLength: 10
      },
      toolTip: {
        content: "{name} - {y}"
      },
      // data: array
      data: [
        {
          // Change type to "bar", "area", "spline", "pie",etc.
          type: "column",
          dataPoints: array
        }
      ]
    };
    return options;
  };

  donutDataStructure(label, data, color) {
    let portListCount = {
      labels: label,
      datasets: [
        {
          data: data,
          backgroundColor: color,
          barPercentage: 20
        }
      ]
    };
    return portListCount;
  }
  col(text, count, style, sm = 3) {
    style = "callout callout-" + style;
    return (
      <Col sm={sm}>
        <div className={style}>
          <small className="text-muted">{text}</small>
          <br />
          <strong className="h4">{count}</strong>
        </div>
      </Col>
    );
  }
  formselect = async e => {
    let UserList = await CmsContent.getFreedom(
      "tbl_user_web.userName as label ,tbl_user_web.id as value",
      "tbl_formanswer,tbl_form,tbl_user_web",
      `tbl_form.id = tbl_formanswer.formid and tbl_user_web.id = tbl_formanswer.userid and tbl_formanswer.formid =${e.value} and tbl_user_web.customerId = ${this.state.customerId} `,
      "tbl_formanswer.userid",
      1
    );
    let questionList = await CmsContent.getFreedom(
      "tbl_formquestion.question as label ,tbl_formquestion.id as value",
      "tbl_formquestion",
      `tbl_formquestion.parentid=${e.value}`
    );
    this.setState({
      formselect: e,
      formid: e.value,
      UserList: UserList.data,
      jsonname: questionList.data
    });
  };
  userselect = async e => {
    if (e) {
      await this.setState({ selecteduser: e });
    }
    let use = [];
    this.state.selecteduser.filter((item, ival) => {
      use.push(item.value);
    });
    let strng = use.toString();
    this.setState({ strng });
  };
  quizSelect = async e => {
    const { quizdata, quizAnsdata } = this.state;
    await this.setState({ quizSelect: e });

    let questionOption = [];
    quizdata.map((item, i) => {
      if (item.value == e.value) {
        let option = JSON.parse(item.json);
        option.map((element, key) => {
          questionOption.push({ label: element.question, value: key });
        });
      }
    });

    let quizUser = await CmsContent.getFreedom(
      "userId as value , userName as label",
      "tbl_quizAnswer",
      `tbl_quizAnswer.quizId = ${e.value}`,
      "userId",
      "userId"
    );
    let quizXoption = [];
    quizdata.map((item, index) => {
      if (item.value == e.value) {
        let quizAns = JSON.parse(item.json);
        quizAns.map((element, key) => {
          quizXoption.push({ label: element.question, value: key });
        });
      }
    });
    let quizYoption = [];
    quizAnsdata.map((item, i) => {
      if (item.quizId == e.value) {
        let question = JSON.parse(item.quizJson);
        question.map((element, key) => {
          quizYoption.push({ label: element.answer, value: element.index });
        });
      }
    });
    await this.setState({
      questionOption,
      quizUserList: quizUser.data,
      quizUserlen: quizUser.data.length,
      quizXoption,
      quizYoption
    });
  };

  QuizX = e => {
    this.setState({ QuizX: e });
  };

  Form = e => {
    this.setState({
      formchart1: [],
      formselect: "",
      selecteduser: "",
      number: 0
    });
    if (e.dataPointIndex == 8) {
      let formoption = [];
      formoption.push(
        <div>
          <h3>Form</h3>
        </div>
      );
      this.setState({
        formoption,
        formoptionlen: formoption.length,
        number: 1
      });
    } else if (e.dataPointIndex == 7) {
      let quiz = [];
      quiz.push(
        <div>
          <h3>Evaluation</h3>
        </div>
      );
      this.setState({ quiz, quizlen: quiz.length, number: 2 });
    } else if (e.dataPointIndex == 9) {
      let survey = [];
      survey.push(
        <div>
          <h3>Survey</h3>
        </div>
      );
      this.setState({ survey, surveylen: survey.length, number: 3 });
    }
  };
  fromDateSelect = e => {
    this.setState({ fromDateSelect: e.target.value });
  };
  toDateSelect = e => {
    this.setState({ toDateSelect: e.target.value });
  };
  xaxis = e => {
    this.setState({ SelectXaxis: e, XaxisId: e.value });
  };
  yaxis = async e => {
    if (e) {
      await this.setState({ SelectYaxis: e, YaxisId: e.value });
    }
    let yAxis = [];
    this.state.SelectYaxis.filter((item, ival) => {
      yAxis.push(item.value);
    });
    let yAxisstrng = yAxis.toString();
    this.setState({ yAxisstrng });
  };
  surveySelect = async e => {
    this.setState({ surveySelect: e });
    let SurveyUserList = await CmsContent.getFreedom(
      "tbl_user_web.userName as label ,tbl_user_web.id as value",
      "tbl_surveyAnswer,tbl_survey,tbl_user_web",
      `tbl_survey.id = tbl_surveyAnswer.surveyId and tbl_user_web.id = tbl_surveyAnswer.userid and tbl_surveyAnswer.surveyId =${e.value} and tbl_user_web.customerId = ${this.state.customerId} `,
      "tbl_surveyAnswer.userid",
      1
    );
    // let surQueList = await CmsContent.getFreedom(
    //   "tbl_surveyQuestion.questions as label ,tbl_surveyQuestion.id as value",
    //   "tbl_surveyQuestion",
    //   `tbl_surveyQuestion`
    // );
    this.setState({
      SurveyUserList: SurveyUserList.data,
      surLen: SurveyUserList.data.length
      // surQueList: surQueList.data
    });
  };
  viewchart = async type => {
    const {
      fromDateSelect,
      toDateSelect,
      SelectXaxis,
      SelectYaxis,
      formselect,
      selecteduser,
      UserList,
      formQuestionData,
      yAxisstrng
    } = this.state;
    let poo = [];
    UserList.map((ival, i) => {
      poo.push(ival.value);
    });
    let strng = this.state.strng ? this.state.strng : poo.toString();
    let user = strng ? `and userid in (${strng})` : "";
    let dates =
      fromDateSelect && toDateSelect
        ? `and createdAt between '${fromDateSelect}' and '${toDateSelect}'`
        : "";

    let SelectYaxisd = [];
    SelectYaxis.map((ival, i) => {
      SelectYaxisd.push(ival.value);
    });

    let y = SelectYaxis.length > 1 ? SelectYaxisd.toString() : SelectYaxisd;
    const getFormReport = await CmsContent.getFreedom(
      "answer,questionId,userid",
      "tbl_formanswer",
      `formid = ${formselect.value} ${user} and questionId IN(${SelectXaxis.value}, ${y}) and companyid =${this.state.customerId}  ${dates} `,
      "id",
      "userid DESC"
    );
    let plotChart = [];
    let canPlotChart = null;
    let counting = [];
    let name = [];
    let dataPoints = [];
    if (
      SelectYaxis.length === 1 &&
      (selecteduser.length === 1 || UserList.length === 1) &&
      SelectYaxisd.length === 1
    ) {
      getFormReport.data.map((ival, i) => {
        if (ival.questionId === SelectXaxis.value) {
          name.push(ival.answer);
        } else if (ival.questionId == SelectYaxisd.toString()) {
          counting.push(ival.answer);
        }
      });
      name.map((item, i) => {
        plotChart.push({ label: name[i], y: parseInt(counting[i]), x: i });
      });
      let chart1 = this.canvaschart(formselect.label, plotChart);
      let formchart1 = [];
      formchart1.push(<CanvasJSChart options={chart1} />);
      this.setState({ formchart1 });
    } else if (UserList.length > 1 && SelectYaxis.length === 1) {
      let str = strng.split(",");
      getFormReport.data.map((ival, i) => {
        str.map((jval, i) => {
          if (jval == ival.userid) {
            if (ival.questionId == SelectXaxis.value) {
              name.push({ label: ival.answer, userid: ival.userid });
            } else if (ival.questionId == SelectYaxisd.toString()) {
              counting.push({ label: ival.answer, userid: ival.userid });
            }
          }
        });
      });
      let data = [];
      name.map((ival, i) => {
        data.push({
          label: ival.label,
          y: parseInt(counting[i].label),
          userid: ival.userid,
          name: this.getValueFromArray(ival.userid, this.state.learnerList)
        });
      });
      var chat = [];
      str.map((ival, i) => {
        var json = data.filter(item => {
          return item.userid == ival;
        });
        dataPoints.push({ dataPoints: json });
      });
      let chart1 = this.canvaschart1(formselect.label, dataPoints);
      let formchart1 = [];
      formchart1.push(<CanvasJSChart options={chart1} />);
      this.setState({ formchart1 });
    } else if (selecteduser.length == 1 && SelectYaxis.length > 1) {
      getFormReport.data.map((ival, i) => {
        SelectYaxis.map((jval, i) => {
          if (ival.questionId == SelectXaxis.value) {
            name.push({
              label: ival.answer,
              userid: ival.userid,
              questionId: ival.questionId
            });
          } else if (ival.questionId == jval.value) {
            counting.push({
              label: ival.answer,
              userid: ival.userid,
              questionId: jval.value
            });
          }
        });
      });
      let data = [];
      counting.map((ival, i) => {
        data.push({
          label: name[i].label,
          y: parseInt(ival.label),
          userid: ival.userid,
          questionId: ival.questionId,
          type: "stackedColumn"
        });
      });
      SelectYaxis.map((ival, i) => {
        var json = data.filter(item => {
          return ival.value == item.questionId;
        });
        dataPoints.push({ type: "stackedColumn", dataPoints: json });
      });
      let chart1 = this.canvaschart2(formselect.label, dataPoints);
      let formchart1 = [];
      formchart1.push(<CanvasJSChart options={chart1} />);
      this.setState({ formchart1 });
    } else if (selecteduser.length > 1 && SelectYaxis.length > 1) {
      getFormReport.data.map((ival, i) => {
        SelectYaxis.map((jval, j) => {
          if (ival.questionId == SelectXaxis.value) {
            name.push({
              label: ival.answer,
              userid: ival.userid,
              questionId: ival.questionId
            });
          } else if (ival.questionId == jval.value) {
            counting.push({
              label: ival.answer,
              userid: ival.userid,
              questionId: jval.value
            });
          }
        });
      });
      let data = [];
      counting.map((ival, i) => {
        data.push({
          label: name[i].label,
          y: parseInt(ival.label),
          userid: ival.userid,
          questionId: ival.questionId,
          name: this.getValueFromArray(ival.userid, UserList)
        });
      });
      let secondary = [
        "",
        "secondary",
        "third",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten"
      ];
      data.sort((a, b) => parseFloat(a.questionId) - parseFloat(b.questionId));
      let user = [];
      let ques = [];
      data.map((element, key) => {
        if (user.indexOf(element.userid) === -1) {
          user.push(element.userid);
        }
        if (ques.indexOf(element.questionId) === -1) {
          ques.push(element.questionId);
        }
      });
      user.map((jval, j) => {
        ques.map((ival, i) => {
          let json = data.filter((element, key) => {
            if (element.questionId == ival && element.userid == jval) {
              return element;
            }
          });
          dataPoints.push({
            type: "stackedColumn",
            dataPoints: json,
            axisYType: secondary[j]
          });
        });
      });
      let chart1 = this.canvaschart2(formselect.label, dataPoints);
      let formchart1 = [];
      formchart1.push(<CanvasJSChart options={chart1} />);
      this.setState({ formchart1 });
    }
  };

  ViewEvaluation = () => {
    const {
      QuizX,
      quizSelect,
      strng,
      selecteduser,
      quizUserList,
      yAxisstrng,
      quizAnsdata,
      quizdata
    } = this.state;
    // let ans = (SelectYaxis[0].label == 'yes') || (SelectYaxis.label == 'Yes') ? 2 : 1;
    let data = [];
    let data1 = [];
    let dataPoints = [];
    let count = 0;

    selecteduser.map((item, index) => {
      quizAnsdata.map((element, key) => {
        if (
          quizSelect.value == element.quizId &&
          item.value == element.userId
        ) {
          data.push(element);

          // let ans = JSON.parse(element.quizJson);
          // ans.map((ival,i)=>{

          //   data.push({index : ival.index , answer : ival.answer , userId : element.userId});
          // })
        }
      });
    });
    selecteduser.map((item, index) => {
      data.map((element, key) => {
        if (item.value == element.userId) {
          let ans = JSON.parse(element.quizJson);
          ans.map((ival, i) => {
            data1.push({
              index: ival.index,
              answer: ival.answer,
              userId: element.userId,
              name: element.label
            });
          });
        }
      });
    });
    let datajson = [];
    selecteduser.map((element, key) => {
      let label = "";
      let name = "";
      data1.map((item, i) => {
        if (element.value == item.userId && QuizX.value == item.index) {
          count = count + 1;
          label = item.answer;
          name = item.name;
        }
      });
      datajson.push({ label: label, y: count, name: name });
      count = 0;
    });
    dataPoints.push({ datajson });

    let chart1 = this.canvaschart3(quizSelect.label, datajson, QuizX.label);
    let formchart1 = [];
    formchart1.push(<CanvasJSChart options={chart1} />);
    this.setState({ formchart1 });
  };

  surveyX = async e => {
    let { surveyQues } = this.state;
    let SurX = [];
    var X = surveyQues.filter((item, index) => {
      return e.value == item.value;
    });
    X.map((element, key) => {
      element.options.split(",").map((ival, i) => {
        SurX.push({ label: ival, value: i, QuestionId: element.value });
      });
    });
    await this.setState({ Xaxis: e, SurveyYaxis: SurX });
  };
  surveyY = async e => {
    if (e) {
      await this.setState({ Yaxis: e });
    }
  };

  ViewSurvey = () => {
    const {
      fromDateSelect,
      toDateSelect,
      Xaxis,
      SurveyYaxis,
      Yaxis,
      surveySelect,
      surveyY,
      strng,
      selecteduser,
      yAxisstrng,
      surveyData,
      surveyQues,
      surveyAns,
      SurveyUserList
    } = this.state;

    let name = [];
    let dataPoints = [];
    if (selecteduser.length == 1) {
      surveyQues.map((item, index) => {
        surveyAns.map((element, key) => {
          if (item.value == element.value) {
            name.push({
              label: element.label,
              y: element.mark,
              userid: element.userid
            });
          }
        });
      });
      let chart1 = this.canvaschart(surveySelect.label, name);
      let formchart1 = [];
      formchart1.push(<CanvasJSChart options={chart1} />);
      this.setState({ formchart1 });
    } else if (selecteduser.length > 1 && Yaxis.length == 1) {
      surveyQues.map((item, index) => {
        surveyAns.map((element, key) => {
          if (item.value == element.value) {

            name.push({
              label: item.label,
              y: element.mark,
              userid: element.userid,
              name: this.getValueFromArray(
                element.userid,
                this.state.SurveyUserList
              ),
              QuestionId: element.value
            });
          }
        });
      });
      let arr = [];
      let str = strng.split(",");
      str.map((ival, i) => {
        var json = name.filter((jval, j) => {
          return ival == jval.userid && Xaxis.value == jval.QuestionId;
        });
        dataPoints.push({ dataPoints: json, x: i });
      });
      let chart1 = this.canvaschart2(surveySelect.label, dataPoints);
      let formchart1 = [];
      formchart1.push(<CanvasJSChart options={chart1} />);
      this.setState({ formchart1 });
    } else if (selecteduser.length > 1 && Yaxis.length > 1) {
      let arr = [];
      let dataPoints = [];
      let str = strng.split(",");

      Yaxis.map((ival, i) => {
        surveyAns.map((jval, j) => {
          if (ival.QuestionId == jval.value && ival.label === jval.label) {
            arr.push({
              label: jval.label,
              y: jval.mark,
              userid: jval.userid,
              name: this.getValueFromArray(
                jval.userid,
                this.state.SurveyUserList
              ),
              x: i
            });
          }
        });
      });
      str.map((ival, i) => {
        var json = arr.filter((jval, j) => {
          return ival == jval.userid;
        });
        dataPoints.push({ dataPoints: json });
      });
      let chart1 = this.canvaschart2(surveySelect.label, dataPoints);
      let formchart1 = [];
      formchart1.push(<CanvasJSChart options={chart1} />);
      this.setState({ formchart1 });
    }
  };

  getValueFromArray = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.label;
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  };

  getMonth = (d1, d2) => {
    var monthShortNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    var t1 = new Date(d1);
    var t2 = new Date(d2);
    return (
      monthShortNames[t1.getMonth()] +
      " " +
      t1.getFullYear() +
      " " +
      "To" +
      " " +
      monthShortNames[t2.getMonth()] +
      " " +
      t2.getFullYear()
    );
    // t.getDate() + ' ' + monthShortNames[t.getMonth()] + ', ' + t.getFullYear();
  };
  content = () => {
    const {
      learnerCount,
      trainerCount,
      cohortCount,
      portletCount,
      portListCount,
      barchartview,
      number,
      QList,
      FList,
      SList
    } = this.state;
    return (
      <React.Fragment>
        <Row>
          {this.col("Total Learner", learnerCount, "info")}
          {this.col("Total Trainer", trainerCount, "danger")}
          {this.col("Total Cohorts", cohortCount, "warning")}
          {this.col("Total Portlets", portletCount, "secondary")}
        </Row>
        <div className="row form-group ">
          <CanvasJSChart options={barchartview} />
        </div>
        <div>
          {number == 3 &&
            this.state.survey &&
            this.state.surveylen > 0 && (
              <div>
                <div className="row form group">
                  <div className="col-sm-3" />
                  <div className="col-sm-2" />
                  {this.state.survey}
                </div>
                <br />
                <div className="row form group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label for="exampleInputEmail1">Survey</label>
                  </div>
                  <div className="col-sm-4">
                    <SingleSelect
                      options={this.state.SList}
                      handleChange={this.surveySelect}
                      selectedService={this.state.surveySelect}
                    />
                  </div>
                  {/* <div className="col-sm-4" /> */}
                </div>
              </div>
            )}
          <br />

          {number == 3 &&
            this.state.surveySelect &&
            this.state.SurveyUserList &&
            this.state.surLen > 0 && (
              <div>
                <div className="row form group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label for="exampleInputEmail1">
                      Select User
                                  </label>
                  </div>
                  <div className="col-sm-4">
                    <MultiSelect
                      options={this.state.SurveyUserList}
                      handleChange={this.userselect}
                      selectedService={this.state.selecteduser}
                    />
                  </div>
                  <div className="col-sm-4" />
                </div>
                <br />
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">Date</div>
                  <div className="col-sm-3">
                    From Date
                                  <input
                      type="date"
                      id="myDate"
                      className="form-control"
                      onChange={this.fromDateSelect}
                      value={this.state.fromDateSelect}
                    />
                  </div>
                  <div className="col-sm-3">
                    To Date
                                  <input
                      type="date"
                      className="form-control"
                      onChange={this.toDateSelect}
                      value={this.state.toDateSelect}
                    />
                  </div>
                  <div className="col-sm-2" />
                </div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">AXIS</div>
                  <div className="col-sm-3">
                    X-AXIS
                                  {/* <SingleSelect
                                    options={this.state.surQueList}
                                    handleChange={this.surveyX}
                                    selectedService={this.state.Xaxis}
                                  /> */}
                  </div>
                  <div className="col-sm-3">
                    Bar Chart Mapping
                                  <MultiSelect
                      options={this.state.SurveyYaxis}
                      handleChange={this.surveyY}
                      selectedService={this.state.Yaxis}
                    />
                  </div>
                  <div className="col-sm-2" />
                </div>
                <div className="row form group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2" />
                  <div className="col-sm-4">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={this.ViewSurvey}
                    >
                      Genarate Form
                    </button>
                  </div>
                  <div className="col-sm-4" />
                </div>
              </div>
            )}
        </div>

        <div>
          {number == 2 &&
            this.state.quiz &&
            this.state.quizlen > 0 && (
              <div>
                <div className="row form group">
                  <div className="col-sm-3" />
                  <div className="col-sm-2" />
                  {this.state.quiz}
                </div>
                <br />
                <div className="row form group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label for="exampleInputEmail1">
                      Evaluation
                                  </label>
                  </div>
                  <div className="col-sm-4">
                    <SingleSelect
                      options={this.state.QList}
                      handleChange={this.quizSelect}
                      selectedService={this.state.quizSelect}
                    />
                  </div>
                  {/* <div className="col-sm-4" /> */}
                </div>
              </div>
            )}
          <br />

          {number == 2 &&
            this.state.quizSelect &&
            this.state.quizUserlen > 0 && (
              <div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label for="exampleInputEmail1">
                      Select Question
                                  </label>
                  </div>
                  <div className="col-sm-4">
                    <SingleSelect
                      options={this.state.questionOption}
                      handleChange={this.QuizX}
                      selectedService={this.state.QuizX}
                    />
                  </div>
                  <div className="col-sm-4" />
                </div>
                <div className="row form group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label for="exampleInputEmail1">
                      Select User
                                  </label>
                  </div>
                  <div className="col-sm-4">
                    <MultiSelect
                      options={this.state.quizUserList}
                      handleChange={this.userselect}
                      selectedService={this.state.selecteduser}
                    />
                  </div>
                  <div className="col-sm-4" />
                </div>
                <br />
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">Date</div>
                  <div className="col-sm-3">
                    From Date
                                  <input
                      type="date"
                      id="myDate"
                      className="form-control"
                      onChange={this.fromDateSelect}
                      value={this.state.fromDateSelect}
                    />
                  </div>
                  <div className="col-sm-3">
                    To Date
                                  <input
                      type="date"
                      className="form-control"
                      onChange={this.toDateSelect}
                      value={this.state.toDateSelect}
                    />
                  </div>
                  <div className="col-sm-2" />
                </div>
                <div className="row form group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2" />
                  <div className="col-sm-4">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={this.ViewEvaluation}
                    >
                      Genarate Form
                                  </button>
                  </div>
                  <div className="col-sm-4" />
                </div>
              </div>
            )}
        </div>

        <div>
          {number == 1 &&
            this.state.formoption &&
            this.state.formoption.length > 0 && (
              <div>
                <div className="row form group">
                  <div className="col-sm-3" />
                  <div className="col-sm-2" />
                  {this.state.formoption}
                </div>
                <div className="row form group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label for="exampleInputEmail1">
                      Select Form
                                  </label>
                  </div>
                  <div className="col-sm-4">
                    <SingleSelect
                      options={this.state.FList}
                      handleChange={this.formselect}
                      selectedService={this.state.formselect}
                    />
                  </div>
                  <div className="col-sm-4" />
                </div>
              </div>
            )}

          <br />
          {number == 1 &&
            this.state.UserList &&
            this.state.UserList.length > 0 && (
              <div>
                <div className="row form group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label for="exampleInputEmail1">
                      Select User
                                  </label>
                  </div>
                  <div className="col-sm-4">
                    <MultiSelect
                      options={this.state.UserList}
                      handleChange={this.userselect}
                      selectedService={this.state.selecteduser}
                    />
                  </div>
                  <div className="col-sm-4" />
                </div>
                <br />
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">Date</div>
                  <div className="col-sm-3">
                    From Date
                                  <input
                      type="date"
                      id="myDate"
                      className="form-control"
                      onChange={this.fromDateSelect}
                      value={this.state.fromDateSelect}
                    />
                  </div>
                  <div className="col-sm-3">
                    To Date
                                  <input
                      type="date"
                      className="form-control"
                      onChange={this.toDateSelect}
                      value={this.state.toDateSelect}
                    />
                  </div>
                  <div className="col-sm-2" />
                </div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">AXIS</div>
                  <div className="col-sm-3">
                    X-AXIS
                                  <SingleSelect
                      options={this.state.jsonname}
                      handleChange={this.xaxis}
                      selectedService={this.state.SelectXaxis}
                    />
                  </div>
                  <div className="col-sm-3">
                    Bar Chart Mapping
                                  <MultiSelect
                      options={this.state.jsonname}
                      handleChange={this.yaxis}
                      selectedService={this.state.SelectYaxis}
                    />
                  </div>
                  <div className="col-sm-2" />
                </div>
                <div className="row form group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2" />
                  <div className="col-sm-4">
                    <button
                      type="button"
                      className="btn btn-warning"
                      onClick={() => this.viewchart("Form")}
                    >
                      Genarate Form
                                  </button>
                  </div>
                  <div className="col-sm-4" />
                </div>
              </div>
            )}
        </div>
        <br />
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-8">{this.state.formchart1}</div>
          <div className="col-sm-2" />
        </div>
      </React.Fragment>
    )
  }
  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <Dashboard1 />
                    <div className="col-12">
                      <button
                        type="button"
                        className="btn btn-primary"
                        disabled={this.state.loader}
                        onClick={() => { this.getContent(); this.setState({ loader: true }) }}
                      >
                        Show Report
                    </button>
                    </div>
                    {(this.state.loader && !this.state.barchartview) && <SpinKit></SpinKit>}
                    {this.state.barchartview && this.content()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}
