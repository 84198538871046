import React from "react";
import CmsContent from "../../../../MiddleWare/CmsContent";

class Type5 extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            button:this.props.button,
            customerId: localStorage.getItem("userId")


         }
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps) {
          this.setState({
            button: nextProps.button,
            boxname: nextProps.boxname,
            boxvalue: nextProps.boxvalue,
            thumbnail: nextProps.thumbnail,
            traytext: nextProps.traytext,
            portletHeading: nextProps.portletHeading,
            descriptionText: nextProps.descriptionText,
            newdate: nextProps.newdate
          });
        }
      }


      onSubmit = () =>{
        !this.state.boxname ? this.props.errormessageboxname('errorname','Please Enter Box Name') :this.props.errormessageboxname('errorname','');

        !this.state.portletHeading ? this.props.errormessageheading('errorheading','Please Enter Portlet Heading') :this.props.errormessageheading('errorheading','');

        !this.state.thumbnail ?  this.props.errorthumbnail('errorthumbnail','Please Select a thumbnail') :  this.props.errorthumbnail('errorthumbnail','');

       !this.state.boxtext ? this.setState ({errortext :'Please Enter Notification' }) : this.setState ({errortext :'' }) 


       


       let formData = new FormData();
       formData.append("name", this.state.boxname);
       formData.append("type", this.state.boxvalue);
       formData.append("text", this.state.boxtext);
       formData.append("status", "active");
       formData.append("customerId", this.state.customerId);
       formData.append("thumbnail", this.state.thumbnail);
       formData.append("notificationtext",  this.state.traytext);
       formData.append("heading", this.state.portletHeading);
       formData.append("createdAt", this.state.newdate);
       formData.append("description", this.state.descriptionText);

      
        if(this.state.boxname && this.state.portletHeading &&  this.state.thumbnail && this.state.boxtext && this.state.boxtext)
        {


console.log([...formData])
this.submitCommon(formData);


        }


      }


      boxtext = e => {
        this.setState({ boxtext: e.target.value });
      };


      submitCommon = async formData => {

        //  this.props.handler(this.state.boxvalue,this.props.alertVisible)
      
          try {
            const result = await CmsContent.mappingportlet(
              formData,
              "tbl_boxcontent"
            );
            console.log(result.data)
            if (result) {
              window.scrollTo({
                top: 0,
                behavior: "smooth"
              });
               
              this.props.handler(this.state.boxvalue,this.props.alertVisible)
      
              // this.setState({ alertVisible: true, boxvalue: "" });
              // setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
          } catch (error) {
            console.log(error);
          }
        };

        addtext =  async () =>
  {

    !this.state.boxname ? this.props.errormessageboxname('errorname','Please Enter Box Name') :this.props.errormessageboxname('errorname','');

    !this.state.portletHeading ? this.props.errormessageheading('errorheading','Please Enter Portlet Heading') :this.props.errormessageheading('errorheading','');

    !this.state.thumbnail ?  this.props.errorthumbnail('errorthumbnail','Please Select a thumbnail') :  this.props.errorthumbnail('errorthumbnail','');

   !this.state.boxtext ? this.setState ({errortext :'Please Enter Notification' }) : this.setState ({errortext :'' }) 


   let formData = new FormData();
   formData.append("name", this.state.boxname);
   formData.append("type", this.state.boxvalue);
   formData.append("text", this.state.boxtext);
   formData.append("status", "active");
   formData.append("customerId", this.state.customerId);
   formData.append("thumbnail", this.state.thumbnail);
   formData.append("notificationtext",  this.state.traytext);
   formData.append("heading", this.state.portletHeading);
   formData.append("createdAt", this.state.newdate);
   formData.append("description", this.state.descriptionText);

  // console.log([...formData])

  
    if(this.state.boxname && this.state.portletHeading &&  this.state.thumbnail && this.state.boxtext && this.state.boxtext)
    {


console.log([...formData])
this.submitCommon(formData);


    }

  

   }
      
      

    render() { 
        console.log(this.props)
        const {boxtext,errortext} =this.state;
        return (  <React.Fragment>  
            
            <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              Notifcation Text
                            </label>
                          </div>
                          <div className="col-sm-5">
                            <textarea
                              type="text"
                              className="form-control"
                              placeholder="Enter Notificatin Text"
                              onChange={this.boxtext}
                              value={boxtext}
                            />
                            <span className="error-shows ">{errortext}</span>
                          </div>
                          <div className="col-sm-3" />
                        </div>

                        <div className="row form-group">
                <div className="col-sm-4" />
                <div className="col-sm-5">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={
                      this.state.button === "Add" ? this.addtext : this.onSubmit
                    }
                  >
                    {this.state.button}
                  </button>
                </div>
                <div className="col-sm-3" />
              </div>   
            
              </React.Fragment> );
    }
}
 
export default Type5 ;