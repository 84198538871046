import http from './httpMiddleWare';
import { ACCESS_POINT } from '../config';
let device =JSON.parse(localStorage.getItem("info"));

const loginCheck = async mobileNumber => {
  const result = await http.get(ACCESS_POINT + `/user/mobileCheck/${mobileNumber}`);
  return result;
};

const loginOTPCheck = async (mobileNumber,from='murugappa') => {
  const result = await http.put(ACCESS_POINT + `/user/mobileOTPCheck/${mobileNumber}/${from}`,{num:mobileNumber});
  return result;
};
const loginOTPCheckPost = async (actualNum,mobileNumber) => {
  const result = await http.post(ACCESS_POINT + `/user/mobileOTPCheck/${actualNum}`,{num:mobileNumber});
  return result;
};

const sendOTP = async (mobileNumber,cId='')=> {
  const result = await http.put(ACCESS_POINT + `/user/commonLogin/${mobileNumber}/${cId}`);
  return result;
};

const Freelogin = async (mobileNumber,otp='') => {
  const result = await http.post(ACCESS_POINT + `/user/commonLogin/${mobileNumber}`,{num:otp,device});
  return result;
};
export default {
  sendOTP,
  Freelogin,
  loginCheck,
  loginOTPCheck,
  loginOTPCheckPost
};
