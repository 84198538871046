import React, { Component } from "react";
import renderHTML from "react-render-html";
import DownPanel from "./DownPanel";
import RightPanel from "./RightPanel";
import { ACCESS_POINT } from "../../config";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import LoginModal from "../../components/Modal/Modal";
import ScriptTag from "react-script-tag";

const js1 = "/js/gallery/gallery.js";
const images = [
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/EIDPLogo1_1582716905982.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/EIDPLogo1_1582716905982.jpg"
  },
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_20190917_122824_1582717890832.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_20190917_122824_1582717890832.jpg"
  },
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_20191014_131140_1582718049515.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_20191014_131140_1582718049515.jpg"
  },
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_20191024_125638_1582718128041.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_20191024_125638_1582718128041.jpg"
  }
];

const style = `<style>
table {
border-collapse: collapse;
width: 106%;
}
td, th {
border: 1px solid #dddddd;
text-align: left;
padding: 8px;


}
body {
  font-family: Verdana, sans-serif;
  margin: 0;
}
* {
  box-sizing: border-box;
}
.row > .column {
  padding: 0 8px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.column {
  float: left;
  width: 20%;
  margin: auto;
  margin-left: 5px;
}
/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
}
/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}



.mySlides {
  display: none;
}
.cursor {
  cursor: pointer;
}
/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}
/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;



}
.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}
.demo {
  opacity: 0.6;
}
.active,
.demo:hover {
  opacity: 1;
}
img.hover-shadow {
  transition: 0.3s;
}
.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>`;
class SheetContent extends Component {
  state = {
    show: false,
    title: "Heading",
    loaded: true,
    tabTitle: ["PEQW", "Value Chain"],
    tabContent: [
      `
      ${style}


      <table>
      <tr>
      <th>S.No.</th>
      <th>Observations</th>
      <th>People</th>
      <th>Process</th>
      <th>Policy</th>
      <th>Training</th>
      <th>Technology</th>
      <th>Frequency of errors</th>
      <th>Risk Involved (H/M/L) </th>
      <th>State </th>
      <th>Error</th>
      <th>CERT</th>
      <th>Actions (if change has to happen, what specific) </th>
      <th>Start When</th>
      <th>Review</th>
      </tr>
      <tr>
      <td>1</td>
      <td>For example - Chemical Storage in the storeroom</td>
      <td>Yes</td>
      <td>Yes</td>
      <td>Yes</td>
      <td>Yes</td>
      <td>No</td>
      <td>7 out of 10 </td>
      <td>H</td>
      <td>Complacency</td>
      <td>Mind Not on Task</td>
      <td>Work on Habits </td>
      <td>Instead of fortnightly checks, do weekly checks </td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td></td>
      <td>Name: K.Sukumaran/J.Gangatharan</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td></td>
      <td>Function : Sugar Process</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td></td>
      <td>Cross Function: Engineering</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>1</td>
      <td>Welder doing welding work without welding shield</td>
      <td>Yes</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>1</td>
      <td>L</td>
      <td>Rushing/  Complacency/ FATIGUE</td>
      <td>Eyes not on task,Mind not on task</td>
      <td>Self trigger</td>
      <td>Use the proper PPE</td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>2</td>
      <td>Welder doing welding without glouse</td>
      <td>Yes</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>1</td>
      <td>L</td>
      <td>Rushing/  Complacency/ FATIGUE</td>
      <td>Eyes not on task,Mind not on task</td>
      <td>Self trigger</td>
      <td>Use the proper PPE</td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>3</td>
      <td>Welder igniting the torch with welding holder</td>
      <td>Yes</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>2</td>
      <td>L</td>
      <td>Rushing/  Complacency/ FATIGUE</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger</td>
      <td>Use the proper PPE</td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>4</td>
      <td>Welder using small glass piece for eye safety</td>
      <td>Yes</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>5</td>
      <td>L</td>
      <td>Rushing/  Complacency/ FATIGUE</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger</td>
      <td>Use the proper PPE</td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>5</td>
      <td>Welder not using apron</td>
      <td>Yes</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>8</td>
      <td>L</td>
      <td>Rushing/  Complacency/ FATIGUE</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger</td>
      <td>Use the proper PPE</td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>6</td>
      <td>Helper/Fitter using a damage pipe as lever for tightening</td>
      <td>YES</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>3</td>
      <td>L</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task,Balance/Traction/Grip</td>
      <td>Self trigger</td>
      <td>Look for things that could cause you to lose your balance, grip. </td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>7</td>
      <td>Helper /Fitter using a hammer to remove coupling</td>
      <td>YES</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>2</td>
      <td>L</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task,Balance/Traction/Grip</td>
      <td>Self trigger</td>
      <td>Use the proper tools and Hold it  carefully</td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>8</td>
      <td>Welder given earth connection with connected rods</td>
      <td>YES</td>
      <td>YES</td>
      <td></td>
      <td></td>
      <td></td>
      <td>6</td>
      <td>L</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task,Balance/Traction/Grip</td>
      <td>Self trigger</td>
      <td>Look for things that could cause you to lose your balance, grip. </td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>9</td>
      <td>Gas cylinders don’t have pr guages</td>
      <td>Yes</td>
      <td>YES</td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>2</td>
      <td>L</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger</td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>10</td>
      <td>Gas cylinders don’t have the open/close nobs</td>
      <td>Yes</td>
      <td>YES</td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>3</td>
      <td>L</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger</td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>11</td>
      <td>Using grinding wheels without goggles</td>
      <td>Yes</td>
      <td>YES</td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>4</td>
      <td>L</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger</td>
      <td>Use proper PPE</td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>12</td>
      <td>Chipping the welding bur withour goggles</td>
      <td>Yes</td>
      <td>YES</td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>2</td>
      <td>L</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger</td>
      <td>Use proper PPE</td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>13</td>
      <td>Cyliders shifting trolley without cynlider lock</td>
      <td>Yes</td>
      <td></td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>5</td>
      <td>L</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger, Work on habits</td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>14</td>
      <td>No regulators in Cylinders</td>
      <td>Yes</td>
      <td></td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>8</td>
      <td>L</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger, Work on habits</td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>15</td>
      <td>Helper cleaning the metal surface with bare hand</td>
      <td>Yes</td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>YES</td>
      <td>4</td>
      <td>L</td>
      <td>Rushing/  Complacency/td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger, Work on habits</td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>16</td>
      <td>Welders using damaged welding cables</td>
      <td>Yes</td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>YES</td>
      <td>5</td>
      <td>L</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger, Work on habits</td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>17</td>
      <td>Welding cables connected without proper connector</td>
      <td>Yes</td>
      <td></td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>5</td>
      <td>M</td>
      <td>Rushing/  Complacency/ FATIGUE</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger, Work on habits</td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>18</td>
      <td>Lifting the heavy equipments with V belts</td>
      <td>Yes</td>
      <td><td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>3</td>
      <td>H</td>
      <td>Rushing/  Complacency/ FATIGUE</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger, Work on habits</td>
      <td>Use the proper tools and Hold it  carefully</td>
      <td></td>

      </tr>
      <tr>
      <td>19</td>
      <td>No safety rope / barry guard while doing at height work</td>
      <td>Yes</td>
      <td><td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>6</td>
      <td>H</td>
      <td>Rushing/  Complacency/ FATIGUE</td>
      <td>Eyes not on task, Mind not on task , LINE OF IRE</td>
      <td>Self trigger, Work on habits</td>
      <td>Look for things that could cause you to lose your balance, grip. </td>
      <td></td>

      </tr>
      <tr>
      <td>20</td>
      <td>Throwing cut heavy items to ground floor without any barry guard</td>
      <td>Yes</td>
      <td><td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>8</td>
      <td>H</td>
      <td>Rushing/  Complacency/ FATIGUE</td>
      <td>Eyes not on task, Mind not on task , LINE OF IRE</td>
      <td>Self trigger, Work on habits</td>
      <td>Look for things that could cause you to lose your balance, grip. </td>
      <td></td>

      </tr>
      <tr>
      <td>21</td>
      <td>Lost the balance at Uneven step plates in the SK ladder</td>
      <td>Yes</td>
      <td><td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>9</td>
      <td>L</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger, Work on habits</td>
      <td>Look for things that could cause you to lose your balance, grip. </td>
      <td></td>

      </tr>
      <tr>
      <td>22</td>
      <td>Uneven gaps in the steps in SK ladder</td>
      <td>Yes</td>
      <td><td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>1</td>
      <td>L</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger, Work on habits</td>
      <td>Look for things that could cause you to lose your balance, grip. </td>
      <td></td>

      </tr>
      <tr>
      <td>23</td>
      <td>Hitting the toe at the projected walkway plates near A1 pan</td>
      <td>Yes</td>
      <td><td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>2</td>
      <td>L</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger, Work on habits</td>
      <td>Look for things that could cause you to lose your balance, grip. </td>
      <td></td>

      </tr>
      <tr>
      <td>24</td>
      <td>Rubbimg the metal items without goggles in Mill area</td>
      <td>Yes</td>
      <td><td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>3</td>
      <td>L</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger, Work on habits</td>
      <td>Use proper PPE </td>
      <td></td>

      </tr>
      <tr>
      <td>25</td>
      <td>Cleaning the oil & grease with bare hands</td>
      <td>Yes</td>
      <td><td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>5</td>
      <td>M</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger, Work on habits</td>
      <td>Use proper PPE </td>
      <td></td>

      </tr>
      <tr>
      <td>26</td>
      <td>Dumping scarp items whereever doing the fabrication work</td>
      <td>Yes</td>
      <td><td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>6</td>
      <td>L</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task , LINE OF IRE</td>
      <td>Self trigger, Work on habits</td>
      <td>Look for things that could cause you to lose your balance, grip. </td>
      <td></td>

      </tr>
      <tr>
      <td>27</td>
      <td>Doing fabrication at wherever they wish</td>
      <td>Yes</td>
      <td><td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>5</td>
      <td>M</td>
      <td>Rushing/  Complacency</td>
      <td>Eyes not on task, Mind not on task , LINE OF IRE</td>
      <td>Self trigger, Work on habits</td>
      <td></td>
      <td></td>

      </tr>
      <tr>
      <td>28</td>
      <td>Using cutting torch for Lighting purpose</td>
      <td>Yes</td>
      <td><td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>2</td>
      <td>L</td>
      <td>Rushing/Complacency/FATIGUE</td>
      <td>Eyes not on task, Mind not on task , LINE OF IRE</td>
      <td>Self trigger, Work on habits</td>
      <td>Look for things that could cause you to lose your balance, grip. </td>
      <td></td>

      </tr>
      <tr>
      <td>29</td>
      <td>Using the Trolleys without rubber tyre which will damage the floor</td>
      <td>Yes</td>
      <td><td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>3</td>
      <td>L</td>
      <td>Rushing/Complacency/FATIGUE</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger, Work on habits</td>
      <td>Look for things that could cause you to lose your balance, grip. </td>
      <td></td>

      </tr>
      <tr>
      <td>30</td>
      <td>Using Hand lamp without plug top in the extension boxes</td>
      <td>Yes</td>
      <td><td>
      <td></td>
      <td></td>
      <td>YES</td>
      <td>4</td>
      <td>H</td>
      <td>Rushing/Complacency/FATIGUE</td>
      <td>Eyes not on task, Mind not on task , LINE OF IRE</td>
      <td>Self trigger, Work on habits</td>
      <td>Look for things that could cause you to lose your balance, grip. </td>
      <td></td>

      </tr>
      <tr>
      <td></td>
      <td>Name: T.Tamilselvan</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td></td>
      <td>Function : Refinery  Process</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td></td>
      <td>Cross Function: Engineering</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>1</td>
      <td>EOT crane maitenence work</td>
      <td>Yes</td>
      <td>NO</td>
      <td>NO</td>
      <td>Yes</td>
      <td>NO</td>
      <td>2 out of 10</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Balance/grip/tration</td>
      <td>Work on Habits </td>
      <td>PPE to be used. Oil , grease, sugar  spillage to be cleaned periodically to avoid slippery condition</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>2</td>
      <td>Melt line dummy replacement work</td>
      <td>Yes</td>
      <td>NO</td>
      <td>NO</td>
      <td>Yes</td>
      <td>YES</td>
      <td>2 out of 10</td>
      <td>M</td>
      <td>Complacency</td>
      <td>Line of fire</td>
      <td>Self trigger on the state or amount of hazardous energy</td>
      <td>Instead of dummies valve to be provided</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>3</td>
      <td>Magma pump cover opening and fixing work</td>
      <td>Yes</td>
      <td>NO</td>
      <td>NO</td>
      <td>Yes</td>
      <td>NO</td>
      <td>1 out of 10</td>
      <td>H</td>
      <td>Rushing , Frustration</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits</td>
      <td>Electrical isolation and tag out system to be followed.</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>4</td>
      <td>Melt pump dismantling for service.</td>
      <td>Yes</td>
      <td>NO</td>
      <td>NO</td>
      <td>Yes</td>
      <td>NO</td>
      <td>2 out of 10</td>
      <td>H</td>
      <td>Rushing , Frustration</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits</td>
      <td>Electrical isolation and tag out system to be followed.</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>5</td>
      <td>Melter tank door openeing for cleaning</td>
      <td>Yes</td>
      <td>NO</td>
      <td>NO</td>
      <td>Yes</td>
      <td>NO</td>
      <td>1 out of 10</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Self trigger on the state or amount of hazardous energy</td>
      <td>SOP to be provided and followed</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>6</td>
      <td>Elevator door open and drive chain allignment </td>
      <td>Yes</td>
      <td>NO</td>
      <td>NO</td>
      <td>Yes</td>
      <td>NO</td>
      <td>1 out of 10</td>
      <td>M</td>
      <td>Complacency</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Self trigger on the state or amount of hazardous energy</td>
      <td>SOP to be strictcly followed</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>7</td>
      <td>Steam valve gland leak and joint leak arresting</td>
      <td>YES</td>
      <td>NO</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>2 out of 10</td>
      <td>H</td>
      <td>Rushing</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits</td>
      <td>SOP to be strictcly followed</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>8</td>
      <td>Heater plate Opening for cleaning</td>
      <td>YES</td>
      <td>NO</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>1 out of 10</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits</td>
      <td>SOP to be strictcly followed</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>9</td>
      <td>Phosphoric acid pump leak arresting work</td>
      <td>YES</td>
      <td>YES</td>
      <td>No</td>
      <td>YES</td>
      <td>No</td>
      <td>2 out of 10</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits</td>
      <td>Necessary PPE to be weared</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>10</td>
      <td>MOL Pump leak arresting work</td>
      <td>YES</td>
      <td>YES</td>
      <td>No</td>
      <td>YES</td>
      <td>No</td>
      <td>1 out of 10</td>
      <td>M</td>
      <td>Complacency</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits</td>
      <td>Necessary PPE to be weared</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>11</td>
      <td>Flocullant Pump leak arresting work</td>
      <td>YES</td>
      <td>YES</td>
      <td>No</td>
      <td>YES</td>
      <td>No</td>
      <td>4 out of 10</td>
      <td>M</td>
      <td>Complacency</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits</td>
      <td>Necessary PPE to be weared</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>12</td>
      <td>Reaction tank agitator motor & gearbox dismantling work</td>
      <td>YES</td>
      <td>YES</td>
      <td>No</td>
      <td>YES</td>
      <td>No</td>
      <td>1 out of 10</td>
      <td>M</td>
      <td>Complacency</td>
      <td>Eyes not on task. Balance/grip/tration</td>
      <td>analyze close call and small errors</td>
      <td>SOP to be stricly followed</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>13</td>
      <td>Clarifier aeration disc dismantling for cleaning</td>
      <td>YES</td>
      <td>NO</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>1 out of 10</td>
      <td>H</td>
      <td>Rushing</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Look at others for patterns that increase the risk of injury</td>
      <td>lock  out system to  be followed</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>14</td>
      <td>Clarifier coil  opening for cleaning</td>
      <td>YES</td>
      <td>NO</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>1 out of 10</td>
      <td>M</td>
      <td>Rushing</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Balance/Traction/grip</td>
      <td>SOP to be stricly followed</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>15</td>
      <td>Clarifier vibroscreen mesh dismantling /Motor replacement</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>1 out of 10</td>
      <td>M</td>
      <td>Rushing,Frustration</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Balance/Traction/grip</td>
      <td>SOP to be stricly followed</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>16</td>
      <td>Precoat pump leak arresting work</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>2 out of 10</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits</td>
      <td>SOP to be stricly followed</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>17</td>
      <td>Candle filter opening for cleaning</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>2 out of 10</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits</td>
      <td>SOP to be stricly followed</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>18</td>
      <td>Candle filter box up</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>2 out of 10</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits</td>
      <td>SOP to be stricly followed</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>19</td>
      <td>Check filter opening for cleaning</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>YES</td>
      <td>NO</td>
      <td>2 out of 10</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits</td>
      <td>SOP to be stricly followed</td>
      <td>October</td>
      <td>December</td>
      </tr>

      <tr>
      <td>20</td>
      <td>Pan Steam valve gland leak and joint leak arresting</td>
      <td>YES</td>
      <td>No</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>2 out of 10</td>
      <td>H</td>
      <td>Rushing</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits</td>
      <td>SOP to be stricly followed</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>21</td>
      <td>Pan discharge valve leak arresting and seating change work</td>
      <td>YES</td>
      <td>NO</td>
      <td>No</td>
      <td>YES</td>
      <td>NO</td>
      <td>3 out of 10</td>
      <td>H</td>
      <td>Rushing , Frustration</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits </td>
      <td>Proper communication to be given to XLR attender and Pan man. SOP  to  be stricly followed</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>22</td>
      <td>Pan circulator Gland rope renewal</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>2 out of 10</td>
      <td>M</td>
      <td>Rushing , Frustration</td>
      <td>Eyes not on task Mind not on task</td>
      <td>analyze close call and small errors</td>
      <td>Proper communication to be given to XLR attender and Pan conract labour</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>23</td>
      <td>Pan bush change work</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>YES</td>
      <td>NO</td>
      <td>2 out of 10</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Eyes not on task Mind not on task</td>
      <td>analyze close call and small errors</td>
      <td>SOP to be strictcly followed</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>24/td>
      <td>Crystallizer motor gear box dismantling and fixing work.</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>No</td>
      <td>YES</td>
      <td>1 out of 10</td>
      <td>M</td>
      <td>Complacency</td>
      <td>Complacency</td>
      <td>Work on Habits </td>
      <td>SOP to be strictcly followed</td>
      <td>October</td>
      <td>April</td>
      </tr>
      <tr>
      <td>25</td>
      <td>crystalizer discharge line dummy opening for choke removing </td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>2 out of 10</td>
      <td>M</td>
      <td>Rushing</td>
      <td>Balance/traction/ grip</td>
      <td>Self trigger on the state or amount of hazardous energy</td>
      <td>Hot water tracing jacket to be provided in Crystaliser discharge line</td>
      <td>October</td>
      <td>January</td>
      </tr>
      <tr>
      <td>26</td>
      <td>Pugmill motor and  gear box dismantling and fixing work.</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td>No</td>
      <td>YES</td>
      <td>2 out of 10</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Balance/traction/ grip</td>
      <td>Work on Habits </td>
      <td>SOP to be strictcly followed</td>
      <td>October</td>
      <td>April</td>
      </tr>
      <tr>
      <td>27</td>
      <td>Chute opening in product centrifuge</td>
      <td>YES</td>
      <td>No</td>
      <td>NO</td>
      <td>YES</td>
      <td>NO</td>
      <td>1 out of 10</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits </td>
      <td>Training to be given</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>28</td>
      <td>Product centrifugal screen change</td>
      <td>YES</td>
      <td>No</td>
      <td>NO</td>
      <td>YES</td>
      <td>NO</td>
      <td>2 out of 10</td>
      <td>H</td>
      <td>Rushing/ Fatigue</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits </td>
      <td>Training to be given</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>29</td>
      <td>Product centrifugal sensor adjustment</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>YES</td>
      <td>NO</td>
      <td>1 out of 10</td>
      <td>M</td>
      <td>Rushing/ Fatigue</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits </td>
      <td>Training to be given</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>30</td>
      <td>Product centrifugal plough adjustment</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>YES</td>
      <td>NO</td>
      <td>2 out of 10</td>
      <td>M</td>
      <td>Rushing/ Fatigue</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits </td>
      <td>Training to be given</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>31</td>
      <td>Drum drier dummy opening for cleaning</td>
      <td>YES</td>
      <td>NO</td>
      <td>NO</td>
      <td>YES</td>
      <td>NO</td>
      <td>1 out of 10</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits </td>
      <td>Training to be given</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>32</td>
      <td>Duire outlet Screw conveyor Gearbox and motor cahnge</td>
      <td>YES</td>
      <td>NO</td>
      <td>NO</td>
      <td>YES</td>
      <td>NO</td>
      <td>1 out of 10</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Eyes not on task Mind not on task</td>
      <td>Work on Habits </td>
      <td>Training to be given</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>33</td>
      <td>Grader mesh change work</td>
      <td>YES</td>
      <td>NO</td>
      <td>NO</td>
      <td>YES</td>
      <td>NO</td>
      <td>2 out of 10</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Eyes not on task Mind not on task</td>
      <td>analyze close call and small errors</td>
      <td>Drier efficiency to be improved to reduce grader mesh cleaning frequency</td>
      <td>October</td>
      <td>April</td>
      </tr>
      <tr>
      <td>34</td>
      <td>Bin door opening for cleaning</td>
      <td>YES</td>
      <td>NO</td>
      <td>NO</td>
      <td>YES</td>
      <td>NO</td>
      <td>2 out of 10</td>
      <td>H</td>
      <td>Rushing</td>
      <td>Eyes not on task Mind not on task</td>
      <td>analyze close call and small errors</td>
      <td>Training to be given. PPE to be used</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>35</td>
      <td>Packing vibroscreen opening for cleaning</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>YES</td>
      <td>NO</td>
      <td>1 out of 10</td>
      <td>M</td>
      <td>Rushing</td>
      <td>Eyes not on task Mind not on task</td>
      <td>analyze close call and small errors</td>
      <td>Training to be given. PPE to be used</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>36</td>
      <td>Graded sugar vibroscreen opening and cleaning and box up</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>YES</td>
      <td>NO</td>
      <td>1 out of 10</td>
      <td>M</td>
      <td>Rushing</td>
      <td>Eyes not on task Mind not on task</td>
      <td>analyze close call and small errors</td>
      <td>Training to be given. PPE to be used</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td>37</td>
      <td>30-40 blower line dismantling for choke removal</td>
      <td>YES</td>
      <td>YES</td>
      <td>NO</td>
      <td>YES</td>
      <td>NO</td>
      <td>1 out of 10</td>
      <td>M</td>
      <td>Complacency</td>
      <td>Complacency</td>
      <td>analyze close call and small errors</td>
      <td>SOP to be followed</td>
      <td>October</td>
      <td>December</td>
      </tr>
      <tr>
      <td></td>
      <td>Name: Vijayan</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td></td>
      <td>Function : Distillery  Process</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td></td>
      <td>Cross Function: Engineering </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>1</td>
      <td>Leading Heavy weight material by using JCB</td>
      <td>YES</td>
      <td>YES</td>
      <td>YES</td>
      <td></td>
      <td></td>
      <td>5</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Line on Fire</td>
      <td>Work on Habits </td>
      <td>Proper traing  to be given</td>
      <td>Immediate</td>
      <td>Daily</td>
      </tr>
      <tr>
      <td>2</td>
      <td>Removing of bearing by hammering without bearing puller</td>
      <td>Yes</td>
      <td></td>
      <td>Yes </td>
      <td>Yes </td>
      <td></td>
      <td>4</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Mind Not on Task</td>
      <td>Work on Habits </td>
      <td>Proper traing  to be given</td>
      <td>Immediate</td>
      <td>Daily</td>

    </tr>
      <tr>
      <td>3</td>
      <td>Work at Height</td>
      <td>Yes</td>
      <td>Yes </td>
      <td>Yes </td>
      <td>Yes </td>
      <td>Yes </td>
      <td>3</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Balance/Traction/Grip</td>
      <td>Work on Habits </td>
      <td>Training on usage of PPE</td>
      <td>Immediate</td>
      <td>At the time of work</td>

      </tr>
      <tr>
            <td>4</td>
            <td>Job welding without proper earthing</td>
            <td>Yes</td>
            <td></td>
            <td></td>
            <td>Yes </td>
            <td></td>
            <td>4</td>
            <td>M</td>
            <td>Complacency</td>
            <td>Eyes not on task, Mind not on task</td>
            <td>Self trigger, work on habits </td>
            <td>Proper checking before welding</td>
            <td>Immediate</td>
            <td>Regular</td>

      </tr>
      <tr>
      <td>5</td>
      <td>Hammering by metal hammer for removing the bolt &amp; nuts in the alcohol storage places</td>
      <td>Yes</td>
      <td></td>
      <td>Yes </td>
      <td>Yes </td>
      <td>Yes </td>
      <td>4</td>
      <td>H</td>
      <td>Complacency</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Work on Habits </td>
      <td>Proper training need to be given for the impact</td>
      <td>Immediate</td>
      <td>Regular</td>

    </tr>
    <tr>
      <td>6</td>
      <td>Tube cleaning by Gutter machine without proper PPE</td>
      <td>Yes</td>
      <td>Yes </td>
      <td>Yes </td>
      <td>Yes </td>
      <td></td>
      <td>4</td>
      <td>M</td>
      <td>Complacency</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger, Analyse close calls, work on habits</td>
      <td>Proper training to be given on the impact of the job</td>
      <td>Immediate</td>
      <td>Continous</td>

    </tr>
    <tr>
      <td>7</td>
      <td>Adjusting the coupling guard without stopping the pump</td>
      <td>Yes</td>
      <td>Yes </td>
      <td>Yes </td>
      <td>Yes </td>
      <td></td>
      <td>4</td>
      <td>H</td>
      <td>Rushing/Complacency</td>
      <td>Eyes not on task, Mind not on task</td>
      <td>Self trigger, work on habits </td>
      <td>Proper training to be given on the impact of the job</td>
      <td>Immediate</td>
      <td>Continous</td>

    </tr>


      <tr>
      <td></td>
      <td>Name: s.Senthilvelan </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td></td>
      <td>Function : Distillery  Process</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td></td>
      <td>Cross Function: Engineering</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>

      <tr>
      <td>1</td>
      <td>At Distillery Workshop, the contractor using grinding wheel, without PPE</td>
      <td>Yes</td>
      <td></td>
      <td></td>
      <td >Yes</td>
      <td ></td>
      <td >5</td>
      <td >H</td>
      <td >Complacency</td>
      <td >Eyes not on task, Mind not on task</td>
      <td >Self trigger, Analyse close calls, work on habits</td>
      <td >Move your eyes before move your body, on getting down the steps</td>
      <td >Immediate</td>
      <td >Continous</td>

    </tr>
    <tr >
      <td >2</td>
      <td >Lifting equipment with chain bloc with normal rope</td>
      <td >Yes</td>
      <td ></td>
      <td ></td>
      <td >Yes</td>
      <td ></td>
      <td >5</td>
      <td >H</td>
      <td >Complacency</td>
      <td >Grip</td>
      <td >Self trigger, work on habits </td>
      <td >Traing to be given on usage and risk on the job</td>
      <td >Immediate</td>
      <td >Continous</td>

    </tr>
    <tr >
      <td >3</td>
      <td >Re- location of tank by leading with normally wothout PPE</td>
      <td >Yes</td>
      <td ></td>
      <td ></td>
      <td >Yes</td>
      <td ></td>
      <td >2</td>
      <td >M</td>
      <td >Complacency</td>
      <td >Line on Fire</td>
      <td >Self trigger, work on habits </td>
      <td >Training to be given on propoer usage of PPE</td>
      <td >Immediate</td>
      <td >Continous</td>

    </tr>
    <tr >
      <td >4</td>
      <td >Using old Chain Bloc and not using the lock system in the bloc</td>
      <td >yes</td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td >3</td>
      <td >H</td>
      <td >Complacency</td>
      <td >Line on Fire</td>
      <td >Analse close calls</td>
      <td >Proper arrangement</td>
      <td >Immediate</td>
      <td >Continous</td>

    </tr>
    <tr >
      <td >5</td>
      <td >Checking the pressure gauge at height without safety belt</td>
      <td >Yes</td>
      <td ></td>
      <td ></td>
      <td >Yes</td>
      <td >Yes</td>
      <td >3</td>
      <td >M</td>
      <td >Rushing</td>
      <td >Line on Fire</td>
      <td >Self trigger, work on habits </td>
      <td >Providing proper handles to valve operation</td>
      <td >Immediate</td>
      <td >Regular</td>

    </tr>
    <tr >
      <td >6</td>
      <td >Manual tube cleaning</td>
      <td >Yes</td>
      <td >Yes </td>
      <td >Yes </td>
      <td >Yes </td>
      <td >Yes </td>
      <td >4</td>
      <td >H</td>
      <td >Rushing/Complacency</td>
      <td >Mind Not on Task</td>
      <td >Self trigger, work on habits </td>
      <td >Updation of SOP/Training on lockout system &amp; Usage of PPE's</td>
      <td >Immediate</td>
      <td >Three months once</td>

    </tr>
    <tr >
      <td >7</td>
      <td >Attending cooling tower gear box failure without Rope</td>
      <td >Yes</td>
      <td >Yes </td>
      <td >Yes </td>
      <td >Yes </td>
      <td >Yes </td>
      <td >4</td>
      <td >H</td>
      <td >Rushing/Complacency</td>
      <td >Eyes not on task, Mind not on task</td>
      <td >Work on Habits </td>
      <td >Proper walkway with handrails, foot toe plate/ladder/Cover on the top inspection openings.</td>
      <td >Immediate</td>
      <td >Regular</td>

    </tr>
    <tr >
      <td >8</td>
      <td>Work at Height</td>
      <td >Yes</td>
      <td >Yes </td>
      <td >Yes </td>
      <td >Yes </td>
      <td >Yes </td>
      <td >3</td>
      <td >H</td>
      <td >Complacency</td>
      <td >Balance/Traction/Grip</td>
      <td >Work on Habits </td>
      <td >Training on usage of PPE</td>
      <td >Immediate</td>
      <td >At the time of work</td>

    </tr>
    <tr >
      <td >9</td>
      <td >Spanner slipped while tightning</td>
      <td >Yes</td>
      <td ></td>
      <td ></td>
      <td >Yes </td>
      <td ></td>
      <td >1</td>
      <td >L</td>
      <td >Complacency</td>
      <td >Eyes not on task, Mind not on task</td>
      <td >Self trigger, work on habits </td>
      <td >Look for things that could cause you to lose your balance, grip. </td>
      <td >Immediate</td>
      <td >Regular</td>

    </tr>
    <tr >
      <td >10</td>
      <td >High speed rotating equipments</td>
      <td >Yes</td>
      <td >Yes </td>
      <td >Yes </td>
      <td >Yes </td>
      <td >Yes </td>
      <td >2</td>
      <td >H</td>
      <td >Complacency</td>
      <td >Eyes not on task, Mind not on task</td>
      <td >Work on Habits </td>
      <td >Proper protecting guards, Proper indications/Display/Training on emergency</td>
      <td >Immediate</td>
      <td >Regular</td>

    </tr>
    <tr >
    <td ></td>
    <td >Name: G.MohanRaj</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>

    <td >&nbsp;</td>
  </tr>
  <tr >
    <td ></td>
    <td >Function : Engineering </td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>

  </tr>
  <tr >
    <td ></td>
    <td >Cross Function: Process</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>

  </tr>
  <tr >
    <td >1</td>
    <td >Chance of cut injury during working without PPE during the de-slitting the drain at Distillery </td>
    <td >Yes</td>
    <td >No</td>
    <td >No</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >2</td>
    <td >L</td>
    <td >Complacency</td>
    <td >Line of Fire<br />
Balance / Traction / Grip</td>
    <td >Self trigger, <br />
Analyse close calls</td>
    <td >Adequate Training to be given to use right PPE for suitable works.</td>
    <td >Immediate</td>
    <td >November</td>

  </tr>
  <tr >
    <td >2</td>
    <td >Floor Slipper Condition at B &amp; C range area </td>
    <td >Yes</td>
    <td >No</td>
    <td >No</td>
    <td >Yes</td>
    <td >No</td>
    <td >8</td>
    <td >M</td>
    <td >Complacency</td>
    <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
    <td >Work On Habits<br />
Analyse Close Calls</td>
    <td >Work are floor should keep clean ad dry from slipper materials like sugar melt, molasses etc .</td>
    <td >All times</td>
    <td >November</td>

  </tr>
  <tr >
    <td  >3</td>
    <td >A team of peoples working without using the PPE's for sludge removal from the process drain</td>
    <td >Yes</td>
    <td >No</td>
    <td >No</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >2</td>
    <td >L</td>
    <td >Complacency<br />
Rushing</td>
    <td >Line of Fire</td>
    <td >Analyse Close Calls<br />
Work On Habits<br />
Self Trigger</td>
    <td >Training required to use the right tools for right job and adequate Training to be given to use right PPE for suitable works.</td>
    <td >Immediate</td>
    <td >12.11.2019</td>

  </tr>
  <tr >
    <td >4</td>
    <td >Scrap bagged dropped from first floor- no barry guard on the ground, objects may fall on the person who passing.</td>
    <td >Yes</td>
    <td >No</td>
    <td >No</td>
    <td >Yes</td>
    <td >No</td>
    <td >5</td>
    <td >H</td>
    <td >Complacency<br />
Rushing</td>
    <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
    <td >Analyse Close Calls<br />
Work On Habits<br />
Self Trigger</td>
    <td >Require training to the work men </td>
    <td >November</td>
    <td >November</td>

  </tr>
  <tr >
    <td >5</td>
    <td >While tube cleaning chance to hit by the cleaning rod at distillery</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >No</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >2</td>
    <td >H</td>
    <td >Frustration <br />
Rushing<br />
Complacency</td>
    <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
    <td >Analyse Close Calls<br />
Work On Habits<br />
Self Trigger</td>
    <td >Require training to the work men, alternate technology to be implemented</td>
    <td >November</td>
    <td >November</td>
  </tr>
  <tr >
    <td >6</td>
    <td >Chance of get injury during the Jet cleaning of vehicles and tubes of equipment in all process </td>
    <td >Yes</td>
    <td >Yes</td>
    <td >No</td>
    <td >Yes</td>
    <td >No</td>
    <td >1</td>
    <td >H</td>
    <td >Complacency</td>
    <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
    <td >Analyse Close Calls<br />
Work On Habits<br />
Self Trigger</td>
    <td >Require training to the work men</td>
    <td >November</td>
    <td >November</td>

  </tr>
  <tr >
    <td >7</td>
    <td >No proper MSDS for process chemical storage</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >No</td>
    <td >3</td>
    <td >H</td>
    <td >Complacency</td>
    <td >Line of Fire</td>
    <td >Analyse Close Calls<br />
Work On Habits<br />
Self Trigger</td>
    <td >MSDS to be displayed to understand the chemical characterizes </td>
    <td >Immediate</td>
    <td >November</td>

  </tr>
  <tr >
    <td >8</td>
    <td >Chemical handling and storage </td>
    <td >Yes</td>
    <td >Yes</td>
    <td >No</td>
    <td >Yes</td>
    <td >No</td>
    <td >4</td>
    <td >M</td>
    <td >Complacency</td>
    <td >Line of Fire</td>
    <td >Analyse Close Calls<br />
Work On Habits</td>
    <td >adequate chemical storage to be identified with proper barrier and training to be given to the handling peoples </td>
    <td >Immediate</td>
    <td >November</td>

  </tr>
  <tr >
    <td >9</td>
    <td >Injection water channel cleaning by manual chance for incidence by gases </td>
    <td >Yes</td>
    <td >Yes</td>
    <td >No</td>
    <td >Yes</td>
    <td >No</td>
    <td >2</td>
    <td >M</td>
    <td >Complacency</td>
    <td >Line of Fire</td>
    <td >Analyse Close Calls<br />
Work On Habits</td>
    <td >Require training to the work men for use PPE's and confined space work procedures to follow.</td>
    <td >Immediate</td>
    <td >November</td>

  </tr>
  <tr >
    <td >10</td>
    <td >Chance of hot water burn injury at C/F operation area by improper identification of lines.</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >No</td>
    <td >Yes</td>
    <td >No</td>
    <td >5</td>
    <td >M</td>
    <td >Complacency</td>
    <td >Line of Fire<br />
Balance / Traction / Grip</td>
    <td >Analyse Close Calls<br />
Work On Habits</td>
    <td >Proper identification to be given in usage locations and adequate training to be given in working team </td>
    <td >Immediate</td>
    <td >November</td>

  </tr>
  <tr >
  <td ></td>
  <td >Name: R.Ravichandran</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td>&nbsp;</td>
</tr>
<tr >
  <td ></td>
  <td >Function :   Engineering </td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >&nbsp;</td>
</tr>
<tr >
  <td ></td>
  <td >Cross Function: Process</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >1</td>
  <td >At distillery evaparator tube cleaning by using tube cleaning equipment it will lead to hit the person in case of shaft damage by overload.</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >5</td>
  <td >M</td>
  <td >Complacency</td>
  <td >Line Of Fire</td>
  <td >Self trigger, <br />
Analyse close calls</td>
  <td >Training to be given the operator to use right PPE's, right tools and operational training</td>
  <td >Immedite</td>
  <td >Given</td>

</tr>
<tr >
  <td >2</td>
  <td >At distillery H2 So4 storage area no barrier, due to this person using as a coming walkway it will lead to chimical accidents.</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >No</td>
  <td >1</td>
  <td >H</td>
  <td >Complacency<br />
Rushing</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
  <td >Analyse Close Calls<br />
Work On Habits<br />
Self Trigger</td>
  <td >Barrier to be provid to isolate the area from common access, MSDS and sign to be displayed.</td>
  <td >Immedite</td>
  <td >Given</td>

</tr>
<tr >
  <td >3</td>
  <td >Process area slippary condition due to process liquid overflow it will lead the accident to the working pepoles</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >1</td>
  <td >L</td>
  <td >Complacency</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
  <td >Self Trigger<br />
Analyse close calls</td>
  <td >Interlock system to be provide to avoid overflow </td>
  <td >Immedite</td>
  <td >November</td>

</tr>
<tr >
  <td >4</td>
  <td >Dosing chemical leading from stacking place to work place it will lead an accident by manual handling.</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >1</td>
  <td >M</td>
  <td >Complacency</td>
  <td >Line Of Fire</td>
  <td >Analyse Close Calls<br />
Work On Habits<br />
Self Trigger</td>
  <td >Require training to the work men to use proper lifting and safe transfer equpments </td>
  <td >Immedite</td>
  <td >November</td>
</tr>
<tr >
  <td >5</td>
  <td >At distllery person working in cooling tower basin cleaning without safety permit (confind space)</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >1</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Line Of Fire</td>
  <td >Self trigger<br />
work on habits<br />
Analyse close calls</td>
  <td >Require training to the work men to raise safety permits,  gas test and to use PPE's </td>
  <td >Immedite</td>
  <td >11.11.2019</td>

</tr>
<tr >
  <td ></td>
  <td >Name: K.Thirunavukarasu</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

  <td >&nbsp;</td>
</tr>
<tr >
  <td ></td>
  <td >Function :   Engineering </td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td ></td>
  <td >Cross Function: Process</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >1</td>
  <td >Sulphur splashed in eyes while loading to melter</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td ></td>
  <td >5</td>
  <td >H</td>
  <td >Frustration/Complacency</td>
  <td >Line - of - Fire</td>
  <td >Self trigger, Analyze close calls, work on habits</td>
  <td >Move your eyes before move your body, the wet area to be cleaned</td>
  <td >October</td>
  <td >Regular</td>

</tr>
<tr >
  <td >2</td>
  <td >Tube cleaning cutter hit with cleaning person leg</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td ></td>
  <td >1</td>
  <td >M</td>
  <td >Complacency/Rushing</td>
  <td >Mind are not in  task</td>
  <td >Self trigger, work on habits </td>
  <td >Move your eyes before move your body, the cable to be laid in proper way</td>
  <td >October</td>
  <td >Regular</td>

</tr>
<tr >
  <td >3</td>
  <td >Head hitting with man hole door flange while entering in to evaporator</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td ></td>
  <td >1</td>
  <td >H</td>
  <td >Complacency /Rushing</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Look for line of fire potential before moving / start the work</td>
  <td >Immediate</td>
  <td >Regular</td>
</tr>
<tr >
  <td >4</td>
  <td >Hip muscle got damage while entering in to manhole door </td>
  <td >Yes</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td >5</td>
  <td >H</td>
  <td >Complacency /Rushing</td>
  <td >Eyes not on task/Mind not on task/ line of fire</td>
  <td >work on habits</td>
  <td >Move your eyes before move your body, sign board for step to follow for  man entry</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >5</td>
  <td >Un balance while opening a door at sulphator top</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >1</td>
  <td >H</td>
  <td >Complacency /Rushing</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Look for things that could cause you to lose your balance, grip. </td>
  <td >October</td>
  <td >Regular</td>

</tr>
<tr >
  <td >6</td>
  <td >Operator Slip down in A range CFL steps</td>
  <td >Yes</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >1</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Mind are not in  task</td>
  <td >Self trigger, work on habits </td>
  <td >Test your footing or grip before you commit your weight </td>
  <td >October</td>
  <td >Regular</td>

</tr>
<tr >
  <td >7</td>
  <td >Hot water flashes on leg while spraying antifoaming oil to injection channel</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >1</td>
  <td >M</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >work on habits</td>
  <td >Move your eyes before move your body, the wet area to be cleaned</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >8</td>
  <td >Sugar flashes and hit with eye while taking sample from continuous CFL machine</td>
  <td >Yes</td>
  <td >Yes</td>
  <td ></td>
  <td >Yes</td>
  <td ></td>
  <td >2</td>
  <td >M</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Move your eyes before move your body,</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >9</td>
  <td >Hot soda splashes on hand while removing hydrant hose</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td ></td>
  <td >1</td>
  <td >M</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Move your eyes before move your body,</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >10</td>
  <td >Sulphur bag falling from height</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td ></td>
  <td >2</td>
  <td >M</td>
  <td >Complacency /Rushing</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, Analyze close calls, work on habits</td>
  <td >Glance up before standing up or raising your hands. (bang heads and hands etc,.) wearing PPE is mandatory, Putting barry guard</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
<td ></td>
<td >Name: T.Venkatesan</td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
</tr>
<tr >
<td ></td>
<td >Function :   Engineering </td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>

</tr>
<tr >
<td ></td>
<td >Cross Function: Process</td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>
<td ></td>

</tr>
<tr >
<td >1</td>
<td >Raw sugar melting sugar bag falling down from lot in storage and handling area</td>
<td >Yes</td>
<td >Yes</td>
<td >No</td>
<td >Yes</td>
<td >No</td>
<td >5</td>
<td >M</td>
<td >Complacency</td>
<td >Line Of Fire</td>
<td >Self trigger, <br />
Analyse close calls</td>
<td >Training to be given the handle de-stacking and convying</td>
<td >Immedite</td>
<td >Given</td>

</tr>
<tr >
<td >2</td>
<td >Raw sugar mingler operation : Hot Water addition and inspection brix : Sugar magma flashing on eye.</td>
<td >Yes</td>
<td >Yes</td>
<td >No</td>
<td >No</td>
<td >No</td>
<td >1</td>
<td >H</td>
<td >Complacency<br />
Rushing</td>
<td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
<td >Analyse Close Calls<br />
Work On Habits<br />
Self Trigger</td>
<td >Require training to the work men to PPE usage </td>
<td >Immedite</td>
<td >Given</td>

</tr>
<tr >
<td >3</td>
<td >Process area slippary condition due to process liquid overflow it will lead the accident to the working pepoles</td>
<td >Yes</td>
<td >Yes</td>
<td >No</td>
<td >No</td>
<td >Yes</td>
<td >1</td>
<td >L</td>
<td >Complacency</td>
<td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
<td >Self Trigger<br />
Analyse close calls</td>
<td >Barrier to be provid to isolate the area from common access, MSDS and sign to be displayed.</td>
<td >Immedite</td>
<td >November</td>

</tr>
<tr >
<td >4</td>
<td >Dosing chemical leading from stacking place to work place it will lead an accident by manual handling.</td>
<td >Yes</td>
<td >No</td>
<td >No</td>
<td >Yes</td>
<td >Yes</td>
<td >1</td>
<td >M</td>
<td >Complacency</td>
<td >Line Of Fire</td>
<td >Analyse Close Calls<br />
Work On Habits<br />
Self Trigger</td>
<td >Require training to the work men to use proper lifting and safe transfer equpments </td>
<td >Immedite</td>
<td >November</td>

</tr>
<tr >
            <td ></td>
            <td >Name: S.Chakravarthi</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>

          </tr>
          <tr >
            <td ></td>
            <td >Function :   Engineering </td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>

          </tr>
          <tr >
            <td ></td>
            <td >Cross Function: Process</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>

          </tr>
          <tr >
            <td >1</td>
            <td >Chemical Stored near RO plant MCC</td>
            <td >yes</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td >H</td>
            <td ></td>
            <td ></td>
            <td >Self Trigger,work on habbits</td>
            <td >Instruction given to chemist to take way  the chemicals near to MCC </td>
            <td ></td>
            <td ></td>

          </tr>
          <tr >
            <td >2</td>
            <td >Chemical name are not pasted in the chemical stored rack</td>
            <td >yes</td>
            <td ></td>
            <td ></td>
            <td >yes</td>
            <td ></td>
            <td ></td>
            <td >H</td>
            <td ></td>
            <td ></td>
            <td >Self Trigger,work on habbits</td>
            <td >Instruction given to chemist to paste the chemical names </td>
            <td ></td>
            <td ></td>

          </tr>
          <tr >
            <td >3</td>
            <td >Sulpuric acid dosing near CT area temporary flexible hose provided </td>
            <td >yes</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td >H</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td >Planed to provided permanent hose to the delivery line</td>
            <td >Noc-19</td>
            <td >Dec-19</td>

          </tr>
          <tr >
            <td >4</td>
            <td >DM plant degasser top side flat form checker plates are corroded</td>
            <td >yes</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td >H</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td >Unsafe while taking samples and during inspection</td>
            <td ></td>
            <td ></td>

          </tr>
          <tr >
            <td >5</td>
            <td >Netural Pit nets &amp; supports are damaged  </td>
            <td >yes</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td >May lead to fall in the pit if supported</td>
            <td ></td>
            <td ></td>

          </tr>
          <tr >
            <td ></td>
            <td >Name: V.Rajendran/K.P.Jayaraman</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>

          </tr>
          <tr >
            <td ></td>
            <td >Function :  Safety</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>

            <td >&nbsp;</td>
          </tr>
          <tr >
            <td >&nbsp;</td>
            <td >Cross Function: Quality</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>

            <td >&nbsp;</td>
          </tr>
          <tr >
            <td >1</td>
            <td >Sample bags opened by using knife</td>
            <td >Yes</td>
            <td >no</td>
            <td >Yes </td>
            <td >Yes </td>
            <td >No </td>
            <td >4 out of 10 </td>
            <td >M</td>
            <td >Rushing , Fatigue ,Complacency</td>
            <td >Mind Not on Task , Eye Not on Task </td>
            <td >Analyse close calls  and small errors , Work on Habits </td>
            <td >Provide suitable glove, Instruction given to follow safe work practice</td>
            <td >immediate</td>
            <td >next week</td>

          </tr>
          <tr >
            <td >2</td>
            <td >one Liter conical flask with hot water lifting by  hand  and touched by bare hand </td>
            <td >Yes</td>
            <td >no</td>
            <td >Yes </td>
            <td >Yes </td>
            <td >No </td>
            <td >3 out of 10 </td>
            <td >M</td>
            <td >Complacency</td>
            <td >Mind Not on Task , Eye Not on Task </td>
            <td >Analyse close calls  and small errors , Work on Habits </td>
            <td >Provide suitable glove, Instruction given to follow safe work practice</td>
            <td >immediate</td>
            <td next week</td>

          </tr>
          <tr >
            <td >3</td>
            <td >Concentracted sulphuric acid lift lift from packing to shelf without gloves</td>
            <td >Yes</td>
            <td >no</td>
            <td >Yes </td>
            <td >Yes </td>
            <td >No </td>
            <td >2 out of 10 </td>
            <td >H</td>
            <td >Rushing , Complacency/ grip</td>
            <td >Mind Not on Task , Eye Not on Task , line of fire</td>
            <td >Analyse close calls  and small errors , Work on Habits </td>
            <td >Instruction given to wear safety shoe, gloves, Full body apron</td>
            <td >immediate</td>
            <td >next week</td>

          </tr>
          <tr >
            <td >4</td>
            <td >Concentracted sulphuric acid lift lift from shelf to sampling  point without apron, gloves</td>
            <td >Yes</td>
            <td >no</td>
            <td >Yes </td>
            <td >Yes </td>
            <td >No </td>
            <td >2 out of 10 </td>
            <td >H</td>
            <td >Rushing , Complacency/ grip</td>
            <td >Mind Not on Task , Eye Not on Task , line of fire</td>
            <td >Analyse close calls  and small errors , Work on Habits </td>
            <td >Instruction given to wear safety shoe, gloves, Full body apron</td>
            <td >immediate</td>
            <td >next week</td>

          </tr>
          <tr >
            <td >5</td>
            <td >Taking of Con. Sulphuric acid in to conical flask  not wearing gloves  acid splash on the hand</td>
            <td >Yes</td>
            <td >no</td>
            <td >Yes </td>
            <td >Yes </td>
            <td >No </td>
            <td >3 out of 10 </td>
            <td >H</td>
            <td >Rushing , Complacency</td>
            <td >Mind Not on Task , Eye Not on Task </td>
            <td >Analyse close calls  and small errors , Work on Habits </td>
            <td >Instruction given to wear safety shoe, gloves, Full body apron</td>
            <td >immediate</td>
            <td >next week</td>

          </tr>
          <tr >
            <td >6</td>
            <td >Sample pp bags sealed by using heat sealing machine without hand gloves </td>
            <td >Yes</td>
            <td >no</td>
            <td >Yes </td>
            <td >Yes </td>
            <td >No </td>
            <td >3 out of 10 </td>
            <td >H</td>
            <td >Rushing , Complacency</td>
            <td >Mind Not on Task , Eye Not on Task </td>
            <td >Analyse close calls  and small errors , Work on Habits </td>
            <td >Instruction given to wear safety , gloves</td>
            <td >immediate</td>
            <td >next week</td>

          </tr>
          <tr >
            <td >7</td>
            <td >Biological waste washing done in the same room Snacks and tea served (very closed to Wash basin)</td>
            <td >Yes</td>
            <td >no</td>
            <td >Yes </td>
            <td >No</td>
            <td >No </td>
            <td >10 out of 10 </td>
            <td >H</td>
            <td >Complacency</td>
            <td >Mind Not on Task , Eye Not on Task </td>
            <td >Work on Habits </td>
            <td >Instruction given to handle in separate place</td>
            <td >immediate</td>
            <td >next week</td>

          </tr>
          <tr >
            <td >8</td>
            <td >Chemist not wearing apron </td>
            <td >Yes</td>
            <td >no</td>
            <td >Yes </td>
            <td >Yes </td>
            <td >No </td>
            <td >6 out of 10 </td>
            <td >M</td>
            <td >Complacency</td>
            <td >Mind Not on Task , Eye Not on Task </td>
            <td >Work on Habits </td>
            <td >Instruction given to wear apron</td>
            <td >immediate</td>
            <td >next week</td>

          </tr>
          <tr >
            <td >9</td>
            <td >Handle the electric switches with wet hand </td>
            <td >Yes</td>
            <td >no</td>
            <td >Yes </td>
            <td >Yes </td>
            <td >No </td>
            <td >6 out of 10 </td>
            <td ></td>
            <td >Complacency</td>
            <td >Mind Not on Task , Eye Not on Task </td>
            <td >Work on Habits </td>
            <td >Instruction given to follow safe work practice</td>
            <td >immediate</td>
            <td >next week</td>

          </tr>
          <tr >
            <td >10</td>
            <td >Slipped down in sample wash basin area  due to wet condition</td>
            <td >yes</td>
            <td >no</td>
            <td >Yes </td>
            <td >No</td>
            <td >No </td>
            <td >3 out of 10 </td>
            <td >M</td>
            <td >Rushing , Complacency</td>
            <td >Mind Not on Task , Eye Not on Task ,Balance/slip/Trip</td>
            <td >Analyse close calls  and small errors , Work on Habits </td>
            <td >Instruction given to maintain the floor in dry condition</td>
            <td >immediate</td>
            <td >next week</td>

          </tr>
          <tr >
            <td >11</td>
            <td >one Liter Measuring carrying with water slipped  </td>
            <td >Yes</td>
            <td >no</td>
            <td >Yes </td>
            <td >Yes </td>
            <td >No </td>
            <td >1 out of 10 </td>
            <td >M</td>
            <td >Complacency</td>
            <td >Mind Not on Task , Eye Not on Task </td>
            <td >Analyse close calls  and small errors , Work on Habits </td>
            <td >Provide suitable glove, Instruction given to follow safe work practice</td>
            <td >immediate</td>
            <td >next week</td>

          </tr>
          <tr >
            <td >12</td>
            <td >Weighing of AGAR possible to expose to eye </td>
            <td >Yes</td>
            <td >no</td>
            <td >Yes </td>
            <td >Yes </td>
            <td >No </td>
            <td >10 out of 10 </td>
            <td >M</td>
            <td >Complacency</td>
            <td >Mind Not on Task , Eye Not on Task </td>
            <td >Analyse close calls  and small errors , Work on Habits </td>
            <td >Provide suitable glove, Instruction given to follow safe work practice</td>
            <td >immediate</td>
            <td >next week</td>

          </tr>
          <tr >
            <td >13</td>
            <td >Disolving of AGAR in Distilled water </td>
            <td >Yes</td>
            <td >no</td>
            <td >Yes </td>
            <td >Yes </td>
            <td >No </td>
            <td >10 out of 10 </td>
            <td >M</td>
            <td >Complacency</td>
            <td >Mind Not on Task , Eye Not on Task </td>
            <td >Analyse close calls  and small errors , Work on Habits </td>
            <td >Provide suitable glove, Instruction given to follow safe work practice</td>
            <td>immediate</td>
            <td >next week</td>

          </tr>
          <tr >
            <td >14</td>
            <td >switch on electric heater for Boiling the meadia solution</td>
            <td >Yes</td>
            <td >no</td>
            <td >Yes </td>
            <td >Yes </td>
            <td >No </td>
            <td >4 out of 10 </td>
            <td >M</td>
            <td >Rushing /Complacency</td>
            <td >Mind Not on Task , Eye Not on Task </td>
            <td >Analyse close calls  and small errors , Work on Habits </td>
            <td >Provide suitable glove, Instruction given to follow safe work practice</td>
            <td >immediate</td>
            <td >next week</td>

          </tr>
          <tr >
            <td >15</td>
            <td >While transfer the Hydro choloric acid spilled on the body </td>
            <td >Yes</td>
            <td >no</td>
            <td >Yes </td>
            <td >Yes </td>
            <td >No </td>
            <td >1 out of 10 </td>
            <td >M</td>
            <td >Rushing /Complacency</td>
            <td >Mind Not on Task , Eye Not on Task </td>
            <td >Analyse close calls  and small errors , Work on Habits </td>
            <td >Provide suitable apron and glove, Instruction given to follow safe work practice</td>
            <td >immediate</td>
            <td >next week</td>

          </tr>
          <tr >
            <td ></td>
            <td >Name:S.Gowrishankar/G.Manikandaraman</td>
            <td ></td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>

          </tr>
          <tr >
            <td ></td>
            <td >Function :  Quality</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>

          </tr>
          <tr >
            <td >&nbsp;</td>
            <td >Cross Function: Raw material, Purchase</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>
            <td >&nbsp;</td>

          </tr>
          <tr >
            <td >1</td>
            <td >Stackingof tubelights on the Cupboard with potential to fall</td>
            <td >Yes</td>
            <td >No</td>
            <td >Yes </td>
            <td >Yes</td>
            <td >No </td>
            <td >10 out of 10</td>
            <td >H</td>
            <td >Complacency</td>
            <td >Line of fire</td>
            <td >Self trigger on the state or amount of hazardous energy</td>
            <td >Identify a safe place for placing the tubelights and keep it there</td>
            <td >November</td>
            <td >December </td>

          </tr>
          <tr >
            <td >2</td>
            <td >Stacking of Bolt Nuts in the cupboard with potential to fall on the feet</td>
            <td >Yes</td>
            <td >No</td>
            <td >Yes </td>
            <td >Yes</td>
            <td >yes</td>
            <td >5 out of 10</td>
            <td >M</td>
            <td >Complacency</td>
            <td >Line of fire  </td>
            <td >Work on Habits </td>
            <td >Modify the shelves so as to safely hold the material</td>
            <td >December</td>
            <td >December </td>

          </tr>
          <tr >
            <td >3</td>
            <td >Hand wash area has potential to cause slippery floor</td>
            <td >Yes</td>
            <td >No</td>
            <td >No</td>
            <td >No</td>
            <td >Yes</td>
            <td >2 out of 10</td>
            <td >L</td>
            <td >Rushing , Complacency</td>
            <td >Balance Traction</td>
            <td >Work on Habits </td>
            <td >Provide a proper wash basin arrangement for hand wash</td>
            <td >December</td>
            <td >December </td>

          </tr>
          <tr >
            <td >4</td>
            <td >Inflammable solvent kept in open area</td>
            <td >Yes</td>
            <td >No</td>
            <td >Yes </td>
            <td >Yes</td>
            <td >No </td>
            <td >3 out of 10</td>
            <td >H</td>
            <td >Complascency</td>
            <td >Eyes not on task and mind not on task</td>
            <td >Work on Habits </td>
            <td >Identify a location and place the inflammable material</td>
            <td >October</td>
            <td >December </td>

          </tr>
          <tr >
            <td >5</td>
            <td >Shaft protruding in walkway</td>
            <td >Yes</td>
            <td >No</td>
            <td >No</td>
            <td >Yes</td>
            <td >No </td>
            <td >10 out of 10</td>
            <td >H</td>
            <td >Complacency</td>
            <td >Eyes not on task  Mind not on task, Line oof Fire, Balance traction</td>
            <td >Work on Habits </td>
            <td >Shift the shafts to a safer place</td>
            <td >October</td>
            <td >December </td>

          </tr>
          <tr >
            <td >6</td>
            <td >Non metallic expansion joint hanging in a rope, Heavy valves kept leaning against the wall</td>
            <td >Yes</td>
            <td >Yes </td>
            <td >No</td>
            <td >Yes</td>
            <td >No </td>
            <td >4 out of 10</td>
            <td >H</td>
            <td >Complacency</td>
            <td >Eyes not on task  Mind not on task</td>
            <td >Work on Habits </td>
            <td >Shift the items to a safer location</td>
            <td ></td>
            <td ></td>

          </tr>


      </table>`,
      `
    ${style}
    <h3>Value Chain for Nellikuppam Plant </h3>
    <table>
    <tr><th>PROCESS FLOW</th></tr>
    <tr>
    <td>Cane Preparation </td></tr>
    <tr><td>Milling</td></tr>
    <tr><td>Clarification</td></tr>
   <tr> <td>Evaporation</td></tr>
    <tr><td>Crystallization </td></tr>
   <tr> <td>Centralization</td></tr>
    <tr><td>SugarDry</td></tr>
    <tr><td>Grading</td></tr>
    <tr><td>Quality</td></tr>
    <tr><td>Packing</td>

    </tr>





    </table>`
    ],

    tabContent1: [
      `
      ${style}

      <table>
      <tr >
      <th colspan="2">Function: </th>
      <th colspan="5">Reason</th>
      <th colspan="2">Risk </th>
      <th  colspan="3">SafeStart </th>
      <th >Training/ Process Change/ Policy </th>
      <th ></th>
      <th ></th>

    </tr>
    <tr >
      <th >S.No.</th>
      <th >Observations </th>
      <th >People</th>
      <th >Process </th>
      <th >Policy </th>
      <th >Training </th>
      <th >Technology </th>
      <th >Frequency of errors</th>
      <th >Risk Involved (H/M/L) </th>
      <th >State </th>
      <th >Error </th>
      <th >CERT </th>
      <th >Actions (if change has to happen, what specific) </th>
      <th >Start When</th>
      <th >Review </th>

    </tr>
    <tr >
    <td ></td>
    <td >PROCESS</td>
    <td >&nbsp;</td>
    <td >&nbsp;</td>
    <td >&nbsp;</td>
    <td >&nbsp;</td>
    <td >&nbsp;</td>
    <td >&nbsp;</td>
    <td >&nbsp;</td>
    <td >&nbsp;</td>
    <td >&nbsp;</td>
    <td >&nbsp;</td>
    <td >&nbsp;</td>
    <td >&nbsp;</td>

    <td >&nbsp;</td>
  </tr>
  <tr >
    <td ></td>
    <td >Gangatharan  and Sukumar </td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>

  </tr>
  <tr >
    <td >1</td>
    <td >Lost balance while walking in spl b machine area due to un even slab on the effluent gutter</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >8</td>
    <td >M</td>
    <td >Complacency</td>
    <td >Eyes not on task, Mind not on task</td>
    <td >Self trigger, Analyse close calls, work on habits</td>
    <td >Test your footing or grip before you commit your weight.</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >2</td>
    <td >Lost balance while stepping down from the platform after rehoppering at west hopper no.4 as there is no proper handrail in the ladder.</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >9</td>
    <td >H</td>
    <td >Complacency</td>
    <td >Eyes not on task, Mind not on task,Balance/Traction/Grip</td>
    <td >Self trigger, Analyse close calls, work on habits</td>
    <td >Test your footing or grip before you commit your weight.</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >3</td>
    <td >Washing the spl b machine with hot water hose</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td >3</td>
    <td >M</td>
    <td >Complacency</td>
    <td >Line fo fire</td>
    <td >Self trigger, Analyse close calls, work on habits</td>
    <td >Use proper PPE as per the work you took like hand glouse at this work.</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >4</td>
    <td >Lost the balance while cleaning the gas line by standing on a carbuoy </td>
    <td >Yes</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td >6</td>
    <td >L</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task,Balance/Traction/Grip</td>
    <td >Self trigger, Analyse close calls, work on habits</td>
    <td >Test ypur footing or grip before you commit your weight.</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >5</td>
    <td >Rod got slipped from hand while clearing the sugar jamming from A2 crystallizer</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >5</td>
    <td >M</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task,Balance/Traction/Grip</td>
    <td >Look at others</td>
    <td >Use the proper tools and Hold it  carefully</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >6</td>
    <td >Hand got hit while clearing the sugar jamming from A2 crystallizer(using improper tool)</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td >Yes</td>
    <td >3</td>
    <td >H</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task,Balance/Traction/Grip</td>
    <td >Look at others</td>
    <td >Use the proper tools and Hold it  carefully</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >7</td>
    <td >Rust material splashed on face while cleaning the melter tank</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >1</td>
    <td >L</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task</td>
    <td >Self trigger</td>
    <td >Use the proper PPE</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >8</td>
    <td >Doing welding with small glass to protect the eye</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td >2</td>
    <td >M</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task, Line of fire</td>
    <td >Self trigger</td>
    <td >Use the proper PPE</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >9</td>
    <td >Lost the balance while destacking the bags at rehoppering area</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >6</td>
    <td >H</td>
    <td >Rushing/  Frustration</td>
    <td >Eyes not on task, Mind not on task, Balance /traction/Grip</td>
    <td >Look at others</td>
    <td >Look for things that could cause you to lose your balance, grip. </td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >10</td>
    <td >Lost the grip on the tool(hook) used for removing the thread</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >8</td>
    <td >M</td>
    <td >Rushing/ Frustration</td>
    <td >Eyes not on task, Mind not on task, Balance /traction/Grip</td>
    <td >Look at others</td>
    <td >Look for things that could cause you to lose your balance, grip. </td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >11</td>
    <td >Lost his balance while lifting the bags to put on conveyor at rehoppering area</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >3</td>
    <td >M</td>
    <td >Rushing/ Frustration</td>
    <td >Eyes not on task, Mind not on task, Balance /traction/Grip</td>
    <td >Look at others</td>
    <td >Look for things that could cause you to lose your balance, grip. </td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >12</td>
    <td >Finger got slight scratch while opening the bags (stitches) by sharp small blade</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >2</td>
    <td >L</td>
    <td >Rushing/ Frustration</td>
    <td >Eyes not on task, Mind not on task, Balance /traction/Grip</td>
    <td >Look at others</td>
    <td >Usage of proper tools (blade with handle)</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >13</td>
    <td >Lost balance while walking on the sticky floor near rehoppering area (sugar got wet at dedusting of bags)</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >2</td>
    <td >L</td>
    <td >Complacency</td>
    <td >Eyes not on task, Mind not on task, Balance /traction/Grip</td>
    <td >Self trigger</td>
    <td >Look for things that could cause you to lose your balance, grip. </td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >14</td>
    <td >Tool got slipped while opening the stitches of the sugar bags at rehoppering area</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >3</td>
    <td >L</td>
    <td >Rushing/ Frustration</td>
    <td >Eyes not on task, Mind not on task, Balance /traction/Grip</td>
    <td >Look at others</td>
    <td >Look for things that could cause you to lose your balance, grip. </td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >15</td>
    <td >Lost his balance while putting the empty bags on truck (Jumping on the bundles to accommodate more)</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >3</td>
    <td >L</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task, Balance /traction/Grip</td>
    <td >Self trigger</td>
    <td >Look for things that could cause you to lose your balance, grip. </td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >16</td>
    <td >Lost balance (slipped as not hold the handle properly)while entering in to SRI juice clarifier</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >1</td>
    <td >L</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task, Balance /traction/Grip</td>
    <td >Self trigger</td>
    <td >Look carefully at anything you are going to stick your hand into or rest your hand on</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >17</td>
    <td >Lost his balance - standing on the side frame of the filter while checking </td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >1</td>
    <td >L</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task, Balance /traction/Grip</td>
    <td >Self trigger</td>
    <td >Look carefully at anything you are going to stick your hand into or rest your hand on</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >18</td>
    <td >Lime powder spilled on eyes while preparing Milk Of lime </td>
    <td >Yes</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td >1</td>
    <td >M</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task</td>
    <td >Self trigger</td>
    <td >usage of proper PPE</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >19</td>
    <td >Lost balance at slippery floor near AH run off tank </td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td >Yes</td>
    <td >1</td>
    <td >M</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task</td>
    <td >Self trigger</td>
    <td >Test your footing or grip before you commit your weight.</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >20</td>
    <td >Lost balance while opening the man hole door south syrup sulphitor</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >1</td>
    <td >H</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task</td>
    <td >Work on habits</td>
    <td >Look for things that could cause you to lose your balance, grip. </td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >21</td>
    <td >Caustic soda spill over on legs while preparing soda solution</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td >1</td>
    <td >H</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task</td>
    <td >Self trigger</td>
    <td >usage of proper PPE</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >22</td>
    <td >Lost balance while dragging the lime bags to pour it to slaker (Bag got dehooked)</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td >3</td>
    <td >M</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task</td>
    <td >Self trigger</td>
    <td >Look for things that could cause you to lose your balance, grip. </td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >23</td>
    <td >Milk of lime spilled over while using it to spray on the inner walls of the vessels (Hose not clamped properly)</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >1</td>
    <td >L</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task</td>
    <td >Self trigger</td>
    <td >Usage of proper tools (Clamp)</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >24</td>
    <td >Head got slightly hit with the conveyor frame at rehoppering area conveyor</td>
    <td >Yes</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td >6</td>
    <td >M</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task, Line of fire</td>
    <td >Self trigger, work on habits</td>
    <td >Move your eyes first before you move your   body</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >25</td>
    <td >Lost balance while opening the steam valves with F rod / opening tool</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >2</td>
    <td >L</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task, Balance /traction/Grip</td>
    <td >Self trigger</td>
    <td >Look for things that could cause you to lose your balance, grip. </td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >26</td>
    <td >Suffocation due to sugar dust near sugar elevator </td>
    <td >Yes</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td >Yes</td>
    <td >6</td>
    <td >L</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task</td>
    <td >Work on habits</td>
    <td >Usage of PPE</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >27</td>
    <td >Sugar bags fell on legs while refilling the sweeping sugar (50 kg bags to 1 ton Jumbo bag)</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >4</td>
    <td >L</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task</td>
    <td >Work on habits</td>
    <td >Look for things that could cause you to lose your balance, grip. </td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >28</td>
    <td >Lost balance while hooking the jumbo bags(sweeping sugar) to Fork lift near rehoppering area</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >2</td>
    <td >L</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task</td>
    <td >Self trigger</td>
    <td >Look for things that could cause you to lose your balance, grip. </td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >29</td>
    <td >Lost the grip while holding the tube cleaning shaft </td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td ></td>
    <td >3</td>
    <td >H</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task, Balance /traction/Grip</td>
    <td >Self trigger</td>
    <td >Look for things that could cause you to lose your balance, grip. </td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
    <td >30</td>
    <td >Lost balance due to the slippery floor (Low Pty molasses tank leak)</td>
    <td >Yes</td>
    <td >Yes</td>
    <td ></td>
    <td ></td>
    <td >Yes</td>
    <td >1</td>
    <td >M</td>
    <td >Rushing/  Complacency</td>
    <td >Eyes not on task, Mind not on task</td>
    <td >Self trigger</td>
    <td >Move your eyes first before you move your   body</td>
    <td >October</td>
    <td >November</td>

  </tr>
  <tr >
  <td ></td>
  <td >Tamil selvan </td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td ></td>
  <td >Melting</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >31</td>
  <td >Bag destacking from Lot</td>
  <td >Yes</td>
  <td >No</td>
  <td >Yes </td>
  <td >Yes</td>
  <td >No </td>
  <td >10 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Line of fire</td>
  <td >Self trigger on the state or amount of hazardous energy</td>
  <td >Destacking to be done only by godown personal.  Lot height to be reduced</td>
  <td >October</td>
  <td >December </td>

</tr>
<tr >
  <td >32</td>
  <td >Bag dumping into transit silo</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >3 out of 10</td>
  <td >M</td>
  <td >Complacency</td>
  <td >Line of fire balance /traction/Grip          </td>
  <td >Look at others for the patterns that increase the risk of injury</td>
  <td >Regular experienced personal only employed for this work</td>
  <td >October</td>
  <td >December </td>

</tr>
<tr >
  <td>33</td>
  <td >Transfering sugar from silo to belt conveyor</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10</td>
  <td >H</td>
  <td >Rushing , Frustration</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Work on Habits </td>
  <td >Regular experienced personal only employed for this work</td>
  <td >October</td>
  <td >December </td>

</tr>
<tr >
  <td >34</td>
  <td >Steam valve opening/ closing </td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >2 out of 10</td>
  <td >H</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >October</td>
  <td >December </td>
</tr>
<tr >
  <td >35</td>
  <td >Melt sampling</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >3 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task  Mind not on task</td>
  <td >Work on Habits </td>
  <td >PPE to be used</td>
  <td >October</td>
  <td >December </td>

</tr>
<tr >
  <td >36</td>
  <td >colour precipitant addition into dosing tank</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >4 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task Mind not on task</td>
  <td >Work on Habits </td>
  <td >Necessary PPE to be weared</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >37</td>
  <td >Elevator choke removal</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Self trigger on the state or amount of hazardous energy</td>
  <td >SOP to be strictcly followed</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td ></td>
  <td >Clarification</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >38</td>
  <td >Steam valve opening to heater</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >2 out of 10</td>
  <td >H</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >October</td>
  <td >December </td>

</tr>
<tr >
  <td >39</td>
  <td >Heater plate Opening for cleaning</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >yes</td>
  <td >1out of 10</td>
  <td >M</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >40</td>
  <td >Heater plate cleaning with acid during off season</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >yes</td>
  <td >2 out of 10</td>
  <td >M</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >PPE to be used</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >41</td>
  <td >Phosphoric acid addtion into tank</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >4 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Work on Habits </td>
  <td >Necessary PPE to be weared</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >42</td>
  <td >MOL addition and Preparation</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >4 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Work on Habits </td>
  <td >Necessary PPE to be weared</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >43</td>
  <td >Mol prepartion tank cleaning</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >4 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Work on Habits </td>
  <td >Necessary PPE to be weared</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >44</td>
  <td >MOL holding tank cleaning</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >4 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Work on Habits </td>
  <td >Necessary PPE to be weared</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >45</td>
  <td >Flocullant preparation</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >4 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Work on Habits </td>
  <td >Necessary PPE to be weared</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >46</td>
  <td >Chemical flow measuring at reaction tank inlet</td>
  <td >yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >no</td>
  <td >No </td>
  <td >1 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task. Balance/grip/tration</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >47</td>
  <td >Clarifier aeration disc cleaning</td>
  <td >yes</td>
  <td >No</td>
  <td >Yes </td>
  <td >Yes</td>
  <td >No </td>
  <td >2 out of 10</td>
  <td >H</td>
  <td >Rushing</td>
  <td >Eyes not on task           Mind not on task</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >48</td>
  <td >Clarifier coil cleaning</td>
  <td >yes</td>
  <td >No</td>
  <td >Yes </td>
  <td >Yes</td>
  <td >No </td>
  <td >2 out of 10</td>
  <td >H</td>
  <td >Rushing</td>
  <td >Eyes not on task           Mind not on task</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >49</td>
  <td >Vibroscreen washing with hot water </td>
  <td >yes</td>
  <td >Yes </td>
  <td >NO</td>
  <td >no</td>
  <td >No </td>
  <td >2 out of 10</td>
  <td >M</td>
  <td >Rushing , Frustration</td>
  <td >Eyes not on task           Mind not on task</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td ></td>
  <td >Filtration</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >50</td>
  <td >Precoat preparartion for candle filter</td>
  <td >yes</td>
  <td >Yes </td>
  <td >NO</td>
  <td >no</td>
  <td >No </td>
  <td >3 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task. Balance/grip/tration</td>
  <td ></td>
  <td ></td>
  <td >october</td>
  <td >Novemeber</td>

</tr>
<tr >
  <td >51</td>
  <td >Melt sampling from filter</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >3 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Work on Habits </td>
  <td ></td>
  <td >October</td>
  <td >December </td>

</tr>
<tr >
  <td >52</td>
  <td >Candle filter candle washing</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >4 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task   Mind not on task</td>
  <td >Work on Habits </td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >53</td>
  <td >Candle filter opening and box up</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >2out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Work on Habits </td>
  <td ></td>
  <td ></td>
  <td ></td>
</tr>
<tr >
  <td >54</td>
  <td >Check filter cloth renewal work</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >2 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Work on Habits </td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td ></td>
  <td >PAN</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >55</td>
  <td >Steam valve opening to pan</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >2 out of 10</td>
  <td >H</td>
  <td >Rushing , Frustration</td>
  <td >Eyes not on task           Mind not on task</td>
  <td ></td>
  <td ></td>
  <td >October</td>
  <td >October</td>

</tr>
<tr >
  <td >56</td>
  <td >Discharging of massecuite</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >3 out of 10</td>
  <td >H</td>
  <td >Rushing , Frustration</td>
  <td >Eyes not on task           Mind not on task</td>
  <td ></td>
  <td >Proper communication to be given to XLR attender and Pan conract labour</td>
  <td >October</td>
  <td >october</td>

</tr>
<tr >
  <td >57</td>
  <td >Pan circulator Gland rope renewal</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >2out of 10</td>
  <td >H</td>
  <td >Rushing , Frustration</td>
  <td >Eyes not on task           Mind not on task</td>
  <td ></td>
  <td >Proper communication to be given to XLR attender and Pan conract labour</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >58</td>
  <td >Pan tube cleaning</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >2 out of 10</td>
  <td >H</td>
  <td >Rushing , Frustration</td>
  <td >Eyes not on task           Mind not on task</td>
  <td ></td>
  <td >Proper communication to be given to XLR attender and Pan conract labour</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td ></td>
  <td >Crystalizer</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >59</td>
  <td >Crystallizer level checking</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >2 out of 10</td>
  <td >H</td>
  <td >Rushing , Frustration, Fatigue</td>
  <td >Balance/traction/ grip</td>
  <td >Work on Habits </td>
  <td >Level indicator to be provided for Crystallizer Level monitoring</td>
  <td >October</td>
  <td >April</td>

</tr>
<tr >
  <td >60</td>
  <td >Steam flushing to crystalizer discharge line</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >3 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Work on Habits </td>
  <td >Training to be given</td>
  <td >October</td>
  <td >December </td>

</tr>
<tr >
  <td >61</td>
  <td >crystalizer discharge line choke removing</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >2 out of 10 </td>
  <td >M</td>
  <td >Rushing</td>
  <td >Balance/traction/ grip</td>
  <td ></td>
  <td >Hot water tracing jacket to be provided in Crystaliser discharge line</td>
  <td >October</td>
  <td >January</td>

</tr>
<tr >
  <td >62</td>
  <td >Crystallizer inside cleaning</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >3 out of 10 </td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td ></td>
  <td >Self trigger on the state or amount of hazardous energy</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >63</td>
  <td >Crystalizer arms rotating manually during power failure</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10 </td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td ></td>
  <td >Product centrifugal</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >64</td>
  <td >Pugmill arms rotating manually during Power failure</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10 </td>
  <td >H</td>
  <td >Complacency</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >65</td>
  <td >Sugar sampling in Product centrifuge</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >No</td>
  <td >No </td>
  <td >1 out of 10 </td>
  <td >M</td>
  <td >Rushing/ Frustration</td>
  <td >Eyes not on task /mind not on task</td>
  <td ></td>
  <td ></td>
  <td >October</td>
  <td >December </td>

</tr>
<tr >
  <td >66</td>
  <td >Chute cleaning in product centrifuge</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Work on Habits </td>
  <td >Training to be given</td>
  <td >October</td>
  <td >December </td>

</tr>
<tr >
  <td >67</td>
  <td >Product centrifugal screen change</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >2 out of 10</td>
  <td >H</td>
  <td >Rushing/ Fatigue</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Work on Habits </td>
  <td >Training to be given</td>
  <td >October</td>
  <td >December </td>

</tr>
<tr >
  <td >68</td>
  <td >Product centrifugal sensor adjustment</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10</td>
  <td >M</td>
  <td >Rushing/ Fatigue</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Work on Habits </td>
  <td >Training to be given</td>
  <td >October</td>
  <td >December </td>

</tr>
<tr >
  <td >69</td>
  <td >Product centrifugal plough adjustment</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >2 out of 10</td>
  <td >M</td>
  <td >Rushing/ Fatigue</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >70</td>
  <td >Drum drier water washing</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Work on Habits </td>
  <td >Training to be given</td>
  <td >October</td>
  <td >December </td>

</tr>
<tr >
  <td >71</td>
  <td >Duire outlet Screw conveyor cleaning</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td >Work on Habits </td>
  <td >Training to be given</td>
  <td >October</td>
  <td >December </td>

</tr>
<tr >
  <td ></td>
  <td >Grading and Packing</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
</tr>
<tr >
  <td >72</td>
  <td >Grader mesh change work</td>
  <td >yes</td>
  <td >No</td>
  <td >NO</td>
  <td >Yes</td>
  <td >No </td>
  <td >2 out of 10</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task           Mind not on task</td>
  <td ></td>
  <td >Drier efficiency to be improved to reduce grader mesh cleaning frequency</td>
  <td >October</td>
  <td >April</td>

</tr>
<tr >
  <td >73</td>
  <td >Bin cleaning</td>
  <td >yes</td>
  <td >No</td>
  <td >NO</td>
  <td >Yes</td>
  <td >No </td>
  <td >2 out of 10</td>
  <td >H</td>
  <td ></td>
  <td >Eyes not on task           Mind not on task</td>
  <td ></td>
  <td >Drier efficiency to be improved to reduce grader mesh cleaning frequency</td>
  <td >October</td>
  <td >April</td>

</tr>
<tr >
  <td >74</td>
  <td >Bin outlet screw conveyor cleaning</td>
  <td >yes</td>
  <td >Yes </td>
  <td >NO</td>
  <td >Yes</td>
  <td >No </td>
  <td >3 out of 10</td>
  <td >H</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >75</td>
  <td >Packing vibroscreen cleaning</td>
  <td >yes</td>
  <td >Yes </td>
  <td >NO</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10</td>
  <td >M</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >76</td>
  <td >Magnets cleaning</td>
  <td >yes</td>
  <td >Yes </td>
  <td >NO</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10</td>
  <td >M</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >77</td>
  <td >Packing vibroscreen opening and cleaning and box up</td>
  <td >yes</td>
  <td >Yes </td>
  <td >NO</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10</td>
  <td >M</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >78</td>
  <td >Graded sugar vibroscreen opening and cleaning and box up</td>
  <td >yes</td>
  <td >Yes </td>
  <td >NO</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10</td>
  <td >M</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >79</td>
  <td >Slippery floor after product cfl chute washing and drum drier water washing</td>
  <td >yes</td>
  <td >Yes </td>
  <td >NO</td>
  <td >Yes</td>
  <td >No </td>
  <td >2 out of 10</td>
  <td >H</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >80</td>
  <td >30-40 blower line choke removal</td>
  <td >yes</td>
  <td >Yes </td>
  <td >NO</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10</td>
  <td >M</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >81</td>
  <td >Heat sealing operation</td>
  <td >yes</td>
  <td >Yes </td>
  <td >NO</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10</td>
  <td >M</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

  <td ></td>
</tr>
<tr >
  <td >82</td>
  <td >Bag receiving from graded sugar section outlet conveyor</td>
  <td >yes</td>
  <td >Yes </td>
  <td >NO</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10</td>
  <td >H</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >83</td>
  <td >Bag stacking at graded sugar transit storage area.</td>
  <td >yes</td>
  <td >Yes </td>
  <td >NO</td>
  <td >Yes</td>
  <td >No </td>
  <td >1 out of 10</td>
  <td >H</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td ></td>
  <td >Senthilvelan </td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

  <td >&nbsp;</td>
</tr>
<tr >
  <td >84</td>
  <td >Step ahead while down in the staircase of distillation section</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >2</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, Analyse close calls, work on habits</td>
  <td >Move your eyes before move your body, on getting down the steps</td>
  <td >Immediate</td>
  <td >Continous</td>

</tr>
<tr >
  <td >85</td>
  <td >Carrying Yeast Culture Falsks without Glouse after the sterilization from Auto Clave</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td ></td>
  <td >5</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Grip</td>
  <td >Self trigger, work on habits </td>
  <td >Traing to be given on usage and risk of carrying flask without glouse</td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >86</td>
  <td >Opening Foam oil barrels without propoer tools</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td ></td>
  <td >4</td>
  <td >M</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Training to be given on propoer usage of drum openers</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >87</td>
  <td >Spirit loading area- tanker movement unbalanced due to uneven surface</td>
  <td ></td>
  <td >Yes</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >5</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Line on Fire</td>
  <td >Analse close calls</td>
  <td >Proper arrangement</td>
  <td >December</td>
  <td >Regular</td>

</tr>
<tr >
  <td >88</td>
  <td >Operating the valve without proper handle</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td >3</td>
  <td >M</td>
  <td >Rushing</td>
  <td >Line on Fire</td>
  <td >Self trigger, work on habits </td>
  <td >Providing proper handles to valve operation</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >89</td>
  <td >Manual tube cleaning</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >4</td>
  <td >H</td>
  <td >Rushing/Complacency</td>
  <td >Mind Not on Task</td>
  <td >Self trigger, work on habits </td>
  <td >Updation of SOP/Training on lockout system &amp; Usage of PPE's</td>
  <td >Immediate</td>
  <td >Three months once</td>

</tr>
<tr >
  <td >90</td>
  <td >Walking on and around the molasses storage tanks </td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >5</td>
  <td >H</td>
  <td >Rushing/Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Work on Habits </td>
  <td >Proper walkway with handrails, foot toe plate/ladder/Cover on the top inspection openings.</td>
  <td >November</td>
  <td >Monthly once</td>

</tr>
<tr >
  <td >91</td>
  <td >Confined space entry</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >6</td>
  <td >H</td>
  <td >Rushing/Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Work on Habits </td>
  <td >Proper checking before entry /Training on usage on PPE</td>
  <td >Immediate</td>
  <td >At the time of work</td>

</tr>
<tr >
  <td >92</td>
  <td >Dust emmision</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >5</td>
  <td >H</td>
  <td >Frustration</td>
  <td >Line on Fire</td>
  <td >Work on Habits </td>
  <td >Proper technology to be adopted , Proper indications/Display/Training on usage on PPE</td>
  <td >December</td>
  <td >Daily</td>

</tr>
<tr >
  <td >93</td>
  <td >High pressure lines/Vessels</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >2</td>
  <td >H</td>
  <td >Rushing</td>
  <td >Line on Fire</td>
  <td >Work on Habits </td>
  <td >Proper line coding / Indications / Displays </td>
  <td >Immediate</td>
  <td >Yearly</td>

</tr>
<tr >
  <td >94</td>
  <td >Hydrated lime dust emission </td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >6</td>
  <td >H</td>
  <td >Frustration</td>
  <td >Line on Fire</td>
  <td >Work on Habits </td>
  <td >Proper indications/Display/Training on usage on PPE</td>
  <td >December</td>
  <td >Daily</td>

</tr>
<tr >
  <td >95</td>
  <td >Handling of sulphuric acid chemicals</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >2</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Mind Not on Task</td>
  <td >Work on Habits </td>
  <td >MSDS Display / Training on usage of PPE &amp; Handling on acids</td>
  <td >Immediate</td>
  <td >Daily</td>

</tr>
<tr >
  <td >96</td>
  <td >Work at Height</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >3</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Balance/Traction/Grip</td>
  <td >Work on Habits </td>
  <td >Training on usage of PPE</td>
  <td >Immediate</td>
  <td >At the time of work</td>

</tr>
<tr >
  <td >97</td>
  <td >Spanner slipped while tightning</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes </td>
  <td ></td>
  <td >1</td>
  <td >L</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Look for things that could cause you to lose your balance, grip. </td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >98</td>
  <td >High speed rotating equipments</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >1</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Work on Habits </td>
  <td >Proper protecting guards, Proper indications/Display/Training on emergency</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr class="row111">
  <td ></td>
  <td >Vijayan</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >99</td>
  <td >ATFD dust emission </td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >6</td>
  <td >H</td>
  <td >Frustration</td>
  <td >Line on Fire</td>
  <td >Work on Habits </td>
  <td >Proper indications/Display/Training on usage on PPE</td>
  <td >December</td>
  <td >Daily</td>

</tr>
<tr >
  <td >100</td>
  <td >Handling of Hydrochloric acid chemicals</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >2</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Mind Not on Task</td>
  <td >Work on Habits </td>
  <td >MSDS Display / Training on usage of PPE &amp; Handling on acids</td>
  <td >Immediate</td>
  <td >Daily</td>

</tr>
<tr >
  <td >101</td>
  <td >Work at Height</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >3</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Balance/Traction/Grip</td>
  <td >Work on Habits </td>
  <td >Training on usage of PPE</td>
  <td >Immediate</td>
  <td >At the time of work</td>

</tr>
<tr >
  <td >102</td>
  <td >Materials fall from height</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes </td>
  <td ></td>
  <td >1</td>
  <td >L</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Look for things that could cause you to lose your balance, grip. </td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >103</td>
  <td >High speed rotating equipments</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >1</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Work on Habits </td>
  <td >Proper protecting guards, Proper indications/Display/Training on emergency</td>
  <td >Immediate</td>
  <td >Regular</td>
</tr>
<tr >
  <td >104</td>
  <td >Open effluent storage pond</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td ></td>
  <td ></td>
  <td >0</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, Analyse close calls, work on habits</td>
  <td >Move your eyes before move your body, Proper barry guard to be provide</td>
  <td >Immediate</td>
  <td >Continous</td>

</tr>
<tr >
  <td >105</td>
  <td >Hydrojet tube cleaning</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >4</td>
  <td >H</td>
  <td >Rushing/Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Proper triping system/Training on lockout system &amp; Usage of PPE's</td>
  <td >Immediate</td>
  <td >Three months once</td>

</tr>
<tr >
  <td >106</td>
  <td >Confined space entry</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >6</td>
  <td >H</td>
  <td >Rushing/Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Work on Habits </td>
  <td >Proper checking before entry /Training on usage on PPE</td>
  <td >Immediate</td>
  <td >At the time of work</td>

</tr>
<tr >
  <td >107</td>
  <td >High temperature lines/Vessels</td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >2</td>
  <td >H</td>
  <td >Rushing</td>
  <td >Line on Fire</td>
  <td >Work on Habits </td>
  <td >Proper insulation / line coding/Indication/Display</td>
  <td >Immediate</td>
  <td >Daily</td>

</tr>
<tr >
  <td >108</td>
  <td >Walking on and around the alcohol storage tanks </td>
  <td >Yes</td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >Yes </td>
  <td >5</td>
  <td >H</td>
  <td >Rushing/Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Work on Habits </td>
  <td >Proper walkway with handrails, foot toe plate/ladder/Cover on the top inspection openings.</td>
  <td >November</td>
  <td >Monthly once</td>

</tr>
<tr >
  <td ></td>
  <td >ENGINEERING</td>
  <td ></td>
  <td >&nbsp;</td>
  <td >&nbsp;</td>
  <td >&nbsp;</td>
  <td >&nbsp;</td>
  <td >&nbsp;</td>
  <td >&nbsp;</td>
  <td >&nbsp;</td>
  <td >&nbsp;</td>
  <td >&nbsp;</td>
  <td >&nbsp;</td>
  <td >&nbsp;</td>
  <td >&nbsp;</td>

</tr>
<tr >
  <td ></td>
  <td >KESAVAN</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >1</td>
  <td >boiler ash collection bags dropped from steam drum floor- no barrguard on the ground, objects may fall on the person who passing.</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td >No</td>
  <td >5</td>
  <td >M</td>
  <td >Complacency<br />
Rushing</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
  <td >Analyse Close Calls<br />
Work On Habits<br />
Self Trigger</td>
  <td >Requir training to the work men </td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >2</td>
  <td >Not wearing the Safety Helmet and Shoes in side the plant permises </td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >10</td>
  <td >H/M/L</td>
  <td >Complacency</td>
  <td >Mind not on task<br />
Line of File</td>
  <td >Self Trigger<br />
Look at Others<br />
Work on habits</td>
  <td >Training to be given who not wearing the PPE's </td>
  <td >November</td>
  <td >November</td>

</tr>
<tr >
  <td >3</td>
  <td >While coal feeding front end loader bucket removes the bottom chequered plant in coal feeding area</td>
  <td >Yes</td>
  <td >Process </td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >2</td>
  <td >H</td>
  <td >Rushing <br />
Complacency</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
  <td >Self trigger, <br />
Work on habits</td>
  <td >Training to be given to front end loader operator and the persons who feeding the coal should be happen on the designated places with proper barryguard.</td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >4</td>
  <td >Slipped while down from boiler drag chain feeder floor </td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >No</td>
  <td >No</td>
  <td >4</td>
  <td >M</td>
  <td >Complacency</td>
  <td >Eyes not on task, <br />
Mind not on task</td>
  <td >Self trigger, <br />
Analyse close calls</td>
  <td >Training &amp; Process Change ( slippey steps to be changes with anti slippery condition)</td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >5</td>
  <td >Front end loader hit the coal shed column while reversing</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >2</td>
  <td >M</td>
  <td >Complacency<br />
Rushing</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
  <td >Work On Habits<br />
Analyse Close Calls</td>
  <td >Get Your Eyes Back on the road quickly, Traning to the drivers and Use the technology like Reverse Sensor or Camera can be fited to all the vehicles</td>
  <td >All times</td>
  <td >November</td>

</tr>
<tr >
  <td >6</td>
  <td >A Person fall from height of 5 Ft while while downing from furnace floor afetr water sample taking</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >1</td>
  <td >L</td>
  <td >Complacency<br />
Rushing</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Balance / Traction / Grip</td>
  <td >Work On Habits<br />
Self Trigger</td>
  <td >Training required to use the roght tools for right job</td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >7</td>
  <td >While shifting of empty paint cans from work location, dopped to his leg. Near miss happen with no injury</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >3</td>
  <td >M</td>
  <td >Complacency<br />
Rushing<br />
Fatigue</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Balance / Traction / Grip</td>
  <td >Look at others<br />
work on habits<br />
Analyse close calls</td>
  <td >Requir training to the work men and the same was given all workrs on tool box talk while day commence of work</td>
  <td >22.10.19</td>
  <td >22.10.19</td>
</tr>
<tr >
  <td >8</td>
  <td >Less gap was maintain between the bagasse and coal storage leads to auto ignition </td>
  <td >Yes</td>
  <td >Yes</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >8</td>
  <td >H</td>
  <td >Rushing <br />
Complacency<br />
Fatigue</td>
  <td >Mind not on task<br />
Line of File<br />
Balance / Traction/ Grip</td>
  <td >Work On Habit<br />
Self Trigger<br />
Analyse Close calls</td>
  <td >Training to be given all the drivers SOP to be created and followed </td>
  <td >November</td>
  <td >November</td>

</tr>
<tr >
  <td >9</td>
  <td >Exposed underground cables in boiler 2 ground floor</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >1</td>
  <td >H</td>
  <td >Rushing<br />
Fatigue<br />
Complacency</td>
  <td >Line of Fire</td>
  <td >Self Trigger<br />
Analyse close calls &amp; small errors</td>
  <td >Training to be given to all the electrician's </td>
  <td >November</td>
  <td >November</td>

</tr>
<tr >
  <td >10</td>
  <td >ESP screw conveyor top not covered leads to ash spillage and dust affects the path way leads to near miss</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >8</td>
  <td >L / M</td>
  <td >Complacency <br />
Rushing</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
  <td >Work On Habits<br />
Analyse Close Calls</td>
  <td >Training to be given the performer </td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >11</td>
  <td >Shifting of PVC round table - part of table fell down on the person near to foot  </td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >1</td>
  <td >L</td>
  <td >Rushing </td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
  <td >Self Trigger<br />
Analyse close calls &amp; small errors</td>
  <td >Training given on the spot </td>
  <td >19.10.19</td>
  <td >23.10.19</td>

</tr>
<tr >
  <td >12</td>
  <td >Sulphuric acid pump splashing the acid due to gland leak in cooling tower -may splase to the person work near to it</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >3</td>
  <td >H</td>
  <td >Rushing<br />
Complacency</td>
  <td >Eyes not on taks<br />
Line of fire </td>
  <td >Work On Habits<br />
Analyse Close Calls</td>
  <td >Training to be give the operator </td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >13</td>
  <td >Two type of Vendor work in the boiler ground and furnace floor- chance of drop the object and may hit to the person working on the ground.</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >4</td>
  <td >H</td>
  <td >Rushing<br />
Complacency</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire<br />
Balance / Traction / Grip</td>
  <td >Self Trigger<br />
Analyse close calls &amp; small errors<br />
Work on habit</td>
  <td >Training to be given the both vendors<br />
Process- Work permit should not issued on the same location to two vendors</td>
  <td >Immedite</td>
  <td >October</td>

</tr>
<tr >
  <td >14</td>
  <td >coal truckr truck driver binding the tarpalins on the truck to cover the sugar bags - there may be chance of falldown </td>
  <td >Yes</td>
  <td >Yes</td>
  <td >No</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >10</td>
  <td >M/H</td>
  <td >Rushing<br />
Complacency</td>
  <td >Line Of Fire<br />
Balance / Traction / Grip</td>
  <td >Self Trigger<br />
Analyse close calls &amp; small errors<br />
Work on habit</td>
  <td >Training to be given the performer<br />
Process - work to be performed in allocated area and working at height safety procedure to be followd </td>
  <td >Immedite</td>
  <td >November</td>

</tr>
<tr >
  <td >15</td>
  <td >While stacking the bagasse, driver not usnig nose mask and helmat, leads to dust allergy and PPE violation</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >3</td>
  <td >H</td>
  <td >Rushing<br />
Complacency<br />
Fatigue</td>
  <td >Eyes Not on Task<br />
Line Of Fire<br />
Balance / Traction / Grip</td>
  <td >Self Trigger<br />
Analyse close calls &amp; small errors<br />
Work on habit</td>
  <td >Training to be given to the operator</td>
  <td >Immedite</td>
  <td >November</td>

</tr>
<tr >
  <td ></td>
  <td >Thirunavukarsu</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >16</td>
  <td >Sulphur splashed in eyes while loading to melter</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td ></td>
  <td >5</td>
  <td >H</td>
  <td >Frustration/Complacency</td>
  <td >Line - of - Fire</td>
  <td >Self trigger, Analyze close calls, work on habits</td>
  <td >Move your eyes before move your body, the wet area to be cleaned</td>
  <td >October</td>
  <td >Regular</td>

</tr>
<tr >
  <td >17</td>
  <td >Iron dust falls in eyes while grinding</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td ></td>
  <td >2</td>
  <td >M</td>
  <td >Complacency/Rushing</td>
  <td >Mind are not in  task</td>
  <td >Self trigger, work on habits </td>
  <td >Move your eyes before move your body, the cable to be laid in proper way</td>
  <td >October</td>
  <td >Regular</td>

</tr>
<tr >
  <td >18</td>
  <td >Electrical earth shock while grindings</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td ></td>
  <td >1</td>
  <td >H</td>
  <td >Complacency /Rushing</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Look for line of fire potential before moving / start the work</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >19</td>
  <td >Iron piece felt on his head while crossing the House keeping up area</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >yes</td>
  <td ></td>
  <td ></td>
  <td >5</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task/Mind not on task/ line of fire</td>
  <td >work on habits</td>
  <td >Move your eyes before move your body, sign board for restriction of man entry</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >20</td>
  <td >Un balance while opening a door at sulphator top</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td >1</td>
  <td >H</td>
  <td >Complacency /Rushing</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Look for things that could cause you to lose your balance, grip. </td>
  <td >October</td>
  <td >Regular</td>

</tr>
<tr >
  <td >21</td>
  <td >Slip down from A range CFL steps</td>
  <td >Yes</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >1</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Mind are not in  task</td>
  <td >Self trigger, work on habits </td>
  <td >Test your footing or grip before you commit your weight </td>
  <td >October</td>
  <td >Regular</td>

</tr>
<tr >
  <td >22</td>
  <td >Hot water flashes on leg while spraying antifoaming oil to injection channel</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >1</td>
  <td >M</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >work on habits</td>
  <td >Move your eyes before move your body, the wet area to be cleaned</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >23</td>
  <td >Hammer hit on finger while inserting a bearing</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >2</td>
  <td >M</td>
  <td >Complacency / Fatigue</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Keep your hands out of pintch points</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >24</td>
  <td >Head hit with low level tie beam</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >4</td>
  <td >M</td>
  <td >Complacency /Rushing</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >work on habits</td>
  <td >Glance up before standing up or raising your hands. (bang heads and hands etc,.) wearing PPE is mandatory</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >25</td>
  <td >Pipe sharp edge cut finger while fabrication</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td ></td>
  <td >2</td>
  <td >M</td>
  <td >Complacency /Rushing</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >work on habits</td>
  <td >Glance up before standing up or raising your hands. (bang heads and hands etc,.) wearing PPE is mandatory</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >26</td>
  <td >Electrical flash happened while charging a MCB</td>
  <td >Yes</td>
  <td >Yes</td>
  <td ></td>
  <td >Yes</td>
  <td >Yes</td>
  <td >1</td>
  <td >H</td>
  <td >Rushing / Frustration</td>
  <td >Eyes not on task/Mind not on task/ line of fire</td>
  <td >Self trigger, work on habits </td>
  <td >Look for line of fire potential before moving / start the work</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >27</td>
  <td >Cleaner boy fall down from tractor while standing on doe bar</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td ></td>
  <td >1</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task/Mind not on task/ line of fire</td>
  <td >work on habits</td>
  <td >Test your footing or grip before you commit your weight </td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >28</td>
  <td >Hot soda splashes on hand while removing hydrant hose</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td ></td>
  <td >1</td>
  <td >M</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Move your eyes before move your body,</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >29</td>
  <td >DA cylinder catch fire while cutting fabrication at distillery</td>
  <td >Yes</td>
  <td ></td>
  <td >Yes</td>
  <td >Yes</td>
  <td ></td>
  <td >1</td>
  <td >H</td>
  <td >Complacency/Rushing</td>
  <td >Eyes not on task/Mind not on task/ line of fire</td>
  <td >Analysis close calls and small errors</td>
  <td >Look for line of fire potential before moving / start the work</td>
  <td >Completed</td>
  <td >Regular</td>

</tr>
<tr >
  <td >30</td>
  <td >Ext box spanner rod slips and hand hit with working screen while dis centrifugal basket cause bleeding</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td ></td>
  <td >3</td>
  <td >M</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Look for things that could cause you to lose your balance, grip. </td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td ></td>
  <td >Udyannan</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >31</td>
  <td >Slipped while walking in fermentaion area</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >yes</td>
  <td >1</td>
  <td >M</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, Analyse close calls, work on habits</td>
  <td >Move your eyes before move your body, the wet area to be cleaned</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >32</td>
  <td >unsafe to walk near sugar weigh bridge area<br />
(no reverse horn,crossing the speed limit,not obeying the security)</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >yes</td>
  <td ></td>
  <td >10</td>
  <td >H</td>
  <td >Rushing,Frustration,Fatigue,Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits ,work on habit</td>
  <td >Move your eyes first before you move your hands, feet,body,or car</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >33</td>
  <td >Spanner slipped while tightning</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >yes</td>
  <td ></td>
  <td >1</td>
  <td >L</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Look for things that could cause you to lose your balance, grip. </td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >34</td>
  <td >Rope got cut while waking near Gas holder</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >yes</td>
  <td >1</td>
  <td >L</td>
  <td >Complacency</td>
  <td >LINE OF FIRE</td>
  <td >Analyse close calls and small errors</td>
  <td >Move your eyes before move your body, sign board for restriction of man entry</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >35</td>
  <td >Pump started while work  men was inspecting the pump.</td>
  <td >yes</td>
  <td ></td>
  <td ></td>
  <td >yes</td>
  <td ></td>
  <td >1</td>
  <td >H</td>
  <td >Rushing,Frustration,Fatigue,Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Look at others for the patterns that increase the risk of injury</td>
  <td >look carefullery at anything you are going to stick tour hand in to or rest your hand on</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >36</td>
  <td >While Hammer the plate for bending, hammer slipped and hit on his leg</td>
  <td >yes</td>
  <td ></td>
  <td ></td>
  <td >yes</td>
  <td ></td>
  <td >1</td>
  <td >L</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits ,work on habit,Analyse close calls and small errors</td>
  <td >Look carefullery at anything you are going to stick tour hand in to or rest your hand on</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >37</td>
  <td >Bush plate fell on workmens leg</td>
  <td >yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >1</td>
  <td >L</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, Analyse close calls, work on habits</td>
  <td >use tree points of contact when asending or desending.</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >38</td>
  <td >Slipped while walking in cooling tower area</td>
  <td >yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >1</td>
  <td >L</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, Analyse close calls, work on habits</td>
  <td >Move your eyes before move your body, the wet area to be cleaned</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >39</td>
  <td >contractor work men was climing 8 mts without safety belt</td>
  <td >yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >1</td>
  <td >L</td>
  <td >Complacency</td>
  <td >Line of fire</td>
  <td >work on habits</td>
  <td >Look carefulley at anything you are going to stick your hand in to or rest your hand on.</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >40</td>
  <td >Earth flat got strucked on work men leg while doing pump alignment work</td>
  <td >yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >1</td>
  <td >L</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, Analyse close calls, work on habits</td>
  <td >Move your eyes before move your body, the wet area to be cleaned</td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td ></td>
  <td >Ravichandran</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >41</td>
  <td >Excavator overturned and operatior got locked under the vehicle </td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >1</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task, <br />
Mind not on task</td>
  <td >Self trigger, <br />
Analyse close calls</td>
  <td >Training to be give the operator </td>
  <td >Immedite</td>
  <td >Given</td>

</tr>
<tr >
  <td >42</td>
  <td >Fallen in to the trench at near R-5 Molaases Tank</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >No</td>
  <td >No</td>
  <td >1</td>
  <td >L</td>
  <td >Complacency<br />
Rushing</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
  <td >Work On Habits<br />
Analyse Close Calls</td>
  <td >Self Trained and unsafe condition elimination</td>
  <td >Immedite</td>
  <td >Given</td>

</tr>
<tr >
  <td >43</td>
  <td >H2SO4 leaked from the tstorage tank and splashed over the person working near to it</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >No</td>
  <td >No</td>
  <td >1</td>
  <td >H</td>
  <td >Rushing</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
  <td >Self Trigger<br />
Analyse close calls</td>
  <td >Training required to use the PPE's and Unsafe condition to be eliminate</td>
  <td >Immedite</td>
  <td >October</td>

</tr>
<tr >
  <td >44</td>
  <td >Object fall from height on the person working at ground </td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >1</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Line Of Fire</td>
  <td >Analyse Close Calls<br />
Work On Habits<br />
Self Trigger</td>
  <td >Require training to the work men to wear the PPE's </td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >45</td>
  <td >A person fall down from ladder while check the tank level and he got bonefracture in his leg.</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >1</td>
  <td >H</td>
  <td >Complacency<br />
Rushing</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Balance / Traction / Grip</td>
  <td >Self trigger<br />
work on habits<br />
Analyse close calls</td>
  <td >Require training to the work men<br />
Technology - Proper safe access to be provided</td>
  <td >23.10.2019</td>
  <td >23.10.2019</td>

</tr>
<tr >
  <td >46</td>
  <td >A person slip from the of 4 Mt platform while attending the bio-gas pipe line repair work due to gas  inhale </td>
  <td >Yes</td>
  <td >Yes</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >1</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
  <td >Analyse close calls <br />
Self trigger, <br />
Work on habits</td>
  <td >Training to be given to all the worke<br />
Lock Out / Tag Out system to be implimented <br />
</td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >47</td>
  <td >A person leg locked between wall and MS tank while shifting the Tank for erection, near miss happened</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >1</td>
  <td >M/H</td>
  <td >Rushing <br />
Complacency</td>
  <td >Mind not on task<br />
Line of Fire<br />
Balance / Traction/ Grip</td>
  <td >Work On Habit<br />
Self Trigger<br />
Analyse Close calls</td>
  <td >Training to be given all the workers<br />
Proper tools and tackls to be used </td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >48</td>
  <td >A person got electic flash while charging the electrical panel and near miss captured</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >No</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >1</td>
  <td >H</td>
  <td >Rushing <br />
Complacency</td>
  <td >Mind not on task<br />
Line of Fire</td>
  <td >Analyse close calls <br />
Self trigger,</td>
  <td >Training to be given to use right tools and PPE's<br />
LockOut / TagOut System to be impleminted</td>
  <td >Immediate</td>
  <td >Given</td>

</tr>
<tr >
  <td ></td>
  <td >Chakaravathi</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >49</td>
  <td >Slip while walking in switchyard</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >1</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, Analyse close calls, work on habits</td>
  <td >Move your eyes before move your body, broken slabs replaced</td>
  <td >43344</td>
  <td >43374</td>

</tr>
<tr >
  <td >50</td>
  <td >Slip while washing hands near cogen PCC in Hot water</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >1</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task,Balance/Traction/Grip</td>
  <td >Self trigger, Analyse close calls, work on habits</td>
  <td >Move your eyes before move your body, broken slabs replaced</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >51</td>
  <td >EOT Sfu switch flash over -cogen</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >1</td>
  <td >H</td>
  <td >Rushing,Complacency</td>
  <td >Eyes not on task, Mind not on task,Line of fire,Balance/Traction/Grip</td>
  <td >Self trigger, Analyse close calls, work on habits</td>
  <td >Training given to the Electricans </td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >52</td>
  <td >Power capacitor bank charging &amp; Discharging</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td >0</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Work on habbits,Self trigger</td>
  <td >Earth rod to be kept while charing and discharging of capacitors </td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >53</td>
  <td >110KV Switchyard Equipment works</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >yes</td>
  <td ></td>
  <td >0</td>
  <td >H</td>
  <td >Rushing,Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self Trigger,work on habbits</td>
  <td >Earth rod to be provided before and after the equipment which is to be taken maintenance / Breakdown work</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >54</td>
  <td >Interlock system for Fuel conveyors</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >yes</td>
  <td ></td>
  <td >0</td>
  <td >H</td>
  <td >Rushing,Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self Trigger,work on habbits</td>
  <td >By-passing of Electrical interlock to be carried out with HOD/Shift incharge permission</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >55</td>
  <td >Opening of feeders in MCC</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >yes</td>
  <td ></td>
  <td >0</td>
  <td >H</td>
  <td >Rushing,Frustration ,Complacency</td>
  <td >Eyes not on task, Mind not on task,Line of fire,Balance/Traction/Grip</td>
  <td >Self trigger, Analyse close calls, work on habits</td>
  <td >Feeders to be open from side ways to avoid direct flash over to the face</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >56</td>
  <td >Unwanted power cables in trays</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >yes</td>
  <td ></td>
  <td >0</td>
  <td >H</td>
  <td >Rushing</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self Trigger,work on habbits</td>
  <td >Unwanted cable removable helps to avoid live cable identification during breakdown </td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >57</td>
  <td >Corrosive cable trays removable</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td >0</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task,Balance/Traction/Grip</td>
  <td >Self trigger, Analyse close calls, work on habits</td>
  <td >Distillery plant area GI cable trays are to replaced by FRB trays to avoid corrosion </td>
  <td >43739</td>
  <td >Regular</td>

</tr>
<tr >
  <td ></td>
  <td >Mohanraj</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >58</td>
  <td >Sliped while down from upstair at near C/F Machine</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >No</td>
  <td >No</td>
  <td >4</td>
  <td >M</td>
  <td >Complacency</td>
  <td >Eyes not on task, <br />
Mind not on task</td>
  <td >Self trigger, <br />
Analyse close calls</td>
  <td >Training &amp; Process Change ( slippey steps to be changes with anti slippery condition)</td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >59</td>
  <td >Truck Hit on the wall while taking reverse at 'B' Warehouse Entrance</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >2</td>
  <td >M</td>
  <td >Complacency<br />
Rushing</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
  <td >Work On Habits<br />
Analyse Close Calls</td>
  <td >Get Your Eyes Back on the road quickly, Traning to the drivers and Use the technology like Reverse Sensor or Camera can be fited to all the vehicles</td>
  <td >All times</td>
  <td >November</td>

</tr>
<tr >
  <td >60</td>
  <td >A Person fall from height of 5 Ft while de-rooting the plant on the wall at two wheeler parking Area</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >1</td>
  <td >L</td>
  <td >Complacency<br />
Rushing</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Balance / Traction / Grip</td>
  <td >Work On Habits<br />
Self Trigger</td>
  <td >Training required to use the roght tools for right job</td>
  <td ></td>
  <td ></td>

</tr>
<tr>
  <td >61</td>
  <td >Scrap bagged dropped from first floor- no barrguard on the ground, objects may fall on the person who passing.</td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >Yes</td>
  <td >No</td>
  <td >5</td>
  <td >H</td>
  <td >Complacency<br />
Rushing</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
  <td >Analyse Close Calls<br />
Work On Habits<br />
Self Trigger</td>
  <td >Requir training to the work men </td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >62</td>
  <td >Shifting of tile box to work location, doped one tile from the box. Near miss happen with no injury</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >1</td>
  <td >M</td>
  <td >Complacency<br />
Rushing<br />
Fatigue</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Balance / Traction / Grip</td>
  <td >Look at others<br />
work on habits<br />
Analyse close calls</td>
  <td >Requir training to the work men and the same was given all workrs on tool box talk while day commence of work</td>
  <td >23.10.2019</td>
  <td >23.10.2019</td>

</tr>
<tr >
  <td >63</td>
  <td >Forklift loading the jumbo bags to Truck and men walk over under the bag</td>
  <td >Yes</td>
  <td >Process </td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >2</td>
  <td >H</td>
  <td >Rushing <br />
Complacency</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
  <td >Self trigger, <br />
Work on habits</td>
  <td >Training to be given to forklift operator and the persons who cross under the object.<br />
Loadings should be happen on the designated places with proper barryguard.</td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >64</td>
  <td >too close gap was maintain between the stacks at sugar godowns- it may lead to majob accident to the persons who cross between the stacks.</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >8</td>
  <td >H</td>
  <td >Rushing <br />
Complacency<br />
Fatigue</td>
  <td >Mind not on task<br />
Line of File<br />
Balance / Traction/ Grip</td>
  <td >Work On Habit<br />
Self Trigger<br />
Analyse Close calls</td>
  <td >Training to be given all the loadmens, SOP to be created and followed </td>
  <td >November</td>
  <td >November</td>

</tr>
<tr >
  <td >65</td>
  <td >Not wering the Safety Helmet and Shoes in side the plant permises </td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >10</td>
  <td >H/M/L</td>
  <td >Complacency</td>
  <td >Mind not on task<br />
Line of File</td>
  <td >Self Trigger<br />
Look at Others<br />
Work on habits</td>
  <td >Training to be given who not wearing the PPE's </td>
  <td >November</td>
  <td >November</td>
</tr>
<tr >
  <td >66</td>
  <td >Exposed power cable joints </td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >5</td>
  <td >H</td>
  <td >Rushing<br />
Fatigue<br />
Complacency</td>
  <td >Line of Fire</td>
  <td >Self Trigger<br />
Analyse close calls &amp; small errors</td>
  <td >Training to be given to all the electrician's </td>
  <td >November</td>
  <td >November</td>

</tr>
<tr >
  <td >67</td>
  <td >Equipment washing by using got water - hot water splashed on self &amp; others </td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >8</td>
  <td >L / M</td>
  <td >Complacency <br />
Rushing</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
  <td >Work On Habits<br />
Analyse Close Calls</td>
  <td >Training to be given the performer </td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >68</td>
  <td >Shifting of PVC round table - part of table fell down on the person near to foot  </td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >1</td>
  <td >L</td>
  <td >Rushing </td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire</td>
  <td >Self Trigger<br />
Analyse close calls &amp; small errors</td>
  <td >Training given on the spot </td>
  <td >19.10.19</td>
  <td >23.10.19</td>

</tr>
<tr >
  <td >69</td>
  <td >HCL pump splashing the acid due to gland leak-may splase to the person work near to it</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >3</td>
  <td >H</td>
  <td >Rushing<br />
Complacency</td>
  <td >Eyes not on taks<br />
Line of fire </td>
  <td >Work On Habits<br />
Analyse Close Calls</td>
  <td >Training to be give the operator </td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >70</td>
  <td >Two type of Vendor work in the same place like ground and over head - chance of drop the object and may hit to the person working on the ground.</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >4</td>
  <td >H</td>
  <td >Rushing<br />
Complacency</td>
  <td >Eyes Not on Task<br />
Mind Not on Task<br />
Line Of Fire<br />
Balance / Traction / Grip</td>
  <td >Self Trigger<br />
Analyse close calls &amp; small errors<br />
Work on habit</td>
  <td >Training to be given the both vendors<br />
Process- Work permit should not issued on the same location to two vendors</td>
  <td >Immedite</td>
  <td >October</td>

</tr>
<tr >
  <td >71</td>
  <td >Thr truck driver binding the tarpalins on the truck to cover the sugar bags - there may be chance of falldown </td>
  <td >Yes</td>
  <td >Yes</td>
  <td >No</td>
  <td >Yes</td>
  <td >Yes</td>
  <td >10</td>
  <td >M/H</td>
  <td >Rushing<br />
Complacency</td>
  <td >Line Of Fire<br />
Balance / Traction / Grip</td>
  <td >Self Trigger<br />
Analyse close calls &amp; small errors<br />
Work on habit</td>
  <td >Training to be given the performer<br />
Process - work to be performed in allocated area and working at height safety procedure to be followd </td>
  <td >Immedite</td>
  <td >November</td>

</tr>
<tr >
  <td >72</td>
  <td >Electric stacker stacking the 1MT jumbo bags on the Racks at Refinery - operator misplaced on the rack</td>
  <td >Yes</td>
  <td >No</td>
  <td >No</td>
  <td >Yes</td>
  <td >No</td>
  <td >3</td>
  <td >H</td>
  <td >Rushing<br />
Complacency<br />
Fatigue</td>
  <td >Eyes Not on Task<br />
Line Of Fire<br />
Balance / Traction / Grip</td>
  <td >Self Trigger<br />
Analyse close calls &amp; small errors<br />
Work on habit</td>
  <td >Training to be given to the operator</td>
  <td >Immedite</td>
  <td >November</td>

</tr>
<tr >
  <td ></td>
  <td >WARE HOUSE</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td ></td>
  <td >Hubert selvaraj /V.Rajendran/K.P.Jayaraman</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td ></td>

</tr>
<tr >
  <td >1</td>
  <td >Fork lift 1 mt jumbo bags loading time Slip while  in floor wet contidion  in warehouse  c bay </td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >yes</td>
  <td >2</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, Analyse close calls, work on habits</td>
  <td >Move your eyes before move your body, the wet area to be cleaned &amp; water washing  after rainny seaon </td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >2</td>
  <td >Conveyor movement one godown to another godown for lot changing time (S30 50 kg to 25 kg  bagging) conveyor wheet hit on right leg  minor injury </td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td ></td>
  <td >yes</td>
  <td ">2</td>
  <td ">M</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Move your eyes before move your work  area floor condtion and running time very carefully hadnled and safely worked </td>
  <td >October</td>
  <td >November</td>

</tr>
<tr >
  <td >3</td>
  <td >Sugar bags Dispatch time  slipped while destacking time some bags are improper handling bags in hit on ankle &amp; foot </td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >yes</td>
  <td ></td>
  <td >3</td>
  <td >L</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Look for things that could cause you to lose your balance, grip </td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >4</td>
  <td >Rope got cut while  Lorry un loading  time F godown  refeinry bagging time  </td>
  <td >Yes</td>
  <td ></td>
  <td >yes</td>
  <td ></td>
  <td ></td>
  <td >2</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >work on habits</td>
  <td >Move your eyes before move your body, sign board for restriction of man entry</td>
  <td >Immediate</td>
  <td >Shift once</td>

</tr>
<tr >
  <td >5</td>
  <td >Sugar bags  Stiching time  slipped while  left in front of  chain gurd  are improper handling in sugar bags stiching </td>
  <td >Yes</td>
  <td ></td>
  <td ></td>
  <td >yes</td>
  <td ></td>
  <td >3</td>
  <td >L</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Look for things that could cause you to lose your balance, grip </td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >6</td>
  <td >Jumbo bag holding  by hand</td>
  <td >Yes</td>
  <td ></td>
  <td >Yes</td>
  <td >Yes</td>
  <td >yes</td>
  <td >2</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger,  work on habits</td>
  <td >Look carefully atanything you are going to stick your hand  in to or rest your hand on</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >7</td>
  <td >Sugar filling in jumbo bag </td>
  <td >Yes</td>
  <td ></td>
  <td >Yes</td>
  <td >Yes</td>
  <td >yes</td>
  <td >2</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Look carefully atanything you are going to stick your hand  in to or rest your hand on</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >8</td>
  <td >Sugar bag  striching </td>
  <td >Yes</td>
  <td ></td>
  <td >Yes</td>
  <td >yes</td>
  <td >Yes</td>
  <td >3</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Look carefully atanything you are going to stick your hand  in to or rest your hand on</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >9</td>
  <td >Sugar bag conveying </td>
  <td >Yes</td>
  <td ></td>
  <td >yes</td>
  <td >yes</td>
  <td >yes</td>
  <td >2</td>
  <td >H</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >work on habits</td>
  <td >Move your eyes before move your body</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >10</td>
  <td >Film roll falling in retail</td>
  <td >Yes</td>
  <td >No</td>
  <td >Yes</td>
  <td >yes</td>
  <td >No</td>
  <td >1</td>
  <td >M</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Look for things that could cause you to lose your balance, grip </td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >11</td>
  <td >Exposue to hot jaw  in retail bagging </td>
  <td >Yes</td>
  <td >No</td>
  <td >Yes</td>
  <td >yes</td>
  <td >No</td>
  <td >1</td>
  <td >M</td>
  <td >Complacency</td>
  <td >Eyes not on task, Mind not on task</td>
  <td >Self trigger, work on habits </td>
  <td >Keeo your hand out of pinch points</td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>
<tr >
  <td >12</td>
  <td >30kg pouch weighing </td>
  <td >yes</td>
  <td >no</td>
  <td >yes </td>
  <td >yes </td>
  <td >yes</td>
  <td >3</td>
  <td >M</td>
  <td >Fatigue</td>
  <td >mind not on task </td>
  <td >Self trigger, work on habits </td>
  <td >Look for things that could cause you to lose your balance, grip </td>
  <td >Immediate</td>
  <td >Regular</td>

</tr>



    </table>`,
      `
      ${style}

      <table>
      <tr><th>PROCESS FLOW</th></tr>
      <tr>
      <td>Cane Preparation </td></tr>
      <tr><td>Milling</td></tr>
      <tr><td>Clarification</td></tr>
     <tr> <td>Evaporation</td></tr>
      <tr><td>Crystallization </td></tr>
     <tr> <td>Centralization</td></tr>
      <tr><td>SugarDry</td></tr>
      <tr><td>Grading</td></tr>
      <tr><td>Quality</td></tr>
      <tr><td>Packing</td>

      </tr>




    </table>`
    ]
  };
  openModal = n => {
    document.getElementById("myModal").style.display = "block";
  };
  closeModal = () => {
    document.getElementById("myModal").style.display = "none";
  };
  view = () => {
    this.setState({ show: true });
  };
  hideView = () => {
    console.log("hide");
    this.setState({ show: false });
  };
  loadScript = src => {
    this.setState({ loaded: false });
    return <ScriptTag type="text/javascript" src={src} />;
  };
  render() {
    let state = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-margin-top">
          <div className="col-sm-12">
            <div className="row form-group">
              <div className="card">
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-12" style={{ marginBottom: '2%' }}>
                      <div className="row ">
                        <div className="col-sm-1">
                          <img
                            style={{ marginTop: 10 }}
                            src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/EIDPLogo_1582715870766.jpg`}
                          />
                        </div>
                        <div className="col-sm-10">
                          <h2
                            className="theme-tiile text-capitalize"
                            style={{
                              marginLeft: "0px",
                              float: "left",
                              marginTop: "24px"
                            }}
                          >
                            EID Parry, Nelikuppam
                          </h2>
                        </div>
                        <div className="col-sm-2"></div>
                      </div>
                    </div>

                    <div className="col-sm-12">
                      <div style={{ marginBottom: '2%' }}>
                        <LoginModal
                          buttonTitle="Gallery"
                          title="EID Parry, Nelikuppam"
                          id="we"
                          indexStyle={{ zIndex: 99999 }}
                          maxwidth="modal-lg"
                          bodyText={
                            <div>
                              <section className="carousel-container">
                                <img
                                  src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/EIDPLogo1_1582716905982.jpg`}
                                  alt=""
                                  className="current-image"
                                />
                                <span className="arrow arrow-left"> Prev </span>
                                <ul className="next-list">
                                  <li>
                                    <img
                                      src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/EIDPLogo1_1582716905982.jpg`}
                                      alt=""
                                      className="image-of-list current-image-list"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/IMG_20191014_131140_1582718049515.jpg`}
                                      alt=""
                                      className="image-of-list"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/IMG_3_1582637362524.jpg`}
                                      className="image-of-list"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/IMG_20191024_125638_1582718128041.jpg`}
                                      alt=""
                                      className="image-of-list"
                                    />
                                  </li>
                                </ul>
                                <span className="arrow arrow-right"> Next </span>
                              </section>
                              <ScriptTag type="text/javascript" src={js1} />
                            </div>
                          }
                        />
                      </div>
                      <div>
                        <h3>EID Parry, Nelikuppam</h3>
                        <br />
                        <p>
                          With EID Parry, Nelikuppam we facilitated the 5
                          SafeStart core units for 25 Senior executives of their
                          Management Team on following dates.
                        </p>
                        <br />
                        <table >
                          <tr>
                            <th colspan="2">Units</th>
                            <th>Dates</th>
                          </tr>
                          <tr>
                            <td rowSpan="6">
                              5 SafeStart
                              <br /> Core Units for
                              <br /> MG Staff
                            </td>
                            <td>Unit 1</td>
                            <td>20-Aug 2019</td>
                          </tr>
                          <tr>
                            <td>Unit 2</td>
                            <td>27-Aug 2019</td>
                          </tr>
                          <tr>
                            <td>Unit 3</td>
                            <td>17-Sep 2019</td>
                          </tr>
                          <tr>
                            <td>Safety Health Check</td>
                            <td>14-Oct 2019</td>
                          </tr>
                          <tr>
                            <td>Unit 4</td>
                            <td>24-Oct 2019</td>
                          </tr>
                          <tr>
                            <td>Unit 5</td>
                            <td>12-Nov 2019</td>
                          </tr>
                        </table>
                        <br />
                        <h3>Safety Health Check</h3>
                        <br />
                        <p>
                          As a part of this intervention, the executives were
                          asked to carry out a Safety Health Check on the shop
                          floor, first within their functions and then cross-
                          functionally to observe and note, manual interventions
                          where safety is compromised due to human factors.
                        </p>
                        <br />
                        <p>The observations are being shown here </p>
                      </div>
                      <br />

                      <label style={{ fontWeight: 600, marginLeft: "3px" }}>
                        EID Cross Fuxnal Safety Health Check :
                      </label>

                      <ul class="nav nav-tabs" role="tablist">
                        {state.tabTitle.map((ival, i) => {
                          return (
                            <li class="nav-item">
                              <a
                                class={`nav-link ${i === 0 ? "active" : ""}`}
                                data-toggle="tab"
                                href={`#home1${i}`}
                              >
                                {ival}
                              </a>
                            </li>
                          );
                        })}
                      </ul>

                      <div class="tab-content" style={{ paddingRight: "23px" }}>
                        {state.tabContent.map((ival, i) => {
                          return (
                            <div
                              id={`home1${i}`}
                              class={`container tab-pane ${
                                i === 0 ? " active" : ""
                                }`}
                              style={{
                                overflowX: "auto",
                                margin: "0px",
                                marginTop: "12px"
                              }}
                            >
                              <p className="content-para">
                                {renderHTML(ival ? ival : "")}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      <br />
                      <br />
                      <label style={{ fontWeight: 600, marginLeft: "3px" }}>
                        Function-wise EID Safety Health Check :
                      </label>
                      <br />
                      <br />
                      <ul class="nav nav-tabs" role="tablist">
                        {state.tabTitle.map((ival, i) => {
                          return (
                            <li class="nav-item">
                              <a
                                class={`nav-link ${i === 0 ? "active" : ""}`}
                                data-toggle="tab"
                                href={`#home2${i}`}
                              >
                                {ival}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                      <div class="tab-content" style={{ paddingRight: "23px" }}>
                        {state.tabContent1.map((ival, i) => {
                          return (
                            <div
                              id={`home2${i}`}
                              class={`container tab-pane ${
                                i === 0 ? " active" : ""
                                }`}
                              style={{
                                overflowX: "auto",
                                margin: "0px",
                                marginTop: "11px"
                              }}
                            >
                              <p className="content-para">
                                {renderHTML(ival ? ival : "")}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                      {/* <div className="row form-group">
                    <h2 className="theme-tiile text-capitalize">{pageList.name}</h2>
                  </div>
                  <p style={{ color: '#828282', fontSize: 14, lineHeight: 2, fontWeight: 400, marginTop: 0, paddingTop: 0 }}>
                    {'Last Updated  :  '}
                    {date}
                  </p>
                  <br />
                  {(pageList.sheetContent && pageList.sheetContent.length) ? this.loadContent(pageList.sheetContent) : null}
                  <br />
                  <div className="blog-post-author d-flex">
                    <div className="author-thumbnail">
                      <img src={pageList.authorimage} alt="" />
                    </div>
                    <div className="author-info">
                      <a className="author-name">
                        {pageList.authorname}, <span>The Author</span>
                      </a>
                      <p>{pageList.authorabout}</p>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card">
                {/* <div className="card-body">
                  <RightPanel />
                </div> */}
              </div>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-sm-12 mb-new">
              <div className="card">
                {/* <div className="card-body px-0">
                  <DownPanel />
                </div> */}
              </div>
            </div>
          </div>
          {this.state.loaded ? this.loadScript(js1) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default SheetContent;
