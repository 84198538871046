
import React from "react";
import customFont from "./ttf_files/Montserrat-SemiBold_72e0d276db1e5baa6f2b4caddfef5b93.ttf"
import ptsans from "./ttf_files/pt-sans.narrow.ttf"

// import logo1 from './m.png'
import {
    Image,
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
    PDFViewer
} from "@react-pdf/renderer";
import url from "socket.io-client/lib/url";

const MomPdf = ({
    data
    // logosrc
}) => {
    // console.log("enteree");
    console.log(data, "dtaaaa");

    // var imagelogo = group[0].image
    // const groupcompId = localStorage.getItem("groupcompId");

    // console.log(data, "data");
    // console.log(short, "short", mid, "mid", long, "long");
    // console.log(section1, "section1", section2, "section2", section3, "section3", group, "group");
    return (
        <Document>
            <Page size="A4" style={s.body} >
                {/* <Image
          style={s.logo}
          src={groupcompId == "52" ? wexel : groupcompId == "24" ? logo :
            `https://compexp.difuza.com/getpro/getprofile?fileurl_1=` +
            imagelogo
          }
          fixed
        /> */}

                <View style={{
                    //   marginLeft: 30,
                    //   marginRight: 30, marginTop: 30
                }}>
                    {/* <Text style={[s.head, { fontFamily: 'CustomFont' }]}>{data.discussion}</Text> */}

                    <Text style={[s.head, { fontFamily: 'CustomFont' }]}>MINUTES OF MEETING                     <Text style={[s.head, { fontFamily: 'CustomFont' }]}>{data.userName}</Text></Text>
                </View>
                <View style={
                    [s.section, s.st]}
                >
                    <Text style={s.para}>
                        Date :  <Text style={[s.head, { fontFamily: 'ptsans' }]}>{data.date}</Text>
                    </Text>
                    {/* <Text style={s.para}>  </Text> */}
                    <Text style={s.para}  >
                        Time : <Text style={[s.parag1, { fontFamily: 'ptsans' }]}>{data.startTime} to {data.endTime}</Text>
                    </Text>
                    <Text style={s.para}>  </Text>
                    <Text style={s.para}  >
                        Attendees : <Text style={[s.parag1, { fontFamily: 'ptsans' }]}>{data.attendees}</Text>
                    </Text>
                    {/* <Text style={s.para}>  </Text> */}
                    <Text style={s.para}  >
                        Topic : <Text style={[s.parag1, { fontFamily: 'ptsans' }]}>{data.topicName}</Text>
                    </Text>
                    {/* <Text style={s.para}>  </Text> */}
                    <Text style={s.para}  >
                        Description  : <Text style={[s.parag1, { fontFamily: 'ptsans' }]}>{data.description}</Text>
                    </Text>
                    <Text style={s.para}>  </Text>
                    <Text style={s.para}  >
                        Discussion :

                    </Text>
                    <Text style={s.para}  >
                        <Text style={[s.parag1, { fontFamily: 'ptsans' }]}>{data.discussion.map((ival, index) => {
                            return (
                                <Text key={index} style={s.listItem}>
                                    {'\u2022'} {ival}{'\n'}
                                </Text>

                            )

                        })}</Text></Text>
                    <Text style={s.para}>  </Text>
                    <Text style={s.para}  >
                        Notes : <Text style={[s.parag1, { fontFamily: 'ptsans' }]}>{data.notes}</Text>
                    </Text>
                </View>

                <View style={
                    [s.section, s.st]}
                >

                    <Text style={s.para2}>
                        {" "}
                    </Text>
                    {/* <Text style={s.para2}>
                        {" "}
                    </Text> */}
                    <Text style={s.para} >{data.userName}</Text>
                    <Text style={[s.parag1, { marginLeft: 0, fontFamily: 'ptsans' }]} >
                        Student Counselor
                    </Text>
                    <Text style={[s.parag1, { marginLeft: 0, fontFamily: 'ptsans' }]}>
                        Dr. Latha Girish Phd.,
                    </Text>
                    <Text style={[s.parag1, { marginLeft: 0, fontFamily: 'ptsans' }]}>
                        Senior Consultant Clinical Psychologist
                    </Text>
                    <Text style={[s.parag1, { marginLeft: 0, fontFamily: 'ptsans' }]}>
                        RCI A12931
                    </Text>
                </View>
                {/* </Page> */}

            </Page>
        </Document>
    );
};


Font.register({ family: 'CustomFont', src: customFont });
Font.register({ family: 'ptsans', src: ptsans });



const s = StyleSheet.create({


    listItem: {
        marginBottom: 5,
        fontFamily: 'ptsans',
        marginLeft: 5
    },
    row: {
        flexDirection: "row"
    },
    column: {
        flexDirection: "column"
    },
    section: {
        position: "relative",
        margin: 10,
        padding: 0,
        flexGrow: 1
    },

    list1: {
        listStyleType: "circle"
    },
    para: {
        // textIndent: "2",
        textAlign: "justify",
        letterSpacing: "1",
        fontSize: 12,
        marginTop: 10,
    },
    para2: {
        // textIndent: "2",
        textAlign: "justify",
        letterSpacing: "1",
        fontSize: 13,
        marginTop: 5,
        // fontWeight:'bold',
        // src:Montserrat
        // fontFamily: "Montserrat"
        fontFamily: 'CustomFont'
    },
    para3: {
        letterSpacing: "1",
        fontSize: 12,
        marginTop: 10,
        fontWeight: "bold",
        textAlign: "center",


    },
    para2next: {
        textAlign: "justify",
        letterSpacing: "1",
        fontSize: 12,
        marginTop: 10,
        marginLeft: 30,
        fontFamily: 'ptsans'
    },
    parag1: {
        // textIndent: "2",
        textAlign: "justify",
        lineHeight: "4px",
        // letterSpacing: "1",
        fontSize: 12,
        marginLeft: 30,
        marginTop: 10,
        // src:ptsans
        // fontFamily:'ptsansfamily'
        fontFamily: 'ptsans'

    },
    parabreak: {
        // textIndent: "2",
        textAlign: "justify",
        // letterSpacing: "1",
        fontSize: 12,
        marginLeft: 30,
        marginTop: "15mm",
    },
    paragg2: {
        // textIndent: "2",
        textAlign: "justify",
        // letterSpacing: "1",
        fontSize: 12,
        marginLeft: 50,
        marginTop: 10,
        // src:ptsans
        // fontFamily:'ptsansfamily'
        fontFamily: 'ptsans'

    },
    color: {
        color: "#2D5986",
        fontSize: 10,
        width: "25%",
        marginLeft: 75
    },
    d: {
        fontSize: 10,
        width: "25%",
        marginLeft: -45
    },
    dot: {
        fontSize: 10,
        width: "1%",
        marginLeft: 20
    },
    border: {
        borderBottomColor: "black",
        borderBottomWidth: 1,
        paddingBottom: 123,
        marginBottom: 123
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        fontFamily: "Oswald"
    },
    head: {
        // marginTop: "35px",
        textAlign: "center",
        fontWeight: 200,
        color: "black",
        fontSize: 13,
        lineHeight: 1.3,
        marginBottom: "10px",
        // backgroundColor: "#666666",
        marginLeft: 10,
        marginRight: 10,
        fontFamily: 'CustomFont'
        // src: Montserrat,
        // fontFamily: "Montserrat",




    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey"
    }, logo: {
        top: "3px",
        left: "450px",
        width: "10%"
    },
    body: {
        paddingTop: 50,
        paddingBottom: 65,
        paddingHorizontal: 2,
    },
    breaking: {
        height: "100%",
        padding: "100%"
    },
    page: {
        width: "210mm",
        minHeight: "297mm",

    },
    rowdata: {
        position: "relative",
        margin: 8,
        padding: 0,
        flexGrow: 1,
        fontSize: 10,
        marginLeft: 150,
        top: 5
    },
    rowdata1: {
        position: "relative",
        margin: 8,
        padding: 0,
        top: 5,
        flexGrow: 1,
        fontSize: 15,
        marginLeft: 150
    },
    rowdata4: {
        position: "relative",
        margin: 8,
        padding: 0,
        flexGrow: 1,
        fontSize: 10,
        top: 7,
        marginLeft: 150
    },
    rowdata2: {
        position: "relative",
        margin: 8,
        padding: 0,
        flexGrow: 1,
        fontSize: 8,
        top: 5,
        marginLeft: 150
    },
    subdata: {
        position: "relative",
        margin: 7,
        padding: 0,
        flexGrow: 1,
        color: "#2D5986",
        fontSize: 10,
        width: "35%"
    },
    subdata1: {
        position: "relative",
        margin: 7,
        marginLeft: 10,
        // marginTop:'23px',
        padding: 0,
        flexGrow: 1,
        color: "red",
        fontSize: 10,
        width: "35%",
        top: 10
    },
    top: {
        marginTop: -130,
        paddingTop: 0
    },
    derailers: {
        marginTop: "15px",
        color: "red",
        // color: "#2D5986",
        fontSize: 10,
        width: "30%",
        marginLeft: "35px",
        textAlign: "center"
    },
    derailers1: {
        marginTop: "15px",
        color: "red",
        // color: "#2D5986",
        fontSize: 10,
        width: "70%",
        marginLeft: "140px"
        // textAlign: "center"
        // marginLeft: "35px",
        // marginTop: "15px",
        // color: "red",
        // fontSize: 10,
        // width: "70%",
        // textAlign: "center"
        // wordWrap: "break-word"
    },
    derailers_under: {
        marginTop: "15px",
        color: "black",
        // color: "#2D5986",
        fontSize: 10,
        width: "50%",
        marginLeft: "15px"
    },
    derailers1_under: {
        // marginLeft: "px",
        marginTop: "15px",
        color: "black",
        fontSize: 10,
        width: "70%",
        textAlign: "justify"
        // wordWrap: "break-word"
    },
    image: {
        height: 50,
        width: 50,
        borderRadius: 100,
        top: 65,
        left: 18
    },
    partOne: {
        width: "25 %"
    },
    partTwo: {
        width: "75 %"
    },
    topHead: {
        textAlign: "center",
        fontWeight: 700,
        top: -30,
        color: "white",
        fontSize: 12,
        backgroundColor: "gray",
        marginLeft: 10,
        marginRight: 10
    },
    idpHead: {
        width: "12.5%",
        fontSize: 10,
        color: "#2D5986"
    },
    idpdata: {
        width: "12.5%",
        fontSize: 10
    },
    rowDate: {
        position: "absolute",
        fontSize: 9,
        width: "12.5%",
        flexGrow: 3,
        marginLeft: 10
    },
    fullrow: {
        position: "absolute",
        fontSize: 9,
        width: "80%",
        flexGrow: 3
    },
    section_2: {
        position: "relative",
        margin: 10,
        padding: 0,
        flexGrow: 3
    },
    section_3: {
        position: "relative",
        margin: 10,
        padding: 0,
        flexGrow: 3,
        width: "80%",
        marginLeft: 30
    },
    rowdata5: {
        position: "relative",
        margin: 8,
        padding: 0,
        flexGrow: 1,
        fontSize: 15,
        marginLeft: 150
    },
    width_20: {
        width: "20%",
        fontSize: 10,
        color: "red"
    },
    width_70: {
        width: "70%",
        fontSize: 10
    },
    width_10: {
        width: "10%",
        fontSize: 10,
        marginLeft: 20
    },

    st: { marginLeft: 60, marginRight: 60, marginTop: 40 },

});
const styles = StyleSheet.create({
    row: {
        flexDirection: "row"
    },
    column: {
        flexDirection: "column"
    },
    section: {
        position: "relative",
        margin: 10,
        padding: 0,
        flexGrow: 1
    },
    idpHead: {
        width: "14.3%",
        fontSize: 10,
        color: "#2D5986",
        borderRightColor: '#000',
        borderRightWidth: 1,
        textAlign: 'center',
        paddingTop: '5px',
        paddingBottom: '5px'

    },
    idpdata: {
        width: "12.5%",
        fontSize: 10
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
        // padding: '4px'
    },
    row: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        width: 'auto'
    },
    cell: {
        width: "14.3%",
        fontSize: 10,
        color: "#000",
        borderRightColor: '#000',
        borderRightWidth: 1,
        // width: 'auto',
        borderRightColor: '#000',
        borderRightWidth: 1,
        textAlign: 'center',
        paddingTop: '5px',
        paddingBottom: '5px',
        paddingLeft: '5px',
        paddingRight: '3px'

        // height: 'auto'
    },

})

export default MomPdf;
