import React, { Component } from "react";
import { Link } from "react-router-dom";

class DifuzaUserNav extends Component {
  // async componentWillUnmount() {
  //   window.top.location.href = "";
  // }
  render() {
    let serviceId = localStorage.getItem("currentService");

    return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <li className="nav">
            <li className="nav-item">
              <Link className="nav-link" to={`www.amazon.com`}>
                <i className="nav-icon icon-speedometer" />
                <span>Service Dashboard</span>
              </Link>
            </li>

          
            <li className="nav-item">
              <Link className="nav-link" to="#">
                <i className="nav-icon fa fa-flag-checkered" />
                <span className="link-white">Logout</span>
              </Link>
            </li>
          </li>

        </nav>
      </div>
    );
  }
}
export default DifuzaUserNav;
