import React, { Component } from "react";
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import LoginModal from "../../../components/Modal/Modal";
import { Alert } from "reactstrap";
import CustomEvent from './CustomEvent';
import { MultiSelect, SingleSelect } from "../../../components/Form";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';



const localizer = momentLocalizer(moment)
// import { ACCESS_SAMPLE } from "../../../config";
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import { CSVLink, CSVDownload } from "react-csv";
// import AddpagePDF from "../../../components/PDF/Addpagespdf";

// //import Theme9 from './addPages/theme9';
// import Datatable from "../../../components/Datatable/Datatable";
// import { confirmAlert } from "react-confirm-alert";


class Calender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            authorlist: [],
            checked: 0,
            customerId: localStorage.getItem("userId"),
            userType: JSON.parse(localStorage.getItem("userlog")).userType,
            errorPageName: null,
            data: [],
            alertVisible1: false,
            btnDisable: false,
            formAlert: false,
            formBtn: false,
            themeSelected: "",
            screen: window.screen.height,
            danger: "btn btn-danger",
            success: "btn btn-success",
            home: "Home",
            set: "SetusHome",
            formAlertdelete: false,
            events: [],
            modalInput: "",
            newEvent: {
                title: '',
                start: '',
                end: '',
                schoolname: "",
                allDay: false,
                venue: "",
                department: ''
            },
            view: 'month',
            titleerr: "",
            DepartmentOptions: [],
            schdulEvents: [],
            topicData: []
        };
        this.divRef = React.createRef();
    }
    column1 = [
        {
            Header: "ID ",
            accessor: "id",
        },
        {
            Header: "SCHOOL NAME",
            accessor: "schoolname",
        },
        {
            Header: "TOPIC",
            accessor: "title",
        },
        {
            Header: "DEPARTMENT",
            accessor: "department",
        },
        {
            Header: "VENUE",
            accessor: "venue",
        },
        {
            Header: "DATE",
            accessor: "date",
        },
    ];

    async componentDidMount() {
        this.Mainfun()
    }
    Mainfun = async () => {
        try {
            this.setState({ loading: true })
            const GetOpts = await CmsContent.GetCmdlDatas(this.state.customerId, "SchoolReport")
            console.log(GetOpts, "schooloption")
            if (GetOpts.SchoolData) {
                await this.setState({ schoolopt: GetOpts.SchoolData });
            }
            const Department = await CmsContent.getFreedom(
                "id as value, department_name as label",
                "psychol_department",
                `id`,
                "id",
                "id"
            );
            if (Department) {
                this.setState({ DepartmentOptions: Department.data })
            }
            const topic = await CmsContent.getFreedom(
                "id as value, topic_name as label",
                "psychol_topic",
                `id`,
                "id",
                "id"
            );
            if (topic) {
                this.setState({ TopicOption: topic.data })
            }
            const getEvents = await CmsContent.getFreedom(
                "Psychol_schedule.schoolname,Psychol_schedule.title,Psychol_schedule.start,Psychol_schedule.end,Psychol_schedule.venue,Psychol_schedule.department",
                "Psychol_schedule",
                "id",
                "id",
                "id DESC"
            )
            console.log(getEvents.data, "getEvents");
            if (getEvents && getEvents.data) {
                getEvents.data.map((ival) => {
                    ival.start = new Date(ival.start)
                    ival.end = new Date(ival.end)
                })
                this.setState({ events: getEvents.data })
            }

            this.setState({ loading: false });

        } catch (error) {
            console.log(error);
        }
    }
    handleInputChange = (e, type, fieldName) => {
        this.setState({ errorSchoolname: "", titleerr: '', errorstdate: "", errorenddate: "", errorvenue: "", errorDepartment: "" })
        console.log(e, "e");
        console.log(type, "type");
        if (type == "Multi") {
            this.setState((prevState) => ({
                newEvent: {
                    ...prevState.newEvent,
                    [fieldName]: e,
                },
            }));
        } else if (type == "schdule") {
            this.setState({ [fieldName]: e, });
        } else {
            const { name, value } = e.target;
            this.setState((prevState) => ({
                newEvent: {
                    ...prevState.newEvent,
                    [name]: value,
                },
            }));
        }

    };
    handleSchduleInput = (e, type, fieldName) => {
        this.setState({ errorSchoolname: "", titleerr: '', errorstdate: "", errorenddate: "", errorvenue: "", errorDepartment: "" })
        console.log(e, "e");
        console.log(type, "type");
        if (type == "schdule") {
            this.setState({ [fieldName]: e, });
        } else {
            const { name, value } = e.target;
            this.setState({ [name]: value, });

        }

    };


    handleAddEvent = async () => {
        const { title, start, end, allDay, schoolname, venue, department, } = this.state.newEvent;
        const { topicData } = this.state
        if (!schoolname) {
            this.setState({ errorSchoolname: "Select the School name" })
            return false
        }
        if (!department) {
            this.setState({ errorDepartment: "Select the Department" })
            return false
        }
        if (topicData && topicData.length <= 0) {
            this.setState({ titleerr: "Enter the topic name" })
            return false
        }
        if (!venue) {
            this.setState({ errorvenue: "Enter the venue" })
            return false
        }
        if (!start) {
            this.setState({ errorstdate: "Select the start date" })
            return false
        }
        if (!end) {
            this.setState({ errorenddate: "Select the end date" })
            return false
        }

        else {

            try {
                const labeles = topicData.map(topic => topic.label).join(', ')
                const newEvent = {
                    title: labeles,
                    start: new Date(start),
                    end: new Date(end),
                    schoolname: schoolname.label,
                    customerid: schoolname.value,
                    venue: venue,
                    department: department.label
                };
                console.log(newEvent, " end newEvent");
                const result = await CmsContent.addMaster(
                    "Psychol_schedule",
                    newEvent
                );
                if (result) {
                    this.setState({
                        newEvent: { title: '', start: '', end: '', schoolname: '', allDay: false, venue: "", department: "" },
                        topicData: [],
                        btnDisable: false,
                        alertVisible: true,
                        badgeText: "Successfully event added ",
                        badgeColor: "success"
                    });
                    this.Mainfun()
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                        // window.location.reload()
                    }, 3000);
                }

            } catch (error) {
                console.log(error);
            }
        }


    };
    submitSchdule = async () => {
        const { startDate, endDate, schoolname } = this.state;

        try {
            // console.log(fromDate, toDate, schoolData,)
            let obj = {}
            // obj.schoolname = schoolname.value
            obj.start = startDate
            obj.end = endDate

            console.log(obj, "schdule object")
            const submit = await CmsContent.getSchduleDate(obj)
            console.log(typeof (submit), "submit", submit);

            if (submit && submit.length) {
                await submit.map((ival) => {
                    ival.date = moment(ival.start).format('DD-MM-YYYY')
                })
                console.log(submit, "submit after");
                await this.setState({ schdulEvents: submit, data: submit, submit: false })
            } else {
                console.log("enter");
                this.setState({ submit: true, schdulEvents: [] })
            }
            // return false


            // setTimeout(() => {
            //     this.setState({ loading: false })
            // }, 3000);

        }
        catch (error) {
            console.log(error)
        }
    }
    handleViewChange = (view) => {
        this.setState({ view });
    };


    getAllBorders = () => {
        return {
            top: { style: "thin", color: { auto: 1 } },
            right: { style: "thin", color: { auto: 1 } },
            bottom: { style: "thin", color: { auto: 1 } },
            left: { style: "thin", color: { auto: 1 } }
        };
    };

    applyStyles = (ws, sheetData, startRow) => {
        const titleStyle = {
            font: { sz: 14, bold: true, color: { rgb: "000000" } },
            alignment: { horizontal: 'center' },
            border: this.getAllBorders()
        };
        const headerStyle = {
            font: { sz: 12, bold: true, color: { rgb: "000000" } },
            alignment: { horizontal: 'center' },
            border: this.getAllBorders()
        };
        const cellStyle = { border: this.getAllBorders() };

        // Apply title style
        for (let i = startRow; i < startRow + 3; i++) {
            for (let j = 0; j <= 11; j++) {
                const cell = ws[XLSX.utils.encode_cell({ r: i, c: j })];
                if (cell) {
                    cell.s = titleStyle;
                }
            }
        }

        // Apply header style
        for (let j = 0; j <= 3; j++) {
            const cell = ws[XLSX.utils.encode_cell({ r: startRow + 3, c: j })];
            if (cell) {
                cell.s = headerStyle;
            }
        }

        // Apply cell style to all cells
        for (let i = startRow + 4; i < sheetData.length + startRow; i++) {
            for (let j = 0; j < sheetData[i - startRow].length; j++) {
                const cell = ws[XLSX.utils.encode_cell({ r: i, c: j })];
                if (cell) {
                    cell.s = cellStyle;
                }
            }
        }
    };

    groupBySchoolName = (data) => {
        return data.reduce((acc, item) => {
            if (!acc[item.schoolname]) {
                acc[item.schoolname] = [];
            }
            acc[item.schoolname].push(item);
            return acc;
        }, {});
    };

   
    //     const { data } = this.state;

    //     if (!data || data.length === 0) {
    //         alert('No data available to export');
    //         return;
    //     }

    //     const workbook = XLSX.utils.book_new();
    //     const groupedData = this.groupBySchoolName(data);

    //     Object.keys(groupedData).forEach((schoolname) => {
    //         const schoolData = groupedData[schoolname];
    //         const ws = XLSX.utils.aoa_to_sheet([]);
    //         let startRow = 0;

    //         const firstData = schoolData[0];
    //         if (!firstData) return;

    //         const startDate = new Date(firstData.start);
    //         const currentMonth = startDate.getMonth() + 1;

    //         const monthNames = [
    //             'January', 'February', 'March', 'April', 'May', 'June', 'July',
    //             'August', 'September', 'October', 'November', 'December'
    //         ];
    //         const currentMonthName = monthNames[currentMonth - 1];

    //         const startDateFormatted = startDate.toLocaleDateString('en-GB');
    //         const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
    //         const endDateFormatted = endDate.toLocaleDateString('en-GB');

    //         const schoolName = firstData.schoolname || 'School Name Not Available';

    //         const headers = [
    //             ['VELAMMAL KNOWLEDGE PARK -CENTRAL OFFICE '],
    //             [`SCHOOL NAME - ${schoolName}`, '', '', '', '', '', '', '', '', `DATE: ${startDateFormatted} - ${endDateFormatted}`],
    //             [`COUNSELLING SCHEDULE - ${currentMonthName.toUpperCase()} (GROUP AND INDIVIDUAL)`],
    //             ['S. NO', 'GRADE AND DEPARTMENT', 'DATE OF COUNSELLING', 'TOPIC COVERED IN GROUP COUNSELLING']
    //         ];

    //         XLSX.utils.sheet_add_aoa(ws, headers, { origin: startRow });
    //         startRow += headers.length;

    //         const sheetData = schoolData.map((row, index) => ([
    //             index + 1,
    //             row.department,
    //             row.date,
    //             row.title
    //         ]));

    //         XLSX.utils.sheet_add_aoa(ws, sheetData, { origin: startRow });

    //         ws['!merges'] = (ws['!merges'] || []).concat([
    //             { s: { r: 0, c: 0 }, e: { r: 0, c: 11 } },
    //             { s: { r: 1, c: 0 }, e: { r: 1, c: 10 } },
    //             { s: { r: 2, c: 0 }, e: { r: 2, c: 11 } },
    //             { s: { r: 3, c: 3 }, e: { r: 3, c: 11 } },

    //         ]);
    //         ws['!cols'] = [
    //             { width: 5 }, 
    //             { width: 20 }, 
    //             { width: 15 }, 
    //             { width: 40 } 
    //         ];

    //         ws['!rows'] = [
    //             { hpx: 20 }, 
    //             { hpx: 20 }, 
    //             { hpx: 20 }, 
    //             { hpx: 20 }  
    //         ];

    //         this.applyStyles(ws, sheetData, startRow);

    //         XLSX.utils.book_append_sheet(workbook, ws, schoolname);
    //     });

    //     const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //     const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
    //     saveAs(blob, 'ScheduleEvents.xlsx');
    // };
    exportToExcel = () => {
        let { data } = this.state;
    
        if (!data || data.length === 0) {
            alert('No data available to export');
            return;
        }
    
        const workbook = new ExcelJS.Workbook();
    
        // Get sorted dates
        const groupedData = this.groupBySchoolName(data);
    
        Object.keys(groupedData).forEach((schoolname) => {
            const schoolData = groupedData[schoolname];
            let startRow = 0;
    
            const firstData = schoolData[0];
            if (!firstData) return;
    
            const startDate = new Date(firstData.start);
            const currentMonth = startDate.getMonth() + 1;
    
            const monthNames = [
                'January', 'February', 'March', 'April', 'May', 'June', 'July',
                'August', 'September', 'October', 'November', 'December'
            ];
            const currentMonthName = monthNames[currentMonth - 1];
    
            const startDateFormatted = startDate.toLocaleDateString('en-GB');
            const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
            const endDateFormatted = endDate.toLocaleDateString('en-GB');
    
            const schoolName = firstData.schoolname || 'School Name Not Available';
    
            // Create worksheet
            const worksheet = workbook.addWorksheet(schoolName);
    
            const headers = [
                ['VELAMMAL KNOWLEDGE PARK -CENTRAL OFFICE '],
                [`SCHOOL NAME - ${schoolName}`, '', '', '', '', '', '', '', '', `DATE: ${startDateFormatted} - ${endDateFormatted}`],
                [`COUNSELLING SCHEDULE - ${currentMonthName.toUpperCase()} (GROUP AND INDIVIDUAL)`],
                ['S. NO', 'GRADE AND DEPARTMENT', 'DATE OF COUNSELLING', 'TOPIC COVERED IN GROUP COUNSELLING']
            ];
    
            headers.forEach((row, rowIndex) => {
                const newRow = worksheet.addRow(row);
                newRow.eachCell(cell => {
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });
    
                // Merge cells for specific rows
                if (rowIndex === 0) worksheet.mergeCells('A1:D1');
                if (rowIndex === 1) worksheet.mergeCells('A2:J2');
                if (rowIndex === 2) worksheet.mergeCells('A3:J3');
            });
    
            // Set column widths
            worksheet.columns = [
                { width: 10 },  // S. NO
                { width: 40 },  // GRADE AND DEPARTMENT
                { width: 30 },  // DATE OF COUNSELLING
                { width: 60 },  // TOPIC COVERED IN GROUP COUNSELLING
                { width: 80 },  // NAME OF THE MENTOR / WARDEN
                { width: 90 },  // REASON FOR THE COUNSELLING
                { width: 40 }   // WHETHER COUNSELLING CONDUCTED ALONG WITH PARENT (YES OR NO)
            ];
    
            // Ensure text wrapping and alignment
            worksheet.columns.forEach(column => {
                column.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
            });
    
            // Add data rows
            const sheetData = schoolData.map((row, index) => ([
                index + 1,
                row.department,
                row.date,
                row.title
            ]));
    
            worksheet.addRows(sheetData);
    
            // Optional: Adjust row heights if needed
            worksheet.getRow(1).height = 30; // Example height adjustment
            worksheet.getRow(2).height = 30; // Example height adjustment
            worksheet.getRow(3).height = 30; // Example height adjustment
    
        });
    
        // Write to file
        let schoolName="Scheduled_events"
        workbook.xlsx.writeBuffer().then(buffer => {
            const filename = `${schoolName.toUpperCase()}.xlsx`; // Adjusted filename
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename);
        });
    };
    optionSelect = async (selectedOption) => {
        console.log(selectedOption, "selectedOption");
        this.setState({ titleerr: "" })
        if (selectedOption) {
            let selectedValue = "";
            selectedOption.map(values => (selectedValue += `${values.label},`));
            // console.log(selectedValue, "selectedvaluie")
            selectedValue = selectedValue.replace(/,\s*$/, "");
            console.log(selectedValue, "selectedValue");
            await this.setState({
                topicData: selectedOption,
                // email: selectedValue,
            });
        }
    }
    modalhandlechange = (e) => {
        this.setState({ modalInput: e.target.value, error: "" })
    }
    submitmodal = async (b, name, data) => {
        console.log("its working")
        let value = this.state.modalInput
        if (value === null || value.trim() === "") {
            this.setState({ error: "Value Cannot Be Empty" });
            return false;
        }
        else if (b === "topic_name") {
            const a = {
                table_name: "psychol_topic",
                categoryArray: { topic_name: null }
            }
            a.categoryArray.topic_name = value.trim();
            console.log(a.categoryArray.topic_name)
            console.log(a, "a");
            try {
                this.setState({ disableValue: true });
                const result = await CmsContent.addMaster(
                    a.table_name,
                    a.categoryArray
                );
                console.log(result)
                if (result) {
                    this.setState({
                        alertVisible: true,
                        modalInput: "",
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                    }, 3000);
                    this.Mainfun()
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
    convertToPdf = () => {
        const printContents = this.divRef.current.innerHTML;
        const originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
    };

    render() {
        const {
            badgeColor,
            badgeText,
            textalert,
            color,
            MOlist, //marketing off
            Saleslist, alertVisible, added, notadded, loading, isEdit, newEvent, events, schoolopt, schoolData, userType, DepartmentOptions, TopicOption, schdulEvents, modalInput, error
        } = this.state;
        console.log("schdulEvents", this.state.newEvent.title);

        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3> </h3>
                                    </div>
                                    <div className="card-body">
                                        {userType && userType == 3 &&
                                            <>
                                                <div className="inlinebutton inlinecalender">
                                                    <LoginModal
                                                        buttonTitle="Add Event"
                                                        title={"Add Event"}
                                                        id="customer"
                                                        onClick={this.reset}
                                                        bodyText={
                                                            <div>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-1" />
                                                                    <div className="col-sm-4">
                                                                        <label htmlFor="exampleInputEmail1">SCHOOL NAME </label>
                                                                    </div>
                                                                    <div className="col-sm-5">

                                                                        <SingleSelect
                                                                            name="schoolname"
                                                                            // disabled={this.state.DCDisable}
                                                                            options={schoolopt}
                                                                            handleChange={(e) => this.handleInputChange(e, "Multi", "schoolname")}
                                                                            selectedService={this.state.newEvent.schoolname}
                                                                        />
                                                                    </div>

                                                                </div>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-5" />
                                                                    <span className="error-show ">
                                                                        {this.state.errorSchoolname}
                                                                    </span>
                                                                </div>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-1" />
                                                                    <div className="col-sm-4">
                                                                        <label htmlFor="exampleInputEmail1">DEPARTMENT </label>
                                                                    </div>
                                                                    <div className="col-sm-5">

                                                                        <SingleSelect
                                                                            name="department"
                                                                            // disabled={this.state.DCDisable}
                                                                            options={DepartmentOptions}
                                                                            handleChange={(e) => this.handleInputChange(e, "Multi", "department")}
                                                                            selectedService={this.state.newEvent.department}
                                                                        />
                                                                    </div>

                                                                </div>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-5" />
                                                                    <span className="error-show ">
                                                                        {this.state.errorDepartment}
                                                                    </span>
                                                                </div>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-1" />
                                                                    <div className="col-sm-4">
                                                                        <label htmlFor="exampleInputEmail1">TOPIC </label>
                                                                    </div>
                                                                    <div className="col-sm-5">
                                                                        <MultiSelect
                                                                            // disabled={this.state.DCDisable}
                                                                            options={this.state.TopicOption}
                                                                            handleChange={(e) => this.optionSelect(e)}
                                                                            selectedService={this.state.topicData}

                                                                        />

                                                                        {/* <SingleSelect
                                                                            name="title"
                                                                            // disabled={this.state.DCDisable}
                                                                            options={TopicOption}
                                                                            handleChange={(e) => this.handleInputChange(e, "Multi", "title")}
                                                                            selectedService={this.state.newEvent.title}
                                                                        /> */}
                                                                        {/* <textarea
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="title"
                                                                            rows="3"
                                                                            placeholder="Enter Topic"
                                                                            value={this.state.newEvent.title}
                                                                            onChange={this.handleInputChange}
                                                                        /> */}
                                                                    </div>


                                                                </div>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-5" />
                                                                    <div className="col-sm-3">
                                                                        <LoginModal
                                                                            buttonTitle="Add Topic "
                                                                            title="Add Topic"
                                                                            id="businessUnit"
                                                                            extraClass="btn-width mom-btn"
                                                                            onClick={this.reset}
                                                                            bodyText={
                                                                                <div>
                                                                                    <div className="row form-group ">
                                                                                        <div className="col-sm-4" />
                                                                                        <Alert
                                                                                            className="badge-content"
                                                                                            color="success"
                                                                                            isOpen={alertVisible}
                                                                                            toggle={this.onDismiss}
                                                                                        >
                                                                                            New Topic Added
                                                                                        </Alert>
                                                                                    </div>
                                                                                    <div className="card-header">
                                                                                        <h1>Topic</h1>
                                                                                    </div>
                                                                                    <div className="row form-group pt-5">
                                                                                        <div className="col-sm-2" />
                                                                                        <div className="col-sm-2">
                                                                                            <label htmlFor="subcategory">Topic </label>
                                                                                        </div>
                                                                                        <div className="col-sm-5">
                                                                                            <input
                                                                                                type="text"
                                                                                                name="subcategory"
                                                                                                placeholder="Enter Topic "
                                                                                                className="form-control"
                                                                                                value={modalInput}
                                                                                                onChange={(e) => this.modalhandlechange(e)}
                                                                                            />
                                                                                            <span className="modal-error-show">{error}</span>
                                                                                        </div>
                                                                                        <div className="col-sm-3" />
                                                                                    </div>
                                                                                    <div className="row form-group">
                                                                                        <div className="col-sm-2" />
                                                                                        <div className="col-sm-2" />
                                                                                        <div className="col-sm-5">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-primary"
                                                                                                onClick={() => this.submitmodal("topic_name")}
                                                                                            >
                                                                                                submit
                                                                                            </button>
                                                                                        </div>
                                                                                        <div className="col-sm-3" />
                                                                                    </div>
                                                                                    <br></br>
                                                                                </div>
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-1" />
                                                                    <div className="col-sm-4" />
                                                                    <span className="error-show ">
                                                                        {this.state.titleerr}
                                                                    </span>
                                                                </div>
                                                                <br />
                                                                <div className="row form-group">
                                                                    <div className="col-sm-1" />
                                                                    <div className="col-sm-4">
                                                                        <label htmlFor="exampleInputEmail1">VENUE </label>
                                                                    </div>
                                                                    <div className="col-sm-5">
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="venue"
                                                                            placeholder="Event Venue"
                                                                            value={this.state.newEvent.venue}
                                                                            onChange={this.handleInputChange}
                                                                        />
                                                                    </div>

                                                                </div>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-1" />
                                                                    <div className="col-sm-4" />
                                                                    <span className="error-show ">
                                                                        {this.state.errorvenue}
                                                                    </span>
                                                                </div>
                                                                <br />
                                                                <div className="row form-group">
                                                                    <div className="col-sm-1" />
                                                                    <div className="col-sm-4">
                                                                        <label htmlFor="exampleInputEmail1">START DATE</label>
                                                                    </div>
                                                                    <div className="col-sm-5">
                                                                        <input
                                                                            type="datetime-local"
                                                                            className="form-control"
                                                                            name="start"
                                                                            placeholder="Start Date"
                                                                            value={this.state.newEvent.start}
                                                                            onChange={this.handleInputChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-1" />
                                                                    <div className="col-sm-4" />
                                                                    <span className="error-show ">
                                                                        {this.state.errorstdate}
                                                                    </span>
                                                                </div>
                                                                <br />
                                                                <br />
                                                                <div className="row form-group">
                                                                    <div className="col-sm-1" />
                                                                    <div className="col-sm-4">
                                                                        <label htmlFor="exampleInputEmail1">END DATE</label>
                                                                    </div>
                                                                    <div className="col-sm-5">
                                                                        <input
                                                                            type="datetime-local"
                                                                            className="form-control"
                                                                            name="end"
                                                                            placeholder="End Date"
                                                                            value={this.state.newEvent.end}
                                                                            onChange={this.handleInputChange}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-1" />
                                                                    <div className="col-sm-4" />
                                                                    <span className="error-show ">
                                                                        {this.state.errorenddate}
                                                                    </span>
                                                                </div>
                                                                <br />

                                                                <br />

                                                                <div className="row form-group">
                                                                    <div className="col-sm-1" />
                                                                    <div className="col-sm-2" />
                                                                    <div className="col-sm-7">
                                                                        <Alert
                                                                            className="badge-content"
                                                                            color={badgeColor}
                                                                            isOpen={this.state.alertVisible}
                                                                            toggle={this.onDismiss}
                                                                        >
                                                                            {badgeText && badgeText}
                                                                        </Alert>
                                                                    </div>
                                                                    <div className="col-sm-3" />
                                                                </div>
                                                                <br />
                                                                <div className="row form-group">
                                                                    <div className="col-sm-2" />
                                                                    <div className="col-sm-2" />
                                                                    <div className="col-sm-5">
                                                                        <button
                                                                            type="button"
                                                                            className={`btn btn-primary`}
                                                                            disabled={this.state.btnDisable}
                                                                            onClick={this.handleAddEvent}
                                                                        >
                                                                            {'Submit'}
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-sm-3" />
                                                                </div>

                                                            </div>
                                                        }
                                                    />
                                                    <br></br>
                                                    <div className="exportDiv" style={{ marginLeft: "10px" }}>
                                                        <LoginModal
                                                            buttonTitle="Export Schedule"
                                                            title={"Export Schedule"}
                                                            id="Schedule"
                                                            extraClass="btn btn-success"
                                                            onClick={this.reset}
                                                            bodyText={
                                                                <div>
                                                                    {/* <div className="row form-group">
                                                                        <div className="col-sm-1" />
                                                                        <div className="col-sm-4">
                                                                            <label htmlFor="exampleInputEmail1">SCHOOL NAME </label>
                                                                        </div>
                                                                        <div className="col-sm-5">

                                                                            <SingleSelect
                                                                                name="schoolname"
                                                                                // disabled={this.state.DCDisable}
                                                                                options={schoolopt}
                                                                                handleChange={(e) => this.handleSchduleInput(e, "schdule", "schoolname")}
                                                                                selectedService={this.state.schoolname}
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                    <div className="row form-group">
                                                                        <div className="col-sm-5" />
                                                                        <span className="error-show ">
                                                                            {this.state.errorSchoolname}
                                                                        </span>
                                                                    </div> */}

                                                                    <div className="row form-group">
                                                                        <div className="col-sm-1" />
                                                                        <div className="col-sm-4">
                                                                            <label htmlFor="exampleInputEmail1">START DATE</label>
                                                                        </div>
                                                                        <div className="col-sm-5">
                                                                            <input
                                                                                type="date"
                                                                                className="form-control"
                                                                                name="startDate"
                                                                                placeholder="Start Date"
                                                                                value={this.state.startDate}
                                                                                onChange={this.handleSchduleInput}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row form-group">
                                                                        <div className="col-sm-1" />
                                                                        <div className="col-sm-4" />
                                                                        <span className="error-show ">
                                                                            {this.state.errorstdate}
                                                                        </span>
                                                                    </div>
                                                                    <br />
                                                                    <br />
                                                                    <div className="row form-group">
                                                                        <div className="col-sm-1" />
                                                                        <div className="col-sm-4">
                                                                            <label htmlFor="exampleInputEmail1">END DATE</label>
                                                                        </div>
                                                                        <div className="col-sm-5">
                                                                            <input
                                                                                type="date"
                                                                                className="form-control"
                                                                                name="endDate"
                                                                                placeholder="End Date"
                                                                                value={this.state.endDate}
                                                                                onChange={this.handleSchduleInput}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row form-group">
                                                                        <div className="col-sm-1" />
                                                                        <div className="col-sm-4" />
                                                                        <span className="error-show ">
                                                                            {this.state.errorenddate}
                                                                        </span>
                                                                    </div>
                                                                    <br />

                                                                    <br />

                                                                    <div className="row form-group">
                                                                        <div className="col-sm-1" />
                                                                        <div className="col-sm-2" />
                                                                        <div className="col-sm-7">
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color={badgeColor}
                                                                                isOpen={this.state.alertVisible}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                {badgeText && badgeText}
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="col-sm-3" />
                                                                    </div>
                                                                    <br />
                                                                    <div className="row form-group">
                                                                        <div className="col-sm-2" />
                                                                        <div className="col-sm-2" />
                                                                        <div className="col-sm-5">
                                                                            <button
                                                                                type="button"
                                                                                className={`btn btn-primary`}
                                                                                disabled={this.state.btnDisable}
                                                                                onClick={this.submitSchdule}
                                                                            >
                                                                                {'Submit'}
                                                                            </button>
                                                                        </div>
                                                                        <div className="col-sm-3" />
                                                                    </div>
                                                                    {schdulEvents && schdulEvents.length ?
                                                                        <>

                                                                            <div className="row form-group">
                                                                                <div className="col-sm-9" />
                                                                                <div className="col-sm-2" >
                                                                                    <button className="btn btn-success" onClick={this.exportToExcel}>Export Schedule</button>

                                                                                </div>
                                                                            </div>

                                                                            <div className="row form-group">
                                                                                <div className="col-sm-12">
                                                                                    <Datatable
                                                                                        data={schdulEvents}
                                                                                        columnHeading={this.column1}
                                                                                    />


                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        : this.state.endDate && this.state.submit ?
                                                                            <>
                                                                                <center>No data Found</center>
                                                                            </> : <></>



                                                                    }



                                                                </div>
                                                            }
                                                        />
                                                    </div>
                                                    <br></br>

                                                    <button style={{ position: "absolute", right: "30px" }} className="btn btn-secondary downloadPdf" onClick={this.convertToPdf}>Download </button>
                                                </div>
                                                <br></br>
                                            </>

                                        }
                                        <br></br>

                                        <div ref={this.divRef}>

                                            {/* <Calendar
                                                localizer={localizer}
                                                events={this.state.events}
                                                startAccessor="start"
                                                endAccessor="end"
                                                style={{ height: 500 }}
                                            /> */}
                                            <Calendar
                                                localizer={localizer}
                                                events={this.state.events}
                                                startAccessor="start"
                                                endAccessor="end"
                                                style={{ height: 700 }}
                                                views={['month', 'week', 'day', 'agenda']}
                                                defaultView="month"
                                                step={60}
                                                showMultiDayTimes
                                                // components={{
                                                //     event: CustomEvent,
                                                // }}
                                                components={{
                                                    event: (props) => <CustomEvent {...props} view={this.state.view} />,
                                                }}
                                                onView={this.handleViewChange}
                                            // defaultDate={new Date(2024, 5, 1)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default Calender;
