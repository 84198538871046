import { object } from 'prop-types';
import React, { Component } from 'react'
import CmsContent from '../../MiddleWare/CmsContent';
import SingleSelect from '../../components/Form/SingleSelect';
import { ReactComponent as ReactLogo } from '../../images/Upload/redis-logo-svgrepo-com.svg';


export default class RedisUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingPage: false,
            buttonShow: false,
            loadingProject: '',
            cardOpen: false,
            Verify_Btn: false,
            enterOtp: false,
            loadingOtp: false,
            OTP: "",
            enterEmailOtp: "",

            projectList: [
                { label: "tts", value: 246 },
                { label: "swtn", value: 159 },
                { label: "vidhyaonline", value: 807 },
                { label: "smilecares", value: 782 },
                { label: "newall", value: 206 },
                { label: "crucible", value: 5336 },
                { label: "eoi", value: 783 }

            ],
            projectSelected: ""
        }
    }
    updateRedis2 = async () => {
        try {
            let object = new Promise((resolve, reject) => {
                const tables = ['tbl_mapping_page', 'tbl_rightPanel_mapping', 'theme_master']
                // const tables = ['tbl_pages', tbl_user_web]
                let obj = {}
                tables.map(async (table, i) => {
                    if (i !== 2) {
                        const liveToLive = await CmsContent.getFreedom('*', `${table}`, 'id', 1, 1).then((response, err) => {
                            console.log(response, table, "response")
                            obj[table] = response.data

                        })

                    } else if (i === 2) {
                        const liveToLive = await CmsContent.getFreedom('*', `${table}`, 'themeId', 'themeId', 'themeId').then((response, err) => {
                            console.log(response, table, "response")
                            obj[table] = response.data
                        })

                    }
                    if (obj && Object.keys(obj).length === tables.length) {
                        resolve(obj)
                    }
                })
            }).then((value) => {
                if (value) {
                    console.log(value, "value")
                    this.updateRedis(null, null, value)
                }
            })

        } catch (error) {
            alert(error)
        }
    }

    updateRedis = async (projectName = null, index = null, data = null) => {
        let { projectSelected } = this.state
        try {
            if (projectName !== null && index !== null && data === null) {
                await this.setState({ loading: true, loadingProject: index })
                const update = await CmsContent.updateToRedisLocal(projectName);
                if (update) {
                    alert("Updated to local redis")
                    await this.setState({ loading: false, loadingProject: '' })
                }
            } else if (projectName === null && index === null && data === null) {
                await this.setState({ buttonShow: true })

            } else if (data !== null && projectName === null && index === null) {
                if (projectSelected == '') {
                    alert('Please select project name');

                } else if(projectSelected) {

                    const update = await CmsContent.uploadToRedisLive(projectSelected);
                    if (update && update.status === 200) {
                        await this.setState({
                            projectSelected: ""
                        })
                        alert(update.data)

                    } else {
                        alert("Something went wrong. Please try again.")
                    }
                }
            }

        } catch (error) {
            alert("Please try again")
            console.log('error: ', error)
            await this.setState({ loading: false, loadingProject: '' })
        }


    }
    updateRedis3 = async () => {
        try {
            let objectArray = {}
            objectArray.name = "testing"
            const upload = await CmsContent.updateManualRedisLocal(objectArray).then((response) => {
                console.log(response, "response");
                alert("Added")
            });

        } catch (error) {
            alert(error)
        }
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    sendOtp = async () => {
        try {
            await this.setState({ loadingOtp: true })
            // return true
            let objectArray = {}
            objectArray.name = "testing"
            const upload = await CmsContent.sendOtp(objectArray, 123).then((response) => {
                console.log(response, "response");
                if (response && response.data && response.status == 200) {
                    this.setState({ OTP: response.data.OTP, enterOtp: true, Verify_Btn: true, loadingOtp: false })
                }
            });

        } catch (error) {
            console.log(error);
        }

    }
    verifiOtp = async () => {
        let { OTP, enterEmailOtp } = this.state
        if (enterEmailOtp == OTP) {
            await this.setState({ loadingPage: true })
        } else {
            alert("Invalid Otp")
        }

    }
    render() {
        let { loading, buttonShow, loadingProject, cardOpen, projectList, loadingPage, enterOtp, loadingOtp } = this.state;
        let buttons = ['vidhyaonline', 'tts', 'smilecares', 'newall', 'swtn']

        return (
            <>
                {(loadingPage) ?
                    <>
                        <div className='container'>
                            <div class="Rediscard">
                                <div className='mysqlToRedisBtn'>

                                    <center>
                                        <button class="btn_center" onClick={() => { this.setState({ cardOpen: true }) }}>
                                            <ReactLogo style={{ width: "30px", height: "25px" }} /> Redis upload
                                        </button>
                                        {/* <button
                                            className='btn btn-lg btn-primary'
                                            onClick={() => { this.setState({ cardOpen: true }) }}
                                        >
                                            Redis upload
                                        </button> */}
                                    </center>
                                </div>
                                {(cardOpen) ?
                                    <>
                                        <div className='formCard'>
                                            <a onClick={() => this.setState({ cardOpen: false })}><span className='close_card-btn'>&#215;</span></a>
                                            <div className="row form-group">
                                                <div className="col-sm-1" />
                                                <div className="col-sm-4">
                                                    <label htmlFor="authorName">Project Name :</label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <SingleSelect
                                                        name="category"
                                                        options={projectList}
                                                        handleChange={(d) =>
                                                            this.setState({ projectSelected: d })
                                                        }
                                                        selectedService={this.state.projectSelected}
                                                    />
                                                </div>
                                                <div className="col-sm-1" />
                                            </div>

                                            <center>
                                                {/* <button style={{ marginTop: "50px" }}

                                                    className='btn btn-primary'
                                                    onClick={() => { this.updateRedis(null, null, true) }}
                                                >
                                                    Upload to Local mysql data to Local redis data
                                                </button> */}
                                                <button className='upload_redis_btn' style={{ marginTop: "50px" }} onClick={() => { this.updateRedis(null, null, true) }}>
                                                    <div className="svg-wrapper-1">
                                                        <div className="svg-wrapper">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 24 24"
                                                                width={24}
                                                                height={24}
                                                            >
                                                                <path fill="none" d="M0 0h24v24H0z" />
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <span>Upload this project's mysql data to redis database</span>
                                                </button>
                                            </center>

                                        </div>
                                    </>
                                    :
                                    <>
                                    </>}
                                <br>
                                </br>
                                {/* <div className='manualuploadBtn'>
                <center>
                    <button
                        className='btn btn-lg btn-primary'
                        onClick={() => { this.updateRedis3() }}
                    >
                        Manual Upload
                    </button>
                </center>
        
                </div> */}
                            </div>

                            {/* <div className={loading ? 'is-disabled' : ""} style={{ display: "flex", alignItems: 'center', justifyContent: 'center', marginTop: '150px', cursor: "pointer" }}>
                    <button className='btn btn-lg btn-primary' onClick={() => this.updateRedis()}> Live redis to local redis Update</button>
                </div>
        
                <br></br>
        
                {
                    (buttonShow)
                    &&
                    <div
                        className={loading ? 'row is-disabled' : "row"}
                        style={{ display: "flex", alignItems: 'center', justifyContent: 'center' }}
                    >
                        {
                            buttons && buttons.length && buttons.map((ival, i) => {
                                return (
                                    <button
                                        key={ival}
                                        className={loading && loadingProject === i ? 'btn btn-lg btn-secondary' : 'btn btn-lg btn-primary'}
                                        style={{ width: '150px', cursor: "pointer", marginRight: '1px', marginLeft: '1px' }}
                                        onClick={() => this.updateRedis(ival, i)}
                                    >
                                        {
                                            (loading && loadingProject === i)
                                                ?
                                                <div>Loading...</div>
                                                :
                                                ival
                                        }
                                    </button>
                                )
                            })
                        }
                    </div>
                } */}

                            <br></br>

                        </div>
                    </>
                    :
                    <>
                        <div class="modal fade model_fade" id="modelloc" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
                            style={{ backdropFilter: "blur(4px)", zIndex: "111111111", display: "block", opacity: "1" }}
                            aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <div class="text-center">
                                            <h2 className="selectlocationlabel" style={{ color: "#07278f" }}>Do you want to open this page? </h2>
                                            <br></br>

                                        </div>
                                        <div className='Lock_icon'>

                                        </div>                                        <div class="contact-form">
                                            <center>

                                                <i class="fa fa-solid fa-lock Lock_icon"></i>
                                            </center>

                                            {
                                                (enterOtp) ?
                                                    <>
                                                        <div className='text-center OTP_FORM'>
                                                            <h3 className="selectlocationlabel" style={{ color: "#07278f" }}>Enter verification Code </h3>

                                                            <br />
                                                            <h3 className="selectlocationlabel" style={{ color: "" }}> which was sent to  :  <strong>gxxxxxxxs@gmail.com</strong> </h3>
                                                        </div>
                                                        <div className="row form-group OTP_FORM">
                                                            <div className="col-sm-2" />
                                                            <div className="col-sm-1">
                                                                {/* <label htmlFor="position">Portlet Alias</label> */}
                                                            </div>
                                                            <div className="col-sm-5">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Enter your otp"
                                                                    name="enterEmailOtp"
                                                                    onChange={(e) => { this.handleChange(e) }}
                                                                    value={this.state.enterEmailOtp}
                                                                    maxLength={4}
                                                                />

                                                            </div>
                                                            <div className="col-sm-1">

                                                            </div>

                                                        </div>


                                                    </>
                                                    :
                                                    <>
                                                    </>
                                            }
                                            <div className="row form-group text-center">
                                                <div className="col-sm-3" />
                                                <div className="col-sm-5 text-center">
                                                    <center>



                                                        <div class="advanced-button">
                                                            {
                                                                this.state.Verify_Btn == true ?
                                                                    <center>
                                                                        <button className='Send_Otp_Btn verifi_Btn'
                                                                            onClick={() => this.verifiOtp()}>Verify
                                                                            {/* <i class="fa fa-paper-plane"></i> */}
                                                                            <div class="arrow-wrapper_send">
                                                                                <div class="arrow_send"></div>

                                                                            </div>
                                                                        </button>
                                                                    </center>

                                                                    :
                                                                    // <button  onClick={this.login_submit}>
                                                                    //    Send <i class="fa fa-paper-plane"></i>
                                                                    // </button>
                                                                    <center>
                                                                        <div className='otp_Btn'>
                                                                            {
                                                                                (loadingOtp) ?
                                                                                    <div className='text-center loading_wait_otp' style={{ marginLeft: "30px" }}>
                                                                                        <br></br>
                                                                                        <div className='text-center'>
                                                                                            <h3> Please wait</h3>
                                                                                        </div>
                                                                                        <br></br>

                                                                                        <div class="lds-ellipsis"> <div></div><div></div><div></div></div>
                                                                                    </div>
                                                                                    :
                                                                                    <>
                                                                                        <button className='Send_Otp_Btn' onClick={() => this.sendOtp()}>
                                                                                            Yes, send request access
                                                                                            {/* <i class="fa fa-paper-plane"></i> */}
                                                                                            <div class="arrow-wrapper_send">
                                                                                                <div class="arrow_send"></div>

                                                                                            </div>
                                                                                        </button>
                                                                                        {/*<br>
                                                                                         </br>
                                                                                        <button className='Send_Otp_Btn' onClick={() => this.sendOtp()}>
                                                                                            No, back to home page
                                                                                            {/* <i class="fa fa-paper-plane"></i> 
                                                                                            <div class="arrow-wrapper_send">
                                                                                                <div class="arrow_send"></div>

                                                                                            </div>
                                                                                        </button> */}

                                                                                        <br>
                                                                                        </br>

                                                                                        <button className="Btn_logout" onClick={() => window.location.replace("/")}>
                                                                                            <div className="sign_logout">
                                                                                                <svg viewBox="0 0 512 512">
                                                                                                    <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z" />
                                                                                                </svg>
                                                                                                {/* <svg
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                    width={16}
                                                                                                    height={16}
                                                                                                    fill="currentColor"
                                                                                                    className="bi bi-arrow-left"
                                                                                                    viewBox="0 0 16 16"
                                                                                                >
                                                                                                    {" "}
                                                                                                    <path
                                                                                                        fillRule="evenodd"
                                                                                                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                                                                                                    />{" "}
                                                                                                </svg> */}
                                                                                            </div>
                                                                                            <div className="text_logout"> No, back to home page</div>
                                                                                        </button>
                                                                                    </>

                                                                            }


                                                                        </div>
                                                                    </center>

                                                            }
                                                        </div>



                                                    </center>
                                                </div>
                                            </div>
                                            {/* </form> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </>
                }
            </>

        )
    }
}
