
import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../MiddleWare/CmsContent";
import Datatable from "../../components/Datatable/Datatable";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import LoginModal from "../../components/Modal/Modal";
import AddSurveyModal from "./AddSurveyModal";
import CMSaddcatsubcatpdf from "../../components/PDF/Cmsaddcatsubcat";
import SingleSelect from "../../components/Form/SingleSelect";
import { FormMiddleWare, MultiSelect } from "../../components/Form";

export default class AddSurvey extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            checked: [],
            alertVisible: false,
            editvalue: "",
            editid: null,
            disableValue: false,
            allchecked: false,
            answer: [],
            addNewSubCategory: false,
            error: null,
            button: "Submit",
            index: null,
            CHAROPTIONS: [],
            QuesOPTIONS: [],
            AnsOPTIONS: [],
            QuesOPTIONS1: [],
            question: null,
            Characteristics: null,
            formAlertdelete: false,
            ques: [{ value: "" }],
            type: null,
            PosorNeg: [
                { value: "Positive", label: "Positive" },
                { value: "Negative", label: "Negative" },
            ],
            customerId: localStorage.getItem("userId"),
        };
    }
    async componentDidMount() {
        this.Mainfun()
    }
    async Mainfun() {
        try {
            // const result = await cmsContent.getFreedom("tbl_category_master");
            //for charact dropdwon
            const result2 = await cmsContent.getFreedom(
                "id as value, name as label",
                "tbl_EOI_Characteristics_master",
                `customerId='${this.state.customerId}'`,
                "id",
                "id DESC"
            );

            if (result2) {
                //console.log(result);
                this.setState({ CHAROPTIONS: result2.data });
            }
            //for question dropdown            
            const result3 = await cmsContent.getFreedom(
                "id as value, question as label,charid as refid ",
                "tbl_EOI_questions_master",
                `customerId='${this.state.customerId}'`,
                "id",
                "id DESC"
            );

            if (result3) {
                //console.log(result);
                let checked=[]
                result3.data.map((ival, i) => {
                    checked[ival.value] = false
                })
                this.setState({ QuesOPTIONS: result3.data, checked, QuesOPTIONS1: result3.data });
            }
            //for answer dropdown            
            const result4 = await cmsContent.getFreedom(
                "id as value, name as label ",
                "tbl_EOI_answer_master",
                `customerId='${this.state.customerId}'`,
                "id",
                "id DESC"
            );

            if (result4) {
                //console.log(result);
                this.setState({ AnsOPTIONS: result4.data });
            }
            const result = await cmsContent.getFreedom(
                "tbl_EOI_AddSurvey.*",
                "tbl_EOI_AddSurvey",
                `tbl_EOI_AddSurvey.customerId=${this.state.customerId} 
               `,
                "id",
                "id DESC"
            );

            if (result) {
                //console.log(result);
                this.setState({ data: result.data });
            }

        } catch (error) {
            console.log(error);
        }
    }

    column = [
        {
            Header: "Char",
            accessor: "name",
        },
        {
            Header: "Page Limit",
            accessor: "pagelimit",
        },
        {
            Header: "Type",
            accessor: "type",
            Cell: (d) => d.original.type == 1 ? "Yes" : "No"
        },
        // {
        //     Header: "Question",
        //     accessor: "question",
        // },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: (d) => this.edit(d),
        },
        {
            Header: "Delete",
            accessor: "delete",
            Cell: (d) => this.delete(d),
        },
    ]
    edit = (d) => {
        let value = d;
        return (
            <button
                type="button"
                className="btn btn-info"
                onClick={() => this.edition(value)}
            >
                Edit
            </button>
        );
    };
    edition = async (value) => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        let values = value.original;
        let surveyname = values.name
        let pagelimit = values.pagelimit
        let type = values.type == 1 ? true : false
        let answer = []
        let answer1 = []
        if (type) {
            answer = { value: values.answerid, label: this.getValueFromArray(values.answerid, this.state.AnsOPTIONS) }
        } else {
            answer = JSON.parse(values.answerid)
            answer.map((ival) => {
                answer1.push(
                    { value: ival.ans, label: this.getValueFromArray(ival.ans, this.state.AnsOPTIONS) }
                )
            })

        }

        let check = JSON.parse(values.questionid)
        let checked = []
        check.map((ival, i) => {
            if (ival) {
                checked[ival] = true
            }
        })
        await this.setState({
            index: value.index,
            editvalue: values.name,
            editid: values.id,
            surveyname,
            pagelimit,
            type,
            answer1,
            checked,
            answer,
            button: "Update",
        });
    };
    delete = (d) => {

        return (
            <button
                type="button"
                className="btn btn-danger"
                onClick={() => this.deletion(d)}
            >
                Delete
            </button>
        );
    };
    deletion = async (value) => {
        //Getting Previous state data

        const previousData = [...this.state.data];
        // Seperating data row using row-index
        const getData = { ...previousData[value.index] };

        //getting id on that data
        const id = getData.id;
        //removing specific id in previous state data
        const data = previousData.filter((delelteid) => delelteid.id !== id);

        try {
            const result = await cmsContent.deleteMaster(
                "tbl_EOI_AddSurvey",
                id
            );
            if (result) {
                this.setState({ editvalue: "", formAlertdelete: true, data });

                setTimeout(() => this.setState({ formAlertdelete: false }), 3000);
            }
        } catch (error) {
            this.setState({ data: previousData });
            console.log(error);
        }
    };
    handlechange = (e) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: value });
    };
    addnew = async () => {
        let value = this.state.editvalue;
        let { surveyname, pagelimit, type, checked, answer } = this.state;
        let customerId = this.state.customerId;
        let dd = [surveyname, pagelimit, checked, answer]
        let move = false
        dd.map((ival, i) => {
            if (ival == null || ival == "" || ival.length == 0) {
                console.log(ival);
                move = true
            }
        })
        if (move) {
            // alert("Enter All Fields")
            this.setState({
                alertVisible: true,
                alerttext: "Enter All Fields"
            })
            setTimeout(() => {
                this.setState({ alertVisible: false })
            }, 3000);
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
            return false;
        }
        let categoryArray = {};
        categoryArray.name = surveyname;
        categoryArray.pagelimit = pagelimit;
        categoryArray.type = type ? 1 : 0
        let check = []

        checked.map((ival, i) => {
            console.log({ ival, i });
            if (ival) {
                check.push(i)
                move = true
            }
        })
        if (!move) {
            this.setState({
                alertVisible: true,
                alerttext: "select question"
            })
            setTimeout(() => {
                this.setState({ alertVisible: false })
            }, 3000);
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
        let ans = null
        if (type) {
            ans = answer.value
        } else {
            ans = JSON.stringify(answer)
        }
        categoryArray.questionid = JSON.stringify(check)
        categoryArray.answerid = ans
        categoryArray.customerId = customerId;
        console.log({ categoryArray });

        try {
            this.setState({ disableValue: true });
            let result = null
            console.log(this.state.editid);
            if (this.state.editid) {
                result = await cmsContent.updateMaster(
                    "tbl_EOI_AddSurvey",
                    this.state.editid,
                    categoryArray
                );
            } else {

                result = await cmsContent.addMaster(
                    "tbl_EOI_AddSurvey",
                    categoryArray
                );
            }

            // const result = await cmsContent.questionEOI("tbl_EOI_questions_master/id", categoryArray)
            if (result) {
                this.setState({
                    surveyname: "",
                    pagelimit: "",
                    type: null,
                    checked: [],
                    answer: [],
                    // data: newData,
                    alertVisible: true,
                    editvalue: "",
                    Characteristicsselect: null,
                    type: null,
                    editid: null,
                    ques: [{ value: "" }],
                    disableValue: false,
                    addNewSubCategory: false,
                    error: null,
                    button: "Submit",
                    index: null,
                    alerttext: "Category Added/Updated"
                });

                setTimeout(() => {
                    this.setState({
                        alertVisible: false,
                    });
                }, 2000);

                this.Mainfun()
            }
        } catch (error) {
            console.log(error);
        }
    };

    onDismiss = () => {
        this.setState({ alertVisible: false });
        this.setState({ formAlertdelete: false });
    };
    Characteristicsselect = async (v, ch, ans) => {

        if ("Characteristicsselect" == ch) {
            let QuesOPTIONS1 = []
            let { QuesOPTIONS, Characteristicsselect, checked } = this.state;
            QuesOPTIONS.map((jval) => {

                v.map((ival, i) => {
                    if (ival.value == jval.refid) {
                        QuesOPTIONS1.push(jval)
                    }
                })
            })
            QuesOPTIONS1.map((ival, i) => {
                checked[ival.value] = false
            })
            this.setState({ QuesOPTIONS1, checked })
        }

        if ("answer1" == ch) {
            let answer = this.state.answer;
            answer.push({
                quesid: ans,
                ans: v.value
            })
            console.log(answer);
            await this.setState({ answer, [ch]: v })
        } else {
            await this.setState({ [ch]: v })
        }

    }
    addmore = async () => {
        let { ques } = this.state;
        ques.push({ value: "" })
        // if(ques.length==5)
        this.setState({ ques })
    }
    handleans = async (e, i) => {
        let { ques } = this.state;
        ques[i].value = e.target.value
        this.setState({ ques })
    }
    CheckBox = async (i) => {
        let checked = this.state.checked;
        if (i == "end") {

            checked.map((ival, i) => {
                // console.log(i);
                checked[i] = !this.state.allchecked
            })
            this.setState({ allchecked: !this.state.allchecked })
        } else {
            checked[i] = !checked[i]
            let move = await checked.filter((a) => a == false)
            console.log({ move });
            if (move.length == 0) {
                this.setState({ allchecked: true })
            } else {
                this.setState({ allchecked: false })
            }


        }
        this.setState({ checked })
    }
    render() {
        const {
            editvalue,
            alertVisible,
            disableValue,
            error,
            button,
            formAlertdelete,
            CHAROPTIONS,
            Characteristicsselect,
            ques,
            PosorNeg,
            type,
            surveyname,
            pagelimit,
            QuesOPTIONS,
            question,
            QuesOPTIONS1,
            AnsOPTIONS,
            answer,
            checked,
            alerttext
        } = this.state;
        let ans = []
        // console.table(checked);
        // console.log(type);

        // console.log(ans);
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Add Survey</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color="success"
                                                    isOpen={alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    {alerttext}
                                                </Alert>

                                                <Alert
                                                    className="badge-content"
                                                    color="danger"
                                                    isOpen={formAlertdelete}
                                                    toggle={this.onDismiss}
                                                >
                                                    Delete
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="subcategory">Survey Name</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    name="surveyname"
                                                    placeholder="Enter Survey Name"
                                                    className="form-control"
                                                    value={surveyname}
                                                    onChange={(e) => this.handlechange(e)}
                                                />
                                                <span className="modal-error-show">{error}</span>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="subcategory">Questions Per Page</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="number"
                                                    name="pagelimit"
                                                    placeholder="Enter Questions Per Page"
                                                    className="form-control"
                                                    value={pagelimit}
                                                    onChange={(e) => this.handlechange(e)}
                                                />
                                                <span className="modal-error-show">{error}</span>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label for="exampleInputEmail1">Does this survey follow same question pattern ? </label>
                                            </div>
                                            <div className="col-sm-5">


                                                <input
                                                    type="radio"
                                                    style={{ margin: "12px" }}
                                                    name={`validation `}
                                                    onChange={() => this.Characteristicsselect(true, "type")}
                                                    value={type}
                                                    checked={type}
                                                />{" "}
                                                Yes
                                                <input
                                                    type="radio"
                                                    style={{ margin: "8px" }}
                                                    name={`validation `}
                                                    onChange={() => this.Characteristicsselect(false, "type")}
                                                    value={!type}
                                                    checked={type != null && !type}
                                                />{" "}
                                                No

                                            </div>
                                            <div className="col-sm-3" />
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label for="exampleInputEmail1">Select Characteristics</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <MultiSelect
                                                    options={CHAROPTIONS}
                                                    // disabled={disableReportTo}
                                                    handleChange={(v) => this.Characteristicsselect(v, "Characteristicsselect")}
                                                    selectedService={Characteristicsselect}
                                                    placeholder={"Select..."}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2" />
                                            <div className="col-sm-5">
                                                <LoginModal
                                                    buttonTitle="Select Answers"
                                                    title={
                                                        "Select Questions"
                                                    }
                                                    id="Select"
                                                    onClick={this.reset}
                                                    bodyText={

                                                        <AddSurveyModal
                                                            alert={alertVisible}
                                                            alertMsg={""}
                                                            answer={this.state.answer1}
                                                            Checklist={QuesOPTIONS1}
                                                            CheckBox={this.CheckBox}
                                                            checked={this.state.checked}
                                                            allchecked={this.state.allchecked}
                                                            type={type}
                                                            AnsOPTIONS={AnsOPTIONS}
                                                            Characteristicsselect={this.Characteristicsselect}
                                                        />
                                                    }
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>

                                        {type && <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label for="exampleInputEmail1">Select Answers</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    options={AnsOPTIONS}
                                                    // disabled={disableReportTo}
                                                    handleChange={(v) => this.Characteristicsselect(v, "answer")}
                                                    selectedService={answer}
                                                    placeholder={"Select..."}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>}
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2" />
                                            <div className="col-sm-5">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    disabled={disableValue}
                                                    onClick={
                                                        this.addnew
                                                    }
                                                >
                                                    {this.state.button}
                                                </button>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <br></br>


                                        {this.state.data && (
                                            <Datatable
                                                data={this.state.data}
                                                columnHeading={this.column}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

// export default QuestionMaster;
