import React, { Component } from 'react';
import { mobilePhone } from 'react-icons-kit/fa/mobilePhone';
import Icon from 'react-icons-kit';
import { Input, Form, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

class Otpmodel extends Component {
  constructor(props) {
    super(props);
    this.state = { errorMobileNumber: '', mobileNumberotp: '' };
  }
  handleInput = e => {
    
    if (e.target.value == '') {
      this.setState({ errorMobileNumber: 'insertnubmer' });
      return false;
    } else {
      this.setState({ mobileNumberotp: e.target.value });
    }
  };
  validatenumber = async e => {
    
    this.setState({ mobileNumberotp: '' });
  };

  render() {
    const { errorMobileNumber, checkMobileNumberotp } = this.state;
    return (
      <React.Fragment>
        <div class="container">
          <div class="row">
            <div class="col-sm-4"> </div>
            <div class="col-sm-4">
              <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#myModal">
                OTP
              </button>
              <div class="modal" id="myModal">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title">Modal Heading</h4>
                      <button type="button" class="close" data-dismiss="modal">
                        &times;
                      </button>
                    </div>

                    <div class="modal-body">
                      <Form>
                        <InputGroup className={errorMobileNumber ? null : 'mb-3'}>
                          <label>Enter Number &nbsp;&nbsp;&nbsp;</label>{' '}
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <Icon icon={mobilePhone} />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            name="mobileNumberotp"
                            placeholder="Mobile number"
                            maxlength="10"
                            autoComplete="Mobile number"
                            onChange={e => this.handleInput(e)}
                            value={this.setState.mobileNumberotp}
                            onBlur={checkMobileNumberotp}
                          />
                        </InputGroup>
                        <span className="mb-3 span-error">{errorMobileNumber}</span>

                        <button type="button" class="btn btn-danger" onClick={this.validatenumber}>
                          {'submit'}
                        </button>
                      </Form>
                    </div>

                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger" data-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4"> </div>
          </div>
          <br /> <br /> <br />
        </div>
      </React.Fragment>
    );
  }
}
export default Otpmodel;
