import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PsychologyAdminNav extends Component {
    render() {
        let serviceId = localStorage.getItem('servicesEnabled');

        return (
            <>
                <div className="sidebar">
                    <nav className="sidebar-nav">
                        <ul className="nav">
                            <li className="nav-item">
                                <Link className="nav-link" to={`/superAdmin`}>
                                    <i className="nav-icon fa fa-dashboard" />
                                    <span className="link-white">Service Dashboard</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to={`/PsychologyReports/${serviceId}`}>
                                    <i className="nav-icon fa fa-dashboard" />
                                    <span className="link-white">Dashboard</span>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link className="nav-link" to="/calender">
                                    <i className="nav-icon fa fa-calendar" />
                                    <span className="link-white">Calender</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/addschool">
                                    <i className="nav-icon fa fa-graduation-cap" />
                                    <span className="link-white">Add Entity</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/psychologyForm">
                                    <i className="nav-icon fa fa-check-square-o"/>
                                    <span className="link-white">Form</span>
                                </Link>
                            </li>

                            <li className="nav-item nav-dropdown">
                                <Link className="nav-link nav-dropdown-toggle" to="#">
                                    <i className="nav-icon fa fa-arrows" /> Master
                                </Link>
                                <ul className="nav-dropdown-items">

                                    <li className="nav-item">
                                        <Link className="nav-link" to="/addDepartment">
                                            <i className="nav-icon fa fa-user-plus" />
                                            <span className="link-white">Add Department</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/adddesignation">
                                            <i className="nav-icon fa fa-level-up" />
                                            <span className="link-white">Add Designation</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/addentity">
                                            <i className="nav-icon fa fa-level-up" />
                                            <span className="link-white">Add Entity type</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/addclass">
                                            <i className="nav-icon fa fa-user-plus" />
                                            <span className="link-white">Add Class</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/addMentor">
                                            <i className="nav-icon fa fa-handshake-o" />
                                            <span className="link-white">Add Mentor</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/addressmaster">
                                            <i className="nav-icon fa fa-address-card-o" />
                                            <span className="link-white"> Add Entity Address</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/topicmaster">
                                            <i className="nav-icon fa fa-tasks" />
                                            <span className="link-white">Add Topic</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/discussionmaster">
                                            <i className="nav-icon fa fa-comments-o" />
                                            <span className="link-white">Add Discussion</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/notesmaster">
                                            <i className="nav-icon fa fa-sticky-note-o" />
                                            <span className="link-white">Add Notes</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/emailmaster">
                                            <i className="nav-icon fa fa-envelope-o" />
                                            <span className="link-white">Add Email</span>
                                        </Link>
                                    </li>



                                </ul>
                            </li>
                            <li className="nav-item nav-dropdown">
                                <Link className="nav-link nav-dropdown-toggle" to="#">
                                    <i className="nav-icon fa fa-file" /> Reports
                                </Link>
                                {/*                              
                                <ul className="nav-dropdown-items">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/cmsSeekLog">
                                            <i className="nav-icon fa fa-user-plus" />
                                            <span className="link-white">Video Seek Log</span>
                                        </Link>
                                    </li>
                                </ul> */}

                                <ul className="nav-dropdown-items">
                                    {/* <li className="nav-item">
                                        <Link className="nav-link" to="/psychologyForm">
                                            <i className="nav-icon fa fa-user-plus" />
                                            <span className="link-white">Form</span>
                                        </Link>
                                    </li> */}
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/adminReport">
                                            <i className="nav-icon fa fa-file" />
                                            <span className="link-white">Generate Report</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/momReport">
                                            <i className="nav-icon fa fa-file" />
                                            <span className="link-white">Mom Report</span>
                                        </Link>
                                    </li>

                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </>
        );

    }
}

export default PsychologyAdminNav;
