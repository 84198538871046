import React, { Component } from 'react';
import CmsContent from "../../../MiddleWare/CmsContent";
import SingleSelect from "../../../components/Form/SingleSelect";
import MultiSelect from "../../../components/Form/MultiSelect";
import DataTables from '../../../components/Extra/newDataTable';

class PerformanceReport extends Component {
  state = {
    customerid: localStorage.getItem('userId'),
    cohorList: [],
    portletList: [
      { label: 'Video', value: 2 },
      { label: 'Evaluation', value: 8 }
    ],
    columns: [
      {
        name: <b>Employee name</b>,
        selector: "label",
        sortable: true
      },
      {
        name: <b>Id</b>,
        selector: "value",
        sortable: true
      },
      {
        name: <b>Status</b>,
        selector: d => this.getStatus(d),
        sortable: true,
        cell: d => this.getStatus(d)
      },
      {
        name: <b>Score</b>,
        selector: d => this.getScore(d),
        sortable: true,
        cell: d => this.getScore(d)
      }
    ]
  };
  getStatus = d => {
    return d.status === "completed" ? "completed" : "Unattended"
  }
  getScore = d => {
    if (d.status === "completed") {
      return d.data[0].total
    } else {
      return "-"
    }
  }
  async componentWillMount() {
    try {
      const { data: cohorList } = await CmsContent.getFreedom(
        'name as label,id as value',
        'tbl_group',
        `customerId='${this.state.customerid}' and status='active'`
      );
      const { data: mappedList } = await CmsContent.getFreedom(
        'tbl_group.name as label,tbl_group.id as value,tbl_maptogroup.userid',
        'tbl_group,tbl_maptogroup',
        `tbl_group.customerId='${this.state.customerid}' and tbl_group.status='active' and tbl_maptogroup.status='active'`,
        'tbl_maptogroup.userid',
        'tbl_maptogroup.id DESC'
      );
      this.setState({ cohorList, mappedList });
    } catch (error) {
      console.log(error);
    }
  }
  cohortSelect = e => {
    this.setState({ cohortSelect: e });
  }
  learnerSelect = e => {
    this.setState({ learnerSelect: e });
  }
  portletSelect = e => {
    this.setState({ portletSelect: e });
  }
  portContentSelect = async e => {
    try {
      console.log(this.state.cohortSelect.value);
      const { data: learnerList } = await CmsContent.getFreedom(
        'tbl_user_web.userName as label,tbl_user_web.id as value',
        'tbl_user_web,tbl_maptogroup',
        `tbl_maptogroup.groupid=${this.state.cohortSelect.value}  and tbl_maptogroup.status=1
         and tbl_user_web.status='active'
         and tbl_maptogroup.userid=tbl_user_web.id`,
        'tbl_user_web.id',
        'tbl_user_web.userName ASC'
      );
      const { data: statusLearnerList } = await CmsContent.getFreedom(
        '*',
        'tbl_quizAnswer',
        `portid='${e.value}'`
      );
      // userId: 31
      // userName: "second"
      console.log(learnerList);
      if (statusLearnerList && learnerList) {
        learnerList.map((ival, i) => {
          let checkStatus = statusLearnerList.filter(jval => ival.value === jval.userId);
          if (checkStatus.length) {
            learnerList[i].status = "completed";
            learnerList[i].data = checkStatus;
          }
        })
      }
      this.setState({ portContentSelect: e, learnerList, learnerSelect: [] });
    } catch (error) {
      console.log(error);
    }
  }
  portContent = async e => {
    try {
      const { data: portContent } = await CmsContent.getFreedom(
        'tbl_boxcontent.heading as label ,tbl_boxcontent.id as value',
        'tbl_map_grouptoportlet,tbl_boxcontent',
        `tbl_map_grouptoportlet.contentid=tbl_boxcontent.name and tbl_map_grouptoportlet.boxtypeid=tbl_boxcontent.type and tbl_map_grouptoportlet.status='active' and tbl_boxcontent.status='active' and tbl_map_grouptoportlet.customerid='${this.state.customerid}' and tbl_map_grouptoportlet.boxtypeid=${e.value} and tbl_map_grouptoportlet.groupid=${this.state.cohortSelect.value}`,
        'tbl_boxcontent.id',
        'tbl_boxcontent.id ASC'
      );
      this.setState({ portContent, portContentSelect: {} });
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    const state = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <h1>Evaluation Report</h1>
              </div>
              <div className="card-body" style={{ minHeight: 350 }}>

                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2">
                    <label htmlFor="exampleInputEmail1">Cohorts</label>
                  </div>
                  <div className="col-sm-5">
                    <SingleSelect options={state.cohorList} handleChange={e => { this.cohortSelect(e); }} value={state.cohortSelect} placeholder="Select cohort" />
                  </div>
                </div>
                {state.cohortSelect ?
                  (<div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="exampleInputEmail1">Portlet</label>
                    </div>
                    <div className="col-sm-5">
                      <SingleSelect options={state.portletList} handleChange={e => { this.portletSelect(e); this.portContent(e); }} value={state.portletSelect} placeholder="Select portlet" />
                    </div>
                  </div>) : null}
                {(state.portContent) ? (
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="exampleInputEmail1">Port Content</label>
                    </div>
                    <div className="col-sm-5">
                      <SingleSelect options={state.portContent} handleChange={e => { this.portContentSelect(e); }} value={state.portContentSelect} placeholder="Select portlet" />
                    </div>
                  </div>) : null}

                {state.learnerList ? (
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="exampleInputEmail1">Learners</label>
                    </div>
                    <div className="col-sm-5">
                      <MultiSelect
                        options={state.learnerList}
                        handleChange={e => this.learnerSelect(e)}
                        selectedService={state.learnerSelect}
                      />
                      {/* <span className="error-shows">{error}</span> */}
                    </div>
                  </div>) : null}

                <div className="row form-group">
                  <div className="col-sm-12" >
                    {this.state.learnerSelect ? <DataTables
                      columns={this.state.columns}
                      data={this.state.learnerSelect}
                    /> : null}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default PerformanceReport;