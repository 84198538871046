import React, { Component } from "react";
import { ACCESS_POINT } from "../../config";

class HomeInnerContentSection3 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="row-fluid-wrapper row-depth-1 row-number-9 ">
        <div className="row-fluid ">
          <div
            className="span12 widget-span widget-type-cell bg-f2"
            style={{}}
            data-widget-type="cell"
            data-x={0}
            data-w={12}
          >
            <div className="row-fluid-wrapper row-depth-1 row-number-10 ">
              <div className="row-fluid ">
                <div
                  className="span12 widget-span widget-type-cell page-margins padTB"
                  style={{}}
                  data-widget-type="cell"
                  data-x={0}
                  data-w={12}
                >
                  <div className="row-fluid-wrapper row-depth-1 row-number-11 ">
                    <div className="row-fluid ">
                      <div
                        className="span12 widget-span widget-type-custom_widget "
                        style={{}}
                        data-widget-type="custom_widget"
                        data-x={0}
                        data-w={12}
                      >
                        <div
                          id="hs_cos_wrapper_module_15583650681212505"
                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                          style={{}}
                          data-hs-cos-general-type="widget"
                          data-hs-cos-type="module"
                        >
                          <div className="flexbox-cols flex-row hzSpB vtSTR cols3 imgMaxH">
                            <div className="block" style={{ width: '45%' }}>
                              <p className="blockp">
                                <img
                                  src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/chart-down4507_1581081554962.png`}
                                  alt="chart-down"
                                  width={370}
                                  style={{ width: "370px" }}
                                  sizes="(max-width: 370px) 100vw, 370px"
                                />
                              </p>
                              <p className="blockp">
                                {/* Reduce incident rates by{" "}
                                <span className="big">30%</span>
                                through positive and negative based observations */}
                                Research has revealed that unsafe employee acts contribute to
                                more than 90% of injuries at workplace,
                                 highlighting the importance of a behavior-based approach to safety

                                 {/* Reduce incident rates by{" "} <span className="big">30%</span> */}
                              </p>
                            </div>
                            <div className="block" style={{ width: '45%' }}>
                              <p className="blockp">
                                <img
                                  src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/people-icons1d96_1581081345816.png`}
                                  alt="people-icons"
                                  width={370}
                                  style={{ width: "370px" }}
                                  sizes="(max-width: 370px) 100vw, 370px"
                                />
                              </p>
                              <p className="blockp">
                                Employers can save $4.00 to $6.00 for every dollar spent on a
                                safety and health program. Workplaces with successful safety
                                and health management systems reduce injury and illness costs
                               20-40%, according to OSHA{" "}
                                <span className="med">
                                  $4.00 to $6.00m in savings
                                </span>
                              </p>
                            </div>
                            {/* <div className="block">
                              <p className="blockp">
                                <img
                                  src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/papers9145_1581081486789.png`}
                                  alt="papers"
                                  width={370}
                                  style={{ width: "370px" }}
                                  sizes="(max-width: 370px) 100vw, 370px"
                                />
                              </p>
                              <p className="blockp">
                                Save up to <span className="big">60%</span> time
                                in field documentation
                              </p>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      {/*end widget-span */}
                    </div>
                    {/*end row*/}
                  </div>
                  {/*end row-wrapper */}
                </div>
                {/*end widget-span */}
              </div>
              {/*end row*/}
            </div>
            {/*end row-wrapper */}
          </div>
          {/*end widget-span */}
        </div>
        {/*end row*/}
      </div>
    );
  }
}
export default HomeInnerContentSection3;
