import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import SingleSelect from "../../../components/Form/SingleSelect";
import { Alert } from "reactstrap";
import { Datatable } from "../../../components/Datatable";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import Logpdf from "./logpdf";
import FormMiddleWare from "../../../components/Form/FormMiddleware";

class CmsAlllog extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      customerId: localStorage.getItem("userId"),
      pageOptions: [],
      videoptions: [],
      tabledata: [],
      users: [],
      Accessusercount: [],
      alluserviewpage: [],
      selectedstartdate: "",
      selectedenddate: "",
      disablepage: false,
      disablevideos: false,
    };
  }

  async componentWillMount() {
    const {
      match: { params },
    } = this.props;
    // console.log(params);
    let userid = params["userid"];
    let pageid = params["pageid"];

    const pageview = await CmsContent.getFreedom(
      "tbl_CMSuserAccessLog.*",
      "tbl_CMSuserAccessLog",
      `tbl_CMSuserAccessLog.pageid =${pageid} and tbl_CMSuserAccessLog.userid=${userid} and  tbl_CMSuserAccessLog.customerId=${this.state.customerId} `,
      "id",
      "id DESC"
    );

    if (pageview) {
      this.setState({ tabledata: pageview.data });
    }
    const pages = await CmsContent.getFreedom(
      "id as value,name as label,file",
      "tbl_pages",
      `customerId=${this.state.customerId} and tbl_pages.id=${pageid}  `,
      "id",
      "id DESC"
    );
    if (pages) {
      this.setState({ pageOptions: pages.data });
    }

    const videoptionsvalues = await CmsContent.getFreedom(
      "id as value,file as label",
      "tbl_pages",
      `customerId=${this.state.customerId}  and tbl_pages.id=${pageid} and file_extension="mp4"`,
      "id",
      "id DESC"
    );
    if (videoptionsvalues) {
      this.setState({ videoptions: videoptionsvalues.data });
    }

    const users = await CmsContent.getFreedom(
      "id as value,userName as label",
      "tbl_user_web",
      `customerId=${this.state.customerId} and id=${userid}`,
      "id",
      "id DESC"
    );
    if (users) {
      this.setState({ users: users.data });
    }

    let data = this.state.tabledata;
    //    console.log("data", data);
    var get = [];
    var pg = [];
    var usr = [];
    var user = users.data;
    var page = pages.data;
    data.map((ival, m) => {
      user.map((jval, n) => {
        if (ival.userId == jval.value) {
          usr.push(jval);
        }
      });
      page.map((kval, k) => {
        if (ival.pageid === kval.value) {
          pg.push(kval);
        }
      });
      if (ival.max_timeline == null || ival.play == null) {
        get.push({
          SI_NO: m + 1,
          user: usr[0].label,
          page: pg[0].label,
          ip: ival.ipaddress,
          max: "N/A",
          exit: ival.datetime,
          entry: "N/A",
        });
      } else {
        get.push({
          SI_NO: m + 1,
          user: usr[0].label,
          page: pg[0].label,
          ip: ival.ipaddress,
          max: ival.max_timeline,
          exit: ival.datetime,
          entry: ival.play,
        });
      }
    });
    console.log("var", get);
    this.setState({ Csv: get });
    if (data.length > 0) {
      // console.log("dd");
      const header1 = [
        "SI.NO",
        "Username",
        "Pagename",
        "IpAddress",
        "MAX Time",
        "Entry Time",
        "Exit Time",
      ];
      const excelHead = [
        { label: "SI_No", key: "SI_NO" },
        { label: "Username", key: "user" },
        { label: "Pagename", key: "page" },
        { label: "IpAddress", key: "ip" },
        { label: "MAX Time", key: "max" },
        { label: "Entry Time", key: "entry" },
        { label: "Exit Time", key: "exit" },
      ];
      this.setState({
        header1,
        excelHead,
        data: data,
        excel: data,
        Csv: this.state.Csv,
      });
    }
  }

  column = [
    {
      id: "userId",
      Header: "Username",
      accessor: (d) => this.getValueFromArray(d.userId, this.state.users),
      Cell: (d) => this.getValueFromArray(d.original.userId, this.state.users),
    },
    {
      id: "pageid",
      Header: "Page name",
      accessor: (d) => this.getValueFromArray(d.pageid, this.state.pageOptions),
      Cell: (d) =>
        this.getValueFromArray(d.original.pageid, this.state.pageOptions),
    },
    {
      Header: "IP Address",
      accessor: "ipaddress",
    },
    {
      Header: "Max Time",
      accessor: "max_timeline",
      Cell: (d) => this.checkEmpty(d),
    },
    {
      id: "startTime",
      Header: "Entry Time",
      accessor: (d) => this.dateformatchange(d.startTime),
      Cell: (d) => this.dateformatchange(d.original.startTime),
    },
    {
      id: "datetime",
      Header: "Exit Time",
      accessor: (d) => this.dateformatchange(d.datetime),
      Cell: (d) => this.dateformatchange(d.original.datetime),
    },
  ];
  checkEmpty = (d) => {
    //console.log(d);
    if (d.original.max_timeline) {
      let num = parseInt(d.original.max_timeline);
      //var sec = num.getSeconds();
      //var hours = Math.floor(num / 60);
      var minutes = Math.floor(num / 60) % 60;
      var sec = Math.floor(num - minutes * 60);
      return minutes + "m" + ":" + sec + "s";
    } else {
      return "N/A";
    }
    //return hours + "hrs" + ":" + minutes + "m" + ":" + num + "s";

    //return d.original.max_timeline ? d.original.max_timeline : "N/A";
  };
  dateformatchange = (datevalue) => {
    if (datevalue) {
      let dateview = datevalue.split("-");
      let hourssplit = dateview[2].split(":")[0].split("");
      let Mins = dateview[2].split(":");
      return (
        <span>
          {" "}
          {hourssplit[0] +
            hourssplit[1] +
            "-" +
            dateview[1] +
            "-" +
            dateview[0] +
            " " +
            hourssplit[3] +
            hourssplit[4] +
            ":" +
            Mins[1] +
            ":" +
            Mins[2]}
        </span>
      );
    } else {
      return "N/A";
    }
  };

  render() {
    const { formAlert, formAlertdelete, categoryOptions } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-sm-4"> </div>
                      <div className="col-sm-4">
                        <h3>Total access log</h3>
                      </div>
                      <div className="col-sm-4"> </div>
                    </div>
                    <br />
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorPageId}
                      </span>
                    </div>
                    {this.state.data && this.state.data.length > 0 && (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <Logpdf
                            header={this.state.header1}
                            data={this.state.excel}
                            users={this.state.users}
                            page={this.state.pageOptions}
                          />
                        }
                        fileName="sample.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    )}
                    {this.state.excel && this.state.excel.length > 0 && (
                      <CSVLink
                        data={this.state.Csv}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          EXCEL
                        </button>
                      </CSVLink>
                    )}
                    {this.state.tabledata && (
                      <Datatable
                        data={this.state.tabledata}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default CmsAlllog;
