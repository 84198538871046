import React, { Component } from "react";
import { Alert } from "reactstrap";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import { confirmAlert } from "react-confirm-alert";
import TableExample from '../../../components/PDF/TableExample';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink, CSVDownload } from "react-csv";


class Location extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      alertVisible: false,
      alertVisible1: false,
      editvalue: "",
      editid: null,
      disableValue: false,
      addNewSubgroup: false,
      error: null,
      button: "Submit",
      index: null,
      customerId: localStorage.getItem("userId"),
      formAlertdelete: false,
      textalert: null,
      color: "success"
    };
  }
  async componentWillMount() {
    try {
      // const result = await CmsContent.getSingleConditionedValue(
      //   "tbl_location",
      //   "customerId",
      //   this.state.customerId
      // );

      const result = await CmsContent.getFreedom("*",
        "tbl_location",
        `customerId =${this.state.customerId}`, 1, 1)
      if (result) {

        // this.setState({ data: result.data });
        //console.log(result.data);

      }
      if (result.data.length > 0) {
        const header = [
          'SI.NO',
          'LOCATION',

        ];
        const excelHead = [
          { label: "Location", key: "name" }
        ];

        this.setState({
          header,
          excelHead,
          data: result.data,
          title: "LOCATION"
        });
      }


    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      Header: "Location",
      accessor: "name"
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: d => this.edit(d)
    },
    {
      Header: <b>Delete</b>,
      selector: "delete",
      Cell: d =>
        this.dataTableButton("danger", "Delete", () => {
          this.buttonDeletes(d);
        })
    }
  ];
  edit = d => {
    let value = d;
    return (
      <center>
        <button
          type="button"
          className="btn btn-info"
          onClick={() => this.edition(value)}
        >
          Edit
        </button>
      </center>
    );
  };
  edition = value => {

    let values = value.original;
    let editvalue = values.name;

    this.setState({
      index: value.index,
      editvalue: values.name,
      editid: values.id,
      error: null,
      button: "Update"
    });
  };
  buttonDeletes = value => {
    this.setState({});
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.buttonDelete(value)
          //onClick: () => alert("Group removed from")
        },
        {
          label: "No"
        }
      ]
    });
  };
  buttonDelete = async value => {
    //Getting Previous state data

    const previousData = [...this.state.data];

    let id = value.id;

    try {
      const result = await CmsContent.getSingleConditionedValue(
        "tbl_location",
        "id",
        id,
        "Delete"
      );
      if (result) {

        let datas = previousData.filter((delelteid, i) => {

          if (delelteid.id !== value.id) {
            return delelteid;
          }
        });
        this.setState({
          data: datas,
          alertVisible: true,
          textalert: "Selected Location Deleted",
          color: "danger"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  getValueFromArrays = (d, array) => {

    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {

          return item.id == d;
        });
        let v = filtered[0];
        if (v != undefined) {

          return v.name;
        } else {
          return "---";
        }
      }
    } else {
      return "-";
    }
  };
  // delete = d => {
  //   let value = d.original.value;
  //   return (
  //     <button
  //       type="button"
  //       className="btn btn-danger"
  //       onClick={() => this.deletion(value)}
  //     >
  //       Delete
  //     </button>
  //   );
  // };
  // deletion = async value => {
  //   //Getting Previous state data
  //   const previousData = [...this.state.data];

  //   // Seperating data row using row-index
  //   const getData = { ...previousData[value.index] };

  //   //getting id on that data
  //   const id = getData.value;

  //   //removing specific id in previous state data
  //   const data = previousData.filter(delelteid => delelteid.value !== value);

  //   try {
  //     const result = await CmsContent.deleteMaster('tbl_location', value);
  //     if (result) {
  //       this.setState({ editvalue: '', formAlertdelete: true, data });

  //       setTimeout(() => this.setState({ formAlertdelete: false }), 3000);
  //     }
  //     const { editvalue } = this.state;
  //     if (editvalue === '') {
  //       this.setState({ error: '' });
  //     }
  //   } catch (error) {
  //     this.setState({ data: previousData });
  //     console.log(error);
  //   }
  // };
  handlechange = e => {
    let value = e.target.value;

    this.setState({ editvalue: value });
  };
  addnew = async () => {
    //    let value = this.state.editvalue;
    const { editvalue: value } = this.state;

    if (value === null || value.trim() === "") {
      this.setState({ error: "Please Fill Location" });
      return false;
    }
    let groupArray = {};
    groupArray.name = value.trim();
    groupArray.status = "active";
    groupArray.customerId = this.state.customerId;
    ;
    try {
      this.setState({ disableValue: true });
      const result = await CmsContent.addMaster("tbl_location", groupArray);

      if (result) {
        let valueArray = {};
        valueArray.id = result.data.insertId;
        valueArray.name = groupArray.name;
        const newData = [valueArray, ...this.state.data];
        this.setState({
          data: newData,
          alertVisible: true,
          editvalue: "",
          editid: null,
          disableValue: false,
          addNewSubgroup: false,
          error: null,
          button: "Submit",
          index: null,
          textalert: "New Location Added",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  update = async () => {
    //    let value = this.state.editvalue;
    const { editvalue: value } = this.state;

    if (value === "") {
      this.setState({ error: "Value Cannot Be Empty" });
      return false;
    } else {
      this.setState({ disableValue: true });
      try {
        let previousdata = [...this.state.data];
        //let index = this.state.index;
        //let id = this.state.editid;
        const { index, editid: id } = this.state;

        let subgroupArray = {};
        subgroupArray.name = value;
        subgroupArray.status = "active";
        const result = await CmsContent.updateMaster(
          "tbl_location",
          id,
          subgroupArray
        );
        if (result) {
          previousdata[index].name = value;
          setTimeout(() => this.setState({ data: previousdata }), 500);
        }
      } catch (error) {
        console.log(error);
      }
      this.setState({
        alertVisible: true,
        editvalue: "",
        disableValue: false,
        editid: null,
        addNewSubgroup: false,
        error: null,
        button: "Submit",
        index: null,
        textalert: "Location Updated",
        color: "success"
      });
      setTimeout(() => this.setState({ alertVisible: false }), 3000);
    }
  };
  onDismiss = () => {
    this.setState({ alertVisible: false });
    this.setState({ alertVisible1: false });
    this.setState({ formAlertdelete: false });
  };
  render() {
    const {
      editvalue,
      alertVisible,
      alertVisible1,
      disableValue,
      error,
      button,
      formAlertdelete,
      textalert
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Location Master</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {/* New Location Added */}
                          {textalert}
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="subgroup">Location</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          name="subgroup"
                          placeholder="Enter Location"
                          className="form-control"
                          value={editvalue}
                          onChange={e => this.handlechange(e)}
                        />
                        <span className="error-shows">{error}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={disableValue}
                          onClick={
                            button === "Update" ? this.update : this.addnew
                          }
                        >
                          {this.state.button}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    {this.state.data && this.state.data.length > 0 && (
                      <PDFDownloadLink
                        style={{ color: 'white' }}
                        document={<TableExample title={this.state.title} header={this.state.header} data={this.state.data} />}
                        fileName="sample.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? 'Loading document...' : 'Download Pdf'
                        }
                        <button className="btn btn-warning">
                          PDF
                    </button>
                      </PDFDownloadLink>
                    )}
                    {this.state.data && this.state.data.length > 0 && (
                      <CSVLink data={this.state.data} headers={this.state.excelHead}>
                        <button className="btn btn-success" style={{ marginLeft: '11px' }}>
                          Excel
                    </button>
                      </CSVLink>
                    )}
                    <div style={{ marginTop: '5px' }}> </div>

                    <span>
                      Total of {Object.keys(this.state.data).length} records
                    </span>
                    {this.state.data && (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Location;
