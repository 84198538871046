
import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import cmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import Addauthormodel from "./Addauthormodel";
import { Alert } from "reactstrap";
import { ACCESS_SAMPLE } from "../../../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import AddpagePDF from "../../../components/PDF/Addpagespdf";

//import Theme9 from './addPages/theme9';
import Datatable from "../../../components/Datatable/Datatable";
import { FormMiddleWare } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";
import CmsContent from "../../../MiddleWare/CmsContent";
import Paginationfun from "../pagination";
class RecoverEmployee extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            isEdit: false,
            customerId: localStorage.getItem("userId"),
            data: [],
            editId: null,
            userid: null,
            added: [],
            notadded: [],
            badgeColor: false,
            badgeText: false,
            emp_id: "",
            ansgender: "", ansBusinessUnit: "", ansFunction: "", ansSubFunction: "",
            ansRegion: "", ansDivision: "", ansBranch: "",
            ansoffEmailAdd: "", ansGrade: "", ansBand: "",
            ansEmpCatType: "", ansL1ManCode: "",
            ansL1Man: "", ansL2ManCode: "", ansL2Man: "", ansHRManCode: "", ansHRMan: "", ansBusiDesignation: "",
            errgender: "", errBusinessUnit: "", errFunction: "", errSubFunction: "", errRegion: "", errDivision: "", errBranch: "", erroffEmailAdd: "", errGrade: "", errBand: "", errEmpCatType: "", errL1ManCode: "", errL1ManCode: "",
            errL1Man: "", errL2ManCode: "", errL2Man: "", errHRManCode: "", errHRMan: "", errBusiDesignation: "",
            formAlertdelete: false,
            textalert: "",
            genderopt: [{ label: "MALE", value: 1 }, { label: "FEMALE", value: 2 }],
            // EmployeeCatopt: [{ label: "MS", value: 1 }, { label: "NMS", value: 2 }, { label: "NMS - Staff", value: 3 }, { label: "NMS - Worker", value: 4 }, { label: "MS-Liberty", value: 5 }],
            // Bandopt: [{ label: "Band-1", value: 1 }, { label: "Band-2", value: 2 }, { label: "Band-3", value: 3 }, { label: "NMS", value: 4 },],
            danger: "btn btn-danger",
            success: "btn btn-success",
            pageNumber: 1,
            SelectedEnquiries: [],
            bulkAllocate: false,
            unActiveUser: false
        };
    }
    column = [
       
        {
            Header: "EMPLOYEE ID",
            accessor: "employeeId",
        },
        {
            Header: "EMPLOYEE NAME",
            accessor: "employee_name",
        },
        {
            Header: "IP ADDRESS",
            accessor: "clientIP",
        },
        {
            Header: "DATE & TIME",
            accessor: "currentDateTime",
        },

        // {
        //     Header: "EDIT",
        //     accessor: "edit",
        //     Cell: (d) => this.Editpages(d, 'customer'),
        // },
        // {
        //     Header: "STATUS",
        //     // accessor: "edit",
        //     // Cell: (d) => this.buttondel(d),
        // },
        // {
        //     Header: "Delete",
        //     accessor: "edit",
        //     Cell: (d) => this.DatatableCheckboxx(d, this.updateMyData, this.state.SelectedEnquiries)
        // }
    ];
    async componentDidMount() {
        this.MainFun()

        // const togglePassword = document.querySelector('#togglePassword');
        // const password = document.querySelector('#password');
        // document.querySelector('#togglePassword').addEventListener('click', function (e) {
        //     this.classList.toggle('fa-eye-slash');
        //     const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
        //     password.setAttribute('type', type);
        // });
    }
    // DatatableCheckboxx = (d, func, SelectedEnquiries) => {
    //     // console.log(d.original.id, "SelectedEnquiries[d.original.id]");
    //     if (d.original.status == 0) {
    //         return (
    //             <div style={{ 'text-align': 'center' }}>
    //                 <input type="checkbox"
    //                     checked={SelectedEnquiries && SelectedEnquiries[d.original.id] &&
    //                         SelectedEnquiries[d.original.id] == true ? true : false}
    //                     onChange={(event) => func(parseInt(d.original.id), d.column.id, event.target.checked ? "Yes" : "No")} />
    //             </div>)
    //     } else {
    //         return (
    //             <></>
    //         )
    //     }
    // };
    // updateMyData = async (d, f, event) => {
    //     let { SelectedEnquiries } = this.state
    //     console.log(d, "d", event, "event", f, "f");
    //     console.log(SelectedEnquiries, "SelectedEnquiries");
    //     let AllocateType = "Delete"
    //     const index = SelectedEnquiries.indexOf(d)
    //     console.log(index, "index");
    //     if (index > -1 && event == "No") {
    //         SelectedEnquiries.splice(index, 1)
    //         SelectedEnquiries[d] = false
    //         if (index == 0) {
    //             SelectedEnquiries.length = 0;
    //             this.setState({ bulkAllocate: false })

    //         }

    //     }
    //     else {
    //         SelectedEnquiries.push(d)
    //         SelectedEnquiries[d] = true
    //         this.setState({ bulkAllocate: true })
    //     }
    //     this.setState({ SelectedEnquiries, AllocateType })

    // }
    // selectalldata = async (event) => {
    //     console.log(event, event);
    //     let { data } = this.state
    //     let SelectedEnquiries = []
    //     console.log(SelectedEnquiries, "SelectedEnquiries");
    //     console.log(data, "data");

    //     if (event == "Yes") {
    //         for (let index = 0; index < 10; index++) {
    //             const element = data[index];
    //             if (element.status == 0) {
    //                 SelectedEnquiries[element.id] = true
    //                 SelectedEnquiries.push(element.id)
    //             }
    //         }

    //         await this.setState({ isSelectUnallocated: true })
    //         // console.log(SelectedEnquiries.length, "SelectedEnquiries length");
    //     }

    //     else {
    //         await this.setState({ isSelectUnallocated: false })
    //     }
    //     await this.setState({ SelectedEnquiries })
    // };
    // confirmDeleteT = async (e) => {
    //     console.log(e, "ee");
    //     let { SelectedEnquiries } = this.state
    //     console.log(SelectedEnquiries, "SelectedEnquiries");
    //     if (SelectedEnquiries) {
    //         // const filteredData = Object.fromEntries(
    //         //     Object.entries(SelectedEnquiries).filter(([key, value]) => value === true)
    //         // );

    //         const keysWithTrueValues = Object.keys(SelectedEnquiries)
    //             .filter(key => SelectedEnquiries[key] === true)
    //             .map(Number);

    //         console.log(keysWithTrueValues);
    //         const DeleteMultyUser = await cmsContent.DeleteMultyUser("cmndl_tbl_Employee", keysWithTrueValues)
    //         console.log(DeleteMultyUser, "DeleteMultyUser");
    //         if (DeleteMultyUser) {
    //             this.setState({ bulkAllocate: false, SelectedEnquiries: [], isSelectUnallocated: false })
    //             // this.MainFun()

    //             setTimeout(() => {
    //                 window.location.reload()
    //             }, 3000);
    //         }

    //     }
    // }
    // confirmDeleteP = async (e) => {
    //     console.log(e, "ee");
    //     let { SelectedEnquiries } = this.state
    //     console.log(SelectedEnquiries, "SelectedEnquiries");
    //     if (SelectedEnquiries) {
    //         // const filteredData = Object.fromEntries(
    //         //     Object.entries(SelectedEnquiries).filter(([key, value]) => value === true)
    //         // );

    //         const keysWithTrueValues = Object.keys(SelectedEnquiries)
    //             .filter(key => SelectedEnquiries[key] === true)
    //             .map(Number);

    //         console.log(keysWithTrueValues);
    //         const DeleteMultyUser = await cmsContent.DeletePermanentMultyUser("cmndl_tbl_Employee", keysWithTrueValues)
    //         console.log(DeleteMultyUser, "DeleteMultyUser");
    //         if (DeleteMultyUser) {
    //             this.setState({ bulkAllocate: false, SelectedEnquiries: [], isSelectUnallocated: false })
    //             // this.MainFun()

    //             setTimeout(() => {
    //                 window.location.reload()
    //             }, 3000);
    //         }

    //     }
    // }
    // multyDelete = d => {
    //     confirmAlert({
    //         customUI: ({ onClose }) => {
    //             return <div style={{ textAlign: "center" }}>
    //                 <h1>Delete confirmation</h1>
    //                 <h4>Are you sure you want to Delete this temporary ?</h4><br />
    //                 <button className="btn btn-primary" onClick={() => {
    //                     this.confirmDeleteT(d)
    //                     onClose();
    //                 }}  >Yes</button> &nbsp;  &nbsp;
    //                 <button className="btn btn-danger" onClick={onClose} >No</button>
    //                 <br></br>
    //                 <br></br>

    //                 <h1>Delete confirmation</h1>
    //                 <h4>Are you sure you want to Delete this permanetly ?</h4><br />
    //                 <button className="btn btn-primary" onClick={() => {
    //                     this.confirmDeleteP(d)
    //                     onClose();
    //                 }}  >Yes</button> &nbsp;  &nbsp;
    //                 <button className="btn btn-danger" onClick={onClose} >No</button>
    //             </div>
    //         }
    //     })
    // };
    MainFun = async () => {
        try {
            this.setState({ loading: true })
            // const GetOpts = await cmsContent.getCmndlEmpopt(this.state.customerId)
            // let val = {}
            // val.pageNumber = this.state.pageNumber
            // const GetOpts = await cmsContent.GetCmdlDatas(this.state.customerId, "Employee")
            // console.log(GetOpts, "GetOpts");
            // if (GetOpts) {
            //     if (GetOpts.Employee) {
            //         let data = []
            //         let deleteData =[]
            //         for (let ival of GetOpts.Employee) {
            //             if (ival.employee_id != null) {
            //                 await data.push(ival)
            //             }
            //         }
            //         for (let jval of GetOpts.Employee) {
            //             if (jval.employee_id != null && jval.status !== "0") {
            //                 await deleteData.push(jval)
            //             }
            //         }
            //         console.log(data, "data");
            //         console.log(deleteData,"deleteData");
            //         await this.setState({ data: data ,deleteData:deleteData});
            //     }
            //     console.log(GetOpts.BusiDesignation, "GetOpts.BusiDesignation");
            //     if (GetOpts.BusiDesignation) {
            //         await this.setState({ BusiDesignationopt: GetOpts.BusiDesignation });
            //     }
            //     this.setState({
            //         totalpage: GetOpts.totalpage,
            //         totalrecords: GetOpts.totalrecords
            //     })
            const result = await cmsContent.GetJsonDatas("employee")
            this.setState({ data: result })
            this.setState({ loading: false })
            }

        catch (error) {
            console.log(error);
            this.setState({ loading: false })
        }
    }
    // Editpages = (value, modalWindowId) => {
    //     if (value.original.status == 0) {
    //         return (
    //             <button type="button" data-toggle="modal" data-target={`#${modalWindowId}`} className="btn btn-warning" onClick={() => this.onEdit(value)}>
    //                 {/* <button className="btn btn-info" onClick={() => this.onEdit(value)}> */}
    //                 Edit
    //             </button>
    //         );

    //     } else {
    //         return (<span className="label">Deleted</span>)
    //     }
    // };
    // onEdit = async (value) => {

    //     let d = value.original;
    //     console.log(d.BusinessDesignation.split(/\s/).join('').toUpperCase());
    //     if (d.BusinessDesignation) {
    //         if (isNaN(d.BusinessDesignation) == true) {
    //             let ansBusiDesignation = this.getValueFromArray1(d.BusinessDesignation, this.state.BusiDesignationopt, 'label')
    //             await this.setState({ ansBusiDesignation })
    //         } else {
    //             let ansBusiDesignation = this.getValueFromArray1(d.BusinessDesignation.split(/\s/).join('').toUpperCase(), this.state.BusiDesignationopt, 'value')
    //             await this.setState({ ansBusiDesignation })
    //         }
    //     }
    //     if (d.gender) {
    //         let ansgender = this.getValueFromArray1(d.gender, this.state.genderopt, 'label')
    //         await this.setState({ ansgender })
    //     }
    //     await this.setState({
    //         emp_name: d.employee_name,
    //         emp_id: d.employee_id,
    //         password: d.password,
    //         ansBusinessUnit: d.BusinessUnit, ansFunction: d.Function, ansSubFunction: d.SubFunction,
    //         ansRegion: d.Region, ansDivision: d.Division, ansBranch: d.Branch,
    //         ansoffEmailAdd: d.email, ansGrade: d.Grade, ansBand: d.Band,
    //         ansEmpCatType: d.EmpCatType, ansL1ManCode: d.L1ManCode,
    //         ansL1Man: d.L1Man, ansL2ManCode: d.L2ManCode, ansL2Man: d.L2Man, ansHRManCode: d.HRManCode, ansHRMan: d.HRMan,
    //         userid: d.userid,
    //         editId: d.id,
    //         isEdit: true
    //     });
    //     window.scrollTo({
    //         top: 0,
    //         left: 0,
    //         behavior: "smooth",
    //     });
    // };
    // buttondel = (d) => {
    //     if (d.original.status == 0) {
    //         return (
    //             <button
    //                 className="btn btn-success"
    //             // onClick={() => this.Delete(d)}
    //             >Active</button>
    //         );
    //     } else {
    //         return (
    //             <button
    //                 className="btn btn-danger"

    //                 onClick={() => this.Recover(d)}
    //             >Deleted</button>
    //             // <span className="label">Deleted</span>
    //         )

    //     }
    // }
    // Delete = d => {
    //     confirmAlert({
    //         customUI: ({ onClose }) => {
    //             return <div style={{ textAlign: "center" }}>
    //                 <h1>Delete confirmation</h1>
    //                 <h4>Are you sure ?</h4><br />
    //                 <button className="btn btn-primary" onClick={() => {
    //                     this.HandleDelete(d.original)
    //                     onClose();
    //                 }}  >Yes</button> &nbsp;  &nbsp;
    //                 <button className="btn btn-danger" onClick={onClose} >No</button>
    //             </div>
    //         }
    //     })
    // };
    // Recover = d => {
    //     confirmAlert({
    //         customUI: ({ onClose }) => {
    //             return <div style={{ textAlign: "center" }}>
    //                 <h1>Recover confirmation</h1>
    //                 <h4>Are you sure ?</h4><br />
    //                 <button className="btn btn-primary" onClick={() => {
    //                     this.RecoverData(d.original)
    //                     onClose();
    //                 }}  >Yes</button> &nbsp;  &nbsp;
    //                 <button className="btn btn-danger" onClick={onClose} >No</button>
    //             </div>
    //         }
    //     })
    // };
    // RecoverData = async (e) => {
    //     console.log(e, "ee");
    //     let { SelectedEnquiries } = this.state

    //     const RecoverUser = await cmsContent.recoverUser("cmndl_tbl_Employee", e)
    //     console.log(RecoverUser, "RecoverUser");
    //     if (RecoverUser) {
    //         // this.MainFun()
    //         setTimeout(() => {
    //             window.location.reload()
    //         }, 3000);



    //     }


    // }
    // HandleDelete = async (d) => {
    //     try {
    //         let obj = {}
    //         obj.status = d.status == 0 ? Date.now() : 0;
    //         const result = await cmsContent.updateMaster(
    //             "cmndl_tbl_Employee",
    //             d.id,
    //             obj
    //         )
    //         if (result) {
    //             this.MainFun();
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }
    // getValueFromArray1 = (d, array, ch = "value", ch1 = "label") => {
    //     if (array.length > 0) {
    //         if (array.length !== 0) {
    //             let filtered = array.filter(function (item) {
    //                 return item[ch] == d;
    //             });
    //             let v = filtered[0];
    //             if (v != undefined) {
    //                 return v;
    //             } else {
    //                 return '-';
    //             }
    //         }
    //     } else {
    //         return '-';
    //     }
    // };
    // handlechange = async (e, type, name) => {
    //     if (type == "Select") {
    //         await this.setState({
    //             [name]: e
    //         })
    //     }
    //     else if (type == "caps") {
    //         await this.setState({
    //             [e.target.name]: e.target.value.toUpperCase(), errorempname: "", errorCode: "", errgender: "", errBusinessUnit: "", errFunction: "", errSubFunction: "", errRegion: "", errDivision: "", errBranch: "", erroffEmailAdd: "", errGrade: "", errBand: "", errEmpCatType: "", errL1ManCode: "", errL1ManCode: "",
    //             errL1Man: "", errL2ManCode: "", errL2Man: "", errHRManCode: "", errHRMan: "", errBusiDesignation: "",
    //         });
    //     }
    //     else {
    //         await this.setState({ [e.target.name]: e.target.value, errorempname: "", errorCode: "" });
    //     }
    // };
    // submitCategory = async () => {
    //     const { emp_id, emp_name, password, isEdit, editId, userid,
    //         ansgender, ansBusinessUnit, ansFunction,
    //         ansSubFunction, ansRegion,
    //         ansDivision, ansBranch, ansoffEmailAdd, ansGrade, ansBand, ansEmpCatType, ansL1ManCode, ansL1Man, ansL2ManCode, ansL2Man,
    //         ansHRManCode, ansHRMan, ansBusiDesignation, customerId
    //     } = this.state;

    //     console.log(customerId, "customerId")
    //     if (customerId && parseInt(customerId) && parseInt(customerId) == 6075) {
    //         console.log("if")
    //         if (!emp_id) {
    //             this.setState({ errorCode: "Please Enter Employee ID " });
    //             return false;
    //         }
    //         else if (!emp_name) {
    //             this.setState({
    //                 errorempname: "Please Enter Employee Name ",
    //             });
    //             return false;
    //         }
    //         else if (!password) {
    //             this.setState({
    //                 errorpassword: "Please Enter Password ",
    //             });
    //             return false;
    //         }
    //     } else if (customerId && parseInt(customerId) && parseInt(customerId) == 807) {
    //         console.log("else")
    //         if (!emp_id) {
    //             this.setState({ errorCode: "Please Enter Employee ID " });
    //             return false;
    //         }
    //         else if (!emp_name) {
    //             this.setState({
    //                 errorempname: "Please Enter Employee Name ",
    //             });
    //             return false;
    //         }
    //         else if (!ansBusinessUnit) {
    //             this.setState({
    //                 errBusinessUnit: "Please Select Business Unit  ",
    //             });
    //             return false;
    //         }
    //         else if (!ansgender) {
    //             this.setState({
    //                 errgender: "Please Select Gender ",
    //             });
    //             return false;
    //         }
    //         else if (!ansFunction) {
    //             this.setState({
    //                 errFunction: "Please Enter Function ",
    //             });
    //             return false;
    //         }
    //         else if (!ansSubFunction) {
    //             this.setState({
    //                 errSubFunction: "Please Enter Sub Function ",
    //             });
    //             return false;
    //         }
    //         else if (!ansRegion) {
    //             this.setState({
    //                 errRegion: "Please Enter Region ",
    //             });
    //             return false;
    //         }
    //         else if (!ansDivision) {
    //             this.setState({
    //                 errDivision: "Please Enter Division  ",
    //             });
    //             return false;
    //         }
    //         else if (!ansBranch) {
    //             this.setState({
    //                 errBranch: "Please Enter Branch  ",
    //             });
    //             return false;
    //         }
    //         else if (!ansoffEmailAdd) {
    //             this.setState({
    //                 erroffEmailAdd: "Please Enter Office Email Address ",
    //             });
    //             return false;
    //         }
    //         else if (!ansGrade) {
    //             this.setState({
    //                 errGrade: "Please Enter Grade Name ",
    //             });
    //             return false;
    //         }
    //         else if (!ansBand) {
    //             this.setState({
    //                 errBand: "Please Enter Band  ",
    //             });
    //             return false;
    //         }
    //         else if (!ansEmpCatType) {
    //             this.setState({
    //                 errEmpCatType: "Please Enter Employee Category Type  ",
    //             });
    //             return false;
    //         }
    //         else if (!ansL1ManCode) {
    //             this.setState({
    //                 errL1ManCode: "Please Enter L1 Manager Code  ",
    //             });
    //             return false;
    //         } else if (!ansL1Man) {
    //             this.setState({
    //                 errL1Man: "Please Enter L1 Manager  ",
    //             });
    //             return false;
    //         } else if (!ansL2ManCode) {
    //             this.setState({
    //                 errL2ManCode: "Please Enter L2 Manager Code  ",
    //             });
    //             return false;
    //         } else if (!ansL2Man) {
    //             this.setState({
    //                 errL2Man: "Please Enter L2 Manager  ",
    //             });
    //             return false;
    //         } else if (!ansHRManCode) {
    //             this.setState({
    //                 errHRManCode: "Please Enter HR Manager Code  ",
    //             });
    //             return false;
    //         }
    //         else if (!ansHRMan) {
    //             this.setState({
    //                 errHRMan: "Please Enter HR Manager  ",
    //             });
    //             return false;
    //         } else if (!ansBusiDesignation) {
    //             this.setState({
    //                 errBusiDesignation: "Please Enter Business Designation  ",
    //             });
    //             return false;
    //         }
    //         else if (!password) {
    //             this.setState({
    //                 errorpassword: "Please Enter Password ",
    //             });
    //             return false;
    //         }
    //     } else {
    //         if (!emp_id) {
    //             this.setState({ errorCode: "Please Enter Employee ID " });
    //             return false;
    //         }
    //         else if (!emp_name) {
    //             this.setState({
    //                 errorempname: "Please Enter Employee Name ",
    //             });
    //             return false;
    //         }
    //         else if (!ansoffEmailAdd) {
    //             this.setState({
    //                 erroffEmailAdd: "Please Enter Office Email Address ",
    //             });
    //             return false;
    //         }
    //         else if (!password) {
    //             this.setState({
    //                 errorpassword: "Please Enter Password ",
    //             });
    //             return false;
    //         }
    //     }
    //     let categoryArray = {}
    //     categoryArray.employee_name = emp_name
    //     categoryArray.employee_id = emp_id.replace(/\s/g, "")
    //     categoryArray.password = password
    //     categoryArray.customerid = this.state.customerId
    //     categoryArray.gender = ansgender && ansgender.label ? ansgender.label : ""
    //     categoryArray.BusinessUnit = ansBusinessUnit ? ansBusinessUnit : ""
    //     categoryArray.Function = ansFunction ? ansFunction : ""
    //     categoryArray.SubFunction = ansSubFunction ? ansSubFunction : ""
    //     categoryArray.Region = ansRegion ? ansRegion : ""
    //     categoryArray.Division = ansDivision ? ansDivision : ""
    //     categoryArray.Branch = ansBranch ? ansBranch : ""
    //     categoryArray.email = ansoffEmailAdd ? ansoffEmailAdd : ""
    //     categoryArray.Grade = ansGrade ? ansGrade : ""
    //     categoryArray.Band = ansBand ? ansBand : ""
    //     categoryArray.EmpCatType = ansEmpCatType ? ansEmpCatType : ""
    //     categoryArray.L1ManCode = ansL1ManCode ? ansL1ManCode : ""
    //     categoryArray.L1Man = ansL1Man ? ansL1Man : ""
    //     categoryArray.L2ManCode = ansL2ManCode ? ansL2ManCode : ""
    //     categoryArray.L2Man = ansL2Man ? ansL2Man : ""
    //     categoryArray.HRManCode = ansHRManCode ? ansHRManCode : ""
    //     categoryArray.HRMan = ansHRMan ? ansHRMan : ""
    //     categoryArray.BusinessDesignation = ansBusiDesignation ? ansBusiDesignation.label : null

    //     if (editId) {
    //         categoryArray.id = editId ? editId : null
    //         categoryArray.userid = userid ? userid : null
    //     }
    //     console.log(categoryArray, "categoryArray");
    //     // this.setState({btnDisable: true })
    //     try {
    //         const UpdateEmployee = await cmsContent.CmndlEmployeeUpdate("cmndl_tbl_Employee",
    //             categoryArray)
    //         console.log(UpdateEmployee);
    //         if (UpdateEmployee) {
    //             if (UpdateEmployee && UpdateEmployee.data && UpdateEmployee.data.content == "Employee ID is Already Exist") {
    //                 await this.setState({
    //                     alertVisible: true,
    //                     badgeText: UpdateEmployee.data.content,
    //                     badgeColor: UpdateEmployee.data.color
    //                 });
    //             } else {
    //                 window.scrollTo({
    //                     top: 0,
    //                     behavior: "smooth"
    //                 });
    //                 this.setState({
    //                     errorempname: '', errorCode: '', emp_id: "", emp_name: "", password: '', ansgender: "",
    //                     ansgender: "", ansBusinessUnit: "", ansFunction: "", ansSubFunction: "",
    //                     ansRegion: "", ansDivision: "", ansBranch: "",
    //                     ansoffEmailAdd: "", ansGrade: "", ansBand: "",
    //                     ansEmpCatType: "", ansL1ManCode: "",
    //                     ansL1Man: "", ansL2ManCode: "", ansL2Man: "", ansHRManCode: "", ansHRMan: "", ansBusiDesignation: "",
    //                     errgender: "", errBusinessUnit: "", errFunction: "", errSubFunction: "", errRegion: "", errDivision: "", errBranch: "", erroffEmailAdd: "", errGrade: "", errBand: "", errEmpCatType: "", errL1ManCode: "",
    //                     errL1Man: "", errL2ManCode: "", errL2Man: "", errHRManCode: "", errHRMan: "", errBusiDesignation: "",
    //                     errorpassword: '',
    //                     isEdit: false,
    //                     editId: '',
    //                     btnDisable: false,
    //                     alertVisible: true,
    //                     badgeText: UpdateEmployee.data.content,
    //                     badgeColor: UpdateEmployee.data.color

    //                 });
    //                 this.MainFun()
    //                 setTimeout(() => this.setState({ alertVisible: false, badgeText: false, badgeColor: false }), 3000);
    //             }
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    // fileupload = e => {
    //     this.setState({ csv: e.target.files[0] })
    // }
    // submitcsv = async () => {
    //     const { usertypelog, status1, status2, status3 } = this.state;
    //     console.log(usertypelog);
    //     const files = this.state.csv;
    //     // var typelabel = usertypelog.orderBy == 9 ? "seller" : "buyer";
    //     const customerid = this.state.customerid;
    //     const ServiceId = 10;
    //     const formData = new FormData();
    //     formData.append("customerid", this.state.customerId);
    //     formData.append("file", files);
    //     console.log([...formData])
    //     console.log('log');
    //     try {
    //         const result = await CmsContent.CMNDLCSV("cmndl_tbl_Employee", formData);
    //         console.log(result, "result");
    //         if (result && result.data && result.data.added || result.data.Notadded) {
    //             console.log(result);
    //             await this.setState({
    //                 alertVisible: true,
    //                 badgeText: result.data.content1 ? result.data.content1 : result.data.content,
    //                 badgeColor: result.data.color1 ? result.data.color1 : result.data.color,
    //                 status1: '', status2: '', status3: '', added: result.data.added ? result.data.added : [],
    //                 notadded: result.data.Notadded ? result.data.Notadded : []
    //             })
    //             setTimeout(() => {
    //                 this.setState({
    //                     alertVisible: false, textalert: "",
    //                     badgeText: false, badgeColor: false,
    //                 }); this.MainFun();
    //             }, 2000);

    //         }
    //         else {
    //             await this.setState({
    //                 alertVisible: true, badgeText: result.data.content,
    //                 badgeColor: result.data.color, status1: '', status2: '', status3: ''
    //             })
    //             setTimeout(() => {
    //                 this.setState({
    //                     alertVisible: false, textalert: "", badgeText: result.data.content,
    //                     badgeColor: result.data.color
    //                 }); this.MainFun();
    //             }, 2000);
    //         }
    //     }
    //     catch (error) {
    //         console.log(error)
    //     }
    // };
    // reset = async () => {
    //     await this.setState({
    //         errorempname: '', errorCode: '', emp_id: "", emp_name: "", password: '', ansgender: "",
    //         ansgender: "", ansBusinessUnit: "", ansFunction: "", ansSubFunction: "",
    //         ansRegion: "", ansDivision: "", ansBranch: "",
    //         ansoffEmailAdd: "", ansGrade: "", ansBand: "",
    //         badgeText: false, badgeColor: false, alertVisible: false,
    //         ansEmpCatType: "", ansL1ManCode: "",
    //         ansL1Man: "", ansL2ManCode: "", ansL2Man: "", ansHRManCode: "", ansHRMan: "", ansBusiDesignation: "",
    //         errgender: "", errBusinessUnit: "", errFunction: "", errSubFunction: "", errRegion: "", errDivision: "", errBranch: "", erroffEmailAdd: "", errGrade: "", errBand: "", errEmpCatType: "", errL1ManCode: "",
    //         errL1Man: "", errL2ManCode: "", errL2Man: "", errHRManCode: "", errHRMan: "", errBusiDesignation: "",
    //         errorpassword: '', editId: false, userid: false, isEdit: false, alertVisible: false,
    //     })
    // }
    render() {
        const {
            badgeColor,
            badgeText,
            textalert,
            color,
            MOlist, //marketing off
            Saleslist, alertVisible, added, notadded, loading, unActiveUser
        } = this.state;
        console.log(added, notadded);
        // const togglePassword = document.querySelector('#togglePassword');
        // const password = document.querySelector('#password');
        // document.querySelector('#togglePassword').addEventListener('click',function(e) {
        //    this.classList.toggle('fa-eye-slash');
        //     const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
        //     password.setAttribute('type', type);
        // });
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Deleted Employee</h3>
                                    </div>
                                    <div className="card-body">

                                        {/* {unActiveUser ?
                                            <>
                                                <div className="d-flex justify-content-end">
                                                    <button type="button"
                                                        class="btn btn-primary waves-effect"
                                                        onClick={() => this.setState({ unActiveUser: false })}
                                                    > View all Users </button>
                                                </div>
                                                {/* <button > </button> */}

                                                {/* <br></br>
                                                <span>
                                                    Total of {Object.keys(this.state.deleteData).length} records
                                                </span>
                                                <br></br>
                                                <div className="row form-group">
                                                    <div className="col-sm-12">
                                                        {loading ? <div className="text-center">
                                                            < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                        </div> : this.state.deleteData ?
                                                            <>
                                                                <Datatable
                                                                    data={this.state.deleteData}
                                                                    columnHeading={this.column}
                                                                />
                                                                {/* ) */}
                                                            {/* </>
                                                            : <>No data Found</>}

                                                    </div>
                                                </div> */} 
                                            {/* </>  */}
                                            <>
                                                {/* <div className="d-flex justify-content-end">
                                                    <button type="button"
                                                        class="btn btn-primary waves-effect"
                                                        onClick={() => this.setState({ unActiveUser: true })}                                                                    // onClick={(e) => this.multyDelete(e)}
                                                    >View Delete Users only</button>
                                                </div> */}
                                                {/* <button > View Delete Users only </button> */}

                                                <br></br>
                                                <br></br>

                                                <p style={{fontSize:"20px",fontWeight:"500",padding:"3px 0px"}}>Total Number Of Deleted Employees :{this.state.data ?<>{this.state.data.length}</>:"0"}</p>
                                                <br></br>

                                                <div className="row form-group">
                                                    <div className="col-sm-12">
                                                        {loading ? <div className="text-center">
                                                            < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                        </div> : this.state.data ?
                                                            <>
                                                                <Datatable
                                                                    data={this.state.data}
                                                                    columnHeading={this.column}
                                                                />
                                                                {/* ) */}
                                                            </>
                                                            : <>No data Found</>}

                                                    </div>
                                                </div>
                                            </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {this.state.totalpage && this.state.totalpage > 1 && this.state.pageNumber && <Paginationfun
                        totalpage={this.state.totalpage}
                        page={this.state.pageNumber}
                        pagechage={async (e) => {
                            await this.setState({ pageNumber: e, loading: true, })
                            await this.MainFun()
                        }} />} */}
                </main>
            </React.Fragment>
        );
    }
}

export default RecoverEmployee;
