import React, { Component } from 'react';
import logo from '../../images/CMS/murugappa_logo1.png';
import { Link } from 'react-router-dom';
import CmsContent from '../../MiddleWare/CmsContent';

class CMSfooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      categoryArrray: [],
      fullList: [],
      themeId: null,
      adminId: JSON.parse(localStorage.getItem("userlog")).customerId
    };
  }

  async componentDidMount() {
    this.setState({ themeId: this.props.themeId });
    try {
      const result = await CmsContent.getFreedom(
        'tbl_mapping_page.id as subcatorderid,tbl_mapping_page.listingorder,tbl_mapping_page.listingsubcatagory,tbl_mapping_page.categoryId,tbl_mapping_page.categoryName,tbl_mapping_page.subCategoryId,tbl_mapping_page.subCategoryName,tbl_pages.id as pageId ,theme_master.themeName,tbl_pages.requireLogin',
        'tbl_mapping_page,tbl_pages,theme_master',
        `tbl_mapping_page.pageId = tbl_pages.id and tbl_pages.themeId =  theme_master.themeId and tbl_pages.customerId=${this.state.adminId} and tbl_pages.status="show" and  tbl_mapping_page.status = "active"`,
        'tbl_mapping_page.id',
        'tbl_mapping_page.listingorder,tbl_mapping_page.listingsubcatagory'
      );
      if (result) {

        this.setState({ categories: result.data });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async componentWillReceiveProps(nextProps) {
    this.setState({ themeId: null });
    setTimeout(() => {
      this.setState({ themeId: this.props.themeId });
    }, 500);
  }

  render() {
    const { categories, themeId } = this.state;

    const sortedCategory = categories.reduce((unique, o) => {
      if (!unique.some(obj => obj.categoryId === o.categoryId)) {
        unique.push(o);
      }
      return unique;
    }, []);

    let chunk_size = 5;
    let groups = '';
    let innerJsonData = [];

    if (sortedCategory.length > 0) {
      groups = sortedCategory
        .map(function (e, i) {
          return i % chunk_size === 0 ? sortedCategory.slice(i, i + chunk_size) : null;
        })
        .filter(function (e) {
          return e;
        });
      groups.map(innerData =>
        innerJsonData.push(
          <div class="col-12 col-sm-6 col-lg-2">
            <div class="footer-widget-area mt-80">
              {/* <h4 class="widget-title cmsfooter-title">Politics</h4> */}
              <ul class="list">
                {innerData.map(dataValue => (
                  (dataValue.categoryId===55)? (<li>
                    <Link className="dropdown-item text-capitalize" to={`/CMS/publication`}>
                      {dataValue.categoryName}
                    </Link>
                  </li>):(<li>
                    <Link className="dropdown-item text-capitalize" to={`/CMS/${dataValue.themeName}/${dataValue.pageId}`}>
                      {dataValue.categoryName}
                    </Link>
                  </li>) 
                ))}
              </ul>
            </div>
          </div>
        )
      );
    }
    let year = new Date().getFullYear();
    return (
      <div>
        <footer class="footer-area">
          <div class="main-footer-area">
            <div class="container">
              <div class="row">
                <div class="col-12 col-sm-6 col-lg-4">
                  <div class="footer-widget-area mt-80">
                    <div class="footer-logo">
                      <img src={logo} width="62%" alt="" />
                    </div>
                    <ul class="list" />
                  </div>
                </div>
                {innerJsonData}
              </div>
            </div>
          </div>
          <div class="bottom-footer-area">
            <div class="container h-100">
              <div class="row h-100 align-items-center">
                <div class="col-12">
                  <p>
                    Copyright &copy; {year} All rights reserved Powered by <a href="http://difuza.com/">difuza</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default CMSfooter;
