import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class TopComments extends Component {
  render() {
    return (
      <React.Fragment>
        <h1>
          <h3 className="panel-title">5 Most Popular News</h3>
        </h1>
        <br />
        <div class="single-popular-post">
          <Link>
            <h6 className="comment-panel-color">
              <span className="panel-title">1.</span> Amet, consectetur adipiscing elit. Nam eu metus sit amet odio sodales.
            </h6>
          </Link>
          <p>April 14, 2018</p>
        </div>

        <div class="single-popular-post">
          <Link>
            <h6 className="comment-panel-color">
              <span className="panel-title">2.</span> Eu metus sit amet odio sodales placer. Sed varius leo ac...
            </h6>
          </Link>
          <p>April 14, 2018</p>
        </div>

        <div class="single-popular-post">
          <Link>
            <h6 className="comment-panel-color">
              <span className="panel-title">3.</span> Adipiscing elit. Nam eu metus sit amet odio sodales placer. Sed varius leo.
            </h6>
          </Link>
          <p>April 14, 2018</p>
        </div>

        <div class="single-popular-post">
          <Link>
            <h6 className="comment-panel-color">
              <span className="panel-title">4.</span> Consectetur adipiscing elit. Nam eu metus sit amet odio sodales placer
            </h6>
          </Link>
          <p>April 14, 2018</p>
        </div>

        <div class="single-popular-post">
          <Link>
            <h6 className="comment-panel-color">
              <span className="panel-title">5.</span> Team India has won Seven Matches continously , Fisrt team To Qualify For The Semi-Finals ..
            </h6>
          </Link>
          <p>April 14, 2018</p>
        </div>
      </React.Fragment>
    );
  }
}

export default TopComments;
