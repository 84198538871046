import React, { Component } from "react";
import { ACCESS_POINT } from "../../config";
import { Alert } from "reactstrap";
import login from "../../MiddleWare/Login";
import CmsContent from "../../MiddleWare/CmsContent";
import PreLoader from "./preLoader";
import HomePanelList from "./HomePanelList";
import HomeInnerContentSection1 from "./HomeInnerContentSection1";
import HomeInnerContentSection2 from "./HomeInnerContentSection2";
import HomeInnerContentSection3 from "./HomeInnerContentSection3";
// import HomeInnerContentSection4 from "./HomeInnerContentSection4";
import HomePageFooter from "./HomePageFooter";
import ScriptTag from "react-script-tag";
import LoginModalPage from "./LoginModalPage";


const js2 =
  "/js/module_9795149073_Custom_Page_Scholes_Redesign_Parts_Slide_out_box.min.js";
const js3 =
  "/js/module_10021181382_Templates_Custom_Page_Scholes_Redesign_Parts_Global_logo_slider.js";

class UserHome extends Component {
  state = {
    showAboutus: true,
    mobileNumber: "",
    hideShow: false,
    Cmobile: "",
    Cemail: "",
    Show: false,
    SignIn: true,
    placeHolder: 1,
    place: 1,
    link: "",
    textalert: null,
    alertVisible: false,
    errorEmail: "",
    errormobile: "",
    errorMobileNumber: "",
    confirmation: false
  };
  async componentWillMount() {
    let split = ACCESS_POINT.split("/");
    if (split[2] === "localhost:5000") {
      // if (split[1] === "ehsportal.in") {
      // console.log(JSON.parse(localStorage.getItem("userlog")).customerId);
      let customerId =
        JSON.parse(localStorage.getItem("userlog")) === null
          ? this.setState({ customerId: 50 })
          : this.setState({
            customerId: JSON.parse(localStorage.getItem("userlog")).customerId
          });
    }
  }
  preLoader = () => {
    setTimeout(() => {
      document.getElementById("mainContent").style.display = 'block';
      document.getElementById("loaderContent").style.display = 'none';
    }, 2000)
  }
  async componentDidMount() {
    this.preLoader();
    try {
      const result = await CmsContent.getFreedom(
        "tbl_mapping_page.id as subcatorderid,tbl_mapping_page.listingorder,tbl_mapping_page.listingsubcatagory,tbl_mapping_page.categoryId,tbl_mapping_page.categoryName,tbl_mapping_page.subCategoryId,tbl_mapping_page.subCategoryName,tbl_pages.id as pageId ,theme_master.themeName,tbl_pages.requireLogin",
        "tbl_mapping_page,tbl_pages,theme_master,tbl_category_master",
        `tbl_mapping_page.pageId = tbl_pages.id and tbl_pages.themeId =  theme_master.themeId and tbl_pages.customerId=117 and tbl_mapping_page.categoryId = tbl_category_master.id and tbl_pages.status="show" and  tbl_mapping_page.status = "active"`,
        "tbl_mapping_page.id",
        "tbl_mapping_page.listingorder,tbl_mapping_page.listingsubcatagory"
      );

      if (result) {
        var categoryData = [];
        var fullList = [];
        var pubList = [];
        const map = new Map();
        for (const item of result.data) {
          if (!map.has(item.categoryId)) {
            map.set(item.categoryId, true);
            categoryData.push({ categoryId: item.categoryId });
          }
        }
        fullList = categoryData.map(values => {
          return result.data.filter(
            list => list.categoryId === values.categoryId
          );
        });
        // console.log(fullList);
        let categoryData1 = result.data.filter((ival, i) => {
          // if () {
          categoryData.push({ categoryId: ival.categoryId });
          // return ival.categoryId === 55;
          // }
        });
        categoryData1.map(values => {
          return result.data.filter(
            list => list.categoryId === values.categoryId
          );
        });

        await this.setState({
          data: result.data,
          resList: fullList,
          categoryData
        });
        this.navContent(fullList, categoryData);
        this.pubContent(categoryData1);
      }
    } catch (error) {
      console.log(error);
    }
  }
  handlechange = e => {
    let value = e.target.value;
    //console.log(value);
    this.setState({ Cmobile: value });
  };
  Email = e => {
    let value = e.target.value;
    //console.log(value);
    this.setState({ Cemail: value });
  };
  pubContent = pubList => {
    let PubContent = [];
    let innerContent = [];
    if (pubList !== []) {
      pubList.map((ival, i) => {
        // ival.map((jval, j) => {
        //   console.log(jval);
        //   if (j < 5) {
        let para = `/CMS/${ival.themeName}/${ival.pageId}`;
        innerContent.push(
          <li
            className="hs-menu-item hs-menu-depth-2 hs-item-has-children"
            role="menuitem"
          >
            <a role="menuitem" onClick={() => this.submitFunction(para)}>
              {ival && ival.subCategoryName}
            </a>
          </li>
        );
      });
    }
    PubContent.push(
      <ul className="hs-menu-children-wrapper">{innerContent}</ul>
    );
    this.setState({ PubContent });
  };

  _handleKeyDown = e => {
    if (e.key === "Enter") {
      if (this.state.placeHolder === 1) {
        this.handleSubmit();
      } else {
        this.handleSubmitOTP();
      }
    }
  };

  navContent = (resList, categoryData) => {
    let Content = [];
    let innerContent = [];
    resList.map((ival, i) => {
      innerContent.push(
        <li
          className="hs-menu-item hs-menu-depth-2 hs-item-has-children"
          role="menuitem"
        >
          <a href="#" role="menuitem">
            {ival && ival[0].categoryName}
          </a>
          <ul className="hs-menu-children-wrapper">
            {ival.map((jval, j) => {
              if (j < 5) {
                let para = `/CMS/${jval.themeName}/${jval.pageId}`;
                return (
                  <li className="hs-menu-item hs-menu-depth-3">
                    <a
                      role="menuitem"
                      onClick={() => {
                        this.submitFunction(para);
                      }}
                    >
                      {jval && jval.subCategoryName}
                    </a>
                  </li>
                );
              } else if (j === 5) {
                let para = `/CMS/More/${btoa(jval.categoryId)}`;
                return (
                  <li className="hs-menu-item hs-menu-depth-3">
                    <a
                      role="menuitem"
                      onClick={() => this.submitFunction(para)}
                    >
                      more..
                    </a>
                  </li>
                );
              }
            })}
          </ul>
        </li>
      );
    });
    Content.push(<ul className="hs-menu-children-wrapper">{innerContent}</ul>);
    this.setState({ Content });
  };

  showContactus = () => {
    this.setState({
      showAboutus: true,
      Cmobile: "",
      Cemail: ""
    });
  };

  showAboutus = async () => {
    this.setState({ showAboutus: !this.state.showAboutus });

    //console.log(this.state.showAboutus);
    if (this.state.showAboutus === true) {
    } else {
      this.setState({ showAboutus: false });

      let mobile = this.state.Cmobile;
      if (mobile === "") {
        this.setState({
          errormobile: "Enter Mobile Number"
        });
        return false;
      } else if (
        !/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/.test(mobile)
      ) {
        this.setState({ errormobile: "Invalid Mobile Number" });
        return false;
      } else {
        this.setState({ errormobile: "" });
      }

      let email = this.state.Cemail;
      if (email === "") {
        this.setState({ errorEmail: "Enter Email" });

        return false;
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        this.setState({ errorEmail: "Invalid Email" });
        return false;
      } else {
        this.setState({ errorEmail: "" });
      }
      let values = {};
      values.mobile = mobile;
      values.email_id = email;
      try {
        this.setState({ disableValue: true });
        const result = await CmsContent.addMaster("tbl_contactus", values);
        if (result) {
          this.setState({
            alertVisible: true,
            textalert: "submited",
            color: "success"
          });
          setTimeout(
            () =>
              this.setState({
                alertVisible: false,
                Cmobile: "",
                Cemail: "",
                showAboutus: true
              }),
            3000
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  handleUserInput(e) {
    this.setState({ [e.target.name]: e.target.value });
  }
  handleSubmitOTP = async para => {
    let mobileNumber = this.state.mobileNumber;
    if (mobileNumber === "") {
      this.setState({ errorMobileNumber: "Enter OTP" });
      return false;
    } else {
      this.setState({
        errorMobileNumber: ""
      });
    }
    try {
      const result = await login.loginOTPCheckPost(
        this.state.actualNum,
        mobileNumber
      );
      if (result.data.length) {
        this.setState({ hideShow: false, show: true });
        localStorage.setItem("userlog", JSON.stringify(result.data[0]));
        let userlog = localStorage.getItem("userlog");
        userlog = JSON.parse(userlog);
        localStorage.setItem("userType", userlog.userType);

        if (this.state.link) {
          window.location.href = this.state.link;
        } else {
          if (parseInt(userlog.userType) === 1) {
            this.props.history.replace("/admin");
          } else if (parseInt(userlog.userType) === 2) {
            this.props.history.replace("/subAdmin");
          } else if (parseInt(userlog.userType) === 3) {
            window.location.href = "/superAdmin";
          } else if (
            parseInt(userlog.userType) === 6 &&
            parseInt(userlog.serviceId) === 8
          ) {
            window.location.href = "/CMS/Home";
          }
        }
      } else {
        this.setState({ errorMobileNumber: "Incorrect OTP" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  submitFunction = async d => {
    let l1 = d;
    if (d) {
      this.setState({ link: l1 });
    }
    if (localStorage.getItem("userlog")) {
      if (d) {
        await this.setState({ link: d });
        window.location.href = this.state.link;
      } else {
        window.location.href = "/CMS/Home";
      }
    } else {
      if (d) {
        this.setState({ hideShow: true, link: d });
      } else {
        let link;
        if (this.state.link) {
          link = this.state.link;
        } else {
          link = "/CMS/Home";
        }
        this.setState({ hideShow: true, link: link });
        console.log(this.state.link);
      }

      if (this.state.placeHolder === 1) {
        this.handleSubmit();
      } else {
        this.handleSubmitOTP();
      }
    }
  };

  handleSubmit = async d => {
    let mobileNumber = this.state.mobileNumber;
    if (mobileNumber === "" && !this.state.link) {
      this.setState({ errorMobileNumber: "" });
      return false;
    } else if (mobileNumber === "") {
      this.setState({ errorMobileNumber: "Please enter mobile number" });
      return false;
    } else if (
      !/^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/.test(mobileNumber)
    ) {
      this.setState({ errorMobileNumber: "Invalid Mobile Number" });
      return false;
    } else {
      this.setState({ errorMobileNumber: "", actualNum: mobileNumber });
    }
    if (
      mobileNumber == 9500114666 ||
      mobileNumber == 9500114776 ||
      mobileNumber == 9500114777 ||
      mobileNumber == 9959022134 ||
      mobileNumber == 9879879879 ||
      mobileNumber == 9500114888 ||
      mobileNumber == 6666666661
    ) {
      this.setState({ hideShow: false, errorMobileNumber: "" });
      const result = await login.loginCheck(mobileNumber);
      if (result) {
        localStorage.setItem("userlog", JSON.stringify(result.data[0]));
        let userlog = localStorage.getItem("userlog");
        userlog = JSON.parse(userlog);
        localStorage.setItem("userType", userlog.userType);
        if (parseInt(userlog.userType) === 1) {
          this.props.history.replace("/admin");
        } else if (parseInt(userlog.userType) === 2) {
          this.props.history.replace("/subAdmin");
        } else if (parseInt(userlog.userType) === 3) {
          //this.props.history.replace('/superAdmin');
          window.location.href = "/superAdmin";
        } else if (
          parseInt(userlog.userType) === 6 &&
          parseInt(userlog.serviceId) === 8
        ) {
          if (this.state.link) {
            window.location.href = this.state.link;
          } else {
            window.location.href = "/CMS/Home";
          }
        }
      }
    } else {
      try {
        const result = await login.loginOTPCheck(mobileNumber);
        if (result) {
          if (result.data.res) {
            this.setState({
              mobileNumber: "",
              errorMobileNumber: "Your OTP has been sent",
              placeHolder: 2
            });
          } else {
            this.setState({
              errorMobileNumber: "Mobile Number does not exists"
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  logout = () => {
    if (localStorage.getItem("userlog")) {
      localStorage.clear();
      window.location = "/CMS/Home";
    }
  };
  render() {
    let {
      Cemail,
      Cmobile,
      errorEmail,
      errormobile,
      alertVisible,
      textalert
    } = this.state;
    let BBaner =
      `${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/image_1581056126531.jpg`;
    return (
      <React.Fragment>
        <div id="loaderContent" style={{ display: 'block' }}>
          <img
            style={{ position: "absolute", top: "40%", left: "44.5%" }}
            width="10%"
            src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/1200px-Murugappa_Group_Logo_1581051820908.png`}
          />
        </div>
        <div id="mainContent" style={{ display: 'none' }}>
          <div className="header-container-wrapper">
            <div className="header-container">
              <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
                <div className="row-fluid ">
                  <div
                    className="span12 widget-span widget-type-custom_widget "
                    style={{}}
                    data-widget-type="custom_widget"
                    data-x={0}
                    data-w={12}
                  >
                    {/*
                    <div
                      id="hs_cos_wrapper_module_1558373047236511"
                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                      style={{}}
                      data-hs-cos-general-type="widget"
                      data-hs-cos-type="module"
                    >

                      <div id="slideoutBoxWrapper" className="wider">
                        <div id="slideoutBoxToggle">
                          <span className="arrow-left">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path d="M229.9 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L94.569 282H436c6.627 0 12-5.373 12-12v-28c0-6.627-5.373-12-12-12H94.569l155.13-155.13c4.686-4.686 4.686-12.284 0-16.971L229.9 38.101c-4.686-4.686-12.284-4.686-16.971 0L3.515 247.515c-4.686 4.686-4.686 12.284 0 16.971L212.929 473.9c4.686 4.686 12.284 4.686 16.971-.001z" />
                            </svg>
                          </span>
                          <span className="arrow-right">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 448 512"
                            >
                              <path d="M218.101 38.101L198.302 57.9c-4.686 4.686-4.686 12.284 0 16.971L353.432 230H12c-6.627 0-12 5.373-12 12v28c0 6.627 5.373 12 12 12h341.432l-155.13 155.13c-4.686 4.686-4.686 12.284 0 16.971l19.799 19.799c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L235.071 38.101c-4.686-4.687-12.284-4.687-16.97 0z" />
                            </svg>
                          </span>
                        </div>
                        <div id="slideoutBox">
                          <div className="logo">
                            <span
                              id="hs_cos_wrapper_module_1558373047236511_"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_logo"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="logo"
                            >
                              <a
                                href="#"
                                id="hs-link-module_1558373047236511_"
                                style={{ borderWidth: "0px", border: "0px" }}
                              >
                                <img
                                  src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/1200px-Murugappa_Group_Logo_1581051820908.png`}
                                  className="hs-image-widget "
                                  style={{
                                    height: "40px",
                                    margin: 3,
                                    borderWidth: "0px",
                                    border: "0px"
                                  }}
                                  alt="Murugappa"
                                  title="Murugappa"
                                />
                              </a>
                            </span>
                          </div>
                          <div className="content">
                            <div
                              id="videoos"
                              style={{
                                display: this.state.showAboutus
                                  ? "block"
                                  : "none"
                              }}
                            >
                              <h3>Message from Mr.Manoj Jaiswal</h3>
                              <video
                                width="100%"
                                controls
                                style={{ marginTop: 8, width: "100%" }}
                              >
                                <source
                                  src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Manufacturing Reflection - YouTube_1568377265845.mp4`}
                                  type="video/mp4"
                                />
                                Your browser does not support HTML5 video.
                              </video>
                            </div>
                            <div
                              id="about"
                              style={{
                                display: !this.state.showAboutus
                                  ? "block"
                                  : "none"
                              }}
                            >
                              <button
                                type="button"
                                style={{
                                  position: "absolute",
                                  color: "#ff0000",
                                  top: "53x",
                                  textSize: 11,
                                  right: "7px",
                                  width: "20px",
                                  height: "20px",
                                  borderWidth: "2px",
                                  borderStyle: "solid",
                                  borderColor: "red",
                                  borderRadius: "100%",
                                  background:
                                    "-webkit-linear-gradient(-45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%), -webkit-linear-gradient(45deg, transparent 0%, transparent 46%, white 46%,  white 56%,transparent 56%, transparent 100%)",
                                  backgroundColor: "white",
                                  padding: "0px 0px 0px 0px"
                                }}
                                onClick={() => this.showContactus()}
                              >
                                X
                              </button>
                              <h3>Contact Us</h3>
                              <div>
                                <Alert
                                  className="badge-content"
                                  color={this.state.color}
                                  isOpen={alertVisible}
                                  toggle={this.onDismiss}
                                >
                                  {textalert}
                                </Alert>
                                <div className="row col-12 pt-3">
                                  <div className="col-3">Mobile&nbsp;:</div>
                                  <div className="col-9">
                                    <input
                                      type="text"
                                      name="subgroup"
                                      placeholder="Enter Mobile"
                                      className="form-control"
                                      maxLength="10"
                                      onChange={e => this.handlechange(e)}
                                      value={Cmobile}
                                    />
                                    <span className="error-shows">
                                      {errormobile}
                                    </span>
                                  </div>
                                </div>
                                <div className="row col-12 pt-3">
                                  <div className="col-3">Email&nbsp;:</div>

                                  <div className="col-9">
                                    <input
                                      type="text"
                                      name="subgroup"
                                      placeholder="Enter Email"
                                      className="form-control"
                                      value={Cemail}
                                      onChange={e => this.Email(e)}
                                    />
                                    <span className="error-shows">
                                      {errorEmail}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px"
                              }}
                            >
                              <button
                                id="buttonContent"
                                style={{
                                  color: "rgb(255, 255, 255)",
                                  border: "2px solid rgb(255, 0, 0)",
                                  backgroundColor: "rgb(255, 0, 0)",
                                  borderRadius: "5px",
                                  padding: "6px 18px",
                                  lineHeight: 1
                                }}
                                onClick={() => {
                                  this.showAboutus();
                                }}
                              >
                                {this.state.showAboutus
                                  ? "Contact Us"
                                  : "Submit"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <HomePanelList />
            </div>
          </div>

          <div className="body-container-wrapper">
            <div className="body-container">
              <HomeInnerContentSection1 />
              <HomeInnerContentSection2 />
              <HomeInnerContentSection3 />
              {/* <HomeInnerContentSection4 /> */}
              <div className="row-fluid-wrapper row-depth-1 row-number-12 ">
                <div className="row-fluid "></div>
              </div>

              <div className="footer-container-wrapper">
                <div className="footer-container">
                  <HomePageFooter />
                </div>
                {/*end footer */}
              </div>
              {/* number3 here */}
            </div>
          </div>
          {/* The Modal */}

          <div id="myModal" className="modal fade " role="dialog">
            {/* Modal content */}
            <div className={`modal-dialog `} role="document">
              <div className="modal-content">
                <div style={{ textAlign: "end", marginTop: "1%" }}>
                  <span
                    style={{ fontSize: 30, paddingRight: "2%" }}
                    data-dismiss="modal"
                  >
                    ×
                  </span>
                </div>
                <div style={{ padding: "5px 10px 10px 10px" }}>
                  <video
                    width="100%"
                    controls
                    style={{ marginTop: 8, width: "100%" }}
                  >
                    {/* //Manufacturing Reflection - YouTube_1568377265845.mp4
                    //final_5e94794055bba00015bbb664_756580_1586789094637.mp4 */}
                    <source
                      src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/final_5e94794055bba00015bbb664_756580_1586789094637.mp4`}
                      type="video/mp4"
                    />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div id="myModal1" className="modal fade " role="dialog">
            {/* Modal content */}
            <div className="modal-dialog modal-lg" role="document">
              <div
                className="modal-content"
                style={{ width: "100%", height: "600px" }}
              >
                <div style={{ textAlign: "end", marginTop: "1%" }}>
                  <span
                    style={{
                      fontSize: 30,
                      paddingRight: "2%",
                      cursor: "pointer"
                    }}
                    data-dismiss="modal"
                  >
                    ×
                  </span>
                </div>
                <div>
                  <LoginModalPage para={this.state.link} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <ScriptTag type="text/javascript" src={js2} />
        <ScriptTag type="text/javascript" src={js3} /> */}
      </React.Fragment>
    );
  }
}

export default UserHome;
