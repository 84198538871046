
import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const LearningObjpdf = ({ header, data,program,batch,domain ,res ,train,Loc,title="LEARNING OBJECT"}) => {
  
    let pro = [];
    let bat=[];
    let dom ="";
    let domain1= [];
    
    let resName1="";
    let resource1= [];
    let resName="";
    let trainee=[];
    let loc1=[];
   // var loca2="";
    data.map((item,i)=>{
        program.map((element,key)=>{
            if(item.programId == element.value){
                pro.push(element.label);
            }
        })
        batch.map((element,key)=>{
           if(item.batchid == element.value){
          bat.push(element.label);
          
            }
            
        })
        train.map((element,key)=>{
            if(item.trainerid == element.value){
            //  tran +=element.label;
             trainee.push(element.label)
             }
             
         })
        
         Loc.map((element,key)=>{
          if(item.location == element.value){
          //  loc1 +=element.label;
          loc1.push(element.label)
           //loca1=loca1;
           }
           
       })
      // loca2=loca2.replace(/,\s*$/, '');
       
               
        let domspt  = data[i].domainid.split(",")

        domspt.map((jval,j)=>{
             domain.map((kval,k)=>{
                 if(jval == kval.id){
                   resName1 += kval.label + ",";
                  //  console.log(resName1)
                  }
                })
                resName1 = resName1.replace(/,\s*$/, '');
              })
              domain1.push(resName1);
              resName1 = "";  
            



         let Ressplit  = data[i].resourceid.split(",")
         //let resName = "";
         Ressplit.map((jval,j)=>{
             res.map((kval,k)=>{
                 if(jval == kval.id){
                    
                     resName += kval.label + ","
                    }
                })
          
            resName = resName.replace(/,\s*$/, '');
          })
          resource1.push(resName);
          resName = "";  
      })
     // console.log(tran);
  return (
    <Document>
      <Page style={styles.body}>
      <View style={styles.title}>
         <Text>{title}</Text>
        </View>
        <View style={styles.table}>
        <View style={styles.tableRow}>
            {header &&
              header.map(value => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
          </View>

          {data &&
            data.map((value,index) => {

                
              return (
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{index+1}</Text>
                  </View>
                   <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{pro[index]}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{bat[index]}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.fromdate}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.enddate}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{domain1[index]}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{resource1[index]}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{trainee[index]}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{loc1[index]}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.cost}</Text>
                  </View>
                  
                  
                  
                </View>
              );
            })}
        </View>
      </Page>
    </Document>
  );
};
const styles = StyleSheet.create({
    
  body: {
    padding:1
  },
  table: {
    width: 240,
    marginTop:15,
    borderRadius:14

  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  
  },
  tableColHeader: {
    width: '15%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 2,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontSize:20,
   
    
  },
  tableCol: {
    width: '25%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 'auto',
    fontSize: 12,
    fontWeight: 500,
    paddingBottom:25
  },
  tableCell: {
    margin: 'auto',
    fontSize: 5
  },
  title:{
  fontSize:19,
  fontWeight:"bold",
  marginLeft:'38%',

  }
  });

export default LearningObjpdf;
