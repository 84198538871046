import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";


const Listpagepdf = ({
  header,
  data,
  page,
  title = "User Logs-March 15,2020 to June 3,2020"
}) => {
  console.log(data)
  var pages = [];
  if (data && data.length && data[0].pagename) {
    pages.push(data)
  }
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.title}>
          <Text>{title}</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>

            {header && pages.length > 0 &&
              header.map(value => {
                return (
                  <View style={styles2.tableCol}>
                    <Text style={styles2.tableCell}>{value}</Text>
                  </View>
                );
              })}

          </View>
          {data && pages.length > 0 &&
            data.map((value, index) => {
              return (
                <View style={styles2.tableRow}>
                  <View style={styles2.tableCol}>
                    <Text style={styles2.tableCell}>{index + 1}</Text>
                  </View>
                  <View style={styles2.tableCol}>
                    <Text style={styles2.tableCell}>{value.username}</Text>
                  </View>
                  <View style={styles2.tableCol}>
                    <Text style={styles2.tableCell}>{value.pagename}</Text>
                  </View>
                  <View style={styles2.tableCol}>
                    <Text style={styles2.tableCell}>{value.count}</Text>
                  </View>
                </View>
              );
            })}


        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: "5px"
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    //textAlign: "center"
  },
  tableColHeader: {
    width: "16.6%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: "33.3%%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: "auto",
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: "auto",
    margin: 5,
    fontSize: 10
  },
  title: {
    fontSize: 15,

    fontWeight: "bold",
    marginLeft: "8%",
    textAlign: "center"
  }
});

const styles2 = StyleSheet.create({
  body: {
    padding: 10
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: "5px"
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    //textAlign: "center"
  },
  tableColHeader: {
    width: "16.6%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: "28.3%%",
    borderStyle: "solid",
    borderColor: "#bfbfbf",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: "auto",
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: "auto",
    margin: 5,
    fontSize: 10
  },
  title: {
    fontSize: 15,
    fontWeight: "bold",
    marginLeft: "8%",
    textAlign: "center"
  }
});

export default Listpagepdf;
