import React, { Component } from "react";
import homepage1 from "../../images/CMS/homepage-module-1.jpg";
import homepage2 from "../../images/CMS/homepage-module-2.jpg";
import homepage3 from "../../images/CMS/homepage-module-3.jpg";
import topToBottom from "../../images/CMS/icon-godown.svg";
import backToTop from "../../images/CMS/icon-goup.svg";
import iconClose from "../../images/CMS/icon-hamburger-close.svg";
import iconOpen from "../../images/CMS/icon-hamburger-open.svg";
import logo from "../../images/core-img/murugappa_logo1.png";
import DownPanel from "./DownPanel";
import "./style.css";
import "./stylenew.css";
import UserHomeNav from "../Header/UserHomeNav";
import HomeBottomPanel from "./HomeBottomPanel";
import HomeMobileNav from "../Header/HomeMobileNav";
import { ACCESS_POINT } from '../../config';


// const video = require('../../images/CMS/Lite_ELECTRICAL SAFETY VIDEOS.mp4');

class Home extends Component {
  componentDidMount() {
    const script = document.createElement("script");

    script.src = "../assets/CMSHome/main.js";
    script.async = true;

    document.body.appendChild(script);

    
  }

  componentWillMount() {
   

    if (localStorage.getItem("title") == "Murugappa Safety Architecture") {
      document.getElementsByTagName(
        "title"
      )[0].innerHTML = localStorage.getItem("title");
      var x = document.getElementsByTagName("title")[0];
      
    }
    if (localStorage.getItem("title") == "difuza") {
      
      document.getElementsByTagName(
        "title"
      )[0].innerHTML = localStorage.getItem("title");
      var x = document.getElementsByTagName("title")[0];
    
    }
  }
  render() {
    return (
      <React.Fragment>
        <header id="top">
          <div className="header-bar dt mr-0" style={{ opacity: 1 }}>
            <div className="container mx-0 " style={{ maxWidth: "100%" }}>
              <div className="header-logo-holder ml-0-4">
                <a href="http://LinkToHome">
                  <img
                    className="home-logoMdc"
                    src={logo}
                    alt="Put the Red Murugappa Logo Here"
                  />
                </a>
              </div>
              <UserHomeNav />
            </div>
          </div>

          <div className="header-bar mobile">
            <div className="menu-head">
              <div className="header-logo-holder">
                <a href="http://thecolumbusbuilding.com/index.html">
                  <img
                    className="home-logoMdc-mobile"
                    src={logo}
                    alt="Murugappa - Environment, Health and Safety Portal"
                  />
                </a>
              </div>
              <div className="header-toggle">
                <div className="menu-toggle open">
                  <img src={iconOpen} alt="Menu open" />
                </div>
                <div className="menu-toggle close">
                  <img src={iconClose} alt="Menu close" />
                </div>
              </div>
            </div>
            <div className="menu-dropdown">
              <HomeMobileNav />
            </div>
          </div>
        </header>
        {/* Main */}
        <div className="hero">
          <div className="overlay-fadeout" style={{ opacity: 0 }} />
          <video
            autoplay="autoplay"
            loop=""
            muted="false"
            className="video-siz"
          >
            <source
              src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Manufacturing%20Reflection%20-%20YouTube_1568377265845.mp4`}
              type="video/mp4"
            />
          </video>
        </div>

        <div id="main-start" />
        <main>
          <section className="homepage">
            <div className="module-section">
              <div className="container" id="lockup-transition-4">
                <HomeBottomPanel />
              </div>
            </div>
          </section>
          {/*
          <section className="homepage">
            <div className="module-section home-page-second-module">
              <div className="container" id="lockup-transition-4">
                <HomeBottomPanel />
              </div>
            </div>
          </section>
          */}
        </main>
      </React.Fragment>
    );
  }
}

export default Home;
