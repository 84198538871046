import React, { Component } from 'react';
import { ACCESS_POINT } from '../../config';

class HomePageFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  scrollTop() {
    document.getElementById('root').scrollIntoView(true);
  }
  render() {
    return (
      <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
        <div className="row-fluid ">
          <div className="span12 widget-span widget-type-global_group site-footer" style={{}} data-widget-type="global_group" data-x={0} data-w={12}>
            {/* start coded_template: id:5182944415 path:generated_global_groups/5183543053.html */}
            <div className data-global-widget-path="generated_global_groups/5183543053.html">
              <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
                <div className="row-fluid ">
                  <div className="span12 widget-span widget-type-cell page-margins" style={{}} data-widget-type="cell" data-x={0} data-w={12}>
                    <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-space min24" style={{}} data-widget-type="space" data-x={0} data-w={12}>
                          <div className="cell-wrapper layout-widget-wrapper">
                            <span
                              id="hs_cos_wrapper_module_1495734207432618"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_space"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="space"
                            />
                          </div>
                          {/*end layout-widget-wrapper */}
                        </div>
                        {/*end widget-span */}
                      </div>
                      {/*end row*/}
                    </div>
                    {/*end row-wrapper */}
                    <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-linked_image footer-logo" style={{}} data-widget-type="linked_image" data-x={0} data-w={12}>
                          <div className="cell-wrapper layout-widget-wrapper">
                            {/* <a href="#root"> */}
                            <button id="#" title="Back to top" className="scrollUp" onClick={this.scrollTop}>
                              <i class="fa fa-angle-up"></i>
                            </button>
                            {/* </a> */}
                            <span
                              id="hs_cos_wrapper_logo"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_logo"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="logo"
                            >
                              <a
                                href="#"
                                id="hs-link-logo"
                                style={{
                                  borderWidth: '0px',
                                  border: '0px'
                                }}
                              >
                                <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Murugappa_Group_Logo_1581054673743.png`} />
                              </a>
                            </span>
                          </div>
                          {/*end layout-widget-wrapper */}
                        </div>
                        {/*end widget-span */}
                      </div>
                      {/*end row*/}
                    </div>
                    {/*end row-wrapper */}
                    <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-cell footer-menus" style={{}} data-widget-type="cell" data-x={0} data-w={12}>
                          <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
                            <div className="row-fluid ">
                              <div className="span12 widget-span widget-type-menu " style={{}} data-widget-type="menu" data-x={0} data-w={12}>
                                <div className="cell-wrapper layout-widget-wrapper">
                                  <span
                                    id="hs_cos_wrapper_module_1495734297481638"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu"
                                    style={{}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="menu"
                                  >
                                    <div
                                      id="hs_menu_wrapper_module_1495734297481638"
                                      className="hs-menu-wrapper active-branch no-flyouts hs-menu-flow-horizontal"
                                      role="navigation"
                                      data-sitemap-name="2017 Footer Top Menu"
                                      data-menu-id={5151506033}
                                      aria-label="Navigation Menu"
                                    >
                                      <ul>
                                        <li className="hs-menu-item hs-menu-depth-1">
                                          <a
                                            href="/CMS/Home"
                                            role="menuitem"
                                          // onClick={() => this.call()}
                                          >
                                            Home
                                          </a>
                                        </li>
                                        {/* <li className="hs-menu-item hs-menu-depth-1">
                                                        <a
                                                          className="atahfont16"
                                                          href="#"
                                                          role="menuitem"
                                                        >
                                                          MSA
                                                        </a>
                                                      </li> */}
                                        {/* <li className="hs-menu-item hs-menu-depth-1">
                                                        <a
                                                          className="atahfont16"
                                                          href="#"
                                                          role="menuitem"
                                                        >
                                                          About Us
                                                        </a>
                                                      </li> */}
                                        <li className="hs-menu-item hs-menu-depth-1">
                                          <a href="CMS/Dynamic/218" role="menuitem">
                                            Partnerships
                                          </a>
                                        </li>

                                        <li className="hs-menu-item hs-menu-depth-1">
                                          <a href="/CMS/Evaluation" role="menuitem">
                                            Evaluation
                                          </a>
                                        </li>
                                        <li className="hs-menu-item hs-menu-depth-1">
                                          <a href="/CMS/Gamification" role="menuitem">
                                            Gamification
                                          </a>
                                        </li>
                                        {/* <li className="hs-menu-item hs-menu-depth-1">
                                                        <a
                                                          className="atahfont16"
                                                          href="#"
                                                          role="menuitem"
                                                        >
                                                          Resources
                                                        </a>
                                                      </li> */}
                                        <li className="hs-menu-item hs-menu-depth-1">
                                          <a href="/CMS/Home" role="menuitem">
                                            Sign In
                                          </a>
                                        </li>
                                        <li className="hs-menu-item hs-menu-depth-1">
                                          <a href="/CMS/Publication" role="menuitem">
                                            References
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </span>
                                </div>
                                {/*end layout-widget-wrapper */}
                              </div>
                              {/*end widget-span */}
                            </div>
                            {/*end row*/}
                          </div>
                          {/*end row-wrapper */}
                          <div className="row-fluid-wrapper row-depth-1 row-number-6 ">
                            <div className="row-fluid ">
                              <div className="span12 widget-span widget-type-menu " style={{}} data-widget-type="menu" data-x={0} data-w={12}>
                                <div className="cell-wrapper layout-widget-wrapper">
                                  <span
                                    id="hs_cos_wrapper_module_1495734300065640"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_menu"
                                    style={{}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="menu"
                                  >
                                    {/* <div id="hs_menu_wrapper_module_1495734300065640"
                                                                                    class="hs-menu-wrapper active-branch no-flyouts hs-menu-flow-horizontal"
                                                                                    role="navigation"
                                                                                    data-sitemap-name="2017 Footer Bottom Menu"
                                                                                    data-menu-id="5150067571"
                                                                                    aria-label="Navigation Menu">
                                                                                    <ul>
                                                                                        <li
                                                                                            class="hs-menu-item hs-menu-depth-1">
                                                                                            <a href="contact.html"
                                                                                                role="menuitem">Gamification</a>
                                                                                        </li>
                                                                                        <li
                                                                                            class="hs-menu-item hs-menu-depth-1">
                                                                                            <a href="https://app.Murugappa/login"
                                                                                                role="menuitem">Resources</a>
                                                                                        </li>
                                                                                        <li
                                                                                            class="hs-menu-item hs-menu-depth-1">
                                                                                            <a href="product-demo.html"
                                                                                                role="menuitem">Sign
                                                                                                In</a>
                                                                                        </li>
                                                                                </ul>
                                                                        </div> */}
                                  </span>
                                </div>
                                {/*end layout-widget-wrapper */}
                              </div>
                              {/*end widget-span */}
                            </div>
                            {/*end row*/}
                          </div>
                          {/*end row-wrapper */}
                        </div>
                        {/*end widget-span */}
                      </div>
                      {/*end row*/}
                    </div>
                    {/*end row-wrapper */}
                    <div className="row-fluid-wrapper row-depth-1 row-number-7 ">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-raw_jinja " style={{}} data-widget-type="raw_jinja" data-x={0} data-w={12}>
                          <div className="social-links">
                            {/* <a href="https://itunes.apple.com/us/app/Murugappa-industrial-photo/id1142645268?mt=8"
                                                                    target="_blank"><img
                                                                        src="hubfs/Download_on_the_App_Store_Badge.svg"
                                                                        alt="Available on the App Store"
                                                                        border="0"></a><br><br> */}
                            <a href="https://twitter.com/Smartvidio" alt="Twitter" title="Twitter" target="_blank">
                              <i className="fa fa-twitter" aria-hidden="true" />
                            </a>{' '}
                            &nbsp;
                            <a href="https://www.facebook.com/smartvidio1" alt="Facebook" title="Facebook" target="_blank">
                              <i className="fa fa-facebook" aria-hidden="true" />
                            </a>
                            &nbsp;
                            <a href="https://www.linkedin.com/company/Murugappa" alt="LinkedIn" title="LinkedIn" target="_blank">
                              <i className="fa fa-linkedin" aria-hidden="true" />
                            </a>
                            &nbsp;
                            <a href="https://www.youtube.com/channel/UCagB-7VpLVaVYep6slx094w" alt="YouTube" title="YouTube" target="_blank">
                              <i className="fa fa-youtube-play" aria-hidden="true" />
                            </a>
                          </div>
                        </div>
                        {/*end widget-span */}
                      </div>
                      {/*end row*/}
                    </div>
                    {/*end row-wrapper */}
                    <div className="row-fluid-wrapper row-depth-1 row-number-8 ">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-text copyright" style={{}} data-widget-type="text" data-x={0} data-w={12}>
                          <div className="cell-wrapper layout-widget-wrapper">
                            <span
                              id="hs_cos_wrapper_module_1495734430186693"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_text"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="text"
                            >
                              © 2020 murugappa, Inc. All rights reserved. <br />{' '}
                              <a href="#" style={{ color: '#ffffff' }}>
                                Privacy Policy
                              </a>
                            </span>
                          </div>
                          {/*end layout-widget-wrapper */}
                        </div>
                        {/*end widget-span */}
                      </div>
                      {/*end row*/}
                    </div>
                    {/*end row-wrapper */}

                    <div className="row-fluid-wrapper row-depth-1 row-number-9 ">
                      <div className="row-fluid ">
                        <div className="span12 widget-span widget-type-space h10" style={{}} data-widget-type="space" data-x={0} data-w={12}>
                          <div className="cell-wrapper layout-widget-wrapper">
                            <span
                              id="hs_cos_wrapper_module_1495734218017620"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_space"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="space"
                            />
                          </div>
                          {/*end layout-widget-wrapper */}
                        </div>
                        {/*end widget-span */}
                      </div>
                      {/*end row*/}
                    </div>
                    {/*end row-wrapper */}
                  </div>
                  {/*end widget-span */}
                </div>
                {/*end row*/}
              </div>
              {/*end row-wrapper */}
            </div>
            {/* end coded_template: id:5182944415 path:generated_global_groups/5183543053.html */}
          </div>
          {/*end widget-span */}
        </div>
        {/*end row*/}
      </div>
    );
  }
}

export default HomePageFooter;
