import React, { Component } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import socket from "../../../MiddleWare/socketMiddleWare";
import CmsContent from "../../../MiddleWare/CmsContent";
import { Link } from "react-router-dom";
import SingleSelect from "../../../components/Form/SingleSelect";

class VideoCurrentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageList: [],
      setpage: null,
      userList: [],
      setuser: null,
      videoList: [],
      setvideo: null,
      videoLists: [],
      userListData: {},
      videoCount: null,
      resultArray: [],
      induce: null,
      customerId: localStorage.getItem("userId"),
    
    };
  }

  column = [
    {
      Header: "Name",
      accessor: "videoName"
    },
    {
      Header: "Viewers Count",
      accessor: "count"
    },
    {
      Header: "Current Audience",
      accessor: "view",
      Cell: d => this.videoViews(d)
    }
  ];

  videoViews = videoContent => {
    let videoId = videoContent.original.videoId;

    return (
      <Link
        className="nav-link"
        target="_blank"
        to={`/cmsSelectedVideoView/${videoId}`}
      >
        <button type="button" className="btn btn-info">
          <span style={{ color: "#ffffff" }}>View Users</span>
        </button>
      </Link>
    );
  };

  async componentDidMount() {
    try {
       const pageList = await CmsContent.getFreedom(" tbl_pages.id as value,tbl_pages.name as label ","tbl_pages",`customerId=${this.state.customerId}`);
console.log(pageList.data)
      this.setState({ pageList: pageList.data });
      const userList = await CmsContent.getFreedom("id as value , userName as label ", "tbl_user_web",`userType=6 and customerId=${this.state.customerId} and serviceId =8 and status='active'`);
       //.getCompanyUsers("6", "6");
      this.setState({ userList: userList.data });
      // const videoLists = await CmsContent.getFreedom('tbl_image.id as value , tbl_image.file as label,tbl_image.type as hint',"tbl_image",` customerId=${this.state.customerId} and file_extension = "mp4"  || file_extension = "mkv"`);
    
      //const videoLists = await CmsContent.getLibraryImage("video");  
      //getLibraryImage("video");  
 
     //this.setState({ videoLists: videoLists.data });
     

      const videoList = await CmsContent.getVideoListForAdmin();
      if (videoList) {
        socket.emit("connection");
        socket.on(`responseTimestamp${this.state.customerId}`, data => {
         

          let userViewData = [];
          let filteredArray = [];
          let videoFilteredArray = [];
          let finalListViewArray = [];
          var userListData = this.state.userListData;
          if (data) {
            userListData[data.userName] = parseInt(data.videoId);
            const entries = Object.entries(userListData);
            entries.map(values => userViewData.push(Object.assign({}, values)));

            userViewData.map(allList => {
              let filteredList = videoList.data.filter(
                val => val.videoId === allList[1]
              );
              filteredList[0].userName = allList[0];
              filteredArray.push(filteredList[0]);
            });
            

            filteredArray.map(list => {
              if (videoFilteredArray.includes(list.videoId)) {
                list.count = list.count + 1;
              } else {
                list.count = 1;
                finalListViewArray.push(list);
              }
              this.setState({ resultArray: [] });
              videoFilteredArray.push(list.videoId);
              this.setState({ resultArray: finalListViewArray });
              

              if (this.state.induce) {
                finalListViewArray = finalListViewArray.filter(
                  value =>
                    value.videoId === this.state.setvideo ||
                    data.pageName === this.state.setpage ||
                    finalListViewArray[0].userName === this.state.setuser
                );
              }
              
              this.setState({ data: finalListViewArray });
              
            });
          }
        });
      }
     
    } catch (error) {
      console.log(error);
    }
  }

  removeDuplicate = (arr, prop) => {
    let obj = {};
    return Object.keys(
      arr.reduce((prev, next) => {
        if (!obj[next[prop]]) obj[next[prop]] = next;
        return obj;
      }, obj)
    ).map(i => obj[i]);
  };
  filterByVideo = e => {
    this.setState({ setvideo: e.value, induce: null });
  };
  filterByUser = e => {
    this.setState({ setuser: e.label, induce: null });
  };
  filterByPage =async e => {
    console.log(e.value)
    this.setState({ setpage: e.label, induce: null });
     let videoLists = await CmsContent.getFreedom("tbl_pages.id as value,tbl_pages.file as label","tbl_pages",`id=${e.value} and file_extension='mp4'`,'1','1');  

 
     this.setState({ videoLists: videoLists.data });
     
  };
  submit = () => {
    this.setState({ induce: 1 });
  };
  render() {
    const { data, pageList, userList, videoLists } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Add Datatable</h3>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="pagename">Page Name</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          name="theme"
                          options={pageList}
                          handleChange={this.filterByPage}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="username">User Name</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          name="username"
                          options={userList}
                          handleChange={this.filterByUser}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="video">Video Name</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          name="video"
                          options={videoLists}
                          handleChange={this.filterByVideo}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.submit}
                        >
                          Submit
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <br />
                    {data && (
                      <Datatable data={data} columnHeading={this.column} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default VideoCurrentView;
