// import React, { Component } from "react";
// import SingleSelect from "../../../components/Form/SingleSelect";
// import CmsContent from "../../../MiddleWare/CmsContent";
// import MultiSelect from "../../../components/Form/MultiSelect";
// import { Alert } from "reactstrap";
// import Datatable from "../../../components/Datatable/Datatable";
// import FormMiddleWare from "../../../components/Form/FormMiddleware";
// import { confirmAlert } from "react-confirm-alert";

// class ManagerToMentor extends FormMiddleWare {
//   constructor(props) {
//     super(props);
//     this.state = {
//       data: [],
//       categoryOptions: [],
//       categoryOption: [],
//       categorySelected: [],
//       managerOptions: [],
//       managerOption: [],
//       selectedmanager: [],
//       editvalue: null,
//       categoryId: null,
//       alertVisible: false,
//       disableValue: false,
//       disableGroup: false,
//       button: "Submit",
//       textalert: null,
//       color: "success"
//     };
//   }

//   async componentDidMount() {
//     try {
//       let customerId = await localStorage.getItem("userId");
//       let serviceId = await localStorage.getItem("currentService");

//       //let result = await CmsContent.getMasterValues('tbl_group');
//       //   let result = await CmsContent.getConditionedValuewithStatus(
//       //     "tbl_group",
//       //     "customerId",
//       //     customerId,
//       //     "id",
//       //     "name"
//       //   );

//       //   if (result) {
//       //     this.setState({ categoryOptions: result.data });
//       //   }

//       let result = await CmsContent.getCompanyUsers(customerId, 4, serviceId);
//       if (result) {
//         this.setState({ managerOptions: result.data });
//       }

//       result = await CmsContent.getCompanyUsers(customerId, 8, serviceId);
//       if (result) {
//         this.setState({ mentorOptions: result.data });
//       }

//       result = await CmsContent.getAllSubtitleList("tbl_maptogroup");
//       if (result) {
//         this.setState({ data: result.data });
//       }
//       this.check();
//     } catch (error) {
//       console.log(error);
//     }
//   }

//   column = [
//     {
//       Header: "Cohort",
//       accessor: "label"
//     }
//     // {
//     //   Header: "Edit",
//     //   accessor: "edit",
//     //   Cell: d =>
//     //     this.dataTableButton("warning", "Edit", () => {
//     //       this.edition(d.original);
//     //     })
//     // },
//     // {
//     //   Header: "Un-Map All",
//     //   accessor: "delete",
//     //   Cell: d =>
//     //     this.dataTableButton("danger", "Un-Map", () => {
//     //       this.buttonDeletes(d);
//     //     })
//     // }
//   ];
//   edition = d => {
//     this.setState({ button: "Update" });
//     let { data: mapToGroup, managerOptions: managerList } = this.state;
//     this.programSelect(d);

//     let managers = mapToGroup
//       .map((ival, i) => {
//         if (ival.groupid == d.value) {
//           let returnArray = {};
//           returnArray.value = ival.userid;

//           returnArray.label = this.getValueFromArray(ival.userid, managerList);

//           return returnArray;
//         }
//       })
//       .filter(function(element) {
//         return element !== undefined;
//       });
//     this.managerSelect(managers);
//     this.setState({
//       button: "Update"
//       // disableGroup: true,
//       // alertVisible: true,
//       // textalert: "Map To Group Updated",
//       // color: "success"
//     });
//     // setTimeout(() => this.setState({ alertVisible: false }), 3000);
//   };
//   buttonDeletes = value => {
//     this.setState({});
//     confirmAlert({
//       title: "Confirmation to Delete",
//       message: "Are you sure you want to delete this?",
//       buttons: [
//         {
//           label: "Yes",
//           onClick: () => this.deletion(value)
//           //onClick: () => alert("Group removed from")
//         },
//         {
//           label: "No"
//         }
//       ]
//     });
//   };
//   deletion = async d => {
//     let index = d.index;
//     let value = d.original.value;
//     let result = await CmsContent.getSingleConditionedValue(
//       "tbl_maptogroup",
//       "groupid",
//       value,
//       "Delete"
//     );
//     if (result) {
//       let categoryOptions = this.state.categoryOptions;
//       this.setState({ categoryOption: [] });
//       categoryOptions.splice(index, 1);
//       this.setState({ categoryOptions });
//       this.check();
//     }
//   };
//   check = () => {
//     let { categoryOptions: managerList, data: mapToGroup } = this.state;
//     var newManagerList = [];
//     Object.keys(managerList).forEach(key => {
//       var checkJson = this.filterBy(mapToGroup, {
//         groupid: managerList[key].value
//       });
//       if (Object.keys(checkJson).length !== 0) {
//         newManagerList.push(managerList[key]);
//       }
//     });
//     this.setState({ categoryOption: newManagerList });
//   };
//   programSelect = async e => {
//     this.setState({ categorySelected: e, categoryId: e.value });

//     if (this.state.button === "Submit") {
//       let { data: mapToGroup, managerOptions: managerList } = this.state;

//       var newManagerList = [];
//       Object.keys(managerList).forEach(key => {
//         var checkJson = this.filterBy(mapToGroup, {
//           groupid: e.value,
//           userid: managerList[key].value
//         });
//         if (Object.keys(checkJson).length == 0) {
//           newManagerList.push(managerList[key]);
//         }
//       });

//       this.setState({
//         managerOption: newManagerList
//       });
//     } else {
//       this.setState({
//         managerOption: this.state.managerOptions,
//         disableGroup: true,
//         alertVisible: true,
//         textalert: "Map To Group Updated",
//         color: "success"
//       });
//     }
//   };
//   managerSelect = async e => {
//     this.setState({ selectedmanager: e });
//   };
//   mentorSelect = async e => {
//     if (e) {
//       this.setState({ selectedmentor: e });
//     }
//   };

//   onSubmit = async () => {
//     const { selectedmanager, selectedmentor } = this.state;
//     let customerId = await localStorage.getItem("userId");
//     let selectedValue = "";
//     const value = selectedmentor.map(
//       values => (selectedValue += `${values.value},`)
//     );
//     selectedValue = selectedValue.replace(/,\s*$/, "");
//     const formData = new FormData();
//     let status = this.state.button === "Submit" ? "active" : "deactive";
//     formData.append("managerId", selectedmanager.value);
//     formData.append("mentorId", selectedValue);
//     formData.append("customerid", customerId);

//     console.log([...formData]);
//     if (
//       selectedmanager == "" ||
//       selectedmanager == null ||
//       selectedmanager == undefined
//     ) {
//       this.setState({ errorselect: "select manager" });
//       return false;
//     } else if (
//       selectedmentor == "" ||
//       selectedmentor == null ||
//       selectedmentor == undefined
//     ) {
//       this.setState({ errorselect: "", error: "select mentor" });
//       return false;
//     } else {
//       this.setState({ error: "" });
//     }
//     this.setState({
//       alertVisible: true,
//       disableValue: true,
//       selectedmentor: "",
//       categorySelected: ""
//     });
//     // setTimeout(() => this.setState({ alertVisible: false }), 3000);
//     try {
//       let result = await CmsContent.learningObjectSubmit(
//         "tbl_MangerToMentor",
//         formData
//       );
//       if (result) {
//         result = await CmsContent.getAllSubtitleList("tbl_MangerToMentor");
//         if (result) {
//           this.setState({
//             data: result.data,
//             button: "Submit",
//             disableValue: false,
//             disableGroup: false,
//             alertVisible: true,
//             textalert: "Mentors Mapped to Manager",
//             color: "success"
//           });
//           setTimeout(() => this.setState({ alertVisible: false }), 3000);
//           this.check();
//         }
//       }
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   onDismiss = () => {
//     this.setState({ alertVisible: false });
//   };

//   render() {
//     const {
//       categoryOptions,
//       alertVisible,
//       learnerOption,
//       disableValue,
//       errorselect,
//       error,
//       button,
//       categorySelected,
//       selectedlearner,
//       disableGroup,
//       textalert,
//       selectedmanager,
//       selectedmentor,
//       managerOptions,
//       mentorOptions
//     } = this.state;
//     return (
//       <React.Fragment>
//         <main className="main my-4">
//           <div className="container-fluid">
//             <div className="row">
//               <div className="col-12">
//                 <div className="card">
//                   <div className="card-header">
//                     <h3>Map Manager To Mentors</h3>
//                   </div>
//                   <div className="card-body">
//                     <div className="row form-group">
//                       <div className="col-sm-2" />
//                       <div className="col-sm-7">
//                         <Alert
//                           className="badge-content"
//                           color={this.state.color}
//                           isOpen={alertVisible}
//                           toggle={this.onDismiss}
//                         >
//                           {textalert}
//                         </Alert>
//                       </div>
//                       <div className="col-sm-3" />
//                     </div>

//                     <div className="row form-group">
//                       <div className="col-sm-2" />
//                       <div className="col-sm-2">
//                         <label for="exampleInputEmail1">Manager</label>
//                       </div>
//                       <div className="col-sm-5">
//                         <SingleSelect
//                           disabled={disableGroup}
//                           options={managerOptions}
//                           handleChange={this.managerSelect}
//                           selectedService={selectedmanager}
//                         />
//                         <span className="error-shows">{errorselect}</span>
//                       </div>
//                       <div className="col-sm-3" />
//                     </div>

//                     <div className="row form-group">
//                       <div className="col-sm-2" />
//                       <div className="col-sm-2">
//                         <label htmlFor="subgroup">Mentor</label>
//                       </div>
//                       <div className="col-sm-5">
//                         <MultiSelect
//                           options={mentorOptions}
//                           handleChange={this.mentorSelect}
//                           selectedService={selectedmentor}
//                         />
//                         <span className="error-shows">{error}</span>
//                       </div>
//                       <div className="col-sm-3" />
//                     </div>
//                     <div className="row form-group">
//                       <div className="col-sm-2" />
//                       <div className="col-sm-2" />
//                       <div className="col-sm-5">
//                         <button
//                           type="button"
//                           className="btn btn-primary"
//                           disabled={disableValue}
//                           onClick={this.onSubmit}
//                         >
//                           {this.state.button}
//                         </button>
//                       </div>
//                       <div className="col-sm-3" />
//                     </div>
//                     <span>
//                       Total of {Object.keys(this.state.categoryOption).length}{" "}
//                       records
//                     </span>
//                     {this.state.categoryOption && (
//                       <Datatable
//                         data={this.state.categoryOption}
//                         columnHeading={this.column}
//                       />
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </main>
//       </React.Fragment>
//     );
//   }
// }

// export default ManagerToMentor;
