import React from "react";
import CmsContent from "../../../../MiddleWare/CmsContent";
import MultiSelect from "../../../../components/Form/MultiSelect";
import SingleSelect from "../../../../components/Form/SingleSelect";
import LoginModal from "../../../../components/Modal/Modal";
import { Alert } from "reactstrap";



class Type4 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            button:this.props.button,
            customerId: localStorage.getItem("userId"),
            selectedresource: [],
            resourceAlert:false,
            errordomain:'',
            domainOptions: []

          }
 
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps) {
          this.setState({
            button: nextProps.button,
            boxname: nextProps.boxname,
            boxvalue: nextProps.boxvalue,
            thumbnail: nextProps.thumbnail,
            traytext: nextProps.traytext,
            portletHeading: nextProps.portletHeading,
            descriptionText: nextProps.descriptionText,
            newdate: nextProps.newdate
          });
        }
      }

      resourceSelect = async e => {
        if (e) {
          this.setState({ selectedresource: e });
        }
      };

    
      
      async componentWillMount ()
      {


        const result = await CmsContent.getSelectvalue(
            "tbl_resources",
            "companyid",
            this.state.customerId
          );
          if (result) {
            this.setState({ resourceOptions: result.data });
          }

          const category = await CmsContent.getConditionedValuewithStatus(
            "tbl_domain",
            "companyid",
            this.state.customerId
          );
          if (category) {
            this.setState({ domainOptions: category.data });
          }

      }
      
      getMultiSelectValue = (data, type) => {
        let selectedValue = "0";
        if (data.length !== 0) {
          selectedValue = "";
    
          if (type === "0") {
            const value = data.map(
              values => (selectedValue += `'${values.value}',`)
            );
            selectedValue = selectedValue.replace(/,\s*$/, "");
            var lastChar = selectedValue.slice(-1);
            if (lastChar === ",") {
              selectedValue = selectedValue.slice(0, -1);
            }
          } else if (type === "1") {
            const value = data.map(values => (selectedValue += `${values.value},`));
            selectedValue = selectedValue.replace(/,\s*$/, "");
          }
        }
        return selectedValue;
      };
    
      onSubmit = () =>{


if(!this.state.boxname) 
{

this.props.errormessageboxname('errorname','Please Enter Box Name');
console.log('boxname empty')

}
else
{
 this.props.errormessageboxname('errorname',''); 

}


if(!this.state.portletHeading) 
{
 console.log('portletHeading empty')
 this.props.errormessageheading('errorheading','Please Enter Portlet Heading');

}
 
else
{
 this.props.errormessageheading('errorheading',''); 

}

if(!this.state.thumbnail) 
{
  this.props.errorthumbnail('errorthumbnail','Please Select a thumbnail');

}
 
else
{
 this.props.errorthumbnail('errorthumbnail',''); 

}


        let selectedValue = "";
        const value = this.state.selectedresource.map(
          values => (selectedValue += `${values.value},`)
        );
        selectedValue = selectedValue.replace(/,\s*$/, "");

        let formData = new FormData();
        formData.append("name", this.state.boxname);
        formData.append(
          "text",
          this.getMultiSelectValue(this.state.selectedresource, "1")
        );
        formData.append("type", this.state.boxvalue);
        formData.append("status", "active");
        formData.append("customerId", this.state.customerId);
        formData.append("thumbnail", this.state.thumbnail);
        formData.append("notificationtext", this.state.traytext);
        formData.append("heading", this.state.portletHeading);
        formData.append("description", this.state.descriptionText);
        formData.append("createdAt",this.state.newdate);

        
       


 if(this.state.boxname && this.state.thumbnail && this.state.portletHeading )
 {

  console.log([...formData])
  this.submitCommon(formData);
 

 }
      }



  addtext =  async () =>
  {




    if(!this.state.boxname) 
    {
    
    this.props.errormessageboxname('errorname','Please Enter Box Name');
    console.log('boxname empty')
    
    }
    else
    {
     this.props.errormessageboxname('errorname',''); 
    
    }
    
    
    if(!this.state.portletHeading) 
    {
     console.log('portletHeading empty')
     this.props.errormessageheading('errorheading','Please Enter Portlet Heading');
    
    }
     
    else
    {
     this.props.errormessageheading('errorheading',''); 
    
    }
    
    if(!this.state.thumbnail) 
    {
      this.props.errorthumbnail('errorthumbnail','Please Select a thumbnail');
    
    }
     
    else
    {
     this.props.errorthumbnail('errorthumbnail',''); 
    
    }
    
    
            let selectedValue = "";
            const value = this.state.selectedresource.map(
              values => (selectedValue += `${values.value},`)
            );
            selectedValue = selectedValue.replace(/,\s*$/, "");
    
            let formData = new FormData();
            formData.append("name", this.state.boxname);
            formData.append(
              "text",
              this.getMultiSelectValue(this.state.selectedresource, "1")
            );
            formData.append("type", this.state.boxvalue);
            formData.append("status", "active");
            formData.append("customerId", this.state.customerId);
            formData.append("thumbnail", this.state.thumbnail);
            formData.append("notificationtext", this.state.traytext);
            formData.append("heading", this.state.portletHeading);
            formData.append("description", this.state.descriptionText);
            formData.append("createdAt",this.state.newdate);
    
            
           
    
    
     if(this.state.boxname && this.state.thumbnail && this.state.portletHeading )
     {
    
      console.log([...formData])
      this.submitCommon(formData);
     
    
     }

  }

submitCommon = async (formData) =>{

 
   try {
     const result = await CmsContent.mappingportlet(
       formData,
       "tbl_boxcontent"
     );
     console.log(result.data)
     if (result) {
       window.scrollTo({
         top: 0,
         behavior: "smooth"
       });
        
       this.props.handler(this.state.boxvalue,this.props.alertVisible)

       // this.setState({ alertVisible: true, boxvalue: "" });
       // setTimeout(() => this.setState({ alertVisible: false }), 3000);
     }
   } catch (error) {
     console.log(error);
   }

       

   
}

authorimage = e => {
  let filename = e.target.files[0];
  let filepath = e.target.files[0].name;
  this.setState({ file: filename, filename: filepath });
};

  authorname = e => {
    let name = e.target.value;
    this.setState({ Authorname: name });
  };

  domain = e => {
    this.setState({ selectedDomain: e });
  };

submitresource = async () => {
  const { Authorname, Authoremail } = this.state;
  let userfile = this.state.file;

  var selectOption = this.state.domainOptions;
  if (
    selectOption != undefined &&
    selectOption != "" &&
    selectOption != null
  ) {
    this.setState({ errordomain: "" });
  } else {
    this.setState({ errordomain: "Please Select " });
    return true;
  }
  if (Authorname != undefined && Authorname != "" && Authorname != null) {
    this.setState({ error: "" });
  } else {
    this.setState({ error: "Please Fill Resource Name" });
    return true;
  }
  if (userfile != undefined && userfile != "" && userfile != null) {
    this.setState({ errorfile: "" });
  } else {
    this.setState({ errorfile: "Please Fill Resource file" });
    return true;
  }

  let username = this.state.Authorname;
  let useremail = this.state.selectedDomain;
  let companyid = this.state.companyid;
  let active = "active";

  const formData = new FormData();
  formData.append("name", username);
  formData.append("domainid", useremail.value);
  formData.append("companyid", this.state.customerId);
  formData.append("file", userfile);
  formData.append("status", active);

  let categoryArray = {};
  categoryArray.name = username.trim();
  categoryArray.domainid = useremail.value;
  this.setState({ disableValue: true });

  try {
    this.setState({ disableValue: true });

    const authorinsert = await CmsContent.authorinsert(
      formData,
      "tbl_resources"
    );

    // onUploadProgress: progressEvent => {
    //   this.setState({
    //     uploadPercentage: parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
    //   });
    //   setTimeout(() => this.setState({ uploadPercentage: 0 }), 10000);
    // }

    if (authorinsert) {
      // this.componentWillMount();

      let valueArray = {};
      valueArray.value = authorinsert.data.insertId;
      valueArray.label = categoryArray.name;

      const newData = [valueArray, ...this.state.resourceOptions];
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      this.setState({
        resourceOptions: newData,
        disableValue: false,
        resourceAlert: true,
        Authorname: "",
        filename: null,
        selectedDomain: ""
      });
    }
    setTimeout(() => this.setState({ resourceAlert: false }), 3000);
  } catch (error) {
    console.log(error);
  }
};
    render() { 
        const {selectedresource,resourceOptions,errorfile,resourceAlert,errordomain,Authorname,error}=this.state;
        return ( <React.Fragment> 
            <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="authorimage">Resource File</label>
                            </div>
                            <div className="col-sm-5 custom-file">
                              <MultiSelect
                                options={resourceOptions}
                                handleChange={this.resourceSelect}
                                selectedService={selectedresource}
                              />
                              <span className="error-shows">{errorfile}</span>
                            </div>
                            <div className="col-sm-3">
                              <div>
                                <div className="row form-group">
                                  <div className="col-sm-2" />
                                  <div className="col-sm-2">

                                  <LoginModal
                                      buttonTitle="Add Resource"
                                      title="Add Resource"
                                      id="Resource"
                                      extraClass="btn btn-primary"
                                      bodyText={
                                        <div>
                                          <div className="row form-group">
                                            <div className="col-sm-1" />
                                            <div className="col-sm-10">
                                              <Alert
                                                className="badge-content"
                                                color="success"
                                                isOpen={resourceAlert}
                                                toggle={this.onDismiss}
                                              >
                                                Resource Added Successfully
                                              </Alert>
                                            </div>
                                            <div className="col-sm-1" />
                                          </div>
                                          <div className="row form-group">
                                            <div className="col-sm-2">
                                              <label for="exampleInputEmail1">
                                                Select Domain
                                              </label>
                                            </div>
                                            <div className="col-sm-10">
                                              <SingleSelect
                                                options={
                                                  this.state.domainOptions
                                                }
                                                handleChange={this.domain}
                                                selectedService={
                                                  this.state.selectedDomain
                                                }
                                              />
                                              <span className="modal-error-show">
                                                {errordomain}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="row form-group">
                                            <div className="col-sm-2">
                                              <label htmlFor="Authorname">
                                                Resource Name
                                              </label>
                                            </div>
                                            <div className="col-sm-10">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="Authorname"
                                                name="Authorname"
                                                placeholder="Resource Name"
                                                value={Authorname}
                                                onChange={this.authorname}
                                              />
                                              <span className="modal-error-show">
                                                {error}
                                              </span>
                                            </div>
                                          </div>
                                          <div className="row form-group">
                                            <div className="col-sm-2">
                                              <label htmlFor="authorimage">
                                                Resource File
                                              </label>
                                            </div>
                                            <div className="col-sm-10 custom-file">
                                              <input
                                                type="file"
                                                accept="
                          image/*,
                          video/*,
                          application/pdf,application/msword,
                          applicationvnd.openxmlformats-officedocument.wordprocessingml.document,
                          .zip"
                                                className="custom-file-input"
                                                id="authorimage"
                                                onChange={this.authorimage}
                                              />
                                              <label
                                                className="custom-file-label"
                                                htmlFor="authorimage"
                                              >
                                                <span
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  {this.state.filename}
                                                </span>
                                              </label>
                                              <span className="modal-error-show">
                                                {errorfile}
                                              </span>
                                            </div>
                                          </div>

                                          <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2" />
                                            <div className="col-sm-5">
                                              <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={this.submitresource}
                                              >
                                                submit
                                              </button>
                                            </div>
                                            <div className="col-sm-3" />
                                          </div>
                                        </div>
                                      }
                                    />
                                    
                                  </div>
                                </div>
 
                              </div>

                             
                            </div>
 
                          </div>

                          <div className="row form-group">
                <div className="col-sm-4" />
                <div className="col-sm-5">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={
                      this.state.button === "Add" ? this.addtext : this.onSubmit
                    }
                  >
                    {this.state.button}
                  </button>
                </div>
                <div className="col-sm-3" />
           </div> 
            
             </React.Fragment>);
    }
}
 
export default Type4 ;