import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import { Alert } from "reactstrap";
import Datatable from "../../../components/Datatable/Datatable";
import print from "print-js";
import exportFromJSON from "export-from-json";

const sampleSubtitle = "/assets/subtitles/vtt/sintel-en.vtt";
class SubTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnDisable: false,
      subTitleName: "",
      errorSubTitleName: "",
      file2: "",
      fileName2: "Choose File",
      errorFile2: "",
      subTitleLangName: "",
      errorSubTitleLangName: "",
      alertVisible: false,
      data: [],
      formAlertdelete: false,
      customerId: localStorage.getItem("userId")
    };
  }

  async componentDidMount() {
    try {
      
      const result = await CmsContent.getFreedom(
        "*",
        "tbl_Subtitle",
        `customerId=${this.state.customerId}`,
        "id",
        1
      );
      
      if (result) {
        this.setState({ data: result.data });
      }
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      Header: "SubTitle Name",
      accessor: "subTitleName"
    },
    {
      Header: "SubTitle Language",
      accessor: "subTitleLangName"
    },
    {
      Header: "SubTitle File",
      accessor: "subTitle"
    },

    {
      Header: "Delete",
      accessor: "delete",
      Cell: d => this.delete(d)
    }
  ];

  edit = (value, modalWindowId) => {
    return (
      <button
        type="button"
        data-toggle="modal"
        data-target={`#${modalWindowId}`}
        className="btn btn-warning"
        onClick={() => this.buttonEdit(value)}
      >
        Edit
      </button>
    );
  };

  delete = value => {
    return (
      <button
        type="button"
        className="btn btn-danger"
        onClick={() => this.buttonDelete(value)}
      >
        Delete
      </button>
    );
  };

  buttonDelete = async e => {
    //Getting Previous state data
    const previousData = [...this.state.data];
    const getData = { ...previousData[e.index] };
    const id = getData.id;
    const data = previousData.filter(value => value.id !== id);
  
  };

  handlechange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  selectImage2 = e => {
    this.setState({
      file2: e.target.files[0],
      fileName2: e.target.files[0].name
    });
  };

  addSubtitle = async () => {
    const { subTitleName, file2, subTitleLangName } = this.state;

    if (!subTitleName) {
      this.setState({ errorSubTitleName: "Please Add SubTitle Name" });
      return false;
    } else if (!subTitleLangName) {
      this.setState({
        errorSubTitleName: "",
        errorSubTitleLangName: "Enter Subtitle Language"
      });
      return false;
    } else if (!file2) {
      this.setState({
        errorSubTitleLangName: "",
        errorFile2: "Select Subtitle File"
      });
      return false;
    } else {
      this.setState({ errorFile2: "", btnDisable: true });
      let customerId = this.state.customerId;
      const formData = new FormData();
      formData.append("subTitleName", subTitleName);
      formData.append("subTitleLangName", subTitleLangName);
      formData.append("subTitle", file2);
      formData.append("customerId", customerId);
      
      try {
        const result = await CmsContent.subTitleAdd(formData);
        if (result) {
          const userData = {
            id: parseInt(result.data.insertId),
            subTitleName: subTitleName,
            subTitleLangName: subTitleLangName,
            subTitle: file2.name
          };
          
          const newData = [userData, ...this.state.data];
          this.setState({
            data: newData,
            alertVisible: true,
            subTitleName: "",
            subTitleLangName: "",
            file2: "",
            fileName2: "Browse File",
            btnDisable: false
          });
          setTimeout(() => this.setState({ alertVisible: false }), 3000);

        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  onDismiss = () => {
    this.setState({ alertVisible: false });
    this.setState({ formAlertdelete: false });
  };

  printPdf = () => {
    print({
      printable: this.state.data,
      properties: [
        { field: "subTitleName", displayName: "Subtitle Name" },
        { field: "subTitleLangName", displayName: "Subtitle Language" },
        { field: "subTitle", displayName: "Subtitle Path" }
      ],
      type: "json"
    });
  };

  printCsv = () => {
    const data = this.state.data;
    const fileName = "download";
    const exportType = "csv";
    exportFromJSON({ data, fileName, exportType });
  };

  printExcel = () => {
    const data = this.state.data;
    const fileName = "download";
    const exportType = "xls";
    exportFromJSON({ data, fileName, exportType });
  };

  render() {
    const {
      errorSubTitleName,
      btnDisable,
      fileName2,
      errorFile2,
      errorSubTitleLangName,
      alertVisible,
      data,
      subTitleName,
      subTitleLangName,
      formAlertdelete
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Add SubTitle</h3>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-2" />
                      <div className="col-7">
                        <Alert
                          className="badge-content"
                          color="success"
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          Subtitle Added Successfully
                        </Alert>

                        <Alert
                          className="badge-content"
                          color="danger"
                          isOpen={formAlertdelete}
                          toggle={this.onDismiss}
                        >
                          Mapped Page Delete
                        </Alert>
                      </div>
                      <div className="col-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Subtitle Name</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          class="form-control"
                          id="subTitleName"
                          name="subTitleName"
                          placeholder="Enter Subtitle Name"
                          onChange={this.handlechange}
                          value={subTitleName}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorSubTitleName}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">
                          Subtitle Language Name
                        </label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          class="form-control"
                          id="subTitleLangName"
                          name="subTitleLangName"
                          placeholder="Enter Subtitle Language Name"
                          onChange={this.handlechange}
                          value={subTitleLangName}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {errorSubTitleLangName}
                      </span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Upload Subtitle</label>
                      </div>
                      <div className="col-sm-5 custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          onChange={this.selectImage2}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFile"
                        >
                          {fileName2}
                        </label>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorFile2}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">
                          Sample Subtitle File
                        </label>
                      </div>
                      <div className="col-sm-5 custom-file">
                        <a
                          href={sampleSubtitle}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Click to open in new tab
                        </a>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          class="btn btn-primary"
                          disabled={btnDisable}
                          onClick={this.addSubtitle}
                        >
                          Add SubTitle
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    {data && (
                      <div>
                        <button
                          className="btn btn-danger m-2"
                          onClick={this.printPdf}
                        >
                          Print
                        </button>
                        <button
                          className="btn btn-danger m-2"
                          onClick={this.printCsv}
                        >
                          CSV
                        </button>
                        <button
                          className="btn btn-danger m-2"
                          onClick={this.printExcel}
                        >
                          Excel
                        </button>
                      </div>
                    )}
                    {data && (
                      <Datatable data={data} columnHeading={this.column} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default SubTitle;
