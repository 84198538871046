import React from "react";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import { Redirect } from "react-router-dom";
import { ACCESS_POINT } from "../../../config";





class DfUserSeeAll extends FormMiddleWare {
    constructor(props) {
      super(props);
  this.state = {
       type : this.props.location.state.type,
       data : this.props.location.state.data,
     // data1:this.props.location.state.data1,
        content:[],
      title:"",
       };
    }
    
async componentWillMount (){
  try{
    //console.log(data1);
    let type=this.state.type;
    let data=this.state.data;
   // let data1=this.state.data1;
     let title=this.state.data.title;
     this.setState({
       title:title,
     })
    this.process(type,data);
   }catch(error){
       console.log(error);
   }
}
seperate = (type,data,data1) =>{
  //console.log(data1);
  
  this.setState({type , data ,data1,show : true})
  // window.location.href = "/difuzaNextUser";
}
process=(type,data)=>{
  let content=[];
  // console.log(data.content); 
  // var type=type;
  // var data=[];
  // data=data; 
  data.content.map((item,i)=>{
       let name=item.name;
       let text=item.text;
       let thumbnail=item.thumbnail;
       
       content.push(
        <div style={{ flex: '0 0 100%' }}>
        <div class="container" style={{marginTop:'13px'}}>
      <div class="card-body" style={{width:'100%',backgroundColor: '#fff',borderRadius:'21px',
  marginBottom:'44px'}}>
<div className="row"> 
      <div className="col-sm-6">   <h3  style={{textAlign:'left',fontSize:'inherit'}}>{name}</h3>
      <h3 style={{marginTop:'10px'}}>{text}</h3></div>
       <div className="col-sm-6"> 
      <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${thumbnail}`}    onClick={()=>this.seperate(type,item,data)}
      style={{ 
  width:'100%',height:'300px'  }} />
        </div>
        </div>
        </div>
    </div>
  </div>
       )
  })

   this.setState({
     content:content,
   })
} 
Backtohome=()=>{
  window.location.href="/difuzaUser";

}
render(){

const{title,content}=this.state;
return(
<React.Fragment>
    <main className="main my-4">
      <div className="container-fluid"> 
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h1>DIFUZA_USER</h1>
                <button type="button" onClick={()=>this.Backtohome()}>Back</button>
                {/* <button type="button" onClick={()=>this.backtohome()}>BACK</button> */}
              </div>
              <div className="card-body" style={{backgroundColor:'rgb(211, 211, 211)'}} >
               <div style={{width:"70%",marginLeft:"20px",marginBottom:"8px"}} className='my-own-custom-container'>

                <h2 style={{color:"black"}}>{title}</h2>
                <br/><br/>
                {content}
                {this.state.show === true && (
                      <Redirect to={{
                        pathname: '/difuzaNextUser',
                       state: { type: this.state.type ,data :this.state.data ,data1:this.state.data1}
                    }}
            />
                )}
                
             
                 </div>

                 </div>
               </div>
          </div>
        </div>
      </div>
    </main>
  </React.Fragment>
      );
   }
}

export default DfUserSeeAll ;