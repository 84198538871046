
import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import cmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import { Alert } from "reactstrap";
import Datatable from "../../../components/Datatable/Datatable";
import { FormMiddleWare } from "../../../components/Form";
import { unique } from "@tensorflow/tfjs";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";
// import User from "../../../MiddleWare/User";
import moment from "moment";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';


class UserPyschologyForm extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            isEdit: false,
            customerId: localStorage.getItem("userId"),
            userlog: JSON.parse(localStorage.getItem("userlog")),
            data: [],
            editId: null,
            userid: null,
            formAlertdelete: false,
            textalert: "",
            genderopt: [{ label: "MALE", value: 1 }, { label: "FEMALE", value: 2 }],
            danger: "btn btn-danger",
            success: "btn btn-success",
            pageNumber: 1,
            SelectedEnquiries: [],
            bulkAllocate: false,
            addCounsellingColumn: [
                { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                { label: "Srudent ID", value: 2, type: "text", Name: "SrudentId" },
                { label: "NAME", value: 3, type: "text", Name: "Name" },
                { label: "CLASS", value: 4, type: "selectbox", Name: "Class" },
                { label: "NAME OF THE MENTOR", value: 5, type: "selectbox", Name: "Mentor" },
                { label: "GENDER", value: 6, type: "selectbox", Name: "Gender" },
                { label: "DEPARTMENT", value: 7, type: "selectbox", Name: "Department" },
                { label: "WHETHER COUNSELING CONDUCTED ALONG WITH PARENT  ?", value: 8, type: "selectbox", Name: "Withparent" },
                { label: "DATE OF COUNSELING ", value: 9, type: "date", Name: "Date" },
                { label: "REASON FOR THE COUNSELING", value: 10, type: "textarea", Name: "Reason" },
            ],
            addTrainingColumn: [
                { label: "School Name", value: 1, type: "selectbox", Name: "SchoolName", disabled: true },
                { label: "Srudent ID", value: 2, type: "text", Name: "SrudentId" },
                { label: "NAME", value: 3, type: "text", Name: "Name" },
                { label: "DEPARTMENT", value: 4, type: "selectbox", Name: "Department" },
                { label: "TOPIC", value: 5, type: "textarea", Name: "Topic" },
                { label: "DATE OF COUNSELING ", value: 6, type: "date", Name: "Date" },
                { label: "REASON FOR THE COUNSELING", value: 7, type: "textarea", Name: "Reason" },

            ],
            counsellingArray: [],
            trainingArray: [],

            CounsellingData: [],
            userAddRow: "",
            RowCount: "",
            FormType: 0,
            NTSOptions: [
                { label: "Warden", value: 1 },
                { label: "PT", value: 2 },
                { label: "Housekeeping", value: 3 },
            ],
            // showDatatable:true
        };
    }
    column4 = [

        {
            Header: "ENTITY NAME",
            accessor: "userName",
        },
        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "EMPLOYEE ID",
            accessor: "employeeId",
        },
        {
            Header: "MOBILE",
            accessor: "mobileNumber",
        },
        {
            Header: "GENDER",
            accessor: "gender",
        },
        {
            Header: "DISGNATION",
            accessor: "designationName",
        },
        {
            Header: "DISCRIPTION",
            accessor: "discription",
        },
        {
            Header: "REASON OF COUNSELING",
            accessor: "reason",
        },
        {
            Header: "DATE OF COUNSELING",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },
    ];
    column = [
        // {
        //     Header: "ID ",
        //     accessor: "id",
        // },
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "DOB",
            accessor: "dob",
        },
        {
            Header: "CLASS",
            accessor: "className",
        },
        {
            Header: "DEPARTMENT",
            accessor: "departmentName",
        },
        {
            Header: "NAME OF THE MENTOR",
            accessor: "nameOfMentorName",
        },
        {
            Header: "REASON OF COUNSELING",
            accessor: "reason",
        },
        {
            Header: "DATE OF COUNSELING",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },
        // {
        //     Header: "EDIT",
        //     accessor: "edit",
        //     Cell: (d) => this.Editpages(d, 'customer'),
        // },
        // {
        //     Header: "STATUS",
        //     // accessor: "edit",
        //     Cell: (d) => this.buttondel(d),
        // },
        // {
        //     Header: "Delete",
        //     accessor: "edit",
        //     Cell: (d) => this.DatatableCheckboxx(d, this.updateMyData, this.state.SelectedEnquiries)
        // }
    ];
    column1 = [
        // {
        //     Header: "ID ",
        //     accessor: "id",
        // },
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "TOPIC",
            accessor: "topic",
        },
        {
            Header: "DEPARTMENT",
            accessor: "departmentName",
        },
        {
            Header: "DISCRIPTION",
            accessor: "discription",
        },
        {
            Header: "REASON OF COUNSELING",
            accessor: "reason",
        },

        {
            Header: "DATE OF COUNSELING",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },

        // {
        //     Header: "STATUS",
        //     // accessor: "edit",
        //     Cell: (d) => this.buttondel(d),
        // },
        // {
        //     Header: "Delete",
        //     accessor: "edit",
        //     Cell: (d) => this.DatatableCheckboxx(d, this.updateMyData, this.state.SelectedEnquiries)
        // }
    ];
    column2 = [
        // {
        //     Header: "School Name ",
        //     accessor: "userName",
        // },
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "AGE",
            accessor: "Age",
        },
        {
            Header: "DEPARTMENT",
            accessor: "departmentName",
        },
        {
            Header: "CLASS",
            accessor: "className",
        },
        {
            Header: "GENDER",
            accessor: "gender",
        },
        {
            Header: "MARITAL STATUS",
            accessor: "MaritalStatus",
        },
        {
            Header: "DISCRIPTION",
            accessor: "discription",
        },
        {
            Header: "SUBJECT",
            accessor: "Subject",
        },
        {
            Header: "DATE",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },

    ];
    column3 = [
        // {
        //     Header: "School Name ",
        //     accessor: "userName",
        // },
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "AGE",
            accessor: "Age",
        },
        {
            Header: "GENDER",
            accessor: "gender",
        },
        {
            Header: "MARITAL STATUS",
            accessor: "MaritalStatus",
        },
        {
            Header: "DISCRIPTION",
            accessor: "discription",
        },
        {
            Header: "HOSTEL NAME",
            accessor: "HostelName",
        },
        {
            Header: "NTS",
            accessor: "NTS",
        },
        {
            Header: "DATE",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },

    ];
    excelcolumn1 = [
        // {
        //     label: "ID ",
        //     key: "id",
        // },
        {
            label: "COUNSELING TYPE",
            key: "counsellingType",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "TOPIC",
            key: "topic",
        },
        {
            label: "DEPARTMENT",
            key: "departmentName",
        },
        {
            label: "DISCRIPTION",
            key: "discription",
        },
        {
            label: "REASON OF COUNSELING",
            key: "reason",
        },
        {
            label: "DATE OF COUNSELING",
            key: "date",
        },

    ]
    excelColumn = [
        // {
        //     label: "ID ",
        //     key: "id",
        // },
        {
            label: "COUNSELING TYPE",
            key: "counsellingType",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "DOB",
            key: "dob",
        },
        {
            label: "CLASS",
            key: "className",
        },
        {
            label: "DEPARTMENT",
            key: "departmentName",
        },
        {
            label: "NAME OF THE MENTOR",
            key: "nameOfMentorName",
        },
        {
            label: "REASON OF COUNSELING",
            key: "reason",
        },
        {
            label: "DATE OF COUNSELING",
            key: "date",
        },
        // {
        //     Header: "EDIT",
        //     accessor: "edit",
        //     Cell: (d) => this.Editpages(d, 'customer'),
        // },
        // {
        //     Header: "STATUS",
        //     // accessor: "edit",
        //     Cell: (d) => this.buttondel(d),
        // },
        // {
        //     Header: "Delete",
        //     accessor: "edit",
        //     Cell: (d) => this.DatatableCheckboxx(d, this.updateMyData, this.state.SelectedEnquiries)
        // }
    ];
    excelcolumn2 = [
        // {
        //     label: "School Name ",
        //     key: "userName",
        // },
        {
            label: "COUNSELING TYPE",
            key: "counsellingType",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "AGE",
            key: "Age",
        },
        {
            label: "DEPARTMENT",
            key: "departmentName",
        },
        {
            label: "CLASS",
            key: "className",
        },
        {
            label: "GENDER",
            key: "gender",
        },
        {
            label: "MARITAL STATUS",
            key: "MaritalStatus",
        },
        {
            label: "DISCRIPTION",
            key: "discription",
        },
        {
            label: "SUBJECT",
            key: "Subject",
        },
        {
            label: "DATE",
            key: "date",
        },

    ]
    excelcolumn3 = [
        // {
        //     label: "School Name ",
        //     key: "userName",
        // },
        {
            label: "COUNSELING TYPE",
            key: "counsellingType",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "AGE",
            key: "Age",
        },
        {
            label: "GENDER",
            key: "gender",
        },
        {
            label: "MARITAL STATUS",
            key: "MaritalStatus",
        },
        {
            label: "DISCRIPTION",
            key: "discription",
        },
        {
            label: "HOSTEL NAME",
            key: "HostelName",
        },
        {
            label: "NTS",
            key: "NTS",
        },
        {
            label: "DATE",
            key: "date",
        },

    ]
    excelcolumn4 = [
        {
            label: "ENTITY NAME",
            key: "userName",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "EMPLOYEE ID",
            key: "employeeId",
        },
        {
            label: "MOBILE",
            key: "mobileNumber",
        },
        {
            label: "GENDER",
            key: "gender",
        },
        {
            label: "DISGNATION",
            key: "designationName",
        },
        {
            label: "DISCRIPTION",
            key: "discription",
        },
        {
            label: "REASON OF COUNSELING",
            key: "reason",
        },
        {
            label: "DATE OF COUNSELING",
            key: "date",

        },

    ]
    async componentDidMount() {
        this.MainFun()
    }
    dateFormateChange = (d) => {
        console.log(d, "d");
        if (d.original.date) {
            return (moment(d.original.date).format('DD-MM-YYYY'))
        }
    }

    MainFun = async () => {
        try {
            this.setState({ loading: true })
            const Class = await cmsContent.getFreedom(
                "id as value, class_name as label",
                "psychol_class",
                `id`,
                "id",
                "id"
            );
            if (Class) {
                this.setState({ ClassOptions: Class.data })
            }
            const Department = await cmsContent.getFreedom(
                "id as value, department_name as label",
                "psychol_department",
                `id`,
                "id",
                "id"
            );
            if (Department) {
                this.setState({ DepartmentOptions: Department.data })
            }
            const Mentor = await cmsContent.getFreedom(
                "id as value, mentor_name as label",
                "psychol_mentor",
                `id`,
                "id",
                "id"
            );
            if (Mentor) {
                this.setState({ MentorOptions: Mentor.data })
            }

            const Counselling = await cmsContent.getFreedom(
                "psychol_form.*,tbl_user_web.userName",
                "psychol_form,tbl_user_web",
                `psychol_form.customerId=${this.state.customerId} AND tbl_user_web.id=psychol_form.customerId`,
                "id",
                "id"
            );
            if (Counselling && Class && Department && Mentor) {
                const classLookup = Class.data.reduce((acc, c) => {
                    acc[c.value] = c.label;
                    return acc;
                }, {});

                const departmentLookup = Department.data.reduce((acc, d) => {
                    acc[d.value] = d.label;
                    return acc;
                }, {});

                const mentorLookup = Mentor.data.reduce((acc, m) => {
                    acc[m.value] = m.label;
                    return acc;
                }, {});
                const NTSLookup = this.state.NTSOptions.reduce((acc, d) => {
                    acc[d.value] = d.label;
                    return acc;
                }, {});

                Counselling.data.forEach((ival) => {
                    if (classLookup[ival.class]) {
                        ival.className = classLookup[ival.class];
                    }
                    if (ival.department !== null || ival.department != null || ival.department !== "") {
                        console.log(ival.department)
                        let dept = ival.department && ival.department.split(",")
                        if (dept && dept.length && dept !== null) {
                            dept.map((kval, k) => {
                                if (departmentLookup[kval]) {
                                    dept[k] = departmentLookup[kval];
                                }
                            })
                            let selectedValues = "";
                            dept.map(values => (selectedValues += `${values},`));
                            ival.departmentName = selectedValues.replace(/,\s*$/, "");

                        }
                    }
                    if (mentorLookup[ival.nameOfMentor]) {
                        ival.nameOfMentorName = mentorLookup[ival.nameOfMentor];
                    }
                    if (NTSLookup[ival.NTS]) {
                        ival.NTS = NTSLookup[ival.NTS];
                    }
                    if (ival.counsellingType) {
                        if (ival.counsellingType == "1") {
                            ival.counsellingId = ival.counsellingType
                            ival.counsellingType = "1 to 1 Counseling"
                        }
                        if (ival.counsellingType == "2") {
                            ival.counsellingId = ival.counsellingType
                            ival.counsellingType = "Group Counseling/Training"
                        }
                    }
                    if (ival.date) {
                        ival.date = moment(ival.date).format('DD-MM-YYYY');
                    }
                });
                // console.log(Counselling,"Counselling");
                let counsellingdata = []
                let trainingdata = []
                let TSData = []
                let NTSData = []
                Counselling.data.forEach((ival) => {
                    if (ival.counsellingId == "1" && ival.type == "1") {
                        counsellingdata.push(ival)
                    } else if (ival.counsellingId == "2" && ival.type == "1") {
                        trainingdata.push(ival)
                    } else if (ival.type == "2") {
                        TSData.push(ival)
                    } else if (ival.type == "3") {
                        NTSData.push(ival)
                    }

                });

                this.setState({ CounsellingData: counsellingdata, TrainingData: trainingdata, TSData, NTSData });
            }
            const CompanyForm = await cmsContent.getFreedom(
                "psychol_form.*,tbl_user_web.userName",
                "psychol_form,tbl_user_web",
                `psychol_form.customerId=${this.state.customerId} AND tbl_user_web.id=psychol_form.customerId AND psychol_form.entityType='2'`,
                "id",
                "id"
            );
            console.log(CompanyForm, "CompanyForm");
            // if (CompanyForm) {
            //     this.setState({ CompanyFormData: CompanyForm.data })
            // }
            const Designation = await cmsContent.getFreedom(
                "id as value, designation_name as label",
                "psychol_designation",
                `id`,
                "id",
                "id"
            );
            if (Designation) {
                this.setState({ DesignationOptions: Designation.data })
            }
            if (CompanyForm && Designation) {
                CompanyForm.data.forEach((ival) => {
                    if (ival.date) {
                        console.log(ival.date,"ival.date");
                        ival.date = moment(ival.date).format('DD-MM-YYYY');
                    }
                    Designation.data.map((jval) => {
                        if (ival.designation == jval.value) {
                            ival.designationName = jval.label
                        }
                      

                    })
                })
                this.setState({ CompanyFormData: CompanyForm.data })
            }

            this.setState({ loading: false })

        } catch (error) {
            console.log(error);
        }
    }

    Editpages = (value, modalWindowId) => {
        if (value.original.status == 0) {
            return (
                <button type="button" data-toggle="modal" data-target={`#${modalWindowId}`} className="btn btn-warning" onClick={() => this.onEdit(value)}>
                    {/* <button className="btn btn-info" onClick={() => this.onEdit(value)}> */}
                    Edit
                </button>
            );

        } else {
            return (<span className="label">Deleted</span>)
        }
    };

    onEdit = async (value) => {

        let d = value.original;
        console.log(d.BusinessDesignation.split(/\s/).join('').toUpperCase());
        if (d.BusinessDesignation) {
            if (isNaN(d.BusinessDesignation) == true) {
                let ansBusiDesignation = this.getValueFromArray1(d.BusinessDesignation, this.state.BusiDesignationopt, 'label')
                await this.setState({ ansBusiDesignation })
            } else {
                let ansBusiDesignation = this.getValueFromArray1(d.BusinessDesignation.split(/\s/).join('').toUpperCase(), this.state.BusiDesignationopt, 'value')
                await this.setState({ ansBusiDesignation })
            }
        }
        if (d.gender) {
            let ansgender = this.getValueFromArray1(d.gender, this.state.genderopt, 'label')
            await this.setState({ ansgender })
        }
        await this.setState({
            emp_name: d.employee_name,
            emp_id: d.employee_id,
            password: d.password,
            ansBusinessUnit: d.BusinessUnit, ansFunction: d.Function, ansSubFunction: d.SubFunction,
            ansRegion: d.Region, ansDivision: d.Division, ansBranch: d.Branch,
            ansoffEmailAdd: d.email, ansGrade: d.Grade, ansBand: d.Band,
            ansEmpCatType: d.EmpCatType, ansL1ManCode: d.L1ManCode,
            ansL1Man: d.L1Man, ansL2ManCode: d.L2ManCode, ansL2Man: d.L2Man, ansHRManCode: d.HRManCode, ansHRMan: d.HRMan,
            userid: d.userid,
            editId: d.id,
            isEdit: true
        });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    buttondel = (d) => {
        if (d.original.status == 0) {
            return (
                <button
                    className="btn btn-success"
                    onClick={() => this.Delete(d)}
                >Active</button>
            );
        } else {
            return (
                <button
                    className="btn btn-danger"
                    onClick={() => this.Recover(d)}
                >InActive</button>
                // <span className="label">Deleted</span>
            )

        }
    }

    handlechange = async (e, type, name) => {
        if (type == "Select") {
            await this.setState({
                [name]: e
            })
        }
        else if (type == "caps") {
            await this.setState({
                [e.target.name]: e.target.value.toUpperCase(), errorempname: "", errorCode: "", errgender: "", errBusinessUnit: "", errFunction: "", errSubFunction: "", errRegion: "", errDivision: "", errBranch: "", erroffEmailAdd: "", errGrade: "", errBand: "", errEmpCatType: "", errL1ManCode: "", errL1ManCode: "",
                errL1Man: "", errL2ManCode: "", errL2Man: "", errHRManCode: "", errHRMan: "", errBusiDesignation: "",
            });
        }
        else {
            await this.setState({ [e.target.name]: e.target.value, errorempname: "", errorCode: "" });
        }
    };

    submitCategory = async () => {
        const { } = this.state;
        try {

        } catch (error) {
            console.log(error);
        }
    };

    fileupload = e => {
        this.setState({ csv: e.target.files[0] })
    }

    addRow = (value, type) => {
        console.log(value, "values");
        console.log(type, "type");
        let { userAddRow, counsellingArray, trainingArray } = this.state
        if (type === "1") {
            // console.log("enter 1");

            let addIndex = value ? counsellingArray.length : 0
            const newItems = Array.from({ length: value ? value : Number(userAddRow) }, (_, index) => ({

                SchoolName: "",
                SchoolNameSelect: "",
                SrudentId: "",
                Name: "",
                ClassSelect: "",
                GenderSelect: "",
                DepartmentSelect: "",
                MentorSelect: "",
                WithparentSelect: "",
                Date: "",
                Reason: "",
                GenderOption: [{ label: "MALE", value: 1 }, { label: "FEMALE", value: 2 }],
                DepartmentOption: this.state.DepartmentOptions,
                ClassOption: this.state.ClassOptions,
                MentorOption: this.state.MentorOptions,
                WithparentOption: [{ label: "Yes", value: 1 }, { label: "No", value: 0 }],


                index: value ? addIndex : index
            }));
            console.log(newItems, "newItems");
            this.setState(prevState => ({
                counsellingArray: [...prevState.counsellingArray, ...newItems]
            }));
        } else if (type === "2") {
            // console.log("enter 2");
            console.log(userAddRow, "userAddRow");
            let addIndex = value ? trainingArray.length : 0
            const newItems = Array.from({ length: value ? value : Number(userAddRow) }, (_, index) => ({

                SchoolName: "",
                SchoolNameSelect: "",
                SrudentId: "",
                Name: "",
                DepartmentSelect: "",
                Date: "",
                Reason: "",
                Topic: "",
                DepartmentOption: this.state.DepartmentOptions,

                index: value ? addIndex : index
            }));
            console.log(newItems, "newItems");
            this.setState(prevState => ({
                trainingArray: [...prevState.trainingArray, ...newItems]
            }));
        }

        // console.log(trainingArray, "trainingArray");
    }

    handleInputChange = (e, rowIndex, columnLabel, type) => {
        const { value } = e.target;
        if (type == "1") {

            this.setState(prevState => ({
                counsellingArray: prevState.counsellingArray.map((row, index) =>
                    index === rowIndex ? { ...row, [columnLabel]: value } : row
                )
            }));
        } else if (type == "2") {
            this.setState(prevState => ({
                trainingArray: prevState.trainingArray.map((row, index) =>
                    index === rowIndex ? { ...row, [columnLabel]: value } : row
                )
            }));
        }

    };

    handleSelectChange = async (value, rowIndex, columnLabel, type) => {
        let { adminId } = this.state
        console.log(value, "value");
        console.log(rowIndex, "rowIndex");
        console.log(columnLabel, "columnLabel");
        if (type == "1") {

            this.setState(prevState => ({
                counsellingArray: prevState.counsellingArray.map((row, index) =>
                    index === rowIndex ? { ...row, [columnLabel + "Select"]: value } : row
                )
            }));
        } else if (type == "2") {
            this.setState(prevState => ({
                trainingArray: prevState.trainingArray.map((row, index) =>
                    index === rowIndex ? { ...row, [columnLabel + "Select"]: value } : row
                )
            }));
        }
        if (rowIndex == "counschool") {
            console.log("enter");
            console.log(this.state.counsellingArray, "counsellingArray");
            this.setState(prevState => ({
                counsellingArray: prevState.counsellingArray.map(row => ({
                    ...row,
                    SchoolNameSelect: value
                }))
            }));
        } else if (rowIndex == "trnschool") {
            console.log("enter");
            console.log(this.state.trainingArray, "trainingArray");
            this.setState(prevState => ({
                trainingArray: prevState.trainingArray.map(row => ({
                    ...row,
                    SchoolNameSelect: value
                }))
            }));
        }


    };

    counsellingFormSubmit = async () => {
        let { counsellingArray } = this.state
        console.log(counsellingArray, "counsellingArray");
        let formData = []
        // console.log(counsellingArray,"counsellingArray");
        let wait = counsellingArray.map((ival) => {
            let result = {}
            result.name = ival.Name;
            result.studentid = ival.SrudentId;
            result.class = ival.ClassSelect.value;
            result.nameOfMentor = ival.MentorSelect.value;
            result.gender = ival.GenderSelect.label;
            result.department = ival.DepartmentSelect.value;
            result.withParent = ival.WithparentSelect.label;
            result.date = ival.Date;
            result.reason = ival.Reason;
            result.customerId = ival.SchoolNameSelect.value;
            result.type = "1";
            formData.push(result);
        })
        Promise.all(wait)
        console.log(formData, "formData");
        let emptyarray = []
        let validate = formData.map((a, b) => {
            console.log(a, "a", b, "b")
            for (let key in a) {
                if (a.hasOwnProperty(key)) {
                    if (a[key] === "" || a[key] === undefined) {
                        emptyarray.push(b + 1)
                    }
                }
            }
        })
        console.log(emptyarray)
        let uniquearray = emptyarray.filter((a, b) => emptyarray.indexOf(a) === b)
        console.log(uniquearray, "uniquearray")

        if (uniquearray && uniquearray.length > 0) {
            const number = uniquearray.toString()
            console.log(number, "number")
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `<span id="err-msg">There is an empty  column in a ${number}</span>`,
            });
        }
        else {
            try {
                const result = await cmsContent.InsertMultydata("psychol_form", formData);
                console.log(result, "result");
                if (result) {
                    this.setState({
                        counsellingArray: [],
                        userAddRow: "",
                        alertVisible: true,
                        badgeText: "Successfully Submitted",
                        badgeColor: "success"
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                        window.location.reload()
                    }, 2000);

                }

            } catch (error) {

            }
        }
    }

    tainingFormSubmit = async () => {
        let { trainingArray } = this.state
        console.log(trainingArray, "trainingArray");
        let formData = []
        let wait = trainingArray.map((ival) => {
            let result = {}
            result.name = ival.Name;
            result.studentid = ival.SrudentId;
            result.department = ival.DepartmentSelect.value;
            result.date = ival.Date;
            result.reason = ival.Reason;
            result.topic = ival.Topic;
            result.customerId = ival.SchoolNameSelect.value;
            result.type = "2";

            //   result.index = ival.index;
            formData.push(result);
        })
        Promise.all(wait)
        console.log(formData, "formData");
        let emptyarray = []
        let validate = formData.map((a, b) => {
            console.log(a, "a", b, "b")
            for (let key in a) {
                if (a.hasOwnProperty(key)) {
                    if (a[key] === "" || a[key] === undefined) {
                        emptyarray.push(b + 1)
                    }
                }
            }
        })
        console.log(emptyarray)
        let uniquearray = emptyarray.filter((a, b) => emptyarray.indexOf(a) === b)
        console.log(uniquearray, "uniquearray")
        if (uniquearray && uniquearray.length > 0) {
            const number = uniquearray.toString()
            console.log(number, "number")
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `<span id="err-msg">There is an empty  column in a ${number}</span>`,
            });
        }
        else {
            try {
                const result = await cmsContent.InsertMultydata("psychol_form", formData);
                console.log(result, "result");
                if (result) {
                    this.setState({
                        trainingArray: [],
                        userAddRow: "",
                        alertVisible: true,
                        badgeText: "Successfully Submitted",
                        badgeColor: "success"
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                        window.location.reload()
                    }, 2000);
                }

            } catch (error) {

            }
        }
    }

    reset = async () => {
        await this.setState({
            errorempname: '', errorCode: '', emp_id: "", emp_name: "", password: '', ansgender: "",
            ansgender: "", ansBusinessUnit: "", ansFunction: "", ansSubFunction: "",
            ansRegion: "", ansDivision: "", ansBranch: "",
            ansoffEmailAdd: "", ansGrade: "", ansBand: "",
            badgeText: false, badgeColor: false, alertVisible: false,
            ansEmpCatType: "", ansL1ManCode: "",
            ansL1Man: "", ansL2ManCode: "", ansL2Man: "", ansHRManCode: "", ansHRMan: "", ansBusiDesignation: "",
            errgender: "", errBusinessUnit: "", errFunction: "", errSubFunction: "", errRegion: "", errDivision: "", errBranch: "", erroffEmailAdd: "", errGrade: "", errBand: "", errEmpCatType: "", errL1ManCode: "",
            errL1Man: "", errL2ManCode: "", errL2Man: "", errHRManCode: "", errHRMan: "", errBusiDesignation: "",
            errorpassword: '', editId: false, userid: false, isEdit: false, alertVisible: false,
        })
    }

    deleteColumn = (a, b, c) => {
        const { counsellingArray, trainingArray } = this.state
        if (c === "counscelling") {
            console.log("success")
            const filteredarray = counsellingArray.filter((ival, index) => {
                return b !== index
            })
            console.log(filteredarray, "filteredarray")
            this.setState({ counsellingArray: filteredarray })
        }
        if (c === "training") {
            console.log("success")
            const filteredarray = trainingArray.filter((ival, index) => {
                return b !== index
            })
            console.log(filteredarray, "filteredarray")
            this.setState({ trainingArray: filteredarray })
        }
    }

    convertToSheetData = (headers, data) => {
        // Extract labels from headers
        const labels = headers.map(header => header.label);

        // Extract data based on keys
        const sheetData = data.map(item => {
            return headers.map(header => item[header.key]);
        });

        // Add labels as the first row
        sheetData.unshift(labels);

        return sheetData;
    };

    exportToExcel = () => {
        // const { sheetData } = this.state;
        const { CounsellingData, TrainingData, TSData, NTSData } = this.state;
        // console.log(CounsellingData, TrainingData, TSData, NTSData,"CounsellingData, TrainingData, TSData, NTSData");

        const sheetData = [
            {
                sheetName: '1 to 1 Counselling Form',
                headers: [
                    {
                        label: "SCHOOL NAME ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "CLASS",
                        key: "className",
                    },
                    {
                        label: "DEPARTMENT",
                        key: "departmentName",
                    },
                    {
                        label: "NAME OF THE MENTOR",
                        key: "nameOfMentorName",
                    },
                    {
                        label: "REASON OF COUNSELLING",
                        key: "reason",
                    },
                    {
                        label: "DATE OF COUNSELLING",
                        key: "date",
                    },
                    {
                        label: "HOSTEL NAME",
                        key: "HostelName",
                    },
                ],
                data: CounsellingData || []
            },
            {
                sheetName: 'Group Counseling-Training Form',
                headers: [
                    {
                        label: "SCHOOL NAME ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "TOPIC",
                        key: "topic",
                    },
                    {
                        label: "DEPARTMENT",
                        key: "departmentName",
                    },
                    {
                        label: "DISCRIPTION",
                        key: "discription",
                    },
                    {
                        label: "REASON OF COUNSELLING",
                        key: "reason",
                    },
                    {
                        label: "DATE OF COUNSELLING",
                        key: "date",
                    },
                ],
                data: TrainingData || []
            },
            {
                sheetName: 'Teaching Staff',
                headers: [
                    {
                        label: "SCHOOL NAME ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "AGE",
                        key: "Age",
                    },
                    {
                        label: "DEPARTMENT",
                        key: "departmentName",
                    },
                    {
                        label: "CLASS",
                        key: "className",
                    },
                    {
                        label: "GENDER",
                        key: "gender",
                    },
                    {
                        label: "MARITAL STATUS",
                        key: "MaritalStatus",
                    },
                    {
                        label: "DISCRIPTION",
                        key: "discription",
                    },
                    {
                        label: "SUBJECT",
                        key: "Subject",
                    },
                    {
                        label: "DATE",
                        key: "date",
                    },
                ],
                data: TSData || []
            },
            {
                sheetName: 'Non-Teaching Staff',
                headers: [
                    {
                        label: "SCHOOL NAME ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "AGE",
                        key: "Age",
                    },
                    {
                        label: "GENDER",
                        key: "gender",
                    },
                    {
                        label: "MARITAL STATUS",
                        key: "MaritalStatus",
                    },
                    {
                        label: "DISCRIPTION",
                        key: "discription",
                    },
                    {
                        label: "HOSTEL NAME",
                        key: "HostelName",
                    },
                    {
                        label: "NTS",
                        key: "NTS",
                    },
                    {
                        label: "Date",
                        key: "date",
                    },
                ],
                data: NTSData || []
            }
        ];

        // Create a new workbook
        const sheetsToExport = sheetData.filter(sheet => sheet.data.length > 0);

        const workbook = XLSX.utils.book_new();

        sheetsToExport.forEach(sheet => {
            const worksheetData = this.convertToSheetData(sheet.headers, sheet.data);
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            // XLSX.utils.book_append_sheet(workbook, worksheet, sheet.sheetName);

            // Set column widths (10 characters wide)
            const wscols = sheet.headers.map(header => ({ wch: 20 }));
            worksheet['!cols'] = wscols;

            // Set row heights (10 pixels high)
            worksheet['!rows'] = sheet.data.map(() => ({ hpx: 20 }));

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, sheet.sheetName);
        });

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'ConsolidateForms.xlsx');
    };
    changeTab = (e, a) => {
        console.log(e, "event")
        var counselling = document.getElementById("counselling")
        var training = document.getElementById("training")
        var teaching = document.getElementById("teaching")
        var nonteaching = document.getElementById("nonteaching")
        var link1 = document.getElementById("link1")
        var link2 = document.getElementById("link2")
        var link3 = document.getElementById("link3")
        var link4 = document.getElementById("link4")
        if (a == "counselling") {
            counselling.classList.remove("d-none")
            training.classList.add("d-none")
            teaching.classList.add("d-none")
            nonteaching.classList.add("d-none")
            link1.classList.add("active-a")
            link2.classList.remove("active-a", "active")
            link3.classList.remove("active-a", "active")
            link4.classList.remove("active-a", "active")
        }
        if (a == "training") {
            training.classList.remove("d-none")
            counselling.classList.add("d-none")
            teaching.classList.add("d-none")
            nonteaching.classList.add("d-none")
            link1.classList.remove("active-a", "active")
            link2.classList.add("active-a", "active")
            link3.classList.remove("active-a", "active")
            link4.classList.remove("active-a", "active")

        }
        if (a == "teaching") {
            teaching.classList.remove("d-none")
            counselling.classList.add("d-none")
            training.classList.add("d-none")
            nonteaching.classList.add("d-none")
            link2.classList.remove("active-a", "active")
            link3.classList.add("active-a", "active")
            link1.classList.remove("active-a", "active")
            link4.classList.remove("active-a", "active")
        }
        if (a == "nonteaching") {
            nonteaching.classList.remove("d-none")
            counselling.classList.add("d-none")
            training.classList.add("d-none")
            teaching.classList.add("d-none")
            link3.classList.remove("active-a", "active")
            link4.classList.add("active-a", "active")
            link1.classList.remove("active-a", "active")
            link2.classList.remove("active-a", "active")
        }
    }

    render() {
        const {
            badgeColor,
            badgeText,
            textalert,
            color,
            MOlist, //marketing off
            Saleslist, alertVisible, added, notadded, loading,
            addCounsellingColumn, addTrainingColumn, counsellingArray, userAddRow, trainingArray, TrainingData, SchoolOptions,
            schoolSelect, CounsellingData, TSData, NTSData, userlog, CompanyFormData
        } = this.state;
        console.log(CompanyFormData, "CompanyFormData");
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3> Form </h3>
                                    </div>
                                    <br></br>
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-7">
                                            <Alert
                                                className="badge-content"
                                                color={badgeColor}
                                                isOpen={this.state.alertVisible}
                                                toggle={this.onDismiss}
                                            >
                                                {badgeText && badgeText}
                                            </Alert>
                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <br></br>
                                    {userlog && userlog.entitytype == "1" ?
                                        <>
                                            <div className="row form-group">
                                                <div className="col-sm-8 ">
                                                    <ul class="nav nav-tabs px-5 d-flex justify-content-between">
                                                        <li class="nav-item">
                                                            <a class="nav-link active active-a" id="link1" aria-current="page" onClick={(e) => this.changeTab(e, "counselling")}>1 to 1  counseling data</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link" id="link2" onClick={(e) => this.changeTab(e, "training")}>Group counseling/Training data</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link" id="link3" onClick={(e) => this.changeTab(e, "teaching")}>Teaching staff</a>
                                                        </li>
                                                        <li class="nav-item">
                                                            <a class="nav-link" id="link4" onClick={(e) => this.changeTab(e, "nonteaching")}> Non Teaching staff</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>

                                            <div className="row form-group">
                                                <div className="col-sm-10" />
                                                <div className="col-sm-2" >
                                                    <button className="btn btn-success" onClick={this.exportToExcel}>Export Consolidate Forms</button>

                                                </div>
                                            </div>

                                            <div id="counselling" className="row form-group ">
                                                <div className="card-header mx-3">
                                                    <h1 className="">Counseling Form Datas :</h1>
                                                </div>
                                                <br></br>
                                                <div className="col-sm-12">
                                                    {loading ? <div className="text-center">
                                                        < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                    </div> : this.state.CounsellingData && this.state.CounsellingData.length > 0 ?
                                                        <>
                                                            <div className="row form-group">
                                                                <div className="col-sm-10"></div>
                                                                <div className="col-sm-2 py-1">
                                                                    {this.state.CounsellingData && this.state.CounsellingData.length > 0 && (
                                                                        <CSVLink
                                                                            data={this.state.CounsellingData}
                                                                            headers={this.excelColumn}
                                                                        >
                                                                            <button
                                                                                className="btn btn-success excelBtn"
                                                                                style={{ marginLeft: "135px" }}
                                                                            >
                                                                                EXCEL
                                                                            </button>
                                                                        </CSVLink>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <Datatable
                                                                data={this.state.CounsellingData}
                                                                columnHeading={this.column}
                                                            />
                                                        </>


                                                        : <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data Found</div>
                                                    }

                                                </div>
                                            </div>
                                            <br></br>
                                            <div id="training" className="row form-group d-none">
                                                <div className="card-header  mx-3">
                                                    <h1 className="">Group Counseling/Training Form Datas :</h1>
                                                </div>
                                                <br></br>
                                                <div className="col-sm-12">
                                                    {loading ? <div className="text-center">
                                                        < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                    </div> : this.state.TrainingData ?
                                                        <>
                                                            <div className="row form-group">
                                                                <div className="col-sm-10"></div>
                                                                <div className="col-sm-2 py-1">
                                                                    {this.state.TrainingData && this.state.TrainingData.length > 0 && (
                                                                        <CSVLink
                                                                            data={this.state.TrainingData}
                                                                            headers={this.excelcolumn1}
                                                                        >
                                                                            <button
                                                                                className="btn btn-success excelBtn"
                                                                                style={{ marginLeft: "135px" }}
                                                                            >
                                                                                EXCEL
                                                                            </button>
                                                                        </CSVLink>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <Datatable
                                                                data={this.state.TrainingData}
                                                                columnHeading={this.column1}
                                                            />
                                                        </>


                                                        : <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data Found</div>}

                                                </div>
                                            </div>
                                            <br></br>
                                            <div id="teaching" className="row form-group d-none">
                                                <div className="card-header  mx-3">
                                                    <h1 className="">Teaching Staff Form Datas :</h1>
                                                </div>
                                                <br></br>
                                                <div className="col-sm-12">
                                                    {loading ? <div className="text-center">
                                                        < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                    </div> : this.state.TSData && this.state.TSData.length > 0 ?
                                                        <>
                                                            <div className="row form-group">
                                                                <div className="col-sm-10"></div>
                                                                <div className="col-sm-2 py-1">
                                                                    {this.state.TSData && this.state.TSData.length > 0 && (
                                                                        <CSVLink
                                                                            data={this.state.TSData}
                                                                            headers={this.excelcolumn2}
                                                                        >
                                                                            <button
                                                                                className="btn btn-success excelBtn"
                                                                                style={{ marginLeft: "135px" }}
                                                                            >
                                                                                EXCEL
                                                                            </button>
                                                                        </CSVLink>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <Datatable
                                                                data={this.state.TSData}
                                                                columnHeading={this.column2}
                                                            />
                                                        </>


                                                        : <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data Found</div>}

                                                </div>
                                            </div>
                                            <br></br>
                                            <div id="nonteaching" className="row form-group d-none">
                                                <div className="card-header  mx-3">
                                                    <h1 className="">Non-Teaching Staff Form Datas :</h1>
                                                </div>
                                                <br></br>
                                                <div className="col-sm-12">
                                                    {loading ? <div className="text-center">
                                                        < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                    </div> : this.state.NTSData && this.state.NTSData.length > 0 ?
                                                        <>
                                                            <div className="row form-group">
                                                                <div className="col-sm-10"></div>
                                                                <div className="col-sm-2 py-1">
                                                                    {this.state.NTSData && this.state.NTSData.length > 0 && (
                                                                        <CSVLink
                                                                            data={this.state.NTSData}
                                                                            headers={this.excelcolumn3}
                                                                        >
                                                                            <button
                                                                                className="btn btn-success excelBtn"
                                                                                style={{ marginLeft: "135px" }}
                                                                            >
                                                                                EXCEL
                                                                            </button>
                                                                        </CSVLink>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <Datatable
                                                                data={this.state.NTSData}
                                                                columnHeading={this.column3}
                                                            />
                                                        </>


                                                        : <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data Found</div>}

                                                </div>
                                            </div>
                                        </>
                                        : userlog.entitytype == "2" ?

                                            <>

                                                <div className="row form-group">
                                                    <div className="col-sm-12">
                                                        {loading ? <div className="text-center">
                                                            < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                        </div> : this.state.CompanyFormData ?
                                                            <>
                                                                <div className="row form-group">
                                                                    <div className="col-sm-10"></div>
                                                                    <div className="col-sm-2 py-1">
                                                                        {this.state.CompanyFormData && this.state.CompanyFormData.length > 0 && (
                                                                            <CSVLink
                                                                                data={this.state.CompanyFormData}
                                                                                headers={this.excelcolumn4}
                                                                            >
                                                                                <button
                                                                                    className="btn btn-success excelBtn"
                                                                                    style={{ marginLeft: "135px" }}
                                                                                >
                                                                                    EXCEL
                                                                                </button>
                                                                            </CSVLink>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <Datatable
                                                                    data={this.state.CompanyFormData}
                                                                    columnHeading={this.column4}
                                                                />
                                                            </>
                                                            : <>No data Found</>}

                                                    </div>
                                                </div>
                                            </>

                                            : <>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </React.Fragment>
        );
    }
}

export default UserPyschologyForm;
