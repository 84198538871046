import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CmsContent from '../../MiddleWare/CmsContent';
import DownPanel from './DownPanel';
import RightPanel from './RightPanel';
import { ACCESS_POINT } from '../../config';
import renderHTML from 'react-render-html';
import Pagination from 'react-js-pagination';

class PublicationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageList: [],
      limit: 20,
      actual: null,
      date: null,
      themeid: 0,
      LinkId: 0
    };
    let userlog = localStorage.getItem('userlog');
    userlog = JSON.parse(userlog);
    localStorage.setItem('userId', userlog.id);
    localStorage.setItem('userName', userlog.userName);
    localStorage.setItem('userEmail', userlog.email);
    localStorage.setItem('userMobile', userlog.mobileNumber);
  }

  async componentDidMount() {
    let result = await CmsContent.getFreedom(
      'tbl_pages.id,tbl_pages.Date,tbl_pages.file,tbl_pages.contentTitle1,tbl_mapping_page.categoryName,tbl_mapping_page.pageId,theme_master.themeName,tbl_pages.content1,tbl_pages.linkto as link',
      'tbl_mapping_page,tbl_pages,theme_master',
      `tbl_mapping_page.pageId=tbl_pages.id and theme_master.themeId=tbl_pages.themeId and  tbl_mapping_page.categoryId=55 and tbl_pages.status='show'`,
      'tbl_pages.id',
      'tbl_pages.id'
    );
    if (result) {
      // console.log(result.data)
      if (result.data[0].Date != null && result.data[0].Date != undefined) {
        var dataformate = result.data[0].Date.split('-');
        this.setState({
          date: dataformate[2] + '-' + dataformate[1] + '-' + dataformate[0]
        });
      }
      this.setState({ pageList: result.data });
      this.handlePageChanges(1);
    }
  }

  handlePageChanges = (pageNumber, val = true) => {
    let activePage = pageNumber;
    let startindex;
    let endindex;
    let limit = this.state.limit;
    let displaycontent = [];

    endindex = activePage * limit;
    startindex = endindex - limit;
    this.state.pageList.map((value, key) => {
      // console.log(value)
      if (key >= startindex && key < endindex) {
        displaycontent.push(value);
      }
    });
    let a = [];
    var d = '';
    displaycontent.map((ival, key) => {
      //  console.log(ival)
      this.setState({ themeid: ival.pageId });
      a.push(
        <div className="col-sm-6">
          <div className="card" style={{ padding: 5 }}>
            <div className="row">
              <div className="col-sm-3">
                <a href={`${ival.link}`} target="_blank">
                  <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${ival.file}`} alt="thumbnail" style={{ width: 'auto', height: 111 }} />
                </a>
              </div>
              <div
                className="col-sm-9"
              // style={{
              //   paddingLeft: 0,
              //   paddingRight: 10
              // }}
              >
                <div style={{ marginBottom: 7 }}>
                  <a href={`${ival.link}`} target="_blank">{ival.contentTitle1}</a>
                  {/* <p>{ival.id}</p> */}
                </div>
                {renderHTML(ival.content1)}
                <a href={`${ival.link}`} target="_blank">
                  <p style={{ color: 'red' }}>Read more</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    });
    this.setState({
      displaycontent,
      activePage: activePage,
      startindex: startindex,
      endindex: endindex,
      actual: a
    });
    if (val) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  };
  render() {
    const { pageList, date } = this.state;
    // console.log(pageList.length);
    const len = pageList.length;
    return (
      <React.Fragment>
        <div className="container-fluid container-margin-top">
          <div className="row form-group">
            <div className="col-sm-9">
              <div className="card">
                <div className="card-body">
                  <div className="row form-group">
                    <h2 className="theme-tiile text-capitalize">References</h2>
                  </div>
                  {/* <p
                    style={{
                      color: '#828282',
                      fontSize: 14,
                      lineHeight: 2,
                      fontWeight: 400,
                      marginTop: 0,
                      paddingTop: 0
                    }}
                  >
                    {'Last Updated  :  '}
                    {date}
                  </p>
                  <br /> */}

                  {/* {(pageList.sheetContent && pageList.sheetContent.length) ?
                    this.loadContent(pageList.sheetContent) : null} */}
                  <div className="row form-group col-sm-12">
                    {this.state.actual}
                    {/* {pageList.map((ival, i) => {
                      console.log(i);
                      return (
                        <div className="col-sm-6">
                          <div className="card" style={{ padding: 5 }}>
                            <div className="row">
                              <div className="col-sm-3">
                                <Link
                                  to={`/CMS/${ival.themeName}/${ival.pageId}`}
                                >
                                  <img
                                    src={`${ACCESS_POINT}/superAdmin/file?fileurl=${ival.file}`}
                                    alt="thumbnail"
                                    style={{ width: "auto", height: 111 }}
                                  />
                                </Link>
                              </div>
                              <div
                                className="col-sm-9"
                                style={{
                                  paddingLeft: 0,
                                  paddingRight: 10
                                }}
                              >
                                <Link
                                  to={`/CMS/${ival.themeName}/${ival.pageId}`}
                                >
                                  {ival.contentTitle1}
                                </Link>
                                {renderHTML(ival.content1)}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })} */}
                  </div>
                  <br />
                  <div>
                    {/* {len > 20 && ( */}
                    <Pagination
                      prevPageText={<i class="fa fa-caret-left" aria-hidden="true" />}
                      nextPageText={<i class="fa fa-caret-right" aria-hidden="true" />}
                      activePage={this.state.activePage}
                      itemsCountPerPage={20}
                      totalItemsCount={len / this.state.limit}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChanges}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                    {/* )} */}
                  </div>
                  <br />
                  {/* <div className="blog-post-author d-flex">
                    <div className="author-thumbnail">
                      <img src={pageList.authorimage} alt="" />
                    </div>
                    <div className="author-info">
                      <a className="author-name">
                        {pageList.authorname}, <span>The Author</span>
                      </a>
                      <p>{pageList.authorabout}</p>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="card">
                <div className="card-body">
                  <RightPanel />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row form-group">
            <div className="col-sm-12 mb-new">
              <div className="card">
                <div className="card-body px-0">
                  <DownPanel />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </React.Fragment>
    );
  }
}

export default PublicationList;
