import React from "react";
import './page.css'

export default class Paginationfun extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: this.props.page ? this.props.page : 1,
            totalPages: this.props.totalpage ? this.props.totalpage : 1,
        }
    }

    render() {
        let { page, totalPages } = this.state
        let liTag = []
        if (page) {
            let active;
            let beforePage = page - 1;
            let afterPage = page + 1;
            if (page > 1) {
                liTag.push(< li class="btn prev" onClick={() => {
                    this.props.pagechage(page - 1)
                    this.setState({ page: page - 1 })
                }}> <span><i class="fas fa-angle-left"></i> Prev</span></li >)
            } else if (totalPages == 1) {
                liTag.push(<li class="btn prev" style={{ color: 'gray' }}>
                    <span><i class="fas fa-angle-left"></i> Prev</span></li >)
            }
            if (page > 2) {
                liTag.push(< li class="first numb" onClick={() => {
                    this.props.pagechage(1)
                    this.setState({ page: 1 })
                }}> <span>1</span></li >)
                if (page > 3) {
                    liTag.push(<li class="dots"><span>...</span></li>)
                }
            }
            else if (page == totalPages) { beforePage = beforePage - 2; }
            else if (page == totalPages - 1) { beforePage = beforePage - 1; }
            if (page == 1) { afterPage = afterPage + 2; }
            else if (page == 2) { afterPage = afterPage + 1; }
            for (let plength = beforePage; plength <= afterPage; plength++) {
                if (plength > totalPages) { continue; }
                if (plength == 0) { plength = plength + 1; }
                if (page == plength) { active = "active"; }
                else { active = ""; }
                if (plength > 0) {
                    liTag.push(<li class={`numb ${active}`} onClick={() => {
                        this.props.pagechage(plength)
                        this.setState({ page: plength })
                    }}> <span>{plength}</span></li >)
                }
            }
            if (page < totalPages - 1) {
                if (page < totalPages - 2) { liTag.push(< li class="dots" > <span>...</span></li >) }
                liTag.push(< li class="last numb" onClick={() => {
                    this.props.pagechage(totalPages)
                    this.setState({ page: totalPages })
                }} > <span>{totalPages}</span></li >)
            }
            if (page < totalPages) {
                liTag.push(< li class="btn next" onClick={() => {
                    this.props.pagechage(page + 1)
                    this.setState({ page: page + 1 })
                }} > <span>Next <i class="fas fa-angle-right"></i></span></li >)
            } else if (totalPages == 1) {
                liTag.push(< li class="btn next" style={{ color: 'gray' }} >
                    <span>Next <i class="fas fa-angle-right"></i></span></li >)
            }
        }
        return (<>
            <div class='bodypage123'>
                <div class="pagination">
                    <ul>{liTag && liTag} </ul>
                </div>
            </div>
        </>)
    }

}