import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CmsContent from '../../MiddleWare/CmsContent';
import './usernav.css';
import ScriptTag from 'react-script-tag';
import LoginModal from '../../components/Modal/Modal';
import otpmodelview from '../Modal/Otpmodel';
import iconOpen from '../../images/CMS/menu-alt-512.png';
import { ACCESS_POINT } from '../../config';

const navbarScript = '/js/navbar.js';
class UserNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      categoryArrray: [],
      fullList: [],
      themeId: null,
      adminId: JSON.parse(localStorage.getItem('userlog')).customerId,
      exemption: [41, 43, 44, 45, 46, 47, 49, 58],
      screen: window.screen.height
    };
  }

  async componentDidMount() {
    this.setState({ themeId: this.props.themeId });
    try {
      const result = await CmsContent.getFreedom(
        'tbl_mapping_page.id as subcatorderid,tbl_mapping_page.listingorder,tbl_mapping_page.listingsubcatagory,tbl_mapping_page.categoryId,tbl_mapping_page.categoryName,tbl_mapping_page.subCategoryId,tbl_mapping_page.subCategoryName,tbl_pages.id as pageId ,theme_master.themeName,tbl_pages.requireLogin',
        'tbl_mapping_page,tbl_pages,theme_master',
        `tbl_mapping_page.pageId = tbl_pages.id and tbl_pages.themeId =  theme_master.themeId and tbl_pages.customerId=${this.state.adminId} and tbl_pages.status="show" and  tbl_mapping_page.status = "active"`,
        'tbl_mapping_page.id',
        'tbl_mapping_page.listingorder,tbl_mapping_page.listingsubcatagory'
      );
      if (result) {
        this.setState({ categories: result.data });
      }
    } catch (error) {
      console.log(error);
    }
  }

  async componentWillReceiveProps(nextProps) {
    this.setState({ themeId: null });
    setTimeout(() => {
      this.setState({ themeId: this.props.themeId });
    }, 500);
  }
  call = () => {
    // window.scrollTo(0, 0);
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };
  render() {
    const { categories, themeId, exemption } = this.state;
    var categoryData = [];
    var reference = [];
    var fullList = [];
    if (categories.length > 0) {
      const map = new Map();
      for (const item of categories) {
        if (!map.has(item.categoryId)) {
          map.set(item.categoryId, true); // set any value to Map
          categoryData.push({ categoryId: item.categoryId });
        }
      }

      fullList = categoryData.map(values => {
        return categories.filter(list => list.categoryId === values.categoryId);
      });
    }
    var chunk_size = 5;
    var groups = '';
    var fullData = [];
    var exceptionData = [];
    if (fullList.length > 0) {
      fullList = fullList.filter((ival, i) => {
        if (exemption.indexOf(ival[0].categoryId) === -1) {
          return ival;
        } else {
          exceptionData.push(ival);
        }
        return null;
      });
      fullList.map(values => {
        if (values[0].subCategoryName) {
          groups = values
            .map(function (e, i) {
              return i % chunk_size === 0 ? values.slice(i, i + chunk_size) : null;
            })
            .filter(function (e) {
              return e;
            });

          var innerJsonData = [];
          groups.map(innerData => {
            innerJsonData.push(
              <ul>
                {innerData.map(dataValue => {
                  // console.log(dataValue);
                  let para;
                  if (dataValue.subCategoryId === 152) {
                    // please dont remove
                  } else if (dataValue.subCategoryId === 153) {
                    para = ACCESS_POINT + '/superAdmin/file?fileurl=SDAS/Video/letter_1_1583905591293.jpg';
                    return (
                      <a
                        className="dropdown-item text-capitalize"
                        // href="javascript:void(0)"
                        href="/img"
                        // style={{
                        //   fontSize: "12px"
                        // }}
                        target="_blank"
                      // onClick={() => this.msgCom(para)}
                      >
                        {dataValue.subCategoryName}
                      </a>
                    );
                  } else if (dataValue.subCategoryId === 154) {
                    para = ACCESS_POINT + '/superAdmin/file?fileurl=SDAS/Video/letter_1_1583905591293.jpg';
                    return (
                      <a
                        className="dropdown-item text-capitalize"
                        // href="javascript:void(0)"
                        href="/img1"
                        // style={{
                        //   fontSize: "12px"
                        // }}
                        target="_blank"
                      // onClick={() => this.msgCom(para)}
                      >
                        {dataValue.subCategoryName}
                      </a>
                    );
                  }
                  // else if(dataValue.subCategoryId === 156){
                  //   para='/CMS/safetyHealth';
                  //   return (
                  //     <a
                  //       className="dropdown-item text-capitalize"
                  //       // href="javascript:void(0)"
                  //       href={para}
                  //       // style={{
                  //       //   fontSize: "12px"
                  //       // }}
                  //       target="_blank"
                  //       // onClick={() => this.msgCom(para)}
                  //     >
                  //       {dataValue.subCategoryName}
                  //     </a>
                  //   )
                  // }
                  else if (dataValue.subCategoryId === 155) {
                    para = ACCESS_POINT + '/superAdmin/file?fileurl=SDAS/Video/SafetyCulture_1_1583922018018_1583940024923.pdf';
                    return (
                      <a
                        className="dropdown-item text-capitalize"
                        // href="javascript:void(0)"
                        href={para}
                        // style={{
                        //   fontSize: "12px"
                        // }}
                        target="_blank"
                      // onClick={() => this.msgCom(para)}
                      >
                        {dataValue.subCategoryName}
                      </a>
                    );
                  } else {
                    if (dataValue.subCategoryName === 'CUMI ABR MM Nagar') {
                      para = '/CMS/Sheet';
                    } else if (dataValue.subCategoryName === 'Safety Health Checks') {
                      para = '/CMS/safetyHealth';
                    } else if (dataValue.subCategoryName === 'Murugappa Management Services, HO') {
                      para = '/CMS/Sheet3';
                    } else if (dataValue.subCategoryName === 'EID Parry, Nelikuppam') {
                      para = '/CMS/Sheet2';
                    } else if (
                      // jval.categoryName === "Work@Murugappa" &&
                      dataValue.subCategoryName === 'TPI, Avadi'
                    ) {
                      para = '/CMS/Sheet4';
                    } else {
                      para = `/CMS/${dataValue.themeName}/${dataValue.pageId}`;
                    }
                    let neWTab = ['/CMS/Sheet', '/CMS/Sheet2', '/CMS/Sheet3', '/CMS/Sheet4'];
                    if (neWTab.indexOf(para) !== -1) {
                      return (
                        <a className="dropdown-item text-capitalize" target="_blank" href={para}>
                          {dataValue.subCategoryName}
                        </a>
                      );
                    } else {
                      return (
                        <Link className="dropdown-item text-capitalize" to={para}>
                          {dataValue.subCategoryName}
                        </Link>
                      );
                    }
                  }
                })}
              </ul>
            );
          });

          fullData.push(
            <li className="nav-item dropdown position-static">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {values[0].categoryName}
              </a>
              <div className="dropdown-menu w-100" aria-labelledby="navbarDropdown">
                <div className="row ml-3">
                  <div className="col-sm-4" style={{ borderRight: '2px solid red' }}>
                    {innerJsonData}
                  </div>
                </div>
              </div>
            </li>
          );
        } else {
          if (values[0].categoryId === 55) {
            reference.push(
              <li className="nav-item nav-font text-capitalize">
                <Link className={themeId && parseInt(themeId) === values[0].pageId ? 'nav-link font-red referencept11' : 'nav-link referencept11'} to={`/CMS/publication`} style={{paddingTop:'11px',fontSize:13}}>
                  {values[0].categoryName}
                </Link>
              </li>
            );

          } else {

            fullData.push(
              <li className="nav-item nav-font text-capitalize">
                <Link className={themeId && parseInt(themeId) === values[0].pageId ? 'nav-link font-red' : 'nav-link'} to={`/CMS/${values[0].themeName}/${values[0].pageId}`}>
                  {values[0].categoryName}
                </Link>
              </li>
            );
          }
        }
      });
    }

    return (
      <React.Fragment>
        <div className="newspaper-main-menu header-color header-box-shadow">
          <div className="classy-nav-container breakpoint-off">
            <nav className="navbar navbar-expand-lg">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <div>
                    <img src={iconOpen} alt="Menu open" />
                  </div>
                </span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                  <li className="nav-item nav-font">
                    <a className="nav-link" href="/CMS/Home" onClick={this.call}>
                      Home
                    </a>
                  </li>
                  {fullData}
                  <li className="nav-item nav-font">
                    <Link className="nav-link" to={`/CMS/Dynamic/218`} onClick={this.call}>
                      Partnerships
                    </Link>
                  </li>
                  <li className="nav-item nav-font">
                    <Link className="nav-link" to={`/CMS/Evaluation`} onClick={this.call}>
                      Evaluation
                    </Link>
                  </li>
                  <li className="nav-item nav-font">
                    <Link className="nav-link" to={`/CMS/Gamification`} onClick={this.call}>
                      Gamification
                    </Link>
                  </li>

                  <li className="nav-item dropdown position-static">
                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.call}>
                      Online Resource Centre
                    </a>

                    <div
                      className="dropdown-menu w-100"
                      aria-labelledby="navbarDropdown"
                      style={{
                        overflowY: 'scroll',
                        height: this.state.screen / 3
                      }}
                    >
                      <div className="row ml-3">
                        {exceptionData.map((ival, i) => {
                          return (
                            <div className="col-sm-4" style={{ borderRight: '2px solid red' }}>
                              <ul>
                                <b>{ival[0].categoryName}</b>
                                {ival.map((jval, j) => {
                                  if (j < 5) {
                                    return (
                                      <Link className="dropdown-item text-capitalize" to={`/CMS/${jval.themeName}/${jval.pageId}`} onClick={this.call}>
                                        {jval.subCategoryName}
                                      </Link>
                                    );
                                  } else if (j === 5) {
                                    return (
                                      <a
                                        className="dropdown-item text-capitalize"
                                        href={`/CMS/More/${btoa(jval.categoryId)}`}
                                        style={{
                                          textAlign: 'end',
                                          color: 'red'
                                        }}
                                        onClick={this.call}
                                      >
                                        more..
                                      </a>
                                    );
                                  }
                                })}
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </li>
                  {reference}
                </ul>
              </div>
            </nav>
          </div>
        </div>
        {fullData.length > 0 && <ScriptTag type="text/javascript" src={navbarScript} />}
      </React.Fragment>
    );
  }
}

export default UserNav;
