import React, { Component } from "react";
import { ACCESS_POINT } from "../../config";
class HomeInnerContentSection1 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
        <div className="row-fluid ">
          <div
            className="span12 widget-span widget-type-cell bg-f2"
            style={{}}
            data-widget-type="cell"
            data-x={0}
            data-w={12}
          >
            <div className="row-fluid-wrapper row-depth-1 row-number-2 ">
              <div className="row-fluid ">
                <div
                  className="span12 widget-span widget-type-custom_widget "
                  data-widget-type="custom_widget"
                  data-x={0}
                  data-w={12}
                >
                  <div
                    id="hs_cos_wrapper_module_1559273319590503"
                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                    style={{}}
                    data-hs-cos-general-type="widget"
                    data-hs-cos-type="module"
                  >
                    <div className="bg-corners flex-row hzCTR vtSTR">
                      <div className="bg1">
                        <img
                          src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/L_1581174450253.png`}
                          alt="Murugappa Safety Suite"
                          width={853}
                          height={1089}
                        />
                      </div>
                      <div className=" content">
                        <h2
                          className="red-text"
                          style={{ textAlign: "center", marginBottom: 20, marginTop: 8 }}
                        >
                          Murugappa Safety Architecture
                        </h2>
                        {/* <h2 className="blackcolor">Safestart</h2> */}
                        <ul>
                          <li>
                            Almost 100% of the
                            accidents are due
                            to unsafe acts.
                          </li>
                          <li>
                            40-50% of unsafe
                            behaviors are
                            noticeable at any
                            plant at any given
                            point of time.
                          </li>
                          <li>
                            25-30% of safety awareness is lacking among employees which gets reflected in their unsafe behaviors
                          </li>
                          <li>Targeting zero accidents does not ensure that your plant is safe</li>
                        </ul>
                        <div
                          className="padB-sm"
                          style={{ paddingLeft: "22px" }}
                        >
                          <a className="red-outline" href={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/SafetyCulture_1_1583922018018_1583940024923.pdf`} target="_blank">
                            LEARN MORE
                          </a>
                        </div>
                        {/* <h2 className="blackcolor" style={{ marginBottom: 20 }}>Safety Health Checks</h2> */}
                        {/* <ul>
                          <li>
                            For a culture of safety
                            to flourish, it must be embedded throughout the
                            organization.
                          </li>
                          <li>
                            25-30% of safety
                            awareness is
                            lacking among
                            employees which
                            gets reflected in
                            their unsafe
                            behaviors.
                          </li>
                        </ul> */}
                        {/* <div style={{ paddingLeft: "22px" }}>
                            <a className="red-outline" href={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/SafetyCulture_1_1583922018018_1583940024923.pdf`} target="_blank">
                              LEARN MORE
                            </a>
                          </div> */}
                      </div>
                      <img
                        src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/SafetyImage_1584596533419.jpg`}
                        alt="Murugappa Enterprise Photo & Video Management"
                        style={{ marginTop: 14, borderRadius: 20 }}
                      />
                      <div className="bg2">
                        {/* <img
                          src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/R_1581174589250.png`}
                          alt="Murugappa Enterprise Photo & Video Management"
                          width={853}
                          height={1089}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/*end widget-span */}
              </div>
              {/*end row*/}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default HomeInnerContentSection1;
