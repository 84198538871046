import React, { Component } from "react";
import { Alert } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import cmsContent from "../../../../MiddleWare/CmsContent";
import http from "../../../../MiddleWare/httpMiddleWare";
import Datatable from "../../../../components/Datatable/Datatable";
import LoginModal from "../../../../components/Modal/Modal";
import FromLibrary from "../FromLibrary";
import { ACCESS_POINT } from "../../../../config";
import { ACCESS_SAMPLE } from "../../../../config";
import Switch from "react-switch";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import Progress from "../../../../components/Extra/Progress";
import htmlToDraft from 'html-to-draftjs';


class Theme9 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorState: EditorState.createEmpty(),
			getlibrarydataImage: props.that.getlibrarydataImage,
			getlibrarydataDocs: props.that.getlibrarydataDocs,
			data: [],
			btnDisable: false,
			AllContent: [],
			uploadPercentage: 0,
			errorContentTitle1: '',
			errorSearchTags: '',
			errorSearchTags: '',
			screen: window.screen.height,
			Files: [],
			isEdit: false,
			contentTitle1: '',
		}
	}
	async componentWillMount() {
		try {
			if (this.props.that.data) {

				let pages = [];
				this.props.that.data.map((ival, i) => {
					if (ival.themeId === 9) {
						///console.log(ival)
						pages.push(ival);
					}
				})
				this.setState({ data: pages });
			}
			if (this.props.that.isEdit) {
				console.log('th9')
				let data = JSON.parse(this.props.that.theme4);
				data.map((ival, i) => {
					data[i].description = this.draftToHTML(ival.description);
				})
				this.setState({ isEdit: true, contentTitle1: this.props.that.contentTitle1, searchTags: this.props.that.searchTags, AllContent: data, EditId: this.props.that.id, data: this.props.that.datatable })
			}
		} catch (e) {
			console.log(e);
		}
	};
	column = [
		{
			Header: "Page",
			accessor: "name"
		},
		{
			Header: "Status",
			accessor: "status"
		},
		{
			Header: "Theme",
			accessor: "edit",
			Cell: d => this.showTheme(d)
		},
		{
			Header: "Visibility",
			accessor: "edit",
			Cell: d => this.showVisibility(d)
		},
		{
			Header: "Require Login",
			accessor: "requireLogin"
		},
		{
			Header: "Change Login Status",
			accessor: "edit",
			Cell: d => this.changeLoginStatus(d)
		},
		{
			Header: "Edit",
			accessor: "edit",
			Cell: d => this.Editpages(d)
		},
		{
			Header: "Preview",
			accessor: "edit",
			Cell: d => this.PreviewPage(d)
		}
	];

	contentHeading = [
		{
			Header: "Search By Hint",
			accessor: "hint"
		},
		{
			Header: "File Name",
			accessor: "label",
			Cell: d => this.aModalLink(d)
		},
		{
			Header: "Select",
			accessor: "edit",
			Cell: d => this.checktest(d)
		}
	];
	aModalLink = id => {
		let str = id.original.label;
		let res = str.split("/");
		return (
			<a href="#" onClick={() => this.viewmedia(id.original.label)}>
				{res[2]}
			</a>
		);
	};
	showTheme = d => {
		return `Theme-${d.original.themeId}(To Add Images,Description in Page)`;
	}
	showVisibility = id => {
		return (
			<button
				type="button"
				className="btn btn-info"
				onClick={() => this.updateVisibility(id)}
			>
				Hide/Show
			</button>
		);
	};
	updateVisibility = async value => {
		const index = value.index;
		console.log(index);
		const previousData = [...this.state.data];
		//console.log(previousData);
		const newData = { ...previousData[index] };
		console.log(newData);
		if (newData.status === "show") {
			newData.status = "hide";
		} else {
			newData.status = "show";
		}
		const id = newData.id;
		const data = previousData.filter(value => value.id !== id);
		data.splice(index, 0, newData);
		try {
			const result = await cmsContent.updatePage(newData);
			if (result) {
				this.setState({ data: data });
			}
		} catch (error) {
			this.setState({ data: previousData });
			console.log(error);
		}
	};

	changeLoginStatus = id => {
		return (
			<button
				type="button"
				className="btn btn-info btn-lg col-lg-12" style={{paddingLeft:5,marginBottom:'4px',fontSize:'10px'}}
				onClick={() => this.updateLogin(id)}
			>
				Change Login Status
			</button>
		);
	};

	updateLogin = async value => {
		const index = value.index;
		const previousData = [...this.state.data];
		const newData = { ...previousData[index] };
		if (newData.requireLogin === "yes") {
			newData.requireLogin = "no";
		} else {
			newData.requireLogin = "yes";
		}
		const id = newData.id;
		const data = previousData.filter(value => value.id !== id);
		data.splice(index, 0, newData);
		this.setState({ data });
		try {
			const result = await cmsContent.updateLoginStatus(newData);
			if (result) {
				this.setState({ data: data });
			}
		} catch (error) {
			this.setState({ data: previousData });
			console.log(error);
		}
	};

	draftToHTML = v => {
		const { contentBlocks, entityMap } = htmlToDraft(v);
		const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
		const editorState = EditorState.createWithContent(contentState);
		return editorState;
	}

	PreviewPage = (d) => {

		return (<button
			type="button"
			className="btn btn-info"
			onClick={() => this.PreviewRoute(d)}
		>
			Preview
		</button>
		);
	}

	PreviewRoute = (d) => {
		console.log(d);
		let id = d.original.id;
		let customerId = d.original.customerId;
		if (id) {
			window.open(
				`${ACCESS_SAMPLE}/samplesite/th9/${id}/${customerId}`,
				'_blank'
			);
		}
	}

	Editpages = value => {
		return (<button className="btn btn-info" onClick={() => this.onEdit(value)}>
			Edit
		</button>)
	};

	onEdit = d => {
		const org = d.original;
		const index = d.index;
		console.log(org);
		const options = [
			{ label: "Theme1", value: "1" },
			{ label: "Theme2", value: "2" },
			{ label: "Theme3", value: "3" },
			{ label: "Theme4", value: "4" },
			{ label: "Theme5", value: "5" },
			{ label: "Theme6", value: "6" },
			{ label: "Theme7", value: "7" },
			{ label: "Theme8", value: "8" },
			{ label: "Theme9", value: "9" }
		];
		if (typeof this.props.these === 'function') {
			this.props.these('pageName', org.name);
			this.props.these('autherSelected', this.props.that.authorlist.filter(ival => ival.value === org.authorid)[0]);
			this.props.these('themeSelected', options.filter(ival => ival.value == org.themeId)[0]);
			this.props.these('checked', org.show == 1 ? 1 : 0);
		}
		let data = JSON.parse(org.sheetContent);
		data.map((ival, i) => {

			if (ival.description == null) {
				ival.description = `<p></p>`
			}
			console.log(ival);
			data[i].description = this.draftToHTML(ival.description);
		})
		this.setState({
			contentTitle1: org.contentTitle1,
			searchTags: org.searchTags,
			isEdit: true,
			AllContent: data,
			EditId: org.id,
			Index: index
		})
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
	}

	updateCategory = async e => {
		e.preventDefault();
		const {
			checked,
			autherSelected,
			pageName,
			customerId,
			isEdit
		} = this.props.that;
		const { editorState,
			contentTitle1,
			searchTags,
			AllContent, Files, EditId, Index } = this.state;
		if (!autherSelected) {
			this.props.these('errorAuthorName', 'Select Author')
			return false;
		} else if (!pageName) {
			this.props.these('errorAuthorName', '')
			this.props.these('errorPageName', 'Enter Page name')
			return false;
		}
		else if (!contentTitle1) {
			this.setState({ errorContentTitle1: 'Enter Content Title' });
			return false;
		}
		let AllContent1 = [];
		AllContent.map((ival, i) => {
			let arr = {};
			arr.description = ival.description
				? draftToHtml(
					convertToRaw(ival.description.getCurrentContent())
				)
				: null;
			arr.title = ival.title;
			arr.files = ival.files;
			AllContent1.push(arr);

		});
		let today = new Date();
		let dateformat = today.getDate();
		if (today.getDate() < 10) {
			dateformat = "0" + today.getDate();
		}
		let date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + dateformat;
		const formData = new FormData();
		formData.append("name", pageName);
		formData.append("themeId", '9');
		if (isEdit == true) {
			formData.append("authorid", autherSelected[0].value);
		}
		else {
			formData.append("authorid", autherSelected.value);
		}
		formData.append("contentTitle1", contentTitle1);
		formData.append("searchTags", searchTags);
		formData.append("show", checked);
		Files.map((ival, i) => {
			let a = Object.keys(ival)[0];
			formData.append(a, ival[a])
		});
		formData.append("sheetContent", JSON.stringify(AllContent1));
		formData.append("Date", date);
		formData.append("status", "show");
		formData.append("customerId", customerId);
		formData.append("id", EditId);
		console.log([...formData]);
		try {

			let { data } = await http.put(
				ACCESS_POINT + `/cmsContent/tbl_pages`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data"
					},
					onUploadProgress: progressEvent => {
						this.setState({
							uploadPercentage: parseInt(
								Math.round((progressEvent.loaded * 100) / progressEvent.total)
							)
						});
						setTimeout(() => this.setState({ uploadPercentage: 0 }), 7000);

					}
				}
			);

			if (data && data.length) {
				data = data[0];
				const previousData = [...this.state.data];

				if (this.props.that.isEdit) {
					let index = this.props.that.data.indexOf(this.props.that.data1);
					previousData[index] = data;

				}
				else {
					previousData[Index] = data;
				}
				setTimeout(() => this.setState({
					contentTitle1: "",
					editorState: EditorState.createEmpty(),
					searchTags: "",
					AllContent: [],
					btnDisable: false,
					data: previousData,
					errorContentTitle1: '',
					isEdit: false,
				}), 7000);
				this.props.these('pageName', '');
				this.props.these('checked', 0);
				this.props.these('errorPageName', '')
				this.props.these('autherSelected', '');
				this.props.these('themeSelected', '');
				this.props.these('isEdit', false)
				/*window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});*/
				//this.props.these('alertVisible', true);
				setTimeout(() => this.props.these('alertVisible', true), 7000)

			}



		} catch (error) {
			console.log(error);
		}

	}


	checktest = id => {
		return (
			<button
				type="button"
				className="btn btn-info"
				data-dismiss="modal"
				onClick={() => {
					let { a, AllContent } = this.state;
					if (a.j !== undefined) {
						AllContent[a['i']].files[a['j']][a['name']] = id.original.label;
						this.setState({ a: {}, AllContent })
					} else {
						AllContent[a['i']][a['name']] = id.original.label;
						this.setState({ a: {}, AllContent })
					}
				}}
			>
				Select
			</button>
		);
	};

	viewmedia = async value => {
		this.setState({
			videotype: "",
			viewmedia: ACCESS_POINT + `/superAdmin/file?fileurl=${value}`
		});
		var that = this;
		setTimeout(function () {
			if (value.split(".")[1] === "mp4") {
				that.setState({ videotype: "video" });
			} else if (
				value.split(".")[1] === "jpg" ||
				value.split(".")[1] === "png" ||
				value.split(".")[1] === "jpeg"
			) {
				that.setState({ videotype: "image" });
			} else {
				that.setState({ videotype: null });
			}
		}, 500);
	}


	handlechange = async e => {
		this.setState({ [e.target.name]: e.target.value });
	}

	HandlingChange = (a, b, c, d = null) => {
		let { AllContent, Files } = this.state;
		if (d !== null) {
			if (c === 'Img') {
				AllContent[a].files[d][c] = b.target.files[0].name;
				Files.push({ [b.target.files[0].name]: b.target.files[0] })
			} else {
				AllContent[a].files[d][c] = b.target.value;
			}
		} else {
			if (c === 'description') {
				AllContent[a][c] = b;
			} else {
				AllContent[a][c] = b.target.value;
			}
		}
		this.setState({ AllContent });
	}

	AddNewGallery = () => {
		let { AllContent } = this.state;
		AllContent.push({ title: '', description: '', files: [] });
		this.setState({ AllContent });
	};
	AddNewImage = (i) => {
		let { AllContent } = this.state;
		AllContent[i].files.push({ Img: '', name: '' });
		this.setState({ AllContent });
	};


	onEditorStateChange = editorState => {
		this.setState({
			editorState
		});
	};

	submitCategory = async (e) => {
		e.preventDefault();
		const {
			checked,
			autherSelected,
			pageName,
			customerId,

		} = this.props.that;
		const { editorState,
			contentTitle1,
			searchTags,
			AllContent,
			Files,
			errorContentTitle1 } = this.state;


		if (!autherSelected) {
			this.props.these('errorAuthorName', 'Select Author')
			return false;
		} else if (!pageName) {
			this.props.these('errorAuthorName', '')
			this.props.these('errorPageName', 'Enter Page name')
			return false;
		} else if (!contentTitle1) {
			this.props.these('errorPageName', '');
			this.setState({ errorContentTitle1: 'Enter Content Title' });
			return false;
		} else {
			this.setState({ errorContentTitle1: '' });
			//return true;
		}
		let AllContent1 = [];
		AllContent.map((ival, i) => {
			let arr = {};
			arr.description = ival.description
				? draftToHtml(
					convertToRaw(ival.description.getCurrentContent())
				)
				: null;
			arr.title = ival.title;
			arr.files = ival.files;
			AllContent1.push(arr);
		});
		let today = new Date();
		let dateformat = today.getDate();
		if (today.getDate() < 10) {
			dateformat = "0" + today.getDate();
		}
		let date =
			today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + dateformat;

		const formData = new FormData();
		formData.append("name", pageName);
		formData.append("themeId", '9');
		formData.append("authorid", autherSelected.value);
		formData.append("contentTitle1", contentTitle1);
		formData.append("searchTags", searchTags);
		formData.append("show", checked);
		Files.map((ival, i) => {
			let a = Object.keys(ival)[0];
			formData.append(a, ival[a])
		});
		formData.append("sheetContent", JSON.stringify(AllContent1));
		formData.append("Date", date);
		formData.append("status", "show");
		formData.append("customerId", customerId);
		formData.append("requireLogin", 'no');
		console.log([...formData]);
		try {
			let { data } = await http.post(
				ACCESS_POINT + `/cmsContent/theme/themeNine`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data"
					},
					onUploadProgress: progressEvent => {
						this.setState({
							uploadPercentage: parseInt(
								Math.round((progressEvent.loaded * 100) / progressEvent.total)
							)
						});
						setTimeout(() => this.setState({ uploadPercentage: 0 }), 7000);
					}
				}
			);
			if (data && data.length) {
				data = data[0];
				console.log(data);
				const previousData = [data, ...this.state.data];
				// previousData[Index]=data;
				console.log(previousData);
				setTimeout(() => this.setState({
					contentTitle1: "",
					editorState: EditorState.createEmpty(),
					searchTags: "",
					AllContent: [],
					btnDisable: false,
					data: previousData,
				}), 7000);
				this.props.these('pageName', '');
				this.props.these('checked', 0);
				this.props.these('errorPageName', '')
				this.props.these('autherSelected', '');
				this.props.these('themeSelected', '')
				/*window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				});*/
				//this.props.these('alertVisible', true);
				setTimeout(() => this.props.these('alertVisible', true), 7000)
			}

		} catch (error) {
			console.log(error);
		}

	}

	removeArray = (a, b = null) => {
		const { AllContent } = this.state;
		let index1 = a - 1;
		if (b !== null) {
			let index2 = b - 1;
			AllContent[index1].files.splice(index2, 1);
		} else {
			AllContent.splice(index1, 1);
		}
		this.setState({ AllContent });
	}

	render() {
		const { AllContent, uploadPercentage } = this.state;

		return (
			<React.Fragment>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2">
						<label htmlFor="exampleInputEmail1">
							Content Title
                            </label>
					</div>
					<div className="col-sm-5">
						<input
							type="text"
							className="form-control"
							id="contentTitle1"
							name="contentTitle1"
							placeholder="Enter Content Title"
							value={this.state.contentTitle1}
							onChange={this.handlechange}
						/>
					</div>
					<div className="col-sm-3" />
				</div>
				<div className="row form-group">
					<div className="col-sm-4" />
					<span className="error-show ">
						{this.state.errorContentTitle1}
					</span>
				</div>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2">
						<label htmlFor="exampleInputEmail1">Search Tags</label>
					</div>
					<div className="col-sm-5">
						<textarea
							type="text"
							className="form-control"
							id="searchTags"
							name="searchTags"
							placeholder="Enter Search Tags"
							value={this.state.searchTags}
							onChange={this.handlechange}
						/>
					</div>
					<div className="col-sm-3" />
				</div>
				<div className="row form-group">
					<div className="col-sm-4" />
					<span className="error-show ">{this.state.errorSearchTags}</span>
				</div>

				{AllContent.map((ival, i) => {
					let index = i + 1;
					return (
						<React.Fragment>
							<div className="row">
								<div className="col-12">
									<div className="card">
										<div className="card-header">
											<h4>Gallery Section -{index}</h4>
										</div>
										<div className="card-body">
											<div className="row form-group">
												<div className="col-sm-2" />
												<div className="col-sm-2">
													<label htmlFor="exampleInputEmail1">Section Name -{index}</label>
												</div>
												<div className="col-sm-5">
													<input
														type="text"
														className="form-control"
														placeholder="Enter Section Name"
														value={ival.title}
														onChange={d => this.HandlingChange(i, d, 'title')}
													/>
												</div>
												<div className="col-sm-1">
													<i class="fa fa-times-circle"
														style={{ fontSize: "25px" }}
														onClick={() => this.removeArray(index)}
													></i>
												</div>
												<div className="col-sm-2" />
											</div>
											<div className="row form-group">
												<div className="col-sm-2" />
												<div className="col-sm-2">
													<label htmlFor="exampleInputEmail1">
														Description {index}
													</label>
												</div>
												<div className="col-sm-5">
													<Editor
														editorState={ival.description}
														wrapperClassName="wrapper-className"
														editorClassName="editor-className editor-border"
														toolbarClassName="toolbar-className"
														onEditorStateChange={d => this.HandlingChange(i, d, 'description')}
														toolbar={{
															options: [
																"inline",
																"blockType",
																"fontSize",
																"fontFamily",
																"list",
																"link"
															]
														}}
														value={this.state.editorState}
													/>
												</div>
												<div className="col-sm-3" />
											</div>
											{ival.files.map((jval, j) => {
												let jndex = j + 1;
												return (
													<React.Fragment>
														<div className="row form-group">
															<div className="col-sm-1" />
															<div className="col-sm-1">
																<i class="fa fa-times-circle"
																	style={{ fontSize: "25px" }}
																	onClick={() => this.removeArray(index, jndex)}
																></i>
															</div>
															<div className="col-sm-2">
																<label htmlFor="exampleInputEmail1">
																	Upload Image {jndex}
																</label>
															</div>
															<div className="col-sm-3">
																<input
																	type="file"
																	className="custom-file-input"
																	onChange={d => this.HandlingChange(i, d, 'Img', j)}
																	accept=".pdf,image/*"
																/>
																<label className="custom-file-label" htmlFor="customFileThumbnail" style={{ fontSize: '9px' }}>
																	{jval.Img ? jval.Img : null}
																</label>
																<span>
																	<LoginModal
																		buttonTitle="Image Library"
																		title="Select Image"
																		id="fsdfsf"
																		maxwidth="modal-xl"
																		bodyText={
																			<FromLibrary
																				datas={this.state.getlibrarydataImage}
																				columnHeading={this.contentHeading}
																				imgsource={this.state.viewmedia}
																				type={this.state.videotype}
																			/>
																		}
																		atagLink={true}
																		onClick={() => { this.setState({ a: { i, name: "Img", j } }) }}
																	/>
																</span>
															</div>
															<div className="col-sm-2">
																<label htmlFor="exampleInputEmail1">
																	Image Caption {jndex}
																</label>
															</div>
															<div className="col-sm-3">
																<input
																	type="text"
																	className="form-control"
																	placeholder="Enter Name Title"
																	value={jval.name}
																	onChange={d => this.HandlingChange(i, d, 'name', j)}
																/>
															</div>
														</div>
													</React.Fragment>
												)
											})
											}
											<div className="row form-group">
												<div className="col-sm-2" />
												<div className="col-sm-6" />
												<div className="col-sm-2">
													<button
														type="button"
														className="btn btn-primary"
														onClick={() => this.AddNewImage(i)}
													>
														Add More Image
                    </button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</React.Fragment>
					)
				})
				}
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-6" />
					<div className="col-sm-2">
						<button
							type="button"
							className="btn btn-primary"
							onClick={() => this.AddNewGallery()}
						>
							Add Gallery Section
                    </button>
					</div>
				</div>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-7">
						{uploadPercentage > 0 && (
							<Progress percentage={uploadPercentage} />
						)}
					</div>
					<div className="col-sm-3" />
				</div>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2" />
					<div className="col-sm-5">
						<button
							type="button"
							className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
							disabled={this.state.btnDisable}
							onClick={this.state.isEdit ? this.updateCategory : this.submitCategory}
						>
							{this.state.isEdit ? 'Update' : 'Add Page'}
						</button>
					</div>
					<div className="col-sm-3" />
				</div>
				<div className="row form-group">
					<div className="col-sm-12">
						{this.state.data ? (<Datatable
							data={this.state.data}
							columnHeading={this.column}
						/>) : null}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Theme9;



