import React, { Component } from 'react';
import RightPanel from './RightPanel';
import { Link } from 'react-router-dom';
import imagethumb from '../../images/CMS/OnlineeducationThinkstockPhotos.jpg';
import './style.css';
import renderHTML from 'react-render-html';
class SearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      json: this.props.location.state.data,
      count: this.props.location.state.data.length
    };
  }

  async componentWillReceiveProps(nextProps) {
    this.setState({
      json: nextProps.location.state.data,
      count: nextProps.location.state.data.length
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-9">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  {parseInt(this.state.count) === 0 ? (
                    <div className="col-12">
                      <p>No result found</p>
                    </div>
                  ) : (
                    <div className="col-12">
                      <h2>{this.state.count} Result's found</h2>
                    </div>
                  )}
                  <br />
                </div>
                <div className="row">
                  {this.state.json &&
                    this.state.json.map(value => {
                      return (
                        <React.Fragment>
                          <div className="col-12" style={{ borderBottom: '1px solid #d0d5d8' }}>
                            <div className="row my-2">
                              <div className="col-3">
                                {value.file_extension === 'jpg' ? <img src={value.file} alt="no" width="200" height="auto" /> : <img src={imagethumb} alt="no" width="200" height="auto" />}
                              </div>
                              <div className="col-9">
                                <Link target="_blank" className="nav-link post-title content-padding" to={`/CMS/${value.themeName}/${value.id}`}>
                                  <h2 className="comment-panel-color">{value.contentTitle1}</h2>
                                </Link>
                                <p className="contentDiv">{renderHTML(value.content1)}</p>
                                <Link target="_blank" className="nav-link post-title content-padding" to={`/CMS/${value.themeName}/${value.id}`}>
                                  <span>more >></span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="card">
              <div className="card-body">
                <RightPanel />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchList;
