import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import cmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import { Alert } from "reactstrap";
import { ACCESS_SAMPLE } from "../../../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import AddpagePDF from "../../../components/PDF/Addpagespdf";

//import Theme9 from './addPages/theme9';
import Datatable from "../../../components/Datatable/Datatable";
import { confirmAlert } from "react-confirm-alert";


class AddClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertVisible: false,
      authorlist: [],
      checked: 0,
      customerId: localStorage.getItem("userId"),
      errorPageName: null,
      data: [],
      alertVisible1: false,
      btnDisable: false,
      formAlert: false,
      formBtn: false,
      themeSelected: "",
      screen: window.screen.height,
      danger: "btn btn-danger",
      success: "btn btn-success",
      home: "Home",
      set: "SetusHome",
      formAlertdelete:false
    };
  }
  column = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "CLASS",
      accessor: "class_name",
    },
    {
      Header: "EDIT",
      accessor: "edit",
      Cell: (d) => this.Editpages(d),
    },
    {
      Header: "DELETE",
      accessor: "delete",
      Cell: (d) => this.deletePages(d),
    },


  ];
  deletePages = (d) => {
    let value = d.original
    return (
      <div>
        <button className="btn btn-danger" onClick={() => this.delete(value)}>Delete</button>
      </div>
    )
  }
  delete = (d) => {
    console.log(d.id)
    confirmAlert({
      customUI: ({ onClose }) => {
        return <div style={{ textAlign: "center" }}>
          <br></br>
          <h1>Delete confirmation</h1>
          <h4>Are you sure you want to Delete this permanetly ?</h4><br />
          <button className="btn btn-primary" onClick={() => {
            this.confirmDeleteP(d.id)
            onClose();
          }}  >Yes</button> &nbsp;  &nbsp;
          <button className="btn btn-danger" onClick={onClose} >No</button>
        </div>
      }
    })
  }
  confirmDeleteP = async (d) => {
    console.log(d, "d.id")
    try {
      const result = await cmsContent.deleteMaster(
        "psychol_class",
        d
      );
      if (result) {
        this.setState({ formAlertdelete: true });
        setTimeout(() => {
          this.setState({ formAlertdelete: false })
          window.location.reload()
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  }
  Editpages = (value) => {
    return (
      <button className="btn btn-info" onClick={() => this.onEdit(value)}>
        Edit
      </button>
    );
  };
  onEdit = (value) => {
    let d = value.original;
    console.log(d);


    this.setState({
      State: d.class_name,
      editId: d.id,
      isEdit: true
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  async componentDidMount() {
    this.Mainfun()
  }
  Mainfun = async () => {
    try {
      this.setState({ loading: true })
      const State = await cmsContent.getFreedom(
        "*",
        "psychol_class",
        1,
        "id",
        "id DESC"
      );
      if (State) {
        // console.log(ACCESS_POINT.ACCESS_SAMPLE);
        this.setState({ data: State.data, loading: false });
      }

    } catch (error) {
      console.log(error);
    }
  }
  handlechange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };



  submitCategory = async () => {
    const {
      State,
      isEdit, editId
    } = this.state;
    if (!State) {
      this.setState({ errorState: "Enter class" });
      return false;
    }
    let categoryArray = {}
    categoryArray.class_name = State.trim();
    // console.log([...formData]);
    try {
      const result = isEdit ? await cmsContent.updateMaster(
        "psychol_class",
        editId,
        categoryArray
      ) : await cmsContent.addMaster(
        "psychol_class",
        categoryArray
      );
      if (result) {
        this.setState({
          State: "",
          errorState: '',

          btnDisable: false,
          alertVisible: true,

        });
        this.Mainfun()
        setTimeout(() => {
          this.setState({ alertVisible: false })
          window.location.reload()
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  async componentWillReceiveProps() {
    this.setState({ themeId: 0 });

  }

  render() {
    console.log(this.state.data);

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Add Class</h3>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color="success"
                          isOpen={this.state.alertVisible}
                          toggle={this.onDismiss}
                        >
                          Added Successfully
                        </Alert>
                        <Alert
                          className="badge-content"
                          color="danger"
                          isOpen={this.state.formAlertdelete}
                          toggle={this.onDismiss}
                        >
                          Class Deleted Successfully
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>


                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">CLASS</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          id="pageName"
                          name="State"
                          placeholder="Enter Class"
                          value={this.state.State}
                          onChange={this.handlechange}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorState}
                      </span>
                    </div>
                    <br />

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
                          disabled={this.state.btnDisable}
                          onClick={this.submitCategory}
                        >
                          {this.state.isEdit ? 'Update' : 'Submit'}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <br />

                    <div className="row form-group">
                      <div className="col-sm-12">
                        {this.state.loading ? <div className="text-center"> <i
                          className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                        ></i></div> : this.state.data && this.state.data.length ? (
                          <Datatable
                            data={this.state.data}
                            columnHeading={this.column}
                          />
                        ) : <div className="text-center">No Data Found</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default AddClass;
