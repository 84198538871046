import React from 'react';
import Datatable from '../../components/Datatable/Datatable';
import { FormMiddleWare } from '../../components/Form';
import LoginModal from '../../components/Modal/Modal';
import User from '../../MiddleWare/User';
import AddUser from '../Modal/AddCustomer';
import { async } from 'q';

export default class GetLearnerss extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      mobileNumber: '',
      email: '',
      errorUserName: '',
      errorMobileNumber: '',
      errorEmail: '',
      alertVisible: false,
      data: [],
      uservalue: [],
      updateData: false,
      imgupload: [],
      logoname: null,
      previoususerid: '',
      arrayvalue: [],
      button: '',
      textview: 'No Users',
      textviewusertype: null,
      thiscurrentcustomerid: null
    };
  }

  async componentWillMount() {

    this.getusers();
  }
  getusers = async () => {
    const {
      match: { params }
    } = this.props;
    let id = params['id'];
    this.setState({ thiscurrentcustomerid: id })
    let result = await User.getUservalue(id);

    if (result) {
      this.setState({ data: result.data });
    }
    let getname = await User.getUserById(id);

    if (getname) {
      this.setState({ textview: getname.data[0].userName });
      //  this.setState({ data: getname.data });
    }
  };

  column = [
    {
      Header: 'UserName',
      accessor: 'userName',
      Cell: d => this.getvalue(d.original, 'Userview')
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'MobileNumber',
      accessor: 'mobileNumber'
    },
    {
      Header: 'Status',
      accessor: 'status'
    },
    {
      Header: 'Visibility',
      accessor: 'edit',
      Cell: d => this.showVisibility(d)
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: d => this.edit(d, 'userAdd')
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: d => this.delete(d)
    }
  ];

  getvalue = (value, modalWindowId) => {
    return (
      <button type="button" className="btn btn-warning">
        {value.userName}
      </button>
    );
  };

  onSubmit = async () => {

    let userArray = {};
    let userName = this.state.userName;
    let mobileNumber = this.state.mobileNumber;
    let email = this.state.email;
    let imgupload = this.state.imgupload;

    const formData = new FormData();
    formData.append('userType', '6');
    formData.append('customerId', this.state.thiscurrentcustomerid);
    formData.append('image', imgupload);
    formData.append('userName', userName);
    formData.append('mobileNumber', mobileNumber);
    formData.append('email', email);
    formData.append('status', 'active');


    let resultlearner = await User.adminuserInsert('tbl_user_web', formData);

    if (resultlearner) {
      this.setState({
        alertVisible: true,
        userName: '',
        mobileNumber: '',
        email: '',
        image: '',
        logoname: ''
      });
    }
    setTimeout(() => this.setState({ alertVisible: false }), 3000);



  };


  buttonEdit = async value => {

    let values = value.original;
    const index = value.index;
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    const { userName, mobileNumber, email, image } = { ...previousData[index] };
    var logoname = '';
    if (image != '' && image != undefined && image != null) {
      var logoname = image.split('SDAS/Video/');
    } else {
      var logoname = '';
    }

    // let img = value.image;
    // let res = img.split('/');

    this.setState({
      userIndex: index,
      userName: userName,
      mobileNumber: mobileNumber,
      email: email,
      errorUserName: '',
      errorMobileNumber: '',
      errorEmail: '',
      alertVisible: false,
      updateEmail: false,
      imgupload: image,
      logoname: logoname,
      updateData: true
    });
  };

  onUpdate = async value => {
    let userIndex = this.state.userIndex;
    let userName = this.state.userName;
    let mobileNumber = this.state.mobileNumber;
    let email = this.state.email;
    let imgupload = this.state.imgupload;

    let userArray = {};
    userArray.userName = userName;
    userArray.mobileNumber = mobileNumber;
    userArray.email = email;
    userArray.image = imgupload;

    const previousData = [...this.state.data];

    // get only the edit user from the list of user
    const getData = { ...previousData[userIndex] };

    const id = getData.id;
    getData.userName = userName;
    getData.mobileNumber = mobileNumber;
    getData.email = email;

    const formData = new FormData();
    formData.append('userName', userName);
    formData.append('mobileNumber', mobileNumber);
    formData.append('email', email);
    formData.append('image', imgupload);

    // get all the user except the edit user
    const data = previousData.filter(value => value.id !== id);
    data.splice(userIndex, 0, getData);
    try {
      const result = await User.updateadminuserInsert(id, formData);
      if (result) {
        this.setState({
          data,
          alertVisible: true,
          userName: '',
          mobileNumber: '',
          email: '',
          userIndex: '',
          errorUserName: '',
          errorMobileNumber: '',
          errorEmail: '',
          updateData: false,
          logoname: ''
        });
      }
      setTimeout(() => this.setState({ alertVisible: false }), 3000);
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };

  render() {
    let userType = localStorage.getItem('userType');
    const userData = {
      userName: this.state.userName,
      mobileNumber: this.state.mobileNumber,
      email: this.state.email,
      image: this.state.logoname
    };

    const errorData = {
      errorUserName: this.state.errorUserName,
      errorMobileNumber: this.state.errorMobileNumber,
      errorEmail: this.state.errorEmail
    };
    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12" />
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <center>
                    {' '}
                    <h3>
                      {'List of Users(L4)'}
                      {'  '}
                      {'under '}
                      {'   '}
                      {`${this.state.textview}(L3)`}
                    </h3>{' '}
                  </center>
                </div>
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2"></div>
                    <div className="col-sm-5">
                      {/* <LoginModal
                        atagLink={true}
                        title="User View"
                        id="Userview"
                        onClick={this.buttonView}
                        bodyText={<div>{this.state.uservalue && <Datatable data={this.state.uservalue} columnHeading={this.columns} />}</div>}
                      /> */}
                    </div>
                  </div>
                  <LoginModal
                    buttonTitle="Add Customer"
                    title={this.state.updateData === false ? 'Add User' : 'Update User'}
                    id="userAdd"
                    bodyText={
                      <AddUser
                        handleInput={this.handleUserInput}
                        userData={userData}
                        errorData={errorData}
                        handleSubmit={this.handleSubmit}
                        onDismiss={this.onDismiss}
                        alertVisible={this.state.alertVisible}
                        checkMobileNumber={this.checkMobileNumber}
                        checkEmail={this.checkEmail}
                        uploadimg={this.uploadimg}
                        logoname={this.state.logoname}
                        userType={3}
                        updateData={this.state.updateData}
                      />
                    }
                  />
                  <br />
                  {/* <button type="button" class="btn btn-primary " onClick={this.previousbutton}>
                    BACK
                  </button> */}

                  {this.state.data && <Datatable data={this.state.data} columnHeading={this.column} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
