import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../MiddleWare/CmsContent";
import Datatable from "../../components/Datatable/Datatable";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import CMSaddcatsubcatpdf from "../../components/PDF/Cmsaddcatsubcat";
import SingleSelect from "../../components/Form/SingleSelect";
import { FormMiddleWare, MultiSelect } from "../../components/Form";
export default class QuestionMaster extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            alertVisible: false,
            editvalue: "",
            editid: null,
            disableValue: false,
            addNewSubCategory: false,
            error: null,
            button: "Submit",
            index: null,
            CHAROPTIONS: [],
            Characteristics: null,
            formAlertdelete: false,
            ques: [{ value: "", order: "" }],
            type: null,
            PosorNeg: [
                { value: "Positive", label: "Positive" },
                { value: "Negative", label: "Negative" },
            ],
            customerId: localStorage.getItem("userId"),
        };
    }
    async componentDidMount() {
        this.Mainfun()
    }
    async Mainfun() {
        try {
            // const result = await cmsContent.getFreedom("tbl_category_master");
            const result2 = await cmsContent.getFreedom(
                "id as value, name as label",
                "tbl_EOI_Characteristics_master",
                `customerId='${this.state.customerId}'`,
                "id",
                "id DESC"
            );

            if (result2) {
                //console.log(result);
                this.setState({ CHAROPTIONS: result2.data });
            }
            const result = await cmsContent.getFreedom(
                "tbl_EOI_questions_master.*,tbl_EOI_Characteristics_master.name as charname",
                "tbl_EOI_questions_master,tbl_EOI_Characteristics_master",
                `tbl_EOI_questions_master.customerId=${this.state.customerId}
                 and  tbl_EOI_questions_master.charid= tbl_EOI_Characteristics_master.id`,
                "id",
                "orderBy"
            );

            if (result) {
                console.table(result.data);
                this.setState({ data: result.data });
            }

        } catch (error) {
            console.log(error);
        }
    }
    column = [
        {
            Header: "Characteristics",
            accessor: "charname",
        },
        {
            Header: "Type",
            accessor: "type",
        },
        {
            Header: "Question",
            accessor: "question",
        },
        {
            Header: "Orderby",
            accessor: "orderBy"
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: (d) => this.edit(d),
        },
        {
            Header: "Delete",
            accessor: "delete",
            Cell: (d) => this.delete(d),
        },
    ]
    edit = (d) => {
        let value = d;
        return (
            <button
                type="button"
                className="btn btn-info"
                onClick={() => this.edition(value)}
            >
                Edit
            </button>
        );
    };
    edition = async (value) => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
        let values = value.original;
        let char = { value: values.charid, label: values.charname }
        let type = null
        console.log(values.type == "Positive");
        if (values.type == "Positive") {
            type = true
        } else if (values.type == "Negative") {
            type = false
        }

        let ques = this.state.ques;
        ques[0].value = values.question
        ques[0].order = values.orderBy
        await this.setState({
            index: value.index,
            editvalue: values.name,
            editid: values.id,
            Characteristicsselect: char,
            type,
            ques,
            button: "Update",
        });
    };
    delete = (d) => {

        return (
            <button
                type="button"
                className="btn btn-danger"
                onClick={() => this.deletion(d)}
            >
                Delete
            </button>
        );
    };
    deletion = async (value) => {
        //Getting Previous state data

        const previousData = [...this.state.data];
        // Seperating data row using row-index
        const getData = { ...previousData[value.index] };

        //getting id on that data
        const id = getData.id;
        //removing specific id in previous state data
        const data = previousData.filter((delelteid) => delelteid.id !== id);

        try {
            const result = await cmsContent.deleteMaster(
                "tbl_EOI_questions_master",
                id
            );
            if (result) {
                this.setState({ editvalue: "", formAlertdelete: true, data });

                setTimeout(() => this.setState({ formAlertdelete: false }), 3000);
            }
        } catch (error) {
            this.setState({ data: previousData });
            console.log(error);
        }
    };
    handlechange = (e) => {
        let value = e.target.value;
        this.setState({ editvalue: value });
    };
    addnew = async () => {
        let value = this.state.editvalue;
        let { ques, Characteristicsselect, type } = this.state;
        let customerId = this.state.customerId;
        if (Characteristicsselect == null || type == null) {
            alert("Enter All Fields")
            return false;
        }
        let categoryArray = {};
        categoryArray.charid = Characteristicsselect.value
        categoryArray.type = type ? "Positive" : "Negative"
        categoryArray.question = JSON.stringify(ques)
        categoryArray.customerId = customerId;

        try {
            this.setState({ disableValue: true });
            // const result = await cmsContent.addMaster(
            //     "tbl_EOI_questions_master",
            //     categoryArray
            // );
            const result = await cmsContent.questionEOI("tbl_EOI_questions_master/id", categoryArray)
            if (result) {
                // let valueArray = {};
                // valueArray.value = result.data.insertId;
                // valueArray.name = categoryArray.name;
                // console.log(valueArray);
                // const newData = [valueArray, ...this.state.data];
                this.setState({
                    // data: newData,
                    alertVisible: true,
                    editvalue: "",
                    Characteristicsselect: null,
                    type: null,
                    editid: null,
                    ques: [{ value: "" }],
                    disableValue: false,
                    addNewSubCategory: false,
                    error: null,
                    button: "Submit",
                    index: null,
                });

                setTimeout(() => {
                    this.setState({

                        alertVisible: false,

                    });
                }, 2000);

                this.Mainfun()
            }
        } catch (error) {
            console.log(error);
        }
    };
    update = async () => {

        this.setState({ disableValue: true });
        try {
            let value = this.state.editvalue;
            let { ques, Characteristicsselect, type } = this.state;
            let customerId = this.state.customerId;
            if (Characteristicsselect == null || type == null) {
                alert("Enter All Fields")
                return false;
            }
            let previousdata = [...this.state.data];
            let index = this.state.index;

            let id = this.state.editid;
            let categoryArray = {};
            categoryArray.charid = Characteristicsselect.value
            categoryArray.type = type ? "Positive" : "Negative"
            categoryArray.question = ques[0].value
            categoryArray.orderBy = ques[0].order
            categoryArray.customerId = customerId;
            const result = await cmsContent.updateMaster(
                "tbl_EOI_questions_master",
                id,
                categoryArray
            );
            if (result) {
                // previousdata[index].name = value;

                this.setState({
                    // data: previousdata,
                    alertVisible: true,
                    editvalue: "",
                    Characteristicsselect: null,
                    type: null,
                    editid: null,
                    ques: [{ value: "" }],
                    addNewSubCategory: false,
                    error: null,
                    button: "Submit",
                    index: null, disableValue: false,
                });
                setTimeout(() => {
                    this.setState({ alertVisible: false })
                }, 2000);
                this.Mainfun()
            }
        } catch (error) {
            console.log(error);
        }


    };
    onDismiss = () => {
        this.setState({ alertVisible: false });
        this.setState({ formAlertdelete: false });
    };
    Characteristicsselect = (v, ch) => {
        this.setState({ [ch]: v })
    }
    addmore = async () => {
        let { ques } = this.state;
        ques.push({ value: "", order: "" })
        // if(ques.length==5)
        this.setState({ ques })
    }
    handleans = async (e, i) => {
        let { ques } = this.state;
        ques[i].value = e.target.value
        this.setState({ ques })
    }
    handleord = async (e, i) => {
        let { ques } = this.state;
        ques[i].order = e.target.value
        this.setState({ ques })
    }

    render() {
        const {
            editvalue,
            alertVisible,
            disableValue,
            error,
            button,
            formAlertdelete,
            CHAROPTIONS,
            Characteristicsselect,
            ques,
            PosorNeg,
            type
        } = this.state;
        let ans = []
        // console.table(ques);
        // console.log(type);
        ques.map((ival, i) => {
            ans.push(<div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                    <label htmlFor="subcategory">Question {i + 1}</label>
                </div>
                <div className="col-sm-5">
                    <input
                        type="text"
                        name="subcategory"
                        placeholder={`Question ${i + 1}`}
                        className="form-control"
                        value={ival.value}
                        onChange={(e) => this.handleans(e, i)}
                    />
                    {/* <span className="modal-error-show">{error}</span> */}
                </div>
                <div className="col-sm-3" >
                    <input
                        type="text"
                        name="subcategory"
                        placeholder={`Order by`}
                        className="form-control"
                        value={ival.order}
                        onChange={(e) => this.handleord(e, i)}
                    />
                </div>
            </div>)
        })
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Question Master</h1>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color="success"
                                                    isOpen={alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    Category Added/Updated
                                                </Alert>

                                                <Alert
                                                    className="badge-content"
                                                    color="danger"
                                                    isOpen={formAlertdelete}
                                                    toggle={this.onDismiss}
                                                >
                                                    Delete
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label for="exampleInputEmail1">Select Characteristics</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    options={CHAROPTIONS}
                                                    // disabled={disableReportTo}
                                                    handleChange={(v) => this.Characteristicsselect(v, "Characteristicsselect")}
                                                    selectedService={Characteristicsselect}
                                                    placeholder={"Select..."}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label for="exampleInputEmail1">Type</label>
                                            </div>
                                            <div className="col-sm-5">


                                                <input
                                                    type="radio"
                                                    style={{ margin: "12px" }}
                                                    name={`validation `}
                                                    onChange={() => this.Characteristicsselect(true, "type")}
                                                    value={type}
                                                    checked={type}
                                                />{" "}
                                                Positive
                                                <input
                                                    type="radio"
                                                    style={{ margin: "8px" }}
                                                    name={`validation `}
                                                    onChange={() => this.Characteristicsselect(false, "type")}
                                                    value={!type}
                                                    checked={type != null && !type}
                                                />{" "}
                                                Negative

                                            </div>
                                            <div className="col-sm-3" />
                                        </div>

                                        {ans}
                                        {button != "Update" && <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2" />
                                            <div className="col-sm-5">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    // disabled={disableValue}
                                                    onClick={
                                                        () => this.addmore()
                                                    }
                                                >
                                                    Add More
                                                </button>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>}
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2" />
                                            <div className="col-sm-5">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    disabled={disableValue}
                                                    onClick={
                                                        button === "Update" ? this.update : this.addnew
                                                    }
                                                >
                                                    {this.state.button}
                                                </button>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <br></br>
                                        <span style={{ marginLeft: "5px" }}>
                                            Total of {Object.keys(this.state.data).length} records
                                        </span>

                                        {this.state.data && (
                                            <Datatable
                                                data={this.state.data}
                                                columnHeading={this.column}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

// export default QuestionMaster;
