import React, { Component } from "react";
import MultiSelect from "../../../components/Form/MultiSelect";
import CmsContent from "../../../MiddleWare/CmsContent";
import { CSVLink, CSVDownload } from "react-csv";
import Datatable from "../../../components/Datatable/Datatable";
import { SingleSelect } from "../../../components/Form";
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from "moment";





class UserReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            checked: 0,
            customerId: localStorage.getItem("userId"),
            userlog: localStorage.getItem("userlog"),
            errorPageName: null,
            data: [],
            btnDisable: false,
            formAlert: false,
            counsellingOptions: [
                { label: "1 to 1 [counseling form]", value: 1 },
                { label: "Group Counseling/Training Form", value: 2 },
            ],
            formTypes: [
                { label: "Student", value: 1 },
                { label: "Teaching Staff", value: 2 },
                { label: "Non-Teaching Staff ", value: 3 },
            ],
            SubmitBtn: false,
            loading: false,
            datashow: true,
            datashow1: true,
            DatatableCounselling: [],
            DatatableTrining: [],
            emptyData: false,
            selectedstartdateDisable: true,
            selectedenddateDisable: true,
            submitdisable: false,
            FTDisable: true,
            entityType: JSON.parse(localStorage.getItem("userlog")).entitytype,
            companyDetails: []
        };
    }

    column = [
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "SCHOOL NAME ",
            accessor: "userName",
        },
        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "DOB",
            accessor: "dob",
        },
        {
            Header: "CLASS",
            accessor: "className",
        },
        {
            Header: "DEPARTMENT",
            accessor: "departmentName",
        },

        // {
        //     Header: "STUDENT RANK",
        //     accessor: "studentRank",
        // },
        {
            Header: "NAME OF THE MENTOR",
            accessor: "nameOfMentorName",
        },
        {
            Header: "REASON OF COUNSELING",
            accessor: "reason",
        },
        {
            Header: "DATE OF COUNSELING",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },
        {
            Header: "HOSTEL NAME",
            accessor: "HostelName",
        },

    ];
    column1 = [
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "SCHOOL NAME ",
            accessor: "userName",
        },
        {
            Header: "TOPIC",
            accessor: "topic",
        },
        {
            Header: "DEPARTMENT",
            accessor: "departmentName",
        },
        {
            Header: "DISCRIPTION",
            accessor: "discription",
        },
        {
            Header: "DATE OF COUNSELING",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },

    ];
    column2 = [
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "SCHOOL NAME ",
            accessor: "userName",
        },

        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "AGE",
            accessor: "Age",
        },
        {
            Header: "DEPARTMENT",
            accessor: "departmentName",
        },
        {
            Header: "CLASS",
            accessor: "className",
        },
        {
            Header: "GENDER",
            accessor: "gender",
        },
        {
            Header: "MARITAL STATUS",
            accessor: "MaritalStatus",
        },
        {
            Header: "DISCRIPTION",
            accessor: "discription",
        },
        {
            Header: "SUBJECT",
            accessor: "Subject",
        },
        {
            Header: "Date",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },
    ];
    column3 = [
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "SCHOOL NAME ",
            accessor: "userName",
        },

        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "AGE",
            accessor: "Age",
        },
        {
            Header: "GENDER",
            accessor: "gender",
        },
        {
            Header: "MARITAL STATUS",
            accessor: "MaritalStatus",
        },
        {
            Header: "DISCRIPTION",
            accessor: "discription",
        },
        {
            Header: "HOSTEL NAME",
            accessor: "HostelName",
        },
        {
            Header: "NTS",
            accessor: "NTSname",
        },
        {
            Header: "DATE",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },
    ];
    column4 = [

        {
            Header: "ENTITY NAME",
            accessor: "userName",
        },
        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "EMPLOYEE ID",
            accessor: "employeeId",
        },
        {
            Header: "MOBILE",
            accessor: "mobileNumber",
        },
        {
            Header: "GENDER",
            accessor: "gender",
        },
        {
            Header: "DESGNATION",
            accessor: "designation",
        },
        {
            Header: "DISCRIPTION",
            accessor: "discription",
        },
        {
            Header: "REASON OF COUNSELING",
            accessor: "reason",
        },
        {
            Header: "DATE OF COUNSELING",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },
    ];
    excelColumn1 = [
        {
            label: "COUNSELING TYPE",
            key: "counsellingType",
        },
        {
            label: "SCHOOL NAME",
            key: "userName",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "TOPIC",
            key: "topic",
        },
        {
            label: "DEPARTMENT",
            key: "departmentName",
        },

        {
            label: "DISCRIPTION",
            key: "discription",
        },
        {
            label: "REASON OF COUNSELING",
            key: "reason",
        },
        {
            label: "DATE OF COUNSELING",
            key: "date",
        },

    ]
    excelColumn = [
        {
            label: "COUNSELLING TYPE",
            key: "counsellingType",
        },
        {
            label: "SCHOOL NAME ",
            key: "userName",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "DOB",
            key: "dob",
        },
        {
            label: "CLASS",
            key: "className",
        },
        {
            label: "STUDENT RANK",
            key: "studentRank",
        },
        {
            label: "DEPARTMENT",
            key: "departmentName",
        },
        {
            label: "NAME OF THE MENTOR",
            key: "nameOfMentorName",
        },
        {
            label: "REASON OF COUNSELLING",
            key: "reason",
        },
        {
            label: "DATE OF COUNSELLING",
            key: "date",
        },
        {
            label: "HOSTEL NAME",
            key: "HostelName",
        },
        // {
        //     Header: "EDIT",
        //     accessor: "edit",
        //     Cell: (d) => this.Editpages(d, 'customer'),
        // },
        // {
        //     Header: "STATUS",
        //     // accessor: "edit",
        //     Cell: (d) => this.buttondel(d),
        // },
        // {
        //     Header: "Delete",
        //     accessor: "edit",
        //     Cell: (d) => this.DatatableCheckboxx(d, this.updateMyData, this.state.SelectedEnquiries)
        // }
    ];
    excelColumn2 = [
        {
            label: "COUNSELING TYPE",
            key: "counsellingType",
        },
        {
            label: "SCHOOL NAME ",
            key: "userName",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "Age",
            key: "Age",
        },
        {
            label: "DEPARTMENT",
            key: "departmentName",
        },
        {
            label: "CLASS",
            key: "className",
        },
        {
            label: "GENDER",
            key: "gender",
        },
        {
            label: "MARITAL STATUS",
            key: "MaritalStatus",
        },
        {
            label: "DISCRIPTION",
            key: "discription",
        },
        {
            label: "SUBJECT",
            key: "Subject",
        },
        {
            label: "DATE",
            key: "date",
        },

    ]
    excelColumn3 = [
        {
            label: "COUNSELING TYPE",
            key: "counsellingType",
        },
        {
            label: "SCHOOL NAME ",
            key: "userName",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "AGE",
            key: "Age",
        },
        {
            label: "GENDER",
            key: "gender",
        },
        {
            label: "MARITAL STATUS",
            key: "MaritalStatus",
        },
        {
            label: "HOSTEL NAME",
            key: "HostelName",
        },
        {
            label: "DISCRIPTION",
            key: "discription",
        },
        {
            label: "NTS",
            key: "NTSname",
        },
        {
            label: "DATE",
            key: "date",
        },

    ]
    excelcolumn4 = [
        {
            label: "ENTITY NAME",
            key: "userName",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "EMPLOYEE ID",
            key: "employeeId",
        },
        {
            label: "MOBILE",
            key: "mobileNumber",
        },
        {
            label: "GENDER",
            key: "gender",
        },
        {
            label: "DESGNATION",
            key: "designation",
        },
        {
            label: "DISCRIPTION",
            key: "discription",
        },
        {
            label: "REASON OF COUNSELING",
            key: "reason",
        },
        {
            label: "DATE OF COUNSELING",
            key: "date",

        },

    ]
    async componentDidMount() {
        this.Mainfun()
    }

    Mainfun = async () => {
        try {

        } catch (error) {
            console.log(error);
        }
    }

    optionSelect = async (selectedOption, type) => {
        if (selectedOption && type == "school") {
            let selectedValue = "";
            selectedOption.map(values => (selectedValue += `${values.value},`));
            selectedValue = selectedValue.replace(/,\s*$/, "");
            await this.setState({
                formTypesSelected: selectedOption,
                formTypesSelectedValue: selectedValue,
                selectedstartdateDisable: false,
                DatatableCounselling: [], DatatableTrining: [], DatatableTS: [], DatatableNTS: [], selectedstartdate: "", selectedenddate: "", SubmitBtn: false, submitdisable: false
            });
        }

        if (selectedOption && type == "counsellingType") {
            this.setState({
                counsellingOptSelect: selectedOption,
                counsellingOptSelectValue: selectedOption.value,
                FTDisable: false, formTypesSelected: "",
                DatatableCounselling: [], DatatableTrining: [], selectedstartdate: "", selectedenddate: "", SubmitBtn: false, submitdisable: false
            })

        }
    };

    fromDateSelect = (e) => {
        this.setState({ selectedstartdate: e.target.value, selectedenddateDisable: false, selectedenddate: "", SubmitBtn: false, submitdisable: false, DatatableCounselling: [], DatatableTrining: [] });
    };

    endDateSelect = async (e) => {
        this.setState({ selectedenddate: e.target.value, SubmitBtn: true, submitdisable: false, DatatableCounselling: [], DatatableTrining: [] });
    };

    submitdata = async () => {
        this.setState({ loading: true, submitdisable: true });
        let { formTypesSelected, selectedstartdate, selectedenddate, counsellingOptSelect, formTypesSelectedValue } = this.state
        if (selectedstartdate == undefined && selectedstartdate == undefined) {
            alert("Must fill all fields")
        }
        var userlog = JSON.parse(localStorage.getItem("userlog")).entitytype
        console.log(userlog, "userlog")
        var company = JSON.parse(localStorage.getItem("userlog")).customerId
        console.log(company, "usecompanyrlog")
        var object = {}
        if (userlog == 1) {
            object.formTypesSelected = formTypesSelected
            object.counsellingtype = counsellingOptSelect
            object.fromDate = selectedstartdate
            object.toDate = selectedenddate
            object.entityType = { value: userlog }
            console.log(this.state.userlog.id, object, "object")
            let submit_data = await CmsContent.GetSchoolFormReport(this.state.customerId, "formReport", object)
            console.log(submit_data, "submit data")
            const submit = this.formatDateInForms(submit_data);
            this.setState({ DatatableCounselling: submit.CounsellingForm, DatatableTrining: submit.triningForm, DatatableTS: submit.TSForm, DatatableNTS: submit.NTSForm, emptyData: true })
            setTimeout(() => {
                this.setState({ loading: false })
            }, 3000);
        }
        if (userlog == 2) {
            object.fromDate = selectedstartdate
            object.toDate = selectedenddate
            object.entityType = { value: userlog }
            object.company = { value: this.state.customerId }
            console.log(object, "obj")
            let submit_data = await CmsContent.GetSchoolFormReport(this.state.customerId, "formReport", object)
            console.log(submit_data, "submit data")
            this.setState({companyDetails:submit_data})
            setTimeout(() => {
                this.setState({ loading: false })
            }, 3000);
        }



    }
    formatDateInForms = (data) => {
        const formatDate = (date) => moment(date).format('DD-MM-YYYY');

        const formatArrayDates = (array) => {
            return array.map(item => {
                if (item.date) {
                    item.date = formatDate(item.date);
                }
                return item;
            });
        };

        // Iterate over all keys in the data object
        Object.keys(data).forEach(key => {
            if (Array.isArray(data[key])) {
                data[key] = formatArrayDates(data[key]);
            }
        });

        return data;
    };
    convertToSheetData = (headers, data) => {
        // Extract labels from headers
        const labels = headers.map(header => header.label);

        // Extract data based on keys
        const sheetData = data.map(item => {
            return headers.map(header => item[header.key]);
        });

        // Add labels as the first row
        sheetData.unshift(labels);

        return sheetData;
    };

    exportToExcel = () => {
        // const { sheetData } = this.state;
        const { DatatableCounselling, DatatableTrining, DatatableTS, DatatableNTS } = this.state;
        const sheetData = [
            {
                sheetName: '1 to 1 Counselling Form',
                headers: [
                    {
                        label: "SCHOOL NAME ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "CLASS",
                        key: "className",
                    },
                    {
                        label: "DEPARTMENT",
                        key: "departmentName",
                    },
                    {
                        label: "NAME OF THE MENTOR",
                        key: "nameOfMentorName",
                    },
                    {
                        label: "REASON OF COUNSELLING",
                        key: "reason",
                    },
                    {
                        label: "DATE OF COUNSELLING",
                        key: "date",
                    },
                    {
                        label: "HOSTEL NAME",
                        key: "HostelName",
                    },
                ],
                data: DatatableCounselling || []
            },
            {
                sheetName: 'Group Counseling-Training Form',
                headers: [
                    {
                        label: "SCHOOL NAME ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "TOPIC",
                        key: "topic",
                    },
                    {
                        label: "DEPARTMENT",
                        key: "departmentName",
                    },
                    {
                        label: "DISCRIPTION",
                        key: "discription",
                    },
                    {
                        label: "REASON OF COUNSELLING",
                        key: "reason",
                    },
                    {
                        label: "DATE OF COUNSELLING",
                        key: "date",
                    },
                ],
                data: DatatableTrining || []
            },
            {
                sheetName: 'Teaching Staff',
                headers: [
                    {
                        label: "School Name ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "AGE",
                        key: "Age",
                    },
                    {
                        label: "DEPARTMENT",
                        key: "departmentName",
                    },
                    {
                        label: "CLASS",
                        key: "className",
                    },
                    {
                        label: "GENDER",
                        key: "gender",
                    },
                    {
                        label: "MARITAL STATUS",
                        key: "MaritalStatus",
                    },
                    {
                        label: "DISCRIPTION",
                        key: "discription",
                    },
                    {
                        label: "SUBJECT",
                        key: "Subject",
                    },
                    {
                        label: "DATE",
                        key: "date",
                    },
                ],
                data: DatatableTS || []
            },
            {
                sheetName: 'Non-Teaching Staff',
                headers: [
                    {
                        label: "SCHOOL NAME ",
                        key: "userName",
                    },
                    {
                        label: "NAME",
                        key: "name",
                    },
                    {
                        label: "AGE",
                        key: "Age",
                    },
                    {
                        label: "GENDER",
                        key: "gender",
                    },
                    {
                        label: "MARITAL STATUS",
                        key: "MaritalStatus",
                    },
                    {
                        label: "HOSTEL NAME",
                        key: "HostelName",
                    },
                    {
                        label: "DISCRIPTION",
                        key: "discription",
                    },
                    {
                        label: "NTS",
                        key: "NTSname",
                    },
                    {
                        label: "DATE",
                        key: "date",
                    },
                ],
                data: DatatableNTS || []
            }
        ];

        const sheetsToExport = sheetData.filter(sheet => sheet.data.length > 0);

        const workbook = XLSX.utils.book_new();

        sheetsToExport.forEach(sheet => {
            const worksheetData = this.convertToSheetData(sheet.headers, sheet.data);
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            // XLSX.utils.book_append_sheet(workbook, worksheet, sheet.sheetName);

            // Set column widths (10 characters wide)
            const wscols = sheet.headers.map(header => ({ wch: 20 }));
            worksheet['!cols'] = wscols;

            // Set row heights (10 pixels high)
            worksheet['!rows'] = sheet.data.map(() => ({ hpx: 20 }));

            // Append the worksheet to the workbook
            XLSX.utils.book_append_sheet(workbook, worksheet, sheet.sheetName);
        });

        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });

        saveAs(blob, 'ConsolidateReport.xlsx');
    };

    render() {
        let { DatatableCounselling, DatatableTrining, formTypesSelectedValue, datashow, datashow1, emptyData, DatatableTS, DatatableNTS } = this.state
        console.log()
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>View Report</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="">
                                            {this.state.entityType == 1 ? <><div className="row form-group py-2">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select Counseling Type</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <SingleSelect
                                                        // disabled={this.state.DCDisable}
                                                        options={this.state.counsellingOptions}
                                                        handleChange={(e) => this.optionSelect(e, "counsellingType")}
                                                        selectedService={this.state.counsellingOptSelect}
                                                    />
                                                    <span style={{ color: 'red' }} >{this.state.selectCounsellingError}</span>
                                                </div>
                                            </div>
                                                <div className="row form-group">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select Form Type</label>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <MultiSelect
                                                            disabled={this.state.FTDisable}
                                                            options={this.state.formTypes}
                                                            handleChange={(e) => this.optionSelect(e, "school")}
                                                            selectedService={this.state.formTypesSelected}

                                                        />
                                                        <span style={{ color: 'red' }} >{this.state.SalesErr && this.state.SalesErr}</span>

                                                    </div>
                                                    <div className="col-sm-2" />
                                                </div></> : <></>}
                                            <div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2"><span style={{ color: 'red' }}>*</span>&nbsp;Date</div>
                                                <div className="col-sm-2" >
                                                    From Date
                                                    <input
                                                        type="date"
                                                        id="myDate"
                                                        disabled={this.state.selectedstartdateDisable && this.state.entityType == 1}
                                                        className="form-control"
                                                        onChange={this.fromDateSelect}
                                                        value={this.state.selectedstartdate}

                                                    />
                                                    <span className="error-shows">
                                                        {this.state.startdateerror}
                                                    </span>
                                                </div>
                                                <div className="col-sm-2" >
                                                    To Date
                                                    <input
                                                        type="date"
                                                        disabled={this.state.selectedenddateDisable}
                                                        className="form-control "
                                                        onChange={this.endDateSelect}
                                                        value={this.state.selectedenddate}


                                                    />
                                                    <span className="error-shows">
                                                        {this.state.endateerror}
                                                    </span>
                                                </div>
                                                <div className="col-sm-2">
                                                </div>
                                                <div className="col-sm-2 error-shows"> </div>
                                            </div>
                                            <div className="row form-group">
                                                <div className="col-sm-5" />
                                                {/* <div className="col-sm-2" /> */}
                                                <div className="col-sm-1">
                                                    {this.state.SubmitBtn ?
                                                        <>
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary "
                                                                onClick={this.submitdata}
                                                            >
                                                                Submit
                                                            </button>
                                                        </> :
                                                        <>
                                                        </>}
                                                </div>
                                                &nbsp;&nbsp;
                                                <div className="col-sm-1">
                                                    <button
                                                        type="button"
                                                        className="btn btn-warning "
                                                        onClick={() => {
                                                            this.setState({
                                                                selectedstartdate: "",
                                                                selectedenddate: "",
                                                                formTypesSelected: [],
                                                                DatatableCounselling: [],
                                                                DatatableTrining: [],
                                                                selectedenddateDisable: true,
                                                                selectedstartdateDisable: true,
                                                                submitdisable: false,
                                                                SubmitBtn: false,
                                                                emptyData: false,
                                                                FTDisable: true,
                                                                DatatableTS: [], DatatableNTS: [], counsellingOptSelect: []

                                                            })
                                                        }}>
                                                        Reset
                                                    </button>
                                                </div>
                                                {/* <div className="col-sm-2" >
                                                    
                                                </div> */}
                                            </div>

                                            <br></br>

                                            {this.state.loading && this.state.entityType=="2"? <div className="text-center">
                                                    < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                </div> : (this.state.companyDetails && this.state.companyDetails.length > 0&& this.state.entityType=="2"
                                                                    ?

                                                                    <>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-8"></div>
                                                                            <div className="col-sm-2">
                                                                                {this.state.companyDetails && this.state.companyDetails.length > 0 ?
                                                                                    <CSVLink
                                                                                        data={this.state.companyDetails}
                                                                                        headers={this.excelcolumn4}
                                                                                    >
                                                                                        <button
                                                                                            className="btn btn-success excelBtn"
                                                                                            style={{ marginLeft: "135px" }}
                                                                                        >
                                                                                            EXCEL
                                                                                        </button>
                                                                                    </CSVLink>
                                                                                    : <></>
                                                                                }
                                                                            </div>&nbsp;
                                                                            
                                                                        </div>
                                                                        <div style={{ fontWeight: "600", height: "30px", fontSize: "larger" }}>
                                                                            Company Counseling Form DataTable

                                                                        </div>
                                                                        <Datatable
                                                                            data={this.state.companyDetails}
                                                                            columnHeading={this.column4}
                                                                        />
                                                                        <br /> <br /> <br />

                                                                    </>


                                                                    : this.state.companyDetails && this.state.companyDetails.length < 1 && this.state.entityType.value=="2"?
                                                                        <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show </div>
                                                                        : <></>
                                                                )}
                                            {this.state.loading && this.state.entityType=="1"?
                                                <div className="text-center">
                                                    < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                </div>
                                                : (DatatableCounselling && DatatableCounselling.length == 0 && DatatableTrining && DatatableTrining.length == 0 && DatatableTS && DatatableTS.length == 0 && DatatableNTS && DatatableNTS.length == 0) ?

                                                    <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show </div>
                                                    :
                                                    DatatableCounselling && DatatableCounselling.length !== 0 || DatatableTrining && DatatableTrining.length !== 0 || DatatableTS && DatatableTS.length != 0 || DatatableNTS && DatatableNTS.length != 0 ?
                                                        <>

                                                            <div className="row form-group">
                                                                <div className="col-sm-12">
                                                                    <div className="row form-group">
                                                                        <div className="col-sm-10" />
                                                                        <div className="col-sm-2" >
                                                                            <button className="btn btn-success" onClick={this.exportToExcel}>Export Consolidate Report</button>

                                                                        </div>
                                                                    </div>

                                                                    {/* {formTypesSelectedValue == "1,2" || formTypesSelectedValue == "2,1" || formTypesSelectedValue == "1" ? <> */}
                                                                   
                                                                    {DatatableCounselling && DatatableCounselling.length > 0
                                                                        ?
                                                                        (
                                                                            <>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-10"></div>
                                                                                    <div className="col-sm-2">
                                                                                        {this.state.DatatableCounselling && this.state.DatatableCounselling.length > 0 && (
                                                                                            <CSVLink
                                                                                                data={this.state.DatatableCounselling}
                                                                                                headers={this.excelColumn}
                                                                                            >
                                                                                                <button
                                                                                                    className="btn btn-success excelBtn"
                                                                                                    style={{ marginLeft: "135px" }}
                                                                                                >
                                                                                                    EXCEL
                                                                                                </button>
                                                                                            </CSVLink>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ fontWeight: "600", height: "30px", fontSize: "larger" }}>
                                                                                    Counseling Form DataTable

                                                                                </div>
                                                                                <Datatable
                                                                                    data={DatatableCounselling}
                                                                                    columnHeading={this.column}
                                                                                />
                                                                                <br /> <br /> <br />

                                                                            </>

                                                                        )
                                                                        : DatatableCounselling && DatatableCounselling.length == 0 ?
                                                                            <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show in Counseling</div>
                                                                            : <></>
                                                                    }
                                                                    {/* </> : <></>} */}
                                                                    {/* {formTypesSelectedValue == "1,2" || formTypesSelectedValue == "2,1" || formTypesSelectedValue == "2" ? <> */}
                                                                    {DatatableTrining && DatatableTrining.length > 0
                                                                        ?
                                                                        (
                                                                            <>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-10"></div>
                                                                                    <div className="col-sm-2">
                                                                                        {this.state.DatatableTrining && this.state.DatatableTrining.length > 0 && (
                                                                                            <CSVLink
                                                                                                data={this.state.DatatableTrining}
                                                                                                headers={this.excelColumn1}
                                                                                            >
                                                                                                <button
                                                                                                    className="btn btn-success excelBtn"
                                                                                                    style={{ marginLeft: "135px" }}
                                                                                                >
                                                                                                    EXCEL
                                                                                                </button>
                                                                                            </CSVLink>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ fontWeight: "600", height: "30px", fontSize: "larger" }}>
                                                                                    Group Counseling / Training Form DataTable
                                                                                </div>
                                                                                <Datatable
                                                                                    data={DatatableTrining}
                                                                                    columnHeading={this.column1}
                                                                                />
                                                                                <br /> <br /> <br />

                                                                            </>

                                                                        )
                                                                        : DatatableTrining && DatatableTrining.length == 0
                                                                            ?
                                                                            <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show in Group Counseling / Training</div>
                                                                            : <></>
                                                                    }
                                                                    {/* </> : <></>} */}
                                                                    {/* <br /> <br /> <br /> */}
                                                                    {/* {formTypesSelectedValue == "1,2" || formTypesSelectedValue == "2,1" || formTypesSelectedValue == "2" ?
                                                                    <> */}
                                                                    {DatatableTS && DatatableTS.length > 0
                                                                        ?
                                                                        (
                                                                            <>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-10"></div>
                                                                                    <div className="col-sm-2">
                                                                                        {this.state.DatatableTS && this.state.DatatableTS.length > 0 && (
                                                                                            <CSVLink
                                                                                                data={this.state.DatatableTS}
                                                                                                headers={this.excelColumn2}
                                                                                            >
                                                                                                <button
                                                                                                    className="btn btn-success excelBtn"
                                                                                                    style={{ marginLeft: "135px" }}
                                                                                                >
                                                                                                    EXCEL
                                                                                                </button>
                                                                                            </CSVLink>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ fontWeight: "600", height: "30px", fontSize: "larger" }}>
                                                                                    Teaching Staff Form DataTable
                                                                                </div>
                                                                                <Datatable
                                                                                    data={DatatableTS}
                                                                                    columnHeading={this.column2}
                                                                                />
                                                                                <br /> <br /> <br />

                                                                            </>

                                                                        )
                                                                        // : formTypesSelectedValue == "1,2" || formTypesSelectedValue == "2,1" || datashow1 == true && DatatableTrining && DatatableTrining.length == 0
                                                                        //     ?
                                                                        //     <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show in Group Counseling / Training </div>
                                                                        //     : <></>
                                                                        : DatatableTS && DatatableTS.length == 0
                                                                            ?
                                                                            <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show in Teaching Staff </div>
                                                                            : <></>
                                                                    }
                                                                    {/* </> : <></>} */}
                                                                    {/* <br /> <br /> <br /> */}
                                                                    {/* {formTypesSelectedValue == "1,2" || formTypesSelectedValue == "2,1" || formTypesSelectedValue == "2" ?
                                                                    <> */}
                                                                    {DatatableNTS && DatatableNTS.length > 0
                                                                        ?
                                                                        (
                                                                            <>
                                                                                <div className="row form-group">
                                                                                    <div className="col-sm-10"></div>
                                                                                    <div className="col-sm-2">
                                                                                        {this.state.DatatableNTS && this.state.DatatableNTS.length > 0 && (
                                                                                            <CSVLink
                                                                                                data={this.state.DatatableNTS}
                                                                                                headers={this.excelColumn3}
                                                                                            >
                                                                                                <button
                                                                                                    className="btn btn-success excelBtn"
                                                                                                    style={{ marginLeft: "135px" }}
                                                                                                >
                                                                                                    EXCEL
                                                                                                </button>
                                                                                            </CSVLink>
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                                <div style={{ fontWeight: "600", height: "30px", fontSize: "larger" }}>
                                                                                    Non Teaching Staff Form DataTable
                                                                                </div>
                                                                                <Datatable
                                                                                    data={DatatableNTS}
                                                                                    columnHeading={this.column3}
                                                                                />
                                                                                <br /> <br /> <br />

                                                                            </>

                                                                        )
                                                                        // : formTypesSelectedValue == "1,2" || formTypesSelectedValue == "2,1" || datashow1 == true && DatatableTrining && DatatableTrining.length == 0
                                                                        //     ?
                                                                        //     <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show in Group Counseling / Training </div>
                                                                        //     : <></>
                                                                        : DatatableNTS && DatatableNTS.length == 0
                                                                            ?
                                                                            <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show in Non Teaching Staff </div>
                                                                            : <></>
                                                                    }
                                                                    {/* </> : <></>} */}
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default UserReport;
