import React, { Component } from 'react';
import './footer.css';

export default class index extends Component {
  render() {
    var d = new Date();
    var n = d.getFullYear();
    return (
      <React.Fragment>
        <footer className="footer d-flex justify-content-between">
          <div className="ml-3">
            <span>© {n} All Rights Reserved.</span>
          </div>
          <div className="mr-3">
            <span>Powered by </span>
            <a href="http://www.difuza.com/">difuza</a>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
