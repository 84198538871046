import React, { Component } from 'react';
import Utilities from '../../../MiddleWare/Utilities';
import Vidyadashborad from './Coromandel/Vidhyadash';

class CMSDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerid: localStorage.getItem('userId'),
    };
  }
  componentDidMount() {
    Utilities.serviceList(this.props.match.params.id);
  }

  render() {
    let { customerid } = this.state;
    let content = null
    if (customerid == 807) {
      content = <Vidyadashborad />
    }
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Content Management System</h3>
                  </div>
                  <div className="card-body">
                    {/* <h4>CMS dashboard</h4> */}
                    {this.props.match.params.id == 8 ?
                      content
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default CMSDashboard;
