import React from 'react';
import Datatable from '../../components/Datatable/Datatable';
import { FormMiddleWare } from '../../components/Form';
import LoginModal from '../../components/Modal/Modal';
import User from '../../MiddleWare/User';
import AddUser from '../Modal/AddCustomer';
import SuperAdmin from '../../MiddleWare/SuperAdmin'
import { confirmAlert } from "react-confirm-alert";

export default class GetUsers extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      mobileNumber: '',
      email: '',
      errorUserName: '',
      errorMobileNumber: '',
	  errorloginType:'',
      errorEmail: '',
      alertVisible: false,
      data: [],
      uservalue: [],
      updateData: false,
      imgupload: [],
      logoname: null,
      previoususerid: '',
      arrayvalue: [],
      button: '',
      textview: 'No User',
      textviewusertype: null,
      options: [],
	  options1:[{value:1,label:"SMS_Login"},{value:2,label:"Email_Login"}],
      counting: [],
      thiscurrentcustomerid: "",
	  //selectedloginType:0
    };
  }

  async componentWillMount() {

    this.getusers();
  }

  async componentDidMount() {
    const services = await SuperAdmin.getAllService();
    if (services) {
      this.setState({ options: services.data });
	  //console.log(this.state.options1);

    }

  }
  getusers = async () => {
    const {
      match: { params }
    } = this.props;
    let id = params['id'];
    this.setState({ thiscurrentcustomerid: id })
    let result = await User.getUservalue(id);

    if (result) {
      this.setState({ data: result.data });
    }


    let getname = await User.getUserById(id);

    if (getname) {

      let userType = JSON.parse(localStorage.getItem('userType'));
      let userlog = JSON.parse(localStorage.getItem('userlog'));

      this.setState({ textview: getname.data[0].userName });

    }
  };

 handlelogintype = async(selectedOption) => {
  await this.setState({selectedloginvalue:selectedOption.value});
   //console.log(this.state.selectedloginvalue); 
  };


  handleChanged = selectedOption => {
    let selectedValue = "";
    let arr = [];

    selectedOption.map(values => (selectedValue += `${values.value},`));
    selectedOption.map(values => arr.push(values.label));
    selectedValue = selectedValue.replace(/,\s*$/, "");
    this.addCount(selectedOption, selectedValue, arr);
  };
  addCount = (selectedOption, selectedValue, arr) => {

    var count = 0;
    if (selectedOption && selectedOption.length) {
      count = selectedOption.length;
    } else {
      this.setState({ counting: [] });
    }
    this.setState({
      selectedService: selectedOption,
      servicesValue: selectedValue,
      count,
      arr
    });
  };

  handleCount = async e => {

    let id = e.target.id;
    let value = e.target.value;
    let counting = await this.state.counting;
    counting[id] = value;
    this.setState({ counting });

  };

  onSubmit = async () => {

    let userArray = {};
    let userName = this.state.userName;
    let mobileNumber = this.state.mobileNumber;
    let email = this.state.email;
    let imgupload = this.state.imgupload;
    let servicesValue = this.state.servicesValue;
    let counting = this.state.counting;
	let LoginType=this.state.selectedloginvalue;
	const formData = new FormData();
    formData.append('userType', '3');
    formData.append('customerId', this.state.thiscurrentcustomerid);
    formData.append('image', imgupload);
    formData.append('userName', userName);
    formData.append('mobileNumber', mobileNumber);
    formData.append('email', email);
    formData.append("serviceEnabled", servicesValue);
    formData.append("usercount", counting);
	formData.append("LoginType", LoginType);
    formData.append('status', 'active');



    try {
      //const result = await User.userInsert(userArray);
      const result = await User.adminuserInsert("tbl_user_web", formData);

      if (result) {
        this.setState({
          alertVisible: true,
          userName: "",
          mobileNumber: "",
          email: "",
          servicesValue: "",
          selectedValue: null,
          selectedService: "",
		  selectedloginvalue:null,
          imgupload: "",
          logoname: ""
        });
      }
      this.userList();
      setTimeout(() => this.setState({ alertVisible: false }), 3000);
    } catch (error) {
      console.log(error);
    }



  };
  column = [
    {
      Header: 'UserName',
      accessor: 'userName',
      Cell: d => this.getvalue(d.original, 'Userview')
    },
    {
      Header: 'Email',
      accessor: 'email'
    },
    {
      Header: 'MobileNumber',
      accessor: 'mobileNumber'
    },
    {
      Header: 'Status',
      accessor: 'status'
    },
    {
      Header: 'Visibility',
      accessor: 'edit',
      Cell: d => this.showVisibility(d)
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: d => this.edit(d, 'userAdd')
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: d => this.delete(d)
    }
  ];

  getvalue = (value, modalWindowId) => {
    return (
      <button type="button" className="btn btn-warning" onClick={() => window.open('GetLearner' + '/' + value.id, '_blank')}>
        {value.userName}
      </button>
    );
  };
  buttonDelete = value => {

    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            const id = value.original.id;
            const previousData = [...this.state.data];
            const data = previousData.filter(value => value.id !== id);
            this.setState({
              data
            });
            try {
              await User.deleteSelectedUser(id);
            } catch (error) {
              this.setState({
                data: previousData
              });
              console.log(error);
            }
          }
        },
        {
          label: "No"
        }
      ]
    });
  };


  buttonEdit = async value => {

    let values = value.original;
    const index = value.index;
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    const { userName, mobileNumber, email, image,LoginType, serviceEnabled } = { ...previousData[index] };
    const selectedOption = serviceEnabled 
      .split(",")
      .map(d =>
        this.state.options.filter(
          values => parseInt(values.value) === parseInt(d)
        )
      );
    const serviceList = selectedOption.map(list => list[0]);
    var logoname = '';
    if (image != '' && image != undefined && image != null) {
      var logoname = image.split('SDAS/Video/');
    } else {
      var logoname = '';
    }
   //console.log(LoginType);
      var logintype={};
      this.state.options1.map((ival,i)=>{
		  if(LoginType===ival.value){
			logintype.value=ival.value;  
			logintype.label=ival.label; 
			 //console.log(logintype);
			}
	  })	
	  //console.log(logintype);
    // let img = value.image;
    // let res = img.split('/');

    this.setState({
      userIndex: index,
      userName: userName,
      mobileNumber: mobileNumber,
      email: email,
      errorUserName: '',
      errorMobileNumber: '',
      errorEmail: '',
      alertVisible: false,
      updateEmail: false,
      imgupload: image,
      logoname: logoname,
      updateData: true,
	  selectedloginvalue:logintype.value,
      selectedService: serviceList,
      servicesValue: serviceEnabled,
    });
  };

  onUpdate = async value => {
    let userIndex = this.state.userIndex;
    let userName = this.state.userName;
    let mobileNumber = this.state.mobileNumber;
    let email = this.state.email;
    let imgupload = this.state.imgupload;
    let servicesValue = this.state.servicesValue;
    let counting = this.state.counting;
	let LoginType=this.state.selectedloginvalue;

    let userArray = {};
    userArray.userName = userName;
    userArray.mobileNumber = mobileNumber;
    userArray.email = email;
    userArray.image = imgupload;
    userArray.servicesValue = servicesValue;
    userArray.counting = counting;



    const previousData = [...this.state.data];

    // get only the edit user from the list of user
    const getData = { ...previousData[userIndex] };

    const id = getData.id;
    getData.userName = userName;
    getData.mobileNumber = mobileNumber;
    getData.email = email;
    getData.serviceEnabled = servicesValue;

    const formData = new FormData();
    formData.append('userName', userName);
    formData.append('mobileNumber', mobileNumber);
    formData.append('email', email);
    formData.append('image', imgupload);
    formData.append('serviceEnabled', servicesValue);
	formData.append('LoginType',LoginType);
    formData.append('usercount', counting);

    const data = previousData.filter(value => value.id !== id);
    data.splice(userIndex, 0, getData);
    try {
      const result = await User.updateadminuserInsert(id, formData);
      if (result) {
        this.setState({
          data,
          alertVisible: true,
          userName: '',
          mobileNumber: '',
          email: '',
          userIndex: '',
          errorUserName: '',
          errorMobileNumber: '',
          errorEmail: '',
          updateData: false,
          logoname: '',
          counting: "",
          servicesValue: '',
          selectedService: "",
		  selectedloginType:null,

        });
      }
      setTimeout(() => this.setState({ alertVisible: false }), 3000);
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };

  render() {
    let userType = localStorage.getItem('userType');
    const userData = {
      userName: this.state.userName,
      mobileNumber: this.state.mobileNumber,
      email: this.state.email,
      image: this.state.logoname
    };

    const errorData = {
      errorUserName: this.state.errorUserName,
      errorMobileNumber: this.state.errorMobileNumber,
      errorEmail: this.state.errorEmail,
	  errorloginType:this.state.errorloginType, 
    };


    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12" />
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <center>
                    {' '}
                    <h3>
                      {'List of Users(L3)'}
                      {'  '}
                      {'under '}
                      {'   '}
                      {`${this.state.textview}(L2)`}
                    </h3>{' '}
                  </center>
                </div>
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2"></div>
                    <div className="col-sm-5">
                      {/* <LoginModal
                        atagLink={true}
                        title="User View"
                        id="Userview"
                        onClick={this.buttonView}
                        bodyText={<div>{this.state.uservalue && <Datatable data={this.state.uservalue} columnHeading={this.columns} />}</div>}
                      /> */}
                    </div>
                  </div>

                  <LoginModal
                    buttonTitle="Add Customer"
                    title={this.state.updateData === false ? 'Add User' : 'Update User'}
                    id="userAdd"
                    bodyText={
                      <AddUser
                        handleInput={this.handleUserInput}
                        userData={userData}
                        errorData={errorData}
                        handleSubmit={this.handleSubmit}
                        onDismiss={this.onDismiss}
                        alertVisible={this.state.alertVisible}
                        checkMobileNumber={this.checkMobileNumber}
                        checkEmail={this.checkEmail}
                        uploadimg={this.uploadimg}
                        logoname={this.state.logoname}
                        userType={'2'}
                        updateData={this.state.updateData}
						errorloginType={this.state.errorloginType}
                        options1={this.state.options1}
						selectedloginType={this.state.selectedloginType}
						handlelogintype={this.handlelogintype}
						options={this.state.options}
						handleChange={this.handleChanged}
                        selectedService={this.state.selectedService}
                        selectedValue={this.state.selectedValue}
                        count={this.state.count ? true : false}
                        arr={this.state.arr}
                        handleCount={this.handleCount}
                      />
                    }
                  />
                  <br />
                  {/* <button type="button" class="btn btn-primary " onClick={this.previousbutton}>
                    BACK
                  </button> */}

                  {this.state.data && <Datatable data={this.state.data} columnHeading={this.column} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
