import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from "reactstrap";
import { FormMiddleWare } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";
import BatchPDF from '../../../components/PDF/BatchPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink, CSVDownload } from "react-csv";

class Batch extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      categoryOptions: [],
      categorySelected: [],
      editvalue: null,
      categoryId: null,
      alertVisible: false,
      alertVisible1: false,
      formAlertdelete: false,
      disableValue: false,
      error: null,
      selectboxerror: null,
      customerId: localStorage.getItem("userId"),
      button: "Submit",
      disabled: false,
      textalert: null,
      color: "success"
    };
  }
  async componentDidMount() {
    try {
      //  let category = await CmsContent.getMasteractive('tbl_program');
      let userlog = JSON.parse(localStorage.getItem("userlog"));
      let companyid = userlog.id;
      // let category = await CmsContent.getMasteractive('tbl_program');
      let category = await CmsContent.getConditionedValuewithStatus(
        "tbl_program",
        "customerId",
        companyid,
        "id",
        "name"
      );
      if (category) {

        this.setState({ categoryOptions: category.data });
      }
      // const result = await CmsContent.getSingleConditionedValue(
      //   "tbl_batch",
      //   "customerId",
      //   this.state.customerId
      // );
      const result = await CmsContent.getFreedom("*",
        "tbl_batch",
        `customerId =${this.state.customerId}`, 1, 1)
      let excel = [];
      if (result) {
        result.data.map((item, i) => {
          excel.push({
            index: i + 1,
            program: this.getValueFromArray(item.programid, this.state.categoryOptions),

            Batch: item.name
          })
        })
        // this.setState({ data: result.data });

      }

      if (result.data.length > 0) {
        const header = [
          'SI.NO',
          'PROGRAM NAME',
          'BATCH'
        ];

        const excelHead = [
          { label: "SI.No", key: "index" },
          { label: "Program", key: "program" },
          { label: "Batch", key: "Batch" }
        ];

        this.setState({
          header,
          excelHead,
          excel,
          data: result.data,
          title: "BATCH"
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  column = [
    {
      id: "type",
      Header: "Programs",
      accessor: d =>
        this.getValueFromArray(d.programid, this.state.categoryOptions),
      Cell: d =>
        this.getValueFromArrays(
          d.original.programid,
          this.state.categoryOptions
        )
    },
    {
      Header: "Batch",
      accessor: "name"
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: d => this.edit(d)
    },
    {
      Header: "Delete",
      accessor: "delete",
      Cell: d =>
        this.dataTableButton("danger", "Delete", () => {
          this.buttonDeletes(d.original);
        })
    }
  ];

  edit = d => {
    let value = d.index;
    return (
      <center>
        <button
          type="button"
          className="btn btn-info"
          onClick={() => this.edition(value)}
        >
          Edit
        </button>
      </center>
    );
  };
  edition = value => {

    let data = [...this.state.data];
    let categoryOptions = this.state.categoryOptions;

    let editvalue = data[value].name;

    let editid = data[value].id;
    let categorySelected = {};

    categorySelected.label = categoryOptions[value].label;

    this.setState({
      disabled: true,
      index: value,
      selectboxerror: null,
      categorySelected,
      error: null,
      button: "Update",
      editvalue,
      editid
    });
  };

  buttonDeletes = value => {
    this.setState({});
    confirmAlert({
      title: "Confirmation to Delete",
      message: "Are you sure you want to delete this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.buttonDelete(value)
          //onClick: () => alert("Group removed from")
        },
        {
          label: "No"
        }
      ]
    });
  };

  buttonDelete = async value => {

    const previousData = [...this.state.data];
    let id = value.id;

    try {
      const result = await CmsContent.getSingleConditionedValue(
        "tbl_batch",
        "id",
        id,
        "Delete"
      );
      if (result) {

        let datas = previousData.filter((delelteid, i) => {

          if (delelteid.id !== value.id) {
            return delelteid;
          }
        });
        this.setState({
          data: datas,
          formAlertdelete: true,
          alertVisible: true,
          textalert: "Selected Batch Deleted",
          color: "danger"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  getValueFromArrays = (d, array) => {

    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {

          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {

          return v.label;
        } else {
          return "---";
        }
      }
    } else {
      return "-";
    }
  };
  handlechange = e => {
    let value = e.target.value;

    this.setState({ editvalue: value });
  };
  addnew = async () => {
    let { categorySelected } = this.state;
    //let value = this.state.editvalue;
    //let programid = this.state.categoryId;
    const { editvalue: value, categoryid: programid } = this.state;

    if (programid != undefined && programid != "" && programid != null) {
      this.setState({ selectboxerror: "" });
    } else {
      this.setState({ selectboxerror: "Please fill select box" });
      return false;
    }
    if (value != undefined && value.trim() != "" && value != null) {
      this.setState({ error: "" });
    } else {
      this.setState({ error: "Please Fill The Batch", selectboxerror: "" });
      return true;
    }

    let groupArray = {};
    groupArray.name = value.trim();
    groupArray.programid = programid;
    //groupArray.label = categorySelected.label;
    groupArray.status = "active";
    groupArray.customerId = this.state.customerId;

    try {
      this.setState({ disableValue: true });
      const result = await CmsContent.addMaster("tbl_batch", groupArray);
      if (result) {

        let valueArray = {};
        valueArray.id = result.data.insertId;
        valueArray.name = value.trim();
        valueArray.programid = programid;
        valueArray.label = categorySelected.label;
        const newData = [valueArray, ...this.state.data];

        this.setState({
          data: newData,
          alertVisible: true,
          editvalue: "",
          editid: null,
          categoryId: "",
          categorySelected: "",
          disableValue: false,
          addNewSubgroup: false,
          error: null,
          selectboxerror: null,
          button: "Submit",
          index: null,
          textalert: "New Batch Added",
          color: "success"
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  update = async () => {
    //let value = this.state.editvalue;
    //let programid = this.state.categoryId;
    const { editvalue: value, categoryid: programid } = this.state;
    //this.setState({editvalue ,categoryId });
    if (value === "") {
      this.setState({ error: "Value Cannot Be Empty" });
      return false;
    } else if (programid === null) {
      return false;
    } else {
      this.setState({ disableValue: true });
      try {
        let previousdata = [...this.state.data];
        //let index = this.state.index;
        //let id = this.state.editid;
        const { index, editid: id } = this.state;

        let subgroupArray = {};
        subgroupArray.name = value.trim();
        subgroupArray.programid = programid;
        subgroupArray.status = "active";

        const result = await CmsContent.updateMaster(
          "tbl_batch",
          id,
          subgroupArray
        );
        if (result) {

          previousdata[index].mid = programid;
          previousdata[index].name = value.trim();
          this.setState({
            data: previousdata,
            alertVisible: true,
            error: null,
            disableValue: false,
            categorySelected: null,
            editid: null,
            addNewSubgroup: false,
            index: null,
            button: "Submit",
            disabled: false,
            editvalue: "",
            textalert: "New Batch Updated",
            color: "success"
          });
          setTimeout(() => this.setState({ alertVisible: false }), 3000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  onDismiss = () => {
    this.setState({ alertVisible: false });
    this.setState({ alertVisible1: false });
    this.setState({ formAlertdelete: false });
  };
  programSelect = e => {
    this.setState({ categorySelected: e, categoryid: e.value });
  };

  render() {
    const {
      alertVisible,
      alertVisible1,
      formAlertdelete,
      editvalue,
      disableValue,
      error,
      selectboxerror,
      button,
      categorySelected,
      textalert
    } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Batch</h1>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color={this.state.color}
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          {/* New Batch Added */}
                          {textalert}
                        </Alert>
                        {/* <Alert
                          className="badge-content"
                          color="success"
                          isOpen={alertVisible1}
                          toggle={this.onDismiss}
                        >
                          New Batch Edited
                        </Alert>
                        <Alert
                          className="badge-content"
                          color="danger"
                          isOpen={formAlertdelete}
                          toggle={this.onDismiss}
                        >
                          Selected Batch Deleted
                        </Alert> */}
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Select Program</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          disabled={this.state.disabled}
                          options={this.state.categoryOptions}
                          handleChange={this.programSelect}
                          selectedService={categorySelected}
                        />
                        <span className="error-shows">{selectboxerror}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="subgroup">Batch</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          name="subgroup"
                          placeholder="Enter Batch"
                          className="form-control"
                          value={editvalue}
                          onChange={e => this.handlechange(e)}
                        />
                        <span className="error-shows">{error}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className="btn btn-primary"
                          disabled={disableValue}
                          onClick={
                            button === "Update" ? this.update : this.addnew
                          }
                        >
                          {this.state.button}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                      <PDFDownloadLink
                        style={{ color: 'white' }}
                        document={<BatchPDF title={this.state.title} header={this.state.header} data={this.state.data} data1={this.state.categoryOptions} type="batch" />}
                        fileName="sample.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? 'Loading document...' : 'Download Pdf'
                        }
                        <button className="btn btn-warning" style={{ marginLeft: '20px' }}>
                          PDF
                    </button>
                      </PDFDownloadLink>

                      {this.state.excel && this.state.excel.length > 0 && (
                        <CSVLink data={this.state.excel} headers={this.state.excelHead}>
                          <button className="btn btn-success" style={{ marginLeft: '20px' }}>
                            Excel
                    </button>
                        </CSVLink>
                      )}
                    </div>


                    <span>
                      Total of {Object.keys(this.state.data).length} records
                    </span>
                    {this.state.data && (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Batch;
