import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import SingleSelect from "../../../components/Form/SingleSelect";
import { Alert } from "reactstrap";
import { Datatable } from "../../../components/Datatable";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Loginpdf from "./Loginpdf";
import { CSVLink, CSVDownload } from "react-csv";

class CmsLoginLog extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      customerId: localStorage.getItem("userId"),
      pageOptions: [],
      videoptions: [],
      tabledata: [],
      users: [],
      Accessusercount: [],
      alluserviewpage: [],
      selectedstartdate: "",
      selectedenddate: "",
      disablepage: false,
      disablevideos: false,
    };
  }

  async componentWillMount() {
    const {
      match: { params },
    } = this.props;
    console.log(params);
    let userid = params["userid"];

    const pageview = await CmsContent.getFreedom(
      "tbl_CMSuserAccessLog.*",
      "tbl_CMSuserAccessLog",
      `tbl_CMSuserAccessLog.pageid = 0 and tbl_CMSuserAccessLog.userid=${userid} and  tbl_CMSuserAccessLog.customerId=${this.state.customerId} `,
      "id",
      "id DESC"
    );

    if (pageview) {
      this.setState({ tabledata: pageview.data });
    }

    const users = await CmsContent.getFreedom(
      "id as value,userName as label,email as email,mobileNumber as number",
      "tbl_user_web",
      `customerId=${this.state.customerId} and id=${userid}`,
      "id",
      "id DESC"
    );
    if (users) {
      this.setState({ users: users.data });
    }

    let exc = [];
    let name = [];
    this.state.tabledata.map((pval, n) => {
      this.state.users.map((uval, b) => {
        if (
          (pval.userId == uval.value && pval.max_timeline == null) ||
          pval.startTime == null
        ) {
          console.log(uval);
          exc.push({
            SI_NO: n + 1,
            userName: uval.label,
            datetime: pval.datetime,
            ipAddress: pval.ipaddress,
            maxtime: "N/A",
            entrytime: "N/A",
          });
        } else {
          exc.push({
            SI_NO: n + 1,
            userName: uval.label,
            datetime: pval.datetime,
            ipAddress: pval.ipaddress,
            maxtime: pval.max_timeline,
            entrytime: pval.startTime,
          });
        }
      });
    });

    this.setState({ Csv: exc });
    if (this.state.tabledata.length > 0) {
      const header1 = [
        "SI.NO",
        "Username",
        "IpAddress",
        "MAX Time",
        "Entry Time",
        "Date Time",
      ];
      const excelHead = [

        { label: "Username", key: "userName" },
        { label: "IPAddress", key: "ipAddress" },
        { label: "MAX Time", key: "maxtime" },
        { label: "Entry Time", key: "entrytime" },
        { label: "DateTime", key: "datetime" },
      ];
      this.setState({ excelHead, header1, excel: this.state.tabledata });
    }
  }

  column = [
    {
      id: "userId",
      Header: "Username",
      accessor: (d) => this.getValueFromArray(d.userId, this.state.users),
      Cell: (d) => this.getValueFromArray(d.original.userId, this.state.users),
    },
    {
      Header: "IP Address",
      accessor: "ipaddress",
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (d) => this.checkmail(d.original.userId),
    },
    {
      Header: "MobileNumber",
      Cell: (d) => this.checknum(d.original.userId),
    },
    {
      Header: "Max Time",
      accessor: "max_timeline",
      Cell: (d) => this.checkEmpty(d),
    },
    {
      id: "startTime",
      Header: "Entry Time",
      accessor: (d) => this.dateformatchange(d.startTime),
      Cell: (d) => this.dateformatchange(d.original.startTime),
    },
    {
      id: "datetime",
      Header: "Date Time",
      accessor: (d) => this.dateformatchange(d.datetime),
      Cell: (d) => this.dateformatchange(d.original.datetime),
    },
  ];
  checkEmpty = (d) => {
    console.log(d);
    return d.original.max_timeline ? d.original.max_timeline : "N/A";
  };
  checkmail = (d) => {
    let email = [];
    this.state.users.map((ival, b) => {
      if (ival.value == d) {
        email.push(ival.email);
      }
    });
    return email;
  };
  checknum = (d) => {
    let Num = [];
    this.state.users.map((jval, c) => {
      if (d == jval.value) {
        Num.push(jval.number);
      }
    });
    return Num;
  };
  dateformatchange = (datevalue) => {
    if (datevalue) {
      let dateview = datevalue.split("-");
      let hourssplit = dateview[2].split(":")[0].split("");
      let Mins = dateview[2].split(":");
      return (
        <span>
          {" "}
          {hourssplit[0] +
            hourssplit[1] +
            "-" +
            dateview[1] +
            "-" +
            dateview[0] +
            " " +
            hourssplit[3] +
            hourssplit[4] +
            ":" +
            Mins[1] +
            ":" +
            Mins[2]}
        </span>
      );
    } else {
      return "N/A";
    }
  };

  render() {
    const { formAlert, formAlertdelete, categoryOptions } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-sm-4"> </div>
                      <div className="col-sm-4">
                        <h3>Total access log</h3>
                      </div>
                      <div className="col-sm-4"> </div>
                    </div>
                    <br />

                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorPageId}
                      </span>
                    </div>
                    {this.state.excel && this.state.excel.length > 0 && (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <Loginpdf
                            header={this.state.header1}
                            data={this.state.Csv}

                          />
                        }
                        fileName="sample.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    )}
                    {this.state.excel && this.state.excel.length > 0 && (
                      <CSVLink
                        data={this.state.Csv}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          EXCEL
                        </button>
                      </CSVLink>
                    )}
                    {this.state.tabledata && (
                      <Datatable
                        data={this.state.tabledata}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default CmsLoginLog;
