import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './SideNav.css';

export default class SideNavSecondAdmin extends Component {
  async componentWillUnmount() {
    window.top.location.href = '';
  }
  render() {
    return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <ul className="nav">
            <li className="nav-item">
              <Link className="nav-link" to="/subAdmin">
                <i className="nav-icon icon-speedometer" />
                <span className="link-white">Dashboard</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/AddSurvey">
                <i className="nav-icon fa fa-user" />
                <span className="link-white">Add Survey</span>
              </Link>
            </li>
            <li className="nav-item nav-dropdown">
              <Link className="nav-link nav-dropdown-toggle" to="#">
                <i className="nav-icon icon-puzzle" /> Master
              </Link>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/customer">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Add Company</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/charmaster">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Characteristics Master</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/quesmaster">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Questions Master</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/ansmaster">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Answer Master</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/rangemaster">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Range Master</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item nav-dropdown">
              <Link className="nav-link nav-dropdown-toggle" to="#">
                <i className="nav-icon icon-puzzle" /> Mapping
              </Link>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/MapChartoRange">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Map Characteristics To Range</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/MapComtosurvey">
                    <i className="nav-icon fa fa-user" />
                    <span className="link-white">Map Survey To Company</span>
                  </Link>
                </li>
                
              </ul>
            </li>

          </ul>
        </nav>
      </div>
    );
  }
}
