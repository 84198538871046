
import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import cmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import Addauthormodel from "./Addauthormodel";
import { Alert } from "reactstrap";
import { ACCESS_SAMPLE } from "../../../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import AddpagePDF from "../../../components/PDF/Addpagespdf";

//import Theme9 from './addPages/theme9';
import Datatable from "../../../components/Datatable/Datatable";
import { FormMiddleWare } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";

class AddSales extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            authorlist: [],
            checked: 0,
            customerId: localStorage.getItem("userId"),
            errorPageName: null,
            data: [],
            MOlist: [],
            alertVisible1: false,
            btnDisable: false,
            formAlert: false,
            formBtn: false,
            themeSelected: "",
            screen: window.screen.height,
            danger: "btn btn-danger",
            success: "btn btn-success",
            home: "Home",
            set: "SetusHome",
        };
    }
    column = [
        {
            Header: "SALES DISTRICT NAME",
            accessor: "sales_name",
        },
        {
            Header: "SALES DISTRICT CODE",
            accessor: "sales_code",
        },
        // {
        //     Header: "MO",
        //     accessor: "edit",
        //     Cell: (d) =>  this.getValueFromArray(d.original.moid,this.state.MOlist),
        // },
        {
            Header: "EDIT",
            accessor: "edit",
            Cell: (d) => this.Editpages(d),
        },
        {
            Header: "DELETE",
            Cell: (d) => this.buttondel(d),
        },


    ];







    showVisibility = (id) => {
        return (
            <button
                type="button"
                className="btn btn-info"
                onClick={() => this.updateVisibility(id)}
            >
                Hide/Show
            </button>
        );
    };

    changeLoginStatus = (id) => {
        return (
            <button
                type="button"
                class="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '10px' }}
                onClick={() => this.updateLogin(id)}
            >
                Change Login Status
            </button>
        );
    };
    buttondel = (d) => {
        if (d.original.status == 0) {
            return (
                <button

                    className="btn btn-danger"
                    onClick={() => this.Delete(d)}
                >Delete</button>
            );
        } else {
            return (<span className="label">Deleted</span>)

        }
    }

    Delete = d => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return <div style={{ textAlign: "center" }}>
                    <h1>Delete confirmation</h1>
                    <h4>Are you sure you want to Delete this ?</h4><br />
                    <button className="btn btn-primary" onClick={() => {
                        this.HandleDelete(d.original)
                        onClose();
                    }}  >Yes</button> &nbsp;  &nbsp;
                    <button className="btn btn-danger" onClick={onClose} >No</button>
                </div>
            }
        })
    };
    HandleDelete = async (d) => {
        try {
            let obj = {}
            obj.status = Date.now()
            const result = await cmsContent.updateMaster(
                "cmndl_tbl_Sales_area",
                d.id,
                obj
            )
            if (result) {
                this.MainFun();
            }
        } catch (error) {
            console.log(error);
        }
    }



    Editpages = (value) => {
        if (value.original.status == 0) {
            return (
                <button className="btn btn-info" onClick={() => this.onEdit(value)}>
                    Edit
                </button>
            );
        } else {
            return (<span className="label">Deleted</span>)
        }
    };
    getValueFromArray1 = (d, array, ch = "value", ch1 = "label") => {
        if (array.length > 0) {
            if (array.length !== 0) {
                let filtered = array.filter(function (item) {
                    return item[ch] == d;
                });
                let v = filtered[0];
                if (v != undefined) {
                    return v;
                } else {
                    return '-';
                }
            }
        } else {
            return '-';
        }
    };
    onEdit = (value) => {
        let d = value.original;
        console.log(d);
        // if(d.moid){
        //     let MO = this.getValueFromArray1(d.moid,this.state.MOlist)
        //     console.log(MO);
        //     this.setState({MO})
        // }

        this.setState({
            Name: d.sales_name,
            Code: d.sales_code,
            editId: d.id,
            isEdit: true
        });
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    async componentDidMount() {
        this.MainFun()
    }
    MainFun = async () => {
        try {
            this.setState({ loading: true })
            // const MO = await cmsContent.getFreedom(
            //     "CONCAT(`moid`, ' ', `name`) as label,id as value",
            //     "cmndl_tbl_MO",
            //     1,
            //     "id",
            //     "id DESC"
            // );
            // if (MO) {
            //     this.setState({ MOlist: MO.data });
            // }
            const sales = await cmsContent.getFreedom(
                "*",
                "cmndl_tbl_Sales_area",
                1,
                "id",
                "id DESC"
            );
            if (sales) {
                this.setState({ data: sales.data });
            }
            this.setState({ loading: false })
        } catch (error) {
            console.log(error);
            this.setState({ loading: false })
        }
    }

    handlechange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };



    submitCategory = async () => {
        const {
            Code,
            Name, errorName, errorCode, errorMO, MO, isEdit, editId
        } = this.state;
        // if (!MO) {
        //     this.setState({
        //         errorMO: "select name",             
        //     });
        //     return false;
        // }
        if (!Code) {
            this.setState({ errorMO: "", errorCode: "enter  code" });
            return false;
        } else if (!Name) {
            this.setState({
                errorName: "enter name",
                errorCode: "",
            });
            return false;
        }

        let categoryArray = {}
        // categoryArray.moid = MO.value;
        categoryArray.sales_code = Code;
        categoryArray.sales_name = Name;

        // console.log([...formData]);
        console.log('loast', categoryArray);
        try {
            const result = isEdit ? await cmsContent.updateMaster(
                "cmndl_tbl_Sales_area",
                editId,
                categoryArray
            ) : await cmsContent.addMaster(
                "cmndl_tbl_Sales_area",
                categoryArray
            );
            if (result) {
                this.setState({
                    Code: "",
                    Name: '', errorName: '', errorCode: '',
                    MO: '', errorMO: '',
                    Sales: '', errorSales: '',
                    btnDisable: false,
                    alertVisible: true,

                });
                this.MainFun()
                setTimeout(() => this.setState({ alertVisible: false }), 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };

    handleSelect = (name, selectedOption) => {
        this.setState({
            [name]: selectedOption,
            //   themeId: selectedOption.value,
            //   themeName: selectedOption.label,
        });
    };
    render() {
        console.log(this.state.data);
        const { MOlist //marketing off
        } = this.state;
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Add Page</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color="success"
                                                    isOpen={this.state.alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    Added Successfully
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>

                                        {/* <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Marketing officer</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    options={MOlist}
                                                    handleChange={(e) => this.handleSelect('MO', e)}
                                                    selectedService={this.state.MO}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.errorMO}
                                            </span>
                                        </div> */}

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Sales District Code</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="pageName"
                                                    name="Code"
                                                    placeholder="Enter code"
                                                    value={this.state.Code}
                                                    onChange={this.handlechange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.errorCode}
                                            </span>
                                        </div>
                                        <br />
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Sales District Name</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="Name"
                                                    name="Name"
                                                    placeholder="Enter name"
                                                    value={this.state.Name}
                                                    onChange={this.handlechange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.errorName}
                                            </span>
                                        </div>
                                        <br />
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2" />
                                            <div className="col-sm-5">
                                                <button
                                                    type="button"
                                                    className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
                                                    disabled={this.state.btnDisable}
                                                    onClick={this.submitCategory}
                                                >
                                                    {this.state.isEdit ? 'Update' : 'Submit'}
                                                </button>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <br />

                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                {this.state.loading ? <div className="text-center"> <i
                                                    className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                                ></i></div> : this.state.data && this.state.data.length ? (
                                                    <Datatable
                                                        data={this.state.data}
                                                        columnHeading={this.column}
                                                    />
                                                ) : <div className="text-center">No Data Found</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default AddSales;
