import http from "./httpMiddleWare";
import { ACCESS_POINT, EXPRESS_REDIS_ACCESS_POINT } from "../config";
//import { async } from 'q';
let device = JSON.parse(localStorage.getItem("info"));
let userId = JSON.parse(localStorage.getItem("userId"));
// if(userId){
//   device.id=userId;
// }

const themeInsert = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const updateTheme = async (tableName, formData) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const addMaster = async (tableName, categoryArray) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/master/${tableName}`,
    categoryArray
  );
  return result;
};


const fileuploadgift = async (tableName, formData, c) => {
  // const result = await http.post(
  //   ACCESS_POINT + `/cmsContent/fileuploadgift/${tableName}/${c}`,
  //   formData,
  //   {
  //     headers: {
  //       "Content-Type": "multipart/form-data"
  //     }
  //   }
  // );
  // return result;
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/fileuploadgift/${tableName}/${c}`,
    formData
  );
  return result;
};

// const addContentMaster = async (tableName, categoryArray) => {
//   const result = await http.post(ACCESS_POINT + `/cmsContent/addContentMaster/${tableName}`, categoryArray);
//   return result;
// };

const updateMaster = async (tableName, id, categoryArray, column = "id") => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/master/${tableName}/${column}`,
    { id: id, categoryArray }
  );
  return result;
};

const deleteMaster = async (tableName, id) => {
  const result = await http.delete(
    ACCESS_POINT + `/cmsContent/master/${tableName}/${id}`
  );
  return result;
};

const getMasterValues = async (tableName, columName = "id", order = "desc") => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/master/${tableName}/${columName}/${order}`
  );
  return result;
};

const getMasteractive = async tableName => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/masteractive/${tableName}`
  );
  return result;
};

const getThemes = async () => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/themes`);
  return result;
};

const getThemeById = async themeid => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/${themeid}`);
  return result;
};

const getVideoContentById = async themeid => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/videoContent/${themeid}`
  );
  return result;
};

const getAllMappedpages = async () => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/dynamic/page`);
  return result;
};

const getAllPages = async () => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/dynamic/pages`);
  return result;
};

const updatePage = async data => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/dynamic/pages`,
    data
  );
  return result;
};
const updatecatsubcat = async (t, data) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/CatSubcat/${t}`,
    data
  );
  return result;
};

const updateLoginStatus = async data => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/updateLoginStatus/pages`,
    data
  );
  return result;
};

const getAllVideos = async (type, companyid) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/getContent/getAllVideos/${type}/${companyid}`
  );
  return result;
};

const checkVideoCount = async (videoId, videoName) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/dynamic/viewCount/${videoId}/${videoName}`
  );
  return result;
};

const updateCount = async (id, count) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/dynamic/viewCount/${id}`,
    count
  );
  return result;
};

const rightPanelAdd = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/rightPanel/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const rightPanelUpdate = async (tableName, formData) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/rightPanel/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const getRightPanelList = async tableName => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/rightPanel/${tableName}`
  );
  return result;
};

const getAllRightPanelList = async tableName => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/rightPanel/dynamic/list`
  );
  return result;
};

const getAllSerchKeyValue = async values => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/dynamic/search/${values}`
  );
  return result;
};

const subTitleAdd = async formData => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/subTitleMaster/subTitleAdd`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const getSubTitleValues = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/subTitleMaster/subTitleAdd`
  );
  return result;
};

const getAllSubtitleList = async tablename => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/subTitleMaster/getAllList/${tablename}`
  );
  return result;
};

const getAllSubtitleLists = async (tablename, columnName, order) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/subTitleMaster/getAllLists/${tablename}/${columnName}/${order}`
  );
  return result;
};

const getVideoListForAdmin = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/getVideoListForAdmin/videoList`
  );
  return result;
};

const addQuizContent = async jsonContent => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/quizContent/addQuiz`,
    jsonContent
  );
  return result;
};

const getQuizQuestion = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/quizContent/selectBox`
  );
  return result;
};

//ram
const insertImage = async (formData, tableName) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/library/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const SteerImage = async (formData, id) => {
  const result = await http.post(
    ACCESS_POINT + `/dfcarz/library/${id}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const getLibraryImage = async type => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/library/${type}`);
  return result;
};

const updateQuizAnswer = async (quizAnswerJson, tableName) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/quizAnswer/${tableName}`,
    quizAnswerJson
  );
  return result;
};

const checkQuizAttended = async (userId, quizId) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/quizAnswer/${userId}/${quizId}`
  );
  return result;
};

const getScoreOfUser = async userId => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/userScore/${userId}`
  );
  return result;
};

const updateCategory = async data => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/dynamic/category`,
    data
  );
  return result;
};

const updateCategorys = async datas => {
  const result = await http.put(ACCESS_POINT + `/cmsContent/master`, datas);
  return result;
};

const videoSeekLog = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/videoSeekLog/seekLog`
  );
  return result;
};

const getquizlog = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/quizlogcontent/quizlog`
  );
  return result;
};

const displayQuizQuestion = async (value, customerid) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/quizContent/selectBox`,
    { value, customerid }
  );
  return result;
};

const getAllHomePanelList = async tableName => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/homePanel/${tableName}`
  );
  return result;
};

const authorinsert = async (formData, tableName) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/addauthor/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const giftinsert = async (formData, tableName) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/addgift/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const authorinserts = async (formData, tableName) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/addauthors/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const updateMasters = async (formdata, tableName) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/updateMasters/${tableName}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const getauthorvalues = async tableName => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/addauthor/${tableName}`
  );
  return result;
};

const getAllCategoryMappedPages = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/getallmappedpages/toCategory`
  );
  return result;
};
const updateauthor = async (tableName, categoryArray) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/addauthor/${tableName}`,
    categoryArray,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const Updategift = async (tableName, categoryArray) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/addgift/${tableName}`,
    categoryArray,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const getCompanyUsers = async (companyid, usertype, serviceid = "false") => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/getusers/${companyid}/${usertype}/${serviceid}`
  );
  return result;
};

const UpdateOrder = async (id, listingorder, catid) => {
  //console.log(catid);
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/updateorder/${id}/${catid}`,
    { listingorder }
  );
  return result;
};

const videoList = async customerid => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/selectbox/video/${customerid}`
  );
  return result;
};

const videoValue = async data => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/videoValue/json`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const videoAnalyticsList = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/videoAnalyticsList/json`
  );
  return result;
};

const videoJsonList = async id => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/videoJsonList/json/${id}`
  );
  return result;
};

const getPagesData = async id => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/getpages/getpagesdata/${id}`
  );
  return result;
};

const videoUrl = async id => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/videoUrl/json/${id}`
  );
  return result;
};

const videoObjectTracking = async data => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/videoObjectTracking/json`,
    data
  );
  return result;
};

const getLikesLogs = async () => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/dynamic/likelog`);
  return result;
};

const getConditionedValuewithStatus = async (
  tablename,
  columname,
  value,
  id = "id",
  name = "name"
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getConditionedValuewithStatus/${tablename}/${columname}/${value}/${id}/${name}`
  );
  return result;
};

const getTwoConditionedValue = async (
  tableName,
  columName,
  value,
  columName1,
  value1,
  val = "*"
) => {
  const result = await http.put(
    ACCESS_POINT + "/cmsContent/getTwoConditionedValue/alternatestatus",
    { tableName, columName, value, columName1, value1, val }
  );
  return result;
};

const getTwoConditionedValueFree = async (
  tableName,
  columName,
  value,
  columName1,
  value1,
  val = "*"
) => {
  const result = await http.put(
    ACCESS_POINT + "/cmsContent/getTwoConditionedValueFree/alternatestatus",
    { tableName, columName, value, columName1, value1, val }
  );
  return result;
};

const getThreeConditionedValue = async (
  tableName,
  columName,
  value,
  columName1,
  value1,
  columName2,
  value2,
  val = "*"
) => {
  const result = await http.put(
    ACCESS_POINT + "/cmsContent/getThreeConditionedValue/alternatestatus",
    { tableName, columName, value, columName1, value1, columName2, value2, val }
  );
  return result;
};
const getThreeConditionedValueFree = async (
  tableName,
  columName,
  value,
  columName1,
  value1,
  columName2,
  value2,
  val = "*"
) => {
  const result = await http.put(
    ACCESS_POINT + "/cmsContent/getThreeConditionedValueFree/alternatestatus",
    { tableName, columName, value, columName1, value1, columName2, value2, val }
  );
  return result;
};

const getInnerJoinList = async value => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/getInnerJoinList/getInnerJoin`,
    value
  );
  return result;
};
const getInnerJoinListwithStatus = async (
  tablename1,
  columname1,
  tablename2,
  columname2
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getInnerJoinListwithStatus/${tablename1}/${columname1}/${tablename2}/${columname2}`
  );
  return result;
};

const learningObjectSubmit = async (tableName, formdata) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/learningObjectSubmit/${tableName}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const getUserJson = async id => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/getUserJson/${id}`);
  return result;
};
const mapUserToResource = async (tableName, formdata) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/mapUserToResource/${tableName}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const getSingleConditionedValue = async (
  tableName,
  columname,
  value,
  dothis = "SELECT *"
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getSingleConditionedValue/${dothis}/${tableName}/${columname}/${value}`
  );
  return result;
};

const getgroupbyvalue = async (tableName, columname, type, value) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getgroupbyvalue/${tableName}/${columname}/${type}/${value}`
  );
  return result;
};
const getSingleConditioned = async (
  tableName,
  columName1,
  value,
  dothis = "SELECT *"
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getSingleConditioned/${dothis}/${tableName}/${columName1}/${value}/`
  );
  return result;
};

const mappingportlet = async (formData, tableName) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/mappingportlet/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};



const AddProductMaster = async formData => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/SFA/AddProductMaster`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const AddedProductMaster = async formData => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/R7/AddedProductMaster`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const UpdateProductMaster = async formData => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/SFA/UpdateProductMaster`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const UpdatedProductMaster = async formData => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/R7/UpdateProductMaster`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const getLearner = async (
  tablename,
  columname,
  value,
  id = "id",
  name = "name",
  companyid
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getLearner/${tablename}/${columname}/${value}/${id}/${name}/${companyid}`
  );
  return result;
};

const getColoumtablevalue = async (
  tablename,
  columname,
  customerid,
  type,
  secondcolumn
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/gettwocolumnvalue/${tablename}/${columname}/${customerid}/${type}/${secondcolumn}`
  );
  return result;
};

const getgroupbycohorts = async (tablename, columname, value, columname1) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getgroupbycohorts/${tablename}/${columname}/${value}/${columname1}`
  );
  return result;
};

const getValuebyColumn = async (
  tablename,
  columname,
  value,
  columname1,
  value1
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getValuebyColumn/${tablename}/${columname}/${value}/${columname1}/${value1}`
  );

  return result;
};

const updateBox = async (tableName, formdata) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/updateBox/${tableName}`,
    formdata
  );

  return result;
};

const valueCustomerId = async (boxId, customerid) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/valuecompanyid/${boxId}/${customerid}`
  );

  return result;
};
const getSelectvalue = async (
  tableName,
  column,
  companyid,
  id = "id",
  name = "name"
) => {
  const result = await http.get(
    ACCESS_POINT +
    `/cmsContent/getSelectvalue/${tableName}/${column}/${companyid}/${id}/${name}`
  );

  return result;
};

const getLeanersFromCohorts = async value => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/getLeanersFromCohorts/getLearners`,
    { id: value }
  );

  return result;
};
const getFreedom = async (
  select,
  tableName,
  condition,
  groupby = "id",
  orderby = "id"
) => {
  let value = {};
  value.select = select;
  value.tableName = tableName;
  value.condition = condition;
  value.groupby = groupby;
  value.orderby = orderby;
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/getFullFreedom/getFreedom`,
    value
  );
  //console.log(result)
  if (result.data) {
    return result;
  } else {
    localStorage.clear();
    window.location = "/";
  }
};
const deleteFreedom = async (
  tableName,
  condition,
) => {
  let value = {};
  value.tableName = tableName;
  value.condition = condition;
  console.log({
    value, tableName,
    condition,
  });
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/delete/deleteFreedom/id`,
    value
  );
  //console.log(result)
  if (result.data) {
    return result;
  } else {
    localStorage.clear();
    window.location = "/";
  }
};

const chan = async (tn1, b1, tn2, b2) => {
  let value = {};
  value.tableName1 = tn1;
  value.body1 = b1;
  value.tableName2 = tn2;
  value.body2 = b2;
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/putArrayLoop/putSolo`,
    value
  );
  return result;
};
const chanAnswer = async (tn1, b1) => {
  let value = {};
  value.tableName = tn1;
  value.body = b1;
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/putArrayLoop/putSolo`,
    value
  );
  return result;
};


// const addpageupdate = async (tablename, data) => {
//   let value = {};
//   value.tablename = tablename;
//   value.data = data;

//   const result = await http.post(ACCESS_POINT + `/cmsContent/addpageupdate/update`, data);
//   return result;
// };

const addpageupdate = async (tableName, formdata, index, pageid, currentid) => {
  const result = await http.post(
    ACCESS_POINT +
    `/cmsContent/addpageupdate/update/${tableName}?index=${index}&pageid=${pageid}&currentid=${currentid}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const addpagemodelwindow = async (tableName, formdata, pageid) => {
  const result = await http.post(
    ACCESS_POINT +
    `/cmsContent/addpagemodelwindow/update/${tableName}?pageid=${pageid}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};
const addSurveyContent = async jsonContent => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/insertSurvey/addSurvey`,
    jsonContent
  );
  return result;
};
const addSurveyQuestion = async jsonContent => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/insertQuestionSurvey/addSurveyQuestion`,
    jsonContent
  );
  return result;
};
const addSurveyAnswer = async (tn1, b1) => {
  let value = {};
  value.tableName = tn1;
  value.body = b1;
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/insertResultSurvey/addSurveyAnswer`,
    value
  );
  return result;
};

const getPerformanceForCohort = async d => {
  const result = await http.post(
    ACCESS_POINT + "/cmsContent/getPerformanceForCohort/ForCohort",
    d,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return result;
};
const getoverallTopperdetails = async d => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/getoverallTopperdetails/${d}`
  );
  return result;
};
const sectionContents = async (tableName, data) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/sectionContents/${tableName}`,
    data,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return result;
};
// const PortletAdded = async data => {
//   const result = await http.get(
//     ACCESS_POINT + `/cmsContent/portlets/Added`,
//     data,
//     { headers: { "Content-Type": "multipart/form-data" } }
//   );
//   return result;
// };
const PortletAdded = async (categoryArray, type) => {
  var id = categoryArray.userId;
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/portlets/${type}/${id}`,
    categoryArray
  );
  return result;
};
const portletdelete = async id => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/portletsdelete/${id}`
  );
  return result;
};
const getIP = async () => {
  const result = await http.post(ACCESS_POINT + `/cmsContent/getIP`);
  return result;
};
const pageViewCount = async d => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/getContent/pageViewCount`,
    d,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return result;
};

const createcaptcha = async () => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/createcaptcha/captcha`
  );
  return result;
};

const contactform = async d => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/contactFormmail/enquiry`,
    d,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return result;
};

const deleteUser = async d => {
  const result = await http.post(ACCESS_POINT + `/cmsContent/deleteUser/${d}`);
  return result;
};

const deleteRightPanel = async (tableName, id) => {
  const result = await http.delete(
    ACCESS_POINT + `/cmsContent/rightPanel/${tableName}/${id}`
  );
  return result;
};
const updateHierarchy = async (tableName, data) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/updateHierarchy/${tableName}`,
    data
  );
  return result;
};
const inserthome = async (tableName, data) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/inserthome/${tableName}`,
    data,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
  return result;
};
const learnerfile = async (usertype, formdata) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/learnercsv/${usertype}`,
    formdata,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const SingleFileUpload = async (tableName, formData) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/SingleFileUpload/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const compareValues = async (key, type, order = "asc") => {
  // console.log(1);
  return function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = type === "int" ? parseInt(a[key]) : a[key].toUpperCase();
    const varB = type === "int" ? parseInt(b[key]) : b[key].toUpperCase();

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    //console.log(comparison);
    return order === "desc" ? comparison * -1 : comparison;
  };
}
const GoogleLogin = async (email) => {
  let categoryArray = {}
  categoryArray.email = email
  const result = await http.post(
    ACCESS_POINT + "/cmsContent/App/cars/google/email",
    categoryArray
  );

  return result;
};
const WithPassowrdlogin = async (email, pasword) => {
  let categoryArray = {}
  categoryArray.email = email
  categoryArray.password = pasword
  console.log(categoryArray);
  const result = await http.post(
    ACCESS_POINT + "/cmsContent/App/web/WebLoginWithoutPasword",
    categoryArray
  );

  return result;
};


const CommonAddMaster = async (tableName, categoryArray) => {
  console.log(tableName);
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/app/common/addMaster/` + `${tableName}`,
    categoryArray
  );
  return result;
};



const MultiFileUpload = async (tableName, data, id = "id") => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/web/MultiFileUpload/${tableName}/${id}`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const inventFileupload = async (tableName, data, id = "id") => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/web/inventFileupload/${tableName}/${id}`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
};

const BUYDFCARZ = async (id, api, data) => {
  const result = await http.post(ACCESS_POINT + `/dfcarz/app/geteval/${id}/${api}`,
    data,
    {
      headers: {
        "Content-Type": "application/json"
      }
    });
  return result.data;

}
const coromandelHandlelogin = async (value) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/coromandel/coromandelHandlelogin`,
    {
      params: {
        code: value
      }
    }
  )
  return result;
}
const siteintegratedstatus = async (name, data, id = 'id') => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/app/siteintegratedstatus/${name}/${id}`,
    data
    // {
    //   headers: {
    //     "Content-Type": "application/json"
    //   }
    // }
  );
  return result;
};

const MultiCSVenquiry = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/multiCSVquery/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}
const GetFTD = async (data, data1) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/GetFTD/${data}`, data1

  );
  return result;
}

const GetDynamicForm = async (data) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/Form/GetDynamicForm`,
    data
  );
  return result;
};


const VidyaMapMaster = async (id, type, data) => {
  console.log(data, "data");
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/Coromandel/VidyaMapMaster/${id}/${type}`,
    data
  );
  return result.data;
};

const getCmndlEmpopt = async (id) => {
  const result = await http.get(ACCESS_POINT + `/cmsContent/coromandel/getCmndlEmpopt?id=${id}`);
  return result;
};
const CmndlEmployeeUpdate = async (tablename, data) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/coromandel/CmndlEmployeeUpdate/${tablename}`,
    data
  );
  return result;
};
const CMNDLCSV = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/CMNDLCSV/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}
const CMNDLQuizCSV = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/CMNDLQuizCSV/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}

const CmdlCourseMapCSV = async (formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/coromandel/CmdlCourseMapCSV`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}

const VidyaReport = async (from, to, Courseid, id) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/Coromandel/VidyaReport?from=${from}&&to=${to}&&Courseid=${Courseid}&&id=${id}`
  );
  return result.data;
};
const VidyaDashReport = async (data) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/Coromandel/VidyadashReport`, data
  );
  return result.data;
};

const TotalAccesslog = async (formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/coromandel/TotalAccesslog`, formData
  );
  return result;
}
const mailtest1 = async (data) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/Coromandel/MailtestAPI`, data
  );
  return result.data;
};

const CmdlCourseMapUpdate = async (categoryArray) => {
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/Coromandel/CmdlCourseMapUpdate`, categoryArray
  );
  return result;
};
const CmdlClusterMapCSV = async (id, type, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/coromandel/CmdlClusterMapCSV/${id}/${type}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}

const library_delete = async (id) => {
  const result = await http.get(
    ACCESS_POINT + `/cmsContent/app/library_delete/${id}`
  );
  return result.data;
}
const getAllOption = async (id) => {
  const result = await http.get(
    ACCESS_POINT + `/dfcarz/GetAllOption`
  );
  return result.data;
}
const getAllOption_Web = async (id) => {
  const result = await http.get(
    ACCESS_POINT + `/dfcarz/getAllOption_Web`
  );
  return result.data;
}

const CheckListAddProduct1 = async (tblename, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/dfcarz/CheckListAddProduct1/${tblename}`,
    formData,

  );

  return result;
};
const MultiArrayFileUpload = async (tableName, data, id = 'id') => {
  const result = await http.post(
    ACCESS_POINT + `/dfcarz/app/MultiArrayFileUpload/${tableName}/${id}`,
    data
    // {
    //   headers: {
    //     "Content-Type": "application/json"
    //   }
    // }
  );
  return result;
};
const GetCmdlDatas = async (id, type, data = null) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/Coromandel/GetCmdlDatas/${id}/${type}`,
    data
  );
  return result.data;
};


//test updation
const updateToRedisLocal = async (content) => {
  const result = await http.get(`${EXPRESS_REDIS_ACCESS_POINT}/swtn/uploadToRedisLocal/${content}`);
  return result

}

const uploadToRedisLive = async (projectSelected) => {
 
  const result = await http.post(`${EXPRESS_REDIS_ACCESS_POINT}/swtn/uploadToRedisLive`,projectSelected);
  return result

}
const updateManualRedisLocal = async (objectArray) => {
  const result = await http.post(`${EXPRESS_REDIS_ACCESS_POINT}/swtn/manualDataUploadToRedis`, objectArray);
  return result

}
const sendOtp = async(formData,id)=>{ 
  const result = await http.post(ACCESS_POINT + `/cmsContent/sendOtp/${id}`,formData)
  return result;
}

const DeleteMultyUser = async(table,data)=>{ 
  const result = await http.post(ACCESS_POINT + `/cmsContent/DeleteMultyUser/${table}`,data)
  return result;
}
const recoverUser = async(table,data)=>{ 
  const result = await http.post(ACCESS_POINT + `/cmsContent/recoverUser/${table}`,data)
  return result;
}

const DeletePermanentMultyUser = async(table,data)=>{ 
  const result = await http.post(ACCESS_POINT + `/cmsContent/DeletePermanentMultyUser/${table}`,data)
  return result;
}

const DeleteMultyDealer = async(table,data)=>{ 
  const result = await http.post(ACCESS_POINT + `/cmsContent/DeleteMultyDealer/${table}`,data)
  return result;
}
const recoverDealer = async(table,data)=>{ 
  const result = await http.post(ACCESS_POINT + `/cmsContent/recoverDealer/${table}`,data)
  return result;
}

const DeletePermanentMultyDealer = async(table,data)=>{ 
  const result = await http.post(ACCESS_POINT + `/cmsContent/DeletePermanentMultyDealer/${table}`,data)
  return result;
}

const dealerCsvUpload = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/dealerCsvUpload/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}

const dealerDeleteCsvUpload = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/dealerDeleteCsvUpload/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}

const insertDealerMuldidata = async (tableName, formData) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/insertDealerMuldidata/${tableName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
  return result;
}
const InsertMultydata = async(table,data)=>{ 
  const result = await http.post(ACCESS_POINT + `/cmsContent/InsertMultydata/${table}`,data)
  return result;
}

const GetFormReport = async (id, type, data = null) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/GetFormReport/${id}/${type}`,
    data
  );
  return result.data;
};
const GetSchoolFormReport = async (id, type, data = null) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/GetSchoolFormReport/${id}/${type}`,
    data
  );
  return result.data;
};
const GetChartDatas = async (id, type, data = null) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/GetChartDatas/${id}/${type}`,
    data
  );
  return result.data;
};
const GetSchoolChartDatas = async (id, type, data = null) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/GetSchoolChartDatas/${id}/${type}`,
    data
  );
  return result.data;
};
const GetJsonDatas = async (type, data = null) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/get/jsondata/${type}`,
    data
  );
  return result.data;
};
const sendMailReport = async (mail, data) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/send/momReport/${mail}`,
    data
  );
  return result.data;
};
const emailexcel = async ( data) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/send/emailexcel`,
    data
  );
  return result.data;
};
const downloadExcel = async ( data ) => {

  const result = await http.post(
    ACCESS_POINT + `/cmsContent/send/downloadexcel`,
    data,
    {
      headers: {
        "Content-Type": 'application/json'
      }, 
      responseType: 'blob'
    }
  );
  return result.data;
};
const downloadPdf = async ( data ) => {

  const result = await http.post(
    ACCESS_POINT + `/cmsContent/send/downloadpdf`,
    data,
    {
      headers: {
        "Content-Type": 'application/json'
      }, 
      responseType: 'blob'
    }
  );
  return result.data;
};
const getSchduleDate = async (data) => {
  const result = await http.post(
    ACCESS_POINT + `/cmsContent/get/Schdule`,
    data
  );
  return result.data;
};

  

export default {
  downloadExcel,
  downloadPdf,
  getSchduleDate,
  emailexcel,
  sendMailReport,
  InsertMultydata,
  GetJsonDatas,
  GetChartDatas,
  GetSchoolChartDatas,
  GetSchoolFormReport,
  DeleteMultyUser,
  DeletePermanentMultyDealer,
  recoverDealer,
  recoverUser,
  DeletePermanentMultyUser,
  DeleteMultyDealer,
  sendOtp,
  uploadToRedisLive,
  updateToRedisLocal,
  updateManualRedisLocal,
  mailtest1,
  CheckListAddProduct1,
  themeInsert,
  updateTheme,
  learnerfile,
  inserthome,
  portletdelete,
  compareValues,
  getThemes,
  getThemeById,
  addMaster,
  getMasterValues,
  getAllMappedpages,
  getAllPages,
  updatePage,
  updateLoginStatus,
  getAllVideos,
  checkVideoCount,
  updateCount,
  rightPanelAdd,
  getRightPanelList,
  getAllRightPanelList,
  getVideoContentById,
  getAllSerchKeyValue,
  subTitleAdd,
  getSubTitleValues,
  getAllSubtitleList,
  getAllSubtitleLists,
  getVideoListForAdmin,
  addQuizContent,
  getQuizQuestion,
  insertImage,
  updateQuizAnswer,
  checkQuizAttended,
  getLibraryImage,
  getScoreOfUser,
  updateCategory,
  updateCategorys,
  updateMaster,
  // updateFProof,
  deleteMaster,
  videoSeekLog,
  updatecatsubcat,
  displayQuizQuestion,
  getAllHomePanelList,
  authorinsert,
  authorinserts,
  giftinsert,
  updateMasters,
  getAllCategoryMappedPages,
  getauthorvalues,
  updateauthor,
  Updategift,
  getCompanyUsers,
  UpdateOrder,
  videoList,
  videoValue,
  videoAnalyticsList,
  videoJsonList,
  videoUrl,
  videoObjectTracking,
  getLikesLogs,
  getMasteractive,
  getquizlog,
  getPagesData,
  getConditionedValuewithStatus,
  getInnerJoinListwithStatus,
  getInnerJoinList,
  learningObjectSubmit,
  getUserJson,
  mapUserToResource,
  getSingleConditionedValue,
  getSingleConditioned,
  mappingportlet,
  getLearner,
  getgroupbyvalue,
  getgroupbycohorts,
  getColoumtablevalue,
  getValuebyColumn,
  updateBox,
  valueCustomerId,
  getSelectvalue,
  getTwoConditionedValue,
  getTwoConditionedValueFree,
  getThreeConditionedValue,
  getThreeConditionedValueFree,
  getLeanersFromCohorts,
  getFreedom,
  chan,
  chanAnswer,
  addpageupdate,
  addpagemodelwindow,
  addSurveyContent,
  addSurveyQuestion,
  addSurveyAnswer,
  getPerformanceForCohort,
  getoverallTopperdetails,
  sectionContents,
  PortletAdded,
  getIP,
  pageViewCount,
  contactform,
  createcaptcha,
  deleteUser,
  rightPanelUpdate,
  deleteRightPanel,
  updateHierarchy,
  AddProductMaster,
  AddedProductMaster,
  UpdateProductMaster,
  UpdatedProductMaster,
  SingleFileUpload,
  fileuploadgift,
  deleteFreedom,
  GoogleLogin,
  CommonAddMaster,
  MultiFileUpload,
  WithPassowrdlogin,
  coromandelHandlelogin,
  siteintegratedstatus,
  MultiCSVenquiry,
  GetFTD,
  GetDynamicForm,
  inventFileupload,
  VidyaMapMaster,
  //cmndl
  getCmndlEmpopt,
  CMNDLCSV,
  CMNDLQuizCSV,
  CmndlEmployeeUpdate,
  CmdlCourseMapCSV,
  VidyaReport,
  VidyaDashReport,
  TotalAccesslog,
  CmdlCourseMapUpdate,
  CmdlClusterMapCSV,
  //S3Delete
  library_delete,
  BUYDFCARZ,
  getAllOption,
  MultiArrayFileUpload,
  SteerImage,
  GetCmdlDatas,
  getAllOption_Web,
  dealerCsvUpload,
  insertDealerMuldidata,
  dealerDeleteCsvUpload,
  GetFormReport
};
