
import React, { Component, createRef } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import MultiSelect from "../../../components/Form/MultiSelect";

import cmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
// import Addauthormodel from "./Addauthormodel";
import { Alert } from "reactstrap";
import { ACCESS_SAMPLE } from "../../../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import AddpagePDF from "../../../components/PDF/Addpagespdf";

//import Theme9 from './addPages/theme9';
import Datatable from "../../../components/Datatable/Datatable";
import { FormMiddleWare } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";
import CmsContent from "../../../MiddleWare/CmsContent";
import Paginationfun from "../pagination";
import Mompdf from "./PDF/MomPdf";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import { saveAs } from 'file-saver';
import moment from "moment";

class MomReport extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.pdfRef = createRef();
        this.state = {
            alertVisible: false,
            isEdit: false,
            customerId: localStorage.getItem("userId"),
            data: [],
            editId: null,
            userid: null,
            added: [],
            notadded: [],
            badgeColor: false,
            badgeText: false,
            formAlertdelete: false,
            textalert: "",
            genderopt: [{ label: "MALE", value: 1 }, { label: "FEMALE", value: 2 }],
            // EmployeeCatopt: [{ label: "MS", value: 1 }, { label: "NMS", value: 2 }, { label: "NMS - Staff", value: 3 }, { label: "NMS - Worker", value: 4 }, { label: "MS-Liberty", value: 5 }],
            // Bandopt: [{ label: "Band-1", value: 1 }, { label: "Band-2", value: 2 }, { label: "Band-3", value: 3 }, { label: "NMS", value: 4 },],
            danger: "btn btn-danger",
            success: "btn btn-success",
            pageNumber: 1,
            SelectedEnquiries: [],
            businessunitopt: [],
            bulkAllocate: false,
            modalInput: "",
            showform: false,
            schoolData: [],
            schoolAddress: "",
            date: "",
            startTime: "",
            endTime: "",
            Topic: [],
            discussion: "",
            notes: "",
            topic: "",
            optionattendees: [],
            attendees: "",
            schoolAdresserr: "",
            dateErr: "",
            startTimeErr: "",
            endTimeErr: "",
            topicErr: "",
            attendeesErr: "",
            discussionErr: "",
            notesErr: "",
            alertVisible1: false,
            pdfDownloadshow: false,
            description: "",
            descriptionErr: '',
            discussionarray: [],
            activeId: [],
            notesActiveId: [],
            notesData: [],
            notesOptions: [],
            emailOptions: [],
            email: [],

        };
    }
    column = [
        {
            Header: "ID ",
            accessor: "id",
        },
        {
            Header: "EMPLOYEE ID",
            accessor: "employee_id",
        },
        {
            Header: "EMPLOYEE NAME",
            accessor: "employee_name",
        },
        {
            Header: "EMAIL",
            accessor: "email",
        },


    ];
    async componentDidMount() {
        this.MainFun()

    }

    MainFun = async () => {
        let { customerId } = this.state
        try {
            this.setState({ loading: true })
            const address = await cmsContent.getFreedom(
                "id as value, address_name as label",
                "psychol_address",
                `id`,
                "id",
                "id"
            );
            if (address) {

                this.setState({ schoolData: address.data })
            }
            const email = await cmsContent.getFreedom(
                "id as value, email_id as label",
                " psychol_email",
                `id`,
                "id",
                "id"
            );
            if (email) {
                this.setState({ emailOptions: email.data })
            }
            const topic = await cmsContent.getFreedom(
                "id as value, topic_name as label",
                "psychol_topic",
                `id`,
                "id",
                "id"
            );
            if (topic) {
                this.setState({ Topic: topic.data })
            }
            const notes = await cmsContent.getFreedom(
                "*",
                "Psychol_notes",
                `id`,
                "id",
                "id"
            );
            if (notes) {
                this.setState({ notesOptions: notes.data })
            }
            const mom = await cmsContent.getFreedom(
                "psychol_mom.*,tbl_user_web.userName",
                "psychol_mom,tbl_user_web",
                `psychol_mom.customerid=${customerId} AND tbl_user_web.id=${customerId}`,
                "id",
                "id"
            );
            const Discussion = await cmsContent.getFreedom(
                "*",
                "psychol_discussion",
                1,
                "id",
                "id DESC"
            );
            if (Discussion) {
                // console.log(ACCESS_POINT.ACCESS_SAMPLE);
                this.setState({ DiscussionData: Discussion.data });
            }

            console.log(mom, "mom");
            if (mom && address && topic) {
                const addressLookup = address.data.reduce((acc, c) => {
                    acc[c.value] = c.label;
                    return acc;
                }, {});

                const topicLookup = topic.data.reduce((acc, d) => {
                    acc[d.value] = d.label;
                    return acc;
                }, {});


                mom.data.forEach((ival) => {
                    if (addressLookup[ival.schoolAddress]) {
                        ival.addressName = addressLookup[ival.schoolAddress];
                    }
                    if (topicLookup[ival.topic]) {
                        ival.topicName = topicLookup[ival.topic];
                    }
                    // if (ival.date) {
                    //     ival.date = moment(ival.date).format('DD-MM-YYYY');
                    // }
                    // if (ival.startTime && ival.endTime) {
                    //     ival.time = ival.startTime + "am to " + ival.endTime + "pm"}
                    // } 
                    //     if (ival.discussion) {
                    //         console.log(ival.discussion)
                    //         let selectedValue = "";
                    //     }
                });
                // console.log(mom, "mom1");

                // await Promise.all(mom.data.map((ival) => {
                // let discssionArray=JSON.parse(ival.discussion)
                // console.log(discssionArray,"discussionArray")
                // let discussion = "";
                // JSON.parse(ival.discussion).map(values => (discussion += `${values},`));
                // ival.discussion = discussion.replace(/,\s*$/, "");

                // }))
                console.log(mom, "mom");
                // let counsellingdata = []
                // let trainingdata = []
                // let TSData = []
                // let NTSData = []
                // Counselling.data.forEach((ival) => {
                //     if (ival.type == "1") {
                //         counsellingdata.push(ival)
                //     } else if (ival.type == "2") {
                //         trainingdata.push(ival)
                //     } else if (ival.type == "3") {
                //         TSData.push(ival)
                //     } else if (ival.type == "4") {
                //         NTSData.push(ival)
                //     }

                // });

                this.setState({ momData: mom.data });
            }


            this.setState({ loading: false })

        } catch (error) {
            console.log(error);
        }
    }
    column = [
        {
            Header: "Topic",
            accessor: "topicName",
        },
        {
            Header: "Notes",
            accessor: "notes",
        },
        {
            Header: "Discussion",
            accessor: "discussion",
        },
        {
            Header: "Address",
            accessor: "addressName",
        },
        {
            Header: "Date",
            accessor: "date",
        },

        {
            Header: "PDF",
            accessor: "pdf",
            // Cell: (d) => this.buttonPDF(d),
            Cell: (row) => this.buttonPDF(row),

        },

        {
            Header: "Mail",
            // accessor: "edit",
            Cell: (d) => this.Edit(d, 'sendMail')
        }
    ];
    Edit = (d, modalWindowId) => {
        let value = d.original
        return (
            <>
                <button className="btn btn-info" data-toggle="modal" data-target={`#${modalWindowId}`} onClick={() => this.Editpages(value)}>Send Mail</button>
            </>
        )
    }
    Editpages = async (rowData) => {
        let data = rowData.discussion;
        // console.log(data,"data");
        // Ensure data is a string before calling split
        if (typeof data !== 'string') {
            data = String(data);
        }

        let dataArray = data.split(",");
        rowData.discussion = dataArray.map((item) => item.trim());

        const blob = await pdf(<Mompdf data={rowData} />).toBlob();
        const formData = new FormData();
        formData.append('pdf', blob, 'sample.pdf');
        formData.append("report", JSON.stringify(rowData));

        this.setState({ reportData: formData });
    };

    buttonPDF = (row) => {
        let value = row.original
        // let { pdfdata } = this.state
        return (
            <><button className="btn btn-success" data-toggle="modal" onClick={() => this.viewpdf(value)}>Download PDF</button></>
        );
    }

    viewpdf = async (rowData) => {
        console.log(rowData, "rowData");
        let data = rowData.discussion
        if (typeof data !== 'string') {
            data = String(data);
        }

        let dataArray = data.split(",");
        rowData.discussion = dataArray.map((item) => item.trim());
        console.log(rowData, "rowData")
        const blob = await pdf(<Mompdf data={rowData} />).toBlob();
        saveAs(blob, 'minutes_of_meeting.pdf');
        this.setState({ pdfdata: rowData, pdfDownloadshow: true })
    }


    handlechange = async (e, type, name) => {
        let { customerId } = this.state
        console.log(customerId, "customerId");
        if (type == "Select") {
            console.log("e", e)
            await this.setState({
                [name]: e, schoolAdresserr: "", topicErr: ""
            })
        }
        else if (type == "caps") {
            this.setState({ startTimeErr: "", endTimeErr: "", notesErr: "", discussionErr: "", descriptionErr: "" })
            // if (e.target.name === "date") {
            //     let obj = {
            //         fromdate: e.target.value,
            //         todate: ""
            //     }
            //     this.setState({ date: e.target.value, dateErr: "" })
            //     const result = await CmsContent.GetChartDatas(customerId, "attendees", obj)
            //     console.log(result)
            //     if (result && result.length > 0) {
            //         this.setState({ optionattendees: result })
            //     }
            // }
            await this.setState({
                [e.target.name]: e.target.value,
            });
        }
        else {
            console.log(e.target.name)
            await this.setState({ [e.target.name]: e.target.value, errorempname: "", errorCode: "" });
        }
    };

    submitCategory = async () => {
        const { schoolAddress, notes, topic, attendees, date, startTime, endTime, discussion, discussionarray, customerId, description } = this.state
        if (!schoolAddress) {
            this.setState({ schoolAdresserr: "Enter school address" })
            return false
        }
        if (!date) {
            this.setState({ dateErr: "Select the date" })
            return false
        }
        if (!startTime) {
            this.setState({ startTimeErr: "Select the start time " })
            return false
        }
        if (!endTime) {
            this.setState({ endTimeErr: "Select the end time" })
            return false
        }
        if (!topic) {
            this.setState({ topicErr: "Enter the topic" })
            return false
        }
        if (!description) {
            this.setState({ descriptionErr: "Enter the description" })
            return false
        }
        if (!attendees) {
            this.setState({ attendeesErr: "Enter the attendees" })
            return false
        }
        if (!discussion) {
            this.setState({ discussionErr: "Enter the discussion" })
            return false
        }
        if (!notes) {
            this.setState({ notesErr: "Enter the notes" })
            return false
        }
        else {
            let categoryArray = {}
            categoryArray.schoolAddress = schoolAddress.value
            categoryArray.startTime = moment(startTime, 'HH:mm').format('hh:mm A')
            categoryArray.endTime = moment(endTime, 'HH:mm').format('hh:mm A')
            categoryArray.topic = topic.value
            categoryArray.description = description
            categoryArray.attendees = attendees
            categoryArray.discussion = discussion
            categoryArray.notes = notes
            categoryArray.customerId = customerId
            categoryArray.date = moment(date).format("DD-MM-YYYY")
            console.log(categoryArray)
            const result = await cmsContent.addMaster(
                "psychol_mom",
                categoryArray)
            if (result.status == 200) {
                this.setState({ alertVisible1: true })
                setTimeout(() => {
                    window.location.reload()
                }, 3000);
            }
        }
    };

    reset1 = async () => {
        await this.setState({
            badgeText: false, badgeColor: false,
            editId: false, userid: false, isEdit: false, alertVisible: false, error: ""
        })
    }

    modalhandlechange = (e) => {
        this.setState({ modalInput: e.target.value, error: "" })
    }

    submitmodal = async (b, name, data) => {
        console.log(b, name, [...data])
        let value = this.state.modalInput
        if (value === null || value.trim() === "") {
            this.setState({ error: "Value Cannot Be Empty" });
            return false;
        }
        else if (b === "schooladdress") {
            const a = {
                table_name: "psychol_address",
                categoryArray: { address_name: null }
            }
            a.categoryArray.address_name = value.trim();
            console.log(a.categoryArray.address_name)
            console.log(a, "a");
            try {
                this.setState({ disableValue: true });
                const result = await cmsContent.addMaster(
                    a.table_name,
                    a.categoryArray
                );

                if (result) {
                    this.setState({
                        alertVisible: true,
                        modalInput: "",
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                    }, 3000);
                }
                this.MainFun()
            } catch (error) {
                console.log(error);
            }
        }
        else if (b === "topic_name") {
            const a = {
                table_name: "psychol_topic",
                categoryArray: { topic_name: null }
            }
            a.categoryArray.topic_name = value.trim();
            console.log(a.categoryArray.topic_name)
            console.log(a, "a");
            try {
                this.setState({ disableValue: true });
                const result = await cmsContent.addMaster(
                    a.table_name,
                    a.categoryArray
                );

                if (result) {
                    this.setState({
                        alertVisible: true,
                        modalInput: "",
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                    }, 3000);
                    this.MainFun()
                }
            } catch (error) {
                console.log(error);
            }
        }
        else if (b === "discussion_name") {
            const a = {
                table_name: "psychol_discussion",
                categoryArray: { discussion_name: null }
            }
            a.categoryArray.discussion_name = value.trim();
            console.log(a.categoryArray.discussion_name)
            console.log(a, "a");
            try {
                this.setState({ disableValue: true });
                const result = await cmsContent.addMaster(
                    a.table_name,
                    a.categoryArray
                );

                if (result) {
                    this.setState({
                        alertVisible: true,
                        modalInput: "",
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                    }, 3000);
                    this.MainFun()
                }
            } catch (error) {
                console.log(error);
            }
        }
        else if (b === "notes") {
            const a = {
                table_name: "Psychol_notes",
                categoryArray: { notes_name: null }
            }
            a.categoryArray.notes_name = value.trim();
            console.log(a.categoryArray.notes_name)
            console.log(a, "a");
            try {
                this.setState({ disableValue: true });
                const result = await cmsContent.addMaster(
                    a.table_name,
                    a.categoryArray
                );

                if (result) {
                    this.setState({
                        alertVisible: true,
                        modalInput: "",
                    })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                    }, 3000);
                }
                this.MainFun()
            } catch (error) {
                console.log(error);
            }
        }
        else if (b === "sendMail") {
            // try {
            //     const result = await cmsContent.sendMailReport(name, data)

            //     if (result) {
            //         this.setState({
            //             alertVisible: true,
            //             modalInput: "",
            //         })
            //         setTimeout(() => {
            //             this.setState({ alertVisible: false })
            //         }, 3000);
            //         this.MainFun()
            //     }
            //     // }
            // } catch (error) {
            //     console.log(error);
            // }
        }


    }
    submitmodal2 = async (b, name, data) => {
        let datas = data
        console.log(b, name, [...datas])
        datas.append("mailid", this.state.email)
        console.log([...datas], "data")
        try {
            const result = await cmsContent.sendMailReport("sendMail", datas)
            console.log(result, "result");

            if (result) {
                this.setState({
                    alertVisible: true,
                    emaildata: "",
                })
                setTimeout(() => {
                    this.setState({ alertVisible: false })
                }, 3000);
                this.MainFun()
            }
        }
        catch (error) {
            console.log(error);
        }
    }
    reset = () => {
        this.setState({ error: "" })
    }

    onDismiss = () => {
        this.setState({ alertVisible: false });
        this.setState({ formAlertdelete: false });
    };
    optionSelect = async (selectedOption) => {
        console.log(selectedOption, "selectedOption");
        if (selectedOption) {
            let selectedValue = "";
            selectedOption.map(values => (selectedValue += `${values.label},`));
            // console.log(selectedValue, "selectedvaluie")
            selectedValue = selectedValue.replace(/,\s*$/, "");
            console.log(selectedValue, "selectedValue");
            await this.setState({
                emaildata: selectedOption,
                email: selectedValue,
            });
        }
    }
    copyToClipboard = async (text, type) => {
        console.log(text, "text");
        let { discussionarray, activeId, notesData, notesActiveId } = this.state
        console.log(discussionarray, "discussionarray");
        if (type == "discussion") {
            if (discussionarray && discussionarray.length > 0) {
                discussionarray.map(async (ival, index) => {
                    const newArray = [...this.state.discussionarray, text.discussion_name];
                    const activeAarray = [...this.state.activeId, text.id]
                    await this.setState({ discussionarray: newArray, activeId: activeAarray });
                    console.log(newArray);
                    const result = newArray.join(", ")
                    await this.setState({ discussion: result })
                    if (ival == text.discussion_name) {
                        let array = discussionarray.filter(ival => ival !== text.discussion_name)
                        let activeAarray = activeId.filter(ival => ival !== text.id)
                        console.log(array, "array---")
                        await this.setState({ discussionarray: array, activeId: activeAarray })
                        const result = array.join(", ")
                        this.setState({ discussion: result })
                        // return false
                    }
                })
            }
            else {
                console.log("enter elase");
                const newArray = [...this.state.discussionarray, text.discussion_name];
                const activeAarray = [...this.state.activeId, text.id]
                this.setState({ discussionarray: newArray, activeId: activeAarray });
                console.log(newArray);
                const result = newArray.join(", ")
                this.setState({ discussion: result })
            }
        }
        if (type == "notes") {
            if (notesData && notesData.length > 0) {
                notesData.map(async (ival, index) => {
                    const newArray = [...this.state.notesData, text.notes_name];
                    const activeAarray = [...this.state.notesActiveId, text.id]
                    await this.setState({ notesData: newArray, notesActiveId: activeAarray });
                    console.log(newArray);
                    const result = newArray.join(", ")
                    await this.setState({ notes: result })
                    if (ival == text.notes_name) {
                        let array = notesData.filter(ival => ival !== text.notes_name)
                        let activeAarray = notesActiveId.filter(ival => ival !== text.id)
                        console.log(array, "array---")
                        await this.setState({ notesData: array, notesActiveId: activeAarray })
                        const result = array.join(", ")
                        this.setState({ notes: result })
                        // return false
                    }
                })
            }
            else {
                const newArray = [...this.state.notesData, text.notes_name];
                const activeAarray = [...this.state.notesActiveId, text.id]
                this.setState({ notesData: newArray, notesActiveId: activeAarray });
                console.log(newArray);
                const result = newArray.join(", ")
                this.setState({ notes: result })
            }
        }

    };
    delete = (value) => {
        console.log(value, "value")
    }
    render() {

        const {
            badgeColor,
            badgeText,
            textalert,
            color,
            MOlist, //marketing off
            DiscussionData,
            Saleslist, alertVisible, added, notadded, loading, businessunitopt, ansBusinessUnit, modalInput, error, schoolAddress, topic, date, startTime, endTime, discussion, notes, attendees, alertVisible1, reportData, pdfdata, pdfDownloadshow, notesData, activeId, notesActiveId, notesOptions
        } = this.state;
        // console.log(pdfdata, "pdfdata");
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>MINUTES  OF MEETING</h3>
                                    </div>
                                    <div className="card-body">

                                        <LoginModal
                                            // buttonTitle="Send Mail "
                                            // title="Send Mail"
                                            id="sendMail"
                                            extraClass="btn-width d-none"
                                            onClick={this.reset}
                                            bodyText={
                                                <div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <Alert
                                                            className="badge-content"
                                                            color="success"
                                                            isOpen={alertVisible}
                                                            toggle={this.onDismiss}
                                                        >
                                                            Mail Sent Successfully
                                                        </Alert>
                                                    </div>
                                                    <div className="card-header">
                                                        <h1>SEND MAIL</h1>
                                                    </div>
                                                    <div className="row form-group pt-5">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="subcategory">Email </label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <MultiSelect
                                                                // disabled={this.state.DCDisable}
                                                                options={this.state.emailOptions}
                                                                handleChange={(e) => this.optionSelect(e)}
                                                                selectedService={this.state.emaildata}

                                                            />
                                                            {/* <span className="modal-error-show">{error}</span> */}
                                                        </div>
                                                        <div className="col-sm-3" />
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-5">
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary"
                                                                onClick={(d) => this.submitmodal2("sendMail", modalInput, reportData)}
                                                            >
                                                                submit
                                                            </button>
                                                        </div>
                                                        <div className="col-sm-3" />
                                                    </div>
                                                    <br></br>
                                                </div>
                                            }
                                        />


                                        {/* <div className="row form-group py-5 px-2" id="showform" >
                                            <div className="col-sm-2 d-md-none d-lg-block"></div>
                                            <div className="col-sm-8 col-md-12 col-lg-8">
                                                <div className="py-5" style={{ border: "1px solid #80808038", position: "relative" }}>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <Alert
                                                            className="badge-content"
                                                            color="success"
                                                            isOpen={alertVisible1}
                                                            toggle={this.onDismiss}
                                                        >
                                                            New MoM Form added
                                                        </Alert>
                                                    </div>
                                                    
                                                    <div className="row form-group mom-px">
                                                        <div className="col-sm-2"/>
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">School Address </label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <SingleSelect
                                                                options={this.state.schoolData}
                                                                handleChange={d => this.handlechange(d, "Select", "schoolAddress")}
                                                                selectedService={this.state.schoolAddress}
                                                            />

                                                        </div>
                                                        <div className="col-sm-2 ">
                                                            <LoginModal
                                                                buttonTitle="Add School Address "
                                                                title="Add School Address"
                                                                id="schoolAddress"
                                                                extraClass="btn-width mom-btn"
                                                                onClick={this.reset}
                                                                bodyText={
                                                                    <div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={alertVisible}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                New School Address Added
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="card-header">
                                                                            <h1>School Address</h1>
                                                                        </div>
                                                                        <div className="row form-group pt-5">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2">
                                                                                <label htmlFor="subcategory">School Address </label>
                                                                            </div>
                                                                            <div className="col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    name="subcategory"
                                                                                    placeholder="Enter School Address"
                                                                                    className="form-control"
                                                                                    value={modalInput}
                                                                                    onChange={(e) => this.modalhandlechange(e)}
                                                                                />
                                                                                <span className="modal-error-show">{error}</span>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-5">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => this.submitmodal("schooladdress")}
                                                                                >
                                                                                    submit
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <br></br>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="row form-group mom-px">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.schoolAdresserr}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group mom-px">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Date </label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="date"
                                                                className="form-control"
                                                                id="Name"
                                                                name="date"
                                                                value={this.state.date}
                                                                onChange={d => this.handlechange(d, "caps")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group mom-px">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.dateErr}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group mom-px">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Start Time</label>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <input
                                                                type="time"
                                                                className="form-control"
                                                                id="Name"
                                                                name="startTime"
                                                                value={this.state.startTime}
                                                                onChange={d => this.handlechange(d, "caps")}
                                                            />
                                                        </div>

                                                        <div className="col-sm-1.8">
                                                            <label htmlFor="exampleInputEmail1" className="end-time">End Time</label>
                                                        </div>
                                                        <div className="col-sm-2">
                                                            <input
                                                                type="time"
                                                                className="form-control"
                                                                id="Name"
                                                                name="endTime"
                                                                value={this.state.endTime}
                                                                onChange={d => this.handlechange(d, "caps")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group mom-px">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.startTimeErr}
                                                        </span>
                                                        <div className="col-sm-3" />
                                                        <span className="error-show endtime-err">
                                                            {this.state.endTimeErr}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group mom-px">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Topic </label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <SingleSelect
                                                                options={this.state.Topic}
                                                                handleChange={d => this.handlechange(d, "Select", "topic")}
                                                                selectedService={this.state.topic}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3">
                                                            <LoginModal
                                                                buttonTitle="Add Topic "
                                                                title="Add Topic"
                                                                id="businessUnit"
                                                                extraClass="btn-width mom-btn"
                                                                onClick={this.reset}
                                                                bodyText={
                                                                    <div>
                                                                        <div className="row form-group ">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={alertVisible}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                New Topic Added
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="card-header">
                                                                            <h1>Topic</h1>
                                                                        </div>
                                                                        <div className="row form-group pt-5">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2">
                                                                                <label htmlFor="subcategory">Topic </label>
                                                                            </div>
                                                                            <div className="col-sm-5">
                                                                                <input
                                                                                    type="text"
                                                                                    name="subcategory"
                                                                                    placeholder="Enter Topic "
                                                                                    className="form-control"
                                                                                    value={modalInput}
                                                                                    onChange={(e) => this.modalhandlechange(e)}
                                                                                />
                                                                                <span className="modal-error-show">{error}</span>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-5">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => this.submitmodal("topic_name")}
                                                                                >
                                                                                    submit
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <br></br>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>

                                                    </div>
                                                    <div className="row form-group mom-px">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.topicErr}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group mom-px">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Description</label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <textarea
                                                                type="text"
                                                                className="form-control"
                                                                id="description"
                                                                name="description"
                                                                rows="2"
                                                                placeholder="Enter description"
                                                                value={this.state.description}
                                                                onChange={d => this.handlechange(d, "caps")}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3">

                                                        </div>
                                                    </div>
                                                    <div className="row form-group">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.descriptionErr}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group mom-px">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Attendees </label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <textarea
                                                                type="text"
                                                                className="form-control"
                                                                id="attendees"
                                                                name="attendees"
                                                                rows="2"
                                                                placeholder="Enter attendees"
                                                                value={this.state.attendees}
                                                                onChange={d => this.handlechange(d, "caps")}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3">

                                                        </div>
                                                    </div>
                                                    <div className="row form-group mom-px" >
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.attendeesErr}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group mom-px">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Discussion </label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <textarea
                                                                type="text"
                                                                className="form-control"
                                                                id="pageName"
                                                                name="discussion"
                                                                rows="4"
                                                                placeholder="Enter Discussion"
                                                                value={this.state.discussion}
                                                                onChange={this.handlechange}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <LoginModal
                                                                buttonTitle="Add Discussion "
                                                                title="Add Discussion"
                                                                id="Discussion"
                                                                extraClass="btn-width mom-btn"
                                                                onClick={this.reset}
                                                                bodyText={
                                                                    <div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={alertVisible}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                New Discussion Added
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={alertVisible1}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                Coppied Successfully
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="card-header">
                                                                            <h1>Discussion</h1>
                                                                        </div>
                                                                        <div className="row form-group pt-5">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2">
                                                                                <label htmlFor="subcategory">Discussion </label>
                                                                            </div>
                                                                            <div className="col-sm-5">
                                                                                <textarea
                                                                                    type="text"
                                                                                    name="subcategory"
                                                                                    placeholder="Enter Discussion "
                                                                                    className="form-control"
                                                                                    rows="4"
                                                                                    value={modalInput}
                                                                                    onChange={(e) => this.modalhandlechange(e)}
                                                                                />
                                                                                <span className="modal-error-show">{error}</span>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-5">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => this.submitmodal("discussion_name")}
                                                                                >
                                                                                    submit
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <br></br>

                                                                        <div>
                                                                            <div className="row form-group mom-px">
                                                                                <div className="col-sm-1" />
                                                                                <div className="col-sm-9">
                                                                                    <div class="card cardDataList">
                                                                                        <ul class="list-group list-group-flush">
                                                                                            {DiscussionData && DiscussionData.length > 0 &&
                                                                                                DiscussionData.map((ival) => {
                                                                                                    return (
                                                                                                        <li key={ival.id} className="listPara list-group-item nav-item">
                                                                                                            <i className={`copyiconList nav-icon fa ${activeId.includes(ival.id) ? 'fa-check' : 'fa-copy'}`} onClick={() => this.copyToClipboard(ival, "discussion")} />
                                                                                                            {ival.discussion_name}
                                                                                                        </li>
                                                                                                    )
                                                                                                })}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-1" />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group mom-px">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.discussionErr}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group mom-px">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2">
                                                            <label htmlFor="exampleInputEmail1">Notes </label>
                                                        </div>
                                                        <div className="col-sm-5">
                                                            <textarea
                                                                type="text"
                                                                className="form-control"
                                                                id="Name"
                                                                name="notes"
                                                                rows="4"
                                                                placeholder="Enter Notes"
                                                                value={this.state.notes}
                                                                onChange={d => this.handlechange(d, "caps")}
                                                            />
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <LoginModal
                                                                buttonTitle="Add notes "
                                                                title="Add notes"
                                                                id="notes"
                                                                extraClass="btn-width mom-btn"
                                                                onClick={this.reset}
                                                                bodyText={
                                                                    <div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={alertVisible}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                New Notes Added
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={alertVisible1}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                Coppied Successfully
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="card-header">
                                                                            <h1>Notes</h1>
                                                                        </div>
                                                                        <div className="row form-group pt-5">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2">
                                                                                <label htmlFor="subcategory">Notes</label>
                                                                            </div>
                                                                            <div className="col-sm-5">
                                                                                <textarea
                                                                                    type="text"
                                                                                    name="subcategory"
                                                                                    placeholder="Enter Notes"
                                                                                    className="form-control"
                                                                                    rows="4"
                                                                                    value={modalInput}
                                                                                    onChange={(e) => this.modalhandlechange(e)}
                                                                                />
                                                                                <span className="modal-error-show">{error}</span>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-5">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    onClick={() => this.submitmodal("notes")}
                                                                                >
                                                                                    submit
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <br></br>

                                                                        <div>
                                                                            <div className="row form-group mom-px">
                                                                                <div className="col-sm-1" />
                                                                                <div className="col-sm-9">
                                                                                    <div class="card cardDataList">
                                                                                        <ul class="list-group list-group-flush">
                                                                                            {notesOptions && notesOptions.length > 0 &&
                                                                                                notesOptions.map((ival) => {
                                                                                                    return (
                                                                                                        <li className="listPara list-group-item nav-item">
                                                                                                            <i className={`copyiconList nav-icon fa ${notesActiveId.includes(ival.id) ? 'fa-check' : 'fa-copy'}`} onClick={() => this.copyToClipboard(ival, "notes")} />
                                                                                                            {ival.notes_name}
                                                                                                        </li>
                                                                                                    )
                                                                                                })}
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-1" />
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row form-group mom-px">
                                                        <div className="col-sm-4" />
                                                        <span className="error-show ">
                                                            {this.state.notesErr}
                                                        </span>
                                                    </div>
                                                    <br />
                                                    <div className="row form-group">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-7">
                                                            <Alert
                                                                className="badge-content"
                                                                color={badgeColor && badgeColor}
                                                                isOpen={this.state.alertVisible}
                                                                toggle={this.onDismiss}
                                                            >
                                                                {badgeText && badgeText}
                                                            </Alert>
                                                        </div>
                                                        <div className="col-sm-3" />
                                                    </div>
                                                    <br />
                                                    <div className="row form-group mom-px">
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-2" />
                                                        <div className="col-sm-5">
                                                            <button
                                                                type="button"
                                                                className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
                                                                disabled={this.state.btnDisable}
                                                                onClick={this.submitCategory}
                                                            >
                                                                {this.state.isEdit ? 'Update' : 'Submit'}
                                                            </button>
                                                        </div>
                                                        <div className="col-sm-3" />
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-sm-2 d-md-none d-lg-block"></div>
                                        </div> */}

                                        {/* <br></br> */}
                                        {/* <span>
                                            Total of {Object.keys(this.state.momData).length} records
                                        </span>
                                        <br></br> */}


                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                {loading ? <div className="text-center">
                                                    < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                </div> : this.state.momData ?
                                                    <>
                                                        <Datatable
                                                            data={this.state.momData}
                                                            columnHeading={this.column}
                                                        />
                                                    </>
                                                    : <>No data Found</>}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </main>
            </React.Fragment>
        );
    }
}

export default MomReport;
