import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import cmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import Addauthormodel from "./Addauthormodel";
import { Alert } from "reactstrap";
import { ACCESS_SAMPLE } from "../../../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import AddpagePDF from "../../../components/PDF/Addpagespdf";

//import Theme9 from './addPages/theme9';
import Datatable from "../../../components/Datatable/Datatable";
import { confirmAlert } from "react-confirm-alert";

class AddMO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertVisible: false,
      authorlist: [],
      checked: 0,
      customerId: localStorage.getItem("userId"),
      errorPageName: null,
      data: [],
      alertVisible1: false,
      btnDisable: false,
      formAlert: false,
      formBtn: false,
      themeSelected: "",
      screen: window.screen.height,
      danger: "btn btn-danger",
      success: "btn btn-success",
      home: "Home",
      set: "SetusHome",
    };
  }
  column = [
    {
      Header: "MO NAME",
      accessor: "name",
    },
    {
      Header: "EMP. ID",
      accessor: "moid",
    },
    {
      Header: "EDIT",
      accessor: "edit",
      Cell: (d) => this.Editpages(d),
    },
    {
      Header: "DELETE",
      // accessor: "edit",
      Cell: (d) => this.buttondel(d),
    },


  ];







  showVisibility = (id) => {
    return (
      <button
        type="button"
        className="btn btn-info"
        onClick={() => this.updateVisibility(id)}
      >
        Hide/Show
      </button>
    );
  };

  changeLoginStatus = (id) => {
    return (
      <button
        type="button"
        class="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '10px' }}
        onClick={() => this.updateLogin(id)}
      >
        Change Login Status
      </button>
    );
  };
  buttondel = (d) => {
    if (d.original.status == 0) {
      return (
        <button

          className="btn btn-danger"
          onClick={() => this.Delete(d)}
        >Delete</button>
      );
    } else {
      return (<span className="label">Deleted</span>)

    }
  }
  Delete = d => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <div style={{ textAlign: "center" }}>
          <h1>Delete confirmation</h1>
          <h4>Are you sure you want to Delete this ?</h4><br />
          <button className="btn btn-primary" onClick={() => {
            this.HandleDelete(d.original)
            onClose();
          }}  >Yes</button> &nbsp;  &nbsp;
          <button className="btn btn-danger" onClick={onClose} >No</button>
        </div>
      }
    })
  };
  HandleDelete = async (d) => {
    try {
      let obj = {}
      obj.status = Date.now()
      const result = await cmsContent.updateMaster(
        "cmndl_tbl_MO1",
        d.id,
        obj
      )
      if (result) {
        this.Mainfun();
      }
    } catch (error) {
      console.log(error);
    }
  }


  Editpages = (value) => {
    if (value.original.status == 0) {
      return (
        <button className="btn btn-info" onClick={() => this.onEdit(value)}>
          Edit
        </button>
      );
    } else {
      return (<span className="label">Deleted</span>)
    }
  };
  onEdit = (value) => {
    let d = value.original;
    console.log(d);
    if (d.SBU) {
      let SBU = this.getValueFromArray1(d.SBU, this.state.SBUopt, 'label')
      // console.log(SBU);
      this.setState({ SBU })
    }
    if (d.RBH) {
      let RBH = this.getValueFromArray1(d.RBH, this.state.RBHopt, 'label')
      // console.log(RBH);
      this.setState({ RBH })
    }
    if (d.State) {
      let State = this.getValueFromArray1(d.State, this.state.Stateopt, 'label')
      // console.log(State);
      this.setState({ State })
    }
    if (d.MO_HQ) {
      let MOHQ = this.getValueFromArray1(d.MO_HQ, this.state.MOHQopt, 'label')
      // console.log(MOHQ);
      this.setState({ MOHQ })
    }
    this.setState({
      Name: d.name,
      Code: d.moid,
      // RBH: d.RBH,
      // MOHQ: d.MO_HQ,
      // State: d.State,
      MONum: d.MO_Number,
      editId: d.id,
      isEdit: true
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  async componentDidMount() {
    this.Mainfun()

  }
  Mainfun = async () => {
    try {
      this.setState({ loading: true })
      const SBUlist = await cmsContent.getFreedom(
        "SBU as label,id as value",
        "cmndl_tbl_SBU",
        1,
        "id",
        "id DESC"
      );
      if (SBUlist) {
        this.setState({ SBUopt: SBUlist.data });
      }
      const Statelist = await cmsContent.getFreedom(
        "State as label,id as value",
        "cmndl_tbl_State",
        1,
        "id",
        "id DESC"
      );
      if (Statelist) {
        this.setState({ Stateopt: Statelist.data });
      }
      const RBHlist = await cmsContent.getFreedom(
        "RBH as label,id as value",
        "cmndl_tbl_RBH",
        1,
        "id",
        "id DESC"
      );
      if (RBHlist) {
        this.setState({ RBHopt: RBHlist.data });
      }
      const MOHQlist = await cmsContent.getFreedom(
        "MOHQ as label,id as value",
        "cmndl_tbl_MOHQ",
        1,
        "id",
        "id DESC"
      );
      if (MOHQlist) {
        this.setState({ MOHQopt: MOHQlist.data });
      }
      const MO = await cmsContent.getFreedom(
        "*",
        "cmndl_tbl_MO1",
        1,
        "id",
        "id DESC"
      );
      if (MO) {
        // console.log(ACCESS_POINT.ACCESS_SAMPLE);
        this.setState({ data: MO.data });
      }
      this.setState({ loading: false })
    } catch (error) {
      console.log(error);
      this.setState({ loading: false })
    }
  }

  handlechange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getValueFromArray1 = (d, array, ch = "value", ch1 = "label") => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item[ch] == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v;
        } else {
          return '-';
        }
      }
    } else {
      return '-';
    }
  };

  submitCategory = async () => {
    const {
      Code,
      Name, SBU, RBH, MOHQ, State, MONum, errorName, errorCode, isEdit, editId
    } = this.state;
    if (!Code) {
      this.setState({ errorCode: "enter  code" });
      return false;
    } else if (!Name) {
      this.setState({
        errorName: "enter name",
        errorCode: "",
      });
      return false;
    } else if (!SBU) {
      this.setState({
        errorSBU: "enter SBU",
        errorName: "",
      });
      return false;
    } else if (!RBH) {
      this.setState({
        errorRBH: "enter RBH",
        errorSBU: "",
      });
      return false;
    } else if (!MOHQ) {
      this.setState({
        errorMOHQ: "enter MOHQ",
        errorRBH: "",
      });
      return false;
    } else if (!State) {
      this.setState({
        errorState: "enter State",
        errorMOHQ: "",
      });
      return false;
    } else if (!MONum) {
      this.setState({
        errorMONum: "enter MO Number",
        errorState: "",
      });
      return false;
    }
    const formData = new FormData();
    let categoryArray = {}
    categoryArray.moid = Code;
    categoryArray.name = Name;
    categoryArray.SBU = SBU.label;
    categoryArray.RBH = RBH.label;
    categoryArray.MO_HQ = MOHQ.label;
    categoryArray.State = State.label;
    categoryArray.MO_Number = MONum;
    // console.log([...formData]);

    try {
      const result = isEdit ? await cmsContent.updateMaster(
        "cmndl_tbl_MO1",
        editId,
        categoryArray
      ) : await cmsContent.addMaster(
        "cmndl_tbl_MO1",
        categoryArray
      );
      if (result) {
        this.setState({
          Code: "",
          Name: '',
          SBU: '',
          RBH: '',
          MOHQ: '',
          State: '',
          MONum: '',
          errorName: '',
          errorCode: '',
          errorSBU: '',
          errorRBH: '',
          errorMOHQ: '',
          errorState: '',
          errorMONum: '',
          btnDisable: false,
          alertVisible: true,

        });
        this.Mainfun()
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  async componentWillReceiveProps() {
    this.setState({ themeId: 0 });

  }
  handleSelect = (name, selectedOption) => {
    this.setState({
      [name]: selectedOption,
    });
  };

  render() {
    // console.log(this.state.data);
    const { SBUopt, Stateopt, RBHopt, MOHQopt } = this.state;
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Add MO</h3>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color="success"
                          isOpen={this.state.alertVisible}
                          toggle={this.onDismiss}
                        >
                          Added Successfully
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>


                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">EMP. ID</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          id="pageName"
                          name="Code"
                          placeholder="Enter code"
                          value={this.state.Code}
                          onChange={this.handlechange}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorCode}
                      </span>
                    </div>
                    <br />
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">MO Name</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          id="Name"
                          name="Name"
                          placeholder="Enter name"
                          value={this.state.Name}
                          onChange={this.handlechange}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorName}
                      </span>
                    </div>
                    <br />
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">SBU</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          options={SBUopt}
                          handleChange={(e) => this.handleSelect('SBU', e)}
                          selectedService={this.state.SBU}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorSBU}
                      </span>
                    </div>
                    <br />
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">RBH</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          options={RBHopt}
                          handleChange={(e) => this.handleSelect('RBH', e)}
                          selectedService={this.state.RBH}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorRBH}
                      </span>
                    </div>
                    <br />
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">State</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          options={Stateopt}
                          handleChange={(e) => this.handleSelect('State', e)}
                          selectedService={this.state.State}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorState}
                      </span>
                    </div>
                    <br />
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">MO HQ</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          options={MOHQopt}
                          handleChange={(e) => this.handleSelect('MOHQ', e)}
                          selectedService={this.state.MOHQ}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorMOHQ}
                      </span>
                    </div>
                    <br />
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">MO Number</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="number"
                          className="form-control"
                          id="MONum"
                          name="MONum"
                          placeholder="Enter Mobile Number"
                          value={this.state.MONum}
                          onChange={this.handlechange}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorMONum}
                      </span>
                    </div>
                    <br />
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
                          disabled={this.state.btnDisable}
                          onClick={this.submitCategory}
                        >
                          {this.state.isEdit ? 'Update' : 'Submit'}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <br />

                    <div className="row form-group">
                      <div className="col-sm-12">
                        {this.state.loading ? <div className="text-center"> <i
                          className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                        ></i></div> : this.state.data && this.state.data.length ? (
                          <Datatable
                            data={this.state.data}
                            columnHeading={this.column}
                          />
                        ) : <div className="text-center">No Data Found</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default AddMO;
