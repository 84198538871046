import React, { Component } from "react";
import socket from "../MiddleWare/socketMiddleWare";

class Test extends Component {
  async componentDidMount() {
    try {
      socket.on("responseTimestamp1", data => {
        console.log(data)
      });
      let i=0;
      setInterval(() => {
        if(i<=10000){
          socket.emit("timestamp", {
            timestamp: new Date().getTime(),
            i,
            cId:1,
            pageName: `testing page - ${Math.random()}`
          }); 
          }
          i=i+1;
        }, 1000);
    } catch (error) {
      console.log(error);
    }
  }
  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Location Master</h1>
                  </div>
                  <div className="card-body">
            
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
            
                      </div>
                      <div className="col-sm-5">
                      
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default Test;
