import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class PsychologyUserNav extends Component {
    render() {
        // let serviceId = localStorage.getItem('currentService');

        return (
            <>
                <div className="sidebar">
                    <nav className="sidebar-nav">
                        <ul className="nav">
                            {/* <ul className="nav-dropdown-items"> */}
                            <li className="nav-item">
                                <Link className="nav-link" to={`/PsychologySchoolReports`}>
                                    <i className="nav-icon fa fa-dashboard" />
                                    <span className="link-white">Dashboard</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/usercalender">
                                    <i className="nav-icon fa fa-calendar" />
                                    <span className="link-white">Calender</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/UserPyschologyForm">
                                    <i className="nav-icon fa fa-check-square-o" />
                                    <span className="link-white">Form</span>
                                </Link>
                            </li>
                            {/* <li className="nav-item nav-dropdown">
                                <Link className="nav-link nav-dropdown-toggle" to="#">
                                    <i className="nav-icon fa fa-arrows" /> Master
                                </Link>
                                <ul className="nav-dropdown-items">

                                    <li className="nav-item">
                                        <Link className="nav-link" to="/addressmaster">
                                            <i className="nav-icon fa fa-user-plus" />
                                            <span className="link-white"> Add School Address</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/topicmaster">
                                            <i className="nav-icon fa fa-user-plus" />
                                            <span className="link-white">Add Topic</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/cmssubcategorymaster">
                                            <i className="nav-icon fa fa-user-plus" />
                                            <span className="link-white">Attendees </span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/cmscategorymaster">
                                            <i className="nav-icon fa fa-user-plus" />
                                            <span className="link-white">Add Mentor</span>
                                        </Link>
                                    </li>



                                </ul>
                            </li> */}
                            <li className="nav-item">
                                <Link className="nav-link" to="/momdatareport">
                                    <i className="nav-icon fa fa-user-plus" />
                                    <span className="link-white">Mom</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/userReport">
                                    <i className="nav-icon fa fa-file" />
                                    <span className="link-white">Reports</span>
                                </Link>
                            </li>
                            {/* </ul> */}
                            {/* <li className="nav-item">
                                <Link className="nav-link" to={`/superAdmin`}>
                                    <i className="nav-icon icon-speedometer" />
                                    <span className="link-white">Service Dashboard</span>
                                </Link>
                            </li> */}
                            {/* <li className="nav-item">
                                <Link className="nav-link" to={`/psychologyDashboard`}>
                                    <i className="nav-icon icon-speedometer" />
                                    <span className="link-white">Dashboard</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/addschool">
                                    <i className="nav-icon fa fa-user-circle-o" />
                                    <span className="link-white">Add School</span>
                                </Link>
                            </li> */}

                          
                            {/* <li className="nav-item nav-dropdown">
                                <Link className="nav-link nav-dropdown-toggle" to="#">
                                    <i className="nav-icon fa fa-arrows" /> Reports
                                </Link>
                            </li> */}
                        </ul>
                    </nav>
                </div>
            </>
        );

    }
}

export default PsychologyUserNav;
