import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import cmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from "reactstrap";
import { EditorState, convertToRaw } from "draft-js";
import AddTheme from "../../Swtn/AddTheme";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import http from "../../../MiddleWare/httpMiddleWare";
import { ACCESS_POINT } from "../../../config";
import Progress from "../../../components/Extra/Progress";
import MultiSelect from "../../../components/Form/MultiSelect";
import LoginModal from "../../../components/Modal/Modal";
import FromLibrary from "./FromLibrary";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import "./style.css";
const options = [
  { label: "Theme1", value: "1" },
  { label: "Theme2", value: "2" },
  { label: "Theme3", value: "3" },
  { label: "Theme4", value: "4" },
  { label: "Theme5", value: "5" }
];
class AddPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      showVisible: "",
      pageName: "",
      showAddPage: true,
      themeId: "",
      themeSelected: [],
      themeName: "",
      contentTitle1: "",
      contentTitle2: "",
      content1: "",
      content2: "",
      file1: "",
      fileName1: "choose File",
      file2: "",
      fileName2: "choose File",
      addBanner: [],
      addBannerName: "choose File",
      errorPageName: "",
      errorThemeId: "",
      errorContentTitle1: "",
      errorContentTitle2: "",
      errorContent1: "",
      errorContent2: "",
      errorFile1: "",
      errorFile2: "",
      alertVisible: false,
      btnDisable: false,
      editorState: EditorState.createEmpty(),
      uploadPercentage: 0,
      searchTags: "",
      errorSearchTags: "",
      subTitleOption: [],
      selectedService: "",
      servicesValue: "",
      appendOverlayList: [],
      appendOverlayData: [],
      getQuizQuestion: [],
      getlibrarydataImage: [],
      getlibrarydataVideo: [],
      getlibrarydataDocs: [],
      libraryimage: "",
      selectedServicelibrary: "",
      fromlibrary: false,
      displayColorPicker: [],
      overlayColor: [
        {
          rgb: {
            r: "241",
            g: "112",
            b: "19",
            a: "1"
          },
          hex: `#f17013`
        }
      ],
      timelineCheck: [],
      timelineCheckMM: [],
      timelineCheckSS: [],
      viewmedia: null,
      videotype: null,
      thumbnail: null,
      thumbnailname: "choose File",
      bannerImage: null,
      authorlist: [],
      authorName: "",
      authorId: null,
      errorAuthorName: null,
      autherSelected: [],
      customerId: localStorage.getItem("userId"),
      overrealayvaluesplit: [],
      showinnermodal: false,
      texttype: null,
      editoverlayName: "",
      overlayOptionselected: [],
      editoverlayselectedQuizQuestion: [],
      editheight: "",
      editwidth: "",
      editoverlayPreamble: "",
      colorvalue: [],
      editloadUrl: "",
      editbannerTargetUrl: "",
      alertVisible2: false,
      viewmodal: false,
      stateoverrelay: [],
      BbackendTime: {},
      rowCount: [],
      //addFile:[],
      rowContentTextValue: [],
      rowContentImageValue: [],
      rowContentVideoValue: [],
      widthInPercentage: [
        { label: "0%", value: 0 },
        { label: "8%", value: 1 },
        { label: "16%", value: 2 },
        { label: "24%", value: 3 },
        { label: "36%", value: 4 },
        { label: "42%", value: 5 },
        { label: "52%", value: 6 },
        { label: "58%", value: 7 },
        { label: "64%", value: 8 },
        { label: "72%", value: 9 },
        { label: "84%", value: 10 },
        { label: "96%", value: 11 },
        { label: "100%", value: 12 }
      ],
      ImageContentWidth: [],
      TextContentWidth: [],
      Order: [
        { label: "Image Before Text", value: 1 },
        { label: "Image After Text", value: 2 }
      ],
      OrderSeleceted: [],
      linkto: null,
      Authorname: "",
      Authoremail: "",
      Authorabout: "",
      fileName1: "choose File",
      file: "",
      alertVisible: false,
      color: "success"
    };
  }

  async componentDidMount() {
    try {
      const Author = await cmsContent.getFreedom(
        "id as value,name as label",
        "tbl_author",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (Author) {
        this.setState({ authorlist: Author.data });
      }
      const pages = await cmsContent.getFreedom(
        "id,name,status,requireLogin",
        "tbl_pages",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (pages) {
        this.setState({ data: pages.data });
      }
      const subTitle = await cmsContent.getFreedom(
        "id as value,subTitleName as label",
        "tbl_Subtitle",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (subTitle) {
        this.setState({ subtitleOption: subTitle.data });
      }
      const getQuizQuestion = await cmsContent.getFreedom(
        "id as value,quizName as label",
        "tbl_question",
        `customerid=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (getQuizQuestion) {
        this.setState({ getQuizQuestion: getQuizQuestion.data });
      }
      const getLibraryImage = await cmsContent.getLibraryImage("image");
      if (getLibraryImage) {
        this.setState({ getlibrarydataImage: getLibraryImage.data });
      }
      const getLibraryVideo = await cmsContent.getLibraryImage("video");
      if (getLibraryVideo) {
        this.setState({ getlibrarydataVideo: getLibraryVideo.data });
      }
      const getLibraryDocs = await cmsContent.getLibraryImage("docs");
      if (getLibraryDocs) {
        this.setState({ getlibrarydataDocs: getLibraryDocs.data });
      }
    } catch (error) {
      console.log(error);
    }
  }

  refreshQuiz = async () => {
    try {
      const getQuizQuestion = await cmsContent.getFreedom(
        "id as value,quizName as label",
        "tbl_question",
        `customerid=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (getQuizQuestion) {
        this.setState({ getQuizQuestion: getQuizQuestion.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  refreshSubtitle = async () => {
    try {
      const subTitle = await cmsContent.getFreedom(
        "id as value,subTitleName as label",
        "tbl_Subtitle",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (subTitle) {
        this.setState({ subtitleOption: subTitle.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

  column = [
    {
      Header: "page id",
      accessor: "id"
    },
    {
      Header: "page",
      accessor: "name"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "Visibility",
      accessor: "edit",
      Cell: d => this.showVisibility(d)
    },
    {
      Header: "Require Login",
      accessor: "requireLogin"
    },
    {
      Header: "change Login Status",
      accessor: "edit",
      Cell: d => this.changeLoginStatus(d)
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: d => this.Editpages(d)
    },
    {
      Header: "oveerealy_edit",
      accessor: "edit",
      Cell: d => this.Editoverelay(d)
    }
  ];

  contentHeading = [
    {
      Header: "Search By Hint",
      accessor: "hint"
    },
    {
      Header: "File Name",
      accessor: "label",
      Cell: d => this.aModalLink(d)
    },
    {
      Header: "Select",
      accessor: "edit",
      Cell: d => this.checktest(d)
    }
  ];

  Editpages = value => {
    return (
      <button className="btn btn-info" onClick={() => this.buttonvalue(value)}>
        Edit
      </button>
    );
  };

  buttonvalue = async value => {
    let id = value.original.id;

    try {
      const getPages = await cmsContent.getFreedom(
        "*",
        "tbl_pages",
        `id=${id}`
      );
      this.setState({ pageName: getPages.data[0].name });
      if (getPages.data[0].themeId == 1) {
        this.setState({ themeName: "Theme1" });

        this.setState({ themeId: getPages.data[0].themeId });
        let value = {};
        value.label = "Theme1";
        value.thmeid = getPages.data[0].themeId;
        this.setState({ themeSelected: value });
      } else if (getPages.data[0].themeId == 2) {
        this.setState({ themeName: "Theme2" });
        this.setState({ themeId: getPages.data[0].themeId });
        let value = {};
        value.label = "Theme2";
        value.thmeid = getPages.data[0].themeId;
        this.setState({ themeSelected: value });
      } else if (getPages.data[0].themeId == 3) {
        this.setState({ themeName: "Theme3" });
        this.setState({ themeId: getPages.data[0].themeId });
        let value = {};
        value.label = "Theme3";
        value.thmeid = getPages.data[0].themeId;
        this.setState({ themeSelected: value });
      }

      this.setState({ contentTitle1: getPages.data[0].contentTitle1 });
      this.setState({ content1: getPages.data[0].content1 });
      this.setState({ searchTags: getPages.data[0].searchTags });
      this.setState({ thumbnailname: getPages.data[0].thumbnail });
      this.setState({ fileName1: getPages.data[0].file });
      this.setState({ servicesValue: getPages.data[0].subTitleId });
    } catch (error) {
      console.log(error);
    }
  };

  aModalLink = id => {
    let str = id.original.label;
    let res = str.split("/");
    return (
      <a href="#" onClick={() => this.viewmedia(id.original.label)}>
        {res[2]}
      </a>
    );
  };
  viewmedia = async value => {
    this.setState({
      videotype: "",
      viewmedia: ACCESS_POINT + `/superAdmin/file?fileurl=${value}`
    });
    var that = this;
    setTimeout(function () {
      if (value.split(".")[1] === "mp4") {
        if (that.state.themeId == 2 || that.state.themeId == 3) {
          that.setState({ fileName1: value.split("/")[2] });
        }
        that.setState({ videotype: "video" });
      } else if (
        value.split(".")[1] === "jpg" ||
        value.split(".")[1] === "png" ||
        value.split(".")[1] === "jpeg"
      ) {
        if (that.state.themeId == 1) {
          that.setState({ fileName1: value.split("/")[2] });
        }
        if (that.state.themeId == 2 || that.state.themeId == 3) {
          that.setState({ thumbnailname: value.split("/")[2] });
        }
        if (that.state.themeId == 2) {
          that.setState({ fileName2: value.split("/")[2] });
        }

        that.setState({ videotype: "image" });
      } else {
        that.setState({ videotype: null });
      }
    }, 500);
  };

  contentVideoHeading = [
    {
      Header: "Search",
      accessor: "hint"
    },
    {
      Header: "labels",
      accessor: "label",
      Cell: d => this.aModalLink(d)
    },
    {
      Header: "values",
      accessor: "edit",
      Cell: d => this.checktestvideo(d)
    }
  ];
  checktestvideo = id => {
    return (
      <button
        type="button"
        className="btn btn-info"
        data-dismiss="modal"
        onClick={() =>
          this.setState({ file2: id.original.label, fromlibrary: true })
        }
      >
        Select
      </button>
    );
  };
  checktest = id => {
    return (
      <button
        type="button"
        className="btn btn-info"
        data-dismiss="modal"
        onClick={() =>
          this.setState({ file1: id.original.label, fromlibrary: true })
        }
      >
        Select
      </button>
    );
  };

  showVisibility = id => {
    return (
      <button
        type="button"
        className="btn btn-info"
        onClick={() => this.updateVisibility(id)}
      >
        Hide/Show
      </button>
    );
  };

  changeLoginStatus = id => {
    return (
      <button
        type="button"
        class="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '10px' }}
        onClick={() => this.updateLogin(id)}
      >
        Change Login Status
      </button>
    );
  };

  updateLogin = async value => {
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };
    if (newData.requireLogin === "yes") {
      newData.requireLogin = "no";
    } else {
      newData.requireLogin = "yes";
    }
    const id = newData.id;
    const data = previousData.filter(value => value.id !== id);
    data.splice(index, 0, newData);
    this.setState({ data });
    try {
      const result = await cmsContent.updateLoginStatus(newData);
      if (result) {
        this.setState({ data: data });
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };

  updateVisibility = async value => {
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };

    if (newData.status === "show") {
      newData.status = "hide";
    } else {
      newData.status = "show";
    }
    const id = newData.id;
    const data = previousData.filter(value => value.id !== id);
    data.splice(index, 0, newData);
    this.setState({ data });
    try {
      const result = await cmsContent.updatePage(newData);
      if (result) {
        this.setState({ data: data });
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };

  handlechange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleTheme = selectedOption => {
    if (selectedOption.value == 5) {
      console.log(selectedOption.value);
      this.setState({
        showAddPage: false
      });
    }
    this.setState({
      themeSelected: selectedOption,
      themeId: selectedOption.value,
      themeName: selectedOption.label
    });
  };

  handleSubtitle = selectedOption => {
    let selectedValue = "";
    const value = selectedOption.map(
      values => (selectedValue += `${values.value},`)
    );
    selectedValue = selectedValue.replace(/,\s*$/, "");
    this.setState({
      selectedService: selectedOption,
      servicesValue: selectedValue
    });
  };

  authorChange = selectedOption => {
    this.setState({
      autherSelected: selectedOption,
      authorName: selectedOption.label,
      authorId: selectedOption.value
    });
  };

  selectImage1 = e => {
    this.setState({ file1: e.target.files[0], fromlibrary: false });
    this.setState({ fileName1: e.target.files[0].name });
  };

  selectImage2 = e => {
    this.setState({ file2: e.target.files[0] });
    this.setState({ fileName2: e.target.files[0].name });
  };

  selectthumbnail = e => {
    this.setState({
      thumbnail: e.target.files[0],
      thumbnailname: e.target.files[0].name
    });
  };

  addBanner = (e, index) => {
    const { appendOverlayList } = this.state;
    this.setState({
      bannerImage: e.target.files[0],
      addBannerName: e.target.files[0].name
    });
  };

  submitCategory = async e => {
    e.preventDefault();
    const {
      pageName,
      themeId,
      content2,
      file1,
      file2,
      contentTitle1,
      contentTitle2,
      editorState,
      searchTags,
      servicesValue,
      appendOverlayList,
      fromlibrary,
      overlayColor,
      thumbnail,
      bannerImage,
      authorId,
      fileName1,
      thumbnailname,
      authorName,
      linkto,
      customerId,
      checked
    } = this.state;
    let editorContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    if (!authorId) {
      this.setState({ errorAuthorName: "Select Author" });
      return false;
    } else if (!pageName) {
      this.setState({ errorAuthorName: null, errorPageName: "Add Page Name" });
      return false;
    } else if (!themeId) {
      this.setState({ errorPageName: "", errorThemeId: "Select Theme" });
      return false;
    } else {
      if (!contentTitle1) {
        this.setState({
          errorThemeId: "",
          errorContentTitle1: "Enter Content Title"
        });
        return false;
      } else if (!editorContent) {
        this.setState({
          errorContentTitle1: "",
          errorContent1: "Enter Content"
        });
        return false;
      } else if (!searchTags) {
        this.setState({
          errorContentTitle1: "",
          errorSearchTags: "Enter Search Tags"
        });
        return false;
      } else if (!file1) {
        this.setState({
          errorSearchTags: "",
          errorFile1: "Select File to Upload"
        });
        return false;
      } else if (parseInt(themeId) === 3) {
        // if (!servicesValue) {
        //   this.setState({ errorFile1: '', errorsubTitleSelected: 'Select subtitles' });
        //   return false;
        // }
      } else if (parseInt(themeId) === 2) {
        if (!contentTitle2) {
          this.setState({
            errorsubTitleSelected: "",
            errorFile1: "",
            errorContentTitle2: "Enter Content Title"
          });
          return false;
        } else if (!content2) {
          this.setState({
            errorContentTitle2: "",
            errorContent2: "Enter Content"
          });
          return false;
        } else if (!file2) {
          this.setState({
            errorContent2: "",
            errorFile2: "Select File to Upload"
          });
          return false;
        }
      }
      this.setState({
        errorsubTitleSelected: "",
        errorFile2: "",
        btnDisable: false
      });

      const formData = new FormData();
      formData.append("name", pageName.trim());
      formData.append("themeId", themeId);
      formData.append("contentTitle1", contentTitle1.trim());
      formData.append("contentTitle2", contentTitle2.trim());
      formData.append("content1", editorContent);
      formData.append("content2", content2);
      if (!checked) {
        formData.append("show", 0);
      } else {
        formData.append("show", checked);
      }
      if (fromlibrary) {
        formData.append("file", file1);
      } else {
        formData.append("file1", file1);
      }
      formData.append("file2", file2);
      formData.append("searchTags", searchTags.trim());
      if (servicesValue) {
        formData.append("subTitleId", servicesValue);
      }
      if (bannerImage) {
        formData.append("bannerImage", bannerImage);
      }
      if (linkto) {
        formData.append("linkto", linkto);
      }
      formData.append("videoOverlay", JSON.stringify(appendOverlayList));
      formData.append("status", "show");
      formData.append("fromlibrary", fromlibrary);
      formData.append("overlayColor", JSON.stringify(overlayColor));
      formData.append("thumbnail", thumbnail);
      formData.append("authorid", authorId);
      formData.append("customerId", customerId);
      // console.log([...formData]);
      let today = new Date();
      var dateformat = today.getDate();

      if (today.getDate() < 10) {
        dateformat = "0" + today.getDate();
      }
      //(yy-mm-dd)
      // let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + dateformat;
      let date =
        today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + dateformat;
      formData.append("Date", date);

      try {
        const themeInsert = await http.post(
          ACCESS_POINT + `/cmsContent/tbl_pages`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => {
              this.setState({
                uploadPercentage: parseInt(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                )
              });
              setTimeout(() => this.setState({ uploadPercentage: 0 }), 10000);
              ///10000
            }
          }
        );
        if (themeInsert) {
          let valueArray = {};
          valueArray.name = pageName;
          valueArray.status = "show";
          const newData = [valueArray, ...this.state.data];
          this.setState({
            pageName: "",
            themeId: "",
            themeSelected: [],
            themeName: "",
            content1: "",
            contentTitle1: "",
            file1: "",
            content2: "",
            contentTitle2: "",
            file2: "",
            alertVisible: true,
            btnDisable: false,
            fromlibrary: false,
            fromlibraryerror: "",
            authorName: null,
            authorId: null,
            fileName1: null,
            thumbnail: null,
            thumbnailname: null,
            searchTags: "",
            selectedService: null,
            editorState: "",
            autherSelected: null,
            data: newData
          });
          setTimeout(() => this.setState({ alertVisible: false }), 3000);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  onDismiss = () => {
    this.setState({ alertVisible: false });
    this.setState({ alertVisible2: false });
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };
  checkcontent = () => {
    if (!this.state.file1) {
      this.setState({ fromlibraryerror: "Select File To Import" });
    } else {
      this.setState({ fromlibraryerror: "" });
    }
  };

  appendOverlayList = () => {
    const { appendOverlayList } = this.state;
    appendOverlayList.push({
      overlayName: null,
      overlayType: null,
      duration: null,
      overlayColor: [
        {
          rgb: {
            r: "241",
            g: "112",
            b: "19",
            a: "1"
          },
          hex: `#f17013`
        }
      ],
      quizId: null,
      bannerImage: null,
      bannerTargetUrl: null,
      loadUrl: null,
      width: null,
      height: null
    });
    this.setState({ appendOverlayList });
  };

  json_refill = (e, type, index) => {
    const { appendOverlayList } = this.state;
    appendOverlayList[index][type] = e.target.value;
    this.setState({ appendOverlayList });
  };

  timelineJson = (e, type, index) => {
    const {
      appendOverlayList,
      timelineCheck,
      timelineCheckMM,
      timelineCheckSS
    } = this.state;
    let newValue = null;
    let oldValue = null;

    if (type === "hh") {
      if (timelineCheck.length > 0 && timelineCheck[index]) {
        timelineCheck.map(val => {
          if (val.index === index) {
            let previousHour = parseInt(timelineCheck[index].hh);
            let newHour = parseInt(e.target.value);
            timelineCheck[index].hh = e.target.value;
            if (newHour > previousHour) {
              newHour = newHour - previousHour;
              newValue = newHour * 60 * 60;

              if (this.state.texttype) {
                //console.log('hh-mm-ss-2');
              } else {
                if (parseInt(appendOverlayList[index]["duration"]) > 0) {
                  oldValue = appendOverlayList[index]["duration"];

                  newValue = newValue + parseInt(oldValue);
                }
              }
            } else {
              newHour = previousHour - newHour;
              newValue = newHour * 60 * 60;

              if (this.state.texttype) {
                newValue =
                  newValue +
                  parseInt(timelineCheckMM[0].mm) * 60 +
                  parseInt(timelineCheckSS[0].mm);
              } else {
                if (parseInt(appendOverlayList[index]["duration"]) > 0) {
                  oldValue = appendOverlayList[index]["duration"];
                  newValue = parseInt(oldValue) - newValue;
                }
                appendOverlayList[index]["duration"] = newValue;
              }
            }
          }
        });
      } else {
        let timeLineJson = {};
        timeLineJson.index = index;
        timeLineJson.hh = e.target.value;
        timelineCheck.push(timeLineJson);
        this.setState({ timelineCheck });
        newValue = e.target.value * 60 * 60;

        if (this.state.texttype) {
          newValue =
            e.target.value +
            parseInt(timelineCheckMM[0].mm) * 60 +
            parseInt(timelineCheckSS[0].mm);
        } else {
          if (parseInt(appendOverlayList[index]["duration"]) > 0) {
            oldValue = appendOverlayList[index]["duration"];
            newValue = parseInt(newValue) + parseInt(oldValue);
          }
          appendOverlayList[index]["duration"] = newValue;
        }
      }
    } else if (type === "mm") {
      if (timelineCheckMM.length > 0 && timelineCheckMM[index]) {
        timelineCheckMM.map(val => {
          if (val.index === index) {
            let previousHour = parseInt(timelineCheckMM[index].mm);
            let newHour = parseInt(e.target.value);
            timelineCheckMM[index].mm = e.target.value;
            if (newHour > previousHour) {
              newHour = newHour - previousHour;
              newValue = newHour * 60;
              if (this.state.texttype) {
                this.setState({ timelineCheckMM: this.state.timelineCheckMM });
              } else {
                if (parseInt(appendOverlayList[index]["duration"]) > 0) {
                  oldValue = appendOverlayList[index]["duration"];
                  newValue = newValue + parseInt(oldValue);
                }
              }
            } else {
              if (this.state.texttype) {
              } else {
                newHour = previousHour - newHour;
                newValue = newHour * 60;
                if (parseInt(appendOverlayList[index]["duration"]) > 0) {
                  oldValue = appendOverlayList[index]["duration"];
                  newValue = parseInt(oldValue) - newValue;
                }

                appendOverlayList[index]["duration"] = newValue;
              }
            }
          }
        });
      } else {
        let timeLineJson = {};
        timeLineJson.index = index;
        timeLineJson.mm = e.target.value;
        timelineCheckMM.push(timeLineJson);
        this.setState({ timelineCheckMM });

        newValue = e.target.value * 60;
        if (this.state.texttype) {
          newValue = parseInt(this.state.timelineCheckSS[0].mm) + newValue;
        } else {
          if (parseInt(appendOverlayList[index]["duration"]) > 0) {
            oldValue = appendOverlayList[index]["duration"];
            newValue = parseInt(newValue) + parseInt(oldValue);
          }
          appendOverlayList[index]["duration"] = newValue;
        }
      }
    } else {
      if (timelineCheckSS.length > 0 && timelineCheckSS[index]) {
        timelineCheckSS.map(val => {
          if (val.index === index) {
            let previousHour = parseInt(timelineCheckSS[index].mm);
            let newHour = parseInt(e.target.value);

            timelineCheckSS[index].mm = e.target.value;
            if (newHour > previousHour) {
              newHour = newHour - previousHour;
              newValue = newHour;
              if (this.state.texttype) {
                this.setState({ timelineCheckSS: this.state.timelineCheckSS });
              } else {
                if (parseInt(appendOverlayList[index]["duration"]) > 0) {
                  oldValue = appendOverlayList[index]["duration"];
                  newValue = newValue + parseInt(oldValue);
                }
              }
            } else {
              newHour = previousHour - newHour;
              newValue = newHour;
              if (this.state.texttype) {
                this.setState({ timelineCheckSS: timelineCheckSS[0].mm });
              } else {
                if (parseInt(appendOverlayList[index]["duration"]) > 0) {
                  oldValue = appendOverlayList[index]["duration"];
                  newValue = parseInt(oldValue) - newValue;
                }
                appendOverlayList[index]["duration"] = newValue;
              }
            }
          }
        });
      } else {
        let timeLineJson = {};
        timeLineJson.index = index;
        timeLineJson.mm = e.target.value;
        timelineCheckSS.push(timeLineJson);
        this.setState({ timelineCheckSS });
        newValue = e.target.value;

        if (this.state.texttype) {
          {
            console.log(this.state.timelineCheckSS);

            /* update ss*/
          }
        } else {
          if (parseInt(appendOverlayList[index]["duration"]) > 0) {
            oldValue = appendOverlayList[index]["duration"];
            newValue = parseInt(newValue) + parseInt(oldValue);
          }
          appendOverlayList[index]["duration"] = newValue;
        }
      }
    }
  };

  appendOverlayData = (appendValue, index) => {
    const { appendOverlayData, appendOverlayList } = this.state;
    const appendJson = {
      index,
      appendValue: appendValue.label
    };
    let indexValue = appendOverlayData[index];
    if (indexValue) {
      if (appendOverlayData.length > index) {
        if (parseInt(indexValue.index) !== parseInt(index)) {
          appendOverlayData.splice(indexValue.index, 1);
        }
      }
      if (parseInt(indexValue.index) === parseInt(index)) {
        appendOverlayData.splice(index, 1);
      }
    }
    appendOverlayData.splice(index, 0, appendJson);
    let appendOverlayListIndex = appendOverlayList[index];
    appendOverlayListIndex.overlayType = appendOverlayData[index].appendValue;
    appendOverlayListIndex.surveyUrl = null;
    appendOverlayListIndex.loadUrl = null;
    appendOverlayListIndex.bannerTargetUrl = null;
    appendOverlayListIndex.quizId = null;
    appendOverlayListIndex.width = null;
    appendOverlayListIndex.height = null;
    appendOverlayListIndex.bannerImage = null;
    this.setState({ appendOverlayData, appendOverlayList });
  };

  appendOverlayQuiz = (appendValue, name, index) => {
    const { appendOverlayList } = this.state;
    appendOverlayList[index][name] = appendValue.value;
    this.setState({ appendOverlayList });
  };

  handleClick = index => {
    const { displayColorPicker } = this.state;
    displayColorPicker[index] = true;
    this.setState({ displayColorPicker });
  };

  handleClose = index => {
    const { displayColorPicker } = this.state;
    displayColorPicker[index] = false;
    this.setState({ displayColorPicker });
  };

  addOverlayColor = (color, index) => {
    const { overlayColor, appendOverlayList } = this.state;
    if (this.state.texttype) {
      // overlayColor[index]['overlayColor'] = color;
      let overlayColorvalue = {};
      overlayColorvalue.overlayColor = color;
      // let rgb = this.state.overlayColor[index].color;

      overlayColor[index] = color;
      this.setState({ overlayColor });
    } else {
      overlayColor[index] = color;
      appendOverlayList[index]["overlayColor"] = color;
      this.setState({ overlayColor, appendOverlayList });
    }
  };

  Editoverelay = value => {
    return (
      <button
        className="btn btn-info"
        onClick={() => this.overelaybuttonvalue(value)}
        type="button"
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#exampleModal"
      >
        Editoverelay
      </button>
    );
  };
  overelaybuttonvalue = async value => {
    let id = value.original.id;

    const getPages = await cmsContent.getPagesData(id);

    if (getPages.data[0].overlay != null) {
      this.state = { pageidstate: "" };
      this.setState({ pageidstate: getPages.data[0].id });
      let overrealayvaluecomma = getPages.data[0].overlay;
      let overrealayvalue = overrealayvaluecomma.replace(/,\s*$/, "");

      if (getPages.data[0].themeId == 2 || getPages.data[0].themeId == 3) {
        this.state = { overrealayvaluescolumnaddvalue: "" };
        this.setState({
          overrealayvaluescolumnaddvalue: getPages.data[0].themeId
        });
        const { overrealayvaluesplit } = this.state;
        this.setState({ overrealayvaluesplit: getPages.data });
      }

      const getSingleConditionedValue = await cmsContent.getSingleConditionedValue(
        "tbl_overrelay",
        "id",
        overrealayvalue
      );

      this.setState({ overrealayvaluesplit: getSingleConditionedValue.data });
    }
  };

  overrealayvaluescolumn = [
    {
      Header: "overrelay",
      accessor: "overlaytype",
      Cell: d => this.overlaytype(d)
    }
  ];

  overlaytype = value => {
    var count = 0;
    //var style = '<table> <tr><td><td/> </tr> </table>';
    let addarray = [];
    if (value.original["overlaytype"] == "Quiz") {
      addarray.push(value.original["overlaytype"]);
    }
    let arrray = [];
    if (
      value.original["overlaytype"] != undefined ||
      value.original["overlayName"] != undefined
    ) {
      arrray.push(
        <button className="btn btn-info" onClick={() => this.passvalue(value)}>
          {"( " + value.original["overlaytype"] + ")"}{" "}
          {value.original["overlayName"]}Edit
        </button>
      );
    }

    return (
      <React.Fragment>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-7">
            <Alert
              className="badge-content"
              color="success"
              isOpen={this.state.alertVisible2}
              toggle={this.onDismiss}
            >
              Added Successfully
            </Alert>
          </div>
          <div className="col-sm-3" />
        </div>
        {arrray}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span>
          <button className="btn btn-success" onClick={() => this.pageadd(1)}>
            {addarray}
            {"ADD Overelay"}
          </button>
        </span>
      </React.Fragment>
      //  <button className="btn btn-info" onClick={() => this.passvalue(value)}>
      //   {'( ' + value.original['overlaytype'] + ')'} {value.original['overlayName']}Edit
      // </button>
    );
    {
      /*
      <table>
        <tr>
          <th style={{ padding: 8 ,textAlign:'left'}}>Company</th>
          <th style={{ padding: 8 ,textAlign:'left'}}>Contact</th>
          <th style={{ padding: 8, textAlign: 'left' }}>Country</th>
          <th style={{ padding: 8, textAlign: 'left' }}>Country</th>
        </tr>
        <tr>
          <td style={{ padding: 8, textAlign: 'left' }}>Alfreds Futterkiste</td>
           <td style={{ padding: 8, textAlign: 'left' }}>Alfreds Futterkiste</td>
          <td style={{ padding: 8, textAlign: 'left' }}>Alfreds Futterkiste</td>
          <td style={{ padding: 8, textAlign: 'left' }}>Alfreds Futterkiste</td>
        </tr>

      </table>
      */
    }
  };

  removeAppend = async (index) => {
    console.log("enter");
    const { appendOverlayList } = this.state;
    console.log(appendOverlayList, "before");
    appendOverlayList.splice(index, 1);
    console.log(appendOverlayList, "after");
    await this.setState({ appendOverlayList });
  };

  pageadd = async () => {
    this.state = { viewquiz: "" };
    await this.setState({
      viewmodal: true,
      overrealayvaluesplit: [],
      viewquiz: "Quiz"
    });
  };
  /*state true false set */
  textfunction = a => {
    this.setState({ showinnermodal: false, overrealayvaluesplit: [] });
  };

  passvalue = value => {
    this.setState({ showinnermodal: true });

    let overlayName = value.original.overlayName;
    this.setState({ editoverlayName: overlayName });
    let selectedValue = {};
    selectedValue.label = value.original.overlaytype;
    selectedValue.value = value.original.overlaytype;

    let editoverlayselectedQuizQuestion = {};
    editoverlayselectedQuizQuestion.label = this.getValueFromArrays(
      value.original.quizId,
      this.state.getQuizQuestion
    );
    editoverlayselectedQuizQuestion.value = value.original.quizId;

    let height = value.original.height;
    let width = value.original.width;
    let duration = value.original.duration;

    let hours = Math.floor(duration / 3600);

    let minutes = Math.floor((duration - 3600 * hours) / 60);

    let seconds = Math.floor(duration % 60);
    let selectsec = {};
    let selectmin = {};
    let hourselectvalue = {};
    //timelineCheck

    //select.mm='';
    if (seconds < 10) {
      seconds = "0" + seconds.toString();
      selectsec.mm = seconds;
    }
    if (minutes < 10) {
      minutes = "0" + minutes.toString();
      selectmin.mm = minutes;
    }
    if (hours < 10) {
      hours = "0" + hours.toString();
      hourselectvalue.mm = hours;
    }

    selectsec.mm = seconds;
    selectsec.index = 0;
    selectmin.index = 0;
    selectmin.mm = minutes;
    hourselectvalue.mm = hours;
    hourselectvalue.index = 0;

    let selectsstime = [];
    let selecmmttime = [];
    let selechrttime = [];
    let BbackendTime = {};

    selectsstime.push(selectsec);
    selecmmttime.push(selectmin);
    selechrttime.push(hourselectvalue);
    BbackendTime.hh = hourselectvalue.mm;
    BbackendTime.mm = selectmin.mm;
    BbackendTime.ss = selectsec.mm;

    if (JSON.parse(value.original.OverLay_Color).rgb != undefined) {
      this.state.overlayColor[0].rgb = JSON.parse(
        value.original.OverLay_Color
      ).rgb;
    }
    //this.setState({})
    // this.setState({overlayColor:value.original.overlayColor})
    let editoverlayPreamble = value.original.overlayPreamble;

    let editloadUrl = value.original.loadUrl;
    let editbannerTargetUrl = value.original.bannerTargetUrl;
    let bannerImage = value.original.bannerImage;

    this.setState({
      texttype: value,
      overlayOptionselected: selectedValue,
      editoverlayselectedQuizQuestion: editoverlayselectedQuizQuestion,
      editheight: height,
      editwidth: width,
      editoverlayPreamble: editoverlayPreamble,
      timelineCheckSS: selectsstime,
      timelineCheckMM: selecmmttime,
      timelineCheckHR: selechrttime,
      editbannerTargetUrl: editbannerTargetUrl,
      editloadUrl: editloadUrl,
      bannerImage: bannerImage,
      BbackendTime
    });
  };

  getValueFromArrays = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.label;
        } else {
          return "-";
        }
      }
    } else {
      return "-";
    }
  };

  overlayOptionselected = e => {
    this.setState({ overlayOptionselected: e });
  };

  editoverlayselectedQuizQuestion = e => {
    let select = {};
    select.label = e.label;
    select.value = e.value;

    this.setState({ editoverlayselectedQuizQuestion: select });
    //this.setstate({editoverlayselectedQuizQuestion:e})
  };

  changecontentwidth = e => {
    this.setState({ editwidth: e.target.value });
  };
  changecontentheight = e => {
    this.setState({ editheight: e.target.value });
  };
  editoverlayPreamblevalue = e => {
    this.setState({ editoverlayPreamble: e.target.value });
  };
  editoverlayName = e => {
    this.setState({ editoverlayName: e.target.value });
  };
  editurlvalue = e => {
    this.setState({ editloadUrl: e.target.value });
  };

  editimageuplodefunction = e => {
    this.state = { editaddbanneruploadfile: [] };
    //bannerImage
    this.setState({
      editaddbanneruploadfile: e.target.files[0],
      bannerImage: e.target.files[0].name
    });
    //this.setState({ bannerImage: e.target.files[0].name });
  };
  editbannerTargetUrl = e => {
    this.setState({ editbannerTargetUrl: e.target.value });
  };
  timelineEditJson = async (val, type) => {
    val = val.target.value;
    let BbackendTime = this.state.BbackendTime;
    if (type === "mm") {
      BbackendTime.mm = val;
    } else if (type === "ss") {
      BbackendTime.ss = val;
    } else if (type === "hh") {
      BbackendTime.hh = val;
    }
    await this.setState({ BbackendTime });
  };
  updatePageall = async () => {
    const formData = new FormData();
    let Time = this.state.BbackendTime;
    Time.hh = Time.hh ? Number(Time.hh) * 3600 : 0;
    Time.mm = Time.mm ? Number(Time.mm) * 60 : 0;
    Time.ss = Time.ss ? Number(Time.ss) : 0;

    formData.append("overlayName", this.state.editoverlayName);
    formData.append("quizId", this.state.editoverlayselectedQuizQuestion.value);
    formData.append("width", this.state.editwidth);
    formData.append("height", this.state.editheight);
    formData.append("overlayPreamble", this.state.editoverlayPreamble);
    formData.append(
      "OverLay_Color",
      JSON.stringify(this.state.overlayColor[0])
    );
    formData.append("overlayType", this.state.texttype.original.overlaytype);
    formData.append("duration", Time.hh + Time.ss + Time.mm);
    formData.append("loadUrl", this.state.editloadUrl);
    formData.append("bannerTargetUrl", this.state.editbannerTargetUrl);

    if (this.state.editaddbanneruploadfile) {
      formData.append("bannerImage", this.state.editaddbanneruploadfile);
    } else {
      formData.append("bannerImage", this.state.bannerImage);
    }

    //parseInt(this.state.timelineCheckSS)

    // formData.append('index', this.state.texttype.index);
    // formData.append('pageid', this.state.pageidstate);
    // formData.append('tablename', 'tbl_overrelay');

    try {
      let results = await cmsContent.addpageupdate(
        "tbl_overrelay",
        formData,
        this.state.texttype.index,
        this.state.pageidstate,
        this.state.texttype.original.id
      );

      if (results) {
        this.setState({ alertVisible2: true });
      }
    } catch (error) {
      console.log(error);
    }
  };

  addmodelinsert = async () => {
    const formData = new FormData();
    formData.append("overlayColor", JSON.stringify(this.state.overlayColor));
    formData.append(
      "videoOverlay",
      JSON.stringify(this.state.appendOverlayList)
    );
    if (this.state.bannerImage) {
      formData.append("bannerImage", this.state.bannerImage);
    }

    let results = await cmsContent.addpagemodelwindow(
      "tbl_overrelay",
      formData,
      this.state.pageidstate
    );
    //addpagemodelwindow
    if (results) {
      this.setState({ alertVisible2: true });
    }
  };
  /*addfile=()=>{
    const addFile = this.state.addFile;
    console.log(addFile);
  }*/
  addRow = () => {
    const rowCount = this.state.rowCount;
    console.log(rowCount);
    rowCount.push(this.state.rowCount.length);
    this.setState({ rowCount });
  };
  rowContentImageValue = (d, i) => {
    let rowContentImageValue = this.state.rowContentImageValue;
    rowContentImageValue[i] = d.target.files[0];
    this.setState({ rowContentImageValue });
  };
  rowContentVideoValue = (d, i) => {
    let rowContentVideoValue = this.state.rowContentVideoValue;
    rowContentVideoValue[i] = d.target.files[0];
    this.setState({ rowContentVideoValue });
  };
  rowContentTextValue = (d, i) => {
    let rowContentTextValue = this.state.rowContentTextValue;
    rowContentTextValue[i] = d;
    this.setState({ rowContentTextValue });
  };
  ImageContentWidth = (d, i) => {
    let ImageContentWidth = this.state.ImageContentWidth;
    let widthInPercentage = this.state.widthInPercentage;
    let TextContentWidth = this.state.TextContentWidth;
    ImageContentWidth[i] = d;
    TextContentWidth[i] = widthInPercentage.filter(
      ival => ival.value === 12 - d.value
    )[0];
    // let ImageContentWidth = this.state.ImageContentWidth;
    // let widthInPercentage = this.state.widthInPercentage;
    // let TextContentWidth = this.state.TextContentWidth;
    // TextContentWidth[i] = d;
    // ImageContentWidth[i] = widthInPercentage.filter(ival => ival.value === (12 - d.value))[0]
    // console.log(ImageContentWidth[0])
    // let num = d.label.split("%");
    // num = parseInt(num);
    // let Mul = 100 - num;
    // let newNum = Mul / 2;
    // console.log(newNum);

    // let arr = [];
    // arr[i] = widthInPercentage.filter(ival => ival.label == newNum + "%")[0];
    this.setState({ ImageContentWidth, TextContentWidth });
  };

  TextContentWidth = (d, i) => {
    console.log(d);

    let ImageContentWidth = this.state.ImageContentWidth;
    let widthInPercentage = this.state.widthInPercentage;
    let TextContentWidth = this.state.TextContentWidth;
    TextContentWidth[i] = d;
    ImageContentWidth[i] = widthInPercentage.filter(
      ival => ival.value === 12 - d.value
    )[0];
    // console.log(ImageContentWidth[0])
    // let num = d.label.split("%");
    // num = parseInt(num);
    // let Mul = 100 - num;
    // console.log(Mul);
    // let newNum = Mul / 2;
    // if (newNum % 2 == 0) {
    //   // even
    // } else {
    //   // odd
    // }

    // let arr = [];
    // arr[i] = widthInPercentage.filter(ival => ival.label == newNum + "%")[0];
    this.setState({
      ImageContentWidth,
      TextContentWidth
    });
  };
  OrderSeleceted = (d, i) => {
    let OrderSeleceted = this.state.OrderSeleceted;
    OrderSeleceted[i] = d;
    this.setState({ OrderSeleceted });
  };
  rowContent = i => {
    return (
      <div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">{`Text-${i + 1}`}</label>
          </div>
          <div className="col-sm-5">
            <Editor
              editorState={this.state.rowContentTextValue[i]}
              wrapperClassName="wrapper-className"
              editorClassName="editor-className editor-border"
              toolbarClassName="toolbar-className"
              onEditorStateChange={d => this.rowContentTextValue(d, i)}
              toolbar={{
                options: [
                  "inline",
                  "blockType",
                  "fontSize",
                  "fontFamily",
                  "list",
                  "link"
                ]
              }}
              value={this.state.rowContentTextValue[i]}
            />
          </div>
          <div className="col-sm-3">
            <label htmlFor="exampleInputEmail1">{`Text width-${i + 1}`}</label>
            <SingleSelect
              options={this.state.widthInPercentage}
              handleChange={d => this.TextContentWidth(d, i)}
              selectedService={this.state.TextContentWidth[i]}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">{`Image-${i + 1}`}</label>
          </div>
          <div className="col-sm-5">
            <input
              type="file"
              className="custom-file-input"
              id="customFile"
              onChange={d => this.rowContentImageValue(d, i)}
              accept="image/*"
            />
            <label className="custom-file-label" htmlFor="customFileThumbnail">
              {this.state.rowContentImageValue[i] &&
                this.state.rowContentImageValue[i].name
                ? this.state.rowContentImageValue[i].name
                : "select file to upload"}
            </label>

            {/* <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1" style={{marginLeft:'-179px',marginTop:'26px'}}>{`video-${i + 1}`}</label>

            </div> */}

            <span>
              {/* <LoginModal
                buttonTitle="Select Image from Library"
                title="Select Image"
                id="customer1"
                bodyText={<FromLibrary datas={this.state.getlibrarydataImage} columnHeading={this.contentVideoHeading} imgsource={this.state.viewmedia} type={this.state.videotype} />}
                atagLink={true}
              /> */}
            </span>
          </div>

          <div className="col-sm-3">
            <label htmlFor="exampleInputEmail1">{`Image width-${i + 1}`}</label>
            <SingleSelect
              options={this.state.widthInPercentage}
              handleChange={d => this.ImageContentWidth(d, i)}
              selectedService={this.state.ImageContentWidth[i]}
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">{`Video-${i + 1}`}</label>
          </div>
          <div className="col-sm-5">
            <input
              type="file"
              className="custom-file-input"
              id="customFile"
              onChange={d => this.rowContentVideoValue(d, i)}
              accept="video/*"
            />
            <label className="custom-file-label" htmlFor="customFileThumbnail">
              {this.state.rowContentVideoValue[i] &&
                this.state.rowContentVideoValue[i].name
                ? this.state.rowContentVideoValue[i].name
                : "select file to upload"}
            </label>

            {/* <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1" style={{marginLeft:'-179px',marginTop:'26px'}}>{`video-${i + 1}`}</label>

            </div> */}

            <span>
              {/* <LoginModal
                buttonTitle="Select Image from Library"
                title="Select Image"
                id="customer1"
                bodyText={<FromLibrary datas={this.state.getlibrarydataImage} columnHeading={this.contentVideoHeading} imgsource={this.state.viewmedia} type={this.state.videotype} />}
                atagLink={true}
              /> */}
            </span>
          </div>

          <div className="col-sm-3">
            {/* <label htmlFor="exampleInputEmail1">{`video width-${i + 1}`}</label>
            <SingleSelect
              options={this.state.widthInPercentage}
              handleChange={d => this.ImageContentWidth(d, i)}
              selectedService={this.state.ImageContentWidth[i]}
            /> */}
          </div>
        </div>

        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">{`Order-${i + 1}`}</label>
          </div>
          <div className="col-sm-5">
            <SingleSelect
              options={this.state.Order}
              handleChange={d => this.OrderSeleceted(d, i)}
              selectedService={this.state.OrderSeleceted[i]}
            />
          </div>
          <div className="col-sm-3" />
        </div>
      </div>
    );
  };
  theme4Submit = async () => {
    let state = this.state;

    if (!state.authorId) {
      this.setState({ errorAuthorName: "Select Author" });
      return false;
    } else if (!state.pageName) {
      this.setState({ errorAuthorName: null, errorPageName: "Add Page Name" });
      return false;
    } else if (!state.themeId) {
      this.setState({ errorPageName: "", errorThemeId: "Select Theme" });
      return false;
    }
    let finallContent = [];
    state.rowCount.map((ival, i) => {
      let finallInnerContent = {};
      finallInnerContent.imagewidth = state.ImageContentWidth[i].value;
      finallInnerContent.text = state.rowContentTextValue[i]
        ? draftToHtml(
          convertToRaw(state.rowContentTextValue[i].getCurrentContent())
        )
        : null;
      finallInnerContent.textwidth = state.TextContentWidth[i].value;
      finallInnerContent.order = state.OrderSeleceted[i].value;
      finallContent.push(finallInnerContent);
    });
    let today = new Date();
    let dateformat = today.getDate();
    if (today.getDate() < 10) {
      dateformat = "0" + today.getDate();
    }
    let date =
      today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + dateformat;

    const formData = new FormData();
    formData.append("name", state.pageName.trim());
    formData.append("themeId", state.themeId);
    formData.append("authorid", state.authorId);
    formData.append("sheetContent", JSON.stringify(finallContent));
    console.log();
    formData.append("Date", date);
    formData.append("status", "show");
    formData.append("customerId", state.customerId);
    if (!state.checked) {
      formData.append("show", 0);
    } else {
      formData.append("show", state.checked);
    }
    let q = 0;
    state.rowContentImageValue.map((ival, i) => {
      formData.append(`Imgfile${i}`, ival);
    });
    // rowContentVideoValue
    state.rowContentVideoValue.map((ival, i) => {
      formData.append(`Vidfile${i}`, ival);
    });

    try {
      const themeInsert = await http.post(
        ACCESS_POINT + `/cmsContent/insertThemeFour/tbl_pages`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          },
          onUploadProgress: progressEvent => {
            this.setState({
              uploadPercentage: parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            });
            setTimeout(() => this.setState({ uploadPercentage: 0 }), 10000);
            ///10000
          }
        }
      );
      if (themeInsert) {
        //
      }
    } catch (error) {
      console.log(error);
    }
  };
  CheckBox = async () => {
    var check = document.getElementById("ShowChecked").checked;
    if (check === true) {
      await this.setState({ checked: 1 });
    } else if (check === false) {
      await this.setState({ checked: 0 });
    }
  };
  authorname = e => {
    let name = e.target.value;
    this.setState({ Authorname: name });
  };
  authoremail = e => {
    let email = e.target.value;
    this.setState({ Authoremail: email });
  };

  aboutvalue = e => {
    let about = e.target.value;
    this.setState({ Authorabout: about });
  };

  authorimage = e => {
    let filename = e.target.files[0];
    let filepath = e.target.files[0].name;

    this.setState({ file: filename });
    this.setState({ filename: filepath });
  };
  addnewAuthor = async () => {
    const { Authorname, Authoremail } = this.state;

    if (!Authorname) {
      this.setState({ errorAuthorname: "Please Add Authorname" });
      return false;
    } else if (!Authoremail) {
      this.setState({ errorAuthorname: "", errorAuthoremail: "Please Add Authoremail" });
    }

    let username = this.state.Authorname;
    let useremail = this.state.Authoremail;
    let userabout = this.state.Authorabout;
    let userfile = this.state.file;
    let active = "active";
    let customerId = this.state.customerId;

    let value = this.state.editvalue;
    const formData = new FormData();
    formData.append("name", username.trim());
    formData.append("email", useremail.trim());
    formData.append("about", userabout.trim());
    formData.append("image", userfile);
    formData.append("status", active);
    formData.append("customerId", customerId);

    let categoryArray = {};

    try {
      const authorInsert = await cmsContent.authorinsert(
        formData,
        "tbl_author"
      );

      if (authorInsert) {
        let valueArray = {};
        valueArray.id = authorInsert.data.insertId;
        valueArray.name = this.state.Authorname;
        valueArray.email = this.state.Authoremail;
        valueArray.about = this.state.Authorabout;
        const newData = [valueArray, ...this.state.data];

        this.setState({
          data: newData,
          editvalue: "",
          editid: null,
          error: null,
          button: "Submit",
          index: null,
          alertVisible: true,
          Authorname: "",
          Authoremail: "",
          Authorabout: "",
          filename: "",
          errorAuthorname: null,
          errorAuthoremail: null
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    const {
      errorPageName,
      errorThemeId,
      errorContentTitle1,
      errorContentTitle2,
      errorContent1,
      errorContent2,
      errorFile1,
      errorFile2,
      alertVisible,
      btnDisable,
      editorState,
      uploadPercentage,
      errorSearchTags,
      subtitleOption,
      selectedService,
      errorsubTitleSelected,
      appendOverlayList,
      appendOverlayData,
      getlibrarydataImage,
      getlibrarydataVideo,
      getlibrarydataDocs,
      selectedServicelibrary,
      fromlibraryerror,
      viewmedia,
      imgorvid,
      thumbnail,
      rowCount,
      errorAuthorName
    } = this.state;

    let appendData = [];
    appendOverlayData.map((appendOverlayData, index) => {
      // console.log(appendOverlayData);
      let rgb = {
        r: "241",
        g: "112",
        b: "19",
        a: "1"
      };
      if (this.state.overlayColor[index]) {
        rgb = this.state.overlayColor[index].rgb;
      }
      const styles = reactCSS({
        default: {
          color: {
            width: "36px",
            height: "14px",
            borderRadius: "2px",
            background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
          },
          swatch: {
            padding: "5px",
            background: "#fff",
            borderRadius: "1px",
            boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
            display: "inline-block",
            cursor: "pointer"
          },
          popover: {
            position: "absolute",
            zIndex: "2"
          },
          cover: {
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px"
          }
        }
      });
      let hoursSlect = [];
      for (let i = 0; i <= 12; i++) {
        hoursSlect.push(<option>{i <= 9 ? `0${i}` : i}</option>);
      }
      let minuteSlect = [];
      for (let i = 0; i <= 60; i++) {
        minuteSlect.push(<option>{i <= 9 ? `0${i}` : i}</option>);
      }
      let secondsSlect = [];
      for (let i = 0; i <= 60; i++) {
        secondsSlect.push(<option>{i <= 9 ? `0${i}` : i}</option>);
      }
      appendData.push(
        <div>
          {(appendOverlayData.appendValue === "Survey" ||
            appendOverlayData.appendValue === "Load Url") && (
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">Destination URL</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    name={`overlayName${appendOverlayData.index}`}
                    placeholder="Enter Url"
                    className="form-control"
                    onChange={e => this.json_refill(e, `loadUrl`, index)}
                  />
                </div>
                <div className="col-sm-3" />
              </div>
            )}
          {appendOverlayData.appendValue === "Ad Banner" && (
            <div>
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">Upload Image</label>
                </div>
                <div className="col-sm-5 custom-file">
                  <input
                    type="file"
                    className="custom-file-input "
                    id="customFile"
                    onChange={e => this.addBanner(e, index)}
                  />
                  <label
                    className="custom-file-label lblcross"
                    htmlFor="customFile"
                  >
                    {this.state.addBannerName}
                  </label>
                </div>
                <div className="col-sm-3" />
              </div>
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">Target Url</label>
                </div>
                <div className="col-sm-5">
                  <input
                    type="text"
                    name={`overlayImageTargetUrl${appendOverlayData.index}`}
                    placeholder="Enter Target Url"
                    className="form-control"
                    onChange={e =>
                      this.json_refill(e, `bannerTargetUrl`, index)
                    }
                  />
                </div>
                <div className="col-sm-3" />
              </div>
            </div>
          )}
          {appendOverlayData.appendValue === "Quiz" && (
            <div>
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-2">
                  <label htmlFor="exampleInputEmail1">Select Quiz</label>
                </div>
                <div className="col-sm-5">
                  <SingleSelect
                    name="quiz"
                    options={this.state.getQuizQuestion}
                    handleChange={e =>
                      this.appendOverlayQuiz(e, "quizId", index)
                    }
                  />
                </div>
                <div className="col-sm-3">
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={this.refreshQuiz}
                  >
                    Refresh
                  </button>
                </div>
              </div>
              <div className="row form-group">
                <div className="col-sm-2" />
                <div className="col-sm-1">
                  <label htmlFor="exampleInputEmail1">Width %</label>
                </div>
                <div className="col-sm-2">
                  <input
                    type="number"
                    name={`width${appendOverlayData.index}`}
                    placeholder="width"
                    className="form-control"
                    onChange={e => this.json_refill(e, `width`, index)}
                  />
                </div>
                <div className="col-sm-1" />
                <div className="col-sm-1">
                  <label htmlFor="exampleInputEmail1">Height %</label>
                </div>
                <div className="col-sm-2">
                  <input
                    type="number"
                    name={`height${appendOverlayData.index}`}
                    placeholder="width"
                    className="form-control"
                    onChange={e => this.json_refill(e, `height`, index)}
                  />
                </div>
                <div className="col-sm-3" />
              </div>
            </div>
          )}
          <div className="row form-group">
            <div className="col-sm-2" />
            <div className="col-sm-2">
              <label htmlFor="exampleInputEmail1">OverLay Preamble</label>
            </div>
            <div className="col-sm-5">
              <input
                type="test"
                name={`overlayPreamble${appendOverlayData.index}`}
                placeholder="Overlay Preamble"
                className="form-control"
                onChange={e => this.json_refill(e, `overlayPreamble`, index)}
              />
            </div>
            <div className="col-sm-3" />
          </div>
          <div className="row form-group">
            <div className="col-sm-2" />
            <div className="col-sm-2">
              <label htmlFor="exampleInputEmail1">OverLay Color</label>
            </div>
            <div className="col-sm-5">
              <div style={styles.swatch} onClick={e => this.handleClick(index)}>
                <div style={styles.color} />
              </div>
              {this.state.displayColorPicker[index] ? (
                <div style={styles.popover}>
                  <div
                    style={styles.cover}
                    onClick={e => this.handleClose(index)}
                  />
                  <SketchPicker
                    color={rgb}
                    onChange={e => this.addOverlayColor(e, index)}
                  />
                </div>
              ) : null}
            </div>
            <div className="col-sm-3" />
          </div>
          <div className="row form-group">
            <div className="col-sm-2" />
            <div className="col-sm-1">
              <label className="duration-float" htmlFor="exampleInputEmail1">
                hh
              </label>
            </div>
            <div class="col-sm-2">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                onChange={e => this.timelineJson(e, `hh`, index)}
              >
                {hoursSlect}
              </select>
            </div>
            <div className="col-sm-1">
              <label className="duration-float" htmlFor="exampleInputEmail1">
                mm
              </label>
            </div>
            <div class="col-sm-2">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                onChange={e => this.timelineJson(e, `mm`, index)}
              >
                {minuteSlect}
              </select>
            </div>
            <div className="col-sm-1">
              <label className="duration-float" htmlFor="exampleInputEmail1">
                ss
              </label>
            </div>
            <div class="col-sm-2">
              <select
                class="form-control"
                id="exampleFormControlSelect1"
                onChange={e => this.timelineJson(e, `ss`, index)}
              >
                {secondsSlect}
              </select>
            </div>
            {/* <div className="col-sm-5">
              <input type="text" name={`overlayName${appendOverlayData.index}`} placeholder="Enter Duration" className="form-control" onChange={e => this.json_refill(e, `duration`, index)} />
            </div> */}
            <div className="col-sm-3" />
          </div>
        </div>
      );
    });

    let appendOverlay = [];
    let overlayOption = [
      { label: "Quiz", value: "Quiz" },
      { label: "Survey", value: "Survey" },
      { label: "Ad Banner", value: "Ad Banner" },
      { label: "Load Url", value: "Load Url" }
    ];
    appendOverlayList.map((appendOverlayList, index) => {
      appendOverlay.push(
        <div className="quiz-align">
          <div className="row form-group">
            <div className="col-sm-2" />
            <div className="col-sm-2">
              <label htmlFor="exampleInputEmail1">Overlay Name</label>
            </div>
            <div className="col-sm-5">
              <input
                type="text"
                name={`overlayName${index}`}
                placeholder="Enter overlay Name"
                className="form-control"
                onChange={e => this.json_refill(e, `overlayName`, index)}
              />
            </div>
            <div className="col-sm-3">
              <i
                class="fa fa-times-circle"
                style={{ fontSize: "25px" }}
                onClick={() => this.removeAppend(index)}
              ></i>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-sm-2" />
            <div className="col-sm-2">
              <label htmlFor="exampleInputEmail1">Overlay Type </label>
            </div>
            <div className="col-sm-5">
              <SingleSelect
                name={`overlayType${index}`}
                options={overlayOption}
                handleChange={e => this.appendOverlayData(e, index)}
              />
            </div>
            <div className="col-sm-3" />
          </div>
          {appendData && appendData[index]}
        </div>
      );

      this.state.stateoverrelay = appendOverlay;
    });

    if (this.state.texttype) {
      {
        /* [this.state.texttype.index] replace [0] */
      }

      var rgb = this.state.overlayColor[0].rgb;

      var styles = reactCSS({
        default: {
          color: {
            width: "36px",
            height: "14px",
            borderRadius: "2px",
            background: `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
          },
          swatch: {
            padding: "5px",
            background: "#fff",
            borderRadius: "1px",
            boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
            display: "inline-block",
            cursor: "pointer"
          },
          popover: {
            position: "absolute",
            zIndex: "2"
          },
          cover: {
            position: "fixed",
            top: "0px",
            right: "0px",
            bottom: "0px",
            left: "0px"
          }
        }
      });
    }

    let hoursSlect = [];
    for (let i = 0; i <= 12; i++) {
      hoursSlect.push(<option>{i <= 9 ? `0${i}` : i}</option>);
    }
    let minuteSlect = [];
    for (let i = 0; i <= 60; i++) {
      minuteSlect.push(<option>{i <= 9 ? `0${i}` : i}</option>);
    }
    let secondsSlect = [];
    for (let i = 0; i <= 60; i++) {
      secondsSlect.push(<option>{i <= 9 ? `0${i}` : i}</option>);
    }
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            {/* */}
            <div
              class="modal fade bd-example-modal-lg"
              id="exampleModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                      test
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span
                        aria-hidden="true"
                        onClick={() => this.textfunction()}
                      >
                        &times;
                      </span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color="success"
                          isOpen={this.state.alertVisible2}
                          toggle={this.onDismiss}
                        >
                          Update Successfully
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    {this.state.viewmodal && (
                      <div>
                        {/*JSON.stringify(this.state.appendOverlayList)*/}
                        {this.state.appendOverlayList}

                        {/* {JSON.stringify(this.state.stateoverrelay)}
  {this.state.stateoverrelay} */}
                        {this.state.stateoverrelay}
                        {/* view overrelay */}

                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <button
                              type="button"
                              className="btn btn-danger btn-radius mt-25"
                              onClick={this.appendOverlayList}
                            >
                              Add Overlay
                            </button>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        {this.state.stateoverrelay && (
                          <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2" />
                            <div className="col-sm-5">
                              <button
                                type="button"
                                className="btn btn-info"
                                onClick={() => this.addmodelinsert()}
                              >
                                Newadd
                              </button>
                            </div>
                            <div className="col-sm-3" />
                          </div>
                        )}
                      </div>
                    )}

                    {!this.state.showinnermodal && !this.state.viewmodal && (
                      <Datatable
                        data={this.state.overrealayvaluesplit}
                        columnHeading={this.overrealayvaluescolumn}
                      />
                    )}

                    {this.state.showinnermodal && (
                      <button
                        type="button"
                        class="btn btn-primary "
                        onClick={() => {
                          this.setState({ showinnermodal: false });
                        }}
                      >
                        BACK
                      </button>
                    )}

                    {this.state.showinnermodal && (
                      <div className="card-body">
                        {/*console.log(this.state.texttype)*/}
                        {/* <center>{ this.state.texttype.original.overlaytype }</center>  */}
                        <center>{this.state.viewquiz}</center>
                        <br />

                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              Overlay Name{" "}
                            </label>
                          </div>
                          <div className="col-sm-7">
                            <input
                              type="text"
                              name={`overlayNames`}
                              placeholder="Enter overlay Name"
                              className="form-control"
                              value={this.state.editoverlayName}
                              onChange={e => this.editoverlayName(e)}
                            />
                          </div>
                          <div className="col-sm-1" />
                        </div>

                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              Overlay Type--{" "}
                            </label>
                          </div>
                          <div className="col-sm-7">
                            <SingleSelect
                              name={`overlayTypes`}
                              options={overlayOption}
                              selectedService={this.state.overlayOptionselected}
                              handleChange={e => this.overlayOptionselected(e)}
                            />
                          </div>
                          <div className="col-sm-1" />
                        </div>

                        {this.state.texttype.original.overlaytype ===
                          "Quiz" && (
                            <div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="exampleInputEmail1">
                                    Select Quiz
                                  </label>
                                </div>
                                <div className="col-sm-5">
                                  <SingleSelect
                                    name="quiz"
                                    options={this.state.getQuizQuestion}
                                    selectedService={
                                      this.state.editoverlayselectedQuizQuestion
                                    }
                                    handleChange={e =>
                                      this.editoverlayselectedQuizQuestion(e)
                                    }
                                  />
                                </div>

                                <div className="col-sm-3">
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={this.refreshQuiz}
                                  >
                                    Refresh
                                  </button>
                                </div>
                              </div>

                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-1">
                                  <label htmlFor="exampleInputEmail1">
                                    Width %
                                  </label>
                                </div>
                                <div className="col-sm-2">
                                  <input
                                    type="number"
                                    name={`widths`}
                                    value={this.state.editwidth}
                                    placeholder="width"
                                    className="form-control"
                                    onChange={e => this.changecontentwidth(e)}
                                  />
                                </div>
                                <div className="col-sm-1" />
                                <div className="col-sm-1">
                                  <label htmlFor="exampleInputEmail1">
                                    Height %
                                  </label>
                                </div>
                                <div className="col-sm-2">
                                  <input
                                    type="number"
                                    name={`heights`}
                                    value={this.state.editheight}
                                    placeholder="width"
                                    className="form-control"
                                    onChange={e => this.changecontentheight(e)}
                                  />
                                </div>
                                <div className="col-sm-3" />
                              </div>
                            </div>
                          )}

                        {this.state.texttype.original.overlaytype ===
                          "Ad Banner" && (
                            <div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="exampleInputEmail1">
                                    Upload Image
                                  </label>
                                </div>
                                <div className="col-sm-5 custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input "
                                    id="customFile"
                                    onChange={e =>
                                      this.editimageuplodefunction(e)
                                    }
                                  />
                                  <label
                                    className="custom-file-label lblcross"
                                    htmlFor="customFile"
                                  >
                                    {this.state.bannerImage}
                                  </label>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="exampleInputEmail1">
                                    Target Url
                                  </label>
                                </div>
                                <div className="col-sm-5">
                                  <input
                                    type="text"
                                    name={`overlayImageTargetUrl`}
                                    placeholder="Enter Target Url"
                                    className="form-control"
                                    value={this.state.editbannerTargetUrl}
                                    onChange={e => this.editbannerTargetUrl(e)}
                                  />
                                </div>
                                <div className="col-sm-3" />
                              </div>
                            </div>
                          )}

                        {(this.state.texttype.original.overlaytype ===
                          "Survey" ||
                          this.state.texttype.original.overlaytype ===
                          "Load Url") && (
                            <div className="row form-group">
                              <div className="col-sm-2" />
                              <div className="col-sm-2">
                                <label htmlFor="exampleInputEmail1">
                                  Destination URL
                                </label>
                              </div>
                              <div className="col-sm-5">
                                {/*JSON.stringify(this.state.editloadUrl)*/}
                                <input
                                  type="text"
                                  name={`overlayName`}
                                  placeholder="Enter Url"
                                  value={this.state.editloadUrl}
                                  className="form-control"
                                  onChange={e => this.editurlvalue(e)}
                                />
                              </div>
                              <div className="col-sm-3" />
                            </div>
                          )}

                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              OverLay Preamble
                            </label>
                          </div>
                          <div className="col-sm-5">
                            <input
                              type="test"
                              name={`overlayPreambles}`}
                              placeholder="Overlay Preamble"
                              className="form-control"
                              value={this.state.editoverlayPreamble}
                              onChange={e => this.editoverlayPreamblevalue(e)}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>

                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              OverLay Color-
                            </label>
                          </div>
                          <div className="col-sm-5">
                            <div
                              style={styles.swatch}
                              onClick={e =>
                                this.handleClick(this.state.texttype.index)
                              }
                            >
                              <div style={styles.color} />
                            </div>
                            {this.state.displayColorPicker[
                              this.state.texttype.index
                            ] ? (
                              <div style={styles.popover}>
                                <div
                                  style={styles.cover}
                                  onClick={e =>
                                    this.handleClose(this.state.texttype.index)
                                  }
                                />
                                {/*[this.state.texttype.index] replace [0] */}
                                {/*JSON.stringify(this.state.overlayColor[0].rgb)*/}
                                <SketchPicker
                                  color={this.state.overlayColor[0].rgb}
                                  onChange={e => this.addOverlayColor(e, [0])}
                                />
                              </div>
                            ) : null}
                          </div>
                          <div className="col-sm-3" />
                        </div>

                        {/*console.log(this.state.texttype.index)*/}
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-1">
                            <label
                              className="duration-float"
                              htmlFor="exampleInputEmail1"
                            >
                              hh
                            </label>
                          </div>
                          <div class="col-sm-2">
                            <select
                              class="form-control"
                              id="exampleFormControlSelect2"
                              onChange={e => this.timelineEditJson(e, `hh`)}
                              value={this.state.BbackendTime.hh}
                            >
                              {hoursSlect}
                            </select>
                          </div>
                          <div className="col-sm-1">
                            <label
                              className="duration-float"
                              htmlFor="exampleInputEmail1"
                            >
                              mm
                            </label>
                          </div>
                          <div class="col-sm-2">
                            <select
                              value={this.state.BbackendTime.mm}
                              class="form-control"
                              id="exampleFormControlSelect2"
                              onChange={e => this.timelineEditJson(e, `mm`)}
                            >
                              {minuteSlect}
                            </select>
                          </div>

                          <div className="col-sm-1">
                            <label
                              className="duration-float"
                              htmlFor="exampleInputEmail1"
                            >
                              ss
                            </label>
                          </div>
                          <div class="col-sm-2">
                            <select
                              value={this.state.BbackendTime.ss}
                              class="form-control"
                              id="exampleFormControlSelect2"
                              onChange={e => this.timelineEditJson(e, `ss`)}
                            >
                              {secondsSlect}
                            </select>
                          </div>
                          {/* <div className="col-sm-5">
	              <input type="text" name={`overlayName${appendOverlayData.index}`} placeholder="Enter Duration" className="form-control" onChange={e => this.json_refill(e, `duration`, index)} />
	            </div> */}
                          <div className="col-sm-3" />
                        </div>
                        {/* */}

                        <div className="row form-group">
                          <div className="col-sm-4" />

                          <div className="col-sm-5">
                            <button
                              type="submit"
                              class="btn btn-primary"
                              value="upadate"
                              onClick={() => {
                                this.updatePageall();
                              }}
                            >
                              {"Update"}
                            </button>
                          </div>
                          <div className="col-sm-3" />
                        </div>

                        {/* */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* */}
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Add Page</h3>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color="success"
                          isOpen={alertVisible}
                          toggle={this.onDismiss}
                        >
                          Page Added Successfully
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="authorName">Author Name</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          name="authorName"
                          options={this.state.authorlist}
                          handleChange={this.authorChange}
                          selectedService={this.state.autherSelected}
                        />
                      </div>
                      <div className="col-sm-3">
                        <LoginModal
                          buttonTitle="Add Author"
                          id="addAuthor"
                          bodyText={
                            <div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-7">
                                  <Alert
                                    className="badge-content"
                                    color={this.state.color}
                                    isOpen={alertVisible}
                                    toggle={this.onDismiss}
                                  >
                                    Author Master Added
                                  </Alert>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-4">
                                  <label htmlFor="Authorname">
                                    Author Name
                                  </label>
                                </div>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="Authorname"
                                    name="Authorname"
                                    placeholder="name"
                                    value={this.state.Authorname}
                                    onChange={e => this.authorname(e)}
                                  />
                                </div>
                                <div className="col-sm-1" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4" />
                                <span className="error-show ">
                                  {this.state.errorAuthorname}
                                </span>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-4">
                                  <label htmlFor="Authoremail">
                                    Author Email
                                  </label>
                                </div>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="Authoremail"
                                    name="Authoremail"
                                    placeholder=" email"
                                    value={this.state.Authoremail}
                                    onChange={e => this.authoremail(e)}
                                  />
                                </div>
                                <div className="col-sm-1" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4" />
                                <span className="error-show ">
                                  {this.state.errorAuthoremail}
                                </span>
                              </div>

                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-4">
                                  <label htmlFor="Authormobile">
                                    Author about
                                  </label>
                                </div>
                                <div className="col-sm-6">
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    id="Authorabout"
                                    name="Authorabout"
                                    placeholder="Enter about"
                                    value={this.state.Authorabout}
                                    onChange={e => this.aboutvalue(e)}
                                  />
                                </div>
                                <div className="col-sm-1" />
                              </div>

                              <div className="row form-group">
                                <div className="col-sm-4" />
                                <span className="error-show ">
                                  {this.state.errorAuthorabout}
                                </span>
                              </div>

                              <div className="row form-group">
                                <div className="col-sm-1" />
                                <div className="col-sm-4">
                                  <label htmlFor="authorimage">
                                    Upload Image
                                  </label>
                                </div>
                                <div className="col-sm-6 custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="authorimage"
                                    onChange={e => this.authorimage(e)}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="authorimage"
                                  >
                                    {this.state.filename}
                                  </label>
                                </div>

                                {/* {this.state.editimage && <img src={this.state.editimage} alt="image" />}
                                 */}
                                <div className="col-sm-1" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" />
                                <div className="col-sm-5">
                                  <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                      this.addnewAuthor();
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>

                                <div className="col-sm-3" />
                              </div>
                            </div>
                          }
                        />
                        <div class="form-check">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            id="ShowChecked"
                            // checked=""
                            onClick={this.CheckBox}
                          />
                          <label class="form-check-label" for="materialChecked">
                            Show
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorAuthorName}</span>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">Page Name</label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          id="pageName"
                          name="pageName"
                          placeholder="Enter Page Name"
                          value={this.state.pageName}
                          onChange={this.handlechange}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorPageName}</span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">Select Theme</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect
                          options={options}
                          handleChange={this.handleTheme}
                          selectedService={this.state.themeSelected}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">{errorThemeId}</span>
                    </div>
                    {(parseInt(this.state.themeId) === 1 ||
                      parseInt(this.state.themeId) === 2 ||
                      parseInt(this.state.themeId) === 3) && (
                        <div>
                          <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="exampleInputEmail1">
                                Content Title
                              </label>
                            </div>
                            <div className="col-sm-5">
                              <textarea
                                type="text"
                                className="form-control"
                                id="contentTitle1"
                                name="contentTitle1"
                                placeholder="Enter Content Title"
                                value={this.state.contentTitle1}
                                onChange={this.handlechange}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div>
                          <div className="row form-group">
                            <div className="col-sm-4" />
                            <span className="error-show ">
                              {errorContentTitle1}
                            </span>
                          </div>

                          <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="exampleInputEmail1">
                                Content 1
                              </label>
                            </div>
                            <div className="col-sm-5">
                              <Editor
                                editorState={editorState}
                                wrapperClassName="wrapper-className"
                                editorClassName="editor-className editor-border"
                                toolbarClassName="toolbar-className"
                                onEditorStateChange={this.onEditorStateChange}
                                toolbar={{
                                  options: [
                                    "inline",
                                    "blockType",
                                    "fontSize",
                                    "fontFamily",
                                    "list",
                                    "link"
                                  ]
                                }}
                                value={this.state.editorState}
                              />
                              {/* <textarea type="text" className="form-control" id="content1" name="content1" placeholder="Enter First Content" onChange={this.handlechange} /> */}
                            </div>
                            <div className="col-sm-3" />
                          </div>
                          <div className="row form-group">
                            <div className="col-sm-4" />
                            <span className="error-show ">{errorContent1}</span>
                          </div>

                          <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              {" "}
                              <label htmlFor="exampleInputEmail1">
                                Search Tags
                              </label>
                            </div>
                            <div className="col-sm-5">
                              <textarea
                                type="text"
                                className="form-control"
                                id="searchTags"
                                name="searchTags"
                                placeholder="Enter Search Tags"
                                value={this.state.searchTags}
                                onChange={this.handlechange}
                              />
                            </div>
                            <div className="col-sm-3" />
                          </div>
                          <div className="row form-group">
                            <div className="col-sm-4" />
                            <span className="error-show ">{errorSearchTags}</span>
                          </div>

                          {parseInt(this.state.themeId) === 1 ? (
                            <div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="exampleInputEmail1">
                                    Upload Image
                                  </label>
                                </div>
                                <div className="col-sm-5 custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    onChange={this.selectImage1}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="customFile"
                                  >
                                    {this.state.fileName1}
                                  </label>
                                  <span>
                                    <LoginModal
                                      buttonTitle="Select Image from Library"
                                      title="Select Image"
                                      id="customer"
                                      maxwidth="modal-xl"
                                      bodyText={
                                        <FromLibrary
                                          datas={getlibrarydataImage}
                                          columnHeading={this.contentHeading}
                                          imgsource={viewmedia}
                                          type={this.state.videotype}
                                        />
                                      }
                                      atagLink={true}
                                    />
                                  </span>
                                </div>
                                <div className="col-sm-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Direct to Url"
                                    onChange={d =>
                                      this.setState({ linkto: d.target.value })
                                    }
                                    value={this.state.linkto}
                                  />
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4" />
                                <span className="error-show ">{errorFile1}</span>

                                <div
                                  className="col-sm-2"
                                  style={{
                                    marginTop: "23px",
                                    marginLeft: "-183px"
                                  }}
                                >
                                  <label htmlFor="exampleInputEmail1">
                                    Upload Video
                                  </label>
                                </div>
                                <div
                                  className="col-sm-5 custom-file"
                                  style={{ marginTop: "25px" }}
                                >
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    onChange={this.selectImage2}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="customFile"
                                  >
                                    {this.state.fileName2}
                                  </label>
                                  <span>
                                    {
                                      <LoginModal
                                        buttonTitle="Select Video from Library"
                                        title="Select Video"
                                        id="customer"
                                        bodyText={
                                          <FromLibrary
                                            datas={getlibrarydataVideo}
                                            columnHeading={this.contentHeading}
                                            type={this.state.videotype}
                                            imgsource={viewmedia}
                                          />
                                        }
                                        atagLink={true}
                                      />
                                    }
                                  </span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4" />
                                <span className="error-show ">{errorFile1}</span>
                              </div>

                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="exampleInputEmail1">
                                    Select Thumbnail
                                  </label>
                                </div>

                                <div className="col-sm-5">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    onChange={this.selectthumbnail}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="customFileThumbnail"
                                  >
                                    {this.state.thumbnailname}
                                  </label>
                                  <span>
                                    <LoginModal
                                      buttonTitle="Select Image from Library"
                                      title="Select Image"
                                      id="customer1"
                                      bodyText={
                                        <FromLibrary
                                          datas={getlibrarydataImage}
                                          columnHeading={this.contentVideoHeading}
                                          imgsource={viewmedia}
                                          type={this.state.videotype}
                                        />
                                      }
                                      atagLink={true}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="exampleInputEmail1">
                                    Upload Video
                                  </label>
                                </div>
                                <div className="col-sm-5 custom-file">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    onChange={this.selectImage1}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="customFile"
                                  >
                                    {this.state.fileName1}
                                  </label>
                                  <span>
                                    {
                                      <LoginModal
                                        buttonTitle="Select Video from Library"
                                        title="Select Video"
                                        id="customer"
                                        bodyText={
                                          <FromLibrary
                                            datas={getlibrarydataVideo}
                                            columnHeading={this.contentHeading}
                                            type={this.state.videotype}
                                            imgsource={viewmedia}
                                          />
                                        }
                                        atagLink={true}
                                      />
                                    }
                                  </span>
                                </div>
                                <div className="col-sm-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Direct to Url"
                                    onChange={d =>
                                      this.setState({ linkto: d.target.value })
                                    }
                                    value={this.state.linkto}
                                  />
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4" />
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4" />
                                <span className="error-show ">{errorFile1}</span>
                              </div>

                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="exampleInputEmail1">
                                    Select Thumbnail
                                  </label>
                                </div>

                                <div className="col-sm-5">
                                  <input
                                    type="file"
                                    className="custom-file-input"
                                    id="customFile"
                                    onChange={this.selectthumbnail}
                                  />
                                  <label
                                    className="custom-file-label"
                                    htmlFor="customFileThumbnail"
                                  >
                                    {this.state.thumbnailname}
                                  </label>
                                  <span>
                                    <LoginModal
                                      buttonTitle="Select Image from Library"
                                      title="Select Image"
                                      id="customer1"
                                      bodyText={
                                        <FromLibrary
                                          datas={getlibrarydataImage}
                                          columnHeading={this.contentVideoHeading}
                                          imgsource={viewmedia}
                                          type={this.state.videotype}
                                        />
                                      }
                                      atagLink={true}
                                    />
                                  </span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4" />
                                <span className="error-show ">
                                  {errorsubTitleSelected}
                                </span>
                              </div>

                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="exampleInputEmail1">
                                    Select Subtitles
                                  </label>
                                </div>
                                <div className="col-sm-5">
                                  <MultiSelect
                                    options={subtitleOption}
                                    handleChange={this.handleSubtitle}
                                    selectedService={selectedService}
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={this.refreshSubtitle}
                                  >
                                    Refresh
                                  </button>
                                </div>
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-4" />
                                <span className="error-show ">
                                  {errorsubTitleSelected}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    {parseInt(this.state.themeId) === 2 ||
                      parseInt(this.state.themeId) === 3 ? (
                      <div>
                        {appendOverlay.length > 0 && appendOverlay}
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2" />
                          <div className="col-sm-5">
                            <button
                              type="button"
                              className="btn btn-danger btn-radius mt-25"
                              onClick={this.appendOverlayList}
                            >
                              Add Overlay
                            </button>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                      </div>
                    ) : null}
                    {parseInt(this.state.themeId) === 2 && (
                      <div>
                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              Content Title
                            </label>
                          </div>
                          <div className="col-sm-5">
                            <textarea
                              type="text"
                              className="form-control"
                              id="contentTitle2"
                              name="contentTitle2"
                              placeholder="Enter Second Content"
                              onChange={this.handlechange}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">
                            {errorContentTitle2}
                          </span>
                        </div>

                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              Content 2
                            </label>
                          </div>
                          <div className="col-sm-5">
                            <textarea
                              type="text"
                              className="form-control"
                              id="content2"
                              name="content2"
                              placeholder="Enter Second Content"
                              onChange={this.handlechange}
                            />
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">{errorContent2}</span>
                        </div>

                        <div className="row form-group">
                          <div className="col-sm-2" />
                          <div className="col-sm-2">
                            <label htmlFor="exampleInputEmail1">
                              Upload Image
                            </label>
                          </div>
                          <div className="col-sm-5 custom-file">
                            <input
                              type="file"
                              className="custom-file-input"
                              id="customFile"
                              onChange={this.selectImage2}
                            />
                            <label
                              className="custom-file-label"
                              htmlFor="customFile"
                            >
                              {this.state.fileName2}
                            </label>
                            <span>
                              <LoginModal
                                buttonTitle="Select Image from Library"
                                title="Select Image"
                                id="customer1"
                                bodyText={
                                  <FromLibrary
                                    datas={getlibrarydataImage}
                                    columnHeading={this.contentVideoHeading}
                                    imgsource={viewmedia}
                                    type={this.state.videotype}
                                  />
                                }
                                atagLink={true}
                              />
                            </span>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <span className="error-show ">{errorFile2}</span>
                        </div>
                      </div>
                    )}

                    {/* <Theme4/> */}
                    {parseInt(this.state.themeId) === 4 ? (
                      <div>
                        {rowCount.map((ival, i) => {
                          return this.rowContent(i);
                        })}
                        <div className="row form-group">
                          <div className="col-sm-4" />
                          <div className="col-sm-5">
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={this.addRow}
                            >
                              Add Content Row
                            </button>
                          </div>
                          <div className="col-sm-3" />
                        </div>
                      </div>
                    ) : null}

                    {parseInt(this.state.themeId) === 5 && (
                      <AddTheme
                        PageName={this.state.pageName}
                        customerId={this.state.customerId}
                        authorId={this.state.authorId}
                        authorName={this.state.authorName}
                        themeId={this.state.themeId}
                      />
                    )}

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        {uploadPercentage > 0 && (
                          <Progress percentage={uploadPercentage} />
                        )}
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        {this.state.showAddPage == true && (
                          <button
                            type="button"
                            className="btn btn-primary"
                            disabled={btnDisable}
                            onClick={
                              parseInt(this.state.themeId) === 4
                                ? this.theme4Submit
                                : this.submitCategory
                            }
                          >
                            Add Page
                          </button>
                        )}
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    {this.state.data && (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default AddPage;
