import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import SingleSelect from "../../../components/Form/SingleSelect";
import { Alert } from "reactstrap";
import { ACCESS_POINT } from "../../../config";
import { Datatable } from "../../../components/Datatable";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import Userlogpdf from "./userlogpdf";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
//import Userlogpdf from './userlogpdf';

class CMSuserlog extends FormMiddleWare {
  constructor(props) {
    super(props);
    const {
      match: { params },
    } = this.props;
    this.state = {
      data: [],
      customerId: localStorage.getItem("userId"),
      pageOptions: [],
      videoptions: [],
      tabledata: [],
      users: [],
      Accessusercount: [],
      alluserviewpage: [],
      selectedstartdate: params["sd"],
      selectedenddate: params["ed"],
      disablepage: false,
      disablevideos: false,
      AllContent: [],
      userselected: params["p"],
    };
  }

  async componentWillMount() {
    try {
      const pages = await CmsContent.getFreedom(
        "id as value,name as label,file",
        "tbl_pages",
        `customerId=${this.state.customerId} and status="show" `,
        "id",
        "id DESC"
      );
      if (pages) {
        // console.log("pg", pages);
        this.setState({ pageOptions: pages.data });
      }

      // const videoptionsvalues = await CmsContent.getFreedom(
      //   'id as value,file as label',
      //   'tbl_pages',
      //   `customerId=${this.state.customerId} and file_extension="mp4"`,
      //   'id',
      //   'id DESC');
      // if (videoptionsvalues) {
      //  // console.log(videoptionsvalues.data)
      //   videoptionsvalues.data.map( video=>{
      //     //console.log(video.label.split('/')[2])
      //     video.label=video.label.split('/')[2];
      //   })
      //   this.setState({ videoptions: videoptionsvalues.data });
      // }

      const users = await CmsContent.getFreedom(
        "id as value,userName as label,email as email,mobileNumber as number",
        "tbl_user_web",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      console.log(users);
      if (users) {
        this.setState({ users: users.data });
      }

      let date = new Date();
      var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
      var day = date.getUTCDate();
      var year = date.getUTCFullYear();

      let todaydate = year + "-" + month + "-" + day;

      // console.log(todaydate);
      // console.log(this.state.selectedstartdate);
      // console.log(this.state.selectedenddate);
      let userselected = this.state.userselected;
      let dateselected = await CmsContent.getFreedom(
        "tbl_CMSuserAccessLog.*,tbl_pages.*",
        "tbl_CMSuserAccessLog,tbl_pages",
        `pageid=${userselected} and  tbl_CMSuserAccessLog.date between '${this.state.selectedstartdate}' and '${this.state.selectedenddate}'`,
        "tbl_CMSuserAccessLog.userid",
        "1"
      );

      if (dateselected) {
        this.setState({ tabledata: dateselected.data });
        //console.log(dateselected);
        const pageview = await CmsContent.getFreedom(
          "tbl_CMSuserAccessLog.*,tbl_pages.file",
          "tbl_CMSuserAccessLog,tbl_pages",
          `tbl_CMSuserAccessLog.pageid =${userselected} and tbl_pages.id=${userselected} and tbl_CMSuserAccessLog.customerId=${this.state.customerId} `,
          "userId",
          "id DESC"
        );

        const alluserviewpage = await CmsContent.getFreedom(
          "tbl_CMSuserAccessLog.*,tbl_pages.file",
          "tbl_CMSuserAccessLog,tbl_pages",
          `tbl_CMSuserAccessLog.pageid =${userselected} and tbl_pages.id=${userselected} and tbl_CMSuserAccessLog.customerId=${this.state.customerId} `,
          "id",
          "id DESC"
        );
        //console.log(alluserviewpage.data)
        this.setState({ alluserviewpage: alluserviewpage.data });
        //console.log(pageview)
        let AllContent = [];
        pageview.data.map((ival, i) => {
          if (
            AllContent.filter((jval) => jval.userId === ival.userId).length ===
            0
          ) {
            AllContent.push({
              userId: ival.userId,
              pageid: ival.pageid

            });
          }
        });

        AllContent.map((ival, i) => {
          AllContent[i].innerContent = [];
          this.state.alluserviewpage.map((jval, j) => {
            //console.log(jval);
            if (jval.userId == ival.userId) {
              AllContent[i].innerContent.push({
                jval,
              });
            }
          });
        });

        //Count
        AllContent.map((ival, i) => {
          AllContent[i].count = 0;
          this.state.alluserviewpage.map((jval, j) => {
            let count = 0;
            count = AllContent[i].innerContent.length;
            if (jval.userId == ival.userId) {
              AllContent[i].count = count;
            }
          });
        });
        AllContent.map((ival, i) => {
          AllContent[i].username = [];
          AllContent[i].pagename = [];
          AllContent[i].email = [];
          AllContent[i].mobile = [];
          this.state.users.map((kval, v) => {
            if (ival.userId == kval.value) {

              AllContent[i].username = kval.label
              AllContent[i].email = kval.email
              AllContent[i].mobile = kval.number
            }
            this.state.pageOptions.map((pval, n) => {
              if (ival.pageid == pval.value) {
                AllContent[i].pagename = pval.label
              }
            });
          });
        });
        // let final = [];
        // this.state.pageOptions.map((pval, v) => {
        //   this.state.tabledata.map((kval, v) => {
        //     if (kval.pageid === pval.value) {
        //       final.push({ pagename: pval.label });
        //     }
        //   });
        // });
        // console.log("pg", final);
        if (AllContent) {
          const headers = ["SI.NO", "Username", "pageName", "Count", "Email", "Mobile Number"];
          const excelHead = [
            { label: "UserName", key: "username" },
            { label: "PageName", key: "pagename" },
            { label: "Count", key: "count" },
            { label: "Email", key: "email" },
            { label: "Mobile Number", key: "mobile" }
          ];

          console.log(AllContent);
          this.setState({
            AllContent: AllContent,
            page: "second",
            //CSV: cell,
            excel: AllContent,
            headers,
            excelHead,
          });
        }
        //console.log(this.state.tabledata);
      }
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      id: "userId",
      Header: "Username",
      accessor: (d) => this.getValueFromArray(d.userId, this.state.users),
      Cell: (d) => this.getValueFromArray(d.original.userId, this.state.users),
    },
    {
      id: "pageid",
      Header: "Page name",
      accessor: (d) => this.getValueFromArray(d.pageid, this.state.pageOptions),
      Cell: (d) =>
        this.getValueFromArray(d.original.pageid, this.state.pageOptions),
    },
    {
      Header: "Count",
      Cell: (d) => this.countview(d),
      //Cell:d=>this.countview(d.original)
    },
    {
      Header: "Email",
      accessor: "email",
      Cell: (d) => this.checkEmail(d.original.userId),
    },
    {
      Header: "MobileNumber",
      accessor: "mobile",
      Cell: (d) => this.checkNumber(d.original.userId),
    },
    {
      Header: "Details",
      Cell: (d) => this.viewusers(d.original),
    },
  ];
  checkEmail = (d) => {
    let email = [];
    this.state.users.map((ival, m) => {
      if (d == ival.value) {
        email.push(ival.email);
      }
    });
    return email;
  };
  checkNumber = (d) => {
    let number = [];
    this.state.users.map((ival, m) => {
      if (d == ival.value) {
        number.push(ival.number);
      }
    });
    return number;
  };
  //   dateformatchange =(datevalue) =>{
  //     let dateview=datevalue.split('-');

  // let hourssplit=dateview[2].split(':')[0].split('');
  // let Mins=dateview[2].split(':');
  //   return(<span> {hourssplit[0]+hourssplit[1]+'-'+dateview[1]+'-'+dateview[0]+' '+hourssplit[3]+hourssplit[4]+':'+Mins[1]+':'+Mins[2]}</span>)
  //   }
  //value
  countview = (value) => {
    let count = 0;
    let AllContent = this.state.AllContent;
    AllContent.map((item, i) => {
      if (value.original.userId === item.userId) {
        count = item.innerContent.length;
      }
    });

    return <span> {count}</span>;
    //return(<span  onClick={() => window.open("cmsallog" + "/" + value.userId+'/'+value.pageid, "_blank")} >view</span>)
  };

  viewusers = (value) => {
    return (
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            `../../../cmsallog/${value.userId}/${value.pageid}`,
            "_blank"
          )
        }
      >
        View
      </button>
    );
  };
  pageSelect = async (e) => {
    this.setState({ pageSelected: e, disablevideos: true });

    //this.setState({tabledata :pageview.data})
    ///console.log(this.state.tabledata.length)
    //   console.log(`customerId=${this.state.customerId} and pageid =${e.value}`)
  };

  vidoeschange = async (e) => {
    this.setState({ videoSelected: e, disablepage: true });

    //this.setState({tabledata :videoSelectedpage.data})
    //console.log(this.state.tabledata);
  };

  fromDateSelect = (e) => {
    this.setState({ selectedstartdate: e.target.value });
    console.log(this.state.selectedstartdate);
  };

  endDateSelect = async (e) => {
    this.setState({ selectedenddate: e.target.value });
    console.log(e.target.value);
  };
  resetdisablevalue = () => {
    this.setState({
      disablevideos: false,
      disablepage: false,
      disableuser: false,
      tabledata: [],
      videoSelected: "",
      pageSelected: "",
      userselect: "",
      selectedstartdate: "",
      selectedenddate: "",
      data: []
    });
  };

  submitdata = async () => {
    let userselected = "";

    if (this.state.videoSelected) {
      userselected = this.state.videoSelected.value;
    } else {
      userselected = this.state.pageSelected.value;
    }

    let date = new Date();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
    var day = date.getUTCDate();
    var year = date.getUTCFullYear();

    let todaydate = year + "-" + month + "-" + day;

    console.log(todaydate);
    console.log(this.state.selectedstartdate);
    console.log(this.state.selectedenddate);

    // if(todaydate > this.state.selectedstartdate)
    // {
    //   this.setState({startdateerror :'Please not selected previous date'})
    // }
    // else
    // {
    //   this.setState({startdateerror :''})
    // }
    // if(todaydate > this.state.selectedenddate)
    // {
    //   this.setState({endateerror :'Please not selected previous date'})
    // }
    // else
    // {
    //   this.setState({endateerror :''})
    // }

    console.log(userselected);
    let dateselected = await CmsContent.getFreedom(
      "tbl_CMSuserAccessLog.*,tbl_pages.*",
      "tbl_CMSuserAccessLog,tbl_pages",
      `pageid=${userselected} and  tbl_CMSuserAccessLog.date between '${this.state.selectedstartdate}' and '${this.state.selectedenddate}'`,
      "tbl_CMSuserAccessLog.userid",
      "1"
    );

    if (dateselected) {
      this.setState({ tabledata: dateselected.data });
      // console.log(dateselected);
      const pageview = await CmsContent.getFreedom(
        "tbl_CMSuserAccessLog.*,tbl_pages.file",
        "tbl_CMSuserAccessLog,tbl_pages",
        `tbl_CMSuserAccessLog.pageid =${userselected} and tbl_pages.id=${userselected} and tbl_CMSuserAccessLog.customerId=${this.state.customerId} `,
        "userId",
        "id DESC"
      );

      const alluserviewpage = await CmsContent.getFreedom(
        "tbl_CMSuserAccessLog.*,tbl_pages.file",
        "tbl_CMSuserAccessLog,tbl_pages",
        `tbl_CMSuserAccessLog.pageid =${userselected} and tbl_pages.id=${userselected} and tbl_CMSuserAccessLog.customerId=${this.state.customerId} `,
        "id",
        "id DESC"
      );
      //console.log(alluserviewpage.data)
      this.setState({ alluserviewpage: alluserviewpage.data });

      let AllContent = [];
      pageview.data.map((ival, i) => {
        if (
          AllContent.filter((jval) => jval.userId === ival.userId).length === 0
        ) {
          AllContent.push({
            userId: ival.userId,
          });
        }
      });

      AllContent.map((ival, i) => {
        AllContent[i].innerContent = [];
        this.state.alluserviewpage.map((jval, j) => {
          //console.log(jval);
          if (jval.userId == ival.userId) {
            AllContent[i].innerContent.push({
              jval,
            });
          }
        });
      });

      this.setState({ AllContent: AllContent });
    }
  };

  render() {
    const { formAlert, formAlertdelete, categoryOptions } = this.state;
    console.log(this.state.tabledata.length)
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-sm-4"> </div>
                      <div className="col-sm-4">
                        <h3>Total access log</h3>
                      </div>
                      <div className="col-sm-4"> </div>
                    </div>


                    <br></br>
                    {this.state.tabledata.length && (
                      <span>

                        Total of {Object.keys(this.state.tabledata).length} records
                      </span>)}
                    {/* <span>
                      {this.state.tabledata.length && (
                        Total of {this.state.tabledata.length} records)}
                    </span> */}
                    <br></br>
                    {this.state.excel && this.state.excel.length > 0 && (

                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <Userlogpdf
                            header={this.state.headers}
                            data={this.state.excel}
                            page={this.state.page}
                          />
                        }
                        fileName="sample.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    )}
                    {this.state.excel && this.state.excel.length > 0 && (
                      <CSVLink
                        data={this.state.excel}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          EXCEL
                        </button>
                      </CSVLink>
                    )}
                    <br></br>
                    <br></br>

                    {this.state.tabledata && (

                      <Datatable
                        data={this.state.tabledata}
                        columnHeading={this.column}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default CMSuserlog;
