import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const BatchPDF = ({ header, data , data1 , type,title}) => {

  

    let pro = [];
  if(type == "batch"){
    data.map((item,i)=>{
      data1.map((element,key)=>{
        if(item.programid == element.value){
          pro.push({program : element.label , batch : item.name , si : i+1});
        }
      })
    })
  }else if (type = "resource"){
    data.map((item,i)=>{
      data1.map((element,key)=>{
        if(item.domainid == element.value){
          pro.push({program : element.label , batch : item.name , si : i+1});
        }
      })
    })
  }
    

  return (
    <Document>
      <Page style={styles.body}>
      <View style={styles.title}>
         <Text>{title}</Text>
        </View>
        <View style={styles.table}>
        <View style={styles.tableRow}>
            {header &&
              header.map(value => {
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value}</Text>
                  </View>
                );
              })}
          </View>

          {pro &&
            pro.map((value,index) => {
              return (
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.si}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.batch}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.program}</Text>
                  </View>
                </View>
              );
            })}
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop:'5px'
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    textAlign:"center"
  },
  tableColHeader: {
    width: '16.6%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: '33.3%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 'auto',
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: 'auto',
    margin: 5,
    fontSize: 10
  },
  title:{
    fontSize:19,
    fontWeight:"bold",
    marginLeft:'38%',
  
    }
});

export default BatchPDF;
