import React from "react";
import { Redirect } from "react-router-dom";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import CmsContent from "../../../MiddleWare/CmsContent";
import CarouselMulti from 'react-multi-carousel';
import EmblaCarouselReact from 'embla-carousel-react'
import 'react-multi-carousel/lib/styles.css';
import { ACCESS_POINT } from "../../../config";
import DifuzaNextUser from "../Difuza/DifuzaNextUser";

const responsive = {
   desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};



class DifuzaUser extends FormMiddleWare {
    constructor(props) {
      super(props);
      this.state = {
        groupId : "",
        data:[],
        type : '',
        url:"",
        title:"",
        content_name:[],
        url:[],
        sample:"",
        show : false,
        show1:false,
        currentSlide:0,
      };
    }
  
async componentWillMount() {

  try{
    let userlog = JSON.parse(localStorage.getItem("userlog"));   
    const result = await CmsContent.getUserJson(userlog.id);
    if(result){
      this.setState({data:result.data ,userId : result.data.userid });
  //  console.log(result.data)
    //  userId : result.data.userid
    this.process(result.data.body);
    }
    
  }catch(error){
    console.log(error);
  }
};
SeeAll(type,data){
  //console.log(data);
 // window.location.href="/dfUserSeeAll";
  this.setState({type ,data,show1: true})
}
     
process=(body)=>{
  let content=[];
  body.map(async(ival,i)=>{
    if(ival.type===1){
      let title=[];
        title=[<h3 style={{marginTop:'25px',color:`${ival.fcolour}`,fontSize:`${ival.fsize+'px'}`}}>{ival.title}</h3>];
      let innercontent= this.typeOne(ival);
      if(ival.width==='Half'||ival.width==='half'){
      return content.push(<div style={{width:'135%'}}>{title}
      <CarouselMulti responsive={responsive}  showDots={false}   swipeable={this.props.deviceType !== "mobile" ? true : false} 
         removeArrowOnDeviceType={["tablet", "mobile"]}
         >{innercontent}</CarouselMulti><br/>
         <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
         </div>)
      }
      if (ival.width==='double'||ival.width==='full'){
        return content.push(<div>{title}
        <br/><div style={{width:'132%'}}>
        <EmblaCarouselReact
          emblaRef={c => (this.embla = c)}
          options={{ loop: false }}
        >
          <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact>
          
          </div>
         {/*  <button onClick={() => this.embla.scrollPrev()}>Prev</button>*/}
        {/*<button onClick={() => this.embla.scrollNext()}>Next</button>*/}<br/>
        <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
          </div>)
      }


    } else if(ival.type===2){
      let title=[];
      title=[<h3 style={{marginTop:'25px',color:`${ival.fcolour}`,fontSize:`${ival.fsize+'px'}`}}>{ival.title}</h3>];
      let innercontent= this.typeTwo(ival);
      if(ival.width==='half'||ival.width==='half'){
        return content.push(<div style={{width:'135%'}}>{title}
          <CarouselMulti responsive={responsive}  showDots={false}  removeArrowOnDeviceType={["tablet", "mobile"]} >{innercontent}</CarouselMulti>
          <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
          </div>)
        }
        
        if (ival.width==='full'){
        return content.push(<div>{title}
        <br/><div style={{width:'132%'}}>
        <EmblaCarouselReact
          emblaRef={c => (this.embla = c)}
          options={{ loop: false }}
        >
          <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact></div>
          <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
          {/* <button onClick={() => this.embla.scrollPrev()}>Prev</button>
           <button onClick={() => this.embla.scrollNext()}>Next</button> */}
          </div>)
        }if(ival.width==='double'){
          return content.push(<div>{title}
          <br/><div style={{width:'132%'}}>
          <EmblaCarouselReact
            emblaRef={c => (this.embla = c)}
            options={{ loop: false }}
          >
            <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact></div>
           {/*  <button onClick={() => this.embla.scrollPrev()}>Prev</button>*/}
          {/*<button onClick={() => this.embla.scrollNext()}>Next</button>*/}
          <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
            </div>)


        }
    } 
      else if(ival.type===3){
        let title=[];
        title=[<h3 style={{marginTop:'25px',color:`${ival.fcolour}`,fontSize:`${ival.fsize+'px'}`}}>{ival.title}</h3>];
      let innercontent=this.typeThree(ival);
      if(ival.width==='Half'||ival.width==='half'){
        return content.push(<div style={{width:'135%'}}>{title}
          <CarouselMulti responsive={responsive}  showDots={false}  removeArrowOnDeviceType={["tablet", "mobile"]}>{innercontent}</CarouselMulti>
          <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
          </div>)
        }
        if (ival.width==='full'||ival.width==='double'){
          return content.push(<div>{title}
          <br/><div style={{width:'132%'}}>
          <EmblaCarouselReact
            emblaRef={c => (this.embla = c)}
            options={{ loop: false }}
          >
            <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact></div><br/>
            <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
           {/*  <button onClick={() => this.embla.scrollPrev()}>Prev</button>*/}
          {/*<button onClick={() => this.embla.scrollNext()}>Next</button>*/}
            </div>)
        }
    } 
    else if(ival.type===4){
      let title=[];
      title=[<h3 style={{marginTop:'25px',color:`${ival.fcolour}`,fontSize:`${ival.fsize+'px'}`}}>{ival.title}</h3>];
      let innercontent=this.typeFour(ival);
      if(ival.width==='Half'||ival.width==='half'){
        return content.push(<div style={{width:'135%'}}>{title}
          <CarouselMulti responsive={responsive}  showDots={false}
           removeArrowOnDeviceType={["tablet", "mobile"]} >{innercontent}</CarouselMulti>
           <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button></div>)
        }
        if (ival.width==='full'||ival.width==='double'){
          return content.push(<div>{title}
          <br/><div style={{width:'132%'}}>
          <EmblaCarouselReact
            emblaRef={c => (this.embla = c)}
            options={{ loop: false }}
          >
            <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact></div>
            <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button><br/>
           {/*  <button onClick={() => this.embla.scrollPrev()}>Prev</button>*/}
          {/*<button onClick={() => this.embla.scrollNext()}>Next</button>*/}
            </div>)
        }
    }
    else if(ival.type===5){
      let title=[];
      title=[<h3 style={{marginTop:'25px',color:`${ival.fcolour}`,fontSize:`${ival.fsize+'px'}`}}>{ival.title}</h3>];
      let innercontent=this.typeFive(ival);
      if(ival.width==='Half'||ival.width==='half'){
        return content.push(<div style={{width:'135%'}}> {title}
          <CarouselMulti responsive={responsive}  showDots={false}
           removeArrowOnDeviceType={["tablet", "mobile"]} >{innercontent}</CarouselMulti>
           <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
           </div>)
        }
        if (ival.width==='full'){
          return content.push(<div>{title}
          <br/><div style={{width:'132%'}}>
          <EmblaCarouselReact
            emblaRef={c => (this.embla = c)}
            options={{ loop: false }}
          >
            <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact></div>
           {/*  <button onClick={() => this.embla.scrollPrev()}>Prev</button>*/}
          {/*<button onClick={() => this.embla.scrollNext()}>Next</button>*/}
          <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
            </div>)
        }
        if(ival.width==='double'){
          return content.push(<div>{title}
          <br/><div style={{width:'132%'}}>
          <EmblaCarouselReact
            emblaRef={c => (this.embla = c)}
            options={{ loop: false }}
          >
            <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact></div><br/>
           {/*  <button onClick={() => this.embla.scrollPrev()}>Prev</button>*/}
          {/*<button onClick={() => this.embla.scrollNext()}>Next</button>*/}
          <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
            </div>)

        }
    }
    else if(ival.type===6){
       
        let title=[];
        title=[<h3 style={{marginTop:'25px',color:`${ival.fcolour}`,fontSize:`${ival.fsize+'px'}`}}>{ival.title}</h3>];
      let innercontent=this.typeSix(ival);
      if(ival.width==='Half'||ival.width==='half'){
        return content.push(<div style={{width:'135%'}}>{title}
          <CarouselMulti responsive={responsive}  showDots={false}
           removeArrowOnDeviceType={["tablet", "mobile"]} >{innercontent}</CarouselMulti>
           <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
           </div>)
        }

        if (ival.width==='full'){
          return content.push(<div>{title}
          <br/><div style={{width:'132%'}}>
          <EmblaCarouselReact
            emblaRef={c => (this.embla = c)}
            options={{ loop: false }}
          >
            <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact></div><br/>
           {/*  <button onClick={() => this.embla.scrollPrev()}>Prev</button>*/}
          {/*<button onClick={() => this.embla.scrollNext()}>Next</button>*/}
          <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
            </div>)
        }
        if(ival.width==='double'){
         // console.log(ival.width);  
         return content.push(<div>{title}
          <br/><div style={{width:'132%'}}>
          <EmblaCarouselReact
            emblaRef={c => (this.embla = c)}
            options={{ loop: false }}
          >
            <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact></div><br/>
           {/*  <button onClick={() => this.embla.scrollPrev()}>Prev</button>*/}
          {/*<button onClick={() => this.embla.scrollNext()}>Next</button>*/}
          <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
            </div>)

        }


    }
    else if(ival.type===7){
       let title=[];
        title=[<h3 style={{marginTop:'25px',color:`${ival.fcolour}`,fontSize:`${ival.fsize+'px'}`}}>{ival.title}</h3>];
      let innercontent=this.typeSeven(ival);
      if(ival.width==='Half'||ival.width==='half'){
        return content.push(<div style={{width:'135%'}}>{title}
          <CarouselMulti responsive={responsive}  showDots={false} 
           removeArrowOnDeviceType={["tablet", "mobile"]}>{innercontent}</CarouselMulti>
           <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button></div>)
        }
        if (ival.width==='full'){
          return content.push(<div>{title}
          <br/><div style={{width:'132%'}}>
          <EmblaCarouselReact
            emblaRef={c => (this.embla = c)}
            options={{ loop: false }}
          >
            <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact></div><br/>
           {/*  <button onClick={() => this.embla.scrollPrev()}>Prev</button>*/}
          {/*<button onClick={() => this.embla.scrollNext()}>Next</button>*/}
          <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
            </div>)
        }
        if(ival.width==='double'){
          return content.push(<div>{title}
          <br/><div style={{width:'132%'}}>
          <EmblaCarouselReact
            emblaRef={c => (this.embla = c)}
            options={{ loop: false }}
          >
            <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact></div><br/>
           {/*  <button onClick={() => this.embla.scrollPrev()}>Prev</button>*/}
          {/*<button onClick={() => this.embla.scrollNext()}>Next</button>*/}
          <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
            </div>)

        }
 

    }
    else if(ival.type===8){
      let title=[];
        title=[<h3 style={{marginTop:'25px',color:`${ival.fcolour}`,fontSize:`${ival.fsize+'px'}`}}>{ival.title}</h3>];
      let innercontent=this.typeEight(ival);
      if(ival.width==='Half'||ival.width==='half'){
        return content.push(<div style={{width:'135%'}}>{title}
          <CarouselMulti responsive={responsive}  showDots={false} 
           removeArrowOnDeviceType={["tablet", "mobile"]}>{innercontent}</CarouselMulti><br/>
           <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
           </div>)
        }
        if (ival.width==='full'||ival.width==='double'){
          return content.push(<div>{title}
          <br/><div style={{width:'132%'}}>
          <EmblaCarouselReact
            emblaRef={c => (this.embla = c)}
            options={{ loop: false }}
          >
            <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact></div><br/>
           {/*  <button onClick={() => this.embla.scrollPrev()}>Prev</button>*/}
          {/*<button onClick={() => this.embla.scrollNext()}>Next</button>*/}
          <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
            </div>)
        }
    }
    else if(ival.type===9){
      let title=[];
        title=[<h3 style={{marginTop:'25px',color:`${ival.fcolour}`,fontSize:`${ival.fsize+'px'}`}}>{ival.title}</h3>];
      let innercontent=this.typeNine(ival);
      if(ival.width==='Half'||ival.width==='half'){
        return content.push(<div style={{width:'135%'}}>{title}
          <CarouselMulti responsive={responsive}  showDots={false} 
           removeArrowOnDeviceType={["tablet", "mobile"]}>{innercontent}</CarouselMulti>
             <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button></div>)
        }
        if (ival.width==='full'){
          return content.push(<div>{title}
          <br/><div style={{width:'132%'}}>
          <EmblaCarouselReact
            emblaRef={c => (this.embla = c)}
            options={{ loop: false }}
          >
            <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact></div>
           {/*  <button onClick={() => this.embla.scrollPrev()}>Prev</button>*/}
          {/*<button onClick={() => this.embla.scrollNext()}>Next</button>*/}
          <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
            </div>)
        }
        if(ival.width==='double'){
           return content.push(<div>{title}
          <br/><div style={{width:'132%'}}>
          <EmblaCarouselReact
            emblaRef={c => (this.embla = c)}
            options={{ loop: false }}
          >
            <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact></div><br/>
           {/*  <button onClick={() => this.embla.scrollPrev()}>Prev</button>*/}
          {/*<button onClick={() => this.embla.scrollNext()}>Next</button>*/}
          <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
            </div>)
        }
    }
    else if(ival.type===10){
      let title=[];
        title=[<h3 style={{marginTop:'25px',color:`${ival.fcolour}`,fontSize:`${ival.fsize+'px'}`}}>{ival.title}</h3>];
      let innercontent=this.typeTen(ival);
      if(ival.width==='Half'||ival.width==='half'){
        return content.push(<div style={{width:'135%'}}>{title}
          <CarouselMulti responsive={responsive}  showDots={false}
           removeArrowOnDeviceType={["tablet", "mobile"]} >{innercontent}</CarouselMulti>
           <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
           </div>)
        }
        if (ival.width==='full'){
          return content.push(<div>{title}
          <br/><div style={{width:'132%'}}>
          <EmblaCarouselReact
            emblaRef={c => (this.embla = c)}
            options={{ loop: false }}
          >
            <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact></div><br/>
           {/*  <button onClick={() => this.embla.scrollPrev()}>Prev</button>*/}
          {/*<button onClick={() => this.embla.scrollNext()}>Next</button>*/}
          <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
            </div>)
        }
        if(ival.width==='double'){
          return content.push(<div>{title}
          <br/><div style={{width:'132%'}}>
          <EmblaCarouselReact
            emblaRef={c => (this.embla = c)}
            options={{ loop: false }}
          >
            <div style={{ display: 'flex' }}> {innercontent}</div></EmblaCarouselReact></div><br/>
           {/*  <button onClick={() => this.embla.scrollPrev()}>Prev</button>*/}
          {/*<button onClick={() => this.embla.scrollNext()}>Next</button>*/}
          <button type="button" onClick={()=>this.SeeAll(ival.type,ival)}>See All</button>
            </div>)

        }
    }
  })
  this.setState({content});
}


typeOne=(ival)=>{
  let content=[];
  ival.content.map((jval,j)=>{
    // console.log(jval)
    if(ival.width==='Half'|ival.width=='half'){
      
    content.push(
                <div class="container" style={{marginTop:'13px'}}>
                
              <div class="card-body" style={{width:'89%',backgroundColor: '#fff',borderRadius:'21px'}}>
              <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
                <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} onClick={()=>this.seperate(ival.type,jval,ival)}
               style={{width:'236px',height:'236px'}}   />
              </div>
              </div>
   )}else if(ival.width==='full'){
      content.push(
               <div style={{ flex: '0 0 100%' }}>
        <div class="container" style={{marginTop:'13px'}}>
      <div class="card-body" style={{width:'100%',backgroundColor: '#fff',borderRadius:'21px',
  marginBottom:'44px'}}>
<div className="row"> 
      <div className="col-sm-6">   <h3  style={{textAlign:'left',fontSize:'inherit'}}>{jval.name}</h3><h3 style={{marginTop:'10px'}}>{jval.description}</h3></div>
       <div className="col-sm-6"> 
      <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} onClick={()=>this.seperate(ival.type,jval,ival)}
      style={{
  width:'100%',height:'300px'  }} />
        </div>
        </div>
        </div>
    </div>
  </div>
    )

    }
    else{

      content.push(
        <div style={{ flex: '0 0 100%' }}>
              <div class="container" style={{marginTop:'13px'}}>
              <div class="card-body" style={{width:'99%',backgroundColor: '#fff',borderRadius:'21px',marginLeft:'7px'}}>
              
                <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} onClick={()=>this.seperate(ival.type,jval,ival)} style={{  marginTop:'-3px',
            marginLeft:'0%',
            borderRadius: '10px',
            height: '400px',
            marginBottom:'26px',
            width:'101%'}}/>
              <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
              <h3 style={{textAlign:'center',fontSize:'inherit'}}>{jval.description}</h3> 
              </div>
              </div>
              </div>
      )

    }
  })
  return content
}

seperate = (type,data,data1) =>{
  
  this.setState({type , data ,data1,show : true})
 // window.location.href = "/difuzaNextUser";
}

typeTwo=(ival)=>{
  let content=[];
  var title="";
  title=ival.title;
 // console.log(ival);
  ival.content.map((jval,j)=>{
    if(ival.width==='Half'||ival.width==='half'){
      content.push(
        <div class="container" style={{marginTop:'13px'}}>
                
        <div class="card-body" style={{width:'89%',backgroundColor: '#fff',borderRadius:'21px'}}>
        <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
        <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} onClick={()=>this.seperate(ival.type,jval,ival)}
               style={{width:'236px',height:'236px'}}   />
        </div>
        </div>
        )
    }else if(ival.width==='full'){
      content.push(
        <div style={{ flex: '0 0 100%' }}>
        <div class="container" style={{marginTop:'13px'}}>
      <div class="card-body" style={{width:'100%',backgroundColor: '#fff',borderRadius:'21px',
  marginBottom:'44px'}}>
<div className="row"> 
      <div className="col-sm-6">   <h3  style={{textAlign:'left',fontSize:'inherit'}}>{jval.name}</h3><h3 style={{marginTop:'10px'}}>{jval.description}</h3></div>
       <div className="col-sm-6"> 
      <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} onClick={()=>this.seperate(ival.type,jval,ival)}
      style={{
  width:'100%',height:'300px'  }} />
        </div>
        </div>
        </div>
    </div>
  </div>
        )

    }else{
      content.push(
        <div style={{ flex: '0 0 100%' }}>
        <div class="container" style={{marginTop:'13px'}}>
        <div class="card-body" style={{width:'99%',backgroundColor: '#fff',borderRadius:'21px',marginLeft:'7px'}}>
        
          <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} onClick={()=>this.seperate(ival.type,jval,ival)} style={{  marginTop:'-3px',
      marginLeft:'0%',
      borderRadius: '10px',
      height: '400px',
      marginBottom:'26px',
      width:'101%'}}/>
        <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
        <h3 style={{textAlign:'center',fontSize:'inherit'}}>{jval.description}</h3> 
        </div>
        </div>
        </div>
      )
    }
  })
  return content
}


typeThree=(ival)=>{
  let content=[];
  var title="";
  title=ival.title;
 
  ival.content.map((jval,j)=>{
   // console.log(jval);
    if(ival.width==='Half'||ival.width==='half'){
      //console.log(ival)
      content.push(
        <div class="container" style={{marginTop:'13px'}}>
                
        <div class="card-body" style={{width:'89%',backgroundColor: '#fff',borderRadius:'21px'}}>
        <nav>  <a href={jval.link}>
        <h3  style={{textAlign:'center',fontSize:'inherit',color:`${jval.fcolour}`}}>{jval.name}</h3>  
        <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} 
               style={{width:'236px',height:'236px'}}   />
          </a></nav>
        </div>
        </div>
        )
    }else if(ival.width==='full'){
    //console.log(ival.fcolour)
      content.push(
        <div style={{ flex: '0 0 100%' }}>
        <div class="container" style={{marginTop:'13px'}}>
      <div class="card-body" style={{width:'100%',backgroundColor: '#fff',borderRadius:'21px',
  marginBottom:'44px'}}>
<div className="row"> 
      <div className="col-sm-6">   <h3  style={{textAlign:'left',fontSize:'inherit'}}>{jval.name}</h3><h3 style={{marginTop:'10px'}}>{jval.description}</h3></div>
       <div className="col-sm-6"> 
       <nav><a href={jval.link}>
      <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} 
      style={{
  width:'100%',height:'300px'  }} />
  </a></nav>
        </div>
        </div>
        </div>
    </div>
  </div>
        )
    }else{
      content.push(
        <div style={{ flex: '0 0 100%' }}>
        <div class="container" style={{marginTop:'13px'}}>
        <div class="card-body" style={{width:'99%',backgroundColor: '#fff',borderRadius:'21px',marginLeft:'7px'}}>
        <nav><a href={jval.link}>
          <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} onClick={()=>this.seperate(ival.type,jval,ival)} style={{  marginTop:'-3px',
      marginLeft:'0%',
      borderRadius: '10px',
      height: '400px',
      marginBottom:'26px',
      width:'101%'}}/>
      </a></nav>
        <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
        <h3 style={{textAlign:'center',fontSize:'inherit'}}>{jval.description}</h3> 
        </div>
        </div>
        </div>
      )
    }
  })
  return content
}






typeFour=(ival)=>{
  let content=[];
  ival.content.map((jval,j)=>{
   
    if(ival.width==='Half'){
    content.push(
                <div class="container" style={{marginTop:'13px'}}>
                
              <div class="card-body" style={{width:'89%',backgroundColor: '#fff',borderRadius:'21px'}}>
              <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
              <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} onClick={()=>this.seperate(ival.type,jval,ival)}
               style={{width:'236px',height:'236px'}}   />
              </div>
              </div>
    
    )}else if(ival.width==='full'){
     // console.log(ival.width)
      content.push(
        <div style={{ flex: '0 0 100%' }}>
        <div class="container" style={{marginTop:'13px'}}>
      <div class="card-body" style={{width:'100%',backgroundColor: '#fff',borderRadius:'21px',
  marginBottom:'44px'}}>
<div className="row"> 
      <div className="col-sm-6">   <h3  style={{textAlign:'left',fontSize:'inherit'}}>{jval.name}</h3><h3 style={{marginTop:'10px'}}>{jval.description}</h3></div>
       <div className="col-sm-6"> 
      <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} onClick={()=>this.seperate(ival.type,jval,ival)}
      style={{
  width:'100%',height:'300px'  }} />
        </div>
        </div>
        </div>
    </div>
  </div>
    )

    }
    else{

      content.push(
        <div style={{ flex: '0 0 100%' }}>
              <div class="container" style={{marginTop:'13px'}}>
              <div class="card-body" style={{width:'99%',backgroundColor: '#fff',borderRadius:'21px',marginLeft:'7px'}}>
              
                <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} onClick={()=>this.seperate(ival.type,jval,ival)} style={{  marginTop:'-3px',
            marginLeft:'0%',
            borderRadius: '10px',
            height: '400px',
            marginBottom:'26px',
            width:'101%'}}/>
              <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
              <h3 style={{textAlign:'center',fontSize:'inherit'}}>{jval.description}</h3> 
              </div>
              </div>
              </div>
      )

    }
  })
  return content
}


typeFive=(ival)=>{
  let content=[];

  ival.content.map((jval,j)=>{
    // console.log(jval)
    if(ival.width==='Half'||ival.width==='half'){
    content.push(
      <div class="container" style={{marginTop:'13px'}}>
                
      <div class="card-body" style={{width:'89%',backgroundColor: '#fff',borderRadius:'21px'}}>
      <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
      <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} onClick={()=>this.seperate(ival.type,jval,ival)}
               style={{width:'236px',height:'236px'}}   />
      </div>
      </div>
    
    )}else if(ival.width==='full'){
      content.push(
        <div style={{ flex: '0 0 100%' }}>
        <div class="container" style={{marginTop:'13px'}}>
      <div class="card-body" style={{width:'100%',backgroundColor: '#fff',borderRadius:'21px',
  marginBottom:'44px'}}>
<div className="row"> 
      <div className="col-sm-6">   <h3  style={{textAlign:'left',fontSize:'inherit'}}>{jval.name}</h3><h3 style={{marginTop:'10px'}}>{jval.description}</h3></div>
       <div className="col-sm-6"> 
      <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} onClick={()=>this.seperate(ival.type,jval,ival)}
      style={{
  width:'100%',height:'300px'  }} />
        </div>
        </div>
        </div>
    </div>
  </div>
    )

    }
    else{

      content.push(
        <div style={{ flex: '0 0 100%' }}>
              <div class="container" style={{marginTop:'13px'}}>
              <div class="card-body" style={{width:'99%',backgroundColor: '#fff',borderRadius:'21px',marginLeft:'7px'}}>
              
                <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} onClick={()=>this.seperate(ival.type,jval,ival)} style={{  marginTop:'-3px',
            marginLeft:'0%',
            borderRadius: '10px',
            height: '400px',
            marginBottom:'26px',
            width:'101%'}}/>
              <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
              <h3 style={{textAlign:'center',fontSize:'inherit'}}>{jval.description}</h3> 
              </div>
              </div>
              </div>
      )

    }
  })
  return content
}


typeSix=(ival)=>{
  let content=[];

  ival.content.map((jval,j)=>{
    // console.log(jval)
    if(ival.width==='Half'||ival.width==='half'){
    content.push(
      <div class="container" style={{marginTop:'13px'}}>
                
      <div class="card-body" style={{width:'89%',backgroundColor: '#fff',borderRadius:'21px'}}>
      <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
      <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} onClick={()=>this.seperate(ival.type,jval,ival)}
               style={{width:'236px',height:'236px'}}   />
      </div>
      </div>
    
    )}else if(ival.width==='full'){
      content.push(
        <div style={{ flex: '0 0 100%' }}>
        <div class="container" style={{marginTop:'13px'}}>
      <div class="card-body" style={{width:'100%',backgroundColor: '#fff',borderRadius:'21px',
  marginBottom:'44px'}}>
<div className="row"> 
      <div className="col-sm-6">   <h3  style={{textAlign:'left',fontSize:'inherit'}}>{jval.name}</h3><h3 style={{marginTop:'10px'}}>{jval.description}</h3></div>
       <div className="col-sm-6"> 
      <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} onClick={()=>this.seperate(ival.type,jval,ival)}
      style={{
  width:'100%',height:'300px'  }} />
        </div>
        </div>
        </div>
    </div>
  </div>
    )

    }
    else{
      // console.log(ival.width);

      content.push(
        <div style={{ flex: '0 0 100%' }}>
              <div class="container" style={{marginTop:'13px'}}>
              <div class="card-body" style={{width:'99%',backgroundColor: '#fff',borderRadius:'21px',marginLeft:'7px'}}>
              
                <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} style={{  marginTop:'-3px',
            marginLeft:'0%',
            borderRadius: '10px',
            height: '400px',
            marginBottom:'26px',
            width:'101%'}}/>
              <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
              <h3 style={{textAlign:'center',fontSize:'inherit'}}>{jval.description}</h3> 
              </div>
              </div>
              </div>
      )

    }
  })
  return content
}
////////
typeEight=(ival)=>{
  let content=[];
  const {userId}=this.state;
  ival.content.map((jval,j)=>{
    let link="DF/WizardForm"+"/"+userId+"/"+jval.quizId+"/"+jval.id;
 //  console.log(link);
    if(ival.width==='Half'||ival.width==='half'){
    content.push(
                <div class="container" style={{marginTop:'13px'}}>
                
              <div class="card-body" style={{width:'89%',backgroundColor: '#fff',borderRadius:'21px'}}>
              <nav><a href={`${ACCESS_POINT}/${link}`}>
              <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
                <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} style={{width:'236px',height:'236px'}}/>
               </a></nav>
              </div>
              </div>
    
    )}else if(ival.width==='full'){
      content.push(
        <div style={{ flex: '0 0 100%' }}>
        <div class="container" style={{marginTop:'13px'}}>
      <div class="card-body" style={{width:'100%',backgroundColor: '#fff',borderRadius:'21px',
  marginBottom:'44px'}}>
<div className="row"> 
      <div className="col-sm-6">   <h3  style={{textAlign:'left',fontSize:'inherit'}}>{jval.name}</h3><h3 style={{marginTop:'10px'}}>{jval.description}</h3></div>
       <div className="col-sm-6"> 
    <nav><a href={link}>   <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`}
      style={{
  width:'100%',height:'300px'  }} /></a></nav>
        </div>
        </div>
        </div>
    </div>
  </div>
    )

    }
    else{

      content.push(
        <div style={{ flex: '0 0 100%' }}>
        <div class="container" style={{marginTop:'13px'}}>
        <div class="card-body" style={{width:'99%',backgroundColor: '#fff',borderRadius:'21px',marginLeft:'7px'}}>
        <nav><a href={`${ACCESS_POINT}/${link}`}> 
          <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} style={{  marginTop:'-3px',
      marginLeft:'0%',
      borderRadius: '10px',
      height: '400px',
      marginBottom:'26px',
      width:'101%'}}/>
      </a></nav>
        <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
        <h3 style={{textAlign:'center',fontSize:'inherit'}}>{jval.description}</h3> 
        </div>
        </div>
        </div>
      )

    }
  })
  return content
}
////////
typeNine=(ival)=>{
  let content=[];
  const {userId}=this.state;
  
  ival.content.map((jval,j)=>{
    let link='DF/FormView'+"/"+userId+"/"+jval.formId+"/"+jval.id;
    if(ival.width==='Half'||ival.width==='half'){
    content.push(
                <div class="container" style={{marginTop:'13px'}}>
                
              <div class="card-body" style={{width:'89%',backgroundColor: '#fff',borderRadius:'21px'}}>
              <nav><a href={`${ACCESS_POINT}/${link}`}>
              <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
                <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} style={{width:'236px',height:'236px'}}  />
                </a></nav>
              </div>
              </div>
    
    )}else if(ival.width==='full'){
      //console.log(link)
      content.push(
        <div style={{ flex: '0 0 100%' }}>
        <div class="container" style={{marginTop:'13px'}}>
      <div class="card-body" style={{width:'100%',backgroundColor: '#fff',borderRadius:'21px',
  marginBottom:'44px'}}>
<div className="row"> 
      <div className="col-sm-6">   <h3  style={{textAlign:'left',fontSize:'inherit'}}>{jval.name}</h3><h3 style={{marginTop:'10px'}}>{jval.description}</h3></div>
       <div className="col-sm-6"> 
       <nav><a href={link}>
               <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} 
      style={{
  width:'100%',height:'300px'  }} />
  </a></nav>
        </div>
        </div>
        </div>
    </div>
  </div>
    )

    }
    else{

      content.push(
        <div style={{ flex: '0 0 100%' }}>
        <div class="container" style={{marginTop:'13px'}}>
        <div class="card-body" style={{width:'99%',backgroundColor: '#fff',borderRadius:'21px',marginLeft:'7px'}}>
        <nav><a href={`${ACCESS_POINT}/${link}`}> 
          <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} style={{  marginTop:'-3px',
      marginLeft:'0%',
      borderRadius: '10px',
      height: '400px',
      marginBottom:'26px',
      width:'101%'}}/>
      </a></nav>
        <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
        <h3 style={{textAlign:'center',fontSize:'inherit'}}>{jval.description}</h3> 
        </div>
        </div>
        </div>
      )

    }
  })
  return content
}
////////
typeTen=(ival)=>{
  const {userId}=this.state;
  let content=[];
  ival.content.map((jval,j)=>{
    var link='DF/SurveyForm'+"/"+userId+"/"+jval.survey+"/"+jval.id;
    
    if(ival.width==='Half'||ival.width==='half'){
    content.push(
                <div class="container" style={{marginTop:'13px'}}>
                
              <div class="card-body" style={{width:'89%',backgroundColor: '#fff',borderRadius:'21px'}}>
              <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3> 
              <nav><a href={`${ACCESS_POINT}/${link}`}> 
                <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} style={{width:'236px',height:'236px'}}/>
            </a></nav>
            </div>
              </div>
    
    )}else if(ival.width==='full'){
      content.push(
        <div style={{ flex: '0 0 100%' }}>
        <div class="container" style={{marginTop:'13px'}}>
      <div class="card-body" style={{width:'100%',backgroundColor: '#fff',borderRadius:'21px',
  marginBottom:'44px'}}>
<div className="row"> 
      <div className="col-sm-6">   <h3  style={{textAlign:'left',fontSize:'inherit'}}>{jval.name}</h3><h3 style={{marginTop:'10px'}}>{jval.description}</h3></div>
       <div className="col-sm-6"> <nav><a href={link}>
      <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`}
      style={{
  width:'100%',height:'300px'  }} /></a></nav>
        </div>
        </div>
        </div>
    </div>
  </div>
    )

    }
    else{

      content.push(
        <div style={{ flex: '0 0 100%' }}>
        <div class="container" style={{marginTop:'13px'}}>
        <div class="card-body" style={{width:'99%',backgroundColor: '#fff',borderRadius:'21px',marginLeft:'7px'}}>
        <nav><a href={`${ACCESS_POINT}/${link}`}> 
          <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${jval.thumbnail }`} style={{  marginTop:'-3px',
      marginLeft:'0%',
      borderRadius: '10px',
      height: '400px',
      marginBottom:'26px',
      width:'101%'}}/>
      </a></nav>
        <h3  style={{textAlign:'center',fontSize:'inherit'}}>{jval.name}</h3>  
        <h3 style={{textAlign:'center',fontSize:'inherit'}}>{jval.description}</h3> 
        </div>
        </div>
        </div>
      )

    }
  })
  return content
}
///////////

render(){
const{content}=this.state;
var kk=[];

return(
  <React.Fragment>
    <main className="main my-4">
      <div className="container-fluid"> 
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h1>DIFUZA_USER</h1>
              </div>
              <div className="card-body"    style={{backgroundColor:'#D3D3D3'}} >
               <div style={{width:"70%",marginLeft:"20px",marginBottom:"8px"}} className='my-own-custom-container'>
                {kk}
                
                {content && content}
                {this.state.show === true && (

                 <Redirect to={{
                        pathname: '/difuzaNextUser',
                      //  pathname:'/dfUserSeeAll',
                        state: { type: this.state.type ,data :this.state.data ,data1:this.state.data1}
                    }}
                 />
                )}
                  {this.state.show1 === true && (

                      <Redirect to={{
                           // pathname: '/difuzaNextUser',
                           pathname:'/dfUserSeeAll',
                            state: { type: this.state.type ,data :this.state.data ,data1:this.state.data1}
                        }}
                      />
                      )}
                                      
                 </div>
                 </div>
             </div>
          </div>
        </div>
      </div>
    </main>
  </React.Fragment>
      );
   }
}

export default DifuzaUser ;