import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './SideNav.css';

export default class SideNavThirdAdmin extends Component {
  render() {
    return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <ul className="nav">
            <li className="nav-item">
              <Link className="nav-link" to="/superAdmin">
                <i className="nav-icon icon-speedometer" />
                <span className="link-white">Dashboard</span>
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
