import React, { Component } from "react";
import { ACCESS_POINT } from "../../config";

class HomeInnerContentSection2 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
        <div className="row-fluid ">
          <div
            className="span12 widget-span widget-type-cell row-with-bg-img pos-rel"
            style={{
              backgroundImage: {},
              backgroundAttachment: "scroll",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#ff3E20"
            }}
            data-widget-type="cell"
            data-x={0}
            data-w={12}
          >
            <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
              <div className="row-fluid ">
                {/* page-margins */}
                <div
                  className="span12 page-margins widget-span widget-type-cell  force-white-text"
                  style={{
                    margin: "0 auto !important",
                    maxWidth: "95% !important",
                    float: "none !important"
                  }}
                  data-widget-type="cell"
                  data-x={0}
                  data-w={12}
                >
                  <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
                    <div className="row-fluid ">
                      <div
                        className="span12 widget-span widget-type-header "
                        style={{}}
                        data-widget-type="header"
                        data-x={0}
                        data-w={12}
                      >
                        <div className="cell-wrapper layout-widget-wrapper">
                          <span
                            id="hs_cos_wrapper_module_1495728517837210"
                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_header"
                            style={{}}
                            data-hs-cos-general-type="widget"
                            data-hs-cos-type="header"
                          >
                            <h2 className="whiteheading">Our Safety Initiatives</h2>
                          </span>
                        </div>
                        {/*end layout-widget-wrapper */}
                      </div>
                      {/*end widget-span */}
                    </div>
                    {/*end row*/}
                  </div>
                  {/*end row-wrapper */}
                  <div className="row-fluid-wrapper row-depth-1 row-number-6 ">
                    <div className="row-fluid ">
                      <div
                        className="span12 widget-span widget-type-raw_jinja "
                        style={{}}
                        data-widget-type="raw_jinja"
                        data-x={0}
                        data-w={12}
                      >
                        <div className="flex-row text-center">
                          <div className="w20 flex-col">
                            <div className="hvr-up">
                              <a
                                href="safety.html"
                                id="hs-link-svc_icon_1"
                                style={{
                                  borderWidth: "0px",
                                  border: "0px"
                                }}
                              >
                                <img
                                  src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/icon-safety-alert_136x110-3_1581055829068.png`}
                                  className="hs-image-widget "
                                  style={{
                                    width: "136px",
                                    borderWidth: "0px",
                                    border: "0px"
                                  }}
                                  width={136}
                                  alt="Murugappa for safety"
                                  title="Murugappa for safety"
                                />
                              </a>
                            </div>
                            <div className="svc-label">
                              <h3 style={{ fontWeight: 'bolder' }}>Compliance</h3>
                            </div>
                            <div className="svc-descr min70">
                              Technical Skill Development: Use
                              Gamification mechanics and experience design to digitally engage the workforce on safety
                            </div>
                            <div className="svc-cta self-end">
                              {/*
                              <a
                                href="#"
                                className="hs-cta-img"
                                style={{ borderWidth: "0px" }}
                              >
                                LEARN MORE
                              </a>
                            */}
                            </div>
                          </div>
                          <div className="w20 flex-col">
                            <div className="hvr-up">
                              <a
                                href="project-teams.html"
                                id="hs-link-svc_icon_2"
                                style={{
                                  borderWidth: "0px",
                                  border: "0px"
                                }}
                              >
                                <img
                                  src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/smartvidio_projectTeamsIcon136x110_5_1581055628932.png`}
                                  className="hs-image-widget "
                                  style={{
                                    width: "136px",
                                    borderWidth: "0px",
                                    border: "0px"
                                  }}
                                  width={136}
                                  alt="Murugappa for Project Teams"
                                  title="Murugappa for Project Teams"
                                />
                              </a>
                            </div>
                            <div className="svc-label" >
                              <h3 style={{ fontWeight: 'bolder' }}>People</h3>
                            </div>
                            <div className="svc-descr min70" style={{ marginTop: '9px' }}>
                              Behavior Based Safety Training Programs: Workshops for the leadership teams,
                              Steering committee workshops,
                              Communication Campaign Inputs and Train The Trainer Workshops
                            </div>
                            <div className="svc-cta self-end">
                              {/*
                              <a
                                href="#"
                                className="hs-cta-img"
                                style={{ borderWidth: "0px" }}
                              >
                                LEARN MORE
                              </a>
                            */}
                            </div>
                          </div>
                          {/* <div className="w20 flex-col">
                            <div className="hvr-up">
                              <a
                                href="risk-executives.html"
                                id="hs-link-svc_icon_3"
                                style={{
                                  borderWidth: "0px",
                                  border: "0px"
                                }}
                              >
                                <img
                                  src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/icon-risk-executives_1581055369289.png`}
                                  className="hs-image-widget "
                                  style={{
                                    width: "136px",
                                    borderWidth: "0px",
                                    border: "0px"
                                  }}
                                  width={136}
                                  alt="Murugappa for Risk Executives"
                                  title="Murugappa for Risk Executives"
                                />
                              </a>
                            </div>
                            <div className="svc-label">
                              <h3 style={{ fontWeight: 'bolder' }}>Safety &amp; Risk Executives</h3>
                            </div>
                            <div className="svc-descr min70">
                              Measure safety compliance trends across all
                              projects to reduce risk &amp; save on insurance.
                            </div>
                            <div className="svc-cta self-end">
                              <a
                                href="#"
                                className="hs-cta-img"
                                style={{ borderWidth: "0px" }}
                              >
                                LEARN MORE
                              </a>
                            </div>
                          </div> */}
                          <div className="w20 flex-col">
                            <div className="hvr-up">
                              <a
                                href="it.html"
                                id="hs-link-svc_icon_4"
                                style={{
                                  borderWidth: "0px",
                                  border: "0px"
                                }}
                              >
                                <img
                                  src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/icon_IT_136x110_1581055132788.png`}
                                  className="hs-image-widget "
                                  style={{
                                    width: "136px",
                                    borderWidth: "0px",
                                    border: "0px"
                                  }}
                                  width={136}
                                  alt="Murugappa for IT/Administrators"
                                  title="Murugappa for IT/Administrators"
                                />
                              </a>
                            </div>
                            <div className="svc-label">
                              <h3 style={{ fontWeight: 'bolder' }}>Business</h3>
                            </div>
                            <div className="svc-descr min70">
                              Governance Mechanism: Building Safety Health Checks to ensure regular, rigorous,
                              structured review system to display impact on business
                            </div>
                            <div className="svc-cta self-end">
                              {/*   <a
                                href="#"
                                className="hs-cta-img"
                                style={{ borderWidth: "0px" }}
                              >
                                LEARN MORE
                              </a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*end widget-span */}
                    </div>
                    {/*end row*/}
                  </div>
                  {/*end row-wrapper */}
                  <div className="row-fluid-wrapper row-depth-1 row-number-7 ">
                    <div className="row-fluid ">
                      <div
                        className="span12 widget-span widget-type-space min24"
                        style={{}}
                        data-widget-type="space"
                        data-x={0}
                        data-w={12}
                      >
                        <div className="cell-wrapper layout-widget-wrapper">
                          <span
                            id="hs_cos_wrapper_module_1495728525358213"
                            className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_space"
                            style={{}}
                            data-hs-cos-general-type="widget"
                            data-hs-cos-type="space"
                          />
                        </div>
                        {/*end layout-widget-wrapper */}
                      </div>
                      {/*end widget-span */}
                    </div>
                    {/*end row*/}
                  </div>
                  {/*end row-wrapper */}
                  <div className="row-fluid-wrapper row-depth-1 row-number-8 ">
                    <div className="row-fluid ">
                      <div
                        className="span12 widget-span widget-type-raw_jinja h0"
                        style={{}}
                        data-widget-type="raw_jinja"
                        data-x={0}
                        data-w={12}
                      >
                        {/* <div class="orange-triangle"></div> */}
                      </div>
                      {/*end widget-span */}
                    </div>
                    {/*end row*/}
                  </div>
                  {/*end row-wrapper */}
                </div>
                {/*end widget-span */}
              </div>
              {/*end row*/}
            </div>
            {/*end row-wrapper */}
          </div>
          {/*end widget-span */}
        </div>
        {/*end row*/}
      </div>
    );
  }
}
export default HomeInnerContentSection2;
