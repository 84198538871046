import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './SideNav.css';

export default class SideNavAdmin extends Component {
  async componentWillUnmount() {
    window.top.location.href = '';
  }
  render() {
    return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <ul className="nav">
            <li className="nav-item">
              <Link className="nav-link" to="/admin">
                <i className="nav-icon icon-speedometer" />
                <span className="link-white">Dashboard</span>
              </Link>
            </li>          
            <li className="nav-item">
              <Link className="nav-link" to="/AddService">
                <i className="nav-icon icon-speedometer" />
                <span className="link-white">Service Master</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/userList">
                <i className="nav-icon fa fa-user" />
                <span className="link-white">UserList</span>
              </Link>
            </li>
            <li className="nav-item nav-dropdown">
              <Link className="nav-link nav-dropdown-toggle" to="#">
                <i className="nav-icon icon-puzzle" /> Base
              </Link>
              <ul className="nav-dropdown-items">
                <li className="nav-item">
                  <Link className="nav-link" to="/barChart">
                    <i className="nav-icon icon-puzzle" />
                    <span className="link-white">Bar Chart</span>
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
