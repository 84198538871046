import React, { Component } from 'react';
import FromLibrary from "../ThirdAdmin/CMS/FromLibrary";
import LoginModal from "../../components/Modal/Modal";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import { ACCESS_POINT } from "../../config";
import draftToHtml from "draftjs-to-html";
import { Alert } from "reactstrap";
import Switch from "react-switch";
import Progress from "../../components/Extra/Progress";
import cmsContent from "../../MiddleWare/CmsContent";
import http from "../../MiddleWare/httpMiddleWare";


class AddTheme extends Component {
  constructor(props) {
    super(props);
    this.state = {
    AllContent:[],
	AllContent1:[],
    EmptySection:{title:"",thumbnail:'',files:[]},
	EmptyPublic:{name:'',link:'',icon:'',tray:false,files:[]},
    EmptyFileSection:{name:'',link:'',icon:''},
	editorState: EditorState.createEmpty(),
	ContentTitle:"",
	searchTags:"",
	uploadPercentage: 0,
	tray: false,
	tray1:false,
	//staff:false

    }; 
  } 
 async componentDidMount() {
    try{
    const {data : getLibraryImage} = await cmsContent.getLibraryImage("image");
        if (getLibraryImage) {
          this.setState({ getLibraryImage });
    }
    }catch(error){
      console.log(error);
    }
	
	} 
	
	
ThemeSix=async()=>{
	const{ContentTitle,AllContent1,editorState}=this.state;
    console.log(this.props)
	let authorName=this.props.authorName;
	let authorId=this.props.authorId;
	let name =this.props.PageName;
	
	let themeId= this.props.themeId;
	let customerId=this.props.customerId;
    let editorContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );	
	let FullContent=[];
	let fileCount=0;
	let FileName=[];
	AllContent1.map((ival,i)=>{
		FileName.push({name:[`file${i}`],file:ival.icon});
		AllContent1[i].icon=`file${i}`;
		FileName.push({name:[`pdf${i}`],file:ival.link});
		AllContent1[i].link=`pdf${i}`;
		ival.files.map((jval,j)=>{
			
		FileName.push({name:[`file${i}${j}`],file: jval.icon});	
		AllContent1[i].files[j].icon=`file${i}${j}`;
		FileName.push({name:[`pdf${i}${j}`],file: jval.link});	
		AllContent1[i].files[j].link=`pdf${i}${j}`;
			
		});
	})
	 let today = new Date(); 
    let dateformat = today.getDate();
    if (today.getDate() < 10) {
      dateformat = "0" + today.getDate();
    }
    let date =
      today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + dateformat;
	   const formData = new FormData();
	   formData.append("name",name.trim()); 
	   formData.append("themeId",6);
	   formData.append("authorid", authorId);
	   formData.append("Content",ContentTitle);
	   formData.append("Content_text",editorContent);
	  
	   FileName.map((ival,i)=>{
		   formData.append(ival.name,ival.file)
		 });  
	  formData.append("sheetContent", JSON.stringify(AllContent1));
	   
       formData.append("Date", date);
       formData.append("status", "show");
	   formData.append("customerId", customerId);	 	
        	    
				 console.log([...formData]); 
				 
				 try{
		 const themeInsert=await http.post(
		  ACCESS_POINT + `/cmsContent/theme/themeSix`, 
		  formData,
		  {  
          headers: {
            "Content-Type": "multipart/form-data"
          },
		   onUploadProgress: progressEvent => {
            this.setState({
              uploadPercentage: parseInt( 
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            });
            setTimeout(() => this.setState({ uploadPercentage: 0 }), 10000);
            ///10000
          }  
        }
      ); 
	  if (themeInsert) {
        //
      }	
         }   
     catch (error) {
      console.log(error);
    }
	
	
}	
AddPub=()=>{
	    let{ AllContent1,EmptyPublic}=this.state;
    AllContent1.push({name:'',link:'',icon:'',tray:false,files:[]});
    this.setState({AllContent1});
	//console.log(this.state.AllContent1);
}
AddSub=(i)=>{
	 let{ AllContent1,EmptyFileSection }=this.state;
   AllContent1[i].files.push({name:'',link:'',icon:''});
    this.setState({AllContent1});
	//console.log(this.state.AllContent1);
}
	
	
SubmitPage= async()=>{
	
	const{editorState,ContentTitle,searchTags,AllContent}=this.state;
	let editorContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
	console.log(this.props)
	let authorName=this.props.authorName;
	let authorId=this.props.authorId;
	let name =this.props.PageName;
	//console.log(name);
	let themeId= this.props.themeId;
	let customerId=this.props.customerId;
	let FullContent=[];
	//let Fulldata=[];
	let fileCount=0;
	let FileName=[];
	AllContent.map((ival,i)=>{
		
		AllContent[i].title= ival.title
        ? draftToHtml(
            convertToRaw(ival.title.getCurrentContent())
          )
        : null;
		
		FileName.push({name:[`file${i}`],file:ival.thumbnail});
		AllContent[i].thumbnail=`file${i}`;
		ival.files.map((jval,j)=>{
			
		FileName.push({name:[`file${i}${j}`],file: jval.icon});	
		AllContent[i].files[j].icon=`file${i}${j}`;
		FileName.push({name:[`pdf${i}${j}`],file: jval.link});
        AllContent[i].files[j].link=`pdf${i}${j}`;		
			
		});
         	
	 });
	  let today = new Date(); 
    let dateformat = today.getDate();
    if (today.getDate() < 10) {
      dateformat = "0" + today.getDate();
    }
    let date =
      today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + dateformat;
	   const formData = new FormData();
	   formData.append("name",name.trim()); 
	   formData.append("themeId",themeId);
	   formData.append("authorid", authorId);
	   formData.append("Content",ContentTitle);
	   formData.append("Content_text",editorContent);
	   formData.append("searchTags",searchTags);
	  // formData.append("stafftray",staff);
	   FileName.map((ival,i)=>{ 
		   formData.append(ival.name,ival.file)
		 });  
	  formData.append("sheetContent", JSON.stringify(AllContent));
	   
       formData.append("Date", date);
       formData.append("status", "show");
	   formData.append("customerId", customerId);	 	
        	    
				console.log([...formData]); 
     try{
		 const themeInsert=await http.post(
		  ACCESS_POINT + `/cmsContent/theme/themeFive`, 
		  formData,
		  {
          headers: {
            "Content-Type": "multipart/form-data"
          },
		   onUploadProgress: progressEvent => {
            this.setState({
              uploadPercentage: parseInt( 
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              )
            });
            setTimeout(() => this.setState({ uploadPercentage: 0 }), 10000);
            ///10000
          }
        }
      ); 
	  if (themeInsert) {
        this.setState({
			AllContent:[],
			ContentTitle:'',
			editorState: EditorState.createEmpty(),
			searchTags:'',
			staff:false, 
		})
      }	
         }   
     catch (error) {
      console.log(error);
    }
	
	
	
}	 

selectedTray = e => {
  //console.log(this.state.tray)
  this.setState({ tray: !this.state.tray });
};


/*selectedTray3 = e => {
  //console.log(this.state.staff)
  //this.setState({ staff: !this.state.staff });
};*/

onEditorStateChange = editorState => { 
    this.setState({
      editorState
    });
  };
handleChange = e =>{
	this.setState({searchTags:e.target.value})
	
}
 handlechange = e => {
       //console.log(e.target.value); 
	this.setState({ ContentTitle: e.target.value });
  };
	
  AddFile=()=>{
    let{ AllContent,EmptySection }=this.state;
    AllContent.push({title:"",thumbnail:'',files:[]});
    this.setState({AllContent});
  }
  AddPage=(i)=>{
    let{ AllContent,EmptyFileSection }=this.state;
    AllContent[i].files.push({name:'',link:'',icon:''});
    this.setState({AllContent});
  }
  HandlingChange1=(a,b,c,d=null)=>{
	let{ AllContent1 }=this.state;  
	//console.log(b.target.value) 
	if(d!==null){
		if(c==='name' ){
        AllContent1[a].files[d][c]=b.target.value;
		}else if(c==='icon'|| c==='link'){
			 AllContent1[a].files[d][c]=b.target.files[0];
			
		}
		
	}else{
		if(c==='name'){
			AllContent1[a][c]=b.target.value;
		}else if(c==='link'){
			AllContent1[a][c]=b.target.files[0];
		}else if(c==='icon'){
			AllContent1[a][c]=b.target.files[0];
		}else if(c==='tray'){
       AllContent1[a][c]=b; 
       this.setState({ });
			 console.log(b);
		} 
	}
	  this.setState({AllContent1});  
	//  console.log(AllContent1); 
  } 
  HandlingChange=(a,b,c,d=null)=>{
    let{ AllContent }=this.state;
    if(d !== null){
      if(c==='name'){
        AllContent[a].files[d][c]=b.target.value; 
		//console.log(AllContent[a].files[d][c]) 
      } else if(c==='icon' || c==='link'){
        AllContent[a].files[d][c]=b.target.files[0];
		//console.log(AllContent[a].files[d][c])
      }
    }else {
      if(c==='title'){
		//  console.log(b)
		 // console.log(b.target.value)
        AllContent[a][c]=b;
      }else if(c==='thumbnail'){
        AllContent[a][c]=b.target.files[0];
      }
    }
    this.setState({AllContent});
  }
  render(){
    let {AllContent,AllContent1,uploadPercentage}=this.state;
//console.log(AllContent);
    return (
      <React.Fragment>
        <div className="row form-group">
        <div className="col-sm-2" />
        <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">
            Content Title
            </label>
        </div>
        <div className="col-sm-5">
            <input
              type="text"
              className="form-control"
              id="pageName"
              name="pageName"
              placeholder="Enter Page Name"
                             
              onChange={this.handlechange}
              />
        </div>
		</div>
		 <div className="row form-group">
        <div className="col-sm-2" />
        <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">
            Content
            </label>
        </div>
        <div className="col-sm-5">
            <Editor
            wrapperClassName="wrapper-className"
            editorClassName="editor-className editor-border"
            toolbarClassName="toolbar-className"
            onEditorStateChange={this.onEditorStateChange}
            toolbar={{
            options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "link"
            ]
            }}
            value={this.state.editorState}
            />
        </div>
        
        <div className="col-sm-2 ">
                        <label style={{ paddingTop: 5 }}>
                          ThemeSwitch:
                          <Switch
                            height={18}
                            width={41}
                            marginBottom={1}
                            paddingTop={5}
                            onChange={this.selectedTray}
                            checked={this.state.tray}
                          />
                        </label>
                      </div>
					  <div className="col-sm-1" />
         </div>
		{this.state.tray==true&&(
		<div>
		{AllContent1.map((ival,i)=>{
			let index=i+1; 
			//console.log(ival);
			return(
			<React.Fragment>
			 <div className="row form-group">
				  <div className="col-sm-2 ">   
                        <label style={{ paddingTop: 5 }}>
                          AddSubMenu:
                          <Switch
                            height={18}
                            width={41}
                            marginBottom={1}
                            paddingTop={5}
                            onChange={d => this.HandlingChange1(i,d,'tray')}
                            checked={ival.tray}
                          />
                        </label>
			        </div>
                      <div className="col-sm-1">
                        <label htmlFor="exampleInputEmail1">
                        Upload Icon {index}
                        </label>
                      </div>
					   <div className="col-sm-3">
                        <input
                          type="file"
                          className="custom-file-input"
                          onChange={d => this.HandlingChange1(i,d,'icon')}
                          accept="image/*"
                        />
                        <label className="custom-file-label" htmlFor="customFileThumbnail"></label>
                      </div>
					   <div className="col-sm-1">
                        <label htmlFor="exampleInputEmail1">
                        Link Name {index}
                        </label>
                      </div>
                      <div className="col-sm-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Name Title"
                          value={ival.name}
                          onChange={d => this.HandlingChange1(i, d,'name')}
                          />
                      </div>
					   <div className="col-sm-1">
                        <label htmlFor="exampleInputEmail1">
                        Link {index}
                        </label>
                      </div>
                      <div className="col-sm-2">
                       <input
                          type="file"
                          className="custom-file-input"
                          onChange={d => this.HandlingChange1(i,d,'link')}
                          accept="image/*"
                        />
                        <label className="custom-file-label" htmlFor="customFileThumbnail"></label>
                      </div>
					  </div> 
					  {ival.files.map((jval,j)=>{
						  return(
						      <React.Fragment>
            <div className="row form-group">
			   <div className="col-sm-2 ">  
                    <label htmlFor="exampleInputEmail1">
					 SubMenu{j+1} 
					</label>
			   </div>
                <div className="col-sm-1">
                        <label htmlFor="exampleInputEmail1">
                        Upload Icon {j+1}
                        </label>
                      </div>
					   <div className="col-sm-3">
                        <input
                          type="file"
                          className="custom-file-input"
                          onChange={d => this.HandlingChange1(i,d,'icon',j)}
                          accept="image/*"
                        />
                        <label className="custom-file-label" htmlFor="customFileThumbnail"></label>
                      </div>
					   <div className="col-sm-1">
                        <label htmlFor="exampleInputEmail1">
                        Link Name {j+1}
                        </label>
                      </div>
                      <div className="col-sm-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Name Title"
                          value={jval.name}
                          onChange={d => this.HandlingChange1(i, d,'name',j)}
                          />
                      </div>
					   <div className="col-sm-1">
                        <label htmlFor="exampleInputEmail1">
                        Link {j+1}
                        </label>
                      </div>
                      <div className="col-sm-2">
                         <input
                          type="file"
                          className="custom-file-input"
                          onChange={d => this.HandlingChange1(i,d,'link',j)}
                          accept="image/*"
                        />
                        <label className="custom-file-label" htmlFor="customFileThumbnail"></label>
                      </div>
              
				</div>
				</React.Fragment>
						  )
						  
					  })}
					  {ival.tray==true&&(
					  <div>
					   <div className="row form-group">
                    <div className="col-sm-2" />
                     <div className="col-sm-6" />
                     <div className="col-sm-2">
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={()=>this.AddSub(i)} 
              >
              Add New SubMenu 
              </button>
            </div>
			</div>
			
					  </div>
					  )}
				  
			</React.Fragment>
			)
		})
		}
		 <div className="row form-group">
        <div className="col-sm-2" />
        <div className="col-sm-6" />
            <div className="col-sm-2">
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={()=>this.AddPub()} 
              >
              Add New Files 
              </button>
            </div>
        </div>
		
		
		</div>
		)}
		
		
		{this.state.tray==false &&(<div>
		<div className="row form-group">
        <div className="col-sm-4" />
            <span className="error-show ">
            </span>
        </div>
        <div className="row form-group">
        <div className="col-sm-4" />
        </div>
       
        <div className="row form-group">
        <div className="col-sm-4" />
            <span className="error-show "></span>
        </div>
        <div className="row form-group">
        <div className="col-sm-2" />
        <div className="col-sm-2">
            {" "}
            <label htmlFor="exampleInputEmail1">
            Search Tags
            </label>
        </div>
        <div className="col-sm-5">
            <textarea
              type="text"
              className="form-control"
              id="searchTags"
              name="searchTags"
              placeholder="Enter Search Tags"
              
              onChange={this.handleChange}
              />
        </div>
		<div className="col-sm-2">
		{/* <label style={{ paddingTop: 5 }}>
                          StaffSwitch:
                          <Switch
                            height={18}
                            width={41}
                            marginBottom={1}
                            paddingTop={5}
                            onChange={this.selectedTray3}
                            checked={this.state.staff}
                          />
                        </label>*/}
						</div>
        <div className="col-sm-1" />
        </div>
        <div className="row form-group">
        <div className="col-sm-4" />
          
        </div>
        {AllContent.map((ival,i)=>{
          let index=i+1;
          return (
          <React.Fragment>
            <div className="row form-group">
              <div className="col-sm-2" />
              <div className="col-sm-2">
                <label htmlFor="exampleInputEmail1">
                  Section Title {index}
                </label>
              </div>
              <div className="col-sm-5">
                <Editor
                editorState={ival.title}
                wrapperClassName="wrapper-className"
                editorClassName="editor-className editor-border"
                toolbarClassName="toolbar-className"
                onEditorStateChange={d => this.HandlingChange(i, d,'title')}
                toolbar={{
                options: [
                "inline",
                "blockType",
                "fontSize",
                "fontFamily",
                "list",
                "link"
                ]
                }}
                value={this.state.editorState}
                />
              </div>
              <div className="col-sm-3" />
            </div>

            <div className="row form-group">
              <div className="col-sm-2" />
              <div className="col-sm-2">
                <label htmlFor="exampleInputEmail1">
                Upload Thumbnail {index}
                </label>
              </div>
              <div className="col-sm-5">
                <input
                  type="file"
                  className="custom-file-input"
                  id="customFile"
                  onChange={d => this.HandlingChange(i, d,'thumbnail')}
                  accept="image/*"
                />
                <label className="custom-file-label" htmlFor="customFileThumbnail"></label>
              </div>
              <div className="col-sm-3" />
            </div>

            {ival.files.map((jval,j)=>{
              let jndex=j+1;
              return (
                <React.Fragment>
                  <div className="row form-group">
				  {/*<div className="col-sm-2" />*/}
                      <div className="col-sm-1">
                        <label htmlFor="exampleInputEmail1">
                        Upload Icon {jndex}
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <input
                          type="file"
                          className="custom-file-input"
                          onChange={d => this.HandlingChange(i,d,'icon',j)}
                          accept="image/*"
                        />
                        <label className="custom-file-label" htmlFor="customFileThumbnail"></label>
                      </div>
					  {/*  <div className="col-sm-3" />*/}
					   <div className="col-sm-1">
                        <label htmlFor="exampleInputEmail1">
                        Link Name {jndex}
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Name Title"
                          value={jval.name}
                          onChange={d => this.HandlingChange(i, d,'name',j)}
                          />
                      </div>
					   <div className="col-sm-1">
                        <label htmlFor="exampleInputEmail1">
                        Link {jndex}
                        </label>
                      </div>
                      <div className="col-sm-3">
                        <input
                          type="file"
                          className="custom-file-input"
                          onChange={d => this.HandlingChange(i,d,'link',j)}
                          accept="image/*"
                        />
                        <label className="custom-file-label" htmlFor="customFileThumbnail"></label>
                      </div>
					  
					  
                    </div>

					{/* <div className="row form-group">
					<div className="col-sm-2" />*/}
                     
                     {/* <div className="col-sm-3" />
					 </div>*/}

					 {/* <div className="row form-group">
                      <div className="col-sm-2" />*/}
					
					  {/* <div className="col-sm-3" />
					  </div>*/}
                  </React.Fragment>
              )
            })}
            <div className="row form-group">
              <div className="col-sm-2" />
              <div className="col-sm-6" />
              <div className="col-sm-2">
                <button
                type="button"
                className="btn btn-primary"
                onClick={()=>this.AddPage(i)} 
                >
                  Add File 
                </button>
              </div>
            </div>
          </React.Fragment>)
        })}
        <div className="row form-group">
        <div className="col-sm-2" />
        <div className="col-sm-6" />
            <div className="col-sm-2">
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={()=>this.AddFile()} 
              >
              Add New Section 
              </button>
            </div>
        </div>
		<div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        {uploadPercentage > 0 && (
                          <Progress percentage={uploadPercentage} />
                        )}
                      </div>
                      <div className="col-sm-3" />
		</div> 
		</div>)}
        <div className="row form-group">
        <div className="col-sm-2" />
        <div className="col-sm-2" />
            <div className="col-sm-5">
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.state.tray === true
                              ? this.ThemeSix
                              : this.SubmitPage }
              >
              Add Page
              </button>
            </div>
        </div>
      </React.Fragment>
    )
  }
}
export default AddTheme;
