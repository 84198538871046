import React from 'react';
import Datatable from '../../../components/Datatable/Datatable';
import Video from './Video';

const FromLibrary = ({ datas, columnHeading, imgsource, type }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <Datatable data={datas} columnHeading={columnHeading} />
      <br />
      <h4>Preview</h4>
      {type === 'image' && imgsource ? (
        <div>
          <img src={imgsource} alt="img" />
        </div>
      ) : null}
      {type === 'video' && imgsource ? (
        <div>
          <Video imgsource={imgsource} />
        </div>
      ) : null}
      {/* {video ? imgsource ? <Video imgsource={imgsource} /> : null : <img src={imgsource} />} */}
    </div>
  );
};

export default FromLibrary;
