import React, { Component } from "react";
import renderHTML from "react-render-html";
import DownPanel from "./DownPanel";
import { ACCESS_POINT } from "../../config";
import LoginModal from "../../components/Modal/Modal";
import ScriptTag from "react-script-tag";

const js1 = "/js/gallery/gallery.js";

const images = [
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_1_1582637362517.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_1_1582637362517.jpg"
  },
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_2_1582637362521.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_2_1582637362521.jpg"
  },
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_3_1582637362524.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_3_1582637362524.jpg"
  },
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/Pic2_1582637698132.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/Pic2_1582637698132.jpg"
  }
];

const style = `<style>
table {
border-collapse: collapse;
width: auto%;
}
td, th {
border: 1px solid #dddddd;
text-align: left;
padding: 8px;
}
body {
  font-family: Verdana, sans-serif;
  margin: 0;
}
* {
  box-sizing: border-box;
}
.row > .column {
  padding: 0 8px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.column {
  float: left;
  width: 20%;
  margin: auto;
  margin-left: 5px;
}
/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
}
/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}
.mySlides {
  display: none;
}
.cursor {
  cursor: pointer;
}
/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}
/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}
.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}
.demo {
  opacity: 0.6;
}
.active,
.demo:hover {
  opacity: 1;
}
img.hover-shadow {
  transition: 0.3s;
}
.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>`;
class SheetContent extends Component {
  state = {
    show: false,
    title: "Heading",
    loaded: true,
    tabTitle: ["QUALITY", "COMMERCIALS", "MAINTENANCE", "PRODUCTION"],
    tabContent: [
      `
      ${style}
      <h3>QUALITY-CLOTH</h3>
      <table>
        <tr>
          <th>S.No.</th>
          <th>Observed Behaviors</th>
          <th>SafeStart Auditor</th>
          <th>Frequency</th>
          <th>Y/N</th>
          <th>Target</th>
          <th>Variance</th>
          <th>Why(use dropdown)</th>
          <th>Cost of non-confirmance</th>
          <th>ActionSteps</th>
        </tr>
        <tr>
         <td>1</td>
         <td>Observed behaviors while clearance of dispatch</td>
         <td>Maintenance</td>
         <td>daily - 2 out of 7 occ</td>
         <td>Y</td>
         <td>Zero rushing</td>
         <td>jumbo storage improper</td>
         <td>rushing</td>
         <td> </td>
         <td>advance information of extract - 1 day in advance</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Following SOPs during QC of RM </td>
          <td>Maintenance</td>
          <td>daily - 2 out of 7 occ</td>
          <td>Y</td>
          <td>Zero rushing</td>
          <td></td>
          <td>rushing</td>
          <td></td>
          <td></td>

        </tr>
        <tr>
        <td>3</td>
        <td>Safety procedures not followed due to work to be done mindset</td>
        <td>Maintenance</td>
        <td>Nil</td>
        <td>N</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        </tr>
        <tr>
        <td>4</td>
        <td>Follow process while quality checks </td>
        <td>Maintenance</td>
        <td>fatigue</td>
        <td>Y</td>
        <td>eliminate fatigue</td>
        <td>jumbo storage improper</td>
        <td>fatigue</td>
        <td></td>
        <td>jumbo for dispatch to be kept separately in proper manner</td>
        </tr>
        <tr>
        <td>5</td>
        <td>Raised issues immediately where safety hazards observed</td>
        <td>Maintenance</td>
        <td>2 issues per day</td>
        <td>Y</td>
        <td>Zero issues</td>
        <td></td>
        <td></td>
        <td></td>
        <td>actions for safety issues to be implemented immediately</td>
        </tr>
        <tr>
        <td>6</td>
        <td>Testing with proper PPE Followed</td>
        <td>Maintenance</td>
        <td>not followed</td>
        <td>Y</td>
        <td>100% adherence</td>
        <td>low awareness</td>
        <td>complacency</td>
        <td></td>
        <td>conduct training and ensure usage of PPE</td>
        </tr>
        <tr>
        <td>7</td>
        <td>Sample collection as per process</td>
        <td>Maintenance</td>
        <td>1 occ / day</td>
        <td>Y</td>
        <td>Zero rushing</td>
        <td>time delays</td>
        <td>rushing/frustration</td>
        <td></td>
        <td>collection of sample on time / increase transit frequency</td>
        </tr>
        <tr>
        <td>8</td>
        <td>Knowledge of safe handling of chemicals</td>
        <td>Maintenance</td>
        <td>to be improved</td>
        <td>Y</td>
        <td>Skill level 3</td>
        <td>low awareness</td>
        <td>complacency</td>
        <td></td>
        <td>conduct training, display MSDS</td>
        </tr>
        <tr>
        <td>9</td>
        <td>Knowledge and follow of safe handling while loading unloading</td>
        <td>Maintenance</td>
        <td>to be improved</td>
        <td>Y</td>
        <td>Skill level 3</td>
        <td>low awareness</td>
        <td>complacency</td>
        <td></td>
        <td>conduct training,display MSDS</td>
        </tr>
      </table></br></br>
       <h3>QUALITY-POLYMER</h3>
      <table>
      <tr>
      <th>S.No.</th>
      <th>Observed Behaviors</th>
      <th>SafeStart Auditor</th>
      <th>Frequency</th>
      <th>Y/N</th>
      <th>Target</th>
      <th>Variance</th>
      <th>Why(use dropdown)</th>
      <th>Cost of non-confirmance</th>
      <th>ActionSteps</th>
    </tr>
    <tr>
    <td>1</td>
    <td>Observed behaviors while clearance of dispatch </td>
    <td>Maintenance</td>
    <td>daily 1 OCC</td>
    <td>Y</td>
    <td>Zero rushing</td>
    <td>people skill level</td>
    <td>rushing</td>
    <td></td>
    <td >improve people skill through training</td>

  </tr>
  <tr>
  <td>2</td>
  <td>Following SOPs during QC of RM </td>
  <td>Maintenance</td>
  <td>Nil</td>
  <td>N</td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>

</tr>
<tr>
<td>3</td>
<td>Safety procedures not followed due to work to be done mindset</td>
<td>Maintenance</td>
<td>Nil</td>
<td>N</td>
<td>-</td>
<td></td>
<td>-</td>
<td></td>
<td></td>

</tr>
<tr>
<td>4</td>
<td>Follow process while quality checks </td>
<td>Maintenance</td>
<td>1 occ / week</td>
<td>Y</td>
<td>eliminate fatigue / frustration</td>
<td>multiple queries</td>
<td>fatigue / frustration</td>
<td></td>
<td>implement and follow daily work management</td>

</tr>
<tr>
<td>5</td>
<td>Raised issues immediately where safety hazards observed</td>
<td>Maintenance</td>
<td>Nil</td>
<td>N</td>
<td>-</td>
<td></td>
<td></td>
<td></td>
<td>look out for safety deviations and raise whenever observed</td>

</tr>
<tr>
<td>6</td>
<td>Testing with proper PPE Followed </td>
<td>Maintenance</td>
<td>Followed</td>
<td>N</td>
<td>sustain 100% adherence</td>
<td></td>
<td></td>
<td></td>
<td></td>

</tr>
            <tr>
            <td>8</td>
            <td>Knowledge of safe handling of chemicals </td>
            <td>Maintenance</td>
            <td>Good knowledge level observed</td>
            <td>N</td>
            <td>Sustain</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>

          </tr>
          <tr>
            <td>9</td>
            <td>Knowledge and follow of safe handling while loading unloading </td>
            <td>Maintenance</td>
            <td>Good knowledge level observed</td>
            <td>N</td>
            <td>Sustain</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>

          </tr>
      </table> </br></br>
      <h3>QUALITY-THIN WHEEL</h3>
      <table>
      <tr>
      <th>S.No.</th>
      <th>Observed Behaviors</th>
      <th>SafeStart Auditor</th>
      <th>Frequency</th>
      <th>Y/N</th>
      <th>Target</th>
      <th>Variance</th>
      <th>Why(use dropdown)</th>
      <th>Cost of non-confirmance</th>
      <th>ActionSteps</th>
    </tr>

    <tr>
    <td>1</td>
    <td>Observed behaviors while clearance of dispatch </td>
    <td>Maintenance</td>
    <td>daily</td>
    <td>Y</td>
    <td>Zero rushing</td>
    <td>various issues</td>
    <td>rushing</td>
    <td></td>
    <td>address various issues and resolve</td>

  </tr>
  <tr>
    <td>2</td>
    <td>Following SOPs during QC of RM </td>
    <td>Maintenance</td>
    <td>Nil</td>
    <td>N</td>
    <td>-</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>

  </tr>
  <tr>
    <td>3</td>
    <td>Safety procedures not followed due to work to be done mindset</td>
    <td>Maintenance</td>
    <td>Nil</td>
    <td>N</td>
    <td>-</td>
    <td></td>
    <td>-</td>
    <td></td>
    <td></td>

  </tr>
  <tr>
    <td>4</td>
    <td>Follow process while quality checks </td>
    <td>Maintenance</td>
    <td>fatigue &amp; frustration</td>
    <td>Y</td>
    <td>eliminate fatigue / frustration</td>
    <td></td>
    <td>multiple queries</td>
    <td></td>
    <td>implement and follow daily work management</td>

  </tr>
  <tr>
    <td>5</td>
    <td>Raised issues immediately where safety hazards observed</td>
    <td>Maintenance</td>
    <td>Nil</td>
    <td>N</td>
    <td>-</td>
    <td></td>
    <td></td>
    <td></td>
    <td>look out for safety deviations and raise whenever observed</td>

  </tr>
  <tr>
    <td>6</td>
    <td>Testing with proper PPE Followed </td>
    <td>Maintenance</td>
    <td>not followed</td>
    <td>Y</td>
    <td>100% adherence</td>
    <td>low awareness</td>
    <td>complacency</td>
    <td></td>
    <td>conduct training</td>

  </tr>
  <tr>
    <td>7</td>
    <td>Sample collection as per process</td>
    <td>Maintenance</td>
    <td>Nil</td>
    <td>N</td>
    <td>-</td>
    <td></td>
    <td></td>
    <td>></td>
    <td></td>

  </tr>
  <tr>
    <td>8</td>
    <td>Knowledge of safe handling of chemicals </td>
    <td>Maintenance</td>
    <td>to be improved</td>
    <td>Y</td>
    <td>Skill level 3</td>
    <td>low awareness</td>
    <td>complacency</td>
    <td></td>
    <td>conduct training</td>

  </tr>
  <tr>
    <td>9</td>
    <td>Knowledge and follow of safe handling while loading unloading </td>
    <td>Maintenance</td>
    <td>to be improved</td>
    <td>Y</td>
    <td>Skill level 3</td>
    <td>low awareness</td>
    <td>complacency</td>
    <td></td>
    <td>conduct training</td>

  </tr>
    </table>`,
      `
      ${style}
      <h3>SAFESTART AUDIT FOR MM NAGAR PLANT</h3>
      <table>
      <tr>
      <td>S.No. </td>
      <td>Observed Behaviors </td>
      <td>SafeStart Auditor</td>
      <td>Frequency</td>
      <td>Y/N</td>
      <td>Target</td>
      <td>Variance</td>
      <td>Why(use dropdown)</td>
      <td>Cost of non-confirmance</td>
      <td>ActionSteps</td>
        </tr>
        <tr>
            <td>1</td>
            <td>Knowledge on hazardous chemicals </td>
            <td>Production </td>
            <td>Regular - 2 out of 10 Occasions</td>
            <td>Y</td>
            <td>100% Competency</td>
            <td>Insufficient MSDS Display Board</td>
            <td>Complacency</td>
            <td>Injury</td>
            <td>Displays to be incresed</td>
            </tr>
            <tr>
            <td>2</td>
            <td>competency matrix for commercial department</td>
            <td>Production </td>
            <td>3 out of 10 ocassions</td>
            <td>Y</td>
            <td>100% Competency</td>
            <td></td>
            <td>Fatigue</td>
            <td>Injury</td>
            <td>Competency matrix to be done for commercial</td>
            </tr>
            <tr>
            <td>3</td>
            <td>List of all hazardous chemical available at department</td>
            <td>Production </td>
            <td>10 out of 10 occassions</td>
            <td>Y</td>
            <td>100% Compliance</td>
            <td>Nil</td>
            <td>Fatigue</td>
            <td >Injury</td>
            <td class="column9 style5 s">Nil</td>
            </tr>
            <tr>
            <td>4</td>
            <td>Matrix displayed with list of hazardous chemicals in department</td>
            <td>Production </td>
            <td>10 out of 10 Ocassions</td>
            <td>Y</td>
            <td>100% Compliance</td>
            <td>Nil</td>
            <td>Complacency</td>
            <td>Injury</td>
            <td>Nil</td>
            </tr>

            <tr>
            <td>5</td>
            <td>How often this matrix is updated </td>
            <td>Production </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>Complacency</td>
            <td></td>
            <td></td>
            </tr>
            <tr>
            <td>6</td>
            <td>MSDS displayed at store and production (recommended locations) </td>
            <td>Production </td>
            <td>Display available 1 out of 10</td>
            <td>Y</td>
            <td>100% Compliance</td>
            <td>Nil</td>
            <td>Complacency</td>
            <td>Injury</td>
            <td>MSDS Display to be incresed inside stores</td>
            </tr>
            <tr>
            <td>7</td>
            <td>Importance given to safety at vendor selection stage</td>
            <td>Production </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
            <tr>
            <td>8</td>
            <td>Have you rejected vendor on safety issues? </td>
            <td>Production </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
            <tr>
            <td>9</td>
            <td>Reference of vendors for safety from other customers</td>
            <td>Production</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
            <tr>
            <td>10</td>
            <td>Vendor audit covers issues on safety</td>
            <td>Production</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
            <tr>
            <td>11</td>
            <td>Safety procedure for handling hazardous chemicals </td>
            <td>Production </td>
            <td>10 Out of 10 Ocassions</td>
            <td>Y</td>
            <td>100% Compliance</td>
            <td>Nil</td>
            <td>Complacency</td>
            <td>Injury</td>
            <td></td>
            </tr>
            <tr>
            <td>12</td>
            <td>Trained teams on Hazardous Chemicals</td>
            <td>Production </td>
            <td>5 out of 10 ocassions</td>
            <td>Y</td>
            <td>100% Compliance</td>
            <td>Nil</td>
            <td>Complacency</td>
            <td>Injury</td>
            <td>Training should be provided on Handling and usage of Hazardous Chemicals</td>
             </tr>
             <tr>
             <td>13</td>
             <td>random check on knowledge of Hazardous Chemicals amongst team members</td>
             <td>Production </td>
             <td>5 out of 10 ocassions</td>
             <td>Y</td>
             <td>100% Compliance</td>
             <td>Nil</td>
             <td>Complacency</td>
             <td>Injury</td>
             <td>Training should be provided on Handling and usage of Hazardous Chemicals</td>
             </tr>
             <tr>
            <td>14</td>
            <td>Proper storage of raw material as per regulations</td>
            <td>Production </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>Complacency</td>
            <td></td>
            <td></td>
             </tr>
             <tr>
             <td>15</td>
             <td>Handlig of chemicals in storage area </td>
             <td>Production </td>
             <td>5 out of 10 ocassions</td>
             <td>Y</td>
             <td>100 % compliance</td>
             <td>Nil</td>
             <td>Complacency</td>
             <td>Injury</td>
             <td>Handling machines like barrel lifter / hand pallets should be used</td>
             </tr>
             <tr>
             <td>16</td>
             <td>FIFO system followed </td>
             <t>Production </td>
             <td>9 out of 10 ocassions</td>
             <td>Y</td>
             <td>100 % compliance</td>
             <td>Nil</td>
             <td>Complacency</td>
             <td>Injury</td>
             <td>FIFO - IN Cloth should be relooked</td>
             </tr>
             <tr class="row19">
            <td>17</td>
            <td>Strict adhearance to policy and procedures</td>
            <td>Production </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>Complacency</td>
            <td></td>
            <td></td>
             </tr>
             <tr>
             <td>18</td>
             <td>Attitude towards cleanliness in the storage space</td>
             <td>Production </td>
             <td>1 out of 10 times</td>
             <td>Y</td>
             <td>100 % compliance</td>
             <td>Floor and acessing zone house keeping to be checked</td>
             <td>Complacency</td>
             <td>Injury</td>
             <td></td>
             </tr>
             <tr>
             <td>19</td>
             <td>Ordering material </td>
             <td>Production </td>
             <td></td>
             <td></td>
             <td></td>
             <td></td>
             <td>Complacency</td>
             <td></td>
             <td></td>
             </tr>
             <tr>
            <td>20</td>
            <td>Handlilng of material while loading and unloading </td>
            <td>Production </td>
            <td>4 out of 10 ocassions</td>
            <td>Y</td>
            <td>100 % compliance</td>
            <td>Improper facility / equipment </td>
            <td>Complacency</td>
            <td>Injury</td>
            <td>Fork Lift for unloading Jumbos + Unloading canopy</td>
             </tr>
      </table>`,
      `
      ${style}
      <table>
      <tr>
      <td>S.No. </td>
      <td>Observed Behaviors </td>
      <td>SafeStart Auditor</td>
      <td>Frequency</td>
      <td>Y/N </td>
      <td>Target </td>
      <td>Variance </td>
      <td>Why  (use dropdown) </td>
      <td>Cost of non-confirmance</td>
      <td>ActionSteps</td>
        </tr>
        <tr>
        <td>1</td>
        <td>Safety gaurds available at all machines</td>
        <td>Commericial</td>
        <td>5 out of 10 ocassions</td>
        <td>Y</td>
        <td>100% Compliance</td>
        <td>BRP PRESS, Piling, BSP 01 - SIDE Guard Ladder Handrail</td>
        <td>Complacency</td>
        <td>Injury</td>
        <td></td>
        </tr>
        <tr>
        <td>2</td>
        <td>Oil leakages observed by auditor </td>
        <td>Commericial</td>
        <td>10 out of 10 ocassions</td>
        <td>Y</td>
        <td>100% Compliance</td>
        <td></td>
        <td>Complacency</td>
        <td>Injury</td>
        <td></td>
        </tr>
        <tr>
            <td>3</td>
            <td>Electrical department rushing to switch on the DG to regulate power supply </td>
            <td>Commericial</td>
            <td>10 out of 10 ocassions</td>
            <td>Y</td>
            <td>100% Compliance</td>
            <td></td>
            <td>Rushing</td>
            <td>Injury</td>
            <td></td>
        </tr>
        <tr>
        <td>4</td>
        <td>Height work as per PPE </td>
        <td>Commericial</td>
        <td>10 out of 10 ocassions</td>
        <td>Y</td>
        <td>100% Compliance</td>
        <td>Tunnel Oven Height work </td>
        <td>Rushing, Complacency</td>
        <td>Injury</td>
        <td></td>
        </tr>
        <tr>
        <td>5</td>
        <td>Overtiming in nthe last one month </td>
        <td>Commericial</td>
        <td>5 out of 10 ocassions</td>
        <td>Y</td>
        <td>100% Compliance</td>
        <td>Over work in Hazardous operations should be avoided</td>
        <td>Fatigue</td>
        <td>Injury</td>
        <td></td>
        </tr>
        <tr>
        <td>6</td>
        <td>LOTO followed for electrical breakdowns </td>
        <td>Commericial</td>
        <td>5 out of 10 ocassions</td>
        <td>Y</td>
        <td>100% Compliance</td>
        <td>Knowledge or importance of LOTO should be transferred down the line</td>
        <td>Complacency</td>
        <td>Injury</td>
        <td>Maintainance Dept - LOTO Training </td>
        </tr>
        <tr>
        <td>7</td>
        <td>Welding work done on unsafe platforms </td>
        <td>Commericial</td>
        <td>5 out of 10 ocassions</td>
        <td>Y</td>
        <td>100% Compliance</td>
        <td>Welding in flammable zones</td>
        <td>Complacency</td>
        <td>Fire / Explosion</td>
        <td></td>

        </tr>
        <tr>
        <td>8</td>
        <td>Naked wiring observed and handled </td>
        <td>Commericial</td>
        <td>1 out of 10 Ocassions</td>
        <td>Y</td>
        <td>100% Compliance</td>
        <td>ELCB / MCB</td>
        <td>Complacency</td>
        <td>Fire / Shock </td>
        <td></td>
        </tr>
        <tr>
            <td>9</td>
            <td>Confined space knowledge to all maintence workers </td>
            <td>Commericial</td>
            <td>2 out of 10 Ocassions</td>
            <td>Y</td>
            <td>100% Compliance</td>
            <td></td>
            <td>Complacency, Fatigue</td>
            <td>Injury</td>
            <td>Confined Space Safety training </td>
        </tr>
        <tr>
        <td >10</td>
        <td >Confiend space SOP followed </td>
        <td>Commericial</td>
        <td>2 out of 10 Ocassions</td>
               <td>Y</td>
               <td>100% Compliance</td>
               <td></td>
               <td>Complacency, Fatigue</td>
               <td>Injury</td>
        <td class="column9 style7 s">Confined Space Safety training </td>
        </tr>
        <tr>
            <td>11</td>
            <td>Boiler maintence kowledge and SOP followed </td>
            <td>Commericial</td>
            <td >4 ot of 10 Ocassions</td>
            <td>Y</td>
                   <td>100% Compliance</td>
                   <td></td>
                   <td>Complacency,Fatigue</td>
                   <td>Injury</td>
                   <td></td>
        </tr>
        <tr>
                <td>12</td>
                <td>ETP area knowledge and SOP followwed </td>
                <td>Commericial</td>
                <td>4 ot of 10 Ocassions</td>
               <td>Y</td>
               <td>100% Compliance</td>
               <td></td>
               <td>Complacency,Fatigue</td>
               <td>Injury</td>
               <td></td>
        </tr>

      </table>`,
      `
    ${style}
    <h3>SAFESTART AUDIT FOR MM NAGAR PLANT</h3>
    <table>
    <tr>
    <td class="column0 style4 s">S.No. </td>
    <td class="column1 style4 s">Observed Behaviors </td>
    <td class="column2 style4 s">SafeStart Auditor</td>
    <td class="column3 style4 s">Frequency</td>
    <td class="column4 style4 s">Y/N </td>
    <td class="column5 style4 s">Target </td>
    <td class="column6 style4 s">Variance </td>
    <td class="column7 style4 s">Why  (use dropdown) </td>
    <td class="column8 style4 s">Cost of non-confirmance</td>
    <td class="column9 style4 s">ActionSteps</td>
    </tr>
    <tr>
            <td>1</td>
            <td>competency matrix for department</td>
                   <td>Quality</td>
                   <td>10 Out of 10 Ocassions</td>
                   <td>Y</td>
                   <td>100% Compliance</td>
                   <td>NIL</td>
            <td>Complacency</td>
                   <td>Injury</td>
                   <td>Nil</td>
    </tr>
    <tr>
    <td>2</td>
    <td>PPE as per matrix </td>
           <td>Quality</td>
           <td>5 Out of 10 Ocassions</td>
           <td>Y</td>
           <td>100% Compliance</td>
           <td>Cloth Modul, Polymer &amp; Lab displays to be improved</td>
          <td>Complacency</td>
           <td>Injury</td>
           <td>Displays &amp; Training to be intitiated</td>
    </tr>
    <tr>
            <td>3</td>
            <td>Proper storage and handling  of chemicals </td>
                   <td>Quality</td>
                   <td>5 Out of 10 Ocassions</td>
                   <td>Y</td>
                   <td>100% Compliance</td>
                   <td>Mixing area ( TW, Cloth, Polymer ) to be improved</td>
            <td>Complacency</td>
                   <td>Injury</td>
                   <td>Training to be strengthned</td>
    </tr>
    <tr>
    <td>4</td>
    <td>Near misses reported </td>
           <td>Quality</td>
           <td>5 Out of 10 Ocassions</td>
           <td>Y</td>
           <td>100% Compliance</td>
           <td>Cloth Module Polymer should be improved</td>
    <td>Complacency</td>
           <td>Injury</td>
           <td>Awareness displays to be done</td>
    </tr>
    <tr>
            <td>5</td>
            <td>Random knowledge check of workers on safety process </td>
                   <td>Quality</td>
                   <td>5 Out of 10 Ocassions</td>
                   <td>Y</td>
                   <td>100% Compliance</td>
                   <td>Knowledge on Kaizens, Limit switches, Poke Yokes should be brought down the line</td>
            <td>Complacency</td>
                   <td>Injury</td>
                   <td>Training</td>
    </tr>
    <tr>
            <td>6</td>
            <td>Dry floor conditions </td>
                   <td>Quality</td>
                   <td>2 out of 10 Ocassions</td>
                   <td>Y</td>
                   <td>100% Compliance</td>
                   <td>Cloth mixing, Polymer Barrel area</td>
            <td>Complacency</td>
                   <td>Injury</td>
                   <td>Training</td>
    </tr>
    <tr>
            <td>7</td>
            <td>Floor damage and timelines ffor repair </td>
                   <td>Quality</td>
                   <td>5 Out of 10 Ocassions</td>
                   <td>Y</td>
                   <td>100% Compliance</td>
                   <td>Cloth shop floor, Moulding - TW</td>
            <td>Complacency</td>
                   <td>Slip /Trip Hazard</td>
                   <td>Floor condition improvement</td>
    </tr>
    <tr>
    <td>8</td>
    <td>Ergonomics for handling material </td>
           <td>Quality</td>
           <td>4 out of 10 ocassions</td>
           <td>Y</td>
           <td>100 % compliance</td>
           <td>Improper facility / equipment </td>
           <td>Complacency</td>
           <td>Injury</td>
           <td>Fork Lift for unloading Jumbos + Unloading canopy</td>
    </tr>
    <tr>
          <td>9</td>
           <td>Following SOPS </td>
           <td>Quality</td>
           <td>10 Out of 10 Ocassions</td>
    <td>Y</td>
           <td>100 % compliance</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    </tr>
    <tr>
    <td>10</td>
    <td>Not Removal of safety gaurds in any circumstances </td>
           <td>Quality</td>
           <td>4 out of 10 ocassions</td>
           <td>Y</td>
           <td>100%</td>
           <td>BRP Guards, BSP Guards to be checked </td>
           <td>Complacency</td>
           <td>Injury</td>
           <td></td>
    </tr>
    <tr>
          <td>11</td>
           <td>Shortcut to operations</td>
           <td>Quality</td>
           <td>4 out of 10 ocassions</td>
           <td>Y</td>
           <td>100%</td>
           <td>BRP Guards, BSP Guards to be checked </td>
           <td>Complacency</td>
           <td>Injury</td>
    <td ></td>
    </tr>
    <tr>
                  <td>12</td>
                   <td>Station wise procedural check on following SOP </td>
                   <td>Quality</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
    </tr>
    <tr>
          <td>13</td>
           <td>Material handling done as per the recommendation of Safe Working Conditions</td>
           <td>Quality</td>
    <td >4 out of 10 ocassions</td>
           <td>Y</td>
           <td>100 % compliance</td>
           <td>Improper facility / equipment </td>
    <td>Complacency</td>
           <td>Injury</td>
           <td>Fork Lift for unloading Jumbos + Unloading canopy</td>
    </tr>
    <tr>
                  <td>14</td>
                   <td>Workers knowledge on process </td>
                   <td>Quality</td>
            <td >3 out of 10 ocassions</td>
                   <td>Y</td>
            <td >100% Competency</td>
                   <td></td>
            <td >Fatigue</td>
            <td >Injury</td>
                 <td>Competency matrix to be done for commercial</td>
    </tr>
    <tr>
    <td >15</td>
    <td >emergency situation awareness </td>
    <td >Quality</td>
          <td>5 Out of 10 Ocassions</td>
           <td>Y</td>
    <td >100% Competency</td>
    <td >New Joinees to be trained on emergency evacuation</td>
    <td >Fatigue</td>
    <td >Injury</td>
    <td ></td>
    </tr>
    <tr>
                  <td>16</td>
                   <td>Mock drills done regularly </td>
                   <td>Quality</td>
                  <td>5 Out of 10 Ocassions</td>
                   <td>Y</td>
            <td>100% Competency</td>
            <td>New Joinees to be trained on emergency evacuation</td>
            <td>Fatigue</td>
            <td>Injury</td>
            <td></td>
    </tr>
    <tr>
                  <td>17</td>
                   <td>Immediate first aid process known and followed </td>
                   <td>Quality</td>
                  <td>5 Out of 10 Ocassions</td>
                   <td>Y</td>
            <td>100% Competency</td>
            <td>First Aid training is periodically given</td>
            <td>Complacency</td>
            <td>NIL</td>
                 <td>NIL</td>
    </tr>
    <tr>
          <td>18</td>
           <td>Supervisor briefing </td>
           <td>Quality</td>
    <td class="column3 style9 null"></td>
           <td>Y</td>
           <td>100% Competency</td>
           <td></td>
           <td></td>
           <td></td>
         <td>OJT - 100%</td>
    </tr>
    <tr>
          <td>19</td>
           <td>Proper tools stored at right place </td>
           <td>Quality</td>
          <td>5 Out of 10 Ocassions</td>
           <td>Y</td>
           <td>100% Competency</td>
           <td>Proper storage area to be provided with display </td>
           <td>Fatigue</td>
           <td>Injury</td>
           <td>Lock and Key to be provided</td>
    </tr>
    <tr>
                  <td>20</td>
                   <td>Proper tools for proper jobs </td>
                   <td>Quality</td>
                  <td>5 Out of 10 Ocassions</td>
                   <td>Y</td>
                   <td>100% Competency</td>
                   <td>Proper storage area to be provided with display </td>
                   <td>Fatigue</td>
                   <td>Injury</td>
                   <td>Lock and Key to be provided</td>
    </tr>
    <tr>
          <td>21</td>
           <td>Clearning, inspection done every week by supervisor </td>
           <td>Quality</td>
          <td>5 Out of 10 Ocassions</td>
           <td>Y</td>
           <td>100% Competency</td>
           <td>NIL</td>
           <td>Complacency</td>
           <td>Injury</td>
           <td>Cleaning check list to be done</td>
    </tr>




    </table>`
    ],
    // tabTitle: ["QUALITY", "COMMERCIALS", "MAINTENANCE","PRODUCTION"],
    tabContent1: [
      `
      ${style}
      <h3>QUALITY-CLOTH</h3>
      <table>
        <tr>
          <th>S.No.</th>
          <th>Observed Behaviors</th>
          <th>SafeStart Auditor</th>
          <th>Frequency</th>
          <th>Y/N</th>
          <th>Target</th>
          <th>Variance</th>
          <th>Why(use dropdown)</th>
          <th>Cost of non-confirmance</th>
          <th>ActionSteps</th>
        </tr>
        <tr>
            <td>1</td>
            <td>Observed behaviors while clearance of dispatch </td>
            <td>Maintenance</td>
            <td>daily - 2 out of 7 occ</td>
            <td>Y</td>
            <td>Zero rushing</td>
            <td>jumbo storage improper</td>
            <td>rushing</td>
            <td></td>
            <td>advance information of extract - 1 day in advance</td>
        </tr>
        <tr>
        <td>2</td>
        <td>Following SOPs during QC of RM </td>
        <td>Maintenance</td>
        <td>daily - 2 out of 7 occ</td>
        <td>Y</td>
        <td>Zero rushing</td>
        <td></td>
        <td>rushing</td>
        <td></td>
        <td></td>
        </tr>
        <tr>
        <td>3</td>
        <td>Safety procedures not followed due to work to be done mindset</td>
        <td>Maintenance</td>
        <td>Nil</td>
        <td>N</td>
        <td>-</td>
        <td></td>
        <td>-</td>
        <td></td>
        <td></td>
        </tr>
        <tr>
        <td>4</td>
        <td>Follow process while quality checks </td>
        <td>Maintenance</td>
        <td>fatigue</td>
        <td>Y</td>
        <td>eliminate fatigue</td>
        <td>jumbo storage improper</td>
        <td>fatigue</td>
        <td></td>
        <td>jumbo for dispatch to be kept separately in proper manner</td>
        </tr>
        <tr>
        <td>5</td>
        <td>Raised issues immediately where safety hazards observed</td>
        <td>Maintenance</td>
        <td>2 issues per day</td>
        <td>Y</td>
        <td>Zero issues</td>
        <td></td>
        <td></td>
        <td></td>
        <td>actions for safety issues to be implemented immediately</td>
        </tr>
        <tr>
            <td>6</td>
            <td>Testing with proper PPE Followed </td>
            <td>Maintenance</td>
            <td>not followed</td>
            <td>Y</td>
            <td>100% adherence</td>
            <td>low awareness</td>
            <td>complacency</td>
            <td></td>
            <td>conduct training and ensure usage of PPE</td>
        </tr>
        <tr>
            <td>7</td>
            <td>Sample collection as per process</td>
            <td>Maintenance</td>
            <td>1 occ / day</td>
            <td>Y</td>
            <td>Zero rushing</td>
            <td>time delays</td>
            <td>rushing / frustration</td>
            <td></td>
            <td>collection of sample on time / increase transit frequency</td>
        </tr>
        <tr>
        <td>8</td>
        <td>Knowledge of safe handling of chemicals </td>
        <td>Maintenance</td>
        <td>to be improved</td>
        <td>Y</td>
        <td>Skill level 3</td>
        <td>low awareness</td>
        <td>complacency</td>
        <td></td>
        <td>conduct training, display MSDS</td>
        </tr>
        <tr>
            <td>9</td>
            <td>Knowledge and follow of safe handling while loading unloading </td>
            <td>Maintenance</td>
            <td>to be improved</td>
            <td>Y</td>
            <td>Skill level 3</td>
            <td>low awareness</td>
            <td>complacency</td>
            <td></td>
            <td>conduct training, display MSDS</td>
        </tr>

      </table>
      </br></br>
       <h3>QUALITY-POLYMER</h3>
      <table>
    <tr>
      <th>S.No.</th>
      <th>Observed Behaviors</th>
      <th>SafeStart Auditor</th>
      <th>Frequency</th>
      <th>Y/N</th>
      <th>Target</th>
      <th>Variance</th>
      <th>Why(use dropdown)</th>
      <th>Cost of non-confirmance</th>
      <th>ActionSteps</th>
    </tr>
    <tr>
    <td>1</td>
    <td>Observed behaviors while clearance of dispatch </td>
    <td>Maintenance</td>
    <td>daily 1 OCC</td>
    <td>Y</td>
    <td>Zero rushing</td>
    <td>people skill level</td>
    <td>rushing</td>
    <td></td>
    <td>improve people skill through training</td>
    </tr>
    <tr>
            <td>2</td>
            <td>Following SOPs during QC of RM </td>
            <td>Maintenance</td>
            <td>Nil</td>
            <td>N</td>
            <td>-</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
    </tr>
    <tr>
    <td>3</td>
    <td>Safety procedures not followed due to work to be done mindset</td>
    <td>Maintenance</td>
    <td>Nil</td>
    <td>N</td>
    <td>-</td>
    <td></td>
    <td>-</td>
    <td></td>
    <td></td>
    </tr>
    <tr>
    <td>4</td>
    <td>Follow process while quality checks </td>
    <td>Maintenance</td>
    <td>1 occ / week</td>
    <td>Y</td>
    <td>eliminate fatigue / frustration</td>
    <td>multiple queries</td>
    <td>fatigue / frustration</td>
    <td></td>
    <td>implement and follow daily work management</td>
    </tr>
    <tr>
    <td>5</td>
    <td>Raised issues immediately where safety hazards observed</td>
    <td>Maintenance</td>
    <td>Nil</td>
    <td>N</td>
    <td>-</td>
    <td></td>
    <td></td>
    <td></td>
    <td>look out for safety deviations and raise whenever observed</td>
    </tr>
    <tr>
    <td>6</td>
    <td>Testing with proper PPE Followed </td>
    <td>Maintenance</td>
    <td>Followed</td>
    <td>N</td>
    <td>sustain 100% adherence</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    </tr>
    <tr>
            <td>7</td>
            <td>Sample collection as per process</td>
            <td>Maintenance</td>
            <td>Nil</td>
            <td>N</td>
            <td>-</td>
            <td></td>
            <td>&nbsp;</td>
            <td></td>
            <td></td>
    </tr>
    <tr>
    <td>8</td>
    <td>Knowledge of safe handling of chemicals </td>
    <td>Maintenance</td>
    <td>Good knowledge level observed</td>
    <td>N</td>
    <td>Sustain</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    </tr>
    <tr>
            <td>9</td>
            <td>Knowledge and follow of safe handling while loading unloading </td>
            <td>Maintenance</td>
            <td>Good knowledge level observed</td>
            <td>N</td>
            <td>Sustain</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
    </tr>


      </table> </br></br>
      <h3>QUALITY-THIN WHEEL</h3>
      <table>
      <tr>
      <th>S.No.</th>
      <th>Observed Behaviors</th>
      <th>SafeStart Auditor</th>
      <th>Frequency</th>
      <th>Y/N</th>
      <th>Target</th>
      <th>Variance</th>
      <th>Why(use dropdown)</th>
      <th>Cost of non-confirmance</th>
      <th>ActionSteps</th>
    </tr>
    <tr>
    <td>1</td>
    <td>Observed behaviors while clearance of dispatch </td>
    <td>Maintenance</td>
    <td>daily</td>
    <td>Y</td>
    <td>Zero rushing</td>
    <td>various issues</td>
    <td>rushing</td>
    <td></td>
    <td>address various issues and resolve</td>
    </tr>
    <tr>
    <td>2</td>
    <td>Following SOPs during QC of RM </td>
    <td>Maintenance</td>
    <td>Nil</td>
    <td>N</td>
    <td>-</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    </tr>
    <tr>
    <td>3</td>
    <td>Safety procedures not followed due to work to be done mindset</td>
    <td>Maintenance</td>
    <td>Nil</td>
    <td>N</td>
    <td>-</td>
    <td></td>
    <td>-</td>
    <td></td>
    <td></td>
    </tr>
    <tr>
    <td>4</td>
    <td>Follow process while quality checks </td>
    <td>Maintenance</td>
    <td>fatigue &amp; frustration</td>
    <td>Y</td>
    <td>eliminate fatigue / frustration</td>
    <td></td>
    <td>multiple queries</td>
    <td></td>
    <td>implement and follow daily work management</td>
    </tr>
    <tr>
    <td>5</td>
    <td>Raised issues immediately where safety hazards observed</td>
    <td>Maintenance</td>
    <td>Nil</td>
    <td>N</td>
    <td>-</td>
    <td></td>
    <td></td>
    <td></td>
    <td>look out for safety deviations and raise whenever observed</td>
    </tr>
    <tr>
    <td>6</td>
    <td>Testing with proper PPE Followed </td>
    <td>Maintenance</td>
    <td>not followed</td>
    <td>Y</td>
    <td>100% adherence</td>
    <td>low awareness</td>
    <td>complacency</td>
    <td></td>
    <td>conduct training</td>
    </tr>
    <tr>
    <td>7</td>
    <td>Sample collection as per process</td>
    <td>Maintenance</td>
    <td>Nil</td>
    <td>N</td>
    <td>-</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    </tr>
    <tr>
    <td>8</td>
    <td>Knowledge of safe handling of chemicals </td>
    <td>Maintenance</td>
    <td>to be improved</td>
    <td>Y</td>
    <td>Skill level 3</td>
    <td>low awareness</td>
    <td>complacency</td>
    <td></td>
    <td>conduct training</td>
    </tr>
    <tr>
    <td>9</td>
    <td>Knowledge and follow of safe handling while loading unloading </td>
    <td>Maintenance</td>
    <td>to be improved</td>
    <td>Y</td>
    <td>Skill level 3</td>
    <td>low awareness</td>
    <td>complacency</td>
    <td></td>
    <td>conduct training</td>
    </tr>


    </table>`,
      `
      ${style}

      <table>
      <tr>
      <td>S.No.</td>
      <td>Observed Behaviors </td>
      <td>SafeStart Auditor</td>
      <td>Frequency</td>
      <td>12:30pm 11 June </td>
      <td>Y/N </td>
      <td>Target </td>
      <td>Variance </td>
      <td>Why  (use dropdown) </td>
      <td>Cost of non-confirmance</td>
      <td>ActionSteps</td>
      </tr>
      <tr>
      <td>1</td>
      <td>Knowledge on hazardous chemicals </td>
      <td>Production </td>
      <td>Regular - 2 out of 10 Occasions</td>
      <td></td>
      <td>Y</td>
      <td>100% Cometency</td>
      <td>Insufficient MSDS Display Board</td>
      <td>Complacency</td>
      <td>Injury</td>
      <td>Displays to be incresed</td>
      </tr>
      <tr>
      <td>2</td>
      <td>competency matrix for commercial department</td>
      <td>Production </td>
      <td>3 out of 10 ocassions</td>
      <td>0</td>
      <td>Y</td>
      <td>100% Competency</td>
      <td></td>
      <td>Fatigue</td>
      <td>Injury</td>
      <td>Competency matrix to be done for commercial</td>
      </tr>
      <tr>
      <td>3</td>
      <td>List of all hazardous chemical available at department</td>
      <td>Production </td>
      <td>10 out of 10 occassions</td>
      <td>only one module but it is suppose to be in three modules </td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td>Nil</td>
      <td>Fatigue</td>
      <td>Injury</td>
      <td>Nil</td>
      </tr>
      <tr>
      <td>4</td>
      <td>Matrix displayed with list of hazardous chemicals in department</td>
      <td>Production </td>
      <td>10 out of 10 Ocassions</td>
      <td></td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td>Nil</td>
      <td>Complacency</td>
      <td>Injury</td>
      <td>Nil</td>
      </tr>
      <tr>
      <td>5</td>
      <td>How often this matrix is updated </td>
      <td>Production </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>Complacency</td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>6</td>
      <td>MSDS displayed at store and production (recommended locations) </td>
      <td>Production </td>
      <td>Display available 1 out of 10</td>
      <td></td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td>Nil</td>
      <td>Complacency</td>
      <td>Injury</td>
      <td>MSDS Display to be incresed inside stores</td>
      </tr>
      <tr>
      <td>7</td>
      <td >Importance given to safety at vendor selection stage</td>
      <td>Production </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>8</td>
      <td>Have you rejected vendor on safety issues? </td>
      <td>Production </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>9</td>
      <td>Reference of vendors for safety from other customers</td>
      <td>Production </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>10</td>
      <td>Vendor audit covers issues on safety</td>
      <td>Production </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>11</td>
      <td>Safety procedure for handling hazardous chemicals </td>
      <td>Production </td>
      <td>10 Out of 10 Ocassions</td>
      <td></td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td>Nil</td>
      <td>Complacency</td>
      <td>Injury</td>
      <td></td>
      </tr>
      <tr>
      <td>12</td>
      <td>Trained teams on Hazardous Chemicals</td>
      <td>Production </td>
      <td>5 out of 10 ocassions</td>
      <td></td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td>Nil</td>
      <td>Complacency</td>
      <td>Injury</td>
      <td>Training should be provided on Handling and usage of Hazardous Chemicals</td>
      </tr>
      <tr>
      <td>13</td>
      <td>random check on knowledge of Hazardous Chemicals amongst team members</td>
      <td>Production </td>
      <td>5 out of 10 ocassions</td>
      <td></td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td>Nil</td>
      <td>Complacency</td>
      <td>Injury</td>
      <td>Training should be provided on Handling and usage of Hazardous Chemicals</td>
      </tr>
      <tr>
      <td>14</td>
      <td>Proper storage of raw material as per regulations</td>
      <td>Production </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>Complacency</td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>15</td>
      <td>Handlig of chemicals in storage area </td>
      <td>Production </td>
      <td>5 out of 10 ocassions</td>
      <td></td>
      <td>Y</td>
      <td>100 % compliance</td>
      <td>Nil</td>
      <td>Complacency</td>
      <td>Injury</td>
      <td>Handling machines like barrel lifter / hand pallets should be used</td>
      </tr>
      <tr>
      <tr class="row18">
            <td>16</td>
            <td>FIFO system followed </td>
            <td>Production </td>
            <td>9 out of 10 ocassions</td>
            <td></td>
            <td>Y</td>
            <td>100 % compliance</td>
            <td>Nil</td>
            <td>Complacency</td>
            <td>Injury</td>
            <td>FIFO - IN Cloth should be relooked</td>
      </tr>
      <tr>
      <td>17</td>
      <td>Strict adhearance to policy and procedures</td>
      <td>Production </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>Complacency</td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>18</td>
      <td>Attitude towards cleanliness in the storage space</td>
      <td>Production </td>
      <td>1 out of 10 times</td>
      <td></td>
      <td>Y</td>
      <td>100 % compliance</td>
      <td>Floor and acessing zone house keeping to be checked</td>
      <td>Complacency</td>
      <td>Injury</td>
      <td></td>
      </tr>
      <tr>
      <td>19</td>
      <td>Ordering material</td>
      <td>Production </td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>Complacency</td>
      <td></td>
      <td></td>
      </tr>
      <tr>
      <td>20</td>
      <td>Handlilng of material while loading and unloading </td>
      <td>Production </td>
      <td>4 out of 10 ocassions</td>
      <td></td>
      <td>Y</td>
      <td>100 % compliance</td>
      <td>Improper facility / equipment </td>
      <td>Complacency</td>
      <td>Injury</td>
      <td>Fork Lift for unloading Jumbos + Unloading canopy</td>
      </tr>

      </table>`,
      `
      ${style}
      <table>
      <tr>
      <td>S.No.</td>
            <td>Observed Behaviors </td>
            <td>SafeStart Auditor</td>
            <td>Frequency</td>
            <td>Y/N </td>
            <td>Target </td>
            <td>Variance </td>
            <td>Why (use dropdown) </td>
            <td>Cost of non-confirmance</td>
            <td>ActionSteps</td>
      </tr>
      <tr>
      <td>1</td>
      <td>Safety gaurds available at all machines</td>
      <td>Commericial</td>
      <td>5 out of 10 ocassions</td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td>BRP PRESS, Piling, BSP 01 - SIDE Guard Ladder Handrail</td>
      <td>Complacency</td>
      <td>Injury</td>
      <td></td>

      </tr>
      <tr>
      <td>2</td>
      <td>Oil leakages observed by auditor </td>
      <td>Commericial</td>
      <td>10 out of 10 ocassions</td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td></td>
      <td>Complacency</td>
      <td>Injury</td>
      <td></td>
      </tr>
      <tr>
      <td>3</td>
      <td>Electrical department rushing to switch on the DG to regulate power supply </td>
      <td>Commericial</td>
      <td>10 out of 10 ocassions</td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td></td>
      <td>Rushing</td>
      <td>Injury</td>
      <td></td>
      </tr>
      <tr>
      <td>4</td>
      <td>Height work as per PPE </td>
      <td>Commericial</td>
      <td>10 out of 10 ocassions</td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td>Tunnel Oven Height work </td>
      <td>Rushing, Complacency</td>
      <td>Injury</td>
      <td></td>
      </tr>
      <tr>
      <td>5</td>
      <td>Overtiming in nthe last one month </td>
      <td>Commericial</td>
      <td>5 out of 10 ocassions</td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td>Over work in Hazardous operations should be avoided</td>
      <td>Fatigue</td>
      <td>Injury</td>
      <td></td>
      </tr>
      <tr>
      <td>6</td>
      <td>LOTO followed for electrical breakdowns </td>
      <td>Commericial</td>
      <td>5 out of 10 ocassions</td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td>Knowledge or importance of LOTO should be transferred down the line</td>
      <td>Complacency</td>

      <td>Injury</td>
      <td>Maintainance Dept - LOTO Training</td>
      </tr>
      <tr>
      <td>7</td>
      <td>Welding work done on unsafe platforms </td>
      <td>Commericial</td>
      <td>5 out of 10 ocassions</td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td>Welding in flammable zones</td>
      <td>Complacency</td>
      <td>Fire / Explosion</td>
      <td></td>
      </tr>
      <tr>
      <td>8</td>
      <td>Naked wiring observed and handled </td>
      <td>Commericial</td>
      <td>1 out of 10 Ocassions</td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td>ELCB / MCB</td>
      <td>Complacency</td>
      <td>Fire / Shock </td>
      <td></td>
      </tr>
      <tr>
      <td>9</td>
      <td>Confined space knowledge to all maintence workers </td>
      <td>Commericial</td>
      <td>2 out of 10 Ocassions</td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td></td>
      <td>Complacency, Fatigue</td>
      <td>Injury</td>
      <td>Confined Space Safety training </td>
      </tr>
      <tr>
      <td>10</td>
      <td>Confiend space SOP followed </td>
      <td>Commericial</td>
      <td>2 out of 10 Ocassions</td>
      <td>Y</td>
      <td>100% Compliance</td>
      <td></td>
      <td>Complacency, Fatigue</td>
      <td>Injury</td>
      <td>Confined Space Safety training </td>
      </tr>
      <tr>
      <td>11</td>
            <td>Boiler maintence kowledge and SOP followed </td>
            <td>Commericial</td>
            <td>4 ot of 10 Ocassions</td>
            <td>Y</td>
            <td>100% Compliance</td>
            <td></td>
            <td>Complacency, Fatigue</td>
            <td>Injury</td>
            <td></td>
      </tr>
      <tr>
      <td>12</td>
            <td>ETP area knowledge and SOP followwed </td>
            <td>Commericial</td>
            <td>4 ot of 10 Ocassions</td>
            <td>Y</td>
            <td>100% Compliance</td>
            <td></td>
            <td>Complacency, Fatigue</td>
            <td>Injury</td>
            <td></td>
      </tr>


      </table>`,
      `


    <table style="font-size:15px" >
    <tr>
    <th>S.No.</th>
    <th>Observed Behaviors </th>
    <th>SafeStart Auditor</th>
    <th>Frequency</th>
    <th>Y/N </th>
    <th>Target</th>
    <th>Variance</th>
    <th>Why(use dropdown)</th>
    <th>Cost of non-confirmance</th>
    <th>ActionSteps</th>
    <th>To do : Unit Head </th>
    <th>Focus Area</th>
    </tr>
    <tr>
    <td>1</td>
    <td>competency matrix for department</td>
    <td>Quality</td>
    <td>10 Out of 10 Ocassions</td>
    <td>Y</td>
    <td>100% Compliance</td>
    <td>NIL</td>
    <td>Complacency</td>
    <td>Injury</td>
    <td>Nil</td>
    <td>Internal Technical competency matrix </td>
    <td></td>
    </tr>
    <tr>
    <td>2</td>
    <td>PPE as per matrix </td>
    <td>Quality</td>
    <td>5 Out of 10 Ocassions</td>
    <td>Y</td>
    <td>100% Compliance</td>
    <td>Cloth Modul, Polymer & Lab displays to be improved</td>
    <td>Complacency</td>
    <td>Injury</td>
    <td>Displays & Training to be intitiated</td>
    <td>Put all displays</td>
    <td></td>
    </tr>
    <tr>
    <td>3</td>
    <td>Proper storage and handling  of chemicals </td>
    <td>Quality</td>
    <td>5 Out of 10 Ocassions</td>
    <td>Y</td>
    <td>100% Compliance</td>
    <td>Mixing area ( TW, Cloth, Polymer ) to be improved</td>
    <td>Complacency</td>
    <td>Injury</td>
    <td>Training to be strengthned</td>
    <td>Discipline</td>
    <td></td>
    </tr>
    <tr>
    <td>4</td>
    <td>Near misses reported </td>
    <td>Quality</td>
    <td>5 Out of 10 Ocassions</td>
    <td>Y</td>
    <td>100% Compliance</td>
    <td>Cloth Module Polymer should be improved</td>
    <td>Complacency</td>
    <td>Injury</td>
    <td>Awareness displays to be done</td>
    <td>Discipline</td>
    <td></td>
    </tr>
    <tr>
    <td>5</td>
    <td>Random knowledge check of workers on safety process </td>
    <td>Quality</td>
    <td>5 Out of 10 Ocassions</td>
    <td>Y</td>
    <td>100% Compliance</td>
    <td>Knowledge on Kaizens, Limit switches, Poke Yokes should be brought down the line</td>
    <td>Complacency</td>
    <td>Injury</td>
    <td>Training</td>
    <td>Knowledge</td>
    <td></td>
    </tr>
    <tr>
    <td>6</td>
    <td>Dry floor conditions </td>
    <td>Quality</td>
    <td>2 out of 10 Ocassions</td>
    <td>Y</td>
    <td>100% Compliance</td>
    <td>Cloth mixing, Polymer Barrel area</td>
    <td>Complacency</td>
    <td>Injury</td>
    <td>Training</td>
    <td>Discipline</td>
    <td></td>
    </tr>
    <tr>
    <td>7</td>
    <td>Floor damage and timelines ffor repair </td>
    <td>Quality</td>
    <td>5 Out of 10 Ocassions</td>
    <td>Y</td>
    <td>100% Compliance</td>
    <td>Cloth shop floor, Moulding - TW</td>
    <td>Complacency</td>
    <td>Slip /Trip Hazard</td>
    <td>Floor condition improvement</td>
    <td>Work in Progress</td>
    <td></td>
    </tr>
    <tr>
    <td>8</td>
    <td>Ergonomics for handling material </td>
    <td>Quality</td>
    <td>4 out of 10 ocassions</td>
    <td>Y</td>
    <td>100 % compliance</td>
    <td>Improper facility / equipment </td>
    <td>Complacency</td>
    <td>Injury</td>
    <td>Fork Lift for unloading Jumbos + Unloading canopy</td>
    <td>Work in Progress</td>
    <td></td>
    </tr>
    <tr>
    <td>9</td>
    <td>Following SOPS </td>
    <td>Quality</td>
    <td>10 Out of 10 Ocassions</td>
    <td>Y</td>
    <td>100 % compliance</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    </tr>
    <tr>
    <td>10</td>
    <td>Not Removal of safety gaurds in any circumstances </td>
    <td>Quality</td>
    <td>4 out of 10 ocassions</td>
    <td>Y</td>
    <td>100%</td>
    <td>BRP Guards, BSP Guards to be checked </td>
    <td>Complacency</td>
    <td>Injury</td>
    <td></td>
    <td>Discipline</td>
    <td></td>
    </tr>
    <tr>
    <td>11</td>
    <td>Shortcut to operations</td>
    <td>Quality</td>
    <td>4 out of 10 ocassions</td>
    <td>Y</td>
    <td>100%</td>
    <td>BRP Guards, BSP Guards to be checked </td>
    <td>Complacency</td>
    <td>Injury</td>
    <td></td>
    <td>Discipline</td>
    <td></td>
    </tr>
    <tr>
    <td>12</td>
    <td>Station wise procedural check on following SOP </td>
    <td>Quality</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    </tr>
    <tr>
    <td>13</td>
    <td>Material handling done as per the recommendation of Safe Working Conditions</td>
    <td>Quality</td>
    <td>4 out of 10 ocassions</td>
    <td>Y</td>
    <td>100 % compliance</td>
    <td>Improper facility / equipment </td>
    <td>Complacency</td>
    <td>Injury</td>
    <td>Fork Lift for unloading Jumbos + Unloading canopy</td>
    <td>Ordered & Rectification in process </td>
    <td></td>
    </tr>
    <tr>
    <td>14</td>
    <td>Workers knowledge on process </td>
    <td>Quality</td>
    <td>3 out of 10 ocassions</td>
    <td>Y</td>
    <td>100% Competency</td>
    <td></td>
    <td>Fatigue</td>
    <td>Injury</td>
    <td>Competency matrix to be done for commercial</td>
    <td>Training</td>
    <td></td>
    </tr>
    <tr>
    <td>15</td>
    <td>emergency situation awareness </td>
    <td>Quality</td>
    <td>5 out of 10 ocassions</td>
    <td>Y</td>
    <td>100% Competency</td>
    <td>New Joinees to be trained on emergency evacuation</td>
    <td>Fatigue</td>
    <td>Injury</td>
    <td></td>
    <td></td>
    <td></td>
    </tr>
    <tr>
    <td>16</td>
    <td>Mock drills done regularly </td>
    <td>Quality</td>
    <td>5 out of 10 ocassions</td>
    <td>Y</td>
    <td>100% Competency</td>
    <td>New Joinees to be trained on emergency evacuation</td>
    <td>Fatigue</td>
    <td>Injury</td>
    <td></td>
    <td></td>
    <td></td>
    </tr>
    <tr>
    <td>17</td>
    <td>Immediate first aid process known and followed </td>
    <td>Quality</td>
    <td>5 out of 10 ocassions</td>
    <td>Y</td>
    <td>100% Competency</td>
    <td>First Aid training is periodically given</td>
    <td>Complacency</td>
    <td>Nil</td>
    <td>Nil</td>
    <td></td>
    <td></td>
    </tr>
    <tr>
    <td>18</td>
    <td>Supervisor briefing </td>
    <td></td>
    <td>Y</td>
    <td>100% Competency</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td>OJT - 100%</td>
    <td></td>
    <td></td>
    </tr>
    <tr>
    <td>19</td>
    <td>Proper tools stored at right place </td>
    <td>Quality</td>
    <td>5 out of 10 ocassions</td>
    <td>Y</td>
    <td>100% Competency</td>
    <td>Proper storage area to be provided with display </td>
    <td>Fatigue</td>
    <td>Injury</td>
    <td>Lock and Key to be provided</td>
    <td>Immediate</td>
    <td></td>
    </tr>
    <tr>
    <td>20</td>
    <td>Proper tools for proper jobs </td>
    <td>Quality</td>
    <td>5 out of 10 ocassions</td>
    <td>Y</td>
    <td>100% Competency</td>
    <td>Proper storage area to be provided with display </td>
    <td>Fatigue</td>
    <td>Injury</td>
    <td>Lock and Key to be provided</td>
    <td>Immediate</td>
    <td></td>
    </tr>
    <tr>
    <td>21</td>
    <td>Clearning, inspection done every week by supervisor </td>
    <td>Quality</td>
    <td>5 out of 10 ocassions</td>
    <td>Y</td>
    <td>100% Competency</td>
    <td>Nil </td>
    <td>Complacency</td>
    <td>Injury</td>
    <td>Cleaning check list to be done/td>
    <td>Immediate</td>
    <td></td>
    </tr>
    <tr>
    <td>22</td>
    <td>Inadvertent action near the equipment</td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    </tr>
    <tr>
    <td>23</td>
    <td>Awareness of First Aid/ Fire Fighting Group membership </td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    <td></td>
    </tr>





    </table>`
    ]
  };
  openModal = n => {
    document.getElementById("myModal").style.display = "block";
  };
  closeModal = () => {
    document.getElementById("myModal").style.display = "none";
  };
  view = () => {
    this.setState({ show: true });
  };
  hideView = () => {
    console.log("hide");
    this.setState({ show: false });
  };
  loadScript = src => {
    this.setState({ loaded: false });
    return <ScriptTag type="text/javascript" src={src} />;
  };
  render() {
    let state = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-margin-top">
          <div className="col-sm-12">
            <div className="row form-group">
              <div className="card">
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-12" style={{ marginBottom: '2%' }}>
                      <div className="row ">
                        <div className="col-sm-1">
                          <img
                            style={{ marginTop: '10%' }}
                            src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Logo-cumi_1582713919229.JPG`}
                          />
                        </div>
                        <div className="col-sm-10">
                          <h2
                            className="theme-tiile text-capitalize"
                            style={{ marginLeft: "0px", marginTop: "35px" }}
                          >
                            CUMI ABR MM Nagar
                          </h2>
                        </div>
                        <div className="col-sm-2"></div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div style={{ marginBottom: '2%' }}>
                        <LoginModal
                          buttonTitle="Gallery"
                          title="CUMI ABR MM Nagar"
                          id="we"
                          maxwidth="modal-lg"
                          indexStyle={{ zIndex: 99999 }}
                          bodyText={
                            <div>
                              <section className="carousel-container">
                                <img
                                  src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/IMG_1_1582637362517.jpg`}
                                  alt="cumi"
                                  className="current-image"
                                />
                                <span className="arrow arrow-left"> Prev </span>
                                <ul className="next-list">
                                  <li>
                                    <img
                                      src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/IMG_1_1582637362517.jpg`}
                                      alt=""
                                      className="image-of-list current-image-list"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/IMG_2_1582637362521.jpg`}
                                      alt=""
                                      className="image-of-list"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/IMG_3_1582637362524.jpg`}
                                      className="image-of-list"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Pic2_1582637698132.jpg`}
                                      alt=""
                                      className="image-of-list"
                                    />
                                  </li>
                                </ul>
                                <span className="arrow arrow-right"> Next </span>
                              </section>
                            </div>
                          }
                        />
                      </div>

                      <div>
                        <h3>CUMI Abrasives, MM Nagar</h3>
                        <br />
                        <p>
                          We did our pilot behavioral safety intervention with
                          CUMI, MM Nagar. We facilitated the 5 SafeStart core
                          units for 11 Senior executives of their Management
                          Team on following dates.
                        </p>
                        <br />
                        <table>
                          <tr>
                            <th colspan="2">Units</th>
                            <th>Dates</th>
                          </tr>
                          <tr>
                            <td rowSpan="6">
                              5 SafeStart
                              <br /> Core Units for
                              <br /> MG Staff
                            </td>
                            <td>Unit 1</td>
                            <td>07-May 2019</td>
                          </tr>
                          <tr>
                            <td>Unit 2</td>
                            <td>14-May 2019</td>
                          </tr>
                          <tr>
                            <td>Unit 3</td>
                            <td>21-May 2019</td>
                          </tr>
                          <tr>
                            <td>Safety Health Check</td>
                            <td>21 May & 28 May 2019</td>
                          </tr>
                          <tr>
                            <td>Unit 4</td>
                            <td>28-May 2019</td>
                          </tr>
                          <tr>
                            <td>Unit 5</td>
                            <td>11-Jun 2019</td>
                          </tr>
                        </table>
                        <br />
                        <h3>Safety Health Check</h3>
                        <br />
                        <p>
                          As a part of this intervention, the executives were
                          asked to carry out a Safety Health Check on the shop
                          floor, first within their functions and then cross-
                          functionally to observe and note, manual interventions
                          where safety is compromised due to human factors.
                        </p>
                        <br />
                        <p>The observations are being shown here </p>
                        <br />
                        <p>
                          We also piloted the SafeStart Performance Units’
                          intervention successfully with CUMI, MM Nagar for the
                          same team on following dates:
                        </p>
                        <br />
                        <table>
                          <tr>
                            <th colspan="2">Units</th>
                            <th>Dates</th>
                          </tr>
                          <tr>
                            <td rowspan="3">
                              3 SafeStart
                              <br /> Performance <br />
                              Units for
                              <br /> MG Staff
                            </td>
                            <td>Unit 1</td>
                            <td>10-Dec 2019</td>
                          </tr>
                          <tr>
                            <td>Unit 2</td>
                            <td>07-Jan 2020</td>
                          </tr>
                          <tr>
                            <td>Unit 3</td>
                            <td>07-Feb 2020</td>
                          </tr>
                        </table>
                      </div>
                      <br />
                      <br />
                      <div className="col-sm-12"></div>
                      <label style={{ fontWeight: 600, marginLeft: "16px" }}>
                        Safety Health Check for MM Nagar :
                      </label>
                      <div className="col-sm-12">
                        <ul class="nav nav-tabs" role="tablist">
                          {state.tabTitle.map((ival, i) => {
                            return (
                              <li class="nav-item">
                                <a
                                  class={`nav-link ${i === 0 ? "active" : ""}`}
                                  data-toggle="tab"
                                  href={`#home1${i}`}
                                >
                                  {ival}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                        <div class="tab-content">
                          {state.tabContent.map((ival, i) => {
                            return (
                              <div
                                id={`home1${i}`}
                                class={`container tab-pane ${
                                  i === 0 ? " active" : ""
                                  }`}
                                style={{ margin: "0px", overflowX: "auto" }}
                              >
                                <p className="content-para">
                                  {renderHTML(ival ? ival : "")}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                        <br />
                        <br />
                        <label style={{ fontWeight: 600, marginLeft: "3px" }}>
                          Cross Functional Safety Health Check
                        </label>
                        <ul class="nav nav-tabs" role="tablist">
                          {state.tabTitle.map((ival, i) => {
                            return (
                              <li class="nav-item">
                                <a
                                  class={`nav-link ${i === 0 ? "active" : ""}`}
                                  data-toggle="tab"
                                  href={`#home2${i}`}
                                >
                                  {ival}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                        <div class="tab-content">
                          {state.tabContent1.map((ival, i) => {
                            return (
                              <div
                                id={`home2${i}`}
                                class={`container tab-pane ${
                                  i === 0 ? " active" : ""
                                  }`}
                                style={{ margin: "0px", overflowX: "auto" }}
                              >
                                <p className="content-para">
                                  {renderHTML(ival ? ival : "")}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3"></div>
            </div>
            <div className="row form-group">
              <div className="col-sm-12 mb-new">
                <div className="card">
                  {/* <div className="card-body px-0">
                    <DownPanel />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {this.state.loaded ? this.loadScript(js1) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default SheetContent;