import React, { Component } from 'react';
import CmsContent from '../../../MiddleWare/CmsContent';
import SingleSelect from '../../../components/Form/SingleSelect';
import MultiSelect from '../../../components/Form/MultiSelect';
import FormMiddleWare from '../../../components/Form/FormMiddleware';
import { Alert } from 'reactstrap';
import Datatable from '../../../components/Datatable/Datatable';
import LoginModal from '../../../components/Modal/Modal';
import Progress from '../../../components/Extra/Progress';
import AddUser from '../../Modal/AddCustomer';
import UserData from '../../../MiddleWare/User';
import { confirmAlert } from 'react-confirm-alert';
import LearningObjpdf from '../../../components/PDF/LearningObjpdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { CSVLink, CSVDownload } from "react-csv";
var QRCode = require('qrcode.react');

class LearningObject extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      programOptions: [],
      selectedprogram: [],
      batchOptions: [],
      selectedbatch: [],
      domainOptions: [],
      selecteddomain: [],
      resourcesOption: [],
      selectedResource: [],
      trainerOptions: [],
      selectedTrainer: [],
      groupOptions: [],
      selectedGroup: [],
      locationOptions: [],
      selectedLocation: [],
      trainingCost: null,
      errorTrainingCost: null,
      disableSubmit: null,
      alertVisible: false,
      calculatedateerror: '',
      endateerror: '',
      startdateerror: '',
      updateData: false,
      uploadPercentage: 0,
      button: 'Submit',
      error11: null,
      error12: null,
      error13: null,
      error14: null,
      error15: null,
      error16: null,
      textalert: null,
      color: 'success'
    };
  }
  async componentDidMount() {
    let today = new Date();
    var dateformat = today.getDate();

    if (today.getDate() < 10) {
      dateformat = '0' + today.getDate();
    }
    var monthformate = today.getMonth() + 1;
    if (monthformate < 10) {
      monthformate = '0' + (today.getMonth() + 1);
    }
    let date = today.getFullYear() + '-' + monthformate + '-' + dateformat;

    await this.setState({ selectedstartdate: date });
  }

  async componentWillMount() {
    try {
      let userlog = JSON.parse(localStorage.getItem('userlog'));
      let companyid = userlog.id;
      let customerId = localStorage.getItem('userId');
      let serviceId = await localStorage.getItem('currentService');
      const userType = 8;
      this.setState({ customerId, serviceId, userType, companyid });

      let result = await CmsContent.getConditionedValuewithStatus('tbl_program', 'customerId', companyid, 'id', 'name');

      if (result) {
        this.setState({ programOptions: result.data });
      }
      result = await CmsContent.getConditionedValuewithStatus('tbl_domain', 'companyid', companyid, 'id', 'name');
      if (result) {
        this.setState({ domainOptions: result.data });
      }
      result = await CmsContent.getLearner('tbl_user_web', 'userType', 8, 'id', 'userName', companyid);
      if (result) {
        this.setState({ trainerOptions: result.data });
      }
      result = await CmsContent.getConditionedValuewithStatus('tbl_group', 'customerId', companyid, 'id', 'name');
      if (result) {
        this.setState({ groupOptions: result.data });
      }
      result = await CmsContent.getConditionedValuewithStatus('tbl_location', 'customerId', companyid, 'id', 'name');
      if (result) {
        this.setState({ locationOptions: result.data });
      }
      result = await CmsContent.getConditionedValuewithStatus('tbl_batch', 'customerId', companyid, 'id', 'name');
      if (result) {
        this.setState({ batchOptions: result.data });
      }
      result = await CmsContent.getConditionedValuewithStatus('tbl_resources', 'companyid', companyid, 'id', 'name');
      if (result) {
        this.setState({ resourceOptions: result.data });
      }
      result = await CmsContent.getConditionedValuewithStatus('tbl_learningobject', 'companyid', companyid, 1, 1);
      let excel = [];
      if (result) {
        result.data.map((item, i) => {
          excel.push({
            index: i + 1,
            program: this.getValueFromArray(item.programId, this.state.programOptions),
            batch: this.getValueFromArray(item.batchid, this.state.batchOptions),
            fromdate: item.fromdate,
            enddate: item.enddate,
            domain: this.getValueFromArray(item.domainid, this.state.domainOptions),
            resource: this.getValueFromArray(item.resourceid, this.state.resourceOptions),
            train: this.getValueFromArray(item.trainerid, this.state.trainerOptions),
            loc: this.getValueFromArray(item.location, this.state.locationOptions),
            cost: item.cost
          });
        })
      }
      if (result.data.length > 0) {
        const header = [
          'SI.NO',
          'Program Name',
          'BATCH',
          'FROMDATE',
          'ENDDATE',
          'DOMAIN',
          'RESOURCES',
          'TRAINER',
          'LOCATION',
          'COST'

        ];
        const excelHead = [
          { label: "SI.No", key: "index" },
          { label: "Programn", key: "program" },
          { label: "Batch", key: "batch" },

          { label: "Fromdate", key: "fromdate" },
          { label: "Enddate", key: "enddate" },
          { label: "Domain", key: "domain" },
          { label: "Resources", key: "resource" },
          { label: "Trainer", key: "train" },
          { label: "Location", key: "loc" },
          { label: "Cost", key: "cost" },
        ];
        this.setState({
          header,
          excelHead,
          excel,
          data: result.data
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      id: 'programId',
      Header: 'Program',

      accessor: d => this.getValueFromArray(d.programId, this.state.programOptions),
      Cell: d => this.views(d, 'groups')
    },
    {
      id: 'batchid',
      Header: 'Batch',
      accessor: d => this.getValueFromArray(d.batchid, this.state.batchOptions),
      Cell: d => this.batch(d, 'batch')
    },
    {
      Header: 'From Date',
      accessor: 'fromdate'
    },
    {
      Header: 'To Date',
      accessor: 'enddate'
    },
    {
      id: 'domainid',
      Header: 'Domain',
      accessor: d => this.getValueFromArray(d.domainid, this.state.domainOptions),
      Cell: d => this.check(d, 'group')
    },
    {
      id: 'resourceid',
      Header: 'Resource',
      accessor: d => this.getValueFromArray(d.resourceid, this.state.resourceOptions),
      Cell: d => this.checks(d)
    },
    {
      id: 'trainerid',
      Header: 'Trainer',
      accessor: d => this.getValueFromArray(d.trainerid, this.state.trainerOptions),
      Cell: d => this.getValueFromArray(d.original.trainerid, this.state.trainerOptions)
    },
    {
      id: 'groupid',
      Header: 'Cohorts',
      accessor: d => this.getValueFromArray(d.groupid, this.state.groupOptions),
      Cell: d => this.view(d, 'group')
    },
    {
      id: 'location',
      Header: 'Location',
      accessor: d => this.getValueFromArray(d.location, this.state.locationOptions),
      cell: d => this.view(d, 'group')
    },
    {
      id: 'cost',
      Header: 'Cost',
      accessor: d => this.getValueFromArray2(d.cost, this.state.data),
      Cell: d => this.getValueFromArray2(d.original.cost, this.state.data)
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: d => this.edit(d)
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: d =>
        this.dataTableButton('danger', 'Delete', () => {
          this.buttonDeletes(d.original);
        })
    }
  ];
  columns = [
    {
      Header: 'Cohorts',
      accessor: 'label'
    }
  ];
  columns1 = [
    {
      Header: 'Program',
      accessor: 'label'
    }
  ];
  batchcolumn = [
    {
      Header: 'Batch',
      accessor: 'label'
    }
  ];

  batch = (value, modalWindowId) => {
    return (
      <center>
        <button type="button" data-toggle="modal" data-target={`#${modalWindowId}`} className="btn btn-warning" onClick={() => this.batchView(value)}>
          Batch
        </button>
      </center>
    );
  };

  batchView = value => {
    const { batchOptions } = this.state;
    let All = batchOptions
      .map((ival, i) => {
        if (ival.value == value.original.groupid) {
          let returnArray = {};
          returnArray.label = this.getValueFromArray1(value.original.groupid, this.state.batchOptions);
          return returnArray;
        }
      })
      .filter(function (element) {
        return element !== undefined;
      });

    this.setState({ batchView: All });
  };
  getValueFromArray1 = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {

          return v.label;
        } else {
          return '-';
        }
      }
    } else {
      return '-';
    }
  };
  getValueFromArray2 = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.cost == d;
        });
        let v = filtered[0];
        if (v != undefined) {
          return v.cost;
        } else {
          return '-';
        }
      }
    } else {
      return '-';
    }
  };
  getValueFromArray3 = (d, array) => {
    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {

          return item.cost == d;
        });
        let v = filtered[0];
        if (v != undefined) {

          return v.cost;
        } else {
          return '-';
        }
      }
    } else {
      return '-';
    }
  };
  view = (value, modalWindowId) => {
    return (
      <center>
        <button type="button" data-toggle="modal" data-target={`#${modalWindowId}`} className="btn btn-warning" onClick={() => this.buttonView(value)}>
          Cohorts
        </button>
      </center>
    );
  };
  buttonView = value => {

    const { groupOptions } = this.state;
    let alls = groupOptions
      .map((ival, i) => {

        if (ival.value == value.original.groupid) {
          let returnArray = {};
          returnArray.label = this.getValueFromArray1(value.original.groupid, this.state.groupOptions);

          return returnArray;
        }
      })
      .filter(function (element) {
        return element !== undefined;
      });

    this.setState({ buttonView: alls });
  };
  getValueFromArray1 = (d, array) => {

    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {

          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {

          return v.label;
        } else {
          return '-';
        }
      }
    } else {
      return '-';
    }
  };
  views = (value, modalWindowId) => {
    return (
      <center>
        <button type="button" data-toggle="modal" data-target={`#${modalWindowId}`} className="btn btn-warning" onClick={() => this.buttonViews(value)}>
          Program
        </button>
      </center>
    );
  };
  buttonViews = value => {

    const { programOptions } = this.state;
    let all = programOptions
      .map((ival, i) => {

        if (ival.value == value.original.programId) {
          let returnArrays = {};
          returnArrays.label = this.getValueFromArrays(ival.value, this.state.programOptions);

          return returnArrays;
        }
      })
      .filter(function (element) {
        return element !== undefined;
      });

    this.setState({ buttonViews: all });
  };
  getValueFromArrays = (d, array) => {

    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {

          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {

          return v.label;
        } else {
          return '-';
        }
      }
    } else {
      return '-';
    }
  };
  check = d => {
    let value = '';
    value = d.original.domainid;
    let res = value.split(',');

    var result = '';

    for (var i = 0; i < res.length; i++) {
      result += this.getValueFromArray(res[i], this.state.domainOptions) + ',';
    }
    result = result.replace(/,\s*$/, '');
    return result;
  };
  checks = d => {
    let value = '';
    value = d.original.resourceid;
    let res = value.split(',');

    var result = '';

    for (var i = 0; i < res.length; i++) {
      result += this.getValueFromArray(res[i], this.state.resourceOptions) + ',';
    }
    result = result.replace(/,\s*$/, '');
    return result;
  };
  buttonEdit = value => {

    let values = value.original;

    let index = value.original.index;

    let selectedprogram = {};
    selectedprogram.value = values.programId;
    selectedprogram.label = this.getValueFromArray(values.programId, this.state.programOptions);


    let selectedbatch = {};
    selectedbatch.value = values.batchid;
    selectedbatch.label = this.getValueFromArray(values.batchid, this.state.batchOptions);

    let selectedstartdate = values.fromdate;

    let selectedenddate = values.enddate;

    let domainlist = values.domainid.split(',');


    let domainval = domainlist
      .map((ival, i) => {

        if (ival == domainlist[i]) {
          let returnArray = {};
          returnArray.label = this.getValueFromArray(ival, this.state.domainOptions);
          returnArray.value = ival;

          return returnArray;
        }
      })
      .filter(function (element) {
        return element !== undefined;
      });


    let domainlabel = domainval
      .map((ival, i) => {

        if (ival.value == domainlist[i]) {

          let returnArray = {};
          // returnArray.label = this.getValueFromArray(ival.value , domainval);
          returnArray.value = ival.value;

          return returnArray;
        }
      })
      .filter(function (element) {
        return element !== undefined;
      });
    this.domainSelect(domainlist);

    let selecteddomain = domainval;


    let resourceList = values.resourceid.split(',');


    let resourceval = resourceList
      .map((ival, i) => {

        if (ival == resourceList[i]) {
          let returnArray = {};
          returnArray.label = this.getValueFromArray(ival, this.state.resourceOptions);
          returnArray.value = ival;

          return returnArray;
        }
      })
      .filter(function (element) {
        return element !== undefined;
      });
    this.resourceSelect(resourceList);
    // this.setState({selectedResource : resourceval });

    let selectedResource = resourceval;
    //  selectedResource.value = resourceval.value;
    //  selectedResource.label =



    let selectedTrainer = {};
    selectedTrainer.value = values.trainerid;
    selectedTrainer.label = this.getValueFromArray(values.trainerid, this.state.trainerOptions);

    let selectedGroup = {};
    selectedGroup.value = values.groupid;
    selectedGroup.label = this.getValueFromArray(values.groupid, this.state.groupOptions);

    let selectedLocation = {};
    selectedLocation.value = values.location;
    selectedLocation.label = this.getValueFromArray(values.location, this.state.locationOptions);

    let trainingCost = values.cost;

    this.setState({
      selectedprogram,
      selectedGroup,
      selectedTrainer,
      selectedResource,
      selectedbatch,
      selectedstartdate,
      selectedenddate,
      selecteddomain,
      selectedLocation,
      trainingCost,
      editvalue: values,
      index,
      domainlabel,
      button: 'Update'
    });
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  update = async () => {
    const {
      selectedprogram,
      selectedGroup,
      selectedTrainer,
      selectedResource,
      selectedbatch,
      selectedstartdate,
      selectedenddate,
      selecteddomain,
      selectedLocation,
      trainingCost,
      editvalue,
      domainlabel
    } = this.state;

    let previousdata = [...this.state.data];
    let index = this.state.index;

    let id = editvalue.id;
    let valueArray = {};
    valueArray.id = id;
    valueArray.programId = selectedprogram.value;
    valueArray.batchid = selectedbatch.value;
    valueArray.fromdate = selectedstartdate;
    valueArray.enddate = selectedenddate;
    valueArray.domainid = this.getMultiSelectValue(selecteddomain, '1');
    valueArray.resourceid = this.getMultiSelectValue(selectedResource, '1');
    valueArray.trainerid = selectedTrainer.value;
    valueArray.groupid = selectedGroup.value;
    valueArray.location = selectedLocation.value;
    valueArray.cost = trainingCost;
    valueArray.companyid = this.state.customerId;
    valueArray.status = 'active';

    try {
      let result = await CmsContent.updateMaster('tbl_learningobject', id, valueArray, 'id');
      if (result) {


        previousdata.id = editvalue.id;


        previousdata.programId = selectedprogram.value;
        previousdata.batchid = selectedbatch.value;
        previousdata.fromdate = selectedstartdate;
        previousdata.enddate = selectedenddate;
        previousdata.domainid = this.getMultiSelectValue(selecteddomain, '1');
        previousdata.resourceid = this.getMultiSelectValue(selectedResource, '1');
        previousdata.trainerid = selectedTrainer.value;
        previousdata.groupid = selectedGroup.value;
        previousdata.location = selectedLocation.value;
        previousdata.cost = trainingCost;

        var data = this.state.data;
        data = data.filter(function (obj) {

          return obj.id !== id;
        });



        let newData = [previousdata, ...data];

        this.setState({
          data: newData,
          selectedprogram: '',
          selectedbatch: '',
          selectedenddate: '',
          selectedstartdate: '',
          selecteddomain: '',
          selectedResource: '',
          selectedTrainer: '',
          selectedGroup: '',
          selectedLocation: '',
          trainingCost: '',
          button: 'Submit',
          alertVisible: true,
          textalert: 'Learning Object Updated',
          color: 'success'
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  buttonDeletes = value => {
    this.setState({});
    confirmAlert({
      title: 'Confirmation to Delete',
      message: 'Are you sure you want to delete this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.buttonDelete(value)
          //onClick: () => alert("Group removed from")
        },
        {
          label: 'No'
        }
      ]
    });
  };
  buttonDelete = async value => {
    //Getting Previous state data

    const previousData = [...this.state.data];
    let id = value.id;
    try {
      const result = await CmsContent.getSingleConditionedValue('tbl_location', 'id', id, 'Delete');
      if (result) {

        let datas = previousData.filter((delelteid, i) => {

          if (delelteid.id !== value.id) {
            return delelteid;
          }
        });
        this.setState({
          data: datas,
          alertVisible: true,
          textalert: 'Selected LOB Deleted',
          color: 'danger'
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  getbatch = async e => {
    this.setState({ selectedprogram: e });
    let val = e.value;
    try {
      let result = await CmsContent.getConditionedValuewithStatus('tbl_batch', 'programid', val);
      if (result) {
        this.setState({ batchOptions: result.data, selectedbatch: [] });
      }
    } catch (error) {
      console.log(error);
    }
  };

  batchSelect = e => {
    this.setState({ selectedbatch: e });
  };
  fromDateSelect = e => {


    this.setState({ selectedstartdate: e.target.value });

    let today = new Date();
    var dateformat = today.getDate();

    if (today.getDate() < 10) {
      dateformat = '0' + today.getDate();
    }
    var todaymonth = today.getMonth() + 1;

    if (today.getMonth() + 1 < 10) {

      todaymonth = '0' + today.getMonth() + 1;
    }

    let todaydate = today.getFullYear() + '-' + today.getMonth() + 1 + '-' + dateformat;


    if (todaydate > e.target.value) {
      this.setState({ startdateerror: 'Please check start date' });
    } else {
      this.setState({ startdateerror: '' });

    }
  };
  endDateSelect = e => {
    this.setState({ selectedenddate: e.target.value });
    if (this.state.selectedstartdate > e.target.value) {
      this.setState({ endateerror: 'Please check end date' });
      return false;
    } else {
      this.setState({ endateerror: '' });
    }

    let firstdate = new Date(this.state.selectedstartdate);
    let secondate = new Date(e.target.value);
    let diffTime = Math.abs(secondate - firstdate);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    let today = new Date();

    var dateformat = today.getDate();
    if (today.getDate() < 10) {
      dateformat = '0' + today.getDate();
    }
    var monthformate = today.getMonth() + 1;
    if (monthformate < 10) {
      monthformate = '0' + (today.getMonth() + 1);
    }


    let todaydate = today.getFullYear() + '-' + monthformate + '-' + dateformat;


    if (todaydate > this.state.selectedstartdate) {
      this.setState({ calculatedateerror: 'Please check start date' });
    }

    if (diffDays >= 7) {
      this.setState({ calculatedateerror: 'Please choose the date between 7 days from the start date' });


    } else {
      this.setState({ calculatedateerror: '' });
    }
    if (todaydate > e.target.value) {
      this.setState({ endateerror: 'Please Change End Date ' });
      return false;

    } else {
      this.setState({ endateerror: '' });

    }
  };

  getMultiSelectValue = (data, type) => {

    let selectedValue = '0';
    if (data.length !== 0) {
      selectedValue = '';

      if (type === '0') {
        const value = data.map(values => (selectedValue += `'${values.value}',`));
        selectedValue = selectedValue.replace(/,\s*$/, '');
        var lastChar = selectedValue.slice(-1);
        if (lastChar === ',') {
          selectedValue = selectedValue.slice(0, -1);
        }
      } else if (type === '1') {
        const value = data.map(values => (selectedValue += `${values.value},`));
        selectedValue = selectedValue.replace(/,\s*$/, '');
      }
    }
    return selectedValue;
  };

  domainSelect = async e => {
    if (e) {

      let selectedValue = [];
      this.setState({ selecteddomain: e, selectedResource: [] });
      selectedValue = this.getMultiSelectValue(e, '0');
      try {
        let result = await CmsContent.getConditionedValuewithStatus('tbl_resources', 'domainid', selectedValue);
        if (result) {

          let data = result.data;
          this.setState({ resourcesOption: data });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  resourceSelect = e => {
    if (e) {
      let selectedValue = [];
      this.setState({ selectedResource: e });
    }
  };
  trainerSelect = e => {
    this.setState({ selectedTrainer: e });
  };
  groupSelect = e => {
    let selectedValue = [];
    this.setState({ selectedGroup: e });
    selectedValue = this.getMultiSelectValue(e, '1');
  };
  locaionSelect = e => {
    this.setState({ selectedLocation: e });

  };
  trainingCost = e => {
    this.setState({ trainingCost: e.target.value });

  };

  validateSingleSelect = val => {

    if (!val.value) {
      return false;
    } else {
      return true;
    }
  };

  refreshbatch = async () => {
    try {

      let val = this.state.selectedprogram.value;
      if (val != undefined && val != '' && val != null) {
        let result = await CmsContent.getConditionedValuewithStatus('tbl_batch', 'programid', val);
        if (result) {
          this.setState({ batchOptions: result.data, selectedbatch: [] });
        }
      }
      // const getQuizQuestion = await cmsContent.getQuizQuestion();
      // if (getQuizQuestion) {
      //   this.setState({ getQuizQuestion: getQuizQuestion.data });
      // }
    } catch (error) {
      console.log(error);
    }
  };

  onSubmitlob = async () => {
    const {
      selectedprogram,
      selectedbatch,
      selecteddomain,
      selectedstartdate,
      selectedenddate,
      selectedResource,
      selectedTrainer,
      groupOptions,
      selectedGroup,
      selectedLocation,
      trainingCost,
      errorTrainingCost,
      disableSubmit
    } = this.state;

    let firstdate = new Date(this.state.selectedstartdate);
    let secondate = new Date(this.state.selectedenddate);
    let diffTime = Math.abs(secondate - firstdate);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


    let today = new Date();
    var dateformat = today.getDate();
    if (today.getDate() < 10) {
      dateformat = '0' + today.getDate();
    }
    var monthformate = today.getMonth() + 1;
    if (monthformate < 10) {
      monthformate = '0' + (today.getMonth() + 1);
    }
    let todaydate = today.getFullYear() + '-' + monthformate + '-' + dateformat;


    if (todaydate > this.state.selectedstartdate) {
      await this.setState({ startdateerror: 'Please change start date' });
      return false;
    }

    if (diffDays >= 7) {
      await this.setState({ calculatedateerror: 'Please choose the date between 7 days from the start date' });
      return false;

    } else {
      await this.setState({ calculatedateerror: '' });
    }
    if (this.state.selectedstartdate > this.state.selectedenddate) {
      await this.setState({ endateerror: 'Please Change End Date ' });
      return false;

    } else {
      await this.setState({ endateerror: '' });
    }

    if (selectedprogram == null || selectedprogram == '' || selectedprogram == undefined) {
      this.setState({ error1: 'Please Select Program' });
      return false;
    }
    if (selectedbatch == null || selectedbatch == '' || selectedbatch == undefined) {
      this.setState({ error2: 'Please Select Batch', error1: '' });
      return false;
    }
    if (selectedstartdate == null || selectedstartdate == '' || selectedstartdate == undefined) {
      this.setState({ error3: 'Please Select From Date', error2: '' });
      return false;
    }
    if (selectedenddate == null || selectedenddate == '' || selectedenddate == undefined) {
      this.setState({ error4: 'Please Select To Date', error3: '' });
      return false;
    }
    if (selecteddomain == null || selecteddomain == '' || selecteddomain == undefined) {
      this.setState({ error5: 'Please Select Domain', error4: '' });
      return false;
    }
    if (selectedResource == null || selectedResource == '' || selectedResource == undefined) {
      this.setState({ error6: 'Please Select Resource', error5: '' });
      return false;
    }
    if (selectedTrainer == null || selectedTrainer == '' || selectedTrainer == undefined) {
      this.setState({ error7: 'Please Select Trainer', error6: '' });
      return false;
    }
    if (selectedGroup == null || selectedGroup == '' || selectedGroup == undefined) {
      this.setState({ error8: 'Please Select Group', error7: '' });
      return false;
    }
    if (selectedLocation == null || selectedLocation == '' || selectedLocation == undefined) {
      this.setState({ error9: 'Please Select Location', error8: '' });
      return false;
    }
    if (trainingCost == null || trainingCost == '' || trainingCost == undefined) {
      this.setState({ error10: 'Please Select Training Cost', error9: '' });
      return false;
    }

    if (isNaN(trainingCost)) {
      this.setState({ error10: 'please enter a number', error9: '' });
      return false;
    }
    this.setState({ error10: '' });

    if (this.validateSingleSelect(selectedprogram)) {

    }
    const formData = new FormData();
    formData.append('programId', selectedprogram.value);
    formData.append('batchid', selectedbatch.value);
    formData.append('fromdate', selectedstartdate);
    formData.append('enddate', selectedenddate);
    formData.append('domainid', this.getMultiSelectValue(selecteddomain, '1'));
    formData.append('resourceid', this.getMultiSelectValue(selectedResource, '1'));
    formData.append('trainerid', selectedTrainer.value);
    formData.append('groupid', this.getMultiSelectValue(selectedGroup, '1'));
    formData.append('location', selectedLocation.value);
    formData.append('cost', trainingCost);
    formData.append('companyid', localStorage.getItem('userId'));
    formData.append('status', 'active');

    let valueArray = {};
    valueArray.programId = selectedprogram.value;
    valueArray.batchid = selectedbatch.value;
    valueArray.fromdate = selectedstartdate;
    valueArray.enddate = selectedenddate;
    valueArray.domainid = this.getMultiSelectValue(selecteddomain, '1');
    valueArray.resourceid = this.getMultiSelectValue(selectedResource, '1');
    valueArray.trainerid = selectedTrainer.value;
    valueArray.groupid = this.getMultiSelectValue(selectedGroup, '1');
    valueArray.location = selectedLocation.value;
    valueArray.cost = trainingCost;
    valueArray.companyid = localStorage.getItem('userId');
    valueArray.status = 'active';


    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    try {
      let result = await CmsContent.learningObjectSubmit('tbl_learningobject', formData);
      if (result) {

        let newData = [valueArray, ...this.state.data];
        this.setState({ data: newData, alertVisible: true });
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({
      selectedprogram: '',
      selectedbatch: '',
      selectedstartdate: '',
      selectedenddate: '',
      selecteddomain: '',
      selectedResource: '',
      selectedTrainer: '',
      selectedGroup: '',
      selectedLocation: '',
      trainingCost: '',
      errorTrainingCost: null,
      textalert: 'New LOB Added Successfully',
      alertVisible: true,
      color: 'success',
      disableSubmit: null
    });

    setTimeout(() => this.setState({ alertVisible: false }), 3000);
  };
  handlechange = e => {
    let value = e.target.value;

    this.setState({ editvalue: value });
  };
  submitprogram = async () => {
    //let value = this.state.editvalue;
    const { editvalue: value, customerId } = this.state;

    if (value === null || value === '' || value === undefined) {
      this.setState({ error11: 'Please Fill The Content' });
      return false;
    } else {
    }
    let groupArray = {};
    groupArray.name = value;

    groupArray.status = 'active';
    groupArray.customerId = localStorage.getItem('userId');

    try {
      this.setState({ disableValue: true });
      const result = await CmsContent.addMaster('tbl_program', groupArray);
      if (result) {
        this.componentWillMount();

        let valueArray = {};
        this.setState({
          editvalue: '',
          editid: null,
          disableValue: false,
          addNewSubgroup: false,
          error: null,
          button: 'Submit',
          index: null,
          error11: '',
          textalert: 'New Program Added Successfully',
          alertVisible: true,
          color: 'success'
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  submitbatch = async () => {
    let { batchOptions } = this.state;
    const { editvalue: value, categoryid: programid } = this.state;
    if (programid != undefined && programid != '' && programid != null) {
      this.setState({ selectboxerror: '' });
    }
    if (value != undefined && value.trim() != '' && value != null) {
      this.setState({ error12: '' });
    } else {
      this.setState({ error12: 'Please Fill The Batch', selectboxerror: '' });
      return true;
    }
    let groupArray = {};
    groupArray.name = value.trim();
    groupArray.programid = programid;
    groupArray.status = 'active';
    groupArray.customerId = this.state.companyid;

    try {
      this.setState({ disableValue: true });
      const result = await CmsContent.addMaster('tbl_batch', groupArray);
      if (result) {
        this.componentWillMount();

        this.setState({
          editvalue: '',
          editid: null,
          categoryId: '',
          categorySelected: '',
          disableValue: false,
          addNewSubgroup: false,
          error: null,
          selectboxerror: null,
          button: 'Submit',
          index: null,
          textalert: 'New Batch Added Successfully',
          alertVisible: true,
          color: 'success'
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  submitdomain = async () => {
    const { editvalue: value, companyid } = this.state;
    if (value === null || value === '' || value === undefined) {
      this.setState({ error13: 'Please Fill Domain' });
      return false;
    }
    let groupArray = {};
    groupArray.name = value;
    groupArray.companyid = companyid;
    groupArray.status = 'active';

    try {
      this.setState({ disableValue: true });
      const result = await CmsContent.addMaster('tbl_domain', groupArray);

      if (result) {
        this.componentWillMount();

        this.setState({
          alertVisible: true,
          editvalue: '',
          editid: null,
          disableValue: false,
          addNewSubgroup: false,
          error: null,
          button: 'Submit',
          index: null,
          textalert: 'New Domain Added Successfully',
          alertVisible: true,
          color: 'success'
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  submitresource = async () => {
    const { Authorname, Authoremail } = this.state;
    let userfile = this.state.file;


    var selectOption = this.state.domainOptions;
    if (selectOption != undefined && selectOption != '' && selectOption != null) {
      this.setState({ errordomain: '' });
    } else {
      this.setState({ errordomain: 'Please Select ' });
      return true;
    }
    if (Authorname != undefined && Authorname != '' && Authorname != null) {
      this.setState({ error14: '' });
    } else {
      this.setState({ error14: 'Please Fill Resource Name' });
      return true;
    }
    if (userfile != undefined && userfile != '' && userfile != null) {
      this.setState({ errorfile: '' });
    } else {
      this.setState({ errorfile: 'Please Fill Resource file' });
      return true;
    }

    let username = this.state.Authorname;
    let useremail = this.state.selectedDomain;
    let companyid = this.state.companyid;
    let active = 'active';

    const formData = new FormData();
    formData.append('name', username);
    formData.append('domainid', useremail.value);
    formData.append('companyid', companyid);
    formData.append('file', userfile);
    formData.append('status', active);


    let categoryArray = {};
    categoryArray.name = username.trim();
    categoryArray.domainid = useremail.value;
    this.setState({ disableValue: true });

    try {
      this.setState({ disableValue: true });

      const authorinsert = await CmsContent.authorinsert(formData, 'tbl_resources');

      // // onUploadProgress: progressEvent => {
      // //   this.setState({
      // //     uploadPercentage: parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      // //   });
      // //   setTimeout(() => this.setState({ uploadPercentage: 0 }), 10000);
      // // }

      if (authorinsert) {
        this.componentWillMount();



        this.setState({
          disableValue: false,

          Authorname: '',
          filename: null,
          selectedDomain: '',
          button: 'Submit',
          textalert: 'New Resource Added Successfully',
          alertVisible: true,
          color: 'success'
        });
      }
      setTimeout(() => this.setState({ alertVisible: false }), 3000);
    } catch (error) {
      console.log(error);
    }
  };
  submittrainer = async () => {
    let userArray = {};
    let userName = this.state.userName;
    let mobileNumber = this.state.mobileNumber;
    let email = this.state.email;
    //let status = this.state.status;
    //  let serviceId = await localStorage.getItem('currentService');
    userArray.serviceId = this.state.serviceId;
    userArray.userName = userName;
    userArray.mobileNumber = mobileNumber;
    userArray.email = email;
    userArray.status = 'active';
    userArray.userType = this.state.userType;
    userArray.customerId = this.state.customerId;
    const previousData = [...this.state.data];

    try {
      const result = await UserData.userInsert(userArray);
      if (result) {
        // this.componentWillMount();


        const userData = {
          id: parseInt(result.data.insertId),
          userName: userName,
          mobileNumber: mobileNumber,
          email: email,
          status: 'active'
        };
        const newData = [...this.state.data, userData];

        this.setState({
          userName: '',
          mobileNumber: '',
          email: '',
          errorUserName: '',
          errorMobileNumber: '',
          errorEmail: '',
          imgupload: '',
          logoname: '',
          textalert: 'New Trainer Added Successfully',
          alertVisible: true,
          color: 'success'
        });

        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      this.setState({
        data: previousData
      });
      console.log(error);
    }
  };
  submitcohorts = async () => {
    let value = this.state.editvalue;
    if (value === null || value === '' || value === undefined) {
      this.setState({ error15: 'Please Fill The Cohorts' });
      return false;
    }
    let groupArray = {};
    groupArray.name = value;
    groupArray.status = 'active';
    groupArray.customerId = this.state.customerId;


    try {
      this.setState({ disableValue: true });
      const result = await CmsContent.addMaster('tbl_group', groupArray);

      if (result) {
        this.componentWillMount();


        this.setState({
          editvalue: '',
          editid: null,
          disableValue: false,
          addNewSubgroup: false,
          error: null,
          button: 'Submit',
          index: null,
          textalert: 'New Cohorts Added Successfully',
          alertVisible: true,
          color: 'success'
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  submitlocation = async () => {
    //    let value = this.state.editvalue;
    let value = this.state.editvalue;

    if (value === null || value === '') {
      this.setState({ error16: 'Please Fill Location' });
      return false;
    }
    let groupArray = {};
    groupArray.name = value;
    groupArray.status = 'active';
    groupArray.customerId = this.state.customerId;

    try {
      this.setState({ disableValue: true });
      const result = await CmsContent.addMaster('tbl_location', groupArray);

      if (result) {
        this.componentWillMount();


        this.setState({
          editvalue: '',
          editid: null,
          disableValue: false,
          addNewSubgroup: false,
          error: null,
          button: 'Submit',
          index: null,
          textalert: 'New Location Added Successfully',
          alertVisible: true,
          color: 'success'
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  onSubmit = async () => {

    let userArray = {};
    let userName = this.state.userName;
    let mobileNumber = this.state.mobileNumber;
    let email = this.state.email;
    //let status = this.state.status;
    //let serviceId = await localStorage.getItem('currentService');
    let imgupload = this.state.imgupload;
    // userArray.serviceId = serviceId;
    // userArray.userName = userName;
    // userArray.mobileNumber = mobileNumber;
    // userArray.email = email;
    // userArray.status = 'active';
    // userArray.userType = this.state.user === 'Trainer' ? 8 : 6;
    // userArray.customerId = localStorage.getItem('userId');
    const formData = new FormData();

    formData.append('serviceId', this.state.serviceId);
    formData.append('userName', userName);
    formData.append('mobileNumber', mobileNumber);
    formData.append('email', email);
    formData.append('status', 'active');
    formData.append('userType', this.state.userType);
    formData.append('customerId', this.state.customerId);
    formData.append('image', imgupload);

    // const previousData = [...this.state.data];
    try {
      //const result = await UserData.userInsert(userArray);
      const result = await UserData.adminuserInsert('tbl_user_web', formData);
      if (result) {
        const userData = {
          id: parseInt(result.data.insertId),
          value: parseInt(result.data.insertId),
          label: userName,
          mobileNumber: mobileNumber,
          email: email,
          status: 'active',
          image: result.data.image
        };
        const newData = [...this.state.trainerOptions, userData];

        this.setState({
          trainerOptions: newData,
          userName: '',
          mobileNumber: '',
          email: '',
          errorUserName: '',
          errorMobileNumber: '',
          errorEmail: '',
          imgupload: '',
          logoname: '',
          alertVisible: true,
          textalert: 'New LOB Added Successfully',
          color: 'success'
        });
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      // this.setState({
      //   data: previousData
      // });
      console.log(error);
    }
  };

  authorname = e => {
    let name = e.target.value;
    this.setState({ Authorname: name });
  };
  authorimage = e => {
    let filename = e.target.files[0];
    let filepath = e.target.files[0].name;
    this.setState({ file: filename, filename: filepath });
  };
  domain = e => {
    this.setState({ selectedDomain: e });
  };
  programSelect = e => {
    this.setState({ categorySelected: e, categoryid: e.value });
  };
  onDismiss = () => {
    this.setState({ alertVisible: false });
  };

  render() {
    const {
      programOptions,
      selectedprogram,
      batchOptions,
      selectedbatch,
      domainOptions,
      selecteddomain,
      selectedstartdate,
      selectedenddate,
      resourceOptions,
      selectedResource,
      trainerOptions,
      selectedTrainer,
      groupOptions,
      selectedGroup,
      selectedLocation,
      locationOptions,
      trainingCost,
      alertVisible,
      error1,
      error2,
      error3,
      error4,
      error5,
      error6,
      error7,
      error8,
      error9,
      error10,
      disableSubmit,
      disableValue,
      editvalue,
      error,
      categorySelected,
      selectboxerror,
      errordomain,
      Authorname,
      errorfile,
      uploadPercentage,
      updateData,
      button,
      error11,
      error12,
      error13,
      error14,
      error15,
      error16,
      textalert
    } = this.state;
    let userType = localStorage.getItem('userType');
    const userData = {
      userName: this.state.userName,
      mobileNumber: this.state.mobileNumber,
      email: this.state.email
    };
    const errorData = {
      errorUserName: this.state.errorUserName,
      errorMobileNumber: this.state.errorMobileNumber,
      errorEmail: this.state.errorEmail
    };

    let title = updateData === false ? 'Add Mentor' : 'Update Mentor';

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2"></div>
                  <div className="col-sm-5">
                    <LoginModal
                      buttonTitle={this.view}
                      atagLink={true}
                      title="Group"
                      id="group"
                      onClick={this.buttonView}
                      bodyText={<div>{this.state.buttonView && <Datatable data={this.state.buttonView} columnHeading={this.columns} />}</div>}
                    />
                  </div>
                </div>
                <div className="card-body"></div>
                <div className="row" style={{ textAlign: 'center' }}>
                  <div className="col-sm-12" style={{ textAlign: 'center' }}>
                    <Alert className="badge-content" color={this.state.color} isOpen={alertVisible} toggle={this.onDismiss}>
                      {/* Learning Object Added */}
                      {textalert}
                    </Alert>
                  </div>
                </div>

                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2"></div>
                  <div className="col-sm-5">
                    <LoginModal
                      buttonTitle={this.view}
                      atagLink={true}
                      title="Batch"
                      id="batch"
                      onClick={this.batchView}
                      bodyText={<div>{this.state.batchView && <Datatable data={this.state.batchView} columnHeading={this.batchcolumn} />}</div>}
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-sm-2" />
                  <div className="col-sm-2"></div>
                  <div className="col-sm-5">
                    <LoginModal
                      buttonTitle={this.views}
                      atagLink={true}
                      title="Program"
                      id="groups"
                      onClick={this.buttonViews}
                      bodyText={<div>{this.state.buttonViews && <Datatable data={this.state.buttonViews} columnHeading={this.columns1} />}</div>}
                    />
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h3>Add Learning Object</h3>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Select Program</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect handleChange={this.getbatch} options={programOptions} selectedService={selectedprogram} />
                        <span className="error-shows">{error1}</span>
                      </div>
                      <div className="col-sm-2">
                        <LoginModal
                          buttonTitle="Add Program"
                          title="Add Program"
                          id="Program"
                          extraClass="btn btn-primary form-control"
                          onClick={this.reset}
                          bodyText={
                            <div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="subgroup">Programs</label>
                                </div>
                                <div className="col-sm-5">
                                  <input type="text" name="subgroup" placeholder="Enter Program" className="form-control" value={editvalue} onChange={e => this.handlechange(e)} />
                                  <span className="error-shows">{error11}</span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" />
                                <div className="col-sm-5">
                                  {this.state.editvalue == '' || this.state.editvalue == null ? (
                                    <button type="button" className="btn btn-primary " onClick={this.submitprogram}>
                                      Submit
                                    </button>
                                  ) : (
                                    <button type="button" data-dismiss="modal" className="btn btn-primary " onClick={this.submitprogram}>
                                      Submit
                                    </button>
                                  )}
                                </div>
                                <div className="col-sm-3" />
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="Batch">Batch</label>
                      </div>
                      <div className="col-sm-5">
                        <SingleSelect handleChange={this.batchSelect} options={batchOptions} selectedService={selectedbatch} />
                        <span className="error-shows">{error2}</span>
                      </div>
                      <div className="col-sm-2">
                        <LoginModal
                          buttonTitle="Add Batch"
                          title="Add Batch"
                          id="Batch"
                          extraClass="btn btn-primary form-control"
                          onClick={this.reset}
                          bodyText={
                            <div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label for="exampleInputEmail1">Select Program</label>
                                </div>
                                <div className="col-sm-5">
                                  <SingleSelect disabled={this.state.disabled} options={this.state.programOptions} handleChange={this.programSelect} selectedService={categorySelected} />
                                  <span className="error-shows">{selectboxerror}</span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="subgroup">Batch</label>
                                </div>
                                <div className="col-sm-5">
                                  <input type="text" name="subgroup" placeholder="Enter Batch" className="form-control" value={editvalue} onChange={e => this.handlechange(e)} />
                                  <span className="error-shows">{error12}</span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" />
                                <div className="col-sm-5">
                                  {this.state.categorySelected == '' || this.state.categorySelected == null || this.state.editvalue == '' || this.state.editvalue == null ? (
                                    <button type="button" className="btn btn-primary " onClick={this.submitbatch}>
                                      Submit
                                    </button>
                                  ) : (
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.submitbatch}>
                                      Submit
                                    </button>
                                  )}
                                </div>
                                <div className="col-sm-3" />
                              </div>
                            </div>
                          }
                        />

                        {/*<button type="button" className="btn btn-info" onClick={this.refreshbatch}>
                          Refresh
                        </button>*/}
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">Date</div>
                      <div className="col-sm-3">
                        From Date
                        <input type="date" id="myDate" className="form-control" onChange={this.fromDateSelect} value={selectedstartdate} />
                        <span className="error-shows">{this.state.startdateerror}</span>
                      </div>
                      <div className="col-sm-3">
                        To Date
                        <input type="date" className="form-control" onChange={this.endDateSelect} value={selectedenddate} />
                        <span className="error-shows">{this.state.endateerror}</span>
                      </div>
                      <div className="col-sm-2 error-shows"> </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2"></div>
                      <div className="col-sm-6 error-shows"> {this.state.calculatedateerror} </div>
                      <div className="col-sm-2 "> </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">Domain</div>
                      <div className="col-sm-5">
                        <MultiSelect options={domainOptions} handleChange={this.domainSelect} selectedService={selecteddomain} />
                        <span className="error-shows">{error5}</span>
                      </div>
                      <div className="col-sm-2">
                        <LoginModal
                          buttonTitle="Add Domain"
                          title="Add Domain"
                          id="Domain"
                          extraClass="btn btn-primary form-control"
                          onClick={this.reset}
                          bodyText={
                            <div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="subgroup">Domain</label>
                                </div>
                                <div className="col-sm-5">
                                  <input type="text" name="subgroup" placeholder="Enter Domain" className="form-control" value={editvalue} onChange={e => this.handlechange(e)} />
                                  <span className="error-shows">{error13}</span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" />
                                <div className="col-sm-5">
                                  {this.state.editvalue == '' || this.state.editvalue == null ? (
                                    <button type="button" className="btn btn-primary" onClick={this.submitdomain}>
                                      Submit
                                    </button>
                                  ) : (
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.submitdomain}>
                                      Submit
                                    </button>
                                  )}
                                </div>
                                <div className="col-sm-3" />
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">Resources</div>
                      <div className="col-sm-5">
                        <MultiSelect options={this.state.resourcesOption} handleChange={this.resourceSelect} selectedService={selectedResource} />
                        <span className="error-shows">{error6}</span>
                      </div>
                      <div className="col-sm-2">
                        <LoginModal
                          buttonTitle="Add Resource"
                          title="Add Resource"
                          id="Resource"
                          extraClass="btn btn-primary form-control"
                          onClick={this.reset}
                          bodyText={
                            <div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label for="exampleInputEmail1">Select Domain</label>
                                </div>
                                <div className="col-sm-5">
                                  <SingleSelect disabled={disableValue} options={this.state.domainOptions} handleChange={this.domain} selectedService={this.state.selectedDomain} />
                                  <span className="modal-error-show">{errordomain}</span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="Authorname">Resource Name</label>
                                </div>
                                <div className="col-sm-5">
                                  <input type="text" className="form-control" id="Authorname" name="Authorname" placeholder="Resource Name" value={Authorname} onChange={this.authorname} />
                                  <span className="modal-error-show">{error14}</span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="authorimage">Resource File</label>
                                </div>
                                <div className="col-sm-5 custom-file">
                                  <input
                                    type="file"
                                    accept="
                          image/*,
                          video/*,
                          application/pdf,application/msword,
                          applicationvnd.openxmlformats-officedocument.wordprocessingml.document,
                          .zip"
                                    className="custom-file-input"
                                    id="authorimage"
                                    onChange={this.authorimage}
                                  />
                                  <label className="custom-file-label" htmlFor="authorimage">
                                    <span style={{ fontSize: '12px' }}>{this.state.filename}</span>
                                  </label>
                                  <span className="modal-error-show">{errorfile}</span>
                                </div>

                                <div className="col-sm-3" />
                              </div>

                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" />
                                <div className="col-sm-5">
                                  {this.state.selectedDomain == '' ||
                                    this.state.selectedDomain == null ||
                                    this.state.Authorname == '' ||
                                    this.state.Authorname == null ||
                                    this.state.filename == '' ||
                                    this.state.filename == null ? (
                                    <button type="button" className="btn btn-primary" onClick={this.submitresource}>
                                      Submit
                                    </button>
                                  ) : (
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.submitresource}>
                                      Submit
                                    </button>
                                  )}
                                </div>
                                <div className="col-sm-3" />
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">Trainer</div>
                      <div className="col-sm-5">
                        <SingleSelect options={this.state.trainerOptions} handleChange={this.trainerSelect} selectedService={selectedTrainer} />
                        <span className="error-shows">{error7}</span>
                      </div>
                      <div className="col-sm-2">
                        <LoginModal
                          buttonTitle="Add Mentor"
                          title={title}
                          id="customer"
                          extraClass="btn btn-primary form-control"
                          //onClick={this.reset}
                          bodyText={
                            <AddUser
                              userData={userData}
                              errorData={errorData}
                              userType={userType}
                              handleInput={this.handleUserInput}
                              handleSubmit={this.handleSubmit}
                              onDismiss={this.onDismiss}
                              alertVisible={this.state.alertVisible}
                              formAlertdelete={this.state.formAlertdelete}
                              checkMobileNumber={this.checkMobileNumber}
                              checkEmail={this.checkEmail}
                              updateData={this.state.updateData}
                              updateEmail={this.state.updateEmail}
                              uploadimg={this.uploadimg}
                              logoname={this.state.logoname}
                            />
                          }
                        />
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">Cohorts</div>
                      <div className="col-sm-5">
                        <MultiSelect options={groupOptions} handleChange={this.groupSelect} selectedService={selectedGroup} />
                        <span className="error-shows">{error8}</span>
                      </div>
                      <div className="col-sm-2">
                        <LoginModal
                          buttonTitle="Add Cohorts"
                          title="Add Cohorts"
                          id="Cohorts"
                          extraClass="btn btn-primary form-control"
                          onClick={this.reset}
                          bodyText={
                            <div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="subgroup">Cohorts</label>
                                </div>
                                <div className="col-sm-5">
                                  <input type="text" name="subgroup" placeholder="Enter cohort" className="form-control" value={editvalue} onChange={e => this.handlechange(e)} />
                                  <span className="error-shows">{error15}</span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" />
                                <div className="col-sm-5">
                                  {this.state.editvalue == '' || this.state.editvalue == null ? (
                                    <button type="button" className="btn btn-primary" onClick={this.submitcohorts}>
                                      Submit
                                    </button>
                                  ) : (
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.submitcohorts}>
                                      Submit
                                    </button>
                                  )}
                                </div>
                                <div className="col-sm-3" />
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">Location</div>
                      <div className="col-sm-5">
                        <SingleSelect options={locationOptions} handleChange={this.locaionSelect} selectedService={selectedLocation} />
                        <span className="error-shows">{error9}</span>
                      </div>
                      <div className="col-sm-2">
                        <LoginModal
                          buttonTitle="Add Location"
                          title="Add Location"
                          id="Location"
                          extraClass="btn btn-primary form-control"
                          onClick={this.reset}
                          aria-labelledby="Location"
                          aria-hidden="true"
                          bodyText={
                            <div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2">
                                  <label htmlFor="subgroup">Location</label>
                                </div>
                                <div className="col-sm-5">
                                  <input type="text" name="subgroup" placeholder="Enter Location" className="form-control" value={editvalue} onChange={e => this.handlechange(e)} />
                                  <span className="error-shows">{error16}</span>
                                </div>
                                <div className="col-sm-3" />
                              </div>
                              <div className="row form-group">
                                <div className="col-sm-2" />
                                <div className="col-sm-2" />
                                <div className="col-sm-5">
                                  {this.state.editvalue == '' || this.state.editvalue == null ? (
                                    <button type="button" className="btn btn-primary" onClick={this.submitlocation}>
                                      Submit
                                    </button>
                                  ) : (
                                    <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.submitlocation}>
                                      Submit
                                    </button>
                                  )}
                                </div>
                                <div className="col-sm-3" />
                              </div>
                            </div>
                          }
                        />
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">Training Cost</div>
                      <div className="col-sm-5">
                        <input type="text" placeholder="Enter Training Cost" className="form-control" value={trainingCost} onChange={this.trainingCost} />
                        <span className="modal-error-show">{error10}</span>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button type="button" className="btn btn-primary" disabled={disableSubmit} onClick={button === 'Update' ? this.update : this.onSubmitlob}>
                          {this.state.button}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    {this.state.data && this.state.data.length > 0 && (
                      <PDFDownloadLink
                        style={{ color: 'white' }}
                        document={<LearningObjpdf header={this.state.header} data={this.state.data} program={this.state.programOptions} batch={this.state.batchOptions} domain={this.state.domainOptions}
                          res={this.state.resourceOptions} train={this.state.trainerOptions} cohort={this.state.groupOptions} Loc={this.state.locationOptions} />}
                        fileName="sample.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? 'Loading document...' : 'Download Pdf'
                        }
                        <button className="btn btn-warning">
                          PDF
                    </button>
                      </PDFDownloadLink>
                    )}
                    {this.state.excel && this.state.excel.length > 0 && (
                      <CSVLink data={this.state.excel} headers={this.state.excelHead}>
                        <button className="btn btn-success" style={{ marginLeft: '5px' }}>
                          Excel
                   </button>
                      </CSVLink>)}


                    <span style={{ marginLeft: '5px' }}>Total of {Object.keys(this.state.data).length} records</span>
                    <br /><br />
                    {this.state.data && <Datatable data={this.state.data} columnHeading={this.column} />}

                    {/* <QRCode value="http://facebook.github.io/react/" renderAs="svg" includeMargin={true} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default LearningObject;
