import React, { Component } from "react";
import cmsContent from "../../../MiddleWare/CmsContent";
import { SITEBUILDER_ACCESS_POINT } from "../../../config";
import CmsContent from "../../../MiddleWare/CmsContent";


class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: '',
      pagesMapped: false,
      publishWork: false,
      publishing: false,
      published: false,
      publishMsg: false
    };
  }

  async componentDidMount() {
    try {
      let oldUserlog = JSON.parse(localStorage.getItem('userlog'));
      if (oldUserlog) {
        const userlogUpdate = await cmsContent.getFreedom(
          "tbl_user_web.*",
          "tbl_user_web",
          `id=${oldUserlog.id}`,
          "id",
          "id DESC"
        );
        if (userlogUpdate && userlogUpdate.data.length) {
          let { published, publishMsg } = this.state;
          if (userlogUpdate.data[0].published == 1) {
            published = true;
            publishMsg = "Your work was already published!"
          }
          await this.setState({ userDetails: userlogUpdate.data[0], published, publishMsg });
          await localStorage.setItem('userlog', JSON.stringify(userlogUpdate.data[0]));
          const getAllMappedpages = await cmsContent.getFreedom(
            "tbl_mapping_page.id,tbl_mapping_page.language,tbl_mapping_page.pageId,tbl_mapping_page.subCategoryId,tbl_mapping_page.subCategoryName,tbl_mapping_page.categoryName,tbl_mapping_page.categoryId,tbl_mapping_page.status,tbl_mapping_page.subCategoryName,tbl_pages.name,tbl_mapping_page.deptid,tbl_mapping_page.userType",
            "tbl_pages,tbl_mapping_page",
            `tbl_pages.id=tbl_mapping_page.pageId and tbl_pages.customerId=${oldUserlog.id}`,
            "tbl_mapping_page.id",
            "tbl_mapping_page.id DESC"
          );
          if (getAllMappedpages.data.length > 0) await this.setState({ pagesMapped: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  newTempWindow = (userId, templateId = null, domain = null) => {
    if (domain === null) {
      if (templateId === null) {
        window.open(`${SITEBUILDER_ACCESS_POINT}/project/template/choose/${userId}`)
      } else if (templateId) {
        window.open(`${SITEBUILDER_ACCESS_POINT}/project/template/preview/Template${templateId}/${userId}`);
        setTimeout(() => {
          this.setState({ publishWork: true });
        }, 2000);
      }
    } else {
      window.open(`${SITEBUILDER_ACCESS_POINT}/${domain}`);
    }

  }

  publishWork = async () => {
    let { userDetails } = this.state;
    await this.setState({ publishing: true });
    const publish = await CmsContent.updateMaster('tbl_user_web', userDetails.id, { published: 1 });
    if (publish && publish.status === 200) {
      await this.setState({ published: true, publishing: false });
    }
  }

  render() {
    let { userDetails, pagesMapped, publishWork, published, publishing, publishMsg } = this.state;
    if (userDetails.templateId == null) {
      return (
        <React.Fragment>
          <main className="main my-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 style={{ textAlign: 'center' }}>Please choose a template using below button <span style={{ fontSize: '30px' }}>↓</span></h3>
                    </div>
                    <div className="card-body">
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-7">
                          <center>
                            <button className="submitButton" onClick={() => this.newTempWindow(userDetails.id)}>
                              Choose a template
                              <svg className="subIcon" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </center>
                        </div>
                        <div className="col-sm-3" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </React.Fragment>
      )
    } else if (userDetails.templateId && pagesMapped === false) {
      return (
        <React.Fragment>
          <main className="main my-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 style={{ textAlign: 'center' }}> Sorry, we can't provide you previews, until you create your pages and map it to a category. </h3>
                    </div>
                    <div className="card-body">
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        letterSpacing: '0.4rem',
                        color: '#dadce0',
                        fontSize: '200px'
                      }}> <span> (;-;) </span> </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </React.Fragment>
      )
    } else if (userDetails.templateId && pagesMapped) {
      return (
        <React.Fragment>
          <main className="main my-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 style={{ textAlign: 'center' }}> You can preview your mapped pages using below button <span style={{ fontSize: '30px' }}>↓</span></h3>
                    </div>
                    <div className="card-body">
                      <div className="row form-group">
                        <div className="col-sm-2" />
                        <div className="col-sm-7">
                          <center>
                            <button
                              className="submitButton"
                              style={{ width: "250px" }}
                              onClick={() => this.newTempWindow(userDetails.id, userDetails.templateId)}>
                              Preview template
                              <svg className="subIcon" viewBox="0 0 24 24" fill="currentColor">
                                <path
                                  fillRule="evenodd"
                                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                            <br></br>
                            <br></br>
                            {
                              (publishWork)
                                ?
                                <div>
                                  <h3 style={{ textAlign: 'center' }}> Click on publish button to publish your work <span style={{ fontSize: '30px' }}>↓</span></h3>
                                  <br></br>
                                  <button
                                    className="submitButton"
                                    style={{ width: "250px" }}
                                    data-toggle="modal"
                                    data-target={`#publishModal`}
                                  >
                                    Publish
                                  </button>
                                  <div
                                    className="modal fade"
                                    id="publishModal"
                                    tabindex="-1"
                                    role="dialog"
                                    aria-labelledby="exampleModalLabel"
                                    aria-hidden="true"
                                  >
                                    <div className={`modal-dialog modal-md`} role="document">
                                      <div className="modal-content" style={{ marginTop: '90px' }}>
                                        <div className="modal-header" style={{ height: '50px' }}>
                                          <center>
                                            <h3 className="modal-title" id="exampleModalLabel">
                                              Publish your template
                                            </h3>
                                          </center>
                                          <button
                                            type="button"
                                            className="close "
                                            data-dismiss="modal"
                                            aria-label="Close"
                                          >
                                            <span aria-hidden="true">&times;</span>
                                          </button>
                                        </div>
                                        <div className="modal-body" style={{ height: '260px' }}>
                                          <br></br>
                                          <br></br>
                                          {
                                            (published)
                                              ?
                                              <center>
                                                <h2 style={{ color: "forestgreen", fontWeight: '600', fontSize: "larger" }}> {(publishMsg) ? publishMsg : 'Your work was published!'}</h2>
                                                <br></br>
                                                <h3 style={{ textAlign: 'center' }}> Click on the below link to preview your published site <span style={{ fontSize: '30px' }}>↓</span></h3>
                                                <br></br>
                                                <button
                                                  className="submitButton"
                                                  style={{ width: "250px" }}
                                                  onClick={() => this.newTempWindow(userDetails.id, null, userDetails.domain)}>
                                                  Move to site
                                                  <svg className="subIcon" viewBox="0 0 24 24" fill="currentColor">
                                                    <path
                                                      fillRule="evenodd"
                                                      d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                                                      clipRule="evenodd"
                                                    />
                                                  </svg>
                                                </button>
                                              </center>
                                              :
                                              <div className={(publishing) ? "is-disabled" : ""}>
                                                <center>
                                                  <h2 style={{ color: "#424548" }}>Are you sure you want to publish your template?</h2>
                                                </center>
                                                <br></br>
                                                <br></br>
                                                <center>
                                                  <button
                                                    style={{ width: "150px", fontWeight: '600', cursor: 'pointer' }}
                                                    className={`btn btn-lg ${(publishing) ? "btn-secondary" : "btn-success"}`}
                                                    onClick={() => { this.publishWork() }}
                                                  > {(publishing) ? "Publishing..." : "Publish"} </button>
                                                  &nbsp;&nbsp;
                                                  <button
                                                    style={{ width: "150px", fontWeight: '600', cursor: 'pointer' }}
                                                    className="btn btn-lg btn-danger"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                  > Cancel </button>
                                                </center>
                                              </div>
                                          }

                                          <br></br>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <div></div>
                            }

                          </center>
                        </div>
                        <div className="col-sm-3" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main >
        </React.Fragment >
      )
    }

  }
}

export default Preview;