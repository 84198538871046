import React, { Component } from "react";
import { MultiSelect, SingleSelect } from "../../../components/Form";
import CmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { CSVLink } from "react-csv";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
// import { saveAs } from 'file-saver';
import LoginModal from "../../../components/Modal/Modal";
import { Alert } from "reactstrap";
import moment from "moment";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { split } from "lodash";
import axios from "axios";


class AdminReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            checked: 0,
            customerId: localStorage.getItem("userId"),
            errorPageName: null,
            data: [],
            btnDisable: false,
            formAlert: false,
            fromDate: "",
            toDate: "",
            fromDateerror: "",
            toDateerror: "",
            schoolopt: [],
            selectedOption: [],
            customerId: localStorage.getItem("userId"),
            schoolData: [],
            selectSchoolError: "",
            DatatableCounselling: [],
            DatatableTrining: [],
            trainingDataShow: false,
            DataShow: false,
            datashow: true,
            datashow1: true,
            emptyData: false,
            counsellingOptions: [
                { label: "1 to 1 [counseling form]", value: 1 },
                { label: "Group Counseling/Training Form", value: 2 },
            ],
            formTypes: [
                { label: "Student", value: 1 },
                { label: "Teaching Staff", value: 2 },
                { label: "Non-Teaching Staff ", value: 3 },
            ],
            disablebtn: false,
            disablefromdate: true,
            disableenddate: true,
            disableselectform: true,
            counselingDisable: true,
            entityOptions: [],
            entityType: "",
            companyType: "",
            companyDetails: "",
            checkboxChecked: false,
        };
        this.exportToExcel = this.exportToExcel.bind(this);
    }


    async componentDidMount() {
        this.Mainfun()
    }

    Mainfun = async () => {
        try {

            const Email = await CmsContent.getFreedom(
                "id as value, email_id as label",
                "psychol_email",
                `id`,
                "id",
                "id"
            );
            if (Email) {
                this.setState({ EmailOptions: Email.data })
            }
            const State = await CmsContent.getFreedom(
                "id as value,entity_name as label",
                "psychol_entity",
                1,
                "id",
                "id"
            );
            if (State) {
                this.setState({ entityOptions: State.data });
            }

        } catch (error) {
            console.log(error);
        }
    }
    column = [
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "SCHOOL NAME ",
            accessor: "userName",
        },
        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "DOB",
            accessor: "dob",
        },
        {
            Header: "CLASS",
            accessor: "className",
        },
        {
            Header: "DEPARTMENT",
            accessor: "departmentName",
        },

        // {
        //     Header: "STUDENT RANK",
        //     accessor: "studentRank",
        // },
        {
            Header: "NAME OF THE MENTOR",
            accessor: "nameOfMentorName",
        },
        {
            Header: "REASON OF COUNSELING",
            accessor: "reason",
        },
        {
            Header: "DATE OF COUNSELING",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },
        {
            Header: "HOSTEL NAME",
            accessor: "HostelName",
        },

    ];
    column1 = [
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "SCHOOL NAME ",
            accessor: "userName",
        },
        {
            Header: "TOPIC",
            accessor: "topic",
        },
        {
            Header: "DEPARTMENT",
            accessor: "departmentName",
        },
        {
            Header: "DISCRIPTION",
            accessor: "discription",
        },
        {
            Header: "DATE OF COUNSELING",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },

    ];
    column2 = [
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "SCHOOL NAME ",
            accessor: "userName",
        },

        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "AGE",
            accessor: "Age",
        },
        {
            Header: "DEPARTMENT",
            accessor: "departmentName",
        },
        {
            Header: "CLASS",
            accessor: "className",
        },
        {
            Header: "GENDER",
            accessor: "gender",
        },
        {
            Header: "MARITAL STATUS",
            accessor: "MaritalStatus",
        },
        {
            Header: "DISCRIPTION",
            accessor: "discription",
        },
        {
            Header: "SUBJECT",
            accessor: "Subject",
        },
        {
            Header: "Date",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },
    ];
    column3 = [
        {
            Header: "COUNSELING TYPE",
            accessor: "counsellingType",
        },
        {
            Header: "SCHOOL NAME ",
            accessor: "userName",
        },

        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "AGE",
            accessor: "Age",
        },
        {
            Header: "GENDER",
            accessor: "gender",
        },
        {
            Header: "MARITAL STATUS",
            accessor: "MaritalStatus",
        },
        {
            Header: "DISCRIPTION",
            accessor: "discription",
        },
        {
            Header: "HOSTEL NAME",
            accessor: "HostelName",
        },
        {
            Header: "NTS",
            accessor: "NTSname",
        },
        {
            Header: "DATE",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },
    ];
    column4 = [

        {
            Header: "ENTITY NAME",
            accessor: "userName",
        },
        {
            Header: "NAME",
            accessor: "name",
        },
        {
            Header: "EMPLOYEE ID",
            accessor: "employeeId",
        },
        {
            Header: "MOBILE",
            accessor: "mobileNumber",
        },
        {
            Header: "GENDER",
            accessor: "gender",
        },
        {
            Header: "DESGNATION",
            accessor: "designation",
        },
        {
            Header: "DISCRIPTION",
            accessor: "discription",
        },
        {
            Header: "REASON OF COUNSELING",
            accessor: "reason",
        },
        {
            Header: "DATE OF COUNSELING",
            accessor: "date",
            // Cell: (d) => this.dateFormateChange(d),

        },
    ];
    excelcolumn1 = [
        {
            label: "COUNSELING TYPE",
            key: "counsellingType",
        },
        {
            label: "SCHOOL NAME",
            key: "userName",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "TOPIC",
            key: "topic",
        },
        {
            label: "DEPARTMENT",
            key: "departmentName",
        },

        {
            label: "DISCRIPTION",
            key: "discription",
        },
        {
            label: "REASON OF COUNSELING",
            key: "reason",
        },
        {
            label: "DATE OF COUNSELING",
            key: "date",
        },

    ]
    excelColumn = [
        {
            label: "COUNSELLING TYPE",
            key: "counsellingType",
        },
        {
            label: "SCHOOL NAME ",
            key: "userName",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "DOB",
            key: "dob",
        },
        {
            label: "CLASS",
            key: "className",
        },
        {
            label: "STUDENT RANK",
            key: "studentRank",
        },
        {
            label: "DEPARTMENT",
            key: "departmentName",
        },
        {
            label: "NAME OF THE MENTOR",
            key: "nameOfMentorName",
        },
        {
            label: "REASON OF COUNSELLING",
            key: "reason",
        },
        {
            label: "DATE OF COUNSELLING",
            key: "date",
        },
        {
            label: "HOSTEL NAME",
            key: "HostelName",
        },
        // {
        //     Header: "EDIT",
        //     accessor: "edit",
        //     Cell: (d) => this.Editpages(d, 'customer'),
        // },
        // {
        //     Header: "STATUS",
        //     // accessor: "edit",
        //     Cell: (d) => this.buttondel(d),
        // },
        // {
        //     Header: "Delete",
        //     accessor: "edit",
        //     Cell: (d) => this.DatatableCheckboxx(d, this.updateMyData, this.state.SelectedEnquiries)
        // }
    ];
    excelcolumn2 = [
        {
            label: "COUNSELING TYPE",
            key: "counsellingType",
        },
        {
            label: "SCHOOL NAME ",
            key: "userName",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "Age",
            key: "Age",
        },
        {
            label: "DEPARTMENT",
            key: "departmentName",
        },
        {
            label: "CLASS",
            key: "className",
        },
        {
            label: "GENDER",
            key: "gender",
        },
        {
            label: "MARITAL STATUS",
            key: "MaritalStatus",
        },
        {
            label: "DISCRIPTION",
            key: "discription",
        },
        {
            label: "SUBJECT",
            key: "Subject",
        },
        {
            label: "DATE",
            key: "date",
        },

    ]
    excelcolumn3 = [
        {
            label: "COUNSELING TYPE",
            key: "counsellingType",
        },
        {
            label: "SCHOOL NAME ",
            key: "userName",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "AGE",
            key: "Age",
        },
        {
            label: "GENDER",
            key: "gender",
        },
        {
            label: "MARITAL STATUS",
            key: "MaritalStatus",
        },
        {
            label: "HOSTEL NAME",
            key: "HostelName",
        },
        {
            label: "DISCRIPTION",
            key: "discription",
        },
        {
            label: "NTS",
            key: "NTSname",
        },
        {
            label: "DATE",
            key: "date",
        },

    ]
    excelcolumn4 = [
        {
            label: "ENTITY NAME",
            key: "userName",
        },
        {
            label: "NAME",
            key: "name",
        },
        {
            label: "EMPLOYEE ID",
            key: "employeeId",
        },
        {
            label: "MOBILE",
            key: "mobileNumber",
        },
        {
            label: "GENDER",
            key: "gender",
        },
        {
            label: "DESGNATION",
            key: "designation",
        },
        {
            label: "DISCRIPTION",
            key: "discription",
        },
        {
            label: "REASON OF COUNSELING",
            key: "reason",
        },
        {
            label: "DATE OF COUNSELING",
            key: "date",

        },

    ]
    handleChange = (e) => {
        const { fromDateerror, toDateerror } = this.state
        const { name, value } = e.target
        this.setState({ [name]: value, DatatableCounselling: [], DatatableTrining: [], disablebtn: false })
        this.setState({ fromDateerror: "", toDateerror: "", selectSchoolError: "" })
        if (name === "fromDate") {
            this.setState({ disableenddate: false, toDate: "", disablebtn: false })
        }

    }
    optionSelectentity = async (selectedOption) => {
        if (selectedOption) {
            await this.setState({
                entityType: selectedOption,
            });
            const school = await CmsContent.getFreedom(
                "id as value,userName as label",
                "tbl_user_web",
                `customerId=${this.state.customerId} AND entitytype=${selectedOption.value}`,
                "id",
                "id"
            );
            if (school) {
                this.setState({
                    schoolopt: school.data, companyOptions: school.data, fromDate: "", toDate: "", fromDateerror: "", toDateerror: "", selectSchoolError: "", checkboxChecked: false,
                    formTypesSelected: "", counselingDisable: false, disableselectform: true, counsellingOptSelect: "", schoolData: "", companyType: "",
                    disablefromdate: true, formTypesSelectedValue: "", disableenddate: true,
                    // DatatableCounselling: [],
                    // DatatableTrining: [], DatatableNTS: [], DatatableTS: [],
                });
            }
        }
    }
    optionSelectCompany = async (selectedOption) => {
        if (selectedOption) {
            const { entityType } = this.state
            if (this.state.entityType.value && selectedOption.value) {
                console.log(selectedOption, "success")
                const Email = await CmsContent.getFreedom(
                    "*",
                    "psychol_email",
                    `id`,
                    "id",
                    "id"
                );
                console.log(Email.data, "emailaddress")
                var array = []
                if (entityType) {
                    console.log("enter entity type")
                    console.log(Email.data)
                    Email.data.filter((ival) => {
                        // console.log(ival)
                        if (ival.entitytype_id !== null && ival.entity_id !== null) {
                            const entityTypeArray = JSON.parse(ival.entitytype_id);
                            const entityName = JSON.parse(ival.entity_id);
                            console.log(entityTypeArray, entityName)
                            if (entityTypeArray.includes(entityType.value) && entityName.includes(selectedOption.value)) {
                                let obj = {
                                    value: ival.id,
                                    label: ival.email_id
                                }
                                array.push(obj)
                            }
                        }
                    });
                    console.log(array, "selectEntityType")
                }
            }
            await this.setState({
                companyType: selectedOption,
                disablefromdate: false,
                EmailOptions: array,
            });

        }
    }
    optionSelect = async (selectedOption, type) => {
        // if (selectedOption) {
        //     this.setState({ disableselectform: false })
        //     let selectedValue = "";
        //     selectedOption.map(values => (selectedValue += `${values.value},`));
        //     selectedValue = selectedValue.replace(/,\s*$/, "");
        //     console.log(selectedValue, "selectedValue");
        //     await this.setState({
        //         schoolData: selectedOption
        //     })
        //     this.setState({
        //         fromDateerror: "", toDateerror: "", selectSchoolError: "", formTypesSelected: "",
        //         disablefromdate: true, formTypesSelectedValue: "", disableenddate: true, fromDate: "", toDate: "", disablebtn: false, DatatableCounselling: [],
        //         DatatableTrining: [],DatatableNTS:[],DatatableTS:[]
        //     })
        // }
        if (selectedOption && type == "school") {
            const { entityType } = this.state
            if (this.state.entityType.value && selectedOption.value) {
                const Email = await CmsContent.getFreedom(
                    "*",
                    "psychol_email",
                    `id`,
                    "id",
                    "id"
                );
                var array = []
                if (entityType) {
                    Email.data.filter((ival, indexes) => {
                        if (ival.entity_id == null) {
                            let obj = {
                                value: ival.id,
                                label: ival.email_id + "- unmapped"
                            }
                            console.log(obj, "obj")
                            array.push(obj)
                        }
                        else if (ival.entity_id != null) {
                            var entity = JSON.parse(ival.entity_id)
                            if (entity.length > 1) {
                                let obj = {
                                    value: ival.id,
                                    label: ival.email_id + "- admin"
                                }
                                array.push(obj)
                            }
                            else if (entity.length = 1) {
                                this.state.schoolopt.map((jval, index) => {
                                    if (entity.length == 1 && entity[0] == jval.value) {
                                        let obj = {
                                            value: ival.id,
                                            label: ival.email_id + `- ${jval.label}`
                                        }
                                        array.push(obj)
                                    }

                                })
                            }

                        }

                    })
                }
            }
            this.setState({
                schoolData: selectedOption, EmailOptions: array, fromDateerror: "", toDateerror: "", selectSchoolError: "",
                formTypesSelected: "", counselingDisable: false, disableselectform: true, counsellingOptSelect: "",
                disablefromdate: true, formTypesSelectedValue: "", disableenddate: true, fromDate: "",
                toDate: "", disablebtn: false, DatatableCounselling: [],
                DatatableTrining: [],
            })

        }
        if (type == "counsellingType") {
            this.setState({
                counsellingOptSelect: selectedOption, checkboxChecked: false, disableselectform: false, fromDateerror: "", toDateerror: "", selectSchoolError: "",
                formTypesSelected: "",
                disablefromdate: true, formTypesSelectedValue: "", disableenddate: true, fromDate: "", toDate: "", disablebtn: false, DatatableCounselling: [],
                DatatableTrining: [],
            })
        }
    }
    optionSelect2 = async (selectedOption , type) => {
        if (selectedOption && type === "formtype") {
            this.setState({ disablefromdate: false })
            let selectedValue = "";
            selectedOption.map(values => (selectedValue += `${values.value},`));
            selectedValue = selectedValue.replace(/,\s*$/, "");
            await this.setState({
                formTypesSelected: selectedOption,
                formTypesSelectedValue: selectedValue, DatatableCounselling: [], DatatableTrining: [],
                fromDate: "", toDate: "", disableenddate: true, disablebtn: false, DatatableNTS: [], DatatableTS: []
            });
        }
        if (selectedOption && type == "school") {
            const { entityType } = this.state
            if (this.state.entityType.value ) {
                const Email = await CmsContent.getFreedom(
                    "*",
                    "psychol_email",
                    `id`,
                    "id",
                    "id"
                );
                var array = []
                if (entityType) {
                    Email.data.filter((ival, indexes) => {
                        if (ival.entity_id == null) {
                            let obj = {
                                value: ival.id,
                                label: ival.email_id + "- unmapped"
                            }
                            console.log(obj, "obj")
                            array.push(obj)
                        }
                        else if (ival.entity_id != null) {
                            var entity = JSON.parse(ival.entity_id)
                            if (entity.length > 1) {
                                let obj = {
                                    value: ival.id,
                                    label: ival.email_id + "- admin"
                                }
                                array.push(obj)
                            }
                            else if (entity.length = 1) {
                                this.state.schoolopt.map((jval, index) => {
                                    if (entity.length == 1 && entity[0] == jval.value) {
                                        let obj = {
                                            value: ival.id,
                                            label: ival.email_id + `- ${jval.label}`
                                        }
                                        array.push(obj)
                                    }

                                })
                            }

                        }

                    })
                }
            }
            let selectedValue = "";
            selectedOption.map(values => (selectedValue += `${values.value},`));
            selectedValue = selectedValue.replace(/,\s*$/, "");
            this.setState({
                schoolData: selectedOption, EmailOptions: array, fromDateerror: "", toDateerror: "", selectSchoolError: "",
                formTypesSelected: "", counselingDisable: false, disableselectform: true, counsellingOptSelect: "",
                disablefromdate: true, formTypesSelectedValue: "", disableenddate: true, fromDate: "",
                toDate: "", disablebtn: false, DatatableCounselling: [],
                DatatableTrining: [],
            })

        }

    };

    submit = async () => {
        const { fromDate, toDate, schoolData, formTypesSelected, emptyData, counsellingOptSelect, entityType, companyType, counsellingOptSelectcheckbox, formTypesSelectedcheckbox } = this.state
        this.setState({ loading: true, emptyData: true, })
        if (entityType.value == 2) {
            let obj = {}
            obj.fromDate = fromDate
            obj.toDate = toDate
            obj.entityType = entityType
            obj.company = companyType
            console.log(obj, "obj")
            // return false
            const submit_data = await CmsContent.GetSchoolFormReport(this.state.customerId, "schoolFormReport", obj)
            this.setState({ companyDetails: submit_data })
            setTimeout(() => {
                this.setState({ loading: false })
            }, 3000);
        }
        else {
            try {
                let obj = {}
                obj.fromDate = fromDate
                obj.toDate = toDate
                obj.schoolData = schoolData
                obj.counsellingtype = counsellingOptSelectcheckbox ? counsellingOptSelectcheckbox : counsellingOptSelect
                obj.entityType = entityType
                obj.formTypesSelected = formTypesSelectedcheckbox ? formTypesSelectedcheckbox : formTypesSelected
                console.log(obj, "obj")
                // return false
                const submit_data = await CmsContent.GetSchoolFormReport(this.state.customerId, "schoolFormReport", obj)
                console.log(submit_data, "submit_data");
                const submit = this.formatDateInForms(submit_data);
                this.setState({ DatatableCounselling: submit.CounsellingForm, DatatableTrining: submit.triningForm, DatatableTS: submit.TSForm, DatatableNTS: submit.NTSForm, DataShow: true, trainingDataShow: true })

                setTimeout(() => {
                    this.setState({ loading: false })
                }, 3000);

            }
            catch (error) {
                console.log(error)
            }
        }
    }
    formatDateInForms = (data) => {
        const formatDate = (date) => moment(date).format('DD-MM-YYYY');

        const formatArrayDates = (array) => {
            console.log(array, "array")
            return array.map(item => {
                if (item.date) {
                    item.date = formatDate(item.date);
                    console.log(item.date)
                }
                return item;
            });
        };

        Object.keys(data).forEach(key => {
            if (Array.isArray(data[key])) {
                data[key] = formatArrayDates(data[key]);
            }
        });

        return data;
    };

    convertToSheetData = (headers, data) => {
        // Extract labels from headers
        const labels = headers.map(header => header.label);

        // Extract data based on keys
        const sheetData = data.map(item => {
            return headers.map(header => item[header.key]);
        });

        // Add labels as the first row
        sheetData.unshift(labels);

        return sheetData;
    };

    // exportToExcel() {
    //     let { DatatableCounselling, DatatableTrining, DatatableTS, DatatableNTS, schoolData } = this.state;

    //     const staticDepartments = [
    //         ['1', 'MIDDLE  BOYS'],
    //         ['2', 'MIDDLE GIRLS'],
    //         ['3', 'HIGH SCHOOL BOYS'],
    //         ['4', 'HIGH SCHOOL GIRLS'],
    //         ['5', 'HIGHER SEC BOYS'],
    //         ['6', 'HIGHER SEC GIRLS'],
    //         ['7', 'HOSTEL BOYS'],
    //         ['8', 'HOSTEL GIRLS'],
    //         ['9', 'LONG TERM BOYS'],
    //         ['10', 'LONG TERM GIRLS'],
    //         ['11', 'PRIMARY BOYS & GIRLS'],
    //         ['12', 'GIRLS AND BOYS WARDEN'],
    //         ['13', '10 MIN SLOGAN (GRADE 9- GRADE 12)'],
    //         ['14', 'STAFF COUNSELING'],
    //         ['15', 'OTHERS IF ANY']
    //     ];

    //     const dataByDate = {};

    //     const addDescriptions = (data, nameField) => {
    //         data.forEach(item => {
    //             const departmentName = item[nameField].trim();
    //             const departmentRow = staticDepartments.find(row => row[1].trim() === departmentName);

    //             if (!dataByDate[item.date]) {
    //                 dataByDate[item.date] = JSON.parse(JSON.stringify(staticDepartments));
    //             }

    //             const dateEntry = dataByDate[item.date].find(row => row[1].trim() === departmentName);

    //             if (dateEntry) {
    //                 dateEntry.push(item.date, item.discription);
    //             } else {
    //                 dataByDate[item.date].push([staticDepartments.length + 1, departmentName, item.date, item.discription]);
    //             }
    //         });
    //     };

    //     // Add descriptions for each array
    //     DatatableTrining && DatatableTrining.length && addDescriptions(DatatableTrining, 'departmentName');
    //     DatatableTS && DatatableTS.length && addDescriptions(DatatableTS, 'departmentName');
    //     DatatableNTS && DatatableNTS.length && addDescriptions(DatatableNTS, 'NTSname');

    //     // Ensure all dates in DatatableCounselling are included
    //     DatatableCounselling.forEach(item => {
    //         if (!dataByDate[item.date]) {
    //             dataByDate[item.date] = JSON.parse(JSON.stringify(staticDepartments));
    //         }
    //     });

    //     const workbook = new ExcelJS.Workbook();
    //     let monthSheetname;
    //     let rowMerge;

    //     // Get sorted dates
    //     const sortedDates = Object.keys(dataByDate).sort((a, b) => moment(a, "DD-MM-YYYY") - moment(b, "DD-MM-YYYY"));

    //     sortedDates.forEach(date => {
    //         const formattedDate = moment(date, "DD-MM-YYYY").format("MM-DD-YYYY");
    //         const startDate = new Date(formattedDate);
    //         const currentMonth = startDate.getMonth() + 1;

    //         const monthNames = [
    //             'January', 'February', 'March', 'April', 'May', 'June', 'July',
    //             'August', 'September', 'October', 'November', 'December'
    //         ];
    //         const currentMonthName = monthNames[currentMonth - 1];
    //         monthSheetname = currentMonthName

    //         const wsData = [
    //             ['VELAMMAL KNOWLEDGE PARK - CENTRAL OFFICE'],
    //             [`SCHOOL NAME - ${schoolData.label.toUpperCase()}`, '', ''],
    //             [`COUNSELING SCHEDULE - ${currentMonthName.toUpperCase()} (GROUP AND INDIVIDUAL)`, '', '', ''],
    //             ['S. NO', 'GRADE AND DEPARTMENT', 'DATE OF COUNSELING', 'TOPIC COVERED IN GROUP COUNSELING'],
    //             ...dataByDate[date],
    //             [],
    //             ['INDIVIDUAL STUDENT OR PARENT COUNSELING LIST'],
    //             ['S. NO', 'NAME OF THE STUDENT', 'CLASS', 'NAME OF THE MENTOR /WARDEN', 'REASON FOR THE COUNSELING', 'WHETHER COUNSELING CONDUCTED ALONG WITH PARENT (YES OR NO )'],
    //             ...DatatableCounselling.filter(item => item.date === date).map((item, index) => [
    //                 index + 1,
    //                 item.name,
    //                 item.className,
    //                 item.nameOfMentorName,
    //                 item.reason,
    //                 item.withparent ? item.withparent : ""
    //             ])
    //         ];

    //         const worksheet = workbook.addWorksheet(moment(date, "DD-MM-YYYY").format("DD-MM-YYYY"));

    //         wsData.forEach((row, rowIndex) => {
    //             const newRow = worksheet.addRow(row);
    //             // Apply bold style to the first few rows and the specific header rows
    //             if (rowIndex < 4 || rowIndex === wsData.length - DatatableCounselling.filter(item => item.date === date).length - 2) {
    //                 newRow.eachCell(cell => {
    //                     cell.font = { bold: true };
    //                 });
    //             }
    //             // Apply bold style to the second header row
    //             if (rowIndex === wsData.length - DatatableCounselling.filter(item => item.date === date).length - 1) {
    //                 newRow.eachCell(cell => {
    //                     cell.font = { bold: true };
    //                 });
    //             }
    //             // Apply borders to all cells
    //             newRow.eachCell(cell => {
    //                 cell.border = {
    //                     top: { style: 'thin' },
    //                     left: { style: 'thin' },
    //                     bottom: { style: 'thin' },
    //                     right: { style: 'thin' }
    //                 };
    //             });

    //             // Merge cells for specific rows
    //             // console.log(rowIndex,"rowIndex");
    //             // console.log(row,"row");
    //             // console.log(wsData.indexOf(['INDIVIDUAL STUDENT OR PARENT COUNSELING LIST']),"--");
    //             if (row[0] == 'INDIVIDUAL STUDENT OR PARENT COUNSELING LIST') {
    //                 console.log("newRow.number", newRow.number);
    //                 worksheet.mergeCells(`A${newRow.number}:F${newRow.number}`);
    //                 rowMerge = `A${newRow.number}`
    //                 // const mergedCell = newRow.getCell(1);
    //                 // mergedCell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: false };
    //             }
    //         });

    //         // });

    //         // Set column widths
    //         worksheet.columns = [
    //             { width: 10 },  // S. NO
    //             { width: 40 },  // GRADE AND DEPARTMENT
    //             { width: 30 },  // DATE OF COUNSELLING
    //             { width: 60 },  // TOPIC COVERED IN GROUP COUNSELLING
    //             { width: 80 },  // NAME OF THE MENTOR /WARDEN
    //             { width: 90 },  // REASON FOR THE COUNSELLING
    //             { width: 40 }   // WHETHER COUNSELLING CONDUCTED ALONG WITH PARENT (YES OR NO )
    //         ];

    //         // Ensure text wrapping and alignment
    //         worksheet.columns.forEach(column => {
    //             column.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
    //         });

    //         console.log(rowMerge, "rowMerge");
    //         // Merge cells for 'SCHOOL NAME -' row
    //         worksheet.mergeCells('A1:D1');
    //         worksheet.mergeCells('A2:D2');
    //         worksheet.mergeCells('A3:D3');
    //         // worksheet.mergeCells(rowMerge)
    //         console.log("enter 1");
    //         worksheet.getCell('A1').alignment = { horizontal: 'center' };
    //         worksheet.getCell(rowMerge).alignment = { horizontal: 'center' };

    //     });

    //     // Write to file
    //     // workbook.xlsx.writeBuffer().then(buffer => {
    //     //     saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${schoolData.label.toUpperCase()}_${monthSheetname}.xlsx`);
    //     // });
    //     // workbook.xlsx.writeBuffer().then(buffer => {
    //     //     saveAs(new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `${schoolData.label.toUpperCase()}_${monthSheetname}.xlsx`);
    //     // });
    //     // Write to file and get the buffer
    //     workbook.xlsx.writeBuffer().then(buffer => {
    //         const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    //         // Create a link element, set the URL and click to start download
    //         // const link = document.createElement('a');
    //         // link.href = window.URL.createObjectURL(blob);
    //         // link.download = `${schoolData.label.toUpperCase()}_${monthSheetname}.xlsx`;
    //         // document.body.appendChild(link);
    //         // link.click();
    //         // document.body.removeChild(link);
    //         saveAs(blob, `${schoolData.label.toUpperCase()}_${monthSheetname}.xlsx`)
    //     }).catch(error => {
    //         console.error('Error exporting Excel file:', error);
    //         alert('Failed to export Excel file. Please try again.');
    //     });
    // }
    exportToExcel() {
        // Extract state data
        let { DatatableCounselling, DatatableTrining, DatatableTS, DatatableNTS, schoolData } = this.state;

        const staticDepartments = [
            ['1', 'MIDDLE BOYS'],
            ['2', 'MIDDLE GIRLS'],
            ['3', 'HIGH SCHOOL BOYS'],
            ['4', 'HIGH SCHOOL GIRLS'],
            ['5', 'HIGHER SEC BOYS'],
            ['6', 'HIGHER SEC GIRLS'],
            ['7', 'HOSTEL BOYS'],
            ['8', 'HOSTEL GIRLS'],
            ['9', 'LONG TERM BOYS'],
            ['10', 'LONG TERM GIRLS'],
            ['11', 'PRIMARY BOYS & GIRLS'],
            ['12', 'GIRLS AND BOYS WARDEN'],
            ['13', '10 MIN SLOGAN (GRADE 9- GRADE 12)'],
            ['14', 'STAFF COUNSELING'],
            ['15', 'OTHERS IF ANY']
        ];

        const dataByDate = {};

        const addDescriptions = (data, nameField) => {
            data.forEach(item => {
                const departmentName = item[nameField].trim();
                const departmentRow = staticDepartments.find(row => row[1].trim() === departmentName);

                if (!dataByDate[item.date]) {
                    dataByDate[item.date] = JSON.parse(JSON.stringify(staticDepartments));
                }

                const dateEntry = dataByDate[item.date].find(row => row[1].trim() === departmentName);

                if (dateEntry) {
                    dateEntry.push(item.date, item.discription);
                } else {
                    dataByDate[item.date].push([staticDepartments.length + 1, departmentName, item.date, item.discription]);
                }
            });
        };

        // Add descriptions for each array
        if (DatatableTrining && DatatableTrining.length) addDescriptions(DatatableTrining, 'departmentName');
        if (DatatableTS && DatatableTS.length) addDescriptions(DatatableTS, 'departmentName');
        if (DatatableNTS && DatatableNTS.length) addDescriptions(DatatableNTS, 'NTSname');

        // Ensure all dates in DatatableCounselling are included
        DatatableCounselling.forEach(item => {
            if (!dataByDate[item.date]) {
                dataByDate[item.date] = JSON.parse(JSON.stringify(staticDepartments));
            }
        });

        const workbook = new ExcelJS.Workbook();
        let monthSheetname;
        let rowMerge;

        // Get sorted dates
        const sortedDates = Object.keys(dataByDate).sort((a, b) => moment(a, "DD-MM-YYYY") - moment(b, "DD-MM-YYYY"));

        sortedDates.forEach(date => {
            const formattedDate = moment(date, "DD-MM-YYYY").format("MM-DD-YYYY");
            const startDate = new Date(formattedDate);
            const currentMonth = startDate.getMonth() + 1;

            const monthNames = [
                'January', 'February', 'March', 'April', 'May', 'June', 'July',
                'August', 'September', 'October', 'November', 'December'
            ];
            const currentMonthName = monthNames[currentMonth - 1];
            monthSheetname = currentMonthName

            const wsData = [
                ['VELAMMAL KNOWLEDGE PARK - CENTRAL OFFICE'],
                [`SCHOOL NAME - ${schoolData.label.toUpperCase()}`, '', ''],
                [`COUNSELING SCHEDULE - ${currentMonthName.toUpperCase()} (GROUP AND INDIVIDUAL)`, '', '', ''],
                ['S. NO', 'GRADE AND DEPARTMENT', 'DATE OF COUNSELING', 'TOPIC COVERED IN GROUP COUNSELING'],
                ...dataByDate[date],
                [],
                ['INDIVIDUAL STUDENT OR PARENT COUNSELING LIST'],
                ['S. NO', 'NAME OF THE STUDENT', 'CLASS', 'NAME OF THE MENTOR /WARDEN', 'REASON FOR THE COUNSELING', 'WHETHER COUNSELING CONDUCTED ALONG WITH PARENT (YES OR NO )'],
                ...DatatableCounselling.filter(item => item.date === date).map((item, index) => [
                    index + 1,
                    item.name,
                    item.className,
                    item.nameOfMentorName,
                    item.reason,
                    item.withparent ? item.withparent : ""
                ])
            ];

            const worksheet = workbook.addWorksheet(moment(date, "DD-MM-YYYY").format("DD-MM-YYYY"));

            wsData.forEach((row, rowIndex) => {
                const newRow = worksheet.addRow(row);
                // Apply bold style to the first few rows and the specific header rows
                if (rowIndex < 4 || rowIndex === wsData.length - DatatableCounselling.filter(item => item.date === date).length - 2) {
                    newRow.eachCell(cell => {
                        cell.font = { bold: true };
                    });
                }
                // Apply bold style to the second header row
                if (rowIndex === wsData.length - DatatableCounselling.filter(item => item.date === date).length - 1) {
                    newRow.eachCell(cell => {
                        cell.font = { bold: true };
                    });
                }
                // Apply borders to all cells
                newRow.eachCell(cell => {
                    cell.border = {
                        top: { style: 'thin' },
                        left: { style: 'thin' },
                        bottom: { style: 'thin' },
                        right: { style: 'thin' }
                    };
                });

                if (row[0] === 'INDIVIDUAL STUDENT OR PARENT COUNSELING LIST') {
                    worksheet.mergeCells(`A${newRow.number}:F${newRow.number}`);
                    rowMerge = `A${newRow.number}`;
                }
            });

            // Set column widths
            worksheet.columns = [
                { width: 10 },  // S. NO
                { width: 40 },  // GRADE AND DEPARTMENT
                { width: 30 },  // DATE OF COUNSELLING
                { width: 60 },  // TOPIC COVERED IN GROUP COUNSELLING
                { width: 80 },  // NAME OF THE MENTOR /WARDEN
                { width: 90 },  // REASON FOR THE COUNSELLING
                { width: 40 }   // WHETHER COUNSELLING CONDUCTED ALONG WITH PARENT (YES OR NO )
            ];

            // Ensure text wrapping and alignment
            worksheet.columns.forEach(column => {
                column.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
            });

            worksheet.mergeCells('A1:D1');
            worksheet.mergeCells('A2:D2');
            worksheet.mergeCells('A3:D3');
            worksheet.getCell('A1').alignment = { horizontal: 'center' };
            worksheet.getCell(rowMerge).alignment = { horizontal: 'center' };
        });

        // Write to file and download using FileSaver.js
        workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, `${schoolData.label.toUpperCase()}_${monthSheetname}.xlsx`);
        }).catch(error => {
            console.error('Error exporting Excel file:', error);
            alert('Failed to export Excel file. Please try again.');
        });
    }


    // exportToExcelAndSendEmail = async () => {
    //     let { DatatableCounselling, DatatableTrining, DatatableTS, DatatableNTS, schoolData, emailSelectedValues } = this.state;

    //     const staticDepartments = [
    //         ['1', 'MIDDLE  BOYS'],
    //         ['2', 'MIDDLE GIRLS'],
    //         ['3', 'HIGH SCHOOL BOYS'],
    //         ['4', 'HIGH SCHOOL GIRLS'],
    //         ['5', 'HIGHER SEC BOYS'],
    //         ['6', 'HIGHER SEC GIRLS'],
    //         ['7', 'HOSTEL BOYS'],
    //         ['8', 'HOSTEL GIRLS'],
    //         ['9', 'LONG TERM BOYS'],
    //         ['10', 'LONG TERM GIRLS'],
    //         ['11', 'PRIMARY BOYS & GIRLS'],
    //         ['12', 'GIRLS AND BOYS WARDEN'],
    //         ['13', '10 MIN SLOGAN (GRADE 9- GRADE 12)'],
    //         ['14', 'STAFF COUNSELING'],
    //         ['15', 'OTHERS IF ANY']
    //     ];

    //     const dataByDate = {};

    //     const addDescriptions = (data, nameField) => {
    //         data.forEach(item => {
    //             const departmentName = item[nameField].trim();
    //             const departmentRow = staticDepartments.find(row => row[1].trim() === departmentName);

    //             if (!dataByDate[item.date]) {
    //                 dataByDate[item.date] = JSON.parse(JSON.stringify(staticDepartments));
    //             }

    //             const dateEntry = dataByDate[item.date].find(row => row[1].trim() === departmentName);

    //             if (dateEntry) {
    //                 dateEntry.push(item.date, item.discription);
    //             } else {
    //                 dataByDate[item.date].push([staticDepartments.length + 1, departmentName, item.date, item.discription]);
    //             }
    //         });
    //     };

    //     // Add descriptions for each array
    //     DatatableTrining && DatatableTrining.length && addDescriptions(DatatableTrining, 'departmentName');
    //     DatatableTS && DatatableTS.length && addDescriptions(DatatableTS, 'departmentName');
    //     DatatableNTS && DatatableNTS.length && addDescriptions(DatatableNTS, 'NTSname');

    //     // Ensure all dates in DatatableCounselling are included
    //     DatatableCounselling.forEach(item => {
    //         if (!dataByDate[item.date]) {
    //             dataByDate[item.date] = JSON.parse(JSON.stringify(staticDepartments));
    //         }
    //     });

    //     const workbook = new ExcelJS.Workbook();
    //     let monthSheetname;
    //     let rowMerge;

    //     // Get sorted dates
    //     const sortedDates = Object.keys(dataByDate).sort((a, b) => moment(a, "DD-MM-YYYY") - moment(b, "DD-MM-YYYY"));

    //     sortedDates.forEach(date => {
    //         const formattedDate = moment(date, "DD-MM-YYYY").format("MM-DD-YYYY");
    //         const startDate = new Date(formattedDate);
    //         const currentMonth = startDate.getMonth() + 1;

    //         const monthNames = [
    //             'January', 'February', 'March', 'April', 'May', 'June', 'July',
    //             'August', 'September', 'October', 'November', 'December'
    //         ];
    //         const currentMonthName = monthNames[currentMonth - 1];
    //         monthSheetname = currentMonthName

    //         const wsData = [
    //             ['VELAMMAL KNOWLEDGE PARK - CENTRAL OFFICE'],
    //             [`SCHOOL NAME - ${schoolData.label.toUpperCase()}`, '', ''],
    //             [`COUNSELING SCHEDULE - ${currentMonthName.toUpperCase()} (GROUP AND INDIVIDUAL)`, '', '', ''],
    //             ['S. NO', 'GRADE AND DEPARTMENT', 'DATE OF COUNSELING', 'TOPIC COVERED IN GROUP COUNSELING'],
    //             ...dataByDate[date],
    //             [],
    //             ['INDIVIDUAL STUDENT OR PARENT COUNSELING LIST'],
    //             ['S. NO', 'NAME OF THE STUDENT', 'CLASS', 'NAME OF THE MENTOR /WARDEN', 'REASON FOR THE COUNSELING', 'WHETHER COUNSELING CONDUCTED ALONG WITH PARENT (YES OR NO )'],
    //             ...DatatableCounselling.filter(item => item.date === date).map((item, index) => [
    //                 index + 1,
    //                 item.name,
    //                 item.className,
    //                 item.nameOfMentorName,
    //                 item.reason,
    //                 item.withparent ? item.withparent : ""
    //             ])
    //         ];

    //         const worksheet = workbook.addWorksheet(moment(date, "DD-MM-YYYY").format("DD-MM-YYYY"));

    //         wsData.forEach((row, rowIndex) => {
    //             const newRow = worksheet.addRow(row);
    //             // Apply bold style to the first few rows and the specific header rows
    //             if (rowIndex < 4 || rowIndex === wsData.length - DatatableCounselling.filter(item => item.date === date).length - 2) {
    //                 newRow.eachCell(cell => {
    //                     cell.font = { bold: true };
    //                 });
    //             }
    //             // Apply bold style to the second header row
    //             if (rowIndex === wsData.length - DatatableCounselling.filter(item => item.date === date).length - 1) {
    //                 newRow.eachCell(cell => {
    //                     cell.font = { bold: true };
    //                 });
    //             }
    //             // Apply borders to all cells
    //             newRow.eachCell(cell => {
    //                 cell.border = {
    //                     top: { style: 'thin' },
    //                     left: { style: 'thin' },
    //                     bottom: { style: 'thin' },
    //                     right: { style: 'thin' }
    //                 };
    //             });

    //             // Merge cells for specific rows
    //             // console.log(rowIndex,"rowIndex");
    //             // console.log(row,"row");
    //             // console.log(wsData.indexOf(['INDIVIDUAL STUDENT OR PARENT COUNSELING LIST']),"--");
    //             if (row[0] == 'INDIVIDUAL STUDENT OR PARENT COUNSELING LIST') {
    //                 console.log("newRow.number", newRow.number);
    //                 worksheet.mergeCells(`A${newRow.number}:F${newRow.number}`);
    //                 rowMerge = `A${newRow.number}`
    //                 // const mergedCell = newRow.getCell(1);
    //                 // mergedCell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: false };
    //             }
    //         });

    //         // });

    //         // Set column widths
    //         worksheet.columns = [
    //             { width: 10 },  // S. NO
    //             { width: 40 },  // GRADE AND DEPARTMENT
    //             { width: 30 },  // DATE OF COUNSELLING
    //             { width: 60 },  // TOPIC COVERED IN GROUP COUNSELLING
    //             { width: 80 },  // NAME OF THE MENTOR /WARDEN
    //             { width: 90 },  // REASON FOR THE COUNSELLING
    //             { width: 40 }   // WHETHER COUNSELLING CONDUCTED ALONG WITH PARENT (YES OR NO )
    //         ];

    //         // Ensure text wrapping and alignment
    //         worksheet.columns.forEach(column => {
    //             column.alignment = { wrapText: true, vertical: 'middle', horizontal: 'left' };
    //         });

    //         console.log(rowMerge, "rowMerge");
    //         // Merge cells for 'SCHOOL NAME -' row
    //         worksheet.mergeCells('A1:D1');
    //         worksheet.mergeCells('A2:D2');
    //         worksheet.mergeCells('A3:D3');
    //         // worksheet.mergeCells(rowMerge)
    //         console.log("enter 1");
    //         worksheet.getCell('A1').alignment = { horizontal: 'center' };
    //         worksheet.getCell(rowMerge).alignment = { horizontal: 'center' };

    //     });

    //     let blob;
    //     try {
    //         // Write to file and get the buffer
    //         const buffer = await workbook.xlsx.writeBuffer();
    //         blob = new Blob([buffer], { type: 'application/octet-stream' });

    //         // Save the file locally
    //         // saveAs(blob, `${schoolData.label.toUpperCase()}_${monthSheetname}.xlsx`);
    //         const formData = new FormData();
    //         formData.append('email', emailSelectedValues);
    //         formData.append('file', blob, `${schoolData.label.toUpperCase()}_${monthSheetname}.xlsx`);

    //         const submit = await CmsContent.emailexcel(formData)


    //         if (submit) {
    //             this.setState({
    //                 alertVisible: true,
    //                 emailSelectedOptions: "",
    //             })
    //             setTimeout(() => {
    //                 this.setState({ alertVisible: false })
    //             }, 3000);
    //             // this.MainFun()
    //         }
    //         //   const response = await axios.post('/api/send-email', formData, {
    //         //     headers: {
    //         //       'Content-Type': 'multipart/form-data'
    //         //     }
    //         //   });

    //         // console.log('Email sent successfully:', submit.data);
    //         // alert('Excel sheet sent to ' + emailSelectedValues);
    //         this.setState({ emailSelectedOptions: "" })
    //     } catch (error) {
    //         console.error('Error sending email:', error);
    //         alert('Failed to send Excel sheet via email. Please try again.');
    //         this.setState({ emailSelectedOptions: "" })
    //     }
    // };
    exportToExcelAndSendEmail = async () => {
        let { DatatableCounselling, DatatableTrining, DatatableTS, DatatableNTS, schoolData, emailSelectedValues } = this.state;

        try {
            let obj = {
                DatatableCounselling: DatatableCounselling,
                DatatableTrining: DatatableTrining,
                DatatableTS: DatatableTS,
                DatatableNTS: DatatableNTS,
                schoolData: schoolData
            }
           
            console.log(obj,"obj");
            const formData = new FormData();
            formData.append('email', emailSelectedValues);
            formData.append('data', JSON.stringify(obj));

            const submit = await CmsContent.emailexcel(formData)


            if (submit) {
                this.setState({
                    alertVisible: true,
                    emailSelectedOptions: "",
                })
                setTimeout(() => {
                    this.setState({ alertVisible: false })
                }, 3000);
                // this.MainFun()
            }
    
            this.setState({ emailSelectedOptions: "" })
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to send Excel sheet via email. Please try again.');
            this.setState({ emailSelectedOptions: "" })
        }
    };


    optionSelect3 = async (selectedOption) => {
        if (selectedOption) {
            let selectedValue = "";
            // selectedOption.map(values => (selectedValue += `${values.label,split("-")[0]},`));
            selectedOption.map((ival, index) => {
                let result = ival.label
                let email = result.split("-")
                selectedValue += `${email[0]},`
            })
            selectedValue = selectedValue.replace(/,\s*$/, "");
            console.log(selectedValue)
            await this.setState({
                emailSelectedOptions: selectedOption,
                emailSelectedValues: selectedValue
            });
        }
    };

    emailExcelSend = async () => {
        const { emailSelectedValues, emailSingleExcelData, emailSingleExcelvalue } = this.state;
        let sheetData;
        if (emailSingleExcelvalue == "1") {

            sheetData = [
                {
                    sheetName: '1 to 1 Counselling Form',
                    headers: [
                        {
                            label: "School Name ",
                            key: "userName",
                        },
                        {
                            label: "NAME",
                            key: "name",
                        },
                        {
                            label: "CLASS",
                            key: "className",
                        },
                        {
                            label: "DEPARTMENT",
                            key: "departmentName",
                        },
                        {
                            label: "NAME OF THE MENTOR",
                            key: "nameOfMentorName",
                        },
                        {
                            label: "REASON OF COUNSELLING",
                            key: "reason",
                        },
                        {
                            label: "DATE OF COUNSELLING",
                            key: "date",
                        },
                        {
                            label: "Hostel Name",
                            key: "HostelName",
                        },
                    ],
                    data: emailSingleExcelData || []
                },
            ];
        } else if (emailSingleExcelvalue == "2") {

            sheetData = [
                {
                    sheetName: 'Group Counseling-Training Form',
                    headers: [
                        {
                            label: "School Name ",
                            key: "userName",
                        },
                        {
                            label: "NAME",
                            key: "name",
                        },
                        {
                            label: "TOPIC",
                            key: "topic",
                        },
                        {
                            label: "DEPARTMENT",
                            key: "departmentName",
                        },
                        {
                            label: "REASON OF COUNSELLING",
                            key: "reason",
                        },
                        {
                            label: "DATE OF COUNSELLING",
                            key: "date",
                        },
                    ],
                    data: emailSingleExcelData || []
                },
            ]
        } else if (emailSingleExcelvalue == "3") {

            sheetData = [
                {
                    sheetName: 'Teaching Staff',
                    headers: [
                        {
                            label: "School Name ",
                            key: "userName",
                        },
                        {
                            label: "NAME",
                            key: "name",
                        },
                        {
                            label: "Age",
                            key: "Age",
                        },
                        {
                            label: "DEPARTMENT",
                            key: "departmentName",
                        },
                        {
                            label: "Class",
                            key: "className",
                        },
                        {
                            label: "gender",
                            key: "gender",
                        },
                        {
                            label: "Marital Status",
                            key: "MaritalStatus",
                        },
                        {
                            label: "Subject",
                            key: "Subject",
                        },
                        {
                            label: "Date",
                            key: "date",
                        },
                    ],
                    data: emailSingleExcelData || []
                },
            ]
        } else if (emailSingleExcelvalue == "4") {

            sheetData = [
                {
                    sheetName: 'Non-Teaching Staff',
                    headers: [
                        {
                            label: "School Name ",
                            key: "userName",
                        },
                        {
                            label: "NAME",
                            key: "name",
                        },
                        {
                            label: "Age",
                            key: "Age",
                        },
                        {
                            label: "Gender",
                            key: "gender",
                        },
                        {
                            label: "Marital Status",
                            key: "MaritalStatus",
                        },
                        {
                            label: "Hostel Name",
                            key: "HostelName",
                        },
                        {
                            label: "NTS",
                            key: "NTS",
                        },
                        {
                            label: "Date",
                            key: "date",
                        },
                    ],
                    data: emailSingleExcelData || []
                }
            ]
        }
        else if (emailSingleExcelvalue == "5") {
            sheetData = [
                {
                    sheetName: 'Company Counselling Data',
                    headers: [
                        {
                            label: "ENTITY NAME",
                            key: "userName",
                        },
                        {
                            label: "NAME",
                            key: "name",
                        },
                        {
                            label: "EMPLOYEE ID",
                            key: "employeeId",
                        },
                        {
                            label: "MOBILE",
                            key: "mobileNumber",
                        },
                        {
                            label: "GENDER",
                            key: "gender",
                        },
                        {
                            label: "DISGNATION",
                            key: "designation",
                        },
                        {
                            label: "DISCRIPTION",
                            key: "discription",
                        },
                        {
                            label: "REASON OF COUNSELING",
                            key: "reason",
                        },
                        {
                            label: "DATE OF COUNSELING",
                            key: "date",

                        },

                    ],
                    data: emailSingleExcelData || []
                }
            ]
        }

        const sheetsToExport = sheetData.filter(sheet => sheet.data.length > 0);
        const workbook = XLSX.utils.book_new();
        sheetsToExport.forEach(sheet => {
            const worksheetData = this.convertToSheetData(sheet.headers, sheet.data);
            const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
            XLSX.utils.book_append_sheet(workbook, worksheet, sheet.sheetName);
        });
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });


        try {
            const formData = new FormData();
            formData.append('email', emailSelectedValues);
            formData.append('file', blob, 'ExcelReport.xlsx');
            const submit = await CmsContent.emailexcel(formData)

            console.log('Email sent successfully:', submit.data);
            alert('Excel sheet sent to ' + emailSelectedValues);
            this.setState({ emailSelectedOptions: "" })
        } catch (error) {
            console.error('Error sending email:', error);
            alert('Failed to send Excel sheet via email. Please try again.');
            this.setState({ emailSelectedOptions: "" })
        }


    }
    emailSingleExcel = async (data, value) => {
        this.setState({ emailSend: true, emailSingleExcelData: data, emailSingleExcelvalue: value })
    }

    selectAllData = async () => {
        let { counsellingOptions, formTypes, checkboxChecked } = this.state

        this.setState(prevState => ({
            checkboxChecked: !prevState.checkboxChecked
        })
        )
        if (checkboxChecked == false) {
            this.setState({
                counsellingOptSelectcheckbox: counsellingOptions, formTypesSelectedcheckbox: formTypes,
                disablefromdate: false, disableenddate: false, counselingDisable: true, fromDateerror: "", toDateerror: "",
                selectSchoolError: "", formTypesSelected: "", disableselectform: true, counsellingOptSelect: "",
                formTypesSelectedValue: "", fromDate: "", toDate: "", disablebtn: false, DatatableCounselling: [],
                DatatableTrining: [], DatatableNTS: [], DatatableTS: [],
            })
        } else {
            this.setState({
                counsellingOptSelectcheckbox: "", formTypesSelectedcheckbox: "",
                disablefromdate: true, disableenddate: true, counselingDisable: false, fromDateerror: "", toDateerror: "",
                selectSchoolError: "", formTypesSelected: "", disableselectform: true, counsellingOptSelect: "",
                formTypesSelectedValue: "", fromDate: "", toDate: "", disablebtn: false, DatatableCounselling: [],
                DatatableTrining: [], DatatableNTS: [], DatatableTS: [],
            })
        }

    }

    exportToPDF = () => {
        const { DatatableCounselling, DatatableTrining, DatatableTS, DatatableNTS, schoolData } = this.state;
        console.log(DatatableTrining, "DatatableTrining");

        let counter = 16;
        const staticDepartments = [
            ['1', 'MIDDLE BOYS'],
            ['2', 'MIDDLE GIRLS'],
            ['3', 'HIGH SCHOOL BOYS'],
            ['4', 'HIGH SCHOOL GIRLS'],
            ['5', 'HIGHER SEC BOYS'],
            ['6', 'HIGHER SEC GIRLS'],
            ['7', 'HOSTEL BOYS'],
            ['8', 'HOSTEL GIRLS'],
            ['9', 'LONG TERM BOYS'],
            ['10', 'LONG TERM GIRLS'],
            ['11', 'PRIMARY BOYS & GIRLS'],
            ['12', 'GIRLS AND BOYS WARDEN'],
            ['13', '10 MIN SLOGAN (GRADE 9- GRADE 12)'],
            ['14', 'STAFF COUNSELING'],
            ['15', 'OTHERS IF ANY']
        ];
        //new
        let dynamicDate = "";
        //
        const addDescriptions = (data, nameField) => {
            data.forEach(item => {
                const departmentName = item[nameField];
                const departmentRow = staticDepartments.find(row => row[1] === departmentName);
                if (departmentRow) {
                    //new
                    dynamicDate = item.date;
                    //
                    departmentRow.push(item.date);
                    departmentRow.push(item.discription);
                } else {
                    dynamicDate = item.date;
                    if ((DatatableTrining != undefined || DatatableTrining != null) || (DatatableTS != undefined || DatatableTS != null) || (DatatableNTS != undefined || DatatableNTS != null)) {

                        staticDepartments.push([counter++, departmentName, item.date, item.discription]);
                    }

                }
            });
        };
        if (DatatableTrining != undefined || DatatableTrining != null) {
            addDescriptions(DatatableTrining, 'departmentName');
        }
        if (DatatableTS != undefined || DatatableTS != null) {
            addDescriptions(DatatableTS, 'departmentName');
        }
        if (DatatableNTS != undefined || DatatableNTS != null) {
            addDescriptions(DatatableNTS, 'NTSname');
        }
        if (DatatableCounselling != undefined || DatatableCounselling != null) {
            addDescriptions(DatatableCounselling, 'counselling');
        }

        //new
        const formattedDate = moment(dynamicDate, "DD-MM-YYYY").format("MM-DD-YYYY");
        console.log(formattedDate, "formattedDate");
        const startDate = new Date(formattedDate);
        console.log(startDate, "startDate");
        const currentMonth = startDate.getMonth() + 1;
        console.log(currentMonth, "currentMonth");


        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June', 'July',
            'August', 'September', 'October', 'November', 'December'
        ];
        const currentMonthName = monthNames[currentMonth - 1];
        console.log(currentMonthName, "currentMonthName2");



        const pdf = new jsPDF('p', 'pt', 'a4'); // A3 size and A4 size
        const pageWidth = 841.89; // A3 width in points
        const pageHeight = 1190.55; // A3 height in points

        const margins = { top: 30, left: 30, bottom: 30, right: 30 };
        let yPosition = margins.top;

        // Add a title and a page header
        pdf.setFontSize(18);
        pdf.text('VELAMMAL KNOWLEDGE PARK - CENTRAL OFFICE', margins.left, yPosition);
        yPosition += 20;

        // Add group and individual counselling schedule
        pdf.setFontSize(14);
        pdf.text(`SCHOOL NAME - ${schoolData.label.toUpperCase()}`, margins.left, yPosition);
        yPosition += 20;
        pdf.text(`COUNSELLING SCHEDULE- ${currentMonthName.toUpperCase()} (GROUP AND INDIVIDUAL)`, margins.left, yPosition);
        yPosition += 20;

        // Add table header
        pdf.autoTable({
            head: [['S. NO', 'GRADE AND DEPARTMENT', 'DATE OF COUNSELLING', 'TOPIC COVERED IN GROUP COUNSELLING']],
            body: staticDepartments,
            startY: yPosition,
            margin: margins,
            styles: { fontSize: 10 },
            theme: 'grid'
        });

        yPosition = pdf.lastAutoTable.finalY + 10; // Add some space between tables
        if (DatatableCounselling != undefined || DatatableCounselling != null) {
            // Add individual student or parent counselling list
            pdf.setFontSize(14);
            pdf.text('INDIVIDUAL STUDENT OR PARENT COUNSELLING LIST', margins.left, yPosition);
            yPosition += 20;

            // Add table header
            pdf.autoTable({
                head: [['S. NO', 'NAME OF THE STUDENT', 'CLASS', 'NAME OF THE MENTOR /WARDEN', 'REASON FOR THE COUNSELLING', 'WHETHER COUNSELLING CONDUCTED ALONG WITH PARENT (YES OR NO )']],
                body: DatatableCounselling.map((item, index) => [index + 1, item.name, item.className, item.nameOfMentorName, item.reason, item.withparent ? item.withparent : ""]),
                startY: yPosition,
                margin: margins,
                styles: { fontSize: 10 },
                theme: 'grid'
            });
        }

        // Save the PDF
        pdf.save(`${schoolData.label.toUpperCase()}.pdf`);
    };
    downloadexcel = async () => {
        let { DatatableCounselling, DatatableTrining, DatatableTS, DatatableNTS, schoolData } = this.state;
        let obj = {
            DatatableCounselling: DatatableCounselling,
            DatatableTrining: DatatableTrining,
            DatatableTS: DatatableTS,
            DatatableNTS: DatatableNTS,
            schoolData: schoolData
        }
        console.log(obj, "obj");
   
        try {
            const response = await CmsContent.downloadExcel(obj)
            console.log(response, "response.data")
            console.log("enter 1");
            // if (!response.ok) {
            //     const errorText = await response.text();
            //     throw new Error(`Network response was not ok: ${response.statusText}. ${errorText}`);
            // }
            console.log("enter 2");
            const url = window.URL.createObjectURL(new Blob([response]));
            console.log(url, "url");
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `DrLathaGirish_DailyReport.xlsx`); // Set the file name
            document.body.appendChild(link);
            link.click();
            link.remove();

        
            console.log("File downloaded successfully");

        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    }
    downloadPdf = async () => {
        let { DatatableCounselling, DatatableTrining, DatatableTS, DatatableNTS, schoolData } = this.state;
        let obj = {
            DatatableCounselling: DatatableCounselling,
            DatatableTrining: DatatableTrining,
            DatatableTS: DatatableTS,
            DatatableNTS: DatatableNTS,
            schoolDataArray: schoolData
        }
        console.log(obj, "obj");
   
        try {
            // const response = await CmsContent.downloadPdf(obj)
            // const url = window.URL.createObjectURL(new Blob([response]));
            // const link = document.createElement('a');
            // link.href = url;
            // link.setAttribute('download', `${schoolData.label.toUpperCase()}.pdf`); // Set the file name
            // document.body.appendChild(link);
            // link.click();
            // link.remove();

            const response = await CmsContent.downloadPdf(obj);
            console.log(response, "response");
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `School_Counselling_Report.pdf`); // Set the file name
            document.body.appendChild(link);
            link.click();
            link.remove();
            

        
            console.log("File downloaded successfully");

        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    }


    render() {
        const { schoolData, schoolopt, customerId, DataShow, trainingDataShow, DatatableTrining, DatatableCounselling, fromDate, toDate, formTypesSelectedValue, datashow, datashow1, emptyData, DatatableNTS, DatatableTS, companyDetails } = this.state
        console.log(this.state.companyDetails, "entitytype")
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>View Report</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="">
                                            <div className="row form-group py-2">
                                                <div className="col-sm-3" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select Entity</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <SingleSelect
                                                        // disabled={this.state.DCDisable}
                                                        options={this.state.entityOptions}
                                                        handleChange={(e) => this.optionSelectentity(e)}
                                                        selectedService={this.state.entityType}
                                                    />
                                                    <span style={{ color: 'red' }} >{this.state.selectSchoolError}</span>
                                                </div>
                                            </div>
                                            {this.state.entityType && this.state.entityType.value == 2 ? <>
                                                <div className="row form-group py-2">
                                                    <div className="col-sm-3" />
                                                    <div className="col-sm-2">
                                                        <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select Company</label>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <SingleSelect
                                                            // disabled={this.state.DCDisable}
                                                            options={this.state.companyOptions}
                                                            handleChange={(e) => this.optionSelectCompany(e)}
                                                            selectedService={this.state.companyType}
                                                        />
                                                        <span style={{ color: 'red' }} >{this.state.selectSchoolError}</span>
                                                    </div>
                                                </div></> : <></>}
                                            {this.state.entityType && this.state.entityType.value == 1 ? <><div className="row form-group py-2">
                                                <div className="col-sm-3" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select School</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    {/* <SingleSelect
                                                        options={schoolopt}
                                                        handleChange={(e) => this.optionSelect(e, "school")}
                                                        selectedService={schoolData}
                                                    /> */}
                                                    <MultiSelect
                                                            options={schoolopt}
                                                            handleChange={(e) => this.optionSelect2(e, "school")}
                                                            selectedService={schoolData}
                                                        />
                                                </div>
                                            </div>
                                                <div className="row form-group py-2">
                                                    <div className="col-sm-3" />
                                                    <div className="col-sm-2">
                                                        <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select Counseling Type</label>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <SingleSelect
                                                            disabled={this.state.counselingDisable}
                                                            options={this.state.counsellingOptions}
                                                            handleChange={(e) => this.optionSelect(e, "counsellingType")}
                                                            selectedService={this.state.counsellingOptSelect}
                                                        />
                                                    </div>
                                                    <div className="col-sm-2 check-input">
                                                        <input type="checkbox" checked={this.state.checkboxChecked} style={{ width: "20px", height: "20px", marginTop: "10px" }}
                                                            onChange={this.state.checkboxChecked ? this.selectAllData : this.selectAllData}></input>
                                                        <label>Select all</label>
                                                    </div>
                                                </div>

                                                <div className="row form-group">
                                                    <div className="col-sm-3" />
                                                    <div className="col-sm-2">

                                                        <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select Form Type</label>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <MultiSelect
                                                            // disabled={this.state.DCDisable}
                                                            options={this.state.formTypes}
                                                            handleChange={(e) => this.optionSelect2(e,"formtype")}
                                                            selectedService={this.state.formTypesSelected}
                                                            disabled={this.state.disableselectform}

                                                        />
                                                        <span style={{ color: 'red' }} >{this.state.SalesErr && this.state.SalesErr}</span>

                                                    </div>
                                                    <div className="col-sm-2" />
                                                </div></> : <></>}
                                        </div>
                                        <div className="row form-group py-2">
                                            <div className="col-sm-3" />
                                            <div className="col-sm-2 "><span style={{ color: 'red' }}>*</span>&nbsp;Select Date</div>
                                            <div className="col-sm-2 " >
                                                From Date
                                                <input
                                                    type="date"
                                                    id="myDate"
                                                    name="fromDate"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={this.state.fromDate}
                                                    disabled={this.state.disablefromdate}
                                                />
                                                <span className="error-shows">
                                                    {this.state.fromDateerror}
                                                </span>
                                            </div>
                                            <div className="col-sm-2 " >
                                                To Date
                                                <input
                                                    type="date"
                                                    name="toDate"
                                                    className="form-control "
                                                    onChange={this.handleChange}
                                                    value={this.state.toDate}
                                                    disabled={this.state.disableenddate}
                                                />
                                                <span className="error-shows">
                                                    {this.state.toDateerror}
                                                </span>
                                            </div>
                                            <div className="col-sm-2">
                                            </div>
                                            <div className="col-sm-2 error-shows"> </div>
                                        </div>
                                        <div className="row  form-group py-2">
                                            <div className="col-sm-5" />
                                            <div className="col-sm-1">
                                                <div className="row justify-content-center">
                                                    {fromDate && toDate ? <><button className="btn btn-primary" disabled={this.state.disablebtn} onClick={this.submit}>Submit</button></> : ""}
                                                </div>
                                            </div>
                                            <div className="col-sm-1">
                                                <button
                                                    type="button"
                                                    className="btn btn-warning "
                                                    onClick={() => {
                                                        this.setState({
                                                            fromDate: "",
                                                            toDate: "",
                                                            schoolData: [],
                                                            DataShow: false,
                                                            DatatableTrining: [],
                                                            DatatableCounselling: [],
                                                            formTypesSelected: [],
                                                            disablebtn: false,
                                                            disableenddate: true,
                                                            disablefromdate: true,
                                                            disableselectform: true,
                                                            counselingDisable: true,
                                                            emptyData: false,
                                                            DatatableCounselling: [], DatatableTrining: [],
                                                            fromDate: "", toDate: "", disableenddate: true,
                                                            disablebtn: false, DatatableNTS: [], DatatableTS: [],
                                                            counsellingOptSelect: "", formTypesSelected: "", schoolData: "", checkboxChecked: false
                                                        })
                                                    }}>
                                                    Reset
                                                </button>
                                            </div>
                                        </div>

                                        <br></br>
                                        {this.state.loading ?
                                            <div className="text-center">
                                                < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                            </div>
                                            : DatatableCounselling && DatatableCounselling.length == 0 && DatatableTrining && DatatableTrining.length == 0 && DatatableTS && DatatableTS.length == 0 && DatatableNTS && DatatableNTS.length == 0 || companyDetails && companyDetails.length == 0 ?

                                                <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show </div>
                                                :
                                                DatatableCounselling && DatatableCounselling.length !== 0 || DatatableTrining && DatatableTrining.length !== 0 || DatatableTS && DatatableTS.length != 0 || DatatableNTS && DatatableNTS.length != 0 || companyDetails && companyDetails.length != 0 ?
                                                    <>
                                                        <div className="row form-group">
                                                            <LoginModal
                                                                // buttonTitle="Send Mail "
                                                                // title="Send Mail"
                                                                id="sendMail"
                                                                extraClass="btn-width d-none"
                                                                onClick={this.reset}
                                                                bodyText={
                                                                    <div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-4" />
                                                                            <Alert
                                                                                className="badge-content"
                                                                                color="success"
                                                                                isOpen={this.state.alertVisible}
                                                                                toggle={this.onDismiss}
                                                                            >
                                                                                Mail Sent Successfully
                                                                            </Alert>
                                                                        </div>
                                                                        <div className="card-header">
                                                                            <h1>SEND MAIL</h1>
                                                                        </div>
                                                                        <div className="row form-group pt-5">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2">

                                                                                <label htmlFor="subcategory">Email </label>
                                                                            </div>
                                                                            <div className="col-sm-5">
                                                                                <MultiSelect
                                                                                    // disabled={this.state.DCDisable}
                                                                                    options={this.state.EmailOptions}
                                                                                    handleChange={(e) => this.optionSelect3(e)}
                                                                                    selectedService={this.state.emailSelectedOptions}
                                                                                // disabled={this.state.disableselectform}

                                                                                />
                                                                                {/* <input
                                                                                    type="text"
                                                                                    name="subcategory"
                                                                                    placeholder="Enter Email"
                                                                                    className="form-control"
                                                                                    value={this.state.modalInput}
                                                                                    onChange={(e) => this.modalhandlechange(e)}
                                                                                /> */}
                                                                                {/* <span className="modal-error-show">{error}</span> */}
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-2" />
                                                                            <div className="col-sm-5">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-primary"
                                                                                    onClick={this.state.emailSend ? this.emailExcelSend : this.exportToExcelAndSendEmail}
                                                                                >
                                                                                    submit
                                                                                </button>
                                                                            </div>
                                                                            <div className="col-sm-3" />
                                                                        </div>
                                                                        <br></br>
                                                                    </div>
                                                                }
                                                            />
                                                            <div className="col-sm-12">
                                                                {this.state.entityType.value == 1 ? <>
                                                                    <div className="row form-group">
                                                                        {/* <div className="col-sm-10" /> */}
                                                                        <div className="col-sm-2" >
                                                                            <button className="btn btn-secondary" onClick={this.downloadPdf}>Export Consolidate PDF</button>

                                                                        </div>&nbsp;&nbsp;&nbsp;
                                                                        <div className="col-sm-2" >
                                                                            <button className="btn btn-success" style={{ cursor: "pointer" }} onClick={this.downloadexcel}>Export Consolidate Report</button>

                                                                        </div>&nbsp;&nbsp;&nbsp;
                                                                        {/* <div className="col-sm-1" /> */}
                                                                        <div className="col-sm-2" >
                                                                            {/* <button className="btn btn-success" onClick={this.exportToExcelAndSendEmail}>Email Consolidate Forms</button> */}
                                                                            <button className="btn btn-info" data-toggle="modal" data-target='#sendMail'>Mail Consolidate Forms</button>

                                                                        </div>
                                                                        <div className="col-sm-6" />
                                                                    </div>
                                                                </> : <></>}
                                                                {this.state.companyDetails && this.state.companyDetails.length > 0 && this.state.entityType.value == "2"
                                                                    ?

                                                                    <>
                                                                        <div className="row form-group">
                                                                            <div className="col-sm-8"></div>
                                                                            <div className="col-sm-2">
                                                                                {this.state.companyDetails && this.state.companyDetails.length > 0 ?
                                                                                    <CSVLink
                                                                                        data={this.state.companyDetails}
                                                                                        headers={this.excelcolumn4}
                                                                                    >
                                                                                        <button
                                                                                            className="btn btn-success excelBtn"
                                                                                            style={{ marginLeft: "135px" }}
                                                                                        >
                                                                                            EXCEL
                                                                                        </button>
                                                                                    </CSVLink>
                                                                                    : <></>
                                                                                }
                                                                            </div>&nbsp;
                                                                            {/* {this.state.companyDetails && this.state.companyDetails.length > 0 && this.state.entityType.value == "2" &&
                                                                                <div className="col-sm-1 ">
                                                                                    <button className="btn btn-info" data-toggle="modal" data-target='#sendMail' onClick={() => this.emailSingleExcel(this.state.companyDetails, "5")}>Mail Excel</button>
                                                                                </div>} */}
                                                                        </div>
                                                                        <div style={{ fontWeight: "600", height: "30px", fontSize: "larger" }}>
                                                                            Company Counseling Form DataTable

                                                                        </div>
                                                                        <Datatable
                                                                            data={this.state.companyDetails}
                                                                            columnHeading={this.column4}
                                                                        />
                                                                        <br /> <br /> <br />

                                                                    </>


                                                                    : this.state.companyDetails && this.state.companyDetails.length < 1 && this.state.entityType.value == "2" ?
                                                                        <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show </div>
                                                                        : <></>
                                                                }
                                                                {/* {formTypesSelectedValue == "1,2" || formTypesSelectedValue == "2,1" || formTypesSelectedValue == "1" ? 
                                                                <> */}
                                                                {DatatableCounselling && DatatableCounselling.length > 0 && this.state.entityType.value == "1"
                                                                    ?
                                                                    (
                                                                        <>
                                                                            <div className="row form-group">
                                                                                <div className="col-sm-8"></div>
                                                                                <div className="col-sm-2">
                                                                                    {this.state.DatatableCounselling && this.state.DatatableCounselling.length > 0 && (
                                                                                        <CSVLink
                                                                                            data={this.state.DatatableCounselling}
                                                                                            headers={this.excelColumn}
                                                                                        >
                                                                                            <button
                                                                                                className="btn btn-success excelBtn"
                                                                                                style={{ marginLeft: "135px" }}
                                                                                            >
                                                                                                EXCEL
                                                                                            </button>
                                                                                        </CSVLink>
                                                                                    )}
                                                                                </div>&nbsp;
                                                                                {/* {this.state.DatatableCounselling && this.state.DatatableCounselling.length > 0 && (
                                                                                    <div className="col-sm-1 ">
                                                                                        <button className="btn btn-info" data-toggle="modal" data-target='#sendMail' onClick={() => this.emailSingleExcel(DatatableCounselling, "1")}>Mail Excel</button>
                                                                                    </div>)} */}
                                                                            </div>
                                                                            <div style={{ fontWeight: "600", height: "30px", fontSize: "larger" }}>
                                                                                Counseling Form DataTable

                                                                            </div>
                                                                            <Datatable
                                                                                data={DatatableCounselling}
                                                                                columnHeading={this.column}
                                                                            />
                                                                            <br /> <br /> <br />

                                                                        </>

                                                                    )
                                                                    // : formTypesSelectedValue == "1,2" || formTypesSelectedValue == "2,1" || formTypesSelectedValue == "1" && datashow == true && DatatableCounselling && DatatableCounselling.length == 0 ?
                                                                    //     <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show in Counselling</div>
                                                                    //     : <></>
                                                                    : DatatableCounselling && DatatableCounselling.length == 0 && this.state.entityType.value == "1" ?
                                                                        <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show in 1 to 1 Counseling</div>
                                                                        : <></>
                                                                }
                                                                {/* </>
                                                                 : <></>} */}
                                                                {/* {formTypesSelectedValue == "1,2" || formTypesSelectedValue == "2,1" || formTypesSelectedValue == "2" ?
                                                                    <> */}
                                                                {DatatableTrining && DatatableTrining.length > 0
                                                                    ?
                                                                    (
                                                                        <>
                                                                            <div className="row form-group">
                                                                                <div className="col-sm-8"></div>
                                                                                <div className="col-sm-2">
                                                                                    {this.state.DatatableTrining && this.state.DatatableTrining.length > 0 && (
                                                                                        <CSVLink
                                                                                            data={this.state.DatatableTrining}
                                                                                            headers={this.excelcolumn1}
                                                                                        >
                                                                                            <button
                                                                                                className="btn btn-success excelBtn"
                                                                                                style={{ marginLeft: "135px" }}
                                                                                            >
                                                                                                EXCEL
                                                                                            </button>
                                                                                        </CSVLink>
                                                                                    )}
                                                                                </div>&nbsp;
                                                                                {/* {this.state.DatatableTrining && this.state.DatatableTrining.length > 0 && (
                                                                                    <div className="col-sm-1 ">
                                                                                        <button className="btn btn-info" data-toggle="modal" data-target='#sendMail' onClick={() => this.emailSingleExcel(DatatableTrining, "2")}>Mail Excel</button>
                                                                                    </div>)} */}
                                                                            </div>
                                                                            <div style={{ fontWeight: "600", height: "30px", fontSize: "larger" }}>
                                                                                Group Counseling / Training Form DataTable
                                                                            </div>
                                                                            <Datatable
                                                                                data={DatatableTrining}
                                                                                columnHeading={this.column1}
                                                                            />
                                                                            <br /> <br /> <br />

                                                                        </>

                                                                    )
                                                                    // : formTypesSelectedValue == "1,2" || formTypesSelectedValue == "2,1" || datashow1 == true && DatatableTrining && DatatableTrining.length == 0
                                                                    //     ?
                                                                    //     <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show in Group Counseling / Training </div>
                                                                    //     : <></>
                                                                    : DatatableTrining && DatatableTrining.length == 0 && this.state.entityType.value == "1"
                                                                        ?
                                                                        <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show in Group Counseling / Training </div>
                                                                        : <></>
                                                                }
                                                                {/* </> : <></>} */}
                                                                {/* {formTypesSelectedValue == "1,2" || formTypesSelectedValue == "2,1" || formTypesSelectedValue == "2" ?
                                                                    <> */}
                                                                {DatatableTS && DatatableTS.length > 0
                                                                    ?
                                                                    (
                                                                        <>
                                                                            <div className="row form-group">
                                                                                <div className="col-sm-8"></div>
                                                                                <div className="col-sm-2">
                                                                                    {this.state.DatatableTS && this.state.DatatableTS.length > 0 && (
                                                                                        <CSVLink
                                                                                            data={this.state.DatatableTS}
                                                                                            headers={this.excelcolumn2}
                                                                                        >
                                                                                            <button
                                                                                                className="btn btn-success excelBtn"
                                                                                                style={{ marginLeft: "135px" }}
                                                                                            >
                                                                                                EXCEL
                                                                                            </button>
                                                                                        </CSVLink>
                                                                                    )}
                                                                                </div>
                                                                                &nbsp;
                                                                                {/* {this.state.DatatableTS && this.state.DatatableTS.length > 0 && (
                                                                                    <div className="col-sm-1 ">
                                                                                        <button className="btn btn-info" data-toggle="modal" data-target='#sendMail' onClick={() => this.emailSingleExcel(DatatableTS, "3")}>Mail Excel</button>
                                                                                    </div>)} */}
                                                                            </div>
                                                                            <div style={{ fontWeight: "600", height: "30px", fontSize: "larger" }}>
                                                                                Teaching Staff Form DataTable
                                                                            </div>
                                                                            <Datatable
                                                                                data={DatatableTS}
                                                                                columnHeading={this.column2}
                                                                            />
                                                                            <br /> <br /> <br />

                                                                        </>

                                                                    )
                                                                    // : formTypesSelectedValue == "1,2" || formTypesSelectedValue == "2,1" || datashow1 == true && DatatableTrining && DatatableTrining.length == 0
                                                                    //     ?
                                                                    //     <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show in Group Counseling / Training </div>
                                                                    //     : <></>
                                                                    : DatatableTS && DatatableTS.length == 0
                                                                        ?
                                                                        <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show in Teaching Staff </div>
                                                                        : <></>
                                                                }
                                                                {/* </> : <></>} */}
                                                                {/* {formTypesSelectedValue == "1,2" || formTypesSelectedValue == "2,1" || formTypesSelectedValue == "2" ?
                                                                    <> */}
                                                                {DatatableNTS && DatatableNTS.length > 0
                                                                    ?
                                                                    (
                                                                        <>
                                                                            <div className="row form-group">
                                                                                <div className="col-sm-8"></div>
                                                                                <div className="col-sm-2">
                                                                                    {this.state.DatatableNTS && this.state.DatatableNTS.length > 0 && (
                                                                                        <CSVLink
                                                                                            data={this.state.DatatableNTS}
                                                                                            headers={this.excelcolumn3}
                                                                                        >
                                                                                            <button
                                                                                                className="btn btn-success excelBtn"
                                                                                                style={{ marginLeft: "135px" }}
                                                                                            >
                                                                                                EXCEL
                                                                                            </button>
                                                                                        </CSVLink>
                                                                                    )}
                                                                                </div>
                                                                                &nbsp;
                                                                                {/* {this.state.DatatableNTS && this.state.DatatableNTS.length > 0 && (
                                                                                    <div className="col-sm-1 ">
                                                                                        <button className="btn btn-info" data-toggle="modal" data-target='#sendMail' onClick={() => this.emailSingleExcel(DatatableNTS, "4")}>Mail Excel</button>
                                                                                    </div>)} */}
                                                                            </div>
                                                                            <div style={{ fontWeight: "600", height: "30px", fontSize: "larger" }}>
                                                                                Non Teaching Staff Form DataTable
                                                                            </div>
                                                                            <Datatable
                                                                                data={DatatableNTS}
                                                                                columnHeading={this.column3}
                                                                            />
                                                                        </>

                                                                    )
                                                                    // : formTypesSelectedValue == "1,2" || formTypesSelectedValue == "2,1" || datashow1 == true && DatatableTrining && DatatableTrining.length == 0
                                                                    //     ?
                                                                    //     <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show in Group Counseling / Training </div>
                                                                    //     : <></>
                                                                    : DatatableNTS && DatatableNTS.length == 0
                                                                        ?
                                                                        <div style={{ marginLeft: "580px", fontWeight: "600", height: "30px", fontSize: "larger", marginTop: "50px" }}> No data to show in Non Teaching Staff </div>
                                                                        : <></>
                                                                }
                                                                {/* </> : <></>} */}
                                                            </div>
                                                        </div>
                                                    </>
                                                    :
                                                    <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment >
        );
    }
}

export default AdminReport;
