import React, { Component } from "react";
import renderHTML from "react-render-html";
import DownPanel from "./DownPanel";
import { ACCESS_POINT } from "../../config";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import LoginModal from "../../components/Modal/Modal";
import ScriptTag from "react-script-tag";

const js1 = "/js/gallery/gallery.js";
const images = [
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/Pic_1_1582718652482.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/Pic_1_1582718652482.jpg"
  },
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/Pic_2_1582718652487.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/Pic_2_1582718652487.jpg"
  },
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/pic_3_1582718676190.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/pic_3_1582718676190.jpg"
  },
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/pic_4_1582718676192.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/pic_4_1582718676192.jpg"
  }
];
const style = `<style>
table {
border-collapse: collapse;
width: 100%;
}
td, th {
border: 1px solid #dddddd;
text-align: left;
padding: 2px;
}
body {
  font-family: Verdana, sans-serif;
  margin: 0;
}
* {
  box-sizing: border-box;
}
.row > .column {
  padding: 0 8px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.column {
  float: left;
  width: 20%;
  margin: auto;
  margin-left: 5px;
}
/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
}
/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}

.mySlides {
  display: none;
}
.cursor {
  cursor: pointer;
}
/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}
/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}
.demo {
  opacity: 0.6;
}
.active,
.demo:hover {
  opacity: 1;
}
img.hover-shadow {
  transition: 0.3s;
}
.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>`;
class SheetContent4 extends Component {
  state = {
    show: false,
    title: "Heading",
    loaded: true,
    tabTitle: [
      "Master",
      "Materials",
      "Maint Service M&TR",
      "Production process TM",
      "Production process F&WP",
      "Quality",
      "HR Function",
      "Logistics"
    ],
    tabContent: [
      `
      ${style}
      <table>
      <tr>
            <td>Department</td>
            <td>Process Name</td>
            <td>Process Owner</td>
            <td>Suppoting Team</td>
            <td>Points Identified</td>
            <td>No of Actions</td>
            <td>Target</td>
          </tr>
          <tr>
            <td>Materials</td>
            <td>Material receipt &amp; storage Process</td>
            <td>Mr.Venkatesan - Material Head</td>
            <td>Ms.Sheela - Marketing<br/>Mr.Sekar - BU Head</td>
            <td>7</td>
            <td>7</td>
            <td>Jan'2020</td>
          </tr>
          <tr>
            <td rowspan="5">Quality</td>
            <td>Incoming Material Inspection</td>
            <td rowspan="5">Mr.Mathavan - Quality Head</td>
            <td rowspan="5">Mr.Sasikumar - Finance  <br/>Mr.Sreekanth - Marketing</td>
            <td rowspan="5">25</td>
            <td rowspan="5">25</td>
            <td rowspan="5">Feb'2020</td>
          </tr>
          <tr>
            <td>Process stagewise Inspection (Entire)</td>
          </tr>
          <tr>
            <td>ECT / UT testing process</td>
          </tr>
          <tr>
            <td>Final Inspection (LL &amp; VAC)</td>
          </tr>
          <tr>
            <td>Lab Testing process</td>
          </tr>
          <tr>
            <td rowspan="8">Production Process</td>
            <td>Tube mill</td>
            <td>Mr.Esakkiyappan - Production</td>
            <td>Mr.Durai babu - Finance Head</td>
            <td rowspan="8">22</td>
            <td rowspan="8">26</td>
            <td rowspan="8">Mar'2020</td>
          </tr>
          <tr class="row8">
            <td>Furnace &amp; Wet Process</td>
            <td rowspan="7">Mr.Chandrasekar - Production Head</td>
            <td rowspan="7">Mr. Balasubramanian - (Production)<br/>Mr. Elangovan - NPD ,<br/>
Mr. Mathiyarau (NPD),<br />
Mr. Rajasekaran (Plant Head)<br />
Mr. Raghupathy</td>
          </tr>
          <tr>
            <td>Ancellaries &amp; Tagging</td>
          </tr>
          <tr>
            <td>Draw bench &amp; Online</td>
          </tr>
          <tr>
            <td>Straightening &amp; Testing lines</td>
          </tr>
          <tr>
            <td>Final Cutting &amp; Frazing Lines</td>
          </tr>
          <tr>
            <td>VAC Cutting process</td>
          </tr>
          <tr>
            <td>Oiling &amp; Packing (LL &amp; VAC)</td>
          </tr>
          <tr>
            <td rowspan="2">Maintenance &amp; Services</td>
            <td>Mech &amp; Elec Maintenance</td>
            <td>Mr.Sriram - Maintenance</td>
            <td rowspan="2">Mr.Sendhilkumar - Maintenance Head</td>
            <td rowspan="2">17</td>
            <td rowspan="2">17</td>
            <td rowspan="2">Jan'2020</td>
          </tr>
          <tr>
            <td>Tooling Services</td>
            <td>Mr.Kamal - Tool room</td>
          </tr>
          <tr>
            <td>HR Function</td>
            <td>Canteen &amp; Food Handling Process</td>
            <td>Mr.Sundar - HR</td>
            <td></td>
            <td>5</td>
            <td>5</td>
            <td>Jan'2020</td>
          </tr>
          <tr>
            <td rowspan="3">Logistics</td>
            <td>Finish Goods Storage &amp; Retrivals</td>
            <td rowspan="3">Mr.Mohamed mustafa - Logistics </td>
            <td rowspan="3">Mr.Balaji - Sp.Projects&amp;vendor development</td>
            <td rowspan="3">5</td>
            <td rowspan="3">5</td>
            <td rowspan="3">Mar'2020</td>
          </tr>
          <tr>
            <td>Loading into Trucks &amp; Containers</td>
          </tr>
          <tr>
            <td>Transport &amp; Transporters Safety</td>
          </tr>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>81</td>
            <td>85</td>
            <td>&nbsp;</td>
          </tr>
          </table>`,
      `

        ${style}

        <table style="font-size:11px">
        <tr>
        <td></td>
        <td>Function: Materials</td>
        <td colspan="4">Reason(s)</td>
        <td colspan="2">Risk </td>
        <td colspan="3">SafeStart </td>
        <td>Training/ Process Change/ Policy </td>
        <td colspan="2">Action Plan</td>
        </tr>
        <tr>
        <td> S.No.</td>
        <td>Observations </td>
        <td>People</td>
        <td>Process </td>
        <td>Policy </td>
        <td>Equipment</td>
        <td>Frequency of errors</td>
        <td>Risk Involved (H/M/L) </td>
        <td>State </td>
        <td>Error </td>
        <td>CERT </td>
        <td>Actions (if change has to happen, what specific) </td>
        <td>Start When</td>
        <td>Review </td>
        </tr>
        <tr>
        <td>1</td>
        <td>EOT crane should be operated within the 10 metre from the crane otherwise it will hit coil loading person or damage the rack.</td>
        <td>Yes</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>3 out of 5</td>
        <td>M</td>
        <td>Rushing/Complacency</td>
        <td>Eyes not on task, Mind not on task</td>
        <td>Self trigger/ Work on habits</td>
        <td>Work on habits</td>
        <td>12/19</td>
        <td>01/20</td>
        </tr>
        <tr>
        <td>2</td>
        <td>Coil loading person separate the coil using a steel bar if it slip the person get hit by the coil</td>
        <td>Yes</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>3 out of 5</td>
        <td>M</td>
        <td>Fatigue Rushing/Complacency</td>
        <td>Eyes not on task,Mind not on task,Balance,tarction grip</td>
        <td>Self trigger/ Work on habits</td>
        <td>Work on habits</td>
        <td>12/19</td>
        <td>01/20</td>
        </tr>
        <tr>
        <td>3</td>
        <td>Coil should be 3 feet above from the truck body otherwise it will hit the loading person and the truck body.</td>
        <td>Yes</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>2 out of 5</td>
        <td>M</td>
        <td>Frustration/ Rushing/  Complacency</td>
        <td>Mind not on task/ Eye not on the task</td>
        <td>Self trigger/ Work on habits</td>
        <td>Work on habits</td>
        <td>12/19</td>
        <td>01/20</td>
        </tr>
        <tr>
        <td>4</td>
        <td>Coil swing while lifting it by EOT crane by using remote by the entry line operator it will hit the coil rack.</td>
        <td>Yes</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>2 out of 5</td>
        <td>M</td>
        <td>Rushing/Complacency</td>
        <td>Eyes not on task, Mind not on task</td>
        <td>Self trigger/ Work on habits</td>
        <td>Work on habits</td>
        <td>12/19</td>
        <td>01/20</td>
        </tr>
        <tr>
        <td>5</td>
        <td>While keeping the coil in the rack the coil get tilt to one side it result in operator hand stuck inbetween the coil.</td>
        <td>Yes</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>2 out of 5</td>
        <td>H</td>
        <td>Rushing/  Complacency</td>
        <td>Eyes not on task, Mind not on task and Line of fire</td>
        <td>Self trigger/ Work on habits</td>
        <td>Work on habits</td>
        <td>12/19</td>
        <td>01/20</td>
        </tr>
        <tr>
        <td>6</td>
        <td>8 ton web sling should not be in damaged condition otherwise coil will fell down from the crane result in operator get injury</td>
        <td>Yes</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>1 out of 5</td>
        <td>H</td>
        <td>Rushing/Complacency/Fatigue</td>
        <td>Eyes not on task, Mind not on task</td>
        <td>Self trigger/ Work on habits</td>
        <td>Work on habits</td>
        <td>12/19</td>
        <td>01/20</td>
        </tr>
        <tr>
        <td>7</td>
        <td>Good Co-ordination between Person on Truck &amp; Crane operator else chances of hitting of coil to Top person due to lifting in unbancend way</td>
        <td>Yes</td>
        <td>No</td>
        <td>No</td>
        <td>No</td>
        <td>0 Out of 5</td>
        <td>H</td>
        <td>Rushing/  Complacency</td>
        <td>Eyes not on task, Mind not on task</td>
        <td>Self trigger/ Work on habits</td>
        <td>Work on concentration</td>
        <td>12/19</td>
        <td>01/20</td>
        </tr>

        </table>`,
      `
      ${style}

      <table style="font-size:11px">

      <tr>
      <td></td>
      <td>Function: ERW Tube Making</td>
      <td colspan="4">Reason(s)</td>
      <td colspan="2">Risk </td>
      <td colspan="3">SafeStart </td>
      <td>Training/ Process Change/ Policy </td>
      <td colspan="2">Action Plan</td>
      </tr>
      <tr>
            <td>S.No.</td>
            <td>Observations </td>
            <td>People</td>
            <td>Process </td>
            <td>Policy </td>
            <td>Equipment</td>
            <td>Frequency of errors</td>
            <td>Risk Involved(H/M/L)</td>
            <td>State </td>
            <td>Error </td>
            <td>CERT </td>
            <td>Actions(if change has to happen, what specific)</td>
            <td>Start When</td>
            <td>Review </td>
            </tr>
            <tr>
            <td>1</td>
            <td>While roll polishing mandrel with roll released from tailstock  due to over load and fell down on operator Leg</td>
            <td>yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1</td>
            <td>M</td>
            <td>Rushing/Complacency</td>
            <td>Eyes not on task,Mind not on task and Line of fire</td>
            <td>Self trigger/ Work on habits</td>
            <td>Engineering control, Trainining Provided</td>
            <td>Completed</td>
            <td>Compleated</td>
            </tr>
            <tr>
            <td>2</td>
            <td>when doing Die and plug polishing operator didn't lock the chuck guard,it may lead injury</td>
            <td>yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>2 out of 6</td>
            <td>M</td>
            <td>Rushing/Complacency</td>
            <td>Eyes not on task, Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>&nbsp;Trainining to be Provided</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>3</td>
            <td>Removing Burr from blades operator didn't wear ppe</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out  of 5</td>
            <td>L</td>
            <td>Complacency</td>
            <td>Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>&nbsp;Trainining to be Provided</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>4</td>
            <td>While grinding wheel dressing,found machine door in open condition</td>
            <td>yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out of 10</td>
            <td>L</td>
            <td>Complacency</td>
            <td>&nbsp;Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>&nbsp;Trainining  Provided</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>5</td>
            <td>operator lift rools by hand insted of crane loading</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>2 out of 5</td>
            <td>M</td>
            <td>Complacency</td>
            <td>&nbsp;Mind not on task and Line of fire/Balance</td>
            <td>Self trigger/ Work on habits</td>
            <td>Trainining to be Provided</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>6</td>
            <td>when plug rod change,operatot climb on machine</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>Yes</td>
            <td>every time</td>
            <td>H</td>
            <td></td>
            <td>Line of fire/Balance</td>
            <td>work on Habits</td>
            <td>Engineering  method</td>
            <td>01/19</td>
            <td>02/20</td>
            </tr>
            <tr>
            <td>7</td>
            <td>when thread cutting in lathe machine operator found without safety goggless.</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out of 5</td>
            <td>L</td>
            <td>Complacency</td>
            <td>Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>Trainining to be Provided</td>
            <td>01/19</td>
            <td>02/20</td>
            </tr>
            <tr>
            <td>8</td>
            <td>while using air grinder/air gun operator found without safety goggles</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out of 5</td>
            <td>L</td>
            <td>Rushing/Complacency</td>
            <td>Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>Trainining to be Provided</td>
            <td>01/19</td>
            <td>02/20</td>
            </tr>
            <tr>
            <td>9</td>
            <td>when using kerosine doing tool cleaning operator found without Nose masc</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 of 6</td>
            <td>L</td>
            <td>Complacency</td>
            <td>Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>Trainining to be Provided</td>
            <td>01/19</td>
            <td>02/20</td>
            </tr>
            <tr>
            <td></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td></td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            </tr>
            <tr>
            <td colspan="14">TPI Safety Health Check</td>
            </tr>
            <tr>
            <td></td>
            <td>Function: Maintenance</td>
            <td colspan="4">Reason(s)</td>
            <td colspan="2">Risk </td>
            <td colspan="3">SafeStart </td>
            <td>Training/Process Change/Policy</td>
            <td colspan="2">Action Plan</td>
            </tr>
            <tr>
            <td>S.No.</td>
            <td>Observations </td>
            <td>People</td>
            <td>Process </td>
            <td>Policy </td>
            <td>Equipment</td>
            <td>Frequency of errors</td>
            <td>Risk Involved (H/M/L) </td>
            <td>State </td>
            <td>Error </td>
            <td>CERT </td>
            <td>Actions(if change has to happen, what specific) </td>
            <td>Start When</td>
            <td>Review </td>
            </tr>
            <tr>
            <td>1</td>
            <td>While climbing down from ladder not facing the ladder step leads single point contact chance of fell down</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>2 out of 5</td>
            <td>M</td>
            <td>&nbsp;&nbsp;Complacency</td>
            <td>Mind not on task/Balance,tarction grip</td>
            <td>Self trigger/Work on habits</td>
            <td>Work on habits - Use three points of contact when ascending or descending</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>2</td>
            <td>Complete isolation of potential hazard such as power, air.etc before carryout breakdown activity</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>0 out of 5</td>
            <td>H</td>
            <td>Complaceny</td>
            <td>Mind not on task, line of fire</td>
            <td>Self trigger/ Analyse Close calls and small errors</td>
            <td>Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>3</td>
            <td>While replacing motor in wet process proper usage of tools to tighten the fixing bolts</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out of 5</td>
            <td>L</td>
            <td>Rushing/Complacency</td>
            <td>Complacency</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>4</td>
            <td >Replacing of chain link without removing link, chance of finger struck between sprockets </td>
            <td >Yes</td>
            <td >No</td>
            <td >No</td>
            <td >No</td>
            <td >1 out of 5</td>
            <td >M</td>
            <td >Rushing/Complacency</td>
            <td >Eyes not on task, Mind not on task</td>
            <td >Self trigger/ Work on habits</td>
            <td >Work on habits - Look carefully at anything you are going to stick your hand into or rest your hand on</td>
            <td >12/19</td>
            <td >01/20</td>
            </tr>
            <tr>
            <td>5</td>
            <td>While replacing bearing, inadequate usage of proper tools leads to slippage</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>3 out of 5</td>
            <td>M</td>
            <td>Fatigue/ Complacency</td>
            <td>Eyes not on task, Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>6</td>
            <td>Working in oily condition workplace without cleaning it, leads to chance of slippery and fall</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>2 out of 5</td>
            <td>M</td>
            <td>Complacency</td>
            <td>line of fire</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>7</td>
            <td>Not wearing gloves while working in equipments for maintenance activity</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>2 out of 5</td>
            <td>M</td>
            <td>Complacency</td>
            <td>Eyes not on task, Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>8</td>
            <td>During loosening of fasteners chance of tools slippage leads to hand injury</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out of 5</td>
            <td>M</td>
            <td>Fatigue/Complacency</td>
            <td>&nbsp;Mind not on task, Line of fire</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>

      </table>`,
      `
    ${style}

    <table style="font-size:15px">
    <tr>
    <td colspan="14">TPI Safety Health Check</td>
    </tr>
    <tr>
            <td></td>
            <td>Function: ERW Tube Making</td>
            <td colspan="4">Reason(s)</td>
            <td colspan="2">Risk </td>
            <td colspan="3">SafeStart </td>
            <td>Training/ Process Change/ Policy </td>
            <td colspan="2">Action Plan</td>
            </tr>
            <tr>
            <td>S.No.</td>
            <td>Observations </td>
            <td>People</td>
            <td>Process </td>
            <td>Policy </td>
            <td>Equipment</td>
            <td>Frequency of errors</td>
            <td>Risk Involved (H/M/L) </td>
            <td>State </td>
            <td>Error </td>
            <td>CERT </td>
            <td>Actions (if change has to happen, what specific) </td>
            <td>Start When</td>
            <td>Review </td>
            </tr>
            <tr>
            <td>1</td>
            <td>Coil swing while lifting it by EOT crane by using remote by the entry line operator it will hit the coil rack.</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>2 out of 5</td>
            <td>M</td>
            <td>Rushing/Complacency</td>
            <td>Eyes not on task, Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr >
            <td>2</td>
            <td>&nbsp;Web sling pull out from Decoiler mandrel by the entry line operator manually which get slip from the hand and the operator get hit on the forehead.</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>3 out of 5</td>
            <td>L</td>
            <td>Rushing/  Fatigue</td>
            <td>Mind not on task/ Balance, tarction grip</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>3</td>
            <td>Throwing the end cut bits of the coil in the pallet by the entry line operator manually which hit the pallet and lie on the shop floor.</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out of 5</td>
            <td>L</td>
            <td>Rushing/Complacency</td>
            <td>Eyes not on task, Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td >4</td>
            <td>Shear cutter kept on the welding unit after cutting the OD deburr by the mill operator which fell on the operator leg. </td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>3 out of 5</td>
            <td>L</td>
            <td>Rushing/Complacency</td>
            <td>Eyes not on task, Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>5</td>
            <td>While change over roll get stuck in the stand the operator hit the roll with a bar which make roll to fell on the operator leg.</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>3 out of 5</td>
            <td>M</td>
            <td>Fatigue/ Complacency</td>
            <td>Eyes not on task, Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>6</td>
            <td>Quality operator has to unload the 12m tube in initial set up tube on the shop floor which hit the next operator present on the opposite side. </td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>2 out of 5</td>
            <td>M</td>
            <td>Fatigue/ Rushing/ Complacency</td>
            <td>Eyes not on task, Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>7</td>
            <td>CL remove the ID finburr from inside the tube and keep in the pallet provided sometime burr spill on the shop floor which lead to cut injury.</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>3 out of 5</td>
            <td>M</td
            <td>Fatigue/ Rushing/ Complacency</td>
            <td>Eyes not on task, Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>8</td>
            <td>Coil get swing when it is hit the decoiler drum instead of entering into the coil box in decoiler .</td>
            <td>Yes</td>
            <td >No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out of 5</td>
            <td>H</td>
            <td>Rushing/Complacency</td>
            <td>Eyes not on task, Mind not on task, Line of fire</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>9</td>
            <td>If the entry line operator not off the auto button when last lap of the coil is running it will pull out from the equipment and hit the nearby guard.</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out of 5</td>
            <td>M</td>
            <td>Rushing/Complacency</td>
            <td>Eyes not on task, Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>10</td>
            <td>Operator actuate the OD deburr winder to pull off the winded OD burr while fell from 1.5 m and hit near by bailing press.</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out of 5</td>
            <td>M</td>
            <td>Rushing/  Complacency</td>
            <td>Eyes not on task, Mind not on task and Line of fire</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>11</td>
            <td>Quality operator check the sampling test tube in the  run out conveyor same time the next tube went too close to hit the  operator finger.</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>4 out of 5</td>
            <td>M</td>
            <td>Fatigue/ Rushing/ Complacency</td>
            <td>Eyes not on task, Mind not on task and Line of fire</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>



    </table>`,
      `
  ${style}

       <table>
       <tr>
       <td></td>
       <td>Function: Furnace </td>
       <td colspan="4">Reason(s)</td>
       <td colspan="2">Risk </td>
       <td colspan="3">SafeStart </td>
       <td>Training/ Process Change/ Policy </td>
       <td colspan="2">Action Plan</td>
       </tr>
       <tr class="row2">
       <td>S.No.</td>
       <td>Observations </td>
       <td>People</td>
       <td>Process </td>
       <td>Policy </td>
       <td>Equipment</td>
       <td>Frequency of errors</td>
       <td>Risk Involved (H/M/L) </td>
       <td>State </td>
       <td>Error </td>
       <td>CERT </td>
       <td>Actions (if change has to happen, what specific) </td>
       <td>Start When</td>
       <td>Review </td>
       </tr>
       <tr>
            <td>1</td>
            <td>Operator moving of 2 bundles at a time in EOT crane where bundle may swing &amp; hit against near the portion</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out of 20</td>
            <td>L</td>
            <td>Rushing /  Complacency</td>
            <td>Eyes not on task, <br />
Mind not on task,<br />
Line of Fire</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>Jan'2020</td>
            <td>Feb'2020</td>
            </tr>
            <tr>
            <td>2</td>
            <td>Tube bundles are stored over the safety limit in storage racks</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>2 out of 15</td>
            <td>L</td>
            <td>Rushing / Complacency</td>
            <td>Eyes not on task, <br />
Mind not on task,<br />
Line of Fire</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>Jan'2020</td>
            <td>Feb'2020</td>
            </tr>
            <tr>
            <td>3</td>
            <td>Operator adjusting the tubes in loading stand by hand for evenness which may possible for cut injury (No leather glooves used)</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>3 out of 5</td>
            <td>L</td>
            <td>Fatigue / Rushing /Complacency</td>
            <td>Eyes not on task, <br />
Mind not on task,<br />
Line of Fire</td>
            <td >Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>Jan'2020</td>
            <td>Feb'2020</td>
            </tr>
            <tr >
            <td>4</td>
            <td>Bundle lifting with unbalanced condition (Slings putting in uneven distance) may lead to hit hazard</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out of 5</td>
            <td>L</td>
            <td>Rushing /  Complacency</td>
            <td>Eyes not on task, <br />
Mind not on task,<br />
Line of Fire &amp; <br />
Balance/ Traction / Grip</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>Jan'2020</td>
            <td>Feb'2020</td>
            </tr>
            <tr>
            <td></td>
            <td>Function: Wet Process</td>
            <td></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td ></td>
            </tr>
            <tr>
            <td>5</td>
            <td>Wet process loading / Unloading operator stands beneath the transporter for sling / unsling the bundle (No any hand signal practice with the main operator)</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>3 out of 5</td>
            <td>M</td>
            <td>Fatigue / Rushing / Complacency</td>
            <td>Eyes not on task, <br />
Mind not on task,<br />
Line of Fire</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>Jan'2020</td>
            <td>Feb'2020</td>
            </tr>
            <tr>
            <td  rowspan="2">6</td>
            <td  rowspan="2">Wet process chemical top up by handling bags / cans. While nearing to the process tanks handling these bags &amp; cans showing high hazard</td>
            <td rowspan="2">Yes</td>
            <td rowspan="2">Yes</td>
            <td rowspan="2">No</td>
            <td rowspan="2">No</td>
            <td rowspan="2">Everytime</td>
            <td rowspan="2">M</td>
            <td rowspan="2">Fatigue / Rushing / Complacency</td>
            <td rowspan="2">Eyes not on task, <br />
Mind not on task,<br />
Line of Fire &amp; <br />
Balance/ Traction / Grip</td>
            <td rowspan="2">Self trigger/ Work on habits / Bag or Container size to be changed</td>
            <td >Work on habits</td>
            <td>Jan'2020</td>
            <td>Feb'2020</td>
            </tr>
            <tr>
            <td>Explore dosing system or pumping system</td>
            <td>Apr'2020</td>
            <td>May'2020</td>
            </tr>
            <tr>
            <td rowspan="2">7</td>
            <td rowspan="2">Wet process - Acid sample collection for analysis is not in accessable. The chemist climbing over the duct in unsafe condition</td>
            <td rowspan="2">Yes</td>
            <td rowspan="2">Yes</td>
            <td rowspan="2">No</td>
            <td rowspan="2">No</td>
            <td rowspan="2">Everytime</td>
            <td rowspan="2">H</td>
            <td rowspan="2">Fatigue / Rushing /  Complacency</td>
            <td rowspan="2">Eyes not on task, <br />
Mind not on task,<br />
Line of Fire &amp; <br />
Balance/ Traction / Grip</td>
            <td rowspan="2">Self trigger/ Work on habits/ Walking steps to be arranged</td>
            <td >Work on habits</td>
            <td >Jan'2020</td>
            <td >Feb'2020</td>
            </tr>
            <tr>
            <td>Creating steps for easy access</td>
            <td>Mar'2020</td>
            <td>Apr'2020</td>
            </tr>
            <tr>
            <td>8</td>
            <td>Eye shower in wet process #01 &amp; Oil area is not in accessable (Covered by oil barrel storage) </td>
            <td>Yes</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>Everytime</td>
            <td>M</td>
            <td >Frastration / Fatigue/ Rushing</td>
            <td >Eyes not on task, <br />
Mind not on task,<br />
Line of Fire</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>Jan'2020</td>
            <td>Feb'2020</td>
            </tr>
            <tr>
            <td>9</td>
            <td>While chemical addition in wet process area, operator has not using adequate (suitable) Eye protection glass. Using ordinary white goggles</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>Everytime</td>
            <td>H</td>
            <td>Rushing /  Complacency</td>
            <td>Eyes not on task, <br />
Mind not on task,<br />
Line of Fire</td>
            <td>Self trigger/ Work on habits</td>
            <td>Work on habits</td>
            <td>Jan'2020</td>
            <td>Feb'2020</td>
            </tr>
            <tr>
            <td rowspan="2">10</td>
            <td rowspan="2">Wet process#02 Eye shower, water not flow with required force</td>
            <td rowspan="2">Yes</td>
            <td rowspan="2">Yes</td>
            <td rowspan="2">No</td>
            <td rowspan="2">No</td>
            <td rowspan="2">Everytime</td>
            <td rowspan="2">M</td>
            <td rowspan="2">Fatigue / Rushing / Complacency</td>
            <td rowspan="2">Eyes not on task, <br />
Mind not on task,<br />
Line of Fire</td>
            <td rowspan="2">Self trigger/ Work on habits/ Separate water line can be provided for adequate pressure</td>
            <td>Work on habits</td>
            <td>Jan'2020</td>
            <td>Feb'2020</td>
            </tr>
            <tr>
            <td>Separate water line can be provided for adequate pressure</td>
            <td>Feb'2020</td>
            <td>Mar'2020</td>
            </tr>
            <tr>
            <td rowspan="2">11</td>
            <td rowspan="2">No steps or proper approach to phasphate tank for chemical addition. Operator climping over hot water  pipe line (Insulated)</td>
            <td rowspan="2">Yes</td>
            <td rowspan="2">Yes</td>
            <td rowspan="2">No</td>
            <td rowspan="2">No</td>
            <td rowspan="2">Everytime</td>
            <td rowspan="2">H</td>
            <td rowspan="2">Fatigue / Rushing / Complacency</td>
            <td rowspan="2">Eyes not on task, <br />
Mind not on task,<br />
Line of Fire &amp; <br />
Balance/ Traction / Grip</td>
            <td rowspan="2">Self trigger/ Work on habits/ Walking steps to be arranged</td>
            <td >Work on habits</td>
            <td >Jan'2020</td>
            <td>Feb'2020</td>
            </tr>
            <tr>
            <td >Creating steps for easy access</td>
            <td >Mar'2020</td>
            <td>Apr'2020</td>
            </tr>


       </table>`,
      `
${style}

<table style="font-size:14px">
<tr>
<td></td>
<td>Function: Quality</td>
<td colspan="4">Reason(s)</td>
<td colspan="2">Risk </td>
<td colspan="3">SafeStart </td>
<td>Training/ Process Change/ Policy </td>
<td colspan="2">Action Plan</td>
</tr>
<tr>
            <td>SL NO</td>
            <td>OBSERVATION</td>
            <td>PEOPLE</td>
            <td>PROCESS</td>
            <td>POLICY</td>
            <td>EQIUPMENT</td>
            <td>FREQUENCY OF ERROR</td>
            <td>RISK INVOLVED (H/M/L)</td>
            <td>STATE</td>
            <td>ERROR</td>
            <td>CERT</td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
            <tr>
            <td></td>
            <td>Final inspection</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
            <tr>
            <td>1</td>
            <td>During cutting, burr removed by air blowing other side Inspector is available burr spreads to entire shop floor and to the inspector side it may cause eye damage also burr strucks in the skin due to air applied forcely.</td>
            <td>ü</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>2/5</td>
            <td>M</td>
            <td>COMPLESANCY</td>
            <td>LINE OF FIRE</td>
            <td></td>
            <td>Training to be provided to wear goggles always. Check the possibility of providing safe guard in between cutting machine and inspection table.</td>
            <td>01/20</td>
            <td>02/20</td>
            </tr>
            <tr>
            <td>2</td>
            <td>Tubes are unloaded with hand. Sharp edge in the tube having a chance of leads to cut injury.</td>
            <td>ü</td>
            <td>No</td>
            <td >No</td>
            <td >No</td>
            <td>5/5</td>
            <td>M</td>
            <td>COMPLESANCY</td>
            <td>LINE OF FIRE</td>
            <td></td>
            <td>Look for different gloves to protect from sharp edge. Training nees to be given.</td>
            <td>01/20</td>
            <td>02/20</td>
            </tr>
            <tr>
            <td>3</td>
            <td >Inspector after qualifying the tubes scroll to the unloading bucket. Bucket overflowed and tubes fall from the bucket which leads to injure a inspector.</td>
            <td >ü</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1/5</td>
            <td>M</td>
            <td>Rushing</td>
            <td>Eye not on Task</td>
            <td></td>
            <td>Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
            <td>01/20</td>
            <td>02/20</td>
            </tr>
            <tr>
            <td>4</td>
            <td>Bundles are loading into the final inspection table and sometimes coming close to the inspector arm.</td>
            <td>ü</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td >1/5</td>
            <td>M</td>
            <td>Complecancy/Rushing</td>
            <td>Balancing</td>
            <td ></td>
            <td>Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
            <td>01/20</td>
            <td>02/20</td>
            </tr>
            <tr>
            <td>5</td>
            <td>Unloading bucket for Rejected tubes is away from the inspector. Currently handling by manual lifting the rejected Tubes</td>
            <td>ü</td>
            <td>No</td>
            <td>No</td>
            <td>YES</td>
            <td>5/5</td>
            <td>M</td>
            <td>FATIGUE</td>
            <td>Balancing</td>
            <td></td>
            <td>Needs to provide facility to move the tube to rejection bucket.<br />
Training also needs to be provided to handle the tubes.</td>
            <td>01/20</td>
            <td>02/20</td>
            </tr>
            <tr>
            <td>6</td>
            <td >Online UT short length tubes running which the tube not fallen into unloading bucket which results tubes fallen in Zigzag manner which may cause injury to Cap Removal person</td>
            <td >ü</td>
            <td>No</td>
            <td>No</td>
            <td >YES</td>
            <td>1/5</td>
            <td>M</td>
            <td >Complacency</td>
            <td >LINE OF FIRE</td>
            <td ></td>
            <td >To study the auto loading time gap and re visit to avoid struck of tubes</td>
            <td >02/20</td>
            <td >03/20</td>
            </tr>
            <tr >
            <td></td>
            <td >ECT &amp; UT</td>
            <td ></td>
            <td ></td>
            <td></td>
            <td ></td>
            <td ></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
            <tr>
            <td>7</td>
            <td>While loading into machines ropes are tied.while running in auto mode CL enters into the loading bucket &amp; removes a rope it may cause damage to person.</td>
            <td>ü</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>3/5</td>
            <td>M</td>
            <td>COMPLESANCY</td>
            <td>LINE OF FIRE</td>
            <td></td>
            <td><br />
Mechanism to provide remove the rope without enters into the loading table.<br />
Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided.</td>
            <td>02/20</td>
            <td>03/20</td>
            </tr>
            <tr >
            <td>8</td>
            <td>While inserting end cap for UT testing tubes, tubes are unevenly arranged, it may leads to injure CL finger aslo space constrain for inserting cup on both sides. </td>
            <td>ü</td>
            <td>No</td>
            <td>No</td>
            <td>YES</td>
            <td>3/5</td>
            <td>M</td>
            <td>Complacency/Rushing</td>
            <td>Line of Fire/Balancing</td>
            <td></td>
            <td>To study the auto loading time gap and re visit to avoid zig zag</td>
            <td>02/20</td>
            <td>03/20</td>
            </tr>
            <tr>
            <td>9</td>
            <td>For retesting a qualified tubes manually has to be handle the tubes while handling both sides error occurs &amp; injures to a person improperly.</td>
            <td>ü</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>2/5</td>
            <td>M</td>
            <td>Rushing</td>
            <td>Balancing</td>
            <td></td>
            <td>On the job training</td>
            <td >01/20</td>
            <td >02/20</td>
            </tr>
            <tr>
            <td >10</td>
            <td >Sometimes operating a crane zigzagly and hitting to UT low roof if it hits to human risk is high.</td>
            <td >ü</td>
            <td >No</td>
            <td >No</td>
            <td >No</td>
            <td>1/5</td>
            <td >M</td>
            <td >Rushing</td>
            <td >Eye not on Task</td>
            <td ></td>
            <td >On the job training</td>
            <td >01/20</td>
            <td >02/20</td>
            </tr>
            <tr >
            <td>11</td>
            <td >Online UT machine rejected tubes can't tie a rope due to space constraint person has tie a rope walking on above of the tubes it will leads to slip and injure.</td>
            <td>ü</td>
            <td >No</td>
            <td >No</td>
            <td >No</td>
            <td >2/5</td>
            <td >M</td>
            <td >COMPLACENCY</td>
            <td >Balancing</td>
            <td></td>
            <td>Facility to be provided to tie a rope instead of walking on the bundle.</td>
            <td>02/20</td>
            <td >03/20</td>
            </tr>
            <tr>
            <td>12</td>
            <td>Some chain guard's are not available. Persons  moving nearer to that without tuckin the shirt it may leads to injure.</td>
            <td>ü</td>
            <td>No</td>
            <td >No</td>
            <td >No</td>
            <td >1/5</td>
            <td >M</td>
            <td >COMPLACENCY</td>
            <td >Line of Fire</td>
            <td ></td>
            <td >Guard to be provided.<br />
Training to be given for insert the shirt.</td>
            <td >01/20</td>
            <td >02/20</td>
            </tr>
            <tr>
            <td></td>
            <td>STRAIGHTENING</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td ></td>
            <td ></td>
            </tr>
            <tr>
            <td >13</td>
            <td >During sample cutting for inspection, spatters are hitting fore arm and eyes.</td>
            <td>ü</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>3/5</td>
            <td >M</td>
            <td>COMPLACENCY</td>
            <td >LINE OF FIRE</td>
            <td ></td>
            <td >Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
            <td >01/20</td>
            <td >02/20</td>
            </tr>
            <tr >
            <td >14</td>
            <td>Tubes are loaded in to the surface table manually by two persons which may fall down on th leg.</td>
            <td >ü</td>
            <td >No</td>
            <td >No</td>
            <td >No</td>
            <td >3/5</td>
            <td >L</td>
            <td>FATIGUE</td>
            <td >Balancing</td>
            <td ></td>
            <td >Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
            <td >01/20</td>
            <td >02/20</td>
            </tr>
            <tr >
            <td >15</td>
            <td >Tube are cutting wihout locking</td>
            <td >ü</td>
            <td >No</td>
            <td >No</td>
            <td >No</td>
            <td >2/5</td>
            <td >M</td>
            <td >COMPLACENCY</td>
            <td >Mind not on Task/Line of Fire</td>
            <td ></td>
            <td >Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
            <td >01/20</td>
            <td >02/20</td>
            </tr>
            <tr class="row21">
            <td ></td>
            <td ></td>
            <td >ü</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
            <tr>
            <td></td>
            <td>Drawing</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
            <tr>
            <td>16</td>
            <td>Goggles are not weared during cutting.</td>
            <td>ü</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1/5</td>
            <td>M</td>
            <td>COMPLACENCY</td>
            <td>Line of Fire</td>
            <td ></td>
            <td >Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
            <td>01/20</td>
            <td>02/20</td>
            </tr>
            <tr>
            <td >17</td>
            <td >Tubes are cutting without clamping.</td>
            <td>ü</td>
            <td >No</td>
            <td >No</td>
            <td >No</td>
            <td >1/5</td>
            <td >M</td>
            <td>COMPLACENCY</td>
            <td>Mind not on Task/Line of Fire</td>
            <td></td>
            <td>Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
            <td>01/20</td>
            <td>02/20</td>
            </tr>
            <tr>
            <td>18</td>
            <td>Hot tubes are inspected after drawing. Chnance of touching hand and leads to burn injury.</td>
            <td>ü</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>3/5</td>
            <td>M</td>
            <td>COMPLACENCY</td>
            <td>Mind not on Task/Line of Fire</td>
            <td></td>
            <td>Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
            <td>01/20</td>
            <td >02/20</td>
            </tr>
            <tr>
            <td>19</td>
            <td>During sample cutting for inspection, spatters are splash on fore arm and eyes.</td>
            <td>ü</td>
            <td>No</td>
            <td >No</td>
            <td >No</td>
            <td >3/5</td>
            <td >M</td>
            <td >COMPLACENCY</td>
            <td >LINE OF FIRE</td>
            <td ></td>
            <td >Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
            <td >01/20</td>
            <td >02/20</td>
            </tr>
            <tr>
            <td ></td>
            <td >UTM</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            </tr>
            <tr>
            <td>20</td>
            <td>After testing tube plug removal(hitting to remove) may lead to fingure injury</td>
            <td>ü</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>5/5</td>
            <td>M</td>
            <td>COMPLACENCY</td>
            <td>Mind not on Task/Line of Fire</td>
            <td></td>
            <td>Safety PPE to be provided</td>
            <td>01/20</td>
            <td>02/20</td>
            </tr>
            <tr>
            <td></td>
            <td >VAC</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td></td>
            <td ></td>
            </tr>
            <tr>
            <td>21</td>
            <td>In all Oiling tank Extra air hose projecting due to this inspector was falling down on foot.</td>
            <td>ü</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1/5</td>
            <td>L</td>
            <td>Rushing</td>
            <td>Eye not on Task</td>
            <td></td>
            <td rowspan="4">Remove unwanted hose,bolt and pipelines. Training also to be provided</td>
            <td rowspan="4">02/20</td>
            <td rowspan="4">Mar'20</td>
            </tr>
            <tr>
            <td>22</td>
            <td >Air flow pipe projection on floor, it will leads to injure a inspector or operaor</td>
            <td >ü</td>
            <td >No</td>
            <td >No</td>
            <td >No</td>
            <td >1/5</td>
            <td>L</td>
            <td>Rushing</td>
            <td >Eye not on Task</td>
            <td></td>
            </tr>
            <tr>
            <td>23</td>
            <td >Hydraulic pipe lines are above the shop floor  while inspecting or testing a tubes a inspector foot was hitted and fallen down.</td>
            <td >ü</td>
            <td> No</td>
            <td>No</td>
            <td >No</td>
            <td >1/5</td>
            <td >L</td>
            <td >Rushing</td>
            <td >Eye not on Task</td>
            <td ></td>
            </tr>
            <tr >
            <td>24</td>
            <td>Bolt projection on shop floor near machine it will leads to falling down on foot.</td>
            <td>ü</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1/5</td>
            <td>L</td>
            <td >Rushing</td>
            <td>Eye not on Task</td>
            <td></td>
            </tr>
            <tr >
            <td>25</td>
            <td >While chamfering a tube burr spreads on the shop floor it injures a inspector &amp; operator near online inspection table.</td>
            <td >ü</td>
            <td >No</td>
            <td >No</td>
            <td >No</td>
            <td >1/5</td>
            <td >M</td>
            <td >Complacency</td>
            <td >Line of Fire</td>
            <td ></td>
            <td >Saftey guard to be provided. Training to give to wear goggles always</td>
            <td >02/20</td>
            <td >Mar'20</td>
            </tr>



</table>`,
      `
${style}

<table style="font-size:14px">
<tr>
<td></td>
<td>Function: HR</td>
<td colspan="4">Reason(s)</td>
<td colspan="2">Risk </td>
<td colspan="3">SafeStart </td>
<td>Training/Process Change/Policy</td>
<td colspan="2">Action Plan</td>
</tr>
<tr>
<td>S.No.</td>
<td>Observations </td>
<td>People</td>
<td>Process </td>
<td>Policy </td>
<td>Equipment</td>
<td>Frequency of errors</td>
<td>Risk Involved (H/M/L) </td>
<td>State </td>
<td>Error </td>
<td>CERT </td>
<td>Actions (if change has to happen, what specific) </td>
<td>Start When</td>
<td>Review </td>
</tr>
<tr>
            <td>1</td>
            <td>While Moving the food from Main Plant, the vehicle has crossing the internal shop floor</td>
            <td>yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1</td>
            <td>M</td>
            <td>Rushing/Complacency</td>
            <td>Eyes not on task, Mind not on task and Line of fire</td>
            <td>Self trigger/ Work on habits</td>
            <td>Engineering control, Trainining Provided</td>
            <td>Completed</td>
            <td>Compleated</td>
            </tr>
            <tr>
            <td>2</td>
            <td>While Carrying / lifting the food from ground floor to 1st Floor the person tend to slip in steps</td>
            <td>yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>2 out of 6</td>
            <td>M</td>
            <td>Rushing/  Complacency</td>
            <td>Eyes not on task, Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>&nbsp;Trainining to be Provided</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>3</td>
            <td >Hand wash area observed in wet condition-  person may get slip</td>
            <td >Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out  of 5</td>
            <td>M</td>
            <td>Complacency</td>
            <td>Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>&nbsp;Trainining to be Provided</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>4</td>
            <td>The Tea service person has not used proper PPE while serving the tea in shop floor - The person may face and injury</td>
            <td>yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out of 10</td>
            <td>M</td>
            <td>Rushing / Complacency</td>
            <td>&nbsp;Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>&nbsp;Trainining  Provided</td>
            <td>12/19</td>
            <td>01/20</td>
            </tr>
            <tr>
            <td>5</td>
            <td>The food service person has not used head cap for cover the hair fall</td>
            <td>yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out of 10</td>
            <td>M</td>
            <td>Complacency</td>
            <td>&nbsp;Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>&nbsp;Trainining  Provided</td>
            <td >12/19</td>
            <td>01/20</td>
            </tr>




</table>`,
      `
${style}

<table style="font-size:15px">

<tr>
<td></td>
<td>Function: Logistics</td>
<td colspan="4">Reason(s)</td>
<td colspan="2">Risk </td>
<td colspan="3">SafeStart </td>
<td>Training/ Process Change/ Policy </td>
<td colspan="2">Action Plan</td>
</tr>
<tr>
<td>S.No.</td>
<td>Observations </td>
<td>People</td>
<td>Process </td>
<td>Policy </td>
<td>Equipment</td>
<td>Frequency of errors</td>
<td>Risk Involved (H/M/L) </td>
<td>State </td>
<td>Error </td>
<td>CERT </td>
<td>Actions (if change has to happen, what specific) </td>
<td>Start When</td>
<td>Review </td>
</tr>
<tr>
            <td>1</td>
            <td>While Loading Long Length 9 Wooden Crates in a Container, a person require to go Inside the contianer for aligning the crates - may be possible of Hit Injuiry</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>5 out of 5</td>
            <td>H</td>
            <td>Rushing/ Frustration /  Fatigue</td>
            <td>Eyes not on task, Mind not on task/ Balance, Tarction grip</td>
            <td>Self trigger/ Analyse Close Call / Work on habits</td>
            <td>Explore Alternative method to stuff the  wooden Crates without entering the person inside the container</td>
            <td>01/20</td>
            <td>03/20</td>
            </tr>
            <tr>
            <td>2</td>
            <td>To Unsling the Ropes from Crane Hook the person required to go top of Vehicle-  - may be possible of Hit Injuiry / Fall from Height.</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>5 out of 5</td>
            <td>H</td>
            <td>Rushing/ Frustration /  Fatigue</td>
            <td>Eyes not on task, Mind not on task/ Balance, Tarction grip</td>
            <td>Self trigger/ Analyse Close Call /Work on habits</td>
            <td>Explore Alternative method to stuff the  wooden Crates without entering the person inside the container</td>
            <td>01/20</td>
            <td>03/20</td>
            </tr>
            <tr>
            <td>3</td>
            <td>Bundles get Rotating Sometimes to ensure Lables/ TAG Display in fornt Side - - may be possible of Hit Injuiry</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>3 out of 5</td>
            <td>M</td>
            <td>Rushing/Complacency</td>
            <td>Eyes not on task, Mind not on task/ Balance, Tarction grip</td>
            <td>Self trigger/Analyse Close Call/Work on habits</td>
            <td>Explore Alternative method to stuff the  wooden Crates without entering the person inside the container</td>
            <td>01/20</td>
            <td>03/20</td>
            </tr>
            <tr>
            <td>4</td>
            <td>No Side Barry Guard in Scissor Lift in VAC Loading Point - may be possible of Hit Injuiry / Fall from Height.</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>5 out of 5</td>
            <td>H</td>
            <td>Rushing/   Fatigue / Complacency</td>
            <td>Eyes not on task, Mind not on task/ Balance, Tarction grip / Line of Fire</td>
            <td>Self trigger/ Analyse Close Call / Work on habits</td>
            <td>Explore Alternative method to stuff the  wooden Crates without entering the person inside the container</td>
            <td>01/20</td>
            <td>03/20</td>
            </tr>
            <tr>
            <td>5</td>
            <td>Road is uneven in VAC Loading area - While move containers from loading point after Completing Loading - may be possible of Hit Injuiry / Fall of Stuffed Material.</td>
            <td>Yes</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>2 out of 5</td>
            <td>H</td>
            <td>Rushing/  Frustration /  Complacency</td>
            <td>Eyes not on task, Mind not on task/ Balance, Tarction grip / Line of Fire</td>
            <td>Self trigger/ Analyse Close Call / Work on habits</td>
            <td>Explore Alternative method to stuff the  wooden Crates without entering the person inside the container</td>
            <td>01/20</td>
            <td>03/20</td>
            </tr>
    </table>`
    ]
  };
  openModal = n => {
    document.getElementById("myModal").style.display = "block";
  };
  closeModal = () => {
    document.getElementById("myModal").style.display = "none";
  };
  view = () => {
    this.setState({ show: true });
  };
  hideView = () => {
    this.setState({ show: false });
  };
  loadScript = src => {
    this.setState({ loaded: false });
    return <ScriptTag type="text/javascript" src={src} />;
  };

  render() {
    let state = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-margin-top">
          <div className="col-sm-12">
            <div className="row form-group">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-12" style={{ marginBottom: '2%' }}>
                        <div className="row ">
                          <div className="col-sm-1">
                            <img
                              style={{ marginTop: 2 }}
                              src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/logo_ticycles_1582718652479.png`}
                            />
                          </div>
                          <div className="col-sm-10">
                            <h2
                              className="theme-tiile text-capitalize"
                              style={{
                                marginLeft: "0px",
                                float: "left",
                                marginTop: "35px"
                              }}
                            >
                              TPI, Avadi
                            </h2>
                          </div>
                          <div className="col-sm-2"></div>
                        </div>
                      </div>

                      <div className="col-sm-12" >
                        <div style={{ marginBottom: '2%' }}>
                          <LoginModal
                            buttonTitle="Gallery"
                            title="TPI, Avadi"
                            id="we"
                            indexStyle={{ zIndex: 99999 }}
                            maxwidth="modal-lg"
                            bodyText={
                              <div>
                                <section className="carousel-container">
                                  <img
                                    src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Pic_1_1582718652482.jpg`}
                                    alt=""
                                    className="current-image"
                                  />
                                  <span className="arrow arrow-left"> Prev </span>
                                  <ul className="next-list">
                                    <li>
                                      <img
                                        src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Pic_1_1582718652482.jpg`}
                                        alt=""
                                        className="image-of-list current-image-list"
                                      />
                                    </li>
                                    <li>
                                      <img
                                        src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Pic_2_1582718652487.jpg`}
                                        alt=""
                                        className="image-of-list"
                                      />
                                    </li>
                                    <li>
                                      <img
                                        src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/pic_3_1582718676190.jpg`}
                                        className="image-of-list"
                                      />
                                    </li>
                                    <li>
                                      <img
                                        src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/pic_4_1582718676192.jpg`}
                                        alt=""
                                        className="image-of-list"
                                      />
                                    </li>
                                  </ul>
                                  <span className="arrow arrow-right">
                                    {" "}
                                  Next{" "}
                                  </span>
                                </section>
                                <ScriptTag type="text/javascript" src={js1} />
                              </div>
                            }
                          />
                        </div>
                        <div>
                          <h3>TPI, Avadi</h3>
                          <br />
                          <p>
                            We facilitated the 5 SafeStart core units for 20
                            Senior executives of TPI Avadi’s Management Team on
                            following dates.
                          </p>
                          <br />
                          <table >
                            <tr>
                              <th colspan="2">Units</th>
                              <th>Dates</th>
                            </tr>
                            <tr>
                              <td rowSpan="6">
                                5 SafeStart
                                <br /> Core Units for
                                <br /> MG Staff
                              </td>
                              <td>Unit 1</td>
                              <td>14-Nov 2019</td>
                            </tr>
                            <tr>
                              <td>Unit 2</td>
                              <td>22-Nov 2019</td>
                            </tr>
                            <tr>
                              <td>Unit 3</td>
                              <td>06-Dec 2019</td>
                            </tr>
                            <tr>
                              <td>Safety Health Check</td>
                              <td>13-Jan 2020</td>
                            </tr>
                            <tr>
                              <td>Unit 4</td>
                              <td>13-Jan 2020</td>
                            </tr>
                            <tr>
                              <td>Unit 5</td>
                              <td>25-Feb 2020</td>
                            </tr>
                          </table>
                          <br />
                          <h3>Safety Health Check</h3>
                          <br />
                          <p>
                            As a part of this intervention, the executives were
                            asked to carry out a Safety Health Check on the shop
                            floor, first within their functions and then cross-
                            functionally to observe and note, manual
                            interventions where safety is compromised due to
                            human factors.
                          </p>
                          <br />
                          <p>The observations are being shown here </p>
                          <br />
                        </div>
                        <br />

                        <label style={{ fontWeight: 600, marginLeft: "16px" }}>
                          TPI Health Check Sheet
                        </label>
                        <div className="col-sm-12">
                          <ul class="nav nav-tabs" role="tablist">
                            {state.tabTitle.map((ival, i) => {
                              return (
                                <li class="nav-item">
                                  <a
                                    class={`nav-link ${
                                      i === 0 ? "active" : ""
                                      }`}
                                    data-toggle="tab"
                                    href={`#home1${i}`}
                                  >
                                    {ival}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>

                          <div class="tab-content">
                            {state.tabContent.map((ival, i) => {
                              return (
                                <div
                                  id={`home1${i}`}
                                  class={`container tab-pane ${
                                    i === 0 ? " active" : ""
                                    }`}
                                  style={{ margin: "0px", overflowX: "auto" }}
                                >
                                  <p className="content-para">
                                    {renderHTML(ival ? ival : "")}
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="card"></div>
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-12 mb-new">
                <div className="card">
                  {/* <div className="card-body px-0">
                    <DownPanel />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {this.state.loaded ? this.loadScript(js1) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default SheetContent4;
