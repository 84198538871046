import React from "react";
import { Alert } from "reactstrap";
import LoginModal from "../../../components/Modal/Modal";
import AddUser from "../../Modal/AddCustomer";
import UserData from "../../../MiddleWare/User";
import cmsContent from "../../../MiddleWare/CmsContent";
import Datatable from "../../../components/Datatable/Datatable";
import { FormMiddleWare, SingleSelect } from "../../../components/Form";
import quizUserAnswer from "./quizUserAnswer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import LearnerPDF from "../../../components/PDF/LearnerPDF";
import { CSVLink, CSVDownload } from "react-csv";

class User extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      userIndex: "",
      userName: "",
      mobileNumber: "",
      email: "",
      UserPassword: "",
      department: [],
      userOptions: [],
      selectedOption: "",
      errorUserName: "",
      errorMobileNumber: "",
      errorEmail: "",
      errorPassword: "",
      errordep: "",
      alertVisible: false,
      updateData: false,
      updateEmail: false,
      updatePassword: false,
      formAlertdelete: false,
      data: [],
      dataScore: [],
      showinnermodal: false,
      subdata: [],
      subdatavalue: [],
      extraAnswer: [],
      customerId: localStorage.getItem("userId"),
    };
  }

  async componentWillMount() {
    this.userList();
  }

  userList = async () => {
    let customerId = localStorage.getItem("userId");
    let serviceId = await localStorage.getItem("currentService");

    const userType = 6;

    let userlog = localStorage.getItem("userlog");

    let serviceEnabled = JSON.parse(userlog).serviceEnabled;
    // let userType = JSON.parse(userlog).usertype 
    let usercount = JSON.parse(userlog).usercount;

    let counting = null;
    serviceEnabled.split(",").map((item, i) => {
      if (serviceId === item) {
        counting = usercount.split(",")[i];
      }
    });

    try {
      const result = await UserData.customerIdFullList(
        customerId,
        userType,
        serviceId
      );


      this.setState({
        data: result.data,
        counts: result.data.length,
        counting,
      });


      const user = await cmsContent.getTwoConditionedValue(
        "tbl_user_web",
        "customerId",
        customerId,
        "status",
        "active",
        "count(*) as counts"
      );

      if (user) {
        this.setState({
          users: user.data,
          // counts: user.data[0].counts,
          // counting
        });
      }
      let users = this.state.data;

      if (users.length > 0) {
        const header = ["SI.NO", "USERNAME", "MOBILE", "EMAIL_ID"];
        const excelHead = [
          { label: "USERNAME", key: "userName" },
          { label: "EMAILID", key: "email" },
          { label: "MOBILE", key: "mobileNumber" },

          { label: "STATUS", key: "status" },
        ];
        this.setState({ header, excelHead, excel: users, title: "Users" });
      }


      const result1 = await cmsContent.getFreedom(
        "id as value,name as label",
        "tbl_department_master",
        `customerId=${this.state.customerId} and status="active"`,
        "id",
        "id DESC"
      );
      console.log(result1)
      if (result1) {
        this.setState({ userOptions: result1.data });
        this.setState({ department: result1.data });
      }






    } catch (error) {
      console.log(error);
    }
  };

  /**
   * column will be main for the table to dispay the selected value
   * Header : title for the table
   * accessor : key in the josn which was sent to the table
   * Cell: used to create custom button for the filed in the table
   *
   * link : Ref this link for more info : https://github.com/tannerlinsley/react-table/tree/v6
   */
  column = [
    {
      Header: "UserName",
      accessor: "userName",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "MobileNumber",
      accessor: "mobileNumber",
    },
    {
      Header: "Password",
      accessor: "withoutmd5",
    },
    // {
    //   Header: "Department",
    //   accessor: "deptid",
    //   Cell: d =>
    //         this.getValueFromArrays(
    //           d.original.deptid,
    //           this.state.userOptions
    //         )
    // },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Visibility",
      accessor: "status",
      Cell: (d) => this.userVisibility(d),
    },
    {
      Header: "Quiz",
      accessor: "quiz",
      Cell: (d) => this.quizView(d),
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: (d) => this.edit(d, "customer"),
    },
    {
      Header: "Delete",
      accessor: "delete",
      Cell: (d) => this.delete(d),
    },
  ];

  columns = [
    {
      Header: "UserName",
      accessor: "userName",
      // Cell: (d) => this.getUserName(d),
    },
    {
      Header: "QuizName",
      accessor: "quizName",
    },
    {
      Header: "Questions",
      accessor: "totalQue",
      Cell: (d) => this.Questions(d),
    },
    {
      Header: "Correct",
      accessor: "total",
    },
  ];
  subcolumns = [
    {
      Header: "Question",
      accessor: "question",
    },
    {
      Header: "Answer",
      accessor: "answer",
    },
    {
      Header: "Options",
      accessor: "options",
    },
    {
      Header: "attendquizanswer",
      accessor: "quizanswer",
    },
  ];


  getValueFromArrays = (d, array) => {

    if (array.length > 0) {
      if (array.length !== 0) {
        let filtered = array.filter(function (item) {
          return item.value == d;
        });
        let v = filtered[0];
        if (v != undefined) {

          return v.label;
        } else {
          return "---";
        }
      }
    } else {
      return "-";
    }
  };
  getUserName = (e) => {
    let userName = this.state.data.filter(
      (ival) => ival.id === e.original.userId
    );
    return userName[0].userName;
  };
  Questions = (id) => {
    return (
      <a
        href="#"
        onClick={() => {
          let subdata = id.original;

          var quizanswer = [];
          var contentJson = [];

          if (
            subdata.quizanswer != null &&
            subdata.quizanswer != undefined &&
            subdata.quizanswer != ""
          ) {
            quizanswer = JSON.parse(subdata.quizanswer);
          }
          if (id.original.contentJson != undefined) {
            var contentJson1 = JSON.parse(id.original.contentJson);

            var originalAnswerJson = [];
            Object.keys(contentJson1).forEach((key) => {
              var empAnswer = "";

              if (quizanswer[key] != undefined) {
                if (quizanswer[key].index != undefined) {
                  empAnswer = quizanswer[key].answer;

                  var optionsplit = [];

                  for (var i = 0; i < empAnswer.length; i++) {
                    var indexData = empAnswer[i].slice(6);

                    optionsplit.push(empAnswer[i].slice(6));
                  }
                }
              }
              var array = contentJson1[key].answer.split(",");

              var quizstaticans = [];
              for (var i = 0; i < array.length; i++) {
                var indexData = array[i].slice(6);
                var count = indexData - 1;

                originalAnswerJson.push(contentJson1[key].options[count]);

                quizstaticans.push(contentJson1[key].options[count]);
              }

              let alredyans = contentJson1[key].options;
              let userattendtans = "";
              var newArr = optionsplit.map((key1) => {
                var newarr2 = alredyans.map((key2, index) => {
                  if (key1 == index + 1) {
                    userattendtans += key2 + ",";
                  }
                });
              });

              contentJson.push({
                //
                question: contentJson1[key].question,
                answer: quizstaticans,
                options: contentJson1[key].options,
                count: contentJson1[key].count,
                quizanswer: userattendtans,
              });
            });
          }

          this.setState({
            subdatavalue: subdata.quizanswer,
            extraAnswer: quizanswer,
          });

          this.setState({
            showinnermodal: true,
            subdata: JSON.stringify(contentJson),
          });
          // this.state.subdatavalue.push(this.state.subdatavalue);
        }}
      >
        {id.original.totalQue}
      </a>
    );
  };

  quizView = (d) => {
    return (
      <button
        type="button"
        class="btn btn-primary"
        data-toggle="modal"
        data-target="#exampleModal"
        onClick={() => this.scoreOfuser(d)}
      >
        View
      </button>
    );
  };

  scoreOfuser = async (value) => {
    let userId = value.original.id;
    console.log(value);
    try {
      const score = await cmsContent.getScoreOfUser(userId);
      if (score) {
        this.setState({ dataScore: score.data });
      } else {
        this.setState({ dataScore: [] });
      }
    } catch (error) {
      this.setState({ dataScore: [] });
    }
  };

  onSubmit = async () => {
    let counting = this.state.counting;
    let counts = this.state.counts;
    // let dep = this.state.selectedOption
    let userArray = {};
    let userName = this.state.userName;
    let mobileNumber = this.state.mobileNumber;
    let email = this.state.email;
    let password = this.state.UserPassword
    // let depid = dep.value
    let serviceId = await localStorage.getItem("currentService");
    userArray.userName = userName;
    userArray.mobileNumber = mobileNumber;
    userArray.email = email;
    userArray.withoutmd5 = password
    // userArray.deptid = depid
    userArray.status = "active";
    userArray.serviceId = serviceId;
    userArray.userType = 6;
    userArray.customerId = localStorage.getItem("userId");
    console.log(userArray)
    const previousData = [...this.state.data];
    // if (counts <= counting) {
    try {
      const result = await UserData.userInsert(userArray);
      if (result) {
        let counts = counts + result.data.affectedRows;
        const userData = {
          userName: userName,
          mobileNumber: mobileNumber,
          email: email,
          status: "active",
        };
        const newData = [...this.state.data, userData];
        this.setState({
          data: newData,
          alertVisible: true,
          userName: "",
          mobileNumber: "",
          email: "",
          selectedOption: [],
          errorUserName: "",
          errorMobileNumber: "",
          errorEmail: "",

        });

        setTimeout(() => this.setState({ alertVisible: false }), 3000);
        this.userList();
      }
    } catch (error) {
      this.setState({
        data: previousData,
      });
      console.log(error);
    }
    // }
  };

  buttonEdit = (value) => {
    const index = value.index;
    const previousData = [...this.state.data];
    const getData = { ...previousData[index] };
    this.setState({
      userIndex: index,
      userName: getData.userName,
      mobileNumber: getData.mobileNumber,
      email: getData.email,
      UserPassword: getData.withoutmd5,
      //  selectedOption:[{label:this.getValueFromArrays(this.state.userOptions)}],

      errorUserName: "",
      errorMobileNumber: "",
      errorEmail: "",
      alertVisible: false,
      updateData: true,
    });
  };

  onUpdate = async () => {
    // get all the state value and assign the variable
    let userIndex = this.state.userIndex;
    let userName = this.state.userName;
    let mobileNumber = this.state.mobileNumber;
    let email = this.state.email;
    let password = this.state.UserPassword;
    // let dep =this.state.selectedOption
    /**
     * create a new json array with the update value from the form
     * this array will be passed to the backend for updating the user
     */
    let userArray = {};
    userArray.userName = userName;
    userArray.mobileNumber = mobileNumber;
    userArray.email = email;
    userArray.withoutmd5 = password
    // userArray.deptid=dep.value

    // get all the user
    const previousData = [...this.state.data];

    // get only the edit user from the list of user
    const getData = { ...previousData[userIndex] };
    const id = getData.id;
    getData.userName = userName;
    getData.mobileNumber = mobileNumber;
    getData.email = email;
    // getData.withoutmd5 = password

    // get all the user except the edit user
    const data = previousData.filter((value) => value.id !== id);
    data.splice(userIndex, 0, getData); // update the user value in the list at particular index

    try {
      /**
       * @param tbl_field_agent : tableName which should be updated
       * @param id : user id which should be updated
       * @param userArray : json list value for update
       */
      const result = await UserData.editSelectedUser(id, userArray);
      if (result) {
        this.setState({
          data,
          alertVisible: true,
          userName: "",
          mobileNumber: "",
          email: "",
          selectedOption: [],
          userIndex: "",
          errorUserName: "",
          errorMobileNumber: "",
          errorEmail: "",
        });

        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      this.setState({
        data: previousData,
      });
      console.log(error);
    }
  };

  buttonDelete = async (value) => {
    const id = value.original.id;
    const previousData = [...this.state.data];
    const data = previousData.filter((value) => value.id !== id);
    this.setState({
      data,
    });
    try {
      await UserData.deleteSelectedUser(id);
    } catch (error) {
      this.setState({
        data: previousData,
      });
      console.log(error);
    }
  };

  reset = () => {
    this.setState({
      userIndex: "",
      userName: "",
      mobileNumber: "",
      email: "",
      UserPassword: "",
      errorPassword: "",
      errorUserName: "",
      selectedOption: [],
      errorMobileNumber: "",
      errorEmail: "",
      alertVisible: false,
      updateData: false,
    });
  };

  userVisibility = (d) => {
    let status = d.original.status;
    let colour = d.original.status == "active" ? "warning" : "danger";
    return this.dataTableButton(colour, status, () => this.activeVisibility(d));
  };

  activeVisibility = async (value) => {
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };
    if (newData.status === "active") {
      newData.status = "Inactive";
      this.setState({ button: "Inactive" });
    } else {
      newData.status = "active";
      this.setState({ button: "active" });
    }

    const id = newData.id;
    const data = previousData.filter((value) => value.id !== id);
    data.splice(index, 0, newData);
    try {
      const result2 = await UserData.editSelectedUser(id, newData);
      // alert(data);
      if (result2) {
        this.setState({ data: data });
      }
    } catch (error) {
      console.log(error);
    }
  };
  submitcsv = async () => {
    if (!this.state.csv) {
      this.setState({ cserror: "Please Upload File" });
    } else {
      this.setState({ cserror: " " });
      const file = this.state.csv;
      let usertype = 6;
      let customerId = localStorage.getItem("userId");
      let serviceid = await localStorage.getItem("currentService");

      const formData = new FormData();
      formData.append("customerId", customerId);
      formData.append("serviceId", serviceid);
      formData.append("file", file);
      //console.log([...formData]);
      const result = await cmsContent.learnerfile(usertype, formData);
      console.log(result);
      if (result.data.msg == "Incorrect File Format") {
        this.setState({
          formAlertdelete: true,
          textalert: "Incorrect File Format",
          color: "danger",
          csv: null,
          resp: result.data,
          file: "",
        });
      }
      if (result.data.alreadymapped.length > 0) {
        this.setState({
          formAlertdelete: true,
          textalert: "UserAlreadyExist",
          color: "danger",
          resp: result.data,
          file: "",
        });
      } else {
        this.setState({
          formAlertdelete: true,
          textalert: "File uploaded Successfully",
          color: "success",
          csv: null,
          resp: result.data,
          file: "",
        });
      }
      // if (result) {
      //   this.setState({
      //     formAlertdelete: true,
      //     textalert: "File uploaded Successfully",
      //     color: "success",
      //     csv: " ",
      //     resp: result.data,
      //   });
      // }
      setTimeout(() => this.setState({ formAlertdelete: false }), 3000);
    }
  };
  render() {
    const { formAlertdelete, textalert, resp } = this.state;

    let userlog = localStorage.getItem("userlog");

    let userType1 = JSON.parse(userlog)
    let userType = userType1.id
    //console.log(userType.userType)
    const userData = {
      userName: this.state.userName,
      mobileNumber: this.state.mobileNumber,
      email: this.state.email,
      UserPassword: this.state.UserPassword

    };
    console.log(this.state.UserPassword)
    const { counting } = this.state;
    const errorData = {
      errorUserName: this.state.errorUserName,
      errorMobileNumber: this.state.errorMobileNumber,
      errorEmail: this.state.errorEmail,
      errorServices: this.state.errordep,
      errorPassword: this.state.errorPassword
    };
    //console.log(this.state.department)
    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3>Add User</h3>
                </div>
                <div className="card-body">
                  <div
                    class="modal fade bd-example-modal-lg"
                    id="exampleModal"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">
                            Quiz Answer
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          {!this.state.showinnermodal &&
                            this.state.dataScore && (
                              <Datatable
                                data={this.state.dataScore}
                                columnHeading={this.columns}
                              />
                            )}
                          {this.state.showinnermodal && (
                            <button
                              type="button"
                              class="btn btn-primary "
                              onClick={() => {
                                this.setState({ showinnermodal: false });
                              }}
                            >
                              BACK
                            </button>
                          )}
                          {this.state.showinnermodal &&
                            this.state.subdata.length > 0 && (
                              <Datatable
                                data={JSON.parse(this.state.subdata)}
                                columnHeading={this.subcolumns}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inlinebutton">
                    <LoginModal
                      buttonTitle="Add User"
                      title={
                        this.state.updateData === false
                          ? "Add User"
                          : "Update User"
                      }
                      id="customer"
                      onClick={this.reset}
                      bodyText={
                        <div>
                          {this.state.counts >= counting ? (
                            <div>
                              You have exhausted your limit to add more user
                            </div>
                          ) : (
                            < div >
                              <AddUser
                                userData={userData}
                                options1={this.state.department}
                                selectedOption={this.state.selectedOption}
                                handleChange={this.handleChange1}
                                errorData={errorData}
                                userType={userType}
                                handleInput={this.handleUserInput}
                                handleSubmit={this.handleSubmit}
                                onDismiss={this.onDismiss}
                                alertVisible={this.state.alertVisible}
                                checkPassword={this.CheckPassword}
                                checkMobileNumber={this.checkMobileNumber}
                                checkEmail={this.checkEmail}
                                updateData={this.state.updateData}
                                updateEmail={this.state.updateEmail}
                                updatePassword={this.state.updatePassword}

                              />

                            </div>
                          )}
                        </div>
                      }
                    />
                    {console.log(this.state.department1)}
                    {/* {console.log(this.state.UserPassword)} */}
                    {this.state.data && this.state.data.length > 0 ? (
                      <PDFDownloadLink
                        style={{ color: "white", marginLeft: "10px" }}
                        document={
                          <LearnerPDF
                            title={this.state.title}
                            header={this.state.header}
                            data={this.state.data}
                          />
                        }
                        fileName="Users.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    ) : null}
                    {this.state.data && this.state.data.length ? (
                      <CSVLink
                        data={this.state.data}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "10px" }}
                        >
                          Excel
                        </button>
                      </CSVLink>
                    ) : null}

                    {this.state.data && this.state.data.length ? (
                      <LoginModal
                        buttonTitle="Import Csv"
                        title="Csv file upload"
                        buttonStyle={{ marginLeft: "10px" }}
                        id="importuser"
                        maxwidth="sm"
                        bodyText={
                          <div>
                            <Alert
                              className="badge-content"
                              color={this.state.color}
                              isOpen={formAlertdelete}
                              toggle={this.onDismiss}
                            >
                              {textalert}
                            </Alert>

                            {resp && (
                              <div className=" col-sm-4 col">
                                <div>
                                  <b>UsersAlreadyExists</b>
                                </div>
                                {resp.alreadymapped.map((ival, n) => (
                                  <div>{ival}</div>
                                ))}
                                <div>
                                  <b>Incorrect Format</b>
                                </div>
                                {resp.usernotfound.map((jval, c) => (
                                  <div>
                                    {jval.email},{jval.mobileNumber},
                                    {jval.userName}
                                  </div>
                                ))}
                              </div>
                            )}

                            <label>Upload file:</label>
                            <input
                              type="file"
                              className="form-control"
                              accept=".csv"
                              onChange={(e) =>
                                this.setState({ csv: e.target.files[0] })
                              }
                            />
                            <span className="error-shows">
                              {this.state.cserror}
                            </span>
                            <br />
                            <div className="col-md-5">
                              <a href="/public/assets/files/Sample.csv" download="Sample.csv">
                                download sample.csv
                              </a>
                            </div>
                            <button
                              type="button"
                              class="btn btn-primary waves-effect"
                              onClick={() => this.submitcsv()}
                            >
                              Submit
                            </button>
                          </div>
                        }
                      />
                    ) : null}
                  </div>
                  <br></br>
                  <span>
                    Total of {Object.keys(this.state.data).length} records
                  </span>
                  <br></br>
                  {this.state.data && (
                    <Datatable
                      data={this.state.data}
                      columnHeading={this.column}
                    />
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </main >
    );
  }
}

export default User;
