import React, { Component } from 'react';
import CmsContent from "../../../MiddleWare/CmsContent";
import SingleSelect from "../../../components/Form/SingleSelect";
import MultiSelect from "../../../components/Form/MultiSelect";
import DataTables from '../../../components/Extra/newDataTable';
import { ACCESS_POINT } from '../../../config';

class Dashboard1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: localStorage.getItem("userId"),
      portletList: [
        { label: 'Video', value: 2 },
        { label: 'Evaluation', value: 8 }
      ],
      columns: [
        {
          name: <b>Employee name</b>,
          selector: "label",
          sortable: true
        },
        {
          name: <b>Status</b>,
          selector: d => this.getStatus(d),
          sortable: true,
          cell: d => this.getStatus(d)
        },
        {
          name: <b>Score</b>,
          selector: d => this.getScore(d),
          sortable: true,
          cell: d => this.getScore(d)
        }
      ],
      userImage: 'SDAS/Video/img_avatar_1582189345642.png'
    };
  }
  async componentWillMount() {
    try {
      const { data: overall } = await CmsContent.getoverallTopperdetails(this.state.customerId);
      let userlog = JSON.parse(localStorage.getItem("userlog"))
      console.log(userlog)
      let filepath = userlog.userName
      console.log(filepath)
      this.setState({ filepath })
      const { data: cohorList } = await CmsContent.getFreedom(
        'name as label,id as value',
        'tbl_group',
        `customerId='${this.state.customerId}' and status='active'`
      );
      const { data: mappedList } = await CmsContent.getFreedom(
        'tbl_group.id as groupid,tbl_group.name as groupname, tbl_user_web.id as userid ,tbl_user_web.userName as username,tbl_user_web.*',
        'tbl_group,tbl_maptogroup,tbl_user_web',
        `tbl_group.status='active' and tbl_user_web.status='active' and tbl_maptogroup.status='active' and tbl_maptogroup.groupid=tbl_group.id and tbl_maptogroup.userid=tbl_user_web.id and tbl_group.customerId=tbl_user_web.customerId and tbl_user_web.customerId=${this.state.customerId}`,
        'tbl_user_web.id',
        'tbl_user_web.id DESC'
      );


      const { data: evaluationList } = await CmsContent.getFreedom(
        'quizName as label,id as value',
        'tbl_question',
        `customerid='${this.state.customerId}' and type='active'`
      );
      let groupList = [];
      if (mappedList && overall) {
        mappedList.map((ival, i) => {
          if (groupList.filter(jval => ival.groupid === jval.gid).length === 0) {
            groupList.push({ gid: ival.groupid, gname: ival.groupname, score: 0 });
          }
        })
      }
      groupList.map((ival, i) => {
        mappedList.map((jval, j) => {
          if (ival.gid === jval.groupid) {
            overall.map((lval, l) => {
              if (lval.id === jval.userid) {
                ival.score = ival.score + lval.score;
              }
            })
          }
        });
      });
      this.setState({ cohorList, mappedList, overall, groupList, evaluationList });
    } catch (error) {
      console.log(error);
    }
  }
  getStatus = d => {
    return d.status === "completed" ? <div className="row col-12"><div className="col-3 p-0" style={{ marginTop: 'auto' }}>Completed</div><div className="col-6"><img style={{ height: 24, width: 24 }} src={`${ACCESS_POINT}/superAdmin/file?fileurl=ramprasadselvam/Gold_Cup_Trophy_PNG_Clipart_Picture_1582119385914.png`} /></div></div> : "Unattended"
  }
  getScore = d => {
    if (d.status === "completed") {
      return d.data[0].total
    } else {
      return "-"
    }
  }
  cohortSelect = e => {
    this.setState({ cohortSelect: e });
  }
  portletSelect = e => {
    this.setState({ portletSelect: e });
  }
  portContent = async e => {
    try {
      // const { data: portContent } = await CmsContent.getFreedom(
      //   'tbl_boxcontent.heading as label ,tbl_boxcontent.id as value,tbl_map_grouptoportlet.boxtitle',
      //   'tbl_map_grouptoportlet,tbl_boxcontent',
      //   `tbl_map_grouptoportlet.contentid=tbl_boxcontent.name and tbl_map_grouptoportlet.boxtypeid=tbl_boxcontent.type and tbl_map_grouptoportlet.status='active' and tbl_boxcontent.status='active' and tbl_map_grouptoportlet.customerid='${this.state.customerId}' and tbl_map_grouptoportlet.boxtypeid=${e.value} and tbl_map_grouptoportlet.groupid=${this.state.cohortSelect.value}`,
      //   'tbl_boxcontent.id',
      //   'tbl_boxcontent.id desc'
      // );

      let portContent = await CmsContent.getFreedom(
        'tbl_map_grouptoportlet.id as value  ,tbl_map_grouptoportlet.boxtitle as label ',
        'tbl_map_grouptoportlet',
        `tbl_map_grouptoportlet.boxtypeid=${e.value} and tbl_map_grouptoportlet.groupid=${this.state.cohortSelect.value}`,
        'tbl_map_grouptoportlet.id',
        'tbl_map_grouptoportlet.id desc'
      );
      // portContent.map((ival, i) => {
      //   ival.label = `${ival.label} (${ival.boxtitle})`
      // })
      this.setState({ portContent: portContent.data });
    } catch (error) {
      console.log(error);
    }
  }
  portContentSelect = async e => {
    try {
      // const { data: learnerList } = await CmsContent.getFreedom(
      //   'tbl_user_web.userName as label,tbl_user_web.id as value',
      //   'tbl_user_web,tbl_maptogroup',
      //   `tbl_maptogroup.groupid=${this.state.cohortSelect.value} and tbl_maptogroup.status='active' and tbl_user_web.status='active' and tbl_maptogroup.userid=tbl_user_web.id`,
      //   'tbl_user_web.id',
      //   'tbl_user_web.userName ASC'
      // );
      const { data: learnerList } = await CmsContent.getFreedom(
        'tbl_user_web.userName as label,tbl_user_web.id as value',
        'tbl_user_web,tbl_maptogroup',
        `tbl_maptogroup.groupid=${this.state.cohortSelect.value}  and tbl_maptogroup.userid=tbl_user_web.id and tbl_user_web.customerId=${this.state.customerId}`,
        'tbl_maptogroup.userid',
        'tbl_user_web.userName ASC'
      );
      // const { data: statusLearnerList } = await CmsContent.getFreedom(
      //   '*',
      //   'tbl_quizAnswer',
      //   `portid=${e.value}`
      // );

      let statusLearnerList = '';
      if (this.state.portletSelect.value == 8) {
        statusLearnerList = await CmsContent.getFreedom(
          'tbl_quizAnswer.*',
          'tbl_map_grouptoportlet,tbl_boxcontent,tbl_quizAnswer,tbl_maptogroup',
          `tbl_boxcontent.type=8 and  tbl_maptogroup.groupid=${this.state.cohortSelect.value} and  tbl_map_grouptoportlet.id=${e.value} and tbl_boxcontent.name=tbl_map_grouptoportlet.contentid and tbl_boxcontent.evaluation=tbl_quizAnswer.quizid`
        );
      }
      console.log(`tbl_maptogroup.groupid=${this.state.cohortSelect.value} and  tbl_map_grouptoportlet.id=${e.value} and tbl_boxcontent.name=tbl_map_grouptoportlet.contentid and tbl_boxcontent.evaluation=tbl_quizAnswer.quizid`)

      if (this.state.portletSelect.value == 2) {
        console.log('vdieo')
        let statusLearner = await CmsContent.getFreedom(
          'tbl_boxcontent.*',
          'tbl_map_grouptoportlet,tbl_boxcontent,tbl_maptogroup',
          `tbl_boxcontent.type=2 and  tbl_maptogroup.groupid=${this.state.cohortSelect.value}  and tbl_map_grouptoportlet.id=${e.value} and tbl_boxcontent.name=tbl_map_grouptoportlet.contentid `
        );
        console.log(statusLearner.data[0].id)
        let quizvalue = ''
        console.log(JSON.parse(statusLearner.data[0].overlay))
        JSON.parse(statusLearner.data[0].overlay).map(ival => {
          //console.log()
          if (ival.overlayType == "Quiz") {
            quizvalue = ival.quizId;
          }
        })
        console.log(quizvalue)
        statusLearnerList = await CmsContent.getFreedom(
          'tbl_quizAnswer.*',
          'tbl_map_grouptoportlet,tbl_boxcontent,tbl_quizAnswer',
          ` tbl_quizAnswer.portid=${statusLearner.data[0].id} and tbl_map_grouptoportlet.id=${e.value}  and tbl_quizAnswer.quizid=${quizvalue} and tbl_boxcontent.name=tbl_map_grouptoportlet.contentid `
        );

      }

      console.log(statusLearnerList)

      if (statusLearnerList && learnerList) {
        learnerList.map((ival, i) => {
          let checkStatus = statusLearnerList.data.filter(jval => ival.value === jval.userId);
          if (checkStatus.length) {
            learnerList[i].status = "completed";
            learnerList[i].data = checkStatus;
          }
        })
      }
      console.log(learnerList)
      this.setState({ portContentSelect: e, learnerList, learnerSelect: learnerList });
    } catch (error) {
      console.log(error);
    }
  }
  learnerSelect = e => {
    this.setState({ learnerSelect: e });
  }
  evaluationListSelect = e => {
    const { mappedList, overall } = this.state;
    let SpecificWinnerPerson = {};
    if (overall) {
      overall.map((ival, i) => {
        ival.quizscore.map((jval, j) => {
          if (jval.qid === e.value) {
            if (SpecificWinnerPerson.score === undefined) {
              SpecificWinnerPerson = { id: ival.id, name: ival.name, score: jval.qscore };
            } else if (SpecificWinnerPerson.score <= jval.qscore) {
              SpecificWinnerPerson = { id: ival.id, name: ival.name, score: jval.qscore };
            }
          }
        })
      })
    }

    let filterMap = new Map();
    let allGroupList = [];
    for (const item of mappedList) {
      if (!filterMap.has(item.groupid)) {
        filterMap.set(item.groupid, true);
        allGroupList.push({ groupid: item.groupid, groupname: item.groupname, score: 0 });
      }
    }

    allGroupList.map((ival, i) => {
      ival.users = [];
      mappedList.map((jval, j) => {
        if (jval.groupid === ival.groupid) {
          ival.users.push({ userid: jval.userid, username: jval.username, score: 0 })
        }
      })
    })
    let SpecificWinnerGroup = {}
    allGroupList.map((ival, i) => {
      ival.users.map((jval, j) => {
        overall.map((kval, k) => {
          if (kval.id === jval.userid) {
            kval.quizscore.map((lval, l) => {
              if (lval.qid === e.value) {
                jval.score = lval.qscore;
                ival.score = ival.score + lval.qscore;
              }
            });
          }
        })
      });
      if (!SpecificWinnerGroup.score) {
        SpecificWinnerGroup = { ...ival }
      } else if (SpecificWinnerGroup.score <= ival.score) {
        SpecificWinnerGroup = { ...ival };
      }
    })
    SpecificWinnerGroup = SpecificWinnerGroup.score === 0 ? {} : SpecificWinnerGroup;
    this.setState({ evaluationListSelect: e, SpecificWinnerPerson, SpecificWinnerGroup });
  }
  render() {
    const state = this.state;
    const filepath = this.state;

    let winnerGroup = {};
    let winnerGroupscore = 0;
    if (state.groupList && state.groupList.length) {
      state.groupList.map((ival, i) => {
        if (winnerGroupscore <= ival.score) {
          winnerGroupscore = ival.score;
          winnerGroup = { ...ival };
        }
      })
    }
    let winnerPerson = {};
    let winnerPersonscore = 0;

    let SpecificWinnerPerson = state.SpecificWinnerPerson ? state.SpecificWinnerPerson : {};
    if (state.overall && state.overall.length) {
      state.overall.map((ival, i) => {
        if (winnerPersonscore <= ival.score) {
          winnerPersonscore = ival.score;
          winnerPerson = { ...ival };
        }
      })
      if (winnerPerson.id) {
        state.mappedList.map((ival, i) => {
          ival.image = ival.image.split("SDAS/Video").join(this.state.filepath)
          if (winnerPerson.id === ival.id) {
            SpecificWinnerPerson.image = `${ACCESS_POINT}/superAdmin/file?fileurl=${ival.image ? ival.image : state.userImage}`;
            SpecificWinnerPerson.name = ival.username;
            winnerPerson.image = `${ACCESS_POINT}/superAdmin/file?fileurl=${ival.image ? ival.image : state.userImage}`;
            winnerPerson.name = ival.username;
          }
        });
      }
    }
    return (
      <React.Fragment>
        <h1 className="my-2 black text-left">Leaderboard</h1>
        <hr className="hrColour" />
        <div className="row">
          <div className="col-sm-6" >
            <h2 className="mt-2 black">Overall Ranking</h2>
          </div>
          <div className="col-sm-2 px-0 ">
            <h4 className="mt-2 black">Ranking on Evaluation</h4>
            {/* <label htmlFor="exampleInputEmail1" className="mt-1">Ranking On Evaluation</label> */}
          </div>
          <div className="col-sm-3 px-0">
            <SingleSelect options={state.evaluationList} handleChange={e => { this.evaluationListSelect(e); }} value={state.evaluationListSelect} placeholder="Select evaluation" />
          </div>
          <div className="col-sm-1" />
        </div>

        <div className="row col-12 mt-3">

          <div className="col-sm-3 ">
            <div className="col dashboardShadow gradientDashboard dashboardCard">
              {/* <img className="dashboardBadge" src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Top_Number1Medal_1582119374543.png`} /> */}
              <img className="dashboardBadge"
                src={`${ACCESS_POINT}/superAdmin/file?fileurl=ramprasadselvam/Top_Number1Medal_1582119374543.png`}
              //  src={`${ACCESS_POINT}/superAdmin/file?fileurl=${state.filepath}/Top_Number1Medal_1582119374543.png`}
              />  <h2 className="mt-2">Top Cohort</h2>
              {winnerGroup.gname ? (
                <div>
                  <h2 className="bold mt-4 mb-2" style={{ marginLeft: 20, fontSize: 20 }}><b>{winnerGroup.gname}</b></h2>
                  <hr className="hrColour" />
                  <div className="bold mb-1">{`User Count : ${state.mappedList.filter(jval => winnerGroup.gid === jval.groupid).length}`}</div>
                  <div className="bold mb-1">{`Total Score : ${winnerGroup.score}`}</div>
                </div>
              ) : null}
              <div className="row-12">
              </div>
            </div>

          </div>

          <div className="col-sm-3 ">
            <div className="dashboardCard dashboardShadow">
              {/* <img className="dashboardBadge" src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Top_Number1Medal_1582119374543.png`} /> */}
              <img className="dashboardBadge"
                src={`${ACCESS_POINT}/superAdmin/file?fileurl=ramprasadselvam/Top_Number1Medal_1582119374543.png`}
              //  src={`${ACCESS_POINT}/superAdmin/file?fileurl=${state.filepath}/Top_Number1Medal_1582119374543.png`}
              /> <h2 className="mt-2 black">Top Learner</h2>
              {winnerPerson.name ? (
                <div>
                  <div className="mt-3 mb-2">
                    <img src={winnerPerson.image} className="UserImageDashboard" />
                  </div>
                  <div className="text-capitalize"><b>{winnerPerson.name}</b></div>
                  <div>{`Total Score : ${winnerPerson.score}`}</div>
                </div>
              ) : null}
            </div>

          </div>

          <div className="col-sm-3 ">
            <div className="dashboardCard">
              {/* <img className="dashboardBadge" src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Top_Number1Medal_1582119374543.png`} /> */}
              <img className="dashboardBadge"
                src={`${ACCESS_POINT}/superAdmin/file?fileurl=ramprasadselvam/Top_Number1Medal_1582119374543.png`}
              //  src={`${ACCESS_POINT}/superAdmin/file?fileurl=${state.filepath}/Top_Number1Medal_1582119374543.png`}
              />
              <h2 className="mt-2 black">Top Cohort</h2>
              {state.SpecificWinnerGroup && state.SpecificWinnerGroup.groupname ? (
                <div>
                  <h2 className="bold mt-4 mb-2 black"><b>{state.SpecificWinnerGroup.groupname}</b></h2>
                  <hr className="hrColour" />
                  <div className="bold mb-1">{`User Count : ${state.SpecificWinnerGroup.users.length}`}</div>
                  <div className="bold mb-1">{`Total Score : ${state.SpecificWinnerGroup.score}`}</div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="col-sm-3 ">
            <div className="dashboardCard">
              {/* <img className="dashboardBadge" src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/Top_Number1Medal_1582119374543.png`} /> */}
              <img className="dashboardBadge"
                src={`${ACCESS_POINT}/superAdmin/file?fileurl=ramprasadselvam/Top_Number1Medal_1582119374543.png`}
              //  src={`${ACCESS_POINT}/superAdmin/file?fileurl=${state.filepath}/Top_Number1Medal_1582119374543.png`}
              />
              <h2 className="mt-2 black">Top Learner</h2>
              {SpecificWinnerPerson.score ? (
                <div>
                  <div className="mt-3 mb-2">
                    <img src={SpecificWinnerPerson.image} className="UserImageDashboard" />
                  </div>
                  <div className="text-capitalize"><b>{SpecificWinnerPerson.name}</b></div>
                  <div>{`Total Score : ${SpecificWinnerPerson.score}`}</div>
                </div>
              ) : null}
            </div>
          </div>

        </div>


        <h1 className="my-2 mt-4 black text-left">Performance Report</h1>
        <hr className="hrColour" />

        <div className="row">
          <div className="col-sm-12 mb-3 mt-1">
            <div className="row">

              <div className="col-sm-3 ">
                <div className="row">
                  <div className="col-sm-4 px-0 centeralign">
                    {/* <label htmlFor="exampleInputEmail1" className="mt-1">Cohorts</label> */}
                  </div>
                  <div className="col-sm-8 px-0 ">
                    <SingleSelect options={state.cohorList} handleChange={e => { this.cohortSelect(e); }} value={state.cohortSelect} placeholder="Cohorts" />
                  </div>
                </div>
              </div>

              <div className="col-sm-3 ">
                <div className="row">
                  <div className="col-sm-4 px-0 centeralign">
                    {/* <label htmlFor="exampleInputEmail1" className="mt-1">{state.cohortSelect ? "Portlet" : null}</label> */}
                  </div>
                  <div className="col-sm-8 px-0">
                    {state.cohortSelect ?
                      (<SingleSelect options={state.portletList} handleChange={e => { this.portletSelect(e); this.portContent(e); }} value={state.portletSelect} placeholder="Portlet" />) : null}

                  </div>
                </div>
              </div>

              <div className="col-sm-3 ">
                <div className="row">
                  <div className="col-sm-4 centeralign">
                    {/* <label htmlFor="exampleInputEmail1" className="mt-1">{(state.portContent) ? "Content Name" : null}</label> */}
                  </div>
                  <div className="col-sm-8 px-0">
                    {(state.portContent) ? (<SingleSelect placeholder="Content" options={state.portContent} handleChange={e => this.portContentSelect(e)} value={state.portContentSelect} />) : null}

                  </div>
                </div>
              </div>

              {/* <div className="col-sm-3 ">
                <div className="row">
                  <div className="col-sm-5 px-0 centeralign">
                    <label htmlFor="exampleInputEmail1" className="mt-1">Learners</label>
                  </div>
                  <div className="col-sm-7 px-0">
                    {state.learnerList ? (<MultiSelect
                      options={state.learnerList}
                      handleChange={e => this.learnerSelect(e)}
                      selectedService={state.learnerSelect}
                    />) : null}
                  </div>
                </div>
              </div> */}

            </div>

          </div>
          {state.learnerSelect ? <DataTables
            columns={this.state.columns}
            data={this.state.learnerSelect}
          /> : null}

        </div>
        <h1 className="my-2 mt-4 black text-left">Dashboard Stats</h1>
        <hr className="hrColour" />
      </React.Fragment >
    );
  }
}
export default Dashboard1;