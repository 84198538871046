import React, { Component } from 'react';
import CmsContent from '../../../MiddleWare/CmsContent';
import Datatable from "../../../components/Datatable/Datatable";
import { Alert } from 'reactstrap';
import LoginModal from '../../../components/Modal/Modal';
import SingleSelect from "../../../components/Form/SingleSelect";
import { confirmAlert } from "react-confirm-alert";
// import CmsContent from "../../../MiddleWare/CmsContent";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
import cmsContent from "../../../MiddleWare/CmsContent";
import { async } from 'q';
import FormModal from '../../../components/Modal/FormModal';
import { ACCESS_POINT } from '../../../config';

class SiteHome extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            themeId: null,
            Model_data: [],
            changenumber: null,
            fullData: [],
            imagecontent: [],
            index: null,
            alertVisible: false,
            active: 1,
            customerId: localStorage.getItem("userId"),
            column: [
                {
                    Header: "ID",
                    accessor: "id"
                },
                {
                    Header: "MAKE",
                    accessor: "makename"
                },
                {
                    Header: "MODEL",
                    accessor: "modelname"
                },
                {
                    Header: "YEAR",
                    accessor: "make_year"
                },
                // {
                //     Header: "KMS",
                //     accessor: "kms"
                // },
                {
                    Header: "COLOUR",
                    accessor: "ColourName"
                },
                // {
                //     Header: "OWNER_TYPE",
                //     accessor: "owner_type"
                // },
                // {
                //     Header: "PRICE",
                //     accessor: "price"
                // },
                {
                    Header: "LOCATION",
                    accessor: "LocationName"
                },
                {
                    Header: "SOURCE",
                    accessor: "submitted_by"
                },
                {
                    Header: "IMAGES",
                    accessor: "isImage"
                },

                {
                    Header: "STATUS",
                    accessor: "status",
                    Cell: d => this.Status(d)
                },
                {
                    Header: "MOVE TO SITE",
                    minWidth: 120,
                    accessor: "Movetosite",
                    Cell: d => this.Details(d)
                },
                {
                    Header: "ASSIGN HOME",
                    minWidth: 120,
                    accessor: "Movetosite",
                    Cell: d => this.Details1(d)
                },
                {
                    Header: "DETAILS",
                    minWidth: 120,
                    accessor: "Movetosite",
                    Cell: d => this.Other_MOdel(d.original)
                },
                // {
                //     Header: "Leads",
                //     accessor: "",
                //     Cell: d => this.leads(d)
                // },
                // {
                //     Header: "Share",
                //     accessor: "",
                //     Cell: d => this.Share(d)
                // }
            ],
        };
    }
    async componentDidMount() {
        this.MainFun()
    }
    MainFun = async () => {
        try {
            this.setState({ loading: true })
            let result = await cmsContent.getFreedom(
                "tbl_Dfcars_Garage.*,tbl_make_master.Make as makename,tbl_dfcarsmodel.Model as modelname ,tbl_variant_master.variant as variantname,tbl_colour.colour as ColourName,tbl_location.name as LocationName",
                `tbl_Dfcars_Garage,tbl_make_master,tbl_dfcarsmodel,tbl_colour,tbl_location,tbl_variant_master`,
                `tbl_Dfcars_Garage.Serviceid = 10 and tbl_Dfcars_Garage.Movedtosite = 1 and tbl_Dfcars_Garage.status IS NULL and tbl_Dfcars_Garage.ongoing IS NULL and tbl_Dfcars_Garage.make=tbl_make_master.id and tbl_dfcarsmodel.id=tbl_Dfcars_Garage.model and tbl_colour.id = tbl_Dfcars_Garage.colour and tbl_location.id = tbl_Dfcars_Garage.location and tbl_variant_master.id= tbl_Dfcars_Garage.variant`,
                1,
                "id desc")
            if (result) {
                let v1 = 0
                let json = await result.data.map(async (ival, j) => {
                    if (ival.home == 1) {
                        v1 = ++v1
                    }
                    if (ival.temp == 1) {
                        ival.submitted_by = "SITE"
                    } else {
                        ival.submitted_by = "WEB"
                    }
                    if (ival.filesArray) {
                        ival.Cimages = []
                        let arrayofile = []
                        let samp = JSON.parse(ival.filesArray);
                        arrayofile.push(samp)
                        arrayofile.map((val, i) => {
                            // console.log(val, 'val');
                            if (val) {
                                if (val.LHS) {
                                    val.LHS.map((jval, j) => {
                                        if (jval) {
                                            let uri = "/superAdmin/sharefile?filename=" + jval;
                                            ival.Cimages.push(uri);
                                        }
                                    })
                                }
                                if (val.RHS) {
                                    val.RHS.map((jval, j) => {
                                        if (jval) {
                                            let uri = "/superAdmin/sharefile?filename=" + jval;
                                            ival.Cimages.push(uri);
                                        }
                                    })
                                }
                                if (val.FRONT) {
                                    val.FRONT.map((jval, j) => {
                                        if (jval) {
                                            let uri = "/superAdmin/sharefile?filename=" + jval;
                                            ival.Cimages.push(uri);
                                        }
                                    })
                                }
                                if (val.BACK) {
                                    val.BACK.map((jval, j) => {
                                        if (jval) {
                                            let uri = "/superAdmin/sharefile?filename=" + jval;
                                            ival.Cimages.push(uri);
                                        }
                                    })
                                }
                            }
                        })
                    }
                })
                let json1 = await result.data.map(async (ival, j) => {
                    if (ival && ival.Cimages && ival.Cimages.length) {
                        ival.isImage = 'Yes'
                    } else {
                        ival.isImage = 'No'
                    }
                })
                await Promise.all(json, json1)
                console.log(result, "Mainapapi");
                await this.setState({ fullData: result.data, point: v1, loading: false })
            }
        } catch (err) {
            console.log(err);
            this.setState({ loading: false })
        }
    }
    Other_MOdel = (d) => {
        return <button style={{ Width: 95, textAlign: "center" }} className="btn btn-primary" onClick={async () => {
            await this.openmodel(d)
        }}>View</button>
    }
    openmodel = async (d) => {
        let imagecontent = []
        let json1 = await [d].map(async (ival, i) => {
            await imagecontent.push(
                ival.Cimages && ival.Cimages.length && ival.Cimages.map((ival, j) => {
                    if (ival) {
                        return (
                            <div class="col-md-4">
                                <img src={ACCESS_POINT + ival} />
                            </div>
                        )
                    }
                })

            )
        })
        if (imagecontent && imagecontent.includes(undefined)) {
            imagecontent = []
        }
        await Promise.all(json1)
        await this.setState({ Model_data: d, imagecontent, active: 1 })
        window.$("#formmodel").modal("show")
    }
    Status = (d) => {
        if (d.original.status == 'inactive') {
            return (<div><button type="button" className="btn btn-danger btn-sm"
            // onClick={()=>this.F_proofForm(d)}
            >SOLD</button></div>);
        } else {
            return (
                <div>
                    <button type="button" className="btn btn-primary btn-sm"
                    >AVAILABLE</button>
                </div>
            );
        }
    }
    Details = (d) => {
        if (d.original.Movedtosite !== null && d.original.Movedtosite) {
            return (<div><button type="button" className="btn btn-warning btn-sm" style={{ width: '100%' }}
            >Already Moved</button></div>);
        } else {
            return (<div><button type="button" className="btn btn-danger btn-sm" style={{ width: '100%' }}
                onClick={() => this.Movetosite(d)}
            >Move To Site</button></div>);
        }
    }
    Details1 = (d) => {
        let { point } = this.state

        if (d.original.Movedtosite == 1 && d.original.status == null && d.original.ongoing == null && d.original.home == null && point <= 2) {
            return (<div><button type="button" onClick={() => this.MovetoHome(d)} className="btn btn-success btn-sm" style={{ width: '100%' }}
            >Assign</button></div>);
        }
        else if (d.original.Movedtosite == 1 && d.original.status == null && d.original.ongoing == null && d.original.home == 1) {
            return (<div><button type="button" onClick={() => this.MovetoHome1(d)} className="btn btn-secondary btn-sm" style={{ width: '100%' }}
            >Assigned</button></div>);
        }
        else if (d.original.Movedtosite == 1 && d.original.status == null && d.original.ongoing == null && point >= 3) {
            return (<div><button type="button" className="btn btn-primary btn-sm" style={{ width: '100%' }}
            >Exceseed</button></div>);
        }
        else {
            return (<div><button type="button" className="btn btn-primary btn-sm" style={{ width: '100%' }}
            >Something went wrong</button></div>);
        }
    }
    Movetosite = async (d) => {
        try {
            let result = await cmsContent.updateMaster(
                "tbl_Dfcars_Garage",
                `${d.original.id}`,
                { Movedtosite: "1" }
            );
        } catch (err) { console.log(err); }
        this.update();

    }
    MovetoHome = async (d) => {
        try {
            let result = await cmsContent.updateMaster(
                "tbl_Dfcars_Garage",
                `${d.original.id}`,
                { home: "1" }
            );
        } catch (err) { console.log(err); }
        this.MainFun();

    }
    MovetoHome1 = async (d) => {
        try {
            let result = await cmsContent.updateMaster(
                "tbl_Dfcars_Garage",
                `${d.original.id}`,
                { home: null }
            );
        } catch (err) { console.log(err); }
        this.MainFun();

    }
    render() {
        const { fullData, alertVisible, Model_data, active, imagecontent } = this.state;

        console.log(imagecontent, "imagecontent");
        return (
            <>
                <FormModal
                    title={`${Model_data.make_year} ${Model_data.makename} ${Model_data.modelname} `}
                    id={"formmodel"}
                    // reset={() => this.reset123()}
                    bodyText={
                        <div>
                            <ul class="nav nav-tabs" id="myTab" role="tablist" >
                                <li class="nav-item" role="presentation" >
                                    <button style={{ width: "auto", outline: "none" }} class={`nav-link ${active == 1 ? "active" : ""} `} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true" onClick={() => this.setState({ active: 1 })}>View Car Details</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button style={{ width: "auto", outline: "none" }} class={`nav-link ${active == 2 ? "active" : ""} `} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false" onClick={() => this.setState({ active: 2 })}>View Car Images</button>
                                </li>
                            </ul>
                            <br />
                            {active == 1 &&
                                <div className="row">
                                    {this.state.Model_data &&
                                        <table className="LeadTable">
                                            <thead>
                                                <tr class="LeadTable-head" style={{ background: "#42BD3B" }}>
                                                    <th class="column1">Type</th>
                                                    <th class="column2">Data</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {Model_data.id && <tr>
                                                    <td class="column1">ID </td>
                                                    <td class="column2"> {Model_data.id} </td>
                                                </tr>}
                                                {Model_data.enq_id && <tr>
                                                    <td class="column1">Car ID </td>
                                                    <td class="column2"> {Model_data.enq_id} </td>
                                                </tr>}
                                                {Model_data.make_year && <tr>
                                                    <td class="column1">Year </td>
                                                    <td class="column2"> {Model_data.make_year} </td>
                                                </tr>}
                                                {Model_data.makename && <tr>
                                                    <td class="column1">Make </td>
                                                    <td class="column2"> {Model_data.makename} </td>
                                                </tr>}
                                                {Model_data.modelname && <tr>
                                                    <td class="column1">Model </td>
                                                    <td class="column2"> {Model_data.modelname} </td>
                                                </tr>}
                                                {Model_data.ColourName && <tr>
                                                    <td class="column1">Color </td>
                                                    <td class="column2"> {Model_data.ColourName} </td>
                                                </tr>}
                                                {Model_data.variantname && <tr>
                                                    <td class="column1">Variant </td>
                                                    <td class="column2"> {Model_data.variantname} </td>
                                                </tr>}

                                                {Model_data.LocationName && <tr>
                                                    <td class="column1">Location </td>
                                                    <td class="column2"> {Model_data.LocationName} </td>
                                                </tr>}
                                                {Model_data.submitted_by && <tr>
                                                    <td class="column1">Source </td>
                                                    <td class="column2"> {Model_data.submitted_by} </td>
                                                </tr>}
                                                {Model_data.isImage && <tr>
                                                    <td class="column1">Image </td>
                                                    <td class="column2"> {Model_data.isImage} </td>
                                                </tr>}
                                                {Model_data.price && <tr>
                                                    <td class="column1">Price </td>
                                                    <td class="column2"> {Model_data.price} </td>
                                                </tr>}
                                                {Model_data.fueloption && <tr>
                                                    <td class="column1">Fuel </td>
                                                    <td class="column2"> {Model_data.fueloption} </td>
                                                </tr>}
                                                {Model_data.kms && <tr>
                                                    <td class="column1">Kilometer </td>
                                                    <td class="column2"> {Model_data.kms} </td>
                                                </tr>}
                                                {Model_data.owner_type && <tr>
                                                    <td class="column1">Owner </td>
                                                    <td class="column2"> {Model_data.owner_type} </td>
                                                </tr>}
                                                {Model_data.RTO && <tr>
                                                    <td class="column1">RTO </td>
                                                    <td class="column2"> {Model_data.RTO} </td>
                                                </tr>}
                                                {Model_data.Reg_no && <tr>
                                                    <td class="column1">Register no </td>
                                                    <td class="column2"> {Model_data.Reg_no} </td>
                                                </tr>}

                                            </tbody>
                                        </table>
                                    }
                                </div>
                            }
                            {active == 2 &&
                                <div className="row">
                                    {imagecontent && imagecontent.length ? imagecontent :
                                        <h3 className='col text-center' style={{ color: "red", textAlign: "center" }}>No Images Found</h3>}
                                </div>
                            }


                        </div>
                    } />
                <main className="main my-4">
                    <main className="container-fluid">

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h1>Home </h1>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-7">

                                        </div>
                                        <div className="col-sm-3" />
                                    </div>
                                    <div className="card-body">
                                        {this.state.loading ? <div className="text-center"> <i
                                            className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                        ></i></div> : fullData && fullData.length ? (
                                            <Datatable data={fullData} columnHeading={this.state.column} />
                                        ) : <div className="text-center">No data Found</div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </main>
            </>
        );
    }
}

export default SiteHome;