
import React from "react";
import { SingleSelect, MultiSelect } from "../../components/Form";
import cmsContent from "../../MiddleWare/CmsContent";
import { Alert } from "reactstrap";
import { Datatable } from "../../components/Datatable";
import { FormMiddleWare } from "../../components/Form";
import { confirmAlert } from "react-confirm-alert";

class CourseMappingToUser extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            userList: [],
            User: [],
            errorUser: '',
            checked: false,
            customerId: localStorage.getItem("userId"),
            alertVisible1: false,
            btnDisable: false,
            screen: window.screen.height,
            course: null,
            errorCourse: '',
            Courselist: [],
            Checked: false,
            isEdit: false,
            editId: ''
        };
    }

    async componentDidMount() {
        this.MainFun()
    }

    MainFun = async () => {
        try {
            this.setState({ loading: true })
            const Usertypes = await cmsContent.getFreedom(
                "id as value,usertype as label",
                "tbl_UserType",
                `customerid = ${this.state.customerId} and serviceid = 8`,
                "id",
                "id DESC"
            );
            if (Usertypes) {
                this.setState({ UserOption: Usertypes.data });
            }

            const Courselist = await cmsContent.getFreedom(
                "tbl_pages.name as label,tbl_pages.id as value",
                "tbl_pages,tbl_mapping_page",
                `tbl_pages.customerId=${this.state.customerId} and tbl_pages.themeId=3`,
                "tbl_pages.id",
                "tbl_pages.id DESC"
            );
            if (Courselist) {
                this.setState({ Courselist: Courselist.data });
            }

            const userList = await cmsContent.getFreedom(
                "tbl_user_web.*, tbl_user_web.id AS value, tbl_user_web.userName AS label",
                "tbl_user_web",
                `tbl_user_web.customerId = ${this.state.customerId}`,
                "tbl_user_web.id",
                "tbl_user_web.id DESC"
            )
            if (userList) {
                this.setState({ userList: userList.data });
            }

            const data = await cmsContent.getFreedom(
                "tbl_course_mapping.*, tbl_pages.name AS pageName",
                "tbl_course_mapping, tbl_pages",
                `tbl_course_mapping.customerId=${this.state.customerId} AND tbl_course_mapping.status = 1 AND tbl_course_mapping.pageId = tbl_pages.id`,
                "tbl_course_mapping.id",
                "tbl_course_mapping.id DESC"
            )
            if (data) {
                let result = data.data
                for (let ival of result) {
                    ival.userCount = (JSON.parse(ival.mappedUsers).length ? JSON.parse(ival.mappedUsers).length : 0) + " " + "users";
                }
                this.setState({ data: result });
            }
            this.setState({ loading: false })

        } catch (error) {
            console.log(error);
        }
    }

    column = [
        {
            Header: "Course",
            accessor: "pageName"
        },
        {
            Header: "Users",
            accessor: "userCount",
        },
        {
            Header: "Edit",
            accessor: "Edit",
            Cell: (d) => this.Button(d, "Edit"),
        },
        {
            Header: "Delete",
            Cell: (d) => this.Button(d, "Delete"),
        },


    ];

    Button = (data, buttonName) => {
        return (
            <center>
                <button
                    className={`btn ${buttonName === "Edit" ? "btn-warning" : "btn-danger"}`}
                    style={{ width: "120px" }}
                    onClick={() => { this.buttonAction(data, buttonName) }}
                > {buttonName} </button>
            </center>
        )
    }

    buttonAction = (data, buttonName) => {
        var { Courselist, userList } = this.state;
        var tableData = data.original;
        console.log(tableData, 'tableData')
        if (buttonName === "Edit") {

            this.setState({ isEdit: true, editId: tableData.id });
            let course = Courselist.find((cval) => { return tableData.pageId == cval.value });
            let mappedUsers = tableData && tableData.mappedUsers ? JSON.parse(tableData.mappedUsers) : []
            if (mappedUsers.length > 0) {
                var User = [];
                mappedUsers.map((users) => {
                    userList.map((ival) => {
                        if (users == ival.id) {
                            User.push({ label: ival.userName, value: ival.id })
                        }
                    })
                })
            }
            let Checked = tableData.sendMail == 1 ? true : false
            this.setState({ course, User, Checked })

        } else if (buttonName == "Delete") {
            console.log(tableData, "tableData");
            try {
                let result = new Promise((resolve, reject) => {
                    let upload = cmsContent.deleteMaster("tbl_course_mapping", tableData.id)
                    if (upload) {
                        resolve(upload);
                    }
                })
                if (result) {
                    console.log(result, 'result')
                    this.MainFun()
                }
            } catch (error) {
                console.log(error, "error in deleting")
            }


        }
    }

    submitCategory = async () => {
        let { course, User, Checked, customerId, isEdit, editId } = this.state;

        if (!course) {
            this.setState({ errorCourse: "Please select course!" });
            return false;
        }
        if (User.length === 0) {
            this.setState({ errorUser: "Please select user!" });
            return false;
        }
        let mappedUsers = await User.map((ival) => {
            return ival.value
        })
        if (mappedUsers) {
            let insertObject = {
                pageId: course.value,
                mappedUsers: JSON.stringify(mappedUsers),
                customerId,
                sendMail: Checked ? 1 : 0
            }
            try {
                this.setState({ btnDisable: true })
                let result = (isEdit) ? await cmsContent.updateMaster("tbl_course_mapping", editId, insertObject)
                    : await cmsContent.addMaster("tbl_course_mapping", insertObject);

                if (result) {
                    this.setState({ alertVisible: true })
                    setTimeout(() => {
                        this.setState({
                            alertVisible: false,
                            User: [],
                            errorUser: '',
                            checked: false,
                            btnDisable: false,
                            course: null,
                            errorCourse: '',
                            Checked: false,
                            isEdit: false,
                            editId: ''
                        })
                    }, 1500);
                    this.MainFun();

                }
            } catch (error) {
                console.log(error);
            }
            return true;
        }
    };

    reset = () => {
        this.setState({
            alertVisible: false,
            User: [],
            errorUser: '',
            checked: false,
            btnDisable: false,
            course: null,
            errorCourse: '',
            Checked: false,
            isEdit: false,
            editId: ''
        })
    }

    handleCheck = (name, selectedOption) => {
        this.setState({
            [name]: selectedOption,
        });
    }

    render() {
        const { Courselist, userList } = this.state;

        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Map Courses to User</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color="success"
                                                    isOpen={this.state.alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    Added Successfully
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">Select Course</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <SingleSelect
                                                    options={Courselist}
                                                    handleChange={(e) => this.handleCheck('course', e)}
                                                    selectedService={this.state.course}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.errorCourse}
                                            </span>
                                        </div>

                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label for="exampleInputEmail1">Select User</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <MultiSelect
                                                    options={this.state.userList}
                                                    handleChange={(e) => this.handleCheck('User', e)}
                                                    selectedService={this.state.User}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">{this.state.errorUser}</span>
                                        </div>

                                        {this.state.User && this.state.User.filter(e => e.label == 'EMPLOYEE').length ?
                                            (<div>
                                                <div className="row form-group">
                                                    <div className="col-sm-2" />
                                                    <div className="col-sm-2">
                                                        <label for="exampleInputEmail1">Select Employee</label>
                                                    </div>
                                                    {this.state.csvUpdate ?
                                                        <div className="col-sm-5">
                                                            <input
                                                                type="file"
                                                                className="custom-file-input"
                                                                id="customFile"
                                                                accept='.csv'
                                                                // value={this.state.csv}
                                                                onChange={this.fileupload}
                                                            />
                                                            <label
                                                                className="custom-file-label"
                                                                htmlFor="customFile"
                                                            >
                                                                {this.state.file ? this.state.file.name : 'Choose File'}
                                                            </label>
                                                        </div>
                                                        : <div className="col-sm-5">
                                                            <MultiSelect
                                                                options={this.state.EmpOption}
                                                                handleChange={(e) => this.handleCheck('SelectedEmployee', e)}
                                                                selectedService={this.state.SelectedEmployee}
                                                            />
                                                        </div>}
                                                    <div className="col-sm-3" >
                                                        <input
                                                            type="checkbox"
                                                            name="Checked"
                                                            onChange={() => this.setState({ csvUpdate: !this.state.csvUpdate, file: null })}
                                                            checked={this.state.csvUpdate}
                                                        />
                                                        <label>{" "} &nbsp; CSV Upload</label>
                                                    </div>
                                                </div>
                                                {this.state.csvUpdate ? <div className="row form-group">
                                                    <div className="col-sm-4" />
                                                    <div className="col-md-5">
                                                        <a href="../../files/Employee.csv" download="Employee.csv">
                                                            download sample.csv
                                                        </a>
                                                    </div>
                                                </div> : null}
                                                <div className="row form-group">
                                                    <div className="col-sm-4" />
                                                    <span className="error-show ">{this.state.errorEmp}</span>
                                                </div>
                                            </div>) : null
                                        }
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {(!this.state.isEdit || !this.state.csvUpdate) ? null : 'Note: Old Mapped Employees will be unmapped'}
                                            </span>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.csvUpdate ? 'MODE: CSV UPLOAD' : null}</span>
                                        </div>
                                        {this.state.User && this.state.User.filter(e => e.label == 'EMPLOYEE').length ?
                                            (<><div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label for="exampleInputEmail1">Select Employee Rules</label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <SingleSelect
                                                        handleChange={(e) => this.handleSelect('Rulesvalue', e)}
                                                        options={this.state.Ruleslist}
                                                        selectedService={this.state.Rulesvalue}
                                                    />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>
                                                <div className="row form-group">
                                                    <div className="col-sm-4" />
                                                    <span className="error-show ">{this.state.errorLang}</span>
                                                </div></>) : null}
                                        {this.state.User && this.state.User.filter(e => e.label == 'DEALER').length ?
                                            (<><div className="row form-group">
                                                <div className="col-sm-2" />
                                                <div className="col-sm-2">
                                                    <label for="exampleInputEmail1">Select Dealer Rules</label>
                                                </div>
                                                <div className="col-sm-5">
                                                    <SingleSelect
                                                        handleChange={(e) => this.handleSelect('Rulesvalue1', e)}
                                                        options={this.state.Ruleslist}
                                                        selectedService={this.state.Rulesvalue1}
                                                    />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>
                                                <div className="row form-group">
                                                    <div className="col-sm-4" />
                                                    <span className="error-show ">{this.state.errorLang1}</span>
                                                </div></>) : null}

                                        <center style={{ display: 'flex', justifyContent: 'center' }}>
                                            <label for="exampleInputEmail1">Send mail to Mapped users</label>
                                            &nbsp; &nbsp; &nbsp;
                                            {/* <div> */}
                                            <input
                                                type="checkbox"
                                                name="Checked"
                                                onChange={() => this.setState({ Checked: !this.state.Checked })}
                                                checked={this.state.Checked}
                                            />
                                            {/* </div> */}
                                        </center>
                                        <br></br>
                                        <center style={{ display: 'flex', justifyContent: 'center' }}>
                                            <button
                                                type="button"
                                                className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
                                                style={{ width: "120px" }}
                                                disabled={this.state.btnDisable}
                                                onClick={this.submitCategory}
                                            >
                                                {this.state.btnDisable ? <i class="fa fa-circle-o-notch fa-spin"></i> : null}&nbsp;
                                                {this.state.isEdit ? 'Update' : 'Submit'}
                                            </button>
                                            &nbsp; &nbsp;
                                            <button
                                                type="button"
                                                className="btn btn-warning"
                                                onClick={this.reset}
                                                style={{ width: "120px" }}
                                            >
                                                Reset
                                            </button>

                                        </center>


                                        <br></br>
                                        {/* <span>
                                            Total of {Object.keys(this.state.data).length ?? 0 } records
                                        </span> */}
                                        <br></br>
                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                {this.state.loading ? <div className="text-center">
                                                    < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i>
                                                </div> : this.state.data ? (
                                                    <Datatable
                                                        data={this.state.data}
                                                        columnHeading={this.column}
                                                    />
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment >
        );
    }
}

export default CourseMappingToUser;