import React, { Component } from "react";
import Datatable from "../../../components/Datatable/Datatable";
import CmsContent from "../../../MiddleWare/CmsContent";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import Cmsvideolistpdf from "../../../components/PDF/cmsvideolist";

class VideoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      customerId: localStorage.getItem("userId"),
    };
  }

  async componentDidMount() {
    console.log("enter VideoList");
    const { data } = await CmsContent.videoList(this.state.customerId);
    if (data) {
      this.setState({
        data,
      });
      if (data.length > 0) {
        const header = ["SI.NO", "VIDEONAME", "DATE"];
        const excelHead = [
          { label: "VIDEONAME", key: "label" },
          { label: "DATE", key: "Date" },
        ];
        this.setState({
          excelHead,
          header,
          excel: data,
          title: "VIDEOLIST",
        });
      }
    }
  }

  column = [
    {
      Header: "Video Name",
      accessor: "label",
    },
    {
      Header: "Date",
      accessor: "Date",
      Cell: (d) => this.datechange(d.original),
    },
    {
      Header: "View",
      accessor: "label",
      Cell: (d) => this.data(d.original),
    },
  ];
  data = (d) => {
    return (
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open("vidoplay" + "/" + d.label.split("/")[2], "_blank")
        }
      >
        {"View"}
      </button>
    );
  };
  datechange = (d) => {
    //console.log(d.Date.split('-'))
    console.log(d);
    let date = d.Date;
    // let date =
    //   d.Date.split("-")[2] +
    //   "-" +
    //   d.Date.split("-")[1] +
    //   "-" +
    //   d.Date.split("-")[0];
    return <span> {date} </span>;
  };
  render() {
    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h1>Video Listing</h1>
                  </div>
                  <div className="card-body">
                    <br></br>
                    {this.state.excel && this.state.excel.length > 0 ? (
                      <PDFDownloadLink
                        style={{ color: "white" }}
                        document={
                          <Cmsvideolistpdf
                            title={this.state.title}
                            header={this.state.header}
                            data={this.state.excel}
                          />
                        }
                        fileName="Videolisting.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? "Loading document..." : "Download Pdf"
                        }
                        <button className="btn btn-warning">PDF</button>
                      </PDFDownloadLink>
                    ) : null}
                    {this.state.data && this.state.data.length ? (
                      <CSVLink
                        data={this.state.data}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          Excel
                        </button>
                      </CSVLink>
                    ) : null}
                    <br></br>
                    <br></br>
                    {this.state.data && (
                      <Datatable
                        data={this.state.data}
                        columnHeading={this.column}
                      />
                    )}
                    {/* <video width="400" controls>
  <source src="http://localhost:5000/superAdmin/file?fileurl=SDAS/Video/new_1588251415783.mp4" />
   Your browser does not support HTML video.
</video> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default VideoList;
