import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import MultiSelect from "../../../components/Form/MultiSelect";
import cmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import { Alert } from "reactstrap";
import { ACCESS_SAMPLE } from "../../../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import AddpagePDF from "../../../components/PDF/Addpagespdf";

//import Theme9 from './addPages/theme9';
import Datatable from "../../../components/Datatable/Datatable";
import { confirmAlert } from "react-confirm-alert";


class EmailMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertVisible: false,
      authorlist: [],
      checked: 0,
      customerId: localStorage.getItem("userId"),
      errorPageName: null,
      data: [],
      alertVisible1: false,
      btnDisable: false,
      formAlert: false,
      formBtn: false,
      themeSelected: "",
      screen: window.screen.height,
      danger: "btn btn-danger",
      success: "btn btn-success",
      home: "Home",
      set: "SetusHome",
      formAlertdelete: false,
      entitytypeSelected: "",
      entityOpt: [
      ],
      entityname: [],
      entityOptions: [],
      State: "",
    };
  }
  column = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "EMAIL ID",
      accessor: "email_id",
    },
    {
      Header: "EDIT",
      accessor: "edit",
      Cell: (d) => this.Editpages(d),
    },
    {
      Header: "DELETE",
      accessor: "delete",
      Cell: (d) => this.deletePages(d),
    },

  ];

  Editpages = (value) => {
    return (
      <button className="btn btn-info" onClick={() => this.onEdit(value)}>
        Edit
      </button>
    );
  };
  onEdit = (value) => {
    let d = value.original;
    console.log(d);
    let entity_id = JSON.parse(d.entity_id)
    let entitytype_id = JSON.parse(d.entitytype_id)
    if (entity_id !== null) {
      var selectEntityType = this.state.entityOpt.filter(ival => entitytype_id.some(jval => jval == ival.value))
    }
    if (entitytype_id !== null) {
      var selctedEntityName = this.state.entityname.filter(ival => entity_id.some(jval => jval == ival.value))
    }
    if(d.email_id){
      var state=d.email_id.split('(')
    }
    this.setState({
      entitytypeSelected: selectEntityType,
      entitySelected: selctedEntityName,
      State: state[0],
      editId: d.id,
      isEdit: true
    });
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  async componentDidMount() {
    this.Mainfun()
  }
  Mainfun = async () => {
    try {
      this.setState({ loading: true })
      const State = await cmsContent.getFreedom(
        "*",
        "psychol_email",
        1,
        "id",
        "id DESC"
      );
      if (State) {
        State.data.filter((ival,index)=>{
          if(ival.entity_id==null && ival.entitytype_id==null){
            ival.email_id=ival.email_id+"  (unmapped)"
          }
          else{
            ival.email_id=ival.email_id
          }
        })
        console.log(State.data,"statedata")
        this.setState({ data: State.data, loading: false });
      }
      const entity = await cmsContent.getFreedom(
        "id as value,entity_name as label",
        "psychol_entity",
        1,
        "id",
        "id DESC"
      );
      if (entity) {
        this.setState({ entityOpt: entity.data, loading: false, entityOptions: entity.data });
      }
      console.log(entity.data, "entity")
      const entityname = await cmsContent.getFreedom(
        "id as value,userName as label",
        "tbl_user_web",
        `customerId=${this.state.customerId} `,
        "id",
        "id"
      );
      if (entityname) {
        this.setState({ entityname: entityname.data, loading: false });
      }
    } catch (error) {
      console.log(error);
    }
  }
  handlechange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleMultiSelect = async (selectedOption, value) => {
    if (selectedOption && value == "1") {
      let selectedValue = "";
      selectedOption.map(values => (selectedValue += `${values.label},`));
      selectedValue = selectedValue.replace(/,\s*$/, "");
      await this.setState({
        entitySelected: selectedOption,
        email: selectedValue,
      });
    }
    if (selectedOption && value == "2") {
      let selectedValue = "";
      selectedOption.map(values => (selectedValue += `${values.label},`));
      selectedValue = selectedValue.replace(/,\s*$/, "");
      if (selectedOption.length == 1) {
        const school = await cmsContent.getFreedom(
          "id as value,userName as label",
          "tbl_user_web",
          `customerId=${this.state.customerId} AND entitytype=${selectedOption[0].value}`,
          "id",
          "id"
        );
        if (school) {
          this.setState({ entityOption: school.data });
        }
      }
      if (selectedOption.length > 1) {
        const school = await cmsContent.getFreedom(
          "id as value,userName as label",
          "tbl_user_web",
          `customerId=${this.state.customerId} AND entitytype=${selectedOption[0].value} or entitytype=${selectedOption[1].value}`,
          "id",
          "id"
        );
        if (school) {
          this.setState({ entityOption: school.data });
        }
      }
      await this.setState({
        entitytypeSelected: selectedOption,
        // email: selectedValue,
      });
    }
  }
  deletePages = (d) => {
    let value = d.original
    return (
      <div>
        <button className="btn btn-danger" onClick={() => this.delete(value)}>Delete</button>
      </div>
    )
  }
  delete = (d) => {
    console.log(d.id)
    confirmAlert({
      customUI: ({ onClose }) => {
        return <div style={{ textAlign: "center" }}>
          <br></br>
          <h1>Delete confirmation</h1>
          <h4>Are you sure you want to Delete this permanetly ?</h4><br />
          <button className="btn btn-primary" onClick={() => {
            this.confirmDeleteP(d.id)
            onClose();
          }}  >Yes</button> &nbsp;  &nbsp;
          <button className="btn btn-danger" onClick={onClose} >No</button>
        </div>
      }
    })
  }
  confirmDeleteP = async (d) => {
    try {
      const result = await cmsContent.deleteMaster(
        "psychol_email",
        d
      );
      if (result) {
        this.setState({ formAlertdelete: true });
        setTimeout(() => {
          this.setState({ formAlertdelete: false })
          window.location.reload()
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  submitCategory = async () => {
    const {
      State,
      isEdit, editId, entitytypeSelected, entitySelected
    } = this.state;
    let categoryArray = {}
    if (!State) {
      this.setState({ errorState: "Enter Email" });
      return false;
    }
    console.log(entitytypeSelected, entitySelected)
    if (entitySelected.length >= 1 && entitytypeSelected.length >= 1) {
      let typearray = []
      let entityarray = []
      entitytypeSelected.map((ival, index) => {
        typearray.push(ival.value)
      })
      entitySelected.map((ival, index) => {
        entityarray.push(ival.value)
      })
      console.log(typearray, entityarray)
      categoryArray.email_id = State.trim();
      categoryArray.entitytype_id = JSON.stringify(typearray);
      categoryArray.entity_id = JSON.stringify(entityarray);
      console.log(categoryArray, "categoryArray")
    }
    // console.log([...formData]);
    try {
      const result = isEdit ? await cmsContent.updateMaster(
        "psychol_email",
        editId,
        categoryArray
      ) : await cmsContent.addMaster(
        "psychol_email",
        categoryArray
      );
      if (result) {
        this.setState({
          State: "",
          errorState: '',
          btnDisable: false,
          alertVisible: true,
        });
        this.Mainfun()
        setTimeout(() => {
          this.setState({ alertVisible: false })
          window.location.reload()
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  async componentWillReceiveProps() {
    this.setState({ themeId: 0 });

  }

  render() {
    console.log(this.state.data);

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3>Add Email Id</h3>
                  </div>
                  <div className="card-body">
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-7">
                        <Alert
                          className="badge-content"
                          color="success"
                          isOpen={this.state.alertVisible}
                          toggle={this.onDismiss}
                        >
                          Email Added Successfully
                        </Alert>
                        <Alert
                          className="badge-content"
                          color="danger"
                          isOpen={this.state.formAlertdelete}
                          toggle={this.onDismiss}
                        >
                          Email Deleted Successfully
                        </Alert>
                      </div>
                      <div className="col-sm-3" />
                    </div>


                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">Email </label>
                      </div>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          id="pageName"
                          name="State"
                          placeholder="Enter Email"
                          value={this.state.State}
                          onChange={this.handlechange}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorState}
                      </span>
                    </div>
                    <br />
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">Entity Type </label>
                      </div>
                      <div className="col-sm-5">
                        <MultiSelect
                          // disabled={this.state.DCDisable}
                          options={this.state.entityOpt}
                          handleChange={(e) => this.handleMultiSelect(e, "2")}
                          selectedService={this.state.entitytypeSelected}
                          disabled={this.state.State == ""}
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                      </span>
                    </div>
                    <br />
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label htmlFor="exampleInputEmail1">Entity </label>
                      </div>
                      <div className="col-sm-5">
                        <MultiSelect
                          disabled={this.state.entitytypeSelected == ""}
                          options={this.state.entityOption}
                          handleChange={(e) => this.handleMultiSelect(e, "1")}
                          selectedService={this.state.entitySelected}

                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                      </span>
                    </div>
                    <br />
                    {/* <div className="row form-group mx-2">
                      <div className="col-sm-3" />
                      <div className="col-sm-2">
                        <label htmlFor="indicators">Entity Type</label>
                      </div>
                      <div className="col-sm-3 ">
                        <SingleSelect
                          handleChange={(d) => this.handleSelectChange(d, "entityForm")}
                          options={this.state.entityOpt}
                          selectedService={this.state.entityOptSelect}
                        // disabled={jval.value == 9 && is_managerSelect.value==1}
                        />                                                        </div>
                      <div className="col-sm-2" />
                    </div> */}

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2" />
                      <div className="col-sm-5">
                        <button
                          type="button"
                          className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
                          disabled={this.state.btnDisable}
                          onClick={this.submitCategory}
                        >
                          {this.state.isEdit ? 'Update' : 'Submit'}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <br />

                    <div className="row form-group">
                      <div className="col-sm-12">
                        {this.state.loading ? <div className="text-center"> <i
                          className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                        ></i></div> : this.state.data && this.state.data.length ? (
                          <Datatable
                            data={this.state.data}
                            columnHeading={this.column}
                          />
                        ) : <div className="text-center">No Data Found</div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default EmailMaster;
