import React, { Component } from 'react'
import { ACCESS_POINT } from '../../../../config';

import Progress from "../../../../components/Extra/Progress";
import http from "../../../../MiddleWare/httpMiddleWare";
import { Alert } from 'reactstrap';

export default class MappingLoader extends Component {
    state = {
        uploadPercentage: 0,
        alertVisible: false,
    }

    componentDidMount = async () => {
        try {
            window.onbeforeunload = (event) => {
                const e = event || window.event;
                // Cancel the event
                e.preventDefault();
                if (e) {
                    e.returnValue = ''; // Legacy method for cross browser support
                }
                return ''; // Legacy method for cross browser support
            };
            let array = localStorage.getItem('ClusterData')
            if (array) {
                let Arr1 = JSON.parse(array);
                let body = {}
                // body.clusterId = Arr1.clusterId;
                // body.clusterName = Arr1.clusterName;
                // body.courseId = Arr1.courseId
                body.customerid = Arr1.customerid
                body.logid = Arr1.logid
                // body.loop_video = Arr1.loop_video
                let id = Arr1.editId

                const result = await http.post(
                    // ACCESS_POINT + `/cmsContent/Coromandel/VidyaMapMaster/${id}/${Arr1.Checked}`,
                    // body,
                    ACCESS_POINT + `/cmsContent/Coromandel/VidyaClusterMail/${id}`,
                    body,
                    {
                        onUploadProgress: progressEvent => {
                            this.setState({
                                uploadPercentage: parseInt(
                                    Math.round((progressEvent.loaded * 100) / progressEvent.total)
                                )
                            });
                            setTimeout(() => this.setState({ uploadPercentage: 0 }), 10000);
                        }
                    }
                );
                if (result.status == 200) {
                    localStorage.removeItem("ClusterData");
                    this.setState({ alertVisible: true })
                    setTimeout(() => {
                        this.setState({ alertVisible: false })
                        window.close()
                    }, 3000);

                } else {
                    this.setState({ alertVisible: false })
                }
            }
        } catch (error) {
            console.log(error);
        }
    }
    render() {
        let { uploadPercentage } = this.state;
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Cluster Mapping</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color="success"
                                                    isOpen={this.state.alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    Added Successfully
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                {uploadPercentage > 0 && (
                                                    <Progress percentage={uploadPercentage} />
                                                )}
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}
