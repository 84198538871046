import React, { Component } from "react";
import { Bar, Pie } from "react-chartjs-2";
import CanvasJSReact from '@canvasjs/react-charts';
import CmsContent from "../../../MiddleWare/CmsContent";
import { SingleSelect } from "../../../components/Form";
import moment from "moment";
import { split } from "lodash";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
class PSYDashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            customerId: localStorage.getItem("userId"),
            barChartLabel: [],
            bardata: [],
            SchoolOpt: [],
            schoolData: [],
            traningData: [],
            fromdate: "",
            todate: "",
            fromdate1: "",
            todate1: "",
            fromdate2: "",
            todate2: "",
            rankSchoolData: "",
            rankfromdate: "",
            ranktodate: "",
            classData: "",
            classOption: [],
            studentOption: [],
            studentData: "",
            rankgraph: [],
            rankSchoolData2: "",
            rankfromdate2: "",
            ranktodate2: "",
            classData2: "",
            classOption2: [],
            studentOption2: [],
            studentData2: "",
            rankgraph2: [],
            dob: ""
        }
    }
    async componentDidMount() {

        this.Mainfun()

    }
    Mainfun = async () => {
        try {
            const today = moment()
            const oneMonthAgo = moment().subtract(1, 'months');
            const enddate = today.format("YYYY-MM-DD")
            const startdate = oneMonthAgo.format('YYYY-MM-DD')
            this.setState({ fromdate: startdate, fromdate1: startdate, fromdate2: startdate, todate: enddate, todate1: enddate, todate2: enddate, },
                async () => {
                    if (this.state.fromdate && this.state.todate) {
                        let obj = {
                            fromdate: this.state.fromdate,
                            todate: this.state.todate
                        }
                        const result1 = await CmsContent.GetChartDatas(this.state.customerId, "1", obj)
                        if (result1 && result1.length > 0) {
                            let labels = []
                            result1.map((a, b) => {
                                labels.push(a.label)
                            })
                            this.setState({ barChartLabel: labels, bardata: result1 })
                        }
                    }
                    if (this.state.fromdate1 && this.state.todate1) {
                        let obj = {
                            fromdate: this.state.fromdate1,
                            todate: this.state.todate1
                        }
                        const result = await CmsContent.GetChartDatas(this.state.customerId, "2", obj)
                        if (result && result.length > 0) {
                            this.setState({ data: result })
                        }
                    }
                }
            )
            const school = await CmsContent.getFreedom(
                "id as value, userName as label",
                "tbl_user_web",
                `customerId=${this.state.customerId}`,
                "id",
                "id"
            );
            if (school && school.data && school.data.length > 0) {
                this.setState({ SchoolOpt: school.data })
                this.setState({ schoolData: school.data[0] },
                    async () => {
                        if (startdate && enddate) {
                            let obj = {
                                fromdate: startdate,
                                todate: enddate
                            }
                            const result = await CmsContent.GetChartDatas(this.state.schoolData.value, "topic", obj)
                            if (result && result.length > 0) {
                                let topicarray = []
                                let topicArray = []
                                let result2 = result.map((a, b) => {
                                    let obj = {
                                        label: a.label.split(","),
                                        y: b
                                    }
                                    topicArray.push(obj)
                                })
                                let second = []
                                topicArray.map((a, b) => {
                                    a.label.map((ival, index) => {
                                        let obj = {
                                            label: ival,
                                            y: a.y
                                        }
                                        second.push(obj)
                                    })
                                })
                                let labelMap = new Map();

                                second.forEach(item => {
                                    if (!labelMap.has(item.label) || labelMap.get(item.label).y < item.y) {
                                        labelMap.set(item.label, item);
                                    }
                                });
                                let uniqueData = Array.from(labelMap.values());
                                this.setState({ traningData: uniqueData })
                            }
                        }
                    }
                )
                this.optionSelect(school.data[0])
            }
            const State = await CmsContent.getFreedom(
                "id as value, class_name as label",
                "psychol_class",
                1,
                "id",
                "id DESC"
            );
            if (State) {
                this.setState({ classOption: State.data, classOption2: State.data });
            }

        } catch (error) {
            console.log(error);
        }
    }
    catch(err) {
        console.log(err)
    }
    optionSelect = async (selectedOption) => {
        if (selectedOption) {
            this.setState({
                schoolData: selectedOption
            })

        }
    }

    optionSelect2 = async (selectedOption) => {
        if (selectedOption) {
            this.setState({
                rankSchoolData: selectedOption, classData: "", studentData: "", ranktodate: "", rankfromdate: ""
            })
        }
    }
    optionSelect3 = async (selectedOption) => {
        if (selectedOption) {
            this.setState({
                rankSchoolData2: selectedOption, classData2: "", studentData2: "", ranktodate2: "", rankfromdate2: "", dob: ""
            })
        }
    }
    studentoptionSelect = async (selectedOption) => {
        if (selectedOption) {
            this.setState({
                studentData: selectedOption, ranktodate: "", rankfromdate: ""
            })
        }
    }
    studentoptionSelect2 = async (selectedOption) => {
        if (selectedOption) {
            this.setState({
                studentData2: selectedOption, ranktodate2: "", rankfromdate2: "", dob: ""
            })
        }
    }
    classoptionSelect = async (selectedOption) => {
        if (selectedOption) {
            this.setState({
                classData: selectedOption, studentData: "", ranktodate: "", rankfromdate: ""
            })
            const student = await CmsContent.getFreedom(
                "studentid as value,name as label,dob as dob",
                "psychol_form",
                `customerId='${this.state.rankSchoolData.value}' AND class='${selectedOption.value}'`,
                "studentid",
                "id"
            );
            if (student && student.data.length > 0) {
                this.setState({ studentOption: student.data })
            }
            else {
                this.setState({ studentOption: [] })
            }
        }
    }
    classoptionSelect2 = async (selectedOption) => {
        if (selectedOption) {
            this.setState({
                classData2: selectedOption, studentData2: "", ranktodate2: "", rankfromdate2: "", dob: ""
            })
            const student = await CmsContent.getFreedom(
                "studentid as value,name as label",
                "psychol_form",
                `customerId='${this.state.rankSchoolData2.value}'AND class='${selectedOption.value}'`,
                "name,dob",
                "id"
            );
            if (student && student.data.length > 0) {
                this.setState({ studentOption2: student.data })
            }
            else {
                this.setState({ studentOption2: [] })
            }
        }
    }
    handleChange = (e) => {
        const { name, value } = e.target
        const { SchoolOpt, schoolData, traningData, fromdate, todate, studentData } = this.state
        this.setState({ [name]: value },
            async () => {
                if (this.state.fromdate && this.state.todate) {
                    let obj = {
                        fromdate: this.state.fromdate,
                        todate: this.state.todate
                    }
                    const result1 = await CmsContent.GetChartDatas(this.state.customerId, "1", obj)
                    let labels = []
                    result1.map((a, b) => {
                        labels.push(a.label)
                    })
                    this.setState({ barChartLabel: labels, bardata: result1 })
                }
                if (this.state.fromdate1 && this.state.todate1) {
                    let obj = {
                        fromdate: this.state.fromdate1,
                        todate: this.state.todate1
                    }
                    const result = await CmsContent.GetChartDatas(this.state.customerId, "2", obj)
                    this.setState({ data: result })
                }
                if (this.state.fromdate2 && this.state.todate2) {
                    let obj = {
                        fromdate: this.state.fromdate2,
                        todate: this.state.todate2
                    }
                    const result = await CmsContent.GetChartDatas(schoolData.value, "topic", obj)
                     if (result && result.length > 0) {
                        let topicarray = []
                        let topicArray = []
                        let result2 = result.map((a, b) => {
                            let obj = {
                                label: a.label.split(","),
                                y: b
                            }
                            topicArray.push(obj)
                        })
                        let second = []
                        topicArray.map((a, b) => {
                            a.label.map((ival, index) => {
                                let obj = {
                                    label: ival,
                                    y: b
                                }
                                second.push(obj)
                            })
                        })

                        let labelMap = new Map();

                        second.forEach(item => {
                            if (!labelMap.has(item.label) || labelMap.get(item.label).y < item.y) {
                                labelMap.set(item.label, item);
                            }
                        });
                        let uniqueData = Array.from(labelMap.values());
                        this.setState({ traningData: uniqueData })
                    }
                }
                // if (this.state.rankfromdate && this.state.ranktodate) {
                //     // console.log(this.state.studentData.value, "data")
                //     const start = this.state.rankfromdate
                //     const end = this.state.ranktodate
                //     const student = await CmsContent.getFreedom(
                //         "date as x,studentRank as y",
                //         "psychol_form",
                //         `customerId=${this.state.rankSchoolData.value} AND class=${this.state.classData.value} AND studentid=${this.state.studentData.value} AND DATE(date) BETWEEN DATE('${start}') AND DATE('${end}')`,
                //         "id",
                //         "id"
                //     );
                //     if (student && student.data.length > 0) {
                //         let array = []
                //         student.data.map((a, b) => {
                //             let datestr = a.x
                //             const [year, month, day] = datestr.split("-")
                //             let obj = {
                //                 x: new Date(year, month - 1, day),
                //                 y: parseInt(a.y)
                //             }
                //             array.push(obj)
                //         })
                //         this.setState({ rankgraph: array })
                //     }
                // }
                if (this.state.rankfromdate2 && this.state.ranktodate2) {
                    const start = this.state.rankfromdate2
                    let DOB = moment(this.state.dob).format('DD-MM-YYYY');
                    const end = this.state.ranktodate2
                    const student = await CmsContent.getFreedom(
                        "date as x,studentRank as y",
                        "psychol_form",
                        `customerId=${this.state.rankSchoolData2.value} AND class=${this.state.classData2.value}  AND type='1' AND   counsellingType='1' AND dob='${DOB}' AND DATE(date) BETWEEN DATE('${start}') AND DATE('${end}')`,
                        "id",
                        "id"
                    );
                    if (student && student.data.length > 0) {
                        let array = []
                        student.data.map((a, b) => {
                            let datestr = a.x
                            const [year, month, day] = datestr.split("-")
                            let obj = {
                                x: new Date(year, month - 1, day),
                                y: parseInt(a.y)
                            }
                            array.push(obj)
                        })
                        this.setState({ rankgraph2: array })
                    }
                }
            }
        )
    }
    render() {
        const options = {
            title: {
                text: ""
            },
            data: [
                {
                    type: "column",
                    dataPoints: this.state.bardata
                }
            ]
        }
        const pieoptions = {
            animationEnabled: true,
            exportEnabled: true,
            theme: "light1", // "light1", "dark1", "dark2"
            title: {
                text: ""
            },
            data: [{
                type: "pie",
                indexLabel: "{label}: {y}%",
                startAngle: -90,
                dataPoints: this.state.data
            }]
        }
        const graphoptions = {
            title: {
                text: ""
            },
            data: [
                {
                    // Change type to "doughnut", "line", "splineArea", etc.
                    type: "column",
                    dataPoints: this.state.traningData

                }
            ]
        }
        const rankoptions = {
            theme: "light2",
            animationEnabled: true,
            exportEnabled: true,
            title: {
                text: ""
            },
            axisY: {
                title: "Rank"
            },
            data: [
                {
                    type: "area",
                    xValueFormatString: "MMM",
                    dataPoints: this.state.rankgraph
                }
            ]
        }
        const rankoptions2 = {
            theme: "light2",
            animationEnabled: true,
            exportEnabled: true,
            title: {
                text: ""
            },
            axisY: {
                title: "Rank"
            },
            data: [
                {
                    type: "area",
                    xValueFormatString: "MMM",
                    dataPoints: this.state.rankgraph2,
                    toolTipContent: "Month: {x}, Rank: {y}",

                }
            ]
        }
        const { SchoolOpt, schoolData, traningData, fromdate, todate, fromdate1, todate1, fromdate2, todate2, bardata, data, rankfromdate, ranktodate, rankfromdate2, ranktodate2, } = this.state
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Content Management System</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-gorup py-2">
                                            <div className="col-sm-3" />
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select From date</label>
                                                <input
                                                    type="date"
                                                    id="myDate"
                                                    name="fromdate"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={fromdate}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select To date</label>
                                                <input
                                                    type="date"
                                                    id="myDate"
                                                    name="todate"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={this.state.todate}
                                                    disabled={fromdate == ""}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group ">
                                            <div className="col-sm-12">
                                                <div className="text-center">
                                                    <h1>Total number of students counseling per school</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row form-group py-2">
                                            <div className="col-sm-12">

                                                <CanvasJSChart options={options}
                                                />
                                            </div>
                                        </div>
                                        <div className="row form-gorup py-2">
                                            <div className="col-sm-3" />
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select From date</label>
                                                <input
                                                    type="date"
                                                    id="myDate"
                                                    name="fromdate1"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={fromdate1}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select To date</label>
                                                <input
                                                    type="date"
                                                    id="myDate"
                                                    name="todate1"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={todate1}
                                                    disabled={fromdate1 == ""}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group py-3">
                                            <div className="col-sm-12">
                                                <div className="">
                                                    <h1>Total number of students training per school</h1>
                                                </div>
                                                <div className="py-2">

                                                    <CanvasJSChart options={pieoptions}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                <div className="">
                                                    <h1>Training topics covered by the school</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row form-group py-2">
                                            <div className="col-sm-3" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select School</label>
                                            </div>
                                            <div className="col-sm-4">
                                                <SingleSelect
                                                    options={SchoolOpt}
                                                    handleChange={(e) => this.optionSelect(e)}
                                                    selectedService={schoolData}
                                                />
                                            </div>
                                        </div>
                                        <div className="row form-gorup py-2">
                                            <div className="col-sm-3" />
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select From date</label>
                                                <input
                                                    type="date"
                                                    id="myDate"
                                                    name="fromdate2"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={fromdate2}
                                                />
                                            </div>
                                            <div className="col-sm-3">
                                                <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select To date</label>
                                                <input
                                                    type="date"
                                                    id="myDate"
                                                    name="todate2"
                                                    className="form-control"
                                                    onChange={this.handleChange}
                                                    value={todate2}
                                                    disabled={fromdate2 == ""}
                                                />
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group py-2">
                                            <div className="col-sm-12">

                                                <CanvasJSChart options={graphoptions}
                                                />
                                            </div>
                                        </div>
                                        {/* <div>
                                            <div className="row form-group py-2">
                                                <div className="col-sm-12">
                                                    <div className="">
                                                        <h1>Students rank based on the school and classes</h1>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row form-group py-2">
                                                <div className="col-sm-3" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select School</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <SingleSelect
                                                        options={SchoolOpt}
                                                        handleChange={(e) => this.optionSelect2(e)}
                                                        selectedService={this.state.rankSchoolData}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-group py-2">
                                                <div className="col-sm-3" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select Class</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <SingleSelect
                                                        options={this.state.classOption}
                                                        handleChange={(e) => this.classoptionSelect(e)}
                                                        selectedService={this.state.classData}
                                                        disabled={this.state.rankSchoolData===""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-group py-2">
                                                <div className="col-sm-3" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select Student</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <SingleSelect
                                                        options={this.state.studentOption}
                                                        handleChange={(e) => this.studentoptionSelect(e)}
                                                        selectedService={this.state.studentData}
                                                        disabled={this.state.classData===""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-gorup py-2">
                                                <div className="col-sm-3" />
                                                <div className="col-sm-3">
                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select From date</label>
                                                    <input
                                                        type="date"
                                                        id="myDate"
                                                        name="rankfromdate"
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                        value={rankfromdate}
                                                        disabled={this.state.studentData===""}
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select To date</label>
                                                    <input
                                                        type="date"
                                                        id="myDate"
                                                        name="ranktodate"
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                        value={ranktodate}
                                                        disabled={this.state.rankfromdate===""}
                                                    />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>
                                            <div className="row form-group py-2">
                                                <CanvasJSChart options={rankoptions}
                                                />
                                            </div>
                                        </div> */}
                                        <div className="">
                                            <div className="row form-group py-2">
                                                <div className="col-sm-12">
                                                    <div className="">
                                                        <h1>Individual students rank chart</h1>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="row form-group py-2">
                                                <div className="col-sm-3" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select School</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <SingleSelect
                                                        options={SchoolOpt}
                                                        handleChange={(e) => this.optionSelect3(e)}
                                                        selectedService={this.state.rankSchoolData2}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-group py-2">
                                                <div className="col-sm-3" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select Class</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <SingleSelect
                                                        options={this.state.classOption2}
                                                        handleChange={(e) => this.classoptionSelect2(e)}
                                                        selectedService={this.state.classData2}
                                                        disabled={this.state.rankSchoolData2 === ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-group py-2">
                                                <div className="col-sm-3" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select Student</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <SingleSelect
                                                        options={this.state.studentOption2}
                                                        handleChange={(e) => this.studentoptionSelect2(e)}
                                                        selectedService={this.state.studentData2}
                                                        disabled={this.state.classData2 === ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-group py-2">
                                                <div className="col-sm-3" />
                                                <div className="col-sm-2">
                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select DOB</label>
                                                </div>
                                                <div className="col-sm-4">
                                                    <input
                                                        type="date"
                                                        id="myDate"
                                                        name="dob"
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                        value={this.state.dob}
                                                        disabled={this.state.classData2 === ""}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row form-gorup py-2">
                                                <div className="col-sm-3" />
                                                <div className="col-sm-3">
                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select From date</label>
                                                    <input
                                                        type="date"
                                                        id="myDate"
                                                        name="rankfromdate2"
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                        value={rankfromdate2}
                                                        disabled={this.state.dob === ""}
                                                    />
                                                </div>
                                                <div className="col-sm-3">
                                                    <label htmlFor="exampleInputEmail1"><span style={{ color: 'red' }}>*</span>&nbsp;Select To date</label>
                                                    <input
                                                        type="date"
                                                        id="myDate"
                                                        name="ranktodate2"
                                                        className="form-control"
                                                        onChange={this.handleChange}
                                                        value={ranktodate2}
                                                        disabled={this.state.rankfromdate2 === ""}
                                                    />
                                                </div>
                                                <div className="col-sm-3" />
                                            </div>
                                            <div className="row form-group py-2">
                                                <CanvasJSChart options={rankoptions2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        )
    }
}

export default PSYDashboard;