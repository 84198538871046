import React, { Component } from 'react';
import CmsContent from '../../MiddleWare/CmsContent';
import DownPanel from './DownPanel';
import RightPanel from './RightPanel';
import { Link } from 'react-router-dom';
import { ACCESS_POINT } from '../../config';
import renderHTML from 'react-render-html';

class ListingMore extends Component {
  constructor(props) {
    super(props);
    this.state = {result:[]}
  }
  async componentWillMount() {
    const { match: { params } } = this.props;
      let id = atob(params["id"]);
    try {
      let result = await CmsContent.getFreedom(
        'tbl_pages.thumbnail,tbl_mapping_page.categoryName,tbl_mapping_page.subCategoryName,tbl_mapping_page.pageId,theme_master.themeName,tbl_pages.content1',
         'tbl_mapping_page,tbl_pages,theme_master',
         `tbl_mapping_page.pageId=tbl_pages.id and tbl_mapping_page.status='active' and  tbl_pages.status='show' and theme_master.themeId=tbl_pages.themeId and  tbl_mapping_page.categoryId=${id}`,
         'tbl_pages.id',
         'tbl_pages.id');
         if(result){
           this.setState({ result:result.data});
         }
    } catch (error) {
      console.error(error);
    }
  }
  content=(result)=>{
    return(
    <div className="card">
      <div className="card-body">
        <div className="row form-group">
          <h2 className="theme-tiile text-capitalize">{result[0].categoryName}</h2>
        </div>

        <div className="row form-group col-sm-12">
          {result.map((ival,i)=>{
            return(
              <div className="col-sm-6">
              <div className="card" style={{padding:5}}>
            <div className="row">
              <div className="col-sm-5">
                <Link to={`/CMS/${ival.themeName}/${ival.pageId}`}>
                <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${ival.thumbnail}`} alt="thumbnail" style={{width: 210,height: 115}} />
                </Link>
              </div>
              <div className="col-sm-7" style={{paddingLeft:0,paddingRight:10}}>
                <Link  to={`/CMS/${ival.themeName}/${ival.pageId}`}>
                  {ival.subCategoryName.length > 40 ? ival.subCategoryName.substr(0, 40)+'...' :ival.subCategoryName}
                </Link>
                {ival.content1 && <p>{renderHTML(ival.content1.length > 80 ? ival.content1.substr(0, 80)+'...' : ival.content1 )}</p>}
              </div>
            </div>
          </div>
        </div>
              )
          })}
        </div>
      </div>
    </div>)
  }
  render() {
    let {result}=this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-margin-top">
          <div className="row form-group">
            <div className="col-sm-9">
            {result.length ? this.content(result):null}
            </div>
            <div className="col-sm-3">
              <div className="card">
                <div className="card-body">
                  <RightPanel />
                </div>
              </div>
            </div>
          <div className="row form-group">
            <div className="col-sm-12 mb-new">
          </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ListingMore;