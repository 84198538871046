import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { element } from 'prop-types';

const SurveyRpPdf = ({ header, data,title,User,score,ques,pg}) => {
  
    var username=User;
    var count=0;
    var x = score.length;
    if(pg==0){
      title="USER_DETAILS";
    }else{
      title=username+"_"+"QUIZ_DETAILS";
    }
   

   
    data.map((item,index)=>{
       //let count=0;
        score.map((element,i)=>{
         
            count=count+item.mark;
            
        })
        
          })
  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.title}>
         <Text>{title}</Text>
        </View>

        <View style={styles.table}>
        <View style={styles.tableRow}>
            {header &&
              header.map(value => {
                  if(pg==0){
                return (
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell }>{value}</Text>
                  </View>
                );
                  }
                  else{
                    return (
                        <View style={styles.tableCol}>
                          <Text style={styles.tableCell}>{value}</Text>
                        </View>
                      );

                  }
              })}
          </View>  

          {data &&
            data.map((value,index) => {
              var dt =new Date(value.date);
              var d=dt.getFullYear();
              var d1=dt.getMonth()+1;
              var d3=dt.getDate();
              var d4=d+"-"+d1+"-"+d3;
               if(pg==0){
              return (
              
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{index+1}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{username}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{ques.length}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{count}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{x/ques.length}</Text>
                  </View>
                  
                </View>
              );
               }
               else{
                   return(
                   
                    <View style={styles.tableRow}>
                
                  <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{index+1}</Text>
                  </View>
                 
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.question}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.answer}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{value.mark}</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{d4}</Text>
                  </View>
                  
                </View>




                   )
               }
            })}
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    padding: 10
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
    textAlign:"center"
  },
  tableColHeader: {
    marginTop:'5px',
    width: '16.6%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol: {
    width: '20%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCol1: {
    width: '25%',
    borderStyle: 'solid',
    borderColor: '#bfbfbf',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCellHeader: {
    margin: 'auto',
    margin: 5,
    fontSize: 12,
    fontWeight: 500
  },
  tableCell: {
    margin: 'auto',
    margin: 5,
    fontSize: 10
  },
  title:{
    fontSize:19,
    fontWeight:"bold",
    marginLeft:'38%',
    marginBottom:'2px'
  
    }
});

export default SurveyRpPdf;
