import React, { Component } from "react";
import renderHTML from "react-render-html";
import DownPanel from "./DownPanel";
import { ACCESS_POINT } from "../../config";
import ScriptTag from "react-script-tag";



const style = `<style>
table {
border-collapse: collapse;
width: auto%;
}
td, th {
border: 1px solid #dddddd;
text-align: left;
padding: 8px;
}
body {
  font-family: Verdana, sans-serif;
  margin: 0;
}
* {
  box-sizing: border-box;
}
.row > .column {
  padding: 0 8px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.column {
  float: left;
  width: 20%;
  margin: auto;
  margin-left: 5px;
}
/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
}
/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}
.mySlides {
  display: none;
}
.cursor {
  cursor: pointer;
}
/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}
/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}
.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}
.demo {
  opacity: 0.6;
}
.active,
.demo:hover {
  opacity: 1;
}
img.hover-shadow {
  transition: 0.3s;
}
.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>`;
class SingleContent1 extends Component {
  state = {
    show: false,
    title: 'Heading',
    // tabTitle: ['Maintenance Function', 'Value Chain'],
    tabTitle: ['Maintenance Function'],
    tabContent: [
      `
      ${style}
       <br/>
      <table>
         <tr>
            <td ></td>
            <td >Function: Tool Room</td>
            <td colspan="4">Reason(s)</td>
            <td colspan="2">Risk </td>
            <td colspan="3">SafeStart </td>
            <td>Training/ Process Change/ Policy </td>
            <td colspan="2">Action Plan</td>
          </tr>
          <tr >
            <td>S.No.</td>
            <td>Observations </td>
            <td >People</td>
            <td >Process </td>
            <td >Policy </td>
            <td >Equipment</td>
            <td >Frequency of errors</td>
            <td >Risk Involved (H/M/L) </td>
            <td>State </td>
            <td >Error </td>
            <td >CERT </td>
            <td>Actions (if change has to happen, what specific) </td>
            <td>Start When</td>
            <td >Review </td>
             </tr>
             <tr >
            <td>1</td>
            <td>While roll polishing mandrel with roll released from tailstock  due to over load and fell down on operator Leg</td>
            <td >yes</td>
            <td No</td>
            <td >No</td>
            <td >No</td>
            <td >1</td>
            <td >M</td>
            <td >Rushing/  Complacency</td>
            <td >Eyes not on task, Mind not on task and Line of fire</td>
            <td >Self trigger/ Work on habits</td>
            <td >Engineering control, Trainining Provided</td>
            <td>Completed</td>
            <td >Completed</td>
          </tr>
           <tr >
            <td >2</td>
            <td>when doing Die and plug polishing operator didn't lock the chuck guard , it may lead injury</td>
            <td >yes</td>
            <td >No</td>
            <td >No</td>
            <td >No</td>
            <td>2 out of 6</td>
            <td >M</td>
            <td >Rushing/Complacency</td>
            <td >Eyes not on task, Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>&nbsp;Trainining to be Provided</td>
            <td>Dec-19</td>
            <td>Jan-20</td>
          </tr>
           <tr >
            <td>3</td>
            <td>Removing Burr from blades operator didn't wear ppe</td>
            <td>Yes</td>
            <td>No</td>
            <td>No</td>
            <td>No</td>
            <td>1 out  of 5</td>
            <td>L</td>
            <td>Complacency</td>
            <td>Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td>&nbsp;Trainining to be Provided</td>
            <td>Dec-19</td>
            <td>Jan-20</td>
           </tr>
            <tr >
            <td>4</td>
            <td>While grinding wheel dressing, found machine door in open condition</td>
            <td >yes</td>
            <td >No</td>
            <td >No</td>
            <td >No</td>
            <td >1 out of 10</td>
            <td >L</td>
            <td >Complacency</td>
            <td>&nbsp;Mind not on task</td>
            <td>Self trigger/ Work on habits</td>
            <td >&nbsp;Trainining  Provided</td>
            <td>Dec-19</td>
            <td >Jan-20</td>
            <td >&nbsp;</td>
          </tr>
           <tr >
            <td >5</td>
            <td>operator lift rools by hand insted of crane loading</td>
            <td >Yes</td>
            <td >No</td>
            <td >No</td>
            <td >No</td>
            <td >2 out of 5</td>
            <td >M</td>
            <td >Complacency</td>
            <td >&nbsp;Mind not on task and Line of fire/Balance</td>
            <td >Self trigger/ Work on habits</td>
            <td >&nbsp;Trainining to be Provided</td>
            <td >Dec-19</td>
            <td >Jan-20</td>
            <td>&nbsp;</td>
          </tr>
          <tr>
            <td >6</td>
            <td >when  plug rod change, operatot climb on machine</td>
            <td >No</td>
            <td >No</td>
            <td >No</td>
            <td >Yes</td>
            <td >every time</td>
            <td >H</td>
            <td ></td>
            <td >Line of fire /Balance</td>
            <td >work on Habits</td>
            <td >Engineering  method</td>
            <td >Jan-19</td>
            <td >Feb-20</td>
          </tr>
          <tr>
            <td >7</td>
            <td >when thread cutting in lathe machine operator found without safety goggless.</td>
            <td>Yes</td>
            <td>No</td>
            <td >No</td>
            <td >No</td>
            <td >1 out of 5</td>
            <td >L</td>
            <td >Complacency</td>
            <td >&nbsp;Mind not on task</td>
            <td >Self trigger/ Work on habits</td>
            <td >&nbsp;Trainining to be Provided</td>
            <td >Jan-19</td>
            <td >Feb-20</td>
          </tr>
          <tr >
            <td >8</td>
            <td>while using air grinder  / air gun operator found without safety goggles</td>
            <td>Yes</td>
            <td >No</td>
            <td >No</td>
            <td >No</td>
            <td >1 out of 5</td>
            <td>L</td>
            <td>Rushing/  Complacency</td>
            <td>Mind not on task</td>
            <td >Self trigger/ Work on habits</td>
            <td >&nbsp;Trainining to be Provided</td>
            <td >Jan-19</td>
            <td >Feb-20</td>
          </tr>
          <tr >
            <td>9</td>
            <td>when using kerosine doing tool cleaning operator found without Nose masc</td>
            <td >Yes</td>
            <td >No</td>
            <td >No</td>
            <td>No</td>
            <td >1 of 6</td>
            <td >L</td>
            <td >Complacency</td>
            <td >Mind not on task</td>
            <td >Self trigger/ Work on habits</td>
            <td >&nbsp;Trainining to be Provided</td>
            <td >Jan-19</td>
            <td >Feb-20</td>
          </tr>



       </table>
       <br/><br/>

       <br/>
       <table>
       <tr >
       <td ></td>
       <td >Function: Maintenance</td>
       <td colspan="4">Reason(s)</td>
       <td colspan="2">Risk </td>
       <td colspan="3">SafeStart </td>
       <td >Training/ Process Change/ Policy </td>
       <td colspan="2">Action Plan</td>
       </tr>
       <tr>
       <td>S.No.</td>
       <td>Observations </td>
       <td>People</td>
       <td >Process </td>
       <td >Policy </td>
       <td>Equipment</td>
       <td>Frequency of errors</td>
       <td>Risk Involved (H/M/L) </td>
       <td >State </td>
       <td >Error </td>
       <td >CERT </td>
       <td>Actions (if change has to happen, what specific) </td>
       <td >Start When</td>
       <td >Review </td>
       </tr>
       <tr>
       <td >1</td>
       <td >While climbing down from ladder not facing the ladder step leads single point contact chance of fell down</td>
       <td >Yes</td>
       <td >No</td>
       <td>No</td>
       <td>No</td>
       <td>2 out of 5</td>
       <td >M</td>
       <td >&nbsp;&nbsp;Complacency</td>
       <td >Mind not on task/ Balance, tarction grip</td>
       <td >Self trigger/ Work on habits</td>
       <td>Work on habits - Use three points of contact when ascending or descending</td>
       <td>12/19</td>
       <td>01/20</td>
       </tr>
       <tr >
       <td>2</td>
       <td>Complete isolation of potential hazard such as power, air.etc before carryout breakdown activity</td>
       <td>Yes</td>
       <td >No</td>
       <td >No</td>
       <td>No</td>
       <td >0 out of 5</td>
       <td >H</td>
       <td >Complaceny</td>
       <td >Mind not on task, line of fire</td>
       <td >Self trigger/ Analyse Close calls and small errors</td>
       <td >Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
       <td >12/19</td>
       <td >01/20</td>
       </tr>
       <tr>
       <td >3</td>
       <td >While replacing motor in wet process proper usage of tools to tighten the fixing bolts</td>
       <td >Yes</td>
       <td >No</td>
       <td >No</td>
       <td >No</td>
       <td >1 out of 5</td>
       <td >L</td>
       <td >Rushing/  Complacency</td>
       <td >Complacency</td>
       <td >Self trigger/ Work on habits</td>
       <td >Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
       <td >12/19</td>
       <td >01/20</td>
       </tr>
       <tr>
       <td>4</td>
       <td >Replacing of chain link without removing link, chance of finger struck between sprockets </td>
       <td >Yes</td>
       <td >No</td>
       <td >No</td>
       <td >No</td>
       <td >1 out of 5</td>
       <td >M</td>
       <td >Rushing/  Complacency</td>
       <td >Eyes not on task, Mind not on task</td>
       <td >Self trigger/ Work on habits</td>
       <td >Work on habits - Look carefully at anything you are going to stick your hand into or rest your hand on</td>
       <td >12/19</td>
       <td >01/20</td>
       </tr>
       <tr >
       <td >5</td>
       <td >While replacing bearing, inadequate usage of proper tools leads to slippage</td>
       <td >Yes</td>
       <td >No</td>
       <td >No</td>
       <td >No</td>
       <td >3 out of 5</td>
       <td >M</td>
       <td >Fatigue/ Complacency</td>
       <td >Eyes not on task, Mind not on task</td>
       <td >Self trigger/ Work on habits</td>
       <td >Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
       <td >12/19</td>
       <td>01/20</td>
       </tr>
       <tr>
        <td >6</td>
        <td >Working in oily condition workplace without cleaning it, leads to chance of slippery and fall</td>
        <td>Yes</td>
        <td>No</td>
        <td >No</td>
        <td >No</td>
        <td >2 out of 5</td>
        <td>M</td>
        <td >Complacency</td>
        <td >line of fire</td>
        <td >Self trigger/ Work on habits</td>
        <td >Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
        <td >12/19</td>
        <td >01/20</td>
        </tr>
        <tr>
        <td >7</td>
        <td >Not wearing gloves while working in equipments for maintenance activity</td>
        <td >Yes</td>
        <td >No</td>
        <td >No</td>
        <td >No</td>
        <td >2 out of 5</td>
        <td >M</td>
        <td>Complacency</td>
        <td >Eyes not on task, Mind not on task</td>
        <td >Self trigger/ Work on habits</td>
        <td >Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
        <td >12/19</td>
        <td >01/20</td>
        </tr>
        <tr >
        <td >8</td>
        <td >During loosening of fasteners chance of tools slippage leads to hand injury</td>
        <td >Yes</td>
        <td >No</td>
        <td >No</td>
        <td >No</td>
        <td >1 out of 5</td>
        <td >M</td>
        <td >Fatigue/  Complacency</td>
        <td >&nbsp;Mind not on task, Line of fire</td>
        <td >Self trigger/ Work on habits</td>
        <td >Work on habits - Look for things that could cause you to lose your balance, grip. Training to be provided</td>
        <td >12/19</td>
        <td >01/20</td>
        </tr>
       </table>
       `,
      `
    ${style}
       <h3>Value Chain at a plant - example</h3>
       <br/>
       Observations to be recorded across each function
       <br/>
       <table>
       <tr>
       <td>Health Check Steps</td>
       </tr>
       <tr>
       <td>What is your value chain / process flow?</td>
       </tr>
       <tr>
       <td>Make a note of all the KPI's / tasks in each function where
       manual intervention is involved. </td>
       <tr>
       <tr>
       <td>Next, make a note of errors happening due to manual interventions in these tasks.
       We will call these as observations. Fill them in the table on next sheet</td>
       <tr>
       <tr>
       <td>Follow the next sheet for details</td>
       <tr>
       <tr>
       <td>Each observation to be noted multiple times on different occasions </td>
       <tr>
       <tr>
       <td>Each function to make observations on different excel sheets
       </td>
       <tr>
       </table>`
    ]
  };

  //   openModal = n => {
  //     document.getElementById("myModal").style.display = "block";
  //   };
  //   closeModal = () => {
  //     document.getElementById("myModal").style.display = "none";
  //   };
  //   view = () => {
  //     this.setState({ show: true });
  //   };
  //   hideView = () => {
  //     console.log("hide");
  //     this.setState({ show: false });
  //   };
  //   loadScript = src => {
  //     this.setState({ loaded: false });
  //     return <ScriptTag type="text/javascript" src={src} />;
  //   };
  render() {
    let state = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-margin-top">
          <div className="col-sm-12">
            <div className="row form-group">
              <div className="card">
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-12">
                      <div className="row ">
                        <div className="col-sm-1"></div>
                        <div className="col-sm-2"></div>
                      </div>
                    </div>
                    <div className="col-sm-12"></div>
                    <h2
                      className="theme-tiile text-capitalize"
                      style={{
                        marginLeft: '30px',
                        float: 'left',
                        marginTop: '24px'
                      }}
                    >
                      Safety Health Check
                    </h2>
                    <div className="col-sm-12">
                      <br />
                      <div className="col-sm-12">
                        <p>
                          To measure the impact of our behavior-based safety interventions and to carry out an analysis on how human factor affects safety at work, MDC introduced the Safety Health
                          Check Observations Analysis for each business to follow. The steps to this health check are explained as follows:
                        </p>
                        <br />
                        <p>A : Businesses to draw their value chain / process flow</p>
                        <p>B: Make a note of all the KPI's / tasks in each function where manual intervention is involved</p>
                        <p>C: Next, function-wise, make a note of errors happening due to manual interventions in these tasks. We will call these as observations</p>
                        <p>D: Each observation to be noted multiple times on different occasions </p>
                        <p>E: The same process to be followed for cross-functional health check analysis</p>
                      </div>
                      <br />
                      <h3 style={{ marginLeft: '14px' }}>Safety Health Check of a function at a Plant:</h3>
                      <br />
                      <div className="col-sm-12">
                        <ul class="nav nav-tabs" role="tablist">
                          {state.tabTitle.map((ival, i) => {
                            return (
                              <li class="nav-item">
                                <a class={`nav-link ${i === 0 ? 'active' : ''}`} data-toggle="tab" href={`#home1${i}`}>
                                  {ival}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                        <div class="tab-content">
                          {state.tabContent.map((ival, i) => {
                            return (
                              <div id={`home1${i}`} class={`container tab-pane ${i === 0 ? ' active' : ''}`} style={{ margin: '0px', overflowX: 'auto' }}>
                                <p className="content-para">{renderHTML(ival ? ival : '')}</p>
                              </div>
                            );
                          })}
                        </div>
                        <br />
                        <br />
                        {/* <label style={{ fontWeight: 600, marginLeft: "3px" }}>
                          Cross Functional Safety Health Check
                        </label>
                        <ul class="nav nav-tabs" role="tablist">
                          {state.tabTitle.map((ival, i) => {
                            return (
                              <li class="nav-item">
                                <a
                                  class={`nav-link ${i === 0 ? "active" : ""}`}
                                  data-toggle="tab"
                                  href={`#home2${i}`}
                                >
                                  {ival}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                        <div class="tab-content">
                          {state.tabContent1.map((ival, i) => {
                            return (
                              <div
                                id={`home2${i}`}
                                class={`container tab-pane ${
                                  i === 0 ? " active" : ""
                                  }`}
                                style={{ margin: "0px", overflowX: "auto" }}
                              >
                                <p className="content-para">
                                  {renderHTML(ival ? ival : "")}
                                </p>
                              </div>
                            );
                          })}
                        </div> */}
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3"></div>
            </div>
            <div className="row form-group">
              <div className="col-sm-12 mb-new">
                <div className="card">
                  {/* <div className="card-body px-0">
                    <DownPanel />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default SingleContent1;
