import React, { Component } from 'react';
import { Datatable } from '../../../../components/Datatable';
import CmsContent from '../../../../MiddleWare/CmsContent';
import SingleSelect from "../../../../components/Form/SingleSelect";
import FormMiddleWare from '../../../../components/Form/FormMiddleware';
import { CSVLink } from 'react-csv';
import NewDataTables from '../../../../components/Extra/newDataTable';
// import { PDFDownloadLink } from '@react-pdf/renderer';
// import LoginModal from '../../../../components/Modal/Modal';
// import CmsCertificate from '../cmsCertificate';
class Quiztable extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            customerid: localStorage.getItem('userId'),
            serviceid: localStorage.getItem('currentService'),
            page2: 0,
            customerId: localStorage.getItem("userId"),
            pageOptions: []
        };
    }

    async componentWillMount() {
        try {
            let QuizList = await CmsContent.getTwoConditionedValue('tbl_question', 'customerid', this.state.customerid, 1, 1, "id as value ,quizName as label,contentJson");
            // console.log(QuizList, "QuizList");
            if (QuizList){
                this.setState({QuizList: QuizList.data });
            }
            const pages = await CmsContent.getFreedom(
                "id as value,name as label",
                "tbl_pages",
                `customerId=${this.state.customerId} and themeId=3`,
                "id",
                "id DESC"
            );
            // console.log(pages, "pages");
            if (pages) {
                this.setState({ pageOptions: pages.data });
            }
        } catch (error) {
            console.error(error);
        }
    }
    column = [
        // {
        //     Header: "Category",
        //     accessor: "categoryName",
        // },
        {
            Header: "Courses Name",
            accessor: "subCategoryName",
        },
        {
            Header: "Page",
            accessor: "name",
        },

        {
            Header: "Status",
            accessor: "status",
            Cell: (d) => this.statusChange(d),
        },
        {
            Header: "Visibility",
            accessor: "edit",
            Cell: (d) => this.showVisibility(d),
        },
        {
            Header: "Edit",
            accessor: "edit",
            Cell: (d) => this.edit(d, "customer"),
        },
        {
            Header: "Delete",
            accessor: "delete",
            Cell: (d) => this.delete(d),
        },
    ];
    column1 = [
        {
            name: 'Employee Code',
            selector: 'employee_id',
            sortable: true,
        },
        {
            name: 'Employee Name',
            selector: 'label',
        },
        {
            name: 'Program Name',
            selector: 'contentTitle1',
        },
        {
            name: 'Program Status',
            selector: 'iscomplete',
        },
        {
            name: 'Program Score',
            selector: 'percent',
            sortable: true,
        },
        {
            name: 'Module Completion date',
            selector: 'createdAt',
        },
        {
            name: 'Module Assigned Date',
            selector: 'PageDate',
        },
        {
            name: 'Business Unit',
            selector: 'BusinessUnit',
        },
        {
            name: 'Division',
            selector: 'Division',
        },
        {
            name: 'Branch',
            selector: 'Branch',
        },
        

    ];

    deside = d => {
        let val = d.original.posttotal * 100 / d.original.total;
        if (val >= 60) {
            return (this.dataTableButton('success', 'View', () => {
                this.ViewCertificate(d.original);
            }))
        } else {
            return <center>Incomplete</center>
        }
    };
    ViewCertificate = d => {
        window.open(`CmsCertificate/${btoa(this.state.selectQuiz.label)}/${btoa(d.label)}/${btoa(d.time)}/${btoa(d.score * 100 / d.total)}/${btoa(d.posttotal * 100 / d.total)}`, "_blank");
    }
    pageSelect = async (selectedOption) => {
        console.log(selectedOption, "selectedOption");
        await this.setState({
            CourseName: selectedOption, pageSelected: selectedOption
        });
    };
    ViewOne = (d) => {
        let answer = JSON.parse(d.answerJson)
        let question = JSON.parse(this.state.selectQuiz.contentJson);
        let postAnswer = JSON.parse(d.postAns)

        let finalSingleValue = [];
        question.map((ival, i) => {
            answer.map((mval, ind) => {
                if (ival.questionId == mval.questionId) {
                    if (ival.type === 'multiple') {
                        let ans1 = [];
                        answer[ind].answer.map((jval, j) => {
                            let ans = jval.split('option').pop();
                            ans1.push(ival.options[ans - 1]);
                        });
                        let postAns = [];
                        if (postAnswer && postAnswer[ind].answer) {
                            postAnswer[ind].answer.map((jval, j) => {
                                let ans = jval.split('option').pop();
                                postAns.push(ival.options[ans - 1]);
                            });
                            postAns = postAns.toString();
                        } else {
                            postAns = '-';
                        }

                        let actualAnswer = [];
                        if (ival.answer) {
                            ival.answer.split(',').map((jval, j) => {
                                let cA = jval.split('option').pop();
                                actualAnswer.push(ival.options[cA - 1]);
                            });
                        }

                        finalSingleValue.push({ question: ival.question, answer: ans1.toString().toLowerCase(), correct: actualAnswer.toString().toLowerCase(), postanswer: postAns, result: ans1.toString().toLowerCase() === actualAnswer.toString().toLowerCase() ? 'Correct' : "Wrong" });
                    } else {
                        let postAns = (postAnswer && postAnswer[ind] && postAnswer[ind].answer) ? postAnswer[ind].answer.toLowerCase() : '-';
                        finalSingleValue.push({ question: ival.question, answer: answer[ind].answer.toLowerCase(), correct: ival.answer.toLowerCase(), postanswer: postAns, result: answer[ind].answer.toLowerCase() == ival.answer.toLowerCase() ? 'Correct' : "Wrong" })
                    }
                }
            })
        });
        this.setState({ finalSingleValue, page2: 2, userName: d.label, prePost: d.prepost ? 'Pre' : 'Post', date: this.secondFunction(d) });
    }
    firstFunction = (d, value) => {
        return <a href="#" onClick={() => { this.doFunction(d) }}>{value}</a>
    }
    secondFunction(date) {
        let d = new Date(date.time),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('-');
    }


    resetState = () => this.setState({ page2: 0, singleUser: [], finalSingleValue: [], userName: [], date: [] })
    resetdata = async () => {
        await this.setState({
            selectedstartdate: "", selectedenddate: "", pageSelected: { label: 'Select Course', value: 0 }, CourseName: []
        })
    }
    resetcoursedata = async () => {
        await this.setState({
            pageSelected: { label: 'Select Course', value: 0 }, CourseName: []
        })
    }
    submitdata = async () => {
        let { selectedstartdate,
            selectedenddate, CourseName } = this.state;
        console.log(selectedstartdate, selectedenddate);
        this.setState({ loading: true })
        let Courseid = 0
        if (CourseName && CourseName.value) {
            Courseid = CourseName.value
        }
        let result = await CmsContent.VidyaReport(selectedstartdate, selectedenddate, Courseid, this.state.customerid)
        console.log(result);
        this.setState({ tableData: result.data, loading: false })
    }
    OnBack = val => {
        this.setState({ page2: val - 1 })
    }
    fromDateSelect = (e) => {
        this.setState({ selectedstartdate: e.target.value });
        // console.log(this.state.selectedstartdate);
    };

    endDateSelect = async (e) => {
        this.setState({ selectedenddate: e.target.value });
        // console.log(e.target.value);
    };
    render() {
        const { loading, UserList, QuizList, page2, singleUser, finalSingleValue, userName, prePost, date, tableData, pageOptions, pageSelected } = this.state;
        console.log(pageSelected, "pageSelected");
        return (
            <main className="main my-4">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3>Course Report</h3>
                                </div>
                                <div className="card-body" style={{ minHeight: 350 }}>

                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">Date</div>
                                        <div className="col-sm-3">
                                            From Date
                                            <input
                                                type="date"
                                                id="myDate"
                                                className="form-control"
                                                onChange={this.fromDateSelect}
                                                value={this.state.selectedstartdate}
                                            />
                                            <span className="error-shows">
                                                {this.state.startdateerror}
                                            </span>
                                        </div>
                                        <div className="col-sm-3">
                                            To Date
                                            <input
                                                type="date"
                                                className="form-control"
                                                onChange={this.endDateSelect}
                                                value={this.state.selectedenddate}
                                            />
                                            <span className="error-shows">
                                                {this.state.endateerror}
                                            </span>
                                        </div>
                                        <div className="col-sm-2 error-shows"> </div>
                                    </div>
                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <label for="exampleInputEmail1">Select Course*</label>
                                        </div>
                                        <div className="col-sm-5">
                                            <SingleSelect
                                                handleChange={this.pageSelect}
                                                options={pageOptions}
                                                selectedService={pageSelected}
                                            />
                                        </div>
                                        <div className="col-sm-2">
                                            <i
                                                class="fa fa-times-circle"
                                                style={{ fontSize: "25px" }}
                                                onClick={this.resetcoursedata}
                                            ></i>
                                        </div>
                                    </div>

                                    <div className="row form-group">
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2" />
                                        <div className="col-sm-2">
                                            <button
                                                type="button"
                                                className="btn btn-primary "
                                                onClick={this.submitdata}
                                            >
                                                Submit
                                            </button>
                                        </div>
                                        <div className="col-sm-2 text-left" >
                                            <button
                                                type="button"
                                                className="btn btn-warning "
                                                onClick={this.resetdata}
                                            >
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                    <span>
                                        {tableData && `Total of ${Object.keys(this.state.tableData).length} records`}
                                    </span>
                                    <br></br>
                                    {this.state.excel && this.state.excel.length > 0 && (
                                        <CSVLink
                                            data={this.state.Csv}
                                            headers={this.state.excelHead}
                                        >
                                            <button
                                                className="btn btn-success"
                                                style={{ marginLeft: "5px" }}
                                            >
                                                EXCEL
                                            </button>
                                        </CSVLink>
                                    )}

                                    {loading ? <div className="text-center"> < i className="fa fa-spinner fa-2x fa-spin" style={{ color: "blueviolet" }} ></i></div> : tableData ? <NewDataTables data={tableData} columns={this.column1} /> : null}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
        );
    }
}

export default Quiztable;
