import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import SingleSelect from "../../../components/Form/SingleSelect";
import { Alert } from "reactstrap";
import { Datatable } from "../../../components/Datatable";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Firstpagepdf from "./Firstpagepdf";
import Userlogpdf from "./userlogpdf";
import Loginpagepdf from "./loginpagepdf";
import Listpagepdf from "./Listpagepdf";
import { CSVLink, CSVDownload } from "react-csv";
import FormMiddleWare from "../../../components/Form/FormMiddleware";
class CMSuserlogFirstPage extends FormMiddleWare {
  constructor(props) {
    super(props);
    this.state = {
      SubmitBtn: false,
      selectedstartdateDisable: true,
      selectedenddateDisable: true,
      customerId: localStorage.getItem("userId"),
      pageOptions: [],
      videoptions: [],
      tabledata: [],
      users: [],
      Accessusercount: [],
      Filterloding: false,
      alluserviewpage: [],
      selectedstartdate: "",
      selectedenddate: "",
      disablepage: false,
      disablevideos: false,
      alertVisible: false,
      AllContent: [],
      groupedData: [],
      pageList: [],
      tabledata1: [],
    };
  }

  async componentWillMount() {
    try {
      const pages = await CmsContent.getFreedom(
        "id as value,name as label,file",
        "tbl_pages",
        `customerId=${this.state.customerId} and status='show' `,
        "id",
        "id DESC"
      );
      if (pages) {
        this.setState({
          pageOptions: [
            { label: "All", value: "All" },
            { label: "Login", value: "Login" },
            ...pages.data,
          ],
        });
      }

      const videoptionsvalues = await CmsContent.getFreedom(
        "id as value,file as label",
        "tbl_pages",
        `customerId=${this.state.customerId} and themeId="3"`,
        "id",
        "id DESC"
      );
      console.log(videoptionsvalues)
      if (videoptionsvalues) {
        videoptionsvalues.data.map((video) => {
          video.label = video.label.split("/")[1];
        });
        this.setState({
          videoptions: [
            { label: "All", value: "All" },
            ...videoptionsvalues.data,
          ],
        });
      }

      const users = await CmsContent.getFreedom(
        "id as value,userName as label",
        "tbl_user_web",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      const userOptions = [{ label: "All Users", value: "All Users" }];
      this.setState({ userOptions });
      if (users) {
        this.setState({ users: users.data });
      }
    } catch (error) {
      console.log(error);
    }
  }

  column = [
    {
      Header: "Username",
      accessor: "userId",
      Cell: (d) => this.getValueFromArray(d.original.userId, this.state.users),
    },
    {
      Header: "Page name",
      accessor: "pageid",
      Cell: (d) =>
        this.getValueFromArray(d.original.pageid, this.state.pageOptions),
    },
    {
      Header: "Count",
      Cell: (d) => this.countview(d),
    },
    {
      Header: "Details",
      Cell: (d) => this.viewusers(d.original),
    },
  ];
  usercolumn = [
    {
      Header: "Username",
      accessor: "username",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Mobile Number",
      accessor: "mobile",
    },
    {
      Header: "Page views",
      accessor: "count"
    },
    {
      Header: "View Details",
      Cell: (d) => this.viewallusers(d),
    },
  ];
  loginColumn = [
    {
      Header: "Username",
      accessor: "userId",
      Cell: (d) => this.getValueFromArray(d.original.userId, this.state.users),
    },
    {
      Header: "Email",
      accessor: "email"
    },
    {
      Header: "Mobile Number",
      accessor: "number"
    },
    {
      Header: "Count",
      accessor: "count",
    },
    {
      Header: "Details",
      Cell: (d) => this.viewLogin(d.original),
    },
  ];

  FirstPageColumn = [
    {
      Header: "Page Name",
      accessor: "contentTitle1",
    },
    {
      Header: "Viewers",
      accessor: "pageid",
      Cell: (d) => this.gd(d),
    },
  ];

  viewallusers = (d) => {

    return (
      <button
        className="btn btn-primary"
        onClick={() =>

          window.open(
            `./cmsalluserlog/${d.original.userId}/${this.state.selectedstartdate}/${this.state.selectedenddate}`,
            "_blank"
          )
        }
      >
        View
      </button>
    )
  };
  loginemail = async (d) => {
    //console.log("frst", d);
    let result = await CmsContent.getFreedom(
      "*",
      "tbl_user_web",
      `id=${d.userId}`,
      1,
      1
    );
    if (result) {
      console.log("res", result);
      let number = result.data[0].mobilenumber;
    }
    return <span>Email</span>;
  };
  gd = (d) => {
    let a = this.state.groupedData.filter(
      (jval) => d.original.pageid === jval.pageid
    ).length;
    return (
      <a
        style={{ cursor: "pointer" }}
        onClick={() =>
          window.open(
            `cmsuserlogSecond/${d.original.pageid}/${this.state.selectedstartdate}/${this.state.selectedenddate}`,
            "_blank"
          )
        }
      >
        {a}
      </a>
    );
  };
  //   dateformatchange =(datevalue) =>{
  //     let dateview=datevalue.split('-');

  // let hourssplit=dateview[2].split(':')[0].split('');
  // let Mins=dateview[2].split(':');
  //   return(<span> {hourssplit[0]+hourssplit[1]+'-'+dateview[1]+'-'+dateview[0]+' '+hourssplit[3]+hourssplit[4]+':'+Mins[1]+':'+Mins[2]}</span>)
  //   }
  //value
  countview = (value) => {
    let count = 0;
    let AllContent = this.state.AllContent;
    AllContent.map((item, i) => {
      if (value.original.userId === item.userId) {
        count = item.innerContent.length;
      }
    });

    return <span> {count}</span>;
    //return(<span  onClick={() => window.open("cmsallog" + "/" + value.userId+'/'+value.pageid, "_blank")} >view</span>)
  };

  viewusers = (value) => {
    return (
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "cmsallog" + "/" + value.userId + "/" + value.pageid,
            "_blank"
          )
        }
      >
        View
      </button>
    );
  };
  //   cmsloginog
  viewLogin = (value) => {
    return (
      <button
        className="btn btn-primary"
        onClick={() => window.open("cmsloginog" + "/" + value.userId, "_blank")}
      >
        View
      </button>
    );
  };
  userSelect = async (e) => {
    this.setState({ userselect: e, disablevideos: true, disablepage: true, selectedstartdateDisable: false, selectedenddateDisable: false });
  };
  pageSelect = async (e) => {
    // console.log(e);
    this.setState({ pageSelected: e, disablevideos: true, disableuser: true, selectedstartdateDisable: false, selectedenddateDisable: false });
    // if(e.value === "Login"){

    // }

    //this.setState({tabledata :pageview.data})
    ///console.log(this.state.tabledata.length)
    //   console.log(`customerId=${this.state.customerId} and pageid =${e.value}`)
  };

  vidoeschange = async (e) => {
    this.setState({ videoSelected: e, disablepage: true, disableuser: true, selectedstartdateDisable: false, selectedenddateDisable: false });

    //this.setState({tabledata :videoSelectedpage.data})
    // console.log(this.state.tabledata);
  };

  fromDateSelect = (e) => {
    this.setState({ selectedstartdate: e.target.value });
    // console.log(this.state.selectedstartdate);
  };

  endDateSelect = async (e) => {
    this.setState({ selectedenddate: e.target.value, SubmitBtn: true });
    // console.log(e.target.value);
  };
  resetdisablevalue = () => {
    this.setState({
      disablevideos: false,
      disablepage: false,
      disableuser: false,
      tabledata: "",
      userselect: "",
      videoSelected: "",
      pageSelected: "",
      selectedstartdate: "",
      selectedenddate: "",
      SubmitBtn: false,
      selectedstartdateDisable: true,
      selectedenddateDisable: true,
      Filterloding: false
    });
  };

  submitdata = async () => {
    await this.setState({ Filterloding: true })
    try {
      let userselected = "";
      let vidCon = "";
      if (this.state.videoSelected) {
        userselected = this.state.videoSelected.value;
        vidCon = ` and themeId="3"`;
      } else if (this.state.pageSelected) {
        userselected = this.state.pageSelected.value;
      }
      if (this.state.userselect) {
        userselected = this.state.userselect.value;
      }
      if (userselected === "All") {
        let { data: getAlldataForDate } = await CmsContent.getFreedom(
          "tbl_CMSuserAccessLog.*,tbl_pages.contentTitle1,tbl_user_web.userName,tbl_user_web.email,tbl_user_web.mobileNumber",
          "tbl_CMSuserAccessLog,tbl_user_web,tbl_pages",
          `tbl_CMSuserAccessLog.userId=tbl_user_web.id and tbl_pages.id=tbl_CMSuserAccessLog.pageid and tbl_CMSuserAccessLog.customerId=tbl_pages.customerId and tbl_pages.customerId=${this.state.customerId} ${vidCon} and tbl_CMSuserAccessLog.date between '${this.state.selectedstartdate}' and '${this.state.selectedenddate}'`,
          "tbl_CMSuserAccessLog.id",
          "tbl_CMSuserAccessLog.id DESC"
        );

        if (getAlldataForDate) {
          let data = [];
          getAlldataForDate.map((ival, i) => {
            let a = true;
            if (data.length) {
              data.map((jval, j) => {
                if (jval.userId === ival.userId && jval.pageid === ival.pageid) {
                  a = false;
                }
              });
              if (a) {
                data.push(ival);
              }
            } else {
              data.push(ival);
            }
          });
          let b = [];
          let c = [];
          var get = [];
          var pg = [];
          var usr = [];
          var user = this.state.users;
          data.map((ival, i) => {
            if (b.indexOf(ival.pageid) === -1) {
              b.push(ival.pageid);
              c.push(ival);
              // console.log(ival.contentTitle1);
              // console.log(
              //   data.filter((jval, j) => ival.pageid === jval.pageid).length
              // );
              let views = data.filter((jval, j) => ival.pageid === jval.pageid)
                .length;

              get.push({
                SI_NO: i + 1,
                pageName: ival.contentTitle1,
                Viewer: views,
              });
            }
          });
          if (get.length > 0) {
            const header = ["SI.NO", "pageName", "Viewers"];
            const excelHead = [

              { label: "PageName", key: "pageName" },
              { label: "Viewers", key: "Viewer" },
            ];
            this.setState({ header, excelHead });
          }
          this.setState({
            getAlldataForDate,
            groupedData: data,
            pageList: c,
            excel: get,
            page: "All",
            tabledata1: "",
            userdata: "",
            tabledata1: "", Filterloding: false
          });
        }
      } else if (userselected == "All Users") {
        let { data: getAlldataForUsers } = await CmsContent.getFreedom(
          "tbl_CMSuserAccessLog.*,tbl_pages.name,tbl_user_web.userName,tbl_user_web.email,tbl_user_web.mobileNumber",
          "tbl_CMSuserAccessLog,tbl_user_web,tbl_pages",
          `tbl_CMSuserAccessLog.userId=tbl_user_web.id and tbl_pages.id=tbl_CMSuserAccessLog.pageid and tbl_CMSuserAccessLog.customerId=tbl_pages.customerId and tbl_pages.customerId=${this.state.customerId} ${vidCon} and tbl_CMSuserAccessLog.date between '${this.state.selectedstartdate}' and '${this.state.selectedenddate}'`,
          "tbl_CMSuserAccessLog.id",
          "tbl_CMSuserAccessLog.id DESC"
        );
        let pages = [];
        console.log(getAlldataForUsers)
        if (getAlldataForUsers) {
          let data = [];
          let AllContent = [];
          getAlldataForUsers.map((ival, i) => {
            let a = true;
            if (data.length) {
              data.map((jval, j) => {
                if (jval.userId === ival.userId) {
                  a = false;
                }
              });
              if (a) {
                data.push(ival);
              }
            } else {
              data.push(ival);
            }
          });
          console.log(data)
          let pages = [];
          getAlldataForUsers.map((ival, i) => {
            let a = true;
            if (data.length) {
              pages.map((jval, j) => {
                if (jval.pageid === ival.pageid) {
                  a = false;
                }
              });
              if (a) {
                pages.push(ival);
              }
            } else {
              pages.push(ival);
            }
          });

          let b = [];
          let c = [];

          data.map((ival, m) => {
            AllContent.push({ username: ival.userName, mobile: ival.mobileNumber, email: ival.email, userId: ival.userId, pageid: ival.pageid })
          })
          AllContent.map((ival, i) => {
            AllContent[i].innerContent = [];
            pages.map((jval, n) => {
              if (ival.userId == jval.userId) {
                AllContent[i].innerContent.push({ jval })
              }
            })
          })
          //count
          AllContent.map((ival, i) => {
            AllContent[i].count = 0;
            pages.map((jval, j) => {
              let count = 0;
              count = AllContent[i].innerContent.length;
              if (jval.userId == ival.userId) {
                AllContent[i].count = count;
              }
            });
          });
          if (AllContent.length > 0) {
            const header = [
              "SI_NO",
              "UserName",
              "Email",
              "Mobile Number",
              "TotalPages",
            ];
            const excelHead = [
              { label: "Username", key: "username" },
              { label: "Email", key: "email" },
              { label: "Mobile Number", key: "mobile" },
              { label: "Totalpages", key: "count" },

            ];
            this.setState({ excelHead, header });

          }
          this.setState({ userdata: AllContent, Filterloding: false, excel: AllContent, page: "All Users", tabledata1: "", tabledata: "", pageList: "" });

        }
      } else if (userselected === "Login") {
        let { data: getLogindataForDate } = await CmsContent.getFreedom(
          "tbl_CMSuserAccessLog.*,tbl_user_web.userName,tbl_user_web.email,tbl_user_web.mobileNumber",
          "tbl_CMSuserAccessLog,tbl_user_web",
          `tbl_CMSuserAccessLog.userId=tbl_user_web.id and tbl_CMSuserAccessLog.pageid = 0 and tbl_CMSuserAccessLog.customerId = ${this.state.customerId} and tbl_CMSuserAccessLog.date between '${this.state.selectedstartdate}' and '${this.state.selectedenddate}'`,
          "tbl_CMSuserAccessLog.id",
          "tbl_CMSuserAccessLog.id DESC"
        );

        if (getLogindataForDate) {
          let AllContent = [];
          getLogindataForDate.map((ival, i) => {
            if (
              AllContent.filter((jval) => jval.userId === ival.userId).length ===
              0
            ) {
              AllContent.push({
                userId: ival.userId,
                email: ival.email,
                number: ival.mobileNumber,
                userName: ival.userName
              });
            }
          });
          let cel = [];
          AllContent.map((ival, i) => {
            AllContent[i].innerContent = [];
            getLogindataForDate.map((jval, j) => {
              if (jval.userId == ival.userId) {
                // let count = 0
                // count =
                AllContent[i].innerContent.push({
                  jval,
                });
              }
            });
          });
          //Count
          AllContent.map((ival, i) => {
            AllContent[i].count = 0;
            getLogindataForDate.map((jval, j) => {
              let count = 0;
              count = AllContent[i].innerContent.length;
              if (jval.userId == ival.userId) {
                AllContent[i].count = count;
              }
            });
          });

          // AllContent.map((kval, i) => {
          //   this.state.users.map((ival, v) => {
          //     if (ival.value == kval.userId) {
          //       cel.push({
          //         SI_NO: i + 1,
          //         userName: ival.label,
          //         count: kval.count,
          //       });
          //     }
          //   });
          // });

          // console.log(cel);
          if (AllContent) {
            const header = ["SI.NO", "UserName", "Count"];
            const excelHead = [

              { label: "UserName", key: "userName" },
              { label: "Count", key: "count" },
            ];
            this.setState({ header, excelHead });
            this.setState({
              tabledata1: AllContent,
              Csv: cel,
              excel: AllContent,
              tabledata: "",
              userdata: "",
              PageList: "",
              page: "Login", Filterloding: false
            });
          }
        }
      } else {
        console.log('else log');
        try {
          let date = new Date();
          var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
          var day = date.getUTCDate();
          var year = date.getUTCFullYear();
          let todaydate = year + "-" + month + "-" + day;
          let dateselected = await CmsContent.getFreedom(
            "tbl_CMSuserAccessLog.*,tbl_pages.*,tbl_user_web.email,tbl_user_web.mobileNumber",
            "tbl_CMSuserAccessLog,tbl_pages,tbl_user_web",
            `pageid=${userselected} and  tbl_CMSuserAccessLog.userId=tbl_user_web.id and tbl_CMSuserAccessLog.date between '${this.state.selectedstartdate}' and '${this.state.selectedenddate}'`,
            "tbl_CMSuserAccessLog.userid",
            "1"
          );
          if (dateselected) {
            this.setState({ tabledata: dateselected.data });
            const pageview = await CmsContent.getFreedom(
              "tbl_CMSuserAccessLog.*,tbl_pages.file,tbl_pages.name",
              "tbl_CMSuserAccessLog,tbl_pages",
              `tbl_CMSuserAccessLog.pageid =${userselected} and tbl_pages.id=${userselected} and tbl_CMSuserAccessLog.customerId=${this.state.customerId} `,
              "userId",
              "id DESC"
            );
            console.log('before alluserviewpage');
            const alluserviewpage = await CmsContent.getFreedom(
              "tbl_CMSuserAccessLog.*,tbl_pages.file",
              "tbl_CMSuserAccessLog,tbl_pages",
              `tbl_CMSuserAccessLog.pageid =${userselected} and tbl_pages.id=${userselected} and tbl_CMSuserAccessLog.customerId=${this.state.customerId} `,
              "id",
              "id DESC"
            );
            this.setState({ alluserviewpage: alluserviewpage.data });
            let AllContent = [];
            console.log('beforemap 2');
            pageview.data.map((ival, i) => {
              if (
                AllContent.filter((jval) => jval.userId === ival.userId).length ===
                0
              ) {
                AllContent.push({
                  userId: ival.userId,

                });
              }
            });
            console.log('beforemap 2');
            let index = [];
            AllContent.map((ival, i) => {
              AllContent[i].innerContent = [];
              this.state.alluserviewpage.map((jval, j) => {
                // console.log(jval);
                if (jval.userId == ival.userId) {
                  AllContent[i].innerContent.push({
                    jval,
                  });
                }
              });
            });

            // console.log(this.state.alluserviewpage)
            //Count
            let w = await AllContent && AllContent.map(async (ival, i) => {
              AllContent[i].count = 0;
              AllContent[i].username = [];
              AllContent[i].pagename = [];
              await this.state.alluserviewpage.map(async (jval, j) => {
                // await body.users.map(async (hval, m) => {
                await this.state.pageOptions.map((pval, n) => {
                  let count = 0;
                  count = AllContent[i].innerContent.length;
                  if (jval.userId == ival.userId) {
                    AllContent[i].count = count;
                  }
                  // if (ival.userId == hval.value) {
                  //   AllContent[i].username = hval.label
                  // }
                  if (jval.pageid == pval.value) {
                    AllContent[i].pagename = pval.label
                  }
                  // })
                })
              });
            });
            await Promise.all(w)



            let wait123 = await AllContent.map(async (ival, i) => {
              AllContent[i].username = [];
              let count = 0;
              await this.state.users.map(async (hval, m) => {

                count = AllContent[i].innerContent.length;
                if (ival.userId == hval.value) {
                  AllContent[i].username = hval.label
                }
              })
            });
            await Promise.all(wait123)
            // console.log(AllContent, 'allcontent')
            // let name = [];
            // AllContent.map((kval, i) => {
            //   this.state.users.map((ival, v) => {
            //     this.state.tabledata.map((cal, r) => {
            //       this.state.pageOptions.map((hval, s) => {
            //         if (cal.pageid == hval.value && ival.value == kval.userId) {
            //           index.push({
            //             SI_NO: i + 1,
            //             userName: ival.label,
            //             pageName: hval.value,
            //             count: kval.count,
            //           });
            //         }
            //       });
            //     });
            //   });
            // });


            if (AllContent) {

              const header = ["SI.NO", "UserName", "pageName", "Count"];
              const excelHead = [
                { label: "UserName", key: "username" },
                { label: "PageName", key: "pagename" },
                { label: "Count", key: "count" },
              ];
              this.setState({ header, excelHead });

            }
            this.setState({
              AllContent: AllContent,
              //Csv: index,
              excel: AllContent,
              tabledata1: "",
              userdata: "",
              pageList: "",
              page: "None", Filterloding: false
            });

          }
        } catch (e) {
          console.log(e);
        }
      }
    } catch (err) {
      console.log(err, 'err');
    }
  };
  submitdata1 = async () => {
    await this.setState({ Filterloding: true })
    let userselected = "";
    let val = {}
    val.users = this.state.users
    val.userselect = this.state.userselect
    val.pageSelected = this.state.pageSelected
    val.videoSelected = this.state.videoSelected
    val.pageOptions = this.state.pageOptions
    val.customerId = this.state.customerId
    val.selectedstartdate = this.state.selectedstartdate
    val.selectedenddate = this.state.selectedenddate;
    if (this.state.videoSelected) {
      userselected = this.state.videoSelected.value;
    } else if (this.state.pageSelected) {
      userselected = this.state.pageSelected.value;
    }
    if (this.state.userselect) {
      userselected = this.state.userselect.value;
    }
    const { data: result } = await CmsContent.TotalAccesslog(val);
    console.log(result, 'result');
    console.log(userselected, 'userselected');
    if (Object.values(result).length && result !== "Error") {
      if (userselected === "All") {
        this.setState({
          getAlldataForDate: result.getAlldataForDate,
          groupedData: result.groupedData,
          pageList: result.pageList,
          excel: result.excel,
          page: "All",
          tabledata1: "",
          userdata: "",
          tabledata1: "",
          excelHead: result.excelHead,
          header: result.header
        });
      } else if (userselected == "All Users") {
        this.setState({
          userdata: result.userdata,
          excel: result.excel,
          page: "All Users",
          tabledata1: "",
          tabledata: "",
          pageList: "",
          excelHead: result.excelHead,
          header: result.header,
          Filterloding: false
        });
      } else if (userselected === "Login") {
        this.setState({
          tabledata1: result.tabledata1,
          Csv: result.Csv,
          excel: result.excel,
          tabledata: "",
          userdata: "",
          PageList: "",
          page: "Login",
          excelHead: result.excelHead,
          header: result.header,
          Filterloding: false
        });
      } else {
        console.log('else log');
        this.setState({
          AllContent: result.AllContent,
          alluserviewpage: result.alluserviewpage,
          //Csv: index,
          excel: result.excel,
          tabledata1: "",
          userdata: "",
          pageList: "",
          page: "None",
          excelHead: result.excelHead,
          header: result.header,
          tabledata: result.tabledata,
          Filterloding: false
        });
      }
    }
    else {
      this.setState({ alertVisible: true, Filterloding: false })
      setTimeout(() => this.setState({ alertVisible: false }), 3000);
    }
  }
  render() {
    let { Filterloding, alertVisible, selectedstartdateDisable, selectedenddateDisable } = this.state

    return (
      <React.Fragment>
        <main className="main my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <div className="row">
                      <div className="col-sm-4"> </div>
                      <div className="col-sm-4">
                        <h3>Total access log</h3>
                      </div>
                      <div className="col-sm-4"> </div>
                    </div>
                    <br />
                    <Alert
                      className="badge-content"
                      color="danger"
                      isOpen={this.state.alertVisible}
                      toggle={this.onDismiss}
                    >
                      No Data Found
                    </Alert>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Select User</label>
                      </div>
                      <div className="col-sm-4">
                        <SingleSelect
                          disabled={this.state.disableuser}
                          handleChange={this.userSelect}
                          options={this.state.userOptions}
                          selectedService={this.state.userselect}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Select Page</label>
                      </div>
                      <div className="col-sm-4">
                        <SingleSelect
                          disabled={this.state.disablepage}
                          handleChange={this.pageSelect}
                          options={this.state.pageOptions}
                          selectedService={this.state.pageSelected}
                        />
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorPageId}
                      </span>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">
                        <label for="exampleInputEmail1">Video Name</label>
                      </div>
                      <div className="col-sm-4">
                        <SingleSelect
                          disabled={this.state.disablevideos}
                          handleChange={this.vidoeschange}
                          options={this.state.videoptions}
                          selectedService={this.state.videoSelected}
                        />
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-2">Date</div>
                      <div className="col-sm-2">
                        From Date
                        <input
                          type="date"
                          id="myDate"
                          disabled={selectedstartdateDisable}
                          className="form-control"
                          onChange={this.fromDateSelect}
                          value={this.state.selectedstartdate}
                        />
                        <span className="error-shows">
                          {this.state.startdateerror}
                        </span>
                      </div>
                      <div className="col-sm-2">
                        To Date
                        <input
                          type="date"
                          disabled={selectedenddateDisable}
                          className="form-control"
                          onChange={this.endDateSelect}
                          value={this.state.selectedenddate}
                        />
                        <span className="error-shows">
                          {this.state.endateerror}
                        </span>
                      </div>
                      <div className="col-sm-2 error-shows"> </div>
                    </div>
                    <br></br>
                    <div className="row form-group">
                      <div className="col-sm-2" />
                      <div className="col-sm-3" />
                      <div className="col-sm-4">
                        <button
                          type="button"
                          className="btn btn-primary "
                          onClick={this.submitdata1}
                          disabled={!this.state.SubmitBtn}
                        >
                          Submit
                        </button>

                        &nbsp;&nbsp;
                        <button
                          className="btn btn-warning"
                          onClick={this.resetdisablevalue}
                        >
                          Reset{" "}
                        </button>
                      </div>
                      <div className="col-sm-3" />
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4" />
                      <span className="error-show ">
                        {this.state.errorPageId}
                      </span>
                    </div><br></br>
                    {/* {this.state.excel && this.state.excel.length>0 && ( */}

                    {/* {this.state.excel && this.state.excel.length > 0 && (
                      <CSVLink
                        data={this.state.excel}
                        headers={this.state.excelHead}
                      >
                        <button
                          className="btn btn-success"
                          style={{ marginLeft: "5px" }}
                        >
                          EXCEL
                        </button>
                      </CSVLink>
                    )} */}
                    {Filterloding ? <div className="text-center"> <i
                      className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                    ></i></div> :
                      <div>
                        {this.state.userdata &&
                          this.state.userselect.value === "All Users" &&
                          this.state.userselect.value !== "All" && (
                            <div>
                              <PDFDownloadLink
                                style={{ color: "white" }}
                                document={
                                  <Userlogpdf
                                    header={this.state.header}
                                    data={this.state.excel}
                                    page={this.state.page}
                                  />
                                }
                                fileName="sample.pdf"
                              >
                                {({ blob, url, loading, error }) =>
                                  loading ? "Loading document..." : "Download Pdf"
                                }
                                <button className="btn btn-warning">PDF</button>
                              </PDFDownloadLink>
                              {this.state.excel && this.state.excel.length > 0 && (
                                <CSVLink
                                  data={this.state.excel}
                                  headers={this.state.excelHead}
                                >
                                  <button
                                    className="btn btn-success"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    EXCEL
                                  </button>
                                </CSVLink>
                              )}<br></br>
                              <p>Total  of records:{this.state.userdata.length}</p><br></br>
                              <Datatable
                                data={this.state.userdata}
                                columnHeading={this.usercolumn}
                              />
                            </div>
                          )}<br></br>
                        {this.state.tabledata &&
                          ((this.state.pageSelected &&
                            this.state.pageSelected.value !== "All" &&
                            this.state.pageSelected.value !== "Login") ||
                            (this.state.videoSelected &&
                              this.state.videoSelected.value !== "All")) && (
                            <div>
                              <PDFDownloadLink
                                style={{ color: "white" }}
                                document={
                                  <Listpagepdf
                                    header={this.state.header}
                                    data={this.state.excel}
                                    page={this.state.page}
                                  />
                                }
                                fileName="sample.pdf"
                              >
                                {({ blob, url, loading, error }) =>
                                  loading ? "Loading document..." : "Download Pdf"
                                }
                                <button className="btn btn-warning">PDF</button>
                              </PDFDownloadLink>
                              {this.state.excel && this.state.excel.length > 0 && (
                                <CSVLink
                                  data={this.state.excel}
                                  headers={this.state.excelHead}
                                >
                                  <button
                                    className="btn btn-success"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    EXCEL
                                  </button>
                                </CSVLink>
                              )}<br></br>
                              <p>Total of records:{this.state.tabledata.length}</p><br></br>
                              <Datatable
                                data={this.state.tabledata}
                                columnHeading={this.column}
                              />
                            </div>
                          )}<br></br>
                        {this.state.pageList &&
                          ((this.state.pageSelected &&
                            this.state.pageSelected.value === "All" &&
                            this.state.pageSelected.value !== "Login") ||
                            (this.state.videoSelected &&
                              this.state.videoSelected.value === "All")) && (
                            <div>
                              <PDFDownloadLink
                                style={{ color: "white" }}
                                document={
                                  <Firstpagepdf
                                    header={this.state.header}
                                    data={this.state.excel}
                                    page={this.state.page}
                                  />
                                }
                                fileName="sample.pdf"
                              >
                                {({ blob, url, loading, error }) =>
                                  loading ? "Loading document..." : "Download Pdf"
                                }
                                <button className="btn btn-warning">PDF</button>
                              </PDFDownloadLink>
                              {this.state.excel && this.state.excel.length > 0 && (
                                <CSVLink
                                  data={this.state.excel}
                                  headers={this.state.excelHead}
                                >
                                  <button
                                    className="btn btn-success"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    EXCEL
                                  </button>
                                </CSVLink>
                              )}<br></br>
                              <p>Total of records:{this.state.pageList.length}</p><br></br>
                              <Datatable
                                data={this.state.pageList}
                                columnHeading={this.FirstPageColumn}
                              />
                            </div>
                          )}<br></br>
                        {this.state.tabledata1 &&
                          this.state.tabledata1.length > 0 && (
                            <div>
                              <PDFDownloadLink
                                style={{ color: "white" }}
                                document={
                                  <Loginpagepdf
                                    header={this.state.header}
                                    data={this.state.excel}
                                    page={this.state.page}
                                  />
                                }
                                fileName="sample.pdf"
                              >
                                {({ blob, url, loading, error }) =>
                                  loading ? "Loading document..." : "Download Pdf"
                                }
                                <button className="btn btn-warning">PDF</button>
                              </PDFDownloadLink>
                              {this.state.excel && this.state.excel.length > 0 && (
                                <CSVLink
                                  data={this.state.excel}
                                  headers={this.state.excelHead}
                                >
                                  <button
                                    className="btn btn-success"
                                    style={{ marginLeft: "5px" }}
                                  >
                                    EXCEL
                                  </button>
                                </CSVLink>
                              )}<br></br>
                              <p>Total of records:{this.state.tabledata1.length}</p><br></br>
                              <Datatable
                                data={this.state.tabledata1}
                                columnHeading={this.loginColumn}
                              />
                            </div>
                          )}<br></br>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default CMSuserlogFirstPage;