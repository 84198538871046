import React from 'react';
import { Alert } from 'reactstrap';

const Addauthormodel = ({
	authorname, 
	handleChange, 
	addNewauthor,
	errorauthorname,
	alert, 
	dismiss, 
	disableValue, 
	alertMsg,
	authoremail,
	errorauthoremail,
	authorabout,
	errorauthorabout,
	authorlogo,
	handleImage
	}) => {
  return (
    <React.Fragment>
      <div>
        <Alert className="badge-content" color="success" isOpen={alert} toggle={dismiss}>
          {alertMsg}
        </Alert>
        <div className="row form-group">
		{/*<div className="col-sm-1" />*/}
          <div className="col-sm-4">
            <label for="exampleInputEmail1">Author Name</label>
          </div>
          <div className="col-sm-7">
            <input type="text" class="form-control" id="authorname" name="authorname" placeholder="Enter Author" onChange={handleChange} value={authorname} />
          </div>
          <div className="col-sm-1" />
        </div>
        <div className="row form-group">
          <div className="col-sm-4" />
          <span className="modal-error-show">{errorauthorname}</span>
        </div>
		
		 <div className="row form-group">
		{/*<div className="col-sm-1" />*/}
          <div className="col-sm-4">
            <label for="exampleInputEmail1">Author Email</label>
          </div>
          <div className="col-sm-7">
            <input type="text" class="form-control" id="authoremail" name="authoremail" placeholder="Enter  Email" onChange={handleChange} value={authoremail} />
          </div>
          <div className="col-sm-1" />
        </div>
        <div className="row form-group">
          <div className="col-sm-4" />
          <span className="modal-error-show">{errorauthoremail}</span>
        </div>
		
		<div className="row form-group">
		{/*<div className="col-sm-1" />*/}
          <div className="col-sm-4">
            <label for="exampleInputEmail1">Author About</label>
          </div>
          <div className="col-sm-7">
           <textarea
               type="text"
               className="form-control"
               id="authorabout"
               name="authorabout"
               placeholder="Enter About"
               value={authorabout}
               onChange={handleChange}     
               />
          </div>
          <div className="col-sm-1" />
        </div>
        <div className="row form-group">
          <div className="col-sm-4" />
          <span className="modal-error-show">{errorauthorabout}</span>
        </div>
		
		
		<div className="row form-group">
		{/*<div className="col-sm-1" />*/}
          <div className="col-sm-4">
            <label for="exampleInputEmail1">Author About</label>
          </div>
          <div className="col-sm-7">
            <input
                type="file"
                 className="custom-file-input"
                id="authorimage"
                onChange={handleImage}
                 />
               <label className="custom-file-label" htmlFor="authorimage">
                 {authorlogo}
               </label>
          </div>
          <div className="col-sm-1" />
        </div>
        <div className="row form-group">
          <div className="col-sm-4" />
          {/*<span className="modal-error-show">{errorauthorabout}</span>*/}
        </div>
		

        <div className="row form-group">
          <div className="col-sm-4" />
          <div className="col-sm-4">
            <button type="button" class="btn btn-primary" disabled={disableValue} onClick={addNewauthor}>
              Add Author 
            </button>
          </div>
          <div className="col-sm-4" />
        </div>
      </div>
    </React.Fragment>
  );
};
 
export default Addauthormodel; 
