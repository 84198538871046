import React, { Component } from "react";
import { Datatable } from "../../../components/Datatable";
import CmsContent from "../../../MiddleWare/CmsContent";
import socket from '../../../MiddleWare/socketMiddleWare';
import SingleSelect from "../../../components/Form/SingleSelect";
//import BarChart from '../../../components/Chart/BarChart';
import LineChart from "../../../components/Chart/LineChart";
import HorizontalBarChart1 from '../../../components/Chart/HorizontalBarChart1';

class VideoSeekLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      v1: [],
      VideoList: [],
      customerId: localStorage.getItem("userId"),
      selectVideoList: {},
      socketTable: []
    };
  }
  data = []
  liveData = []
  v1 = []
  column = [
    {
      Header: "User Name",
      accessor: "userName"
    },
    {
      Header: "Video Name",
      accessor: "pageName"
    },
    {
      Header: "Duration",
      accessor: "timestamp"
    }
    // {
    //   Header: "Button",
    //   accessor: "pageName",
    //   Cell: d => this.modelwindow(d)

    // }
  ];
  modelwindow = d => {

    return (<button class="btn btn-primary" data-toggle="modal" data-target="#myModal" onClick={() => this.buttonclick(this.state.socketTable, { maintainAspectRatio: false }, 'horizontalbar')} >{'button'}</button>)
  }

  buttonclick = (data, option) => {
    console.log(data)
    console.log(option)


  }
  column1 = [
    {
      Header: "Video Name",
      accessor: "pageName"
    },
    {
      Header: "Viewers",
      accessor: "videoId",
      Cell: d => this.dp(d)
    }
  ];
  dp = d => {
    let a = this.liveData.filter(ival => ival.videoId === d.original.videoId).length;
    return (<a style={{ cursor: 'pointer' }} onClick={() => window.open(`cmsSeekLog/${d.original.videoId}`, "_blank")} >{a}</a>)
  }
  async componentDidMount() {
    try {
      const { data: VideoList } = await CmsContent.getFreedom(
        'contentTitle1 as label,id as value',
        'tbl_pages',
        `themeId='3' and customerId='${this.state.customerId}' and status='show'`
      );
      if (VideoList) {
        this.setState({ VideoList: [{ label: 'All', value: 'All' }, ...VideoList] });
      }
      socket.on(`responseTimestamp${this.state.customerId}`, data => {
        //  console.log(data)
        this.data.push(data);
      });
      setInterval(() => {
        let liveData = [];
        let v1 = [];
        let testdata = [];
        this.data.map((ival, i) => {
          if (v1.filter(jval => ival.videoId === jval.videoId).length === 0) {
            v1.push(ival);
          }
          if (liveData.filter(jval => ival.userId === jval.userId).length === 0) {
            // ival=ival.duration;
            liveData.push(ival);
            testdata.push({ duration: ival.timestamp, username: ival.userName })

          }
        })
        this.liveData = [...liveData];
        // console.log(testdata)
        this.setState({ socketTable: testdata })
        //  console.log(this.state.socketTable)
        this.v1 = v1;
        this.data = [];
        //  console.log(liveData)
        if (this.state.selectVideoList && this.state.selectVideoList.value) {
          this.content(this.state.selectVideoList);
        }
      }, 3000)
    } catch (error) {
      console.error(error);
    }
  }
  selectVideoList = selectVideoList => {
    this.setState({ selectVideoList });
    this.content(selectVideoList);
  }
  content = (selectVideoList) => {
    if (selectVideoList.value !== 'All') {
      let data = this.liveData;
      let Filter = []
      console.log(data, "data in datatable");
      // console.log(selectVideoList.value, "se");
      data.map((ival) => {
        if (ival.videoId == selectVideoList.value) {
          Filter.push(ival);
        }
      })
      this.setState({ data: Filter })
    } else {
      this.setState({ v1: this.v1 })
      // console.log(this.v1)
      // console.log(this.liveData)
    }
  }

  render() {
    const { data, VideoList, selectVideoList } = this.state;
    // console.log(this.data, 'data render')
    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div class="modal fade" id="myModal">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Modal Heading</h4>
                  <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                  <HorizontalBarChart1 dataValue={this.state.socketTable} barOptions={{ maintainAspectRatio: false }} />

                  {/* <BarChart dataValue={this.state.socketTable} barOptions={{ maintainAspectRatio: false }}  /> */}
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3>Video Seek</h3>
                </div>
                <div className="card-body" style={{ minHeight: 300 }}>
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-2">
                      <label htmlFor="group">Select Page</label>
                    </div>
                    <div className="col-sm-5">
                      <SingleSelect
                        options={VideoList}
                        handleChange={this.selectVideoList}
                        selectedService={selectVideoList}
                      />
                    </div>
                    <div className="col-sm-2">
                      {
                        selectVideoList && selectVideoList.value &&
                        <i
                          class="fa fa-times-circle"
                          style={{ fontSize: "25px" }}
                          onClick={() => {
                            this.setState({
                              selectVideoList: {}
                            })
                          }}>
                        </i>

                      }

                    </div>
                  </div>
                  {
                    selectVideoList && selectVideoList.value
                      ?
                      selectVideoList.value === 'All' && this.state.v1.length > 0
                        ?
                        <Datatable data={this.state.v1} columnHeading={this.column1} />
                        :
                        selectVideoList.value !== 'All' && data.length > 0
                          ?
                          <Datatable data={data} columnHeading={this.column} />
                          :
                          <div style={{ "marginLeft": "600px", "marginTop": "50px", "fontWeight": "bold", "fontSize": "larger" }}> No viewers currently </div>
                      :
                      <div style={{ "marginLeft": "600px", "marginTop": "50px", "fontWeight": "bold", "fontSize": "larger" }}> Please select course</div>
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default VideoSeekLog;
