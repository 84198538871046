import React, { Component } from "react";
import { Alert } from "reactstrap";
import cmsContent from "../../../../MiddleWare/CmsContent";
import Datatable from "../../../../components/Datatable/Datatable";
import LoginModal from "../../../../components/Modal/Modal";
import { ACCESS_POINT } from "../../../../config";
import { ACCESS_SAMPLE } from "../../../../config";
import http from "../../../../MiddleWare/httpMiddleWare";
import Progress from "../../../../components/Extra/Progress";
import SingleSelect from "../../../../components/Form/SingleSelect";
import Addformmodel from "./Addformmodel";

class Theme8 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			btnDisable: false,
			isEdit: false,
			uploadPercentage: 0,
			screen: window.screen.height,
			formList: [],
			formName: "",
			formId: 0,
			formSelected: {},
			data: props.that.data,
			isEdit: false,
			btnDisable: false,
			errorformName: '',
			formdetails: {},
			emailchecked: false,
			EmailId: '',
			errorEmail: '',
			screen: window.screen.height,
		}
	}
	column = [
		{
			Header: "Page",
			accessor: "name"
		},
		{
			Header: "Status",
			accessor: "status"
		},
		{
			Header: "Theme",
			accessor: "edit",
			Cell: d => this.showTheme(d)
		},
		{
			Header: "Visibility",
			accessor: "edit",
			Cell: d => this.showVisibility(d)
		},
		{
			Header: "Require Login",
			accessor: "requireLogin"
		},
		{
			Header: "Change Login Status",
			accessor: "edit",
			Cell: d => this.changeLoginStatus(d)
		},
		{
			Header: "Edit",
			accessor: "edit",
			Cell: d => this.Editpages(d)
		},
		{
			Header: "Preview",
			accessor: "edit",
			Cell: d => this.PreviewPage(d)
		}
	];
	showTheme = d => {
		return `Theme-${d.original.themeId}(To Add Form)`;
	}
	async componentWillMount() {
		const { customerId } = this.props.that;
		//console.log(customerId);
		try {
			//console.log(this.props.that)
			if (this.props.that.data) {

				let pages = [];
				this.props.that.data.map((ival, i) => {
					if (ival.themeId === 8) {
						pages.push(ival);
					}
				})
				this.setState({ data: pages, for: this.props.that.sheetContent });
				//Without selcting themes states
				if (this.props.that.sheetContent) {
					const result = await cmsContent.getFreedom(
						"id as value, name as label",
						"tbl_form",
						`companyid = ${this.props.that.customerId}`,
						"id",
						"id DESC"
					);
					if (result) {
						let arr = {};
						result.data.map((ival, i) => {
							if (ival.value === parseInt(this.props.that.sheetContent))
								arr.value = ival.value;
							arr.label = ival.label;
						})
						if (this.props.that.emailchecked == true) {
							let EmailId1 = JSON.parse(this.props.that.content1)
							//console.log(222);
							var screen = '';
							let wait = await EmailId1.map(async (ival, m) => {
								screen += ival + ",";
							})
							await Promise.all(wait);
							screen = screen.replace(/,\s*$/, '');
							this.setState({ emailchecked: this.props.that.emailchecked })
						}

						this.setState({ formSelected: arr, EmailId: screen, isEdit: true, editId: this.props.that.id, autherSelected: this.props.that.autherSelected, data: this.props.that.datatable })
					}
				}
			}
			////End////
			const result = await cmsContent.getFreedom(
				"id as value, name as label",
				"tbl_form",
				`companyid = ${customerId}`,
				"id",
				"id DESC"
			);
			if (result) {
				this.setState({ formList: result.data });
			}


		} catch (error) {
			console.log(error);
		}
	}

	PreviewPage = (d) => {

		return (<button
			type="button"
			className="btn btn-info"
			onClick={() => this.PreviewRoute(d)}
		>
			Preview
		</button>
		);
	}
	PreviewRoute = d => {
		let id = d.original.id;
		let customerId = d.original.customerId;
		if (id) {
			window.open(
				`${ACCESS_SAMPLE}/samplesite/th8/${id}/${customerId}`,
				"_blank"
			);
		}
	};



	showVisibility = id => {
		return (
			<button
				type="button"
				className="btn btn-info"
				onClick={() => this.updateVisibility(id)}
			>
				Hide/Show
			</button>
		);
	};
	updateVisibility = async value => {
		const index = value.index;
		const previousData = [...this.state.data];
		//console.log(previousData);
		const newData = { ...previousData[index] };

		if (newData.status === "show") {
			newData.status = "hide";
		} else {
			newData.status = "show";
		}
		const id = newData.id;
		const data = previousData.filter(value => value.id !== id);
		data.splice(index, 0, newData);
		try {
			const result = await cmsContent.updatePage(newData);
			if (result) {
				this.setState({ data: data });
			}
		} catch (error) {
			this.setState({ data: previousData });
			console.log(error);
		}
	};
	changeLoginStatus = id => {
		return (
			<button
				type="button"
				className="btn btn-info btn-lg col-lg-12" style={{paddingLeft:5,marginBottom:'4px',fontSize:'10px'}}
				onClick={() => this.updateLogin(id)}
			>
				Change Login Status
			</button>
		);
	};

	updateLogin = async value => {
		const index = value.index;
		const previousData = [...this.state.data];
		const newData = { ...previousData[index] };
		if (newData.requireLogin === "yes") {
			newData.requireLogin = "no";
		} else {
			newData.requireLogin = "yes";
		}
		const id = newData.id;
		const data = previousData.filter(value => value.id !== id);
		data.splice(index, 0, newData);
		this.setState({ data });
		try {
			const result = await cmsContent.updateLoginStatus(newData);
			if (result) {
				this.setState({ data: data });
			}
		} catch (error) {
			this.setState({ data: previousData });
			console.log(error);
		}
	};

	Editpages = value => {
		return (
			<button className="btn btn-info" onClick={() => this.onEdit(value)}>
				Edit
			</button>
		);
	};
	onEdit = async (d) => {
		console.log(d.original);
		const org = d.original;
		const index = d.index;

		const emailchecked = org.subTitleId;
		//console.log(emailchecked);

		var EmailId1 = JSON.parse(org.content1);

		//console.log(EmailId1);
		var screen = '';
		if (emailchecked == 'true') {

			console.log(222);

			let wait = await EmailId1.map(async (ival, m) => {
				screen += ival + ",";
			})
			await Promise.all(wait);
			screen = screen.replace(/,\s*$/, '');
			this.setState({ EmailId: screen, emailchecked: true })
		}
		else {
			//console.log(111);
			this.setState({ emailchecked: false, EmailId: '', errorEmail: '' })
		}
		const options = [
			{ label: "Theme1", value: "1" },
			{ label: "Theme2", value: "2" },
			{ label: "Theme3", value: "3" },
			{ label: "Theme4", value: "4" },
			{ label: "Theme5", value: "5" },
			{ label: "Theme6", value: "6" },
			{ label: "Theme7", value: "7" },
			{ label: "Theme8", value: "8" },
			{ label: "Theme9", value: "9" }
		];
		//console.log(emailchecked)
		let arr = {};
		// console.log(org.sheetContent);
		if (typeof this.props.these === 'function') {
			this.props.these('pageName', org.name);
			this.props.these('autherSelected', this.props.that.authorlist.filter(ival => ival.value === org.authorid)[0]);
			this.props.these('themeSelected', options.filter(ival => ival.value == org.themeId)[0]);
			this.props.these('checked', org.show == 1 ? 1 : 0);
			this.props.these('errorPageName', '');
		}

		//this.state.formList(ival => ival.value === parseInt(org.sheetContent)[0])
		this.state.formList.map((ival, i) => {
			if (ival.value === parseInt(org.sheetContent)) {
				console.log(ival)
				arr.value = ival.value;
				arr.label = ival.label;
			}
		});

		this.setState({ formSelected: arr, isEdit: true, Index: index, editId: org.id, errorEmail: '', searchTags: org.searchTags });
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		});

	}


	submitCategory = async e => {
		e.preventDefault();
		const {
			checked,
			autherSelected,
			pageName,
			customerId
		} = this.props.that;

		const { formSelected, formId, editId,
			Index, emailchecked, EmailId } = this.state;
		// console.log(formSelected);
		//console.log(formSelected);
		if (!autherSelected) {
			this.props.these('errorAuthorName', 'Select Author');
			return false
		} else if (!pageName) {
			this.props.these('errorAuthorName', '')
			this.props.these('errorPageName', 'Enter Page name');
			return false;
		} else if (formSelected == null || formSelected.value == undefined) {
			console.log('1dgdg22');
			//this.props.these('errorPageName', '');
			this.props.these('errorAuthorName', '');
			this.setState({ errorformName: 'Select Form name' });
			return false;
		}

		var arr = [];
		if (emailchecked == true) {
			if (!EmailId) {
				this.setState({ errorEmail: " Please Enter Email Address" })
				return false;
			}
			else {
				this.setState({ errorEmail: '' })
				console.log(EmailId)
				EmailId.split(",").map((item, i) => {
					// console.log(item)
					arr.push(item)
				})
				//console.log(arr)
			}
		}
		if (arr && arr.length !== null) {
			let wait = await arr.map(async (ival, m) => {
				var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
				if (!ival.match(mailformat)) {
					this.setState({ errorEmail: `Please Enter Correct Email-(${ival})` });
					return false;
				}
			});
			await Promise.all(wait)
			//console.log(this.state.errorEmail);
		}
		/*else
		{
			this.setState({errorEmail:''})
		}*/


		let today = new Date();
		var dateformat = today.getDate();
		if (today.getDate() < 10) {
			dateformat = "0" + today.getDate();
		}
		let date =
			today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + dateformat;
		const formData = new FormData();
		formData.append("name", pageName.trim());
		formData.append("themeId", 8);
		if (this.props.that.isEdit == true) {
			formData.append("authorid", autherSelected[0].value)
		}
		else {
			formData.append("authorid", autherSelected.value);
		}
		formData.append("show", checked);
		formData.append("customerId", customerId);
		formData.append("Date", date);
		formData.append("status", "show");
		formData.append("sheetContent", formSelected.value);
		formData.append("subTitleId", emailchecked);
		formData.append("searchTags", this.state.searchTags);
		if (emailchecked === true) {
			formData.append("content1", JSON.stringify(arr));
		}
		else {
			let dumy = [];
			formData.append("content1", JSON.stringify(dumy));
		}



		//console.log([...formData]);
		try {
			//console.log(this.state.errorEmail);
			if (this.state.errorEmail == '') {
				if (this.state.isEdit) {

					formData.append("id", editId);
					console.log([...formData]);
					let { data } = await http.put(
						ACCESS_POINT + `/cmsContent/tbl_pages`,
						formData,
						{
							headers: {
								"Content-Type": "multipart/form-data"
							},
							onUploadProgress: progressEvent => {
								this.setState({
									uploadPercentage: parseInt(
										Math.round((progressEvent.loaded * 100) / progressEvent.total)
									)
								});
								setTimeout(() => this.setState({ uploadPercentage: 0 }), 2000);
								///10000
							}
						}
					);
					// console.log(data);
					if (data && data.length) {
						//console.log(data);
						data = data[0];

						const previousData = [...this.state.data];

						if (this.props.that.isEdit) {
							let index = this.props.that.data.indexOf(this.props.that.data1);
							previousData[index] = data;

						}
						else {
							previousData[Index] = data;
						}
						//console.log(previousData);
						setTimeout(() => this.setState({
							data: previousData,
							errorformName: '',
							formSelected: {},
							isEdit: false,
							btnDisable: false,
							emailchecked: false,
							errorEmail: '',
							EmailId: '',
						}), 3000);
						this.props.these('pageName', '');
						this.props.these('checked', 0);
						this.props.these('errorPageName', '');
						this.props.these('autherSelected', '');
						this.props.these('themeSelected', '');
						this.props.these('isEdit', false)
						this.setState({ searchTags: '' })
						setTimeout(() => this.props.these('alertVisible', true), 2000);
					}
				}


				else {
					formData.append("requireLogin", 'no');
					let { data } = await http.post(
						ACCESS_POINT + `/cmsContent/tbl_pages`,
						formData,
						{
							headers: {
								"Content-Type": "multipart/form-data"
							},
							onUploadProgress: progressEvent => {
								this.setState({
									uploadPercentage: parseInt(
										Math.round((progressEvent.loaded * 100) / progressEvent.total)
									)
								});
								setTimeout(() => this.setState({ uploadPercentage: 0 }), 2000);
								///10000
							}
						}
					)
					if (data && data.length) {
						data = data[0];
						const previousData = [data, ...this.state.data];
						this.setState({
							data: previousData,
							errorformName: '',
							formSelected: {},
							emailchecked: false,
							EmailId: '',
							errorEmail: '',
							searchTags: ''
						});
						this.props.these('pageName', '');
						this.props.these('checked', 0);
						this.props.these('errorPageName', '');
						this.props.these('autherSelected', '');
						this.props.these('themeSelected', '')
						setTimeout(() => this.props.these('alertVisible', true), 2000);

						//console.log(data);
					}
					console.log([...formData])
				}
			}

		} catch (error) {
			console.log(error);
		}

	}
	handleForm = selectedForm => {
		//console.log(selectedForm);
		this.setState({ formSelected: selectedForm, formId: selectedForm.value, formName: selectedForm.label });
	}

	formdetails = async (s, v) => {
		this.setState({ [s]: v });
		if (v) {
			const previousData = [v, ...this.state.formList];
			this.setState({ formList: previousData });
		}
	}
	CheckBox = e => {
		if (this.state.emailchecked == true) {
			this.setState({ emailchecked: false, EmailId: '', errorEmail: '' })
		}
		else {
			this.setState({ emailchecked: true })
		}
		// this.setState({emailchecked:!this.state.emailchecked})

	}
	aboutvalue = e => {
		let about = e.target.value;
		this.setState({ EmailId: about });

	};
	refresh = async e => {
		try {

			const { data: formList } = await cmsContent.getFreedom(
				"id as value,name as label",
				"tbl_form",
				`companyid=${this.props.that.customerId}`,
				"id",
				"id DESC"
			);
			if (formList) {
				formList.map((ival, i) => {
					if (i == 0) {
						console.log(ival);
						this.setState({ formSelected: ival });
					}
				});

				// this.handleForm(formSelected);
				this.setState({ formList });
			}
		} catch (error) {
			console.log(error);
		}
	};
	addform = e => {
		window.open("/addform")
	}
	handlechange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		const { uploadPercentage, formList, formSelected, EmailId } = this.state;
		//console.log(formList);
		return (
			<React.Fragment>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2">
						<label htmlFor="exampleInputEmail1">Search Tags</label>
					</div>
					<div className="col-sm-5">
						<textarea
							type="text"
							className="form-control"
							id="searchTags"
							name="searchTags"
							placeholder="Enter Search Tags"
							value={this.state.searchTags}
							onChange={this.handlechange}
						/>
					</div>
					<div className="col-sm-3" />
				</div>

				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2">
						<label htmlFor="exampleInputEmail1">
							Form Name
                 </label>
					</div>
					<div className="col-sm-5">
						<SingleSelect
							options={formList}
							handleChange={d => this.handleForm(d)}
							selectedService={formSelected}
						/>
					</div>
					<div className="col-sm-3">
						<button
							type="button"
							className='btn btn-primary'
							onClick={this.addform}
						>
							Add Form
					</button>
					</div>

				</div>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2" />
					<div className="col-sm-5">
						<button
							type="button"
							className='btn btn-primary'
							onClick={this.refresh}
						>
							Refresh
					</button>
					</div>
					<div className="col-sm-3" />
				</div>
				{/*
				<div className="col-sm-3">

					<LoginModal
						buttonTitle="Add Form"
						title="Add Form"
						id="form"
						extraClass="btn-width"
						maxwidth="modal-xl"
						extraStyle={{
							width: "1140px",
							left: "-300px"
						}}
						onClick={this.reset}
						bodyText={
							<Addformmodel these={this.formdetails} />
						}
					/>
				</div> */}

				<div className="row form-group">
					<div className="col-sm-4" />
					<span className="error-show ">{this.state.errorformName}</span>
				</div>
				{this.state.emailchecked == true ? (
					<div className="row form-group">
						<div className="col-sm-2" />
						<div className="col-sm-2">
							<label htmlFor="exampleInputEmail1">
								Email
                 </label>
						</div>

						<div className="col-sm-6">
							<textarea
								type="text"
								className="form-control"
								id="Authorabout"
								name="Authorabout"
								placeholder="Enter EmailId"
								value={this.state.EmailId}
								onChange={e => this.aboutvalue(e)}
							/>
						</div>
					</div>

				) : (
						null
					)}

				{this.state.emailchecked == true ? (
					<div className="row form-group">
						<div className="col-sm-4" />
						<span className="error-show ">{this.state.errorEmail}</span>
					</div>) : (null)}

				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2" />
					<div className="col-sm-2">
						<div class="form-check">
							<input
								type="checkbox"
								class="form-check-input"
								id="ShowChecked"
								onClick={this.CheckBox}
								checked={this.state.emailchecked}
							/>
							<label class="form-check-label" for="materialChecked">
								Email This Form
                          </label>

						</div>

					</div>

				</div>

				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2" />
					<div className="col-sm-5">
						<button
							type="button"
							className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
							disabled={this.state.btnDisable}
							onClick={this.submitCategory}
						>
							{this.state.isEdit ? 'Update' : 'Add Page'}
						</button>
					</div>
					<div className="col-sm-3" />
				</div>

				<div className="row form-group">
					<div className="col-sm-12">
						{this.state.data.length ? (<Datatable
							data={this.state.data}
							columnHeading={this.column}
						/>) : null}
					</div>
				</div>
			</React.Fragment>
		)
	}
}
export default Theme8;

