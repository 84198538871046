import React, { Component } from "react";
import { Alert } from "reactstrap";
import http from "../../../../MiddleWare/httpMiddleWare";
import Datatable from "../../../../components/Datatable/Datatable";
import { ACCESS_POINT } from "../../../../config";
import { ACCESS_SAMPLE } from "../../../../config";
import Progress from "../../../../components/Extra/Progress";
import cmsContent from "../../../../MiddleWare/CmsContent";
import { Editor } from "@tinymce/tinymce-react";
import renderHTML from "react-render-html";
import LoginModal from "../../../../components/Modal/Modal";
import AddLibaryModel from "../AddLibaryModel";
import { CopyToClipboard } from "react-copy-to-clipboard";
import exportFromJSON from "export-from-json";

class Theme7 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      btnDisable: false,
      uploadPercentage: 0,
      content: "<p></p>",
      isEdit: false,
      uploadPercentage: 0,
      screen: window.screen.height,
      alertVisible: false,
      customerId: localStorage.getItem("userId")
    };
  }
  async componentWillMount() {
    try {
      /// console.log(this.props.that);
      if (this.props.that.data) {
        let pages = [];
        this.props.that.data.map((ival, i) => {
          if (ival.themeId === 7) {
            pages.push(ival);
          }
        });
        this.setState({
          data: pages
        });
      }
      if (this.props.that.isEdit) {
        // console.log("edit");
        let content = this.props.that.theme4
          .split("SDAS/Video/")
          .join(`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/`);

        this.setState({ isEdit: this.props.that.isEdit, editId: this.props.that.id, content, data: this.props.that.datatable });
      }
    } catch (e) {
      console.log(e);
    }
  }
  async componentDidMount() {
    //     document.querySelectorAll('img').forEach(function(item) {
    //      // item.src = "img/shoe.png";
    // console.log('thjer')
    //      console.log(item.src)
    //     })


    try {
      const { data: List } = await cmsContent.getFreedom(
        "*",
        "tbl_image",
        `customerId=${this.state.customerId}`,
        "id",
        "id DESC"
      );
      if (List) {
        this.setState({ List });
      }
    } catch (error) {
      console.log(error);
    }
  }
  column = [
    {
      Header: "Page",
      accessor: "name"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "Theme",
      accessor: "edit",
      Cell: d => this.showTheme(d)
    },
    {
      Header: "Visibility",
      accessor: "edit",
      Cell: d => this.showVisibility(d)
    },
    {
      Header: "Require Login",
      accessor: "requireLogin"
    },
    {
      Header: "Change Login Status",
      accessor: "edit",
      Cell: d => this.changeLoginStatus(d)
    },
    {
      Header: "Edit",
      accessor: "edit",
      Cell: d => this.Editpages(d)
    },
    {
      Header: "Preview",
      accessor: "edit",
      Cell: d => this.PreviewPage(d)
    }
  ];

  Lib_column = [
    {
      Header: "Hint",
      accessor: "type"
    },
    {
      Header: "Link",
      accessor: "file",
      Cell: d => this.fullLink(d)
    },
    {
      Header: "Type",
      accessor: "file_extension",
      Cell: d => this.file_extension(d)
    },
    {
      Header: "Image",
      accessor: "file_extension",
      Cell: d => this.showimage(d)
    },
    {
      Header: "Copy",
      accessor: "file",
      Cell: d => this.clipBoard(d)
    }
  ];
  showTheme = d => {
    return `Theme-${d.original.themeId}(HTML Editor)`;
  }
  file_extension = d => {
    let validationImage = ["jpeg", "jpg", "png", "gif"];
    let validationVideo = [
      "ogg",
      "ogv",
      "mpg",
      "mpeg",
      "mp4",
      "mp3",
      "dat",
      "avi"
    ];
    let ImageExtension = d.original.file_extension.toLowerCase();
    if (validationImage.indexOf(ImageExtension) !== -1) {
      return "Image";
    } else if (validationVideo.indexOf(ImageExtension) !== -1) {
      return "Video";
    } else {
      return "NA";
    }
  };
  showimage = (d) => {
    let validationImage = ["jpeg", "jpg", "png", "gif"];
    let validationVideo = [
      "ogg",
      "ogv",
      "mpg",
      "mpeg",
      "mp4",
      "mp3",
      "dat",
      "avi"
    ];
    let ImageExtension = d.original.file_extension.toLowerCase();
    if (validationImage.indexOf(ImageExtension) !== -1) {
      return (
        // <img src={`${ACCESS_POINT}/superAdmin/file?fileurl=${d.original.file}`} style={{ width: 25, height: 25 }} />
        <img src={`${ACCESS_POINT}/superAdmin/sharefile?filename=${d.original.file}`} style={{ width: 25, height: 25 }} />

      )
    } else if (validationVideo.indexOf(ImageExtension) !== -1) {
      return "Video";
    } else {
      return "NA";
    }


  }
  clipBoard = d => {
    return (
      <div>
        <CopyToClipboard
          // text={`${ACCESS_POINT}/superAdmin/file?fileurl=${d.original.file}`}
          text={`${ACCESS_POINT}/superAdmin/sharefile?filename=${d.original.file}`}
          onCopy={() => this.setState({ copied: true })}
        >
          <button type="button" className="btn btn-primary">
            Copy to clipboard
          </button>
        </CopyToClipboard>
      </div>
    );
  };

  fullLink = d => {
    // return `${ACCESS_POINT} /superAdmin/file ? fileurl = ${d.original.file} `;
    return `${ACCESS_POINT} /superAdmin/sharefile?filename = ${d.original.file} `;
  };

  PreviewPage = d => {
    return (
      <button
        type="button"
        className="btn btn-info"
        onClick={() => this.PreviewRoute(d)}
      >
        Preview
      </button>
    );
  };

  PreviewRoute = d => {
    let id = d.original.id;
    let customerId = d.original.customerId;
    if (id) {
      window.open(
        `${ACCESS_SAMPLE} /samplesite/th7 / ${id} /${customerId}`,
        "_blank"
      );
    }
  };
  showVisibility = id => {
    return (
      <button
        type="button"
        className="btn btn-info"
        onClick={() => this.updateVisibility(id)}
      >
        Hide/Show
      </button>
    );
  };
  updateVisibility = async value => {
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };

    if (newData.status === "show") {
      newData.status = "hide";
    } else {
      newData.status = "show";
    }
    const id = newData.id;
    const data = previousData.filter(value => value.id !== id);
    data.splice(index, 0, newData);
    try {
      const result = await cmsContent.updatePage(newData);
      if (result) {
        this.setState({ data: data });
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };
  changeLoginStatus = id => {
    return (
      <button
        type="button"
        className="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '10px' }}
        onClick={() => this.updateLogin(id)}
      >
        Change Login Status
      </button>
    );
  };

  updateLogin = async value => {
    const index = value.index;
    const previousData = [...this.state.data];
    const newData = { ...previousData[index] };
    if (newData.requireLogin === "yes") {
      newData.requireLogin = "no";
    } else {
      newData.requireLogin = "yes";
    }
    const id = newData.id;
    const data = previousData.filter(value => value.id !== id);
    data.splice(index, 0, newData);
    this.setState({ data });
    try {
      const result = await cmsContent.updateLoginStatus(newData);
      if (result) {
        this.setState({ data: data });
      }
    } catch (error) {
      this.setState({ data: previousData });
      console.log(error);
    }
  };
  Editpages = value => {
    return (
      <button className="btn btn-info" onClick={() => this.onEdit(value)}>
        Edit
      </button>
    );
  };
  onEdit = async d => {
    try {
      let org = d.original;
      let index = d.index;
      const options = [
        { label: "Theme1", value: "1" },
        { label: "Theme2", value: "2" },
        { label: "Theme3", value: "3" },
        { label: "Theme4", value: "4" },
        { label: "Theme5", value: "5" },
        { label: "Theme6", value: "6" },
        { label: "Theme7", value: "7" },
        { label: "Theme8", value: "8" },
        { label: "Theme9", value: "9" }
      ];
      if (typeof this.props.these === "function") {
        this.props.these("pageName", org.name);
        this.props.these(
          "autherSelected",
          this.props.that.authorlist.filter(
            ival => ival.value === org.authorid
          )[0]
        );
        this.props.these('themeSelected', options.filter(ival => ival.value == org.themeId)[0]);
        this.props.these("checked", org.show == 1 ? 1 : 0);
        this.props.these("errorPageName", "");
        this.props.these("errorAuthorName", "");
        this.setState({
          contentTitle1: org.contentTitle1,
          searchTags: org.searchTags
        });
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      }
      let username = JSON.parse(localStorage.getItem('userlog')).userName
      let content = org.sheetContent

      // let imgsrcchange;
      // if (content) {
      //   imgsrcchange = content.split(username + '/').join(`${ACCESS_POINT}/superAdmin/file?fileurl=${username}/`)
      // }
      // .split("SDAS/Video/")
      // .join(`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/`);
      // this.setState({ content: imgsrcchange, isEdit: true, editId: org.id, Index: index });
      this.setState({ content,isEdit: true, editId: org.id, Index: index });

    } catch (error) {
      console.log(error);
    }

  };
  handleModelChange = model => {
    this.setState({
      content: model
    });
  };

  submitCategory = async () => {
    const { checked, autherSelected, pageName, customerId } = this.props.that;
    let { content, Index } = this.state;
    let Images = [];
    if (!autherSelected) {
      this.props.these("errorAuthorName", "Select Author");
      return false;
    } else if (!pageName) {
      this.props.these("errorAuthorName", "");
      this.props.these("errorPageName", "Enter Page name");
      return false;
    }
    // content = content
    //   .split(`${ACCESS_POINT}/superAdmin/file?fileurl=`)
    //   .join("");

    // content = content
    //   .split(`${ACCESS_POINT}/superAdmin/sharefile?filename=`)
    //   .join("");
    const formData = new FormData();
    formData.append("name", pageName);
    formData.append("themeId", "7");
    if (this.props.that.isEdit == true) {
      formData.append("authorid", autherSelected[0].value);
    }
    else {
      formData.append("authorid", autherSelected.value)
    }
    formData.append("show", checked);
    formData.append("status", "show");
    formData.append("customerId", customerId);
    formData.append("sheetContent", content);
    formData.append("searchTags", this.state.searchTags);
    try {
      if (this.state.isEdit) {

        formData.append("id", this.state.editId);
        console.log([...formData])
        let { data } = await http.put(
          ACCESS_POINT + `/cmsContent/tbl_pages`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => {
              this.setState({
                uploadPercentage: parseInt(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                )
              });
              setTimeout(() => this.setState({ uploadPercentage: 0 }), 10000);
            }
          }
        );
        if (data && data.length) {
          data = data[0];
          const previousData = [...this.state.data];

          if (this.props.that.isEdit) {
            let index = this.props.that.data.indexOf(this.props.that.data1);
            previousData[index] = data;


          }
          else {
            previousData[Index] = data;
          }
          setTimeout(
            () =>
              this.setState({
                data: previousData,
                content: "<p></p>",
                isEdit: false,
                btnDisable: false
              }),
            10000
          );
          this.props.these("pageName", "");
          this.props.these("checked", 0);
          this.props.these("errorPageName", "");
          this.props.these('autherSelected', '');
          this.props.these('themeSelected', '');
          this.props.these('isEdit', false)
          this.setState({ searchTags: '' })
          setTimeout(() => this.props.these("alertVisible", true), 10000);
        }
      } else {
        formData.append("requireLogin", "no");
        let { data } = await http.post(
          ACCESS_POINT + `/cmsContent/tbl_pages`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            },
            onUploadProgress: progressEvent => {
              this.setState({
                uploadPercentage: parseInt(
                  Math.round((progressEvent.loaded * 100) / progressEvent.total)
                )
              });
              setTimeout(() => this.setState({ uploadPercentage: 0 }), 10000);
            }
          }
        );
        if (data && data.length) {
          data = data[0];
          const previousData = [data, ...this.state.data];

          setTimeout(
            () =>
              this.setState({
                data: previousData,
                content: "<p></p>",
                isEdit: false,
                btnDisable: false
              }),
            10000
          );
          this.props.these("pageName", "");
          this.props.these("checked", 0);
          this.props.these("errorPageName", "");
          this.props.these('autherSelected', '');
          this.props.these('themeSelected', '')
          this.setState({ searchTags: '' })
          setTimeout(() => this.props.these("alertVisible", true), 10000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  onDismiss = () => {
    this.setState({ alertVisible: false });
  };
  selectImage = e => {
    this.setState({ file: e.target.files[0] });
    this.setState({ fileName: e.target.files[0].name });
  };
  submit = async e => {
    e.preventDefault();
    const { file, hint } = this.state;
    if (!file) {
      this.setState({ errorFile: "Select File to Upload" });
      return false;
    } else if (!hint) {
      this.setState({ errorFile: "", errorHint: "Enter Searchable hint" });
      return false;
    } else {
      this.setState({ errorFile: "", btnDisable: true });
      let customerId = this.state.customerId;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", hint);
      formData.append("customerId", customerId);
      try {
        const Result = await cmsContent.insertImage(formData, "tbl_image");

        if (Result) {
          console.log(Result);
          let list = this.state.List;
          list.push(Result.data);
          this.setState({
            List: list,
            file: "",
            errorFile: "",
            btnDisable: false,
            alertVisible: true,
            fileName: "choose File",
            errorHint: null
          });
          //remove success msg after 3 sec
          setTimeout(() => this.setState({ alertVisible: false }), 3000);
        }
      } catch (error) { }
    }
  };
  handlechange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { uploadPercentage } = this.state;
    return (
      <React.Fragment>

        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label htmlFor="exampleInputEmail1">Search Tags</label>
          </div>
          <div className="col-sm-5">
            <textarea
              type="text"
              className="form-control"
              id="searchTags"
              name="searchTags"
              placeholder="Enter Search Tags"
              value={this.state.searchTags}
              onChange={this.handlechange}
            />
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2" />
          <div className="col-sm-5">
            <h2 style={{ color: "#000" }}>Content</h2>
          </div>
          <div style={{ marginLeft: "83px" }} className="col-sm-4">
            <LoginModal
              buttonTitle="Add Media to Library"
              title="Add Media to Library"
              id="library"
              extraClass="btn-width"
              maxwidth="modal-lg"
              onClick={this.reset}
              bodyText={
                <AddLibaryModel
                  selectImage={this.selectImage}
                  fileName={this.state.fileName}
                  handleChange={e => this.setState({ hint: e.target.value })}
                  dismiss={this.onDismiss}
                  disableValue={this.state.btnDisable}
                  alertMsg="New Library Added"
                  submit={this.submit}
                  alertVisible={this.state.alertVisible}
                  List={this.state.List}
                  column={this.Lib_column}
                />
              }
            />
          </div>
        </div>
        <div className="row form-group">
          <div className="col-sm-1" />
          <div className="col-sm-10" style={{ zIndex: 0, minHeight: 150 }}>
            <Editor
              apiKey="sqrfu0ffkb8d8fdyjqgxuvtkcvru79noq34s32622j87etzv"
              // initialValue={this.state.content}
              value={this.state.content}
              init={{
                menubar: true,
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount"
                ],
                toolbar:
                  "undo redo | formatselect | bold italic backcolor | \
alignleft aligncenter alignright alignjustify | \
bullist numlist outdent indent | removeformat | code | preview | help"
              }}
              onEditorChange={this.handleModelChange}
            />
          </div>
          <div className="col-sm-1" />
        </div>

        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2" />
          <div className="col-sm-5">
            <button
              type="button"
              className={`btn ${this.state.isEdit ? "btn-secondary" : "btn-primary"
                }`}
              disabled={this.state.btnDisable}
              onClick={this.submitCategory}
            >
              {this.state.isEdit ? "Update" : "Add Page"}
            </button>
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-7">
            {uploadPercentage > 0 && <Progress percentage={uploadPercentage} />}
          </div>
          <div className="col-sm-3" />
        </div>

        <div className="row form-group">
          <h2>Check</h2>
        </div>
        <div className="row form-group">
          <div className="col-sm-12">
            {this.state.data ? (
              <Datatable data={this.state.data} columnHeading={this.column} />
            ) : null}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Theme7;
