import React from "react";
import CmsContent from "../../../../MiddleWare/CmsContent";
class Type7 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errorurljson:'',
            boxtext:'',
            errortext:'',
            button:this.props.button,
            customerId: localStorage.getItem("userId")
          }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps) {
          this.setState({
            button: nextProps.button,
            boxname: nextProps.boxname,
            boxvalue: nextProps.boxvalue,
            thumbnail: nextProps.thumbnail,
            traytext: nextProps.traytext,
            portletHeading: nextProps.portletHeading,
            descriptionText: nextProps.descriptionText,
            newdate: nextProps.newdate
          });
        }
      }

      is_url(str) {
        var regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (regexp.test(str)) {
          return true;
        } else {
          return false;
        }
      }


    onSubmit =()=>{
//console.log('onSubmit')


if(!this.state.boxname) 
{

this.props.errormessageboxname('errorname','Please Enter Box Name');
console.log('boxname empty')

}
else
{
 this.props.errormessageboxname('errorname',''); 

}


if(!this.state.portletHeading) 
{
 console.log('portletHeading empty')
 this.props.errormessageheading('errorheading','Please Enter Portlet Heading');

}
 
else
{
 this.props.errormessageheading('errorheading',''); 

}

if(!this.state.thumbnail) 
{
  this.props.errorthumbnail('errorthumbnail','Please Select a thumbnail');

}
 
else
{
 this.props.errorthumbnail('errorthumbnail',''); 

}

if(!this.state.boxurljson) 
{
 
    this.setState({ errorurljson :'Please Enter URL' })
}
 
else
{
 
    this.setState({ errorurljson :'' })

}


 !this.state.boxtext ? this.setState({ errortext :'Please Enter JSon' }) :this.setState({ errortext :'' })

 if (!this.is_url(this.state.boxurljson)) {
    this.setState({ errorurljson: "Please enter a valid URL" });
    return false;
  }

 const formData = new FormData();

 formData.append('name',this.state.boxname)
 formData.append("type", this.state.boxvalue)
 formData.append("notificationtext", this.state.traytext);
 formData.append("thumbnail", this.state.thumbnail);
formData.append("heading", this.state.portletHeading);
formData.append("description", this.state.descriptionText);
formData.append("customerId", this.state.customerId);
formData.append("status", "active");
formData.append("createdAt", this.state.newdate);
formData.append("url", this.state.boxurljson);
formData.append("text", this.state.boxtext);
  
 if(  
    this.state.boxname && this.state.portletHeading && this.state.thumbnail && this.state.boxtext && this.state.boxurljson)
    {

        console.log([...formData])
        this.submitCommon(formData);
    }

}


submitCommon = async (formData) =>{

   // this.props.handler(this.state.boxvalue,this.props.alertVisible)
   //addMaster

    try {
      const result = await CmsContent.mappingportlet(
        formData,
        "tbl_boxcontent"
      );
      console.log(result.data)
      if (result) {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
         
        this.props.handler(this.state.boxvalue,this.props.alertVisible)

        // this.setState({ alertVisible: true, boxvalue: "" });
        // setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }

        

    
}

    jsonUrl = e => {
        this.setState({ boxurljson: e.target.value });
      };

      boxtext = e => {
        this.setState({ boxtext: e.target.value });
      };


  addtext =  async () =>
  {


    if(!this.state.boxname) 
    {
    
    this.props.errormessageboxname('errorname','Please Enter Box Name');
    console.log('boxname empty')
    
    }
    else
    {
     this.props.errormessageboxname('errorname',''); 
    
    }
    
    
    if(!this.state.portletHeading) 
    {
     console.log('portletHeading empty')
     this.props.errormessageheading('errorheading','Please Enter Portlet Heading');
    
    }
     
    else
    {
     this.props.errormessageheading('errorheading',''); 
    
    }
    
    if(!this.state.thumbnail) 
    {
      this.props.errorthumbnail('errorthumbnail','Please Select a thumbnail');
    
    }
     
    else
    {
     this.props.errorthumbnail('errorthumbnail',''); 
    
    }
    
    if(!this.state.boxurljson) 
    {
     
        this.setState({ errorurljson :'Please Enter URL' })
    }
     
    else
    {
     
        this.setState({ errorurljson :'' })
    
    }
    
    
     !this.state.boxtext ? this.setState({ errortext :'Please Enter JSon' }) :this.setState({ errortext :'' })
    
     if (!this.is_url(this.state.boxurljson)) {
        this.setState({ errorurljson: "Please enter a valid URL" });
        return false;
      }
    
     const formData = new FormData();
    
     formData.append('name',this.state.boxname)
     formData.append("type", this.state.boxvalue)
     formData.append("notificationtext", this.state.traytext);
     formData.append("thumbnail", this.state.thumbnail);
    formData.append("heading", this.state.portletHeading);
    formData.append("description", this.state.descriptionText);
    formData.append("customerId", this.state.customerId);
    formData.append("status", "active");
    formData.append("createdAt", this.state.newdate);
    formData.append("url", this.state.boxurljson);
    formData.append("text", this.state.boxtext);
      
     if(  
        this.state.boxname && this.state.portletHeading && this.state.thumbnail && this.state.boxtext && this.state.boxurljson)
        {
    
            console.log([...formData])
            this.submitCommon(formData);
        }
    


  }


    render() { 
        const {errorurljson,boxtext,errortext}=this.state;
        return ( <React.Fragment>
            <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="exampleInputEmail1">Url</label>
                            </div>
                            <div className="col-sm-5">
                              <input
                                type="text"
                                className="form-control"
                                id="URL"
                                name="URL"
                                placeholder="Enter Url"
                                onChange={this.jsonUrl}
                              />
                              <span className="error-shows ">
                                {errorurljson}
                              </span>
                            </div>
                            <div className="col-sm-3" />
                          </div>
                          <div className="row form-group">
                            <div className="col-sm-2" />
                            <div className="col-sm-2">
                              <label htmlFor="exampleInputEmail1">Json</label>
                            </div>
                            <div className="col-sm-5">
                              <textarea
                                type="text"
                                className="form-control"
                                placeholder="Paste Json Here"
                                onChange={this.boxtext}
                                value={boxtext}
                              />
                              <span className="error-shows ">{errortext}</span>
                            </div>
                            <div className="col-sm-3" />
                          </div>


          <div className="row form-group">
                <div className="col-sm-4" />
                <div className="col-sm-5">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={
                      this.state.button === "Add" ? this.addtext : this.onSubmit
                    }
                  >
                    {this.state.button}
                  </button>
                </div>
                <div className="col-sm-3" />
           </div> 

              </React.Fragment> );
    }
}
 
export default Type7 ;