import http from './httpMiddleWare';
import { ACCESS_POINT } from '../config';
let device =JSON.parse(localStorage.getItem("info"));
let userId =JSON.parse(localStorage.getItem("userId"));
// if(userId){
//   device.id=userId;
// }

const getServiceName = async serviceId => {
  const result = await http.get(ACCESS_POINT + `/superAdmin/service/tbl_services/${serviceId}`);
  return result;
};

const getFieldAgents = async customerId => {
  const result = await http.get(ACCESS_POINT + `/superAdmin/tbl_field_agent`);
  return result;
};

const createFieldAgent = async userArray => {
  const result = await http.post(ACCESS_POINT + `/superAdmin/tbl_field_agent`, userArray);
  return result;
};

const getManagers = async customerId => {
  const result = await http.get(ACCESS_POINT + `/superAdmin/tbl_manager`);
  return result;
};

const createManager = async userArray => {
  const result = await http.post(ACCESS_POINT + `/superAdmin/tbl_manager`, userArray);
  return result;
};

const getManagerForSelect = async customerId => {
  const result = await http.get(ACCESS_POINT + `/superAdmin/tbl_manager/customerIdSelect/${customerId}`);
  return result;
};

const getAgentForSelect = async customerId => {
  const result = await http.get(ACCESS_POINT + `/superAdmin/tbl_field_agent/customerIdSelect/${customerId}`);
  return result;
};

const mapAgentToManager = async userArray => {
  const result = await http.post(ACCESS_POINT + `/superAdmin/dynamic/tbl_mapping_agent_to_manager`, userArray);
  return result;
};

const getMappedAgent = async customerId => {
  const result = await http.get(ACCESS_POINT + `/superAdmin/customerFullList/${customerId}`);
  return result;
};

const getMasterValue = async tableName => {
  const result = await http.get(ACCESS_POINT + `/superAdmin/${tableName}`);
  return result;
};

const createMasterValue = async (tableName, masterValue) => {
  const result = await http.post(ACCESS_POINT + `/superAdmin/dynamic/${tableName}`, masterValue);
  return result;
};

const getMasterValueById = async (tableName, masterId) => {
  const result = await http.get(ACCESS_POINT + `/superAdmin/${tableName}/${masterId}`);
  return result;
};

const getMasterForSelect = async (tableName, customerId) => {
  const result = await http.get(ACCESS_POINT + `/superAdmin/${tableName}/customerIdSelect/${customerId}`);
  return result;
};

const getMasterForCompany = async (tableName, customerId) => {
  const result = await http.get(ACCESS_POINT + `/superAdmin/${tableName}/customerFullList/${customerId}`);
  return result;
};

const editMasterValue = async (tableName, masterId, masterValue) => {
  const result = await http.put(ACCESS_POINT + `/superAdmin/dynamic/${tableName}/${masterId}`, masterValue);
  return result;
};

const deleteMasterValue = async (tableName, masterId) => {
  const result = await http.delete(ACCESS_POINT + `/superAdmin/dynamic/${tableName}/${masterId}`);
  return result;
};
const getAppointmentList = async tableName => {
  const result = await http.get(ACCESS_POINT + `/superAdmin/appointment`);
  return result;
};

const getAllService = async () => {
  // console.log(ACCESS_POINT + `/superAdmin/getAllServices`);
  const result = await http.get(ACCESS_POINT + `/superAdmin/getAllServices`);
  return result;
};

const getFreedom = async (
  select,
  tableName,
  condition,
  groupby = "id",
  orderby = "id"
) => {
  let value = {};
  value.select = select;
  value.tableName = tableName;
  value.condition = condition;
  value.groupby = groupby;
  value.orderby = orderby;
  const result = await http.put(
    ACCESS_POINT + `/cmsContent/getFullFreedom/getFreedom`,
    value
  );
  console.log(result)
  if(result.data){
    return result;
  } else {
    localStorage.clear();
    window.location = "/";
  }
};

export default {
  getServiceName,
  getFieldAgents,
  createFieldAgent,
  createManager,
  getManagers,
  getAgentForSelect,
  getManagerForSelect,
  mapAgentToManager,
  getMappedAgent,
  getMasterValue,
  createMasterValue,
  getMasterValueById,
  getMasterForSelect,
  getMasterForCompany,
  editMasterValue,
  deleteMasterValue,
  getAppointmentList,
  getAllService,
  getFreedom
};
