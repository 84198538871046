import React, { Component } from "react";
import CmsContent from "../../../MiddleWare/CmsContent";
import { Alert } from "reactstrap";

class AddquizmodelBc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quizName: "",
      send_json: [],
      alertVisible: false,
      optionCount: "",
      optionJson: [],
      optionListArray: [],
      optionJsonArray: [],
      data: [],
      dataListArray: [],
      customerId: localStorage.getItem("userId"),
      data_1: []
    };
  }

  handlechange = e => {
    if (e.target.name === "optionCount") {
      this.setState({ optionJson: [] });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  JSON_refill = (quiz_value, quiz_name, index) => {

    let send_json = this.state.send_json;
    if (index + 1) {
      var JSON_data = send_json[index];
      JSON_data[quiz_name] = quiz_value;
      JSON_data['questionId'] = index;
      send_json[index] = JSON_data;
    }
    this.setState({ send_json: send_json });
    console.log(send_json, 'send_json');
  };
  appendOption = (value, key, index) => {

    let send_json = this.state.send_json;
    if (index + 1) {
      var JSON_data = send_json[index];
      JSON_data["options"][key] = value;
      send_json[index] = JSON_data;
    }
    send_json[index].count = send_json[index].options.length;
    this.setState({ send_json: send_json });
  };

  optionAnswer = async (value, key, index) => {
    if (document.getElementById(`option${key + 1}index${index + 1}`).checked) {
      let checkedstate = `option${key + 1}index${index + 1}`;

      document.getElementById(`options${key + 1}indexs${index + 1}`).innerHTML = 'Checked';
      //console.log(document.getElementById(`option${key + 1}index${index+1}`).checked )
    }
    else {
      document.getElementById(`options${key + 1}indexs${index + 1}`).innerHTML = '';
    }
    let send_json = this.state.send_json;
    if (index + 1) {
      var JSON_data = send_json[index];

      if (JSON_data["answer"]) {
        // let oldAnswer = JSON_data["answer"];
        // let newValue = `${oldAnswer},${value}`;
        // JSON_data["answer"] = newValue;
        if (document.getElementById(`option${key + 1}index${index + 1}`).checked) {
          let oldAnswer = JSON_data["answer"];
          let newValue = `${oldAnswer},${value}`;
          JSON_data["answer"] = newValue;
        } else {
          let str1 = document.getElementById(`option${key + 1}index${index + 1}`).value
          let newValue = JSON_data["answer"].split(',').length > 1 ? JSON_data["answer"].replace(str1, '') : null;
          if (newValue) {
            JSON_data["answer"] = this.trimCommas(newValue);
          } else {
            JSON_data["answer"] = ""
          }
        }
      } else {
        if (document.getElementById(`option${key + 1}index${index + 1}`).checked) {
          JSON_data["answer"] = value;
        }
      }
    }
    console.log(JSON_data, "JSON_data");
  };
  trimCommas = (s) => {
    s = s.replace(/,*$/, "");
    s = s.replace(/^\,*/, "");
    return s;
  }
  handlePhoneCount = (value, index) => {


    const { optionListArray } = this.state;
    for (let i = 0; i <= index; i++) {
      if (i === index) {
        optionListArray.splice(index, 1);
        let optionObject = {};
        optionObject.index = index;
        optionObject.value = value;
        optionListArray.splice(index, 0, optionObject);
      } else {


        let optionObject = {};
        optionObject.index = null;
        optionObject.value = null;
        optionListArray.splice(index, 0, optionObject);

      }
    }
    this.setState({ optionListArray });
  };

  printOptions = index => {

    const { optionJson, optionListArray, optionJsonArray } = this.state;
    this.setState({ optionJson: [] });
    let total = optionListArray[index].value;
    for (let i = 0; i < total; i++) {
      optionJson.push({ option: null });
    }
    optionJsonArray.splice(index, 0, optionJson);
    this.setState({ optionJsonArray });

  };

  addSingleChoice = (type) => {
    const { send_json } = this.state;
    send_json.push({
      question: null,
      answer: null,
      type,
      options: [],
      count: null,
      questionId: null
    });
    this.setState({ send_json });
    // console.log(send_json);
  };

  removeQuiz = index => {
    const { send_json, optionJsonArray } = this.state;
    send_json.splice(index, 1);
    optionJsonArray.splice(index, 1);
    if (send_json.length != index) {
      send_json[index].questionId = index

    }
    this.setState({ send_json: [], optionJsonArray: [] });
    this.setState({ send_json, optionJsonArray });
  };

  submitQuiz = async () => {
    const { send_json, quizName } = this.state;
    const { customerId } = this.props.that;

    if (quizName == null || quizName == undefined || quizName == "") {
      this.setState({ error: "Please fill Quiz Name" });
      return false;
    } else {
      this.setState({ error: " " });
    }

    let today = new Date();
    let createdAt =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    let jsonContent = {};
    jsonContent.quizName = quizName;
    jsonContent.contentJson = send_json;
    jsonContent.customerid = customerId;
    jsonContent.createdAt = createdAt;
    jsonContent.type = "active";
    try {
      const result = await CmsContent.addQuizContent(jsonContent);
      //console.log(result.data)

      let valueArray = {};
      valueArray.value = result.data.insertId;
      valueArray.label = quizName;
      // const newdata=[valueArray,...this.state.data]
      //console.log(valueArray);
      if (result) {
        console.log(result);
        this.setState({
          //data:newdata,
          quizName: "",
          send_json: [],
          alertVisible: true
        });
        this.props.these('quizModel', valueArray);
        setTimeout(() => this.setState({ alertVisible: false }), 3000);
      }
    } catch (error) {
      console.log(error);
    }
  };



  render() {
    const {
      send_json,
      quizName,
      alertVisible,
      optionCount,
      optionJson,
      optionListArray,
      optionJsonArray,
      data,
      dataListArray,
      error
    } = this.state;
    let quizViewlist = [];

    send_json.map((quizList, index) => {
      if (quizList.type === "single") {
        quizViewlist.push(
          <div className="row form-group question-margin">
            <div className="col-sm-2">Question {index + 1}</div>
            <div className="col-sm-7">
              <textarea
                type="text"
                class="form-control"
                name={`question${index}`}
                placeholder="Enter Question"
                onChange={e => {
                  this.JSON_refill(e.target.value, "question", index);
                }}
                value={quizList.question}
              />
            </div>
            <div className="col-sm-2">
              <div className="row form-group">
                <div className="col-sm-12">Answer {index + 1}</div>
              </div>
              <div className="row form-group">
                <div className="col-sm-6 form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={`answer${index}`}
                    value="yes"
                    onChange={e => {
                      this.JSON_refill(e.target.value, "answer", index);
                    }}
                  />
                  <label className="form-check-label" for="answer">
                    Yes
                  </label>
                </div>
                <div className="col-sm-6 form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name={`answer${index}`}
                    value="No"
                    onChange={e => {
                      this.JSON_refill(e.target.value, "answer", index);
                    }}
                  />
                  <label className="form-check-label" for="answer">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="col-sm-1">
              <i
                className="fa fa-window-close fa-3x"
                aria-hidden="true"
                onClick={() => this.removeQuiz(index)}
              />
            </div>
          </div>
        );
      } else {
        let optionsAppend = [];
        optionJsonArray.map((totalOption, index1) => {
          if (index1 === index) {
            totalOption.map((val, key) =>
              optionsAppend.push(
                <div className="col-sm-6">
                  <div className="row form-group">
                    <div className="col-sm-12">Option {key + 1}</div>
                  </div>
                  <div className="row form-group">
                    <div className="col-sm-12">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          // id="inlineCheckbox1"
                          id={`option${key + 1}index${index1 + 1}`}
                          value={`option${key + 1}`}
                          onChange={e =>
                            this.optionAnswer(e.target.value, key, index1)
                          }
                        />
                        <label
                          className="form-check-label"
                          for="inlineCheckbox1"
                        >
                          <input
                            type="text"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder={`option ${key + 1}`}
                            onChange={e =>
                              this.appendOption(e.target.value, key, index1)
                            }
                            value={quizList.options[key]}
                          />
                        </label>

                      </div>
                      <span id={`options${key + 1}indexs${index1 + 1}`} style={{ fontSize: 15, marginLeft: 20 }}></span>
                    </div>
                  </div>
                </div>
              )
            );
          }
        });

        quizViewlist.push(
          <div>
            <div className="row form-group question-margin" style={{ marginBottom: -10 }}>
              <div className="col-sm-2">Question {index + 1}</div>
              <div className="col-sm-7">
                <textarea
                  type="text"
                  class="form-control"
                  name="question"
                  placeholder="Enter Question"
                  onChange={e => {
                    this.JSON_refill(e.target.value, "question", index);
                  }}
                  value={quizList.question}
                />
              </div>
              <div className="col-sm-2">
                <div className="row form-group">
                  <div className="col-sm-8">
                    <div className="row form-group">
                      <input
                        type="number"
                        class="form-control"
                        name="optionCount"
                        placeholder="number"
                        onChange={e =>
                          this.handlePhoneCount(e.target.value, index)
                        }
                        value={quizList.count}
                      />
                    </div>
                    <div className="row form-group">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={e => this.printOptions(index)}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="col-sm-4" />
                </div>
              </div>
              <div className="col-sm-1">
                <i
                  className="fa fa-window-close fa-3x"
                  aria-hidden="true"
                  onClick={() => this.removeQuiz(index)}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-sm-2"> </div>
              <div className="col-sm-7">
                <div className="row form-group">{optionsAppend}</div>
              </div>
              <div className="col-sm-2"> </div>
            </div>
          </div>
        );
      }
    });

    return (
      <React.Fragment>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-7">
            <Alert
              className="badge-content"
              color="success"
              isOpen={alertVisible}
              toggle={this.onDismiss}
            >
              Question Added Successfully
            </Alert>
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-2">
            <label for="exampleInputEmail1">
              <b>Quiz Name</b>
            </label>
          </div>
          <div className="col-sm-5">
            <input
              type="text"
              class="form-control"
              name="quizName"
              placeholder="Enter Quiz Name"
              onChange={this.handlechange}
              value={quizName}
            />
            <span className="error-shows">{error}</span>
          </div>
          <div className="col-sm-3" />
        </div>
        <div className="row form-group">
          <div className="col-sm-2" />
          <div className="col-sm-4">
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block btn-radius"
              onClick={() => this.addSingleChoice("multiple")}
            >
              Add Multi Choice
            </button>
          </div>
          <div className="col-sm-4">
            <button
              type="button"
              className="btn btn-primary btn-lg btn-block btn-radius"
              onClick={() => this.addSingleChoice("single")}
            >
              Add Single Choice
            </button>
          </div>
          <div className="col-sm-2" />
        </div>
        {quizViewlist.length > 0 && (
          <div className="row form-group">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  {quizViewlist}
                  <div className="row form-group">
                    <div className="col-sm-2" />
                    <div className="col-sm-3">


                      <button
                        type="button"
                        className="btn btn-success btn-lg btn-radius"
                        onClick={this.submitQuiz}
                      >
                        Submit Quiz
                      </button>
                    </div>
                    <div className="col-sm-7" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      </React.Fragment>)

  }

}

export default AddquizmodelBc;