import React, { Component } from "react";
import renderHTML from "react-render-html";
import DownPanel from "./DownPanel";
import { ACCESS_POINT } from "../../config";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import LoginModal from "../../components/Modal/Modal";
import ScriptTag from "react-script-tag";

const js1 = "/js/gallery/gallery.js";

const images = [
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_20190822_153634_1582714980506.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_20190822_153634_1582714980506.jpg"
  },
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_20190918_153034_1582714980510.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_20190918_153034_1582714980510.jpg"
  },
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_20191017_152612_1582714980512.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_20191017_152612_1582714980512.jpg"
  },
  {
    original:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_20190725_155728_1582715078875.jpg",
    thumbnail:
      ACCESS_POINT +
      "/superAdmin/file?fileurl=SDAS/Video/IMG_20190725_155728_1582715078875.jpg"
  }
];
const style = `<style>
table {
border-collapse: collapse;
width: auto;
}
td, th {
border: 1px solid #dddddd;
text-align: left;
padding: 8px;
font-size:12px;
}
body {
  font-family: Verdana, sans-serif;
  margin: 0;
}
* {
  box-sizing: border-box;
}
.row > .column {
  padding: 0 8px;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
.column {
  float: left;
  width: 20%;
  margin: auto;
  margin-left: 5px;
}
/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
}
/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}



.mySlides {
  display: none;
}
.cursor {
  cursor: pointer;
}
/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}
/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;

}
.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}
.demo {
  opacity: 0.6;
}
.active,
.demo:hover {
  opacity: 1;
}
img.hover-shadow {
  transition: 0.3s;
}
.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>`;
class SheetContent3 extends Component {
  state = {
    show: false,
    title: "Heading",
    loaded: true,
    tabTitle: ["Observation Sheet"],
    tabContent: [
      `
      ${style}

      <h3>MMS Safety Health Check</h3>
      <table>
      <tr>
            <td>S.No</td>
            <td>Obsevable Behaviors </td>
            <td>Frequency (if possible) </td>
            <td>Rating </td>
            <td>Who has final say (Employee/ Employer) </td>
            <td>Compliance/ Behavior </td>
            <td>Process/ Technology/ Training </td>
            <td>STATE</td>
            <td>ERROR </td>
            <td>Impact </td>
            <td>Specific CERT </td>
            <td>If habits need change, then specify change</td>

          </tr>
          <tr>
            <td ></td>
            <td >Observed Function - Food Service </td>
            <td ></td>
            <td ></td>
            <td></td>
            <td></td>
            <td >Auditor </td>
            <td>Gautam</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>

          </tr>
          <tr>
            <td></td>
            <td >Observable Behaviors </td>
            <td >Frequency (if possible) </td>
            <td >Rating </td>
            <td >Who has final say (Employee/ Employer) </td>
            <td >Compliance/ Behavior </td>
            <td >Process/ Technology/ Training </td>
            <td >STATE</td>
            <td >ERROR </td>
            <td >Impact </td>
            <td >Specific CERT </td>
            <td >If habits need change, then specify change</td>

          </tr>
          <tr>
            <td>1</td>
            <td>All staff in kitchen wearing chef caps, safety shoes, gloves, uniform</td>
            <td>3 out of 10 </td>
            <td>3</td>
            <td>Employer </td>
            <td>Behavior </td>
            <td>Process - Staff are aware of standards but a stringent process to be put in place to monitor</td>
            <td>Complacency </td>
            <td >Line of Fire </td>
            <td >Close Calls, Can lead to accidents</td>
            <td >Work on habits </td>
            <td >Staff to be trained on importance of wearing PPE</td>

          </tr>
          <tr >
          <td>2</td>
          <td >Correct order taking by telephone operator all the time </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td >3</td>
          <td >Availability/ Checks of first aid box </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr >
          <td>4</td>
          <td>Floor in the kitchen is clean/ slippery </td>
          <td>2 out of 5</td>
          <td >4</td>
          <td>Employer </td>
          <td >Behavior (Compliance to check) </td>
          <td>Process -to be put in place to ensure required levles of cleaning </td>
          <td >Rushing </td>
          <td >Line of Fire /        B T G  </td>
          <td >Increase the risk of accidents </td>
          <td >Work on habits </td>
          <td >Staff to be trained &amp; monitored on importancekeeping the floor clean &amp; dry</td>

        </tr>
        <tr >
          <td >5</td>
          <td>Cold storage temperature </td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>6</td>
          <td>Expiry dates of products both cold storage &amp; dry store </td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td>7</td>
          <td >Dishwash area PPE used </td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>8</td>
          <td>Staff trained on safety hazards </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td >9</td>
          <td>LPG alarm checked frequently </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>10</td>
          <td>LPG Valves checked frequently </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>11</td>
          <td>Fire supression system checked regularly </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td>12</td>
          <td >Food service staff grooming </td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

          <td >&nbsp;</td>
          <td >&nbsp;</td>
        </tr>
        <tr >
          <td >13</td>
          <td >Exhaust cleaned regularly </td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
        </tr>
        <tr>
          <td >14</td>
          <td >First Aid / Fire Fighting training done regularly </td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >15</td>
          <td >Pest Control done regularly </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td >16</td>
          <td >Chemicals stored in place </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

          <td >&nbsp;</td>
          <td >&nbsp;</td>
        </tr>
        <tr>
          <td>17</td>
          <td>Transporting food while movement </td>
          <td>2 out of 10</td>
          <td>2</td>
          <td >Employer </td>
          <td >Behavior </td>
          <td>Process</td>
          <td >Complacency, Rushing </td>
          <td>Mind not on task , Line of Fire</td>
          <td>Food Contamination,</td>
          <td>Self Trigger</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td >18</td>
          <td >Receiving area clean</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td >19</td>
          <td >Trolleys in working and safe conditions </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>20</td>
          <td>Food covered while transporting </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>21</td>
          <td>Equipments to check for functioning </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>22</td>
          <td>Sharp objects (Knife etc)) in right condition </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

          <td>&nbsp;</td>
          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>23</td>
          <td>Employee with injury working on floor (open wound handling food) </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

          <td>&nbsp;</td>
        </tr>
        <tr>
          <td>24</td>
          <td>Quality of raw material </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>25</td>
          <td>Vendor selection/ Reference checks </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td>26</td>
          <td >Medical check of food handlers </td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

          <td >&nbsp;</td>
        </tr>
        <tr>
          <td>27</td>
          <td>Quality and taste  of food (checks) </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

          <td >&nbsp;</td>
        </tr>
        <tr>
          <td >&nbsp;</td>
          <td >Observed Function - Security </td>
          <td></td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >Auditor </td>
          <td >Gautam</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td></td>
          <td>Observable Behaviors </td>
          <td>Frequency (if possible) </td>
          <td >Rating </td>
          <td >Who has final say (Employee/ Employer) </td>
          <td >Compliance/ Behavior </td>
          <td >Process/ Technology/ Training </td>
          <td >STATE</td>
          <td >ERROR </td>
          <td >Impact </td>
          <td >Specific CERT </td>
          <td >If habits need change, then specify change</td>

        </tr>
        <tr>
          <td >1</td>
          <td>Guards</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td >2</td>
          <td >Check wearing seatbelt (in and out) </td>
          <td >12 out of 15</td>
          <td >8</td>
          <td >Employer </td>
          <td >Compliance</td>
          <td >Training</td>
          <td >Rushing, Complaceny</td>
          <td >MNOT, LOF</td>
          <td >Can lead to serious injury / death in case of accident</td>
          <td >Self Trigger</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td >3</td>
          <td >Helmet wearing at all times (in and out) </td>
          <td >8 out of 10</td>
          <td >8</td>
          <td >Employer </td>
          <td >Compliance</td>
          <td >Training</td>
          <td >Rushing, Complaceny</td>
          <td >MNOT, LOF</td>
          <td >Can lead to serious injury / death in case of accident</td>
          <td >Self Trigger</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td >4</td>
          <td>Speed limit 10km inside premises </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >5</td>
          <td >Driving with high beam lights in two wheeler ramp in opposite direction </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >6</td>
          <td >Signages in place and easy to read for color blind </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td>7</td>
          <td>Slow down the vehicles before speed breakers </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>8</td>
          <td>No honking </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>9</td>
          <td>Mock drill for all employees </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>10</td>
          <td>Water logging in parking areaa </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td>11</td>
          <td>Facilitties for differently abled</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td>12</td>
          <td >Check cleanliness of floors </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td >13</td>
          <td >Signanges on wet floor </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>14</td>
          <td>Ergonomics training for all </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>15</td>
          <td >Rotating positions in shifts </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >16</td>
          <td >Florescent suspenders for guards as required </td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td>17</td>
          <td >Near misses reported </td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >18</td>
          <td >Valets to drive as per guidelines </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td>19</td>
          <td >Safety training to security team </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

          <td >&nbsp;</td>
        </tr>
        <tr >
          <td>20</td>
          <td>Nametags for all employees </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

          <td>&nbsp;</td>
        </tr>
        <tr>
          <td >21</td>
          <td >First aid kit for security </td>
          <td >1 out of 3</td>
          <td >3</td>
          <td >Employer </td>
          <td >Compliance</td>
          <td >Process- in the 1 Kit available 50% items were missing </td>
          <td>Complacency</td>
          <td>MNOT</td>
          <td>Can lead to delay in providing First Aid</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >22</td>
          <td >Wheel chairs </td>
          <td >1 out of 1</td>
          <td >10</td>
          <td >Employer </td>
          <td >Compliance</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >As only 1 wheel chair is avaialble for all 3 buildings, it might lead to a delay in providing this facility </td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>23</td>
          <td >Doctor room access </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

          <td >&nbsp;</td>
        </tr>
        <tr>
          <td>24</td>
          <td>Key control room / register </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>25</td>
          <td >Work permit for any contractor </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

          <td >&nbsp;</td>
        </tr>
        <tr>
          <td >26</td>
          <td >Gate passes for in and out movement of material </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td>27</td>
          <td >Barricades/ signs when work in progress </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td>28</td>
          <td>PPE for vendors </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr >
          <td>&nbsp;</td>
          <td>Observed Function - Electrical/ Maintenance </td>
          <td ></td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>Auditor </td>
          <td >Gautam</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td ></td>
          <td >Observable Behaviors </td>
          <td >Frequency (if possible) </td>
          <td >Rating </td>
          <td >Who has final say (Employee/ Employer) </td>
          <td >Compliance/ Behavior </td>
          <td>Process/ Technology/ Training </td>
          <td >STATE</td>
          <td>ERROR </td>
          <td >Impact </td>
          <td >Specific CERT </td>
          <td>If habits need change, then specify change</td>

        </tr>
        <tr>
          <td>1</td>
          <td>Sub-station checklist </td>
          <td>0 out of 5</td>
          <td >0</td>
          <td>Employer </td>
          <td >Compliance</td>
          <td >Process - RMZ room is being used as a staff locker and storage area</td>
          <td >Compacency</td>
          <td >MNOT, LOF</td>
          <td >Serious Fire Hazard</td>
          <td >Self Trigger</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >2</td>
          <td >UPS checklist </td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td >3</td>
          <td >Elevators checklist </td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td>4</td>
          <td >Diesel Generator checklist </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td >5</td>
          <td >Projector condition </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td >6</td>
          <td >Kitchen Equipment </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>7</td>
          <td>Lighting checklist </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td >8</td>
          <td >Customer calls and feedback </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td>9</td>
          <td>AC checklist </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>10</td>
          <td>Access control checklist </td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td >11</td>
          <td >PA system check </td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>12</td>
          <td>CCTV working and checked regularly </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr >
          <td>13</td>
          <td>Access card and lunch card management </td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >14</td>
          <td>CO2 management </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >15</td>
          <td >Fire alarms systems checked and trained </td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td >16</td>
          <td >Staff trained on systems </td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td >17</td>
          <td>Handling customer complaints </td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td >18</td>
          <td >Elevator phone working </td>
          <td >5 out of 5</td>
          <td >10</td>
          <td >Employer </td>
          <td>Compliance</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>19</td>
          <td>Diesel storing checking </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>20</td>
          <td>Elevator checklisst </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td >&nbsp;</td>
          <td >Observed Function - Housekeeping </td>
          <td ></td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >Auditor </td>
          <td >Gautam</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td></td>
          <td >Observable Behaviors </td>
          <td >Frequency (if possible) </td>
          <td >Rating </td>
          <td >Who has final say (Employee/ Employer) </td>
          <td >Compliance/ Behavior </td>
          <td >Process/ Technology/ Training </td>
          <td >STATE</td>
          <td>ERROR </td>
          <td>Impact </td>
          <td>Specific CERT </td>
          <td>If habits need change, then specify change</td>

        </tr>
        <tr>
          <td>1</td>
          <td>Parking free of obstacles (solid objects, tiny nails ) </td>
          <td >3 out of 5</td>
          <td >6</td>
          <td>Employer </td>
          <td >Behavior </td>
          <td>Process- Uneven flooring noticed at DH Portico, Next to PH Drivers Rest room &amp; Rajai Salai Entrance</td>
          <td >Complacency</td>
          <td >ENOT, MNOT, LOF, BTG</td>
          <td >Can lead to accidents &amp; vehicle damage</td>
          <td >Work on habits </td>
          <td >Periodic checks with defined timlelines to intiate repairs</td>

        </tr>
        <tr>
          <td >2</td>
          <td >Clearning of parking area </td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td >3</td>
          <td >condition of speed brakers </td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td>4</td>
          <td >Corner guards (pillars) </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >5</td>
          <td >Lobby free from wet surface </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >6</td>
          <td >Door cleaning / functioning </td>
          <td >2 out of 6</td>
          <td >3</td>
          <td >Employer </td>
          <td >Behavior </td>
          <td >Training - Glass doors had finger print marks</td>
          <td >Complacency</td>
          <td>ENOT, MNOT, </td>
          <td>Bad image ofdept. </td>
          <td >Self Trigger</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td >7</td>
          <td >Clearning of office floor </td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>8</td>
          <td >Stair case clearning </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td>9</td>
          <td >Elevator clearning </td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td>10</td>
          <td >Floor mat </td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

          <td >&nbsp;</td>
        </tr>
        <tr>
          <td >11</td>
          <td >Odor </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >12</td>
          <td >Dust </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td >13</td>
          <td >Pest control  </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >14</td>
          <td >Terraces </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >15</td>
          <td >Patio areas </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >16</td>
          <td >Restrooms </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td>17</td>
          <td >Pantry (water, dustbin, pest) </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >18</td>
          <td >Waste management </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

          <td>&nbsp;</td>
        </tr>
        <tr>
          <td >19</td>
          <td >Hourly check by housekeeping staff (restrooms) </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td>20</td>
          <td>Gym cleaning </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td class="column6">&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

          <td >&nbsp;</td>
        </tr>
        <tr >
          <td>21</td>
          <td>Carpet clearning as per weekly schedule </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>

        </tr>
        <tr>
          <td>22</td>
          <td>Building cleaning ( vendor checklist) on weekend </td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

          <td >&nbsp;</td>
        </tr>
        <tr >
          <td >23</td>
          <td >Deep cleaning during weekends </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr >
          <td >24</td>
          <td>Garden cleaning </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td >25</td>
          <td >Main drainage cleaning </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>
        <tr>
          <td >26</td>
          <td >Safe assembly points </td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td>&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>
          <td >&nbsp;</td>

        </tr>










    </table>`
    ],
    // tabTitle: ["QUALITY", "COMMERCIALS", "MAINTENANCE","PRODUCTION"],
    tabContent1: [
      `
      ${style}
     <h3>Security SafeStart Health Check for MMS</h3>
      <table>
      <tr >
      <td >Observed Function</td>
      <td >Observable Behaviors </td>
      <td >Frequency (if possible) </td>
      <td ></td>
      <td >Rating </td>
      <td >Who has final say (Employee/ Employer) </td>
      <td >Compliance/ Behavior </td>
      <td>Process/ Technology/ Training </td>
      <td>STATE</td>
      <td>ERROR </td>
      <td>Impact </td>
      <td>Specific CERT </td>
      <td>If habits need change, then specify change</td>
      <td>Action Steps </td>
      </tr>
      <tr class="row3">
      <td></td>
      <td ></td>
      <td ></td>
      <td >Response/ frequency</td>
      <td >&nbsp;</td>
      <td >&nbsp;</td>
      <td>&nbsp;</td>
      <td >&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
      </tr>
      <tr>
      <td >Security </td>
      <td >Guards</td>
      <td >Stationed at points with full uniform/ There is no Canape/ shelter for guards at Rajaji Salai entrance point, TNSC Bank parking- They are standing in hot sun </td>
      <td >Security Deployed by Chola un officialy due to high court order hence shelter can't be provided</td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Check wearing seatbelt (in and out) </td>
      <td >Yes/ 100%</td>
      <td >Any point of Time security to monitor and collect data like vehicle No / company Name</td>
      <td ></td>
      <td >Employer</td>
      <td >Behavier </td>
      <td ></td>
      <td >Complacency</td>
      <td >Line of Fire </td>
      <td >Life risk</td>
      <td ></td>
      <td ></td>
      <td >security team started collect data like vehicle No / company Name</td>

      </tr>
      <tr >
      <td ></td>
      <td >Helmet wearing at all times (in and out) </td>
      <td >Yes/ 100%</td>
      <td >Any point of Time security to monitor and collect data like vehicle No / company Name</td>
      <td ></td>
      <td >Employer</td>
      <td >Behavier </td>
      <td ></td>
      <td >Complacency</td>
      <td >Line of Fire </td>
      <td >Life risk</td>
      <td ></td>
      <td ></td>
      <td >security team started collect data like vehicle No / company Name</td>
      </tr>
      <tr >
      <td ></td>
      <td >Speed limit 10km inside premises </td>
      <td >Sec. Team is insisting, but few employees/ visitors cross 10 KM/Hr limit</td>
      <td >Any point of Time security to monitor and collect data like vehicle No / company Name</td>
      <td ></td>
      <td >Employer</td>
      <td >Behavier </td>
      <td ></td>
      <td >Rushing </td>
      <td ></td>
      <td >Near misses </td>
      <td ></td>
      <td ></td>
      <td >security team started collect data like vehicle No / company Name</td>
      </tr>
      <tr >
      <td ></td>
      <td >driving with high beam lights in two wheeler ramp in opposite direction </td>
      <td >Few employees don’t follow this inspite of informing them.</td>
      <td >During evening hours security to monitor and collect data like vehicle No / company Name</td>
      <td ></td>
      <td >Employer</td>
      <td >Behavier </td>
      <td ></td>
      <td >Fatigue</td>
      <td >Mind not on task</td>
      <td >Near misses </td>
      <td ></td>
      <td ></td>
      <td >During evening hours security team started collect data like vehicle No / company Name</td>
      </tr>
      <tr >
            <td ></td>
            <td >Signages in place and easy to read for color blind </td>
            <td >Yes/ Would require more like - walking track next to driveway</td>
            <td >will explore and collect the signage details &amp; requirments. after due approval will install by 30th Sep in Dh complex ground floor drive area / PH basement</td>
            <td ></td>
            <td >Employer</td>
            <td >Compliance </td>
            <td >Need more signages </td>
            <td >Complacency</td>
            <td >Mind not on task</td>
            <td >Near misses </td>
            <td ></td>
            <td ></td>
            <td >Started to collect the signage details &amp; requirments. after due approval will install by 30th Sep in Dh complex ground floor drive area / PH basement</td>
      </tr>
      <tr >
      <td ></td>
      <td >Slow down the vehicles before speed breakers </td>
      <td >Yes</td>
      <td >Any point of Time security to monitor and collect data like vehicle No / company Name</td>
      <td ></td>
      <td >Employer</td>
      <td >Behavier </td>
      <td ></td>
      <td >Rushing </td>
      <td >Line of Fire </td>
      <td >Life risk</td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Slow down the vehicles before speed breakers </td>
      <td >Yes</td>
      <td >Any point of Time security to monitor and collect data like vehicle No / company Name</td>
      <td ></td>
      <td >Employer</td>
      <td >Behavier </td>
      <td ></td>
      <td >Rushing </td>
      <td >Line of Fire </td>
      <td >Life risk</td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Mock drill for all employees </td>
      <td >Yes</td>
      <td >twice a Year</td>
      <td ></td>
      <td >Employer</td>
      <td >Behavier </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Water logging in parking areaa </td>
      <td >No</td>
      <td >Any point of Time security to monitor and report to help desk</td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Water logging </td>
      <td >Duty Aso responsibilitiy to check and inform to House keeping team </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Facilitties for differently abled</td>
      <td >Yes/ Ramp in all 3 buildings</td>
      <td >will explore and collect the signage details &amp; requirments. after due approval will install by 30th Sep in Dh complex ground floor drive area / PH basement</td>
      <td ></td>
      <td >Employer</td>
      <td >Behavier </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td >Scollect the signage details &amp; requirments. after due approval will install by 30th Sep in Dh complex ground floor drive area / PH basement</td>
      </tr>
      <tr >
      <td ></td>
      <td >Check cleanliness of floors </td>
      <td >Yes</td>
      <td >Any point of Time security to monitor and report to help desk</td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Signages on wet floor </td>
      <td >Yes</td>
      <td >Any point of Time security to monitor and report to help desk</td>
      <td ></td>
      <td >Employer</td>
      <td >Compliance </td>
      <td >Need Training </td>
      <td >Complacency</td>
      <td >Mind not on task</td>
      <td >Near misses </td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Ergonomics training for all </td>
      <td >No</td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
            <td ></td>
            <td >Rotating positions in shifts </td>
            <td >Yes</td>
            <td >Duty Aso responsibilitiy</td>
            <td ></td>
            <td >Employer</td>
            <td >Behavier </td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
      </tr>
      <tr >
            <td ></td>
            <td >Florescent suspenders for guards as required </td>
            <td >When regulating traffic outside building</td>
            <td >5.30 PM Till all the vehicles leave the permises</td>
            <td ></td>
            <td >Employer</td>
            <td >Behavier </td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
      </tr>
      <tr >
            <td ></td>
            <td >Near misses reported </td>
            <td >No/ Incident report sent to Vertical head</td>
            <td >Will explore to identify the Near misses and record it to take corrective Measures</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td >Started to identify the Near misses and record it to take corrective Measures</td>
      </tr>
      <tr >
      <td ></td>
      <td >Valets to drive as per guidelines </td>
      <td >YES</td>
      <td >Any point of Time security to monitor and control &amp; record</td>
      <td ></td>
      <td >Employer</td>
      <td >Behavier </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Safety training to security team </td>
      <td >Yes/ Security</td>
      <td >Daily briefing at 7.45 AM &amp; 7.15 PM  on Saturday Detail Breefing at 7 .45 AM to 8.30 AM Month 1st saturday training by G4 operation Manager at 7.45 AM to 9 AM </td>
      <td ></td>
      <td >Employer</td>
      <td >Behavier </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Daily Briefing </td>
      <td >Daily Morning </td>
      <td >Duty ASO give the brifing about the programms &amp; parking of the day .<br />
Basic Turn out </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Weekly Briefing </td>
      <td >Every Saturday morning </td>
      <td >AM give the physical excercise &amp; Turnout </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr ">
      <td "></td>
      <td >Fire Fighting &amp; Rescue Training </td>
      <td >Once in a Month Satuday 8.30am </td>
      <td >AM give the Fire hydrent operating traing &amp; rescue training </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Monythly Traing </td>
      <td >Month 1st Saturday </td>
      <td >Month 1st saturday training by G4 operation Manager at 7.45 AM to 9 AM</td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Nametags for all employeews </td>
      <td >Very few employees wear it/ many don't </td>
      <td >Need HR approval</td>
      <td ></td>
      <td >Employer</td>
      <td >Compliance </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >First aid kid for security </td>
      <td ></td>
      <td >Currenty security Team Having 2 First Aid Kit require 3 More will close it By 20th Sep</td>
      <td ></td>
      <td >Employer</td>
      <td >Compliance </td>
      <td >Need some first aid things </td>
      <td >Complacency</td>
      <td >Mind not on task</td>
      <td >Near misses </td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Wheel chairs </td>
      <td >1 @Doctor room</td>
      <td >we require 2 more for PH &amp; DHE will close it bY 30th Sep</td>
      <td ></td>
      <td >Employer</td>
      <td >Behavier </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Doctor room access </td>
      <td >Key @ DH reception</td>
      <td ></td>
      <td ></td>
      <td >Employer</td>
      <td >Behavier </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Key control room / register </td>
      <td >Available @ DH Secutity</td>
      <td ></td>
      <td ></td>
      <td >Employer</td>
      <td >Behavier </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Key IN &amp; Out </td>
      <td >Key Register </td>
      <td >DH security make the entry in key register </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
            <td ></td>
            <td >Work permit for any contractor </td>
            <td >Yes/ 100%</td>
            <td >At Any point of Time</td>
            <td ></td>
            <td >Employer</td>
            <td >Behavier </td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Permit work </td>
      <td >Approval</td>
      <td >&nbsp;check the Mail approval of department head </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Open terrace work </td>
      <td ">Security team  be with the vendor till work complete </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Welding work &amp; Gas cutting work</td>
      <td >Fire Guard monitor the work till work complete with fire extinguser </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Scaffolding work</td>
      <td >Duty ASO monitor the safety </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >CCTV WORK</td>
      <td >CCTV Duty ASO monitor the work </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Alcohol consumption check </td>
      <td >Duty Aso responsibilitiy</td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Tools Checkup </td>
      <td >Security check the unsafe condition of tools </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >AMC WORK </td>
      <td >Approval</td>
      <td >&nbsp;check the approval of department head </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Open terrace work </td>
      <td >Security team  be with the vendor till work complete </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Welding work &amp; Gas cutting work</td>
      <td >Fire Guard monitor the work till work complete with fire extinguser </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Alcohol consumption check </td>
      <td >Duty Aso responsibilitiy</td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Tools Checkup </td>
      <td >Security check the unsafe condition of tools </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Gate passes for in and out movement of material </td>
      <td >Yes/ 100%</td>
      <td >At Any point of Time</td>
      <td ></td>
      <td >Employer</td>
      <td >Behavier </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
            <td ></td>
            <td >Material out </td>
            <td >Approval </td>
            <td >Security check the approval signature from authorised person in out pass  </td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Quantity of Material </td>
      <td >Security check the quantity ofmaterial as per gate pass </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Material In </td>
      <td >Invoic address</td>
      <td >Security check the Invoice Address</td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Quantity of Material </td>
      <td >Security check the quantity of material as per Invoice </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Barricades/ signs when work in progress </td>
      <td >Yes/ 100%</td>
      <td >At Any point of Time</td>
      <td ></td>
      <td >Employer</td>
      <td >Behavier </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
            <td ></td>
            <td >PPE for vendors </td>
            <td >Yes/ 100%</td>
            <td >At Any point of Time</td>
            <td ></td>
            <td >Employer</td>
            <td >Behavier </td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Welding work </td>
      <td >Fire guard check the Welding shield ,gloves ,shoe and machine  </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Cutting work </td>
      <td >Fire guard check the Helmet ,Goggles gloves ,shoe and machine  </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Hight work </td>
      <td >Fire guard check the Helmet ,Goggles gloves ,shoe Ladder and machine  </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td ></td>
      <td >Scaffolding work </td>
      <td >Duty ASO check Safety belt ,Helmet ,Shoe ,Gloves &amp; Balance  of Scaffolding </td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
      </tr>
      <tr >
            <td ></td>
            <td >Daily Check for correct extinguishers available in right location</td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
            <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Timely Servicing of Fire Extinguishers</td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >No Material Storage in Fire Hydrent room</td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Limit the no of bikes parked in basement to 230 nos</td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>
      <tr >
      <td ></td>
      <td >Limit the cars parked in the parking area to 100 nos</td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      <td ></td>
      </tr>











    </table>`
    ]
  };
  openModal = n => {
    document.getElementById("myModal").style.display = "block";
  };
  closeModal = () => {
    document.getElementById("myModal").style.display = "none";
  };
  view = () => {
    this.setState({ show: true });
  };
  hideView = () => {
    console.log("hide");
    this.setState({ show: false });
  };
  loadScript = src => {
    this.setState({ loaded: false });
    return <ScriptTag type="text/javascript" src={src} />;
  };
  render() {
    let state = this.state;
    return (
      <React.Fragment>
        <div className="container-fluid container-margin-top">
          <div className="col-sm-12">
            <div className="row form-group">
              <div className="card">
                <div className="card-body">
                  <div className="row form-group">
                    <div className="col-sm-12" style={{ marginBottom: '2%' }}>
                      <div className="row ">
                        <div className="col-sm-1">
                          <img
                            style={{ marginTop: 4 }}
                            src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/MDC_Logo_New_1582718944462.png`}
                          />
                        </div>
                        <div className="col-sm-10">
                          <h2
                            style={{ marginLeft: "0px", marginTop: "2px" }}
                            className="theme-tiile text-capitalize"
                          >
                            Murugappa Management Services, HO
                          </h2>
                        </div>
                        <div className="col-sm-2"></div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <div style={{ marginBottom: '2%' }}>
                        <LoginModal
                          buttonTitle="Gallery"
                          title="Murugappa Management Services, HO"
                          id="we"
                          indexStyle={{ zIndex: 99999 }}
                          maxwidth="modal-lg"
                          bodyText={
                            <div>
                              <section className="carousel-container">
                                <img
                                  src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/IMG_20190822_153634_1582714980506.jpg`}
                                  alt=""
                                  className="current-image"
                                />
                                <span className="arrow arrow-left"> Prev </span>
                                <ul className="next-list">
                                  <li>
                                    <img
                                      src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/IMG_20190822_153634_1582714980506.jpg`}
                                      alt=""
                                      className="image-of-list current-image-list"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/IMG_20190918_153034_1582714980510.jpg`}
                                      alt=""
                                      className="image-of-list"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/IMG_20191017_152612_1582714980512.jpg`}
                                      className="image-of-list"
                                    />
                                  </li>
                                  <li>
                                    <img
                                      src={`${ACCESS_POINT}/superAdmin/file?fileurl=SDAS/Video/IMG_20190725_155728_1582715078875.jpg`}
                                      alt=""
                                      className="image-of-list"
                                    />
                                  </li>
                                </ul>
                                <span className="arrow arrow-right"> Next </span>
                              </section>
                              <ScriptTag type="text/javascript" src={js1} />
                            </div>
                          }
                        />
                      </div>
                      <div>
                        <h3>Murugappa Management Services (MMS), HO</h3>
                        <br />
                        <p>
                          We facilitated the 5 SafeStart core units for 11
                          Senior executives of MMS’s Management Team on
                          following dates
                        </p>
                        <br />
                        <table >
                          <tr>
                            <th colspan="2">Units</th>
                            <th>Dates</th>
                          </tr>
                          <tr>
                            <td rowSpan="6">
                              5 SafeStart
                              <br /> Core Units for
                              <br /> MG Staff
                            </td>
                            <td>Unit 1</td>
                            <td>25-Jul 2019</td>
                          </tr>
                          <tr>
                            <td>Unit 2</td>
                            <td>01-Aug 2019</td>
                          </tr>
                          <tr>
                            <td>Unit 3</td>
                            <td>22-Aug 2019</td>
                          </tr>
                          <tr>
                            <td>Safety Health Check</td>
                            <td>29-Aug 2019</td>
                          </tr>
                          <tr>
                            <td>Unit 4</td>
                            <td>18-Sep 2019</td>
                          </tr>
                          <tr>
                            <td>Unit 5</td>
                            <td>17-Oct 2019</td>
                          </tr>
                        </table>
                        <br />
                        <h3>Safety Health Check</h3>
                        <br />
                        <p>
                          As a part of this intervention, the executives were
                          asked to carry out a Safety Health Check on the shop
                          floor, first within their functions and then cross-
                          functionally to observe and note, manual interventions
                          where safety is compromised due to human factors.
                        </p>
                        <br />
                        <p>The observations are being shown here </p>
                        <br />
                      </div>
                      <br />
                      <label style={{ fontWeight: 600, marginLeft: "16px" }}>
                        MMS Safety Health Check
                      </label>

                      <div className="col-sm-12">
                        <ul class="nav nav-tabs" role="tablist">
                          {state.tabTitle.map((ival, i) => {
                            return (
                              <li class="nav-item">
                                <a
                                  class={`nav-link ${i === 0 ? "active" : ""}`}
                                  data-toggle="tab"
                                  href={`#home1${i}`}
                                >
                                  {ival}
                                </a>
                              </li>
                            );
                          })}
                        </ul>

                        <div class="tab-content">
                          {state.tabContent.map((ival, i) => {
                            return (
                              <div
                                id={`home1${i}`}
                                class={`container tab-pane ${
                                  i === 0 ? " active" : ""
                                  }`}
                                style={{ margin: "0px", overflowX: "auto" }}
                              >
                                <p className="content-para">
                                  {renderHTML(ival ? ival : "")}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                        <br />
                        <br />
                        <label style={{ fontWeight: 600, marginLeft: "3px" }}>
                          Security SafeStart Health Check for MMS
                        </label>
                        <br />
                        <ul class="nav nav-tabs" role="tablist">
                          {state.tabTitle.map((ival, i) => {
                            return (
                              <li class="nav-item">
                                <a
                                  class={`nav-link ${i === 0 ? "active" : ""}`}
                                  data-toggle="tab"
                                  href={`#home2${i}`}
                                >
                                  {ival}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                        <div class="tab-content">
                          {state.tabContent1.map((ival, i) => {
                            return (
                              <div
                                id={`home2${i}`}
                                class={`container tab-pane ${
                                  i === 0 ? " active" : ""
                                  }`}
                                style={{ margin: "0px", overflowX: "auto" }}
                              >
                                <p className="content-para">
                                  {renderHTML(ival ? ival : "")}
                                </p>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-3"></div>
            </div>
            <div className="row form-group">
              <div className="col-sm-12 mb-new">
                <div className="card">
                  {/* <div className="card-body px-0">
                    <DownPanel />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {this.state.loaded ? this.loadScript(js1) : null}
        </div>
      </React.Fragment>
    );
  }
}

export default SheetContent3;
