import React from "react";

const FormModal = ({
  title,
  id,
  bodyText,
  reset,
  extraStyle,
  maxwidth = "lg",
  indexStyle = {},
 }) => {
  return (
    <div>
      <div
        className="modal fade"
        id={id}
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={indexStyle}
      >
        <div className={`modal-dialog modal-${maxwidth}`} role="document">
          <div className="modal-content" style={extraStyle ? extraStyle : null}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {title}
              </h5>
              <button
                type="button"
                className="close "
                data-dismiss="modal"
                aria-label="Close"
                onClick={reset}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">{bodyText}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormModal;
