import React, { Component } from "react";
import { Datatable } from "../../../components/Datatable";
import CmsContent from "../../../MiddleWare/CmsContent";
import socket from '../../../MiddleWare/socketMiddleWare';
import SingleSelect from "../../../components/Form/SingleSelect";
class VideoSeekLog extends Component {
  constructor(props) {
    super(props);
    const {
      match: { params }
    } = this.props;
    this.state = {
      data: [],
      VideoList: [],
      customerId: localStorage.getItem("userId"),
      selectVideoList: { value: params['id'], label: '' }
    };
  }
  data = []
  liveData = []
  column = [
    {
      Header: "User Name",
      accessor: "userName"
    },
    {
      Header: "Video Name",
      accessor: "pageName"
    },
    {
      Header: "Duration",
      accessor: "timestamp"
    }
  ];

  async componentDidMount() {
    try {
      const { data: VideoList } = await CmsContent.getFreedom(
        'contentTitle1 as label,id as value',
        'tbl_pages',
        `themeId='3' and customerId='${this.state.customerId}' and status='show'`
      );
      if (VideoList) {
        this.setState({ VideoList });
      }
      socket.on(`responseTimestamp${this.state.customerId}`, data => {
        this.data.push(data);
      });
      setInterval(() => {
        let liveData = [];
        this.data.map((ival, i) => {
          if (liveData.filter(jval => ival.userId === jval.userId).length === 0) {
            liveData.push(ival);
          }
        })
        this.liveData = [...liveData];
        this.data = [];
        if (this.state.selectVideoList && this.state.selectVideoList.value) {
          this.content(this.state.selectVideoList);
        }
      }, 3000)
    } catch (error) {
      console.error(error);
    }
  }
  selectVideoList = selectVideoList => {
    this.setState({ selectVideoList });
    this.content(selectVideoList);
  }
  content = (selectVideoList) => {
    let data = this.liveData;
    let Filter = []
    data.map((ival) => {
      if (ival.videoId == selectVideoList.value) {
        Filter.push(ival);
      }
    })
    this.setState({ data: Filter })
  }

  render() {
    const { data, VideoList, selectVideoList } = this.state;
    return (
      <main className="main my-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3>Video Seek</h3>
                </div>
                <div className="card-body" style={{ minHeight: 300 }}>
                  {(data && data.length) ? (
                    <Datatable data={data} columnHeading={this.column} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default VideoSeekLog;
