import React, { Component } from "react";
import { Alert } from "reactstrap";
import http from "../../../../MiddleWare/httpMiddleWare";
import Datatable from "../../../../components/Datatable/Datatable";
import { ACCESS_POINT } from "../../../../config";
import { ACCESS_SAMPLE } from "../../../../config";
import Progress from "../../../../components/Extra/Progress";
import cmsContent from "../../../../MiddleWare/CmsContent";
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.css';
import 'froala-editor/js/plugins.pkgd.min.js';
import FroalaEditor from 'react-froala-wysiwyg';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';


class Theme2 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			btnDisable: false,
			uploadPercentage: 0,
			content: '<p></p>',
			isEdit: false,
			uploadPercentage: 0,
			screen: window.screen.height,
			msgShow: false,
		}
	}
	async componentWillMount() {
		try {
			if (this.props.that.data) {
				let pages = [];
				this.props.that.data.map((ival, i) => {
					if (ival.themeId === 2) {
						pages.push(ival);
					}
				})
				this.setState({ data: pages });
			}
		} catch (e) {
			console.log(e);
		}
	}
	column = [
		{
			Header: "Page",
			accessor: "name"
		},
		{
			Header: "Status",
			accessor: "status"
		},
		{
			Header: "Visibility",
			accessor: "edit",
			Cell: d => this.showVisibility(d)
		},
		{
			Header: "Require Login",
			accessor: "requireLogin"
		},
		{
			Header: "Change Login Status",
			accessor: "edit",
			Cell: d => this.changeLoginStatus(d)
		},
		{
			Header: "Edit",
			accessor: "edit",
			Cell: d => this.Editpages(d)
		},
		{
			Header: "Preview",
			accessor: "edit",
			Cell: d => this.PreviewPage(d)
		}
	];
	PreviewPage = (d) => {

		return (<button
			type="button"
			className="btn btn-info"
			onClick={() => this.PreviewRoute(d)}
		>
			Preview
		</button>
		);
	}

	PreviewRoute = (d) => {
		let id = d.original.id;
		let customerId = d.original.customerId;
		if (id) {
			window.open(
				`${ACCESS_SAMPLE}/samplesite/th7/${id}/${customerId}`,
				'_blank'
			);
		}
	}
	showVisibility = id => {
		return (
			<button
				type="button"
				className="btn btn-info"
				onClick={() => this.updateVisibility(id)}
			>
				Hide/Show
			</button>
		);
	};
	updateVisibility = async value => {
		const index = value.index;
		const previousData = [...this.state.data];
		const newData = { ...previousData[index] };

		if (newData.status === "show") {
			newData.status = "hide";
		} else {
			newData.status = "show";
		}
		const id = newData.id;
		const data = previousData.filter(value => value.id !== id);
		data.splice(index, 0, newData);
		try {
			const result = await cmsContent.updatePage(newData);
			if (result) {
				this.setState({ data: data });
			}
		} catch (error) {
			this.setState({ data: previousData });
			console.log(error);
		}
	};
	changeLoginStatus = id => {
		return (
			<button
				type="button"
				className="btn btn-info btn-lg col-lg-12" style={{ paddingLeft: 5, marginBottom: '4px', fontSize: '10px' }}
				onClick={() => this.updateLogin(id)}
			>
				Change Login Status
			</button>
		);
	};

	updateLogin = async value => {
		const index = value.index;
		const previousData = [...this.state.data];
		const newData = { ...previousData[index] };
		if (newData.requireLogin === "yes") {
			newData.requireLogin = "no";
		} else {
			newData.requireLogin = "yes";
		}
		const id = newData.id;
		const data = previousData.filter(value => value.id !== id);
		data.splice(index, 0, newData);
		this.setState({ data });
		try {
			const result = await cmsContent.updateLoginStatus(newData);
			if (result) {
				this.setState({ data: data });
			}
		} catch (error) {
			this.setState({ data: previousData });
			console.log(error);
		}
	};
	Editpages = value => {
		return (<button className="btn btn-info" onClick={() => this.onEdit(value)}>
			Edit
		</button>)
	};
	onEdit = async d => {
		let { isEdit } = this.state
		this.setState({ isEdit: true })
		let org = d.original;
		let index = d.index;
		if (typeof this.props.these === 'function') {
			this.props.these('pageName', org.name);
			this.props.these('autherSelected', this.props.that.authorlist.filter(ival => ival.value === org.authorid)[0]);
			this.props.these('checked', org.show == 1 ? 1 : 0);
			this.props.these('errorPageName', '');
			this.props.these('errorAuthorName', '');
		}
		let wait = await org.sheetContent.split('img src="').map(async (ival, i) => {
			if (i) {
				let a = ival.split('"')[0];
				org.sheetContent = org.sheetContent.replace(a, `${ACCESS_POINT}/superAdmin/file?fileurl=${a}`);
			}
		});
		await Promise.all(wait);
		let wait1 = await org.sheetContent.split('controls="" src="').map(async (ival, i) => {
			if (i) {
				let a = ival.split('"')[0];
				org.sheetContent = org.sheetContent.replace(a, `${ACCESS_POINT}/superAdmin/file?fileurl=${a}`);
			}
		});
		await Promise.all(wait1);
		this.setState({ content: org.sheetContent, isEdit: true, editId: org.id, Index: index });
	}
	handleModelChange = (model) => {
		this.setState({
			content: model
		});
	}

	submitCategory = async () => {
		const {
			checked,
			autherSelected,
			pageName,
			customerId,
		} = this.props.that;
		let { content, Index } = this.state;
		let Images = [];
		if (!autherSelected) {
			this.props.these('errorAuthorName', 'Select Author');
			return false
		} else if (!pageName) {
			this.props.these('errorAuthorName', '')
			this.props.these('errorPageName', 'Enter Page name');
			return false;
		}
		let wait = await content.split('img src="').map(async (ival, i) => {
			let a = ival.split('"')[0];
			if (i && a.search(ACCESS_POINT) === -1) {
				let response = await fetch(a);
				let data = await response.blob();
				let metadata = {
					type: 'image/jpeg'
				};
				let file = new File([data], "test.jpg", metadata);
				Images.push({ [ival.split('"')[0]]: file });
			}
			if (content.search(ACCESS_POINT) !== -1) {
				content = content.replace(`${ACCESS_POINT}/superAdmin/file?fileurl=`, '');
			}
			if (content.search(ACCESS_POINT) !== -1) {
				content = content.replace(`${ACCESS_POINT}/superAdmin/sharefile?filename=`, '');
			}
		});
		await Promise.all(wait);
		let wait1 = await content.split('controls="" src="').map(async (ival, i) => {
			let a = ival.split('"')[0];
			if (i && a.search(ACCESS_POINT) === -1) {
				let response = await fetch(a);
				let data = await response.blob();
				let metadata = {
					type: 'video/mp4'
				};
				let file = new File([data], "test.mp4", metadata);
				Images.push({ [ival.split('"')[0]]: file });
			}
			if (content.search(ACCESS_POINT) !== -1) {
				content = content.replace(`${ACCESS_POINT}/superAdmin/file?fileurl=`, '');
			}
			if (content.search(ACCESS_POINT) !== -1) {
				content = content.replace(`${ACCESS_POINT}/superAdmin/sharefile?filename=`, '');
			}
		});
		await Promise.all(wait1);
		const formData = new FormData();
		formData.append("name", pageName);
		formData.append("themeId", '2');
		formData.append("authorid", autherSelected.value);
		formData.append("show", checked);
		formData.append("status", "show");
		formData.append("customerId", customerId);
		formData.append("sheetContent", content);
		Images.map((ival, i) => {
			let a = Object.keys(ival)[0];
			formData.append([a], ival[a]);
		});
		try {
			if (this.state.isEdit) {
				formData.append("id", this.state.editId);
				const themeUpdate = await cmsContent.updateTheme('tbl_pages', formData).then((response) => {
					if (response && response.status === 200) {
						this.setState({ msgShow: true, msg: 'Theme updated successfully!' })
						setTimeout(() => {
							this.setState({ msgShow: false, content: '<p></p>' })
						}, 3000);
						window.location.reload()
					}
				})
				// let { data } = await http.put(
				// 	ACCESS_POINT + `/cmsContent/tbl_pages`,
				// 	formData,
				// 	{
				// 		headers: {
				// 			"Content-Type": "multipart/form-data"
				// 		},
				// 		onUploadProgress: progressEvent => {
				// 			this.setState({
				// 				uploadPercentage: parseInt(
				// 					Math.round((progressEvent.loaded * 100) / progressEvent.total)
				// 				)
				// 			});
				// 			setTimeout(() => this.setState({ uploadPercentage: 0 }), 2000);
				// 			///10000
				// 		}
				// 	}
				// );
				// if (data && data.length) {
				// 	data = data[0];
				// 	const previousData = [...this.state.data];
				// 	previousData[Index] = data;
				// 	this.setState({
				// 		data: previousData,
				// 		content: '<p></p>',
				// 	});
				// 	this.props.these('pageName', '');
				// 	this.props.these('checked', 0);
				// 	this.props.these('errorPageName', '');
				// 	window.scroll({
				// 		top: 0,
				// 		left: 0,
				// 		behavior: 'smooth'
				// 	});

				// 	this.props.these('alertVisible', true);

				// }
			} else {
				formData.append("requireLogin", 'no');
				if (!this.state.isEdit) {
					const themeUpdate = await cmsContent.themeInsert('tbl_pages', formData).then((response) => {
						if (response && response.status === 200) {
							this.setState({ msgShow: true, msg: 'Theme updated successfully!' })
							setTimeout(() => {
								this.setState({ msgShow: false, content: '<p></p>' })
							}, 3000);

							window.location.reload()
						}
					})

				}
				// let { data } = await http.post(
				// 	ACCESS_POINT + `/cmsContent/tbl_pages`,
				// 	formData,
				// 	{
				// 		headers: {
				// 			"Content-Type": "multipart/form-data"
				// 		},
				// 		onUploadProgress: progressEvent => {
				// 			this.setState({
				// 				uploadPercentage: parseInt(
				// 					Math.round((progressEvent.loaded * 100) / progressEvent.total)
				// 				)
				// 			});
				// 			setTimeout(() => this.setState({ uploadPercentage: 0 }), 3000);
				// 			///10000
				// 		}
				// 	}
				// );
				// if (data && data.length) {
				// 	data = data[0];
				// 	const previousData = [data, ...this.state.data];
				// 	this.setState({
				// 		data: previousData,
				// 		content: '<p></p>',
				// 	});
				// 	this.props.these('pageName', '');
				// 	this.props.these('checked', 0);
				// 	this.props.these('errorPageName', '');
				// }
				// window.scroll({
				// 	top: 0,
				// 	left: 0,
				// 	behavior: 'smooth'
				// });
				// this.props.these('alertVisible', true);
			}
		} catch (error) {
			console.log(error);
		}
	}
	render() {
		const { uploadPercentage } = this.state;
		return (
			<React.Fragment>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2" />
					<div className="col-sm-5">
						<h2 style={{ color: "#000" }}>Content</h2>
					</div>
				</div>
				<div className="row form-group">
					<div className="col-sm-1" />
					<div className="col-sm-10" style={{ zIndex: 0, minHeight: 150 }}>
						<FroalaEditor
							model={this.state.content}
							onModelChange={this.handleModelChange}
						/>
					</div>
					<div className="col-sm-1" />
				</div>

				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-2" />
					<div className="col-sm-5">
						<button
							type="button"
							className={`btn ${this.state.isEdit ? 'btn-secondary' : 'btn-primary'}`}
							disabled={this.state.btnDisable}
							onClick={this.submitCategory}
						>
							{this.state.isEdit ? 'Update' : 'Add Page'}
						</button>
					</div>
					<div className="col-sm-3" />
				</div>
				<center>
					<div>
						{
							(this.state.msgShow)
								?
								<div style={{ color: 'forestgreen', fontSize: 'medium', fontWeight: 600, backgroundColor: '#AAF7C2', borderRadius: '7px' }}> {this.state.msg}</div>
								:
								<div></div>

						}
					</div>
				</center>
				<div className="row form-group">
					<div className="col-sm-2" />
					<div className="col-sm-7">
						{uploadPercentage > 0 && (
							<Progress percentage={uploadPercentage} />
						)}
					</div>
					<div className="col-sm-3" />
				</div>

				<div className="row form-group">
					<h2>Check</h2>
				</div>
				<div className="row form-group">
					<div className="col-sm-1" />
					<div className="col-sm-10">
						<FroalaEditorView
							model={this.state.content}
						/>
					</div>
					<div className="col-sm-1" />
				</div>
				<div className="row form-group">
					<div className="col-sm-12">
						{this.state.data ? (<Datatable
							data={this.state.data}
							columnHeading={this.column}
						/>) : null}
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default Theme2;