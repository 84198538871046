import React, { Component } from "react";
import { ACCESS_POINT } from "../../config";
import User from "../../MiddleWare/User";
import { Alert } from "reactstrap";
import CmsContent from "../../MiddleWare/CmsContent";
import { Link } from "react-router-dom";
import http from "../../MiddleWare/httpMiddleWare";
import moment from "moment";

var imagelogo = require("./difuzaWebLogo.png");
export default class AdminHeader extends Component {
  logout = async () => {
    var userlog = JSON.parse(localStorage.getItem("userlog"));
    var userId = localStorage.getItem("userId");
    console.log(userlog.customerId, "customerId");
    console.log(userId, "userlog");

    // return false
    if (userId == 11025 || userlog.customerId == 11025) {
      localStorage.clear();
      // localStorage.removeItem(`userlog`);
      window.location = "/feeltalk";
    } else {
      localStorage.clear();
      // localStorage.removeItem(`userlog`);
      window.location = "/";
    }
    // return false
    // const result1 = await CmsContent.getFreedom(
    //   "*",
    //   "tbl_user_web",
    //   "loginStatus = 'online'",
    //   1,
    //   1
    // );
    // if (result1) {

    //   result1.data.map(async (ival, i) => {
    //     if (userlog.id === ival.id) {
    //       let categoryArray = {};
    //       categoryArray.loginStatus = "offline";
    //       let res = await CmsContent.updateMaster(
    //         "tbl_user_web",
    //         userlog.id,
    //         categoryArray
    //       );
    //       console.log(res, "res");
    //       // localStorage.removeItem(`userlog${i}`);
    //       localStorage.clear();
    //       // localStorage.removeItem(`userlog`);
    //       window.location = "/";
    //     }
    //     else {
    //       // localStorage.removeItem(`userlog${i}`);
    //       localStorage.clear();
    //       // localStorage.removeItem(`userlog`);
    //       window.location = "/";
    //     }
    //   });
    // }
    // if (res) {
    // }
  };
  //viewmedia: ACCESS_POINT + `/superAdmin/file?fileurl=${value}`
  constructor(props) {
    super(props);
    var userlog = localStorage.getItem("userlog");
    userlog = JSON.parse(userlog);
    console.log(userlog);
    if (userlog == null) {
      window.location.href = "/";
    }

    var link = "";
    if (!userlog.link) {
      link = "http://www.difuza.com/";
    } else {
      link = userlog.link;
    }

    if (link !== "" && link !== undefined && link !== null) {
      link = this.getValidUrl(link);
    } else {
      link = "http://www.difuza.com/";
    }
    var len = userlog.image;
    var imgsrc = "";
    if (len != "" && len != undefined) {
      var imgsrc = userlog.id == 807 ? ACCESS_POINT + "/cmsContent/getImages/" + userlog.image : imagelogo;
    } else {
      imgsrc = imagelogo;
      // imagelogo 'https://www.grouptravelvideos.com/images/client/00954/resources/you%20logo%20here.png'
    }
    const date = new Date();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
    var day = date.getUTCDate();
    var year = date.getUTCFullYear();
    var newDate = year + "-" + month + "/" + day;
    let dataArray = {};
    dataArray.userId = userlog.id;
    dataArray.date = newDate;
    this.state = {
      userlog,
      userName: userlog.userName,
      imgsrc: imgsrc,
      userType: userlog.userType,
      link: link,
      show: false,
      UserList: [],
      pageJson: [],
      L1Json: [],
      L2Json: [],
      userJson: [],
      portJson: [],
      quizJson: [],
      surveyJson: [],
      formJson: [],
      QuizAttend: [],
      SurveyAttend: [],
      TotalNoti: 0,
      Notifi: null,
      dataArray,
      newDate,
      event: [],
      schoolEvent: []
    };
  }

  async componentWillMount() {
    try {

      let online = window.navigator.onLine;
      //console.log(online)
      //console.log(localStorage.getItem("userId"))
      if (!online) {
        //console.log('offline')

        let categoryArray = {};
        categoryArray.loginStatus = "offline";
        let result = await CmsContent.updateMaster(
          "tbl_user_web",
          localStorage.getItem("userId"),
          categoryArray
        );
      }

      let result = await User.getUserById(localStorage.getItem("userId"));
      localStorage.setItem("userlog", JSON.stringify(result.data[0]));
      let serviceId = localStorage.getItem("currentService");
      var userlog = JSON.parse(localStorage.getItem("userlog"));
      //console.log('userType =?'+userlog.userType)

      if (userlog.userType == 1) {
        let userUnder = await CmsContent.getFreedom(
          "*",
          "tbl_user_web",
          `loginStatus = 'online' and NOT userType = ${userlog.userType}`,
          1,
          1
        );
        this.setState({ userUnder: userUnder.data.length, userlog });
        this.Notification(serviceId);

        //console.log(userUnder.data.length)
        //console.log(userUnder.data)
      } else if (userlog.userType == 2) {
        let userUnderid = await CmsContent.getFreedom(
          "*",
          "tbl_user_web",
          ` customerId = ${userlog.id} and loginStatus = 'online'   `,
          1,
          1
        );

        let usertype_3 = await CmsContent.getFreedom(
          "*",
          "tbl_user_web",
          ` customerId = ${userlog.id} and userType=3  and loginStatus = 'online'   `,
          1,
          1
        );

        let usertype_6_8 = await CmsContent.getFreedom(
          "*",
          "tbl_user_web",
          `userType=6 and loginStatus = 'online' or userType=8 and loginStatus = 'online' and customerId = ${userlog.id}  `,
          1,
          1
        );
        if (userUnderid && userUnderid.data && userUnderid.data.length > 0) {
          let idvalue = "";
          userUnderid.data.map(value => {
            idvalue += value.id + ",";
            //console.log(value.id)
          });
          let removeidvalue = idvalue.replace(/,\s*$/, "");
          let userUnder = await CmsContent.getFreedom(
            "*",
            "tbl_user_web",
            `customerId = ${userlog.id} and loginStatus = 'online' or customerId in (${removeidvalue}) and loginStatus = 'online'   `,
            1,
            1
          );

          this.setState({ userUnder: userUnder.data.length, userlog });
          this.Notification(serviceId);
        } else {
          this.setState({ userUnder: 0, userlog });
          this.Notification(serviceId);
        }
      } else {
        let userUnderid = await CmsContent.getFreedom(
          "*",
          "tbl_user_web",
          ` customerId = ${userlog.id} and loginStatus = 'online'   `,
          1,
          1
        );
        let idvalue = "";
        if (userUnderid && userUnderid.data && userUnderid.data.length > 0) {
          userUnderid.data.map(value => {
            idvalue += value.id + ",";
            //console.log(value.id)
          });
          // console.log(idvalue)

          let removeidvalue = idvalue.replace(/,\s*$/, "");
          //console.log(removeidvalue)
          let userUnder = await CmsContent.getFreedom(
            "*",
            "tbl_user_web",
            `customerId = ${userlog.id} and loginStatus = 'online' or customerId in (${removeidvalue}) and loginStatus = 'online'   `,
            1,
            1
          );

          //console.log(`customerId = ${userlog.id} and loginStatus = 'online' or customerId in (${removeidvalue}) and loginStatus = 'online' `)
          // console.log('-->'+userUnder.data.length)
          //console.log(userUnder.data)

          this.setState({ userUnder: userUnder.data.length, userlog });
          this.Notification(serviceId);
        } else {
          this.setState({ userUnder: 0, userlog });
        }


      }
    } catch (error) {
    }
  }
  Notification = async serviceId => {
    const userlog = this.state.userlog;
    const customerId = userlog.customerId;
    const userid = userlog.id;
    //console.log(userid);
    const date = new Date();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //months from 1-12
    var day = date.getUTCDate();
    var year = date.getUTCFullYear();
    var hour = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    var newdate = year + "-" + month + "-" + day;

    var timesDate =
      year +
      "-" +
      month +
      "-" +
      day +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds;

    const myDate = new Date();
    const newDate = new Date(myDate);
    newDate.setHours(newDate.getHours() - 1);

    var BeforeMonth = ("0" + (newDate.getMonth() + 1)).slice(-2); //months from 1-12
    var BeforeDay = newDate.getUTCDate();
    var BeforeYear = newDate.getUTCFullYear();
    var BeforeHour = newDate.getHours();
    var BeforeMinutes = newDate.getMinutes();
    var BeforeSeconds = newDate.getSeconds();

    var AfterTimesDate =
      BeforeYear +
      "-" +
      BeforeMonth +
      "-" +
      BeforeDay +
      " " +
      BeforeHour +
      ":" +
      BeforeMinutes +
      ":" +
      BeforeSeconds;

    this.setState({ userid, newdate });
    let dataArray = {};
    dataArray.userId = userid;
    dataArray.date = newdate;
    // console.log("array", dataArray);

    if (userlog.userType === "1") {
      try {
        const L1 = await CmsContent.getFreedom(
          "userName as name",
          "tbl_user_web",
          `customerId = ${userid} and serviceId = 9 and createdAt BETWEEN "${AfterTimesDate}" AND "${timesDate}"`,
          1,
          1
        );
        await this.setState({ L1: L1.data, TotalNoti: L1.data.length });
      } catch (error) {
        console.log(error);
      }
    } else if (userlog.userType === "2") {
      try {
        const L2 = await CmsContent.getFreedom(
          "userName as name , id as value",
          "tbl_user_web",
          `customerId = ${userid}`,
          1,
          1
        );

        if (L2 && L2.data && L2.data.length > 0) {
          let id = [];
          L2.data.map((item, index) => {
            id.push(item.value);
          });
          let strng = id.toString();
          const UnderL2 = await CmsContent.getFreedom(
            "userName as name , id as value",
            "tbl_user_web",
            `customerId in (${strng})`,
            1,
            1
          );
          // console.log(L2, "L2");
          await this.setState({ L2: L2.data, TotalNoti: L2.data.length });
        } else {
          await this.setState({ L2: [], TotalNoti: 0 });
        }
      } catch (error) {
        console.log(error);
      }
    } else if (userlog.userType === "3") {
      const UserList = await CmsContent.getFreedom(
        "userName as name",
        "tbl_user_web",
        `customerId=${userid} and createdAt BETWEEN "${AfterTimesDate}" AND "${timesDate}"`,
        1,
        1
      );

      await this.setState({ UserList: UserList.data });
    }

    if (serviceId === 9 || serviceId === "9") {
      if (userlog.userType === "3") {
        try {
          if (
            localStorage.getItem("noti") === "" ||
            localStorage.getItem("noti") === undefined ||
            localStorage.getItem("noti") === null
          ) {
            const All = await CmsContent.PortletAdded(dataArray, "ALL");

            if (All.data) {
              localStorage.setItem("noti", JSON.stringify(All.data[0]));
            }
          } else {
            const All = await CmsContent.PortletAdded(dataArray, "ALL");
            if (All.data) {
              localStorage.setItem("noti", JSON.stringify(All.data[0]));
            }
          }
          let Notifi = JSON.parse(localStorage.getItem("noti"));
          console.log(Notifi);

          await this.setState({
            Notifi: Notifi
          });
          let portLen = 0;
          if (Notifi && Notifi.portlet) {
            portLen = Notifi.portlet.length;
          }
          let QuizLen = 0;
          if (Notifi && Notifi.quiz) {
            QuizLen = Notifi.quiz.length;
          }
          let SurveyLen = 0;
          if (Notifi && Notifi.survey) {
            SurveyLen = Notifi.survey.length;
          }
          let FormLen = 0;
          if (Notifi && Notifi.form) {
            FormLen = Notifi.form.length;
          }
          let UserLen = 0;
          if (Notifi && Notifi.user) {
            UserLen = Notifi.user.length;
          }
          let quizAttCount = 0;
          if (Notifi && Notifi.quizAtten) {
            quizAttCount = Notifi.quizAtten.length;
          }
          let surAttcount = 0;
          if (Notifi && Notifi.surveyAtten) {
            surAttcount = Notifi.surveyAtten.length;
          }

          var TotalNoti =
            portLen +
            QuizLen +
            SurveyLen +
            FormLen +
            UserLen +
            quizAttCount +
            surAttcount;
          this.setState({ TotalNoti: TotalNoti });
        } catch (error) {
          console.log(error);
        }
      }
      this.setState({ serviceId });
    } else if (serviceId === 8 || serviceId === "8") {
      try {
        const UserList = await CmsContent.getFreedom(
          "userName as name",
          "tbl_user_web",
          `customerId=${userid} and serviceId = 8 and createdAt BETWEEN "${AfterTimesDate}" AND "${timesDate}"`,
          1,
          1
        );
        const PageList = await CmsContent.getFreedom(
          "userName as user , tbl_pages.name as name",
          "tbl_user_web,tbl_pages",
          `tbl_pages.customerId=${userid} and tbl_pages.Date = "${newdate}" and tbl_user_web.id=${userid} `,
          "tbl_pages.id",
          "tbl_pages.id"
        );
        var TotalNoti = UserList.data.length + PageList.data.length;
        await this.setState({
          UserList: UserList.data,
          PageList: PageList.data,
          TotalNoti,
          serviceId
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  getValidUrl = (url = "") => {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if (/^(:\/\/)/.test(newUrl)) {
      return `http${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `https://${newUrl}`;
    }
    return newUrl;
  };
  async componentDidMount() {
    this.count();
    this.setNotiLocal();
    this.psychology();
    var userlog = localStorage.getItem("userlog");
    userlog = JSON.parse(userlog);
    console.log(userlog, "userlog");
    if (userlog.id) {
      try {
        const Class = await CmsContent.getFreedom(
          "*",
          "tbl_user_web",
          `id=${userlog.id}`,
          "id",
          "id"
        );
        if (Class) {
          console.log(Class.data)
          let data =Class.data
          if (userlog.mobileNumber !== data[0].mobileNumber) {
            console.log("pls logout")
            var alert = document.getElementById("alert")
            alert.style.opacity = 1
            alert.style.transition = "all 0.7s ease-in-out"
            setTimeout(() => {
              localStorage.clear()
              console.log("clear")
              window.location.href = "/"
            }, 3000);
          }
        }
      }
      catch {

      }
    }

  }
  psychology = async () => {
    let customerId = localStorage.getItem("userId")
    let usertype = JSON.parse(localStorage.getItem("userlog")).userType
    let userCustomerId = JSON.parse(localStorage.getItem("userlog")).customerId
    let userid = localStorage.getItem("userId")
    const today = moment()
    const todayDate = today.format("YYYY-MM-DD")
    let currentime = today.format('HH:mm')

    if (customerId == 11025 && usertype == "3") {
      try {
        const result = await CmsContent.getFreedom(
          "*",
          "Psychol_schedule",
          `id`,
          "id",
          "id"
        );
        // console.log(result, "result")
        if (result && result.data.length > 0) {
          let data = result.data
          let event = []
          data.map((ival, index) => {
            let stdate = moment(ival.start).format('YYYY-MM-DD')
            let enddate = moment(ival.end).format('YYYY-MM-DD')
            let time = moment(ival.end).format('HH:mm');
            if (stdate === todayDate || (stdate <= todayDate && todayDate <= enddate)) {
              event.push(ival)
            }
            this.setState({ event: event, TotalNoti: event.length })
          })
        }
      }
      catch (error) {
        console.log("error")
      }
    }
    if (userCustomerId == 11025 && usertype == "4") {
      let onemonthago = moment().subtract(1, 'months').format('YYYY-MM-DD')
      let onemonthafter = moment().add(1, 'months').format('YYYY-MM-DD');
      try {
        const result = await CmsContent.getFreedom(
          "*",
          "Psychol_schedule",
          `customerid='${userid}' AND DATE(Psychol_schedule.start) BETWEEN DATE('${onemonthago}') AND DATE('${onemonthafter}')`,
          1,
          1
        );
        if (result && result.data.length > 0) {
          let data = result.data
          let event = []
          data.map(async (ival, index) => {
            let stdate = moment(ival.start).format('YYYY-MM-DD')
            let enddate = moment(ival.end).format('YYYY-MM-DD')
            let time = moment(ival.end).format('HH:mm');
            if (stdate === todayDate || (stdate <= todayDate && todayDate <= enddate)) {
              await event.push(ival)
            }
            await this.setState({ schoolEvent: data, TotalNoti: event.length })
          })
        }
      }
      catch (error) {
        console.log("error")
      }
    }

  }

  count = () => {
    const { Notifi } = this.state;
    let portLen = 0;
    if (Notifi && Notifi.portlet) {
      portLen = Notifi.portlet.length;
    }
    let QuizLen = 0;
    if (Notifi && Notifi.quiz) {
      QuizLen = Notifi.quiz.length;
    }
    let SurveyLen = 0;
    if (Notifi && Notifi.survey) {
      SurveyLen = Notifi.survey.length;
    }
    let FormLen = 0;
    if (Notifi && Notifi.form) {
      FormLen = Notifi.form.length;
    }
    let UserLen = 0;
    if (Notifi && Notifi.user) {
      UserLen = Notifi.user.length;
    }
    let quizAttCount = 0;
    if (Notifi && Notifi.quizAtten) {
      quizAttCount = Notifi.quizAtten.length;
    }
    let surAttcount = 0;
    if (Notifi && Notifi.surveyAtten) {
      surAttcount = Notifi.surveyAtten.length;
    }

    var TotalNoti =
      portLen +
      QuizLen +
      SurveyLen +
      FormLen +
      UserLen +
      quizAttCount +
      surAttcount;
    this.setState({ TotalNoti });
  };
  setNotiLocal = async () => {
    let { Notifi, userid, newdate } = this.state;
    let removedId = JSON.parse(localStorage.getItem("removed"));
    let dataArray = {};
    dataArray.userId = userid;
    dataArray.date = newdate;
    const All = await CmsContent.PortletAdded(this.state.dataArray, "ALL");
    if (All.data && All.data.length === 0) {
      All.data.map((item, index) => {
        item.user.map((ival, i) => {
          let uturn = removedId.map((jval, j) => {
            //console.log(jval);
            //         if (ival.id !== jval.id) {
            //           return ival;
            //         }
          });
          //console.log(uturn);
          //       Notifi.user = uturn;
        });
      });
      console.log("All", All);
      localStorage.setItem("noti", JSON.stringify(All.data[0]));
      // }
    }
    let Noti = JSON.parse(localStorage.getItem("noti"));
    this.setState({ Notifi: Noti });
  };
  SetJson = async () => {
    const {
      userlog,
      Notifi,
      PageList,
      L1,
      L2,
      port,
      Quiz,
      Survey,
      Form,
      UserList
    } = this.state;

    let L1Json = [];
    let L2Json = [];
    let pageJson = [];
    let userJson = [];
    let portJson = [];
    let quizJson = [];
    let surveyJson = [];
    let formJson = [];
    let QuizAttend = [];
    let SurveyAttend = [];
    if (Notifi) {
      Notifi.user.map((ival, i) => {
        console.log(ival);
        if (ival !== null) {
          userJson.push({ id: ival.id, name: ival.userName });
        }
      });
      Notifi.portlet.map((ival, i) => {
        if (ival !== null) {
          portJson.push({ name: ival.name, id: ival.id, user: userlog.userName });
        }
      });
      Notifi.form.map((ival, i) => {
        if (ival !== null) {
          formJson.push({ name: ival.name, id: ival.id, user: userlog.userName });
        }
      });
      Notifi.quiz.map((ival, i) => {
        if (ival !== null) {
          quizJson.push({
            name: ival.quizName,
            id: ival.id,
            user: userlog.userName
          });
        }
      });
      Notifi.survey.map((ival, i) => {
        if (ival !== null) {
          surveyJson.push({
            name: ival.surveyname,
            id: ival.id,
            user: userlog.userName
          });
        }
      });
      Notifi.quizAtten.map((ival, i) => {
        if (ival !== null) {
          QuizAttend.push({ id: ival.id, user: ival.user, name: ival.name });
        }
      });
      Notifi.surveyAtten.map((ival, i) => {
        if (ival !== null) {
          SurveyAttend.push({ id: ival.id, user: ival.user, name: ival.name });
        }
      });

      if (L1 && L1.length > 0) {
        L1.map((item, i) => {
          L1Json.push({
            name: item.name
          });
        });
      } else if (L2 && L2.length > 0) {
        L2.map((item, i) => {
          L2Json.push({
            name: item.name
          });
        });
      } else if (PageList && PageList.length > 0) {
        PageList.map(async (item, i) => {
          pageJson.push({
            user: item.user,
            name: item.name
          });
        });
      }
      await this.setState({
        L1Json: L1Json,
        L2Json: L2Json,
        portJson: portJson,
        quizJson: quizJson,
        surveyJson: surveyJson,
        formJson: formJson,
        userJson: userJson,
        pageJson: pageJson,
        QuizAttend: QuizAttend,
        SurveyAttend: SurveyAttend
      });
    };
  }
  remove = (index, type, id) => {
    const {
      Notifi,
      serviceId,
      L1Json,
      L2Json,
      portJson,
      quizJson,
      surveyJson,
      formJson,
      userJson,
      TotalNoti,
      pageJson,
      QuizAttend
    } = this.state;

    if (type === "L1user") {
      L1Json.splice(index, 1);
      this.setState({ L1Json: [] });
    } else if (type === "L2user") {
      L2Json.splice(index, 1);
      this.setState({ L2Json: [] });
    } else if (type === "port") {
      Notifi.portlet.splice(index, 1);
    } else if (type === "quiz") {
      Notifi.quiz.splice(index, 1);
    } else if (type === "survey") {
      Notifi.survey.splice(index, 1);
    } else if (type === "form") {
      Notifi.form.splice(index, 1);
    } else if (type === "user") {
      Notifi.user.splice(index, 1);
    } else if (type === "page" && serviceId === "8") {
      pageJson.splice(index, 1);
      this.setState({ pageJson: [] });
    } else if (type === "quizAtt") {
      Notifi.quizAtten.splice(index, 1);
    } else if (type === "surveyAtt") {
      Notifi.surveyAtten.splice(index, 1);
    }
    let removed = [];
    removed.push({ type: type, index: index, id: id });
    localStorage.setItem("removed", JSON.stringify(removed));
    localStorage.setItem("noti", JSON.stringify(Notifi));
    this.setState({ Notifi: JSON.parse(localStorage.getItem("noti")) });
    this.setNotiLocal();
    var Total = TotalNoti - 1;
    this.setState({
      L1Json,
      L2Json,
      portJson,
      quizJson,
      surveyJson,
      formJson,
      userJson,
      pageJson,
      TotalNoti: Total
    });
    this.SetJson();
  };
  render() {
    const {
      userName,
      imgsrc,
      userType,
      link,
      userUnder,
      surveyCount,
      L1Json,
      L2Json,
      User,
      portJson,
      quizJson,
      surveyJson,
      formJson,
      userJson,
      pageJson,
      TotalNoti,
      QuizAttend,
      SurveyAttend,
      userlog
    } = this.state;
    const styles = {
      width: "230px",
      height: "76px",
      marginRight: "15px",
      paddingRight: "0px",
      marginLeft: "-25px",
      marginBottom: "0px",
      paddingBottom: "18px"
    };
    let pageNoti = [];
    if (pageJson && pageJson.length > 0) {
      pageJson.map((item, index) => {
        pageNoti.push(
          <span className="dropdown-item">
            <i className="icon-user-unfollow text-success" />
            <span style={{ color: "red" }}>{item.user}</span> added New Page{" "}
            <span style={{ color: "red" }}>{item.name}</span>
            <i
              className="fa fa-times-circle-o"
              aria-hidden="true"
              style={{ margin: "0px" }}
              onClick={() => this.remove(index, "page")}
            ></i>
          </span>
        );
      });
    }

    let L1Noti = [];
    if (L1Json && L1Json.lenth > 0) {
      L1Json.map((item, index) => {
        L1Noti.push(
          <span className="dropdown-item">
            <i className="icon-user-unfollow text-success" />
            Added New User <span style={{ color: "red" }}>{item.name}</span>
            <i
              className="fa fa-times-circle-o"
              aria-hidden="true"
              style={{ margin: "0px" }}
              onClick={() => this.remove(index, "L1user")}
            ></i>
          </span>
        );
      });
    }

    let L2Noti = [];
    if (L2Json && L2Json.length > 0) {
      L2Json.map((item, index) => {
        L2Noti.push(
          <span className="dropdown-item">
            <i className="icon-user-unfollow text-success" />
            Added New User <span style={{ color: "red" }}>{item.name}</span>
            <i
              className="fa fa-times-circle-o"
              aria-hidden="true"
              style={{ margin: "0px" }}
              onClick={() => this.remove(index, "L2user")}
            ></i>
          </span>
        );
      });
    }

    let userNoti = [];
    if (userJson && userJson.length > 0) {
      userJson.map((item, index) => {
        userNoti.push(
          <span className="dropdown-item">
            <i className="icon-user-unfollow text-success" />
            Added New User <span style={{ color: "red" }}>{item.name}</span>
            <i
              className="fa fa-times-circle-o"
              aria-hidden="true"
              style={{ margin: "0px" }}
              onClick={() => this.remove(index, "user", item.id)}
            ></i>
          </span>
        );
      });
    }

    let portNoti = [];
    if (portJson && portJson.length > 0) {
      portJson.map((item, index) => {
        portNoti.push(
          <span className="dropdown-item">
            <i className="icon-user-unfollow text-success" />
            <span style={{ color: "red" }}>{item.user}</span> added New Portlet{" "}
            <span style={{ color: "red" }}>{item.name}</span>
            <i
              className="fa fa-times-circle-o"
              aria-hidden="true"
              style={{ margin: "0px" }}
              onClick={() => this.remove(index, "port", item.id)}
            ></i>
          </span>
        );
      });
    }

    let quizNoti = [];
    if (quizJson && quizJson.length > 0) {
      quizJson.map((item, index) => {
        quizNoti.push(
          <span className="dropdown-item">
            <i className="icon-user-unfollow text-success" />
            <span style={{ color: "#4dbd74" }}>{item.user}</span> added Quiz{" "}
            <span style={{ color: "#4dbd74" }}>{item.name}</span>
            <i
              className="fa fa-times-circle-o"
              aria-hidden="true"
              style={{ margin: "0px" }}
              onClick={() => this.remove(index, "quiz", item.id)}
            ></i>
          </span>
        );
      });
    }

    let quizAttList = [];
    if (QuizAttend && QuizAttend.length > 0) {
      QuizAttend.map((item, index) => {
        quizAttList.push(
          <span className="dropdown-item">
            <i className="icon-user-unfollow text-success" />
            <span style={{ color: "#4dbd74" }}>{item.user}</span> completed Quiz{" "}
            <span style={{ color: "#4dbd74" }}>{item.name}</span>
            <i
              className="fa fa-times-circle-o"
              aria-hidden="true"
              style={{ margin: "0px" }}
              onClick={() => this.remove(index, "quizAtt", item.id)}
            ></i>
          </span>
        );
      });
    }

    let surveyNoti = [];
    if (surveyJson && surveyJson.length > 0) {
      surveyJson.map((item, index) => {
        surveyNoti.push(
          <span className="dropdown-item">
            <i className="icon-user-unfollow text-success" />
            <span style={{ color: "#4dbd74" }}>{item.user}</span> added Survey{" "}
            <span style={{ color: "#4dbd74" }}>{item.name}</span>
            <i
              className="fa fa-times-circle-o"
              aria-hidden="true"
              style={{ margin: "0px" }}
              onClick={() => this.remove(index, "survey", item.id)}
            ></i>
          </span>
        );
      });
    }

    let surAttNoti = [];
    if (SurveyAttend && SurveyAttend.length > 0) {
      SurveyAttend.map((item, index) => {
        surAttNoti.push(
          <span className="dropdown-item">
            <i className="icon-user-unfollow text-success" />
            <span style={{ color: "#4dbd74" }}>{item.user}</span> attend Survey{" "}
            <span style={{ color: "#4dbd74" }}>{item.name}</span>
            <i
              className="fa fa-times-circle-o"
              aria-hidden="true"
              style={{ margin: "0px" }}
              onClick={() => this.remove(index, "surveyAtt", item.id)}
            ></i>
          </span>
        );
      });
    }

    let formNoti = [];
    if (formJson && formJson.length > 0) {
      formJson.map((item, index) => {
        formNoti.push(
          <span className="dropdown-item">
            <i className="icon-user-unfollow text-success" />
            <span style={{ color: "#4dbd74" }}>{item.user}</span> added Form{" "}
            <span style={{ color: "#4dbd74" }}>{item.name}</span>
            <i
              className="fa fa-times-circle-o"
              aria-hidden="true"
              style={{ margin: "0px" }}
              onClick={() => this.remove(index, "form", item.id)}
            ></i>
          </span>
        );
      });
    }

    return (
      <React.Fragment>
        <header className="app-header navbar" style={{ paddingTop: "0px" }}>
          <button
            className="navbar-toggler sidebar-toggler d-lg-none mr-auto"
            type="button"
            data-toggle="sidebar-show"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <a className="navbar-brand" href={link}>
            {/*  {parseInt(userType) === 1 && <img className="navbar-brand-minimized" src={imgsrc} width="30" height="30" alt="Logo" />}
            {parseInt(userType) === 2 && (
              <img className="navbar-brand-minimized" src="https://www.grouptravelvideos.com/images/client/00954/resources/you%20logo%20here.png" width="30" height="30" alt="Logo" />
            )}
            {parseInt(userType) === 3 && (
              <img className="navbar-brand-minimized" src="https://www.grouptravelvideos.com/images/client/00954/resources/you%20logo%20here.png" width="30" height="30" alt="Logo" />
            )}*/}
            {/*https://www.grouptravelvideos.com/images/client/00954/resources/you%20logo%20here.png *
            {this.state.imgsrc && <img src={this.state.imgsrc} width="89" height="25" alt="Logo" />}*/}
            <img
              className="navbar-brand-full"
              src={imgsrc}
              style={styles}
              alt="Logo"
            />
            { }
          </a>
          <button
            className="navbar-toggler sidebar-toggler d-md-down-none"
            type="button"
            data-toggle="sidebar-lg-show"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <p className="nav-username">{userName}</p>
          <ul className="nav navbar-nav ml-auto ">
            <li className="nav-item dropdown">
              <span
                className="nav-link res-bell"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => this.SetJson()}
              >
                <i className="icon-bell" />
                <span className="badge badge-pill badge-danger">
                  {TotalNoti}
                </span>
              </span>
              <div
                className="dropdown-menu dropdown-menu-right dropdown-menu-lg dropdown-menu-sm res-drop-down"
                style={{
                  width: "289px",
                  height: '550px',
                  overflowY: 'scroll'
                }}
              >
                <div className="dropdown-header " style={{ backgroundColor: "white" }}>
                  <p className="text-center">You have {TotalNoti} notifications</p>
                  {this.state.event && this.state.event.length ? <>
                    <p className="text-center">Event List</p>
                    {this.state.event.map((ival, index) => {
                      let eventStDate = ival.start.split('T')
                      return (
                        <div className="text-start mt-2 event-noti">
                          <div>
                            <p> School name : {ival.schoolname}</p>
                          </div>
                          <div>
                            <p>Topic : {ival.title}</p>
                          </div>
                          <div>
                            <p>Venue : {ival.venue}</p>
                          </div>
                        </div>
                      )
                    })}
                  </> : ""}
                  {this.state.schoolEvent && this.state.schoolEvent.length ? <>
                    {this.state.schoolEvent.map((ival, index) => {
                      let today = moment()
                      const todayDate = today.format("YYYY-MM-DD")
                      let currentime = today.format('HH:mm')
                      let stdate = moment(ival.start).format('YYYY-MM-DD')
                      let enddate = moment(ival.end).format('YYYY-MM-DD')
                      let time = moment(ival.start).format('HH:mm');
                      if (todayDate > stdate && enddate < todayDate) {
                        // past events
                        return (
                          <div className="past-events">
                            <div className="py-1">
                              <p>Department :{ival.department}</p>
                            </div>
                            <div className="py-1">
                              <p>Venue :{ival.venue}</p>
                            </div>
                            <div className="py-1">
                              <p>Date:{moment(ival.start).format('DD-MM-YYYY')}</p>
                            </div>
                            <div className="py-1">
                              <p>Time:{moment(ival.start).format('HH:mm')}</p>
                            </div>
                          </div>
                        )
                      }
                      if (stdate === todayDate || (stdate <= todayDate && todayDate <= enddate)) {
                        // present event
                        return (
                          <div>
                            <div className="present-events">
                              <div className="py-1">
                                <p>Department :{ival.department}</p>
                              </div>
                              <div className="py-1">
                                <p>Venue:{ival.venue}</p>
                              </div>
                              <div className="py-1">
                                <p>Date:{moment(ival.start).format('DD-MM-YYYY')}</p>
                              </div>
                              <div className="py-1">
                                <p>Time:{moment(ival.start).format('HH:mm')}</p>
                              </div>
                            </div>
                          </div>
                        )
                      }
                      if (todayDate < stdate && todayDate < enddate) {
                        // future events
                        return (
                          <div className="future-events">
                            <div className="py-1">
                              <p>Department :{ival.department}</p>
                            </div>
                            <div className="py-1">
                              <p>Venue :{ival.venue}</p>
                            </div>
                            <div className="py-1">
                              <p>Date:{moment(ival.start).format('DD-MM-YYYY')}</p>
                            </div>
                            <div className="py-1">
                              <p>Time:{moment(ival.start).format('HH:mm')}</p>
                            </div>
                          </div>
                        )
                      }
                    })}

                  </> : <></>}
                </div>
                {L1Noti}
                {L2Noti}
                {userNoti}
                {pageNoti}
                {portNoti}
                {quizNoti}
                {surveyNoti}
                {formNoti}
                {quizAttList}
                {surAttNoti}
                {/* <span className="dropdown-item">
                  <i className="icon-user-unfollow text-success" />
                  New Survey
                  <span className="badge badge-pill badge-danger">
                    {surveyCount}
                  </span>
                </span>
                <span className="dropdown-item">
                  <i className="icon-user-unfollow text-danger" /> User deleted
                </span> */}
              </div>
            </li>

            <li className="nav-item dropdown">
              <span
                className="nav-link nav-link"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  className="img-avatar"
                  style={{ width: "35px" }}
                  src={
                    ACCESS_POINT + "/cmsContent/getImages/" + userlog.image
                  }
                  alt="userName"
                />
              </span>
              <div className="dropdown-menu dropdown-menu-right">
                <span className="dropdown-item" onClick={this.logout}>
                  <i className="fa fa-lock" /> Logout
                </span>
              </div>
            </li>
            <p className="nav-usercount" style={{ float: "right" }}>
              Currently Logged in Users <a href="/DF/UserUnder">{userUnder}</a>
            </p>
          </ul>
        </header>
        <section id="alert">
          <div className="box">
            <div className="d-flex justify-content-center align-items-center">
              <div className="text-center">
                <div>
                  <p className="fa fa-exclamation-circle"></p>
                </div>
                <div className="py-1 invalid">
                  <h3>Invalid User Details</h3>
                </div>

                <div className="py-1 message">
                  <p>Your session will be logout in few seconds</p>
                </div>

                <div className="message">
                  <p>pls check your username & password</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
