
import React, { Component } from "react";
import SingleSelect from "../../../components/Form/SingleSelect";
import cmsContent from "../../../MiddleWare/CmsContent";
import LoginModal from "../../../components/Modal/Modal";
import Addauthormodel from "./Addauthormodel";
import { Alert } from "reactstrap";
import { ACCESS_SAMPLE } from "../../../config";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { CSVLink, CSVDownload } from "react-csv";
import AddpagePDF from "../../../components/PDF/Addpagespdf";
import moment, { relativeTimeRounding } from "moment";
//import Theme9 from './addPages/theme9';
import Datatable from "../../../components/Datatable/Datatable";
import { FormMiddleWare } from "../../../components/Form";
import { confirmAlert } from "react-confirm-alert";

class AddUserType extends FormMiddleWare {
    constructor(props) {
        super(props);
        this.state = {
            alertVisible: false,
            customerId: localStorage.getItem("userId"),
            data: [],
            user_name: "",
            createdAt: moment(new Date()).format("YYYY-MM-DD"),
            danger: "btn btn-danger",
            success: "btn btn-success",
        };
    }
    column = [
        {
            Header: "Id ",
            accessor: "id",
        },
        {
            Header: "UserType Name",
            accessor: "usertype",
        },
        {
            Header: "CreatedAt",
            accessor: "createdAt",
            Cell: d => this.DateView(d.original)
        },
    ];
    async componentDidMount() {
        this.MainFun()
    }
    MainFun = async () => {
        try {
            this.setState({ loading: true })
            const Employee = await cmsContent.getFreedom(
                "*",
                "tbl_UserType",
                `customerid = ${this.state.customerId} and serviceid = 8`,
                "id",
                "id DESC"
            );
            if (Employee) {
                this.setState({ data: Employee.data });
            }
            this.setState({ loading: false })
        } catch (error) {
            console.log(error);
            this.setState({ loading: false })
        }
    }
    DateView = (d) => {
        let date = d.createdAt
        let curdate = date.split(" ")
        return (
            <span> {curdate[0]}</span>

        );
    }
    getValueFromArray1 = (d, array, ch = "value", ch1 = "label") => {
        if (array.length > 0) {
            if (array.length !== 0) {
                let filtered = array.filter(function (item) {
                    return item[ch] == d;
                });
                let v = filtered[0];
                if (v != undefined) {
                    return v;
                } else {
                    return '-';
                }
            }
        } else {
            return '-';
        }
    };
    handlechange = (e) => {
        this.setState({ [e.target.name]: e.target.value, errorempname: "", errorCode: "" });
    };
    submitCategory = async () => {
        const { user_name, createdAt } = this.state;

        if (!user_name) {
            this.setState({
                errorusername: "Please Enter User Name ",
            });
            return false;
        }
        let categoryArray = {}
        categoryArray.usertype = user_name
        categoryArray.status = "active"
        categoryArray.customerid = this.state.customerId
        categoryArray.serviceid = 8;
        categoryArray.createdAt = createdAt
        console.log(categoryArray, "categoryArray");
        try {
            const result = await cmsContent.addMaster(
                "tbl_UserType",
                categoryArray
            );
            if (result) {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                await this.setState({
                    errorusername: '', user_name: "",
                    alertVisible: true,

                });
                this.MainFun()
                setTimeout(() => this.setState({ alertVisible: false, errorusername: '', user_name: "" }), 3000);
            }
        } catch (error) {
            console.log(error);
        }
    };
    render() {
        const { MOlist, //marketing off
            Saleslist
        } = this.state;
        return (
            <React.Fragment>
                <main className="main my-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Add UserType</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-7">
                                                <Alert
                                                    className="badge-content"
                                                    color="success"
                                                    isOpen={this.state.alertVisible}
                                                    toggle={this.onDismiss}
                                                >
                                                    Added Successfully
                                                </Alert>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2">
                                                <label htmlFor="exampleInputEmail1">USERTYPE NAME</label>
                                            </div>
                                            <div className="col-sm-5">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="Name"
                                                    name="user_name"
                                                    placeholder="Enter name"
                                                    value={this.state.user_name}
                                                    onChange={this.handlechange}
                                                />
                                            </div>
                                        </div>
                                        <br />
                                        <div className="row form-group">
                                            <div className="col-sm-4" />
                                            <span className="error-show ">
                                                {this.state.errorusername}
                                            </span>
                                        </div>
                                        <br />
                                        <div className="row form-group">
                                            <div className="col-sm-2" />
                                            <div className="col-sm-2" />
                                            <div className="col-sm-5">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"

                                                    onClick={this.submitCategory}
                                                >
                                                    {this.state.isEdit ? 'Update' : 'Submit'}
                                                </button>
                                            </div>
                                            <div className="col-sm-3" />
                                        </div>
                                        <br />

                                        <div className="row form-group">
                                            <div className="col-sm-12">
                                                {this.state.loading ? <div className="text-center"> <i
                                                    className="fa fa-spinner fa-3x fa-spin" style={{ color: "blueviolet" }}
                                                ></i></div> : this.state.data && this.state.data.length ? (
                                                    <Datatable
                                                        data={this.state.data}
                                                        columnHeading={this.column}
                                                    />
                                                ) : <div className="text-center">No Data Found</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </React.Fragment>
        );
    }
}

export default AddUserType;
